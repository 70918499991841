import queryString from 'query-string';
import { apiGET, apiPOST, apiPATCH, apiDELETE } from './api';

export const getUserRegistrations = () => apiGET('/user_registrations');

export const getUserRegistrationByUserId = (userId, accessTokenOverride) => apiGET(
  `/user_registrations?user_id=${userId}`,
  accessTokenOverride
);

export const getUserRegistrationByEmail = (email, accessTokenOverride) => apiGET(
  `/user_registrations?${queryString.stringify({ email })}`,
  accessTokenOverride
);

export const createUserRegistration = (data, accessTokenOverride) => apiPOST(
  '/user_registrations',
  data,
  accessTokenOverride
);

export const updateUserRegistration = (email, data, accessTokenOverride) => apiPATCH(
  `/user_registrations/${email}`,
  data,
  accessTokenOverride
);

export const deleteUserRegistration = userRegistrationId => apiDELETE(`/user_registrations/${userRegistrationId}`);

export const sendSmsVerifyRequest = (userRegistrationId, accessTokenOverride) => apiPOST(
  `/user_registrations/${userRegistrationId}/smsverifyrequests`,
  {},
  accessTokenOverride
);

export const requestVerificationEmail = (userRegistrationId, data, accessTokenOverride) => apiPOST(
  `/user_registrations/${userRegistrationId}/emailverifyrequests`,
  data,
  accessTokenOverride
);
