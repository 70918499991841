import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Grid, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import DonationContext from '../../../../context/DonationContext/DonationContext';
import PickupsFormVariant from './PickupsFormVariant/PickupsFormVariant';
import { donationManageActions } from '../../../../helpers/donations';
import { FREQUENCY_ONCE } from '../../../../models/donationsNew';
import classNames from 'classnames';
import { DONATION_TYPE_REQUEST_APPROVAL, DONATION_TYPE_SD } from '../../../../actions/donationNew';

const usePickupWrapperStyles = makeStyles(() => ({
  pickupsWrapper: {
    overflow: 'scroll',
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
}));

const Pickups = () => {
  const classes = usePickupWrapperStyles();
  const {
    all_days_are_the_same: allDaysTheSame,
    all_pickups_are_the_same: allPickupsTheSame,
    frequency: frequency,
    pickup_specs: pickups,
  } = useSelector(state => state.ui.donation_new.draft);
  const diffInflight = useSelector(state => state.ui.donation_new.diffInflight);

  const { onFieldChange, donationType } = useContext(DonationContext);

  if (!pickups || pickups.length === 0) {
    return null;
  }
  return (
    <Grid container item spacing={2}>
      {[DONATION_TYPE_SD].includes(donationType) && frequency === FREQUENCY_ONCE && pickups.length > 1 && (
        <Grid container item xs={12}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={diffInflight}
                  onChange={event => {
                    if (event.target.checked) {
                      return onFieldChange(donationManageActions.set_all_days_are_the_same);
                    }

                    return onFieldChange(donationManageActions.clear_all_pickups_are_the_same);
                  }}
                  name="all_pickups_are_the_same"
                  checked={allPickupsTheSame || false} //fallback for undefined value
                />
              }
              label="All pickups are the same"
            />
          </Grid>
        </Grid>
      )}
      {frequency !== FREQUENCY_ONCE && (
        <Grid container item xs={12}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={diffInflight}
                  onChange={event => {
                    if (event.target.checked) {
                      return onFieldChange(donationManageActions.set_all_days_are_the_same);
                    }
                    return onFieldChange(donationManageActions.clear_all_days_are_the_same);
                  }}
                  name="all_days_are_the_same"
                  checked={allDaysTheSame || false} //fallback for undefined value
                />
              }
              label="All days are the same"
            />
          </Grid>
          {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={diffInflight}
                    onChange={event => {
                      if (event.target.checked) {
                        return onFieldChange(donationManageActions.set_all_pickups_are_the_same);
                      }
                      return onFieldChange(donationManageActions.clear_all_pickups_are_the_same);
                    }}
                    name="all_pickups_are_the_same"
                    checked={allPickupsTheSame || false} //fallback for undefined value
                  />
                }
                label="All pickups are the same"
              />
            </Grid>
          )}
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        className={classNames({
          [classes.pickupsWrapper]:
            !(frequency !== FREQUENCY_ONCE && allDaysTheSame) && !(frequency === FREQUENCY_ONCE && allPickupsTheSame),
        })}
      >
        <PickupsFormVariant />
      </Grid>
    </Grid>
  );
};

export default Pickups;
