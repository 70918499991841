import React from 'react';
import { Link } from 'react-router-dom';
import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  Language as LanguageIcon,
} from '@material-ui/icons';
import { TikTokIcon } from '../assets/SvgIcons';
import { Box } from '@material-ui/core';
import HtmlTooltip from './Common/HtmlTooltip';

const SocialMediaLinkIcons = ({ iconColor = '#313131' }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: `8px`,
        alignItems: 'center',
      }}
    >
      <HtmlTooltip title="Facebook" withBorder>
        <Link to={{ pathname: 'https://www.facebook.com/FoodRescueUS' }} target="_blank">
          <FacebookIcon fontSize="large" htmlColor={iconColor} />
        </Link>
      </HtmlTooltip>

      <HtmlTooltip title="Instagram" withBorder>
        <Link to={{ pathname: 'https://www.instagram.com/foodrescueus/' }} target="_blank">
          <InstagramIcon fontSize="large" htmlColor={iconColor} />
        </Link>
      </HtmlTooltip>

      <HtmlTooltip title="Twitter" withBorder>
        <Link to={{ pathname: 'https://twitter.com/foodrescueUS' }} target="_blank">
          <TwitterIcon fontSize="large" htmlColor={iconColor} />
        </Link>
      </HtmlTooltip>

      <HtmlTooltip title="LinkedIn" withBorder>
        <Link to={{ pathname: 'https://www.linkedin.com/company/food-rescue-us/' }} target="_blank">
          <LinkedInIcon fontSize="large" htmlColor={iconColor} />
        </Link>
      </HtmlTooltip>

      <HtmlTooltip title="TikTok" withBorder>
        <Link to={{ pathname: 'https://www.tiktok.com/@foodrescueus' }} target="_blank">
          <TikTokIcon fontSize="large" htmlColor={iconColor} width="35px" height="40px" />
        </Link>
      </HtmlTooltip>

      <HtmlTooltip title="FoodRescue US" withBorder>
        <Link to={{ pathname: 'https://foodrescue.us/' }} target="_blank">
          <LanguageIcon fontSize="large" htmlColor={iconColor} />
        </Link>
      </HtmlTooltip>
    </Box>
  );
};

export default SocialMediaLinkIcons;
