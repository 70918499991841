import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import OverlayLoader from '../OverlayLoader';

export const BaseCard = ({ children, ...rest }) => (
  <Card square elevation={24} {...rest}>
    {children}
  </Card>
);

export const DashboardTableCard = withStyles({
  root: {
    '& * .MuiCardHeader-root': {
      borderBottom: '1px solid rgba(32,39,140,.125)',
    },
  },
})(({ children, isLoading = false, ...rest }) => (
  <Card square elevation={25} {...rest}>
    <OverlayLoader isLoading={isLoading}>{children}</OverlayLoader>
  </Card>
));
