import React from 'react';
import { Badge, colors, withStyles } from '@material-ui/core';

const DashboardMobileTabBadgeCounter = withStyles({
  badge: {
    background: colors.amber[600],
    color: colors.grey[900],
  },
})(Badge);

export default DashboardMobileTabBadgeCounter;
