import queryString from 'query-string';
import { apiGET } from './api';

export const getBadges = userId => {
  const query = queryString.stringify(
    {
      user_id: userId,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/badges?${query}`);
};
