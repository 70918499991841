import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { cardColors, DashboardCard } from './DashboardCard';
import { numberFormatter } from '../../helpers/formatters';
import useHasActiveFeatureFlag from '../../hooks/useFeatureFlags';
import { FF_LIFETIME_RESCUERS_METRIC } from '../FeatureFlagWrapper';

const bigNumberFormatter = numberFormatter();
const smallNumberFormatter = numberFormatter({ signDisplay: 'exceptZero' });

const totalValueFormatter = value => (isNaN(value) ? undefined : bigNumberFormatter.format(value));
const currentValueFormatter = value => (isNaN(value) ? undefined : smallNumberFormatter.format(value));

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  card: {
    flexGrow: 1,
    minWidth: 'fit-content',
    maxWidth: 280,
    flexBasis: 230,
    [breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    gridGap: spacing(2),
  },
}));

const DashboardGrowthCards = ({
  isUnsupported = false,
  hideCurrentValue = false,
  inflight = true,
  growth,
  showSitesGrowth = false,
  showMealsLbsGrowth = false,
  showMealsValueGrowth = false,
}) => {
  const classes = useStyles();
  const hasActiveLifetimeRescuersMetricFeatureFlag = useHasActiveFeatureFlag(FF_LIFETIME_RESCUERS_METRIC);
  const getTotalValue = key => (
    isUnsupported ? 'N/A' : totalValueFormatter(growth && growth[key] ? growth[key].total : 0)
  );
  const getCurrentValue = key => {
    if (hideCurrentValue) {
      return {
        value: null,
        color: null,
      };
    }

    if (isUnsupported) {
      return {
        value: 'N/A',
        color: null,
      };
    }

    return {
      value: currentValueFormatter(growth && growth[key] ? growth[key].dateRangeCount : 0),
      color: cardColors[Math.sign(growth && growth[key] ? growth[key].dateRangeCount : 0)],
    };
  };

  const isLoading = isUnsupported ? false : inflight;

  return (
    <Box my={2}>

      <Box>
        <Typography variant="h6" color="textSecondary">
          Growth
        </Typography>
      </Box>

      <Box className={classes.wrapper}>
        {showSitesGrowth && (
          <Box className={classes.card}>
            <DashboardCard
              isLoading={isLoading}
              totalValue={getTotalValue('sites')}
              currentValue={getCurrentValue('sites')}
              title="Sites"
            />
          </Box>
        )}
        
        <Box className={classes.card}>
          <DashboardCard
            isLoading={isLoading}
            totalValue={getTotalValue('donors')}
            currentValue={getCurrentValue('donors')}
            title="Food Donors"
          />
        </Box>

        <Box className={classes.card}>
          <DashboardCard
            isLoading={isLoading}
            totalValue={getTotalValue('pickupLocations')}
            currentValue={getCurrentValue('pickupLocations')}
            title="Pickup Locations"
          />
        </Box>

        <Box className={classes.card}>
          <DashboardCard
            isLoading={isLoading}
            totalValue={getTotalValue('receivers')}
            currentValue={getCurrentValue('receivers')}
            title="Receiving Agencies"
          />
        </Box>
        <Box className={classes.card}>
          <DashboardCard
            isLoading={isLoading}
            totalValue={getTotalValue('rescuers')}
            currentValue={getCurrentValue('rescuers')}
            title="Rescuers"
          />
        </Box>
        {hasActiveLifetimeRescuersMetricFeatureFlag && (
          <Box className={classes.card}>
            <DashboardCard
              isLoading={isLoading}
              // TO DO: Change data from rescuers to lifetime rescuers when the API is ready
              totalValue={getTotalValue('rescuers')}
              currentValue={getCurrentValue('rescuers')}
              title="Lifetime Rescuers"
            />
          </Box>
        )}
        <Box className={classes.card}>
          <DashboardCard
            isLoading={isLoading}
            totalValue={getTotalValue('rescues')}
            currentValue={getCurrentValue('rescues')}
            title="Rescues"
          />
        </Box>
  
        {showMealsLbsGrowth && (
          <Box className={classes.card}>
            <DashboardCard
              isLoading={isLoading}
              totalValue={getTotalValue('mealsLbs')}
              currentValue={getCurrentValue('mealsLbs')}
              title="Lbs"
            />
          </Box>
        )}
        
        <Box className={classes.card}>
          <DashboardCard
            isLoading={isLoading}
            totalValue={getTotalValue('meals')}
            currentValue={getCurrentValue('meals')}
            title="Meals"
          />
        </Box>
  
        {showMealsValueGrowth && (
          <Box className={classes.card}>
            <DashboardCard
              isLoading={isLoading}
              totalValue={getTotalValue('lbsValue')}
              currentValue={getCurrentValue('lbsValue')}
              title="$"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DashboardGrowthCards;
