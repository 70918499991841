import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Link, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import moment from 'moment';
import * as authService from '../services/auth';
import * as uiPersistedActions from '../actions/uiPersisted';
import { Colors } from '../assets/theme/Colors';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_ANNUAL_APPEAL } from './FeatureFlagWrapper';

const AnnualAppearBanner = () => {
  const user = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const isDrawerOpen = useSelector((state) => state.ui.persisted.isDrawerOpen);

  const useStyles = makeStyles((theme) => ({
    banner: {
      position: 'relative',
      paddingLeft: () => {
        if (user && !authService.shouldHideMainDrawer(user)) {
          return isDrawerOpen ? 250 : 86;
        }

        return 16;
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '6px !important',
      },
    },
    body: {
      marginRight: 30,
    },
    icon: {
      color: Colors.primaryColor,
      position: 'absolute',
      top: 6,
      right: 24,
    },
    link: {
      display: 'inline-block',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const hideAnnualAppearBanner = useSelector((state) => state.ui.persisted.hideAnnualAppearBanner);
  const hasActiveAnnualAppearFeatureFlag = useHasActiveFeatureFlag(FF_ANNUAL_APPEAL);

  if (!hasActiveAnnualAppearFeatureFlag) {
    return null;
  }

  if (hideAnnualAppearBanner && moment().isBefore(moment(hideAnnualAppearBanner).add(1, 'days'))) {
    return null;
  }

  const onDismiss = () => {
    dispatch(uiPersistedActions.hideAnnualAppearBanner());
  };

  return (
    <Alert severity="info" className={classes.banner}>
      <Typography variant="body2" className={classes.body}>
        This holiday season, we're on a mission to deliver 6 million meals to those in need. But we can't do it without
        you!{' '}
        <Link
          className={classes.link}
          href="https://secure.givelively.org/donate/food-rescue-us/rescue-food-nourish-our-neighbors-protect-our-planet"
          target="_blank"
          underline="always"
        >
          Click here
        </Link>{' '}
        to join us in the fight against hunger and food waste.⁠
      </Typography>
      <IconButton className={classes.icon} size="small" onClick={onDismiss}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Alert>
  );
};

export default AnnualAppearBanner;
