import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import RescuesSchedule, { REDUX_KEY } from '../components/RescuesSchedule';
import { eventToScheduleEvent, rescueToScheduleEvent } from '../helpers/RescuesHelper';
import routes from '../routes';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { fetchSiteEventsListIfNeeded, fetchSiteRescues } from '../actions/sites';
import { setUserCalendarOptions } from '../actions/calendarOptions';
import { getLastSiteRescueDate } from '../api/sites';
import useActiveUser from '../hooks/useActiveUser';

const RescuesScheduleView = ({ bottomPadding, siteId, testId }) => {
  const calendarRef = useRef();
  const history = useHistory();
  const activeUser = useActiveUser();
  const dispatch = useDispatch();
  const calendars = useSelector(state => state.calendarOptions);
  const rescues = useSelector(state => state.entities.sites.rescues);
  const events = useSelector(state => state.entities.sites.eventsList);
  const currentSite = useSelector(state => state.app.site);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: null,
  });

  const fetchSiteId = siteId || currentSite.id;
  const rescuesList = rescues.bySiteId[fetchSiteId] || [];
  const eventsList = events.bySiteId[fetchSiteId] || [];
  const eventsListWithoutCancelled = eventsList.filter((event) => !event.cancelled_at);

  const calendar = get(
    calendars,
    [activeUser.id, REDUX_KEY],
    isMobileView
      ? {
          view: 'listWeek',
          searchQuery: '',
          start: moment().format('YYYYMMDD'),
          end: moment().format('YYYYMMDD'),
          showTimeGrid: false,
          viewDateRange: {
            start: moment().startOf('week').toDate(),
            end: moment().toDate(),
          },
        }
      : {
          view: 'dayGridDay',
          searchQuery: '',
          start: moment().format('YYYYMMDD'),
          end: moment().endOf('week').format('YYYYMMDD'),
          showTimeGrid: false,
          viewDateRange: {
            start: moment().startOf('week').toDate(),
            end: moment().toDate(),
          },
        }
  );
  const [searchQuery, setSearchQuery] = useState(calendar.searchQuery || '');
  const [dateFrom, setDateFrom] = useState(calendar.start);
  const [dateTo, setDateTo] = useState(calendar.end);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const setShowTimeGrid = () => dispatch(setUserCalendarOptions(activeUser.id, REDUX_KEY, {
    ...calendar,
    showTimeGrid: !calendar.showTimeGrid,
    view: calendarRef.current ? calendarRef.current.getApi().view.type : calendar.view
  }));

  const onViewChange = view => {
    dispatch(
      setUserCalendarOptions(activeUser.id, REDUX_KEY, {
        ...calendar,
        view: view.type,
        viewDateRange: {
          start:
            view.type === 'dayGridMonth'
            ? moment(currentSite.created_at).startOf('month').toDate()
            : moment(currentSite.created_at).startOf('week').toDate(),
          end: calendar.viewDateRange.end,
      }
      })
    )
}

  useEffect(() => {
    const date = moment().format('YYYY-MM-DD');
    getLastSiteRescueDate(date, fetchSiteId)
      .then(res => res.json())
      .then(({ data }) => {
        let calendarMaxDate = moment().toDate();
        if (data[0]) {
          calendarMaxDate = moment(`${data[0].date} 23:59:59`).toDate();
        }

        dispatch(
          setUserCalendarOptions(activeUser.id, REDUX_KEY, {
            ...calendar,
            viewDateRange: {
              start:
                calendar.view === 'dayGridMonth'
                  ? moment(currentSite.created_at).startOf('month').toDate()
                  : moment(currentSite.created_at).startOf('week').toDate(),
              end: calendarMaxDate,
            },
          })
        );
      });

    dispatch(fetchSiteEventsListIfNeeded(fetchSiteId));
  }, [fetchSiteId]);

  // fires only on deps[] change
  useEffect(() => {
    if (fetchSiteId) {
      dispatch(fetchSiteRescues(dateFrom, dateTo, fetchSiteId));
    }
  }, [fetchSiteId, dateFrom, dateTo]);

  // componentWillUnmount
  useEffect(() => {
    return () => {
      if (calendarRef.current) {
        const cAPI = calendarRef.current.getApi();
        dispatch(
          setUserCalendarOptions(activeUser.id, REDUX_KEY, {
            // start: moment(cAPI.view.currentStart).format('YYYYMMDD'),
            // end: moment(cAPI.view.activeEnd).format('YYYYMMDD'),
            start: dateFrom,
            end: dateTo,
            view: cAPI.view.type,
          })
        );
      }
    }
  }, []);

  // we are re-fetching data only if we don't have them in the store
  const fetchRescues = (startDate, endDate) => {
    if (moment(startDate).isBefore(moment(dateFrom))) {
      setDateFrom(moment(startDate).format('YYYYMMDD'));
    }

    if (moment(endDate).isAfter(moment(dateTo))) {
      setDateTo(moment(endDate).format('YYYYMMDD'));
    }
  };

  const handleSearchQueryChange = value => {
    dispatch(
      setUserCalendarOptions(activeUser.id, REDUX_KEY, {
        ...calendar,
        searchQuery: value
      })
    );
    return setSearchQuery(value);
  };

  const handleEventClick = event => {
    if (event._def.extendedProps.type === 'rescue') {
      return history.push(generatePath(routes.rescuesScheduleEdit, { rescueId: event.id }));
    }

    if (event._def.extendedProps.type === 'event') {
      return history.push(generatePath(routes.event, { eventId: event.id }));
    }

    return null;
  };

  const rescuesListFilteredByDate = dateRange.startDate
    ? rescuesList.filter((r) => moment(r.date).isBetween(dateRange.startDate, dateRange.endDate, undefined, '[]'))
    : rescuesList;

  const eventsListFilteredByDate = dateRange.startDate
    ? eventsListWithoutCancelled.filter((e) =>
        moment(e.date.value).isBetween(dateRange.startDate, dateRange.endDate, undefined, '[]')
      )
    : eventsListWithoutCancelled;

  const queryUppercase = searchQuery.toUpperCase().trim();
  const eventsFiltered = [
    ...rescuesListFilteredByDate.reduce((acc, curr) => {
      const rescueSchedule = rescueToScheduleEvent(curr);

      if (queryUppercase.length <= 2) {
        acc.push(rescueSchedule);
        return acc;
      } else {
        if (curr.location.toUpperCase().includes(queryUppercase) || curr.receiver.toUpperCase().includes(queryUppercase)) {
          acc.push(rescueSchedule);
          return acc;
        }
      }
      return acc;
    }, []),
    ...eventsListFilteredByDate.reduce((acc, curr) => {
      const eventSchedule = eventToScheduleEvent(curr);

      if (queryUppercase.length <= 2) {
        acc.push(eventSchedule);
        return acc;
      } else {
        if (curr.name.toUpperCase().includes(queryUppercase)) {
          acc.push(eventSchedule);
          return acc;
        }
      }
      return acc;
    }, []),
  ];

  const handleDateRangeChange = (data) => {
    if (data.dateRange === null) {
      return setDateRange({ startDate: null, endDate: null });
    }

    if (calendarRef.current) {
      const cAPI = calendarRef.current.getApi();
      cAPI.gotoDate(moment(data.dateRange.startDate).toDate());
    }

    return setDateRange({
      startDate: moment(data.dateRange.startDate, 'YYYYMMDD'),
      endDate: moment(data.dateRange.endDate, 'YYYYMMDD'),
    });
  };

  if (isMobileView === null) {
    // wait for isMobile flag, prevent view update by useEffects
    return null;
  }

  return (
    <Box height={`calc(100% - ${2 * 8}px)`} mt={2} data-testid={testId}>
      <RescuesSchedule
        isMobileView={isMobileView}
        ref={calendarRef}
        bottomPadding={bottomPadding}
        events={eventsFiltered}
        isFetching={rescues.inflight}
        fetchRescues={fetchRescues}
        onSearchQueryChange={handleSearchQueryChange}
        defaultSearchQuery={searchQuery}
        calendarOptions={{ ...calendar, start: moment().format('YYYYMMDD') }} // Set start date always to current date
        handleGridSwitchClick={setShowTimeGrid}
        handleViewChange={onViewChange}
        onEventClick={handleEventClick}
        onDateRangeChange={handleDateRangeChange}
      />
    </Box>
  );
};

RescuesScheduleView.propTypes = {
  bottomPadding: PropTypes.number,
  siteId: PropTypes.number,
  testId: PropTypes.string,
};

RescuesScheduleView.defaultProps = {
  bottomPadding: 0,
  testId: 'rescues-schedule-view',
  siteId: undefined, //if not set, default app.site is used
};

export default RescuesScheduleView;
