import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { Clear as ClearIcon, Help as HelpIcon, PlayCircleOutline as PlayCircleOutlineIcon } from '@material-ui/icons';
import moment from 'moment';
import { useFormContext, Controller } from 'react-hook-form';
import { TimePicker, DatePicker } from '@material-ui/pickers';
import get from 'lodash/get';
import PagePaper from '../../../../../components/Common/PagePaper';
import { TextFieldWrapper } from '../../../../../components/Form/MuiFormWrapper';
import ZipCodeSelect from '../../../../../components/Common/ZipCodeSelect';
import { formatAddress } from '../../../../../helpers/formatters';
import useHasActiveFeatureFlag from '../../../../../hooks/useFeatureFlags';
import { FF_COMMUNITY_AND_ENGAGEMENT } from '../../../../../components/FeatureFlagWrapper';
import EventFrequencySelector from './EventFrequencySelector';
import { FREQUENCY_EVERY_OTHER_WEEK, FREQUENCY_MONTHLY, FREQUENCY_ONCE, FREQUENCY_WEEKLY } from '../../../../../models/donationsNew';
import DaysOfWeekSelector from '../../../../Donation/DonationManage/Components/DaysSelectors/DaysOfWeekSelector';
import DaysOfMonthSelector from '../../../../Donation/DonationManage/Components/DaysSelectors/DaysOfMonthSelector/DaysOfMonthSelector';
import { Colors } from '../../../../../assets/theme/Colors';
import { dayname } from '../../../../../models/donations';

const EventDetailsForm = ({ isCanceled, isMobileView, isEdited = false }) => {
  const {
    control,
    getValues,
    register,
    setValue,
    errors,
    formState: { isSubmitted },
  } = useFormContext();
  const formValues = getValues();
  const hasCommunityAndEngagementFF = useHasActiveFeatureFlag(FF_COMMUNITY_AND_ENGAGEMENT);
  const [virtualEventToggled, setVirtualEventToggled] = useState(formValues.digital || false);
  const [frequency, setFrequency] = useState(formValues.frequency || FREQUENCY_ONCE);
  const [startDate, setStartDate] = useState(formValues.date);
  const [endDate, setEndDate] = useState(formValues.end_date);
  const [pauseDate, setPauseDate] = useState(formValues.pause_date);
  const [resumeDate, setResumeDate] = useState(formValues.resume_date);
  const [daysOfWeek, setDaysOfWeek] = useState(formValues.days_of_week);
  const [daysOfMonth, setDaysOfMonth] = useState(formValues.days_of_month);

  useEffect(() => {
    register({ name: 'zip' });
    register({ name: 'frequency' });
    register({ name: 'end_date' });
    register({ name: 'days_of_week' });
    register({ name: 'days_of_month' });
  }, []);

  useEffect(() => {
    setValue('pause_date', pauseDate);
    setValue('resume_date', resumeDate);
  }, [pauseDate, resumeDate]);

  const onZipCodeChange = (option) => {
    if (option.zipCode.length >= 3) {
      setValue('state', get(option, 'data.state', ''));
    } else {
      setValue('state', '');
    }

    if (option.zipCode.length === 5) {
      setValue('city', get(option, 'data.city', ''));
    } else {
      setValue('city', '');
    }

    setValue('zip', get(option, 'zipCode', ''), isSubmitted);
  };

  const handleVirtualClick = () => {
    setValue('digital', !virtualEventToggled);
    setVirtualEventToggled(!virtualEventToggled);
  };

  const onFrequencyChange = (value) => {
    setValue('frequency', value);
    setFrequency(value);
  };

  const handleDaysOfWeekChange = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      setDaysOfWeek({ ...daysOfWeek, [name]: { checked: true } });
      return setValue(`days_of_week`, { ...daysOfWeek, [name]: { checked: true } });
    }

    setDaysOfWeek({ ...daysOfWeek, [name]: { checked: false } });
    return setValue(`days_of_week`, { ...daysOfWeek, [name]: { checked: false } });
  };

  const handleDaysOfMonthChange = ({ newOrdinaryValue, newDayValue, oldOrdinaryValue, oldDayValue }) => {
    if (newOrdinaryValue === null && newDayValue === null && oldOrdinaryValue && (oldDayValue || oldDayValue === 0)) {
      const day = dayname(oldDayValue).toLowerCase();
      setDaysOfMonth({
        ...daysOfMonth,
        [day]: daysOfMonth[day].filter((day) => day.ordinal !== oldOrdinaryValue),
      });
      return setValue('days_of_month', {
        ...daysOfMonth,
        [day]: daysOfMonth[day].filter((day) => day.ordinal !== oldOrdinaryValue),
      });
    }

    if (
      newOrdinaryValue &&
      (newDayValue || newDayValue === 0) &&
      !oldOrdinaryValue &&
      !oldDayValue &&
      oldDayValue !== 0
    ) {
      const day = dayname(newDayValue).toLowerCase();
      setDaysOfMonth({
        ...daysOfMonth,
        [day]: [...daysOfMonth[day], ...[{ ordinal: newOrdinaryValue, allow_uncheck: true }]],
      });
      return setValue('days_of_month', {
        ...daysOfMonth,
        [day]: [...daysOfMonth[day], ...[{ ordinal: newOrdinaryValue, allow_uncheck: true }]],
      });
    }

    if (newDayValue !== oldDayValue && newOrdinaryValue === oldOrdinaryValue) {
      const newDay = dayname(newDayValue).toLowerCase();
      const oldDay = dayname(oldDayValue).toLowerCase();
      const daysWithRemovedOldDay = {
        ...daysOfMonth,
        [oldDay]: daysOfMonth[oldDay].filter((day) => day.ordinal !== oldOrdinaryValue),
      };
      setDaysOfMonth({
        ...daysWithRemovedOldDay,
        [newDay]: [...daysWithRemovedOldDay[newDay], ...[{ ordinal: oldOrdinaryValue, allow_uncheck: true }]],
      });
      return setValue('days_of_month', {
        ...daysWithRemovedOldDay,
        [newDay]: [...daysWithRemovedOldDay[newDay], ...[{ ordinal: oldOrdinaryValue, allow_uncheck: true }]],
      });
    }

    if (newDayValue === oldDayValue && newOrdinaryValue !== oldOrdinaryValue) {
      const oldDay = dayname(oldDayValue).toLowerCase();
      setDaysOfMonth({
        ...daysOfMonth,
        [oldDay]: daysOfMonth[oldDay].map((day) =>
          day.ordinal !== oldOrdinaryValue ? day : { ordinal: newOrdinaryValue, allow_uncheck: true }
        ),
      });
      return setValue('days_of_month', {
        ...daysOfMonth,
        [oldDay]: daysOfMonth[oldDay].map((day) =>
          day.ordinal !== oldOrdinaryValue ? day : { ordinal: newOrdinaryValue, allow_uncheck: true }
        ),
      });
    }
  };

  return (
    <PagePaper style={{ marginBottom: 8 }}>
      <Typography variant="h6" gutterBottom>
        {isCanceled ? 'Event (CANCELLED)' : 'Event'}
      </Typography>
      <Box display="grid" gridAutoFlow="row" gridRowGap={16}>
        <TextFieldWrapper
          required
          name="title"
          label="Title"
          id="title"
          fullWidth
          variant="outlined"
          data-testid="event-title"
          size="small"
          inputRef={register}
          InputLabelProps={{ shrink: !!formValues.title || undefined }}
          error={!!errors.title}
          helperText={errors.title ? errors.title.message : ''}
        />
        <TextFieldWrapper
          name="description"
          label="Description"
          id="description"
          fullWidth
          variant="outlined"
          data-testid="event-description"
          size="small"
          multiline
          inputRef={register}
          InputLabelProps={{ shrink: !!formValues.description || undefined }}
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : ''}
        />

        <EventFrequencySelector
          name="frequency"
          inputRef={register}
          frequency={formValues.frequency}
          onChange={(value) => onFrequencyChange(value)}
        />

        {frequency === FREQUENCY_ONCE && (
          <Box
            display="grid"
            gridTemplateAreas={isMobileView ? '"date date"\n"begin end"' : '"date begin end"'}
            gridGap={isMobileView ? 8 : 16}
            gridRowGap={isMobileView ? 16 : 8}
          >
            <Box gridArea="date">
              <Controller
                name="start_date"
                control={control}
                as={({ onChange, value }) => (
                  <DatePicker
                    disablePast
                    name="start_date"
                    variant={isMobileView ? 'dialog' : 'inline'}
                    inputVariant="outlined"
                    label="Date"
                    size="small"
                    inputProps={{
                      label: 'test1',
                    }}
                    data-testid="event-date"
                    value={value}
                    fullWidth
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    onChange={(momentEvent) => onChange(moment(momentEvent).format('YYYY-MM-DD'))}
                  />
                )}
              />
            </Box>
            <Box gridArea="begin">
              <Controller
                name="begin"
                control={control}
                as={({ value, name }) => (
                  <TimePicker
                    data-testid="event-begin"
                    value={value}
                    name={name}
                    inputVariant="outlined"
                    error={!!get(errors, 'begin.message', undefined)}
                    helperText={get(errors, 'begin.message', null)}
                    variant={isMobileView ? 'dialog' : 'inline'}
                    label="From"
                    size="small"
                    minutesStep={5}
                    onChange={(event) => {
                      const durationDiff = moment.duration(formValues.end.diff(value));
                      const endTime = event.clone().add(durationDiff);

                      setValue(`begin`, event, isSubmitted);
                      setValue(`end`, endTime, isSubmitted);
                    }}
                  />
                )}
              />
            </Box>
            <Box gridArea="end">
              <Controller
                name="end"
                control={control}
                as={({ value, name }) => (
                  <TimePicker
                    data-testid="event-end"
                    value={value}
                    name={name}
                    error={!!get(errors, 'end_.message', undefined)}
                    helperText={get(errors, 'end.message', null)}
                    inputVariant="outlined"
                    variant={isMobileView ? 'dialog' : 'inline'}
                    label="To"
                    size="small"
                    minutesStep={5}
                    onChange={(event) => setValue(`end`, event, true)}
                  />
                )}
              />
            </Box>
          </Box>
        )}
        {(frequency === FREQUENCY_WEEKLY || frequency === FREQUENCY_EVERY_OTHER_WEEK) && (
          <Grid container item>
            <Grid item xs={12}>
              <DaysOfWeekSelector
                name="days_of_week"
                inputRef={register}
                dow={formValues.days_of_week}
                onChange={handleDaysOfWeekChange}
                error={
                  get(errors, 'days_of_week.message', undefined)
                    ? { message: 'You must select at least 1 day of the week' }
                    : false
                }
              />
            </Grid>
          </Grid>
        )}

        {frequency === FREQUENCY_MONTHLY && (
          <Grid container item>
            <Grid item xs={12}>
              <DaysOfMonthSelector
                dom={formValues.days_of_month}
                onChange={handleDaysOfMonthChange}
                error={
                  get(errors, 'days_of_month.message', undefined)
                    ? { message: 'You must select at least 1 day of the month' }
                    : false
                } />
            </Grid>
          </Grid>
        )}

        {frequency !== FREQUENCY_ONCE && (
          <Grid container spacing={2}>
            <Grid container item md={12} lg={8} xl={6} spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="no-wrap"
                  alignItems="center"
                  gridGap="8px"
                  justifyContent="space-between"
                >
                  <Controller
                    name="start_date"
                    control={control}
                    as={({ onChange, value }) => (
                      <DatePicker
                        disablePast={!isEdited}
                        name="start_date"
                        variant={isMobileView ? 'dialog' : 'inline'}
                        inputVariant="outlined"
                        label="Start Date"
                        size="small"
                        data-testid="event-start-date"
                        value={value || null}
                        fullWidth
                        format="MM/DD/YYYY"
                        placeholder="mm/dd/yyyy"
                        onChange={(momentEvent) => {
                          setStartDate(moment(momentEvent).format('YYYY-MM-DD'));
                          onChange(moment(momentEvent).format('YYYY-MM-DD'));
                        }}
                      />
                    )}
                  />
                  <Tooltip arrow placement="top" title="Event will begin after this date.">
                    <HelpIcon htmlColor={Colors.blue.main} />
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="row" flexWrap="no-wrap" alignItems="center" gridGap="8px">
                  <DatePicker
                    inputRef={register}
                    minDate={startDate}
                    disablePast
                    autoOk
                    name="end_date"
                    variant={isMobileView ? 'dialog' : 'inline'}
                    inputVariant="outlined"
                    label="End Date"
                    size="small"
                    data-testid="event-end-date"
                    value={endDate}
                    fullWidth
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    onChange={(momentEvent) => {
                      setEndDate(moment(momentEvent).format('YYYY-MM-DD'));
                      setValue('end_date', moment(momentEvent).format('YYYY-MM-DD'));
                    }}
                  />
                  {endDate && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEndDate(null);
                        setValue('end_date', null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <Tooltip arrow placement="top" title="Event will pause after this date.">
                    <HelpIcon htmlColor={Colors.blue.main} />
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Grid container item md={12} lg={8} xl={6} spacing={2}>
              <Grid item xs={12} sm={6}>
                {pauseDate ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="no-wrap"
                    alignItems="center"
                    gridGap="8px"
                    justifyContent="space-between"
                  >
                    <Controller
                      name="pause_date"
                      control={control}
                      as={({ onChange, value }) => (
                        <DatePicker
                          minDate={startDate}
                          disablePast
                          name="pause_date"
                          variant={isMobileView ? 'dialog' : 'inline'}
                          inputVariant="outlined"
                          label="Pause Date"
                          size="small"
                          data-testid="event-pause-date"
                          value={formValues.pause_date || null}
                          fullWidth
                          format="MM/DD/YYYY"
                          placeholder="mm/dd/yyyy"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!get(errors, 'pause_date.message', undefined)}
                              helperText={get(errors, 'pause_date.message', null)}
                            />
                          )}
                          onChange={(momentEvent) => {
                            setPauseDate(moment(momentEvent).format('YYYY-MM-DD'));
                            onChange(moment(momentEvent).format('YYYY-MM-DD'));
                          }}
                        />
                      )}
                    />
                    <Tooltip arrow placement="top" title="Event will begin after this date.">
                      <HelpIcon htmlColor={Colors.blue.main} />
                    </Tooltip>
                  </Box>
                ) : (
                  <Button
                    color="secondary"
                    startIcon={<PlayCircleOutlineIcon />}
                    variant="contained"
                    onClick={() => {
                      setPauseDate((moment().isBefore(startDate) ? moment(startDate) : moment()).format('YYYY-MM-DD'));
                    }}
                  >
                    Pause now
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="no-wrap"
                  alignItems="center"
                  gridGap="8px"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => {
                          // when unchecked & there was pauseDate before, clear it
                          if (event.target.checked) {
                            // make sure that pause date is after start date (eg. when donation is from the future)
                            setPauseDate(
                              (moment().isBefore(startDate) ? moment(startDate) : moment()).format('YYYY-MM-DD')
                            );
                          } else {
                            setPauseDate(null);
                          }
                        }}
                        checked={!!pauseDate}
                        name="pause_date"
                      />
                    }
                    label="Pause after date"
                  />
                  <Tooltip arrow placement="top" title="Rescue will pause after this date.">
                    <HelpIcon htmlColor={Colors.blue.main} />
                  </Tooltip>
                </Box>
              </Grid>

              {!!pauseDate && (
                <>
                  <Grid item xs={12} sm={6}>
                    {resumeDate ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="no-wrap"
                        alignItems="center"
                        gridGap="8px"
                        justifyContent="space-between"
                      >
                        <Controller
                          name="resume_date"
                          control={control}
                          as={({ onChange, value }) => (
                            <DatePicker
                              minDate={pauseDate}
                              disablePast
                              name="resume_date"
                              variant={isMobileView ? 'dialog' : 'inline'}
                              inputVariant="outlined"
                              label="Resume Date"
                              size="small"
                              data-testid="event-pause-date"
                              value={formValues.resume_date || null}
                              fullWidth
                              format="MM/DD/YYYY"
                              placeholder="mm/dd/yyyy"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!get(errors, 'resume_date.message', undefined)}
                                  helperText={get(errors, 'resume_date.message', null)}
                                />
                              )}
                              onChange={(momentEvent) => {
                                setResumeDate(moment(momentEvent).format('YYYY-MM-DD'));
                                onChange(moment(momentEvent).format('YYYY-MM-DD'));
                              }}
                            />
                          )}
                        />
                        <Tooltip arrow placement="top" title="Event will resume after this date.">
                          <HelpIcon htmlColor={Colors.blue.main} />
                        </Tooltip>
                      </Box>
                    ) : (
                      <Button
                        color="secondary"
                        startIcon={<PlayCircleOutlineIcon />}
                        variant="contained"
                        onClick={() => {
                          setResumeDate(
                            (moment().isBefore(pauseDate) ? moment(pauseDate) : moment()).format('YYYY-MM-DD')
                          );
                        }}
                      >
                        Resume now
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="no-wrap"
                      alignItems="center"
                      gridGap="8px"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => {
                              // when unchecked & there was pauseDate before, clear it
                              if (event.target.checked) {
                                // make sure that pause date is after start date (eg. when donation is from the future)
                                setResumeDate(
                                  (moment().isBefore(pauseDate) ? moment(pauseDate) : moment()).format('YYYY-MM-DD')
                                );
                              } else {
                                setResumeDate(null);
                              }
                            }}
                            checked={!!resumeDate}
                            name="resume_date"
                          />
                        }
                        label="Resume on date"
                      />
                      <Tooltip arrow placement="top" title="Rescue will resume after this date.">
                        <HelpIcon htmlColor={Colors.blue.main} />
                      </Tooltip>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}

        {hasCommunityAndEngagementFF && (
          <FormControlLabel
            name="digital"
            label="Virtual Event"
            control={<Checkbox checked={virtualEventToggled} onChange={handleVirtualClick} />}
            inputRef={register}
          />
        )}

        {formValues.digital && (
          <TextFieldWrapper
            required
            type="url"
            data-testid="event-link"
            name="link"
            label="Link to virtual event"
            id="link"
            fullWidth
            variant="outlined"
            size="small"
            inputRef={register}
            InputLabelProps={{ shrink: !!formValues.link || undefined }}
            error={!!errors.link}
            helperText={errors.link ? errors.link.message : ''}
          />
        )}

        {!formValues.digital && (
          <>
            <TextFieldWrapper
              required
              data-testid="event-address"
              name="address"
              label="Address"
              id="address"
              fullWidth
              variant="outlined"
              size="small"
              inputRef={register}
              InputLabelProps={{ shrink: !!formValues.address || undefined }}
              error={!!errors.address}
              helperText={errors.address ? errors.address.message : ''}
            />

            <ZipCodeSelect
              required
              label="Zip code"
              name="zip"
              fullWidth
              onZipCodeChange={onZipCodeChange}
              error={!!errors.zip}
              defaultLocationText={
                formValues.zip
                  ? formatAddress({
                      st: formValues.state,
                      city: formValues.city,
                    })
                  : null
              }
              helperText={get(errors, 'zip.message', false)}
              inputRef={register}
            />
            <Controller name="city" defaultValue="" control={control} as="input" type="hidden" />
            <Controller name="state" defaultValue="" control={control} as="input" type="hidden" />
          </>
        )}
      </Box>
    </PagePaper>
  );
};

export default EventDetailsForm;
