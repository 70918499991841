import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';
export const PHONE_EXT_MAX_LENGTH = 8;

export const TextFieldWrapper = forwardRef(function TextFieldWrapper(
  { required, label, ...rest },
  ref
) {
  const labelValue = required ? label : `${label} (optional)`;
  return <TextField ref={ref} label={label ? labelValue : null} {...rest} />;
});

export const TextFieldPhoneExt = forwardRef(function TextField(
  { inputProps, ...rest },
  ref
) {
  return (
    <TextFieldWrapper
      ref={ref}
      inputProps={{
        ...inputProps,
        maxLength: PHONE_EXT_MAX_LENGTH,
        autoComplete: 'chrome-off',
      }}
      {...rest}
    />
  );
});

export const NumberField = forwardRef(function NumberField({ inputProps, ...rest }, ref) {
  return (
    <TextFieldWrapper
      ref={ref}
      onKeyDown={event => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()}
      type="number"
      inputProps={{
        ...inputProps,
        inputMode: 'numeric', // support for numeric keyboard on mobile devices
      }}
      {...rest}
    />
  );
});
