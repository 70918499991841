import React from 'react';
import brokenImage from '../../../../../assets/images/rescue-image-broken.png';
import { Box, Dialog, Typography, IconButton, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { Close as CloseIcon, CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import DialogDetails from './DialogDetails';
import routes from '../../../../../routes';
import { Colors } from '../../../../../assets/theme/Colors';
import { downloadFileByURL } from '../../../../../helpers/files';

const useMobileDialogStyles = makeStyles(() => ({
  dialogImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));

const MobileDialog = ({ rescuePhotoData, handleClose }) => {
  const classes = useMobileDialogStyles();
  const rescueDetailsText = 'Rescue Details';

  return (
    <Dialog fullScreen open onClose={handleClose} aria-labelledby="mobile-dialog">
      <Box display="flex" flexDirection="column">
        <Box flexGrow={1} flexBasis={1}>
          <img
            src={rescuePhotoData.url}
            onError={e => {
              e.target.onerror = null;
              e.target.src = brokenImage;
              e.target.alt = 'Image not found';
            }}
            alt="Image"
            loading="lazy"
            className={classes.dialogImage}
          />

          <IconButton
            disabled={!rescuePhotoData.url}
            onClick={() => downloadFileByURL({
              url: rescuePhotoData.url,
              fileName: rescuePhotoData.name,
            })}
            aria-label="download photo"
            style={{
              position: 'absolute',
              top: 10,
              right: 20,
              color: Colors.white,
            }}
          >
            <CloudDownloadIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box flexGrow={1} flexBasis={1} p={1}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box p={1}>
              <Typography variant="h6" id="dialog-title">
                {rescuePhotoData.rescue ? (
                  <Link to={generatePath(routes.rescue, { rescueId: rescuePhotoData.rescue_id })}>
                    {rescueDetailsText}
                  </Link>
                ) : (
                  rescueDetailsText
                )}
              </Typography>
            </Box>
            <Box>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Box height="100%" p={1} overflow="scroll">
            <DialogDetails rescuePhotoData={rescuePhotoData} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MobileDialog;
