import React, { useState, createRef } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import Bluebird from 'bluebird';
import { Grid, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import SubmitWithSendEmailConfirmation from './SubmitWithSendEmailConfirmation';
import OverlayLoader from './OverlayLoader';
import routes from '../routes';
import { formatPhoneNumber } from '../helpers/formatters';
import { Colors } from '../assets/theme/Colors';
import FormGrid from './Common/FormGrid';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import { getFormFieldError, isFormFieldInvalid } from '../helpers/validators';

const MAX_CHARACTERS_LIMIT = 160;

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const SendSmsForm = ({ verifiedRecipients, unverifiedRecipients, submitCallback }) => {
  const formRef = createRef();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const validate = values => {
    const errors = {};

    if (!values.body) {
      errors.body = 'Required';
    }

    if (verifiedRecipients.length <= 0) {
      errors.recipients = 'Required';
    }

    return errors;
  };

  const submit = values =>
    Bluebird.try(() => {
      setLoading(true);

      return submitCallback({
        body: values.body,
        users_ids: verifiedRecipients.map(r => r.id),
      });
    })
      .then(() => {
        history.push(routes.rescuers);

        snackbarHelper.success(`SMS send successfully out to ${verifiedRecipients.length} rescuer(s)!`);
      })
      .catch(err => {
        errorsHelper.handleError(err);

        return {
          [FORM_ERROR]: 'POST failed',
        };
      });

  return (
    <OverlayLoader isLoading={loading}>
      <Paper className={classes.paper} data-testid="sms-recipients-paper">
        <Typography variant="h6">
          {`Recipients (${verifiedRecipients.length}):`}

          <Tooltip arrow placement="top" title="List of users who are going to receive this SMS.">
            <HelpIcon htmlColor={Colors.blue.main} style={{ position: 'relative', top: 5, marginLeft: 5 }} />
          </Tooltip>
        </Typography>

        {verifiedRecipients.length > 0 ? (
          <ul>
            {verifiedRecipients.slice(0, 10).map(r => (
              <li key={r.id}>{`${r.firstname} ${r.lastname} (${formatPhoneNumber(r.phone)})`}</li>
            ))}

            {verifiedRecipients.length > 10 && (
              <li>
                <em>{`+${verifiedRecipients.length - 10} more recipients`}</em>
              </li>
            )}
          </ul>
        ) : (
          <Typography data-testid="no-verified-recipients-help" color="error">
            You don't have any recipients with verified phone# selected therefore you will not be able to send the SMS.
          </Typography>
        )}
      </Paper>

      {unverifiedRecipients.length > 0 && (
        <Paper className={classes.paper} data-testid="sms-ignored-recipients-paper">
          <Typography variant="h6">
            {`Ignored recipients (${unverifiedRecipients.length}):`}

            <Tooltip
              arrow
              placement="top"
              title="List of users who are not going to receive this SMS, because their phone# is not verified."
            >
              <HelpIcon htmlColor={Colors.blue.main} style={{ position: 'relative', top: 5, marginLeft: 5 }} />
            </Tooltip>
          </Typography>

          <ul>
            {unverifiedRecipients.slice(0, 10).map(r => (
              <li key={r.id}>{`${r.firstname} ${r.lastname} (${formatPhoneNumber(r.phone)})`}</li>
            ))}

            {unverifiedRecipients.length > 10 && (
              <li>
                <em>{`+${unverifiedRecipients.length - 10} more ignored recipients`}</em>
              </li>
            )}
          </ul>
        </Paper>
      )}

      <Paper className={classes.paper}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit, submitting, pristine, invalid, dirtySinceLastSubmit }) => (
            <form onSubmit={handleSubmit} ref={formRef}>
              <FormGrid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      name="body"
                      render={({ input, meta }) => {
                        const showError = isFormFieldInvalid(meta);

                        return (
                          <TextFieldMaterial
                            InputProps={{
                              disabled: verifiedRecipients.length === 0,
                            }}
                            fullWidth
                            rowsMax={10}
                            multiline
                            required
                            type="text"
                            label="SMS Content"
                            inputProps={{
                              maxLength: MAX_CHARACTERS_LIMIT,
                            }}
                            error={showError}
                            helperText={
                              showError
                                ? getFormFieldError(meta)
                                : `Used ${input.value.length} out of ${MAX_CHARACTERS_LIMIT} characters`
                            }
                            {...input}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SubmitWithSendEmailConfirmation
                      title="Heads up!"
                      message={`Do you really want to send SMS out to ${verifiedRecipients.length} recipient(s)?`}
                      confirmButtonLabel="Send SMS"
                      label="Send SMS"
                      showConfirmation
                      disabled={pristine || (invalid && !dirtySinceLastSubmit) || submitting}
                      className={classes.button}
                      onButtonNotSend={() => true}
                      onButtonSend={() => {
                        formRef.current.dispatchEvent(
                          new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                          })
                        );
                      }}
                      data-testid="send-sms-submit-button"
                    />
                  </Grid>
                </Grid>
              </FormGrid>
            </form>
          )}
        />
      </Paper>
    </OverlayLoader>
  );
};

export default SendSmsForm;
