import React from 'react';
import { Badge, colors, withStyles } from '@material-ui/core';

const AchievementBadge = withStyles({
  badge: {
    top: 20,
    right: 15,
    fontSize: '1rem',
    height: 28,
    minWidth: 30,
  },
})(Badge);

export default AchievementBadge;
