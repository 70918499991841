import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';
import PagePaper from './Common/PagePaper';
import RescueSizingTable from './RescueSizingTable';
import ButtonWithLoading from './ButtonWithLoading';
import OverlayLoader from './OverlayLoader';
import ConfirmationDialog from './ConfirmationDialog';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_RESCUE_SIZING } from './FeatureFlagWrapper';

const RescueSizing = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));
  const sitesEntities = useSelector((state) => state.entities.sites);
  const sites = Object.values(sitesEntities.byId);
  const foodDonorsEntities = useSelector((state) => state.entities.foodDonors);
  const foodDonors = Object.values(foodDonorsEntities.byId);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedFoodDonor, setSelectedFoodDonor] = useState(null);
  const foodDonorsList = selectedSites.length
    ? foodDonors.filter((donor) => selectedSites.some((site) => site.id === donor.site_id))
    : foodDonors;
  const [dataUpdated, setDataUpdated] = useState(false);
  const hasRescueSizingFeatueFlag = useHasActiveFeatureFlag(FF_RESCUE_SIZING);

  const handleUpdate = () => {
    confirmAlert({
      title: `Update ${selectedFoodDonor.name} rescue sizing data`,
      message: 'Are you sure you want to do this?',
      buttons: [
        {
          label: 'No',
          color: 'primary',
        },
        {
          label: 'Yes',
          color: 'primary',
          onClick: () => {
            setDataUpdated(true);

            // TO DO: WHEN API IS READY
          },
        },
      ],
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
        />
      ),
    });
  };

  // Mockup data
  const rescueSizeData = [
    {
      size: 'S',
      current: 50,
      recalculated: 61,
    },
    {
      size: 'M',
      current: 100,
      recalculated: 134,
    },
    {
      size: 'L',
      current: 125,
      recalculated: 162,
    },
    {
      size: 'XL',
      current: 150,
      recalculated: 188,
    },
  ];

  if (!hasRescueSizingFeatueFlag) {
    return 'no access';
  }

  return (
    <PagePaper>
      <ReactSelect
        isDisabled={sitesEntities.inflight}
        placeholder="Select site(s) or leave blank to show food donors of all sites"
        value={selectedSites}
        options={[{ id: -1, name: 'All' }, ...sites]}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        onChange={(option) => {
          let choices = option !== null ? option : [];
          if (choices.some((site) => site.id === -1)) {
            choices = [];
          }

          setSelectedSites(choices);
        }}
        styles={{ menu: (base) => ({ ...base, zIndex: 101 }) }}
        isClearable
        isMulti
      />

      <Box mt={2}>
        <ReactSelect
          isDisabled={foodDonorsEntities.inflight}
          placeholder="Select food donor"
          value={selectedFoodDonor}
          options={foodDonorsList}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          onChange={(option) => setSelectedFoodDonor(option)}
          styles={{ menu: (base) => ({ ...base, zIndex: 101 }) }}
          isClearable
        />
      </Box>

      {selectedFoodDonor && (
        <Box mt={2}>
          <OverlayLoader isLoading={foodDonorsEntities.inflight}>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <RescueSizingTable
                  rescueSizeData={rescueSizeData}
                  dataUpdated={dataUpdated}
                  loading={foodDonorsEntities.inflight}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  p="4px"
                  height="100%"
                  display="flex"
                  alignItems="end"
                  justifyContent={isMobileView ? 'end' : 'start'}
                >
                  <ButtonWithLoading
                    disabled={dataUpdated}
                    variant="contained"
                    color="primary"
                    onClick={handleUpdate}
                    isLoading={foodDonorsEntities.inflight}
                  >
                    Update
                  </ButtonWithLoading>
                </Box>
              </Grid>
            </Grid>
          </OverlayLoader>
        </Box>
      )}
    </PagePaper>
  );
};

export default RescueSizing;
