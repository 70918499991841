import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import DashboardBadge from '../../../../components/Common/TabBadgeCounter';
import { paths } from '../MarketingHubDesktop';

const RenderTabWithBadge = ({ label, url, badgeCounter, testId }) => (
  <Tab
    component={Link}
    to={url}
    label={
      <div>
        <DashboardBadge badgeContent={badgeCounter} color="primary">
          {label}
        </DashboardBadge>
      </div>
    }
    key={testId}
    data-testid={testId}
  />
);

const MarketingHubDestkopAppBar = () => {
  const { url } = useRouteMatch();

  const tabs = [
    { label: 'Accounts', url: paths[0], badgeCounter: null, testId: 'marketHubDesktopTab0' },
    { label: 'Superfeed', url: paths[1], badgeCounter: null, testId: 'marketHubDesktopTab1' },
    { label: 'Create', url: paths[2], badgeCounter: null, testId: 'marketHubDesktopTab2' },
    { label: 'Metrics', url: paths[3], badgeCounter: null, testId: 'marketHubDesktopTab3' },
    { label: 'Calendar', url: paths[4], badgeCounter: null, testId: 'marketHubDesktopTab4' },
  ];

  const getActiveTab = () => Math.max(paths.indexOf(url), 0);

  return (
    <AppBar position="static">
      <Tabs value={getActiveTab()} aria-label="market hub dashboard tabs" indicatorColor="primary">
        {tabs.map(RenderTabWithBadge)}
      </Tabs>
    </AppBar>
  );
};

export default MarketingHubDestkopAppBar;
