import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { uniqBy } from 'lodash';
import { Typography, Grid, FormControl, Breadcrumbs, Box, InputLabel, Select, MenuItem, Tooltip } from '@material-ui/core';
import Bluebird from 'bluebird';
import * as rescuesActions from '../actions/rescues';
import { fetchSiteRescues } from '../actions/sites';
import { setUserTableOptions } from '../actions/tableOptions';
import RescuesTable from '../components/RescuesTable';
import DateAndGranularityPicker, {
  dateRangeOptionsList,
} from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import useActiveSite from '../hooks/useActiveSite';
import useActiveUser from '../hooks/useActiveUser';
import { renderPickupLocationNameOrAdress } from '../helpers/RescuesHelper';
import BulkEditReviewAndSave from '../components/BulkEditReviewAndSave';
import useNotificationService from '../hooks/useNotificationService';
import { fetchRescueSizesIfNeeded } from '../actions/rescueSizes';
import { fetchSystemSettingsIfNeeded } from '../actions/systemSettings';

export const RescueFilterTypes = {
  all: 'All',
  claimed: 'Claimed',
  unclaimed: 'Unclaimed',
};

export const RescuesFromSiteFilterTypes = {
  all: 'All Sites',
  enabled: 'Enabled Sites',
  disabled: 'Disabled Sites',
};

const TABLE_ID = '#rescues/future-rescues-table';

const dateRangeOptions = {
  today: dateRangeOptionsList.today,
  thisWeek: dateRangeOptionsList.thisWeek,
  thisMonth: dateRangeOptionsList.thisMonth,
  thisQuarter: dateRangeOptionsList.thisQuarter,
  thisYear: dateRangeOptionsList.thisYear,
};

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 140,
  },
}));

const FutureRescuesListView = ({
  umbrellaFoodDonorId,
  sites,
  filter,
  showCopyRescueButton = true,
  showEditRescueButton = true,
  renderNamesAsLinks = false,
  removedFilters = [],
  displayFoodDonorFilters = true,
  showOnlyPickupFilter = false,
  showDisabledSiteFilter = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const site = useActiveSite();
  const activeUser = useActiveUser();
  const tableOptions = useSelector((state) => state.tableOptions);
  const rescuesEntities = useSelector((state) => state.entities.rescues);
  const sitesRescues = useSelector((state) => state.entities.sites.rescues);
  const rescuesSlice = umbrellaFoodDonorId ? rescuesEntities : sitesRescues;
  const { inflight } = rescuesSlice;
  const rescuesList = (() => {
    if (umbrellaFoodDonorId) {
      return rescuesSlice.allIds.map((rescueId) => rescuesEntities.byId[rescueId]);
    }

    return rescuesSlice.bySiteId[site.id] || {};
  })();
  const [editMode, setEditMode] = useState(false);
  const [editedRescues, setEditedRescues] = useState([]);
  const [inReview, setInReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rescuesFromType, setRescuesFromType] = useState(RescuesFromSiteFilterTypes.enabled);
  const { addSuccessNotification, addErrorNotification } = useNotificationService();

  useEffect(() => {
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSystemSettingsIfNeeded());
  }, []);

  // merge modified, if any, and set modified flag
  // modified rescues are in entities.rescues.modified*
  const rescues = Object.values(rescuesList).map((rescue) => ({
    ...(rescuesSlice.modifiedIds.indexOf(rescue.id) >= 0 ? rescuesSlice.modifiedById[rescue.id] : rescue),
    modified: rescuesSlice.modifiedIds.indexOf(rescue.id) >= 0,
    _position: `${rescue.position}/${rescue.positions}`,
  }));

  const scrollTop = () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  const handleSaveClick = (data) => {
    setIsLoading(true);
    const options = get(tableOptions, [activeUser.id, TABLE_ID], null);
    return Bluebird.try(() =>
      dispatch(rescuesActions.updateRescues(
        data,
        () => fetchRescues(options.dateRange.from, options.dateRange.to)
      )).then(() => {
        setInReview(false);
        setEditMode(false);
        setEditedRescues([]);
        setIsLoading(false);
        addSuccessNotification('Rescue(s) updated.');
        scrollTop();
      })
    ).catch((error) => {
      setIsLoading(false);
      addErrorNotification(error, error.message);
      scrollTop();
    });
  };

  const fetchRescues = (from, to) => {
    if (rescuesEntities.inflight || sitesRescues.inflight) {
      return null;
    }

    if (umbrellaFoodDonorId) {
      return dispatch(rescuesActions.fetchRescues(from.format('YYYYMMDD'), to.format('YYYYMMDD'), null, umbrellaFoodDonorId));
    }

    return dispatch(fetchSiteRescues(from.format('YYYYMMDD'), to.format('YYYYMMDD'), site.id));
  };

  const onDateFilterChange = (from, to, period) => {
    fetchRescues(from, to);

    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'claimer');
    if (columnIndex < 0) {
      return;
    }

    dispatch(
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        period,
        dateRange: {
          from,
          to,
        },
      })
    );
  };

  const getTableSelectedRescueFilter = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'claimer');
    return get(previousTableState, ['filterList', columnIndex, 0], RescueFilterTypes.all);
  };

  const getTableSelectedDateRange = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    return get(previousTableState, ['dateRange'], dateRangeOptions.thisMonth.value);
  };

  const getTableSelectedPeriod = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    return get(previousTableState, ['period'], dateRangeOptions.thisMonth.value);
  };

  const onRadioFilterChange = event => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'claimer');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }
    const choice = event.target.value;

    if (choice === RescueFilterTypes.all) {
      previousFilters[columnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }

    if (choice === RescueFilterTypes.claimed) {
      previousFilters[columnIndex] = [RescueFilterTypes.claimed];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }

    if (choice === RescueFilterTypes.unclaimed) {
      previousFilters[columnIndex] = [RescueFilterTypes.unclaimed];
      dispatch(setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }
  };

  const onRescuesFromFilterChange = (value) => {
    setRescuesFromType(value);
  };

  // this version of the view is intended for the Site Director role. it includes the adopter
  // column and the rescuer/claimer
  // column. for adopted rescues, the rescuer column is rendered empty. for non-adopted rescues, the rescuer column is
  // rendered with either rescuer selector (if not claimed) or the rescuer name in a clear chip.
  const rescueType = getTableSelectedRescueFilter();
  const selectedDateRange = getTableSelectedDateRange();
  const selectedPeriod = getTableSelectedPeriod();

  const filteredRescues =
    filter !== undefined
      ? showDisabledSiteFilter
        ? rescues.filter(filter(rescuesFromType))
        : rescues.filter(filter)
      : rescues;

  const uniqueFoodDonors = uniqBy(filteredRescues, 'location_id');
  const getRescueFoodDonorType = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'location');

    const foodDonorType = get(previousTableState, ['filterList', columnIndex, 0], 'All');

    // Clear food donor filter if the option is no longer selectable
    if (!uniqueFoodDonors.map((rescue) => rescue.location).includes(foodDonorType)) {
      const previousFilters = get(previousTableState, 'filterList', []);
      previousFilters[columnIndex] = [];
      return 'All';
    }

    return get(previousTableState, ['filterList', columnIndex, 0], 'All');
  };

  const rescuesWithSelectedFoodDonor = filteredRescues.filter((rescue) => rescue.location === getRescueFoodDonorType());
  const uniquePickupLocations = showOnlyPickupFilter
    ? uniqBy(filteredRescues, 'pickup_location_id')
    : uniqBy(rescuesWithSelectedFoodDonor, 'pickup_location_id');

  const getRescuePickupLocationType = () => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'pickup_location_name');

    // Clear pickup filter if the option is no longer selectable
    const pickupLocationOptions = uniquePickupLocations.map((rescue) => renderPickupLocationNameOrAdress(rescue))
    const pickupLocationType = get(previousTableState, ['filterList', columnIndex, 0], 'All');
    if (!pickupLocationOptions.includes(pickupLocationType)) {
      const previousFilters = get(previousTableState, 'filterList', []);
      previousFilters[columnIndex] = [];
      return 'All';
    }

    return pickupLocationType;
  };

  const onFoodDonorFilterChange = (choice) => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'location');
    const pickupLocationColumnIndex = get(previousTableState, 'columns', []).findIndex(
      (c) => c.name === 'pickup_location_name'
    );
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === 'All') {
      previousFilters[columnIndex] = [];
      previousFilters[pickupLocationColumnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    } else {
      previousFilters[columnIndex] = [choice];
      previousFilters[pickupLocationColumnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }
  };

  const onPickupLocationFilterChange = (choice) => {
    const previousTableState = get(tableOptions, [activeUser.id, TABLE_ID], null);
    const columnIndex = get(previousTableState, 'columns', []).findIndex((c) => c.name === 'pickup_location_name');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === 'All') {
      previousFilters[columnIndex] = [];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    } else {
      previousFilters[columnIndex] = [choice];
      dispatch(
        setUserTableOptions(activeUser.id, TABLE_ID, {
          ...previousTableState,
          filterList: previousFilters,
        })
      );
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Typography color="textPrimary">Future Rescues</Typography>
      </Breadcrumbs>

      {inReview && (
        <BulkEditReviewAndSave
          onExit={() => setInReview(false)}
          onSave={handleSaveClick}
          dialogOpen={inReview}
          editedRescues={editedRescues}
          rescues={filteredRescues}
          isLoading={isLoading}
        />
      )}

      <Box my={1} p={2} bgcolor="background.paper" boxShadow={25}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <FormControl variant="outlined" size="small" className={classes.formControl}>
              <InputLabel shrink htmlFor="rescues_show">
                Display Rescues
              </InputLabel>

              <Select
                inputProps={{
                  name: 'age',
                  id: 'rescues_show',
                }}
                label="Display Rescues"
                value={rescueType}
                onChange={onRadioFilterChange}
              >
                {[RescueFilterTypes.all, RescueFilterTypes.claimed, RescueFilterTypes.unclaimed].map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {showDisabledSiteFilter && (
            <Grid item>
              <FormControl variant="outlined" size="small" className={classes.formControl}>
                <InputLabel shrink>Rescues from:</InputLabel>

                <Select
                  label="Rescues from"
                  value={rescuesFromType}
                  onChange={(event) => onRescuesFromFilterChange(event.target.value)}
                >
                  {[
                    RescuesFromSiteFilterTypes.all,
                    RescuesFromSiteFilterTypes.enabled,
                    RescuesFromSiteFilterTypes.disabled,
                  ].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {displayFoodDonorFilters && (
            <>
              {!showOnlyPickupFilter && (
                <Grid item>
                  <FormControl variant="outlined" size="small" className={classes.formControl}>
                    <InputLabel shrink>Food Donor</InputLabel>
                    <Select
                      label="Food Donor"
                      value={getRescueFoodDonorType()}
                      onChange={(event) => {
                        onFoodDonorFilterChange(event.target.value)}}
                    >
                      <MenuItem value="All" data-name="all">
                        All
                      </MenuItem>
                      {uniqueFoodDonors.map((item) => (
                        <MenuItem key={item.location_id} value={item.location}>
                          {item.location}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item>
                <Tooltip Tooltip title={getRescueFoodDonorType() === 'All' ? 'Please select a Food Donor first.' : ''}>
                  <FormControl variant="outlined" size="small" className={classes.formControl}>
                    <InputLabel shrink>Pickup Location</InputLabel>
                    <Select
                      label="Pickup Location"
                      disabled={showOnlyPickupFilter ? inflight : getRescueFoodDonorType() === 'All' || inflight}
                      value={getRescuePickupLocationType()}
                      onChange={(event) => {
                        onPickupLocationFilterChange(event.target.value)}}
                    >
                      <MenuItem value="All" data-name="all">
                        All
                      </MenuItem>
                      {uniquePickupLocations.map((item) => (
                        <MenuItem key={item.pickup_location_id} value={renderPickupLocationNameOrAdress(item)}>
                          {renderPickupLocationNameOrAdress(item)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>
            </>
          )}
          <Grid item>
            <DateAndGranularityPicker
              showCustomDateRange
              showOnlyFutureDates
              showGranularity={false}
              dateRangeOptions={dateRangeOptions}
              defaultDateRange={selectedPeriod}
              customDateRangeOptions={{
                minDate: moment().toDate(),
              }}
              customDateRangeValue={{
                startDate: selectedDateRange.from,
                endDate: selectedDateRange.to,
              }}
              onChange={({ dateRange, period }) =>
                onDateFilterChange(moment(dateRange.startDate), moment(dateRange.endDate), period)
              }
            />
          </Grid>
        </Grid>
      </Box>

      <div className={classes.tableContainer}>
        <RescuesTable
          renderNamesAsLinks={renderNamesAsLinks}
          showSiteColumn={umbrellaFoodDonorId}
          showActionsColumn={!umbrellaFoodDonorId}
          sites={sites}
          rescues={filteredRescues}
          tableId={TABLE_ID}
          isLoading={inflight}
          showCopyRescueButton={showCopyRescueButton}
          showEditRescueButton={showEditRescueButton}
          removedFilters={removedFilters}
          handleReviewClick={() => setInReview(true)}
          editedRescues={editedRescues}
          setEditedRescues={setEditedRescues}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </div>
    </>
  );
};

export default FutureRescuesListView;
