import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../assets/theme/Colors';

export const useRescuesTableRowColorStyles = makeStyles(() => ({
  closedRescueRow: {
    borderLeft: `8px solid ${Colors.rescues.closed.color}`,
  },
  cancelledRescueRow: {
    borderLeft: `8px solid ${Colors.rescues.cancelled.color}`,
  },
  unclaimedRescueRow: {
    borderLeft: `8px solid ${Colors.rescues.unclaimed.color}`,
  },
  claimedRescueRow: {
    borderLeft: `8px solid ${Colors.rescues.claimed.color}`,
  },
}));

const RescuesTableStatusBox = ({ rescue }) => {
  if (rescue && rescue.cancelled_by_id) {
    return (
      <>
        <br />
        <span style={{ color: Colors.rescues.cancelled.color, fontWeight: 'bold' }}>Cancelled</span>
      </>
    );
  }

  if (rescue && rescue.closed_by_id) {
    return (
      <>
        <br />
        <span style={{ color: Colors.rescues.closed.color, fontWeight: 'bold' }}>Closed</span>
      </>
    );
  }

  if (rescue && rescue.claimer) {
    return (
      <>
        <br />
        <span style={{ color: Colors.rescues.claimed.color, fontWeight: 'bold' }}>Claimed</span>
      </>
    );
  }
  if (rescue && !rescue.claimer) {
    return (
      <>
        <br />
        <span style={{ color: Colors.rescues.unclaimed.color, fontWeight: 'bold' }}>Unclaimed</span>
      </>
    );
  }

  return null;
};

export default RescuesTableStatusBox;
