import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as Sentry from '@sentry/react';
import rootReducer from '../reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: () => {
    // for now let's don't log any kind of Redux actions into Sentry
    // hopefully we will find out which one might be handy later on and modify it over here
    return null;
  },
  stateTransformer: state => ({
    ...state,
    app: {
      ...state.app,
      accessToken: '<filtered>',
      idToken: '<filtered>',
      userRegistration: {
        ...state.app.userRegistration,
        accessToken: '<filtered>',
        idToken: '<filtered>',
      },
    },
    aggregates: '<filtered>',
    entities: '<filtered>',
  }),
});

const middleware = [thunk];

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_REDUX_LOGGER_ENABLED !== 'false') {
  middleware.push(createLogger());
}

export const store = createStore(
  persistReducer(
    {
      key: 'root',
      storage,
      whitelist: ['app'],
    },
    rootReducer
  ),
  composeWithDevTools(
    applyMiddleware(
      ...middleware
    ),
    sentryReduxEnhancer
  )
);
