import React, { Component } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import { generatePath } from 'react-router-dom';
import NotificationsTable from '../../../components/Notifications/NotificationsList/Desktop/NotificationsTable';
import * as notificationsActions from '../../../actions/notifications';
import SiteCoordinatorUnclaimedRescuesTable from '../../../components/SiteCoordinatorUnclaimedRescuesTable';
import { fetchSitePickupSpecs } from '../../../actions/sites';
import DonationsTable from '../../../components/DonationsTable';
import FoodDonorDonationRequestTable from '../../../components/FoodDonorDonationRequestTable';
import routes from '../../../routes';
import { Accordion, AccordionContent, AccordionHeader } from '../../../components/Common/Accordion';
import SiteCoordinatorFoodDonorRegistrations from '../../../components/SiteCoordinatorFoodDonorRegistrations';
import { fetchRequestDonationList } from '../../../actions/requestDonations';
import { fetchFoodDonorsWithPendingVerifications } from '../../../actions/foodDonors';

class SCDashboardToDo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accordion: [false, false, false, false, false],
    };

    this.navigateToDonationManageForm = this.navigateToDonationManageForm.bind(this);
    this.navigateToDonationRequestReviewForm = this.navigateToDonationRequestReviewForm.bind(this);
  }

  componentDidMount() {
    const { site, fetchContent } = this.props;
    if (site) {
      fetchContent(site.id);
    }
  }

  toggleAccordion = tab => {
    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    this.setState({
      accordion: state,
    });
  };

  markAsRead(row) {
    const { markNotificationAsRead } = this.props;
    return markNotificationAsRead(row.rowData[0]);
  }

  markAsReadBulk = (rowsDeleted, notifications) => {
    const { markNotificationsAsRead } = this.props;
    return markNotificationsAsRead(rowsDeleted.data.map(rowDeleted => notifications[rowDeleted.dataIndex].id));
  };

  navigateToDonationManageForm(donationId) {
    const { history } = this.props;

    return history.push(generatePath(routes.donation_edit, { donationId: donationId }));
  }

  navigateToDonationRequestReviewForm(donationId) {
    const { history } = this.props;

    return history.push(generatePath(routes.donation_request_verify, { donationId: donationId }));
  }

  render() {
    const {
      history,
      notifications,
      foodDonorRegistrations,
      foodDonorRegistrationsFailed,
      foodDonorDonationRequestsFailed,
      foodDonorDonationRequests,
      unclaimedRescues,
      donations,
      unclaimedRescuesFailed,
    } = this.props;
    const { accordion } = this.state;

    return (
      <Box mt={2}>
        <Grid container>
          <Grid item xs={12}>
            <Accordion>
              <AccordionHeader
                onClick={() => this.toggleAccordion(0)}
                title="Donation pickups with no Receiver assigned"
                counter={donations.length}
                action={accordion[0] ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
              />
              <AccordionContent isOpen={accordion[0]}>
                <DonationsTable
                  tableId="#sc-dashboard/donations"
                  donations={donations}
                  onEditClick={this.navigateToDonationManageForm}
                  onLocationClick={(donorId, locationId) =>
                    history.push(generatePath(routes.foodDonor, { foodDonorId: locationId }))
                  }
                  customFilters={{ showPendingRegistrationFilter: false }}
                />
              </AccordionContent>
            </Accordion>

            <Accordion>
              <AccordionHeader
                onClick={() => this.toggleAccordion(1)}
                title="Unclaimed Rescues"
                counter={
                  unclaimedRescuesFailed ? (
                    <Box display="flex" gridColumnGap={4}>
                      <ErrorIcon fontSize="small" />
                      <Typography variant="body2">We are having trouble communicating with the service</Typography>
                    </Box>
                  ) : (
                    unclaimedRescues.length
                  )
                }
                action={accordion[1] ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
              />
              <AccordionContent isOpen={accordion[1]}>
                <SiteCoordinatorUnclaimedRescuesTable
                  tableId="#site-coordinator/rescues-table"
                  rescues={unclaimedRescues}
                />
              </AccordionContent>
            </Accordion>

            <Accordion>
              <AccordionHeader
                onClick={() => this.toggleAccordion(3)}
                title="Food Donor Donation Requests"
                counter={
                  foodDonorDonationRequestsFailed ? (
                    <Box display="flex" gridColumnGap={4}>
                      <ErrorIcon fontSize="small" />
                      <Typography variant="body2">We are having trouble communicating with the service</Typography>
                    </Box>
                  ) : (
                    foodDonorDonationRequests.length
                  )
                }
                action={accordion[3] ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
              />
              <AccordionContent isOpen={accordion[3]}>
                <FoodDonorDonationRequestTable
                  tableId="#sc-dashboard/request-donations"
                  donations={foodDonorDonationRequests}
                  onReviewClick={this.navigateToDonationRequestReviewForm}
                  onLocationClick={(donorId, locationId) =>
                    history.push(generatePath(routes.foodDonor, { foodDonorId: locationId }))
                  }
                  customFilters={{ showPendingRegistrationFilter: false }}
                />
              </AccordionContent>
            </Accordion>

            <Accordion>
              <AccordionHeader
                onClick={() => this.toggleAccordion(4)}
                title="Food Donor Registration Requests"
                counter={
                  foodDonorRegistrationsFailed ? (
                    <Box display="flex" gridColumnGap={4}>
                      <ErrorIcon fontSize="small" />
                      <Typography variant="body2">We are having trouble communicating with the service</Typography>
                    </Box>
                  ) : (
                    foodDonorRegistrations.length
                  )
                }
                action={accordion[4] ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
              />
              <AccordionContent isOpen={accordion[4]}>
                <SiteCoordinatorFoodDonorRegistrations
                  tableId="#site-coordinator/food-donor-registrations-table"
                  foodDonors={foodDonorRegistrations}
                />
              </AccordionContent>
            </Accordion>

            <Accordion>
              <AccordionHeader
                onClick={() => this.toggleAccordion(2)}
                title="Notifications"
                counter={notifications.length}
                action={accordion[2] ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
              />
              <AccordionContent isOpen={accordion[2]}>
                <NotificationsTable
                  tableId="#sc-dashboard/notifications"
                  notifications={notifications}
                  onRowClick={(rowData, rowMeta, e) => {
                    if (e.target.tagName !== 'path' && e.target.tagName !== 'svg' && e.target.tagName !== 'BUTTON') {
                      history.push(generatePath(routes.notification, { notificationId: rowData[0] }));
                    }
                  }}
                  onRowsDelete={rowsDeleted => this.markAsReadBulk(rowsDeleted, notifications)}
                  additionalColumns={[
                    {
                      name: 'actions',
                      label: 'Actions',
                      options: {
                        download: false,
                        filter: false,
                        sort: false,
                        customBodyRender: (meta, row) => {
                          return (
                            <div>
                              <Tooltip placement="top" title="Mark as read">
                                <IconButton size="small" onClick={() => this.markAsRead(row)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          );
                        },
                      },
                    },
                  ]}
                />
              </AccordionContent>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (
  {
    app: { site },
    entities: {
      requestDonations: { byId: requestDonationsEntities, inflight: requestDonationsInflight },
      foodDonors: { byId: foodDonorsEntities },
      notifications: { unread },
      sites: { pickupSpecs, rescuers, unclaimedRescues },
      featureFlags,
    },
  },
  { history }
) => {
  const donationsResult = [
    ...(pickupSpecs.bySiteId[site.id] || [])
      .reduce(
        (entryMap, ps) =>
          entryMap.set(ps.donation_id, {
            ...ps,
            id: ps.donation_id,
          }),
        new Map()
      )
      .values(),
  ];

  return {
    history: history,
    notifications: Object.values(unread.byId),
    unclaimedRescuesFailed: unclaimedRescues.failed,
    foodDonorRegistrationsFailed: unclaimedRescues.failed,
    foodDonorDonationRequestsFailed: requestDonationsEntities.failed,
    foodDonorDonationRequests: Object.values(requestDonationsEntities),
    foodDonorRegistrations: Object.values(foodDonorsEntities),
    unclaimedRescues: unclaimedRescues.bySiteId[site.id] || [], //rescues fetched in parent component
    site,
    donations: donationsResult,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchContent: siteId => {
    dispatch(
      fetchSitePickupSpecs(siteId, false, {
        unassigned: true,
      })
    );
    dispatch(fetchRequestDonationList({
      siteIds: [siteId],
      verificationStatus: 'pending',
    }));
    dispatch(fetchFoodDonorsWithPendingVerifications([siteId]));
  },
  markNotificationAsRead: notificationId => dispatch(notificationsActions.markNotificationAsRead(notificationId)),
  markNotificationsAsRead: notificationIds => dispatch(notificationsActions.markNotificationsAsRead(notificationIds)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SCDashboardToDo);
