import React from 'react';

const DefaultDashboard = props => {
  const { userRole } = props;
  return (
    <>
      <h3>{userRole} Dashboard</h3>

      <p>This is a placeholder for now. In the future, you'll see content relevant to your role.</p>
    </>
  );
};

export default DefaultDashboard;
