import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Info as InfoIcon } from '@material-ui/icons';
import { Colors } from '../assets/theme/Colors';
import DonationSizesTable from '../components/Common/DonationSizesTable';
import { fetchDonationSizesIfNeeded } from '../actions/donationSizes';

export const shouldShowLegend = isOpen => (isOpen === undefined ? true : isOpen);

const useStyles = makeStyles({
  content: {
    margin: 0,
  },
});

const DonationSizeLegend = ({ label = 'Rescue Size Legend', isOpen, onExpandChange }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const donationSizes = useSelector((state) => state.entities.donationSizes);
  const rescueSizesLegend = Object.values(donationSizes.byId);

  useEffect(() => {
    dispatch(fetchDonationSizesIfNeeded());
  }, [dispatch]);

  return (
    <Box>
      <Accordion elevation={0} expanded={shouldShowLegend(isOpen)} onChange={onExpandChange}>
        <AccordionSummary
          style={{ padding: 0 }}
          classes={{ content: classes.content }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" alignItems="center">
            <InfoIcon fontSize="large" htmlColor={Colors.blue.main} style={{ paddingRight: '8px' }} />
            <Typography>{label}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box maxWidth={400}>
            <DonationSizesTable rescueSizes={rescueSizesLegend} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DonationSizeLegend;
