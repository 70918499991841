import React, { useEffect, useState } from 'react';
import {
  Today as TodayIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from '@material-ui/icons';
import { ButtonGroup, Button, Grid, Paper, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import useUIFilter from '../../hooks/useUIFilter';
import { RESCUER_SCHEDULE_FILTER } from '../../pages/rescuerDashboard/rescuerDashboardMobile/Schedule/ScheduleMobile';
import { initialRescuerScheduleFilters } from '../../reducers/ui';

const useStyles = makeStyles(() => ({
  filter: {
    textAlign: 'left',
    padding: '6px 16px',
    fontSize: '0.875rem',
    fontWeight: 500,
    userSelect: 'none',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.45)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    lineHeight: 1.75,
    fontFamily: 'Roboto, Helvetica, Arial',
    borderRadius: 4,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
}));

const RescuerScheduleDateFilter = ({ disableNextDate }) => {
  const classes = useStyles();
  const [isTodayDate, setIsTodayDate] = useState(true);
  const isTinyDevice = useMediaQuery('(max-width:339px)');
  const { setUIFilter, getUIFilter } = useUIFilter();
  const rescuerScheduleFilters = getUIFilter(RESCUER_SCHEDULE_FILTER) || initialRescuerScheduleFilters;

  const [date, setDate] = useState(
    rescuerScheduleFilters.date && rescuerScheduleFilters.date.value ? rescuerScheduleFilters.date.value : moment().startOf('day')
  );

  const setScheduleFilter = (filterName, filterProps) =>
    setUIFilter(RESCUER_SCHEDULE_FILTER, {
      ...rescuerScheduleFilters,
      [filterName]: filterProps,
    });

  useEffect(() => {
    setScheduleFilter('date', { value: date });
    setIsTodayDate(
      moment()
        .startOf('day')
        .format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
    );
  }, [date]);

  const getPreviousDate = () => {
    const selectedDays = rescuerScheduleFilters.days.value;

    if (selectedDays.length === 0) {
      setDate(moment(date).subtract(1, 'd'));
      return;
    }
    let nextDate = null;
    let tempDate = moment(date).clone();
    do {
      tempDate.subtract(1, 'd');
      selectedDays.forEach((dayName) => {
        if (!nextDate && tempDate.format('ddd') === dayName) {
          nextDate = tempDate.clone();
        }
      });
    } while (!nextDate);

    setDate(nextDate);
  };

  const getNextDate = () => {
    const selectedDays = rescuerScheduleFilters.days.value;

    if (selectedDays.length === 0) {
      setDate(moment(date).add(1, 'd'));
      return;
    }
    let nextDate = null;
    let tempDate = moment(date).clone();
    do {
      tempDate.add(1, 'd');
      selectedDays.forEach(dayName => {
        if (!nextDate && tempDate.format('ddd') === dayName) {
          nextDate = tempDate.clone();
        }
      });
    } while (!nextDate);

    setDate(nextDate);
  };

  return (
    <Grid spacing={2} container direction="row" justify="space-between" alignItems="center">
      <Grid item>
        <Paper variant="outlined" className={classes.filter}>
          {`Date: ${moment(rescuerScheduleFilters.date.value).format('MM/DD')}`}
        </Paper>
      </Grid>
      <Grid item>
        <ButtonGroup variant="contained" color="primary" size="medium" disableElevation>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disabled={isTodayDate}
            onClick={() => setDate(moment().startOf('day'))}
          >
            {isTinyDevice ? <TodayIcon /> : 'Today'}
          </Button>
          <Button onClick={getPreviousDate} disabled={isTodayDate}>
            <ArrowBackIosIcon />
          </Button>
          <Button onClick={getNextDate} disabled={disableNextDate}>
            <ArrowForwardIosIcon />
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default RescuerScheduleDateFilter;
