import React from 'react';
import PropTypes from 'prop-types';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { getMuiTableDataIndex } from '../helpers/getters';

const UmbrellaFoodDonorStaffTable = ({ tableId, umbrellaFoodDonorStaff, customToolbar, actionButtons }) => (
  <BaseMUIDataTable
    tableId={tableId}
    title="Staff"
    data={umbrellaFoodDonorStaff}
    columns={[
      {
        name: 'firstname',
        label: 'First Name',
        options: {
          filter: false,
          sort: false,
          display: true,
        },
      },
      {
        name: 'lastname',
        label: 'Last Name',
        options: {
          filter: false,
          sort: false,
          display: true,
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          filter: false,
          sort: false,
          display: true,
        },
      },
      {
        name: 'phone',
        label: 'Phone',
        options: {
          filter: false,
          sort: false,
          display: true,
        },
      },
      {
        name: 'is_admin',
        label: 'Role',
        options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRender: value => (value ? 'Admin' : 'Staff'),
        },
      },
      {
        name: 'completed_registration',
        label: 'Completed Registration',
        options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRender: value => (value !== null ? 'yes' : 'no'),
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        options: {
          download: false,
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, tableMeta) => {
            const staff = umbrellaFoodDonorStaff[getMuiTableDataIndex(tableMeta)];
            return actionButtons(staff);
          },
        },
      },
    ]}
    options={{
      responsive: 'simple',
      selectableRows: 'none',
      customToolbar: customToolbar,
    }}
  />
);

UmbrellaFoodDonorStaffTable.propTypes = {
  tableId: PropTypes.string.isRequired,
};

export default UmbrellaFoodDonorStaffTable;
