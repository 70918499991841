import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Box, Button, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import routes from '../../../../routes';
import { ReactComponent as Wave1 } from '../../../../assets/idMode/wave1.svg';
import { ReactComponent as Wave2 } from '../../../../assets/idMode/wave2.svg';
import { ReactComponent as Hill1 } from '../../../../assets/idMode/hill1.svg';
import { ReactComponent as Hill2 } from '../../../../assets/idMode/hill2.svg';
import { hasRoleInCurrentlySelectedSite } from '../../../../services/auth';
import { Roles } from '../../../../models/roles';
import useActiveUser from '../../../../hooks/useActiveUser';
import { Colors } from '../../../../assets/theme/Colors';
import useActiveSite from '../../../../hooks/useActiveSite';
import frusLogo from '../../../../assets/images/frus-logo-oval.png';

const customTextColorsTypes = {
  toolbar: 'toolbar',
  button: 'button',
}

const getCustomTextColor = (props, theme, defaultColor, type = customTextColorsTypes.toolbar) => {
  if (type === customTextColorsTypes.toolbar && props.customBranding?.toolbar_text_color && props.hasCustomBranding) {
    return props.customBranding.toolbar_text_color;
  }

  if (type === customTextColorsTypes.toolbar && props.customBranding?.primary_color && props.hasCustomBranding) {
    return theme.palette.getContrastText(props.customBranding.primary_color);
  }

  if (type === customTextColorsTypes.button  && props.customBranding?.button_text_color && props.hasCustomBranding) {
    return props.customBranding.button_text_color;
  }

  if (type === customTextColorsTypes.button  && props.customBranding?.secondary_color && props.hasCustomBranding) {
    return theme.palette.getContrastText(props.customBranding.secondary_color);
  }
  return theme.palette.getContrastText(defaultColor);
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: (props) => (props.hasCustomBranding && props.customBranding.primary_color ? props.customBranding.primary_color : Colors.appBar.main),
    padding: 0,
    margin: 0,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
  },
  wave1: {
    position: 'absolute',
    width: '100%',
    right: -1,
    top: 0,
    zIndex: 2,
    fill:(props) => (props.hasCustomBranding && props.customBranding.secondary_color ? props.customBranding.secondary_color : Colors.primaryColor),
  },
  wave2: {
    position: 'absolute',
    top: 0,
    left: 0,
    fill: (props) => (props.hasCustomBranding && props.customBranding.secondary_color ? props.customBranding.secondary_color : Colors.primaryColor),
    filter: 'brightness(3.8) saturate(0.2)',
  },
  hill1: {
    position: 'absolute',
    bottom: 0,
    right: -1,
    zIndex: 2,
    fill:(props) => (props.hasCustomBranding && props.customBranding.secondary_color ? props.customBranding.secondary_color : Colors.primaryColor),
  },
  hill2: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    fill: (props) => (props.hasCustomBranding && props.customBranding.secondary_color ? props.customBranding.secondary_color : Colors.primaryColor),
    filter: 'brightness(3.8) saturate(0.2)',
  },
  role: {
    width: 'fit-content',
    borderRadius: '12px',
    padding: '0.5rem 3rem',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'normal',
    cursor: 'default',
    '&:hover': {
      backgroundColor: (props) => (props.hasCustomBranding && props.customBranding.secondary_color ? props.customBranding.secondary_color : Colors.primaryColor),
    },
    backgroundColor: (props) => (props.hasCustomBranding && props.customBranding.secondary_color ? props.customBranding.secondary_color : Colors.primaryColor),
  },
  logo: {
    width: 60,
    height: 60,
    marginLeft: '1rem',
    position: 'absolute',
    bottom: 50,
    zIndex: 50,
  },
  name: {
    textAlign: 'center',
    color: (props) => getCustomTextColor(props, theme, Colors.appBar.main),
  },
  site: {
    textAlign: 'center',
    fontSize: '1.1rem',
    color: (props) => getCustomTextColor(props, theme, Colors.appBar.main),
  },
  frus: {
    marginBottom: '0.5rem',
    color: (props) => getCustomTextColor(props, theme, Colors.primaryColor, customTextColorsTypes.button),
  },
  frusContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginRight: '1rem',
    marginBottom: '1rem',
    zIndex: 50,
  },
  avatar: {
    height: '160px',
    width: '160px',
    fontSize: '4rem',
    border: '4px solid white',
  },
  userInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    position: 'absolute',
    top: '35%',
    transform: 'translateY(-35%)',
    padding: '0 1rem',
    zIndex: 50,
  },
  exitButton: {
    display: 'block',
    marginLeft: 'auto',
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
}));

const IdModeMobileContainer = () => {
  const site = useActiveSite();
  const history = useHistory();
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const sites = useSelector((state) => state.entities.sites.byId);
  const settings = useSelector((state) => state.entities.settings);
  const isRescuer = hasRoleInCurrentlySelectedSite(activeUser, Roles.Rescuer);
  const idTokenPayload = useSelector((state) => state.app.idTokenPayload);
  const picture =
    // for now support only Google avatars
    idTokenPayload && idTokenPayload.picture && idTokenPayload.picture.includes('googleusercontent')
      ? idTokenPayload.picture
      : 'no-picture'; // Src needs to be a broken link (or in this case a string) for the fallback letter to work - https://github.com/mui/material-ui/issues/32162

  // Check for custom branding setting
  const currentSite = Object.values(sites).find((s) => s.id === site?.id);
  const settingsList = Object.values(settings.byId);
  const customBrandingSetting = settingsList.find((setting) => setting.name === 'custom_branding');
  const hasCustomBranding =
    currentSite?.settings.find((setting) => setting.id === customBrandingSetting?.id)?.value === '1';

  const classes = useStyles({
    hasCustomBranding,
    customBranding: {
      primary_color: currentSite?.primary_color,
      secondary_color: currentSite?.secondary_color,
      button_text_color: currentSite?.button_text_color,
      toolbar_text_color: currentSite?.toolbar_text_color,
    },
  });

  const onExitClick = () => {
    history.push(routes.mobileDashboard);
  };

  if (!isRescuer) {
    return history.push(routes.index);
  }

  return (
    <Box className={classes.container}>
      <Wave1 className={classes.wave1} />
      <Wave2 className={classes.wave2} />
      <Hill1 className={classes.hill1} />
      <Hill2 className={classes.hill2} />
      <Box className={classes.userInfo}>
        <Avatar alt={activeUser?.firstname} src={picture} className={classes.avatar} />
        <Typography
          variant="h3"
          className={classes.name}
        >{`${activeUser?.firstname} ${activeUser?.lastname}`}</Typography>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          disableFocusRipple
          disableElevation
          fullWidth
          className={classes.role}
        >
          Rescuer
        </Button>
        <Box mt={2}>
          <Typography variant="subtitle2" className={classes.site}>
            {activeSite?.name}
          </Typography>
          <Typography variant="subtitle2" className={classes.site}>
            Active since {moment(activeUser?.completed_registration).format('M/DD/YYYY')}
          </Typography>
        </Box>
      </Box>

      <img
        alt="logo"
        className={classes.logo}
        src={hasCustomBranding && currentSite?.logo_url ? currentSite.logo_url : frusLogo}
      />

      <Box className={classes.frusContainer}>
        <Typography variant="h6" className={classes.frus}>
          Food Rescue US
        </Typography>

        <Button className={classes.exitButton} variant="contained" onClick={onExitClick}>
          Exit
        </Button>
      </Box>
    </Box>
  );
};

export default IdModeMobileContainer;
