import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  FormLabel,
  withStyles,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import moment from 'moment';
import Bluebird from 'bluebird';
import { DatePicker } from '@material-ui/pickers';
import { formatAddress } from '../../../../helpers/formatters';
import MinMaxTimePicker from '../../../../components/MinMaxTimePicker';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import errorMessages from '../../../../assets/errorMessages';
import { TextFieldWrapper, TextFieldWrapper as TextField } from '../../../../components/Form/MuiFormWrapper';
import FABContext from '../../../../context/FABContext/FABContext';
import { getActiveFoodDonors } from '../../../../helpers/getters';
import ReceiverSelector from '../../../../components/ReceiverSelector';
import { SelectorMobileDialog } from '../../../../components/Common/SelectorMobileDialog';
import LbsSelector from '../../../Donation/DonationManage/Components/Lbs/LBSSelector';
import { foodTypes as foodTypesModel, newFoodTypes as foodTypesModelNew } from '../../../../models/foodTypes';
import { RESCUE_SIZE_BICYCLE_NAME } from '../../../Donation/DonationManage/donationValidator';

const SubmitButtonWrapper = withStyles(({ palette, spacing, shadows, breakpoints }) => ({
  root: {
    background: '#fff',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    left: 0,
    padding: spacing(2, 3),
    boxShadow: shadows[25],
    borderTop: `1px solid ${palette.divider}`,
    zIndex: 1,
    [breakpoints.up('sm')]: {
      background: 'none',
      position: 'unset',
      padding: spacing(5, 0, 0),
      boxShadow: 'none',
      borderTop: 'unset',
    },
  },
}))(Box);

const validationResolver = (data, { foodDonorsPickupLocations, rescueSizes }) => {
  const errors = {};
  const bicycleSize = rescueSizes.find((size) => size.name === RESCUE_SIZE_BICYCLE_NAME);

  // Require lbs when the Bicycle rescue size is selected
  if (data.rescue_size_id === bicycleSize?.id.toString() && !data.lbs) {
    errors.lbs = errorMessages.REQUIRED.message;
  }

  if (!data.food_donor_id) {
    errors.food_donor_id = { message: errorMessages.REQUIRED.message };
  }

  if ((foodDonorsPickupLocations[data.food_donor_id] || []).length > 1 && !data.pickup_location_id) {
    errors.pickup_location_id = { message: errorMessages.REQUIRED.message };
  }

  if (!data.receiver_id) {
    errors.receiver_id = { message: errorMessages.REQUIRED.message };
  }

  if (!data.rescue_size_id) {
    errors.rescue_size = { message: errorMessages.REQUIRED.message };
  }

  if (!data.start_date) {
    errors.start_date = { message: errorMessages.REQUIRED.message };
  }

  if (!data.pickup_begin) {
    errors.pickup_begin = { message: errorMessages.REQUIRED.message };
  }

  if (!data.pickup_end) {
    errors.pickup_end = { message: errorMessages.REQUIRED.message };
  }

  const start = data.pickup_begin.format('HH:mm:ss');
  const end = data.pickup_end.format('HH:mm:ss');
  const pickupBeginIsBeforeEnd = moment(start, 'HH:mm:ss').isAfter(moment(end, 'HH:mm:ss'));

  if (pickupBeginIsBeforeEnd) {
    errors.pickup_end = { message: errorMessages.PICKUP_START_TIME_BEFORE_END_TIME.message };
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors,
  };
};
const RescueAddForm = ({
  isMobileView,
  donorsLocationsList,
  foodDonorsPickupLocations,
  receiversList,
  rescuersList,
  rescueSizes,
  donationSizes,
  createRescue,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReceiverSelectorOpen, setReceiverSelectorOpen] = useState(false);
  const [isRescuerSelectorOpen, setRescuerSelectorOpen] = useState(false);
  const [isFoodDonorSelectorOpen, setFoodDonorSelectorOpen] = useState(false);
  const [isPickupLocationSelectorOpen, setPickupLocationSelectorOpen] = useState(false);
  const fabContext = useContext(FABContext);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    clearError,
    errors,
    control,
    watch,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      food_donor_id: '',
      receiver_id: '',
      rescuer_id: '',
      pickup_location_id: '',
      food_types: [],
      rescue_size_id: '',
      donation_size_id: '',
      lbs: '',
      start_date: new Date(),
      pickup_begin: moment(8, 'HH'),
      pickup_end: moment(12, 'HH'),
    },
    validationResolver: validationResolver,
    validationContext: {
      foodDonorsPickupLocations: foodDonorsPickupLocations,
      rescueSizes: rescueSizes,
    },
  });

  const watchFoodDonorId = watch('food_donor_id', 0);
  const watchFoodTypes = watch('food_types', []);

  useEffect(() => {
    if (rescueSizes.length > 0) {
      const defaultRescueSize = rescueSizes.reduce(
        (acc, curr) => (curr.name.localeCompare('Compact Sedan', 'en', { sensitivity: 'base' }) === 0 ? curr.id : acc),
        ''
      );

      setValue('rescue_size_id', defaultRescueSize, isSubmitted);
    }

    if (donationSizes.length > 0) {
      const defaultDonationSize = donationSizes.reduce(
        (acc, curr) => (curr.size.localeCompare('S', 'en', { sensitivity: 'base' }) === 0 ? curr.id : acc),
        ''
      );

      setValue('donation_size_id', defaultDonationSize, isSubmitted);
    }
  }, [rescueSizes.length, donationSizes.length]);

  const onSubmit = values =>
    Bluebird.try(() => {
      setIsSubmitting(true);
      return createRescue({
        ...values,
        food_donor_id: values.food_donor_id ? values.food_donor_id.id : '',
        pickup_location_id: values.pickup_location_id ? values.pickup_location_id.id : '',
        rescuer_id: values.rescuer_id ? values.rescuer_id.id : '',
        receiver_id: values.receiver_id ? values.receiver_id.receiver_id : '',
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        pickup_begin: moment(values.pickup_begin).format('HH:mm:ss'),
        pickup_end: moment(values.pickup_end).format('HH:mm:ss'),
      });
    });

  const handleCheck = checkedId => {
    return watchFoodTypes.includes(checkedId)
      ? watchFoodTypes.filter(id => id !== checkedId)
      : [...watchFoodTypes, checkedId];
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SelectorMobileDialog
        dialogTitle="Receiver:"
        getOptionId={receivingAgency => receivingAgency.value.receiver_id}
        getOptionLabel={receivingAgency => receivingAgency.label}
        options={receiversList}
        open={isReceiverSelectorOpen}
        onClose={value => {
          if (value) {
            setValue('receiver_id', value);
            clearError('receiver_id');
          }
          setReceiverSelectorOpen(false);
        }}
      />

      <SelectorMobileDialog
        dialogTitle="Rescuer (optional):"
        getOptionId={rescuer => rescuer.value.id}
        getOptionLabel={rescuer => rescuer.label}
        options={rescuersList}
        open={isRescuerSelectorOpen}
        onClose={value => {
          if (value) {
            setValue('rescuer_id', value);
            clearError('rescuer_id');
          }
          setRescuerSelectorOpen(false);
        }}
      />

      <SelectorMobileDialog
        dialogTitle="Food Donor:"
        getOptionId={option => option.value.id}
        getOptionLabel={option => option.label}
        options={donorsLocationsList}
        open={isFoodDonorSelectorOpen}
        onClose={value => {
          if (value) {
            setValue('food_donor_id', value);
            clearError('food_donor_id');
          }
          setFoodDonorSelectorOpen(false);
        }}
      />

      <SelectorMobileDialog
        dialogTitle="Pickup Location:"
        getOptionId={option => option.value.id}
        getOptionLabel={option => option.label}
        options={foodDonorsPickupLocations[watchFoodDonorId?.id] || []}
        open={isPickupLocationSelectorOpen}
        onClose={value => {
          if (value) {
            setValue('pickup_location_id', value);
            clearError('pickup_location_id');
          }
          setPickupLocationSelectorOpen(false);
        }}
      />

      <Box display="grid" gridAutoFlow="row" gridRowGap={16}>
        <Controller
          name="food_donor_id"
          control={control}
          as={props => (
            <Box>
              <ReceiverSelector
                value={props.value}
                name="food_donor_id"
                inputId="food_donor_id"
                instanceId="food_donor_id"
                placeholder="Food Donor"
                receivers={donorsLocationsList}
                onMenuOpen={() => setFoodDonorSelectorOpen(true)}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
              />
              {errors.food_donor_id && (
                <FormHelperText id="food_donor_id-helper-text" error>
                  {errors.food_donor_id.message}
                </FormHelperText>
              )}
            </Box>
          )}
        />

        {(foodDonorsPickupLocations[watchFoodDonorId.id] || []).length > 1 && (
          <Controller
            name="pickup_location_id"
            control={control}
            as={props => (
              <Box>
                <ReceiverSelector
                  required
                  value={props.value}
                  name="pickup_location_id"
                  inputId="pickup_location_id"
                  instanceId="pickup_location_id"
                  placeholder="Pickup Location"
                  receivers={foodDonorsPickupLocations[watchFoodDonorId.id]}
                  onMenuOpen={() => setPickupLocationSelectorOpen(true)}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                />
                {errors.pickup_location_id && (
                  <FormHelperText id="pickup_location_id-helper-text" error>
                    {errors.pickup_location_id.message}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
        )}

        <TextField
          fullWidth
          name="description"
          label="Description"
          variant="outlined"
          size="small"
          inputRef={register}
          error={!!errors.description}
          helperText={errors.description ? errors.description.message : ''}
          InputLabelProps={{ shrink: !!getValues('description') || undefined }}
        />

        <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
          <Box flexBasis={isMobileView ? '100%' : '50%'} flexGrow={1} pr={{ xs: 0, sm: 2 }} pb={{ xs: 2, sm: 0 }}>
            <Controller
              name="start_date"
              control={control}
              as={({ onChange, value }) => (
                <DatePicker
                  name="start_date"
                  variant={isMobileView ? 'dialog' : 'inline'}
                  inputVariant="outlined"
                  minDate={new Date()}
                  label="Rescue Date"
                  size="small"
                  value={value}
                  fullWidth
                  format="MM/DD/YYYY"
                  placeholder="mm/dd/yyyy"
                  onChange={momentEvent => onChange(moment(momentEvent).format('YYYY-MM-DD'))}
                />
              )}
            />
          </Box>
          <Box pr={1} flexBasis={isMobileView ? '50%' : '25%'} flexGrow={1}>
            <Controller
              name="pickup_begin"
              control={control}
              as={props => (
                <MinMaxTimePicker
                  fullWidth
                  value={props.value}
                  maxTime={getValues('pickup_end')}
                  variant={isMobileView ? 'dialog' : 'inline'}
                  inputVariant="outlined"
                  name="pickup_begin"
                  size="small"
                  label="From"
                  onTimeChange={({ event, duration }) => {
                    const endTime = event.clone().add(duration);

                    setValue('pickup_begin', event);
                    setValue('pickup_end', endTime);
                  }}
                  showWarning={errors.pickup_end}
                />
              )}
            />
            {errors.pickup_end && (
              <FormHelperText id="pickup_end-helper-text" error={true}>
                {errors.pickup_end.message}
              </FormHelperText>
            )}
          </Box>
          <Box pl={1} flexBasis={isMobileView ? '50%' : '25%'} flexGrow={1}>
            <Controller
              name="pickup_end"
              control={control}
              as={props => (
                <MinMaxTimePicker
                  fullWidth
                  showFieldErrors
                  value={props.value}
                  minTime={getValues('pickup_begin')}
                  variant={isMobileView ? 'dialog' : 'inline'}
                  inputVariant="outlined"
                  name="pickup_end"
                  size="small"
                  label="To"
                  onTimeChange={({ event, isValid }) => {
                    if (isValid) {
                      setValue('pickup_end', event, isSubmitted);
                    }
                  }}
                />
              )}
            />
          </Box>
        </Box>

        <Controller
          name="receiver_id"
          control={control}
          as={props => (
            <Box>
              <ReceiverSelector
                value={props.value}
                name="receiver_id"
                inputId="receiver_id"
                instanceId="receiver_id"
                receivers={receiversList}
                onMenuOpen={() => setReceiverSelectorOpen(true)}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.receiver}
              />
              {errors.receiver_id && (
                <FormHelperText id="receiver_id-helper-text" error>
                  {errors.receiver_id.message}
                </FormHelperText>
              )}
            </Box>
          )}
        />

        <Controller
          name="rescuer_id"
          control={control}
          as={props => (
            <Box>
              <ReceiverSelector
                invalid={!!errors.rescuer_id}
                value={props.value}
                name="rescuer_id"
                placeholder="Rescuer (optional)"
                inputId="rescuer_id"
                instanceId="rescuer_id"
                receivers={rescuersList}
                onMenuOpen={() => setRescuerSelectorOpen(true)}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
              />
              {errors.rescuer_id && (
                <FormHelperText id="rescuer_id-helper-text" error>
                  {errors.rescuer_id.message}
                </FormHelperText>
              )}
            </Box>
          )}
        />

        <TextFieldWrapper
          required
          name="notes"
          label="Notes"
          variant="outlined"
          size="small"
          inputRef={register}
          placeholder="Notes"
          error={!!errors.notes}
          helperText={errors.notes ? errors.notes.message : ''}
          InputLabelProps={{ shrink: !!control.getValues('notes') || undefined }}
        />


        <Controller
          name="rescue_size_id"
          control={control}
          as={props => (
            <TextField
              required
              onChange={props.onChange}
              onBlur={props.onBlur}
              select
              SelectProps={{
                native: true,
              }}
              value={props.value}
              id="rescue_size_id"
              label="Rescue Vechicle Size"
              variant="outlined"
              size="small"
              error={!!errors.rescue_size_id}
              helperText={errors.rescue_size_id ? errors.rescue_size.message : ''}
              InputLabelProps={{ shrink: !!props.value || undefined }}
              inputProps={{
                name: props.name,
                id: `id-${props.name}`,
              }}
              fullWidth
            >
              {rescueSizes.map(option => (
                <option key={`rs_0${option.id}`} value={option.id.toString()}>
                  {option.name}
                </option>
              ))}
            </TextField>
          )}
        />

        <Controller
          name="lbs"
          control={control}
          as={({ value, onChange}) => (
            <LbsSelector
              rescueSizeValue={watch('rescue_size_id')}
              rescueSizes={rescueSizes}
              lbs={value}
              onValueChange={onChange}
              error={errors ? errors.lbs : null}
              hasActiveFF={true}
              showOnlyTextField={true}
            />
          )}
        />

        <FormControl component="fieldset" data-testid="food_types">
          <FormLabel component="legend" id="legend-food_types">
            Food Types
          </FormLabel>
          <Controller
            name="food_types"
            control={control}
            as={({ value, onChange }) => {
              return (
                <FormGroup row>
                  {foodTypesModelNew.map(foodType => (
                    <FormControlLabel
                      key={foodType.label}
                      label={foodType.label}
                      control={
                        <Checkbox
                          name={foodType.name}
                          checked={value.includes(foodType.name)}
                          value={foodType.name}
                          onChange={event => onChange(handleCheck(event.target.value))}
                        />
                      }
                    />
                  ))}
                </FormGroup>
              );
            }}
          />
        </FormControl>

        {watchFoodTypes.includes('other') && (
          <TextFieldWrapper
            required
            name="food_type_other"
            variant="outlined"
            size="small"
            inputRef={control.register()}
            id="food_type_other"
            label="Other Food Type"
            placeholder="Other Food Type"
            inputProps={{
              'data-testid': 'food_type_other',
            }}
            error={!!errors.food_type_other}
            helperText={errors.food_type_other ? errors.food_type_other.message : ''}
            InputLabelProps={{ shrink: !!control.getValues('food_type_other') || undefined }}
            style={{ marginTop: -10 }}
          />
        )}
      </Box>

      <SubmitButtonWrapper>
        <Box pr={fabContext.isVisible ? 8 : 0} data-testid="save-btn-wrapper">
          <ButtonWithLoading
            isLoading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="medium"
            aria-label="save"
          >
            Add rescue
          </ButtonWithLoading>
        </Box>
      </SubmitButtonWrapper>

      {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
    </form>
  );
};

export default RescueAddForm;
