import React from 'react';
import { Container, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import loginLogo from '../../assets/images/login-logo.svg';
import LoginForm from './LoginForm';
import { useFormStyles } from './authStyles';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import SocialMediaLinkIcons from '../../components/SocialMediaLinkIcons';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  loginButtonsContainer: {
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gridGap: spacing(1),
      marginTop: spacing(1),
    },
  },
  socialMediaLinks: {
    paddingTop: spacing(2),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const authStyles = useFormStyles();
  return (
    <Box className={authStyles.pageBackground}>
      <Container maxWidth="md">
        <Grid container spacing={2} className={authStyles.pageContainer} data-testid="login-container">
          <Grid item xs className={authStyles.logoWrapper}>
            <img src={loginLogo} alt="logo" className={authStyles.logo} />
          </Grid>
          <Grid item xs className={authStyles.loginFormWrapper}>
            <LoginForm />

            <Grid container className={classes.loginButtonsContainer}>
              <Grid item xs>
                <Link to={routes.forgotPassword} variant="body2" style={{ color: '#fff' }}>
                  Forgot password?
                </Link>
              </Grid>

              <Grid item>
                <Link to={routes.signup} variant="body2" style={{ color: '#fff' }}>
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>

            <Grid container direction="row" justify="center" alignItems="center" className={classes.socialMediaLinks}>
              <SocialMediaLinkIcons iconColor="#fff" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LoginPage;
