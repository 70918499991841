import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import loadingImage from '../../../../assets/images/rescue-image-loading.jpg';

const useStyles = makeStyles(() => ({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  img: {
    maxHeight: '100%',
    minWidth: '100%',
    objectFit: 'cover',
  },
  wrapper: {
    cursor: 'pointer',
    backgroundColor: '#a2a2a2',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  photoCover: (tile) => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${tile.url}), url(${loadingImage})`,
    width: '100%',
    height: '100%',
  }),
}));

const GalleryGridItem = ({ tile, handleDialogOpen, children }) => {
  const classes = useStyles(tile);

  return (
    <Box onClick={handleDialogOpen} className={classes.wrapper}>
      <Box className={classes.photoCover} />
      {children}
    </Box>
  );
};

export default GalleryGridItem;
