import React, { Component } from 'react';
import { generatePath, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import {
  Paper,
  Stepper,
  Step,
  Grid,
  StepLabel,
  Button,
  Typography,
  FormControl,
  FormGroup,
  Checkbox,
  TextField,
  FormHelperText,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  withWidth,
  Box,
  Tooltip,
} from '@material-ui/core';
import { ArrowBack, ArrowForward, Check, Phone, Help as HelpIcon } from '@material-ui/icons';
import { compose } from 'redux';
import StepContent from '@material-ui/core/StepContent';
import { injectIntl } from 'react-intl';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import * as zipCodesApi from '../api/zipCodes';
import * as userRegistrationsApi from '../api/userRegistrations';
import * as authService from '../services/auth';
import * as authActions from '../actions/auth';
import * as userRegistrationsActions from '../actions/userRegistrations';
import routes from '../routes/index';
import PhoneConfirmationCodeDialog from './PhoneConfirmationCodeDialog';
import * as userRegistrationsModel from '../models/userRegistrations';
import { getPhoneNumberFromMask, getZipCodeFromMask } from '../helpers/getters';
import OverlayLoader from './OverlayLoader';
import { Colors } from '../assets/theme/Colors';
import ButtonWithLoading from './ButtonWithLoading';
import { isPhoneMaskValid } from '../helpers/validators';
import { formatPhoneNumber } from '../helpers/formatters';
import errorMessages from '../assets/errorMessages';
import VerificationEmailSentConfirmationDialog from "./VerificationEmailSentConfirmationDialog";
import AddressAutocomplete from './AddressAutocomplete';
import { isInPWAView } from './Add2HS/PWAInstallButton';

class CompleteRescuerRegistration extends Component {
  constructor(props) {
    super(props);

    const { formatMessage } = this.props.intl;

    this.state = {
      showResendEmailDialog: false,
      componentLoading: true,
      loading: false,
      verificationDialogLoading: false,
      activeStep: 1,
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      zipCode: '',
      zipCodeError: false,
      zipCodeSuccess: false,
      zipCodeSites: [],
      zipCodeCity: '',
      zipCodeState: '',
      keepInTouch: {
        newsletter: true,
        notifyMe: true,
        volunteer: false,
      },
      termsAccepted: false,
      showKeepInTouchStep: false,
      phoneNumber: '',
      phoneNumberInput: '',
      phoneNumberError: false,
      phoneNumberErrorMessage: '',
      phoneNumberVerified: false,
      phoneNumberVerificationPending: false,
      showPhoneNumberVerificationDialog: false,
      confirmationCodeError: false,
      isResendEmailLoading: false,
      steps: [
        {
          name: formatMessage({ id: 'complete-rescuer-registration.steps.verify-email' }),
          optional: false,
        },
        {
          name: formatMessage({ id: 'complete-rescuer-registration.steps.name-and-location' }),
          optional: false,
          specifyLocationStep: true,
        },
        {
          name: formatMessage({ id: 'complete-rescuer-registration.steps.verify-phone' }),
          optional: false,
          phoneStep: true,
        },
        {
          name: formatMessage({ id: 'complete-rescuer-registration.steps.complete-registration' }),
          optional: false,
        },
      ],
      keepInTouchStep: {
        name: formatMessage({ id: 'complete-rescuer-registration.steps.keep-in-touch' }),
        optional: true,
      },
    };
  }

  formErrorHandler = err => {
    switch (err.code) {
      case errorMessages.ERR_DUPLICATED_PHONE.code:
        this.setState({
          phoneNumberError: true,
          phoneNumberErrorMessage: errorMessages.ERR_DUPLICATED_PHONE.message,
        });

        break;
      case errorMessages.ERR_DUPLICATED_EMAIL.code:
        snackbarHelper.error(errorMessages.ERR_DUPLICATED_EMAIL.message);

        break;
      default:
        errorsHelper.handleError(err, err.message || err.code || 'Unknown error');

        break;
    }
  };

  componentDidMount = () => {
    const { userRegistration, setUserRegistrationData, history } = this.props;
    const { formatMessage } = this.props.intl;
    const { steps } = this.state;

    if (authService.getCurrentlyLoggedInOrImpersonatingUser()) {
      snackbarHelper.info(
        formatMessage({ id: 'complete-rescuer-registration.snackbarHelper.registration-process-already-completed' })
      );

      return history.push(routes.index);
    } else {
      // if user is not logged in and he visits this page directly, so we don't have auth0 token set, redirect to login
      if (!userRegistration.idTokenPayload) {
        return history.push(routes.login);
      }
    }

    let userRegistrationData;

    Bluebird.try(() => {
      const userId = userRegistration.idTokenPayload.id;
      const email = userRegistration.idTokenPayload.email;
      const accessToken = userRegistration.accessToken;
      const data = {
        firstname: userRegistration.idTokenPayload.given_name ? userRegistration.idTokenPayload.given_name : null,
        lastname: userRegistration.idTokenPayload.family_name ? userRegistration.idTokenPayload.family_name : null,
        email: userRegistration.idTokenPayload.email,
        email_verified: !!userRegistration.idTokenPayload.email_verified,
        social: authService.getIdTokenPayloadCustomClaim(userRegistration.idTokenPayload, 'is_social') || false,
      };

      return Bluebird.try(() => {
        if (userRegistration.idTokenPayload.id) {
          return userRegistrationsApi.getUserRegistrationByUserId(userId, accessToken);
        }

        return userRegistrationsApi.getUserRegistrationByEmail(email, accessToken);
      })
        .then(res => res.json())
        .then(res => {
          if (res.data.length) {
            const foundUserRegistration = res.data[0];
            const dataToBeUpdated = {};

            if (data.firstname && foundUserRegistration.firstname !== data.firstname) {
              dataToBeUpdated['firstname'] = data.firstname;
            }

            if (data.lastname && foundUserRegistration.lastname !== data.lastname) {
              dataToBeUpdated['lastname'] = data.lastname;
            }

            if (foundUserRegistration.email !== data.email) {
              dataToBeUpdated['email'] = data.email;
            }

            if (!!foundUserRegistration.email_verified !== data.email_verified) {
              dataToBeUpdated['email_verified'] = data.email_verified;
            }

            if (foundUserRegistration.social !== data.social) {
              dataToBeUpdated['social'] = data.social;
            }

            if (!Object.keys(dataToBeUpdated).length) {
              return foundUserRegistration;
            }

            return userRegistrationsApi
              .updateUserRegistration(foundUserRegistration.id, {
                firstname: data.firstname ? data.firstname : foundUserRegistration.firstname,
                lastname: data.lastname ? data.lastname : foundUserRegistration.lastname,
                email: data.email,
                email_verified: data.email_verified,
                social: data.social,
              }, accessToken)
              .then(res => res.json());
          }

          return userRegistrationsApi.createUserRegistration(data, accessToken).then(res => res.json());
        });
    })
      .then(data => {
        userRegistrationData = userRegistrationsModel.apiToStore(data);
        setUserRegistrationData(userRegistrationData);

        if (!userRegistrationData.zip_code) {
          return false;
        }

        return zipCodesApi
          .getZipCode(userRegistrationData.zip_code, userRegistration.accessToken)
          .then(res => res.json())
          .then(res => res.data);
      })
      .then(zipCode => {
        const showKeepInTouchStep = zipCode ? !zipCode.sites.length : false;
        let activeStep = 1;
        
        // check user provided firstname, lastname and zip code (so if 1st step is valid)
        if (userRegistrationData.firstname && userRegistrationData.lastname && userRegistrationData.zip_code) {
          activeStep = 2;

          if (
            showKeepInTouchStep &&
            userRegistrationData.newsletter !== null &&
            userRegistrationData.notify_me !== null &&
            userRegistrationData.volunteer !== null
          ) {
            activeStep = 3;
          }

          if (
            userRegistrationData.phone_step_passed ||
            (userRegistrationData.phone && userRegistrationData.phone_verified)
          ) {
            activeStep = showKeepInTouchStep ? 4 : 3;
          }
        }

        if (showKeepInTouchStep) {
          this.addKeepInTouchStep();
        }

        if (!this.isSocial()) {
          steps
            .filter(step => step.specifyLocationStep === true)
            .map(step => (step.name = formatMessage({ id: 'complete-rescuer-registration.steps.name-and-location' })));
        }

        this.setState({
          componentLoading: false,
          firstName: userRegistrationData.firstname ? userRegistrationData.firstname : '',
          lastName: userRegistrationData.lastname ? userRegistrationData.lastname : '',
          zipCode: userRegistrationData.zip_code,
          zipCodeCity: zipCode ? zipCode.city : '',
          zipCodeState: zipCode ? zipCode.state : '',
          zipCodeSites: zipCode ? zipCode.sites : [],
          keepInTouch: {
            newsletter: userRegistrationData.newsletter !== null ? userRegistrationData.newsletter : true,
            notifyMe: userRegistrationData.notify_me !== null ? userRegistrationData.notify_me : true,
            volunteer: userRegistrationData.volunteer !== null ? userRegistrationData.volunteer : false,
          },
          phoneNumber: userRegistrationData.phone !== null ? userRegistrationData.phone : '',
          phoneNumberInput: userRegistrationData.phone !== null ? formatPhoneNumber(userRegistrationData.phone) : '',
          phoneNumberVerified: userRegistrationData.phone_verified !== null,
          phoneNumberVerificationPending:
            userRegistrationData.phone !== null && userRegistrationData.phone_verified === null,
          showKeepInTouchStep,
          activeStep,
          termsAccepted: userRegistrationData.tos_accepted !== null,
        });
      })
      .catch(err => this.formErrorHandler(err));
  };

  handleNext = () => {
    const { activeStep, showKeepInTouchStep } = this.state;

    this.setState({
      loading: true,
    });

    switch (activeStep) {
      case 1:
        this.handleNextZipCode();

        break;
      case 2:
        if (showKeepInTouchStep) {
          this.validateKeepInTouchStep();
        } else {
          this.validatePhoneNumberStep();
        }

        break;
      case 3:
        if (showKeepInTouchStep) {
          this.validatePhoneNumberStep();
        } else {
          this.validateCompleteRegistrationStep();
        }

        break;
      case 4:
        if (showKeepInTouchStep) {
          this.validateCompleteRegistrationStep();
        }

        break;
      default:
        //

        break;
    }
  };

  handleNextZipCode = () => {
    const { userRegistration, setUserRegistrationData } = this.props;
    const { formatMessage } = this.props.intl;
    const { activeStep, firstName, lastName, address, address2, city, state, zip, zipCode, zipCodeSites } = this.state;
    const showKeepInTouchStep = !zipCodeSites.length;
    const isSocial = this.isSocial();

    Bluebird.try(() => {
      if (isSocial) {
        if (zipCode === userRegistration.data.zip_code) {
          return false;
        }
      } else {
        if (
          firstName === userRegistration.data.firstname &&
          lastName === userRegistration.data.lastname &&
          zipCode === userRegistration.data.zip_code &&
          address === userRegistration.data.address &&
          city === userRegistration.data.city &&
          state === userRegistration.data.state
        ) {
          return false;
        }
      }

      let userData = {
        zip_code: zipCode,
      };

      if (!isSocial) {
        userData = {
          ...userData,
          ...{
            firstname: firstName,
            lastname: lastName,
            locations: [
              {
                address,
                address2,
                city,
                state,
                zip,
              },
            ],
          },
        };
      }

      return userRegistrationsApi
        .updateUserRegistration(userRegistration.data.id, userData, userRegistration.accessToken)
        .then(res => res.json())
        .then(userRegistrationData => setUserRegistrationData(userRegistrationsModel.apiToStore(userRegistrationData)));
    }).then(() => {
      if (showKeepInTouchStep) {
        snackbarHelper.info(
          formatMessage({ id: 'complete-rescuer-registration.snackbarHelper.not-in-area-yet' }),
          10000
        );

        if (!this.isKeepInTouchStepAlreadyIncluded()) {
          this.addKeepInTouchStep();
        }
      } else {
        this.removeKeepInTouchStep();
      }

      this.setState({
        loading: false,
        zipCodeError: false,
        showKeepInTouchStep,
      });

      this.setActiveStep(activeStep + 1);

      snackbarHelper.success(
        formatMessage({ id: 'complete-rescuer-registration.snackbarHelper.zip-codes-settings-saved' })
      );
    });
  };

  onResendEmailClick = () => {
    const { resendEmail, userRegistration } = this.props;

    return Bluebird
      .try(() => {
        this.setState({
          isResendEmailLoading: true,
        });

        return resendEmail(
          userRegistration.data.id,
          {
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
          },
          userRegistration.accessToken
        );
      })
      .then(() => this.setState({
        showResendEmailDialog: true,
      }))
      .catch(err => this.formErrorHandler(err))
      .finally(() => this.setState({
        isResendEmailLoading: false,
      }));
  };

  handleSkipPhoneNumberStep = () => {
    const { userRegistration } = this.props;
    const { activeStep } = this.state;

    this.setState({
      phoneNumberInput: formatPhoneNumber(userRegistration.data.phone),
      phoneNumberVerificationPending: false,
      phoneNumberError: false,
      phoneNumberErrorMessage: '',
    }, () => this.setActiveStep(activeStep + 1));
  };

  handleBack = () => {
    const { activeStep } = this.state;

    this.setActiveStep(activeStep - 1);
  };

  handleZipCodeChange = event => {
    this.setState(
      {
        zipCode: getZipCodeFromMask(event.target.value),
      },
      () => {
        this.validateZipCodeStep();
      }
    );
  };

  handleFirstNameChange = event => {
    this.setState(
      {
        firstName: event.target.value.trim(),
      },
      () => {
        this.validateZipCodeStep();
      }
    );
  };

  handleLastNameChange = event => {
    this.setState(
      {
        lastName: event.target.value.trim(),
      },
      () => {
        this.validateZipCodeStep();
      }
    );
  };

  handleAddressChange = data => {
    const addressValues = data.address_components.reduce((prev, curr) => {
      if (curr.types && curr.types.includes('street_number')) {
        prev.street_number = curr.long_name;
      }
      if (curr.types && curr.types.includes('route')) {
        prev.route = curr.long_name;
      }
      if (curr.types && curr.types.includes('locality')) {
        prev.locality = curr.long_name;
      }
      if (curr.types && curr.types.includes('administrative_area_level_1')) {
        prev.state = curr.short_name;
      }
      if (curr.types && curr.types.includes('postal_code')) {
        prev.zip_code = curr.short_name;
      }

      return prev;
    }, {});
    this.setState(
      {
        address: `${addressValues.street_number ? addressValues.street_number : ''} ${
          addressValues.route ? addressValues.route : ''
        }`,
        city: addressValues.locality || '',
        state: addressValues.state || '',
        zip: addressValues.zip_code || '',
      },
      () => {
        this.validateZipCodeStep();
      }
    );
  };

  handleKeepInTouchChange = event => {
    const { keepInTouch } = this.state;

    this.setState({
      keepInTouch: { ...keepInTouch, ...{ [event.target.value]: event.target.checked } },
    });
  };

  handleTermsAcceptedChange = event => {
    const { userRegistration, setUserRegistrationData } = this.props;
    const { data } = userRegistration;

    this.setState({
      loading: true,
      showResendEmailDialog: !data.email_verified,
    });

    const tosAccepted = event.target.checked;

    return Bluebird.try(() =>
      userRegistrationsApi.updateUserRegistration(
        userRegistration.data.id,
        {
          tos_accepted: tosAccepted,
        },
        userRegistration.accessToken
      )
    )
      .then(res => res.json())
      .then(userRegistrationData => setUserRegistrationData(userRegistrationsModel.apiToStore(userRegistrationData)))
      .then(() =>
        this.setState({
          loading: false,
          termsAccepted: tosAccepted,
        })
      )
      .catch(err => this.formErrorHandler(err))
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  };

  handlePhoneNumberChange = event => {
    const { formatMessage } = this.props.intl;
    // do not validate of field focus event
    if(event.type === 'focus') {
      return;
    }

    const isPhoneValid = isPhoneMaskValid(event.target.value);

    this.setState({
      phoneNumberInput: event.target.value,
      phoneNumberVerificationPending: getPhoneNumberFromMask(event.target.value).length,
      phoneNumberError: !isPhoneValid,
      phoneNumberErrorMessage: !isPhoneValid
        ? formatMessage({ id: 'complete-rescuer-registration.inputs-labels.phone-number.error-text' })
        : '',
    });
  };

  handleCodeNotReceived = () => {
    const { userRegistration } = this.props;

    this.setState({ verificationDialogLoading: true }, () => Bluebird
      .try(() => userRegistrationsApi.sendSmsVerifyRequest(userRegistration.data.id, userRegistration.accessToken))
      .then(() => this.setState({
        confirmationCodeError: false,
        verificationDialogLoading: false,
      }))
    );
  };

  handleClose = () => {
    this.setState({
      showPhoneNumberVerificationDialog: false,
    });
  };

  handleVerify = confirmationCode => {
    const { userRegistration, setUserRegistrationData } = this.props;
    const { formatMessage } = this.props.intl;

    this.setState({
      verificationDialogLoading: true,
    });

    userRegistrationsApi
      .updateUserRegistration(
        userRegistration.data.id,
        {
          phone_verification_code: confirmationCode,
        },
        userRegistration.accessToken
      )
      .then(res => res.json())
      .then(userRegistrationData => {
        setUserRegistrationData(userRegistrationsModel.apiToStore(userRegistrationData));

        // the only way to check if provided confirmation code was valid or not...
        if (userRegistrationData.phone_verified === null) {
          this.setState({
            verificationDialogLoading: false,
            confirmationCodeError: true,
          });

          return false;
        }

        snackbarHelper.success(formatMessage({ id: 'complete-rescuer-registration.snackbarHelper.phone-number-settings-saved' }));

        this.setState({
          phoneNumberError: false,
          phoneNumberErrorMessage: '',
          phoneNumberVerified: true,
          phoneNumberVerificationPending: false,
          verificationDialogLoading: false,
          confirmationCodeError: false,
          showPhoneNumberVerificationDialog: false,
        });
      })
      .catch(err => this.formErrorHandler(err))
      .finally(() => {
        this.setState({
          verificationDialogLoading: false,
        })
      })
  };

  addKeepInTouchStep = () => {
    const { steps, keepInTouchStep } = this.state;

    this.setState({
      steps: [steps[0], steps[1], keepInTouchStep, steps[2], steps[3]],
    });
  };

  removeKeepInTouchStep = () => {
    const { steps, keepInTouchStep } = this.state;

    this.setState({
      steps: steps.filter(step => step.name !== keepInTouchStep.name),
    });
  };

  validateZipCodeStep = () => {
    const { userRegistration } = this.props;
    const { zipCode } = this.state;

    // there is no need for us to call API if we don't have 5 digits
    if (zipCode.length < 5) {
      this.setState({
        zipCodeCity: '',
        zipCodeState: '',
        zipCodeSuccess: false,
      });
      return false;
    }

    zipCodesApi
      .getZipCode(zipCode, userRegistration.accessToken)
      .then(res => res.json())
      .then(res => {
        const { city, state, sites } = res.data;

        this.setState({
          loading: false,
          zipCodeError: false,
          zipCodeSuccess: true,
          zipCodeCity: city,
          zipCodeState: state,
          zipCodeSites: sites,
        });
      })
      .catch(err => {
        if (err.code === errorMessages.ERR_ZIP_CODE_NOT_FOUND.code) {
          this.setState({
            loading: false,
            zipCodeError: true,
          });
        }
      });
  };

  validateKeepInTouchStep = () => {
    const { userRegistration, setUserRegistrationData } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      activeStep,
      keepInTouch: { newsletter, notifyMe, volunteer },
    } = this.state;
    const promise =
      userRegistration.data.newsletter === newsletter &&
      userRegistration.data.notify_me === notifyMe &&
      userRegistration.data.volunteer === volunteer
        ? Promise.resolve()
        : userRegistrationsApi
            .updateUserRegistration(
              userRegistration.data.id,
              {
                newsletter: newsletter,
                notify_me: notifyMe,
                volunteer: volunteer,
              },
              userRegistration.accessToken
            )
            .then(res => res.json())
            .then(userRegistrationData =>
              setUserRegistrationData(userRegistrationsModel.apiToStore(userRegistrationData))
            );

    promise.then(() => {
      snackbarHelper.success(
        formatMessage({ id: 'complete-rescuer-registration.snackbarHelper.keep-in-touch-settings-saved' })
      );

      this.setState({
        loading: false,
      });

      this.setActiveStep(activeStep + 1);
    });
  };

  validatePhoneNumberStep = () => {
    const { userRegistration, setUserRegistrationData } = this.props;
    const { formatMessage } = this.props.intl;
    const { phoneNumberInput, phoneNumberVerified, activeStep } = this.state;

    if (phoneNumberInput.length && !phoneNumberVerified) {

      if (!isPhoneMaskValid(phoneNumberInput)) {
        this.setState({
          loading: false,
          phoneNumberError: true,
          phoneNumberErrorMessage: formatMessage({
            id: 'complete-rescuer-registration.inputs-labels.phone-number.error-text',
          }),
        });

        return false;
      }

      userRegistrationsApi
        .updateUserRegistration(
          userRegistration.data.id,
          {
            phone: phoneNumberInput,
          },
          userRegistration.accessToken
        )
        .then(() => {
          this.setState({
            phoneNumber: phoneNumberInput,
          });

          return userRegistrationsApi.sendSmsVerifyRequest(userRegistration.data.id, userRegistration.accessToken);
        })
        .then(() => {
          snackbarHelper.info(
            formatMessage({ id: 'complete-rescuer-registration.snackbarHelper.verification-code-sent' })
          );

          this.setState({
            loading: false,
            phoneNumberError: false,
            phoneNumberErrorMessage: '',
            showPhoneNumberVerificationDialog: true,
          });
        })
        .catch(err => this.formErrorHandler(err))
        .finally(() =>
          this.setState({
            loading: false,
          })
        );
    } else {
      Bluebird.try(() => {
        if (userRegistration.data.phone_step_passed !== null) {
          return false;
        }

        return Bluebird.try(() =>
          userRegistrationsApi.updateUserRegistration(
            userRegistration.data.id,
            {
              phone_step_passed: true,
            },
            userRegistration.accessToken
          )
        )
          .then(res => res.json())
          .then(userRegistrationData =>
            setUserRegistrationData(userRegistrationsModel.apiToStore(userRegistrationData))
          );
      }).then(() => {
        this.setState({
          loading: false,
        });

        this.setActiveStep(activeStep + 1);
      });
    }
  };

  validateCompleteRegistrationStep = () => {
    const { userRegistration, history } = this.props;

    this.setState({
      loading: true,
    });

    return Bluebird
      .try(() => authService.createProfile(userRegistration, history))
      .catch(err => this.formErrorHandler(err))
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  };

  setActiveStep = step => {
    const { phoneNumberInput, phoneNumberVerified } = this.state;

    this.setState({
      activeStep: step,
      phoneNumberVerificationPending:
        this.isPhoneStep(step) && getPhoneNumberFromMask(phoneNumberInput).length && !phoneNumberVerified,
    });
  };

  hasAnyKeepInTouchChecked = () => {
    const { keepInTouch } = this.state;

    return Object.values(keepInTouch).filter(option => !!option).length;
  };

  isKeepInTouchStepAlreadyIncluded = () => {
    const {
      steps,
      keepInTouchStep: { name },
    } = this.state;

    return steps.some(step => step.name === name);
  };

  isButtonDisabled = () => {
    const { userRegistration } = this.props;
    const { activeStep, firstName, lastName, zipCode, zipCodeError, termsAccepted, phoneNumberInput } = this.state;

    if (
      activeStep === 1 &&
      (zipCode.length < 5 || (zipCode.length && zipCodeError) || !firstName.length || !lastName.length)
    ) {
      return true;
    }

    let isButtonDisabled = false;

    if (
      this.isPhoneStepCurrentlyVisible() &&
      isPhoneMaskValid(phoneNumberInput) &&
      userRegistration.data.phone_verified === null
    ) {
      return false
    }

    if (
      this.isPhoneStepCurrentlyVisible() &&
      getPhoneNumberFromMask(phoneNumberInput).length > 0 &&
      !isPhoneMaskValid(phoneNumberInput)
    ) {
      return true;
    }

    if (this.isActiveStepTheLastStep()) {
      if (!termsAccepted || (userRegistration.data && userRegistration.data.email_verified === null)) {
        isButtonDisabled = true;
      }
    }

    return isButtonDisabled;
  };

  isPhoneStepCurrentlyVisible = () => {
    const { activeStep, showKeepInTouchStep } = this.state;

    return (showKeepInTouchStep && activeStep === 3) || (!showKeepInTouchStep && activeStep === 2);
  };

  isPhoneStep = step => {
    const { steps } = this.state;

    return steps[step].phoneStep;
  };

  isActiveStepTheLastStep = () => {
    const { activeStep, steps } = this.state;

    return activeStep === steps.length - 1;
  };

  isSocial = () => {
    const { userRegistration } = this.props;

    return userRegistration.data && userRegistration.data.social;
  };

  getStepContent = stepIndex => {
    const { userRegistration, width } = this.props;
    const {
      firstName,
      lastName,
      zipCode,
      zipCodeError,
      zipCodeSuccess,
      zipCodeCity,
      zipCodeState,
      keepInTouch: { newsletter, notifyMe, volunteer },
      phoneNumberInput,
      phoneNumberError,
      phoneNumberErrorMessage,
      phoneNumberVerified,
      termsAccepted,
      showKeepInTouchStep,
      isResendEmailButtonDisabled,
      isResendEmailLoading,
    } = this.state;
    const { formatMessage } = this.props.intl;
    const isSocial = this.isSocial();
    const isMobile = width === 'xs';

    const keepInTouchContent = (
      <React.Fragment>
        <Typography variant="h6" component="h2" style={{ fontWeight: 400, marginTop: 32 }}>
          {formatMessage({ id: 'complete-rescuer-registration.keep-in-touch.title' })}
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p" style={{ fontWeight: 400 }}>
          {formatMessage({ id: 'complete-rescuer-registration.keep-in-touch.subtitle' })}
        </Typography>

        <FormControl fullWidth style={{ marginTop: 15 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletter}
                  onChange={this.handleKeepInTouchChange}
                  value="newsletter"
                  style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                />
              }
              label={formatMessage({ id: 'complete-rescuer-registration.keep-in-touch.inputs.newsletter' })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={notifyMe}
                  onChange={this.handleKeepInTouchChange}
                  value="notifyMe"
                  style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                />
              }
              label={formatMessage({ id: 'complete-rescuer-registration.keep-in-touch.inputs.notify-me' })}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={volunteer}
                  onChange={this.handleKeepInTouchChange}
                  value="volunteer"
                  style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                />
              }
              label={formatMessage({ id: 'complete-rescuer-registration.keep-in-touch.inputs.volunteer' })}
            />
          </FormGroup>
        </FormControl>
      </React.Fragment>
    );
    const verifyPhoneContent = (
      <div>
        <InputMask
          mask="999-999-9999"
          value={phoneNumberInput}
          id="phone-input"
          onChange={this.handlePhoneNumberChange}
          disabled={phoneNumberVerified}
        >
          <TextField
            fullWidth
            label={formatMessage({ id: 'complete-rescuer-registration.inputs-labels.phone-number' })}
            margin="normal"
            style={{ marginTop: 0, marginBottom: 0 }}
            error={phoneNumberError}
          />
        </InputMask>

        {phoneNumberVerified && (
          <FormHelperText id="my-helper-text" style={{ color: 'green' }}>
            <Check fontSize="small" style={{ fontSize: 12 }} /> Successfully verified.
          </FormHelperText>
        )}

        {phoneNumberError && (
          <FormHelperText id="phone-input-error" error>
            {phoneNumberErrorMessage}
          </FormHelperText>
        )}
      </div>
    );
    const completeRegistrationContent = (
      <>
        <FormControl fullWidth>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={this.handleTermsAcceptedChange}
                  value="termsAccepted"
                  style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                  data-testid="tos-checkbox"
                />
              }
              label={(
                <>
                  {formatMessage({ id: 'complete-rescuer-registration.terms-of-service.i-agree' })}&nbsp;
                  <Link
                    target={isInPWAView ? '_self' : '_blank'}
                    to={generatePath(routes.termsOfService, { type: 'rescuer' })}
                  >
                    {formatMessage({ id: 'complete-rescuer-registration.terms-of-service.text' })}
                  </Link>
                  .
                </>
              )}
            />
          </FormGroup>
        </FormControl>

        {userRegistration.data && userRegistration.data.email_verified === null && (
          <>
            <Typography
              variant="body1"
              style={{ color: Colors.errorText, fontWeight: 400, marginTop: !isMobile ? 28 : 14 }}
              data-testid="please-verify-text"
            >
              {formatMessage({ id: 'complete-rescuer-registration.please-verify-text' })}
            </Typography>

            <ButtonWithLoading
              disabled={isResendEmailButtonDisabled}
              fullWidth={isMobile}
              onClick={this.onResendEmailClick}
              color="primary"
              variant="outlined"
              isLoading={isResendEmailLoading}
              data-testid="resend-verification-email-button"
            >
                {formatMessage({ id: 'complete-rescuer-registration.buttons.resend-email' })}
            </ButtonWithLoading>
          </>
        )}
      </>
    );

    switch (stepIndex) {
      case 0:
        return (
          <React.Fragment>
            <Typography gutterBottom variant="h6" component="h2" style={{ fontWeight: 400 }}>
              Hello, {userRegistration.data.firstname}!
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p" style={{ fontWeight: 400 }}>
              An e-mail with confirmation link has been sent to &nbsp;{' '}
              <span style={{ fontWeight: 'bold' }}>{userRegistration.data.email}</span>. Please verify your e-mail
              address by clicking on a link.
            </Typography>
          </React.Fragment>
        );
      case 1:
        return (
          <>
            <>
              <TextField
                fullWidth
                id="first-name-input"
                label={formatMessage({ id: 'complete-rescuer-registration.inputs-labels.first-name' })}
                value={firstName}
                onChange={this.handleFirstNameChange}
                margin="normal"
                style={{ marginTop: 9, marginBottom: 0 }}
                autoFocus={!!!isSocial}
              />

              <TextField
                fullWidth
                id="last-name-input"
                label={formatMessage({ id: 'complete-rescuer-registration.inputs-labels.last-name' })}
                value={lastName}
                onChange={this.handleLastNameChange}
                margin="normal"
                style={{ marginTop: 9, marginBottom: 0 }}
              />
            </>
            <Box pt={3} display="flex" alignItems="center">
              <Typography style={{ marginRight: '1rem' }} variant="h6">
                Address
              </Typography>
              <Tooltip arrow placement="top" title="We use this to match you with a Food Rescue site in your area!">
                <HelpIcon htmlColor={Colors.blue.main} />
              </Tooltip>
            </Box>

            <AddressAutocomplete onSelect={this.handleAddressChange} />

            <InputMask
              mask="99999"
              value={zipCode}
              id="zip-code-input"
              autoFocus={!!isSocial}
              onChange={this.handleZipCodeChange}
            >
              <TextField
                fullWidth
                label={formatMessage({ id: 'complete-rescuer-registration.inputs-labels.zip-code' })}
                margin="normal"
                style={{ marginTop: 9, marginBottom: 0 }}
                inputProps={{
                  autoComplete: 'chrome-off',
              }}
                error={zipCodeError}
              />
            </InputMask>

            {(zipCodeError || zipCodeSuccess) && (
              <FormHelperText id="zip-code-helper-text" error={zipCodeError}>
                {zipCodeError
                  ? formatMessage({ id: 'complete-rescuer-registration.inputs-labels.zip-code.error-text' })
                  : `${zipCodeCity}, ${zipCodeState}`}
              </FormHelperText>
            )}
          </>
        );
      case 2:
        return showKeepInTouchStep ? keepInTouchContent : verifyPhoneContent;
      case 3:
        return showKeepInTouchStep ? verifyPhoneContent : completeRegistrationContent;
      case 4:
        return showKeepInTouchStep ? completeRegistrationContent : null;
      default:
        return 'Uknown stepIndex';
    }
  };

  renderNextButton = () => {
    const { formatMessage } = this.props.intl;
    const { activeStep, phoneNumberInput, phoneNumberVerificationPending, showKeepInTouchStep } = this.state;

    return (
      <>
        {this.isPhoneStepCurrentlyVisible() && !!phoneNumberVerificationPending && (
          <Button onClick={this.handleSkipPhoneNumberStep} style={{ marginRight: 5 }} data-testid="skip-button">
            {formatMessage({ id: 'complete-rescuer-registration.buttons.skip' })} <ArrowForward fontSize="small" />
          </Button>
        )}

        <Button
          onClick={this.handleNext}
          disabled={this.isButtonDisabled()}
          variant="contained"
          color="primary"
          data-testid="next-button"
        >
          {this.isActiveStepTheLastStep() && (
            <>
              <Check fontSize="small" />
              {formatMessage({ id: 'complete-rescuer-registration.buttons.complete-registration' })}
            </>
          )}

          {!this.isActiveStepTheLastStep() && (
            <>
              {this.isPhoneStepCurrentlyVisible() && !!phoneNumberVerificationPending && (
                <>
                  <Phone fontSize="small" /> {formatMessage({ id: 'complete-rescuer-registration.buttons.verify' })}
                </>
              )}

              {!(this.isPhoneStepCurrentlyVisible() && !!phoneNumberVerificationPending) && (
                <>
                  {(showKeepInTouchStep && activeStep === 2 && !this.hasAnyKeepInTouchChecked()) ||
                  (showKeepInTouchStep && activeStep === 3 && !phoneNumberInput.length) ||
                  (!showKeepInTouchStep && activeStep === 2 && !phoneNumberInput.length)
                    ? formatMessage({ id: 'complete-rescuer-registration.buttons.skip' })
                    : formatMessage({ id: 'complete-rescuer-registration.buttons.next' })}

                  <ArrowForward fontSize="small" />
                </>
              )}
            </>
          )}
        </Button>
      </>
    );
  };

  renderStepsView = () => {
    const { userRegistration, width } = this.props;
    const {
      activeStep,
      firstName,
      lastName,
      zipCode,
      zipCodeCity,
      zipCodeState,
      phoneNumber,
      zipCodeSites,
    } = this.state;
    const isSocial = this.isSocial();
    const { formatMessage } = this.props.intl;
    const sitesInformation = !zipCodeSites.length
      ? formatMessage({ id: 'complete-rescuer-registration.desktop-summary-card.site-not-assigned' })
      : `${formatMessage({ id: 'complete-rescuer-registration.desktop-summary-card.site' })}${
          zipCodeSites.length > 1 ? 's' : ''
        }: ${zipCodeSites.map((row) => row.name).join(', ')}`;
    const isMobile = width === 'xs';

    return (
      <>
        {!isMobile && (
          <>
            <Paper style={{ padding: '12px 24px' }} data-testid="collected-information-paper">
              <List dense style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                  <ListItemText
                    primary={`${formatMessage({ id: 'complete-rescuer-registration.desktop-summary-card.email' })}: ${
                      userRegistration.data ? userRegistration.data.email : ''
                    }`}
                  />
                </ListItem>

                {activeStep > 1 && !isSocial && (
                  <>
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                      <ListItemText
                        primary={`${formatMessage({
                          id: 'complete-rescuer-registration.inputs-labels.first-name',
                        })}: ${firstName}`}
                      />
                    </ListItem>

                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                      <ListItemText
                        primary={`${formatMessage({
                          id: 'complete-rescuer-registration.inputs-labels.last-name',
                        })}: ${lastName}`}
                      />
                    </ListItem>
                  </>
                )}

                {activeStep > 1 && zipCode && (
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                    <ListItemText
                      primary={`${formatMessage({
                        id: 'complete-rescuer-registration.desktop-summary-card.location',
                      })}: ${zipCodeCity}, ${zipCodeState} ${zipCode}`}
                    />
                  </ListItem>
                )}

                {activeStep > 1 && (
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                    <ListItemText primary={sitesInformation} />
                  </ListItem>
                )}

                {this.isActiveStepTheLastStep() &&
                  phoneNumber &&
                  userRegistration.data &&
                  userRegistration.data.phone_verified && (
                    <ListItem style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
                      <ListItemText
                        primary={`${formatMessage({
                          id: 'complete-rescuer-registration.desktop-summary-card.phone-number',
                        })}: ${phoneNumber}`}
                      />
                    </ListItem>
                  )}
              </List>
            </Paper>

            <Divider
              variant="middle"
              style={{
                marginTop: 24,
                marginRight: 0,
                marginBottom: 24,
                marginLeft: 0,
              }}
            />
          </>
        )}

        <div data-testid="step-content-div">
          {this.getStepContent(activeStep)}

          {activeStep > 0 && (
            <div style={{ marginTop: 35, display: 'flex', justifyContent: 'flex-end' }}>
              {activeStep !== 1 && (
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  style={{ marginRight: 5 }}
                  data-testid="back-button"
                >
                  <ArrowBack fontSize="small" />
                  {formatMessage({ id: 'complete-rescuer-registration.buttons.back' })}
                </Button>
              )}

              {this.renderNextButton()}
            </div>
          )}
        </div>
      </>
    );
  };

  render() {
    const { userRegistration, width, history } = this.props;
    const { formatMessage } = this.props.intl;
    const {
      steps,
      activeStep,
      componentLoading,
      loading,
      verificationDialogLoading,
      phoneNumber,
      showPhoneNumberVerificationDialog,
      confirmationCodeError,
      showResendEmailDialog,
    } = this.state;
    const isMobile = width === 'xs';

    return (
      <>
        {showResendEmailDialog && (
          <VerificationEmailSentConfirmationDialog
            onCloseDialog={() => this.setState({ showResendEmailDialog: false })}
            onGotItButtonClick={() => history.push(routes.login)}
          />
        )}
        <Grid container justify="center" style={{ marginTop: !isMobile ? 25 : 0 }}>
          <Grid item md={5} sm={9} xs={12}>
            <OverlayLoader isLoading={loading}>
              <Paper>
                {componentLoading ? (
                  <div style={{ padding: 24 }}>
                    <svg width="300" height="100">
                      <rect x="0" y="15" rx="4" ry="4" width="117" height="6" fill="#ecebeb" />
                      <rect x="0" y="30" rx="3" ry="3" width="85" height="6" fill="#ecebeb" />
                      <rect x="0" y="80" rx="3" ry="3" width="350" height="6" fill="#ecebeb" />
                    </svg>
                  </div>
                ) : (
                  <>
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel={!isMobile}
                      style={ !isMobile ? { paddingBottom: 0 } : { padding: '12px 12px 0 12px' } }
                      data-testid="steps-container"
                      orientation={isMobile ? 'vertical' : 'horizontal'}
                      data-active-step={activeStep}
                    >
                      {steps.map((step, index) => {
                        const labelProps = {};
                        const stepProps = {};

                        if (step.optional) {
                          labelProps.optional = <Typography variant="caption">{formatMessage({ id: 'complete-rescuer-registration.steps.optional' })}</Typography>;
                        }

                        if (index === 0 && userRegistration.data && !userRegistration.data.email_verified) {
                          stepProps.completed = false;
                        }

                        return (
                          <Step key={step.name} {...stepProps}>
                            <StepLabel {...labelProps} style={{ textAlign: isMobile ? 'left' : 'center' }}>
                              {step.name}
                            </StepLabel>
                            {isMobile && (
                              <StepContent>
                                <div style={{ padding: '20px 0' }}>{this.renderStepsView()}</div>
                              </StepContent>
                            )}
                          </Step>
                        );
                      })}
                    </Stepper>

                    {!isMobile && (
                      <>
                        <Divider
                          variant="middle"
                          style={{
                            marginTop: 24,
                            marginRight: 24,
                            marginBottom: 24,
                            marginLeft: 24,
                          }}
                        />
                        <div style={{ padding: '24px' }}>{this.renderStepsView()}</div>
                      </>
                    )}
                  </>
                )}
              </Paper>
            </OverlayLoader>
          </Grid>
        </Grid>

        <PhoneConfirmationCodeDialog
          phoneNo={formatPhoneNumber(phoneNumber)}
          handleCodeNotReceived={this.handleCodeNotReceived}
          open={showPhoneNumberVerificationDialog}
          handleClose={this.handleClose}
          handleVerify={this.handleVerify}
          loading={verificationDialogLoading}
          confirmationCodeError={confirmationCodeError}
        />
      </>
    );
  }
}

const mapStateToProps = ({ app: { userRegistration } }) => ({
  userRegistration,
});
const mapDispatchToProps = dispatch => ({
  resendEmail: (userRegistrationId, data, accessTokenOverride) => dispatch(
    userRegistrationsActions.resendVerificationEmail(userRegistrationId, data, accessTokenOverride)
  ),
  setUserRegistrationData: data => dispatch(authActions.setUserRegistrationData(data)),
});

export default compose(
  withRouter,
  withWidth({ initialWidth: 'lg' }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CompleteRescuerRegistration);
