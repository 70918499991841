import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { TableFilterList } from 'mui-datatables';
import _, { omit } from 'lodash';
import {
  Checkbox,
  FormControlLabel,
  Box,
  TextField,
  makeStyles,
  Button,
  TableRow,
  TableCell,
  Typography,
  TableFooter,
  Tooltip,
} from '@material-ui/core';
import {
  PlayCircleOutline as PlayCircleOutlineIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
} from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { confirmAlert } from 'react-confirm-alert';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import { formatCsvFileName } from '../helpers/formatters';
import { getRescueEstimatedLbs, getRescuePickupLocationFullName } from '../helpers/RescuesHelper';
import ConfirmationDialog from './ConfirmationDialog';
import { hasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import useActiveUser from '../hooks/useActiveUser';
import { Roles } from '../models/roles';
import UndoButton from './UndoButton';
import { Colors } from '../assets/theme/Colors';
import useSystemSettings from '../hooks/useSystemSettings';
import { LBS_PER_MEAL } from '../models/systemSettings';

const useStyles = makeStyles(() => ({
  editableField: {
    backgroundColor: 'white',
  },
  editedField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `3px solid ${Colors.bulkEdit.main}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${Colors.bulkEdit.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `3px solid ${Colors.bulkEdit.main}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.bulkEdit.main,
    },
    marginRight: '1rem',
    backgroundColor: 'white',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
  },
  editedRow: {
    backgroundColor: Colors.bulkEdit.light,
    '&.MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: Colors.bulkEdit.highlight,
    },
  },
}));

const CustomFilterList = ({ filterList, filterUpdate, ...restProps }, editMode) => {
  const { options, columnNames } = restProps;

  const verificationStatusColumnIndex = columnNames.findIndex(col => col.name === 'verification_status');
  const verificationStatusColumn = columnNames[verificationStatusColumnIndex];

  const updatedList = [...filterList];
  const verificationChecked = filterList[verificationStatusColumnIndex].length > 0;
  updatedList[verificationStatusColumnIndex] = [];

  return (
    <Box display="flex" flexDirection="row">
      <TableFilterList {...restProps} filterList={updatedList} filterUpdate={filterUpdate} />

      {options.customFilters && options.customFilters.showPendingRegistrationFilter && (
        <Box marginTop="5px">
          <FormControlLabel
            control={
              <Checkbox
                disabled={editMode}
                checked={verificationChecked}
                onChange={() => {
                  filterUpdate(
                    verificationStatusColumnIndex,
                    'pending',
                    verificationStatusColumn.name,
                    verificationStatusColumn.filterType
                  );
                }}
              />
            }
            label="Pending Request(s)"
          />
        </Box>
      )}
    </Box>
  );
};

const customEditFooter = ({ handleEditClick, handleReviewClick, reviewButtonEnabled }) => (
  <TableFooter>
    <TableRow>
      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'nowrap',
          marginRight: '24px',
          minHeight: '64px',
        }}
      >
        <Typography variant="body2" style={{ marginRight: '1rem' }}>
          Exit Edit Mode to select a different page
        </Typography>
        <Button onClick={handleEditClick}>Exit Edit Mode</Button>
        <Button
          disabled={!reviewButtonEnabled}
          onClick={handleReviewClick}
          style={{ marginLeft: '1rem' }}
          variant="contained"
          color="primary"
        >
          Review and Save
        </Button>
      </TableCell>
    </TableRow>
  </TableFooter>
);

const DonationsTable = ({
  donations,
  tableId,
  onLocationClick,
  onEditClick,
  onReviewClick,
  customToolbar = () => {},
  customFilters = {
    showPendingRegistrationFilter: false,
  },
  editedDonations,
  setEditedDonations,
  handleReviewClick,
  editMode,
  setEditMode,
}) => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const rescueSizes = useSelector((state) => state.entities.rescueSizes);
  const lbsPerMeal = useSystemSettings(LBS_PER_MEAL);
  const rescueSizesArray = rescueSizes.sorted;
  const showEditRescueButton = hasAnyRoleInCurrentlySelectedSite(activeUser, [
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const handleEditClick = () => {
    if (!showEditRescueButton) {
      setEditMode(false);
      return setEditedDonations([]);
    }

    if (editedDonations?.length > 0) {
      return confirmAlert({
        message: 'Are you sure you want to exit without saving?',
        buttons: [
          {
            label: 'No',
            color: 'primary',
          },
          {
            label: 'Yes',
            color: 'primary',
            onClick: () => {
              setEditMode(!editMode);
              setEditedDonations([]);
            },
          },
        ],
        customUI: ({ message, onClose, buttons }) => (
          <ConfirmationDialog buttons={buttons} closeDialog={onClose} message={message} />
        ),
      });
    }

    setEditMode(!editMode);
    return setEditedDonations([]);
  };

  const onEditableFieldChange = (donationEdited, donation, data) => {
    if (!donationEdited) {
      return setEditedDonations([...editedDonations, { id: donation.id, ...data }]);
    }

    return setEditedDonations(editedDonations.map((d) => (d.id !== donation.id ? d : { ...donationEdited, ...data })));
  };

  const handleUndoClick = (donation, field, previousWeight = 0) => {
    const editedRescuesReduced = editedDonations.reduce((acc, curr) => {
      if (curr.id === donation.id) {
        let reducedDonation = omit(curr, [field]);

        if (field === 'status') {
          reducedDonation = omit(curr, ['pause_date', 'resume_date', [field]]);
        }

        if (field === 'lbs') {
          reducedDonation = omit(curr, ['estimated', [field]]);
        }

        if (Object.keys(reducedDonation).length === 1) {
          return acc;
        }

        acc.push(reducedDonation);

        return acc;
      };
      acc.push(curr);
      return acc;
    }, []);

    // Reset value for uncontrolled lbs input
    if (field === 'lbs') {
      const currentLbsInput = document.getElementById(donation.id);
      currentLbsInput.value = previousWeight;
    }

    setEditedDonations(editedRescuesReduced);
  };

  const renderStatusTooltip = (donation) => {
    if (donation?.isRequest) {
      return 'Cannot edit pending requests.';
    }

    if (donation?.frequency === 0) {
      return 'Cannot pause one time only donations.';
    }

    return '';
  };

  const frequencyCustomBodyRender = donation => {
    switch (donation.frequency) {
      case 0:
        return 'one-time';
      case 1:
        return donation.frequency_every === 1 ? 'weekly' : 'every other week';
      case 2:
        return 'monthly';
      default:
        return 'unknown';
    }
  };

  const startDateCustomBodyRender = value => (value ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null);

  const verificationStatusBodyRender = value => value && value !== 'approved' ? _.startCase(_.toLower(value)) : '-';

  const weightCustomBodyRender = (value, tableMeta) => {
    const donation = donations[getMuiTableDataIndex(tableMeta)];
    const rescueSize = rescueSizesArray.find((size) => size.id === donation?.rescue_size_id);
    const estimatedLBSValue = getRescueEstimatedLbs(rescueSize, lbsPerMeal);
    const donationWeight = value !== null ? value : estimatedLBSValue;
    if (editMode) {
      const donationEdited = editedDonations.find((d) => d.id === donation.id);

      return (
        <Box display="flex">
          <Tooltip title={donation.isRequest ? 'Cannot edit pending requests.' : ''}>
            <span>
              <TextField
                id={`${donation.id}`}
                className={donationEdited && 'lbs' in donationEdited ? classes.editedField : classes.editableField}
                style={{ width: 100 }}
                disabled={donation.isRequest}
                type="number"
                variant="outlined"
                size="small"
                defaultValue={donationEdited && 'lbs' in donationEdited ? donationEdited.lbs : donationWeight}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onBlur={(event) => {
                  onEditableFieldChange(donationEdited, donation, {
                    lbs: event.target.value,
                    estimated: donationWeight,
                  });
                }}
              />
            </span>
          </Tooltip>
          {donationEdited && 'lbs' in donationEdited && (
            <UndoButton handleClick={() => handleUndoClick(donationEdited, 'lbs', donationWeight)} />
          )}
        </Box>
      );
    }

    return donationWeight !== null && donationWeight !== undefined ? donationWeight : '-';
  };

  const statusCustomBodyRender = (value, tableMeta) => {
    if (editMode) {
      const donation = donations[getMuiTableDataIndex(tableMeta)];
      const donationEdited = editedDonations.find((d) => d.id === donation.id);

      return (
        <Box display="flex">
          {(!donationEdited && donation.status !== 'PAUSED') ||
          (donationEdited && donationEdited.status !== 'PAUSED') ? (
            <Tooltip title={renderStatusTooltip(donation)}>
              <span>
                <Button
                  disabled={donation.frequency === 0 || donation.isRequest}
                  color="secondary"
                  startIcon={<PauseCircleOutlineIcon />}
                  variant="contained"
                  onClick={() => {
                    onEditableFieldChange(donationEdited, donation, {
                      status: 'PAUSED',
                      pause_date: (moment().isBefore(donation.start_date) ? moment(donation.start_date) : moment()).format(
                        'YYYY-MM-DD'
                      ),
                    });
                  }}
                >
                  Pause now
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
            disabled={donation.frequency === 0}
              color="secondary"
              startIcon={<PlayCircleOutlineIcon />}
              variant="contained"
              onClick={() => {
                onEditableFieldChange(donationEdited, donation, {
                  status: 'CURRENT',
                  resume_date: (moment().isBefore(donation.pause_date) ? moment(donation.pause_date) : moment()).format(
                    'YYYY-MM-DD'
                  ),
                });
              }}
            >
              Resume now
            </Button>
          )}
          {donationEdited && 'status' in donationEdited && (
            <UndoButton handleClick={() => handleUndoClick(donationEdited, 'status', donation)} />
          )}
        </Box>
      )
    }
    return value || 'N/A';
  };

  return (
    <BaseMUIDataTable
      tableId={tableId}
      title={editMode ? 'Edit Mode' : 'Donations'}
      data={donations}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'location',
          label: 'Food Donor',
          options: {
            setCellProps: () => ({
              style: {
                cursor: 'pointer',
              },
            }),
            customBodyRender: (value, tableMeta) => {
              const donation = donations[getMuiTableDataIndex(tableMeta)];
              const foodDonorName = getRescuePickupLocationFullName(donation);

              return (
                <Link onClick={() => onLocationClick(donation.donor_id, donation.location_id)}>{foodDonorName}</Link>
              );
            },
          },
        },
        {
          name: 'donation',
          label: 'Description',
        },
        {
          name: 'frequency',
          label: 'Frequency',
          options: {
            customBodyRender: (value, tableMeta) => {
              const donation = donations[getMuiTableDataIndex(tableMeta)];

              return frequencyCustomBodyRender(donation);
            },
            customBodyRenderCSV: (value, tableMeta) => {
              const donation = donations[getMuiTableDataIndex(tableMeta)];

              return frequencyCustomBodyRender(donation);
            },
          },
        },
        {
          name: 'display_days',
          label: 'Day(s)',
          options: {
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'start_date',
          label: 'Start Date',
          options: {
            sort: true,
            customBodyRender: value => startDateCustomBodyRender(value),
            customBodyRenderCSV: value => startDateCustomBodyRender(value),
          },
        },
        {
          name: 'verification_status',
          label: 'Verification status',
          options: {
            filter: false,
            display: false,
            filterType: 'checkbox',
            customBodyRender: value => verificationStatusBodyRender(value),
            filterOptions: {
              logic: (status, filters) => {
                if (!filters || filters.length === 0) {
                  return false;
                }

                return status !== 'Pending';
              },
              names: ['Pending Request(s)'],
            },
          },
        },
        {
          name: 'lbs',
          label: 'Weight',
          options: {
            filter: true,
            display: true,
            customFilterListOptions: {
              render: (value) => `Weight (lbs): ${value}`,
            },
            customBodyRender: (value, tableMeta) => weightCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value) => value || '-',
          },
        },
        {
          name: 'status',
          label: 'Status',
          options: {
            filter: true,
            display: true,
            customFilterListOptions: {
              render: value => `Status: ${value}`,
            },
            customBodyRender: (value, tableMeta) => statusCustomBodyRender(value, tableMeta),
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            display: !editMode,
            forceDisplay: true,
            searchable: false,
            viewColumns: false,
            customBodyRender: (value, tableMeta) => {
              const donation = donations[getMuiTableDataIndex(tableMeta)];

              if (!donation) {
                return null;
              }

              const isVerificationStatusPending = donation.verification_status === 'pending'
                || donation.status === 'pending';

              return (
                <TableActionsButtonsWrapper>
                  {onEditClick && !isVerificationStatusPending && (
                    <TableActionButton
                      title="Edit Donation"
                      onClick={() => onEditClick(donations[getMuiTableDataIndex(tableMeta)].id)}
                    />
                  )}

                  {onReviewClick && isVerificationStatusPending && (
                    <TableActionButton
                      icon={VisibilityIcon}
                      title="Review Donation"
                      onClick={() => onReviewClick(donations[getMuiTableDataIndex(tableMeta)].id)}
                    />
                  )}
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        customToolbar: () =>
          customToolbar({
            handleEditClick,
            editMode,
            showEditRescueButton,
            handleReviewClick,
            reviewButtonEnabled: editedDonations?.length,
          }),
        customFooter: editMode
          ? () => customEditFooter({ handleEditClick, handleReviewClick, reviewButtonEnabled: editedDonations.length })
          : null,
        selectableRows: 'none',
        responsive: 'simple',
        downloadOptions: {
          filename: formatCsvFileName('Donations'),
        },
        customFilters: customFilters,
        download: !editMode,
        filter: !editMode,
        search: !editMode,
        viewColumns: !editMode,
        sort: !editMode,
        setRowProps: (row, dataIndex) => {
          const donation = donations[dataIndex];
          const rescueEdited = (editedDonations || []).find((d) => d.id === donation.id);

          return {
            className: rescueEdited ? `${classes.editedRow}` : '',
          };
        },
      }}
      components={{
        TableFilterList: (props) => CustomFilterList(props, editMode),
      }}
    />
  );
};

export default DonationsTable;
