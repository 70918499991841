import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import get from 'lodash/get';
import {
  makeStyles,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Divider,
  Box,
  Typography,
  Tooltip,
  CardHeader,
  CardContent,
  Collapse,
  Switch,
} from '@material-ui/core';
import { LocationCity, PersonPinCircle, Help as HelpIcon } from '@material-ui/icons';
import ZipCodeSelect from './Common/ZipCodeSelect';
import PrimaryContactUserSelect from './PrimaryContactUserSelect';
import PhoneTypeSelectNew from './PhoneTypeSelectNew';
import ButtonWithLoading from './ButtonWithLoading';
import * as formsHelpers from '../helpers/forms';
import * as errorsHelper from '../helpers/errors';
import errorMessages from '../assets/errorMessages';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import { PHONE_EXT_MAX_LENGTH, TextFieldPhoneExt } from './Form/MuiFormWrapper';
import { Colors } from '../assets/theme/Colors';
import { TextFieldWrapper as TextField } from './Form/MuiFormWrapper';
import { BaseCard } from './Common/BaseCard';
import { isEmailValid } from '../helpers/validators';
import { sortBySimilar } from '../helpers/sorters';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 15,
  },
  cardContent: {
    paddingBottom: '16px !important',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  makingDonationsCheckboxContainer: {
    marginBottom: 15,
  },
  umbrellaSelect: {
    marginTop: theme.spacing(1),
  },
}));

let formDirtyFields;

const validationResolver = (data, validationContext) => {
  const errors = {};

  if (!data.name) {
    errors.name = { message: errorMessages.REQUIRED.message };
  }

  if (!data.type_id) {
    errors.type_id = { message: errorMessages.REQUIRED.message };
  }

  if (data.umbrella_food_donor && !validationContext.selectedUmbrellaFoodDonor) {
    errors.donor_id = { message: errorMessages.REQUIRED.message };
  }

  if (data.phone_ext && data.phone_ext.length > PHONE_EXT_MAX_LENGTH) {
    errors.phone_ext = { message: errorMessages.ERR_PHONE_EXT_TOO_LONG.message };
  }

  if (
    validationContext.selectedOrganizationType
    && validationContext.selectedOrganizationType.name === 'Other'
    && !data.type_other
  ) {
    errors.type_other = { message: errorMessages.REQUIRED.message };
  }

  if (!data.business_address) {
    errors.business_address = { message: errorMessages.REQUIRED.message };
  }

  if (!data.business_address_zip_code) {
    errors.business_address_zip_code = { message: errorMessages.REQUIRED.message };
  }

  if (data.business_address_zip_code && !data.business_address_city || !data.business_address_state) {
    errors.business_address_zip_code = { message: errorMessages.ERR_INVALID_ZIP_CODE.message };
  }

  if (data.business_address_primary_contact_email) {
    if (!isEmailValid(data.business_address_primary_contact_email)) {
      errors.business_address_primary_contact_email = { message: errorMessages.ERR_INVALID_EMAIL.message };
    }
  }

  // if EXISTING user for business address primary contact is not selected (so we are going to create a new one)
  // and any of primary contact user fields is filled -> let's validate rest of the related fields as well
  if (
    !validationContext.selectedBusinessAddressPrimaryContactUser.meta
    && (
      (data.business_address_primary_contact_email && data.business_address_primary_contact_email.length)
      || (data.business_address_primary_contact_firstname && data.business_address_primary_contact_firstname.length)
      || (data.business_address_primary_contact_lastname && data.business_address_primary_contact_lastname.length)
      || (data.business_address_primary_contact_phone && data.business_address_primary_contact_phone.length)
      || (data.business_address_primary_contact_phone_ext && data.business_address_primary_contact_phone_ext.length)
      || (data.business_address_primary_contact_phone_type && data.business_address_primary_contact_phone_type.length)
    )
  ) {
    if (!data.business_address_primary_contact_email) {
      errors.business_address_primary_contact_email = { message: errorMessages.REQUIRED.message };
    }

    if (!data.business_address_primary_contact_firstname) {
      errors.business_address_primary_contact_firstname = { message: errorMessages.REQUIRED.message };
    }

    if (!data.business_address_primary_contact_lastname) {
      errors.business_address_primary_contact_lastname = { message: errorMessages.REQUIRED.message };
    }

    if (!data.business_address_primary_contact_phone) {
      errors.business_address_primary_contact_phone = { message: errorMessages.REQUIRED.message };
    }

    if (data.business_address_primary_contact_phone_ext && data.business_address_primary_contact_phone_ext.length > PHONE_EXT_MAX_LENGTH) {
      errors.business_address_primary_contact_phone_ext = { message: errorMessages.ERR_PHONE_EXT_TOO_LONG.message };
    }
  }

  if (!validationContext.allowPickupsFromBusinessAddress) {
    if (!data.pickup_address) {
      errors.pickup_address = { message: errorMessages.REQUIRED.message };
    }

    if (!data.pickup_address_zip_code) {
      errors.pickup_address_zip_code = { message: errorMessages.REQUIRED.message };
    }

    if (data.pickup_address_zip_code && !data.pickup_address_city || !data.pickup_address_state) {
      errors.pickup_address_zip_code = { message: errorMessages.ERR_INVALID_ZIP_CODE.message };
    }

    if (data.pickup_address_primary_contact_email) {
      if (!isEmailValid(data.pickup_address_primary_contact_email)) {
        errors.pickup_address_primary_contact_email = { message: errorMessages.ERR_INVALID_EMAIL.message };
      }
    }

    // if EXISTING user for pickup address primary contact is not selected (so we are going to create a new one)
    // and any of primary contact user fields is filled -> let's validate rest of the related fields as well
    if (
      !validationContext.selectedPickupAddressPrimaryContactUser.meta
      && (
        (data.pickup_address_primary_contact_email && data.pickup_address_primary_contact_email.length)
        || (data.pickup_address_primary_contact_firstname && data.pickup_address_primary_contact_firstname.length)
        || (data.pickup_address_primary_contact_lastname && data.pickup_address_primary_contact_lastname.length)
        || (data.pickup_address_primary_contact_phone && data.pickup_address_primary_contact_phone.length)
        || (data.pickup_address_primary_contact_phone_ext && data.pickup_address_primary_contact_phone_ext.length)
        || (data.pickup_address_primary_contact_phone_type && data.pickup_address_primary_contact_phone_type.length)
      )
    ) {
      if (!data.pickup_address_primary_contact_email) {
        errors.pickup_address_primary_contact_email = { message: errorMessages.REQUIRED.message };
      }

      if (!data.pickup_address_primary_contact_firstname) {
        errors.pickup_address_primary_contact_firstname = { message: errorMessages.REQUIRED.message };
      }

      if (!data.pickup_address_primary_contact_lastname) {
        errors.pickup_address_primary_contact_lastname = { message: errorMessages.REQUIRED.message };
      }

      if (!data.pickup_address_primary_contact_phone) {
        errors.pickup_address_primary_contact_phone = { message: errorMessages.REQUIRED.message };
      }

      if (data.pickup_address_primary_contact_phone_ext && data.pickup_address_primary_contact_phone_ext.length > PHONE_EXT_MAX_LENGTH) {
        errors.pickup_address_primary_contact_phone_ext = { message: errorMessages.ERR_PHONE_EXT_TOO_LONG.message };
      }
    }
  }

  if (
    validationContext.hasDuplicatedBusinessAddressPhoneNumberError
    && !formDirtyFields.has('business_address_primary_contact_phone')
  ) {
    errors.business_address_primary_contact_phone = {
      message: errorMessages.ERR_DUPLICATED_BUSINESS_ADDRESS_PHONE.message,
    };
  }

  if (
    validationContext.hasDuplicatedPickupAddressPhoneNumberError
    && !formDirtyFields.has('pickup_address_primary_contact_phone')
  ) {
    errors.pickup_address_primary_contact_phone = {
      message: errorMessages.ERR_DUPLICATED_PICKUP_ADDRESS_PHONE.message,
    };
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors: errors,
  };
};

const FoodDonorAddForm = ({ createFoodDonor, donorTypes, isMobileView, umbrellaFoodDonors = [], }) => {
  const classes = useStyles();
  const [selectedOrganizationType, setSelectedOrganizationType] = useState(false);
  const [selectedUmbrellaFoodDonor, setSelectedUmbrellaFoodDonor] = useState('');
  const [selectedPhoneType, setSelectedPhoneType] = useState(false);
  const [
    selectedBusinessAddressPrimaryContactUserPhoneType,
    setSelectedBusinessAddressPrimaryContactUserPhoneType
  ] = useState(false);
  const [
    selectedPickupAddressPrimaryContactUserPhoneType,
    setSelectedPickupAddressPrimaryContactUserPhoneType
  ] = useState(false);
  const [selectedBusinessAddressPrimaryContactUser, setSelectedBusinessAddressPrimaryContactUser] = useState(false);
  const [selectedPickupAddressPrimaryContactUser, setSelectedPickupAddressPrimaryContactUser] = useState(false);
  const [allowPickupsFromBusinessAddress, setAllowPickupsFromBusinessAddress] = useState(true);
  const [makesDonations, setMakesDonations] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    hasDuplicatedBusinessAddressPhoneNumberError,
    setHasDuplicatedBusinessAddressPhoneNumberError
  ] = useState(false);
  const [hasDuplicatedPickupAddressPhoneNumberError, setHasDuplicatedPickupAddressPhoneNumberError] = useState(false);
  const [phoneNumberFieldFocused, setPhoneNumberFieldFocused] = useState(false);
  const [businessAddressPhoneNumberFieldFocused, setBusinessAddressPhoneNumberFieldFocused] = useState(false);
  const [pickupAddressPhoneNumberFieldFocused, setPickupAddressPhoneNumberFieldFocused] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    getValues,
    reset,
    watch,
    errors,
    control,
    formState: { dirtyFields, isSubmitted },
  } = useForm({
    defaultValues: {
      makes_donations: true,
    },
    validationResolver: validationResolver,
    validationContext: {
      selectedOrganizationType: selectedOrganizationType,
      selectedBusinessAddressPrimaryContactUser: selectedBusinessAddressPrimaryContactUser,
      selectedPickupAddressPrimaryContactUser: selectedPickupAddressPrimaryContactUser,
      allowPickupsFromBusinessAddress: allowPickupsFromBusinessAddress,
      hasDuplicatedBusinessAddressPhoneNumberError: hasDuplicatedBusinessAddressPhoneNumberError,
      hasDuplicatedPickupAddressPhoneNumberError: hasDuplicatedPickupAddressPhoneNumberError,
      selectedUmbrellaFoodDonor,
    },
  });
  const notificationsContext = useContext(NotificationsContext);

  formDirtyFields = dirtyFields;

  useEffect(() => registerControlledInputs(), []);

  const setFormValue = (name, value) => setValue(name, value, isSubmitted);

  const registerControlledInputs = () => {
    register({ name: 'type_id' });
    register({ name: 'business_address_city' });
    register({ name: 'business_address_state' });
    register({ name: 'business_address_primary_contact_email' });
    register({ name: 'pickup_address_city' });
    register({ name: 'pickup_address_state' });
    register({ name: 'pickup_address_primary_contact_email' });
    register({ name: 'umbrella_food_donor' });
    register({ name: 'donor_id' });
  };

  const isUmbrellaFoodDonorChecked = watch('umbrella_food_donor');
  const foodDonorName = watch('name');

  const sortedUmbrellaFoodDonors = [...umbrellaFoodDonors].sort((a, b) =>
    sortBySimilar(a.name, b.name, foodDonorName)
  );

  const handleOrganizationTypeChange = event => {
    const foundDonorOrganizationType = donorTypes.find(type => type.id === event.target.value);

    setSelectedOrganizationType(foundDonorOrganizationType);

    setFormValue('type_id', foundDonorOrganizationType ? foundDonorOrganizationType.id : '');
  };

  const handleUmbrellaDonorChange = event => {
    const selectedUmbrellaDonor = umbrellaFoodDonors.find(donor => donor.id === event.target.value);

    setSelectedUmbrellaFoodDonor(selectedUmbrellaDonor ? selectedUmbrellaDonor.id : -1);

    setFormValue('donor_id', selectedUmbrellaDonor ? selectedUmbrellaDonor.id : -1);
  };

  const handlePhoneTypeChange = event => setSelectedPhoneType(event.target.value);

  const handleBusinessAddressPrimaryContactUserPhoneTypeChange = event => {
    return setSelectedBusinessAddressPrimaryContactUserPhoneType(event.target.value);
  };

  const handlePickupAddressPrimaryContactUserPhoneTypeChange = event => {
    return setSelectedPickupAddressPrimaryContactUserPhoneType(event.target.value);
  };

  const onChangeBusinessAddressUser = option => {
    setSelectedBusinessAddressPrimaryContactUser(option ? option : false);

    setFormValue('business_address_primary_contact_email', get(option, 'meta.email', option ? option.label : ''));

    if (option?.meta) {
      setSelectedBusinessAddressPrimaryContactUserPhoneType(get(option, 'meta.phone_type', false));
      setFormValue('business_address_primary_contact_firstname', get(option, 'meta.firstname', ''));
      setFormValue('business_address_primary_contact_lastname', get(option, 'meta.lastname', ''));
      setFormValue('business_address_primary_contact_phone', get(option, 'meta.phone', ''));
      setFormValue('business_address_primary_contact_phone_ext', get(option, 'meta.phone_ext', ''));
      setFormValue('business_address_primary_contact_phone_type', get(option, 'meta.phone_type', ''));
    }
  };

  const onChangePickupAddressUser = option => {
    setSelectedPickupAddressPrimaryContactUser(option ? option : false);
    setSelectedPickupAddressPrimaryContactUserPhoneType(get(option, 'meta.phone_type', false));

    setFormValue('pickup_address_primary_contact_email', get(option, 'meta.email', option ? option.label : ''));
    setFormValue('pickup_address_primary_contact_firstname', get(option, 'meta.firstname', ''));
    setFormValue('pickup_address_primary_contact_lastname', get(option, 'meta.lastname', ''));
    setFormValue('pickup_address_primary_contact_phone', get(option, 'meta.phone', ''));
    setFormValue('pickup_address_primary_contact_phone_ext', get(option, 'meta.phone_ext', ''));
    setFormValue('pickup_address_primary_contact_phone_type', get(option, 'meta.phone_type', ''));
  };

  const onChangeBusinessAddressZipCode = option => {
    if (option.zipCode.length >= 3) {
      setFormValue('business_address_state', get(option, 'data.state', ''));
    } else {
      setFormValue('business_address_state', '');
    }

    if (option.zipCode.length === 5) {
      setFormValue('business_address_city', get(option, 'data.city', ''));
    } else {
      setFormValue('business_address_city', '');
    }

  };

  const onChangePickupAddressZipCode = option => {
    if (option.zipCode.length >= 3) {
      setFormValue('pickup_address_state', get(option, 'data.state', ''));
    } else {
      setFormValue('pickup_address_state', '');
    }

    if (option.zipCode.length === 5) {
      setFormValue('pickup_address_city', get(option, 'data.city', ''));
    } else {
      setFormValue('pickup_address_city', '');
    }

  };

  const onAllowPickupsFromBusinessAddressChange = event => setAllowPickupsFromBusinessAddress(event.target.checked);

  const onMakesDonationsChange = event => setMakesDonations(event.target.checked);

  const oneUmbrellaFoodDonorChange = (event) => {
    setFormValue('umbrella_food_donor', event.target.checked);
  };

  const onSubmit = values => {
    const data = {
      ...values,

      // all of the controlled inputs have to be defined manually
      business_address_primary_contact_id: selectedBusinessAddressPrimaryContactUser
        && selectedBusinessAddressPrimaryContactUser.meta ? selectedBusinessAddressPrimaryContactUser.meta.id : null,
      business_address_primary_contact_phone_type: selectedBusinessAddressPrimaryContactUserPhoneType
        ? selectedBusinessAddressPrimaryContactUserPhoneType
        : null,
      pickup_address_primary_contact_id: selectedPickupAddressPrimaryContactUser
        && selectedPickupAddressPrimaryContactUser.meta ? selectedPickupAddressPrimaryContactUser.meta.id : null,
      pickup_address_primary_contact_phone_type: selectedPickupAddressPrimaryContactUserPhoneType
        ? selectedPickupAddressPrimaryContactUserPhoneType
        : null,
      phone_type: selectedPhoneType ? selectedPhoneType : null,
      allow_pickups_from_business_address: allowPickupsFromBusinessAddress,
      makes_donations: makesDonations,
    };

    // check if user created any new user in the form
    // if no -> we can just submit the form straight away from this point
    // if yes -> we need to show the invitation email confirmation dialog to the user
    if (
      (!selectedBusinessAddressPrimaryContactUser && !selectedPickupAddressPrimaryContactUser)
      || (
        selectedBusinessAddressPrimaryContactUser
        && data.business_address_primary_contact_id
        && (
          data.allow_pickups_from_business_address
          || (
            !data.allow_pickups_from_business_address
            && selectedPickupAddressPrimaryContactUser
            && data.pickup_address_primary_contact_id
          )
        )
      )
    ) {
      return onSubmitInner(data);
    }

    return formsHelpers.showSendInvitationEmailConfirmationDialog(data, onSubmitInner);
  };

  const onSubmitInner = (data, sendInvitationEmail = false) => Bluebird
    .try(() => {
      setIsSubmitting(true);

      return createFoodDonor({
        ...data,
        send_invitation_email: sendInvitationEmail,
      });
    })
    .catch(err => {
      setIsSubmitting(false);

      switch (err.code) {
        case errorMessages.ERR_DUPLICATED_BUSINESS_ADDRESS_PHONE.code:
          setHasDuplicatedBusinessAddressPhoneNumberError(true);

          break;
        case errorMessages.ERR_DUPLICATED_PICKUP_ADDRESS_PHONE.code:
          setHasDuplicatedPickupAddressPhoneNumberError(true);

          break;
        default:
          errorsHelper.handleErrorNew(err, notificationsContext);

          break;
      }

      // reset form with the current values - to clear touched / dirty flags (there is currently no other way to do it)
      // reset form is also unregistering previously custom registered fields with useEffect, so we have to bring
      // these back and then trigger validation to show API errors
      return Bluebird
        .try(() => reset(getValues(), { isSubmitted: true }))
        .then(() => registerControlledInputs())
        .then(() => triggerValidation());
    });

  const hasFilledAnyBusinessAddressContactPerson =
    !!getValues('business_address_primary_contact_email') ||
    !!getValues('business_address_primary_contact_firstname') ||
    !!getValues('business_address_primary_contact_lastname') ||
    !!getValues('business_address_primary_contact_phone') ||
    !!getValues('business_address_primary_contact_phone_ext');

  const hasFilledAnyPickupAddressContactPerson =
    !!getValues('pickup_address_primary_contact_email') ||
    !!getValues('pickup_address_primary_contact_firstname') ||
    !!getValues('pickup_address_primary_contact_lastname') ||
    !!getValues('pickup_address_primary_contact_phone') ||
    !!getValues('pickup_address_primary_contact_phone_ext');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="grid" gridAutoFlow="row" gridRowGap={16}>
        <TextField
          required
          id="name"
          fullWidth
          name="name"
          label="Name"
          variant="outlined"
          size="small"
          inputRef={register}
          InputLabelProps={{ shrink: !!getValues('name') || undefined }}
          error={!!errors.name}
          helperText={errors.name ? errors.name.message : ''}
        />

        <TextField
          required
          id="type_id"
          name="type_id"
          fullWidth
          label="Type"
          variant="outlined"
          size="small"
          value={selectedOrganizationType ? selectedOrganizationType.id : ''}
          onChange={handleOrganizationTypeChange}
          error={!!errors.type_id}
          helperText={errors.type_id ? errors.type_id.message : ''}
          InputLabelProps={{ shrink: !!getValues('type_id') || undefined }}
          select
          data-testid="organization-type-select"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {donorTypes.map(donorOrganizationType => (
            <MenuItem key={donorOrganizationType.id} value={donorOrganizationType.id}>
              {donorOrganizationType.name}
            </MenuItem>
          ))}
        </TextField>

        {selectedOrganizationType && selectedOrganizationType.name === 'Other' && (
          <TextField
            required
            id="type_other"
            name="type_other"
            fullWidth
            label="Organization Type Other"
            variant="outlined"
            size="small"
            inputRef={register}
            error={!!errors.type_other}
            helperText={errors.type_other ? errors.type_other.message : ''}
            InputLabelProps={{ shrink: !!getValues('type_other') || undefined }}
          />
        )}

        <Box>
          <Typography variant="body1">Umbrella Food Donor?</Typography>
          <Typography variant="body2">
            Select 'Yes' if this food donor has locations in more than one state, even if only one location is actively
            donating
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography>No</Typography>
            <Switch onChange={oneUmbrellaFoodDonorChange} value="true" name="umbrella_food_donor" />
            <Typography>Yes</Typography>
          </Box>

          {isUmbrellaFoodDonorChecked && (
            <TextField
              required={isUmbrellaFoodDonorChecked}
              id="donor_id"
              name="donor_id"
              className={classes.umbrellaSelect}
              fullWidth
              label="Umbrella Food Donor"
              variant="outlined"
              size="small"
              value={selectedUmbrellaFoodDonor}
              onChange={handleUmbrellaDonorChange}
              error={!!errors.donor_id}
              helperText={errors.donor_id ? errors.donor_id.message : ''}
              InputLabelProps={{ shrink: !!getValues('donor_id') || undefined }}
              select
              data-testid="umbrella-donor-select"
            >
              <MenuItem value={-1}>
                <em>I don’t see mine</em>
              </MenuItem>

              {sortedUmbrellaFoodDonors.map(donor => (
                <MenuItem key={donor.id} value={donor.id}>
                  {donor.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>

        <InputMask
          mask="999-999-9999"
          onFocus={() => setPhoneNumberFieldFocused(true)}
          onBlur={() => setPhoneNumberFieldFocused(false)}
        >
          <TextField
            id="phone"
            name="phone"
            fullWidth
            label="Phone#"
            variant="outlined"
            size="small"
            inputRef={register}
            error={!!errors.phone}
            helperText={errors.phone ? errors.phone.message : ''}
            autoComplete="chrome-off"
            InputLabelProps={{ shrink: !!getValues('phone') || phoneNumberFieldFocused }}
          />
        </InputMask>

        <TextFieldPhoneExt
          name="phone_ext"
          className={classes.inputFullWidth}
          label="Phone# Extension"
          variant="outlined"
          size="small"
          error={!!errors.phone_ext}
          helperText={errors.phone_ext ? errors.phone_ext.message : ''}
          inputRef={register}
          InputLabelProps={{ shrink: !!getValues('phone_ext') || undefined }}
        />

        <PhoneTypeSelectNew
          id="phone_type"
          name="phone_type"
          fullWidth
          label="Phone# Type"
          value={selectedPhoneType ? selectedPhoneType : 'mobile'}
          onChange={handlePhoneTypeChange}
          error={!!errors.phone_type}
          helperText={errors.phone_type ? errors.phone_type.message : ''}
          InputLabelProps={{ shrink: !!getValues('phone_type') || undefined }}
        />

        <TextField
          name="ein"
          fullWidth
          label="EIN"
          variant="outlined"
          size="small"
          inputRef={register}
          InputLabelProps={{ shrink: !!getValues('ein') || undefined }}
        />

        <TextField
          name="url"
          fullWidth
          label="URL"
          variant="outlined"
          size="small"
          inputRef={register}
          InputLabelProps={{ shrink: !!getValues('url') || undefined }}
        />

        <Box display="flex" alignItems="center">
          <TextField
            name="notes"
            fullWidth
            label="Notes (admin use)"
            variant="outlined"
            size="small"
            inputRef={register}
            InputLabelProps={{ shrink: !!getValues('notes') || undefined }}
            multiline
          />
          <Box mx={1}>
            <Tooltip arrow placement="top" title="These notes are like a post-it note about the Food Donor">
              <HelpIcon htmlColor={Colors.blue.main} />
            </Tooltip>
          </Box>
        </Box>

        <BaseCard>
          <CardHeader
            disableTypography
            subheader={
              <>
                <Typography gutterBottom>
                  <LocationCity fontSize="small" style={{ position: 'relative', top: 3 }} /> Business Address
                </Typography>
                <Divider />
              </>
            }
          />
          <CardContent>
            <Box display="grid" gridAutoFlow="row" gridRowGap={16}>
              <TextField
                required
                name="business_address"
                fullWidth
                label="Address"
                variant="outlined"
                size="small"
                inputRef={register}
                error={!!errors.business_address}
                helperText={errors.business_address ? errors.business_address.message : ''}
                InputLabelProps={{ shrink: !!getValues('business_address') || undefined }}
              />

              <ZipCodeSelect
                required
                name="business_address_zip_code"
                label="Zip code"
                fullWidth
                onZipCodeChange={onChangeBusinessAddressZipCode}
                error={!!errors.business_address_zip_code}
                helperText={get(errors, 'business_address_zip_code.message', false)}
                inputRef={register}
              />

              <PrimaryContactUserSelect
                required={hasFilledAnyBusinessAddressContactPerson}
                name="business_address_primary_contact_email"
                fullWidth
                onChangeUser={onChangeBusinessAddressUser}
                error={errors.business_address_primary_contact_email}
                formValue={getValues('business_address_primary_contact_email')}
              />

              <TextField
                required={hasFilledAnyBusinessAddressContactPerson}
                id="business_address_primary_contact_firstname"
                name="business_address_primary_contact_firstname"
                fullWidth
                label="Primary Contact First Name"
                variant="outlined"
                size="small"
                inputRef={register}
                error={!!errors.business_address_primary_contact_firstname}
                helperText={
                  errors.business_address_primary_contact_firstname
                    ? errors.business_address_primary_contact_firstname.message
                    : ''
                }
                disabled={!!selectedBusinessAddressPrimaryContactUser.value}
                autoComplete="chrome-off"
                InputLabelProps={{ shrink: !!getValues('business_address_primary_contact_firstname') || undefined }}
              />

              <TextField
                required={hasFilledAnyBusinessAddressContactPerson}
                id="business_address_primary_contact_lastname"
                name="business_address_primary_contact_lastname"
                fullWidth
                label="Primary Contact Last Name"
                variant="outlined"
                size="small"
                inputRef={register}
                error={!!errors.business_address_primary_contact_lastname}
                helperText={
                  errors.business_address_primary_contact_lastname
                    ? errors.business_address_primary_contact_lastname.message
                    : ''
                }
                disabled={!!selectedBusinessAddressPrimaryContactUser.value}
                autoComplete="chrome-off"
                InputLabelProps={{ shrink: !!getValues('business_address_primary_contact_lastname') || undefined }}
              />

              <TextField
                id="title"
                fullWidth
                name="title"
                label="Title"
                variant="outlined"
                size="small"
                inputRef={register}
                InputLabelProps={{ shrink: !!getValues('title') || undefined }}
                error={!!errors.title}
                helperText={errors.title ? errors.title.message : ''}
              />

              <InputMask
                mask="999-999-9999"
                disabled={!!selectedBusinessAddressPrimaryContactUser.value}
                onFocus={() => setBusinessAddressPhoneNumberFieldFocused(true)}
                onBlur={() => setBusinessAddressPhoneNumberFieldFocused(false)}
              >
                <TextField
                  required={hasFilledAnyBusinessAddressContactPerson}
                  id="business_address_primary_contact_phone"
                  name="business_address_primary_contact_phone"
                  fullWidth
                  label="Primary Contact Phone#"
                  variant="outlined"
                  size="small"
                  inputRef={register}
                  error={!!errors.business_address_primary_contact_phone}
                  helperText={
                    errors.business_address_primary_contact_phone
                      ? errors.business_address_primary_contact_phone.message
                      : ''
                  }
                  autoComplete="chrome-off"
                  InputLabelProps={{
                    shrink:
                      !!getValues('business_address_primary_contact_phone') || businessAddressPhoneNumberFieldFocused,
                  }}
                />
              </InputMask>

              <TextFieldPhoneExt
                required={hasFilledAnyBusinessAddressContactPerson}
                name="business_address_primary_contact_phone_ext"
                fullWidth
                label="Primary Contact Phone# Extension"
                variant="outlined"
                size="small"
                error={!!errors.business_address_primary_contact_phone_ext}
                helperText={
                  errors.business_address_primary_contact_phone_ext
                    ? errors.business_address_primary_contact_phone_ext.message
                    : ''
                }
                disabled={!!selectedBusinessAddressPrimaryContactUser.value}
                inputRef={register}
                InputLabelProps={{ shrink: !!getValues('business_address_primary_contact_phone_ext') || undefined }}
              />

              <PhoneTypeSelectNew
                id="business_address_primary_contact_phone_type"
                name="business_address_primary_contact_phone_type"
                fullWidth
                label="Primary Contact Phone# Type"
                value={
                  selectedBusinessAddressPrimaryContactUserPhoneType
                    ? selectedBusinessAddressPrimaryContactUserPhoneType
                    : 'mobile'
                }
                onChange={handleBusinessAddressPrimaryContactUserPhoneTypeChange}
                error={!!errors.business_address_primary_contact_phone_type}
                helperText={
                  errors.business_address_primary_contact_phone_type
                    ? errors.business_address_primary_contact_phone_type.message
                    : ''
                }
                disabled={!!selectedBusinessAddressPrimaryContactUser.value}
                InputLabelProps={{ shrink: !!getValues('business_address_primary_contact_phone_type') || undefined }}
              />
            </Box>
          </CardContent>
        </BaseCard>

        <BaseCard>
          <CardHeader
            disableTypography
            title={
              <>
                <Typography gutterBottom>
                  <PersonPinCircle fontSize="small" style={{ position: 'relative', top: 4 }} /> Pickup Address
                </Typography>
                <Divider />
              </>
            }
            subheader={
              <Box pt={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      name="allow_pickups_from_business_address"
                      color="primary"
                      checked={allowPickupsFromBusinessAddress}
                      onChange={onAllowPickupsFromBusinessAddressChange}
                    />
                  }
                  label="Allow pickups from business address"
                />
              </Box>
            }
          />
          <CardContent>
            <Collapse in={!allowPickupsFromBusinessAddress} timeout="auto">
              <Box display="grid" gridAutoFlow="row" gridRowGap={16} mb={2}>
                <TextField
                  required
                  name="pickup_address"
                  fullWidth
                  label="Address"
                  variant="outlined"
                  size="small"
                  inputRef={register}
                  error={!!errors.pickup_address}
                  helperText={errors.pickup_address ? errors.pickup_address.message : ''}
                  InputLabelProps={{ shrink: !!getValues('pickup_address') || undefined }}
                />

                <ZipCodeSelect
                  required
                  name="pickup_address_zip_code"
                  label="Zip code"
                  fullWidth
                  onZipCodeChange={onChangePickupAddressZipCode}
                  error={!!errors.pickup_address_zip_code}
                  helperText={get(errors, 'pickup_address_zip_code.message', false)}
                  inputRef={register}
                />

                <PrimaryContactUserSelect
                  required={hasFilledAnyPickupAddressContactPerson}
                  name="pickup_address_primary_contact_email"
                  fullWidth
                  onChangeUser={onChangePickupAddressUser}
                  error={errors.pickup_address_primary_contact_email}
                  formValue={getValues('pickup_address_primary_contact_email')}
                />

                <TextField
                  required={hasFilledAnyPickupAddressContactPerson}
                  id="pickup_address_primary_contact_firstname"
                  name="pickup_address_primary_contact_firstname"
                  fullWidth
                  label="Primary Contact First Name"
                  variant="outlined"
                  size="small"
                  inputRef={register}
                  error={!!errors.pickup_address_primary_contact_firstname}
                  helperText={
                    errors.pickup_address_primary_contact_firstname
                      ? errors.pickup_address_primary_contact_firstname.message
                      : ''
                  }
                  disabled={!!selectedPickupAddressPrimaryContactUser.value}
                  autoComplete="chrome-off"
                  InputLabelProps={{ shrink: !!getValues('pickup_address_primary_contact_firstname') || undefined }}
                />

                <TextField
                  required={hasFilledAnyPickupAddressContactPerson}
                  id="pickup_address_primary_contact_lastname"
                  name="pickup_address_primary_contact_lastname"
                  fullWidth
                  label="Primary Contact Last Name"
                  variant="outlined"
                  size="small"
                  inputRef={register}
                  error={!!errors.pickup_address_primary_contact_lastname}
                  helperText={
                    errors.pickup_address_primary_contact_lastname
                      ? errors.pickup_address_primary_contact_lastname.message
                      : ''
                  }
                  disabled={!!selectedPickupAddressPrimaryContactUser.value}
                  autoComplete="chrome-off"
                  InputLabelProps={{ shrink: !!getValues('pickup_address_primary_contact_lastname') || undefined }}
                />

                <InputMask
                  mask="999-999-9999"
                  disabled={!!selectedPickupAddressPrimaryContactUser.value}
                  onFocus={() => setPickupAddressPhoneNumberFieldFocused(true)}
                  onBlur={() => setPickupAddressPhoneNumberFieldFocused(false)}
                >
                  <TextField
                    required={hasFilledAnyPickupAddressContactPerson}
                    id="pickup_address_primary_contact_phone"
                    name="pickup_address_primary_contact_phone"
                    fullWidth
                    label="Primary Contact Phone#"
                    variant="outlined"
                    size="small"
                    inputRef={register}
                    error={!!errors.pickup_address_primary_contact_phone}
                    helperText={
                      errors.pickup_address_primary_contact_phone
                        ? errors.pickup_address_primary_contact_phone.message
                        : ''
                    }
                    autoComplete="chrome-off"
                    InputLabelProps={{
                      shrink:
                        !!getValues('pickup_address_primary_contact_phone') || pickupAddressPhoneNumberFieldFocused,
                    }}
                  />
                </InputMask>

                <TextFieldPhoneExt
                  required={hasFilledAnyPickupAddressContactPerson}
                  name="pickup_address_primary_contact_phone_ext"
                  fullWidth
                  label="Primary Contact Phone# Extension"
                  variant="outlined"
                  size="small"
                  disabled={!!selectedPickupAddressPrimaryContactUser.value}
                  inputRef={register}
                  error={!!errors.pickup_address_primary_contact_phone_ext}
                  helperText={
                    errors.pickup_address_primary_contact_phone_ext
                      ? errors.pickup_address_primary_contact_phone_ext.message
                      : ''
                  }
                  InputLabelProps={{ shrink: !!getValues('pickup_address_primary_contact_phone_ext') || undefined }}
                />

                <PhoneTypeSelectNew
                  id="pickup_address_primary_contact_phone_type"
                  name="pickup_address_primary_contact_phone_type"
                  fullWidth
                  label="Primary Contact Phone# Type"
                  value={
                    selectedPickupAddressPrimaryContactUserPhoneType
                      ? selectedPickupAddressPrimaryContactUserPhoneType
                      : 'mobile'
                  }
                  onChange={handlePickupAddressPrimaryContactUserPhoneTypeChange}
                  error={!!errors.pickup_address_primary_contact_phone_type}
                  helperText={
                    errors.pickup_address_primary_contact_phone_type
                      ? errors.pickup_address_primary_contact_phone_type.message
                      : ''
                  }
                  disabled={!!selectedPickupAddressPrimaryContactUser.value}
                  InputLabelProps={{ shrink: !!getValues('pickup_address_primary_contact_phone_type') || undefined }}
                />
              </Box>
            </Collapse>

            <Box display="grid" gridAutoFlow="row" gridRowGap={16}>
              <Box display="flex" alignItems="center">
                <TextField
                  name="pickup_notes"
                  fullWidth
                  label="Pickup Notes"
                  variant="outlined"
                  size="small"
                  inputRef={register}
                  InputLabelProps={{ shrink: !!getValues('pickup_notes') || undefined }}
                  multiline
                />
                <Box mx={1}>
                  <Tooltip
                    arrow
                    placement="top"
                    title="These notes are shown to all rescuers dropping off at this location"
                  >
                    <HelpIcon htmlColor={Colors.blue.main} />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </BaseCard>

        <div className={classes.makingDonationsCheckboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                name="makes_donations"
                color="primary"
                checked={makesDonations}
                onChange={onMakesDonationsChange}
              />
            }
            label="Making donations"
          />
        </div>

        <ButtonWithLoading
          fullWidth={isMobileView}
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isSubmitting}
        >
          Create Food Donor
        </ButtonWithLoading>
      </Box>
      {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
    </form>
  );
};

export default FoodDonorAddForm;
