import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { Field, Form } from 'react-final-form';
import { Box, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import DateAndGranularityPicker, {
  dateRangeOptionsList,
} from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import ReportLegend, { shouldShowLegend } from '../containers/ReportLegend';
import { getEarliestSiteDate } from '../helpers/getters';
import { Colors } from '../assets/theme/Colors';

const VolumeReportForm = ({ isAdmin, sitesList, onSubmit, defaultValues, fetchReport }) => {
  const [selectedSite, setSelectedSite] = useState(defaultValues.sites);
  const [selectedType, setSelectedType] = useState(defaultValues.type);
  const [dateRange, setDateRange] = useState(defaultValues.dateRange);
  const [suppressEmptyRows, setSuppressEmptyRows] = useState(defaultValues.suppressEmptyRows);
  const [completedRegistration, setCompletedRegistration] = useState(defaultValues.completedRegistration);

  const handleSubmit = values => onSubmit({
    sites: values.sites || [],
    type: values.type,
    startDate: values.startDate ? values.startDate : null,
    endDate: values.endDate ? values.endDate : null,
    period: values.period,
    granularity: values.granularity ? values.granularity : null,
    suppress_empty_rows: values.suppress_empty_rows !== undefined ? values.suppress_empty_rows : true,
    showRescueSizeLegend: shouldShowLegend(defaultValues.showRescueSizeLegend),
    completedRegistration: values.completedRegistration ? values.completedRegistration : null,
  });

  const handleChangeSuppressEmptyRows = event => {
    setSuppressEmptyRows(event.target.checked);

    return handleSubmit({
      sites: selectedSite || [],
      type: selectedType,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      period: dateRange.period,
      granularity: dateRange.granularity,
      suppress_empty_rows: event.target.checked,
      completedRegistration: completedRegistration || null,
    });
  };

  const handleChangeCompletedRegistration = event => {
    setCompletedRegistration(event.target.checked);

    return handleSubmit({
      sites: selectedSite || [],
      type: selectedType,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      period: dateRange.period,
      granularity: dateRange.granularity,
      suppress_empty_rows: suppressEmptyRows,
      completedRegistration: event.target.checked || null,
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        site_id: defaultValues.sites
      }}
      render={() => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box display="flex" alignItems="center" gridGap="16px">
            <Box display="flex" alignItems="center" gridGap="5px">
              <FilterListIcon htmlColor={Colors.inputBorder} />
              <span>Filters</span>
            </Box>
            {(isAdmin || sitesList.length > 1) && (
              <Box width="100%">
                <Field
                  name="site_id"
                  render={({ input }) => (
                    <ReactSelect
                      isMulti
                      name={input.name}
                      placeholder="Select site(s) or leave blank to show data for all sites"
                      value={input.value}
                      options={[{ id: -1, name: 'All'}, ...sitesList]}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      onChange={option => {
                        let choices = option !== null ? option : [];
                        if (choices.some(site => site.id === -1)) {
                          choices = [];
                        }

                        input.onChange(choices);

                        setSelectedSite(choices);

                        return handleSubmit({
                          sites: choices,
                          type: selectedType,
                          startDate: dateRange.startDate,
                          endDate: dateRange.endDate,
                          period: dateRange.period,
                          granularity: dateRange.granularity,
                          suppress_empty_rows: suppressEmptyRows,
                          completedRegistration: completedRegistration,
                        });
                      }}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                      styles={{
                        menu: provided => ({
                          ...provided,
                          zIndex: 101,
                        }),
                      }}
                    />
                  )}
                />
              </Box>
            )}
          </Box>

          <Box my={2} style={{ marginBottom: 0, marginTop: !(isAdmin || sitesList.length > 1) ? 0 : 16 }}>
            <Field
              name="type"
              render={({ input }) => (
                <ReactSelect
                  name="type"
                  placeholder="Type"
                  value={selectedType}
                  options={[
                    {
                      id: 'food_donors',
                      name: 'Food Donors',
                    },
                    {
                      id: 'receivers',
                      name: 'Receivers',
                    },
                    {
                      id: 'rescuers',
                      name: 'Rescuers',
                    },
                  ]}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  onChange={option => {
                    input.onChange(option !== null ? option.id : null);

                    setSelectedType(option);

                    return handleSubmit({
                      sites: selectedSite || [],
                      type: option,
                      startDate: dateRange.startDate,
                      endDate: dateRange.endDate,
                      period: dateRange.period,
                      granularity: dateRange.granularity,
                      suppress_empty_rows: suppressEmptyRows,
                      completedRegistration: completedRegistration,
                    });
                  }}
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      zIndex: 101,
                    }),
                  }}
                />
              )}
            />
          </Box>

          <Box my={2} style={{ marginBottom: 10 }}>
            <DateAndGranularityPicker
              customDateRangeValue={dateRange}
              defaultDateRange={defaultValues.period}
              defaultGranularity={defaultValues.granularity}
              dateRangeOptions={{
                lastWeek: dateRangeOptionsList.lastWeek,
                last7Days: dateRangeOptionsList.last7Days,
                last90Days: dateRangeOptionsList.last90Days,
                thisMTD: dateRangeOptionsList.thisMTD,
                thisQTD: dateRangeOptionsList.thisQTD,
                thisYTD: dateRangeOptionsList.thisYTD,
              }}
              showCustomDateRange
              customDateRangeOptions={{
                maxDate: new Date(),
                minDate: getEarliestSiteDate(selectedSite, sitesList),
              }}
              returnDateObject={false}
              onChange={data => {
                const dateRange = {
                  startDate: data.dateRange ? data.dateRange.startDate : null,
                  endDate: data.dateRange ? data.dateRange.endDate : null,
                  period: data.period ? data.period : null,
                  granularity: data.granularity ? data.granularity : null,
                };

                setDateRange(dateRange);

                return handleSubmit({
                  sites: selectedSite || [],
                  type: selectedType,
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                  period: dateRange.period,
                  granularity: dateRange.granularity,
                  suppress_empty_rows: suppressEmptyRows,
                  completedRegistration: completedRegistration,
                });
              }}
            />
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={suppressEmptyRows}
                onChange={handleChangeSuppressEmptyRows}
                value="suppress_empty_rows"
                style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
              />
            }
            label="Suppress empty rows"
          />

          {selectedType.id === 'rescuers' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={completedRegistration || false}
                  onChange={handleChangeCompletedRegistration}
                  value="completed_registration"
                  style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                />
              }
              label="Registration Complete"
            />
          )}

          <Box textAlign="right" my={2}>
            <Button variant="contained" color="primary" onClick={fetchReport}>
              Run
            </Button>
          </Box>

          <ReportLegend
            label="Rescue Size legend"
            isOpen={defaultValues.showRescueSizeLegend}
            onExpandChange={state =>
              onSubmit({
                ...defaultValues,
                ...defaultValues.dateRange,
                showRescueSizeLegend: state,
              })
            }
          />
        </form>
      )}
    />
  );
};

export default VolumeReportForm;
