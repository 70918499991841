import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Box, ButtonBase, CircularProgress, Typography } from '@material-ui/core';
import { Cancel as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import { useFormContext, Controller } from 'react-hook-form';
import { Colors } from '../../../../../assets/theme/Colors';

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'relative',
    width: '100% !important', // Overrides inline-style
    height: 115,
    [theme.breakpoints.down('xs')]: {
      height: 100,
    },
  },
  focusVisible: {},
  imageSrc: {
    borderRadius: 8,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  deleteButton: {
    position: 'absolute',
    color: Colors.secondaryColor,
    backgroundColor: Colors.white,
    padding: 0,
    top: -3,
    right: -3,
    zIndex: 1,
    '&:hover': {
      backgroundColor: Colors.white,
    },
  },
  buttonContainer: {
    position: 'relative',
  },
  buttonWrapper: {
    border: `1px solid ${Colors.lightGray}`,
    borderRadius: 8,
    overflow: 'hidden',
    padding: 5,
  },
}));

function CircularProgressWithLabel({ isDone, value, children }) {
  return (
    <Box
      position="relative"
      display="inline-flex"
      css={{
        zIndex: 1,
      }}
    >
      <CircularProgress
        value={value}
        size={50}
        color="secondary"
        variant="static"
        style={{
          backgroundColor: Colors.white,
          padding: 5,
          borderRadius: '50%',
          color: isDone ? Colors.green.main : Colors.secondaryColor,
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Box>
  );
}

const RescuePhotoButton = ({ file, index, disabled, onClick }) => {
  const classes = useStyles();
  const { control } = useFormContext(); // retrieve all hook methods

  return (
    <Box className={classes.buttonContainer}>
      <Controller name={`photos[${index}].id`} defaultValue={file.id} control={control} as="input" type="hidden" />
      <Controller name={`photos[${index}].name`} defaultValue={file.name} control={control} as="input" type="hidden" />
      <Controller
        name={`photos[${index}].photoBlob`}
        defaultValue={file.photoBlob}
        control={control}
        as="input"
        type="hidden"
      />
      <Controller name={`photos[${index}].file`} defaultValue={file.file} control={control} as="input" type="hidden" />
      <Controller name={`photos[${index}].s3Key`} defaultValue={file.s3Key} control={control} as="input" type="hidden" />

      <IconButton onClick={onClick} disabled={disabled} component="div" aria-label="delete" className={classes.deleteButton} size="small">
        <CloseIcon />
      </IconButton>
      <Box className={classes.buttonWrapper}>
        <ButtonBase
          disabled={disabled}
          onClick={onClick}
          focusRipple
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
        >
          <Controller
            name={`photos[${index}].progress`}
            control={control}
            as={props => {
              if (!props.value) {
                return null;
              }
              const renderValue = parseInt(props.value, 10);
              return (
                <CircularProgressWithLabel value={renderValue}>
                  {renderValue === 100 && <DoneIcon fontSize="small" />}
                  {renderValue < 100 && (
                    <Typography variant="caption" component="div" color="textSecondary">
                      {`${renderValue}%`}
                    </Typography>
                  )}
                </CircularProgressWithLabel>
              );
            }}
          />
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url('${file.photoBlob}')`,
            }}
          />
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default RescuePhotoButton;
