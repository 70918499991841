import * as zipCodesApi from '../api/zipCodes';

export const REQUEST_ZIP_CODES = 'REQUEST_ZIP_CODES';
export const RECEIVE_ZIP_CODES = 'RECEIVE_ZIP_CODES';

export const requestZipCodes = () => ({
  type: REQUEST_ZIP_CODES,
});

export const receiveZipCodes = zipCodes => ({
  type: RECEIVE_ZIP_CODES,
  zipCodes,
  receivedAt: Date.now(),
});

export const fetchZipCodes = zipCode => dispatch => {
  dispatch(requestZipCodes());

  return zipCodesApi
    .getZipCodes(zipCode)
    .then(res => res.json())
    .then(json => {
      return dispatch(receiveZipCodes(json.data));
    });
};

export const fetchZipCodesIfNeeded = zipCode => dispatch => {
  return dispatch(fetchZipCodes(zipCode));
};
