import React from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import { BaseCard } from '../../../../components/Common/BaseCard';
import OverlayLoader from '../../../../components/OverlayLoader';

const EnviroCard = ({ title, value, isLoading, cardStyle, children }) => (
  <OverlayLoader wrapperStyles={{ height: '100%' }} isLoading={isLoading}>
    <BaseCard className={cardStyle}>
      <CardContent>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box fontSize={{ xs: 72, sm: 96 }}>{children}</Box>
          <Typography style={{ overflowWrap: 'anywhere' }} align="center" variant="h3">
            {value}
          </Typography>
          <Typography align="center" variant="body1" color="textSecondary">
            {title}
          </Typography>
        </Box>
      </CardContent>
    </BaseCard>
  </OverlayLoader>
);

export default EnviroCard;
