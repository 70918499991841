import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import PastRescueForm from './PastRescueForm';
import { Box, Grid, IconButton, Breadcrumbs, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import classNames from 'classnames';
import PagePaper from '../../../../components/Common/PagePaper';
import { GridRow, RowContent, RowTitle } from '../FormGrid';
import {
  calculatedLbsSource,
  getRescuedFoodTypesLabel,
  getRescuedItemsFoodTypes,
  getRescueDropoffAddress,
  getRescueEstimatedLbs,
  getRescueFoodTypes,
  getRescuePickupAddress,
  getRescuePickupLocationFullName,
} from '../../../../helpers/RescuesHelper';
import { formatPhoneNumber, formatRescueDistance, formatRescueDuration } from '../../../../helpers/formatters';
import { makeStyles } from '@material-ui/core/styles';
import { fetchRescueSizesIfNeeded } from '../../../../actions/rescueSizes';
import useActiveSite from '../../../../hooks/useActiveSite';
import { fetchSiteReceiversIfNeeded, fetchSiteRescuersIfNeeded, fetchSiteUsersIfNeeded, fetchSitesPhotos } from '../../../../actions/sites';
import { fetchUnitsIfNeeded } from '../../../../actions/units';
import { fetchFoodDescriptionsIfNeeded } from '../../../../actions/foodDescriptions';
import { get } from 'lodash';
import { sortArrayOfObjects } from '../../../../helpers/sorters';
import * as rescuesActions from '../../../../actions/rescues';
import routes from '../../../../routes';
import { hasAnyRoleInSite } from '../../../../services/auth';
import { Roles } from '../../../../models/roles';
import { fetchSystemSettingsIfNeeded } from '../../../../actions/systemSettings';
import useSystemSettings from '../../../../hooks/useSystemSettings';
import { LBS_PER_MEAL } from '../../../../models/systemSettings';
import { Colors } from '../../../../assets/theme/Colors';
import { fetchDonationSizesIfNeeded } from '../../../../actions/donationSizes';
import PhotoDialog from '../../RescueGallery/Components/Lightbox/PhotoDialog';
import GalleryGridItem from '../../RescueGallery/Components/GalleryGridItem';

const handleAddressClick = address => window.open(`https://maps.google.com/?q=${address}`, '_blank');

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  details: {
    [breakpoints.up('md')]: {
      flexBasis: 'max(33.33333%, 400px)',
      maxWidth: 'max(33.33333%, 400px)',
    },
  },
  addressRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  timePicker: {
    '& input': {
      textAlign: 'center',
    },
  },
  breakWhiteSpaces: {
    whiteSpace: 'pre-wrap',
  },
  lbs: {
    color: Colors.green.main,
    fontWeight: 'normal',
  },
  lbsList: {
    paddingLeft: 0,
    margin: 0,
    listStyle: 'none',

    '& li': {
      '& > span': {
        color: Colors.green.main,
      },

      '&.strike > span': {
        textDecoration: 'line-through',
        textDecorationColor: 'black',
        color: 'red',
      },
    },
  },
  photo: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
}));

const PastRescueView = ({ rescue, isMobileView }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const location = useLocation();
  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const siteRescuers = useSelector(state => state.entities.sites.rescuers);
  const siteUsers = useSelector(state => state.entities.sites.users);
  const foodDescriptions = useSelector(state => state.entities.foodDescriptions);
  const unitTypes = useSelector(state => state.entities.units);
  const sitePhotos = useSelector(state => state.entities.sites.photos);
  const rescuePhotos = Object.values(sitePhotos.byId).filter((photo) => photo?.rescue_id === rescue.id);
  const lbsPerMeal = useSystemSettings(LBS_PER_MEAL);
  const [weight, setWeight] = useState(null);
  const [showPhoto, setShowPhoto] = useState();

  const siteRescuersList = siteRescuers.bySiteId[activeSite.id] || [];
  const siteUsersList = siteUsers.bySiteId[activeSite.id] || [];
  const rescueSizesArray = rescueSizes.sorted;
  const unitTypeOther = Object.values(unitTypes.byId).find(ot => ot.name === 'Other');
  const unitTypesArray = sortArrayOfObjects(Object.values(unitTypes.byId), u => u.name);
  const foodDescriptionOther = Object.values(foodDescriptions.byId).find(ot => ot.name === 'Other');
  const foodDescriptionTypesArray = sortArrayOfObjects(Object.values(foodDescriptions.byId), u => u.name);
  const rescueSize = rescueSizes.byId[rescue.rescue_size_id];
  const estimatedLBSValue = getRescueEstimatedLbs(rescueSize, lbsPerMeal);

  // TODO: add NSD/Admin users to the list
  const cancelledByUsersList = siteUsersList.filter(user =>
    hasAnyRoleInSite(user, activeSite.id, [Roles.SiteDirector, Roles.SiteCoordinator])
  );

  useEffect(() => {
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchDonationSizesIfNeeded());
    dispatch(fetchSiteRescuersIfNeeded(activeSite.id));
    dispatch(fetchSiteUsersIfNeeded(activeSite.id));
    dispatch(fetchSiteReceiversIfNeeded(rescue.site_id));
    dispatch(fetchUnitsIfNeeded());
    dispatch(fetchFoodDescriptionsIfNeeded());
    dispatch(fetchSystemSettingsIfNeeded()); // used in LBSSelector
    dispatch(fetchSitesPhotos(activeSite.id));
  }, [dispatch]);

  const foodTypes = getRescueFoodTypes(rescue);
  const pickupAddress = getRescuePickupAddress(rescue);
  const dropoffAddress = getRescueDropoffAddress(rescue);
  const rescueCompleted = rescue?.completed;
  const foodTypesLabel = getRescuedFoodTypesLabel(rescue);
  const RescuedItemsFoodTypes = getRescuedItemsFoodTypes(rescue, foodDescriptionTypesArray);
  const [categories, setCategories] = useState(rescueCompleted ? RescuedItemsFoodTypes : foodTypesLabel);

  const handleRescueUpdate = (rescueId, data) => dispatch(rescuesActions.updatePastRescue(rescueId, data));

  const handlePrevPhotoChange = () => {
    const prevPhoto = showPhoto - 1;

    if (prevPhoto >= 0) {
      setShowPhoto(prevPhoto);
    }
  };

  const handleNextPhotoChange = () => {
    const nextPhoto = showPhoto + 1;

    if (nextPhoto < rescuePhotos.length) {
      setShowPhoto(nextPhoto);
    }
  };

  if (unitTypes.inflight || foodDescriptions.inflight || siteRescuers.inflight) {
    return 'loading...';
  }

  // @todo: figure out why sometimes food_types are string...
  if (typeof rescue.food_types === 'string') {
    rescue.food_types = rescue.food_types.split(',');
  }

  return (
    <>
      <PhotoDialog
        disablePrevButton={showPhoto === 0}
        disableNextButton={showPhoto === rescuePhotos.length - 1}
        fullScreen={isMobileView}
        rescuePhotoData={rescuePhotos[showPhoto]}
        handlePrevPhoto={handlePrevPhotoChange}
        handleNextPhoto={handleNextPhotoChange}
        open={!isNaN(showPhoto)}
        handleClose={() => setShowPhoto(false)}
      />

      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={get(location, 'state.prevPath', generatePath(routes.pastrescues))}>
          {get(location, 'state.prevPath') === routes.rescuesSchedule ? 'Schedule' : 'Past Rescues'}
        </Link>

        <Typography color="textPrimary">{rescue.id}</Typography>
      </Breadcrumbs>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4} className={classes.details}>
          <PagePaper>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
              <GridRow>
                <RowTitle>Date:</RowTitle>
                <RowContent>{moment(rescue.date, 'YYYY-MM-DD').format('dddd, MMMM Do')}</RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>From:</RowTitle>
                <RowContent>{getRescuePickupLocationFullName(rescue)}</RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>To:</RowTitle>
                <RowContent>{rescue.receiver}</RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>
                  Weight:
                </RowTitle>

                <RowContent>
                  {weight !== null ? <span className={classes.lbs}>{weight} lbs</span> : '-'}
                </RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>
                  Weight Source:
                </RowTitle>

                <RowContent>
                  {calculatedLbsSource(rescue.calculated_lbs_source)
                    ? calculatedLbsSource(rescue.calculated_lbs_source)
                    : '-'}
                </RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>Water Offset:</RowTitle>

                <RowContent>{rescue?.water || '-'}</RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>CO2eq Offset:</RowTitle>

                <RowContent>{rescue?.co2eq || '-'}</RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>Distance / Duration:</RowTitle>

                <RowContent>
                  {`${formatRescueDistance(rescue.distance, ' miles')} / ${formatRescueDuration(
                    rescue.duration,
                    false
                  )}`}
                </RowContent>
              </GridRow>

              <GridRow hidden={!rescue.food_types}>
                <RowTitle>Food Type(s):</RowTitle>

                <RowContent>{categories}</RowContent>
                {/* <RowContent>{rescueCompleted ? RescuedItemsFoodTypes : foodTypesLabel}</RowContent> */}
              </GridRow>

              <GridRow hidden={!rescue.rescue_size}>
                <RowTitle>Rescue Vehicle Size:</RowTitle>
                <RowContent>{rescue.rescue_size}</RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>Pickup Address:</Grid>
                    <Grid item>
                      <Box ml={4}>
                        <IconButton size="small" aria-label="delete" onClick={() => handleAddressClick(pickupAddress)}>
                          <RoomIcon htmlColor="#000" fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </RowTitle>
                <RowContent>
                  <span className={classes.addressRow} onClick={() => handleAddressClick(pickupAddress)}>
                    {pickupAddress}
                  </span>
                </RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>Pickup Contact:</RowTitle>
                {rescue.pickup_contact && <RowContent>Name: {rescue.pickup_contact}</RowContent>}
                {rescue.pickup_contact_phone && (
                  <RowContent>
                    <span>Phone: </span>
                    <a href={`tel:${rescue.pickup_contact_phone}`}>{formatPhoneNumber(rescue.pickup_contact_phone)}</a>
                    {rescue.pickup_contact_phone_ext && <span>{` (ext: ${rescue.pickup_contact_phone_ext})`}</span>}
                  </RowContent>
                )}
                {rescue.pickup_contact_email && (
                  <RowContent>
                    Email: <a href={`mailto:${rescue.pickup_contact_email}`}>{rescue.pickup_contact_email}</a>
                  </RowContent>
                )}
              </GridRow>

              <GridRow hidden={!rescue.pickup_notes}>
                <RowTitle>Pickup Notes:</RowTitle>
                <RowContent>
                  <span className={classes.breakWhiteSpaces}>{rescue.pickup_notes}</span>
                </RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item>Dropoff Address:</Grid>
                    <Grid item>
                      <Box ml={4}>
                        <IconButton size="small" aria-label="delete" onClick={() => handleAddressClick(dropoffAddress)}>
                          <RoomIcon htmlColor="#000" fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </RowTitle>
                <RowContent>
                  <span className={classes.addressRow} onClick={() => handleAddressClick(dropoffAddress)}>
                    {dropoffAddress}
                  </span>
                </RowContent>
              </GridRow>

              <GridRow>
                <RowTitle>Dropoff Contact:</RowTitle>
                {rescue.dropoff_contact && <RowContent>Name: {rescue.dropoff_contact}</RowContent>}
                {rescue.dropoff_contact_phone && (
                  <RowContent>
                    <span>Phone: </span>
                    <a href={`tel:${rescue.dropoff_contact_phone}`}>
                      {formatPhoneNumber(rescue.dropoff_contact_phone)}
                    </a>
                    {rescue.dropoff_contact_phone_ext && <span>{` (ext: ${rescue.dropoff_contact_phone_ext})`}</span>}
                  </RowContent>
                )}
                {rescue.dropoff_contact_email && (
                  <RowContent>
                    Email: <a href={`mailto:${rescue.dropoff_contact_email}`}>{rescue.dropoff_contact_email}</a>
                  </RowContent>
                )}
              </GridRow>

              <GridRow hidden={!rescue.dropoff_notes}>
                <RowTitle>Dropoff Notes:</RowTitle>
                <RowContent>
                  <span className={classes.breakWhiteSpaces}>{rescue.dropoff_notes}</span>
                </RowContent>
              </GridRow>

              <GridRow hidden={!rescuePhotos?.length}>
                <RowTitle>Rescue Photo(s):</RowTitle>
                <RowContent>
                  <Grid container spacing={1}>
                    {rescuePhotos.map((photo, index) => (
                      <Grid key={photo.id} item xs={4} md={6}>
                        <GalleryGridItem handleDialogOpen={() => setShowPhoto(index)} tile={photo}>
                          <Box height={200} />
                        </GalleryGridItem>
                      </Grid>
                    ))}
                  </Grid>
                </RowContent>
              </GridRow>
            </Grid>
          </PagePaper>
        </Grid>

        <Grid item sm={12} md>
          <PastRescueForm
            rescue={rescue}
            estimatedLBSValue={estimatedLBSValue}
            isMobileView={isMobileView}
            rescueSizes={rescueSizesArray}
            rescuersList={siteRescuersList}
            cancelledByUsersList={cancelledByUsersList}
            foodDescriptions={foodDescriptionTypesArray}
            unitTypes={unitTypesArray}
            foodDescriptionOtherId={get(foodDescriptionOther, 'id', null)}
            unitTypeOtherId={get(unitTypeOther, 'id', null)}
            updateRescue={handleRescueUpdate}
            setWeight={setWeight}
            setCategories={setCategories}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PastRescueView;
