import React from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import { Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Joi from '@hapi/joi';
import { TextFieldWrapper as TextField } from './Form/MuiFormWrapper';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import errorMessages from '../assets/errorMessages';
import ButtonWithLoading from './ButtonWithLoading';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    maxWidth: 500,
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const validationResolver = (data) => {
  const errors = {};
  const errorList = Joi.object()
    .keys({ name: Joi.string().required() })
    .messages({
      'string.empty': errorMessages.REQUIRED.message,
    })
    .validate(
      {
        name: data.name || '',
      },
      { abortEarly: false }
    );

  if (errorList.error) {
    errorList.error.details.forEach(error => {
      errors[error.context.key] = { message: error.message };
    });
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors: errors,
  };
};

const UmbrellaFoodDonorEditForm = ({ umbrellaFoodDonor, onSubmit }) => {
  const classes = useStyles();
  const {
    handleSubmit,
    register,
    getValues,
    errors,
    control,
    formState: { dirty, isSubmitting },
  } = useForm({
    validationResolver: validationResolver,
    defaultValues: {
      name: umbrellaFoodDonor.name,
    },
  });

  const submit = async values => {
    try {
      await onSubmit({
        id: umbrellaFoodDonor.id,
        name: values.name,
      });

      snackbarHelper.success('Umbrella Food Donor was updated successfully!');
    } catch (error) {
      errorsHelper.handleError(error);
    }
  };

  return (
    <Paper className={classes.paper}>
      <form className={classes.form} onSubmit={handleSubmit(submit)}>
        <DevTool control={control} />
        <Box display="grid" gridAutoFlow="row" gridRowGap={16}>
          <TextField
            required
            id="name"
            name="name"
            label="Umbrella Food Donor Name"
            variant="outlined"
            size="small"
            inputRef={register}
            InputLabelProps={{ shrink: !!getValues('name') || undefined }}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
          />

          <ButtonWithLoading type="submit" isLoading={isSubmitting} disabled={!dirty} data-testid="update-donor-button">
            Update Umbrella Food Donor
          </ButtonWithLoading>
        </Box>
      </form>
    </Paper>
  );
};

export default UmbrellaFoodDonorEditForm;
