import React from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { CardHeader, CardContent, Typography } from '@material-ui/core';
import RescuerRecentRescuesTable from '../../components/RescuerRecentRescuesTable';
import routes from '../../routes';
import { DashboardTableCard } from '../../components/Dashboard/DashboardTableCard';

const RescuerRecentRescues = ({
  recentRescues,
  isLoading = false,
  onCellClick,
  recentRescuesTitle = 'Showing all of your past rescues.',
  noRecentRescuesText = 'You have no past rescues.',
  user,
}) => {
  const history = useHistory();

  const onCloseRescueButtonClick = rescue => history.push(generatePath(routes.rescueClose, { rescueId: rescue.id }));

  const onCloseNoShowRescueButtonClick = rescue =>
    history.push(generatePath(routes.rescueCloseNoShow, { rescueId: rescue.id }));

  return (
    <DashboardTableCard isLoading={isLoading}>
      {recentRescues.length > 0 && (
        <CardHeader
          subheader={
            <Typography variant="body2" color="textSecondary">
              {recentRescuesTitle}
            </Typography>
          }
        />
      )}
      <CardContent>
        {isLoading && <Typography>fetching</Typography>}
        {recentRescues.length === 0 && !isLoading && (
          <Typography color="textSecondary" variant="body1">
            {noRecentRescuesText}
          </Typography>
        )}
        {recentRescues.length > 0 && !isLoading && (
          <RescuerRecentRescuesTable
            isLoading={isLoading}
            tableId="#rescuer-dashboard-desktop/recent-rescues"
            user={user}
            rescues={recentRescues}
            onCloseRescueButtonClick={onCloseRescueButtonClick}
            onCloseNoShowRescueButtonClick={onCloseNoShowRescueButtonClick}
            onCellClick={onCellClick}
          />
        )}
      </CardContent>
    </DashboardTableCard>
  );
};

export default RescuerRecentRescues;
