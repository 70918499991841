import Bluebird from 'bluebird';
import { getUnits } from '../api/units';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_UNITS = 'REQUEST_UNITS';
export const RECEIVE_UNITS = 'RECEIVE_UNITS';

export const requestUnits = () => ({
  type: REQUEST_UNITS,
});

export const receiveUnits = units => ({
  type: RECEIVE_UNITS,
  units: units,
  receivedAt: Date.now(),
});

export const fetchUnits = () => dispatch => {
  dispatch(requestUnits());

  return Bluebird
    .try(() => getUnits())
    .then(res => res.json())
    .then(json => dispatch(receiveUnits(json.data)));
};

const shouldFetchUnits = state => {
  const { units } = state.entities;

  if (units.allIds.length === 0) {
    return true;
  }

  if (units.inflight) {
    return false;
  }

  return shouldFetch(units.lastUpdated, { hours: 6 });
};

export const fetchUnitsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchUnits(getState())) {
    return dispatch(fetchUnits());
  }

  return null;
};
