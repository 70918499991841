import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { cardColors, DashboardCard } from './DashboardCard';
import { numberFormatter, percentageFormatter } from '../../helpers/formatters';

const tooltipNumberFormatter = numberFormatter({ notation: 'standard', style: 'decimal' });
const formatter = percentageFormatter();

const totalValuePercentFormatter = value => (isNaN(value) ? undefined : formatter.format(value));

const DashboardEngagementCards = ({ isUnsupported = false, hideCurrentValue = false, inflight = true, engagement }) => {
  const getTotalValue = key =>
    isUnsupported
      ? 'N/A'
      : totalValuePercentFormatter(engagement ? engagement[key].dateRange.activePercentage / 100 : 0);

  const getCurrentValue = key => {
    if (hideCurrentValue) {
      return {
        value: null,
        color: null,
        count: null,
      };
    }

    if (isUnsupported) {
      return {
        value: 'N/A',
        color: null,
        count: null,
      };
    }

    return {
      value: totalValuePercentFormatter(engagement ? engagement[key].deltaPercentage / 100 : 0),
      color: cardColors[Math.sign(engagement ? engagement[key].deltaPercentage / 100 : 0)],
      count: engagement ? engagement[key].priorDateRange.rescuesUniqueCount : 0,
    };
  };

  const renderTooltip = ({ count, rescuesUniqueCount }, type) => (
    <Box>
      <Typography gutterBottom variant="body1">
        <strong>Total {type}</strong>: {tooltipNumberFormatter.format(count)}
      </Typography>
      <Typography variant="body1">
        <strong>Active {type}</strong>: {tooltipNumberFormatter.format(rescuesUniqueCount)}
      </Typography>
    </Box>
  );

  const getTotalValueTooltip = (key, type) => {
    if (isUnsupported || !engagement) {
      return '';
    }

    return renderTooltip(engagement[key].dateRange, type);
  };

  const getCurrentValueTooltip = (key, type) => {
    const currentValue = getCurrentValue(key);

    if (currentValue.count === null || !engagement) {
      return '';
    }

    return renderTooltip(engagement[key].priorDateRange, type);
  };

  const isLoading = isUnsupported ? false : inflight;

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" color="textSecondary">
            Engagement
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DashboardCard
            title="Food Donors"
            isLoading={isLoading}
            totalValue={getTotalValue('donors')}
            currentValue={getCurrentValue('donors')}
            totalValueTooltip={getTotalValueTooltip('donors', 'Food Donors')}
            currentValueTooltip={getCurrentValueTooltip('donors', 'Food Donors')}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DashboardCard
            title="Pickup Locations"
            isLoading={isLoading}
            totalValue={getTotalValue('pickupLocations')}
            currentValue={getCurrentValue('pickupLocations')}
            totalValueTooltip={getTotalValueTooltip('pickupLocations', 'Pickup Locations')}
            currentValueTooltip={getCurrentValueTooltip('pickupLocations', 'Pickup Locations')}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DashboardCard
            title="Receiving Agencies"
            isLoading={isLoading}
            totalValue={getTotalValue('receivers')}
            currentValue={getCurrentValue('receivers')}
            totalValueTooltip={getTotalValueTooltip('receivers', 'Receivers')}
            currentValueTooltip={getCurrentValueTooltip('receivers', 'Receivers')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DashboardCard
            title="Rescuers"
            isLoading={isLoading}
            totalValue={getTotalValue('rescuers')}
            currentValue={getCurrentValue('rescuers')}
            totalValueTooltip={getTotalValueTooltip('rescuers', 'Rescuers')}
            currentValueTooltip={getCurrentValueTooltip('rescuers', 'Rescuers')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardEngagementCards;
