import * as emailsApi from '../api/emails';

export const REQUEST_EMAILS = 'REQUEST_EMAILS';
export const REQUEST_EMAIL = 'REQUEST_EMAIL';
export const RECEIVE_EMAILS = 'RECEIVE_EMAILS';
export const RECEIVE_EMAIL = 'RECEIVE_EMAIL';

export const requestEmails = () => ({
  type: REQUEST_EMAILS,
});

export const requestEmail = () => ({
  type: REQUEST_EMAIL,
});

export const receiveEmails = emails => ({
  type: RECEIVE_EMAILS,
  emails: emails,
  receivedAt: Date.now(),
});

export const receiveEmail = email => ({
  type: RECEIVE_EMAIL,
  email: email,
  receivedAt: Date.now(),
});

export const fetchEmails = (sitesIds = [], type = null, userId = null) => async dispatch => {
  dispatch(requestEmails());

  const res = await emailsApi.getEmails(sitesIds, type, userId);
  const json = await res.json();

  return dispatch(receiveEmails(json.data));
};

export const fetchEmailsIfNeeded = (sitesIds = [], type = null, userId = null) => dispatch => dispatch(
  fetchEmails(sitesIds, type, userId)
);

export const fetchEmail = (emailId) => async dispatch => {
  dispatch(requestEmail());

  const res = await emailsApi.getEmail(emailId);
  const json = await res.json();

  return dispatch(receiveEmail(json.data));
};

export const fetchEmailIfNeeded = (emailId) => dispatch => dispatch(fetchEmail(emailId));
