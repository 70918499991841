import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { isInPWAView } from '../components/Add2HS/PWAInstallButton';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsOfService from '../components/TermsOfService';

const TermsOfServiceView = ({ match }) => {
  const {
    params: { type },
  } = match;
  const history = useHistory();

  return (
    <>
      <TermsOfService type={type} />
      <PrivacyPolicy />

      {/* Display Back button for the PWA users */}
      {isInPWAView && (
        <Button onClick={() => history.go(-1)} variant="contained" color="primary">
          Back
        </Button>
      )}
    </>
  );
};

export default TermsOfServiceView;
