import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { get } from 'lodash';
import useActiveUser from '../../../../hooks/useActiveUser';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import { userBadges } from '../../rescuerDashboardDesktop/myFrus/Components/MyBadges';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../../../../routes';
import { StyledRescuerBreadcrumb, StyledRescuerTextBreadcrumb } from '../Components/StyledRescuerBreadcrumb';
import { Home as HomeIcon } from '@material-ui/icons';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  badgesContainer: {
    height: 'calc(100vh - 100px)', // -100 to remove navbar & breadcrumbs
    overflow: 'scroll',
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const BadgesBreadcrumb = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={RouterLink}
        to={routes.index}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="My badges" />
    </Breadcrumbs>
  </Box>
);

const BadgesDashboardMobileContainer = () => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const badgesEntities = useSelector((state) => state.entities.badges);
  const badgesList = get(badgesEntities, `byRescuerId[${activeUser.id}].badges`, []);
  const badgesLoading = get(badgesEntities, `inflight`, true);
  const { fetchMyBadges } = useRescuerDashboardActions();

  useEffect(() => {
    fetchMyBadges(false, false); //quietMode, force
  }, [activeUser.id, fetchMyBadges]);

  const jsx = userBadges(badgesList);

  if (!badgesLoading && badgesList.length === 0) {
    return (
      <>
        <BadgesBreadcrumb />
        <Box className={classes.badgesContainer}>
          <Box className={classes.container}>
            <Box className={classes.noData}>
              <Typography color="textSecondary" variant="h5">
                Loading badges...
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <BadgesBreadcrumb />
      <Box className={classes.badgesContainer}>
        <Box display="flex" flexWrap="wrap" gridColumnGap="5px" gridRowGap="5px" px="5px" overflow="scroll">
          {jsx}
          <Typography color="textSecondary" variant="body2">
            Badges are awarded based on the number of closed rescues.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default BadgesDashboardMobileContainer;
