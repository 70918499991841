import React, { useEffect, useState } from 'react';
import Bluebird from 'bluebird';
import { debounce } from 'lodash';
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import * as zipCodesApi from '../api/zipCodes';
import { getZipCodeSelectOption } from '../helpers/getters';

const ZipCodeSelect = ({
  name,
  className,
  onChangeZipCode,
  inputRef,
  defaultValue,
  formValue,
  error,
  required = false,
  variant = 'outlined',
  showValueOnly = false,
}) => {
  const [zipCodesList, setZipCodesList] = useState([]);
  const [selectedZipCode, setSelectedZipCode] = useState(false);
  const [fetchingZipCodes, setFetchingZipCodes] = useState(false);
  const filter = createFilterOptions({
    stringify: option => option.label,
  });

  useEffect(() => {
    if (formValue) {
      setSelectedZipCode({ label: formValue, value: formValue });
    }
  }, []);

  const fetchZipCodes = value => {
    setFetchingZipCodes(true);
    setZipCodesList([]);

    if (value.length <= 2) {
      return new Promise(resolve => {
        resolve([]);

        setFetchingZipCodes(false);
        setZipCodesList([]);
      });
    }

    return Bluebird
      .try(() => zipCodesApi.getZipCodes(value))
      .then(res => res.json())
      .then(res => res.data)
      .then(data => {
        setFetchingZipCodes(false);
        setZipCodesList(data.map(zipCode => getZipCodeSelectOption(zipCode)));
      });
  };

  const debounceFetch = debounce(fetchZipCodes, 300);

  const handleZipCode = option => {
    setSelectedZipCode(option ? option : false);

    return onChangeZipCode(option);
  };

  return (
    <Autocomplete
      freeSolo
      autoSelect
      autoHighlight
      value={selectedZipCode}
      options={zipCodesList}
      onChange={(event, newValue) => {
        let option = newValue;

        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return;
        }

        return handleZipCode(option, event);
      }}
      loading={fetchingZipCodes}
      filterOptions={(options, params) => filter(options, params)}
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={option => {
        if (!option) {
          return '';
        }

        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }

        return showValueOnly ? option.value : option.label;
      }}
      renderOption={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          required={required}
          id={name ? name : 'zip_code'}
          name={name ? name : 'zip_code'}
          className={className || undefined}
          variant={variant}
          size="small"
          label="Zip Code"
          error={!!error}
          helperText={error ? error.message : ''}
          onKeyDown={event => {
            // this fixes dropdown user select closing when space is pressed
            if (event.keyCode === 32) {
              event.preventDefault();
            }
          }}
          onChange={event => debounceFetch(event.target.value)}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {fetchingZipCodes ? <CircularProgress color="inherit" size={20} /> : null}

                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          InputLabelProps={{ shrink: !!formValue || undefined }}
          inputRef={inputRef}
        />
      )}
    />
  );
};

export default ZipCodeSelect;
