import { UPDATE_RESCUER_COUNTER_BADGE, UPDATE_SC_DASHBOARD_COUNTER_BADGE } from '../actions/misc';

const initialSiteState = {
  rescuerMenuBadges: {},
  scDashboardMenuBadges: {},
};

const miscReducer = (state = initialSiteState, action) => {
  switch (action.type) {
    case UPDATE_RESCUER_COUNTER_BADGE:
      return {
        ...state,
        rescuerMenuBadges: {
          ...state.rescuerMenuBadges,
          [action.payload.name]: action.payload.value,
        },
      };
    case UPDATE_SC_DASHBOARD_COUNTER_BADGE:
      return {
        ...state,
        scDashboardMenuBadges: {
          ...state.scDashboardMenuBadges,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default miscReducer;
