import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button, Card, CardActions, CardContent, makeStyles } from '@material-ui/core';
import * as authService from '../services/auth';
import routes from '../routes';

const PausedRescuerNotification = () => {
  const user = authService.getCurrentlyLoggedInOrImpersonatingUser();

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#e1ebfd',
      width: '100%',
      paddingLeft: () => {
        if (user && !authService.shouldHideMainDrawer(user)) {
          return 70;
        }

        return 16;
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0px !important',
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  }));

  const classes = useStyles();
  const history = useHistory();

  const onProfile = () => {
    history.push(generatePath(routes.profile));
  };

  if (!user.paused_at) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: 0 }}>
        <div className={classes.title}>Rescuing is paused</div>

        <div className={classes.body}>
          You can't claim rescues until you have resumed rescuing. To do it visit your profile and resume it there.
        </div>
      </CardContent>

      <CardActions>
        <Button size="small" color="primary" variant="contained" onClick={onProfile}>
          Visit Profile
        </Button>
      </CardActions>
    </Card>
  );
};

export default PausedRescuerNotification;
