import React, { useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { CardContent, Typography, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import OverlayLoader from '../OverlayLoader';
import { BaseCard, BaseCardHeader } from '../Common/BaseCard';
import { percentageFormatter } from '../../helpers/formatters';

const formatter = percentageFormatter({ maximumFractionDigits: 1 });

const ChartLoadingIndicator = ({ height }) => (
  <Box display="flex" flexDirection="column" height={height} alignItems="center" justifyContent="space-between">
    <Box p={2} pb={0}>
      <Skeleton variant="circle" width={150} height={150} />
    </Box>

    <Box display="flex" flexDirection="column" mt={1} p={2} pt={0}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="row" flexGrow={1} flexBasis={0}>
          <Box mx={1} mr={0}>
            <Skeleton height={15} width={40} />
          </Box>

          <Box mx={1} mr={1}>
            <Skeleton height={15} width={70} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexGrow={1} flexBasis={0}>
          <Box mx={1} mr={0}>
            <Skeleton height={15} width={40} />
          </Box>

          <Box mx={1} mr={1}>
            <Skeleton height={15} width={80} />
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="row" flexGrow={1} flexBasis={0}>
          <Box mx={1} mr={0}>
            <Skeleton height={15} width={40} />
          </Box>

          <Box mx={1} mr={1}>
            <Skeleton height={15} width={75} />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" flexGrow={1} flexBasis={0}>
          <Box mx={1} mr={0}>
            <Skeleton height={15} width={40} />
          </Box>

          <Box mx={1} mr={1}>
            <Skeleton height={15} width={75} />
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

const DashboardChartCard = ({ title, data, isLoading }) => {
  const chartRef = useRef();

  return (
    <OverlayLoader isLoading={isLoading}>
      <BaseCard>
        <BaseCardHeader title={title} />

        <CardContent>
          {isLoading && (
            <ChartLoadingIndicator height={260} />
          )}

          {!isLoading && (
            <>
              {data.labels.length <= 0 && <Typography color="textSecondary" variant="h5">N/A</Typography>}

              {data.labels.length > 0 && (
                <Pie
                  ref={chartRef}
                  height={260}
                  dataKey="value"
                  data={data}
                  options={{
                    tooltips: {
                      callbacks: {
                        label: ({ index }, { datasets, labels }) => {
                          const chartId = chartRef.current.chartInstance.id;
                          const dataset = datasets[0];

                          return ` ${labels[index]}: ${dataset['data'][index]} / ${formatter.format(
                            dataset['data'][index] / dataset['_meta'][chartId]['total']
                          )}`;
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    legend: {
                      position: 'right',
                    },
                  }}
                />
              )}
            </>
          )}
        </CardContent>
      </BaseCard>
    </OverlayLoader>
  );
};

export default DashboardChartCard;
