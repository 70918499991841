import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import routes from '../routes';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import ViewNotSupportedCard from './ViewNotSupportedCard/ViewNotSupportedCard';

const PrivateRoute = ({ component: Component, forceRender = false, width, ...rest }) => {
  let hasAccess = true;
  let pathname = '';
  const user = authService.getCurrentlyLoggedInOrImpersonatingUser();

  if (!authService.getCurrentlyLoggedInUser()) {
    hasAccess = false;
    pathname = routes.login;
  }

  const isMobileView = isWidthDown('xs', width);
  const render = props => {

    if (hasAccess) {
      const component = <Component {...props} isMobileView={isMobileView} />;

      if (forceRender) {
        return component;
      }

      const isRescuer = authService.hasRoleInAnySite(user, Roles.Rescuer);
      const isDonor = authService.hasAnyRoleInCurrentlySelectedSite(user, [
        Roles.DonorAdmin,
        Roles.DonorStaff,
        Roles.DonorLocationStaff,
        Roles.DonorLocationAdmin,
        Roles.SiteDirector,
        Roles.SiteCoordinator,
      ]);
      if ((!isDonor && !isRescuer) && isMobileView) {
        return <ViewNotSupportedCard {...props} isMobileView={isMobileView} />
      }

      return component;
    }

    return <Redirect to={{ pathname, state: { from: rest.location } }} />;
  };

  return <Route {...rest} render={render} />;
};

export default withWidth()(PrivateRoute)
