import React, { useEffect, useState } from 'react';
import { Checkbox, ListItem, ListItemText } from '@material-ui/core';

const RescueAvailabilityFilter = ({ value, handleAvailabilityChange }) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    handleAvailabilityChange(isChecked);
  }, [isChecked]);

  const toggleCheckbox = () => setIsChecked(!isChecked);

  return (
    <ListItem button onClick={toggleCheckbox}>
      <Checkbox edge="start" checked={isChecked} tabIndex={-1} disableRipple />
      <ListItemText primary="Show unclaimed rescues only." />
    </ListItem>
  );
};

export default RescueAvailabilityFilter;
