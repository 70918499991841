import Bluebird from 'bluebird';
import * as reportsApi from '../api/reports';

export const REQUEST_RESCUED_FOOD = 'REQUEST_RESCUED_FOOD';
export const RECEIVE_RESCUED_FOOD = 'RECEIVE_RESCUED_FOOD';

export const requestRescuedFood = () => ({
  type: REQUEST_RESCUED_FOOD,
});

export const receiveRescuedFood = rescuedFood => ({
  type: RECEIVE_RESCUED_FOOD,
  rescuedFood: rescuedFood,
  receivedAt: Date.now(),
});

export const fetchRescuedFood = (sitesIds = [], from = null, to = null) => dispatch => {
  dispatch(requestRescuedFood());

  return Bluebird
    .try(() => reportsApi.getRescuedFood(sitesIds, from, to))
    .then(res => res.json())
    .then(json => dispatch(receiveRescuedFood(json.data)));
};

export const fetchRescuedFoodIfNeeded = (sitesIds = [], from = null, to = null) => dispatch => dispatch(
  fetchRescuedFood(sitesIds, from, to)
);
