import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviousDateRangePeriod, isMetricSupported } from '../../../../helpers/metrics';
import { fetchSitesIfNeeded } from '../../../../actions/sites';
import { fetchMetrics } from '../../../../actions/metrics';
import { dateRangeOptionsList } from '../../../../components/DateAndGranularityPicker/DateAndGranularityPicker';
import DashboardGrowthCards from '../../../../components/Dashboard/DashboardGrowthCards';
import DashboardEngagementCards from '../../../../components/Dashboard/DashboardEngagementCards';
import PagePaper from '../../../../components/Common/PagePaper';
import SitesAndDateFilter from './SitesAndDateFilter';
import { getEarliestSiteDate } from '../../../../helpers/getters';

const NSDAtAGlance = () => {
  const dispatch = useDispatch();
  const sites = useSelector(state => state.entities.sites);
  const metrics = useSelector(state => state.entities.metrics);
  const sitesList = sites.allIds.map(id => sites.byId[id]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedDateRangePeriod, setSelectedDateRangePeriod] = useState(dateRangeOptionsList.thisMonth.value);
  const selectedSitesWithAllData = selectedSites.map(id => sites.byId[id]);

  useEffect(() => {
    dispatch(fetchSitesIfNeeded());
  }, [dispatch]);

  return (
    <div>
      <PagePaper mt={1}>
        <SitesAndDateFilter
          customDateRangeOptions={{
            maxDate: new Date(),
            minDate: getEarliestSiteDate(selectedSitesWithAllData, sitesList),
          }}
          sites={sitesList}
          disabled={metrics.inflight}
          handleChange={state => {
            setSelectedSites(state.sites);
            setSelectedDateRangePeriod(state.dateRange.period);
            const previousPeriod = getPreviousDateRangePeriod(state.dateRange.period, state.dateRange);
            return dispatch(fetchMetrics(previousPeriod, state.dateRange, state.sites));
          }}
        />
      </PagePaper>

      <DashboardGrowthCards
        isUnsupported={!isMetricSupported(selectedSites, selectedDateRangePeriod)}
        hideCurrentValue={selectedDateRangePeriod === 'all'}
        inflight={metrics.inflight}
        growth={metrics.all.growth}
        showSitesGrowth={!selectedSites.length}
        showMealsLbsGrowth
        showMealsValueGrowth
      />

      <DashboardEngagementCards
        isUnsupported={
          selectedDateRangePeriod === 'custom' || !isMetricSupported(selectedSites, selectedDateRangePeriod)
        }
        hideCurrentValue={selectedDateRangePeriod === 'all' || selectedDateRangePeriod === 'custom'}
        inflight={metrics.inflight}
        engagement={metrics.all.engagement}
      />
    </div>
  );
};

export default NSDAtAGlance;
