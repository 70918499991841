import React, { useEffect } from 'react';
import brokenImage from '../../../../../assets/images/rescue-image-broken.png';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { Box, Dialog, Typography, IconButton, withStyles, makeStyles } from '@material-ui/core';
import {
  Close as CloseIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  CloudDownloadTwoTone as CloudDownloadIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import DialogDetails from './DialogDetails';
import routes from '../../../../../routes';
import { Colors } from '../../../../../assets/theme/Colors';
import { downloadFileByURL } from '../../../../../helpers/files';

const MAX_DETAILS_TITLE_HEIGHT = 48;
const useDesktopDialogStyles = makeStyles(() => ({
  navigationWrapper: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  dialogImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  detailsPanelTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: MAX_DETAILS_TITLE_HEIGHT,
  },
  detailsPanelContent: {
    height: `calc(100% - ${MAX_DETAILS_TITLE_HEIGHT}px)`,
    overflow: 'scroll',
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
}));

const NavigationIcon = withStyles({
  label: {
    border: '2px solid white',
    borderRadius: '50%',
    boxShadow: ' 0 0 2pt 0.5pt black',
    color: '#fff',
    '& > svg': {
      filter: 'drop-shadow(0.9px 0.9px 1px black)',
    },
  },
  disabled: {
    opacity: 0.3,
  },
  left: {
    float: 'left',
  },
  right: {
    float: 'right',
  },
})(({ children, classes, isLeft, isRight, onClick, ...other }) => (
  <IconButton
    onClick={onClick}
    classes={{
      label: classes.label,
      disabled: classes.disabled,
    }}
    className={classNames({
      [classes.left]: isLeft,
      [classes.right]: isRight,
    })}
    {...other}
  >
    {children}
  </IconButton>
));

const DesktopDialog = ({
  rescuePhotoData,
  disablePrevButton,
  disableNextButton,
  handleClose,
  handleNextPhoto,
  handlePrevPhoto,
}) => {
  const rescueDetailsText = 'Rescue Details';
  const handleKeydown = event => {
    if (event.key === 'ArrowLeft') {
      return handlePrevPhoto();
    }

    if (event.key === 'ArrowRight') {
      return handleNextPhoto();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeydown);

    return () => document.removeEventListener('keyup', handleKeydown);
  }, [rescuePhotoData]);

  const classes = useDesktopDialogStyles();

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={handleClose} aria-labelledby="desktop-dialog">
      <Box display="flex" overflow="hidden">
        <Box flexGrow={3} flexBasis={1} position="relative">
          <Box className={classes.navigationWrapper}>
            <NavigationIcon isLeft onClick={handlePrevPhoto} disabled={disablePrevButton}>
              <NavigateBeforeIcon fontSize="small" />
            </NavigationIcon>
            <NavigationIcon isRight onClick={handleNextPhoto} disabled={disableNextButton}>
              <NavigateNextIcon fontSize="small" />
            </NavigationIcon>
          </Box>
          <IconButton
            disabled={!rescuePhotoData.url}
            onClick={() => downloadFileByURL({
              url: rescuePhotoData.url,
              fileName: rescuePhotoData.name,
            })}
            aria-label="download photo"
            style={{
              position: 'absolute',
              top: 10,
              right: 20,
              color: Colors.white,
            }}
          >
            <CloudDownloadIcon color="secondary" fontSize="large" />
          </IconButton>

          <img
            src={rescuePhotoData.url}
            onError={e => {
              e.target.onerror = null;
              e.target.src = brokenImage;
              e.target.alt = 'Image not found';
            }}
            alt="Image"
            loading="lazy"
            className={classes.dialogImage}
          />
        </Box>
        <Box flexGrow={1} flexBasis={1} p={1}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box className={classes.detailsPanelTitle}>
              <Box p={1}>
                <Typography variant="h6" id="dialog-title">
                  {rescuePhotoData.rescue ? (
                    <Link to={generatePath(routes.rescue, { rescueId: rescuePhotoData.rescue_id })}>
                      {rescueDetailsText}
                    </Link>
                  ) : (
                    rescueDetailsText
                  )}
                </Typography>
              </Box>
              <Box>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            <Box className={classes.detailsPanelContent}>
              <DialogDetails rescuePhotoData={rescuePhotoData} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DesktopDialog;
