import { objectFromArray } from '../helpers';
import * as unitsActions from '../actions/units';

export const initialSitesState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const unitsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case unitsActions.REQUEST_UNITS:
      return {
        ...state,
        inflight: true,
      };
    case unitsActions.RECEIVE_UNITS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.units, unit => unit.id),
        allIds: action.units.map(unit => unit.id),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default unitsReducer;
