import _array from 'lodash/array';
import omit from 'lodash/omit';
import * as usersActions from '../actions/users';
import * as usersModel from '../models/users';
import { objectFromArray, objectFromArrayWithDup } from '../helpers';
import { DELETE_USER, DELETE_USER_WITHOUT_ROLES } from '../actions/users';

const usersInitialState = {
  byId: {},
  allIds: [],
  modifiedIds: [],
  modifiedById: {},
  inflight: false,
  withoutRoles: {
    byId: {},
    allIds: [],
    inflight: false,
  },
  notificationsPreferences: {
    byUserId: [],
    lastUpdated: null,
  },
  identities: {
    byUserId: [],
    inflight: false,
    lastUpdated: null,
  },
};

export const deleteUserReducer = (state, userId) => {
  return {
    byId: omit(state.byId, userId),
    allIds: state.allIds.filter(id => id !== userId),
  };
};

const usersReducer = (state = usersInitialState, action) => {
  switch (action.type) {
    case DELETE_USER:
      return {
        ...state,
        ...deleteUserReducer(state, action.user.id),
      };
    case DELETE_USER_WITHOUT_ROLES:
      return {
        ...state,
        withoutRoles: {
          ...state.withoutRoles,
          ...deleteUserReducer(state.withoutRoles, action.user.id),
        },
      };
    case usersActions.REQUEST_USERS:
      return {
        ...state,
        inflight: true,
      };
    case usersActions.REQUEST_USER:
      return {
        ...state,
        inflight: true,
      };
    case usersActions.REQUEST_USERS_WITHOUT_ROLES:
      return {
        ...state,
        withoutRoles: {
          ...state.withoutRoles,
          inflight: true,
        },
      };
    case usersActions.RECEIVE_USERS_WITHOUT_ROLES:
      const usersWR = action.users.map(user => usersModel.apiToStore(user));

      return {
        ...state,
        withoutRoles: {
          ...state.withoutRoles,
          inflight: false,
          allIds: usersWR.map(d => d.id),
          byId: objectFromArray(usersWR, d => d.id),
          lastUpdated: action.receivedAt,
        },
      };
    case usersActions.RECEIVE_USERS:
      const users = action.users.map(user => usersModel.apiToStore(user));

      return {
        ...state,
        inflight: false,
        allIds: users.map(d => d.id),
        byId: objectFromArray(users, d => d.id),
        lastUpdated: action.receivedAt,
      };
    case usersActions.RECEIVE_USER:
      return {
        ...state,
        inflight: false,
        ..._addOrUpdateUser(usersModel.apiToStore(action.user), state.byId, state.allIds),
      };
    case usersActions.REQUEST_USER_NOTIFICATIONS_PREFERENCES:
      return {
        ...state,
        notificationsPreferences: {
          ...state.notificationsPreferences,
          ...{
            inflight: true,
          },
        },
      };
    case usersActions.RECEIVE_USER_NOTIFICATIONS_PREFERENCES:
      return {
        ...state,
        notificationsPreferences: {
          inflight: false,
          byUserId: objectFromArrayWithDup(
            action.userNotificationsPreferences,
            userNotificationPreference => userNotificationPreference.user_id
          ),
          lastUpdated: action.receivedAt,
        },
      };
    case usersActions.REQUEST_USER_IDENTITIES:
      return {
        ...state,
        identities: {
          ...state.identities,
          inflight: true,
        },
      };
    case usersActions.RECEIVE_USER_IDENTITIES:
      return {
        ...state,
        identities: {
          ...state.identities,
          inflight: false,
          byUserId: {
            ...state.identities.byUserId,
            [action.userId]: action.userIdentities,
          },
          lastUpdated: action.receivedAt,
        },
      };
    default:
      return state;
  }
};

const _addOrUpdateUser = (user, byId, allIds) => ({
  byId: {
    ...byId,
    ...{
      [user.id]: user,
    },
  },
  allIds: _array.uniq([...allIds, ...[user.id]]),
});

export default usersReducer;
