import React from 'react';
import PropTypes from 'prop-types';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { getMuiTableDataIndex } from '../helpers/getters';

const UmbrellaFoodDonorLocationsTable = ({
  tableId,
  isLoading,
  sites,
  umbrellaFoodDonorLocations,
  actionButtons,
  customToolbar,
}) => (
  <BaseMUIDataTable
    tableId={tableId}
    title="Food Donors"
    isLoading={isLoading}
    data={umbrellaFoodDonorLocations}
    columns={[
      {
        name: 'site_id',
        label: 'Site',
        options: {
          customBodyRender: (value) => {
            if (!sites[value]) {
              return 'n/a';
            }

            return sites[value].name;
          },
          filter: false,
        },
      },
      {
        name: 'name',
        label: 'Food Donor Name',
        options: { filter: false },
      },
      {
        name: 'address1',
        label: 'Address',
      },
      {
        name: 'city',
        label: 'City',
      },
      {
        name: 'st',
        label: 'ST',
      },
      {
        name: 'zip',
        label: 'Zip',
      },
      {
        name: 'makes_donations',
        label: 'Makes Donations',
        options: {
          filter: true,
          customBodyRender: (value) => (value === 1 ? 'Yes' : 'No'),
        },
      },
      {
        name: 'actions',
        label: 'Actions',
        options: {
          download: false,
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, tableMeta) => {
            const foodDonorLocation = umbrellaFoodDonorLocations[getMuiTableDataIndex(tableMeta)];
            return actionButtons(foodDonorLocation);
          },
        },
      },
    ]}
    options={{
      responsive: 'simple',
      selectableRows: 'none',
      customToolbar: customToolbar,
    }}
  />
);

UmbrellaFoodDonorLocationsTable.propTypes = {
  tableId: PropTypes.string.isRequired,
};

export default UmbrellaFoodDonorLocationsTable;
