import Bluebird from 'bluebird';
import * as pastRescuesApi from '../api/pastRescues';
import * as pastRescuesModel from '../models/pastRescue';

export const REQUEST_PAST_RESCUES = 'REQUEST_PAST_RESCUES';
export const RECEIVE_PAST_RESCUES = 'RECEIVE_PAST_RESCUES';

export const requestPastRescues = () => ({
  type: REQUEST_PAST_RESCUES,
});

export const receivePastRescues = pastRescues => ({
  type: RECEIVE_PAST_RESCUES,
  pastRescues,
});

export const fetchPastRescues = (siteIds, from, to, umbrellaFoodDonorId = null) => dispatch => {
  dispatch(requestPastRescues());

  return Bluebird
    .try(() => pastRescuesApi.getPastRescues(siteIds, from, to, umbrellaFoodDonorId))
    .then(res => res.json())
    .then(json => dispatch(receivePastRescues(json.data)));
};

export const updatePastRescues = (pastRescues, values, sites = []) => (dispatch) =>
  Bluebird.try(() => pastRescuesApi.updatePastRescues(pastRescuesModel.mapFormPastRescuesPATCH(pastRescues)))
    .then(() => dispatch(fetchPastRescues(sites, values.dateRange.startDate, values.dateRange.endDate)));
