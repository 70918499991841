import queryString from 'query-string';
import { apiGET, apiPOST, apiPUT, apiDELETE } from './api';

export const getDonationRequests = (props = {}) => {
  const query = queryString.stringify(
    {
      sites_ids: props.siteIds,
      food_donor_id: props.locationId,
      verification_status: props.verificationStatus,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/donation_requests?${query}`);
};

export const declineDonationRequest = (donationId, reason) =>
  apiPOST(`/donation_requests/${donationId}/decline`, {
    message: reason,
  });

export const getRequestDonation = requestDonationId => {
  return apiGET(`/donation_requests/${requestDonationId}`);
};

export const createDonation = donation => {
  return apiPOST('/donation_requests', donation);
};

export const updateDonation = (donationId, data) => {
  return apiPUT(`/donation_requests/${donationId}`, data);
};

export const deleteDonation = (donationId) => {
  return apiDELETE(`/donation_requests/${donationId}`);
};
