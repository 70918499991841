import { objectFromArray } from '../helpers';
import * as rescueSizesActions from '../actions/rescueSizes';

export const initialState = {
  inflight: false,
  byId: {},
  allIds: [],
  sorted: [],
  lastUpdated: null,
};

const rescueSizesReducer = (state = initialState, action) => {
  switch (action.type) {
    case rescueSizesActions.REQUEST_RESCUE_SIZES:
      return {
        ...state,
        inflight: true,
      };
    case rescueSizesActions.RECEIVE_RESCUE_SIZES:
      // Sort vehicle sizes to diplay Compact Sedan and Sedan first
      const rescueSizes = [...action.rescueSizes];
      const rescueSizesSorted = rescueSizes.sort((a, b) => a.meals_per_rescue - b.meals_per_rescue);

      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.rescueSizes, rescueSize => rescueSize.id),
        allIds: action.rescueSizes.map(rescueSize => rescueSize.id),
        sorted: rescueSizesSorted,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default rescueSizesReducer;
