import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Bluebird from 'bluebird';
import { withStyles, Paper, Grid, Switch, FormControlLabel, Button, Typography } from '@material-ui/core';
import snackbarHelper from '../helpers/snackbarHelper';

class SettingsEditForm extends Component {
  handleSubmit = updateSettings => values => Bluebird
    .try(() => updateSettings(values))
    .then(() => snackbarHelper.success('Default sites settings updated successfully!'))
    .catch(err => {
      snackbarHelper.error(err.message);

      return { [FORM_ERROR]: 'error' };
    });

  renderSwitch = ({ input, label }) => (
    <FormControlLabel
      control={
        <Switch
          checked={input.checked}
          onChange={event => input.onChange(event.target.checked)}
        />
      }
      label={label}
    />
  );

  render() {
    const { site, settings, onSubmit, title, useTopButtonsLayout, classes } = this.props;
    let initialValues = {};

    settings.map(setting => initialValues[setting.name] = setting.value === '1');

    return (
      <Form
        onSubmit={this.handleSubmit(onSubmit)}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine, invalid, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  {useTopButtonsLayout && (
                    <Grid spacing={3} container direction="row" justify="space-between" alignItems="center">
                      <Grid item xs>
                        <Typography component="h4">
                          {title}
                        </Typography>
                      </Grid>

                      <Grid item xs>
                        <div className={classes.buttonsWrapper}>
                          <Button
                            data-testid="site-feature-flag-submit-button"
                            variant="contained"
                            color="primary"
                            onClick={() => onSubmit(
                              site.id,
                              settings.map(setting => ({ id: setting.id, value: values[setting.name] }))
                            )}
                          >
                            Save
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  {!useTopButtonsLayout && (
                    <Typography color="textPrimary" variant="h6">
                      {title}
                    </Typography>
                  )}

                  <Field
                    name="notifications_send_emails"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Send Emails"
                  />

                  <Field
                    name="notifications_send_sms"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Send SMS Messages"
                  />

                  <Field
                    name="notifications_send_push"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Send Push Messages"
                  />

                  <Field
                    name="use_ses_simulator"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Use SES Simulator"
                  />

                  <Field
                    name="use_mailtrap_for_emails"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Use Mailtrap For Emails"
                  />

                  <Field
                    name="use_mailtrap_for_sms"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Use Mailtrap For SMS"
                  />

                  <Field
                    name="email_whitelist_only"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Email Whitelist Only"
                  />

                  <Field
                    name="sms_whitelist_only"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="SMS Whitelist Only"
                  />

                  <Field
                    name="use_google_maps_geocode"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Use Google Maps For Geocoding"
                  />
  
                  <Field
                    name="use_google_maps_distance_matrix"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Use Google Maps For Calculating Distance & Duration"
                  />

                  <Field
                    name="custom_branding"
                    type="checkbox"
                    component={this.renderSwitch}
                    label="Custom Branding"
                  />

                  {!useTopButtonsLayout && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={pristine || invalid || submitting}
                      className={classes.button}
                    >
                      Update Default Sites Settings
                    </Button>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing(1),
    width: 300,
  },
  buttonsWrapper: {
    float: 'right',
    '& button:first-child': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
});

export default withStyles(styles)(SettingsEditForm);
