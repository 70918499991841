import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  MenuItem,
  Popper,
  Box,
  Paper,
  Grow,
  Button,
  ListItemText,
  InputLabel,
  Select,
} from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import get from 'lodash/get';
import 'react-date-range/dist/styles.css'; // date range picker style file
import 'react-date-range/dist/theme/default.css'; // date range picker theme css file
import '../components/DateAndGranularityPicker/DateAndGranularityPicker.css';

export const RegistrationProcessFilter = {
  all: 'All',
  completed: 'Completed Registration',
  notCompleted: 'Not Completed Registration',
  last30Days: 'Last 30 days',
  last90Days: 'Last 90 days',
  custom: 'Custom Date Range',
};

const RenderOptions = ({ filterList, onChange, index, column }) => {
  const filterRef = useRef();
  const site = useSelector(state => state.app.site);
  const optionValues = [
    RegistrationProcessFilter.all,
    RegistrationProcessFilter.completed,
    RegistrationProcessFilter.notCompleted,
    RegistrationProcessFilter.last30Days,
    RegistrationProcessFilter.last90Days,
    RegistrationProcessFilter.custom,
  ];
  const [isCustomDateRangeVisible, setCustomRangeVisible] = useState(false);
  const [getRanges, setRanges] = useState([
    {
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
    },
  ]);

  const updateRanges = (selected) => setRanges([selected.selection]);
  const setCustomRange = () => {
    filterList[index] = [
      'custom',
      [moment(getRanges[0].startDate).format('MM/DD/YYYY'), moment(getRanges[0].endDate).format('MM/DD/YYYY')],
    ];
    onChange(filterList[index], index, column);
    setCustomRangeVisible(false);
  };

  const selectedValue = () => {
    if (filterList[index].length === 0) {
      return RegistrationProcessFilter.all;
    }

    if (filterList[index][0] === 'static') {
      return filterList[index][1];
    }

    if (filterList[index][0] === 'custom') {
      return RegistrationProcessFilter.custom;
    }

    return filterList[index];
  };

  const siteCreationDate = moment(site.created_at).toDate();

  return (
    <>
      <FormControl ref={filterRef}>
        <InputLabel htmlFor="select-multiple-chip">Registration</InputLabel>
        <Select
          value={selectedValue()}
          onChange={event => {
            if (RegistrationProcessFilter.custom === event.target.value) {
              return setCustomRangeVisible(true);
            }

            if (RegistrationProcessFilter.all === event.target.value) {
              filterList[index] = [];
              return onChange(filterList[index], index, column);
            }

            filterList[index] = ['static', event.target.value];
            return onChange(filterList[index], index, column);
          }}
        >
          {optionValues.map(item => (
            <MenuItem key={item} value={item}>
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Popper
        open={isCustomDateRangeVisible && Boolean(filterRef.current)}
        anchorEl={get(filterRef, 'current', undefined)}
        role={undefined}
        placement="bottom-end"
        transition
        style={{ zIndex: 1400 }}
        className="dateAndGranularityPickerModal"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper style={{ padding: 20, border: '1px solid rgb(170, 170, 170)' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DateRangePicker
                  ranges={getRanges}
                  minDate={siteCreationDate}
                  maxDate={moment().toDate()}
                  rangeColors={['#3f51b5']}
                  onChange={updateRanges}
                  staticRanges={[]}
                  inputRanges={[]}
                />
                <Box display="flex" flexDirection="row" justifyContent="flex-end" gridColumnGap={8}>
                  <Button type="button" variant="contained" color="secondary" size="small" onClick={() => setCustomRangeVisible(false)}>
                    Cancel
                  </Button>
                  <Button type="button" variant="contained" color="primary" size="small" onClick={setCustomRange}>
                    Apply
                  </Button>
                </Box>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const userRegistrationFilterOptions = {
  filterType: 'custom',
  customFilterListOptions: {
    render: (value) => {
      switch (value[0]) {
        case 'custom':
          return `Registration: ${value[1].join(' - ')}`;
        case 'static':
          return `Registration: ${value ? value[1] : null}`;
        default:
          return `Registration: ${value ? value[1] : null}`;
      }
    },
  },
  filterOptions: {
    logic: (registrationDate, filters) => {
      if (!filters || filters.length === 0) {
        return false;
      }

      // some tables have MM/DD/YYYY format, other ISO format
      const dateFormat = /\d{2}\/\d{2}\/\d{4}/.test(registrationDate) ? 'MM/DD/YYYY' : undefined;
      const dateTimeFormat = /\d{2}\/\d{2}\/\d{4}/.test(registrationDate) ? 'MM/DD/YYYY 00:00:00' : undefined;

      const last30Days = moment().endOf('day').subtract(30, 'd');
      const last90Days = moment().endOf('day').subtract(90, 'd');

      if (filters[0] === 'static' && filters[1] === RegistrationProcessFilter.completed) {
        return registrationDate === null;
      }

      if (filters[0] === 'static' && filters[1] === RegistrationProcessFilter.notCompleted) {
        return registrationDate !== null;
      }

      if (filters[0] === 'static' && filters[1].includes(RegistrationProcessFilter.last30Days)) {
        return !moment(registrationDate, dateFormat).isSameOrAfter(last30Days);
      }

      if (filters[0] === 'static' && filters[1].includes(RegistrationProcessFilter.last90Days)) {
        return !moment(registrationDate, dateFormat).isSameOrAfter(last90Days);
      }

      if (filters[0] === 'custom' && filters[1].length === 2) {
        const startDate = moment(filters[1][0], 'MM/DD/YYYY');
        const endDate = moment(filters[1][1], 'MM/DD/YYYY');

        return !moment(registrationDate, dateTimeFormat).isBetween(startDate, endDate, null, '[]');
      }

      return undefined;
    },
    display: (filterList, onChange, index, column) => (
      <RenderOptions filterList={filterList} onChange={onChange} column={column} index={index} />
    ),
  },
};

export const noneAnyFilter = {
  names: ['None', '> 0'],
  logic(value, filter) {
    switch (filter) {
      case noneAnyFilter.names[0]:
        return value !== 0;
      case noneAnyFilter.names[1]:
        return value === 0;
      default:
        console.warn('Unhandled #closed filter value');
        return false;
    }
  },
};

export const rangeFilter = {
  names: ['0-20', '21-50', '>51'],
  logic(value, filter) {
    switch (filter) {
      case rangeFilter.names[0]:
        return value > 20;
      case rangeFilter.names[1]:
        return value < 20 || value > 50;
      case rangeFilter.names[2]:
        return value < 50;
      default:
        console.warn(`Unhandled range filter value: ${filter}`);
        return false;
    }
  },
};
