import Joi from '@hapi/joi';

export const isPhoneMaskValid = phoneNumber => /([2-9]\d{2}-[2-9]\d{2}-\d{4})/.test(phoneNumber);

export const isFormStateValid = formState => !(formState.hasSubmitErrors || formState.hasValidationErrors);

export const checkPasswordComplexity = password => {
  if (password.length < 8) {
    return false;
  }

  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);

  return hasUpperCase && hasLowerCase && hasNumbers === true;
};

export const getPasswordsValidationErrors = (values, passwordFieldName, passwordConfirmationFieldName) => {
  const errors = {};
  
  if (!values[passwordFieldName]) {
    errors[passwordFieldName] = 'Required';
  }
  
  if (!values[passwordConfirmationFieldName]) {
    errors[passwordConfirmationFieldName] = 'Required';
  }
  
  if (values[passwordFieldName] && !checkPasswordComplexity(values[passwordFieldName])) {
    errors[passwordFieldName] = 'Password is too weak';
  }
  
  if (
    values[passwordFieldName]
    && values[passwordConfirmationFieldName]
    && values[passwordFieldName] !== values[passwordConfirmationFieldName]
  ) {
    errors[passwordConfirmationFieldName] = 'Password mismatch!';
  }
  
  return errors;
};

export const isEmailValid = email => {
  const { error } = Joi
    .object()
    .keys({ email: Joi.string().email({ tlds: { allow: false } }) })
    .validate({ email: email });
  return !error;
};

export const isFormFieldInvalid = meta =>
  ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error || (meta.data && meta.data.error)) &&
  (meta.touched || (meta.data && meta.data.touched));

export const getFormFieldError = meta => meta.error || meta.submitError || (meta.data && meta.data.error) || null;
