import React from 'react';
import ACLService from '../services/acl';
import useActiveUser from './useActiveUser';
import useActiveSite from './useActiveSite';

/**
 * Check for access for specified ACL resource
 *
 * @param activeUser {Object}
 * @param inSite {boolean}  - if undefined, ACL will check for access across all user sites
 * @param resource {string} - resource to be checked
 * @param singleRoleCheck {boolean} - if true, at least one role must meet ACL criteria
 * @returns {boolean}
 */
const usePermission = ({ resource, inSite = true, singleRoleCheck = true }) => {
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const ACL = ACLService;

  const siteId = inSite ? activeSite.id : undefined;

  return ACL.hasAccess(activeUser, resource, siteId, singleRoleCheck);
};

export default usePermission;
