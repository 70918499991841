import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Joi from '@hapi/joi';
import { withStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import { Mail, Phone } from '@material-ui/icons';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import errorMessages from '../../assets/errorMessages';
import { sendContactRequest } from '../../api/contactUs';
import NotificationsContext from '../../context/NotificationsContext/NotificationsContext';
import { addNotification } from '../../helpers/notifications';
import { handleErrorNew } from '../../helpers/errors';
import { getZipCodeFromMask } from '../../helpers/getters';
import bannerImg from '../../assets/images/contact-us.png';
import { Colors } from '../../assets/theme/Colors';
import ReCaptchaToken from '../../components/Common/ReCaptchaToken';
import ZipCodesList from '../../assets/zipcodes.json';
import PagePaper from '../../components/Common/PagePaper';
import { TextFieldWrapper as TextField } from '../../components/Form/MuiFormWrapper';
import SocialMediaLinkIcons from '../../components/SocialMediaLinkIcons';

const Banner = withStyles(({ breakpoints, spacing }) => ({
  hero: {
    marginBottom: spacing(8),
    [breakpoints.only('xs')]: {
      marginBottom: spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '15vh',
    minHeight: 100,
    padding: '1em',
    boxSizing: 'border-box',
    color: 'white',
    position: 'relative',
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(${bannerImg}) no-repeat center center`,
    backgroundSize: 'cover',
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
  },
  heroText: {
    textShadow: '1px 1px 2px black',
    textAlign: 'center',
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  heroButton: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    color: 'white',
    background: Colors.appBar.light,
    '&:hover': {
      background: Colors.appBar.light,
    },
  },
}))(({ classes }) => (
  <section className={classes.hero}>
    <Grid className={classes.heroText} container direction="column" justify="center" alignItems="center">
      <Grid item>
        <Typography variant="h4">Questions or comments?</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4">Get in touch</Typography>
      </Grid>
    </Grid>
  </section>
));

const StyledAvatar = withStyles({
  root: {
    backgroundColor: Colors.appBar.main,
    borderRadius: 10,
    boxShadow: '0px 0px 0px 1px #fff inset',
    border: `${Colors.appBar.main} solid 2px`,
  },
})(Avatar);

const validationResolver = data => {
  const errors = {};

  const errorList = Joi.object()
    .keys({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          'string.empty': errorMessages.REQUIRED.message,
          'string.email': errorMessages.ERR_INVALID_EMAIL.message,
        }),
      captchaToken: Joi.string()
        .required()
        .messages({
          'any.required': errorMessages.REQUIRED.message,
        }),
      name: Joi.string()
        .required()
        .messages({
          'string.empty': errorMessages.REQUIRED.message,
        }),
      text: Joi.string()
        .required()
        .messages({
          'string.empty': errorMessages.REQUIRED.message,
        }),
      zip: Joi.string()
        .length(5)
        .custom((value, helper) => {
          if (ZipCodesList.includes(value)) {
            return true;
          }
          return helper.message(errorMessages.ERR_INVALID_ZIP_CODE.message);
        })
        .required()
        .messages({
          'string.empty': errorMessages.REQUIRED.message,
          'string.length': errorMessages.ERR_INVALID_ZIP_CODE.message,
        }),
    })
    .validate(
      {
        email: data.email,
        captchaToken: data.captchaToken,
        name: data.name,
        text: data.text,
        zip: getZipCodeFromMask(data.zip),
      },
      { abortEarly: false }
    );

  if (errorList.error) {
    errorList.error.details.forEach(error => {
      errors[error.context.key] = { message: error.message };
    });
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors,
  };
};

const ContactUs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const notificationsContext = useContext(NotificationsContext);
  const pushNotification = (message, type) => addNotification(message, notificationsContext, type);
  const handleError = (error, fallbackMessage) => handleErrorNew(error, notificationsContext, fallbackMessage);

  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    errors,
    control,
    formState: { isSubmitted },
  } = useForm({
    validationResolver,
  });

  const registerControlledInputs = () => {
    register({ name: 'captchaToken' });
  };

  useEffect(() => registerControlledInputs(), []);

  const setFormValue = (name, value) => setValue(name, value, isSubmitted);

  const handleRecaptchaChange = value => setFormValue('captchaToken', value);

  const onSubmit = values => {
    setIsSubmitting(true);

    return Bluebird.try(() =>
      sendContactRequest({
        name: values.name,
        email: values.email,
        zipCode: values.zip,
        message: values.text,
      })
    )
      .then(() => setValue([{ zip: null }, { text: null }, { email: null }, { name: null }]))
      .then(() => pushNotification('Your message has been sent!', 'success'))
      .catch(err => handleError(err, 'Unable to send message'))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Banner />
      <PagePaper>
        <Container maxWidth="md" id="form">
          <Grid px={{ xs: 2, sm: 5, md: 10, lg: 20 }}>
            <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
              <Grid item>
                <Typography variant="h5">Contact details</Typography>
              </Grid>

              <Grid item>
                <List>
                  <ListItem alignItems="center" dense>
                    <ListItemAvatar>
                      <StyledAvatar>
                        <Phone />
                      </StyledAvatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" color="textSecondary" display="block">
                          Phone
                        </Typography>
                      }
                      secondary={
                        <Typography
                          component={Link}
                          href="tel:18002803298"
                          variant="subtitle1"
                          color="textPrimary"
                          display="block"
                        >
                          1•800•280•3298
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem alignItems="center" dense>
                    <ListItemAvatar>
                      <StyledAvatar>
                        <Mail />
                      </StyledAvatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" color="textSecondary" display="block">
                          Email
                        </Typography>
                      }
                      secondary={
                        <Typography
                          onClick={() => (window.location.href = 'mailto:info@foodrescue.us')}
                          variant="subtitle1"
                          display="block"
                          href="#"
                          component={Link}
                        >
                          <Box display="flex">
                            <Box order={4}>foodrescue</Box>
                            <Box order={3}>@</Box>
                            <Box order={2}>info</Box>
                            <Box order={1} hidden>
                              {/* just to keep scrapper bots away from that email */}
                              contact-
                            </Box>
                            <Box order={5}>.</Box>
                            <Box order={6}>us</Box>
                          </Box>
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12}>
                <Box width={400} maxWidth="100%" mb={2}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
                      <Grid item xs>
                        <TextField
                          required
                          id="name"
                          name="name"
                          label="Name"
                          fullWidth
                          variant="outlined"
                          size="small"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: !!getValues('name') || undefined,
                          }}
                          error={!!errors.name}
                          helperText={errors.name ? errors.name.message : ''}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          required
                          id="email"
                          name="email"
                          label="E-mail"
                          fullWidth
                          variant="outlined"
                          size="small"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: !!getValues('email') || undefined,
                          }}
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ''}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          required
                          id="text"
                          name="text"
                          label="Send Us A Message"
                          multiline
                          fullWidth
                          rows={4}
                          variant="outlined"
                          size="small"
                          inputRef={register}
                          InputLabelProps={{
                            shrink: !!getValues('text') || undefined,
                          }}
                          error={!!errors.text}
                          helperText={errors.text ? errors.text.message : ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box flexGrow={1} flexBasis={0}>
                            <InputMask mask="99999">
                              <TextField
                                required
                                id="zip"
                                name="zip"
                                label="Zip code"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="tel"
                                inputRef={register}
                                InputLabelProps={{
                                  shrink: !!getValues('zip') || undefined,
                                }}
                                error={!!errors.zip}
                                helperText={errors.zip ? errors.zip.message : ''}
                              />
                            </InputMask>
                          </Box>
                          <Box flexGrow={1.2} flexBasis={0} px={4}>
                            <Tooltip
                              arrow
                              placement="top"
                              title="We operate in over 25 cities through the US. With your zip code, we can route your message to our team in your area."
                            >
                              <Box fontSize="body2.fontSize" color="primary.main">
                                Why do we need this?
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item>
                        <ReCaptchaToken
                          error={!!errors.captchaToken}
                          helperText={errors.captchaToken ? errors.captchaToken.message : ''}
                          onChange={handleRecaptchaChange}
                          onCaptchaLoad={() => setRecaptchaLoaded(true)}
                        />
                      </Grid>

                      <Grid item>
                        <ButtonWithLoading
                          disabled={!recaptchaLoaded}
                          type="submit"
                          variant="contained"
                          color="primary"
                          isLoading={isSubmitting}
                        >
                          Send
                        </ButtonWithLoading>
                      </Grid>

                      {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
                    </Grid>
                  </form>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <SocialMediaLinkIcons />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </PagePaper>
    </>
  );
};

export default ContactUs;
