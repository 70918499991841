import React, { createRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Grid, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import OverlayLoader from './OverlayLoader';
import FormGrid from './Common/FormGrid';
import SubmitWithSendEmailConfirmation from './SubmitWithSendEmailConfirmation';
import { siteGoalsTypes } from '../helpers/siteGoals';
import useNotificationService from '../hooks/useNotificationService';
import routes from '../routes';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const AddNewSiteGoalForm = ({ submit }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const formRef = createRef();
  const next5Years = moment().add(5, 'years').endOf('year').toDate();
  const thisMonth = moment().startOf('day').toDate();
  const [date, setDate] = useState(thisMonth);
  const { addErrorNotification, addSuccessNotification } = useNotificationService();

  const validate = (values) => {
    const errors = {};

    if (!values.goal) {
      errors.goal = 'Required';
    }

    if (!values.type) {
      errors.type = 'Required';
    }

    return errors;
  };

  return (
    <OverlayLoader isLoading={loading}>
      <Paper className={classes.paper}>
        <Typography className={classes.header} variant="h6">
          Add New Site Goal
        </Typography>
        <Form
          initialValues={{}}
          onSubmit={async (values) => {
            try {
              setLoading(true);
              await submit({
                type: values.type,
                goal: values.goal,
                date: moment(date).startOf('month').format('YYYY-MM-DD'),
              });
              addSuccessNotification('Site goal added');
              return history.push(generatePath(routes.dashboardSC, { tab: 'site-goals' }));
            } catch (error) {
              setLoading(false);
              addErrorNotification(error);
              return {
                [FORM_ERROR]: 'POST failed',
              };
            }
          }}
          validate={validate}
          render={({ handleSubmit, submitting, pristine, invalid, dirtySinceLastSubmit }) => (
            <form onSubmit={handleSubmit} ref={formRef}>
              <FormGrid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="goal"
                      render={({ input }) => (
                        <TextField
                          fullWidth
                          required
                          variant="outlined"
                          value={input.value}
                          name={input.name}
                          type="number"
                          onChange={input.onChange}
                          label="Goal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="type"
                      render={({ input }) => (
                        <TextField
                          fullWidth
                          required
                          variant="outlined"
                          value={input.value}
                          name={input.name}
                          onChange={input.onChange}
                          label="Unit"
                          select
                        >
                          <MenuItem value={siteGoalsTypes.RESCUES.type}>{siteGoalsTypes.RESCUES.label}</MenuItem>
                          <MenuItem value={siteGoalsTypes.LBS_RESCUED.type}>{siteGoalsTypes.LBS_RESCUED.label}</MenuItem>
                          <MenuItem value={siteGoalsTypes.MEALS_RESCUED.type}>{siteGoalsTypes.MEALS_RESCUED.label}</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      views={['year', 'month']}
                      label="Year and Month"
                      minDate={thisMonth}
                      maxDate={next5Years}
                      value={date}
                      onChange={(value) => setDate(value)}
                      inputVariant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SubmitWithSendEmailConfirmation
                      title="Heads up!"
                      message={`Are you sure you want to create a site goal with this value?`}
                      confirmButtonLabel="Add site goal"
                      label="Add Site Goal"
                      disabled={pristine || (invalid && !dirtySinceLastSubmit) || submitting || !date}
                      onButtonNotSend={() => true}
                      onButtonSend={() => {
                        formRef.current.dispatchEvent(
                          new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                          })
                        );
                      }}
                      showConfirmation
                    />
                  </Grid>
                </Grid>
              </FormGrid>
            </form>
          )}
        />
      </Paper>
    </OverlayLoader>
  );
};

export default AddNewSiteGoalForm;
