import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link, generatePath } from 'react-router-dom';
import {
  Badge,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { NotificationsActive, Settings } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import routes from '../routes/index';
import OverlayLoader from './OverlayLoader';
import { Colors } from '../assets/theme/Colors';
import { fetchNotificationsIfNeeded } from '../actions/notifications';


const NotificationsContainer = ({ hasCustomBranding, customBranding = {}}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    isLoading,
    unread,
  } = useSelector(({
     entities: { notifications: { isLoading: inflight, unread, lastUpdate } },
   }) => ({ inflight, unread, lastUpdate }), shallowEqual)
  const unreadNotifications = unread.allIds.slice(0, 20).map(id => unread.byId[id]);
  const counter = Object.values(unread.byId).length;

  useEffect(() => {
    dispatch(fetchNotificationsIfNeeded());
  }, [dispatch])

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const getNotificationColor = () => {
    if (hasCustomBranding && customBranding?.toolbar_text_color) {
      return customBranding.toolbar_text_color;
    }

    if (hasCustomBranding && customBranding?.primary_color) {
      return theme.palette.getContrastText(customBranding.primary_color);
    }

    return Colors.white;
  };

  return (
    <>
      <OverlayLoader isLoading={isLoading}>
        <Tooltip title="Notifications">
          <IconButton
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            data-introid="notification-bell"
            onClick={handleOpen}
            style={{ marginRight: 5 }}
          >
            <Badge badgeContent={counter} color="secondary">
              {counter > 0 ? (
                <NotificationsActive htmlColor={getNotificationColor()} />
              ) : (
                <NotificationsIcon htmlColor={getNotificationColor()} />
              )}
            </Badge>
          </IconButton>
        </Tooltip>
      </OverlayLoader>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
        style={{ zIndex: 101 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper style={{ width: 480, maxWidth: '97vw' }}>
              <div
                style={{
                  background: '#eee',
                  padding: '10px 16px',
                  borderRadius: '4px 4px 0 0',
                }}
              >
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Typography variant="subtitle1" color="textPrimary" style={{ fontSize: 17 }}>
                    Notifications
                  </Typography>

                  <Link to={routes.notificationsPreferences} onClick={handleClose}>
                    <Tooltip title="Manage notifications preferences" placement="top">
                      <IconButton>
                        <Settings />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </Grid>
              </div>

              {isLoading ? (
                <Typography style={{ padding: '20px 16px' }}>Loading...</Typography>
              ) : (
                <ClickAwayListener onClickAway={handleClose}>
                  {counter > 0 ? (
                    <MenuList style={{ maxHeight: 295, overflowY: 'auto' }}>
                      {unreadNotifications.map(notification => (
                        <Link
                          to={generatePath(routes.notification, { notificationId: notification.id })}
                          key={notification.id}
                        >
                          <MenuItem
                            key={notification.id}
                            alignItems="flex-start"
                            onClick={handleClose}
                            style={{ height: 'auto', display: 'grid' }}
                          >
                            <Typography variant="body1" noWrap>
                              {notification.subject}
                            </Typography>
                            <Typography component="p" variant="body2" color="textSecondary" noWrap>
                              {moment(notification.created_at).fromNow()}
                            </Typography>
                          </MenuItem>
                        </Link>
                      ))}
                    </MenuList>
                  ) : (
                    <Typography style={{ padding: '20px 16px' }}>You don't have any unread notifications.</Typography>
                  )}
                </ClickAwayListener>
              )}

              <div style={{ background: '#eee', padding: '5px 16px', borderRadius: '0 0 4px 4px' }}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Typography>
                    <Link to={routes.notifications} onClick={handleClose}>
                      view all notifications
                    </Link>
                  </Typography>
                </Grid>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default NotificationsContainer;
