import React from 'react';
import { TextField } from '@material-ui/core';

const ContactListSearch = ({ handleSearch }) => {
  return (
    <TextField
      fullWidth
      id="outlined-basic"
      placeholder="Search"
      variant="outlined"
      onChange={event => handleSearch(event.target.value)}
    />
  );
};

export default ContactListSearch;
