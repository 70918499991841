import React from 'react';
import { Colors } from './theme/Colors';
import { ReactComponent as AppleShare } from './images/apple_share.svg';
import { ReactComponent as UserSlash } from './svg/user-slash.svg';
import { ReactComponent as GoogleLogo } from './svg/icons8-google.svg';
import { ReactComponent as TikTok } from './svg/tiktok.svg';
import { ReactComponent as FrusLogo } from './svg/frus-logo.svg';
import { ReactComponent as FoodDonorSlash } from './svg/donor-slash.svg';

export const TikTokIcon = props => (
  <TikTok
    style={{
      padding: '0px 4px',
      fill: props.htmlColor || Colors.black,
    }}
    {...props}
  />
);

export const FrusLogoIcon = props => (
  <FrusLogo
    style={{
      padding: '0px 4px 4px',
      fill: props.htmlColor || Colors.black,
    }}
    {...props}
  />
);

export const AppleShareIcon = props => {
  return <AppleShare {...props} />;
};

export const UserSlashIcon = props => {
  return <UserSlash {...props} />;
};

export const FoodDonorSlashIcon = props => {
  return <FoodDonorSlash {...props} />;
};

export const GoogleLogoIcon = props => {
  return <GoogleLogo style={{ backgroundColor: Colors.white }} {...props} />;
};
