import React, { useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Bluebird from 'bluebird';
import moment from 'moment';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { TableFilterList } from 'mui-datatables';
import { isUndefinedOrNull } from '../helpers';
import routes from '../routes';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import ConfirmationDialog from './ConfirmationDialog';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getUserFullName } from '../helpers/user';
import { getMuiTableDataIndex } from '../helpers/getters';
import { formatDate, formatCsvFileName } from '../helpers/formatters';
import { sortAlphabetically } from '../helpers/sorters';

const CustomFilterList = ({ filterList, filterUpdate, ...restProps }) => {
  const { columnNames } = restProps;

  const deactivatedRescuerColumnIndex = columnNames.findIndex((col) => col.name === 'role_deleted_at');

  const updatedList = [...filterList];
  const deactiavtedRescuerChecked = filterList[deactivatedRescuerColumnIndex].includes('Deactivated');
  updatedList[deactivatedRescuerColumnIndex] = [];

  return (
    <Box display="flex" flexDirection="row">
      <TableFilterList {...restProps} filterList={updatedList} filterUpdate={filterUpdate} />
      <Box marginTop="5px">
        <FormControlLabel
          control={
            <Checkbox
              checked={deactiavtedRescuerChecked}
              onChange={() => {
                filterUpdate(
                  deactivatedRescuerColumnIndex,
                  'Deactivated'
                );
              }}
            />
          }
          label="Deactivated Rescuers"
        />
      </Box>
    </Box>
  );
};

const UnassignedUsersTable = ({ isLoading, unassignedUsers, showDeleteUserButton, onUserDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  
  const fullNameCustomBodyRender = (value, tableMeta, csv = false) => {
    const user = unassignedUsers[getMuiTableDataIndex(tableMeta)];
  
    if (!user) {
      return csv ? null : '-';
    }
    
    return getUserFullName(user);
  };
  
  const emailVerifiedCustomBodyRender = value => !isUndefinedOrNull(value)
    ? moment(value).format('MM/DD/YYYY')
    : 'Not Verified';
  
  const phoneVerifiedCustomBodyRender = value => !isUndefinedOrNull(value)
    ? moment(value).format('MM/DD/YYYY')
    : 'Not Verified';
  
  return (
    <BaseMUIDataTable
      tableId="#users/unassigned-users"
      title="Unassigned Users"
      isLoading={isLoading || isDeleting}
      data={unassignedUsers}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'firstname',
          label: 'First name',
          options: {
            filter: false,
            display: false,
          },
        },
        {
          name: 'lastname',
          label: 'Last name',
          options: {
            filter: false,
            display: false,
          },
        },
        {
          name: 'fullname',
          label: 'Full name',
          options: {
            filter: false,
            sortCompare: order => (row1, row2) =>
              sortAlphabetically(
                order,
                getUserFullName({
                  firstname: row1.rowData[1],
                  lastname: row1.rowData[2],
                }),
                getUserFullName({
                  firstname: row2.rowData[1],
                  lastname: row2.rowData[2],
                })
              ),
            customBodyRender: (value, tableMeta) => fullNameCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => fullNameCustomBodyRender(value, tableMeta, true),
          },
        },
        {
          name: 'email',
          label: 'E-mail',
          options: {
            filter: false,
          },
        },
        {
          name: 'email_verified',
          label: 'Email verified',
          options: {
            filter: true,
            customFilterListOptions: {
              render: value => `E-mail verified: ${value}`,
            },
            customBodyRender: value => emailVerifiedCustomBodyRender(value),
            customBodyRenderCSV: value => emailVerifiedCustomBodyRender(value),
          },
        },
        {
          name: 'phone',
          label: 'Phone',
          options: {
            filter: false,
            customBodyRender: value => value ? value : '-',
          },
        },
        {
          name: 'phone_verified',
          label: 'Phone verified',
          options: {
            filter: true,
            customFilterListOptions: {
              render: value => `Phone verified: ${value}`,
            },
            customBodyRender: value => phoneVerifiedCustomBodyRender(value),
            customBodyRenderCSV: value => phoneVerifiedCustomBodyRender(value),
          },
        },
        {
          name: 'zip_code',
          label: 'Zip Code',
          options: {
            customBodyRender: value => value ? value : '-',
          },
        },
        {
          name: 'completed_registration',
          label: 'Completed Registration',
          options: {
            filterOptions: {
              renderValue: formatDate,
            },
            customFilterListOptions: {
              render: value => `Completed registration: ${formatDate(value)}`,
            },
            customBodyRenderLite: dataIndex => {
              const user = unassignedUsers[dataIndex];
              
              if (user.completed_registration !== null) {
                return formatDate(user.completed_registration);
              }
              
              return 'Not Completed';
            },
            customBodyRenderCSV: (value, tableMeta) => {
              const user = unassignedUsers[getMuiTableDataIndex(tableMeta)];
  
              if (!user) {
                return null;
              }
  
              return user.completed_registration !== null ? formatDate(user.completed_registration) : 'Not Completed';
            },
          },
        },
        {
          name: 'role_deleted_at',
          label: 'Deactivated Rescuer',
          options: {
            filter: false,
            filterOptions: {
              names: ['Deactivated'],
              logic(value, filterVal) {
                if (filterVal.includes('Deactivated')) {
                  return value === null;
                }

                return false;
              },
            },
            customFilterListOptions: {
              render: (value) => `Deactivated Rescuers: ${value}`,
            },
            customBodyRenderLite: (dataIndex) => {
              const user = unassignedUsers[dataIndex];

              if (user.role_deleted_at !== null) {
                return 'Deactivated';
              }

              return '-';
            },
            customBodyRenderCSV: (value, tableMeta) => {
              const user = unassignedUsers[getMuiTableDataIndex(tableMeta)];

              if (!user) {
                return null;
              }

              return user.role_deleted_at !== null ? 'Yes' : '-';
            },
          },
        },
        {
          name: 'delete_reason',
          label: 'Comments',
          options: {
            filter: false,
            customBodyRender: (value) => value || '-',
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              const user = unassignedUsers[getMuiTableDataIndex(tableMeta)];
              
              return (
                <TableActionsButtonsWrapper>
                  <TableActionButton
                    title="Edit User"
                    buttonProps={{
                      component: Link,
                      to: generatePath(routes.userEdit, { id: tableMeta.rowData[0] })
                    }}
                  />
                  
                  {showDeleteUserButton && (
                    <TableActionButton
                      title="Delete User"
                      icon={DeleteIcon}
                      onClick={() => {
                        confirmAlert({
                          title: 'Delete this user?',
                          message: 'Are you sure you want to delete this user?',
                          buttons: [
                            {
                              label: 'No',
                              color: 'primary',
                            },
                            {
                              label: 'Yes',
                              color: 'primary',
                              onClick: () => {
                                setIsDeleting(true);
                                
                                return Bluebird
                                  .try(() => onUserDelete(user))
                                  .then(() => setIsDeleting(false));
                              },
                            },
                          ],
                          customUI: ({ title, message, onClose, buttons }) => (
                            <ConfirmationDialog
                              buttons={buttons}
                              closeDialog={onClose}
                              title={title}
                              message={message}
                            />
                          ),
                        });
                      }}
                    />
                  )}
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        responsive: 'simple',
        selectableRows: 'none',
        downloadOptions: {
          filename: formatCsvFileName('Unassigned Users'),
        },
      }}
      components={{
        TableFilterList: CustomFilterList,
      }}
    />
  );
};

export default UnassignedUsersTable;
