import React from 'react';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

/**
 * one-line text input with auto-complete listbox if null
 * if not-null, render chipstrip with 'x' icon to delete
 * forces state: => not-selected => selected => not-selected
 */

const useStyles = makeStyles(() => ({
  rootMobile: {
    padding: 20,
    width: '100%',
  },
  rootDesktop: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
}));

const ReceiverChip = ({ value, onClick, isMobile = false, isDisabled = false }) => {
  const classes = useStyles();
  return (
    <Chip
      className={classNames({
        [classes.rootMobile]: isMobile,
        [classes.rootDesktop]: !isMobile,
      })}
      label={value}
      icon={isDisabled ? undefined : <ClearIcon />}
      onClick={isDisabled ? () => {} : onClick}
    />
  );
};

export default ReceiverChip;
