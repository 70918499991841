import React, { PureComponent } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import ReactSelect from 'react-select';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { Box, Button } from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import snackbarHelper from '../helpers/snackbarHelper';
import DateAndGranularityPicker, { dateRangeOptionsList } from './DateAndGranularityPicker/DateAndGranularityPicker';
import ReportLegend, { shouldShowLegend } from '../containers/ReportLegend';
import { getEarliestSiteDate } from '../helpers/getters';
import { Colors } from '../assets/theme/Colors';

class ReceiverReportForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sitesIds: [],
      receivingAgenciesIds: [],
      period: props.defaultValues.period,
      startDate: props.defaultValues.startDate ? moment(props.defaultValues.startDate).toDate() : null,
      endDate: props.defaultValues.endDate ? moment(props.defaultValues.endDate).toDate() : null,
      granularity: props.defaultValues.granularity,
    };
  }

  handleReportRun = () => {
    const { fetchReport } = this.props;

    try {
      return fetchReport();
    } catch (e) {
      snackbarHelper.error(e.message);

      return {
        [FORM_ERROR]: 'POST failed',
      };
    }
  };

  handleSubmit = values => {
    const { setFilter, defaultValues } = this.props;
    const { startDate, endDate, granularity, period } = this.state;

    const dimension = ['receiver'];

    try {
      setFilter({
        dimension: dimension.join(','),
        granularity,
        period,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        receivingAgenciesIds: values.receivingAgenciesIds || [],
        sitesIds: values.sitesIds || [],
        showLegend: shouldShowLegend(defaultValues.showLegend),
      });
    } catch (e) {
      snackbarHelper.error(e.message);

      return {
        [FORM_ERROR]: 'POST failed',
      };
    }
  };

  render() {
    const { sitesList, isAdmin, defaultValues, setFilter } = this.props;

    return (
      <Box width={1}>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{
            receivingAgenciesIds: defaultValues.receivingAgenciesIds,
            sitesIds: defaultValues.sitesIds,
          }}
          render={({ submitError, handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" alignItems="center" gridGap="16px">
                <Box display="flex" alignItems="center" gridGap="5px">
                  <FilterListIcon htmlColor={Colors.inputBorder} />
                  <span>Filters</span>
                </Box>
                {(isAdmin || sitesList.length > 1) && (
                  <Box width="100%">
                    <FormSpy
                      subscription={{ values: true }}
                      onChange={props => {
                        if (Object.keys(props.values).length > 0) {
                          form.submit();
                        }
                      }}
                    />
                    <Field
                      name="sitesIds"
                      render={({ input }) => (
                        <ReactSelect
                          isMulti
                          name={input.name}
                          placeholder="Select site(s) or leave blank to show data for all sites"
                          isClearable
                          value={input.value}
                          options={[{ id: -1, name: 'All' }, ...sitesList]}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => option.name}
                          styles={{
                            menu: provided => ({
                              ...provided,
                              zIndex: 101,
                            }),
                          }}
                          onChange={option => {
                            let choices = option;
                            if (option && option.some(site => site.id === -1)) {
                              choices = [];
                            }

                            input.onChange(choices || []);

                            this.setState({
                              sitesIds: choices || [],
                            });
                          }}
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>

              <Box mt={2} mb={0.5}>
                <DateAndGranularityPicker
                  customDateRangeValue={{
                    startDate: defaultValues.startDate,
                    endDate: defaultValues.endDate,
                  }}
                  defaultGranularity={defaultValues.granularity}
                  defaultDateRange={defaultValues.period}
                  dateRangeOptions={{
                    lastWeek: dateRangeOptionsList.lastWeek,
                    last7Days: dateRangeOptionsList.last7Days,
                    last90Days: dateRangeOptionsList.last90Days,
                    thisMTD: dateRangeOptionsList.thisMTD,
                    thisQTD: dateRangeOptionsList.thisQTD,
                    thisYTD: dateRangeOptionsList.thisYTD,
                  }}
                  showCustomDateRange
                  customDateRangeOptions={{
                    maxDate: new Date(),
                    minDate: getEarliestSiteDate(defaultValues.sitesIds, sitesList),
                  }}
                  returnDateObject={false}
                  onChange={data => {
                    this.setState(
                      {
                        startDate: data.dateRange ? data.dateRange.startDate : null,
                        endDate: data.dateRange ? data.dateRange.endDate : null,
                        granularity: data.granularity,
                        period: data.period,
                      },
                      () => {
                        this.handleSubmit({
                          ...values,
                          startDate: data.dateRange ? data.dateRange.startDate : null,
                          endDate: data.dateRange ? data.dateRange.endDate : null,
                          granularity: data.granularity,
                          period: data.period,
                        });
                      }
                    );
                  }}
                />
              </Box>

              <Box textAlign="right" my={2}>
                <Button variant="contained" color="primary" onClick={this.handleReportRun}>
                  Run
                </Button>
              </Box>

              {submitError && <div className="error">{submitError}</div>}
            </form>
          )}
        />

        <ReportLegend
          isOpen={defaultValues.showLegend}
          onExpandChange={state =>
            setFilter({
              ...defaultValues,
              showLegend: state,
            })
          }
        />
      </Box>
    );
  }
}

export default ReceiverReportForm;
