import React, { Component } from 'react';
import Select from 'react-select';
import { Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { FormHelperText, InputLabel, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import get from 'lodash/get';
import { getFormFieldError, isFormFieldInvalid } from '../../helpers/validators';
import classNames from 'classnames';
import { Colors } from '../../assets/theme/Colors';

class OrganizationTypeSelect extends Component {
  constructor(props) {
    super(props);
    const { selectedTypeId, list } = props;
    const selectedTypeOption = list.find(row => row.id === selectedTypeId);

    this.state = {
      selectedType: selectedTypeOption,
      showCreateNewTypeField: get(selectedTypeOption, 'name') === 'Other',
    };
  }

  componentDidMount() {
    const { showCreateNewTypeField } = this.state;
    const { handleCreateNewTypeFieldFlag } = this.props;
    handleCreateNewTypeFieldFlag(showCreateNewTypeField);
  }

  handleChange = newValue => {
    const { handleCreateNewTypeFieldFlag } = this.props;
    handleCreateNewTypeFieldFlag(get(newValue, 'name') === 'Other');

    this.setState({ selectedType: newValue, showCreateNewTypeField: get(newValue, 'name') === 'Other' });
  };

  render() {
    const { list, selectPlaceholder, label, classes } = this.props;
    const { selectedType, showCreateNewTypeField } = this.state;

    return (
      <Box my={1}>
        <InputLabel shrink htmlFor="organization-type-select">{label}</InputLabel>
        <Field
          name="type_id"
          render={({ input , meta}) => {
            const showError = isFormFieldInvalid(meta)

            return (
              <>
                <Select
                  inputId="organization-type-select"
                  fullWidth
                  name="type_id"
                  placeholder={selectPlaceholder}
                  onChange={option => {
                    input.onChange(option !== null ? option.id : null);

                    this.handleChange(option);
                  }}
                  className={classNames({
                    [classes.invalidField]: showError,
                  })}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      zIndex: 101,
                    }),
                    control: base => ({
                      ...base,
                      borderColor: showError ? Colors.errorText : base.borderColor,
                    }),
                  }}
                  value={selectedType}
                  options={list}
                  isClearable
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                />
                {showError && <FormHelperText error>{getFormFieldError(meta)}</FormHelperText>}
              </>
            );
          }}
        />
        {showCreateNewTypeField && (
          <Box pl={2}>
            <Field
              fullWidth
              name="type_other"
              component={TextField}
              type="text"
              label="Other type"
            />
          </Box>
        )}
      </Box>
    );
  }
}

const styles = theme => ({
  invalidField: {
    background: '#f443360f',
    borderRadius: 2,
  },
});

OrganizationTypeSelect.defaultProps = {
  selectedTypeId: null,
  selectPlaceholder: 'Type',
  label: 'Type',
};
export default withStyles(styles)(OrganizationTypeSelect);
