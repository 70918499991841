import { apiPATCH, apiPUT, apiGET } from './api';

export const updateReceiverLocation = (receiverId, receiverLocationId, receiverLocation) => apiPATCH(
  `/receivers/${receiverId}/locations/${receiverLocationId}`,
  receiverLocation
);

export const getReceiverLocationNeedsProfile = (receiverId, receiverLocationId) =>
  apiGET(`/receivers/${receiverId}/locations/${receiverLocationId}/needs_profile`);

export const updateReceiverLocationNeedsProfile = (receiverId, receiverLocationId, data) =>
  apiPUT(`/receivers/${receiverId}/locations/${receiverLocationId}/needs_profile`, data);
