import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { Table, TableBody, TableRow, TableCell, IconButton, Box, FormHelperText, Typography, makeStyles } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon , Delete as DeleteIcon } from '@material-ui/icons';
import { FREQUENCY_MONTHLY } from '../../../../../../models/donationsNew';
import MinMaxTimePicker from '../../../../../../components/MinMaxTimePicker';
import RescuersNumber from '../../RescuersNumber';
import ReceiverSelector from '../../../../../../components/ReceiverSelector';
import ReceiverChip from '../../../../../../components/ReceiverChip';
import DonationContext from '../../../../../../context/DonationContext/DonationContext';
import {
  donationManageActions,
  getAllPickupsTheSamePickupSpecBaseDay,
  groupMonthlyPickups,
  groupWeeklyPickups,
} from '../../../../../../helpers/donations';
import { DayColumn } from '../PickupsListGrid/PickupsListGrid';
import { getReceiversSelectOptions, getRescuersSelectOptions } from '../../../../../../helpers/getters';
import AllPickupsTheSameSeparator from './AllPickupsTheSameSeparator';
import { TextFieldWrapper } from '../../../../../../components/Form/MuiFormWrapper';
import AdopterSelector from '../../../../../../components/AdopterSelector';
import useActiveSite from '../../../../../../hooks/useActiveSite';
import moment from 'moment';
import { Roles } from '../../../../../../models/roles';
import { hasAnyRoleInCurrentlySelectedSite } from '../../../../../../services/auth';
import useActiveUser from '../../../../../../hooks/useActiveUser';
import RescuerNumberSelectorMobile from '../Mobile/RescuerNumberSelectorMobile';
import PickupSpecCardMobile from '../Mobile/PickupSpecCardMobile';
import { sortAlphabetically } from '../../../../../../helpers/sorters';
import { DONATION_TYPE_REQUEST, DONATION_TYPE_REQUEST_APPROVAL, DONATION_TYPE_SD } from '../../../../../../actions/donationNew';
import { RECEIVING_HOURS_HASH } from '../AllDaysTheSame/AllDaysTheSame';
import { TAB_PROFILE } from '../../../../../../containers/ReceiverDashboard';
import { Colors } from '../../../../../../assets/theme/Colors';
import routes from '../../../../../../routes';

const useStyles = makeStyles((theme) => ({
  warningText: {
    color: Colors.errorText,
    marginTop: theme.spacing(1),
    width: '100%',
  },
  infoText: {
    color: Colors.gray,
    marginTop: theme.spacing(1),
  },
  warningLink: {
    color: Colors.errorText,
    display: 'inline-flex',
  },
  infoLink: {
    color: Colors.gray,
    display: 'inline-flex',
  },
  newTabIcon: {
    fontSize: '12px',
  },
}));

const AllPickupsTheSamDayRow = ({
  isMobileView,
  isNotSCOrGreaterRoleUser,
  receiversList,
  receiversEntities,
  rescuersList,
  rescuersCount,
  locationsList,
  frequency,
  pickup,
}) => {
  const classes = useStyles();
  const [rescuerNotesValue, setRescuerNotesValue] = useState(pickup.rescuer_notes || '');
  const [pickupBeginTimeValue, setPickupBeginTimeValue] = useState(pickup.pickup_begin.value);
  const [pickupEndTimeValue, setPickupEndTimeValue] = useState(pickup.pickup_end.value);
  const [pickupEndTimeValid, setPickupEndTimeValid] = useState(false);
  const handleNotesChange = event => setRescuerNotesValue(event.target.value);

  // update state value on redux data change
  useEffect(() => setRescuerNotesValue(pickup.rescuer_notes || ''), [pickup.rescuer_notes]);
  useEffect(() => setPickupBeginTimeValue(pickup.pickup_begin.value), [pickup.pickup_begin.value]);
  useEffect(() => {
    setPickupEndTimeValid(false);
    setPickupEndTimeValue(pickup.pickup_end.value);
  }, [pickup.pickup_end.value]);

  const { onFieldChange, isLoading, donationType, errors } = useContext(DonationContext);

  // update state value on redux data change
  useEffect(() => setRescuerNotesValue(pickup.rescuer_notes || ''), [pickup.rescuer_notes]);

  const receiverId = pickup?.receiver?.id;
  const selectedReceiver = locationsList?.byReceiverId[receiverId];
  const receiverLocation = selectedReceiver?.length > 0 ? selectedReceiver[0] : null;
  const weeklyHours = receiverLocation?.weekly_receiving_hours || [];

  const hasHoursForSelectedDays = weeklyHours.find((time) => pickup.day === time.day_of_week);
  const receivingHours = weeklyHours.filter((time) => pickup.day === time.day_of_week);

  const isInReceivingHours = receivingHours.length
    ? receivingHours.find(
        (data) =>
          moment(pickup.pickup_begin?.value, 'HH:mm:ss').isBetween(
            moment(data.start, 'HH:mm:ss'),
            moment(data.end, 'HH:mm:ss'),
            undefined,
            '[]'
          ) &&
          moment(pickup.pickup_end?.value, 'HH:mm:ss').isBetween(
            moment(data.start, 'HH:mm:ss'),
            moment(data.end, 'HH:mm:ss'),
            undefined,
            '[]'
          )
      )
    : false;

  const handlePickupDelete = () =>
    onFieldChange(donationManageActions.remove_pickup, getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency));

  const handleNotesBlur = event =>
    onFieldChange(donationManageActions.set_pickup_rescuer_notes, {
      ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
      rescuer_notes: event.target.value,
    });

  const handleReceiverChange = ({ value }) =>
    onFieldChange(donationManageActions.set_pickup_receiver, {
      ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
      receiver_id: value ? value.receiver_id : null,
    });

  const handleAdopterChange = value =>
    onFieldChange(donationManageActions.set_pickup_adopter, {
      ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
      adopter_id: value ? value.id : null,
    });

  const handleBeginChange = ({ event }) => setPickupBeginTimeValue(event.format('HH:mm:00'));

  const updatePickupBeginTime = () => {
    onFieldChange(donationManageActions.set_pickup_begin, {
      ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
      begin: pickupBeginTimeValue,
    });
  };

  const updatePickupEndTime = () => {
    if (pickupEndTimeValid) {
      onFieldChange(donationManageActions.set_pickup_end, {
        ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
        end: pickupEndTimeValue,
      });
    }
  };

  const handleEndChange = ({ event, isValid }) => {
    if (isValid) {
      setPickupEndTimeValue(event.format('HH:mm:00'));
    }
    setPickupEndTimeValid(isValid);
  };

  const pickupBeginIsBeforeEnd = moment(pickup.pickup_begin.value, 'HH:mm:ss').isAfter(
    moment(pickup.pickup_end.value, 'HH:mm:ss')
  );

  return (
    <>
      <TableRow>
        <DayColumn pickup={pickup} frequency={frequency} colspan={1} />

        <TableCell align="center" style={{ minWidth: 120 }}>
          <MinMaxTimePicker
            inputVariant="outlined"
            size="small"
            variant={isMobileView ? 'dialog' : 'inline'}
            value={moment(pickupBeginTimeValue, 'HH:mm:ss')}
            maxTime={moment(pickupEndTimeValue, 'HH:mm:ss')}
            onTimeChange={handleBeginChange}
            onClose={updatePickupBeginTime}
            hasHoursForSelectedDay={hasHoursForSelectedDays}
            weeklyHours={weeklyHours}
            pickupSpecs={[pickup]}
            showWarning={(!isInReceivingHours && hasHoursForSelectedDays) || (errors.pickups && pickupBeginIsBeforeEnd)}
          />
        </TableCell>

        <TableCell align="center" style={{ minWidth: 120 }}>
          <MinMaxTimePicker
            disabled={isLoading}
            showFieldErrors
            inputVariant="outlined"
            size="small"
            variant={isMobileView ? 'dialog' : 'inline'}
            value={moment(pickupEndTimeValue, 'HH:mm:ss')}
            minTime={moment(pickupBeginTimeValue, 'HH:mm:ss')}
            onTimeChange={handleEndChange}
            onClose={updatePickupEndTime}
            hasHoursForSelectedDay={hasHoursForSelectedDays}
            weeklyHours={weeklyHours}
            pickupSpecs={[pickup]}
            showWarning={(!isInReceivingHours && hasHoursForSelectedDays) || (errors.pickups && pickupBeginIsBeforeEnd)}
          />
        </TableCell>

        {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
          <TableCell align="center">
            <RescuersNumber
              inputId={`rescuer`}
              value={rescuersCount}
              handleClick={({ value }) =>
                onFieldChange(donationManageActions.add_pickup, getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency))
              }
            />
          </TableCell>
        )}

        <TableCell style={{ minWidth: 200 }}>
          <TextFieldWrapper
            disabled={isLoading}
            rowsMax={3}
            multiline
            name={`notes`}
            variant="outlined"
            size="small"
            fullWidth
            value={rescuerNotesValue}
            onChange={handleNotesChange}
            onBlur={handleNotesBlur}
          />
        </TableCell>

        {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
          <>
            <TableCell style={{ minWidth: 200 }}>
              {pickup.receiver == null ? (
                <>
                  <ReceiverSelector
                    isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                    receivers={receiversList}
                    onChange={handleReceiverChange}
                  />
                  {errors.receiver && (
                    <FormHelperText data-testid="days-of-month-error" id="days_of_month_error" error>
                      {errors.receiver}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <ReceiverChip
                  isDisabled={isNotSCOrGreaterRoleUser}
                  value={pickup.receiver.name}
                  onClick={handleReceiverChange}
                />
              )}
            </TableCell>

            <TableCell style={{ minWidth: 200 }}>
              {pickup.adopter == null ? (
                <AdopterSelector
                  isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                  rescuersList={rescuersList}
                  onChange={({ value }) => handleAdopterChange(value)}
                />
              ) : (
                <ReceiverChip
                  isDisabled={isNotSCOrGreaterRoleUser}
                  value={pickup.adopter.name}
                  onClick={() => handleAdopterChange(null)}
                />
              )}
            </TableCell>
          </>
        )}

        <TableCell>
          <IconButton onClick={handlePickupDelete} color="primary" size="small" aria-label="delete">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {errors.pickups && pickupBeginIsBeforeEnd && (
        <Box height={20}>
          <Box style={{ position: 'absolute' }}>
            <FormHelperText data-testid="pickup_end-error" id="pickup_end" error>
              {errors.pickups}
            </FormHelperText>
          </Box>
        </Box>
      )}
      {!isInReceivingHours && hasHoursForSelectedDays && (
        <Box height={55}>
          <Box style={{position: 'absolute'}}>
            <Typography variant="subtitle2" className={classes.warningText}>
              Pickup window is outside of the receiving hours for {receiversEntities.byId[receiverId].name}. If this is incorrect, update the
              receiving hours on the{' '}
              <Link
                className={classes.warningLink}
                target="_blank"
                to={{
                  pathname: generatePath(routes.receiver, { id: receiverId, tab: TAB_PROFILE }),
                  hash: RECEIVING_HOURS_HASH,
                }}
              >
                Profile <OpenInNewIcon className={classes.newTabIcon} />
              </Link>{' '}
              the receiving agency page.
            </Typography>
          </Box>
        </Box>
      )}
      {selectedReceiver && !hasHoursForSelectedDays && (
        <Box height={55}>
          <Box style={{position: 'absolute'}}>
            <Typography variant="subtitle2" className={classes.infoText}>
              Pickup window might be outside of the receiving hours for {receiversEntities.byId[receiverId].name}. Update the receiving hours on the{' '}
              <Link
                className={classes.infoLink}
                state={{ scroll: true }}
                target="_blank"
                to={{
                  pathname: generatePath(routes.receiver, { id: receiverId, tab: TAB_PROFILE }),
                  hash: RECEIVING_HOURS_HASH,
                }}
              >
                Profile <OpenInNewIcon className={classes.newTabIcon} />
              </Link>{' '}
              the receiving agency page.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

const AllPickupsTheSame = () => {
  const activeSite = useActiveSite();
  const activeUser = useActiveUser();
  const { pickup_specs: pickups, frequency } = useSelector((state) => state.ui.donation_new.draft);
  const receiversEntities = useSelector(state => state.entities.sites.receivers);
  const receiversList = getReceiversSelectOptions(Object.values(receiversEntities.byId)).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );
  const rescuersEntities = useSelector(state => state.entities.sites.rescuers.bySiteId[activeSite.id]);
  const rescuersList = getRescuersSelectOptions(rescuersEntities || []).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );
  const locationsEntities = useSelector(state => state.entities.receivers.locations);

  const days = frequency === FREQUENCY_MONTHLY ? groupMonthlyPickups(pickups) : groupWeeklyPickups(pickups);
  const totalStackedPickups = days.filter(day => day[1].length > 1).length;

  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const rescueSizesArray = rescueSizes.sorted;

  const { onFieldChange, isMobileView, isLoading, donationType, errors, hasFoodDonorDefined } = useContext(DonationContext);

  const getUniqueRowKey = pickup => {
    if (frequency === FREQUENCY_MONTHLY) {
      return `day-${pickup.day}_ordinal-${pickup.ordinal}`;
    }

    return `day-${pickup.day}`;
  };

  const isNotSCOrGreaterRoleUser = !hasAnyRoleInCurrentlySelectedSite(activeUser, [
    Roles.NationalSiteDirector,
    Roles.Admin,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  if (!Array.isArray(pickups) || pickups.length === 0) {
    return null;
  }

  if (isMobileView) {
    return days.map((_ps, _idx) => {
      const pickup = _ps[1][0];
      return (
        <Box key={_idx}>
          {[DONATION_TYPE_SD].includes(donationType) && (
            <RescuerNumberSelectorMobile
              value={_ps[1].length}
              frequency={frequency}
              pickup={pickup}
              day={pickup.day}
              ordinal={pickup.ordinal}
              onRescuersNumberChange={() =>
                onFieldChange(
                  donationManageActions.add_pickup,
                  getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency)
                )
              }
            />
          )}
          <PickupSpecCardMobile
            pickup={pickup}
            isLoading={isLoading}
            frequency={frequency}
            isNotSCOrGreaterRoleUser={isNotSCOrGreaterRoleUser}
            receiversList={receiversList}
            rescuersList={rescuersList}
            donationType={donationType}
            onPickupBeginUpdate={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_begin, {
                ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
                begin: value,
              })
            }
            onPickupEndUpdate={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_end, {
                ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
                end: value,
              })
            }
            onPickupNotesChange={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_rescuer_notes, {
                ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
                rescuer_notes: value,
              })
            }
            onReceiverChange={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_receiver, {
                ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
                receiver_id: value ? value.receiver_id : null,
              })
            }
            onAdopterChange={(pickup, value) =>
              onFieldChange(donationManageActions.set_pickup_adopter, {
                ...getAllPickupsTheSamePickupSpecBaseDay(pickup, frequency),
                adopter_id: value ? value.id : null,
              })
            }
          />
        </Box>
      );
    });
  }

  return (
    <>
      <Table>
        <AllPickupsTheSameSeparator totalStackedPickups={totalStackedPickups} frequency={frequency} allPickupsTheSame />
        <TableBody>
          {days.map((_ps, _idx) => {
            const rowsCount = _ps[1].length;
            return (
              <AllPickupsTheSamDayRow
                isMobileView={isMobileView}
                isNotSCOrGreaterRoleUser={isNotSCOrGreaterRoleUser}
                key={`${getUniqueRowKey(_ps[1][0])}-rows_${rowsCount}`}
                frequency={frequency}
                pickup={_ps[1][0]}
                _idx={_idx}
                totalStackedPickups={totalStackedPickups}
                rescuersCount={rowsCount}
                receiversList={receiversList}
                receiversEntities={receiversEntities}
                rescuersList={rescuersList}
                locationsList={locationsEntities}
              />
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default AllPickupsTheSame;
