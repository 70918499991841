import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import MUIDataTable from 'mui-datatables';
import * as PropTypes from 'prop-types';
import { setUserTableOptions } from '../../actions/tableOptions';
import OverlayLoader from '../OverlayLoader';

const isVisibleInCSV = ({ display, download }) =>
  (display === true || display === 'true') && (download === true || download === 'true');

/**
 * tableId - unique table reference that is used as key in redux store to save/retrieve table properties
 * Naming convention example: #site-coordinator/rescues/rescues-table:
 * 1. #site-coordinator/rescues - this part can be identified by url
 * 2. /rescues-table - this part is referencing the table purpose on that page (we can have multiple tables under single URL)
 */
function BaseMUIDataTable(props) {
  const {
    columns,
    isLoading = false,
    options,
    data,
    setUserTableOptions,
    previousTableState,
    tableId,
    activeUserId,
    ...rest
  } = props;

  let displayedData = [];
  let memoizedColumns = columns;
  let memoizedRowsPerPage = options.rowsPerPage || 15;
  let memoizedSortOrder = options.sortOrder || {};
  if (previousTableState && previousTableState.filterList) {
    const filterListToArray = previousTableState.filterList.map(list => Array.isArray(list) ? list : [list]) ;
    memoizedColumns = columns.map(column => {
      const columnIndex = previousTableState.columns.findIndex(col => col.name === column.name);
      const forceDisplay = get(column, 'options.forceDisplay', false);
      return {
        ...column,
        options: {
          ...column.options,
          filterList: filterListToArray[columnIndex],
          display: forceDisplay
            ? get(column, 'options.display', true)
            : get(previousTableState.columns[columnIndex], 'display', true),
        },
      };
    });
    memoizedSortOrder = previousTableState.sortOrder || memoizedSortOrder;
    memoizedRowsPerPage = previousTableState.rowsPerPage;
  }

  return (
    <OverlayLoader isLoading={isLoading}>
      <MUIDataTable
        {...rest}
        data={data}
        options={{
          ...options,
          print: false,
          rowsPerPageOptions: [5, 10, 15, 20, 100],
          rowsPerPage: memoizedRowsPerPage,
          sortOrder: memoizedSortOrder,
          onTableInit: (action, tableState) => {
            displayedData = tableState.displayData;

            const filterListToArray = tableState.filterList.map(list => Array.isArray(list) ? list : [list]) ;

            setUserTableOptions(activeUserId, tableId, {
              ...previousTableState,
              columns: previousTableState?.columns || tableState.columns,
              filterList: previousTableState?.filterList || filterListToArray,
              rowsPerPage: previousTableState?.rowsPerPage || tableState.rowsPerPage,
            });
          },
          onTableChange: (action, tableState) => {
            displayedData = tableState.displayData;

            const filterListToArray = tableState.filterList.map(list => Array.isArray(list) ? list : [list]) 

            if (['filterChange', 'sort', 'viewColumnsChange', 'changeRowsPerPage'].indexOf(action) !== -1) {
              setUserTableOptions(activeUserId, tableId, {
                columns: tableState.columns,
                filterList: filterListToArray,
                rowsPerPage: tableState.rowsPerPage,
                sortOrder: tableState.sortOrder,
                period: previousTableState.period,
                dateRange: previousTableState.dateRange,
              });
            }
          },
          // In the current version of mui-datatables in the download CSV file action the result CSV file is NOT
          // containing the data of columns in which we are using "customBodyRender" or "customBodyRenderLite"
          // function to render the data. In order to override default behaviour we will need to override this function.
          onDownload: (buildHead, buildBody, tableColumns, tableData) => {
            // "tableColumns" contains the table columns obviously, but without functions like "customBodyRenderXYZ",
            // so we need to iterate through it, look for "customBodyRenderCSV" one and include it, when its found
            tableColumns.map(tableColumn => {
              const foundColumn = columns.find(column => column.name === tableColumn.name);

              // We could potentially just use existing "customBodyRender" and/or "customBodyRenderLite" functions,
              // however in some scenarios (e.g. Site Director column in the Sites table) its returning a React partial
              // component, instead of just a string. In order to change it, I introduced a separate function called
              // "customBodyRenderCSV" which will be responsible for rendering custom data ONLY for download CSV action.
              if (foundColumn && foundColumn.options && typeof foundColumn.options.customBodyRenderCSV === 'function') {
                tableColumn.customBodyRenderCSV = foundColumn.options.customBodyRenderCSV;
              }
            });

            // Now that we have custom render functions determined, we can start building data, going row by row and
            // column by column. For each column let's check if "customBodyRenderCSV" function is defined and if so,
            // use it to render the data. Instead (or by default) we will use row data.
            const dataWithCustomBodyRendersIncluded = [];

            displayedData.map(displayedDataRow => {
              const rowData = [];

              tableColumns.map((tableColumn, tableColumnIndex) => {
                const originalRow = tableData.find(tableDataRow => tableDataRow.index === displayedDataRow.dataIndex);
                let rowDataColumn = originalRow.data[tableColumnIndex];

                if (typeof tableColumn.customBodyRenderCSV === 'function') {
                  // find initial/initialized data to call customBodyRenderCSV
                  // displayedData contains JSX customBodyRender
                  rowDataColumn = tableColumn.customBodyRenderCSV(rowDataColumn, { dataIndex: originalRow.index });
                }

                if (isVisibleInCSV(tableColumn)) {
                  rowData.push(rowDataColumn);
                }
              });

              dataWithCustomBodyRendersIncluded.push({ data: rowData });
            });

            // filter columns - i.e. remove the one with display set to false
            const filteredColumns = tableColumns.filter(isVisibleInCSV);

            return `${buildHead(filteredColumns)}${buildBody(dataWithCustomBodyRendersIncluded)}`.trim();
          },
        }}
        columns={memoizedColumns}
      />
    </OverlayLoader>
  );
}

BaseMUIDataTable.propTypes = {
  columns: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
  setUserTableOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (
  { app: { loggedInUser, impersonating }, tableOptions },
  { tableId }
) => {
  const activeUser = impersonating || loggedInUser;
  return {
    previousTableState: get(tableOptions, [activeUser.id, tableId], null),
    activeUserId: activeUser.id,
  };
};

const mapDispatchToProps = dispatch => ({
  setUserTableOptions: (userId, tableId, data) => dispatch(setUserTableOptions(userId, tableId, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseMUIDataTable);
