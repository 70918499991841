import React from 'react';
import { useSelector } from 'react-redux';

const usersEquals = (prev, next) => (prev ? prev.id : null === next ? next.id : null);

const useActiveUser = () => {
  const impersonating = useSelector(state => state.app.impersonating, usersEquals);
  const loggedInUser = useSelector(state => state.app.loggedInUser, usersEquals);
  return impersonating || loggedInUser;
};

export default useActiveUser;
