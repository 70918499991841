import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Collapse,
  CardContent,
  CardActions,
  IconButton,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import {
  Room as RoomIcon,
  ExpandMore as ExpandMoreIcon,
  Language as LanguageIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { formatAddress, formatTime } from '../../../../../helpers/formatters';
import EventShift from '../../../../Events/Components/EventShift';
import { BaseCard } from '../../../../../components/Common/BaseCard';
import { Colors } from '../../../../../assets/theme/Colors';
import { get } from 'lodash';
import { createEventShiftRegistration, deleteRescuerEventShiftRegistration } from '../../../../../api/events';
import errorMessages from '../../../../../assets/errorMessages';
import useNotificationService from '../../../../../hooks/useNotificationService';
import Bluebird from 'bluebird';
import useActiveUser from '../../../../../hooks/useActiveUser';
import { receiveDeleteRescuerEventShiftRegistration, receiveRescuerEventShift } from '../../../../../actions/rescuers';
import { Link } from 'react-router-dom';
import useActiveSite from '../../../../../hooks/useActiveSite';
import { fetchSiteEvent, fetchSiteEventIfNeeded, receiveSiteDeleteEventShiftAdoption, receiveSiteDeleteEventShiftRegistration, receiveSiteEventAdoption } from '../../../../../actions/sites';
import { fetchEvent } from '../../../../../actions/events';
import { adoptSiteEvent, deleteEventAdoption } from '../../../../../api/sites';
import useRescuerDashboardActions from '../../../../../hooks/useRescuerDashboardActions';

const useStyles = makeStyles(({ transitions, spacing }) => ({
  root: {
    maxWidth: 345,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
  cardActions: {
    padding: spacing(0, 2, 2, 2),
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardCollapseContent: {
    paddingTop: 0,
  },
  paddingBottom0: {
    paddingBottom: 0,
    transform: 'scale(1)',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const RescuerEventMobileCard = ({ isLoading = false, eventData }) => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const dispatch = useDispatch();
  const { fetchMyEvents } = useRescuerDashboardActions();
  const [isExpanded, setIsExpanded] = useState(false);
  const [submittingShift, setIsSubmittingShift] = useState(false);
  const notificationService = useNotificationService();
  const activeSite = useActiveSite();
  const eventSpecsEntities = useSelector((state) => state.entities.sites.events).bySiteId[[activeSite.id]];
  const eventSpecs = eventSpecsEntities ? eventSpecsEntities.filter((e) => e.id === eventData.event_spec_id)[0] : null;

  const handleExpandClick = () => setIsExpanded(!isExpanded);

  const eventDate = moment(eventData.date.value, eventData.date.format).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd, MMM D',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd, MMM D',
    sameElse: date => (moment().isSame(date, 'year') ? 'dddd, MMM D' : 'dddd, MMM D YYYY'),
  });
  const eventTime = eventData ? `${formatTime(eventData.begin.value)} - ${formatTime(eventData.end.value)}` : null;

  const onShiftButtonClick = (shift, eventId) => {
    setIsSubmittingShift(shift.id);

    const shiftEventRegistration = get(shift, 'registrations', []).find(
      r => r.deleted_at === null && r.user_id === activeUser.id
    );

    if (shiftEventRegistration) {
      return Bluebird.try(() =>
        deleteRescuerEventShiftRegistration(
          shiftEventRegistration.user_id,
          eventId,
          shift.id,
        )
      )
        .then(() =>
          dispatch(
            receiveDeleteRescuerEventShiftRegistration(activeUser.id, eventId, shift.id, shiftEventRegistration.id)
          )
        )
        .catch((error) => notificationService.addErrorNotification(null, error.message))
        .finally(() => setIsSubmittingShift(false));
    }

    return Bluebird.try(() =>
      createEventShiftRegistration(eventId, shift.id, {
        user_id: activeUser.id,
        created_by_id: activeUser.id,
      })
    )
      .then(() => dispatch(fetchEvent(eventId)))
      .then((data) => data.event.shifts.filter((s) => s.id === shift.id))
      .then((eventShift) => {
        dispatch(receiveRescuerEventShift(activeUser.id, eventId, shift.id, eventShift[0]))
      })
      .catch((error) => {
        switch (error.code) {
          case errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.code:
            notificationService.addNotification(errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.message, 'info');
            break;
          case errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.code:
            notificationService.addNotification(
              errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.message,
              'info'
            );
            break;
          default:
            notificationService.addErrorNotification(error, error.message);
            break;
        }
      })
      .finally(() => setIsSubmittingShift(false));
  };

  const handleAdoptClick = (eventSpecsId, rescuerId, shift, createdById, eventSpecs, index, eventId) => {
    setIsSubmittingShift(shift.id);

    const shiftEventAdoption = get(eventSpecs, `shifts[${index}].registrations`, []).find(
      (r) => r.deleted_at === null && r.user_id === activeUser.id
    );


    if (shiftEventAdoption) {
      return Bluebird.try(() =>
        deleteEventAdoption(activeSite.id, eventSpecsId, {
          event_shift_spec_id: shift.id,
          user_id: rescuerId,
          created_by_id: createdById,
        })
      )
        .then(() => dispatch(receiveSiteDeleteEventShiftAdoption(activeSite.id, eventSpecsId, shift.id, shiftEventAdoption.id)))
        .then(() => fetchMyEvents(false, true))
        .catch((error) => notificationService.addErrorNotification(null, error.message))
        .finally(() => setIsSubmittingShift(false));
    }

    return Bluebird.try(() =>
      adoptSiteEvent(activeSite.id, eventSpecsId, {
        event_shift_spec_id: shift.id,
        user_id: rescuerId,
        created_by_id: createdById,
      })
    )
      .then(() => dispatch(fetchEvent(eventId)))
      .then((data) => data.event.shifts.filter((s) => s.id === shift.id))
      .then((eventShift) => dispatch(receiveRescuerEventShift(activeUser.id, eventId, shift.id, eventShift[0])))
      .then(() => dispatch(fetchSiteEvent(activeSite.id, eventSpecsId)))
      .then((data) => data.event.shifts.find((s) => s.id === shift.id))
      .then((eventShift) => dispatch(receiveSiteEventAdoption(activeSite.id, eventSpecsId, shift.id, eventShift)))
      .then(() => fetchMyEvents(false, true))
      .catch((error) => {
        switch (error.code) {
          case errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.code:
            notificationService.addNotification(errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.message, 'info');
            break;
          case errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.code:
            notificationService.addNotification(
              errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.message,
              'info'
            );
            break;
          default:
            notificationService.addErrorNotification(error, error.message);
            break;
        }
      })
      .finally(() => setIsSubmittingShift(false));
  };

  return (
    <BaseCard>
      <CardContent className={classes.cardContent}>
        <Box display="flex">
          <Box flexGrow={1} display="flex" justifyContent="flex-start" flexDirection="row" alignItems="stretch">
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography variant="h5" gutterBottom data-testid="event-name">
                {isLoading ? <Skeleton /> : eventData.name}
              </Typography>

              <Typography data-testid="event-date" variant="subtitle1">
                {isLoading ? <Skeleton /> : eventDate}
              </Typography>
              <Typography data-testid="event-time" variant="subtitle2" color="textSecondary">
                {isLoading ? <Skeleton /> : eventTime}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" py={2}>
            {eventData && eventData.digital && (
              <>
                <LanguageIcon style={{ color: Colors.appBar.main, marginLeft: -5, marginRight: 2 }} />
                <Typography variant="body1" data-testid="event-location">
                  {isLoading ? <Skeleton /> : 'Virtual Event'}
                </Typography>
              </>
            )}

            {eventData && !eventData.digital && eventData.location && (
              <>
                <RoomIcon style={{ color: Colors.appBar.main, marginLeft: -5 }} />
                <Typography variant="body1" data-testid="event-location">
                  {isLoading ? <Skeleton /> : formatAddress(eventData.location)}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </CardContent>
      <CardActions disableSpacing className={classNames(classes.cardActions, { [classes.paddingBottom0]: isExpanded })}>
        <IconButton
          className={classNames(classes.expand, {
            [classes.expandOpen]: isExpanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardCollapseContent}>
          {eventData.description && (
            <Box pb={1}>
              <Box pb={1}>
                <Typography variant="h6">Event Details</Typography>
                <Typography variant="body1" data-testid="event-details-description-text">
                  {isLoading ? <Skeleton /> : eventData.description}
                </Typography>
              </Box>
              <Divider />
            </Box>
          )}

          {eventData && eventData.digital && eventData.link && (
            <Box pb={1}>
              <Typography variant="h6" gutterBottom>
                Link
              </Typography>
              <Box display="flex">
                <LinkIcon style={{ color: Colors.appBar.main, marginRight: 10 }} />
                <Link
                  style={{ marginLeft: 2, overflowWrap: 'anywhere' }}
                  variant="body1"
                  target="_blank"
                  href={eventData.link}
                >
                  {eventData.link}
                </Link>
              </Box>
            </Box>
          )}

          <Typography variant="h6" gutterBottom>
            Shifts
          </Typography>

          <Box display="grid" gridRowGap={16}>
            {eventData.shifts
              .filter((shift) => shift.deleted_at === null)
              .map((shift, index) => (
                <EventShift
                  key={shift.id}
                  index={index}
                  isSubmitting={submittingShift === shift.id}
                  disabled={!!submittingShift}
                  handleShiftButtonClick={onShiftButtonClick}
                  shift={shift}
                  eventData={eventData}
                  eventSpecs={eventSpecs}
                  handeShiftAdoptClick={handleAdoptClick}
                />
              ))}
          </Box>
        </CardContent>
      </Collapse>
    </BaseCard>
  );
};

export default RescuerEventMobileCard;
