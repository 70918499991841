import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import {
  Divider,
  Checkbox,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import get from 'lodash/get';
import SelectZipCode from './SelectZipCode';
import AddressField from './AddressField';
import { Colors } from '../assets/theme/Colors';
import { getFormFieldError, isFormFieldInvalid } from '../helpers/validators';

export const getLocationFieldsValidationErrors = values => {
  if (get(values, 'locations', []).length === 0) {
    return undefined;
  }

  const isSingleMailingAddress = values.locations.filter(location => location.is_mailing_address).length <= 1;

  const isFilled = values.locations.some(
    ({ address, address2, zip, city, st }) => !!address || !!address2 || !!zip || !!city || !!st
  );

  const errors = [];
  values.locations.forEach(location => {
    const rowError = {};
    if (!isSingleMailingAddress) {
      rowError.is_mailing_address = 'Only one mailing address can be selected.';
    }

    if (!location.address) {
      rowError.address = 'Required';
    }

    if (!location.zip) {
      rowError.zip = 'Required';
    }

    if (!location.type_id) {
      rowError.type_id = 'Required';
    }
    errors.push(rowError);
  });

  if (isFilled && errors.some(object => Object.keys(object).length !== 0)) {
    return { locations: errors };
  } else {
    return undefined;
  }
};

const AddressDivider = props => {
  const {
    index = undefined,
    fields,
    allowAdd = false,
    allowDelete = false,
    addTooltipText = 'Add Address',
    helpText = undefined,
    infoTooltipText = 'Address information is used to help you find convenient rescues and provide more relevant communication based on your location. This information is not shared outside of Food Rescue US.',
    isRescuer,
  } = props;
  return (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center">
        <Box p={1} pl={0} flexGrow={1}>
          <Divider />
        </Box>
        {helpText && (
          <>
            <Box p={1} pl={0}>
              {helpText}
            </Box>
            <Box p={1} pl={0} flexGrow={1}>
              <Divider />
            </Box>
          </>
        )}
        <Box p={1} pr={0} display="flex" alignItems="center">
          {isRescuer && (
            <Tooltip arrow placement="top" title={infoTooltipText}>
              <InfoIcon htmlColor={Colors.blue.main} />
            </Tooltip>
          )}

          <IconButton
            color="primary"
            disabled={!allowAdd}
            data-testid={`add-address_${index}`}
            size="small"
            onClick={() => fields.push({})}
          >
            <Tooltip arrow placement="top" title={addTooltipText}>
              <AddCircleOutlineIcon />
            </Tooltip>
          </IconButton>

          <IconButton
            disabled={!allowDelete}
            data-testid={`remove-address_${index}`}
            size="small"
            onClick={() => fields.remove(index)}
          >
            <Tooltip arrow placement="top" title="Delete this address">
              <RemoveCircleOutlineIcon htmlColor={allowDelete ? Colors.errorText : undefined} />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};
const LocationFields = props => {
  const { isMobileView = false, rescuerLocationTypes = {}, isRescuer = false, user = null } = props;
  const rescuerLocationTypesArray = Object.values(rescuerLocationTypes);

  if (rescuerLocationTypesArray.length === 0) {
    return <AddressDivider helpText="Loading location types..." />;
  }

  return (
    <FieldArray name="locations">
      {({ fields }) => (
        <Grid item xs={12}>
          {fields.length === 0 && <AddressDivider isRescuer={isRescuer} fields={fields} allowAdd />}

          {fields.map((name, index) => {
            const showAddButton =
              index + 1 === fields.length && (get(fields, `value.[${index}].address`, '') || '').length > 0;

            return (
              <Grid container key={name} data-testid={`location_${index}`}>
                <AddressDivider
                  isRescuer={isRescuer}
                  index={index}
                  fields={fields}
                  allowAdd={showAddButton}
                  allowDelete
                  addTooltipText="Add another address"
                />

                <Grid item xs={12}>
                  <Box alignItems="center" display="flex">
                    <Box width="100%">
                      <AddressField name={`${name}.address`} label="Street line 1" component={TextField} fullWidth />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <AddressField name={`${name}.address2`} label="Street line 2" component={TextField} fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <SelectZipCode
                    userZipCode={user?.zip_code}
                    fieldName={`${name}.zip`}
                    inputId={`${name}.zip`}
                    value={get(fields.value, `[${index}].zip`)}
                    onZipCodeChange={response => {
                      fields.update(index, {
                        ...fields.value[index],
                        zip: get(response, 'value'),
                        city: get(response, 'data.city'),
                        st: get(response, 'data.state'),
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AddressField name={`${name}.city`} label="City" component={TextField} fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <AddressField name={`${name}.st`} label="State" component={TextField} fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name={`${name}.type_id`}
                    label="Label"
                    component={Select}
                    native={isMobileView}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  >
                    {isMobileView
                      ? rescuerLocationTypesArray.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))
                      : rescuerLocationTypesArray.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name={`${name}.is_mailing_address`}
                    type="checkbox"
                    render={({ input, meta }) => {
                      const showError = isFormFieldInvalid(meta);

                      return (
                        <FormControl error={showError}>
                          <FormControlLabel
                            label="Is mailing address"
                            control={
                              <Checkbox
                                name={input.name}
                                checked={!!input.checked}
                                onFocus={input.onFocus}
                                onChange={event => {
                                  // set all `is_mailing_address` checkboxes to false
                                  fields.forEach((string, eachIndex) => {
                                    fields.update(eachIndex, {
                                      ...fields.value[eachIndex],
                                      is_mailing_address: false,
                                    });
                                  });

                                  // check/uncheck currently clicked checkbox
                                  input.onChange(event);
                                }}
                              />
                            }
                          />
                          {showError && <FormHelperText>{getFormFieldError(meta)}</FormHelperText>}
                        </FormControl>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </FieldArray>
  );
};

export default LocationFields;
