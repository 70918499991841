import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import RescuerDashboardDesktopContainer from './rescuerDashboardDesktop/RescuerDashboardDesktopContainer';
import * as authService from '../../services/auth';
import { Roles } from '../../models/roles';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultDashboard } from '../../actions/uiPersisted';
import routes from '../../routes';
import { Box, Typography } from '@material-ui/core';
import AccessDenied from '../../components/AccessDenied';
import LandingDashboard from './rescuerDashboardMobile/LandingDashboard';
import RescueMobileCard from '../Rescue/RescueMobileCard';

export function RescuesLoader({ highlighted, rows }) {
  return (
    <Box display="grid" gridRowGap={16} width="100%">
      {[...Array(rows || 10).keys()].map(index => (
        <RescueMobileCard key={index} highlighted={highlighted} isLoading />
      ))}
    </Box>
  );
}

export function NoRescueCard({ text }) {
  return (
    <Box width="100%" position="relative" boxShadow={25} bgcolor="background.paper" p={2}>
      <Typography color="textSecondary" variant="body1">
        {text}
      </Typography>
    </Box>
  );
}

export const RescuerDashboardContainer = ({ isMobileView }) => {
  const featureFlagsEntities = useSelector(state => state.entities.featureFlags);
  const activeUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const hasRescuerAccess = authService.hasRoleInAnySite(activeUser, Roles.Rescuer);
  const { params } = useRouteMatch();
  const dispatch = useDispatch();

  const route = isMobileView ? routes.mobileDashboard : routes.dashboardRescuer;

  useEffect(() => {
    if (hasRescuerAccess) {
      dispatch(setDefaultDashboard({ route: route, params }));
    }
  }, [params.tab]);

  if (!hasRescuerAccess) {
    return <AccessDenied />;
  }

  if (!isMobileView) {
    return <RescuerDashboardDesktopContainer />;
  }

  // wait for feature flags fetch before showing user page
  if (featureFlagsEntities.inflight) {
    return null;
  }

  return <LandingDashboard />;
};
