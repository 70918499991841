import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from './app';
import uiReducer from './ui';
import entitiesReducer from './entities';
import aggregatesReducer from './aggregates';
import miscReducer from './misc';
import tableOptionsReducer from './tableOptions';
import calendarOptionsReducer from './calendarOptions';

const rootReducer = combineReducers({
  app: appReducer,
  ui: persistReducer(
    {
      key: 'ui',
      storage,
      whitelist: ['persisted', 'filters'],
    },
    uiReducer
  ),
  entities: entitiesReducer,
  aggregates: aggregatesReducer,
  tableOptions: persistReducer(
    {
      key: 'tableOptions',
      storage,
    },
    tableOptionsReducer
  ),
  calendarOptions: persistReducer(
    {
      key: 'calendarOptions',
      storage,
    },
    calendarOptionsReducer
  ),
  misc: miscReducer,
});

export default rootReducer;
