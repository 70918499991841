export const CssBaseline = ({ spacing, palette, shape, typography, shadows }) => {
  return {
    MuiCssBaseline: {
      '@global': {
        '.introjs-helperLayer': {
          backgroundColor: 'rgb(255 255 255 / 20%)',
          border: '1px solid white',
          outline: '1px solid black',
        },
        '.introjs-tooltip': {
          minWidth: 250,
          maxWidth: 300,
        },
        '.introjs-tooltiptext': {
          ...typography.body1,
        },
        '.introjs-button:not(:first-child)': {
          marginLeft: spacing(1),
        },
        '.introjs-button': {
          backgroundImage: 'unset',
          border: 'unset',
          textShadow: 'unset',
          font: 'unset',
          padding: spacing(0.5, 1.2),
          ...typography.button,
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
          borderRadius: `${shape.borderRadius}px !important`,

          '&:active': {
            backgroundImage: 'unset',
            textDecoration: 'none',
            backgroundColor: palette.primary.dark,
            color: palette.primary.contrastText,
            boxShadow: shadows[25],
          },
          '&:hover': {
            backgroundImage: 'unset',
            textDecoration: 'none',
            backgroundColor: palette.primary.dark,
            color: palette.primary.contrastText,
          },
          '&:disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
};
