import queryString from 'query-string';
import { apiDELETE, apiGET, apiPOST, apiPUT, apiPATCH } from './api';

export const getUnassignedFoodDonors = () => apiGET(`/food_donors?unassigned=true`);

export const updateUnassignedFoodDonor = (foodDonorId, siteId) =>
  apiPATCH(`/food_donors/${foodDonorId}`, { site_id: siteId });

export const deleteUnassignedFoodDonor = foodDonorId => apiDELETE(`/food_donors/${foodDonorId}`);

export const getFoodDonors = (umbrellaFoodDonorId = null) => {
  const query = queryString.stringify(
    {
      donor_id: umbrellaFoodDonorId,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/food_donors?${query}`);
};

export const getFoodDonor = foodDonorId => apiGET(`/food_donors/${foodDonorId}`);

export const getFoodDonorsWithPendingVerification = siteIds => {
  let query = '';

  if (siteIds && siteIds.length > 0) {
    query = queryString.stringify({
      sites_ids: siteIds,
    });
  }

  if (query.length) {
    query = `&${query}`;
  }

  return apiGET(`/food_donors?verification_status=pending${query}`);
};

export const declineFoodDonorRequest = (foodDonorId, reason) =>
  apiPOST(`/food_donors/${foodDonorId}/verifications/decline`, {
    message: reason,
  });

export const approveFoodDonorRequest = (foodDonorId, { parentFoodDonorId, other }) =>
  apiPOST(`/food_donors/${foodDonorId}/verifications/approve`, {
    food_donor_parent_id: parentFoodDonorId,
    food_donor_other: other,
  });

export const createFoodDonor = data => apiPOST('/food_donors', data);

export const updateFoodDonor = (foodDonorId, data) => apiPUT(`/food_donors/${foodDonorId}`, data);

export const getFoodDonorStaff = foodDonorId => apiGET(`/food_donors/${foodDonorId}/staff`);

export const getFoodDonorPickupLocations = foodDonorId => apiGET(`/food_donors/${foodDonorId}/pickup_locations`);

export const createFoodDonorStuff = (foodDonorId, data) => apiPOST(`/food_donors/${foodDonorId}/staff`, data);

export const deleteFoodDonorStuff = (foodDonorId, staffId, data) =>
  apiDELETE(`/food_donors/${foodDonorId}/staff/${staffId}`, data);

export const createFoodDonorPickupLocation = (foodDonorId, data) =>
  apiPOST(`/food_donors/${foodDonorId}/pickup_locations`, data);

export const updateFoodDonorPickupLocation = (foodDonorId, pickupLocationId, data) =>
  apiPUT(`/food_donors/${foodDonorId}/pickup_locations/${pickupLocationId}`, data);

export const deleteFoodDonor = (foodDonorId) => apiDELETE(`/food_donors/${foodDonorId}`);
