import { objectFromArray } from '../helpers';
import * as rescuersActions from '../actions/rescuers';

export const RESCUERS_INITIAL_STATE = {
  byId: {},
  allIds: [],
  modifiedIds: [],
  modifiedById: {},
  photos: {
    byRescuerId: {},
    inflight: false,
    lastUpdated: null,
  },
  inflight: false,
};

const rescuersReducer = (state = RESCUERS_INITIAL_STATE, action) => {
  switch (action.type) {
    case rescuersActions.REQUEST_RESCUERS:
      return {
        ...state,
        inflight: true,
      };
    case rescuersActions.RECEIVE_RESCUERS:
      return {
        ...state,
        inflight: false,
        allIds: action.rescuers.map(r => r.id),
        byId: objectFromArray(action.rescuers, r => r.id),
        lastUpdated: action.receivedAt,
      };
      // @todo: add invalidate
    case rescuersActions.REQUEST_RESCUERS_PHOTOS:
      return {
        ...state,
        photos: {
          ...state.photos,
          inflight: true,
        }
      }
    case rescuersActions.RECEIVE_RESCUERS_PHOTOS:
      console.log( '-->', action )
      
      return {
        ...state,
        photos: {
          ...state.photos,
          byRescuerId: {
            ...state.photos.byRescuerId,
            [action.rescuerId]: action.photos,
          },
          inflight: false,
          lastUpdated: action.receivedAt,
        }
      }
    default:
      return state;
  }
};

export default rescuersReducer;
