import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as userRegistrationsActions from '../actions/userRegistrations';
import InProgressRegistrationsTable from '../components/InProgressRegistrationsTable';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
}));

const InProgressRegistrationsListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRegistrations = useSelector(state => state.entities.userRegistrations);
  const userRegistrationsList = userRegistrations.allIds.map(
    userRegistrationId => userRegistrations.byId[userRegistrationId]
  );
  const deleteUserRegistration = userRegistrationId => dispatch(userRegistrationsActions.deleteUserRegistration(userRegistrationId));

  useEffect(() => {
    dispatch(userRegistrationsActions.fetchUserRegistrationsIfNeeded());
  }, []);

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        <Typography color="textPrimary">
          In-progress Registrations
        </Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12}>
          <InProgressRegistrationsTable
            tableId="#user-registrations/in-progress-registrations"
            isLoading={userRegistrations.inflight}
            userRegistrations={userRegistrationsList}
            deleteUserRegistration={deleteUserRegistration}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InProgressRegistrationsListView;
