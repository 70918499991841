import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as rescuesActions from '../../actions/rescues';
import useActiveUser from '../../hooks/useActiveUser';
import { get } from 'lodash';
import Bluebird from 'bluebird';
import { BaseCard, BaseCardHeader } from '../../components/Common/BaseCard';
import {
  Box,
  Button,
  Checkbox,
  CardHeader,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  FormControlLabel,
  Grid,
  Typography,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Event as CalendarIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { AddressLine } from '../Rescue/RescueMobileCard';
import { getRescuePickupLocationFullName } from '../../helpers/RescuesHelper';
import { formatDate, formatTime } from '../../helpers/formatters';
import errorMessages from '../../assets/errorMessages';
import * as pickupSpecsActions from '../../actions/pickupSpecs';
import * as uiPersistedActions from '../../actions/uiPersisted';
import useNotificationService from '../../hooks/useNotificationService';
import { generatePath } from 'react-router-dom';
import routes from '../../routes';

const RenderDateChip = ({ dateTime }) => <Chip variant="outlined" icon={<CalendarIcon />} label={dateTime} />;

export const RESCUE_BTN_ADOPTED = 'adopted';
export const RESCUE_BTN_CANCEL = 'cancel';

const useStyles = makeStyles(() => ({
  breakWhiteSpaces: {
    whiteSpace: 'pre-wrap',
  },
}));

const RescueAdoptConfirmation = ({ match, history, location, isMobileView }) => {
  const classes = useStyles();
  const [tosAccepted, setTosAccepted] = useState(false);
  const [showPickupNotes, setShowPickupNotes] = useState(!isMobileView);
  const [showDropoffNotes, setShowDropoffNotes] = useState(!isMobileView);
  const { rescueId } = match.params;
  const dispatch = useDispatch();
  const rescuesEntities = useSelector(state => state.entities.rescues);
  const activeUser = useActiveUser();
  const rescueData = get(rescuesEntities, `byId[${rescueId}]`);
  const nextRescuesDates = get(rescuesEntities, `byId[${rescueId}].next_rescues_dates`, []);
  const { addErrorNotification } = useNotificationService();

  const adoptPickup = () => {
    return Bluebird.try(() =>
      dispatch(pickupSpecsActions.adoptPickupSpec(rescueData.pickup_spec_id, { adopter_id: activeUser.id }))
    )
      .then(() => goBack(RESCUE_BTN_ADOPTED))
      .catch(err => {
        window.scrollTo(0, 0);

        switch (err.code) {
          case errorMessages.ERR_UNABLE_TO_ADOPT_PICKUP_USER_EMAIL_NOT_VERIFIED.code:
            dispatch(uiPersistedActions.showEmailVerificationNotification());
            return addErrorNotification(err, errorMessages.ERR_UNABLE_TO_ADOPT_PICKUP_USER_EMAIL_NOT_VERIFIED.message);
          case errorMessages.ERR_UNABLE_TO_ADOPT_PICKUP_USER_PHONE_NOT_VERIFIED.code:
            dispatch(uiPersistedActions.showPhoneVerificationNotification());
            return addErrorNotification(err, errorMessages.ERR_UNABLE_TO_ADOPT_PICKUP_USER_PHONE_NOT_VERIFIED.message);
          default:
            return addErrorNotification(err);
        }
      });
  };

  const goBack = option => {
    if (location.state && location.state.prevPath) {
      return history.push(location.state.prevPath, {
        adoptButton: option,
        rescueId: rescueData.id,
        pickupSpecId: rescueData.pickup_spec_id,
      });
    }

    return history.push(generatePath(routes.rescue_edit, { rescueId: rescueData.id }), {
      adoptButton: option,
      rescueId: rescueData.id,
      pickupSpecId: rescueData.pickup_spec_id,
    });
  };

  useEffect(() => {
    dispatch(rescuesActions.fetchRescuerAvailableRescues(activeUser.id, rescueId, null, null, false, true));
  }, [dispatch, activeUser.id, rescueId]);

  if (!rescueData || rescuesEntities.inflight) {
    return 'Loading...';
  }

  const rescueTimeWindow = `${formatTime(rescueData.pickup_begin)} - ${formatTime(rescueData.pickup_end)}`;

  const rescueDetailsArea = () => (
    <Grid item>
      <BaseCard>
        <CardHeader
          titleTypographyProps={{ variant: 'h5', color: 'textSecondary' }}
          title="Rescue Adopt Confirmation"
        />
        <CardContent>
          <Box pl={2} display="flex" flexDirection="column" pr={2}>
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2">{rescueTimeWindow}</Typography>
            </Box>

            <Box display="flex" py={1}>
              <AddressLine />
              <Box display="flex" flexDirection="column" flexGrow={1} pl={1}>
                <Box my={0.5}>
                  <Typography variant="body1">
                    {getRescuePickupLocationFullName({
                      pickup_location_name: rescueData.pickup_location_name,
                      location: rescueData.location,
                    })}
                  </Typography>
                </Box>
                <Box my={0.5}>
                  <Divider />
                </Box>
                <Box my={0.5}>
                  <Typography variant="body1">{rescueData.receiver || 'N/A'}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </BaseCard>
    </Grid>
  );

  const rescueScheduleArea = () => (
    <Grid item>
      <BaseCard>
        <BaseCardHeader title="Schedule (Next 10 Rescue Dates)" />

        <CardContent>
          <Grid container spacing={1}>
            {nextRescuesDates.map(date => (
              <Grid item key={date}>
                <RenderDateChip dateTime={formatDate(date)} />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </BaseCard>
    </Grid>
  );

  const rescueSummaryArea = () => (
    <Grid item>
      <BaseCard>
        <BaseCardHeader title="Summary" />

        <CardContent>
          <Typography variant="body1">
            Adopting a rescue means that you are signing up to complete all the rescues between a donor and a receiving
            agency according to the rescue schedule.
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={tosAccepted}
                onChange={event => setTosAccepted(event.target.checked)}
                value="termsAccepted"
                style={{ paddingTop: 0 }}
              />
            }
            style={{ marginTop: 15, alignItems: 'flex-start' }}
            label="I agree to complete all of the scheduled rescues. I understand that I can 'unadopt' the recurring rescue at any time, and that I can 'release' any given day if I can't make it."
          />

          <div style={{ marginTop: 15 }}>
            <Button
              onClick={adoptPickup}
              disabled={tosAccepted === false}
              variant="contained"
              color="primary"
              style={{ marginRight: 15 }}
            >
              Confirm
            </Button>

            <Button onClick={() => goBack(RESCUE_BTN_CANCEL)} color="primary" variant="outlined">
              Cancel
            </Button>
          </div>
        </CardContent>
      </BaseCard>
    </Grid>
  );

  const rescuePickupNotesArea = () =>
    rescueData.pickup_notes && (
      <Grid item>
        <BaseCard>
          <BaseCardHeader
            onClick={() => setShowPickupNotes(!showPickupNotes)}
            action={
              <IconButton onClick={() => setShowPickupNotes(!showPickupNotes)}>
                {showPickupNotes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
            title="Pickup Notes"
          />

          <Collapse in={showPickupNotes}>
            <CardContent>
              <Typography variant="body2" className={classes.breakWhiteSpaces}>
                {rescueData.pickup_notes}
              </Typography>
            </CardContent>
          </Collapse>
        </BaseCard>
      </Grid>
    );

  const rescueDropoffNotesArea = () =>
    rescueData.dropoff_notes && (
      <Grid item>
        <BaseCard>
          <BaseCardHeader
            onClick={() => setShowDropoffNotes(!showDropoffNotes)}
            action={
              <IconButton onClick={() => setShowDropoffNotes(!showDropoffNotes)}>
                {showDropoffNotes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
            title="Dropoff Notes"
          />

          <Collapse in={showDropoffNotes}>
            <CardContent>
              <Typography variant="body2" className={classes.breakWhiteSpaces}>
                {rescueData.dropoff_notes}
              </Typography>
            </CardContent>
          </Collapse>
        </BaseCard>
      </Grid>
    );

  if (isMobileView) {
    return (
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={1} direction="column" justify="flex-start" alignItems="stretch">
          {rescueDetailsArea()}
          {rescueScheduleArea()}
          {rescuePickupNotesArea()}
          {rescueDropoffNotesArea()}
          {rescueSummaryArea()}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={7} spacing={1} direction="column" justify="flex-start" alignItems="stretch">
        {rescueDetailsArea()}
        {rescueScheduleArea()}
        {rescueSummaryArea()}
      </Grid>

      <Grid container item xs={12} md={5} spacing={1} direction="column" justify="flex-start" alignItems="stretch">
        {rescuePickupNotesArea()}
        {rescueDropoffNotesArea()}
      </Grid>
    </Grid>
  );
};

export default RescueAdoptConfirmation;
