import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_SITE_GOALS } from '../components/FeatureFlagWrapper';
import { fetchSiteGoals } from '../actions/siteGoals';
import { Box, Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../pages/rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../routes';
import { Home as HomeIcon } from '@material-ui/icons';
import { siteGoalsJsx } from '../pages/rescuerDashboard/rescuerDashboardDesktop/myFrus/Components/MySiteGoals';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  goalsContainer: {
    height: 'calc(100vh - 100px)', // -100 to remove navbar & breadcrumbs
    overflow: 'scroll',
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const GoalsBreadcrumb = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={RouterLink}
        to={routes.index}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="Site Goals" />
    </Breadcrumbs>
  </Box>
);

const SiteGoalsDashboardMobileContainer = () => {
  const classes = useStyles();
  const goalsEntities = useSelector((state) => state.entities.siteGoals.byId);
  const siteId = useSelector((state) => state.app.site.id);
  const dispatch = useDispatch();
  const hasActiveSiteGoalsFeatureFlag = useHasActiveFeatureFlag(FF_SITE_GOALS);
  const goalsList = Object.values(goalsEntities);

  useEffect(() => {
    if (hasActiveSiteGoalsFeatureFlag) {
      dispatch(fetchSiteGoals({ sites_ids: siteId }));
    }
  }, []);

  return (
    <>
      <GoalsBreadcrumb />
      <Box className={classes.goalsContainer}>
        {goalsList < 1 && (
          <Box className={classes.noData}>
            <Typography color="textSecondary" variant="h5">
              There are no site goals set for your site(s).
            </Typography>
          </Box>
        )}
        <Box display="flex" flexWrap="wrap" gridColumnGap="5px" gridRowGap="5px" px="5px" overflow="scroll">
          {siteGoalsJsx({ siteGoals: goalsList, rowGap: 12 })}
        </Box>
      </Box>
    </>
  );
};

export default SiteGoalsDashboardMobileContainer;
