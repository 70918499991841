import React from 'react';
import { generatePath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Link, makeStyles, MenuItem, Select } from '@material-ui/core';
import { SyncAlt } from '@material-ui/icons';
import moment from 'moment';
import * as authServices from '../services/auth';
import { Roles } from '../models/roles';
import routes from '../routes/index';
import { getUserFullName } from '../helpers/user';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import { formatCsvFileName, formatDate } from '../helpers/formatters';
import { isUndefinedOrNull } from '../helpers';
import { Colors } from '../assets/theme/Colors';
import useActiveSite from '../hooks/useActiveSite';

const useStyles = makeStyles(() => ({
  disabledSiteRow: {
    borderLeft: `8px solid ${Colors.sites.disabled.color}`,
  },
}));

const SitesTable = ({ history, sites, onRowClick, onSDClick, tableId, customToolbar }) => {
  const classes = useStyles();
  
  if (!sites) {
    return <div>no sites</div>;
  }

  const getCurrentlyLoggedInUser = authServices.getCurrentlyLoggedInOrImpersonatingUser();
  const currentlyLoggedInUserIsAdminOrNsd = authServices.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite(
    [Roles.Admin, Roles.NationalSiteDirector]
  );
  const currentlyLoggedInUserIsAdminNSDorSDSC = authServices.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite(
    [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator]
  );
  const isSiteDirectorInAnySite = authServices.hasRoleInAnySite(getCurrentlyLoggedInUser, Roles.SiteDirector);
  const activeSite = useActiveSite();

  const tableActions = rowIndex => {
    const site = sites[rowIndex];
    const isSiteDirector = authServices.hasAnyRoleInSite(getCurrentlyLoggedInUser, site.id, [
      Roles.SiteDirector,
      Roles.SiteCoordinator,
    ]);

    return (
      <TableActionsButtonsWrapper>
        <TableActionButton
          title="Switch Site"
          disabled={site.id === activeSite.id}
          icon={SyncAlt}
          onClick={() => onRowClick(site)}
        />

        {(currentlyLoggedInUserIsAdminOrNsd || isSiteDirector) && (
          <TableActionButton
            title="Edit Site"
            onClick={() => history.push(generatePath(routes.siteEdit, { id: site.id }))}
          />
        )}
      </TableActionsButtonsWrapper>
    );
  };
  
  const nameCustomBodyRender = (value, tableMeta, csv = false) => {
    const site = sites[getMuiTableDataIndex(tableMeta)];
  
    if (csv) {
      return site.name;
    }
    
    return (
      <>
        {site.name}
      
        {site && site.disabled_at !== null && (
          <>
            <br />
          
            <span style={{ color: Colors.sites.disabled.color, fontWeight: 'bold' }}>
              Disabled
            </span>
          </>
        )}
      </>
    );
  };

  const typeCustomBodyRender = (value, tableMeta, csv = false) => {
    const site = sites[getMuiTableDataIndex(tableMeta)];

    if (csv) {
      return site.type;
    }

    return site.type.charAt(0).toUpperCase() + site.type.slice(1); // first letter to uppercase
  };
  
  const siteDirectorCustomBodyRender = (value, tableMeta, csv = false) => {
    const primaryContactId = sites[getMuiTableDataIndex(tableMeta)].primary_contact_id;
  
    if (!primaryContactId) {
      return null;
    }
    
    const userFullName = getUserFullName({
      firstname: sites[getMuiTableDataIndex(tableMeta)].primary_contact_firstname,
      lastname: sites[getMuiTableDataIndex(tableMeta)].primary_contact_lastname,
    });
    
    if (csv) {
      return userFullName;
    }
  
    return (
      <Link to="#" onClick={() => onSDClick(primaryContactId)}>
        {userFullName}
      </Link>
    );
  };
  
  const zipCodesCustomBodyRender = value => value.length ? value.join(', ') : 'No zip codes';
  
  const disabledAtCustomBodyRender = value => isUndefinedOrNull(value) ? 'Enabled' : 'Disabled';

  const sitesCreationDates = sites.reduce((acc, curr) => {
    const findDuplicate = acc.find(date => date === curr.created_at);
    if (!findDuplicate) {
      acc.push(curr.created_at);
    }
    return acc;
  }, []);

  // Change date to quarter date range
  const dateToQuarterRange = date => {
    const dateToMonth = moment(date).format('MMM');
    let quarter;

    if (dateToMonth === 'Jan' || dateToMonth === 'Feb' || dateToMonth === 'Mar') quarter = 'Jan - Mar';
    if (dateToMonth === 'Apr' || dateToMonth === 'May' || dateToMonth === 'Jun') quarter = 'Apr - Jun';
    if (dateToMonth === 'Jul' || dateToMonth === 'Aug' || dateToMonth === 'Sep') quarter = 'Jul - Sep';
    if (dateToMonth === 'Oct' || dateToMonth === 'Nov' || dateToMonth === 'Dec') quarter = 'Oct - Dec';

    const quarterDateWithYear = `${quarter} ${moment(date).format('YYYY')}`;
    return quarterDateWithYear;
  };

  // Change site creation dates to quarter range to use it as a filter
  const sitesCreationDatesCompressedToQuarters = sitesCreationDates.reduce((acc, curr) => {
    const quarterDate = dateToQuarterRange(curr);

    const findDuplicate = acc.find(date => date === quarterDate);
    if (!findDuplicate) {
      acc.push(quarterDate);
    }
    return acc;
  }, []);

  const renderDatesList = (filterList, onChange, index, column) => (
    <FormControl>
      <InputLabel htmlFor="date-selector">{column.label}</InputLabel>
      <Select
        id="date-selector"
        value={filterList[index][0] || 'All'}
        onChange={event => {
          filterList[index][0] = event.target.value;
          onChange(filterList[index][0] !== 'All' ? filterList[index] : [], index, column);
        }}
      >
        <MenuItem value="All">All</MenuItem>
        {sitesCreationDatesCompressedToQuarters.map(date => (
          <MenuItem key={date} value={date}>
            {date}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <BaseMUIDataTable
      tableId={tableId}
      title="Sites"
      data={sites}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'created_at',
          label: 'Start Date',
          options: {
            display: currentlyLoggedInUserIsAdminNSDorSDSC || 'excluded',
            filter: currentlyLoggedInUserIsAdminNSDorSDSC || false,
            forceDisplay: !currentlyLoggedInUserIsAdminNSDorSDSC,
            filterType: 'custom',
            filterOptions: {
              logic: (date, filters) => {
                if (!filters || filters.length === 0) {
                  return false;
                }
                const quarterDate = dateToQuarterRange(date);

                return !filters.includes(quarterDate);
              },
              display: renderDatesList,
            },
            customFilterListOptions: {
              render: value => `Start Date: ${value}`,
            },
            customBodyRender: value => formatDate(value),
            customBodyRenderCSV: value => formatDate(value),
          },
        },
        {
          name: 'name',
          label: 'Name',
          options: {
            customFilterListOptions: {
              render: value => `Name: ${value}`,
            },
            customBodyRender: (value, tableMeta) => nameCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => nameCustomBodyRender(value, tableMeta, true),
          },
        },
        {
          name: 'type',
          label: 'Type',
          options: {
            customFilterListOptions: {
              render: value => `Type: ${value}`,
            },
            customBodyRender: (value, tableMeta) => typeCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => typeCustomBodyRender(value, tableMeta, true),
          },
        },
        {
          name: 'site_director',
          label: 'Site Director',
          options: {
            customFilterListOptions: {
              render: value => `Site Director: ${value}`,
            },
            setCellProps: cellValue => ({
              style: {
                cursor: cellValue && cellValue.props.children ? 'pointer' : 'initial',
              },
            }),
            customBodyRender: (value, tableMeta) => siteDirectorCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => siteDirectorCustomBodyRender(value, tableMeta, true),
          },
        },
        {
          name: 'zip_codes',
          label: 'Zip Codes',
          options: {
            filter: false,
            sort: false,
            display: isSiteDirectorInAnySite,
            customBodyRender: value => zipCodesCustomBodyRender(value),
            customBodyRenderCSV: value => zipCodesCustomBodyRender(value),
          },
        },
        {
          name: 'disabled_at',
          label: 'Enabled',
          options: {
            display: currentlyLoggedInUserIsAdminNSDorSDSC || 'excluded',
            filter: currentlyLoggedInUserIsAdminNSDorSDSC || false,
            forceDisplay: !currentlyLoggedInUserIsAdminNSDorSDSC,
            filterList: ['Enabled'],
            customFilterListOptions: {
              render: value => `Enabled: ${value}`,
            },
            customBodyRender: value => disabledAtCustomBodyRender(value),
            customBodyRenderCSV: value => disabledAtCustomBodyRender(value),
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            display: true,
            customBodyRenderLite: tableActions,
          },
        },
      ]}
      options={{
        customToolbar: customToolbar,
        responsive: 'simple',
        selectableRows: 'none',
        setRowProps: (row, dataIndex) => {
          const site = sites[dataIndex];
          
          if (!site || site.disabled_at === null) {
            return false;
          }
    
          return {
            className: classes.disabledSiteRow,
          };
        },
        downloadOptions: {
          filename: formatCsvFileName('Sites'),
        },
      }}
    />
  );
};

SitesTable.propTypes = {
  customToolbar: PropTypes.func,
};

SitesTable.defaultProps = {
  customToolbar: () => null,
};

export default withRouter(SitesTable);
