import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import { makeStyles } from '@material-ui/core';
import ButtonWithLoading from './ButtonWithLoading';
import errorMessages from '../assets/errorMessages';
import useNotificationService from '../hooks/useNotificationService';
import { TextFieldWrapper as TextField } from './Form/MuiFormWrapper';

const useStyles = makeStyles(() => ({
  form: {
    maxWidth: 480,
  },
  textWide: {
    minWidth: 480,
    marginBottom: 15,
  },
}));

const validationResolver = data => {
  const errors = {};

  if (!data.name) {
    errors.name = { message: errorMessages.REQUIRED.message };
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors: errors,
  };
};

const UmbrellaFoodDonorAddForm = ({ createUmbrellaFoodDonor }) => {
  const classes = useStyles();
  const { addErrorNotification } = useNotificationService();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    register,
    triggerValidation,
    getValues,
    reset,
    errors,
    control,
  } = useForm({
    validationResolver: validationResolver,
  });

  const onSubmit = async data => {
    try {
      setIsSubmitting(true);

      return createUmbrellaFoodDonor(data);
    } catch (err) {
      setIsSubmitting(false);

      switch (err.code) {
        default:
          addErrorNotification(err);

          break;
      }

      // reset form with the current values - to clear touched / dirty flags (there is currently no other way to do it)
      // reset form is also unregistering previously custom registered fields with useEffect, so we have to bring
      // these back and then trigger validation to show API errors
      await reset(getValues(), { isSubmitted: true });

      return triggerValidation();
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        required
        id="name"
        name="name"
        className={classes.textWide}
        label="Umbrella Food Donor Name"
        variant="outlined"
        size="small"
        inputRef={register}
        InputLabelProps={{ shrink: !!getValues('name') || undefined }}
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : ''}
      />

      <ButtonWithLoading type="submit" variant="contained" color="primary" isLoading={isSubmitting}>
        Create Umbrella Food Donor
      </ButtonWithLoading>

      {process.env.NODE_ENV === 'development' && (
        <DevTool control={control} />
      )}
    </form>
  );
};

export default UmbrellaFoodDonorAddForm;
