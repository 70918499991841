import React, { useEffect } from 'react';
import Bluebird from 'bluebird';
import Linkify from 'react-linkify';
import RescueEditRescuerForm from './RescueEditRescuerForm';
import RescueDetailsView from '../RescueDetailsView';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRescue as fetchRescueAction } from '../../../../actions/rescues';
import { GridRow, RowContent, RowTitle } from '../FormGrid';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../../../../routes';
import errorMessages from '../../../../assets/errorMessages';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  breakWhiteSpaces: {
    whiteSpace: 'pre-wrap',
  },
}));

const RescueEditRescuerView = ({ isMobileView, user, rescueId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const rescues = useSelector(state => state.entities.rescues);
  const rescue = rescues.byId[rescueId];

  const fetchRescue = () => Bluebird
    .try(() => dispatch(fetchRescueAction(rescueId)))
    .catch(err => {
      history.push(routes.index);
      switch (err.code) {
        case errorMessages.ERR_RESCUE_NOT_FOUND.code:
          snackbarHelper.error(errorMessages.ERR_RESCUE_NOT_FOUND.message);
          break;
        default:
          snackbarHelper.error(err.message);
          break;
      }
    });

  useEffect(() => {
    fetchRescue();
  }, [dispatch]);

  if (!rescue) {
    return 'loading....';
  }

  return (
    <RescueDetailsView rescue={rescue} testId="rescue-rescuer-details">
      <GridRow>
        <RowTitle>Notes:</RowTitle>
        <RowContent>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <span className={classes.breakWhiteSpaces}>{rescue.rescuer_notes}</span>
          </Linkify>
        </RowContent>
      </GridRow>
      <RescueEditRescuerForm rescue={rescue} isMobileView={isMobileView} refreshData={fetchRescue} user={user} />
    </RescueDetailsView>
  );
};

export default RescueEditRescuerView;
