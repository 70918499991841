import omit from 'lodash/omit';
import * as notificationsActions from '../actions/notifications';
import { objectFromArray } from '../helpers';

export const initialNotificationsState = {
  read: {
    byId: {},
    allIds: [],
  },
  unread: {
    byId: {},
    allIds: [],
  },
  inflight: false,
  lastUpdated: null,
};

const notificationsReducer = (state = initialNotificationsState, action) => {
  switch (action.type) {
    case notificationsActions.DELETE_NOTIFICATION:
      return {
        ...state,
        read: {
          byId: omit(state.read.byId, action.notificationId),
          allIds: state.read.allIds.filter(n => n !== action.notificationId),
        },
      };
    case notificationsActions.MARK_NOTIFICATION_AS_READ:
      return {
        ...state,
        unread: {
          byId: omit(state.unread.byId, action.notificationId),
          allIds: state.unread.allIds.filter(id => id !== action.notificationId),
        },
        read: {
          byId: {
            ...state.read.byId,
            [action.notificationId]: {
              ...state.unread.byId[action.notificationId],
              read_at: (new Date()).toISOString()
            },
          },
          allIds: [...new Set([...state.read.allIds, action.notificationId])],
        },
      };
    case notificationsActions.RECEIVE_NOTIFICATION:
      const receiveNType = action.notification.read_at !== null ? 'read' : 'unread';

      return {
        ...state,
        [receiveNType]: {
          byId: {
            ...state[receiveNType].byId,
            [action.notification.id]: {
              ...state[receiveNType].byId[action.notification.id],
              ...action.notification,
            },
          },
          allIds: [...new Set([...state[receiveNType].allIds, action.notification.id])],
        },
      };
    case notificationsActions.DELETE_NOTIFICATIONS:
      return {
        ...state,
        read: {
          byId: omit(state.read.byId, action.notificationsIds),
          allIds: state.read.allIds.filter(n => action.notificationsIds.indexOf(n) < 0),
        },
      };
    case notificationsActions.REQUEST_NOTIFICATIONS:
      return {
        ...state,
        inflight: action.status,
      };
    case notificationsActions.RECEIVE_NOTIFICATIONS:
      const notificationsObj = action.notifications.reduce(
        (obj, notification) => {
          const key = notification.read_at !== null ? 'read' : 'unread';
          obj[key].push(notification);
          return obj;
        },
        { read: [], unread: [] }
      );

      return {
        ...state,
        inflight: false,
        lastUpdated: action.receivedAt,
        read: {
          byId: objectFromArray(notificationsObj.read, r => r.id),
          allIds: notificationsObj.read.map(r => r.id),
        },
        unread: {
          byId: objectFromArray(notificationsObj.unread, r => r.id),
          allIds: notificationsObj.unread.map(r => r.id),
        },
      };
    default:
      return state;
  }
};

export default notificationsReducer;
