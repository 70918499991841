import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Box, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import UmbrellaFoodDonorLocationsTable from '../../components/UmbrellaFoodDonorLocationsTable';
import TableActionsButtonsWrapper from '../../components/TableActionsButtonsWrapper';
import TableActionButton from '../../components/TableActionButton';
import routes from '../../routes';
import { RescuesFromSiteFilterTypes } from '../../containers/FutureRescuesListView';

export const donorLocationsTableId = '#donors/locations-table';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 140,
  },
}));

const UmbrellaFoodDonorLocationView = ({ umbrellaFoodDonorId }) => {
  const classes = useStyles();
  const [donorsFromType, setDonorsFromType] = useState(RescuesFromSiteFilterTypes.all);
  const foodDonors = useSelector(state => state.entities.foodDonors);
  const umbrellaFoodDonorLocations = foodDonors.byUmbrellaFoodDonorId[umbrellaFoodDonorId];
  const sites = useSelector(state => state.entities.sites);
  const sitesList = Object.values(sites.byId);

  const filterDonorLocations = (location) => {
    const donorLocationSite = sitesList.find((site) => site.id === location.site_id);
    const siteDisabled = donorLocationSite.disabled_at;

    if (donorsFromType === RescuesFromSiteFilterTypes.enabled) {
      return !siteDisabled;
    }

    if (donorsFromType === RescuesFromSiteFilterTypes.disabled) {
      return siteDisabled;
    }

    return true;
  };

  const filteredUmbrellaFoodDonorLocations = umbrellaFoodDonorLocations?.filter(filterDonorLocations);

  const onDonorsFromFilterChange = (value) => {
    setDonorsFromType(value);
  };

  const customToolbar = () => (
    <span>
      <Button
        startIcon={<AddIcon fontSize="small" />}
        type="button"
        variant="contained"
        color="primary"
        size="small"
        component={Link}
        to={generatePath(routes.umbrellaFoodDonorAddToUmbrella, { id: umbrellaFoodDonorId })}
      >
        Add Food Donor
      </Button>
    </span>
  );

  const actionButtons = foodDonor => (
    <TableActionsButtonsWrapper>
      <TableActionButton
        title="Edit Food Donor"
        buttonProps={{
          component: Link,
          to: generatePath(routes.foodDonor, { foodDonorId: foodDonor.id, tab: 'profile' }),
        }}
      />
    </TableActionsButtonsWrapper>
  );

  return (
    <>
      <Box my={1} p={2} bgcolor="background.paper" boxShadow={25}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <FormControl variant="outlined" size="small" className={classes.formControl}>
              <InputLabel shrink>Donors from:</InputLabel>

              <Select
                label="Donors from"
                value={donorsFromType}
                onChange={(event) => onDonorsFromFilterChange(event.target.value)}
              >
                {[
                  RescuesFromSiteFilterTypes.all,
                  RescuesFromSiteFilterTypes.enabled,
                  RescuesFromSiteFilterTypes.disabled,
                ].map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <UmbrellaFoodDonorLocationsTable
        tableId={donorLocationsTableId}
        isLoading={foodDonors.inflight}
        sites={sites.byId}
        umbrellaFoodDonorLocations={filteredUmbrellaFoodDonorLocations}
        actionButtons={actionButtons}
        customToolbar={customToolbar}
      />
    </>
  );
};

export default UmbrellaFoodDonorLocationView;
