import moment from 'moment';
import { get } from 'lodash';
import { FREQUENCY_ONCE } from './donationsNew';

export const mapFormToCreateEventPOST = eventData => ({
  name: eventData.title,
  description: eventData.description || null,
  start_date: eventData.start_date,
  end_date: eventData.end_date || null,
  pause_date: eventData.pause_date || null,
  resume_date: eventData.resume_date || null,
  frequency: eventData.frequency || FREQUENCY_ONCE,
  days_of_week: eventData.days_of_week
    ? JSON.stringify(eventData.days_of_week)
    : JSON.stringify({
        mon: {
          checked: false,
        },
        tue: {
          checked: false,
        },
        wed: {
          checked: false,
        },
        thu: {
          checked: false,
        },
        fri: {
          checked: false,
        },
        sat: {
          checked: false,
        },
        sun: {
          checked: false,
        },
  }),
  days_of_month: eventData.days_of_month
    ? JSON.stringify(eventData.days_of_month)
    : JSON.stringify({
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      }),
  begin: eventData.begin,
  end: eventData.end,
  digital: eventData.digital || null,
  link: eventData.link || null,
  address: eventData.address,
  city: eventData.city,
  state: eventData.state,
  zip_code: eventData.zip,
  created_by_id: parseInt(eventData.user.id, 10),
  shifts: eventData.shifts.map(shift => ({
    begin: shift.begin,
    end: shift.end,
    rescuers: shift.rescuers.filter((rescuer) => rescuer?.id) || [],
    min_volunteers: parseInt(shift.min_volunteers, 10) || null,
    max_volunteers: parseInt(shift.max_volunteers, 10) || null,
  })),
});

export const mapFormToEditEventPUT = eventData => ({
  name: eventData.title,
  description: eventData.description || null,
  start_date: eventData.start_date,
  end_date: eventData.end_date || null,
  pause_date: eventData.pause_date || null,
  resume_date: eventData.resume_date || null,
  frequency: eventData.frequency || FREQUENCY_ONCE,
  days_of_week: eventData.days_of_week
    ? JSON.stringify(eventData.days_of_week)
    : JSON.stringify({
        mon: {
          checked: false,
        },
        tue: {
          checked: false,
        },
        wed: {
          checked: false,
        },
        thu: {
          checked: false,
        },
        fri: {
          checked: false,
        },
        sat: {
          checked: false,
        },
        sun: {
          checked: false,
        },
      }),
  days_of_month: eventData.days_of_month
    ? JSON.stringify(eventData.days_of_month)
    : JSON.stringify({
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      }),
  begin: eventData.begin,
  end: eventData.end,
  digital: eventData.digital || null,
  link: eventData.link || null,
  location_id: eventData.location_id || null,
  address: eventData.address,
  city: eventData.city,
  state: eventData.state,
  zip_code: eventData.zip,
  updated_by_id: parseInt(eventData.user.id, 10),
  shifts: eventData.shifts.map(shift => ({
    id: shift.id ? parseInt(shift.id, 10) : undefined,
    begin: shift.begin,
    end: shift.end,
    rescuers: shift.rescuers ? shift.rescuers.filter((rescuer) => rescuer?.id) : [],
    min_volunteers: parseInt(shift.min_volunteers, 10) || null,
    max_volunteers: parseInt(shift.max_volunteers, 10) || null,
  })),
});

export const mapStoreToFormEventEdit = eventData => ({
  id: eventData.id,
  title: eventData.name,
  description: eventData.description || '',
  start_date: eventData.start_date ? moment(eventData.start_date.value).format(eventData.start_date.format) : null,
  begin: moment(eventData.begin.value, eventData.begin.format),
  end: moment(eventData.end.value, eventData.end.format),
  end_date: eventData.end_date ? moment(eventData.end_date.value).format(eventData.end_date.format) : null,
  pause_date: eventData.pause_date ? moment(eventData.pause_date.value).format(eventData.pause_date.format) : null,
  resume_date: eventData.resume_date ? moment(eventData.resume_date.value).format(eventData.resume_date.format) : null,
  frequency: eventData.frequency || FREQUENCY_ONCE,
  days_of_week: eventData.days_of_week
    ? JSON.parse(eventData.days_of_week)
    : {
        mon: {
          checked: false,
        },
        tue: {
          checked: false,
        },
        wed: {
          checked: false,
        },
        thu: {
          checked: false,
        },
        fri: {
          checked: false,
        },
        sat: {
          checked: false,
        },
        sun: {
          checked: false,
        },
      },
  days_of_month: eventData.days_of_month
    ? JSON.parse(eventData.days_of_month)
    : {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      },
  digital: eventData.digital || false,
  link: eventData.link || '',
  location_id: eventData.location_id || null,
  address: eventData.location.address || '',
  city: eventData.location.city || '',
  state: eventData.location.st || '',
  zip: eventData.location.zip || '',
  shifts: eventData.shifts
    .filter((shift) => shift.deleted_at === null)
    .map((shift) => ({
      id: shift.id,
      begin: moment(shift.begin.value, shift.begin.format),
      end: moment(shift.end.value, shift.end.format),
      activeRegistrations: get(shift, 'registrations', []).filter((registration) => registration.deleted_at === null)
        .length,
      min_volunteers: parseInt(shift.min_volunteers, 10) || '',
      max_volunteers: parseInt(shift.max_volunteers, 10) || '',
      rescuers: shift.registrations.map((reg) => reg?.user),
    })),
});
