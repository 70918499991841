import React, { useState } from 'react';
import { Slider, ListItemText, ListItem, Box } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import FilterComponent from './FilterComponent';

export const DISTANCE_ALL_VALUE = 50;

export const getDistanceLabel = value => {
  if (value === DISTANCE_ALL_VALUE) {
    return 'Distance: all';
  }

  return `Distance: ${value} miles`;
};

const distanceMarks = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: DISTANCE_ALL_VALUE,
    label: 'all',
  }
];

const DistanceSliderFilter = ({ value, handleSliderChange }) => {
  const [isDistanceOpen, setIsDistanceOpen] = useState(false);
  const [distanceValue, setDistanceValue] = useState(value);

  return (
    <>
      <ListItem button onClick={() => setIsDistanceOpen(!isDistanceOpen)}>
        <ListItemText id="distance-slider" primary={getDistanceLabel(distanceValue)} />
        {isDistanceOpen ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <FilterComponent isOpen={isDistanceOpen}>
        <Box py={2} px={4} width="100%">
          <Slider
            onChangeCommitted={(event, value) => handleSliderChange(value)}
            onChange={(event, value) => setDistanceValue(value)}
            value={distanceValue}
            track={false}
            min={5}
            step={5}
            max={DISTANCE_ALL_VALUE}
            getAriaValueText={value => `${value} miles`}
            aria-labelledby="distance-slider"
            marks={distanceMarks}
          />
        </Box>
      </FilterComponent>
    </>
  );
};

export default DistanceSliderFilter;
