import React, { useState } from 'react';
import ClearChip from '../../../../components/ClearChip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import { Field } from 'react-final-form';
import * as authService from '../../../../services/auth';
import { getUserFullName } from '../../../../helpers/user';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addressRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  timePicker: {
    backgroundColor: 'pink',
    '& input': {
      textAlign: 'center',
    },
  },
  autocompleteRoot: {
    minWidth: 300,
  },
}));
const RescuerField = ({ rescuersList, form }) => {
  const values = form.getState().values;
  const classes = useStyles();
  const [showRescuerSelector, setShowRescuerSelector] = useState(false);

  const releaseRescue = form => () => {
    const user = authService.getCurrentlyLoggedInOrImpersonatingUser();
    setShowRescuerSelector(true);
    form.batch(() => {
      form.change('rescuer_id', null);
      form.change('rescuer', null);
      form.change('released_by_id', user.id);
      form.change('releaser', getUserFullName(user));
    });
  };

  return (
    <Field
      name="rescuer"
      render={({ input, meta }) => {
        if (values.rescuer) {
          return (
            <ClearChip
              value={input.value}
              onClick={() => {
                input.onChange(null);
                form.change('rescuer_id', null);
              }}
            />
          );
        }

        if (!showRescuerSelector && values.adopter && values.rescuer_id === null && values.released_by_id === null) {
          return <ClearChip value={values.adopter} onClick={releaseRescue(form)} />;
        }

        return (
          values.rescuer_id === null && (
            <Autocomplete
              options={rescuersList}
              classes={{
                root: classes.autocompleteRoot,
              }}
              getOptionLabel={res => (res ? res.name : '')}
              onChange={(event, rescuer) => {
                input.onChange(rescuer.value);
                form.change('rescuer_id', rescuer.id);
              }}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              size="small"
              renderInput={params => (
                <TextFieldMaterial {...params} placeholder="Rescuer" variant="outlined" fullWidth />
              )}
              noOptionsText="There are no active rescuers in the Site"
            />
          )
        );
      }}
    />
  );
};

export default RescuerField;
