import queryString from 'query-string';
import { apiGET } from './api';

export const getLeaderboards = (dateRange, sitesIds = [], foodDonorsIds = []) => {
  const query = queryString.stringify(
    {
      date_range: dateRange ? `${dateRange.startDate}/${dateRange.endDate}` : null,
      sites_ids: sitesIds,
      food_donors_ids: foodDonorsIds,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/leaderboards?${query}`);
};
