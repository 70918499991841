export const convertMetersToMiles = meters => {
  if (meters === undefined || meters === null || isNaN(meters)) {
    return false;
  }
  
  return meters * 0.000621371192;
};

export const convertMilesToMeters = miles => {
  if (miles === undefined || miles === null || isNaN(miles)) {
    return false;
  }

  return miles * 1609.344;
};

export const convertSecondsToMilliseconds = seconds => {
  if (seconds === undefined || seconds === null || isNaN(seconds)) {
    return false;
  }
  
  return seconds * 1000;
};

export const getPercentage = (value1, value2, showOver100 = false) => {
  const percentage = (value1 / value2) * 100;
  const floatToInteger = Math.floor(percentage);
  if (!showOver100 && floatToInteger > 100) {
    return 100;
  }
  return floatToInteger;
};
