export const siteGoalsTypes = {
  RESCUES: {
    label: 'Closed Rescues',
    type: 'RESCUES',
  },
  LBS_RESCUED: {
    label: 'Lbs Rescued',
    type: 'LBS_RESCUED',
  },
  MEALS_RESCUED: {
    label: 'Meals Rescued',
    type: 'MEALS_RESCUED',
  },
};

export const getLabelFromType = (type) => {
  switch (type) {
    case siteGoalsTypes.RESCUES.type:
      return siteGoalsTypes.RESCUES.label;
    case siteGoalsTypes.LBS_RESCUED.type:
      return siteGoalsTypes.LBS_RESCUED.label;
    case siteGoalsTypes.MEALS_RESCUED.type:
      return siteGoalsTypes.MEALS_RESCUED.label;
    default:
      return '';
  }
};
