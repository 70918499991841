import { SET_FAB_ACTIONS, SET_FAB_ACTIONS_BY_KEY, UPDATE_FAB_STYLES } from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_FAB_ACTIONS:
      return {
        ...state,
        actions: action.payload.actions,
        isVisible: action.payload.isVisible,
      };
    case SET_FAB_ACTIONS_BY_KEY:
      return {
        ...state,
        actions: {
          ...state.actions,
          [action.payload.key]: action.payload.actions,
        },
        isVisible: action.payload.isVisible,
      };
    case UPDATE_FAB_STYLES:
      return {
        ...state,
        styles: action.payload.styles,
      };
    default:
      return state;
  }
};
