import { Roles } from './roles';

export const apiToStore = user => {
  return (
    ({
      role_assignments,
      phone,
      firstname,
      lastname,
      ...rest
    }) => ({
      role_assignments: transformRoles(role_assignments),
      phone: phone ? phone.replace(/^([0-9]{3})([0-9]{3})([0-9]{4})$/, '$1-$2-$3') : phone,
      firstname: firstname ? firstname.trim() : firstname,
      lastname: lastname ? lastname.trim() : lastname,
      ...rest
    })
  )(user);
};

export const transformRoles = roleAssignments => {
  let transformedRoles = [];

  roleAssignments.map(roleAssignment => transformedRoles.push({
    ...roleAssignment,
    role_name: transformRole(roleAssignment.role_name),
  }));

  return sortRoles(transformedRoles);
};

export const transformRole = role => {
  switch (role) {
    case 'admin':
      return Roles.Admin;
    case 'national_site_director':
      return Roles.NationalSiteDirector;
    case 'site_admin':
      return Roles.SiteDirector;
    case 'site_coordinator':
      return Roles.SiteCoordinator;
    case 'donor_admin':
      return Roles.DonorAdmin;
    case 'donor_staff':
      return Roles.DonorStaff;
    case 'donor_location_admin':
      return Roles.DonorLocationAdmin;
    case 'donor_location_staff':
      return Roles.DonorLocationStaff;
    case 'receiver_admin':
      return Roles.ReceiverAdmin;
    case 'receiver_staff':
      return Roles.ReceiverStaff;
    case 'receiver_location_admin':
      return Roles.ReceiverLocationAdmin;
    case 'receiver_location_staff':
      return Roles.ReceiverLocationStaff;
    case 'rescuer':
      return Roles.Rescuer;
    default:
      return role;
  }
};

export const transformRolesToApi = roleAssignments => {
  let transformedRoles = [];

  roleAssignments.map(roleAssignment => transformedRoles.push({
    ...roleAssignment,
    role_name: transformRoleToApi(roleAssignment.role_name),
  }));

  return sortRoles(transformedRoles);
};

export const transformRoleToApi = role => {
  switch (role) {
    case Roles.SiteDirector:
      return 'site_admin';
    case Roles.SiteCoordinator:
      return 'site_coordinator';
    case Roles.DonorAdmin:
      return 'donor_admin';
    case Roles.DonorStaff:
      return 'donor_staff';
    case Roles.DonorLocationAdmin:
      return 'donor_location_admin';
    case Roles.DonorLocationStaff:
      return 'donor_location_staff';
    case Roles.ReceiverAdmin:
      return 'receiver_admin';
    case Roles.ReceiverStaff:
      return 'receiver_staff';
    case Roles.ReceiverLocationAdmin:
      return 'receiver_location_admin';
    case Roles.ReceiverLocationStaff:
      return 'receiver_location_staff';
    case Roles.Rescuer:
      return 'rescuer';
    default:
      return role;
  }
};

export const rolesWeights = {
  Admin: 100,
  NationalSiteDirector: 95,
  SiteDirector: 90,
  SiteCoordinator: 85,
  DonorAdmin: 80,
  DonorStaff: 75,
  DonorLocationAdmin: 70,
  DonorLocationStaff: 65,
  ReceiverAdmin: 60,
  ReceiverStaff: 55,
  ReceiverLocationAdmin: 50,
  ReceiverLocationStaff: 45,
  Rescuer: 40,
};

export const sortRoles = roles => roles.sort((a, b) => rolesWeights[b.role_name] - rolesWeights[a.role_name]);

export const getRolesFromRoleAssignments = roleAssignments => {
  if (!roleAssignments) {
    return [];
  }

  const roles = roleAssignments.map(roleAssignment => roleAssignment.role_name);

  return [...new Set(roles)];
};
