import Bluebird from 'bluebird';
import { getInstagramData } from '../api/instagram';

export const REQUEST_INSTAGRAM_DATA = 'REQUEST_INSTAGRAM_DATA';
export const RECEIVE_INSTAGRAM_DATA = 'RECEIVE_INSTAGRAM_DATA';

export const requestInstagramData = () => ({
  type: REQUEST_INSTAGRAM_DATA,
});

export const receiveInstagramData = (data) => ({
  type: RECEIVE_INSTAGRAM_DATA,
  items: data,
  receivedAt: Date.now(),
});

function shouldFetchInstagramData(state) {
  const { instagramItems } = state.entities;
  if (instagramItems.allIds.length === 0 || !instagramItems.lastUpdated) {
    return true;
  }

  return false;}

export const fetchInstagramData = (quietMode = false) => dispatch => {
    if (!quietMode) {
      dispatch(requestInstagramData());
    }

    return Bluebird.try(() => getInstagramData())
      .then((res) => res.json())
      .then((json) => dispatch(receiveInstagramData(json.data)));
  };

export const fetchInstagramDataIfNeeded = (quietMode = false) => (dispatch, getState) => {
    if (shouldFetchInstagramData(getState())) {
      return dispatch(fetchInstagramData(quietMode));
    }

    return null;
  };
