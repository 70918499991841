import { store } from '../store/index';
import * as authService from '../services/auth';
import routes from '../routes';
import history from '../history'
import { tokenExpiredErrorName } from '../errors/TokenExpiredError'
import snackbarHelper from '../helpers/snackbarHelper';

const apiConfig = {
  host: process.env.REACT_APP_API_HOST,
};

const handleErrorResponse = async response => {
  if (!response.ok) {
    const json = await response.json();

    if (json.code === 'TokenExpiredError') {
      if (window.location.pathname !== routes.login) {
        snackbarHelper.info('Your session has expired. Please login again to use the application.', 5000, false);

        history.push(routes.login);
      }

      if (authService.getCurrentlyLoggedInUser()) {
        authService.logout();
      }

      return Promise.reject(tokenExpiredErrorName);
    }

    if (response.status === 403) {
      snackbarHelper.error(
        'You do not have the necessary permissions to perform this action.'
          + ' Please contact your administrator for assistance.',
        5000,
        false
      );
    }

    const error = new Error(json.message);

    error.code = json.code;
    error.statusCode = response.status;
    throw error;
  }

  return response;
};

export const apiGET = (path, accessTokenOverride) => {
  return fetch(
    `${apiConfig.host}${path}`,
    {
      headers: {
        'Authorization': `Bearer ${_getAccessToken(accessTokenOverride)}`,
        'Impersonated': _getImpersonatedUser(),
      }
    }
  ).then(handleErrorResponse);
};

export const apiPOST = (path, body, accessTokenOverride) => {
  return fetch(
    `${apiConfig.host}${path}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_getAccessToken(accessTokenOverride)}`,
        'Impersonated': _getImpersonatedUser(),
      },
    }
  ).then(handleErrorResponse);
};

export const apiPATCH = (path, body, accessTokenOverride) => {
  return fetch(
    `${apiConfig.host}${path}`,
    {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${_getAccessToken(accessTokenOverride)}`,
        'Impersonated': _getImpersonatedUser(),
      },
    }
  ).then(handleErrorResponse);
};

export const apiPUT = (path, body) => {
  return fetch(
    `${apiConfig.host}${path}`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.getState().app.accessToken}`,
        'Impersonated': _getImpersonatedUser(),
      },
    }
  ).then(handleErrorResponse);
};

export const apiDELETE = (path, body = {}) => {
  return fetch(
    `${apiConfig.host}${path}`,
    {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.getState().app.accessToken}`,
        'Impersonated': _getImpersonatedUser(),
      },
    }
  ).then(handleErrorResponse);
};

const _getAccessToken = accessTokenOverride => accessTokenOverride
  ? accessTokenOverride
  : store.getState().app.accessToken;

const _getImpersonatedUser = () => {
  const { app } = store.getState();

  if(app.impersonating) {
    return app.impersonating.id;
  }

  return null;
}
