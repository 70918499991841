import React from 'react';
import { FormControl, FormLabel, Grid, FormHelperText } from '@material-ui/core';
import DayOfMonthSelector from './DayOfMonthSelector';
import { daysList } from '../../../../../../helpers/RescuesHelper';

const DaysOfMonthSelector = ({ onChange, onBlur, dom = {}, error }) => {
  const handleDayChange = (newOrdinaryValue, newDayValue, oldOrdinaryValue, oldDayValue) =>
    onChange({
      newOrdinaryValue,
      newDayValue,
      oldOrdinaryValue,
      oldDayValue,
    });

  return (
    <FormControl component="fieldset" error={!!error}  data-testid="days-of-month-selector">
      <FormLabel component="legend">Day(s) of the Month</FormLabel>
      {daysList.map((d, dayIndex) => {
        const dayDataOrdinals = dom[d.toLowerCase()] || [];
        return dayDataOrdinals.map(dayOrdinal => (
          <Grid key={`${d}_${dayOrdinal.ordinal}`} data-testid={`day-${dayIndex}_ordinal-${dayOrdinal.ordinal}`}>
            <DayOfMonthSelector
              dom={dom}
              disabled={!dayOrdinal.allow_uncheck}
              onBlur={onBlur}
              ordinalValue={dayOrdinal.ordinal}
              dayValue={dayIndex}
              onDayChange={handleDayChange}
            />
          </Grid>
        ));
      })}
      <Grid key="new_value">
        <DayOfMonthSelector
          dom={dom}
          onBlur={onBlur}
          hideDeleteButton
          hideNoneValue={false}
          occurrenceValue=""
          dayName=""
          onDayChange={handleDayChange}
        />
      </Grid>
      {error && (
        <FormHelperText data-testid="days-of-month-error" id="days_of_month_error" error>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DaysOfMonthSelector;
