import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

export const ButtonsWrapper = withStyles(({ spacing }) => ({
  root: {
    '& > :not(:first-child)': {
      marginLeft: spacing(1),
    },
  },
}))(({ children, classes, ...rest }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    className={`r-btn-wrapper ${classes.root}`}
    {...rest}
  >
    {children}
  </Box>
));
