import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { Box, Typography, List, ListItem, makeStyles } from '@material-ui/core';
import EventMobileCard from '../../../Events/EventMobileCard';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import routes from '../../../../routes';
import { sortByDateAndTime } from '../../../../helpers/sorters';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    ...typography['h5'],
    padding: spacing(1),
  },
  listSubItem: {
    display: 'block',
    padding: spacing(1),
  },
  ul: {
    marginBottom: spacing(2),
    backgroundColor: 'inherit',
    padding: 0,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const EventsMobile = ({history}) => {
  const classes = useStyles();
  const events = useSelector(state => state.entities.events);
  const { fetchEventsTab } = useRescuerDashboardActions();
  const isSCDashboard = useRouteMatch(routes.dashboardSC);
  const isRDashboard = useRouteMatch(routes.dashboardRescuer);
  const isNewDashboard = useRouteMatch(routes.mobileDashboardEvents);
  const isLoading = events.inflight;
  const eventsList = Object.values(events.byId);

  const sortEventsByDate = (a, b) => sortByDateAndTime('desc', a.date.value, b.date.value);
  const eventsByDate = eventsList.sort(sortEventsByDate);

  useEffect(() => {
    fetchEventsTab(false, false);
  }, [fetchEventsTab]);

  const handleSignupClick = (event) => {
    if (isSCDashboard) {
      return history.push(
        generatePath(routes.dashboardSCEvents, {
          eventId: event.id,
        })
      );
    }
    if (isRDashboard) {
      return history.push(
        generatePath(routes.dashboardRescuerEvents, {
          eventId: event.id,
        })
      );
    }

    if (isNewDashboard) {
      return history.push(
        generatePath(routes.mobileDashboardEventSignup, {
          eventId: event.id,
        })
      );
    }

    return null;
  };

  if (!isLoading && eventsByDate.length === 0) {
    return (
      <Box className={classes.container}>
        <Box className={classes.noData}>
          <Typography color="textSecondary" variant="h5">
            No available events
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <List className={classes.container} subheader={<li />}>
      <li className={classes.listSection}>
        <ul className={classes.ul}>
          {eventsByDate.map((event) => (
            <ListItem key={`event-${event.id}`} className={classes.listSubItem}>
              <EventMobileCard eventData={event} onSignupClick={() => handleSignupClick(event)} />
            </ListItem>
          ))}
        </ul>
      </li>
    </List>
  );
};

export default EventsMobile;
