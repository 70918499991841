import React, { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routes from '../../routes';
import ContactListMobileView from './ContactListMobile/ContactListMobileView';
import useActiveUser from '../../hooks/useActiveUser';
import useActiveSite from '../../hooks/useActiveSite';
import {
  fetchSiteDonorsLocations,
  fetchSiteReceivers,
  fetchSiteRescuers,
} from '../../actions/sites';
import { hasAnyRoleInSite } from '../../services/auth';
import { Roles } from '../../models/roles';
import AccessDenied from '../../components/AccessDenied';

export const paths = [
  generatePath(routes.contactList, { tab: 'receivers' }),
  generatePath(routes.contactList, { tab: 'rescuers' }),
  generatePath(routes.contactList, { tab: 'donors' }),
];

const ContactListView = () => {
  const dispatch = useDispatch();
  const user = useActiveUser();
  const site = useActiveSite();

  useEffect(() => {
    dispatch(fetchSiteDonorsLocations(site.id));
    dispatch(fetchSiteReceivers(site.id));
    dispatch(fetchSiteRescuers(site.id));
  }, [dispatch]);

  if (!hasAnyRoleInSite(user, site.id, [Roles.SiteCoordinator, Roles.SiteDirector])) {
    return <AccessDenied />;
  }

  return <ContactListMobileView site={site} paths={paths} />;
};

export default ContactListView;
