import { get } from 'lodash';
import {
  FREQUENCY_EVERY_OTHER_WEEK,
  FREQUENCY_MONTHLY,
  FREQUENCY_ONCE,
  FREQUENCY_WEEKLY,
} from '../models/donationsNew';
import {formatDate, formatTime} from './formatters';

export const daysOfWeekValues = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export const getAllPickupsTheSamePickupSpecBaseDay = (pickup, frequency) => {
  switch (frequency) {
    case FREQUENCY_ONCE:
      return {};
    case FREQUENCY_WEEKLY:
    case FREQUENCY_EVERY_OTHER_WEEK:
      return {
        day: pickup.day,
      };
    case FREQUENCY_MONTHLY:
      return {
        day: pickup.day,
        ordinal: pickup.ordinal,
      };
    default:
      return {};
  }
};

export const getPickupSpecBaseDay = (pickup, frequency) => {
  switch (frequency) {
    case FREQUENCY_ONCE:
      return {
        position: pickup.position,
      };
    case FREQUENCY_WEEKLY:
    case FREQUENCY_EVERY_OTHER_WEEK:
      return {
        day: pickup.day,
        position: pickup.position,
      };
    case FREQUENCY_MONTHLY:
      return {
        day: pickup.day,
        position: pickup.position,
        ordinal: pickup.ordinal,
      };
    default:
      return {};
  }
};

export const isEveryOtherWeekFrequency = frequency => frequency === FREQUENCY_EVERY_OTHER_WEEK;

const getDonationFrequencyAsText = donation => {
  switch (donation.frequency) {
    case 0:
      return 'One time only';
    case 1:
      return donation.frequency_every === 1 ? 'Weekly' : 'Every other Week';
    case 2:
      return 'Monthly';
    default:
      return 'unknown';
  }
};

export const groupMonthlyPickups = pickups =>
  Object.entries(
    pickups.reduce((k, v) => ({ ...k, [`${v.day},${v.ordinal}`]: [...(k[`${v.day},${v.ordinal}`] || []), v] }), {})
  ).sort((a, b) => {
    const { day: aDay, ordinal: aOrdinal } = a;
    const { day: bDay, ordinal: bOrdinal } = b;

    // change eg '-1,1' to be on the last position so `last mon` will be after `4th mon`
    const testA = aOrdinal !== -1 ? a[0] : `${aDay},9999`;
    const testB = bOrdinal !== -1 ? b[0] : `${bDay},9999`;

    return testA < testB ? -1 : 1;
  });

export const groupWeeklyPickups = pickups =>
  Object.entries(pickups.reduce((acc, curr) => ({ ...acc, [curr.day]: [...(acc[curr.day] || []), curr] }), {}));

export const donationManageActions = {
  set_all_days_are_the_same: 'set_all_days_are_the_same',
  clear_all_days_are_the_same: 'clear_all_days_are_the_same',
  set_all_pickups_are_the_same: 'set_all_pickups_are_the_same',
  clear_all_pickups_are_the_same: 'clear_all_pickups_are_the_same',
  set_description: 'set_description',
  set_lbs_value: 'set_lbs_value',
  set_food_size: 'set_food_size',
  set_rescue_size: 'set_rescue_size',
  add_food_type: 'add_food_type',
  remove_food_type: 'remove_food_type',
  add_food_sub_category: 'add_food_sub_category',
  remove_food_sub_category: 'remove_food_sub_category',
  set_food_donor: 'set_food_donor',
  set_pickup_location: 'set_pickup_location',
  set_food_type_other: 'set_food_type_other',
  set_frequency: 'set_frequency',
  add_day_of_week: 'add_day_of_week',
  remove_day_of_week: 'remove_day_of_week',
  add_day_of_month: 'add_day_of_month',
  remove_day_of_month: 'remove_day_of_month',
  set_day_of_month_day: 'set_day_of_month_day',
  set_day_of_month_ordinal: 'set_day_of_month_ordinal',
  set_start_date: 'set_start_date',
  set_end_date: 'set_end_date',
  set_pause_date: 'set_pause_date',
  set_resume_date: 'set_resume_date',

  add_pickup: 'add_pickup',
  remove_pickup: 'remove_pickup',
  set_pickup_receiver: 'set_pickup_receiver',
  set_pickup_adopter: 'set_pickup_adopter',
  set_pickup_rescuer: 'set_pickup_rescuer',
  set_pickup_rescuer_notes: 'set_pickup_rescuer_notes',
  set_pickup_time: 'set_pickup_time',
  set_pickup_begin: 'set_pickup_begin',
  set_pickup_end: 'set_pickup_end',
  set_rescue_pickup_begin: 'set_rescue_pickup_begin',
  set_rescue_pickup_end: 'set_rescue_pickup_end',
  set_rescue_rescuer: 'set_rescue_rescuer',
  set_rescue_rescuer_notes: 'set_rescue_rescuer_notes',
  set_rescue_canceller: 'set_rescue_canceller',

  set_donor_notes: 'set_donor_notes',
  set_national_donation: 'set_national_donation',
};

export const compareDonations = (donation, compareDonation, skipFields = ['id']) => {
  const diff = [];
  const comparePrimitive = (a, b) => a === b;
  const compareDate = (a, b) => get(a, 'value') === get(b, 'value');
  const compareArray = (a, b) => {
    if (!Array.isArray(a) || !Array.isArray(b)) {
      return false;
    }

    if (a.length === b.length) {
      return true;
    }

    a.every(value => b.includes(value)) && b.every(value => a.includes(value));
  };

  // frequency section
  if (!comparePrimitive(donation.description, compareDonation.description)) {
    diff.push({
      display: true,
      fieldName: 'Description',
      slug: 'description',
      oldValue: donation.description,
      oldValueFormatted: donation.description,
      newValue: donation.description,
      newValueFormatted: compareDonation.description,
    });
  }

  if (!comparePrimitive(get(donation, 'rescue_size.id'), get(compareDonation, 'rescue_size.id'))) {
    diff.push({
      display: true,
      fieldName: 'Rescue Vehicle Size',
      slug: 'rescue_size',
      oldValue: get(donation, 'rescue_size.id'),
      oldValueFormatted: get(donation, 'rescue_size.name'),
      newValue: get(compareDonation, 'rescue_size.id'),
      newValueFormatted: get(compareDonation, 'rescue_size.name'),
    });
  }

  if (!compareArray(donation.food_types, compareDonation.food_types)) {
    diff.push({
      display: true,
      fieldName: 'Food Types',
      slug: 'food_types',
      oldValue: get(donation, 'food_types', []),
      oldValueFormatted: get(donation, 'food_types', []).join(', '),
      newValue: get(compareDonation, 'food_types', []),
      newValueFormatted: get(compareDonation, 'food_types', []).join(', '),
    });
  }

  if (!comparePrimitive(donation.food_type_other, compareDonation.food_type_other)) {
    diff.push({
      display: true,
      fieldName: 'Food Type Other',
      slug: 'food_type_other',
      oldValue: get(donation, 'food_type_other'),
      oldValueFormatted: get(donation, 'food_type_other'),
      newValue: get(compareDonation, 'food_type_other'),
      newValueFormatted: get(compareDonation, 'food_type_other'),
    });
  }

  if (!comparePrimitive(donation.food_donor_notes, compareDonation.food_donor_notes)) {
    diff.push({
      display: true,
      fieldName: 'Notes for your Site Director',
      slug: 'food_donor_notes',
      oldValue: get(donation, 'food_donor_notes'),
      oldValueFormatted: get(donation, 'food_donor_notes'),
      newValue: get(compareDonation, 'food_donor_notes'),
      newValueFormatted: get(compareDonation, 'food_donor_notes'),
    });
  }

  // schedule section
  if (
    !comparePrimitive(donation.frequency, compareDonation.frequency) ||
    !comparePrimitive(donation.frequency_every, compareDonation.frequency_every)
  ) {
    if (!comparePrimitive(donation.frequency, compareDonation.frequency)) {
      diff.push({
        display: true,
        field: 'Frequency',
        slug: 'frequency',
        oldValue: get(donation, 'food_donor_notes'),
        oldValueFormatted: getDonationFrequencyAsText(donation),
        newValue: get(compareDonation, 'food_donor_notes'),
        newValueFormatted: getDonationFrequencyAsText(compareDonation),
      });
    }

    if (!comparePrimitive(donation.frequency_every, compareDonation.frequency_every)) {
      diff.push({
        display: false,
        field: 'Frequency Every',
        slug: 'frequency_every',
        oldValue: get(donation, 'frequency_every'),
        newValue: get(compareDonation, 'frequency_every'),
      });
    }
  }

  if (!compareDate(donation.start_date, compareDonation.start_date)) {
    diff.push({
      display: true,
      fieldName: 'Start Date',
      slug: 'start_date',
      oldValue: get(donation, 'start_date.value'),
      oldValueFormatted: formatDate(get(donation, 'start_date.value')),
      newValue: get(compareDonation, 'start_date.value'),
      newValueFormatted: formatDate(get(compareDonation, 'start_date.value')),
    });
  }

  if (!compareDate(donation.end_date, compareDonation.end_date)) {
    diff.push({
      display: true,
      fieldName: 'End Date',
      slug: 'end_date',
      oldValue: get(donation, 'end_date.value'),
      oldValueFormatted: formatDate(get(donation, 'end_date.value')),
      newValue: get(compareDonation, 'end_date.value'),
      newValueFormatted: formatDate(get(compareDonation, 'end_date.value')),
    });
  }

  if (!compareDate(donation.pause_date, compareDonation.pause_date)) {
    diff.push({
      display: true,
      fieldName: 'Pause Date',
      slug: 'pause_date',
      oldValue: get(donation, 'pause_date.value'),
      oldValueFormatted: formatDate(get(donation, 'pause_date.value')),
      newValue: get(compareDonation, 'pause_date.value'),
      newValueFormatted: formatDate(get(compareDonation, 'pause_date.value')),
    });
  }

  if (!compareDate(donation.resume_date, compareDonation.resume_date)) {
    diff.push({
      display: true,
      fieldName: 'Resume Date',
      slug: 'resume_date',
      oldValue: get(donation, 'resume_date.value'),
      oldValueFormatted: formatDate(get(donation, 'resume_date.value')),
      newValue: get(compareDonation, 'resume_date.value'),
      newValueFormatted: formatDate(get(compareDonation, 'resume_date.value')),
    });
  }

  if (
    !compareDate(get(donation, 'pickup_specs[0].pickup_begin'), get(compareDonation, 'pickup_specs[0].pickup_begin'))
  ) {
    diff.push({
      display: true,
      fieldName: 'Pickup Begin Time',
      slug: 'pickup_begin',
      oldValue: get(donation, 'pickup_specs[0].pickup_begin.value'),
      oldValueFormatted: formatTime(get(donation, 'pickup_specs[0].pickup_begin.value')),
      newValue: get(compareDonation, 'pickup_specs[0].pickup_begin.value'),
      newValueFormatted: formatTime(get(compareDonation, 'pickup_specs[0].pickup_begin.value')),
    });
  }

  if (!compareDate(get(donation, 'pickup_specs[0].pickup_end'), get(compareDonation, 'pickup_specs[0].pickup_end'))) {
    diff.push({
      display: true,
      fieldName: 'Pickup End Time',
      slug: 'pickup_end',
      oldValue: get(donation, 'pickup_specs[0].pickup_end.value'),
      oldValueFormatted: formatTime(get(donation, 'pickup_specs[0].pickup_end.value')),
      newValue: get(compareDonation, 'pickup_specs[0].pickup_end.value'),
      newValueFormatted: formatTime(get(compareDonation, 'pickup_specs[0].pickup_end.value')),
    });
  }

  if (
    !comparePrimitive(
      get(donation, 'pickup_specs[0].rescuer_notes'),
      get(compareDonation, 'pickup_specs[0].rescuer_notes')
    )
  ) {
    diff.push({
      display: true,
      fieldName: 'Pickup Rescuer Notes',
      slug: 'rescuer_notes',
      oldValue: get(donation, 'pickup_specs[0].rescuer_notes'),
      oldValueFormatted: get(donation, 'pickup_specs[0].rescuer_notes'),
      newValue: get(compareDonation, 'pickup_specs[0].rescuer_notes'),
      newValueFormatted: get(compareDonation, 'pickup_specs[0].rescuer_notes'),
    });
  }

  return diff;
};

export const donationRequestsDisplayFrequency = (donationRequest) => {
  if (donationRequest.frequency === 0) {
    return 'One time only';
  }

  if (donationRequest.frequency === 2) {
    return 'Monthly';
  }

  if (donationRequest.frequency === 1 && donationRequest.frequency_every === 1) {
    return 'Weekly';
  }

  if (donationRequest.frequency === 1 && donationRequest.frequency_every === 2) {
    return 'Every other week';
  }

  return 'N/A';
};
