export const mapFormToCreateFoodDonorPOST = foodDonor => {
  const foodDonorData = { ...foodDonor };

  return {
    name: foodDonorData.name,
    title: foodDonorData.title || null,
    type_id: foodDonorData.type_id,
    type_other: foodDonorData.type_other || null,
    site_id: foodDonorData.site_id,
    donor_id: foodDonorData.donor_id,
    phone: foodDonorData.phone || null,
    phone_ext: foodDonorData.phone_ext || null,
    phone_type: foodDonorData.phone_type || null,
    ein: foodDonorData.ein || null,
    url: foodDonorData.url || null,
    notes: foodDonorData.notes || null,
    pickup_notes: foodDonorData.pickup_notes || null,
    allow_pickups_from_business_address: foodDonorData.allow_pickups_from_business_address,
    makes_donations: foodDonorData.makes_donations,
    business_address: {
      address: foodDonorData.business_address,
      city: foodDonorData.business_address_city,
      state: foodDonorData.business_address_state,
      zip_code: foodDonorData.business_address_zip_code,
      user_id: foodDonorData.business_address_primary_contact_id || null,
      user: {
        firstname: foodDonorData.business_address_primary_contact_firstname || null,
        lastname: foodDonorData.business_address_primary_contact_lastname || null,
        email: foodDonorData.business_address_primary_contact_email || null,
        phone: foodDonorData.business_address_primary_contact_phone || null,
        phone_ext: foodDonorData.business_address_primary_contact_phone_ext || null,
        phone_type: foodDonorData.business_address_primary_contact_phone_type || null,
        send_invitation: foodDonorData.send_invitation_email || null,
      },
    },
    pickup_address: {
      address: foodDonorData.pickup_address,
      city: foodDonorData.pickup_address_city,
      state: foodDonorData.pickup_address_state,
      zip_code: foodDonorData.pickup_address_zip_code,
      user_id: foodDonorData.pickup_address_primary_contact_id || null,
      user: {
        firstname: foodDonorData.pickup_address_primary_contact_firstname || null,
        lastname: foodDonorData.pickup_address_primary_contact_lastname || null,
        email: foodDonorData.pickup_address_primary_contact_email || null,
        phone: foodDonorData.pickup_address_primary_contact_phone || null,
        phone_ext: foodDonorData.pickup_address_primary_contact_phone_ext || null,
        phone_type: foodDonorData.pickup_address_primary_contact_phone_type || null,
        send_invitation: foodDonorData.send_invitation_email || null,
      },
    },
  };
};

export const mapFormToUpdateFoodDonorPATCH = data => {
  // todo: set proper object values
  return data;
};

export const mapFormToCreateFoodDonorStuffPost = data => {
  // todo: set proper object values
  return data;
};

export const mapFormToCreateFoodDonorPickupLocationPOST = foodDonorPickupLocation => {
  const foodDonorPickupLocationData = { ...foodDonorPickupLocation };

  return {
    name: foodDonorPickupLocationData.name,
    address: foodDonorPickupLocationData.address,
    city: foodDonorPickupLocationData.city,
    state: foodDonorPickupLocationData.state,
    zip_code: foodDonorPickupLocationData.zip_code,
    notes: foodDonorPickupLocationData.notes,
    active: foodDonorPickupLocationData.active,
    user_id: foodDonorPickupLocationData.primary_contact_id || null,
    user: {
      firstname: foodDonorPickupLocationData.primary_contact_firstname || null,
      lastname: foodDonorPickupLocationData.primary_contact_lastname || null,
      email: foodDonorPickupLocationData.primary_contact_email || null,
      phone: foodDonorPickupLocationData.primary_contact_phone || null,
      phone_ext: foodDonorPickupLocationData.primary_contact_phone_ext || null,
      phone_type: foodDonorPickupLocationData.primary_contact_phone_type || null,
      send_invitation: foodDonorPickupLocationData.send_invitation_email || null,
    },
  };
};

export const mapFormToUpdateFoodDonorPickupLocationPUT = foodDonorPickupLocation => {
  const foodDonorPickupLocationData = { ...foodDonorPickupLocation };

  return {
    name: foodDonorPickupLocationData.name,
    address: foodDonorPickupLocationData.address,
    city: foodDonorPickupLocationData.city,
    state: foodDonorPickupLocationData.state,
    zip_code: foodDonorPickupLocationData.zip_code,
    notes: foodDonorPickupLocationData.notes,
    active: foodDonorPickupLocationData.active,
    user_id: foodDonorPickupLocationData.primary_contact_id || null,
    user: {
      firstname: foodDonorPickupLocationData.primary_contact_firstname || null,
      lastname: foodDonorPickupLocationData.primary_contact_lastname || null,
      email: foodDonorPickupLocationData.primary_contact_email || null,
      phone: foodDonorPickupLocationData.primary_contact_phone || null,
      phone_ext: foodDonorPickupLocationData.primary_contact_phone_ext || null,
      phone_type: foodDonorPickupLocationData.primary_contact_phone_type || null,
      send_invitation: foodDonorPickupLocationData.send_invitation_email || null,
    },
  };
};

export const mapUnassignedFoodDonorToStore = foodDonor => {
  const foodDonorData = { ...foodDonor };

  return {
    id: foodDonorData.id,
    name: foodDonorData.name,
    title: foodDonorData.title || null,
    email: foodDonorData.email,
    phone: foodDonorData.phone
      ? foodDonorData.phone.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})$/, '$1-$2-$3')
      : foodDonorData.phone,
    store_id: foodDonorData.store_id,
    city: foodDonorData.city,
    state: foodDonorData.st,
    zip_code: foodDonorData.zip,
  };
};
