import React from 'react';
import useActiveUser from './useActiveUser';
import { Roles } from '../models/roles';
import { hasAnyRoleInCurrentlySelectedSite } from '../services/auth';

const useIsAdminInSelectedSite = () => {
  return hasAnyRoleInCurrentlySelectedSite(useActiveUser(), [Roles.Admin, Roles.NationalSiteDirector]);
};

export default useIsAdminInSelectedSite;
