import Bluebird from 'bluebird';
import { getFoodDescriptions } from '../api/foodDescriptions';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_FOOD_DESCRIPTIONS = 'REQUEST_FOOD_DESCRIPTIONS';
export const RECEIVE_FOOD_DESCRIPTIONS = 'RECEIVE_FOOD_DESCRIPTIONS';

export const requestFoodDescriptions = () => ({
  type: REQUEST_FOOD_DESCRIPTIONS,
});

export const receiveFoodDescriptions = fd => ({
  type: RECEIVE_FOOD_DESCRIPTIONS,
  foodDescriptions: fd,
  receivedAt: Date.now(),
});

export const fetchFoodDescriptions = () => dispatch => {
  dispatch(requestFoodDescriptions());

  return Bluebird
    .try(() => getFoodDescriptions())
    .then(res => res.json())
    .then(json => dispatch(receiveFoodDescriptions(json.data)));
};

const shouldFetchFoodDescriptions = state => {
  const { foodDescriptions } = state.entities;

  if (foodDescriptions.allIds.length === 0) {
    return true;
  }

  if (foodDescriptions.inflight) {
    return false;
  }

  return shouldFetch(foodDescriptions.lastUpdated, { hours: 6 });
};

export const fetchFoodDescriptionsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchFoodDescriptions(getState())) {
    return dispatch(fetchFoodDescriptions());
  }

  return null;
};
