import Bluebird from 'bluebird';
import { getRescuerLocationTypes } from '../api/rescuerLocations';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_RESCUER_LOCATION_TYPES = 'REQUEST_RESCUER_LOCATION_TYPES';
export const RECEIVE_RESCUER_LOCATION_TYPES = 'RECEIVE_RESCUER_LOCATION_TYPES';

export const requestRescuerLocationTypes = () => ({
  type: REQUEST_RESCUER_LOCATION_TYPES,
});

export const receiveRescuerLocationTypes = rescuerLocationTypes => ({
  type: RECEIVE_RESCUER_LOCATION_TYPES,
  rescuerLocationTypes: rescuerLocationTypes,
  receivedAt: Date.now(),
});

export const fetchRescuerLocationTypes = () => dispatch => {
  dispatch(requestRescuerLocationTypes());

  return Bluebird
    .try(() => getRescuerLocationTypes())
    .then(res => res.json())
    .then(json => dispatch(receiveRescuerLocationTypes(json.data)));
};

const shouldFetchRescuerLocationTypes = (state) => {
  const rescuerLocationTypes = state.entities.rescuerLocations.locationTypes;

  if (rescuerLocationTypes.allIds.length === 0) {
    return true;
  }

  if (rescuerLocationTypes.inflight) {
    return false;
  }

  return shouldFetch(rescuerLocationTypes.lastUpdated, { hours: 6 });
};

export const fetchRescuerLocationTypesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchRescuerLocationTypes(getState())) {
    return dispatch(fetchRescuerLocationTypes());
  }

  return null;
};
