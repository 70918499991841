import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, generatePath } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { compose } from 'redux';
import UserForm from '../components/UserForm';
import * as authService from '../services/auth';
import * as receiversActions from '../actions/receivers';
import { Roles } from '../models/roles';
import routes from '../routes';

class ReceiverStaffAddView extends Component {
  componentDidMount() {
    const { fetchReceivers } = this.props;

    fetchReceivers();
  }

  submitCallback = data => {
    const { receiver, receiverLocation, addReceiverStaff } = this.props;

    return addReceiverStaff(receiver.id, receiverLocation.id, data);
  };

  render() {
    const { receivers, classes, receiver } = this.props;

    if (receivers.inflight || receiver === undefined) {
      return 'Loading Receiving Agency...';
    }

    const receiverPath = generatePath(routes.receiver, { id: receiver.id });

    return (
      <>
        <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
          <Link color="inherit" to={routes.receivers}>
            Receiving Agencies
          </Link>

          <Link color="inherit" to={receiverPath}>
            {receiver.name}
          </Link>

          <Typography color="textPrimary">
            Add a Receiving Agency Staff
          </Typography>
        </Breadcrumbs>

        <UserForm
          showUserSelector
          redirectPath={receiverPath}
          submitCallback={this.submitCallback}
          showIsAdminCheckbox={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.Admin,
            Roles.NationalSiteDirector,
            Roles.SiteDirector,
            Roles.ReceiverAdmin,
            Roles.ReceiverLocationAdmin,
          ])}
          isAdminCheckboxLabel="Is Receiving Agency Admin"
          showLocationFields={false}
        />
      </>
    );
  }
}

const mapStateToProps = ({ entities: { receivers } }, { match }) => {
  const locations = receivers.locations.byReceiverId[match.params.receiverId] || [];

  return {
    receivers,
    receiver: receivers.byId[match.params.receiverId],
    receiverLocation: locations[0],
  };
};
const mapDispatchToProps = dispatch => ({
  fetchReceivers: () => dispatch(receiversActions.fetchReceiversIfNeeded()),
  addReceiverStaff: (receiverId, locationId, data) => dispatch(
    receiversActions.createReceiverStaff(receiverId, locationId, data)
  ),
});
const styles = theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
)(ReceiverStaffAddView);
