import { objectFromArray } from '../helpers';
import * as settingsActions from '../actions/settings';

export const initialSitesState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const featureFlagsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case settingsActions.REQUEST_SETTINGS:
      return {
        ...state,
        inflight: true,
      };
    case settingsActions.RECEIVE_SETTINGS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.settings, setting => setting.id),
        allIds: action.settings.map(setting => setting.id),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default featureFlagsReducer;
