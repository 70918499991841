import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, Tab, withStyles } from '@material-ui/core';

const StyledTabs = withStyles(({ palette }) => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    borderTop: `1px solid ${palette.divider}`,
    backgroundColor: '#fafafa',
    zIndex: 2,
  },
}))(Tabs);

export const TabLink = ({ label, to, value, ...rest }) => (
  <Tab label={label} component={Link} value={value} to={to} {...rest} />
);

export const BottomNavTabs = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <StyledTabs value={pathname} variant="fullWidth" indicatorColor="primary" textColor="primary" id="tabs">
      {children}
    </StyledTabs>
  );
};
