import { apiDELETE, apiPOST } from './api';

export const addReceiverStaff = (receiverId, locationId, data) => apiPOST(
  `/receivers/${receiverId}/locations/${locationId}/staff`,
  data
);

export const deleteReceiverStaff = (receiverId, locationId, userId, data) => apiDELETE(
  `/receivers/${receiverId}/locations/${locationId}/staff/${userId}`,
  data
);
