import React, {useEffect, useState} from 'react';
import Bluebird from 'bluebird';
import { get, debounce } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import * as usersApi from '../api/users';
import { getPrimaryContactSelectOption } from '../helpers/getters';
import { TextFieldWrapper } from './Form/MuiFormWrapper';

const PrimaryContactUserSelect = ({
  name, label = 'Primary Contact Email', className, onChangeUser, formValue, error, required = false
}) => {
  const [userList, setUserList] = useState([]);
  const [selectedPrimaryContactUser, setSelectedPrimaryContactUser] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const filter = createFilterOptions({
    stringify: option => option.label,
  });

  useEffect(() => {
    if (formValue) {
      setSelectedPrimaryContactUser({ label: formValue, value: formValue });
    }
  }, []);

  const fetchUsers = value => {
    setFetchingUsers(true);
    setUserList([]);

    if (value.length <= 2) {
      return new Promise(resolve => {
        resolve([]);

        setFetchingUsers(false);
        setUserList([]);
      });
    }

    return Bluebird
      .try(() => usersApi.getUsers({ narrow: true, search: value }))
      .then(res => res.json())
      .then(res => res.data)
      .then(data => {
        setFetchingUsers(false);
        setUserList(data.map(user => getPrimaryContactSelectOption(user)));
      });
  };

  const debounceFetch = debounce(fetchUsers, 300);

  const handleChangeUser = option => {
    setSelectedPrimaryContactUser(option ? option : false);

    return onChangeUser(option);
  };

  return (
    <Autocomplete
      freeSolo
      autoSelect
      autoHighlight
      value={selectedPrimaryContactUser}
      options={userList}
      onChange={(event, newValue) => {
        let option = newValue;

        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return;
        }

        // Create a new value from the user input
        if (newValue && newValue.inputValue) {
          option = {
            label: newValue.inputValue,
          };
        }

        return handleChangeUser(option, event);
      }}
      loading={fetchingUsers}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        // Suggestion visibility is hidden in the styles. This must stay to allow user use new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            label: `Use "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={option => {
        if (!option) {
          return '';
        }

        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }

        return get(option, 'meta.email', option.label);
      }}
      renderOption={option => option.label}
      renderInput={params => (
        <TextFieldWrapper
          {...params}
          id={name ? name : 'primary_contact_email'}
          name={name ? name : 'primary_contact_email'}
          className={className || undefined}
          variant="outlined"
          size="small"
          required={required}
          label={label}
          error={!!error}
          helperText={error ? error.message : ''}
          onKeyDown={event => {
            // this fixes dropdown user select closing when space is pressed
            if (event.keyCode === 32) {
              event.preventDefault();
            }
          }}
          onChange={event => debounceFetch(event.target.value)}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {fetchingUsers ? <CircularProgress color="inherit" size={20} /> : null}

                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          InputLabelProps={{ shrink: !!formValue || undefined }}
        />
      )}
    />
  );
};

export default PrimaryContactUserSelect;
