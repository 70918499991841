import React from 'react';
import { useSelector } from 'react-redux';
import ContactList from '../../Components/ContactList';
import { getUserFullName } from '../../../../helpers/user';
import ContactListDetails from '../../Components/ContactListDetails';

const ReceiversContactList = ({ siteId }) => {
  const receivers = useSelector(state => state.entities.sites.receivers);
  const contactList = (receivers.bySiteId[siteId] || []).reduce((acc, curr) => {
    if (curr.primary_contact_id) {
      const findDuplicate = acc.findIndex(contact => contact.id === `dl-${curr.primary_contact_id}`);
      if (findDuplicate > -1) {
        acc[findDuplicate] = {
          ...acc[findDuplicate],
          meta: { ...acc[findDuplicate].meta, name: `${acc[findDuplicate].meta.name}, ${curr.name}` },
        };
      } else {
        acc.push({
          id: `dl-${curr.primary_contact_id}`,
          phone: curr.primary_contact_phone,
          email: curr.primary_contact_email,
          firstname: curr.primary_contact_firstname,
          lastname: curr.primary_contact_lastname,
          meta: curr,
        });
      }
    }

    return acc;
  }, []);

  return (
    <ContactList
      id="receivers"
      isLoading={!receivers.lastUpdated || receivers.inflight}
      list={contactList}
      subHeaderGetter={row => (row.firstname || '#').substr(0, 1).replace(/\d|\W/i, '#')}
      sortBy={row => getUserFullName(row)}
      labelGetter={row => getUserFullName(row)}
      subLabelGetter={row => row.meta.name}
      expandedContent={ContactListDetails}
      searchBy={({ row, query }) =>
        (row.firstname || '').toUpperCase().includes(query) ||
        (row.lastname || '').toUpperCase().includes(query) ||
        (row.phone || '').includes(query) ||
        (row.email || '').toUpperCase().includes(query) ||
        (row.meta.name || '').toUpperCase().includes(query)
      }
    />
  );
};

export default ReceiversContactList;
