import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { Check, Phone } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import PhoneConfirmationCodeDialog from './PhoneConfirmationCodeDialog';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import { updateUser as updateUserAction } from '../actions/users';
import { sendSmsVerifyRequest } from '../api/users';
import { getPhoneNumberFromMask } from '../helpers/getters';
import { isPhoneMaskValid } from '../helpers/validators';
import OverlayLoader from './OverlayLoader';
import errorMessages from '../assets/errorMessages';
import { formatPhoneNumber } from '../helpers/formatters';

class PhoneVerifyDialog extends Component {
  constructor(props) {
    super(props);
    const { user } = props;

    this.state = {
      loading: false,
      phoneNumber: user.phone || '',
      phoneNumberTouched: false,
      phoneNumberError: null,
      phoneNumberVerified: false,
      verificationDialogLoading: false,
      confirmationCodeError: false,
      showPhoneNumberVerificationDialog: false,
    };
  }

  handleConfirmationModalClose = () => {
    this.setState({
      showPhoneNumberVerificationDialog: false,
    });
  };

  handleCodeNotReceived = () => {
    const { user } = this.props;

    this.setState({ verificationDialogLoading: true }, () => Bluebird
      .try(() => sendSmsVerifyRequest(user.id))
      .then(() => this.setState({
        confirmationCodeError: false,
        verificationDialogLoading: false,
      }))
    );
  };

  handleVerify = async confirmationCode => {
    const { handleClose, updateUser, user } = this.props;
    const loggedInUser = user;

    this.setState({
      verificationDialogLoading: true,
    });

    try {
      const user = await updateUser(loggedInUser.id, { phone_verification_code: confirmationCode });
      // the only way to check if provided confirmation code was valid or not...
      if (user.phone_verified === null) {
        throw Error('Provided code is incorrect');
      }

      snackbarHelper.success('Your "phone number" setting has been verified and saved successfully!');

      this.setState({
        phoneNumberError: null,
        phoneNumberVerified: true,
        verificationDialogLoading: false,
        confirmationCodeError: false,
        showPhoneNumberVerificationDialog: false,
      });
      handleClose();
    } catch (err) {
      errorsHelper.handleError(err);

      this.setState({
        verificationDialogLoading: false,
        confirmationCodeError: true,
      });
    }
  };

  onNumberChange = e => {
    const { phoneNumberError } = this.state;

    this.setState({
      phoneNumber: e.target.value,
      phoneNumberTouched: true,
    });

    if (phoneNumberError && !e.target.value.length) {
      this.setState({
        phoneNumberError: null,
      });
    }
  };

  validatePhoneNumberStep = () => {
    const { phoneNumberVerified } = this.state;
    const { updateUser, user } = this.props;
    const phoneNumber = this.getPhoneNumber();

    if (phoneNumber.length && !phoneNumberVerified) {
      if (!isPhoneMaskValid(phoneNumber)) {
        this.setState({
          loading: false,
          phoneNumberError: 'Please provide a valid phone number.',
        });

        return false;
      }

      // let's call update user API endpoint only when its needed, because of the fact how API works now, so when phone
      // number is changed, the PATCH /user/{user_id} is already triggering the verification sms to be send. The only
      // case then we will call directly the send verify sms request will be when user's phone # is not changed since
      // last time, but its still not verified
      const phoneNumberChanged = user.phone !== phoneNumber;
      let updateUserPromise = Promise.resolve();

      if (phoneNumberChanged) {
        updateUserPromise = updateUser(user.id, {
          phone: phoneNumber,
          send_sms_verification: true,
        });
      }

      updateUserPromise
        .then(() => {
          if (phoneNumberChanged) {
            return false;
          }

          return sendSmsVerifyRequest(user.id);
        })
        .then(() => {
          snackbarHelper.info("We've just sent to you a verification code. Please check your phone.");

          this.setState({
            loading: false,
            phoneNumberError: null,
            showPhoneNumberVerificationDialog: true,
          });
        })
        .catch(err => {
          if(err.code === errorMessages.ERR_DUPLICATED_PHONE.code) {
            this.setState({
              phoneNumberError: errorMessages.ERR_DUPLICATED_PHONE.message,
            })
          } else {
            errorsHelper.handleError(err);
          }
        })
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  phoneNumberTouched = () => {
    this.setState({
      phoneNumberTouched: true,
    });
  };

  getPhoneNumber = () => {
    const { user } = this.props;
    const { phoneNumberTouched, phoneNumber } = this.state;
    return phoneNumberTouched ? phoneNumber : user.phone;
  };

  render() {
    const { open, handleClose } = this.props;
    const {
      showPhoneNumberVerificationDialog,
      verificationDialogLoading,
      loading,
      phoneNumberVerified,
      phoneNumberError,
      confirmationCodeError,
    } = this.state;

    const phoneNumber = this.getPhoneNumber();
    const isValidPhoneNumber = isPhoneMaskValid(phoneNumber);

    return (
      <Dialog open={open} disableBackdropClick>
        <OverlayLoader isLoading={loading}>
          <DialogTitle id="form-dialog-title">Enter phone number</DialogTitle>

          <DialogContent>
            <DialogContentText>We will send you verification code on your mobile phone.</DialogContentText>

            <InputMask
              mask="999-999-9999"
              value={phoneNumber}
              onChange={this.onNumberChange}
              onFocus={this.phoneNumberTouched}
              onBlur={this.phoneNumberTouched}
            >
              <TextField
                fullWidth
                id="outlined-name"
                label="Phone Number, e.g.: 555-555-5555"
                margin="normal"
                style={{ marginTop: 0, marginBottom: 0 }}
                error={!isValidPhoneNumber && getPhoneNumberFromMask(phoneNumber).length > 0}
              />
            </InputMask>

            {phoneNumberVerified && (
              <FormHelperText id="my-helper-text" style={{ color: 'green' }}>
                <Check fontSize="small" style={{ fontSize: 12 }} /> Successfully verified.
              </FormHelperText>
            )}

            {phoneNumberError && (
              <FormHelperText id="my-helper-text" error>
                {phoneNumberError}
              </FormHelperText>
            )}
          </DialogContent>

          <DialogActions>
            <Button startIcon={<Phone fontSize="small" />} onClick={handleClose} color="primary">
              Cancel
            </Button>
            {!phoneNumberVerified && (
              <Button
                variant="contained"
                startIcon={<Phone fontSize="small" />}
                onClick={this.validatePhoneNumberStep}
                color="primary"
                disabled={!isValidPhoneNumber}
              >
                Send
              </Button>
            )}
          </DialogActions>
        </OverlayLoader>

        <PhoneConfirmationCodeDialog
          phoneNo={formatPhoneNumber(phoneNumber)}
          handleCodeNotReceived={this.handleCodeNotReceived}
          handleClose={this.handleConfirmationModalClose}
          handleVerify={this.handleVerify}
          open={showPhoneNumberVerificationDialog}
          loading={verificationDialogLoading}
          confirmationCodeError={confirmationCodeError}
        />
      </Dialog>
    );
  }
}

PhoneVerifyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.any.isRequired,
};

const mapStateToProps = ({ app: { loggedInUser, impersonating } }) => ({
  user: impersonating || loggedInUser,
});

const mapDispatchToProps = dispatch => ({
  updateUser: (userId, data) => dispatch(updateUserAction(userId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerifyDialog);
