import React, { useEffect, useState, useContext }  from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import get from 'lodash/get';
import { makeStyles, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import ZipCodeSelect from './ZipCodeSelect';
import PrimaryContactUserSelect from './PrimaryContactUserSelect';
import PhoneTypeSelectNew from './PhoneTypeSelectNew';
import ButtonWithLoading from './ButtonWithLoading';
import * as formsHelpers from '../helpers/forms';
import * as errorsHelper from '../helpers/errors';
import errorMessages from '../assets/errorMessages';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import { TextFieldPhoneExt, TextFieldWrapper } from './Form/MuiFormWrapper';
import { isEmailValid } from '../helpers/validators';

const useStyles = makeStyles(() => ({
  form: {
    maxWidth: 480,
  },
  textWide: {
    minWidth: 480,
    marginBottom: 15,
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  activeCheckboxContainer: {
    marginBottom: 15,
  },
}));

let formDirtyFields;

const validationResolver = (data, validationContext) => {
  const errors = {};

  if (!data.address) {
    errors.address = { message: errorMessages.REQUIRED.message };
  }

  if (!data.zip_code) {
    errors.zip_code = { message: errorMessages.REQUIRED.message };
  }

  if (!data.city) {
    errors.city = { message: errorMessages.REQUIRED.message };
  }

  if (!data.state) {
    errors.state = { message: errorMessages.REQUIRED.message };
  }

  if (data.primary_contact_email) {
    if (!isEmailValid(data.primary_contact_email)) {
      errors.primary_contact_email = { message: errorMessages.ERR_INVALID_EMAIL.message };
    }
  }

  // if EXISTING user for primary contact is not selected (so we are going to create a new one)
  // and any of primary contact user fields is filled -> let's validate rest of the related fields as well
  if (
    !validationContext.selectedPrimaryContactUser
    && (
      (data.primary_contact_email && data.primary_contact_email.length)
      || (data.primary_contact_firstname && data.primary_contact_firstname.length)
      || (data.primary_contact_lastname && data.primary_contact_lastname.length)
      || (data.primary_contact_phone && data.primary_contact_phone.length)
      || (data.primary_contact_phone_ext && data.primary_contact_phone_ext.length)
      || (data.primary_contact_phone_type && data.primary_contact_phone_type.length)
    )
  ) {
    if (!data.primary_contact_email) {
      errors.primary_contact_email = { message: errorMessages.REQUIRED.message };
    }

    if (!data.primary_contact_firstname) {
      errors.primary_contact_firstname = { message: errorMessages.REQUIRED.message };
    }

    if (!data.primary_contact_lastname) {
      errors.primary_contact_lastname = { message: errorMessages.REQUIRED.message };
    }

    if (!data.primary_contact_phone) {
      errors.primary_contact_phone = { message: errorMessages.REQUIRED.message };
    }
  }

  if (
    validationContext.hasDuplicatedPhoneNumberError
    && !formDirtyFields.has('primary_contact_phone')
  ) {
    errors.primary_contact_phone = { message: errorMessages.ERR_DUPLICATED_PHONE.message };
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors: errors,
  };
};

const FoodDonorPickupLocationAddForm = ({ createFoodDonorPickupLocation }) => {
  const classes = useStyles();
  const [selectedPrimaryContactUserPhoneType, setSelectedPrimaryContactUserPhoneType] = useState(false);
  const [selectedPrimaryContactUser, setSelectedPrimaryContactUser] = useState(false);
  const [selectedZipCode, setSelectedZipCode] = useState(false);
  const [active, setActive] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasDuplicatedPhoneNumberError, setHasDuplicatedPhoneNumberError] = useState(false);
  const [primaryContactUserPhoneNumberFieldFocused, setPrimaryContactUserPhoneNumberFieldFocused] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    getValues,
    reset,
    errors,
    control,
    formState: { dirtyFields, isSubmitted },
  } = useForm({
    defaultValues: {
      active: true,
    },
    validationResolver: validationResolver,
    validationContext: {
      selectedPrimaryContactUser: selectedPrimaryContactUser,
      hasDuplicatedPhoneNumberError: hasDuplicatedPhoneNumberError,
    },
  });
  const notificationsContext = useContext(NotificationsContext);

  formDirtyFields = dirtyFields;

  useEffect(() => registerControlledInputs(), []);

  const setFormValue = (name, value) => setValue(name, value, isSubmitted);

  const registerControlledInputs = () => {
    register({ name: 'zip_code' });
    register({ name: 'primary_contact_email' });
  };

  const handlePrimaryContactUserPhoneTypeChange = event => setSelectedPrimaryContactUserPhoneType(event.target.value);

  const onChangePrimaryContactUser = option => {
    setSelectedPrimaryContactUser(option ? option : false);
    setSelectedPrimaryContactUserPhoneType(get(option, 'meta.phone_type', false));

    setFormValue('primary_contact_email', get(option, 'meta.email', option ? option.label : ''));
    setFormValue('primary_contact_firstname', get(option, 'meta.firstname', ''));
    setFormValue('primary_contact_lastname', get(option, 'meta.lastname', ''));
    setFormValue('primary_contact_phone', get(option, 'meta.phone', ''));
    setFormValue('primary_contact_phone_ext', get(option, 'meta.phone_ext', ''));
    setFormValue('primary_contact_phone_type', get(option, 'meta.phone_type', ''));
  };

  const onChangeZipCode = option => {
    setSelectedZipCode(option ? option : false);

    setFormValue('zip_code', get(option, 'value', ''));
    setFormValue('city', get(option, 'data.city', ''));
    setFormValue('state', get(option, 'data.state', ''));
  };

  const onActiveChange = event => setActive(event.target.checked);

  const onSubmit = values => {
    const data = {
      ...values,

      // all of the controlled inputs have to be defined manually
      primary_contact_id: selectedPrimaryContactUser && selectedPrimaryContactUser.meta
        ? selectedPrimaryContactUser.meta.id
        : null,
      primary_contact_phone_type: selectedPrimaryContactUserPhoneType ? selectedPrimaryContactUserPhoneType : null,
      active: active,
    };

    // check if user created any new user in the form
    if (!selectedPrimaryContactUser || (selectedPrimaryContactUser && selectedPrimaryContactUser.value)) {
      return onSubmitInner(data);
    }

    return formsHelpers.showSendInvitationEmailConfirmationDialog(data, onSubmitInner);
  };

  const onSubmitInner = (data, sendInvitationEmail = false) => Bluebird
    .try(() => {
      setIsSubmitting(true);

      return createFoodDonorPickupLocation({
        ...data,
        send_invitation_email: sendInvitationEmail,
      });
    })
    .catch(err => {
      setIsSubmitting(false);

      switch (err.code) {
        case errorMessages.ERR_DUPLICATED_PHONE.code:
          setHasDuplicatedPhoneNumberError(true);

          break;
        default:
          errorsHelper.handleErrorNew(err, notificationsContext);

          break;
      }

      // reset form with the current values - to clear touched / dirty flags (there is currently no other way to do it)
      // reset form is also unregistering previously custom registered fields with useEffect, so we have to bring
      // these back and then trigger validation to show API errors
      return Bluebird
        .try(() => reset(getValues(), { isSubmitted: true }))
        .then(() => registerControlledInputs())
        .then(() => triggerValidation());
    });

  const hasFilledAnyContactPersonField =
    !!getValues('primary_contact_email') ||
    !!getValues('primary_contact_firstname') ||
    !!getValues('primary_contact_lastname') ||
    !!getValues('primary_contact_phone') ||
    !!getValues('primary_contact_phone_ext');

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="name"
        className={classes.textWide}
        label="Name"
        variant="outlined"
        size="small"
        inputRef={register}
        error={!!errors.name}
        helperText={errors.name ? errors.name.message : ''}
        InputLabelProps={{ shrink: !!getValues('name') || undefined }}
      />

      <TextField
        name="address"
        className={classes.textWide}
        label="Address"
        variant="outlined"
        size="small"
        inputRef={register}
        error={!!errors.address}
        helperText={errors.address ? errors.address.message : ''}
        InputLabelProps={{ shrink: !!getValues('address') || undefined }}
      />

      <ZipCodeSelect
        name="zip_code"
        className={classes.textWide}
        onChangeZipCode={onChangeZipCode}
        error={errors.zip_code}
        formValue={getValues('zip_code')}
      />

      <TextField
        name="city"
        className={classes.textWide}
        label="City"
        variant="outlined"
        size="small"
        disabled={!!selectedZipCode.value}
        inputRef={register}
        error={!!errors.city}
        helperText={errors.city ? errors.city.message : ''}
        InputLabelProps={{ shrink: !!getValues('city') || undefined }}
      />

      <TextFieldWrapper
        name="state"
        className={classes.textWide}
        label="State"
        variant="outlined"
        size="small"
        disabled={!!selectedZipCode.value}
        inputRef={register}
        error={!!errors.state}
        helperText={errors.state ? errors.state.message : ''}
        InputLabelProps={{ shrink: !!getValues('state') || undefined }}
      />

      <PrimaryContactUserSelect
        required={hasFilledAnyContactPersonField}
        name="primary_contact_email"
        className={classes.textWide}
        onChangeUser={onChangePrimaryContactUser}
        error={errors.primary_contact_email}
        formValue={getValues('primary_contact_email')}
      />

      <TextFieldWrapper
        required={hasFilledAnyContactPersonField}
        id="primary_contact_firstname"
        name="primary_contact_firstname"
        className={classes.textWide}
        label="Primary Contact First Name"
        variant="outlined"
        size="small"
        inputRef={register}
        error={!!errors.primary_contact_firstname}
        helperText={errors.primary_contact_firstname ? errors.primary_contact_firstname.message : ''}
        disabled={!!selectedPrimaryContactUser.value}
        autoComplete="chrome-off"
        InputLabelProps={{ shrink: !!getValues('primary_contact_firstname') || undefined }}
      />

      <TextFieldWrapper
        required={hasFilledAnyContactPersonField}
        id="primary_contact_lastname"
        name="primary_contact_lastname"
        className={classes.textWide}
        label="Primary Contact Last Name"
        variant="outlined"
        size="small"
        inputRef={register}
        error={!!errors.primary_contact_lastname}
        helperText={errors.primary_contact_lastname ? errors.primary_contact_lastname.message : ''}
        disabled={!!selectedPrimaryContactUser.value}
        autoComplete="chrome-off"
        InputLabelProps={{ shrink: !!getValues('primary_contact_lastname') || undefined }}
      />

      <InputMask
        mask="999-999-9999"
        disabled={!!selectedPrimaryContactUser.value}
        onFocus={() => setPrimaryContactUserPhoneNumberFieldFocused(true)}
        onBlur={() => setPrimaryContactUserPhoneNumberFieldFocused(false)}
      >
        <TextFieldWrapper
          required={hasFilledAnyContactPersonField}
          id="primary_contact_phone"
          name="primary_contact_phone"
          className={classes.textWide}
          label="Primary Contact Phone#"
          variant="outlined"
          size="small"
          inputRef={register}
          error={!!errors.primary_contact_phone}
          helperText={errors.primary_contact_phone ? errors.primary_contact_phone.message : ''}
          autoComplete="chrome-off"
          InputLabelProps={{shrink: !!getValues('primary_contact_phone') || primaryContactUserPhoneNumberFieldFocused }}
        />
      </InputMask>

      <TextFieldPhoneExt
        name="primary_contact_phone_ext"
        className={classes.textWide}
        label="Primary Contact Phone# Extension"
        variant="outlined"
        size="small"
        disabled={!!selectedPrimaryContactUser.value}
        inputRef={register}
        InputLabelProps={{ shrink: !!getValues('primary_contact_phone_ext') || undefined }}
      />

      <PhoneTypeSelectNew
        id="primary_contact_phone_type"
        name="primary_contact_phone_type"
        className={classes.textWide}
        label="Primary Contact Phone# Type"
        value={selectedPrimaryContactUserPhoneType ? selectedPrimaryContactUserPhoneType : 'mobile'}
        onChange={handlePrimaryContactUserPhoneTypeChange}
        error={!!errors.primary_contact_phone_type}
        helperText={errors.primary_contact_phone_type ? errors.primary_contact_phone_type.message : ''}
        disabled={!!selectedPrimaryContactUser.value}
        InputLabelProps={{ shrink: !!getValues('primary_contact_phone_type') || undefined }}
      />

      <TextField
        name="notes"
        className={classes.textWide}
        label="Pickup Notes"
        variant="outlined"
        size="small"
        inputRef={register}
        InputLabelProps={{ shrink: !!getValues('notes') || undefined }}
        multiline
      />

      <div className={classes.activeCheckboxContainer}>
        <FormControlLabel
          control={<Checkbox
            className={classes.checkbox}
            name="active"
            color="primary"
            checked={active}
            onChange={onActiveChange}
          />}
          label="Active"
        />
      </div>

      <ButtonWithLoading type="submit" variant="contained" color="primary" isLoading={isSubmitting}>
        Create Food Donor Pickup Location
      </ButtonWithLoading>

      {process.env.NODE_ENV === 'development' && (
        <DevTool control={control} />
      )}
    </form>
  );
};

export default FoodDonorPickupLocationAddForm;
