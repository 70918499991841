import * as badgesActions from '../actions/badges';
import { objectFromArray } from '../helpers';

export const initialUserBadgeState = {
  inflight: false,
  lastUpdated: null,
  badges: [],
};

export const initialBadgesState = {
  inflight: false,
  byId: {},
  allIds: [],
  byRescuerId: {},
  lastUpdated: null,
};

const badgesReducer = (state = initialBadgesState, action) => {
  switch (action.type) {
    case badgesActions.REQUEST_BADGES:
      return {
        ...state,
      };
    case badgesActions.RECEIVE_BADGES:
      return {
        ...state,
        byId: objectFromArray(action.badges, badge => badge.id),
        allIds: action.badges.map(badge => badge.id),
      };
    case badgesActions.REQUEST_RESCUER_BADGES:
      return {
        ...state,
        byRescuerId: {
          ...state.byRescuerId,
          [action.userId]: {
            ...(state.byRescuerId[action.userId] || initialUserBadgeState),
            inflight: true,
          },
        },
      };
    case badgesActions.RECEIVE_RESCUER_BADGES:
      return {
        ...state,
        byRescuerId: {
          ...state.byRescuerId,
          [action.userId]: {
            ...(state.byRescuerId[action.userId] || initialUserBadgeState),
            inflight: false,
            lastUpdated: action.receivedAt,
            badges: action.badges,
          },
        },
      };
    default:
      return state;
  }
};

export default badgesReducer;
