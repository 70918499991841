import React from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

const DonationRequestModifyDialog = ({ isOpen, onClose, handleCancel, handleModify, modifiedDonationFields }) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = () =>
    handleModify(
      modifiedDonationFields.map(row => ({
        field: row.slug,
        old_value: row.oldValue,
        old_value_formatted: row.oldValueFormatted,
        new_value: row.newValue,
        new_value_formatted: row.newValueFormatted,
      }))
    );

  const modifiedFields = modifiedDonationFields.reduce((acc, field) => {
    if (!field.display) {
      return acc;
    }

    return [
      ...acc,
      <TableRow key={field.fieldName}>
        <TableCell>{field.fieldName}</TableCell>
        <TableCell>
          <strike>{field.oldValueFormatted}</strike> <strong>{field.newValueFormatted}</strong>
        </TableCell>
      </TableRow>,
    ];
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Modify Request to Donate</DialogTitle>
        <DialogContent>
          <DialogContentText>Please verify modified fields.</DialogContentText>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{modifiedFields}</TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit">
            Modify
          </Button>
        </DialogActions>
        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </form>
    </Dialog>
  );
};

export default DonationRequestModifyDialog;
