import React from 'react';
import { useSelector } from 'react-redux';

const usersEquals = (prev, next) => (prev ? prev.id : null === next ? next.id : null);

const useLoggedUser = () => {
  return useSelector(state => state.app.loggedInUser, usersEquals);
};

export default useLoggedUser;
