import { objectFromArray } from '../helpers';
import * as eventsActions from '../actions/events';
import * as rescuersActions from '../actions/rescuers';
import { get } from 'lodash';

export const initialRescuerEventsState = {
  inflight: false,
  lastUpdated: null,
  events: [],
};

export const initialRescuerSiteEventsState = {
  inflight: false,
  byId: {},
  allIds: [],
  byRescuerId: {},
  lastUpdated: null,
};

const setEventShift = (event, eventShift) => {
  const eventData = { ...event };
  const shiftIndex = eventData.shifts.findIndex((shift) => shift.id === eventShift.id);
  eventData.shifts[shiftIndex] = eventShift;

  return eventData;
};

const setRescuerEvents = (rescuerData, eventId, eventShift) => {
  const rescuerEvents = [...rescuerData.events];
  const eventIndex = rescuerEvents.findIndex((event) => event.id === eventId);

  rescuerEvents[eventIndex] = setEventShift(rescuerEvents[eventIndex], eventShift);

  return {
    ...rescuerData,
    events: rescuerEvents,
  };
};

const deleteEventRegistration = (event, { eventShiftId, eventShiftRegistrationId }) => {
  const eventData = { ...event };
  const shiftEventDeleteIndex = get(event, 'shifts', []).findIndex((shift) => shift.id === eventShiftId);
  const shiftEventRegistrations = get(event, `shifts[${shiftEventDeleteIndex}].registrations`, []).filter(
    r => r.id !== eventShiftRegistrationId
  );

  eventData.shifts[shiftEventDeleteIndex].registrations = shiftEventRegistrations || [];
  return eventData;
};

const setRescuerEventRegistrationDeletedAt = (userId, rescuerData, eventId, { eventShiftId, eventShiftRegistrationId }) => {
  const rescuerEvents = [...rescuerData.events];
  const eventIndex = rescuerEvents.findIndex((event) => event.id === eventId);

  rescuerEvents[eventIndex] = deleteEventRegistration(rescuerEvents[eventIndex], {
    eventShiftId,
    eventShiftRegistrationId,
  });

  const hasActiveShifts = rescuerEvents[eventIndex].shifts.filter(({ registrations }) => {
    return registrations.filter((r) => r.user_id === userId && r.deleted_at === null).length !== 0;
  }).length !== 0;

  if (!hasActiveShifts) {
    rescuerEvents.splice(eventIndex, 1)
  }


  return {
    ...rescuerData,
    events: rescuerEvents || [],
  };
};

const eventsReducer = (state = initialRescuerSiteEventsState, action) => {
  switch (action.type) {
    case rescuersActions.REQUEST_RESCUER_EVENTS:
      return {
        ...state,
        byRescuerId: {
          ...state.byRescuerId,
          [action.rescuerId]: {
            ...(state.byRescuerId[action.rescuerId] || initialRescuerEventsState),
            inflight: true,
          },
        },
      };
    case rescuersActions.RECEIVE_RESCUER_EVENTS:
      const rescuerActiveEvents = action.events.filter(event => !event.cancelled_at)

      return {
        ...state,
        byRescuerId: {
          ...state.byRescuerId,
          [action.rescuerId]: {
            ...(state.byRescuerId[action.rescuerId] || initialRescuerEventsState),
            inflight: false,
            lastUpdated: action.receivedAt,
            events: rescuerActiveEvents,
          },
        },
      };
    case rescuersActions.INVALIDATE_RESCUER_EVENTS:
      return {
        ...state,
        byRescuerId: {
          ...state.byRescuerId,
          [action.rescuerId]: initialRescuerEventsState,
        },
      };
    case eventsActions.REQUEST_EVENT:
      return {
        ...state,
        inflight: true,
      };
    case eventsActions.RECEIVE_EVENT:
      return {
        ...state,
        inflight: false,
        byId: {
          ...state.byId,
          [action.event.id]: action.event,
        },
        allIds: Object.values({
          ...state.byId,
          [action.event.id]: action.event,
        }).map(event => event.id),
      };
    case eventsActions.RECEIVE_EVENTS:
      const activeEvents = action.events.filter(event => !event.cancelled_at)
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(activeEvents, (event) => event.id),
        allIds: activeEvents.map((event) => event.id),
        lastUpdated: action.receivedAt,
      };
    case rescuersActions.SET_RESCUER_EVENT_REGISTRATION: {
      return {
        ...state,
        byRescuerId: {
          ...state.byRescuerId,
          [action.rescuerId]: setRescuerEvents(
            state.byRescuerId[action.rescuerId] || initialRescuerEventsState,
            action.eventId,
            action.eventShift
          ),
        },
      };
    }
    case rescuersActions.DELETE_RESCUER_EVENT_REGISTRATION: {
      return {
        ...state,
        byRescuerId: {
          ...state.byRescuerId,
          [action.rescuerId]: setRescuerEventRegistrationDeletedAt(
            action.rescuerId,
            state.byRescuerId[action.rescuerId] || initialRescuerEventsState,
            action.eventId,
            {
              eventShiftId: action.eventShiftId,
              eventShiftRegistrationId: action.eventShiftRegistrationId,
            }
          ),
        },
      };
    }
    case eventsActions.SET_EVENT_REGISTRATION:
      if (!state.byId[action.eventId]) {
        return state;
      }

      return {
        ...state,
        byId: {
          ...state.byId,
          [action.eventId]: setEventShift(state.byId[action.eventId], action.eventShift),
        },
      };
    case eventsActions.DELETE_EVENT_REGISTRATION:
      if (!state.byId[action.eventId]) {
        return state;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.eventId]: deleteEventRegistration(state.byId[action.eventId], {
            eventShiftId: action.eventShiftId,
            eventShiftRegistrationId: action.eventShiftRegistrationId,
          }),
        },
      };
    default:
      return state;
  }
};

export default eventsReducer;
