import React from 'react';
import Button from '@material-ui/core/Button';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationDialog from './ConfirmationDialog';

const SubmitWithSendEmailConfirmation = props => {
  const { title, message, confirmButtonLabel, showConfirmation, label, onButtonNotSend, onButtonSend, ...rest } = props;

  return (
    <Button
      onClick={e => {
        e.preventDefault();

        if (!showConfirmation) {
          onButtonNotSend();
          return;
        }

        confirmAlert({
          title: title ? title : 'Send invitation email?',
          message: message
            ? message
            : 'Should the system send an invitation email now? You can send an invitation email to them anytime from the People tab.',
          buttons: [
            {
              label: 'No',
              color: 'primary',
              onClick: onButtonNotSend,
            },
            {
              label: confirmButtonLabel ? confirmButtonLabel : 'Send Email',
              color: 'primary',
              onClick: onButtonSend,
            },
          ],
          customUI: ({ title, message, onClose, buttons }) => (
            <ConfirmationDialog buttons={buttons} closeDialog={onClose} title={title} message={message} />
          ),
        });
      }}
      type="submit"
      variant="contained"
      color="primary"
      {...rest}
    >
      {label}
    </Button>
  );
};

export default SubmitWithSendEmailConfirmation;
