export const SET_USER_CALENDAR_OPTIONS = 'SET_USER_CALENDAR_OPTIONS';

export const setUserCalendarOptions = (userId, calendarId, data) => ({
  type: SET_USER_CALENDAR_OPTIONS,
  payload: {
    userId,
    calendarId,
    data,
  },
});
