import React from 'react';
import * as TableExport from 'tableexport';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Grid, Tooltip } from '@material-ui/core';

const TableToCSVGenerator = props => {
  const { tableRef, fileName = 'export' } = props;

  return (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      <Grid item>
        <Tooltip title="Download CSV">
          <IconButton
            aria-label="delete"
            onClick={() => {
              const exportInstance = TableExport(tableRef.current, {
                formats: ['csv'],
                filename: fileName,
                position: 'top',
                exportButtons: false,
              });

              const tableId = tableRef.current.getAttribute('id');

              if (!tableId) {
                return;
              }

              const exportData = exportInstance.getExportData()[tableId].csv;
              exportInstance.export2file(
                exportData.data,
                exportData.mimeType,
                exportData.filename,
                exportData.fileExtension
              );
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TableToCSVGenerator;
