import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../types';

export default (state, action) => {
  const addNotification = (currentNotifications, notificationToBeAdded) => [
    ...currentNotifications,
    ...[
      {
        id: notificationToBeAdded.id,
        message: notificationToBeAdded.message,
        type: notificationToBeAdded.type,
      },
    ],
  ];

  const removeNotification = (currentNotifications, notificationId) => [ ...currentNotifications ].filter(
    n => n.id !== notificationId
  );

  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: addNotification(state.notifications, action.payload),
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: removeNotification(state.notifications, action.payload.id),
      };
    default:
      return state;
  }
};
