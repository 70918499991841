import React, { useEffect, useRef, useState } from 'react';
import GoogleMap from 'google-map-react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { fetchSitesIfNeeded } from '../../../actions/sites';
import { getCurrentlyLoggedInOrImpersonatingUser } from '../../../services/auth';
import { getMapUnassignedUser } from '../../../api/map';

const defaultProps = {
  center: {
    lat: 39.0915821,
    lng: -94.8565884,
  },
  defaultZoom: 5,
  minZoom: 5,
  maxZoom: 18,
  greatPlaceCoords: { lat: 59.724465, lng: 30.080121 },
};

export const renderLocationInfoWindow = ({ text, contact }) => (
  <Box display="grid" gridAutoFlow="row" gridRowGap={4}>
    <Typography gutterBottom={!!contact} variant="subtitle1">
      {text}
    </Typography>
    <Box>
      {contact.email && (
        <>
          <Typography variant="subtitle2">E-mail</Typography>
          <Typography gutterBottom variant="body2">
            {contact.email}
          </Typography>
        </>
      )}
    </Box>
  </Box>
);

const UnassignedUserMap = () => {
  const mapApiRef = useRef();
  const dispatch = useDispatch();
  const [mapApi, setMapApi] = useState();
  const [userLocation, setUserLocation] = useState({});
  const sitesEntities = useSelector((state) => state.entities.sites);
  const sites = Object.values(sitesEntities.byId);
  const user = getCurrentlyLoggedInOrImpersonatingUser();

  let markers = [];

  const haversineDistance = (from = {}, to) => {
    const R = 3958.8; // Radius of the Earth in miles
    const rlat1 = from.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = to.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (to.long - from.long) * (Math.PI / 180); // Radian difference (longitudes)

    const distance =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
        )
      );
    return distance;
  };

  const fitMapBounds = () => {
    let isMarkersVisible = false;
    const { map, maps } = mapApi;
    const bounds = new maps.LatLngBounds();

    const nearestMarkers = markers.sort((a, b) => a.distanceFromUser - b.distanceFromUser).slice(0, 2);

    nearestMarkers.forEach((marker) => {
      if (marker.map) {
        isMarkersVisible = true;
        bounds.extend(marker.getPosition());
      }
    });

    if (isMarkersVisible) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
      map.setZoom(map.getZoom());
    }
  };

  const removeAllMarkers = () => {
    markers.forEach((marker) => marker.setMap(null));

    markers = [];
  };

  const addMarker = (location) => {
    const { map, maps } = mapApi;

    const infoWindow = new maps.InfoWindow({
      content: null,
    });

    const distance = haversineDistance(userLocation, location.zip_codes_lat_long[0]);

    const marker = new maps.Marker({
      id: location.id,
      position: {
        lat: location.zip_codes_lat_long[0]?.lat,
        lng: location.zip_codes_lat_long[0]?.long,
      },
      distanceFromUser: distance,
      map,
      meta: location,
    });

    marker.addListener('click', () => {
      const div = document.createElement('div');

      ReactDOM.render(
        renderLocationInfoWindow({
          text: location.name,
          contact: {
            email: location.primary_contact_email,
          },
        }),
        div
      );

      infoWindow.setContent(div);
      infoWindow.open(map, marker);
    });

    markers.push(marker);
  };

  const getUnassignedUserLocation = async () => {
    try {
      const res = await getMapUnassignedUser(user.id);
      const { data } = await res.json();
      return setUserLocation(data);
    } catch (error) {
      return console.error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchSitesIfNeeded(false)); // Fetch active sites
    getUnassignedUserLocation();
  }, []);

  useEffect(() => {
    if (mapApi) {
      removeAllMarkers();
      sites.forEach((site) => {
        if (site?.zip_codes_lat_long[0]?.lat) {
          addMarker(site);
        }
      });
      fitMapBounds();
    }
  }, [mapApi, sitesEntities, userLocation]);

  return (
    <Box height="90%" minHeight={300} width="100%">
      <GoogleMap
        ref={mapApiRef}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.defaultZoom}
        hoverDistance={20}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          maxZoom: defaultProps.maxZoom,
          minZoom: defaultProps.minZoom,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(api) => setMapApi(api)}
      />
      <Box
        style={{
          position: 'relative',
          zIndex: 5,
        }}
      >
        <Box
          boxShadow={25}
          bgcolor="background.paper"
          style={{
            position: 'absolute',
            bottom: 15,
            right: 3,
            padding: 8,
          }}
        >
          <IconButton size="small" onClick={() => mapApi.map.setZoom(mapApi.map.getZoom() + 1)}>
            <AddIcon />
          </IconButton>
          <IconButton size="small" onClick={() => mapApi.map.setZoom(mapApi.map.getZoom() - 1)}>
            <RemoveIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default UnassignedUserMap;
