import { apiPOST } from './api';
import axios from 'axios';

export const s3FileUpload = ({ url, file, onUploadProgress }) =>
  axios.request( {
    method: "put",
    url: url,
    data: file,
    headers: {
      'Content-Type': 'resource/image',
    },
    redirect: 'follow',
    onUploadProgress: onUploadProgress,
  })


export const generateS3Url = (fileName, bucket = null) =>
  apiPOST('/aws/create-write-signed-url', {
    bucket,
    file_name: fileName,
  });
