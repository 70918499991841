import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Grid, Box, ButtonBase, Typography, CardContent, Avatar } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { BaseCard } from '../../../components/Common/BaseCard';
import {
  Add as AddIcon,
  StoreMallDirectory as StoreMallDirectoryIcon,
  Fastfood as FastfoodIcon,
  MultilineChart as MultilineChartIcon, // site goals
  PhotoLibrary as PhotoLibraryIcon,
  EmojiPeople as EmojiPeopleIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
  LocationCity as LocationCityIcon,
  Beenhere as BeenHereIcon, // badges
  Nature as NatureIcon, // environment impact
  Portrait as PortraitIcon, // id mode
} from '@material-ui/icons';
import { Colors } from '../../../assets/theme/Colors';
import HelloUser from './Components/HelloUser';
import classNames from 'classnames';
import { Link, generatePath } from 'react-router-dom';
import routes from '../../../routes';
import HtmlTooltip from '../../../components/Common/HtmlTooltip';
import { RubberStamp } from '../../Rescue/RescueMobileCard';
import useRescuerDashboardActions from '../../../hooks/useRescuerDashboardActions';
import {
  getMyFrusMyEvents,
  getRecentUnclosedRescues,
  getTodayUnclosedRescues,
} from '../../../helpers/RescuesHelper';
import useActiveUser from '../../../hooks/useActiveUser';
import DashboardMobileTabBadgeCounter from '../../../components/Common/DashboardMobileTabBadgeCounter';
import * as authServices from '../../../services/auth';
import { Roles } from '../../../models/roles';
import useActiveSite from '../../../hooks/useActiveSite';
import useHasActiveFeatureFlag from '../../../hooks/useFeatureFlags';
import { FF_EVENTS, FF_SITE_GOALS } from '../../../components/FeatureFlagWrapper';
import * as authService from '../../../services/auth';
import { fetchRequestDonationList } from '../../../actions/requestDonations';
import { fetchFoodDonorsWithPendingVerifications } from '../../../actions/foodDonors';

const StyledBaseCard = withStyles({
  root: {
    height: '100%',
    borderRadius: 16,
    minHeight: '40vw',
  },
})(BaseCard);

const LandingCardText = withStyles(({ typography, spacing }) => ({
  root: {
    ...typography['h5'],
    fontWeight: 500,
    textDecoration: 'none',
    marginTop: spacing(1),
    color: '#fff',
  },
}))(Typography);

const LandingBadge = withStyles(({ spacing }) => ({
  badge: {
    fontSize: '1.2rem',
    top: 10,
    right: 10,
    padding: spacing(1.4, 0.8),
  },
}))(DashboardMobileTabBadgeCounter);

const LandingCardWide = ({ text, color, icon, to = '#', disabled = false, badgeCount }) => (
  <StyledBaseCard>
    <HtmlTooltip withBorder title={disabled ? 'This feature is disabled!' : ''}>
      <Box height={1} width={1}>
        {disabled && (
          <Box
            css={{
              position: 'relative',
              transform: 'scale(0.7)',
              zIndex: 4,
              marginLeft: 25,
            }}
          >
            <RubberStamp isCancelled text="Coming soon" />
          </Box>
        )}
        <ButtonBase
          disabled={disabled}
          component={Link}
          to={to}
          focusRipple
          style={{
            filter: disabled ? 'grayscale(0.7) opacity(0.3)' : 'none',
            backgroundColor: color,
            width: '100%',
            height: '100%',
            borderRadius: 16,
          }}
        >
          <CardContent style={{ width: '100%' }}>
            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
              <LandingBadge style={{ fontSize: '2rem' }} badgeContent={badgeCount} color="primary">
                {icon}
              </LandingBadge>
              <LandingCardText>{text}</LandingCardText>
            </Box>
          </CardContent>
        </ButtonBase>
      </Box>
    </HtmlTooltip>
  </StyledBaseCard>
);

const useStyles = makeStyles(({ spacing, shadows, breakpoints }) => ({
  card: {
    flex: `0 1 calc(50% - ${spacing(1)}px)`,
    border: '1px solid black',
    boxSizing: 'content-box',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    flexFlow: 'row wrap',
    gridGap: spacing(1),
    padding: spacing(1),
  },
  wrapper2: {
    padding: spacing(2),
    width: '100%',
  },
  avatar: {
    borderRadius: 12,
    width: 70,
    height: 70,
  },
  icon: {
    fontSize: '3rem',
  },
  schedule: {
    backgroundColor: Colors.schedule.color,
  },
  environment: {
    backgroundColor: Colors.environment.color,
  },
  goals: {
    backgroundColor: Colors.goals.color,
  },
  badges: {
    backgroundColor: Colors.badges.color,
  },
  today: {
    backgroundColor: Colors.rescues.today.color,
  },
  event: {
    backgroundColor: Colors.event.color,
  },
  photos: {
    backgroundColor: '#5ab53f',
  },
  idMode: {
    backgroundColor: Colors.idMode.color,
  },
  donationRequestList: {
    backgroundColor: Colors.requestDonationList.color,
  },
  donationRequestAdd: {
    backgroundColor: Colors.requestDonationAdd.color,
  },
  pendingFoodDonor: {
    backgroundColor: Colors.pendingFoodDonor.color,
  },
  switchSites: {
    backgroundColor: Colors.switchSites.color,
  },
}));

const LandingDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const eventsEntities = useSelector((state) => state.entities.events);
  const featureFlagsEntities = useSelector((state) => state.entities.featureFlags);
  const rescuesEntities = useSelector((state) => state.entities.rescues);
  const pendingDonationsCount = useSelector((state) => state.entities.requestDonations.allIds).length;
  const foodDonorsPendingRegistrationCount = useSelector((state) => state.entities.foodDonors.allIds).length;
  const hasEventsAccess = useHasActiveFeatureFlag(FF_EVENTS);
  const hasActiveSiteGoalsFeatureFlag = useHasActiveFeatureFlag(FF_SITE_GOALS);
  const { formatMessage } = useIntl();

  const rescuesCount =
    getTodayUnclosedRescues(activeUser.id, rescuesEntities).length +
    getRecentUnclosedRescues(activeUser.id, rescuesEntities).length;
  const eventsCount = getMyFrusMyEvents(eventsEntities, activeUser.id).events.length;

  // role check
  const isSiteDirector = authServices.hasAnyRoleInSite(activeUser, activeSite.id, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
    Roles.NationalSiteDirector,
    Roles.Admin,
  ]);
  const isRescuer = authServices.hasRoleInAnySite(activeUser, Roles.Rescuer);
  const isDonor = authService.hasAnyRoleInSite(activeUser, activeSite.id, [
    Roles.DonorAdmin,
    Roles.DonorStaff,
    Roles.DonorLocationAdmin,
    Roles.DonorLocationStaff,
  ]);
  const hasAccessToMultipleSitesAsSD = (authService.getUserSitesByRoles(
    activeUser.role_assignments,
    [
      Roles.SiteDirector,
      Roles.SiteCoordinator,
    ]
  ) || []).length > 1;

  const isRescuerInCurrentlySelectedSite = authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.Rescuer);

  const { fetchMyEvents, fetchMyFrusTab, fetchBadgesTab, fetchGoals } = useRescuerDashboardActions();

  useEffect(() => {
    fetchMyEvents(false, true);

    if (isRescuer) {
      fetchMyFrusTab(false, true);

      fetchBadgesTab(false, true);

      if (hasActiveSiteGoalsFeatureFlag) {
        fetchGoals(false); // quietMode
      }
    }

    if (isSiteDirector) {
      dispatch(
        fetchRequestDonationList({
          siteIds: [activeSite.id],
          verificationStatus: 'pending',
        })
      );
      dispatch(fetchFoodDonorsWithPendingVerifications([activeSite.id]));
    }
  }, [
    dispatch,
    fetchMyEvents,
    fetchMyFrusTab,
    isRescuer,
    isSiteDirector,
    hasActiveSiteGoalsFeatureFlag,
  ]);

  // wait for feature flags fetch before showing user page
  if (featureFlagsEntities.inflight) {
    return null;
  }

  return (
    <Box className={classes.wrapper2} data-testid="tiled-dashboard">
      <HelloUser />

      <Grid container direction="row" spacing={3}>
        {isSiteDirector && (
          <>
            <Grid item xs={6}>
              <LandingCardWide
                to={generatePath(routes.mobileDashboardSchedule)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.site' })}
                color={Colors.schedule.color}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.schedule)}>
                    <StoreMallDirectoryIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <LandingCardWide
                badgeCount={pendingDonationsCount}
                to={generatePath(routes.pendingDonations)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.pending-donations' })}
                color={Colors.requestDonationAdd.color}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.donationRequestAdd)}>
                    <LocalGroceryStoreIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <LandingCardWide
                badgeCount={foodDonorsPendingRegistrationCount}
                to={generatePath(routes.pendingFoodDonorsRegistrations)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.pending-food-donors' })}
                color={Colors.pendingFoodDonor.color}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.pendingFoodDonor)}>
                    <LocationCityIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>

            {hasAccessToMultipleSitesAsSD && (
              <Grid item xs={6}>
                <LandingCardWide
                  badgeCount={foodDonorsPendingRegistrationCount}
                  to={generatePath(routes.sites)}
                  text={formatMessage({ id: 'dashboard-mobile.tiles.sites' })}
                  color={Colors.switchSites.color}
                  icon={
                    <Avatar className={classNames(classes.avatar, classes.switchSites)}>
                      <StoreMallDirectoryIcon className={classes.icon} />
                    </Avatar>
                  }
                />
              </Grid>
            )}
          </>
        )}

        {isRescuer && (
          <>
            <Grid item xs={6}>
              <LandingCardWide
                to={generatePath(routes.mobileDashboardRescues)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.rescues' })}
                color={Colors.rescues.today.color}
                badgeCount={rescuesCount}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.today)}>
                    <FastfoodIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <LandingCardWide
                to={generatePath(routes.mobileDashboardBadges)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.my-badges' })}
                color={Colors.badges.color}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.badges)}>
                    <BeenHereIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>

            {hasActiveSiteGoalsFeatureFlag && (
              <Grid item xs={6}>
                <LandingCardWide
                  to={generatePath(routes.mobileDashboardSiteGoals)}
                  text={formatMessage({ id: 'dashboard-mobile.tiles.site-goals' })}
                  color={Colors.goals.color}
                  icon={
                    <Avatar className={classNames(classes.avatar, classes.goals)}>
                      <MultilineChartIcon className={classes.icon} />
                    </Avatar>
                  }
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <LandingCardWide
                to={generatePath(routes.mobileDashboardEnvironmentImpact)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.environment-impact' })}
                color={Colors.environment.color}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.environment)}>
                    <NatureIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>
          </>
        )}

        {isDonor && (
          <>
            <Grid item xs={6}>
              <LandingCardWide
                to={generatePath(routes.donation_requests)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.request-to-donate-list' })}
                color={Colors.requestDonationList.color}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.donationRequestList)}>
                    <StoreMallDirectoryIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <LandingCardWide
                to={generatePath(routes.donation_create_request)}
                text={formatMessage({ id: 'dashboard-mobile.tiles.request-new-donation' })}
                color={Colors.requestDonationAdd.color}
                icon={
                  <Avatar className={classNames(classes.avatar, classes.donationRequestAdd)}>
                    <AddIcon className={classes.icon} />
                  </Avatar>
                }
              />
            </Grid>
          </>
        )}

        {hasEventsAccess && (
          <Grid item xs={6}>
            <LandingCardWide
              to={generatePath(routes.mobileDashboardEvents, { tab: 'my-events' })}
              text={formatMessage({ id: 'dashboard-mobile.tiles.events' })}
              color={Colors.event.color}
              badgeCount={eventsCount}
              icon={
                <Avatar className={classNames(classes.avatar, classes.event)}>
                  <EmojiPeopleIcon className={classes.icon} />
                </Avatar>
              }
            />
          </Grid>
        )}

        {false && (
          <Grid item xs={6}>
            <LandingCardWide
              disabled
              text={formatMessage({ id: 'dashboard-mobile.tiles.photos' })}
              color="#5ab53f"
              icon={
                <Avatar className={classNames(classes.avatar, classes.photos)}>
                  <PhotoLibraryIcon className={classes.icon} />
                </Avatar>
              }
            />
          </Grid>
        )}

        {isRescuerInCurrentlySelectedSite && (
          <Grid item xs={6}>
            <LandingCardWide
              to={generatePath(routes.idMode)}
              text={formatMessage({ id: 'dashboard-mobile.tiles.id-mode' })}
              color={Colors.idMode.color}
              icon={
                <Avatar className={classNames(classes.avatar, classes.idMode)}>
                  <PortraitIcon className={classes.icon} />
                </Avatar>
              }
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LandingDashboard;
