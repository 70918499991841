import { objectFromArray, objectFromArrayWithDup } from '../helpers';
import * as donorsActions from '../actions/donors';
import { sortArrayOfObjects } from '../helpers/sorters';

const initialState = {
  byId: {},
  allIds: [],
  modifiedIds: [],
  modifiedById: {},
  inflight: false,
  lastUpdated: null,
  locations: {
    byId: {},
    allIds: [],
    byDonorId: [],
    staff: {
      byId: {},
      allIds: [],
      byLocationId: [],
    },
  },
  types: {
    inflight: false,
    byId: {},
    allIds: [],
  },
  staff: {
    byId: {},
    allIds: [],
    byDonorId: [],
  },
};

const donorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case donorsActions.REQUEST_DONORS:
      return {
        ...state,
        inflight: true,
      };
    case donorsActions.RECEIVE_DONORS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.donors, r => r.id),
        allIds: action.donors.map(r => r.id),
        lastUpdated: action.receivedAt,
      };
    case donorsActions.RECEIVE_DONOR_POST:
      const { byId, allIds } = addDonor(state.byId, state.allIds, action.donor);

      return {
        ...state,
        inflight: false,
        lastUpdated: action.receivedAt,
        byId,
        allIds,
      };
    case donorsActions.RECEIVE_DONOR_LOCATION_POST:
      const newLocations = addLocation(state.locations, action.location, action.user);

      return {
        ...state,
        inflight: false,
        lastUpdated: action.receivedAt,
        locations: newLocations,
      };
    case donorsActions.REQUEST_DONOR_TYPES:
      return {
        ...state,
        types: {
          ...state.types,
          inflight: true,
        },
      };
    case donorsActions.RECEIVE_DONOR_TYPES:
      const donorTypes = sortArrayOfObjects(action.donorTypes, row => row.name);

      return {
        ...state,
        types: {
          ...state.types,
          inflight: false,
          byId: objectFromArray(donorTypes, dt => dt.id),
          allIds: donorTypes.map(dt => dt.id),
        },
      };
    default:
      return state;
  }
};

const addDonor = (_byId, _allIds, donor) => {
  const byId = Object.assign({}, _byId, { [donor.id]: donor });
  const allIds = _allIds.concat(donor.id);

  return {
    byId,
    allIds,
  };
};

const addLocation = (locations, location, user) => {
  const byId = Object.assign({}, locations.byId, { [location.id]: location });
  const allIds = locations.allIds.concat(location.id);
  const byDonorId = objectFromArrayWithDup(allIds.map(id => byId[id]), l => l.donor_id);
  const editedLocation = locations.byId[location.id];
  let donorsStaff = locations.staff.byId;

  if (editedLocation) {
    // remove edited user from staff
    delete donorsStaff[editedLocation.primary_contact_id];
  }

  // add new user to the staff list and mark him as an admin
  donorsStaff[user.id] = {
    ...user,
    is_admin: 1,
    donor_location_id: location.id,
  };
  donorsStaff = Object.values(donorsStaff);

  return {
    byId,
    allIds,
    byDonorId,
    staff: {
      byId: objectFromArray(donorsStaff, l => l.id),
      allIds: donorsStaff.map(l => l.id),
      byLocationId: objectFromArrayWithDup(donorsStaff, l => l.donor_location_id),
    },
  };
};

export default donorsReducer;
