import React, { useContext } from 'react';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import Notification from './Notification';

const Notifications = () => {
  const notificationsContext = useContext(NotificationsContext);

  if (!notificationsContext) {
    return null;
  }

  return (
    <div id="notifications-container">
      {notificationsContext.notifications.map(notification => (
        <Notification key={notification.id} notification={notification} notificationsContext={notificationsContext} />
      ))}
    </div>
  );
};

export default Notifications;
