import { makeStyles } from '@material-ui/core/styles';

export const usePickupSpecsStyles = makeStyles(theme => ({
  dowTable: {
    width: '100%',
    padding: '2px',
  },
  dayColumn: {
    padding: '2px',
    width: '60px',
  },
  positionColumn: {
    width: '1%',
    whiteSpace: 'nowrap',
    padding: '2px 6px 2px 6px',
  },
  nameColumn: {
    width: 100,
    padding: '2px',
  },
  timeColumn: {
    padding: '2px',
    width: '100px',
  },
  notesColumn: {
    padding: '2px',
    width: '160',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  receiverColumn: {
    width: '200px',
    padding: '2px 6px 2px 6px',
  },
  toolsColumn: {
    width: '10px',
    padding: '2px 6px 2px 6px',
  },
  tableCell: {
    padding: '2px 6px 2px 6px',
  },
  tableRow: {
    height: '36px',
  },
}));
