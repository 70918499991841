import React, {useState} from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Bluebird from 'bluebird';
import { Add, Delete as DeleteIcon } from '@material-ui/icons';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { getMuiTableDataIndex } from '../helpers/getters';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import TableActionButton from './TableActionButton';
import ConfirmationDialog from './ConfirmationDialog';
import routes from '../routes';
import {Button} from "@material-ui/core";

const ReceiverStaffUsersTable = ({
  tableId,
  receiverId,
  receiverLocationStaff,
  showDeleteReceiverLocationStaffButton, 
  onReceiverLocationStaffDelete,
}) => {
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <BaseMUIDataTable
      tableId={tableId}
      title="Staff"
      isLoading={isDeleting}
      data={receiverLocationStaff}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: true,
          },
        },
        {
          name: 'firstname',
          label: 'First Name',
          options: {
            filter: false,
            sort: false,
            display: true,
          },
        },
        {
          name: 'lastname',
          label: 'Last Name',
          options: {
            filter: false,
            sort: false,
            display: true,
          },
        },
        {
          name: 'email',
          label: 'Email',
          options: {
            filter: false,
            sort: false,
            display: true,
          },
        },
        {
          name: 'phone',
          label: 'Phone',
          options: {
            filter: false,
            sort: false,
            display: true,
          },
        },
        {
          name: 'is_admin',
          label: 'Is Admin',
          options: {
            filter: true,
            sort: true,
            display: true,
            customBodyRender: value => (value ? 'Admin' : 'Staff'),
          },
        },
        {
          name: 'completed_registration',
          label: 'Completed Registration',
          options: {
            filter: true,
            sort: true,
            display: true,
            customBodyRender: value => (value !== null ? 'yes' : 'no'),
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
              const staff = receiverLocationStaff[getMuiTableDataIndex(tableMeta)];

              return (
                <TableActionsButtonsWrapper>
                  <TableActionButton
                    title="Edit Receiving Agency Location Staff"
                    onClick={() => history.push(generatePath(routes.userEditNew, { id: staff.id }))}
                  />

                  {showDeleteReceiverLocationStaffButton && (
                    <TableActionButton
                      title="Delete Receiving Agency Location Staff"
                      icon={DeleteIcon}
                      onClick={() => {
                        confirmAlert({
                          title: 'Delete this Receiving Agency location staff?',
                          message: 'Are you sure you want to delete this Receiving Agency location staff?',
                          buttons: [
                            {
                              label: 'No',
                              color: 'primary',
                            },
                            {
                              label: 'Yes',
                              color: 'primary',
                              onClick: () => {
                                setIsDeleting(true);

                                return Bluebird
                                  .try(() => onReceiverLocationStaffDelete(staff))
                                  .then(() => setIsDeleting(false));
                              },
                            },
                          ],
                          customUI: ({ title, message, onClose, buttons }) => (
                            <ConfirmationDialog
                              buttons={buttons}
                              closeDialog={onClose}
                              title={title}
                              message={message}
                            />
                          ),
                        });
                      }}
                    />
                  )}
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        customToolbar: () => (
          <Button
            startIcon={<Add fontSize="small" />}
            type="button"
            variant="contained"
            color="primary"
            size="small"
            onClick={() => history.push(generatePath(routes.receiverStaffAdd, { receiverId: receiverId }))}
          >
            Add New Receiving Agency Staff
          </Button>
        ),
        selectableRows: 'none',
        responsive: 'simple',
      }}
    />
  );
};

export default ReceiverStaffUsersTable;
