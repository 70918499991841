import React from 'react';
import RescuersNumber from '../../RescuersNumber';
import { Typography, Grid } from '@material-ui/core';
import { getFullDayByDayIndex, ordinalDomToString } from '../../../../../../models/donations';

const RescuerNumberSelectorMobile = ({ day, ordinal, pickup, value, onRescuersNumberChange, frequency }) => {
  const generateDayName = () => {
    if (frequency === 0 || (!day && !ordinal)) {
      return null;
    }

    if (frequency === 1) {
      return getFullDayByDayIndex(day);
    }

    return ordinalDomToString(day, ordinal);
  };

  const dayName = generateDayName();
  return (
    <Grid container direction="row" justify={dayName ? 'space-between' : 'flex-end'} alignItems="center">
      {dayName && (
        <Grid>
          <Typography color="textSecondary" gutterBottom>
            {generateDayName()}
          </Typography>
        </Grid>
      )}
      <Grid>
        <RescuersNumber
          inputId={`rescuer-${pickup.day}_${ordinal}`}
          showLabel
          isMobile
          value={value}
          handleClick={onRescuersNumberChange}
        />
      </Grid>
    </Grid>
  );
};

export default RescuerNumberSelectorMobile;
