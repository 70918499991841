import * as authActions from '../actions/auth';
import * as appActions from '../actions/app';

const appInitialState = {
  loggingIn: false,
  accessToken: null,
  idToken: null,
  idTokenPayload: null,
  impersonating: null,
  site: null,
  userRegistration: {
    accessToken: null,
    idToken: null,
    idTokenPayload: null,
    data: null,
  },
  loggedInUser: null,
};

const appReducer = (state = appInitialState, action) => {
  switch (action.type) {
    case authActions.SET_LOGGING_IN:
      return {
        ...state,
        loggingIn: action.loggingIn,
      };
    case authActions.SET_AUTHENTICATED_USER:
      return {
        ...state,
        accessToken: action.accessToken,
        idToken: action.idToken,
        idTokenPayload: action.idTokenPayload,
      };
    case authActions.SET_USER_REGISTRATION:
      return {
        ...state,
        userRegistration: {
          accessToken: action.accessToken,
          idToken: action.idToken,
          idTokenPayload: action.idTokenPayload,
          data: action.data,
        }
      };
    case authActions.SET_USER_REGISTRATION_DATA:
      return {
        ...state,
        userRegistration: {
          ...state.userRegistration,
          ...{
            data: action.data,
          }
        },
      };
    case authActions.SET_IMPERSONATED_USER:
      return {
        ...state,
        impersonating: action.user,
      };
    case appActions.SET_SITE:
      return {
        ...state,
        site: action.site,
      };
    case appActions.SET_SITE_FEATURE_FLAGS:
      return {
        ...state,
        site: {
          ...state.site,
          feature_flags: action.featureFlags || [],
        },
      };
    case authActions.RESET_STATE_TO_DEFAULT:
      return {
        ...state,
        ...appInitialState,
      };
    case authActions.SET_LOGGED_IN_USER:
      console.log( 'app reducer.SET_LOGGED_IN_USER: ', action.user );
      return {
        ...state,
        loggedInUser: action.user,
      };
    default:
      return state;
  }
};

export default appReducer;
