import * as siteGoalsActions from '../actions/siteGoals';
import { objectFromArray } from '../helpers';

export const initialState = {
  inflight: false,
  byId: {},
  allIds: [],
  byRescuerId: {},
  goals: [],
  lastUpdated: null,
};

const siteGoalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case siteGoalsActions.REQUEST_SITE_GOALS:
      return {
        ...state,
        inflight: true,
      };
    case siteGoalsActions.RECEIVE_SITE_GOALS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.goals, (goal) => goal.id),
        allIds: action.goals.map((goal) => goal.id),
        goals: action.goals,
      };
    default:
      return state;
  }
};

export default siteGoalsReducer;
