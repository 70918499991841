import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Typography, Box, Divider } from '@material-ui/core';
import { Room as RoomIcon, Schedule as ScheduleIcon, Language as LanguageIcon } from '@material-ui/icons';
import { Skeleton, Alert } from '@material-ui/lab';
import { formatAddress, formatTime } from '../../../helpers/formatters';
import PagePaper from '../../../components/Common/PagePaper';
import EventDate from './EventDate';
import { Colors } from '../../../assets/theme/Colors';
import EventShift from './EventShift';
import useActiveSite from '../../../hooks/useActiveSite';
import { fetchSiteEventIfNeeded } from '../../../actions/sites';

const handleAddressClick = (address) => window.open(`https://maps.google.com/?q=${address}`, '_blank');

const EventDetails = ({ eventData, onShiftButtonClick, onAdoptClick, isLoading, submittingShift }) => {
  const activeSite = useActiveSite();
  const dispatch = useDispatch();
  const eventSpecsEntities = useSelector((state) => state.entities.sites.events).bySiteId[[activeSite.id]];
  const eventSpecs = eventSpecsEntities ? eventSpecsEntities.filter((e) => e.id === eventData?.event_spec_id)[0] : null;
  const eventDate = isLoading || !eventData ? null : moment(eventData.date?.value, eventData.date?.format);
  const eventAddress = isLoading ? <Skeleton width={250} /> : formatAddress(eventData?.location);
  const eventTime = isLoading ? null : `${formatTime(eventData.begin?.value)} - ${formatTime(eventData.end?.value)}`;
  const isEventFromPast = moment(eventDate).isBefore(moment().startOf('day'));

  useEffect(() => {
    if (eventData?.event_spec_id) {
      dispatch(fetchSiteEventIfNeeded(activeSite.id, eventData.event_spec_id));
    }
  }, [eventData]);

  return (
    <>
      <PagePaper style={{ marginBottom: 8 }}>
        {!isLoading && isEventFromPast && (
          <Alert severity="info">This event has ended! Therefore you are not able to join or leave it!</Alert>
        )}
        <Box display="grid" gridTemplateColumns="0.2fr 1fr">
          <EventDate date={eventDate} gridColumn={1} p={2} />
          <Box gridColumn={2} p={2}>
            <Typography variant="h5" paragraph data-testid="event-details-name">
              {isLoading ? <Skeleton /> : eventData.name}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box display="grid" gridRowGap={16} p={2}>
          <Box display="flex" alignItems="center">
            <ScheduleIcon style={{ color: Colors.appBar.main, marginRight: 10 }} />
            <Box display="flex" flexDirection="column">
              <Typography variant="body1" data-testid="event-details-date">
                {isLoading ? <Skeleton width={270} /> : `${eventDate.format('dddd, MMMM DD, YYYY')}`}
              </Typography>
              <Typography variant="body1" data-testid="event-details-time">{isLoading ? <Skeleton width={70} /> : eventTime}</Typography>
            </Box>
          </Box>
          {eventData && eventData.digital && (
            <Box display="flex" alignItems="center">
              <LanguageIcon style={{ color: Colors.appBar.main, marginRight: 10 }} />
              <Typography variant="body1" data-testid="event-digital">
                {isLoading ? <Skeleton width={270} /> : 'Virtual Event'}
              </Typography>
            </Box>
          )}
          {eventData && !eventData.digital && eventAddress && (
            <Box
              onClick={() => handleAddressClick(eventAddress)}
              css={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <RoomIcon style={{ color: Colors.appBar.main, marginRight: 10 }} />
              <Typography variant="body1" data-testid="event-details-address">{eventAddress}</Typography>
            </Box>
          )}
        </Box>
      </PagePaper>

      {isLoading ||
        (eventData.description && (
          <PagePaper style={{ marginBottom: 8 }} data-testid="event-details-description">
            <Box p={2}>
              <Typography variant="subtitle1">Event Details</Typography>
              <Typography variant="body2" data-testid="event-details-description-text">{isLoading ? <Skeleton /> : eventData.description}</Typography>
            </Box>
          </PagePaper>
        ))}

      <PagePaper data-testid="event-details-shifts">
        <Box p={2}>
          <Typography variant="subtitle1" gutterBottom>
            Shifts
          </Typography>

          <Box display="grid" gridRowGap={16}>
            {isLoading && [1, 2, 3].map((i) => <EventShift index={i} key={i} isLoading />)}
            {!isLoading &&
              eventData.shifts
                .filter((shift) => shift.deleted_at === null)
                .map((shift, index) => (
                  <EventShift
                    key={shift.id}
                    index={index}
                    isSubmitting={submittingShift === shift.id}
                    disabled={!!submittingShift || isEventFromPast}
                    isLoading={isLoading}
                    handleShiftButtonClick={onShiftButtonClick}
                    handeShiftAdoptClick={onAdoptClick}
                    shift={shift}
                    eventData={eventData}
                    eventSpecs={eventSpecs}
                  />
                ))}
          </Box>
        </Box>
      </PagePaper>
    </>
  );
};

export default EventDetails;
