import React, { useState } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, Typography, Box } from '@material-ui/core';
import * as usersApi from '../api/users';
import useNotificationService from '../hooks/useNotificationService';
import routes from '../routes';
import { hasRoleInCurrentlySelectedSite } from '../services/auth';
import { Roles } from '../models/roles';

const PrivacyPolicyReagreement = ({ user }) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const { addErrorNotification } = useNotificationService();
  const location = useLocation();
  const isDonor = hasRoleInCurrentlySelectedSite(user, Roles.DonorLocationAdmin);

  const handleAccept = async (e) => {
    if (e.target.checked) {
      try {
        const response = await usersApi.updateUser(user.id, {
          tos_accepted: true,
        });
        const data = await response.json();
        if (!response.ok) {
          return addErrorNotification({}, data.message);
        }
        if (data.data.tos_reagreement_needed) {
          return addErrorNotification({}, 'Terms of service reagreement has not been updated for user');
        }
        return setDialogOpen(false);
      } catch (error) {
        return addErrorNotification(error, error.message);
      }
    }
    return null;
  };

  if (location.pathname === generatePath(routes.termsOfService, { type: isDonor ? 'food-donor' : 'rescuer' })) {
    return null;
  }

  return (
    <Dialog
      open={dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>Terms of Service</DialogTitle>
      <DialogContent>
        <Typography variant="body1">We’ve updated our terms of service and our privacy policy! Please review and accept below:</Typography>
        <Box mt={1}>
          <FormControlLabel
            label={
              <>
                I agree to the&nbsp;
                <Link
                  target="_blank"
                  to={generatePath(routes.termsOfService, { type: isDonor ? 'food-donor' : 'rescuer' })}
                >
                  Terms of Service and Privacy Policy
                </Link>
                .
              </>
            }
            required
            control={<Checkbox onChange={handleAccept} />}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default PrivacyPolicyReagreement;
