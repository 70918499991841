import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useActiveUser from './useActiveUser';
import { setUIFilter } from '../actions/ui';

export default function useUIFilter() {
  const activeUser = useActiveUser();
  const dispatch = useDispatch();
  const uiFilters = useSelector(state => state.ui.filters[activeUser.id]);

  const get = useCallback(
    filterId => {
      if (uiFilters) {
        return uiFilters[filterId];
      }
      return undefined;
    },
    [uiFilters, activeUser.id]
  );

  const set = useCallback(
    (filterId, filterData) => {
      dispatch(setUIFilter(activeUser.id, filterId, filterData));
    },
    [dispatch, activeUser.id]
  );

  return {
    getUIFilter: get,
    setUIFilter: set,
  };
}
