import Bluebird from 'bluebird';
import * as leaderboardsApi from '../api/leaderboards';

export const REQUEST_LEADERBOARD = 'REQUEST_LEADERBOARD';
export const RECEIVE_LEADERBOARD = 'RECEIVE_LEADERBOARD';

export const requestLeaderboard = () => ({
  type: REQUEST_LEADERBOARD,
});

export const receiveLeaderboard = data => ({
  type: RECEIVE_LEADERBOARD,
  data: data,
  receivedAt: Date.now(),
});


export const fetchLeaderboard = (dateRange, sitesIds = [], foodDonorsIds = []) => dispatch => {
  dispatch(requestLeaderboard());

  return Bluebird
    .try(() => leaderboardsApi.getLeaderboards(dateRange, sitesIds, foodDonorsIds))
    .then(res => res.json())
    .then(json => dispatch(receiveLeaderboard(json.data)));
};


export const fetchLeaderboardIfNeeded = (
  dateRange,
  sitesIds = [],
  foodDonorsIds = [],
) => dispatch => dispatch(
  fetchLeaderboard(dateRange, sitesIds, foodDonorsIds)
);
