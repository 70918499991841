import Bluebird from 'bluebird';
import * as settingsApi from '../api/settings';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';

export const requestSettings = () => ({
  type: REQUEST_SETTINGS,
});

export const receiveSettings = settings => ({
  type: RECEIVE_SETTINGS,
  settings,
  receivedAt: Date.now(),
});

export const fetchSettings = () => dispatch => {
  dispatch(requestSettings());

  return Bluebird
    .try(() => settingsApi.getSettings())
    .then(res => res.json())
    .then(json => dispatch(receiveSettings(json.data)));
};

const shouldFetchSettings = (state) => {
  const { settings } = state.entities;

  if (settings.allIds.length === 0) {
    return true;
  }

  if (settings.inflight) {
    return false;
  }

  return shouldFetch(settings.lastUpdated, { minutes: 5 });
};

export const fetchSettingsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchSettings(getState())) {
    return dispatch(fetchSettings());
  }

  return null;
};

export const updateSettings = settings => dispatch => Bluebird
  .try(() => settingsApi.updateSettings(settings))
  .then(res => res.json())
  .then(json => dispatch(receiveSettings(json.data)));
