import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import RescuerScheduleFiltersContainer from '../../../../components/RescuersScheduleFilters/RescuerScheduleFiltersContainer';
import RescueMobileCard from '../../../Rescue/RescueMobileCard';
import { ButtonsWrapper } from '../../../../components/Common/ButtonsWrapper';
import {
  filterRescuesList,
  getScheduleAvailableRescues,
  showAdoptRescueButton,
  showClaimRescueButton,
} from '../../../../helpers/RescuesHelper';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import { Box, makeStyles, Typography } from '@material-ui/core';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import useRescuerRescueActions from '../../../../hooks/useRescuerRescueActions';
import useActiveUser from '../../../../hooks/useActiveUser';
import { Virtuoso } from 'react-virtuoso';
import routes from '../../../../routes';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import { PanTool as PanToolIcon, Favorite as FavoriteIcon } from '@material-ui/icons';
import { RESCUE_BTN_ADOPTED } from '../../../rescueAdoptConfirmation/RescueAdoptConfirmation';
import { NoRescueCard, RescuesLoader } from '../../RescuerDashboardContainer';
import useUIFilter from '../../../../hooks/useUIFilter';
import { initialRescuerScheduleFilters } from '../../../../reducers/ui';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
    padding: spacing(1, 1, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'grid',
    gridRowGap: 16,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    ...typography['h5'],
    padding: spacing(1),
  },
  listSubItem: {
    padding: spacing(1),
  },
  ul: {
    marginBottom: spacing(2),
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

export const RESCUER_SCHEDULE_FILTER = 'rescuer-schedule-mobile'
const RescuerScheduleMobile = ({ history }) => {
  const classes = useStyles();
  let rescuesInflight = {};
  const location = useLocation();

  const activeUser = useActiveUser();
  const rescuesEntities = useSelector(state => state.entities.rescues);
  const { getUIFilter } = useUIFilter();
  const scheduleFilters = getUIFilter(RESCUER_SCHEDULE_FILTER) || initialRescuerScheduleFilters;
  const { fetchScheduleTab } = useRescuerDashboardActions();
  const { rescueClaim, rescueUnadopt } = useRescuerRescueActions();

  const rescuerAvailableRescues = getScheduleAvailableRescues(activeUser.id, rescuesEntities);
  const allRescues = rescuerAvailableRescues.data.map(id => rescuesEntities.byId[id]);
  const availableRescues = filterRescuesList(allRescues, scheduleFilters);
  const availableRescuesCount = availableRescues.length;
  const isInflight = rescuesEntities.inflight || rescuerAvailableRescues.inflight || rescuerAvailableRescues.lastUpdated === null;
  const rescuesCount = allRescues.length;

  const onAdoptClick = (rescue) => history.push(generatePath(routes.rescueAdoptConfirmation, { rescueId: rescue.id }));

  useEffect(() => {
    fetchScheduleTab(true, true);
  }, [activeUser.id, fetchScheduleTab]);

  useEffect(() => {
    if (
      location.state &&
      location.state.adoptButton &&
      matchPath(location.state.prevPath, {
        path: routes.rescueAdoptConfirmation,
        exact: true,
      })
    ) {
      const { state } = location;
      history.replace(location.pathname, null); //remove rescueAdoptConfirmation

      if (state.adoptButton === RESCUE_BTN_ADOPTED) {
        const toastButton = {
          color: 'primary',
          variant: 'contained',
          label: 'Undo',
          onClick: () =>
            rescueUnadopt(
              {
                id: state.rescueId,
                pickup_spec_id: state.pickupSpecId,
              },
              true
            ),
        };

        return snackbarHelper.success('Rescue adopted', true, toastButton);
      }
    }
  }, []);

  return (
    <Box className={classes.container}>
      <RescuerScheduleFiltersContainer
        isMobileView
        filteredRows={availableRescues}
        availableRescues={allRescues}
      />

      {!isInflight && availableRescuesCount > 0 && (
        <Typography variant="caption" color="textSecondary" gutterBottom>
          Showing available rescues for the next four weeks.
        </Typography>
      )}

      {!isInflight && availableRescuesCount === 0 && rescuesCount === 0 && (
        <NoRescueCard text="No available rescues." />
      )}

      {!isInflight && availableRescuesCount === 0 && rescuesCount > 0 && (
        <NoRescueCard text="There are no available rescues that match the current filter criteria." />
      )}

      <Box display="flex" flex={1} flexDirection="column">
        {isInflight && <RescuesLoader />}

        <Virtuoso
          style={{ width: '100%', height: '100%' }}
          totalCount={availableRescuesCount}
          itemContent={(index) => {
            const top = index === 0 ? 0 : 1;
            const bottom = index === availableRescuesCount ? 0 : 1;
            const rescue = availableRescues[index];
            return (
              <Box pt={top} pb={bottom}>
                <RescueMobileCard key={rescue.id} rescue={rescue} isCancelled={rescue.cancelled_by_id !== null} my={2}>
                  <ButtonsWrapper>
                    {showClaimRescueButton(rescue) && (
                      <ButtonWithLoading
                        startIcon={<PanToolIcon />}
                        isLoading={rescuesInflight[rescue.id] && rescuesInflight[rescue.id] === 'claim'}
                        disabled={!!rescuesInflight[rescue.id]}
                        variant="contained"
                        size="small"
                        onClick={() => rescueClaim(rescue, true)}
                      >
                        I'll do it
                      </ButtonWithLoading>
                    )}
                    {showAdoptRescueButton(rescue) && (
                      <ButtonWithLoading
                        startIcon={<FavoriteIcon />}
                        isLoading={rescuesInflight[rescue.id] && rescuesInflight[rescue.id] === 'adopt'}
                        disabled={!!rescuesInflight[rescue.id]}
                        variant="contained"
                        size="small"
                        onClick={() => onAdoptClick(rescue)}
                      >
                        Adopt
                      </ButtonWithLoading>
                    )}
                  </ButtonsWrapper>
                </RescueMobileCard>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default RescuerScheduleMobile;
