import React, { Component } from 'react';
import { components } from 'react-select';
import Async from 'react-select/async';
import classNames from 'classnames';
import { withStyles, Paper, Typography, Chip, MenuItem, FormHelperText } from '@material-ui/core';
import { emphasize } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { Colors } from '../assets/theme/Colors';
import { getFormFieldError, isFormFieldInvalid } from '../helpers/validators';

class SelectMultiple extends Component {
  input(props) {
    const newProps = {
      ...props,
      autoComplete: 'chrome-off',
      name: props.selectProps.name,
    };

    return <components.Input {...newProps} aria-invalid={props.selectProps.error} />;
  }

  option(props) {
    return (
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  menu(props) {
    return (
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }

  multiValue(props) {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    );
  }

  placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
      <Typography
        color="textSecondary"
        className={selectProps.classes.placeholder}
        {...innerProps}
        data-testid="select-multiple-placeholder"
      >
        {children}
      </Typography>
    );
  }

  singleValue(props) {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps} data-testid="single-value">
        {props.children}
      </Typography>
    );
  }

  valueContainer(props) {
    console.log( props )
    return <components.ValueContainer />
    // return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }

  noOptionsMessage(props) {
    return (
      <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  render() {
    const {
      value,
      options,
      loadingMessage,
      loadOptions,
      onChange,
      classes,
      selectPlaceholder,
      isMulti = true,
      onMenuOpen,
      onMenuClose,
      onBlur,
      meta,
      inputId = 'react-select-multiple',
      name,
      isDisabled = false,
    } = this.props;

    const showError = isFormFieldInvalid(meta);
    return (
      <>
        <Async
          inputId={inputId}
          name={name}
          isDisabled={isDisabled}
          styles={{
            control: base => ({
              ...base,
              borderColor: showError ? Colors.errorText : base.borderColor,
            }),
          }}
          error={showError}
          loadingMessage={loadingMessage}
          loadOptions={loadOptions}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          classes={classes}
          onBlur={onBlur}
          placeholder={selectPlaceholder}
          options={options}
          components={{
            Input: this.input,
            Menu: this.menu,
            MultiValue: this.multiValue,
            NoOptionsMessage: this.noOptionsMessage,
            Option: this.option,
            Placeholder: this.placeholder,
            SingleValue: this.singleValue,
          }}
          value={value}
          onChange={onChange}
          isMulti={isMulti}
          isClearable
        />
        {showError && <FormHelperText error>{getFormFieldError(meta)}</FormHelperText>}
      </>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    paddingLeft: theme.spacing(1),
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default withStyles(styles)(SelectMultiple);
