import React, { lazy, useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import * as sitesActions from '../actions/sites';
import * as rescuedFoodActions from '../actions/rescuedFood';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import useUIFilter from '../hooks/useUIFilter';
import { dateRangeOptionsList } from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import { Colors } from '../assets/theme/Colors';
import { assign, get } from 'lodash';

const RescuedFoodReportTable = lazy(() => import('../components/RescuedFoodReportTable'));
const RescuedFoodReportForm = lazy(() => import('../components/RescuedFoodReportForm'));

export const RESCUED_FOOD_REPORT_UI_FILTER = 'report-rescued_food';
const RescuedFoodReportView = () => {
  const dispatch = useDispatch();
  const [filtersExpanded, setFiltersExpanded] = useState(true);
  const sites = useSelector(state => state.entities.sites);
  const rescuedFood = useSelector(state => state.entities.rescuedFood);
  const activeUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const { setUIFilter, getUIFilter } = useUIFilter();
  const filters = assign(
    {
      period: dateRangeOptionsList.thisMTD.value,
      dateRange: null,
      site: null,
      showRescueSizeLegend: true,
    },
    getUIFilter(RESCUED_FOOD_REPORT_UI_FILTER)
  );

  const sitesWithSDSCAccess = authService.getUserSitesByRoles(
    activeUser.role_assignments,
    [
      Roles.SiteDirector,
      Roles.SiteCoordinator,
    ]
  );
  const isAdmin = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);
  const sitesList = isAdmin
    ? Object.values(sites.byId)
    : Object.values(sites.byId).filter(site => sitesWithSDSCAccess.includes(site.id));

  useEffect(() => {
    dispatch(sitesActions.fetchSitesIfNeeded());
  }, []);

  const handleFiltersChange = values => {
    return setUIFilter(RESCUED_FOOD_REPORT_UI_FILTER, {
      site: values.site,
      period: values.period,
      showRescueSizeLegend: values.showRescueSizeLegend,
      dateRange: {
        startDate: values.startDate,
        endDate: values.endDate,
      },
    });
  };

  const getRescuedFoodReportData = rescuedFood => {
    if (isAdmin) {
      return rescuedFood;
    }

    return rescuedFood.filter(rf => sitesWithSDSCAccess.includes(rf.site_id));
  };

  const handleChange = (event, isExpanded) => setFiltersExpanded(isExpanded);

  const fetchReport = () => {
    if (
      filters.dateRange
      && filters.dateRange.startDate !== null
      && filters.dateRange.endDate !== null
      && sitesList.length
    ) {
      // if user selected sites, use it and submit
      // otherwise - check if user is an admin -> then send an empty array to receive ALL sites
      // if user is not an admin, send an array with the sites ids in which user is SD/SC
      const sitesIds = (() => {
        if (Array.isArray(filters.site)) {
          return filters.site.map(s => s.id);
        }

        if (isAdmin) {
          return [];
        }

        return sitesList.map(s => s.id);
      })();

      dispatch(
        rescuedFoodActions.fetchRescuedFoodIfNeeded(
          sitesIds,
          filters.dateRange.startDate,
          filters.dateRange.endDate
        )
      );
    }
  };

  return (
    <Suspense fallback={null}>
      <Grid container>
        <Grid item xs={12}>
          <Accordion expanded={filtersExpanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" color="textPrimary">
                Rescued Food Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RescuedFoodReportForm
                isLoading={sites.inflight || rescuedFood.inflight}
                defaultValues={filters}
                isAdmin={isAdmin}
                sitesList={sitesList}
                onSubmit={handleFiltersChange}
                fetchReport={fetchReport}
              />
            </AccordionDetails>
          </Accordion>

          <Box mt={2}>
            <RescuedFoodReportTable
              tableId="#reports/rescued-food"
              isLoading={sites.inflight || rescuedFood.inflight}
              rescuedFood={getRescuedFoodReportData(rescuedFood.all)}
              showSiteColumn={sitesList.length > 1 && !filters.site}
              initialLoad={!rescuedFood.lastUpdated}
            />
          </Box>
        </Grid>
      </Grid>
    </Suspense>
  );
};

export default RescuedFoodReportView;
