import React from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import { BaseCard } from './Dashboard/DashboardTableCard';
import OverlayLoader from './OverlayLoader';

const EnviroImpactCard = ({
  title,
  value,
  isLoading,
  cardStyle,
  children,
  iconSize = 72,
  valueFontVariant = 'h5',
  textStyle = '',
  textVariant = 'body2',
}) => (
  <OverlayLoader wrapperStyles={{ height: '100%' }} isLoading={isLoading}>
    <BaseCard className={cardStyle}>
      <CardContent>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box fontSize={iconSize}>{children}</Box>
          <Typography style={{ overflowWrap: 'anywhere' }} align="center" variant={valueFontVariant}>
            {value}
          </Typography>
          <Typography align="center" variant={textVariant} color="textSecondary" className={textStyle}>
            {title}
          </Typography>
        </Box>
      </CardContent>
    </BaseCard>
  </OverlayLoader>
);

export default EnviroImpactCard;
