import Bluebird from 'bluebird';
import * as eventsApi from '../api/events';
import * as eventsModel from '../models/events';
import * as sitesApi from '../api/sites';
import { receiveSiteEvent } from './sites';

export const REQUEST_EVENT = 'REQUEST_EVENT';
export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const RECEIVE_EVENT = 'RECEIVE_EVENT';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const SET_EVENT_REGISTRATION = 'SET_EVENT_REGISTRATION';
export const DELETE_EVENT_REGISTRATION = 'DELETE_EVENT_REGISTRATION';

export const receiveEventShift = (eventId, eventShiftId, eventShift) => ({
  type: SET_EVENT_REGISTRATION,
  eventId,
  eventShiftId,
  eventShift,
});

export const receiveDeleteEventShiftRegistration = (eventId, eventShiftId, eventShiftRegistrationId) => ({
  type: DELETE_EVENT_REGISTRATION,
  eventId,
  eventShiftId,
  eventShiftRegistrationId,
});

export const requestEvent = () => ({
  type: REQUEST_EVENT,
});

export const requestEvents = () => ({
  type: REQUEST_EVENTS,
});

export const receiveEvent = event => {
  return {
    type: RECEIVE_EVENT,
    event: event,
    receivedAt: Date.now(),
  };
};
export const receiveEvents = events => {
  return {
    type: RECEIVE_EVENTS,
    events: events,
    receivedAt: Date.now(),
  };
};

export const createEvent = (siteId, eventData) => dispatch =>
  Bluebird.try(() => sitesApi.createSiteEvent(siteId, eventsModel.mapFormToCreateEventPOST(eventData)))
    .then(res => res.json())
    .then(json => {
      dispatch(receiveSiteEvent(json.data));
      dispatch(receiveEvent(json.data));
      return json.data;
    });

export const updateEvent = (siteId, eventId, eventData) => dispatch => Bluebird
    .try(() => sitesApi.updateSiteEvent(siteId, eventId, eventsModel.mapFormToEditEventPUT(eventData)))
    .then(res => res.json())
    .then(json => {
      dispatch(receiveSiteEvent(json.data));
      dispatch(receiveEvent(json.data));
      return json.data;
    });

export const fetchEvent = (eventId, quietMode = false) => dispatch => {
  if (!quietMode) {
    dispatch(requestEvent());
  }

  return Bluebird.try(() => eventsApi.getEvent(eventId))
    .then((res) => res.json())
    .then((json) => dispatch(receiveEvent(json.data)));
};

function shouldFetchEvents(state) {
  const events = state.entities.events;
  if (events.allIds.length === 0 || !events.lastUpdated) {
    return true;
  } else if (events.inflight) {
    return false;
  }

  return !events.lastUpdated || events.lastUpdated + 0.5 * 60 * 60 * 1000 < Date.now(); // older than 0.5h
}

export const fetchEvents = (quietMode = false, params = {}) => dispatch => {
  if (!quietMode) {
    dispatch(requestEvents());
  }

  return Bluebird.try(() => eventsApi.getEvents(params))
    .then(res => res.json())
    .then(json => dispatch(receiveEvents(json.data)));
};

export const fetchEventsIfNeeded = (quietMode = false, params = {}) => (dispatch, getState) => {
  if (shouldFetchEvents(getState())) {
    return dispatch(fetchEvents(quietMode, params));
  }

  return null;
};
