import React, { useState } from 'react';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import Bluebird from 'bluebird';
import classNames from 'classnames';
import queryString from 'query-string';
import { Grid, Box, Container, Typography, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../../routes';
import * as authService from '../../services/auth';
import * as authApi from '../../api/auth';
import snackbarHelper from '../../helpers/snackbarHelper';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import { useFormStyles } from './authStyles';
import { getPasswordsValidationErrors } from '../../helpers/validators';
import loginLogo from '../../assets/images/login-logo.svg';
import { Colors } from '../../assets/theme/Colors';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import SocialMediaLinkIcons from '../../components/SocialMediaLinkIcons';

const useStyles = makeStyles(({ typography, spacing }) => ({
  password1: {
    marginBottom: 0,
  },
  headerText: {
    ...typography['h5'],
    color: `${Colors.white} !important`,
    textAlign: 'center',
    fontWeight: 600,
  },
  socialMediaLinks: {
    paddingTop: spacing(2),
  },
}));

const ResetPasswordPage = () => {
  const classes = useStyles();
  const authStyles = useFormStyles();
  const history = useHistory();
  const location = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  // redirect to index if user is already logged in
  if (authService.getCurrentlyLoggedInUser()) {
    history.push(routes.index);
  }

  const parsedSearch = queryString.parse(location.search);
  const { token } = parsedSearch;

  const validateForm = values => getPasswordsValidationErrors(values, 'password1', 'password2');

  const onSubmit = values => {
    const { password1 } = values;

    setSubmitting(true);

    return Bluebird.try(() => authApi.resetPassword(token, password1))
      .then(res => res.json())
      .then(() => {
        setSubmitting(false);
        setShowDialog(true);
      })
      .catch(err => {
        setSubmitting(false);

        let errorMessage = err.message;

        if (err.code === 'ERR_INVALID_TOKEN') {
          errorMessage = 'Invalid token. Please use a valid one.';
        }

        if (err.code === 'ERR_TOKEN_ALREADY_USED') {
          errorMessage = 'This token has been already used.';
        }

        if (err.code === 'ERR_TOKEN_EXPIRED') {
          errorMessage = 'Token expired.';
        }

        if (err.code === 'ERR_USER_NOT_FOUND') {
          errorMessage = 'User not found.';
        }

        snackbarHelper.error(errorMessage);
      });
  };

  return (
    <Box className={authStyles.pageBackground}>
      <Container maxWidth="md">
        {showDialog && (
          <ConfirmationDialog
            message="Your new password has been set. You can now login using your new password."
            buttons={[{ label: 'Got it', onClick: () => history.push(routes.login) }]}
            closeDialog={() => setShowDialog(false)}
          />
        )}

        <Grid container spacing={2} className={authStyles.pageContainer} data-testid="welcome-index-page">
          <Grid item xs className={authStyles.logoWrapper}>
            <img src={loginLogo} alt="logo" className={authStyles.logo} />
          </Grid>
          <Grid item xs className={authStyles.loginFormWrapper}>
            <Typography className={classes.headerText} data-testid="signup-header">
              Password recovery
            </Typography>

            <Form
              onSubmit={onSubmit}
              validate={validateForm}
              render={({ handleSubmit, submitting, pristine, invalid, dirtySinceLastSubmit, form, values }) => (
                <form className={authStyles.form} onSubmit={handleSubmit}>
                  <Field
                    className={classNames(classes.password1, authStyles.input)}
                    margin="normal"
                    name="password1"
                    component={TextField}
                    type="password"
                    variant="outlined"
                    fullWidth
                    placeholder="Password"
                    autoComplete="new-password"
                    data-testid="register-password-input"
                  />

                  <FormHelperText className={authStyles.helpText}>
                    At least 8 characters including a number and an uppercase letter.
                  </FormHelperText>

                  <Field
                    className={authStyles.input}
                    margin="normal"
                    name="password2"
                    component={TextField}
                    type="password"
                    variant="outlined"
                    fullWidth
                    placeholder="Repeat password"
                    autoComplete="new-password"
                    data-testid="register-password-confirmation-input"
                  />

                  <ButtonWithLoading
                    className={authStyles.submitButton}
                    fullWidth
                    type="submit"
                    isLoading={submitting || isSubmitting}
                    disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                  >
                    Reset Password
                  </ButtonWithLoading>
                </form>
              )}
            />

            <Grid container direction="row" justify="center" alignItems="center" className={classes.socialMediaLinks}>
              <SocialMediaLinkIcons iconColor="#fff" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ResetPasswordPage;
