import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import UserForm from '../components/UserForm';
import * as foodDonorsActions from '../actions/foodDonors';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import routes from '../routes';

const DonorLocationStaffAddView = ({ match }) => {
  const foodDonorId = +match.params.foodDonorId;
  const dispatch = useDispatch();
  const foodDonorsEntities = useSelector(state => state.entities.foodDonors);

  useEffect(() => {
    dispatch(foodDonorsActions.fetchFoodDonor(foodDonorId));
  }, [dispatch, foodDonorId]);

  const submitCallback = data => dispatch(foodDonorsActions.createFoodDonorStuff(foodDonorId, data));

  const donorLocationPath = generatePath(routes.foodDonor, { foodDonorId: foodDonorId, tab: 'staff' });

  if (!foodDonorsEntities.byId[foodDonorId] || foodDonorsEntities.inflight) {
    return 'Loading food donor data...';
  }

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={routes.foodDonors}>
          Food Donors
        </Link>

        <Link color="inherit" to={donorLocationPath}>
          {foodDonorsEntities.byId[foodDonorId].name}
        </Link>

        <Typography color="textPrimary">Add a Food Donor Staff</Typography>
      </Breadcrumbs>

      <UserForm
        showUserSelector
        redirectPath={donorLocationPath}
        submitCallback={submitCallback}
        showIsAdminCheckbox={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
          Roles.Admin,
          Roles.NationalSiteDirector,
          Roles.SiteDirector,
          Roles.DonorAdmin,
          Roles.DonorLocationAdmin,
        ])}
        isAdminCheckboxLabel="Is Food Donor Admin"
        showLocationFields={false}
      />
    </>
  );
};

export default DonorLocationStaffAddView;
