import queryString from 'query-string';
import { apiPOST } from './api';

const url = `https://graph.facebook.com/v16.0`;

export const postCrudAction = (action, details = {}) =>
  apiPOST('/crud-actions', { action_type: action, details: details });

export const getUserProfileInfo = (userId, accessToken) => fetch(`${url}/${userId}?access_token=${accessToken}`);

export const getProfilePicture = (userId, accessToken) =>
  fetch(`${url}/${userId}/picture?redirect=0&type=normal&access_token=${accessToken}`);

export const getUserPages = (userId, accessToken) =>
  fetch(`${url}/${userId}/accounts?fields=access_token,name,id,link,picture&access_token=${accessToken}`);

export const getInstagramBussinessAccount = (pageId, accessToken) =>
  fetch(`${url}/${pageId}?fields=instagram_business_account{profile_picture_url,username}&access_token=${accessToken}`);

export const publishPagePost = (pageId, accessToken, data) => {
  const query = queryString.stringify(
    {
      published: data.published,
      scheduled_publish_time: data.scheduled_publish_time,
      access_token: accessToken,
      message: data.message,
    },
    {
      skipNull: true,
      encode: false,
    }
  );

  return fetch(`${url}/${pageId}/feed?${query}`, {
    method: 'POST',
  });
};

export const publishPagePostWithImage = (pageId, accessToken, data) => {
  const query = queryString.stringify(
    {
      published: data.published,
      scheduled_publish_time: data.scheduled_publish_time,
      access_token: accessToken,
      url: data.url,
      message: data.message,
    },
    {
      skipNull: true,
      encode: false,
    }
  );

  return fetch(`${url}/${pageId}/photos?${query}`, {
    method: 'POST',
  });
};

export const postUnpublishedImage = (pageId, accessToken, data) =>
  fetch(`${url}/${pageId}/photos?&url=${data.url}&published=false&access_token=${accessToken}`, {
    method: 'POST',
  });

export const publishPagePostWithMultipleImages = (pageId, accessToken, data) => {
  const imagesId = data.images.map((image, index) => ({
    [`attached_media[${index}]`]: JSON.stringify({ media_fbid: image.id }),
  }));

  const imagesIdObject = Object.assign({}, ...imagesId);
  const body = queryString.stringify(
    {
      published: data.published,
      access_token: accessToken,
      scheduled_publish_time: data.scheduled_publish_time,
      ...imagesIdObject,
      message: data.message,
    },
    {
      skipNull: true,
      encode: false,
    }
  );

  return fetch(`${url}/${pageId}/feed`, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const createInstagramContainer = (instagramId, accessToken, data) =>
  fetch(`${url}/${instagramId}/media?image_url=${data.url}&caption=${data.caption}&access_token=${accessToken}`, {
    method: 'POST',
  });

export const postInstagramSingleImage = (instagramId, accessToken, containerId) => 
  fetch(`${url}/${instagramId}/media_publish?creation_id=${containerId}&access_token=${accessToken}`, {
    method: 'POST',
  });

export const createInstagramMultipleImageContainer = (instagramId, accessToken, data) =>
  fetch(`${url}/${instagramId}/media?image_url=${data.url}&is_carousel_item=true&access_token=${accessToken}`, {
    method: 'POST',
  });

export const createInstagramCarouselContainer = (instagramId, accessToken, data) =>
  fetch(
    `${url}/${instagramId}/media?caption=${data.caption}&media_type=CAROUSEL&children=${data.imagesId}&access_token=${accessToken}`,
    {
      method: 'POST',
    }
  );

export const postInstagramCarousel = (instagramId, accessToken, containerId) =>
  fetch(`${url}/${instagramId}/media_publish?creation_id=${containerId}&access_token=${accessToken}`, {
    method: 'POST',
  });

export const getFacebookPostsWithComments = (pageId, accessToken) =>
  fetch(
    `${url}/${pageId}/feed?fields=comments{permalink_url,message,from{picture,id,name},created_time,comments{permalink_url,message,from{picture,id,name},created_time}}&access_token=${accessToken}`
  );

export const getInstagramPostsWithComments = (instagramId, accessToken) =>
  fetch(
    `${url}/${instagramId}?fields=media{comments{username,user{profile_picture_url},text,timestamp},permalink}&access_token=${accessToken}`
  );

export const getFacebookMessages = (pageId, accessToken) =>
  fetch(
    `${url}/${pageId}/conversations?fields=name,message_count,snippet,link,updated_time,unread_count,messages{message,from,id}&access_token=${accessToken}`
  );

export const getFacebookMetrics = (pageId, accessToken, since, until) =>
  fetch(
    `${url}/${pageId}/insights?period=day&metric=page_post_engagements,page_posts_impressions,page_views_total,page_daily_follows_unique,post_engaged_users&since=${since}&until=${until}&access_token=${accessToken}`
  );

export const getInstagramMetrics = (accountId, accessToken, from) =>
  fetch(
    `${url}/${accountId}/insights?period=day&metric=follower_count,impressions,profile_views&since=${from}&access_token=${accessToken}`
  );

export const getFacebookPosts = (pageId, accessToken) =>
  fetch(
    `${url}/${pageId}/feed?fields=permalink_url,created_time,message,id,application{id}&access_token=${accessToken}`
  );

export const getFacebookScheduledPosts = (pageId, accessToken) =>
  fetch(
    `${url}/${pageId}/scheduled_posts?fields=permalink_url, created_time, message,id,application{id}&access_token=${accessToken}`
  );

export const getInstagramPosts = (instagramId, accessToken) =>
  fetch(
    `${url}/${instagramId}?fields=media{caption, timestamp, media_type, media_url, thumbnail_url, permalink}&access_token=${accessToken}`
  );

export const deleteFacebookPost = (postId, accessToken) =>
  fetch(`${url}/${postId}?access_token=${accessToken}`, {
    method: 'DELETE',
  });
