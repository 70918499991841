import Bluebird from 'bluebird';
import * as systemSettingsApi from '../api/systemSettings';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_SYSTEM_SETTINGS = 'REQUEST_SYSTEM_SETTINGS';
export const RECEIVE_SYSTEM_SETTINGS = 'RECEIVE_SYSTEM_SETTINGS';

export const requestSystemSettings = () => ({
  type: REQUEST_SYSTEM_SETTINGS,
});

export const receiveSystemSettings = systemSettings => ({
  type: RECEIVE_SYSTEM_SETTINGS,
  systemSettings,
  receivedAt: Date.now(),
});

export const fetchSystemSettings = () => dispatch => {
  dispatch(requestSystemSettings());

  return Bluebird
    .try(() => systemSettingsApi.getSystemSettings())
    .then(res => res.json())
    .then(json => dispatch(receiveSystemSettings(json.data)));
};

function shouldFetchSystemSettings(state) {
  const systemSettings = state.entities.systemSettings;

  if (systemSettings.inflight) {
    return false;
  }

  if (systemSettings.allIds.length === 0) {
    return true;
  }

  return shouldFetch(systemSettings.lastUpdated, { hours: 6 });
}

export const fetchSystemSettingsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchSystemSettings(getState())) {
    return dispatch(fetchSystemSettings());
  }
  return null;
};

export const updateSystemSettings = systemSettings => dispatch => Bluebird
  .try(() => systemSettingsApi.updateSystemSettings(systemSettings))
  .then(res => res.json())
  .then(json => dispatch(receiveSystemSettings(json.data)));
