import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { useIntl } from 'react-intl';

export function DividerLine() {
  const { formatMessage } = useIntl();
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={5}>
        <Divider style={{ backgroundColor: "#fff" }} />
      </Grid>
      <Grid item xs={2} style={{ color: '#fff', textAlign: 'center' }}>
        {formatMessage({ id: 'divider-line.text' })}
      </Grid>
      <Grid item xs={5}>
        <Divider style={{ backgroundColor: "#fff" }} />
      </Grid>
    </Grid>
  );
}
