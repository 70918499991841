import { objectFromArray, objectFromArrayWithDup } from '../helpers';
import * as receiversActions from '../actions/receivers';
import { sortArrayOfObjects } from '../helpers/sorters';

const initialState = {
  inflight: false,
  lastUpdated: null,
  byId: {},
  allIds: [],
  modifiedIds: [],
  modifiedById: {},
  locations: {
    byId: {},
    allIds: [],
    byReceiverId: [],
    staff: {
      byId: {},
      allIds: [],
      byLocationId: [],
    },
  },
  types: {
    byId: {},
    allIds: [],
  },
  needsProfile: {
    byLocationId: {},
  },
};

const receiversReducer = (state = initialState, action) => {
  const addOrUpdate = (r, _byId, _allIds, _modifiedById, _modifiedIds) => {
    const key = r.id;
    const byId = Object.assign({}, _byId, { [key]: r });
    const allIds = Object.keys(byId);
    const modifiedIds = _modifiedIds.slice().filter(id => id !== key);
    const { [key]: dontcare, ...modifiedById } = _modifiedById;
    return {
      byId,
      allIds,
      modifiedIds,
      modifiedById,
    };
  };

  const generateLocations = (locations, staff) => {
    return {
      byId: objectFromArray(locations, l => l.id),
      allIds: locations.map(l => l.id),
      byReceiverId: objectFromArrayWithDup(locations, l => l.receiver_id),
      staff: {
        byId: objectFromArray(staff, l => l.id),
        allIds: locations.map(l => l.id),
        byLocationId: objectFromArrayWithDup(staff, l => l.receiver_location_id),
      },
    };
  };

  switch (action.type) {
    case receiversActions.REQUEST_RECEIVERS:
      return {
        ...state,
        inflight: true,
      };
    case receiversActions.REQUEST_RECEIVER_NEEDS_PROFILE:
      return {
        ...state,
        needsProfile: {
          ...state.needsProfile,
          inflight: true,
        },
      };
    case receiversActions.RECEIVE_RECEIVERS:
      return {
        ...state,
        inflight: false,
        allIds: action.receivers.map(r => r.id),
        byId: objectFromArray(action.receivers, r => r.id),
        locations: generateLocations(action.locations, action.staff),
        lastUpdated: action.receivedAt,
      };
    case receiversActions.RECEIVE_RECEIVER_TYPES:
      const receiveReceiversTypes = sortArrayOfObjects(action.receiverTypes, row => row.name);

      return {
        ...state,
        inflight: false,
        lastUpdated: action.receivedAt,
        types: {
          byId: objectFromArray(receiveReceiversTypes, dt => dt.id),
          allIds: receiveReceiversTypes.map(dt => dt.id),
        },
      };
    case receiversActions.RECEIVE_RECEIVER_POST: {
      return {
        ...state,
        inflight: false,
        lastUpdated: action.receivedAt,
        ...addOrUpdate(action.receiver, state.byId, state.allIds, state.modifiedById, state.modifiedIds),
      };
    }
    case receiversActions.RECEIVE_RECEIVER_LOCATION_POST:
      const editedLocation = state.locations.byId[action.location.id];
      const receiverStaff = state.locations.staff.byId;
      // remove edited user from staff
      delete receiverStaff[editedLocation.primary_contact_id];
      // add new user to the staff list and mark him as an admin
      receiverStaff[action.user.id] = {
        ...action.user,
        is_admin: 1,
        receiver_location_id: action.location.id,
      };

      return {
        ...state,
        inflight: false,
        lastUpdated: action.receivedAt,
        locations: generateLocations(
          Object.values(state.locations.byId).map(location => {
            if (location.id === action.location.id) {
              return action.location;
            }

            return location;
          }),
          Object.values(receiverStaff)
        ),
        ...addOrUpdate(action.receiver, state.byId, state.allIds, state.modifiedById, state.modifiedIds),
      };
    case receiversActions.RECEIVE_RECEIVER_STAFF_DELETE:
      const byLocationId = { ...state.locations.staff.byLocationId };

      byLocationId[action.receiverLocationId] = byLocationId[action.receiverLocationId].filter(
        user => !(user.id === action.userId && user.is_admin === action.isAdmin)
      );

      return {
        ...state,
        locations: {
          ...state.locations,
          staff: {
            ...state.locations.staff,
            byLocationId: byLocationId,
          },
        },
      };
    case receiversActions.RECEIVE_RECEIVER_NEEDS_PROFILE:
      return {
        ...state,
        needsProfile: {
          ...state.needsProfile,
          inflight: false,
          lastUpdated: action.receivedAt,
          byLocationId: {
            ...state.needsProfile.byLocationId,
            [action.receiverLocationId]: [...action.needsProfile],
          },
        },
      };
    default:
      return state;
  }
};

export default receiversReducer;
