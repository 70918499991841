import Bluebird from 'bluebird';
import * as donorsApi from '../api/donors';
import * as donorTypesApi from '../api/donorTypes';

export const REQUEST_DONORS = 'REQUEST_DONORS';
export const REQUEST_DONOR_TYPES = 'REQUEST_DONORS_TYPES';
export const RECEIVE_DONORS = 'RECEIVE_DONORS';
export const RECEIVE_DONOR_TYPES = 'RECEIVE_DONOR_TYPES';
export const RECEIVE_DONOR_POST = 'RECEIVE_DONOR_POST';
export const RECEIVE_DONOR_LOCATION_POST = 'RECEIVE_DONOR_LOCATION_POST';

export const requestDonors = () => ({
  type: REQUEST_DONORS,
});

export const requestDonorTypes = () => ({
  type: REQUEST_DONOR_TYPES,
});

export const receiveDonors = json => ({
  type: RECEIVE_DONORS,
  donors: json.data,
  receivedAt: Date.now(),
});

export const receiveDonorTypes = json => ({
  type: RECEIVE_DONOR_TYPES,
  donorTypes: json.data,
  receivedAt: Date.now(),
});

export const receiveDonorLocationPost = json => ({
  type: RECEIVE_DONOR_LOCATION_POST,
  donor: json.donor,
  location: json.location,
  user: json.user,
  receivedAt: Date.now(),
});

export const fetchDonorTypes = () => dispatch => {
  dispatch(requestDonorTypes());

  return Bluebird
    .try(() => donorTypesApi.getDonorTypes())
    .then(res => res.json())
    .then(json => dispatch(receiveDonorTypes(json)));
};

export const fetchDonors = () => dispatch => {
  dispatch(requestDonors());

  return Bluebird
    .try(() => donorsApi.getDonors())
    .then(res => res.json())
    .then(json => dispatch(receiveDonors(json)));
};

export const fetchDonorsIfNeeded = () => dispatch => dispatch(fetchDonors());
