import React, { useRef, useState, useEffect } from 'react';
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Link, Paper } from '@material-ui/core';
import classNames from 'classnames';
import indigo from '@material-ui/core/colors/indigo';
import get from 'lodash/get';
import { orderBy as lodashOrderBy } from 'lodash';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import TableToCSVGenerator from '../components/TableToCSVGenerator';
import { formatNumber, formatValueOfMeals } from '../helpers/formatters';
import { Colors } from '../assets/theme/Colors';
import { formatDateToMonthName, formatDateToWeekName } from '../helpers/dates';
import { getUserFullName } from '../helpers/user';
import { generatePath, useHistory } from 'react-router-dom';
import routes from '../routes';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
  pivot: {
    '& table': {
      '& thead': {
        '& th': {
          fontWeight: 600,
          padding: '3px 12px',
          whiteSpace: 'nowrap',
        },
      },
      '& tbody': {
        '& td': {
          padding: '3px 12px',
          borderBottom: 'none',
        },
        '& td:first-child': {
          minWidth: 200,
        },
      },
    },
  },
  rowTotal: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  tableTotalRow: {
    fontWeight: 600,
  },
  oddColumn: {
    background: `${indigo[50]} !important`,
  },
  diffOdd: {
    color: `${Colors.tableDiff.odd.contrastText} !important`,
    '-moz-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.odd.color}`,
    '-webkit-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.odd.color}`,
    boxShadow: `inset 0px 0px 1px 100px ${Colors.tableDiff.odd.color}`,
  },
  diffEven: {
    color: `${Colors.tableDiff.even.contrastText} !important`,
    '-moz-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.even.color}`,
    '-webkit-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.even.color}`,
    boxShadow: `inset 0px 0px 1px 100px ${Colors.tableDiff.even.color}`,
  },
  tableContainer: {
    height: 'calc(100vh - 450px)',
    overflow: 'scroll',
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '0.65rem',
      height: '0.65rem',
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
    '@media (max-height: 680px)': {
      height: '50vh',
    },
  },
  descriptionsHeader: {
    borderBottom: 'none',
  },
  borderLeftColumn: {
    borderLeft: `2px solid ${indigo[300]}`,
  },
  periodStickyHeader: {
    top: 0,
    position: 'sticky',
    zIndex: 1,
    background: '#fff',
    borderBottom: 0,
  },
  entityTypeStickyHeader: {
    top: 36,
    position: 'sticky',
    zIndex: 1,
    background: '#fff',
    boxShadow: 'inset 0 -2px 0px 0px #7986cb',
  },
  siteColumnHead: {
    position: 'sticky',
    top: 0,
    left: 0,
    background: '#fff',
    boxShadow: 'inset 0 -2px 0px 0px #7986cb',
    zIndex: 2,
  },
  siteColumn: {
    maxWidth: 200,
    width: 200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'sticky',
    left: 0,
    background: '#fff',
  },
  siteDirectorColumnHead: {
    position: 'sticky',
    top: 0,
    left: 200,
    background: '#fff',
    boxShadow: 'inset 0 -2px 0px 0px #7986cb',
    zIndex: 2,
  },
  siteDirectorColumn: {
    maxWidth: 200,
    width: 200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'sticky',
    left: 200,
    background: '#fff',
  },
  rescueSizeColumnHead: {
    position: 'sticky',
    top: 0,
    left: 373,
    background: '#fff',
    boxShadow: '2px 0 0px 0px #7986cb, inset 0 -2px 0px 0px #7986cb',
    zIndex: 2,
    maxWidth: 280,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  rescueSizeColumn: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    left: 373,
    background: '#fff',
    maxWidth: 280,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    boxShadow: '2px 0 0px 0px #7986cb',
  },
  rescueSizeColumnForReceivingAdmin: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    left: 0,
    background: '#fff',
    maxWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    boxShadow: '2px 0 0px 0px #7986cb',
  },
  sortByCell: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    cursor: 'pointer',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  footerTotalColumn: {
    left: 0,
    position: 'sticky',
  },
}));

const ReceiverReportTable = ({ filters, rescueReportData, showSiteDataColumns }) => {
  const tableRef = useRef();
  const classes = useStyles();
  const history = useHistory();
  const granularity = get(filters, 'granularity');
  const [rescueReport, setRescueReport] = useState(rescueReportData);

  useEffect(() => {
    setRescueReport(rescueReportData);
  }, [rescueReportData]);

  const [orderBy, setOrderBy] = useState({
    period: null,
    entityType: 'site',
    measure: null,
    order: 'asc',
  });

  const handleSortBy = (period, entityType, measure) => {
    const foundOrderBy =
      orderBy.period === period && orderBy.entityType === entityType && orderBy.measure === measure
        ? orderBy.order
        : null;
    const order = foundOrderBy ? (foundOrderBy === 'asc' ? 'desc' : 'asc') : 'asc';

    const saveOrders = () =>
      setOrderBy({
        period: period,
        entityType: entityType,
        measure: measure,
        order: order,
      });

    if (['site', 'siteDirector'].indexOf(entityType) >= 0) {
      setRescueReport({
        ...rescueReport,
        sites: lodashOrderBy(
          rescueReport.sites,
          row => {
            if (entityType === 'site') {
              return row.site.name;
            }

            if (entityType === 'siteDirector') {
              return getUserFullName(row.site.site_director);
            }
          },
          order
        ),
      });

      return saveOrders();
    }

    setRescueReport({
      ...rescueReport,
      sites: rescueReport.sites.map((siteRow, siteIndex) => {
        if (measure !== null) {
          if (period !== null) {
            return {
              ...siteRow,
              receiving_agencies: lodashOrderBy(
                siteRow.receiving_agencies,
                rescueSizeRow => {
                  const value = rescueSizeRow[entityType].periods[period][measure];
                  return value !== null ? value : -1;
                },
                order
              ),
            };
          }

          // period is not set, we want to sort by row total
          const value = siteIndex[entityType].total[measure];

          return value !== null ? value : -1;
        }

        if (entityType === 'receivingAgency') {
          return {
            ...siteRow,
            receiving_agencies: lodashOrderBy(
              siteRow.receiving_agencies,
              rescueSizeRow => rescueSizeRow.receiving_agency.name,
              order
            ),
          };
        }

        return siteRow;
      }),
    });

    return saveOrders();
  };

  const renderSortBy = (index, entityType, label, measure) => (
    <Box
      onClick={() => handleSortBy(index, entityType, measure)}
      className={classNames({
        [classes.sortByCell]: true,
        [classes.justifyContentCenter]:
          entityType !== 'site' && entityType !== 'siteDirector' && entityType !== 'receivingAgency',
      })}
    >
      <>
        <span>{label !== null && label}</span>

        {orderBy.period === index && orderBy.entityType === entityType && (
          <>{orderBy.order === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}</>
        )}
      </>
    </Box>
  );

  const renderCount = (count, format = 'number') => {
    if (format === 'value') {
      return formatValueOfMeals(count);
    }

    return formatNumber(count);
  };

  const renderEntityColumn = (index, entityName, entityType, showBorder = false, isOdd = null) => (
    <TableCell
      variant="head"
      align="center"
      className={classNames({
        [classes.entityTypeStickyHeader]: true,
        [classes.descriptionsHeader]: true,
        [classes.borderLeftColumn]: showBorder,
        [classes.diffOdd]: isOdd === true,
        [classes.diffEven]: isOdd === false,
      })}
    >
      {renderSortBy(index, entityType, entityName, 'count')}
    </TableCell>
  );

  const renderRescueSizesRow = (siteIndex, siteRow) => {
    const rescueSizesList = siteRow.receiving_agencies || [];

    if (rescueSizesList.length === 0) {
      return null;
    }

    const _renderRowEntity = (index, periodIndex, data, format = 'number', showBorder = false, isOdd = null) => (
      <TableCell
        variant="head"
        align="center"
        className={classNames({
          [classes.oddColumn]: index % 2,
          [classes.diffOdd]: isOdd === true,
          [classes.diffEven]: isOdd === false,
          [classes.borderLeftColumn]: showBorder,
        })}
        data-period={periodIndex}
      >
        {renderCount(data.count, format)}
      </TableCell>
    );

    return rescueSizesList.map((rescueSize, rescueSizeIndex) => (
      <React.Fragment key={`${siteIndex}-${rescueSizeIndex}`}>
        <TableRow>
          {showSiteDataColumns && rescueSizeIndex === 0 && (
            <>
              <TableCell
                rowSpan={siteRow.receiving_agencies.length}
                className={classNames({
                  [classes.siteColumn]: true,
                  [classes.oddColumn]: siteIndex % 2,
                })}
              >
                {siteRow.site.name}
              </TableCell>
              <TableCell
                rowSpan={siteRow.receiving_agencies.length}
                className={classNames({
                  [classes.siteDirectorColumn]: true,
                  [classes.oddColumn]: siteIndex % 2,
                })}
              >
                {siteRow.site.site_director && (
                  <Link
                    onClick={() =>
                      history.push(generatePath(routes.userEditNew, { id: siteRow.site.site_director.id }))
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    {getUserFullName(siteRow.site.site_director)}
                  </Link>
                )}

                {!siteRow.site.site_director && 'n/a'}
              </TableCell>
            </>
          )}

          <TableCell
            className={classNames({
              [classes.rescueSizeColumn]: showSiteDataColumns,
              [classes.rescueSizeColumnForReceivingAdmin]: !showSiteDataColumns,
              [classes.oddColumn]: siteIndex % 2,
            })}
          >
            {rescueSize.receiving_agency.name}
          </TableCell>

          {rescueReport.periods.map((period, periodIndex) => (
            <React.Fragment key={periodIndex}>
              {_renderRowEntity(siteIndex, periodIndex, rescueSize.rescues.periods[periodIndex], 'number', true)}
              {_renderRowEntity(siteIndex, periodIndex, rescueSize.meals.periods[periodIndex])}
              {_renderRowEntity(siteIndex, periodIndex, rescueSize.lbs.periods[periodIndex])}
              {showSiteDataColumns && _renderRowEntity(siteIndex, periodIndex, rescueSize.value.periods[periodIndex], 'value')}
            </React.Fragment>
          ))}

        </TableRow>
      </React.Fragment>
    ));
  };

  const renderTotalRow = totalData => {
    const _renderTotalRowEntityPeriod = (index, sitetotalDataEntityTotalPeriod, showBorder = false) => (
      <>
        <TableCell
          variant="head"
          align="center"
          className={classNames({
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
            [classes.borderLeftColumn]: showBorder,
          })}
        >
          {renderCount(sitetotalDataEntityTotalPeriod.count)}
        </TableCell>
      </>
    );

    return (
      <TableRow>
        <TableCell
          colSpan={showSiteDataColumns ? 3 : 0}
          variant="head"
          className={classNames({
            [classes.footerTotalColumn]: true,
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          Total
        </TableCell>

        {rescueReport.periods.map((row, index) => (
          <React.Fragment key={index}>
            {_renderTotalRowEntityPeriod(index, totalData.rescues.periods[index], true)}

            {_renderTotalRowEntityPeriod(index, totalData.meals.periods[index])}

            {_renderTotalRowEntityPeriod(index, totalData.lbs.periods[index])}

            {showSiteDataColumns && _renderTotalRowEntityPeriod(index, totalData.value.periods[index])}
          </React.Fragment>
        ))}
      </TableRow>
    );
  };

  if (!rescueReportData.sites.length) {
    return (
      <Box my={2}>
        <Paper className={classNames(classes.paper, classes.pivot)}>
          Please use "RUN" button to generate the report.
        </Paper>
      </Box>
    );
  }

  return (
    <Box my={2}>
      <Paper className={classNames(classes.paper, classes.pivot)}>
        <TableToCSVGenerator tableRef={tableRef} fileName="Rescue Report" />
        <TableContainer className={classNames(classes.tableContainer, 'table-container')}>
          <Table aria-label="spanning table" size="small"  ref={tableRef} id="rescue-report-table">
            <TableHead>
              <TableRow>
                {showSiteDataColumns && (
                  <TableCell
                    rowSpan={2}
                    className={classNames({
                      [classes.descriptionsHeader]: true,
                      [classes.siteColumnHead]: true,
                    })}
                  >
                    {renderSortBy(null, 'site', 'Site', null)}
                  </TableCell>
                )}
                {showSiteDataColumns && (
                  <TableCell
                    rowSpan={2}
                    className={classNames({
                      [classes.descriptionsHeader]: true,
                      [classes.siteDirectorColumnHead]: true,
                    })}
                  >
                    {renderSortBy(null, 'siteDirector', 'Site Director', null)}
                  </TableCell>
                )}
                <TableCell
                  rowSpan={2}
                  className={classNames({
                    [classes.descriptionsHeader]: true,
                    [classes.rescueSizeColumnHead]: showSiteDataColumns,
                    [classes.siteColumnHead]: !showSiteDataColumns,
                  })}
                >
                  {renderSortBy(null, 'receivingAgency', 'Receiving Agency', null)}
                </TableCell>

                {rescueReport.periods.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableCell
                      colSpan={showSiteDataColumns ? 4 : 3}
                      variant="head"
                      align="center"
                      className={classNames({
                        [classes.periodStickyHeader]: true,
                        [classes.descriptionsHeader]: true,
                        [classes.borderLeftColumn]: true,
                      })}
                    >
                      {granularity === 'monthly' ? formatDateToMonthName(row) : formatDateToWeekName(row)}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>

              <TableRow>
                {rescueReport.periods.map((row, index) => (
                  <React.Fragment key={index}>
                    {renderEntityColumn(index, '# Rescues', 'rescues',true)}

                    {renderEntityColumn(index, '# Meals', 'meals')}

                    {renderEntityColumn(index, '# Lbs', 'lbs')}

                    {showSiteDataColumns && renderEntityColumn(index, '$', 'value')}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {rescueReport.sites.map((siteRow, siteIndex) => (
                  <React.Fragment key={siteIndex}>{renderRescueSizesRow(siteIndex, siteRow)}</React.Fragment>
              ))}

              {renderTotalRow(rescueReport.total)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ReceiverReportTable;
