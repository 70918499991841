import React from 'react';
import * as authService from '../../../services/auth';
import { Roles } from '../../../models/roles';
import useActiveUser from '../../../hooks/useActiveUser';
import RescueEditAdminView from './AdminForm/RescueEditAdminView';
import RescueEditRescuerView from './RescuerForm/RescueEditRescuerView';
import RescueEditDonorView from './DonorForm/RescueEditDonorView';

const RescueEditView = ({
  isMobileView,
  location,
  match: {
    params: { rescueId },
  },
}) => {
  const activeUser = useActiveUser();

  switch (true) {
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.Admin):
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.NationalSiteDirector):
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.SiteDirector):
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.SiteCoordinator):
      return <RescueEditAdminView rescueId={rescueId} isMobileView={isMobileView} user={activeUser} location={location} />;
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.Rescuer):
      return <RescueEditRescuerView rescueId={rescueId} isMobileView={isMobileView} user={activeUser} />;
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.DonorLocationAdmin):
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.DonorLocationStaff):
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.DonorAdmin):
    case authService.hasRoleInCurrentlySelectedSite(activeUser, Roles.DonorStaff):
      return (
        <RescueEditDonorView rescueId={rescueId} isMobileView={isMobileView} user={activeUser} location={location} />
      );
    default:
      return 'no access';
  }
};

export default RescueEditView;
