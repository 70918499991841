import { objectFromArray } from '../helpers';
import * as foodDescriptionsActions from '../actions/foodDescriptions';

export const initialSitesState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const foodDescriptionsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case foodDescriptionsActions.REQUEST_FOOD_DESCRIPTIONS:
      return {
        ...state,
        inflight: true,
      };
    case foodDescriptionsActions.RECEIVE_FOOD_DESCRIPTIONS:
      const foodDescriptions = (action.foodDescriptions || []).filter(
        foodDescription => foodDescription && foodDescription.name !== 'Legacy'
      );

      return {
        ...state,
        inflight: false,
        byId: objectFromArray(foodDescriptions, unit => unit.id),
        allIds: foodDescriptions.map(unit => unit.id),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default foodDescriptionsReducer;
