import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnassignedUsersTable from '../components/UnassignedUsersTable';
import * as userActions from '../actions/users';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
}));

const UsersUnassignedListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const usersWithoutRoles = useSelector(state => state.entities.users.withoutRoles);
  const usersWithoutRolesList = usersWithoutRoles.allIds.map(id => usersWithoutRoles.byId[id]);
  const currentlyLoggedInUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const isAllowedToDeleteUser = authService.hasAnyRoleInCurrentlySelectedSite(currentlyLoggedInUser, [Roles.Admin]);

  useEffect(() => {
    dispatch(userActions.fetchUsersWithoutRoles());
  }, []);

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        <Typography color="textPrimary">
          Unassigned users
        </Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12}>
          <UnassignedUsersTable
            isLoading={usersWithoutRoles.inflight}
            unassignedUsers={usersWithoutRolesList}
            showDeleteUserButton={isAllowedToDeleteUser}
            onUserDelete={user => dispatch(userActions.deleteUserWithoutRoles(user))}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UsersUnassignedListView;
