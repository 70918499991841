import { Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserLinkedAccountsIfNeeded, fetchUserLinkedPagesIfNeeded } from '../../../../actions/marketingHub';
import PagePaper from '../../../../components/Common/PagePaper';
import OverlayLoader from '../../../../components/OverlayLoader';
import ProfileCard from '../../../MarketingHub/MarketingHubDesktop/Components/ProfileCard';

const SiteNewsDesktop = () => {
  const marketingHubEntities = useSelector((state) => state.entities.marketingHub);
  const instagramAccounts = useSelector((state) => state.entities.marketingHub.accounts.instagram);
  const pages = useSelector((state) => state.entities.marketingHub.pages.all);
  const pagesAndInstagramAccounts = [...pages, ...instagramAccounts];
  const displayLinkedAccounts = useSelector((state) => state.entities.marketingHub.displayLinkedAccounts);
  const sdkInitialized = useSelector((state) => state.entities.marketingHub.sdkInit);
  const dispatch = useDispatch();

  useEffect(() => {
    // Placeholder -> change to site accounts after API is done
    if (sdkInitialized) {
      window.FB.getLoginStatus((res) => {
        if (res.status === 'connected') {
          dispatch(fetchUserLinkedPagesIfNeeded(res.authResponse.userID, res.authResponse.accessToken));
          dispatch(fetchUserLinkedAccountsIfNeeded(res.authResponse.userID, res.authResponse.accessToken));
        }
        return null;
      });
    }
  }, [sdkInitialized]);

  if (!pagesAndInstagramAccounts.length || !displayLinkedAccounts) {
    return 'N/A';
  }

  return (
    <OverlayLoader isLoading={marketingHubEntities.inflight}>
      <PagePaper mt={1}>
        <Grid container spacing={2} style={{ marginTop: 1 }}>
          {pagesAndInstagramAccounts.map((account) => (
            <Grid item key={account.id}>
              <Link href={account.link} target="_blank" style={{ textDecoration: 'none' }}>
                <ProfileCard name={account.name} picture={account.picture} type={account.type} />
              </Link>
            </Grid>
          ))}
          {pagesAndInstagramAccounts.length === 0 && <Typography variant="body2">N/A</Typography>}
        </Grid>
      </PagePaper>
    </OverlayLoader>
  );
};

export default SiteNewsDesktop;
