import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useRescuerDashboardActions from '../../../../../hooks/useRescuerDashboardActions';
import useRescuerRescueActions from '../../../../../hooks/useRescuerRescueActions';
import { getMyRescues, getRescuePickupLocationFullName, getScheduleAvailableRescues } from '../../../../../helpers/RescuesHelper';
import RescueMobileCard from '../../../../Rescue/RescueMobileCard';
import { Avatar, Box, List, ListItem, makeStyles, Typography, ListSubheader } from '@material-ui/core';
import { Close as CloseIcon, Update as UpdateIcon } from '@material-ui/icons';
import ButtonWithLoading from '../../../../../components/ButtonWithLoading';
import useActiveUser from '../../../../../hooks/useActiveUser';
import { Colors } from '../../../../../assets/theme/Colors';
import { StyledSubHeader } from '../myFrus/MyFrusMobile';
import classNames from 'classnames';
import { Virtuoso } from 'react-virtuoso';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { formatTime } from '../../../../../helpers/formatters';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    ...typography['h5'],
    padding: spacing(1),
  },
  listSubItem: {
    padding: spacing(1),
  },
  ul: {
    marginBottom: spacing(2),
    backgroundColor: 'inherit',
    padding: 0,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  upcoming: {
    backgroundColor: Colors.rescues.claimed.color,
    filter: 'brightness(0.9)',
  },
}));

const MyRescuesMobile = () => {
  const classes = useStyles();
  let rescuesInflight = {};
  const activeUser = useActiveUser();
  const { fetchMyFrusTab } = useRescuerDashboardActions();
  const { releaseRescue, rescueUnclaim, rescueUnadopt } = useRescuerRescueActions();
  const { formatMessage } = useIntl();
  const rescuesEntities = useSelector(state => state.entities.rescues);
  const rescuerRescues = getMyRescues(activeUser.id, rescuesEntities);
  const availableRescues = getScheduleAvailableRescues(activeUser.id, rescuesEntities);
  const isLoading = rescuesEntities.inflight || availableRescues.inflight || availableRescues.lastUpdated === null;
  const availableRescuesCount = rescuerRescues.length;

  useEffect(() => {
    fetchMyFrusTab(false, false);
  }, [activeUser.id, fetchMyFrusTab]);

  if (!isLoading && rescuerRescues.length === 0) {
    return (
      <Box className={classes.container}>
        <Box className={classes.noData}>
          <Typography color="textSecondary" variant="h5">
            {formatMessage({ id: 'rescuer-dashboard-mobile.my-rescues.no-claimed-rescues' })}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <List className={classes.container} subheader={<li />}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            <ListItem className={classes.listSubItem}>
              <RescueMobileCard isLoading />
            </ListItem>
            <ListItem className={classes.listSubItem}>
              <RescueMobileCard isLoading />
            </ListItem>
            <ListItem className={classes.listSubItem}>
              <RescueMobileCard isLoading />
            </ListItem>
          </ul>
        </li>
      </List>
    );
  }

  const handleUnadopt = (rescue) =>
    confirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          variant: 'outlined',
          label: 'Never mind',
          color: 'primary',
          size: 'small',
          onClick: () => {},
        },
        {
          variant: 'contained',
          label: 'Unadopt',
          color: 'primary',
          size: 'small',
          onClick: () => rescueUnadopt(rescue, true),
        },
      ],
      message: 'Unadopting this rescue will release all rescues from this adoption. Do you want to continue?',
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
        />
      ),
    });

  const handleReleaseClick = (rescue) =>
    confirmAlert({
      title: `Are you sure?`,
      buttons: [
        {
          variant: 'outlined',
          label: 'Never mind',
          color: 'primary',
          onClick: () => {},
        },
        {
          variant: 'contained',
          label: 'Release',
          color: 'primary',
          onClick: () => releaseRescue(rescue, true),
        },
      ],
      message: 'Releasing this rescue may result in this food going to waste, but we understand that life happens!',
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
        />
      ),
    });

  return (
    <Box display="flex" flexDirection="column" width={1} pt={1}>
      {!isLoading && rescuerRescues.length > 0 && (
        <Box px={1}>
          <StyledSubHeader
            color={Colors.rescues.claimed.color}
            // component={RouterLink}
            // to={generatePath(routes.dashboardRescuerRescues, { tab: RESCUER_MOBILE_DASHBOARD_TAB_MY_RESCUES })}
            text={formatMessage({ id: 'rescuer-dashboard-mobile.my-rescues.upcoming-rescues' })}
            subText={formatMessage({ id: 'rescuer-dashboard-mobile.my-rescues.upcoming-rescues-sub' })}
            icon={
              <Avatar className={classNames(classes.icon, classes.upcoming)}>
                <UpdateIcon />
              </Avatar>
            }
          />
        </Box>
      )}
      <Virtuoso
        style={{ width: '100%', height: '100%' }}
        totalCount={availableRescuesCount}
        itemContent={index => {
          const rescue = rescuerRescues[index];
          return (
            <div key={`rescue-${rescue.id}`} className={classes.listSubItem}>
              <RescueMobileCard
                rescue={rescue}
                isCancelled={rescue.cancelled_by_id !== null}
                isClosed={rescue.closed_by_id !== null}
              >
                {rescue.cancelled_by_id === null &&
                  (rescue.adopter_id === activeUser.id ? (
                    <Box display="grid" gridGap={8}>
                      <ButtonWithLoading
                        fullWidth
                        startIcon={<CloseIcon fontSize="small" />}
                        isLoading={rescuesInflight[rescue.id] && rescuesInflight[rescue.id] === 'release'}
                        disabled={!!rescuesInflight[rescue.id]}
                        onClick={() => handleReleaseClick(rescue)}
                        size="large"
                        color="primary"
                        variant="contained"
                      >
                        {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.release-rescue' })}
                      </ButtonWithLoading>
                      <ButtonWithLoading
                        fullWidth
                        startIcon={<CloseIcon fontSize="small" />}
                        isLoading={rescuesInflight[rescue.id] && rescuesInflight[rescue.id] === 'unadopt'}
                        disabled={!!rescuesInflight[rescue.id]}
                        onClick={() => handleUnadopt(rescue)}
                        size="large"
                        color="primary"
                        variant="outlined"
                      >
                        {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.unadopt' })}
                      </ButtonWithLoading>
                    </Box>
                  ) : (
                    <Box display="flex" flexDirection="column" alignItems="stretch">
                      <ButtonWithLoading
                        fullWidth
                        startIcon={<CloseIcon fontSize="small" />}
                        isLoading={rescuesInflight[rescue.id] && rescuesInflight[rescue.id] === 'unclaim'}
                        disabled={!!rescuesInflight[rescue.id]}
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={() => rescueUnclaim(rescue, true)}
                      >
                        {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.release-rescue' })}
                      </ButtonWithLoading>
                    </Box>
                  ))}
              </RescueMobileCard>
            </div>
          );
        }}
      />
    </Box>
  );
};

export default MyRescuesMobile;
