import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { formatNumber } from '../../helpers/formatters';

const RescueSizesTable = ({ lbsPerMeal, rescueSizes }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>
          Rescue Vehicle Size
        </TableCell>

        <TableCell>
          Meals per Rescue
        </TableCell>

        {lbsPerMeal && <TableCell>Lbs</TableCell>}
      </TableRow>
    </TableHead>

    <TableBody>
      {rescueSizes.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            {row.name}
          </TableCell>

          <TableCell>
            {formatNumber(row.meals_per_rescue)}
          </TableCell>

          {lbsPerMeal && <TableCell>{formatNumber(row.meals_per_rescue * lbsPerMeal)}</TableCell>}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default RescueSizesTable;
