import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

const DonationSizesTable = ({ rescueSizes }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Rescue Size</TableCell>

        <TableCell>Boxes per Rescue</TableCell>

        <TableCell>Lbs.</TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {rescueSizes.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{row.size}</TableCell>

          <TableCell>{row.boxes_range}</TableCell>

          <TableCell>{row.weight_lbs}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default DonationSizesTable;
