import React, { useReducer } from 'react';
import NotificationsContext from './NotificationsContext';
import NotificationsReducer from './NotificationsReducer';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../types';

function generateNotificationId() {
  return `${Date.now()}.${(1 + Math.random()) * 0x10000}`.replace(/\./g, '-');
}

function NotificationsState({ children }) {
  const initialState = {
    notifications: [],
  };

  const [state, dispatch] = useReducer(NotificationsReducer, initialState);

  const addNotification = (message, type = 'success') => dispatch({
    type: ADD_NOTIFICATION,
    payload: {
      id: generateNotificationId(),
      message: message,
      type: type,
    },
  });

  const removeNotification = notificationId => dispatch({
    type: REMOVE_NOTIFICATION,
    payload: {
      id: notificationId,
    }
  });

  return (
    <NotificationsContext.Provider
      value={{
        notifications: state.notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsState;
