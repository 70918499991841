import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DevTool } from 'react-hook-form-devtools';
import Joi from '@hapi/joi';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import errorMessages from '../../assets/errorMessages';

const validationResolver = data => {
  const errors = {};

  const errorList = Joi.object()
    .keys({
      other: Joi.string()
        .when('food_donor_name', {
          is: 'Other',
          then: Joi.string().required(),
        })
        .messages({
          'any.required': errorMessages.REQUIRED.message,
          'string.empty': errorMessages.REQUIRED.message,
        }),
    })
    .validate(
      {
        food_donor_name: data.food_donor ? data.food_donor.name : '',
        other: data.other,
      },
      { abortEarly: false, allowUnknown: true }
    );

  if (errorList.error) {
    errorList.error.details.forEach(error => {
      errors[error.context.key] = { message: error.message };
    });
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors: errors,
  };
};

const RegistrationApproveDialog = ({
  umbrellaFoodDonors = [],
  foodDonor,
  isOpen,
  onClose,
  handleCancel,
  handleSave,
}) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    errors,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    validationResolver,
    defaultValues: {
      food_donor: '',
      other: '',
    },
  });

  const selectedChoice = watch('food_donor');
  const onSubmit = values => handleSave(values);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Approve Food Donor Registration</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            Great! {foodDonor.name} will receive an email with the good news.
            <p>If {foodDonor.name} is part of a franchise or national chain, please select from the list here.</p>
          </DialogContentText>

          <Controller
            name="food_donor"
            control={control}
            onChange={([event, data]) => data}
            as={
              <Autocomplete
                autoHighlight
                options={[{ id: -1, name: 'Other' }, ...umbrellaFoodDonors]}
                getOptionLabel={option => option.name || ''}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={option => (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1">{option.name}</Typography>
                  </Box>
                )}
                renderInput={params => <TextField {...params} placeholder="Food Donor" variant="outlined" />}
              />
            }
          />

          {selectedChoice && selectedChoice.name === 'Other' && (
            <Box mt={2} width={1}>
              <TextField
                fullWidth
                error={!!errors.other}
                helperText={errors.other ? errors.other.message : ''}
                label="Other"
                variant="outlined"
                inputRef={register()}
                name="other"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit">
            Submit
          </Button>
        </DialogActions>
        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </form>
    </Dialog>
  );
};

export default RegistrationApproveDialog;
