import * as instagramActions from '../actions/instagram';
import { objectFromArray } from '../helpers';

export const initialInstagramState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const instagramReducer = (state = initialInstagramState, action) => {
  switch (action.type) {
    case instagramActions.REQUEST_INSTAGRAM_DATA:
      return {
        ...state,
        inflight: true,
      };
    case instagramActions.RECEIVE_INSTAGRAM_DATA:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.items, (item) => item.id),
        allIds: action.items.map((item) => item.id),
      };
    default:
      return state;
  }
};

export default instagramReducer;
