import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch } from 'react-redux';
import * as authService from '../../services/auth';
import { Roles } from '../../models/roles';
import DonorLocationDashboardContainer from './DonorLocationDashboard/DonorLocationDashboardContainer';
import UmbrellaFoodDonorView from '../../containers/UmbrellaFoodDonorView';
import { setDefaultDashboard } from '../../actions/uiPersisted';
import routes from '../../routes';
import LandingDashboard from '../rescuerDashboard/rescuerDashboardMobile/LandingDashboard';

const NoDataView = () => {
  return (
    <Box my={2}>
      <Card>
        <CardContent>
          <Typography color="error">No data to present.</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const DonorDashboardContainer = ({ isMobileView, match, history }) => {
  const dispatch = useDispatch();
  const { params } = match;
  useEffect(() => {
    // set as default dashboard
    dispatch(setDefaultDashboard({ route: routes.dashboardFoodDonor, params }));
  }, [dispatch]);

  if (isMobileView) {
    return <LandingDashboard />;
  }

  const currentlyLoggedInUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const userRoles = authService.getUserRolesInCurrentlySelectedSite(currentlyLoggedInUser);
  let rolesWithAccess = userRoles.filter(role => [Roles.DonorAdmin, Roles.DonorStaff].indexOf(role.role_name) > -1);

  if (rolesWithAccess.length > 0) {
    return (
      <UmbrellaFoodDonorView
        match={match}
        history={history}
        renderParams={{
          id: rolesWithAccess[0].donor_id,
        }}
      />
    );
  }

  rolesWithAccess = userRoles.filter(
    role => [Roles.DonorLocationAdmin, Roles.DonorLocationStaff].indexOf(role.role_name) > -1
  );

  if (rolesWithAccess.length > 0) {
    return (
      <DonorLocationDashboardContainer
        renderParams={{ foodDonorId: rolesWithAccess[0].donor_location_id }}
      />
    );
  }

  return <NoDataView />;
};

export default DonorDashboardContainer;
