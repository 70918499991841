import React, { Component } from 'react';
import moment from 'moment';
import { TableFilterList } from 'mui-datatables';
import _ from 'lodash';
import { Checkbox, FormControlLabel, Box, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import { formatCsvFileName } from '../helpers/formatters';
import { getRescuePickupLocationFullName } from '../helpers/RescuesHelper';

const CustomFilterList = ({ filterList, filterUpdate, ...restProps }) => {
  const { options, columnNames } = restProps;

  const verificationStatusColumnIndex = columnNames.findIndex(col => col.name === 'verification_status');
  const verificationStatusColumn = columnNames[verificationStatusColumnIndex];

  const updatedList = [...filterList];
  const verificationChecked = filterList[verificationStatusColumnIndex].length > 0;
  updatedList[verificationStatusColumnIndex] = [];

  return (
    <Box display="flex" flexDirection="row">
      <TableFilterList {...restProps} filterList={updatedList} filterUpdate={filterUpdate} />

      {options.customFilters && options.customFilters.showPendingRegistrationFilter && (
        <Box marginTop="5px">
          <FormControlLabel
            control={
              <Checkbox
                checked={verificationChecked}
                onChange={() => {
                  filterUpdate(
                    verificationStatusColumnIndex,
                    'pending',
                    verificationStatusColumn.name,
                    verificationStatusColumn.filterType
                  );
                }}
              />
            }
            label="Pending Request(s)"
          />
        </Box>
      )}
    </Box>
  );
};

class FoodDonorDonationRequestTable extends Component {
  render() {
    const {
      donations,
      tableId,
      onLocationClick,
      onEditClick,
      onReviewClick,
      customToolbar = () => {},
      customFilters = {
        showPendingRegistrationFilter: false,
      },
    } = this.props;

    const frequencyCustomBodyRender = donation => {
      switch (donation.frequency) {
        case 0:
          return 'one-time';
        case 1:
          return donation.frequency_every === 1 ? 'weekly' : 'every other week';
        case 2:
          return 'monthly';
        default:
          return 'unknown';
      }
    };

    const startDateCustomBodyRender = value => (value ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null);

    const endDateCustomBodyRender = value => (value ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A');

    const requestDateCustomBodyRender = value => (value ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null);

    const verificationStatusBodyRender = value => value && value !== 'approved' ? _.startCase(_.toLower(value)) : '-';

    return (
      <BaseMUIDataTable
        tableId={tableId}
        title="Donations"
        data={donations}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              sort: false,
              display: false,
            },
          },
          {
            name: 'location',
            label: 'Food Donor',
            options: {
              setCellProps: () => ({
                style: {
                  cursor: 'pointer',
                },
              }),
              customBodyRender: (value, tableMeta) => {
                const donation = donations[getMuiTableDataIndex(tableMeta)];
                const foodDonorName = getRescuePickupLocationFullName(donation);

                return (
                  <Link onClick={() => onLocationClick(donation.donor_id, donation.location_id)}>{foodDonorName}</Link>
                );
              },
            },
          },
          {
            name: 'donation',
            label: 'Description',
          },
          {
            name: 'frequency',
            label: 'Frequency',
            options: {
              customBodyRender: (value, tableMeta) => {
                const donation = donations[getMuiTableDataIndex(tableMeta)];

                return frequencyCustomBodyRender(donation);
              },
              customBodyRenderCSV: (value, tableMeta) => {
                const donation = donations[getMuiTableDataIndex(tableMeta)];

                return frequencyCustomBodyRender(donation);
              },
            },
          },
          {
            name: 'display_days',
            label: 'Day(s)',
            options: {
              customBodyRender: value => value || '-',
            },
          },
          {
            name: 'start_date',
            label: 'Start Date',
            options: {
              sort: true,
              customBodyRender: value => startDateCustomBodyRender(value),
              customBodyRenderCSV: value => startDateCustomBodyRender(value),
            },
          },
          {
            name: 'end_date',
            label: 'End Date',
            options: {
              sort: true,
              customBodyRender: value => endDateCustomBodyRender(value),
              customBodyRenderCSV: value => endDateCustomBodyRender(value),
            },
          },
          {
            name: 'created_at',
            label: 'Request Date',
            options: {
              sort: true,
              customBodyRender: value => requestDateCustomBodyRender(value),
              customBodyRenderCSV: value => requestDateCustomBodyRender(value),
            },
          },
          {
            name: 'verification_status',
            label: 'Verification status',
            options: {
              filter: false,
              display: false,
              filterType: 'checkbox',
              customBodyRender: value => verificationStatusBodyRender(value),
              filterOptions: {
                logic: (status, filters) => {
                  if (!filters || filters.length === 0) {
                    return false;
                  }

                  return status !== 'Pending';
                },
                names: ['Pending Request(s)'],
              },
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              filter: false,
              sort: false,
              display: true,
              customBodyRender: (value, tableMeta) => {
                const donation = donations[getMuiTableDataIndex(tableMeta)];

                if (!donation) {
                  return null;
                }

                const isVerificationStatusPending = donation.verification_status === 'pending'
                  || donation.status === 'pending';

                return (
                  <TableActionsButtonsWrapper>
                    {onEditClick && !isVerificationStatusPending && (
                      <TableActionButton
                        title="Edit Donation"
                        onClick={() => onEditClick(donations[getMuiTableDataIndex(tableMeta)].id)}
                      />
                    )}

                    {onReviewClick && isVerificationStatusPending && (
                      <TableActionButton
                        icon={VisibilityIcon}
                        title="Review Donation"
                        onClick={() => onReviewClick(donations[getMuiTableDataIndex(tableMeta)].id)}
                      />
                    )}
                  </TableActionsButtonsWrapper>
                );
              },
            },
          },
        ]}
        options={{
          customToolbar: customToolbar,
          selectableRows: 'none',
          responsive: 'simple',
          downloadOptions: {
            filename: formatCsvFileName('Donations'),
          },
          customFilters: customFilters,
          sortOrder: {
            name: 'start_date',
            direction: 'asc',
          },
        }}
        components={{
          TableFilterList: CustomFilterList,
        }}
      />
    );
  }
}

export default FoodDonorDonationRequestTable;
