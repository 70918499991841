import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const DonationSizeSelector = ({ value, onChange, disabled, rescueSizes = [] }) => (
  <FormControl data-testid="rescue-size">
    <FormLabel component="legend">Rescue Size</FormLabel>
    <RadioGroup
      name="rescue_size_id"
      aria-label="Rescue Size"
      value={value}
      row
      onChange={(event) =>
        onChange({
          name: event.target.name,
          id: parseInt(event.target.value, 10),
        })
      }
    >
      {rescueSizes.map(rescueSize => (
        <FormControlLabel
          control={
            <Radio
              disabled={disabled}
              name={rescueSize.size}
              checked={value === rescueSize.id}
              value={rescueSize.id}
            />
          }
          key={rescueSize.id}
          label={rescueSize.size}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default DonationSizeSelector;
