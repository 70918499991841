import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Table } from './theme/Table';
import { Colors } from './theme/Colors';
import { Tooltip } from './theme/Tooltip';
import { Breadcrumb } from './theme/Breadcrumb';
import { Tabs } from './theme/Tabs';
import { Paper } from './theme/Paper';
import { CssBaseline } from './theme/CssBaseline';
import { Chip } from './theme/Chip';

// disabled for now, will be updated in further PR's
/* basic definitions */
const baseTheme = createMuiTheme({
  palette: {
    // background: {
    //   default: '#f9f9f9',
    // },
    primary: {
      main: Colors.primaryColor,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.secondaryColor,
      contrastText: Colors.white,
    },
    // error: {
    //   main: Colors.red,
    //   contrastText: Colors.white,
    // },
    // text: {
    //   primary: Colors.text1,
    // },
  },
  // typography: {
  //   h2: {
  //     fontFamily: '"averta-semibold", sans-serif',
  //     fontSize: '38.4px',
  //     letterSpacing: '-1.89px',
  //     lineHeight: '52px',
  //     color: Colors.text2,
  //   },
  //   h3: {
  //     fontFamily: '"averta-semibold", sans-serif',
  //     fontSize: '25px',
  //     letterSpacing: '-1.33px',
  //     lineHeight: '33px',
  //     color: Colors.text2,
  //   },
  //   h4: {
  //     fontFamily: '"averta-semibold", sans-serif',
  //     fontSize: '19px',
  //     letterSpacing: '-0.74px',
  //     lineHeight: '23px',
  //     color: Colors.text3,
  //   },
  //   h5: {
  //     fontFamily: '"averta-semibold", sans-serif',
  //     fontSize: '13px',
  //     letterSpacing: '-0.66px',
  //     lineHeight: '14px',
  //     color: Colors.inputLabelColor,
  //   },
  //   body1: {
  //     fontSize: '19px',
  //     letterSpacing: '-0.74px',
  //     lineHeight: '23px',
  //     color: Colors.text3,
  //   },
  //   body2: {
  //     fontSize: '15px',
  //     letterSpacing: '0.11px',
  //     lineHeight: '19px',
  //     color: Colors.text1,
  //   },
  //   caption: {
  //     fontSize: '13px',
  //     letterSpacing: '-0.08px',
  //     lineHeight: '15px',
  //     color: Colors.text4,
  //   },
  //   subtitle1: {
  //     fontFamily: '"averta-semibold", sans-serif',
  //     fontSize: '15px',
  //     letterSpacing: '-0.46px',
  //     lineHeight: '20px',
  //     color: Colors.text5,
  //   },
  //   fontFamily: '"averta-regular", "Roboto", "Helvetica", "Arial", sans-serif',
  //   button: {
  //     fontWeight: 600,
  //   },
  // },
  // breakpoints: {
  //   values: {
  //     xs: 480,
  //     sm: 736,
  //     md: 980,
  //     lg: 1200,
  //     xl: 1366,
  //   },
  // },
});

/* overrides */
export const mainTheme = responsiveFontSizes(createMuiTheme({
  ...baseTheme,
  shadows: baseTheme.shadows.concat(
    '0 0.46875rem 2.1875rem rgba(8,10,37,.03), 0 0.9375rem 1.40625rem rgba(8,10,37,.03), 0 0.25rem 0.53125rem rgba(8,10,37,.05), 0 0.125rem 0.1875rem rgba(8,10,37,.03)'
  ),
  props: {
    ...baseTheme.props,
    MuiSkeleton: {
      animation: 'wave',
    },
    MuiTooltip: {
      ...baseTheme.props.MuiTooltip,
      arrow: true,
      placement: "top",
      enterTouchDelay: 1, // makes tooltips "touchable" for mobile devices
    },
  },
  overrides: {
    MUIDataTableToolbar: {
      filterPaper: {
        minWidth: 450,
      },
    },
    MuiButton: {
      root: {
        transition: 'color .01s', //iOS hack for btn colors after update/disable
      },
    },
    ...CssBaseline(baseTheme),
    ...Breadcrumb(baseTheme),
    ...Table,
    ...Tooltip,
    ...Tabs,
    ...Paper,
    ...Chip,
  },
}));
