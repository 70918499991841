import React, { Component } from 'react';
import { Link, generatePath } from 'react-router-dom';
import moment from 'moment';
import { TableToolbarSelect } from 'mui-datatables';
import {
  Box,
  Button,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';
import { SyncAlt, Mail as MailIcon, Delete as DeleteIcon } from '@material-ui/icons';
import superSearch from '@codewell/super-search';
import { debounceSearchRender, TableFilterList } from 'mui-datatables';
import { isUndefinedOrNull } from '../helpers';
import * as usersModel from '../models/users';
import routes from '../routes';
import * as authService from '../services/auth';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import OverlayLoader from './OverlayLoader';
import ConfirmationDialog from './ConfirmationDialog';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getUserFullName } from '../helpers/user';
import HtmlTooltip from './Common/HtmlTooltip';
import { getMuiTableDataIndex, getRoleAssignment } from '../helpers/getters';
import { formatDate, formatCsvFileName } from '../helpers/formatters';
import { Roles } from '../models/roles';
import { sortAlphabetically } from '../helpers/sorters';
import { rolesWeights } from '../models/users';
import { RegistrationProcessFilter, userRegistrationFilterOptions } from '../helpers/MuiTableCustomFilters';

const CustomFilterList = ({ filterList, filterUpdate, ...restProps }) => {
  const { columnNames } = restProps;

  const registrationCompleteStatusColumnIndex = columnNames.findIndex(col => col.name === 'completed_registration');
  const registrationCompleteStatusColumn = columnNames[registrationCompleteStatusColumnIndex];

  const updatedList = [...filterList];
  const [completeRegistrationFilterType, completeRegistrationFilterValue] = filterList[
    registrationCompleteStatusColumnIndex
  ];

  const completeRegistrationFilterTypeCustom = completeRegistrationFilterType === 'custom';

  const completeRegistrationChecked =
    completeRegistrationFilterTypeCustom ||
    [
      RegistrationProcessFilter.completed,
      RegistrationProcessFilter.custom,
      RegistrationProcessFilter.last30Days,
      RegistrationProcessFilter.last90Days,
    ].includes(completeRegistrationFilterValue);

  if (completeRegistrationFilterValue === RegistrationProcessFilter.completed) {
    updatedList[registrationCompleteStatusColumnIndex] = [];
  }

  return (
    <Box display="flex" flexDirection="row">
      <Box marginTop="5px" ml={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={completeRegistrationChecked}
              onChange={() => {
                filterUpdate(
                  registrationCompleteStatusColumnIndex,
                  completeRegistrationChecked ? [] : ['static', 'Completed Registration'],
                  registrationCompleteStatusColumn.name,
                  registrationCompleteStatusColumn.filterType
                );
              }}
            />
          }
          label="Registration Complete"
        />
      </Box>
      <TableFilterList {...restProps} filterList={updatedList} filterUpdate={filterUpdate} />
    </Box>
  );
};

class UsersTable extends Component {
  state = {
    isDeleting: false,
    selectedRows: [],
  };

  renderCustomToolbarSelector = selectedRows => {
    const { users, showSendEmailButton, showSendSmsButton, onSendEmailButtonClick, onSendSMSButtonClick } = this.props;
    const selectedRescuers = [];

    selectedRows.data.map(selectedRow => {
      const foundRescuer = users.find(r => r.id === selectedRow);

      if (!foundRescuer) {
        return false;
      }

      selectedRescuers.push({
        id: foundRescuer.id,
        email_verified: foundRescuer.email_verified !== null,
        phone_verified: foundRescuer.phone_verified !== null,
      });
    });

    return (
      <Box pr={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
          {showSendEmailButton && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => onSendEmailButtonClick(selectedRescuers)}>
                Send Email
              </Button>
            </Grid>
          )}

          {showSendSmsButton && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => onSendSMSButtonClick(selectedRescuers)}>
                Send SMS
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  renderRolesTable = userRoles => {
    return (
      <Box m={1}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Assignment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRoles.map(roleAssignment => (
              <TableRow key={`sk_${roleAssignment.role_name}`}>
                <TableCell>{roleAssignment.role_name}</TableCell>
                <TableCell>{getRoleAssignment(roleAssignment)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  renderRolesFilter = (filterList, onChange, index, column) => (
    <FormControl>
      <InputLabel htmlFor="select-role-selector">{column.label}</InputLabel>
      <Select
        id="select-role-selector"
        multiple
        value={filterList[index]}
        renderValue={selected => selected.join(', ')}
        onChange={event => {
          filterList[index] = event.target.value;
          onChange(filterList[index], index, column);
        }}
      >
        {Object.values(Roles).map(role => (
          <MenuItem key={role} value={role}>
            <Checkbox color="primary" checked={filterList[index].indexOf(role) > -1} />
            <ListItemText primary={role} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  renderRoles = (userRoles, allRoles) => (
    <HtmlTooltip withBorder title={this.renderRolesTable(allRoles)}>
      <Typography>{userRoles}</Typography>
    </HtmlTooltip>
  );

  render() {
    const { isDeleting, selectedRows } = this.state;
    const {
      tableId,
      title,
      users,
      sendInvitation,
      showRolesColumn,
      showVerifiedColumn,
      showEditUserButton,
      showDeleteUserButton,
      showDeleteSiteCoordinatorButton,
      showImpersonateUserButton,
      showSentInvitationUserButton,
      showSelectableRows,
      onUserDelete,
      onSiteCoordinatorDelete,
      onImpersonateUserButtonClick,
      customToolbar,
    } = this.props;

    const fullNameCustomBodyRender = (value, tableMeta) => {
      const user = users[getMuiTableDataIndex(tableMeta)];

      if (!user) {
        return '';
      }

      return getUserFullName(user);
    };

    const emailVerifiedCustomBodyRender = (value) =>
      !isUndefinedOrNull(value) ? moment(value).format('MM/DD/YYYY') : 'Not Verified';

    const phoneVerifiedCustomBodyRender = (value) =>
      !isUndefinedOrNull(value) ? moment(value).format('MM/DD/YYYY') : 'Not Verified';

    const zipCodeCustomBodyRender = (value) => value || '-';

    const activeCustomBodyRender = (value) => (value ? 'Yes' : 'No');

    const roleAssignmentsCustomBodyRender = (value, csv = false) => {
      const userRoles = usersModel.getRolesFromRoleAssignments(
        authService.getUserRolesInCurrentlySelectedSite({ role_assignments: value })
      );

      if (!userRoles || !userRoles.length) {
        return !csv ? '-' : null;
      }

      const formattedUserRoles = userRoles.join(', ');

      if (csv) {
        return formattedUserRoles;
      }

      return this.renderRoles(formattedUserRoles, value);
    };

    return (
      <OverlayLoader isLoading={isDeleting}>
        {selectedRows.length > 0 && (
          <TableToolbarSelect
            selectedRows={{
              data: selectedRows,
            }}
            options={{
              customToolbarSelect: this.renderCustomToolbarSelector,
              textLabels: {
                selectedRows: {
                  text: 'user(s) selected',
                },
              },
            }}
          />
        )}

        <BaseMUIDataTable
          tableId={tableId}
          title={title || 'Users'}
          data={users}
          columns={[
            {
              name: 'id',
              label: 'ID',
              options: {
                filter: false,
                sort: false,
                display: false,
              },
            },
            {
              name: 'firstname',
              label: 'First Name',
              options: {
                filter: false,
                display: false,
              },
            },
            {
              name: 'lastname',
              label: 'Last Name',
              options: {
                filter: false,
                display: false,
              },
            },
            {
              name: 'fullname',
              label: 'Full Name',
              options: {
                filter: false,
                sortCompare: order => (row1, row2) =>
                  sortAlphabetically(
                    order,
                    getUserFullName({
                      firstname: row1.rowData[1],
                      lastname: row1.rowData[2],
                    }),
                    getUserFullName({
                      firstname: row2.rowData[1],
                      lastname: row2.rowData[2],
                    })
                  ),
                customBodyRender: (value, tableMeta) => fullNameCustomBodyRender(value, tableMeta),
                customBodyRenderCSV: (value, tableMeta) => fullNameCustomBodyRender(value, tableMeta),
              },
            },
            {
              name: 'email',
              label: 'E-mail',
              options: {
                filter: false,
              },
            },
            {
              name: 'email_verified',
              label: 'Email Verified',
              options: {
                filter: true,
                display: false,
                customFilterListOptions: {
                  render: (value) => `E-mail verified: ${value}`,
                },
                customBodyRender: (value) => emailVerifiedCustomBodyRender(value),
                customBodyRenderCSV: (value) => emailVerifiedCustomBodyRender(value),
              },
            },
            {
              name: 'phone',
              label: 'Phone',
              options: {
                filter: false,
              },
            },
            {
              name: 'phone_verified',
              label: 'Phone Verified',
              options: {
                filter: true,
                display: false,
                customFilterListOptions: {
                  render: (value) => `Phone Verified: ${value}`,
                },
                customBodyRender: (value) => phoneVerifiedCustomBodyRender(value),
                customBodyRenderCSV: (value) => phoneVerifiedCustomBodyRender(value),
              },
            },
            {
              name: 'zip_code',
              label: 'Zip Code',
              options: {
                filter: true,
                filterType: 'multiselect',
                customFilterListOptions: {
                  render: (value) => `Zip Code: ${value}`,
                },
                customBodyRender: (value) => zipCodeCustomBodyRender(value),
                customBodyRenderCSV: (value) => zipCodeCustomBodyRender(value),
              },
            },
            {
              name: 'active',
              label: 'Active',
              options: {
                customFilterListOptions: {
                  render: (value) => `Is active: ${value}`,
                },
                customBodyRender: (value) => activeCustomBodyRender(value),
                customBodyRenderCSV: (value) => activeCustomBodyRender(value),
              },
            },
            {
              name: 'role_assignments',
              label: 'Roles',
              options: {
                display: showRolesColumn,
                filterType: 'custom',
                sortCompare: order => ({ data: roles1 }, { data: roles2 }) => {
                  const userRoles1 = usersModel.getRolesFromRoleAssignments(
                    authService.getUserRolesInCurrentlySelectedSite({ role_assignments: roles1 })
                  );
                  const userRoles2 = usersModel.getRolesFromRoleAssignments(
                    authService.getUserRolesInCurrentlySelectedSite({ role_assignments: roles2 })
                  );

                  if (order === 'asc') {
                    return rolesWeights[userRoles2[0]] - rolesWeights[userRoles1[0]];
                  }

                  return rolesWeights[userRoles1[0]] - rolesWeights[userRoles2[0]];
                },
                customFilterListOptions: {
                  render: value => `Role: ${value.join(', ')}`,
                },
                filterOptions: {
                  logic: (roleAssignments, filters) => {
                    if (!filters || filters.length === 0) {
                      return false;
                    }

                    return !Object.values(roleAssignments).some(role => filters.includes(role.role_name));
                  },
                  display: this.renderRolesFilter,
                },
                customBodyRender: value => roleAssignmentsCustomBodyRender(value),
                customBodyRenderCSV: value => roleAssignmentsCustomBodyRender(value, true),
              },
            },
            {
              name: 'completed_registration',
              label: 'Registration',
              options: {
                display: showVerifiedColumn,
                customFilterListOptions: {
                  render: (value) => `Completed registration: ${formatDate(value)}`,
                },
                ...userRegistrationFilterOptions,
                customBodyRenderLite: dataIndex => {
                  const user = users[dataIndex];

                  if (user.completed_registration !== null) {
                    return formatDate(user.completed_registration);
                  }

                  if (showSentInvitationUserButton && user) {
                    return (
                      <>
                        <Button
                          startIcon={<MailIcon fontSize="small" />}
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => sendInvitation(user)}
                          data-testid="add-new-user-button"
                        >
                          {user.invitation_sent && (
                            <>No, invite again ({moment(user.invitation_sent).format('MM/DD/YYYY h:mm A')})</>
                          )}

                          {!user.invitation_sent && 'No, invite'}
                        </Button>
                      </>
                    );
                  }
                },
                customBodyRenderCSV: (value, tableMeta) => {
                  const user = users[getMuiTableDataIndex(tableMeta)];

                  if (!user) {
                    return null;
                  }

                  return user.completed_registration !== null ? formatDate(user.completed_registration) : 'No';
                },
              },
            },
            {
              name: 'actions',
              label: 'Actions',
              options: {
                download: false,
                filter: false,
                sort: false,
                display:
                  showEditUserButton ||
                  showImpersonateUserButton ||
                  showSentInvitationUserButton ||
                  showDeleteUserButton ||
                  showDeleteSiteCoordinatorButton,
                customBodyRender: (value, tableMeta) => {
                  const user = users[getMuiTableDataIndex(tableMeta)];

                  return (
                    <TableActionsButtonsWrapper>
                      {showEditUserButton && (
                        <TableActionButton
                          title="Edit User"
                          buttonProps={{
                            component: Link,
                            to: generatePath(routes.userEdit, { id: tableMeta.rowData[0] }),
                          }}
                        />
                      )}

                      {showDeleteUserButton && (
                        <TableActionButton
                          title="Delete User"
                          icon={DeleteIcon}
                          onClick={() => {
                            confirmAlert({
                              title: 'Delete this user?',
                              message: 'Are you sure you want to delete this user?',
                              buttons: [
                                {
                                  label: 'No',
                                  color: 'primary',
                                },
                                {
                                  label: 'Yes',
                                  color: 'primary',
                                  onClick: () => {
                                    this.setState({ isDeleting: true }, () => {
                                      onUserDelete(user).then(() => {
                                        this.setState({ isDeleting: false });
                                      });
                                    });
                                  },
                                },
                              ],
                              customUI: ({ title, message, onClose, buttons }) => (
                                <ConfirmationDialog
                                  buttons={buttons}
                                  closeDialog={onClose}
                                  title={title}
                                  message={message}
                                />
                              ),
                            });
                          }}
                        />
                      )}

                      {(
                        // show delete (detach) site director button for
                        // users with SC role but without SD role attached
                        showDeleteSiteCoordinatorButton
                        && (user?.role_assignments || []).some(ra => ra.role_name === Roles.SiteCoordinator)
                        && (user?.role_assignments || []).every(ra => ra.role_name !== Roles.SiteDirector)
                      ) && (
                        <TableActionButton
                          title="Delete Site Coordinator"
                          icon={DeleteIcon}
                          onClick={() => {
                            confirmAlert({
                              title: 'Delete this site coordinator?',
                              message: 'Are you sure you want to delete this site coordinator?',
                              buttons: [
                                {
                                  label: 'No',
                                  color: 'primary',
                                },
                                {
                                  label: 'Yes',
                                  color: 'primary',
                                  onClick: () => {
                                    this.setState({ isDeleting: true }, () => {
                                      onSiteCoordinatorDelete(user).then(() => {
                                        this.setState({ isDeleting: false });
                                      });
                                    });
                                  },
                                },
                              ],
                              customUI: ({ title, message, onClose, buttons }) => (
                                <ConfirmationDialog
                                  buttons={buttons}
                                  closeDialog={onClose}
                                  title={title}
                                  message={message}
                                />
                              ),
                            });
                          }}
                        />
                      )}

                      {showImpersonateUserButton && (
                        <TableActionButton
                          title="Impersonate User"
                          icon={SyncAlt}
                          onClick={() => onImpersonateUserButtonClick(tableMeta.rowData[0])}
                        />
                      )}
                    </TableActionsButtonsWrapper>
                  );
                },
              },
            },
          ]}
          options={{
            customSearchRender: debounceSearchRender(500),
            customSearch: (searchQuery, currentRow, columns) => {
              const firstNameIndex = columns.findIndex(({ name }) => name === 'firstname');
              const lastNameIndex = columns.findIndex(({ name }) => name === 'lastname');

              const { numberOfMatches, fullMatch } = superSearch(searchQuery.toLowerCase(), {
                ...[`${currentRow[firstNameIndex]} ${currentRow[lastNameIndex]}`, ...currentRow],
              });

              return fullMatch && numberOfMatches > 0;
            },
            responsive: 'simple',
            customToolbar: customToolbar,
            selectableRows: showSelectableRows ? 'multiple' : 'none',
            selectToolbarPlacement: 'none', // TODO: now supports 'above' - update component to use it, instead of custom function
            onRowSelectionChange: (currentRowsSelected, allRowsSelected) =>
              this.setState({
                selectedRows: allRowsSelected.map(row => users[row.dataIndex].id),
              }),
            downloadOptions: {
              filename: formatCsvFileName('People'),
            },
          }}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      </OverlayLoader>
    );
  }
}

UsersTable.defaultProps = {
  showSelectableRows: false,
  showSendEmailButton: false,
  showSendSmsButton: false,
  showImpersonateUserButton: false,
  customToolbar: () => null,
};

export default UsersTable;
