import React, { useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import OverlayLoader from '../../components/OverlayLoader';
import * as authService from '../../services/auth';
import { Roles } from '../../models/roles';
import UserForm from '../../components/UserForm';
import routes from '../../routes';
import useActiveUser from '../../hooks/useActiveUser';
import { createUmbrellaStaff } from '../../api/umbrellaFoodDonors';
import useNotificationService from '../../hooks/useNotificationService';
import * as umbrellaFoodDonorsActions from '../../actions/umbrellaFoodDonors';

const UmbrellaFoodDonorStaffAddView = ({ match }) => {
  const umbrellaFoodDonorId = match.params.id;
  const dispatch = useDispatch();
  const umbrellaFoodDonors = useSelector(state => state.entities.umbrellaFoodDonors);
  const umbrellaFoodDonor = umbrellaFoodDonors.byId[umbrellaFoodDonorId];
  const activeUser = useActiveUser();
  const { addErrorNotification } = useNotificationService();

  useEffect(() => {
    dispatch(umbrellaFoodDonorsActions.fetchUmbrellaFoodDonorIfNeeded(umbrellaFoodDonorId));
  }, [dispatch]);

  const handleSubmit = async data => {
    try {
      await createUmbrellaStaff(umbrellaFoodDonorId, {
        user_id: null,
        user: data.user,
        is_admin: data.is_admin,
      });
    } catch (error) {
      addErrorNotification(error, `Unable to add staff. Reason: ${error.message}`);
    }
  };

  const donorLocationPath = generatePath(routes.umbrellaFoodDonor, { id: umbrellaFoodDonorId, tab: 'staff' });

  if (umbrellaFoodDonors.inflight || !umbrellaFoodDonor) {
    return <div>fetching Umbrella Food Donors</div>;
  }

  return (
    <div>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={routes.umbrellaFoodDonors}>
          Umbrella Food Donors
        </Link>

        <Link color="inherit" to={donorLocationPath}>
          {umbrellaFoodDonor.name}
        </Link>

        <Typography color="textPrimary">Invite to register as Food Donor Staff</Typography>
      </Breadcrumbs>

      <OverlayLoader isLoading={false}>
        <UserForm
          redirectPath={donorLocationPath}
          submitCallback={handleSubmit}
          showIsAdminCheckbox={authService.hasAnyRoleInCurrentlySelectedSite(activeUser, [
            Roles.Admin,
            Roles.NationalSiteDirector,
            Roles.SiteDirector,
            Roles.DonorAdmin,
            Roles.DonorLocationAdmin,
          ])}
          isAdminCheckboxLabel="Is Food Donor Admin"
          customValidators={{ validatePhone: false }}
          showLocationFields={false}
        />
      </OverlayLoader>
    </div>
  );
};

export default UmbrellaFoodDonorStaffAddView;
