import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

export const SelectorMobileDialog = ({
  onClose,
  open,
  options,
  getOptionId = () => {},
  getOptionLabel = () => {},
  dialogTitle,
  ...rest
}) => {
  const [value, setValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(null);
    }
  }, [open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => onClose();

  const handleOk = () => onClose(options.find(r => getOptionId(r) === value).value);

  const handleChange = event => setValue(parseInt(event.target.value, 10));

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...rest}
    >
      <DialogTitle id="confirmation-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent dividers>
        <TextField
          variant="outlined"
          value={searchQuery}
          fullWidth
          onChange={event => setSearchQuery(event.target.value)}
        />
        <Divider />

        <RadioGroup ref={radioGroupRef} aria-label="receiver" name="receivers" value={value} onChange={handleChange}>
          {options
            .filter(r => (searchQuery.length > 0 ? r.label.toLowerCase().includes(searchQuery.toLowerCase()) : true))
            .map(option => (
              <FormControlLabel
                value={getOptionId(option)}
                key={getOptionId(option)}
                control={<Radio color="primary" />}
                label={getOptionLabel(option)}
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectorMobileDialog.propTypes = {
  getOptionId: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  dialogTitle: PropTypes.string.isRequired,
};
