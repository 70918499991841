import React, { useState } from 'react';
import { Box, ListItem, ListItemText, Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import FilterComponent from './FilterComponent';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  autocomplete: {
    width: '100%',
    padding: '5px 15px',
    // Do not show the input text element but leave the checkboxes
    display: 'none',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
    width: '100%',
  },
  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
  },
  flexColumn: {
    flexDirection: 'column',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const CitySelectorFilter = ({ label, cities, value, handleChange }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const options = (cities || []).map(city => ({ name: city }));
  const values = (value || []).map(value => ({ name: value }));

  const getLabel = () => {
    if (values.length === 0 || values.length === cities.length) {
      return `${label}: all`;
    }

    return `${label}: ${values.length} selected`;
  };

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getLabel(value)} />

        {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </ListItem>

      <FilterComponent isOpen={isOpen}>
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Autocomplete
            open
            onClose={() => {}}
            value={values}
            onChange={(event, newValue) => handleChange((newValue || []).filter(city => !!city).map(city => {
              if (typeof city === 'string') {
                return city;
              }

              return city.name;
            }))}
            multiple
            classes={{
              root: classes.autocomplete,
              option: classes.option,
              paper: classes.paper,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No labels"
            getOptionSelected={(option, value) => option.name === value.name}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </>
            )}
            options={options}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField variant="outlined" fullWidth ref={params.InputProps.ref} inputProps={params.inputProps} />
            )}
          />
        </Box>
      </FilterComponent>
    </>
  );
};

export default CitySelectorFilter;
