import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  notification: {
    marginBottom: 5,
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const Notification = ({ notification, notificationsContext }) => {
  const classes = useStyles();
  // Timeout for notification duration in seconds
  const timeout = 30;

  useEffect(() => {
    // Notification disappears after timeout duration
    setTimeout(() => {
      notificationsContext.removeNotification(notification.id);
    }, timeout * 1000);
  }, []);

  return (
    <Alert
      className={classes.notification}
      variant="filled"
      severity={notification.type}
      onClose={() => notificationsContext.removeNotification(notification.id)}
    >
      {notification.message}
    </Alert>
  );
};

export default Notification;
