import moment from 'moment';

export const isMetricSupported = (siteId, period) => {
  if (siteId === -1 || (Array.isArray(siteId) && siteId.length === 0)) {
    return period !== 'all';
  }

  return period !== 'all';
};

export const getPreviousDateRangePeriod = (currentPeriod, dateRange = null, outputFormat = 'YYYYMMDD') => {
  switch (currentPeriod) {
    case 'today':
      return {
        startDate: moment().subtract(1, 'day').format(outputFormat),
        endDate: moment().subtract(1, 'day').format(outputFormat),
      };
    case 'last-week':
      return {
        startDate: moment().subtract(2, 'week').startOf('week').format(outputFormat),
        endDate: moment().subtract(2, 'week').endOf('week').format(outputFormat),
      };
    case 'last-7-days':
      return {
        startDate: moment().subtract(14, 'days').format(outputFormat),
        endDate: moment().subtract(7, 'days').format(outputFormat),
      };
    case 'last-90-days':
      return {
        startDate: moment().subtract(180, 'days').format(outputFormat),
        endDate: moment().subtract(90, 'days').format(outputFormat),
      };
    case 'this-week':
      return {
        startDate: moment().subtract(1, 'week').startOf('week').format(outputFormat),
        endDate: moment().subtract(1, 'week').endOf('week').format(outputFormat),
      };
    case 'this-month':
    case 'this-mtd':
      return {
        startDate: moment().subtract(1, 'month').startOf('month').format(outputFormat),
        endDate: moment().subtract(1, 'month').endOf('month').format(outputFormat),
      };
    case 'this-quarter':
    case 'this-qtd':
      return {
        startDate: moment().subtract(1, 'quarter').startOf('quarter').format(outputFormat),
        endDate: moment().subtract(1, 'quarter').endOf('quarter').format(outputFormat),
      };
    case 'this-year':
    case 'this-ytd':
      return {
        startDate: moment().subtract(1, 'year').startOf('year').format(outputFormat),
        endDate: moment().subtract(1, 'year').endOf('year').format(outputFormat),
      };
    case 'custom':
      if (dateRange) {
        const daysBetweenDates = moment.duration(moment(dateRange.endDate).diff(dateRange.startDate)).asDays() + 1;
        return {
          startDate: moment(dateRange.startDate).subtract(daysBetweenDates, 'day').format(outputFormat),
          endDate: moment(dateRange.startDate).subtract(1, 'day').format(outputFormat),
        };
      }
      return null;
    default:
      return null;
  }
};
