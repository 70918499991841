import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItem,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import { getMyFrusMyEvents } from '../../../../helpers/RescuesHelper';
import RescuerEventMobileCard from '../Rescues/myFrus/RescuerEventMobileCard';
import useActiveUser from '../../../../hooks/useActiveUser';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import { fetchSiteEvents } from '../../../../actions/sites';
import useActiveSite from '../../../../hooks/useActiveSite';


const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    ...typography['h5'],
    top: -1,
    padding: spacing(1),
    paddingBottom: 0,
    borderRadius: spacing(4),
    borderTopLeftRadius: 0,
  },
  listSubItem: {
    display: 'block',
    padding: spacing(1),
  },
  ul: {
    marginBottom: spacing(3),
    backgroundColor: 'inherit',
    padding: 0,
  },
  ulFirst: {
    marginBottom: spacing(2),
    backgroundColor: 'inherit',
    padding: 0,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));


const MyEvents = () => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const dispatch = useDispatch();
  const eventsEntities = useSelector(state => state.entities.events);
  const eventsList = getMyFrusMyEvents(eventsEntities, activeUser.id);
  const { fetchMyEvents } = useRescuerDashboardActions();


  useEffect(() => {
    fetchMyEvents(false, true);
    dispatch(fetchSiteEvents(activeSite.id))
  }, [fetchMyEvents])

  if (!eventsList.inflight && eventsList.events.length === 0) {
    return (
      <Box className={classes.container}>
        <Box className={classes.noData}>
          <Typography color="textSecondary" variant="h5">
            You are not participating in any event.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <List className={classes.container} subheader={<li />}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            {!eventsList.inflight &&
            eventsList.events.map((event) => (
              <ListItem key={`events-${event.id}`} className={classes.listSubItem}>
                <RescuerEventMobileCard eventData={event} />
              </ListItem>
            ))}
          </ul>
        </li>
    </List>
  );
};

export default MyEvents;
