import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { TextFieldWrapper } from '../../../../components/Form/MuiFormWrapper';
import DonationContext from '../../../../context/DonationContext/DonationContext';
import { donationManageActions } from '../../../../helpers/donations';
import LocationForm from './Location/LocationForm';
import RescueSizeSelector from './RescueSize/RescueSizeSelector';
import FoodTypes from './FoodTypes/FoodTypes';
import useActiveSite from '../../../../hooks/useActiveSite';
import { formatAddress } from '../../../../helpers/formatters';
import PickupLocationForm from './Location/PickupLocationForm';
import { get, toString } from 'lodash';
import LbsSelector from './Lbs/LBSSelector';
import { DONATION_TYPE_REQUEST, DONATION_TYPE_REQUEST_APPROVAL } from '../../../../actions/donationNew';
import DonationSizeLegend from '../../../../containers/DonationSizeLegend';
import DonationSizeSelector from './RescueSize/DonationSizeSelector';
import { fetchDonationSizesIfNeeded } from '../../../../actions/donationSizes';
import CategoryButton from '../../../Rescue/RescueEdit/PastRescueForm/Components/CategoryButton';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../../../../services/auth';
import { Roles } from '../../../../models/roles';

const Description = () => {
  const activeSite = useActiveSite();
  const dispatch = useDispatch();
  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const donationSizes = useSelector(state => state.entities.donationSizes);
  const pickupLocationsEntities = useSelector(state => state.entities.sites.pickupLocations);
  const donorLocationsEntities = useSelector(state => state.entities.sites.donorsLocations);
  const isAdmin = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);

  const {
    description,
    location: foodDonor,
    food_donor_notes: donorNotes,
    pickup_location: pickupLocation,
    rescue_size: rescueSize,
    donation_size: foodSize,
    food_types: foodTypes,
    food_type_other: foodTypeOther,
    lbs: lbs,
    national_donation: nationalDonation,
  } = useSelector(state => state.ui.donation_new.draft);
  const rescueSizesArray = rescueSizes.sorted;
  const donationSizesArray = Object.values(donationSizes.byId);

  useEffect(() => {
    setDonorNotesValue(toString(donorNotes)); //update value on redux change, prevents from multiple page re-renders
  }, [donorNotes]);

  useEffect(() => {
    setLbsValue(lbs); //update value on redux change, prevents from multiple page re-renders
  }, [lbs]);

  useEffect(() => {
    dispatch(fetchDonationSizesIfNeeded());
  }, []);

  const [descriptionValue, setDescriptionValue] = useState(description);
  const [donorNotesValue, setDonorNotesValue] = useState(donorNotes);
  const [lbsValue, setLbsValue] = useState('');
  const [foodSizeLegend, setfoodSizeLegend] = useState(false);

  const {
    onFieldChange,
    errors,
    isNew,
    showFoodDonorSelector,
    isLoading,
    hasFoodDonorDefined,
    donationType,
  } = useContext(DonationContext);

  const rescueSizeValue = (() => {
    if (rescueSize) {
      return rescueSize.id;
    }

    if (rescueSizesArray && rescueSizesArray.length) {
      return rescueSizesArray[0].id;
    }

    return null;
  })();

  const showPickupLocationSelector =
    get(pickupLocationsEntities, ['byFoodDonorId', foodDonor ? foodDonor.id : null], []).length > 1;

  const handleCheck = ({ foodType, checked }) => {
    if (checked) {
      return onFieldChange(donationManageActions.add_food_type, foodType);
    }

    return onFieldChange(donationManageActions.remove_food_type, foodType);
  };

  const handleSubCategoryCheck = ({ category, checked }) => {
    if (checked) {
      return onFieldChange(donationManageActions.add_food_sub_category, category);
    }

    return onFieldChange(donationManageActions.remove_food_sub_category, category);
  };

  const renderFoodDonor = () => (
    <Box my={1}>
      <InputLabel>Food Donor</InputLabel>
      <Typography variant="body1">{get(foodDonor, 'name', 'Loading...')}</Typography>
    </Box>
  );

  const renderPickupLocation = () => (
    <Box my={1}>
      <InputLabel>Pickup Location</InputLabel>
      <Typography variant="body1">
        {pickupLocation ? formatAddress({ ...pickupLocation, st: pickupLocation.state }) : 'Loading...'}
      </Typography>
    </Box>
  );

  const handleExpand = () => {
    setfoodSizeLegend(!foodSizeLegend);
  };

  const handleNationalDonation = () => {
    console.log('test')
  }

  return (
    <Box display="grid" gridAutoFlow="row" gridRowGap={16} width={1}>
      <Grid container spacing={3}>
        <Grid container item xs={12} style={{ paddingTop: 0 }}>
          <Grid container item md={12} lg={6}>
            {isNew && (
              <Box display="flex" flexDirection="column" width={1}>
                {showFoodDonorSelector ? (
                  <LocationForm
                    defaultValue={foodDonor}
                    errors={errors}
                    activeSiteId={activeSite.id}
                    pickupLocations={pickupLocationsEntities}
                    donorLocations={donorLocationsEntities}
                    onFoodDonorChange={value => onFieldChange(donationManageActions.set_food_donor, value)}
                  />
                ) : (
                  renderFoodDonor()
                )}

                {showPickupLocationSelector ? (
                  <PickupLocationForm
                    pickupLocations={pickupLocationsEntities}
                    onPickupLocationChange={value => onFieldChange(donationManageActions.set_pickup_location, value)}
                    foodDonorId={foodDonor ? foodDonor.id : null}
                    pickupLocationId={pickupLocation ? pickupLocation.id : null}
                  />
                ) : (
                  renderPickupLocation()
                )}
              </Box>
            )}

            {!isNew && (
              <Box display="flex" flexDirection="column">
                {renderFoodDonor()}
                {renderPickupLocation()}
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ paddingTop: 0 }}>
          <TextFieldWrapper
            required
            disabled={isLoading || !hasFoodDonorDefined}
            name="description"
            data-testid="description"
            label="Food Description"
            id="description"
            onChange={event => setDescriptionValue(event.target.value)}
            onBlur={event => onFieldChange(donationManageActions.set_description, event.target.value)}
            fullWidth
            value={descriptionValue}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: !!description || undefined }}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ''}
          />
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center">
        <RescueSizeSelector
          disabled={isLoading || !hasFoodDonorDefined}
          value={rescueSizeValue}
          rescueSizes={rescueSizesArray}
          onChange={(value) => onFieldChange(donationManageActions.set_rescue_size, value)}
        />

        <LbsSelector
          disabled={isLoading || !hasFoodDonorDefined}
          rescueSizeValue={rescueSizeValue}
          rescueSizes={rescueSizesArray}
          lbs={lbsValue}
          onValueChange={newValue => onFieldChange(donationManageActions.set_lbs_value, newValue)}
          error={errors ? errors.lbs : null}
          showOnlyTextField={true}
        />
      </Box>

      {isAdmin && (
        <Box marginLeft="auto">
          <CategoryButton
            variant={nationalDonation ? 'contained' : 'outlined'}
            onCategoryClick={() =>
              onFieldChange(donationManageActions.set_national_donation, { national_donation: !nationalDonation })
            }
            disabled={isLoading || !hasFoodDonorDefined}
          >
            National Donation
          </CategoryButton>
        </Box>
      )}

      <FoodTypes
        error={errors}
        disabled={isLoading || !hasFoodDonorDefined}
        foodTypes={foodTypes}
        foodTypeOther={foodTypeOther}
        onFoodTypeChange={handleCheck}
        onSubCategoryChange={handleSubCategoryCheck}
        onFoodTypeOtherChange={value => onFieldChange(donationManageActions.set_food_type_other, value)}
      />

      {[DONATION_TYPE_REQUEST_APPROVAL, DONATION_TYPE_REQUEST].includes(donationType) && (
        <TextFieldWrapper
          name="food_donor_notes"
          data-testid="food_donor_notes"
          label="Notes for your Site Director"
          id="food_donor_notes"
          onChange={event => setDonorNotesValue(event.target.value)}
          onBlur={event => onFieldChange(donationManageActions.set_donor_notes, event.target.value)}
          fullWidth
          value={donorNotesValue}
          variant="outlined"
          size="small"
          helperText={errors.food_donor_notes ? errors.food_donor_notes.message : ''}
        />
      )}
    </Box>
  );
};

export default Description;
