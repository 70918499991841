import { objectFromArray } from '../helpers';
import * as featureFlagsActions from '../actions/featureFlags';

export const initialSitesState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const featureFlagsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case featureFlagsActions.REQUEST_FEATURE_FLAGS:
      return {
        ...state,
        inflight: true,
      };
    case featureFlagsActions.RECEIVE_FEATURE_FLAGS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.featureFlags, featureFlag => featureFlag.id),
        allIds: action.featureFlags.map(featureFlag => featureFlag.id),
        lastUpdated: action.receivedAt,
      };
    case featureFlagsActions.REQUEST_FEATURE_FLAGS_FAILED:
      return {
        ...state,
        inflight: false,
      };
    default:
      return state;
  }
};

export default featureFlagsReducer;
