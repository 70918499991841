import { omit } from 'lodash'
import moment from 'moment';
import { SET_AUTHENTICATED_USER } from '../actions/auth';
import {
  SET_UI_FILTER,
  SET_ORIGINAL_RESCUE,
  UPDATE_DRAFT_RESCUE,
  UPDATE_TOUR_STEPPER,
  DISABLE_TOUR_STEPPER,
  SHOW_A2HS,
  DISMISS_A2HS,
  SET_UI_DISABLED,
  SET_RESCUE_CLOSE_STEP,
} from '../actions/ui';
import {
  DISMISS_EMAIL_VERIFICATION,
  DISMISS_PHONE_VERIFICATION,
  DISMISS_CLOSE_RESCUE_REMINDER_NOTIFICATION,
  RESET_PERSISTED_UI,
  SET_DEFAULT_DASHBOARD,
  RESET_DEFAULT_DASHBOARD,
  SHOW_EMAIL_VERIFICATION,
  SHOW_PHONE_VERIFICATION,
  HIDE_FREQUENCY_CHANGE_DIALOG,
  CLOSE_DRAWER,
  OPEN_DRAWER,
  DISMISS_ALLOW_PUSH_NOTIFICATION,
  HIDE_UNNASIGNED_USER_BANNER,
  HIDE_ANNUAL_APPEAR_BANNER,
} from '../actions/uiPersisted';
import {
  SET_DONATION_DESCRIPTION,
  SET_DONATION_LBS_VALUE,
  SET_DONATION_FOOD_TYPE_OTHER,
  SET_DONATION_ADD_FOOD_TYPE,
  SET_DONATION_REMOVE_FOOD_TYPE,
  SET_DONATION_RESCUE_SIZE,
  SET_DONATION_FOOD_SIZE,
  SET_DONATION_START_DATE,
  SET_DONATION_END_DATE,
  SET_DONATION_APPEND_PICKUP,
  SET_DONATION_FREQUENCY,
  SET_DONATION_FREQUENCY_INTERVAL,
  SET_DONATION_ALL_DAYS_ARE_THE_SAME,
  SET_DONATION_ALL_PICKUPS_ARE_THE_SAME,
  CREATE_DRAFT_DONATION,
  SET_DONATION_REMOVE_PICKUP,
  SET_DONATION_ADD_DAY_OF_WEEK,
  SET_DONATION_REMOVE_DAY_OF_WEEK,
  SET_DONATION_PICKUP_RECEIVER,
  SET_DONATION_FOOD_DONOR,
  SET_DONATION_PICKUP_LOCATION,
  CLEAR_DONATION_ALL_DAYS_ARE_THE_SAME,
  CLEAR_DONATION_ALL_PICKUPS_ARE_THE_SAME,
  SET_DONATION_PICKUP_RESCUER_NOTES,
  SET_DONATION_RESCUES,
  SET_DONATION_PICKUPS,
  SET_DONATION_PICKUP_BEGIN,
  SET_DONATION_PICKUP_END,
  SET_DONATION_DIFF_INFLIGHT,
  SET_DONATION_UNDO_LAST_ACTION,
  SET_DONATION_REDO_LAST_ACTION,
  RESET_DONATION,
  SET_DONATION_PAUSE_DATE,
  SET_DONATION_RESUME_DATE,
  CLEAR_DONATION,
  SET_DRAFT_DONATION,
  SET_DONATION_RESCUE_PICKUP_BEGIN,
  SET_DONATION_RESCUE_PICKUP_END,
  SET_DONATION_RESCUE_RESCUER,
  SET_DONATION_RESCUE_RESCUER_NOTES,
  SET_DONATION_PICKUP_ADOPTER,
  SET_DONATION_RESCUE_CANCELLER,
  SET_DONATION_REMOVE_DAY_OF_MONTH,
  SET_DONATION_ADD_DAY_OF_MONTH,
  SET_DONATION_DAY_OF_MONTH_ORDINAL,
  SET_DONATION_DAY_OF_MONTH_DAY,
  SET_DONATION_FORM_ERROR,
  REMOVE_DONATION_FORM_ERROR,
  CLEAR_DONATION_FORM_ERROR,
  SET_DONATION_DONOR_NOTES,
  SET_DONATION_PICKUP_RESCUER,
  SET_DONATION_ADD_FOOD_SUB_CATEGORY,
  SET_DONATION_REMOVE_FOOD_SUB_CATEGORY,
  SET_NATIONAL_DONATION,
} from '../actions/donationNew';
import { DISTANCE_ALL_VALUE } from '../components/RescuersScheduleFilters/filters/DistanceSliderFilter';

const { Map } = require('immutable');

export const initialRescuerScheduleFilters = {
  showInitialTourStepper: {
    value: true,
  },
  rescueSizes: {
    value: [],
  },
  showInitialInfiniteScrollTour: {
    value: true,
  },
  query: {
    value: '',
  },
  distance: {
    value: DISTANCE_ALL_VALUE,
  },
  date: {
    value: null, // used only for mobile view
  },
  days: {
    value: [],
  },
  showOnlyAvailableRescues: {
    value: false,
  },
  donorReceiverList: {
    value: [],
  },
  siteIds: {
    value: [],
  },
};
const uiInitialState = {
  donation_new: {
    currentState: [],
    futureState: [],
    errors: {},
    diffInflight: false,
    rescues: [],
    draft: null,
    original: null,
  },
  donation: {
    original: null,
    draft: null,
  },
  filters: {},
  rescue: {
    original: null,
    draft: null,
  },
  tourStepper: {
    enabled: true,
    steps: [], // due to intro.js each tour needs at least 2 steps!
    initialStep: 0,
  },
  showA2HSTutorial: false,
  persisted: {
    uiDisabled: false,
    isDrawerOpen: false,
    emailVerificationNotificationVisible: undefined,
    phoneVerificationNotificationVisible: undefined,
    closeRescueReminderNotificationLastDismissDate: undefined,
    allowPushNotificationHide: undefined,
    hideUnnasignedUserBanner: undefined,
    hideAnnualAppearBanner: undefined,
    donationFrequencyChangeDialogVisible: true,
    defaultDashboard: {
      route: undefined,
      params: undefined,
    },
  },
  rescueCloseStep: 0,
};

const uiReducer = (state = uiInitialState, action) => {
  switch (action.type) {
    case SET_UI_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters, // all users ids
          [action.payload.userId]: {
            ...state.filters[action.payload.userId], // other reports settings
            [action.payload.filterId]: action.payload.filterData,
          },
        },
      };
    case CREATE_DRAFT_DONATION:
      return {
        ...state,
        donation_new: {
          currentState: [],
          futureState: [],
          diffInflight: false,
          errors: {},
          rescues: action.rescues,
          draft: action.donation,
          original: action.donation,
        },
      };

    case SET_DRAFT_DONATION:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          rescues: action.rescues || state.donation_new.rescues,
          draft: action.donation,
        },
      };

    case CLEAR_DONATION:
      return {
        ...state,
        donation_new: {
          currentState: [],
          futureState: [],
          diffInflight: false,
          rescues: [],
          draft: null,
          original: null,
        },
      };

    case RESET_DONATION:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [],
          futureState: [],
          diffInflight: false,
          rescues: [],
        },
      };

    case SET_DONATION_FORM_ERROR:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          errors: {
            ...state.donation_new.errors,
            [action.field]: action.error,
          },
        },
      };

    case REMOVE_DONATION_FORM_ERROR:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          errors: omit(state.donation_new.errors, action.field),
        },
      };

    case CLEAR_DONATION_FORM_ERROR:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          errors: {},
        },
      };

    case SET_DONATION_RESCUES:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          rescues: action.rescues,
        },
      };

    case SET_DONATION_UNDO_LAST_ACTION:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState.slice(0, -1)],
          futureState: [...state.donation_new.futureState, state.donation_new.currentState.pop()],
        },
      };

    case SET_DONATION_REDO_LAST_ACTION:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, ...state.donation_new.futureState.slice(-1)],
          futureState: [...state.donation_new.futureState.slice(0, -1)],
        },
      };

    case SET_DONATION_DIFF_INFLIGHT:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: action.inflight,
        },
      };

    case SET_DONATION_PICKUPS:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          draft: {
            ...state.donation_new.draft,
            pickup_specs: action.pickups,
          },
        },
      };

    case SET_DONATION_FOOD_DONOR:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, { type: 'SET_FOOD_DONOR', food_donor_id: action.id }],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            location: {
              id: action.id,
              name: action.name,
            },
          },
        },
      };
    case SET_DONATION_PICKUP_LOCATION:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            { type: 'SET_PICKUP_LOCATION', pickup_location_id: action.id },
          ],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            pickup_location: {
              id: action.id,
              name: action.name,
            },
          },
        },
      };
    case SET_DONATION_DESCRIPTION:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            { type: 'SET_DESCRIPTION', description: action.description },
          ],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            description: action.description,
          },
        },
      };

    case SET_DONATION_LBS_VALUE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, { type: 'SET_LBS', lbs: action.lbsValue }],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            description: action.description,
          },
        },
      };

    case SET_DONATION_RESCUE_SIZE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              type: 'SET_RESCUE_SIZE',
              rescue_size_id: action.rescueSize ? action.rescueSize.id : null,
            },
          ],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            rescue_size: action.rescueSize
              ? {
                  id: action.rescueSize.id,
                  name: action.rescueSize.name,
                }
              : null,
          },
        },
      };

    case SET_DONATION_FOOD_SIZE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              type: 'SET_DONATION_SIZE',
              donation_size_id: action.foodSize ? action.foodSize.id : null,
            },
          ],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            donation_size: action.foodSize
              ? {
                  id: action.foodSize.id,
                  name: action.foodSize.name,
                }
              : null,
          },
        },
      };
    case SET_DONATION_ADD_FOOD_TYPE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, { type: 'ADD_FOOD_TYPE', food_type: action.foodType }],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            food_types: state.donation_new.draft.food_types.concat(action.foodType),
          },
        },
      };
    case SET_DONATION_REMOVE_FOOD_TYPE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, { type: 'REMOVE_FOOD_TYPE', food_type: action.foodType }],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            food_types: state.donation_new.draft.food_types.filter(ft => ft !== action.foodType),
          },
        },
      };
    // TO DO WHEN API IS READY
    // case SET_DONATION_ADD_FOOD_SUB_CATEGORY:
    //   return {
    //     ...state,
    //     donation_new: {
    //       ...state.donation_new,
    //       currentState: [...state.donation_new.currentState, { type: 'ADD_FOOD_SUB_CATEGORY', food_sub_cactegory: action.category }],
    //       futureState: [],
    //       draft: {
    //         ...state.donation_new.draft,
    //         food_sub_cactegory: state.donation_new.draft.food_sub_cactegory.concat(action.category),
    //       },
    //     },
    //   };
    // case SET_DONATION_REMOVE_FOOD_SUB_CATEGORY:
    //   return {
    //     ...state,
    //     donation_new: {
    //       ...state.donation_new,
    //       currentState: [...state.donation_new.currentState, { type: 'REMOVE_FOOD_SUB_CATEGORY', food_sub_cactegory: action.category }],
    //       futureState: [],
    //       draft: {
    //         ...state.donation_new.draft,
    //         food_types: state.donation_new.draft.food_sub_cactegory.filter(ft => ft !== action.category),
    //       },
    //     },
    //   };
    case SET_DONATION_FOOD_TYPE_OTHER:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            { type: 'SET_FOOD_TYPE_OTHER', food_type_other: action.foodTypeOther },
          ],
          futureState: [],
        },
      };
    case SET_DONATION_ADD_DAY_OF_WEEK:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'ADD_DAY_OF_WEEK',
              day: action.day,
            },
          ],
          futureState: [],
        },
      };
    case SET_DONATION_REMOVE_DAY_OF_WEEK:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'REMOVE_DAY_OF_WEEK',
              day: action.day,
            },
          ],
          futureState: [],
        },
      };
    case SET_DONATION_ADD_DAY_OF_MONTH:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'ADD_DAY_OF_MONTH',
              day: action.day,
              ordinal: action.ordinal,
            },
          ],
          futureState: [],
        },
      };
    case SET_DONATION_DAY_OF_MONTH_DAY:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_DAY_OF_MONTH_DAY',
              day: action.day,
              ordinal: action.ordinal,
              new_day: action.newDay,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_DAY_OF_MONTH_ORDINAL:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_DAY_OF_MONTH_ORDINAL',
              day: action.day,
              ordinal: action.ordinal,
              new_ordinal: action.newOrdinal,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_REMOVE_DAY_OF_MONTH:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'REMOVE_DAY_OF_MONTH',
              day: action.day,
              ordinal: action.ordinal,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_START_DATE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              type: 'SET_START_DATE',
              start_date: action.startDate,
              frequency: action.frequency || state.donation_new.draft.frequency,
              frequency_every: action.frequencyEvery || state.donation_new.draft.frequency_every,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_END_DATE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              type: 'SET_END_DATE',
              end_date: action.endDate,
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_PAUSE_DATE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              type: 'SET_PAUSE_DATE',
              pause_date: action.pauseDate,
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_RESUME_DATE:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              type: 'SET_RESUME_DATE',
              resume_date: action.resumeDate,
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_FREQUENCY:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, { type: 'SET_FREQUENCY', frequency: action.frequency }],
          futureState: [],
        },
      };

    case SET_DONATION_FREQUENCY_INTERVAL:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, { type: 'SET_FREQUENCY_EVERY', ...action.payload }],
          futureState: [],
        },
      };

    case SET_DONATION_ALL_DAYS_ARE_THE_SAME:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_ALL_DAYS_ARE_THE_SAME',
            },
          ],
          futureState: [],
        },
      };

    case CLEAR_DONATION_ALL_DAYS_ARE_THE_SAME:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'CLEAR_ALL_DAYS_ARE_THE_SAME',
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_ALL_PICKUPS_ARE_THE_SAME:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_ALL_PICKUPS_ARE_THE_SAME',
            },
          ],
          futureState: [],
        },
      };

    case CLEAR_DONATION_ALL_PICKUPS_ARE_THE_SAME:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'CLEAR_ALL_PICKUPS_ARE_THE_SAME',
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_APPEND_PICKUP:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'ADD_PICKUP',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_REMOVE_PICKUP:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'REMOVE_PICKUP',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_PICKUP_RESCUER_NOTES:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_PICKUP_RESCUER_NOTES',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_PICKUP_RECEIVER:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_PICKUP_RECEIVER',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_PICKUP_ADOPTER:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_PICKUP_ADOPTER',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_PICKUP_RESCUER:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_PICKUP_RESCUER',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_PICKUP_BEGIN:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_PICKUP_BEGIN',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_PICKUP_END:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_PICKUP_END',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_RESCUE_PICKUP_BEGIN:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_RESCUE_PICKUP_BEGIN',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_RESCUE_PICKUP_END:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_RESCUE_PICKUP_END',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_RESCUE_RESCUER_NOTES:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_RESCUE_RESCUER_NOTES',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_RESCUE_RESCUER:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_RESCUE_RESCUER',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_RESCUE_CANCELLER:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          diffInflight: false,
          currentState: [
            ...state.donation_new.currentState,
            {
              frequency: state.donation_new.draft.frequency,
              frequency_every: state.donation_new.draft.frequency_every,
              type: 'SET_RESCUE_CANCELLER',
              ...action.payload,
            },
          ],
          futureState: [],
        },
      };

    case SET_DONATION_DONOR_NOTES:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [
            ...state.donation_new.currentState,
            { type: 'SET_DONOR_NOTES', food_donor_notes: action.donorNotes },
          ],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            food_donor_notes: action.donorNotes,
          },
        },
      };
    case SET_NATIONAL_DONATION:
      return {
        ...state,
        donation_new: {
          ...state.donation_new,
          currentState: [...state.donation_new.currentState, { type: 'SET_NATIONAL_DONATION', ...action.payload }],
          futureState: [],
          draft: {
            ...state.donation_new.draft,
            national_donation: action.payload.national_donation,
          },
        },
      };

    case SET_UI_DISABLED:
      return {
        ...state,
        uiDisabled: action.status,
      };
    case SHOW_A2HS:
      return {
        ...state,
        showA2HSTutorial: true,
      };
    case DISMISS_A2HS:
      return {
        ...state,
        showA2HSTutorial: false,
      };
    case OPEN_DRAWER:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          isDrawerOpen: true,
        },
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          isDrawerOpen: false,
        },
      };
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        isDrawerOpen: action.idTokenPayload == null ? false : state.isDrawerOpen,
      };
    case SET_ORIGINAL_RESCUE:
      const rescue = Map(action.rescue).toJS();

      return {
        ...state,
        rescue: {
          original: rescue,
          draft: Map(rescue).toJS(),
        },
      };
    case UPDATE_DRAFT_RESCUE:
      const draft_rescue = Map(action.rescue).toJS();

      return {
        ...state,
        rescue: {
          ...state.rescue,
          draft: draft_rescue,
        },
      };
    case RESET_PERSISTED_UI:
      return {
        ...state,
        persisted: { ...uiInitialState.persisted },
      };
    case SHOW_EMAIL_VERIFICATION:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          emailVerificationNotificationVisible: true,
        },
      };
    case DISMISS_EMAIL_VERIFICATION:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          emailVerificationNotificationVisible: false,
        },
      };
    case SHOW_PHONE_VERIFICATION:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          phoneVerificationNotificationVisible: true,
        },
      };
    case HIDE_FREQUENCY_CHANGE_DIALOG:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          donationFrequencyChangeDialogVisible: false,
        },
      };
    case DISMISS_PHONE_VERIFICATION:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          phoneVerificationNotificationVisible: false,
        },
      };
    case DISMISS_CLOSE_RESCUE_REMINDER_NOTIFICATION:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          closeRescueReminderNotificationLastDismissDate: moment().format('YYYYMMDD'),
        },
      };
    case DISMISS_ALLOW_PUSH_NOTIFICATION:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          allowPushNotificationHide: true,
        },
      };
    case HIDE_UNNASIGNED_USER_BANNER:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          hideUnnasignedUserBanner: true,
        },
      };
    case HIDE_ANNUAL_APPEAR_BANNER:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          hideAnnualAppearBanner: moment().format('YYYY-MM-DD HH:mm'),
        },
      };
    case SET_DEFAULT_DASHBOARD:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          defaultDashboard: action.payload,
        },
      };
    case RESET_DEFAULT_DASHBOARD:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          defaultDashboard: undefined,
        },
      };
    case UPDATE_TOUR_STEPPER:
      return {
        ...state,
        tourStepper: {
          enabled: action.payload.enabled,
          steps: action.payload.steps,
          initialStep: action.payload.initialStep,
        },
      };
    case DISABLE_TOUR_STEPPER:
      return {
        ...state,
        tourStepper: {
          ...state.tourStepper,
          enabled: false,
        },
      };
    case SET_RESCUE_CLOSE_STEP:
      return {
        ...state,
        rescueCloseStep: action.step,
      };

    default:
      return state;
  }
};

export default uiReducer;
