import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, generatePath, Route, Switch } from 'react-router-dom';
import routes from '../../../../routes';
import { Box, Typography } from '@material-ui/core';
import RescueMobileCard from '../../../Rescue/RescueMobileCard';
import EventRescuerDetailsView from '../../../Events/EventRescuerDetails/EventRescuerDetailsView';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import EventsMobile from '../Events/EventsMobile';
import { makeStyles } from '@material-ui/core/styles';
import { PersonPin as PersonPinIcon, EmojiPeople as EmojiPeopleIcon } from '@material-ui/icons';
import MyEvents from './MyEvents';
import EventsBreadcrumb from './Components/EventsBreadcrumb';
import MobileWrapper from '../../../../components/Common/MobileBottomNavWrapper';
import useActiveUser from '../../../../hooks/useActiveUser';
import { getMyFrusMyEvents } from '../../../../helpers/RescuesHelper';

export const RESCUER_MOBILE_DASHBOARD_TAB_MY_EVENTS = 'my-events';
export const RESCUER_MOBILE_DASHBOARD_TAB_EVENTS = 'events';

export const paths = [
  generatePath(routes.mobileDashboardEvents, { tab: RESCUER_MOBILE_DASHBOARD_TAB_MY_EVENTS }),
  generatePath(routes.mobileDashboardEvents, { tab: RESCUER_MOBILE_DASHBOARD_TAB_EVENTS }),
  routes.mobileDashboardEventSignup,
];

export function RescuesLoader({ highlighted, rows }) {
  return (
    <Box display="grid" gridRowGap={16} width="100%">
      {[...Array(rows || 10).keys()].map((index) => (
        <RescueMobileCard key={index} highlighted={highlighted} isLoading />
      ))}
    </Box>
  );
}

export function NoRescueCard({ text }) {
  return (
    <Box
      boxShadow={25}
      p={2}
      css={{
        width: '100%',
        borderRadius: 16,
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      <Typography color="textSecondary" variant="body1">
        {text}
      </Typography>
    </Box>
  );
}

const TABS_HEIGHT = 85;

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1.8rem',
  },
}));

const RescuerRescuesDashboardMobileContainer = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { fetchMyEvents, fetchEventsTab } = useRescuerDashboardActions();
  const activeUser = useActiveUser();
  const eventsEntities = useSelector(state => state.entities.events);
  const myEventsEntities = getMyFrusMyEvents(eventsEntities, activeUser.id);

  const getActiveTab = () => {
    let path = paths.indexOf(url);

    // mark Events tab as active whenever we are on events tab or event details page (mobile)
    if (url.includes('/signup')) {
      // get index >= 0, let Math.max decide which dashboard is presented
      path = Math.max(
        paths.indexOf(generatePath(routes.mobileDashboardEvents, { tab: RESCUER_MOBILE_DASHBOARD_TAB_EVENTS }))
      );
    }

    return Math.max(0, path); // no negatives numbers
  };

  useEffect(() => {
    fetchMyEvents(false, true);
    fetchEventsTab(false, true);
  }, [fetchEventsTab]);

  const tabs = [
    {
      label: 'My Events',
      url: paths[0],
      icon: <EmojiPeopleIcon classes={{ root: classes.icon }} />,
      badgeCounter: myEventsEntities.events.length,
      testId: 'rescuerDesktopTab0',
    },
    {
      label: 'Events',
      url: paths[1],
      icon: <PersonPinIcon classes={{ root: classes.icon }} />,
      badgeCounter: eventsEntities.allIds.length,
      testId: 'rescuerDesktopTab1',
    },
  ];

  return (
    <MobileWrapper
      containerId="rescuer-dashboard-mobile-div"
      breadcrumb={<EventsBreadcrumb />}
      activeTab={getActiveTab()}
      bottomTabs={tabs}
      bottomBarId='rescuer-mobile-app-bar'
      pageContent={
        <Switch>
          <Route path={routes.mobileDashboardEventSignup} component={EventRescuerDetailsView} />
          <Route path={[generatePath(routes.mobileDashboardEvents), paths[0]]} exact component={MyEvents} />
          <Route path={paths[1]} exact component={EventsMobile} />
        </Switch>
      }
    />
  );
};

export default RescuerRescuesDashboardMobileContainer;
