import * as emailsActions from '../actions/emails';
import { objectFromArray } from '../helpers';
import * as usersModel from '../models/users';
import _array from 'lodash/array';

export const initialSitesState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const emailsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case emailsActions.REQUEST_EMAILS:
      return {
        ...state,
        inflight: true,
      };
    case emailsActions.RECEIVE_EMAILS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.emails, e => e.id),
        allIds: action.emails.map(e => e.id),
        lastUpdated: action.receivedAt,
      };
    case emailsActions.REQUEST_EMAIL:
      return {
        ...state,
        inflight: true,
      };
    case emailsActions.RECEIVE_EMAIL:
      return {
        ...state,
        inflight: false,
        ..._addOrUpdateEmail(action.email, state.byId, state.allIds),
      };
    default:
      return state;
  }
};

const _addOrUpdateEmail = (email, byId, allIds) => ({
  byId: {
    ...byId,
    ...{
      [email.id]: email,
    },
  },
  allIds: _array.uniq([...allIds, ...[email.id]]),
});

export default emailsReducer;
