import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import SendSmsForm from '../components/SendSmsForm';
import * as sitesActions from '../actions/sites';
import * as sitesApi from '../api/sites';
import { useHistory } from 'react-router-dom';
import routes from '../routes';
import * as authService from '../services/auth';

const SendSmsView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const site = useSelector(state => state.app.site);
  const siteUsers = useSelector(state => state.entities.sites.users);

  useEffect(() => {
    if (!location.state || !location.state.usersIds) {
      history.push(routes.index)
    }

    dispatch(sitesActions.fetchSiteUsers(site.id));
  }, [site.id, dispatch]);

  const handleSubmit = values => {
    const currentlyLoggedInOrImpersonatingUser = authService.getCurrentlyLoggedInOrImpersonatingUser();

    return sitesApi.sendSiteSms(
      site.id,
      {
        ...values,
        created_by_id: currentlyLoggedInOrImpersonatingUser.id,
      }
    );
  };

  if (siteUsers.inflight || siteUsers.bySiteId[site.id] === undefined) {
    return 'Loading users...';
  }

  const foundSelectedUsers = siteUsers
    .bySiteId[site.id]
    .filter(r => location.state.usersIds.indexOf(r.id) !== -1);
  const verifiedRecipients = foundSelectedUsers.filter(r => r.phone_verified);
  const unverifiedRecipients = foundSelectedUsers.filter(r => !r.phone_verified);

  return (
    <Box mb={12}>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Typography color="textPrimary">Send SMS</Typography>
      </Breadcrumbs>

      <SendSmsForm
        verifiedRecipients={verifiedRecipients}
        unverifiedRecipients={unverifiedRecipients}
        submitCallback={handleSubmit}
      />
    </Box>
  );
};

export default SendSmsView;
