import React, { Component } from 'react';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { Checkbox, TextField } from 'final-form-material-ui';
import Bluebird from 'bluebird';
import moment from 'moment';
import { withStyles, InputLabel, FormControlLabel, Box, Typography, Button, TextField as TextFieldMaterial, Link, IconButton } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon, Clear as ClearIcon } from '@material-ui/icons';
import snackbarHelper from '../helpers/snackbarHelper';
import SelectMultiple from './SelectMultiple';
import SelectPrimaryContactUser, {
  getPrimaryContactUserForm,
  getSelectPrimaryContactUserValidationErrors,
} from './SelectPrimaryContactUser';
import SubmitWithSendEmailConfirmation from './SubmitWithSendEmailConfirmation';
import setFieldTouched from 'final-form-set-field-touched';
import { isEmailValid } from '../helpers/validators';
import HtmlTooltip from './Common/HtmlTooltip';
import { generateS3Url, s3FileUpload } from '../api/upload';

class SiteForm extends Component {
  constructor(props) {
    super(props);

    this.formApi = undefined;

    const { site } = props;
    const typesOptions = [
      { label: 'Volunteer', value: 'volunteer' },
      { label: 'Stipend', value: 'stipend' },
      { label: 'Licensee', value: 'licensee' },
      { label: 'Probationer', value: 'probationer' },
    ];
    const timezonesOptions = [
      { label: 'Hawaii', value: 'hawaii' },
      { label: 'Alaska', value: 'alaska' },
      { label: 'Pacific', value: 'pacific' },
      { label: 'Mountain', value: 'mountain' },
      { label: 'Central', value: 'central' },
      { label: 'Eastern', value: 'eastern' },
    ];

    this.state = {
      typesOptions,
      timezonesOptions,
      loadSuggestionsMinChars: 2,
      selectedType: site ? typesOptions.find(t => t.value === site.type) : null,
      selectedTimezone: site ? timezonesOptions.find(t => t.value === site.timezone) : null,
      selectedZipCodes: site ? site.zip_codes.map(zipCode => ({ label: zipCode, value: zipCode })) : [],
      sendInvitationEmail: false,
      zipCodePlaceholder: 'Select zip codes',
      logo: site?.logo_url || null,
      newLogoUploaded: null,
    };
  }

  componentDidMount() {
    const { site } = this.props;

    // mark below fields as invalid on form initialization
    if (this.formApi && site) {
      setTimeout(() => {
        this.formApi.batch(() => {
          this.formApi.mutators.setFieldTouched('primary_contact_email', true);
          this.formApi.mutators.setFieldTouched('primary_contact_first_name', true);
          this.formApi.mutators.setFieldTouched('primary_contact_last_name', true);
          this.formApi.mutators.setFieldTouched('primary_contact_phone', true);
        });
      }, 1);
    }
  }

  handleSubmit = values => {
    const { site, submitCallback } = this.props;
    const { selectedType, selectedTimezone, selectedZipCodes, sendInvitationEmail } = this.state;
    const siteData = {
      ...values,
      type: selectedType ? selectedType.value : null,
      timezone: selectedTimezone ? selectedTimezone.value : null,
      send_invitation: sendInvitationEmail,
      zip_codes: selectedZipCodes.map(selectedZipCode => selectedZipCode.value),
      disabled_at: (() => {
        const currentTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
        
        if (site) {
          if (site.disabled_at === null && values.disabled) {
            return currentTimestamp;
          }
  
          if (site.disabled_at !== null && !values.disabled) {
            return null;
          }
  
          return site.disabled_at !== null ? moment(site.disabled_at).format('YYYY-MM-DD HH:mm:ss') : null;
        }
        
        return values.disabled ? currentTimestamp : null;
      })(),
    };

    return Bluebird
      .try(() => {
        if (site) {
          return submitCallback(site.id, siteData);
        }

        return submitCallback(siteData);
      })
      .then(() => snackbarHelper.success(`Site ${site ? 'updated' : 'created'} successfully!`))
      .catch(err => {
        snackbarHelper.error(err.message);

        return {
          [FORM_ERROR]: 'POST failed',
        };
      });
  };

  beforeSubmit = values => {
    const { newLogoUploaded } = this.state;
    if (newLogoUploaded) {
      Bluebird.try(() => generateS3Url(newLogoUploaded.name, 'sites-assets'))
        .then((res) => res.json())
        .then((res) => res.data)
        .then((signedData) =>
          Bluebird.try(() =>
            s3FileUpload({
              url: signedData.url,
              file: newLogoUploaded.file,
            }).then(() => {
              const siteData = {
                ...values,
              };
              const matches = signedData.url.match(/(.*)\?/);

              if (matches && Array.isArray(matches) && matches[1]) {
                siteData.logo = matches[1];
              }

              return this.handleSubmit(siteData);
            })
          )
        );
    } else {
      this.handleSubmit(values);
    }
  };

  handleChangeType = value => this.setState({
      selectedType: value !== null ? value : null,
    });

  handleChangeTimezone = value => this.setState({
      selectedTimezone: value !== null ? value : null,
    });

  handleChangeMulti = value => {
    this.setState({
      selectedZipCodes: value !== null ? value : [],
    });
  };

  validate = values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (values.reply_to_email && !isEmailValid(values.reply_to_email)) {
      errors.reply_to_email = 'Please provide a valid email.';
    }

    return {
      ...errors,
      ...getSelectPrimaryContactUserValidationErrors(values),
    };
  };

  loadingMessage = value => {
    const { loadSuggestionsMinChars } = this.state;

    if (value.inputValue.length <= loadSuggestionsMinChars) {
      return null;
    }

    return 'Loading...';
  };

  loadZipCodes = value => {
    const { fetchZipCodes } = this.props;
    const { loadSuggestionsMinChars } = this.state;

    if (value.length <= loadSuggestionsMinChars) {
      return new Promise(resolve => {
        resolve([]);
      });
    }

    return fetchZipCodes(value).then(res => {
      return res.zipCodes.map(zipCode => ({
        label: `${zipCode.zip_code}, ${zipCode.city}, ${zipCode.state}`,
        value: zipCode.zip_code,
      }));
    });
  };

  submitFormHandler = sendInvitationEmail =>
    this.setState({ sendInvitationEmail: sendInvitationEmail }, () => this.formApi.submit());

  readFiles = (event, input) => {
    event.preventDefault();
    const fileTmp = event.target.files[0];
    const file = {
      ...fileTmp,
      name: fileTmp.name,
      id: fileTmp.name,
      progress: '',
      src: URL.createObjectURL(fileTmp),
      file: fileTmp,
    };

    this.setState({ logo: file.src });
    this.setState({ newLogoUploaded: file });
    input.onChange(file.src);
  };

  removeLogo = (input) => {
    this.setState({ logo: null });
    input.onChange(null);
  };

  resetBranding = () => {
    this.formApi.change('primary_color', null);
    this.formApi.change('secondary_color', null);
    this.formApi.change('button_text_color', null);
    this.formApi.change('toolbar_text_color', null);
    this.formApi.change('logo', null);
    this.setState({ logo: null });
  };

  render() {
    const { site = {}, showDisabledCheckbox = false, classes, hasCustomBranding } = this.props;
    const {
      typesOptions,
      timezonesOptions,
      selectedType,
      selectedTimezone,
      selectedZipCodes,
      zipCodePlaceholder,
      logo,
    } = this.state;
    const isUpdate = !!site.id;

    const hexPattern = ['#', /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/];

    return (
      <Form
        mutators={{
          setFieldTouched,
        }}
        onSubmit={this.beforeSubmit}
        validate={this.validate}
        initialValues={{
          name: site.name,
          reply_to_email: site.reply_to_email,
          primary_contact_id: site.primary_contact_id,
          notes: site.notes,
          zip_codes: site.zip_codes && Array.isArray(site.zip_codes) ? site.zip_codes.join(',') : null,
          disabled: site.disabled_at !== null,
          ...getPrimaryContactUserForm(site.primary_contact_user),
          primary_color: site?.primary_color,
          secondary_color: site?.secondary_color,
          button_text_color: site?.button_text_color,
          toolbar_text_color: site?.toolbar_text_color,
          logo: site?.logo_url,
        }}
        render={({ handleSubmit, submitting, pristine, invalid, dirtySinceLastSubmit, form, values }) => {
          this.formApi = form;
          return (
            <form onSubmit={handleSubmit}>
              <Field name="name" fullWidth required component={TextField} type="text" label="Site Name" autoFocus />

              <div>
                <InputLabel shrink>Type</InputLabel>

                <Field
                  name="type"
                  render={({ input }) => (
                    <Select
                      name="type"
                      onChange={value => {
                        this.handleChangeType(value);

                        input.onChange(value !== null ? value.value : null);
                      }}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                      value={selectedType}
                      options={typesOptions}
                      isClearable
                    />
                  )}
                />
              </div>

              <div>
                <InputLabel shrink>Timezone</InputLabel>

                <Field
                  name="timezone"
                  render={({ input }) => (
                    <Select
                      name="timezone"
                      onChange={value => {
                        this.handleChangeTimezone(value);

                        input.onChange(value !== null ? value.value : null);
                      }}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                      value={selectedTimezone}
                      options={timezonesOptions}
                      isClearable
                    />
                  )}
                />
              </div>

              <Field
                name="reply_to_email"
                fullWidth
                component={TextField}
                type="email"
                label="Reply-to Email"
                style={{ marginBottom: 10 }}
              />

              <SelectPrimaryContactUser
                values={values}
                user={site.primary_contact_user}
                formApi={form}
                showUserSelector
                onUserSelect={() => {
                  const elem = document.querySelector(`[name='notes']`);
                  return elem && elem.focus();
                }}
              />

              <Field
                name="notes"
                fullWidth
                component={TextField}
                type="textarea"
                label="Notes"
                style={{ marginBottom: 10 }}
              />

              {hasCustomBranding && (
                <Box my={2}>
                  <Typography variant="caption">Custom Branding</Typography>
                  <Box mt={1} display="flex" flexWrap="wrap" justifyContent="space-between">
                    <Box display="flex" flexWrap="wrap" style={{ gap: '1rem' }}>
                      <Field
                        name="primary_color"
                        render={({ input }) => (
                          <InputMask
                            mask={hexPattern}
                            value={input.value}
                            onChange={event => {
                              input.onChange(event);
                            }}
                            onFocus={event => {
                              input.onFocus(event);
                            }}
                            onBlur={event => {
                              input.onBlur(event);
                            }}
                          >
                            <TextFieldMaterial
                              name={input.name}
                              autoComplete="off"
                              variant="outlined"
                              size="small"
                              label="Primary Color"
                              margin="normal"
                              style={{ marginTop: 0, marginBottom: 0, width: '150px' }}
                            />
                          </InputMask>
                        )}
                      />
                      <Field
                        name="secondary_color"
                        render={({ input }) => (
                          <InputMask
                            mask={hexPattern}
                            value={input.value}
                            onChange={event => {
                              input.onChange(event);
                            }}
                            onFocus={event => {
                              input.onFocus(event);
                            }}
                            onBlur={event => {
                              input.onBlur(event);
                            }}
                          >
                            <TextFieldMaterial
                              name={input.name}
                              autoComplete="off"
                              variant="outlined"
                              size="small"
                              label="Secondary Color"
                              margin="normal"
                              style={{ marginTop: 0, marginBottom: 0, width: '150px' }}
                            />
                          </InputMask>
                        )}
                      />
                      <Field
                        name="button_text_color"
                        render={({ input }) => (
                          <InputMask
                            mask={hexPattern}
                            value={input.value}
                            onChange={event => {
                              input.onChange(event);
                            }}
                            onFocus={event => {
                              input.onFocus(event);
                            }}
                            onBlur={event => {
                              input.onBlur(event);
                            }}
                          >
                            <TextFieldMaterial
                              name={input.name}
                              autoComplete="off"
                              variant="outlined"
                              size="small"
                              label="Button Text Color"
                              margin="normal"
                              style={{ marginTop: 0, marginBottom: 0, width: '150px' }}
                            />
                          </InputMask>
                        )}
                      />
                      <Field
                        name="toolbar_text_color"
                        render={({ input }) => (
                          <InputMask
                            mask={hexPattern}
                            value={input.value}
                            onChange={event => {
                              input.onChange(event);
                            }}
                            onFocus={event => {
                              input.onFocus(event);
                            }}
                            onBlur={event => {
                              input.onBlur(event);
                            }}
                          >
                            <TextFieldMaterial
                              name={input.name}
                              autoComplete="off"
                              variant="outlined"
                              size="small"
                              label="Toolbar Text Color"
                              margin="normal"
                              style={{ marginTop: 0, marginBottom: 0, width: '150px' }}
                            />
                          </InputMask>
                        )}
                      />
                    </Box>
                  </Box>
                  <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
                      <Field
                        name="logo"
                        render={({ input }) => (
                          <>
                            <HtmlTooltip title="Upload logo">
                              <IconButton color="secondary" component="label" variant="contained">
                                <input
                                  accept="image/*"
                                  name="logo"
                                  onClick={(event) => (event.target.value = '')}
                                  className={classes.input}
                                  id="contained-button-file"
                                  type="file"
                                  onChange={(value) => this.readFiles(value, input)}
                                  hidden
                                />
                                <CloudUploadIcon />
                              </IconButton>
                            </HtmlTooltip>

                            {logo && (
                              <>
                                <Link target="_blank" href={logo}>
                                  <img src={logo} className={classes.logo} alt="uploaded logo" />
                                </Link>
                                <IconButton onClick={() => this.removeLogo(input)}>
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </>
                        )}
                      />
                    </Box>
                    <Box>
                      <Button size="small" variant="text" onClick={this.resetBranding}>
                        Reset Branding
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}

              <div>
                <InputLabel shrink id="react-select-multiple">
                  Zip codes
                </InputLabel>
                <Field
                  name="zip_codes"
                  render={({ input, meta }) => (
                    <SelectMultiple
                      meta={meta}
                      value={selectedZipCodes}
                      onMenuOpen={() => {
                        this.setState({
                          zipCodePlaceholder: 'Type the 3 first digits of the zip code',
                        });
                      }}
                      onMenuClose={() => {
                        this.setState({
                          zipCodePlaceholder: 'Select zip codes',
                        });
                      }}
                      loadingMessage={this.loadingMessage}
                      loadOptions={this.loadZipCodes}
                      onChange={value => {
                        input.onChange(value !== null ? value.map(row => row.value).join(',') : null);

                        this.handleChangeMulti(value);
                      }}
                      selectPlaceholder={zipCodePlaceholder}
                    />
                  )}
                />
              </div>

              {showDisabledCheckbox && (
                <div>
                  <FormControlLabel
                    control={
                      <Field
                        name="disabled"
                        type="checkbox"
                        color="primary"
                        component={Checkbox}
                      />
                    }
                    label="Disabled"
                    labelPlacement="end"
                  />
                </div>
              )}

              <SubmitWithSendEmailConfirmation
                data-testid="submit-site-form-button"
                label={`${isUpdate ? 'Update' : 'Create'} Site`}
                showConfirmation={!values.primary_contact_id} // invite new user?
                disabled={pristine || (invalid && !dirtySinceLastSubmit) || submitting}
                className={classes.button}
                onButtonNotSend={() => this.submitFormHandler(false)}
                onButtonSend={() => this.submitFormHandler(true)}
              />
            </form>
          );
        }}
      />
    );
  }
}

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1),
  },
  logo: {
    height: 30,
    objectFit: 'cover',
    marginRight: 3,
    marginLeft: 5,
  },
});

export default withStyles(styles)(SiteForm);
