import queryString from 'query-string';
import { apiDELETE, apiGET, apiPOST } from './api';

export const getEvent = (eventId) => apiGET(`/events/${eventId}`);

export const getEvents = params => {
  const query = queryString.stringify(params);

  return apiGET(`/events?${query}`);
};

export const createEventShiftRegistration = (eventId, eventShiftId, postParams) =>
  apiPOST(`/events/${eventId}/shifts_registrations`, {
    event_shift_spec_id: eventShiftId,
    user_id: postParams.user_id,
    created_by_id: postParams.created_by_id,
  });

export const deleteRescuerEventShiftRegistration = (rescuerId, eventId, eventShiftId) =>
  apiDELETE(
    `/events/${eventId}/shifts_registrations`, {
      event_shift_spec_id: eventShiftId,
      user_id: rescuerId,
    }
  );
