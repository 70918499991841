import React, { useState } from 'react';
import { Divider, Typography, Box, Collapse } from '@material-ui/core';
import { Help as HelpIcon, Fastfood as FastfoodIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Colors } from '../../../../assets/theme/Colors';
import { formatAddress, formatDate, formatPhoneNumber, formatTime } from '../../../../helpers/formatters';
import { AddressLine, DialButton, MapButton, RescueColor, RubberStamp } from '../../../Rescue/RescueMobileCard';
import { donationRequestsDisplayFrequency } from '../../../../helpers/donations';

const useStyles = makeStyles(() => {
  return {
    claimed: {
      opacity: 0.3,
    },
    card: ({ highlighted }) => ({
      position: 'relative',
      backgroundColor: '#fff',
      width: '100%',
      borderRadius: 16,
      borderTopLeftRadius: highlighted ? 8 : 16,
      borderBottomLeftRadius: highlighted ? 8 : 16,
    }),
    breakWhiteSpaces: {
      whiteSpace: 'pre-wrap',
    },
  };
});

const DonationRequestCard = ({ donationRequest, isLoading = false, hideExpandPanel = false, children }) => {
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles({
    highlighted: false,
  });
  const handleExpandClick = () => setExpanded(!expanded);

  const pickupSpecs = donationRequest.pickup_specs;
  const hasSinglePickupSpec = pickupSpecs.length === 1;

  const isDeclined = donationRequest.status === 'declined';
  const isApproved = donationRequest.status === 'approved';
  const isPending = donationRequest.status === 'pending';

  const rescueTime =
    isLoading ||
    (hasSinglePickupSpec &&
      `${formatTime(pickupSpecs[0].pickup_begin.value)} - ${formatTime(pickupSpecs[0].pickup_end.value)}`);

  return (
    <Box boxShadow={25} className={classes.card}>
      {isPending && <RescueColor visibility={isApproved || isDeclined} color={Colors.rescues.unclaimed.color} />}

      {isApproved && (
        <>
          <RubberStamp isClaimed visibility="visible" text="approved" />
          <RescueColor visibility color={Colors.rescues.claimed.color} />
        </>
      )}

      {isDeclined && (
        <>
          <RubberStamp isCancelled visibility="visible" text="declined" />
          <RescueColor visibility color={Colors.rescues.cancelled.color} />
        </>
      )}

      <Box display="flex" py={2} className={classNames({ [classes.claimed]: false })}>
        <Box flexGrow={1} display="flex" justifyContent="flex-start" flexDirection="column" alignItems="stretch">
          <Box pl={2} display="flex" flexDirection="column" pr={2}>
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle1">
                {isLoading ? <Skeleton /> : donationRequestsDisplayFrequency(donationRequest)}
              </Typography>

              {rescueTime && <Typography variant="subtitle2">{isLoading ? <Skeleton /> : rescueTime}</Typography>}
            </Box>

            <Box display="flex" py={1}>
              <AddressLine />
              <Box display="flex" flexDirection="column" flexGrow={1} pl={1}>
                <Box my={0.5}>
                  <Typography variant="body1">
                    {isLoading ? <Skeleton /> : donationRequest.location || 'N/A'}
                  </Typography>
                </Box>
                <Box my={0.5}>
                  <Divider />
                </Box>
                <Box my={0.5}>
                  <Typography variant="body1">{isLoading ? <Skeleton /> : 'Not assigned yet'}</Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" gridGap={5} pb={2}>
              <FastfoodIcon htmlColor={Colors.lightGray} />
              <Typography variant="subtitle2" color="textSecondary">
                {isLoading ? <Skeleton /> : donationRequest.food_types.join(', ')}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" py={0} pl={2} pr={1} alignItems="baseline" gridColumnGap="8px">
            {children}

            {!hideExpandPanel && (
              <Box
                width={1}
                onClick={handleExpandClick}
                style={{
                  backgroundColor: '#efefef',
                  borderRadius: '20%',
                  width: 55,
                  height: 55,
                  paddingTop: 10,
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <HelpIcon htmlColor={Colors.blue.main} />
                <Typography variant="caption">{expanded ? 'Hide' : 'Info'}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {!hideExpandPanel && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box px={2}>
            <Divider />
          </Box>

          <Box p={2} flexDirection="column" color="text.secondary">
            {!isLoading && donationRequest.food_donor_notes && (
              <>
                <Box>
                  <Typography variant="subtitle1">Food Donor Notes:</Typography>
                  <Typography variant="body1" className={classes.breakWhiteSpaces}>
                    {donationRequest.food_donor_notes}
                  </Typography>
                </Box>

                <Box my={2}>
                  <Divider />
                </Box>
              </>
            )}

            <Box mb={2}>
              <Box display="flex" flexDirection="row" alignItems="center" gridGap="8px">
                {donationRequest.frequency ? (
                  <>
                    <Box
                      style={{
                        textAlign: 'center',
                        borderRight: '2px solid #ccc',
                        paddingRight: '12px',
                      }}
                    >
                      <Typography variant="subtitle1">Start Date:</Typography>
                      <Typography variant="body1">
                        {isLoading ? <Skeleton /> : formatDate(donationRequest.start_date)}
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="subtitle1">End Date:</Typography>
                      <Typography variant="body1">
                      {isLoading ? <Skeleton /> : donationRequest.end_date ? formatDate(donationRequest.end_date) : '-'}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box>
                    <Typography variant="subtitle1">Date:</Typography>
                    <Typography variant="body1">
                      {isLoading ? <Skeleton /> : formatDate(donationRequest.start_date)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box mb={2}>
              {!isLoading ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gridGap="8px"
                >
                  <Box>
                    <Typography variant="subtitle1">Pickup address:</Typography>
                    <Typography variant="body1">
                      {isLoading || !donationRequest.pickupLocation ? (
                        <Skeleton />
                      ) : (
                        formatAddress(donationRequest.pickupLocation)
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <MapButton address={'rescuePickupAddress'} />
                  </Box>
                </Box>
              ) : (
                <Box pt={0.5}>
                  <Typography variant="subtitle1">Pickup address:</Typography>
                  <Skeleton width="65%" />
                  <Skeleton width="45%" />
                  <Skeleton width="40%" />
                </Box>
              )}
            </Box>

            <Box>
              {!isLoading || !get(donationRequest, 'pickupLocation.primary_contact_first_name') ? (
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="subtitle1">Pickup contact:</Typography>
                    <Typography variant="body1">
                      Name: {get(donationRequest, 'pickupLocation.primary_contact_firstname')}{' '}
                      {get(donationRequest, 'pickupLocation.primary_contact_lastname')}
                    </Typography>
                    <Typography variant="body1">
                      <span>Phone: </span>
                      <a href={`tel:${get(donationRequest, 'pickupLocation.primary_contact_phone')}`}>
                        {formatPhoneNumber(get(donationRequest, 'pickupLocation.primary_contact_phone'))}
                      </a>
                      {get(donationRequest, 'pickupLocation.primary_contact_phone_ext') && (
                        <span>{` (ext: ${get(donationRequest, 'pickupLocation.primary_contact_phone_ext')})`}</span>
                      )}
                    </Typography>

                    <Typography variant="body1">
                      Email:
                      <a href={`mailto:${get(donationRequest, 'pickupLocation.primary_contact_email')}`}>
                        {get(donationRequest, 'pickupLocation.primary_contact_email')}
                      </a>
                    </Typography>
                  </Box>
                  <Box>
                    <DialButton phone="0000" />
                  </Box>
                </Box>
              ) : (
                <Box pt={0.5}>
                  <Typography variant="subtitle1">Pickup contact:</Typography>
                  <Skeleton width="65%" />
                  <Skeleton width="45%" />
                  <Skeleton width="40%" />
                </Box>
              )}
            </Box>

            {!isLoading && donationRequest.food_donor_notes && (
              <Box pt={2}>
                <Typography variant="subtitle1">Food Donor Notes:</Typography>
                <Typography variant="body1" className={classes.breakWhiteSpaces}>
                  {donationRequest.food_donor_notes}
                </Typography>
              </Box>
            )}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export default DonationRequestCard;
