import React, { useState, useContext } from 'react';
import { debounce, get } from 'lodash';
import {
  Box,
  CardActions,
  CardContent,
  TextField as TextFieldMaterial,
  Button,
  CircularProgress,
  Typography,
  Link,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Bluebird from 'bluebird';
import { withStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import * as usersApi from '../../../api/users';
import { BaseCard } from '../../../components/Common/BaseCard';
import { getUserFullName } from '../../../helpers/user';
import { apiToStore } from '../../../models/users';
import useImpersonatedUser from '../../../hooks/useImpersonatedUser';
import useLoggedUser from '../../../hooks/useLoggedUser';
import FABContext from '../../../context/FABContext/FABContext';
import { setFABActions } from '../../../helpers/fab';
import * as authActions from '../../../actions/auth';

export const StyledStickyBox = withStyles({
  root: {
    background: '#fafafa',
    position: 'sticky',
    top: 55,
    // margin: -16,
    // padding: '18px 16px 8px',
    marginBotton: 8,
    padding: '16px 1px 1px',
    marginBottom: 8,
    zIndex: 3,
  },
})(Box);

const UserCard = ({ user, handleImpersonate, isImpersonated, isLoading }) => {
  const roles =
    (!isLoading ? user.role_assignments || [] : [])
      .reduce((acc, curr) => {
        acc.push(curr.role_name);
        return acc;
      }, [])
      .join(', ') || 'N/A';

  return (
    <Box mb={1}>
      <BaseCard bgcolor={isImpersonated ? 'green' : 'red'}>
        <CardContent>
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box>
              <Typography variant="h5">{isLoading ? <Skeleton /> : getUserFullName(user)}</Typography>
            </Box>
            <Box>
              {isLoading ? (
                <Skeleton height={24} width={120} />
              ) : (
                <Typography variant="body1">
                  Phone: {user.phone ? <Link href={`tel:${user.phone}`}>{user.phone}</Link> : 'N/A'}
                </Typography>
              )}

              {isLoading ? (
                <Skeleton height={24} width={120} />
              ) : (
                <Typography variant="body1">
                  E-mail: {user.phone ? <Link href={`mailto:${user.email}`}>{user.email}</Link> : 'N/A'}
                </Typography>
              )}
            </Box>
            <Typography variant="body1">{isLoading ? <Skeleton /> : `Roles: ${roles}`}</Typography>
          </Box>
        </CardContent>
        <CardActions>
          {isLoading && <Skeleton width={130} height={36} />}
          {!isLoading && !isImpersonated && (
            <Button variant="contained" color="primary" onClick={() => handleImpersonate(user)}>
              Impersonate
            </Button>
          )}
          {!isLoading && isImpersonated && (
            <Button variant="outlined" color="primary" onClick={() => handleImpersonate(null)}>
              End impersonation
            </Button>
          )}
        </CardActions>
      </BaseCard>
    </Box>
  );
};

const UserSelectorViewMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const fabContext = useContext(FABContext);
  const loggedUser = useLoggedUser();
  const impersonatedUser = useImpersonatedUser();

  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [userList, setUserList] = useState([]);

  const switchUser = user => {
    const userToSwitch = user || loggedUser; // switch back to logged in user, when "null" user is provided

    return Bluebird
      .try(() => dispatch(authActions.changeUser(userToSwitch.id)))
      .then(() => setFABActions(userToSwitch, history, fabContext));
  };

  const fetchUsers = value => {
    setFetchingUsers(true);
    setUserList([]);

    if (value.length <= 2) {
      return new Promise(resolve => {
        resolve([]);
        setFetchingUsers(false);
        setUserList([]);
      });
    }

    return Bluebird.try(() => usersApi.getUsers({ search: value }))
      .then(res => res.json())
      .then(res => setUserList(res.data))
      .then(() => setFetchingUsers(false));
  };

  const debounceFetch = debounce(fetchUsers, 300);

  return (
    <div>
      <StyledStickyBox>
        <TextFieldMaterial
          fullWidth
          variant="outlined"
          label="Email"
          onChange={event => debounceFetch(event.target.value)}
          InputProps={{
            endAdornment: (
              <React.Fragment>{fetchingUsers ? <CircularProgress color="inherit" size={20} /> : null}</React.Fragment>
            ),
          }}
        />
      </StyledStickyBox>
      {fetchingUsers && [1, 2, 3, 4, 5].map((row, index) => <UserCard key={index} isLoading />)}

      {!fetchingUsers && impersonatedUser && userList.length === 0 && (
        <UserCard user={impersonatedUser} isImpersonated handleImpersonate={switchUser} />
      )}
      {!fetchingUsers &&
        userList.map(user => (
          <UserCard
            key={user.id}
            isLoading={fetchingUsers}
            isImpersonated={user.id === get(impersonatedUser, 'id', null)}
            user={apiToStore(user)}
            handleImpersonate={switchUser}
          />
        ))}
    </div>
  );
};

export default UserSelectorViewMobile;
