import React, { useState } from 'react';
import { DevTool } from 'react-hook-form-devtools';
import { FormContext, useForm } from 'react-hook-form';
import moment from 'moment';
import EventDetailsForm from '../Components/EventForm/EventDetails/EventDetailsForm';
import EventShiftsForm from '../Components/EventForm/EventShifts/EventShiftsForm';
import ButtonWithLoading from '../../../components/ButtonWithLoading';
import SubmitButtonWrapper from '../../../components/Common/MobileSubmitWrapper';
import eventFormValidationResolver from '../Components/eventFormValidationResolver';
import { FREQUENCY_ONCE } from '../../../models/donationsNew';

export const eventFormDefaultValues = {
  title: '',
  description: '',
  address: '',
  city: '',
  zip: '',
  state: '',
  start_date: moment().format('YYYY-MM-DD'),
  begin: moment(8, 'HH'),
  end: moment(12, 'HH'),
  end_date: null,
  pause_date: null,
  resume_date: null,
  frequency: FREQUENCY_ONCE,
  days_of_week: {
    mon: {
      checked: false,
    },
    tue: {
      checked: false,
    },
    wed: {
      checked: false,
    },
    thu: {
      checked: false,
    },
    fri: {
      checked: false,
    },
    sat: {
      checked: false,
    },
    sun: {
      checked: false,
    },
  },
  days_of_month: {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  },
  shifts: [{ begin: moment(8, 'HH:mm:ss'), end: moment(12, 'HH:mm:ss') }],
};

const EventCreateForm = ({ submitHandler, isMobileView }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formMethods = useForm({
    defaultValues: eventFormDefaultValues,
    validationResolver: eventFormValidationResolver,
    validationContext: {},
  });
  const { handleSubmit, control } = formMethods;

  const onSubmit = (values) => {
    const beginDates = values.shifts.map((shift) => moment(shift.begin));
    const endDates = values.shifts.map((shift) => moment(shift.end));

    setIsSubmitting(true);
    return submitHandler({
      ...values,
      start_date: moment(values.start_date).format('YYYY-MM-DD'),
      begin: values.begin ? values.begin.format('HH:mm:ss') : moment.min(beginDates).format('HH:mm:ss'),
      end: values.end ? values.end.format('HH:mm:ss') : moment.max(endDates).format('HH:mm:ss'),
      shifts: values.shifts.map((shift) => ({
        ...shift,
        begin: shift.begin.format('HH:mm:ss'),
        end: shift.end.format('HH:mm:ss'),
      })),
    });
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EventDetailsForm isMobileView={isMobileView} />
        <EventShiftsForm isMobileView={isMobileView} />

        <SubmitButtonWrapper>
          <ButtonWithLoading
            data-testid="create-event-button"
            isLoading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="medium"
            aria-label="save"
          >
            Submit
          </ButtonWithLoading>
        </SubmitButtonWrapper>
        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </form>
    </FormContext>
  );
};

export default EventCreateForm;
