import React from 'react';
import { groupBy } from 'lodash';
import { Typography, Box } from '@material-ui/core';
import CircularProgressWithLabel from '../../../../../components/CircularProgressWithValue';
import { siteGoalsTypes } from '../../../../../helpers/siteGoals';
import { Colors } from '../../../../../assets/theme/Colors';
import { getPercentage } from '../../../../../helpers/math';

export const siteGoalsJsx = ({siteGoals, rowGap = 16}) => {
  const goalsBySiteId = groupBy(siteGoals, 'site_id');
  const sitesIds = Object.keys(goalsBySiteId);

  return sitesIds.map((siteId) => {
    const siteGoals = goalsBySiteId[siteId];

    return (
      <Box key={`goals-${siteId}`} display="flex" gridRowGap={16} flexDirection="column">
        {sitesIds.length > 1 && (
          <Typography component="p" variant="subtitle1">
            {siteGoals[0].name}
          </Typography>
        )}

        <Box display="flex" gridColumnGap={rowGap} gridRowGap={rowGap} flexWrap="wrap">
          {siteGoals.map((siteGoal) => (
            <CircularProgressWithLabel
              key={siteGoal.id}
              style={getPercentage(siteGoal.value, siteGoal.goal) >= 100 ? { color: Colors.green.light } : {}}
              value={getPercentage(siteGoal.value, siteGoal.goal)}
              size={110}
              thickness={2.5}
            >
              <div>
                <Typography variant="caption" component="p" color="textSecondary">
                  {siteGoalsTypes[siteGoal.type].label}
                </Typography>
                <Typography variant="caption" component="p" color="textSecondary">
                  {Math.round(siteGoal.value)}/{siteGoal.goal}
                </Typography>
              </div>
            </CircularProgressWithLabel>
          ))}
        </Box>
      </Box>
    );
  });
};

const MySiteGoals = ({ goalsList }) => (
  <Box display="flex" flexDirection="column" gridRowGap={24}>
    {siteGoalsJsx({ siteGoals: goalsList })}
  </Box>
);

export default MySiteGoals;
