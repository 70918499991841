import queryString from 'query-string';
import moment from 'moment';
import { apiGET } from './api';

export const getGrowth = (from, to, granularity, sitesIds = [], supressInactiveSites, completedRegistration) => {
  const query = queryString.stringify(
    {
      from: from,
      to: to,
      granularity: granularity,
      sites_ids: sitesIds,
      supress_inactive_sites: supressInactiveSites,
      completed_registration: completedRegistration,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/reports/growth?${query}`);
};

export const getVolume = (type, from, to, granularity, sitesIds = [], suppressEmptyRows = true, completedRegistration) => {
  const query = queryString.stringify(
    {
      type: type,
      from: from,
      to: to,
      granularity: granularity,
      sites_ids: sitesIds,
      suppress_empty_rows: suppressEmptyRows,
      completed_registration: completedRegistration,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/reports/volume?${query}`);
};

export const getReceivingAgency = (from, to, granularity, sitesIds, receivingAgenciesIds) => {
  const query = queryString.stringify(
    {
      from: from,
      to: to,
      granularity: granularity,
      sites_ids: sitesIds,
      receiving_agencies_ids: receivingAgenciesIds,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/reports/receiving_agency?${query}`);
};

export const getRescues = (from, to, granularity, siteIds) => {
  const query = queryString.stringify(
    {
      from: from,
      to: to,
      granularity: granularity,
      sites_ids: siteIds,
    },
    {
      skipNull: true,
    }
  );
  return apiGET(`/reports/rescue?${query}`);
};

export const getRescuedFood = (sitesIds = [], from = null, to = null) => {
  const query = queryString.stringify(
    {
      sites_ids: sitesIds,
      from: from,
      to: to,
      today: moment().format('YYYY-MM-DD'),
    },
    { skipNull: true }
  );

  return apiGET(`/reports/rescued_food?${query}`);
};
