import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import * as usersActions from '../../../actions/users';
import * as authActions from '../../../actions/auth';
import UsersTableNew from '../../../components/UsersTableNew';
import FABContext from '../../../context/FABContext/FABContext';
import { setFABActions } from '../../../helpers/fab';

class UserSelector extends Component {
  componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers();
  }

  render() {
    const { users, impersonateUser, classes, history } = this.props;

    return (
      <div className={classes.tableContainer}>
        <UsersTableNew
          isLoading={users.inflight}
          data={Object.values(users.byId)}
          onRowClick={userId => {
            impersonateUser(userId);
            setFABActions(users.byId[userId], history, this.context);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ entities: { users } }) => ({
  users,
});
const mapDispatchToProps = dispatch => ({
  fetchUsers: () => dispatch(usersActions.fetchUsersIfNeeded()),
  impersonateUser: userId => dispatch(authActions.changeUser(userId)),
});
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    flexGrow: 1,
  },
  tableContainer: {
    height: 320,
  },
  table: {
    minWidth: 700,
  },
});

UserSelector.contextType = FABContext;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(UserSelector))
);
