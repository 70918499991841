import React, { forwardRef } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';

const CalendarBox = forwardRef(function CalendarBox(
  { isCancelled, noReceiver, statusColor, claimColor, ...rest },
  ref
) {
  return (
    <Box
      ref={ref}
      {...rest}
      className={classNames({
        'rescue-cancelled': isCancelled && !noReceiver,
        'rescue-no_receiver': noReceiver && !isCancelled,
        'rescue-cancelled-no_receiver': noReceiver && isCancelled,
      })}
      style={{
        width: '100%',
        paddingTop: '100%',
        aspectRatio: 1,
        backgroundImage: `linear-gradient(to bottom left, ${statusColor.color} 50%, ${claimColor.color} 50%)`,
        border: '1px solid black',
      }}
    />
  );
});

export default CalendarBox;
