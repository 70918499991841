import moment from 'moment';
import { convertMetersToMiles, convertSecondsToMilliseconds } from './math';

export const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const formatTime = time => {
  if (!time) {
    return '';
  }

  return moment(time, 'HH:mm:ss').format('h:mm A');
};

export const formatDate = date => {
  if (!date) {
    return '';
  }

  return moment(date).format('MM/DD/YYYY');
};

export const formatDateTime = (dateTime, sourceIsUtc = false, format = 'MM/DD/YYYY h:mm A') => {
  if (!dateTime) {
    return '';
  }

  if (sourceIsUtc) {
    return moment.utc(dateTime).local().format(format);
  } 
  
  return moment(dateTime).format(format);
};

export const percentageFormatter = props =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...props,
  });

export const numberFormatter = props =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
    ...props,
  });

export const formatNumber = number => new Intl
  .NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  .format(number);

export const formatValueOfMeals = valueOfMeals => new Intl
  .NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })
  .format(valueOfMeals);

export const formatPickupLocationName = ({ name, address, city, st, zip }) => {
  const formattedAddress = formatAddress({
    address: address,
    city: city,
    st: st,
    zip: zip,
  });

  if (name) {
    return `${name} - ${formattedAddress}`;
  }

  return formattedAddress;
};

export const formatAddress = ({ address, city, st, zip }) => {
  let lineAddress = null;

  if (address) {
    lineAddress = address;
  }

  if (city) {
    if (address) {
      lineAddress = `${lineAddress}, ${city}`;
    } else {
      lineAddress = city;
    }
  }

  if ((st || zip) && lineAddress) {
    lineAddress = `${lineAddress},`;
  }

  if (st) {
    if (lineAddress) {
      lineAddress = `${lineAddress} ${st}`;
    } else {
      lineAddress = st;
    }
  }

  if (zip) {
    if (lineAddress) {
      lineAddress = `${lineAddress} ${zip}`;
    } else {
      lineAddress = zip;
    }
  }

  return lineAddress;
};

export const formatRescueDistance = (distance, suffix = 'mi') => {
  if (distance === undefined || distance === null || isNaN(distance)) {
    return 'n/a';
  }
  
  const distanceInMiles = convertMetersToMiles(distance);
  
  if (!distanceInMiles) {
    return 'n/a';
  }
  
  return `${Math.round(distanceInMiles)}${suffix}`;
};

export const formatRescueDuration = (duration, useShortVersion = true) => {
  if (duration === undefined || duration === null || isNaN(duration)) {
    return 'n/a';
  }
  
  const durationInMilliseconds = convertSecondsToMilliseconds(duration);
  
  if (!durationInMilliseconds) {
    return 'n/a';
  }
  
  const humanizeDuration = require('humanize-duration');
  const options = {
    units: ['h', 'm'],
    round: true,
    spacer: '',
    largest: 2,
  };
  
  if (useShortVersion) {
    const shortEnglishHumanizer = humanizeDuration.humanizer({
      language: 'shortEn',
      languages: {
        shortEn: {
          h: () => 'h',
          m: () => 'min',
        },
      },
    });
  
    return shortEnglishHumanizer(durationInMilliseconds, options);
  }
  
  return humanizeDuration(
    durationInMilliseconds,
    {
      ...options,
      spacer: ' ',
    }
  );
};

export const formatCsvFileName = fileName => `Food Rescue US - ${fileName}.csv`;

export const formatMetricTitle = ({ name, title }) => {
  if (name === 'page_post_engagements') {
    return 'Engagement';
  }

  if (name === 'page_posts_impressions' || name === 'impressions') {
    return 'Impressions';
  }

  if (name === 'page_views_total' || name === 'profile_views') {
    return 'Views';
  }

  if (name === 'page_daily_follows_unique' || name === 'follower_count') {
    return 'Followers';
  }

  return title;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
