import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Bluebird from 'bluebird';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchSiteRescueIfNeeded } from '../../../../actions/sites';
import * as sitesActions from '../../../../actions/sites';
import * as rescuesActions from '../../../../actions/rescues';
import RescueDetailsView from '../RescueDetailsView';
import * as authService from '../../../../services/auth';
import { Roles } from '../../../../models/roles';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import routes from '../../../../routes';
import useActiveSite from '../../../../hooks/useActiveSite';
import { GridRow, RowContent, RowTitle } from '../FormGrid';
import RescueEditDonorForm from './RescueEditDonorForm';
import errorMessages from '../../../../assets/errorMessages';
import { formatTime } from '../../../../helpers/formatters';

const useStyles = makeStyles(() => ({
  breakWhiteSpaces: {
    whiteSpace: 'pre-wrap',
  },
}));

const RescueEditDonorView = ({ isMobileView, user, rescueId, location: { state } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const site = useActiveSite();
  const rescues = useSelector(state => state.entities.sites.rescues);
  const isScheduleView = useRouteMatch(routes.rescuesScheduleEdit);

  const getRedirectPath = () => {
    if (state && state.prevPath) {
      return state.prevPath;
    }

    if (isScheduleView) {
      return routes.rescuesSchedule;
    }

    return routes.index;
  };

  // useMemo prevents re-render when modifiedById change. Basically it mimics original/draft rescue
  const rescue = useMemo(() => rescues.modifiedById[rescueId] || rescues.byId[rescueId], [rescues.byId[rescueId]]);

  const fetchRescue = () => Bluebird
    .try(() => dispatch(fetchSiteRescueIfNeeded(site.id, rescueId)))
    .catch(err => {
      history.push(routes.index);
      switch (err.code) {
        case errorMessages.ERR_RESCUE_NOT_FOUND.code:
          snackbarHelper.error(errorMessages.ERR_RESCUE_NOT_FOUND.message);
          break;
        default:
          snackbarHelper.error(err.message);
          break;
      }
    });

  const updateRescue = data =>
    Bluebird
      .try(() => dispatch(rescuesActions.updateRescue(rescue.id, data)))
      .then(() => snackbarHelper.success('Rescue updated successfully!'))
      .then(() => history.push(getRedirectPath()));

  useEffect(() => {
    fetchRescue();
    dispatch(sitesActions.fetchSiteRescuersIfNeeded(site.id));
  }, [dispatch]);

  if (!rescue) {
    return 'loading....';
  }

  if (
    !authService.hasAnyRoleInSite(user, rescue.site_id, [
      Roles.DonorStaff,
      Roles.DonorAdmin,
      Roles.DonorLocationStaff,
      Roles.DonorLocationAdmin,
    ])
  ) {
    return 'no access';
  }

  // let's check if rescue is past
  if (rescue.is_past) {
    // show read-only version of the page, without ability to edit "past" rescue
    return (
      <RescueDetailsView rescue={rescue} data-testid="rescue-admin-details">
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
          <GridRow>
            <RowTitle>
              Pickup Window:
            </RowTitle>

            <RowContent>
              <span>
                {formatTime(rescue.pickup_begin)} - {formatTime(rescue.pickup_end)}
              </span>
            </RowContent>
          </GridRow>

          <GridRow>
            <RowTitle>
              Rescuer Notes:
            </RowTitle>

            <RowContent>
              <span className={classes.breakWhiteSpaces}>
                {rescue.rescuer_notes}
              </span>
            </RowContent>
          </GridRow>

          <GridRow>
            <RowTitle>
              Rescuer:
            </RowTitle>

            <RowContent>
              <span>
                {rescue.claimer ? rescue.claimer : '-'}
              </span>
            </RowContent>
          </GridRow>
        </Grid>
      </RescueDetailsView>
    );
  }

  // rescue is not past, let's show an edit (future) rescue page
  return (
    <RescueDetailsView rescue={rescue} data-testid="rescue-admin-details">
      <Box pb={1}>
        <GridRow>
          <RowTitle>Adopter:</RowTitle>
          <RowContent>{rescue.adopter || 'N/A'}</RowContent>
        </GridRow>
      </Box>

      <Box pb={1}>
        <GridRow>
          <RowTitle>Rescuer:</RowTitle>
          <RowContent>{rescue.rescuer || 'N/A'}</RowContent>
        </GridRow>
      </Box>

      <RescueEditDonorForm updateRescue={updateRescue} user={user} rescue={rescue} isMobileView={isMobileView} />
    </RescueDetailsView>
  );
};

export default RescueEditDonorView;
