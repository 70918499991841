import { PHONE_EXT_MAX_LENGTH } from '../components/Form/MuiFormWrapper';

const errorMessages = {
  REQUIRED: {
    message: 'This field is required.',
  },
  INVALID: {
    message: 'Please provide a valid value.',
  },
  ERR_DUPLICATED_EMAIL: {
    code: 'ERR_DUPLICATED_EMAIL',
    message: 'That email is already in use.'
  },
  ERR_INVALID_EMAIL: {
    message: 'Please provide a valid email address.'
  },
  ERR_DUPLICATED_PHONE: {
    code: 'ERR_DUPLICATED_PHONE',
    message: 'That phone# is already in use.'
  },
  ERR_DUPLICATED_BUSINESS_ADDRESS_PHONE: {
    code: 'ERR_DUPLICATED_BUSINESS_ADDRESS_PHONE',
    message: 'That phone# is already in use.'
  },
  ERR_DUPLICATED_PICKUP_ADDRESS_PHONE: {
    code: 'ERR_DUPLICATED_PICKUP_ADDRESS_PHONE',
    message: 'That phone# is already in use.'
  },
  ERR_NOT_VERIFIED_PHONE: {
    code: 'ERR_NOT_VERIFIED_PHONE',
    message: 'Please verify your phone# first!.'
  },
  ERR_UNABLE_TO_CLAIM_RESCUE_USER_EMAIL_NOT_VERIFIED: {
    code: 'ERR_UNABLE_TO_CLAIM_RESCUE_USER_EMAIL_NOT_VERIFIED',
    message: 'Before you claim a rescue, you need to verify your email address.'
  },
  ERR_UNABLE_TO_CLAIM_RESCUE_USER_PHONE_NOT_VERIFIED: {
    code: 'ERR_UNABLE_TO_CLAIM_RESCUE_USER_PHONE_NOT_VERIFIED',
    message: 'Before you claim a rescue, you need to verify your mobile phone#.'
  },
  ERR_UNABLE_TO_ADOPT_PICKUP_USER_EMAIL_NOT_VERIFIED: {
    code: 'ERR_UNABLE_TO_ADOPT_PICKUP_USER_EMAIL_NOT_VERIFIED',
    message: 'Before you adopt a rescue, you need to verify your email address.'
  },
  ERR_UNABLE_TO_ADOPT_PICKUP_USER_PHONE_NOT_VERIFIED: {
    code: 'ERR_UNABLE_TO_ADOPT_PICKUP_USER_PHONE_NOT_VERIFIED',
    message: 'Before you adopt a rescue, you need to verify your mobile phone#.'
  },
  ERR_INVALID_PHONE: {
    code: 'ERR_INVALID_PHONE',
    message: 'Please provide a valid phone number.'
  },
  ERR_INVALID_ZIP_CODE: {
    code: 'ERR_INVALID_ZIP_CODE',
    message: 'Please provide a valid zip code.'
  },
  ERR_ZIP_CODE_NOT_FOUND: {
    code: 'ERR_ZIP_CODE_NOT_FOUND',
    message: 'Zip code not found',
  },
  ERR_CAPTCHA_INVALID_TOKEN: {
    code: 'ERR_CAPTCHA_INVALID_TOKEN',
    message: 'Invalid captcha token!',
  },
  ERR_CAPTCHA_INVALID_ACTION: {
    code: 'ERR_CAPTCHA_INVALID_ACTION',
    message: 'Invalid captcha action name!',
  },
  ERR_CAPTCHA_INVALID_SCORE: {
    code: 'ERR_CAPTCHA_INVALID_SCORE',
    message: 'Unable to verify captcha!',
  },
  ERR_CAPTCHA_UNABLE_VERIFY: {
    code: 'ERR_CAPTCHA_UNABLE_VERIFY',
    message: 'Unable to process captcha token!',
  },
  ERR_EVENT_NOT_FOUND: {
    code: 'ERR_EVENT_NOT_FOUND',
    message: 'Unable to find event with given id!'
  },
  ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT: {
    code: 'ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT',
    message: 'There are no free slots left for this event shift. Please refresh the page.'
  },
  ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT: {
    code: 'ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT',
    message: 'User is already registered for the provided shift.'
  },
  ERR_PHONE_EXT_TOO_LONG: {
    code: 'ERR_USER_PHONE_EXT_TOO_LONG',
    message: `Phone ext should have max ${PHONE_EXT_MAX_LENGTH} characters.`
  },
  ERR_SYSTEM_NOT_AVAILABLE: {
    code: 'ERR_SYSTEM_NOT_AVAILABLE',
    message: 'The system is not available. Please contact your administrator.',
  },
  ERR_RESCUE_ALREADY_CLOSED: {
    code: 'ERR_RESCUE_ALREADY_CLOSED',
    message: 'This rescue is already closed.',
  },
  ERR_RESCUE_NOT_FOUND: {
    code: 'ERR_RESCUE_NOT_FOUND',
    message: 'Unable to get rescue with given ID.',
  },
  FOOD_DONOR_ACCOUNT_NOT_VERIFIED: {
    message: 'Please wait until your account has been verified by your Site Director.',
  },
  PICKUP_START_TIME_BEFORE_END_TIME: {
    message: 'Pickup begin time must be before end time',
  },
};

export default errorMessages;
