import moment from 'moment';
import { isUndefinedOrNull } from '../helpers';

export const apiToStore = userRegistration => {
  return (
    ({
       zip_code,
       email_verified,
       tos_accepted,
       valid,
       createdAt,
       updatedAt,
       deletedAt,
      ...rest
    }) =>
    ({
      zip_code: !isUndefinedOrNull(zip_code) ? zip_code : '',
      email_verified: !isUndefinedOrNull(email_verified) ? moment(email_verified).format('YYYY-MM-DD HH:mm:ss') : null,
      tos_accepted: !isUndefinedOrNull(tos_accepted) ? moment(tos_accepted).format('YYYY-MM-DD HH:mm:ss') : null,
      ...rest,
    })
  )(userRegistration);
};
