import moment from 'moment';

const daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const convertApiDataToComponentFormat = (data = []) => {
  const result = {};
  data.forEach(({ day_of_week: dayIndex, start, end }) => {
    const slot = {
      start: moment(start, 'HH:mm:ss').format('hh:mma'),
      end: moment(end, 'HH:mm:ss').format('hh:mma'),
    };
    if (result.hasOwnProperty(daysMap[dayIndex])) {
      result[daysMap[dayIndex]].push(slot);
    } else {
      result[daysMap[dayIndex]] = [slot];
    }
  });
  return result;
};

export const convertComponentDataToApiFormat = hours => {
  const result = [];
  for (const [key, value] of Object.entries(hours)) {
    value.forEach(({ start, end }) =>
      result.push({
        day_of_week: daysMap.indexOf(key),
        start: moment(start, 'hh:mma').format('HH:mm:ss'),
        end: moment(end, 'hh:mma').format('HH:mm:ss'),
      })
    );
  }
  return result;
};
