import React from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import RescuerTodayRescuesTable from './RescuerTodayRescuesTable';
import routes from '../../../../../routes';
import TableSkeletonLoader from '../../../../../components/Common/TableSkeletonLoader';
import useRescuerRescueActions from '../../../../../hooks/useRescuerRescueActions';
import useActiveUser from '../../../../../hooks/useActiveUser';

const RescuerTodayRescues = ({ isLoading = false, todayRescues, onCellClick }) => {
  const history = useHistory();
  const user = useActiveUser();
  const { rescueUnclaim, releaseRescue } = useRescuerRescueActions();

  const onCloseRescue = (rescue) => history.push(generatePath(routes.rescueClose, { rescueId: rescue.id }));

  const onCloseNoShowRescue = (rescue) => history.push(generatePath(routes.rescueCloseNoShow, { rescueId: rescue.id }));

  if (todayRescues.length === 0 && !isLoading) {
    return (
      <Typography color="textSecondary" variant="body1">
        You have no rescues today.
      </Typography>
    );
  }

  if (isLoading) {
    return <TableSkeletonLoader cols={5} rows={3} />;
  }

  return (
    <RescuerTodayRescuesTable
      tableId="#rescuer-dashboard-desktop/today-rescues"
      user={user}
      rescues={todayRescues}
      onCloseRescueButtonClick={onCloseRescue}
      onCloseNoShowRescueButtonClick={onCloseNoShowRescue}
      onUnclaimRescueButtonClick={rescueUnclaim}
      onReleaseRescueButtonClick={releaseRescue}
      onCellClick={onCellClick}
    />
  );
};

export default RescuerTodayRescues;
