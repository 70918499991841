import React from 'react';
import { DialogContentText } from '@material-ui/core';
import ConfirmationDialog from './ConfirmationDialog';

const VerificationPauseRescueConfirmationDialog = ({
  onCloseDialog,
  onResumeButtonClick,
  isSD = false,
}) => (
  <ConfirmationDialog
    dialogProps={{
      fullWidth: true,
    }}
    title="Resume rescuing"
    customMessage={
      isSD ? (
        <DialogContentText>
          Are you sure you want to resume rescuing for this user?
        </DialogContentText>
      ) : (
        <DialogContentText>Are you sure you want to resume rescuing?</DialogContentText>
      )
    }
    closeDialog={onCloseDialog}
    buttons={[
      { variant: 'outlined', label: 'Cancel', color: 'primary', onClick: onCloseDialog },
      { variant: 'contained', label: 'Resume rescuing', color: 'primary', onClick: onResumeButtonClick },
    ]}
  />
);

export default VerificationPauseRescueConfirmationDialog;
