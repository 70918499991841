import * as environmentImpact from '../actions/environmentImpact';

export const initialState = {
  inflight: false,
  all: {},
  lastUpdated: null,
};

const environmentImpactReducer = (state = initialState, action) => {
  switch (action.type) {
    case environmentImpact.REQUEST_ENVIRONMENT_IMPACT:
      return {
        ...state,
        inflight: true,
      };
    case environmentImpact.RECEIVE_RESCUER_ENVIRONMENT_IMPACT:
      return {
        ...state,
        inflight: false,
        all: action.environmentImpact,
      };
    case environmentImpact.RECEIVE_ENVIRONMENT_IMPACT:
      return {
        ...state,
        inflight: false,
        all: action.environmentImpact.reduce(
          (acc, curr) => {
            if (curr.co2eq) {
              acc.co2eq += curr.co2eq;
            }
            if (curr.water) {
              acc.water += curr.water;
            }
            if (curr.weight_lbs) {
              acc.weight_lbs += curr.weight_lbs;
            }
            if (curr.meals) {
              acc.meals += curr.meals;
            }
            return acc;
          },
          { co2eq: 0, water: 0, weight_lbs: 0, meals: 0 }
        ),
      };
    default:
      return state;
  }
};

export default environmentImpactReducer;
