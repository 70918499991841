import React from 'react';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Help as HelpIcon } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import donorImage from '../../../assets/images/donor.png';
import receiverImage from '../../../assets/images/receiver.png';
import rescuerImage from '../../../assets/images/rescuer.png';
import unassignedUserImage from '../../../assets/images/unassigned-user.png';
import { Colors } from '../../../assets/theme/Colors';
import DateAndGranularityPicker, { dateRangeOptionsList } from '../../../components/DateAndGranularityPicker/DateAndGranularityPicker';

export const MARKER_DONOR = 'donor';
export const MARKER_RECEIVER = 'receiver';
export const MARKER_RESCUER = 'rescuer';
export const MARKER_UNASSIGNED_USER = 'unassigned_useer';

const StyledList = withStyles(({ palette }) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: palette.background.paper,
  },
}))(List);

const SwitchOption = ({ handleSwitchToggle, visiblePins, switchType, label, markerUrl, disabled = false }) => (
  <ListItem>
    <ListItemIcon>
      <img src={markerUrl} alt={switchType} />
    </ListItemIcon>
    <ListItemText id={`switch-list-label-${switchType}`} primary={label} />
    <ListItemSecondaryAction>
      <Switch
        edge="end"
        disabled={disabled}
        onChange={handleSwitchToggle(switchType)}
        checked={visiblePins.indexOf(switchType) !== -1}
      />
    </ListItemSecondaryAction>
  </ListItem>
);

const MarkersSelector = ({
  visiblePins,
  handleSwitchToggle,
  isSiteAreaVisible,
  setSiteAreaVisibility,
  isSiteAreaStrokeVisible,
  setSiteAreaStrokeVisibility,
  handleFitMapBounds,
  isLoading,
  isAdmin,
  dateRange,
  onDateRangeChange,
}) => {
  return (
    <Box boxShadow={25} p={2} bgcolor="background.paper">
      <Typography gutterBottom variant="subtitle2" color="textSecondary">
        Visible markers
      </Typography>
      <StyledList dense>
        <SwitchOption
          disabled={isLoading}
          label="Donors"
          markerUrl={donorImage}
          switchType={MARKER_DONOR}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />
        <SwitchOption
          disabled={isLoading}
          label="Receiving Agencies"
          markerUrl={receiverImage}
          switchType={MARKER_RECEIVER}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />
        <SwitchOption
          disabled={isLoading}
          label={(
            <Box display="flex">
              <Typography variant="body2">Rescuers</Typography>
              <Tooltip style={{marginLeft: "5px", paddingBottom: "2px"}} arrow placement="top" title="Only rescuers who have provided their full address will appear on the map.">
                <HelpIcon htmlColor={Colors.blue.main} />
              </Tooltip>
            </Box>
          )}
          markerUrl={rescuerImage}
          switchType={MARKER_RESCUER}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />
        {isAdmin && <SwitchOption
          disabled={isLoading}
          label="Unassigned Users"
          markerUrl={unassignedUserImage}
          switchType={MARKER_UNASSIGNED_USER}
          handleSwitchToggle={handleSwitchToggle}
          visiblePins={visiblePins}
        />}
      </StyledList>
      <Divider />
      <Box px={2} pt={2}>
        <DateAndGranularityPicker
          label="Registration Date Range"
          defaultDateRange="all"
          showGranularity={false}
          showPeriod={dateRange.period === 'custom'}
          dateFullWidth
          showOnlyDatesInLabel
          dateRangeOptions={{
            lastWeek: dateRangeOptionsList.lastWeek,
            last7Days: dateRangeOptionsList.last7Days,
            last90Days: dateRangeOptionsList.last90Days,
            thisMTD: dateRangeOptionsList.thisMTD,
            thisQTD: dateRangeOptionsList.thisQTD,
            thisYTD: dateRangeOptionsList.thisYTD,
          }}
          showCustomDateRange
          showAllDateRange
          customDateRangeOptions={{
            maxDate: new Date(),
          }}
          returnDateObject={false}
          onChange={(data) => onDateRangeChange(data)}
          />
        <FormControlLabel
          control={
            <Checkbox
              disabled={isLoading}
              checked={isSiteAreaVisible}
              onChange={() => setSiteAreaVisibility(!isSiteAreaVisible)}
              name="area_site"
            />
          }
          label="Show site area"
        />
      </Box>
      <Box px={2}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={isLoading || !isSiteAreaVisible}
              checked={isSiteAreaStrokeVisible}
              onChange={() => setSiteAreaStrokeVisibility(!isSiteAreaStrokeVisible)}
              name="area_site_stroke"
            />
          }
          label="Stroke site area zip codes"
        />
      </Box>

      <Box px={2} pt={2}>
        <Button
          disabled={isLoading || visiblePins.length === 0}
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={handleFitMapBounds}
        >
          center map
        </Button>
      </Box>
    </Box>
  );
};

export default MarkersSelector;
