import React, { useState } from 'react';
import { Box, CardContent, Grid, Link, Typography } from '@material-ui/core';
import { ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';
import CompactWidgetTable from './CompactWidgetTable';
import OverlayLoader from './OverlayLoader';
import { BaseCard, BaseCardHeader } from './Common/BaseCard';
import { generatePath, useHistory } from 'react-router-dom';
import { getMuiTableDataIndex } from '../helpers/getters';
import routes from '../routes';
import { formatNumber, formatValueOfMeals } from '../helpers/formatters';

const LeaderboardsTables = ({ data, inflight, showFoodDonorsLeaderboard = true, showLinks = true}) => {
  const history = useHistory();
  const [foodDonorsSort, setFoodDonorsSort] = useState('rescues');
  const [receivingAgenciesSort, setReceivingAgenciesSort] = useState('rescues');
  const [rescuersSort, setRescuersSort] = useState('rescues');
  const foodDonorsData = data.foodDonors[foodDonorsSort];
  const receivingAgenciesData = data.receivingAgencies[receivingAgenciesSort];
  const rescuersData = data.rescuers[rescuersSort];

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" color="textSecondary">
            Top 10
          </Typography>
        </Grid>

        {!!showFoodDonorsLeaderboard && (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <OverlayLoader isLoading={inflight}>
              <BaseCard>
                <BaseCardHeader title="Top Food Donors" />

                <CardContent>
                  <CompactWidgetTable
                    data={foodDonorsData}
                    columns={[
                      {
                        name: 'name',
                        label: 'Name',
                        options: {
                          filter: false,
                          customBodyRender: (value, tableMeta) => {
                            const foodDonor = foodDonorsData[getMuiTableDataIndex(tableMeta)];

                            if (!showLinks) {
                              return foodDonor.name;
                            }

                            return (
                              <Link
                                onClick={() =>
                                  history.push(
                                    generatePath(routes.foodDonor, {
                                      foodDonorId: foodDonor.id,
                                    })
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                {foodDonor.name}
                              </Link>
                            );
                          },
                        },
                      },
                      {
                        name: 'rescues',
                        options: {
                          filter: false,
                          customHeadLabelRender: () => (
                            <Typography
                              style={{ cursor: 'pointer', display: 'flex' }}
                              variant="subtitle2"
                              onClick={() => setFoodDonorsSort('rescues')}
                            >
                              Rescues {foodDonorsSort === 'rescues' && <ArrowDownwardIcon fontSize="small" />}
                            </Typography>
                          ),
                          customBodyRenderLite: dataIndex => {
                            const data = foodDonorsData[dataIndex];
                            return formatNumber(data.rescues);
                          },
                        },
                      },
                      {
                        name: 'meals',
                        options: {
                          filter: false,
                          customHeadLabelRender: () => (
                            <Typography
                              style={{ cursor: 'pointer', display: 'flex' }}
                              variant="subtitle2"
                              onClick={() => setFoodDonorsSort('meals')}
                            >
                              Meals {foodDonorsSort === 'meals' && <ArrowDownwardIcon fontSize="small" />}
                            </Typography>
                          ),
                          customBodyRenderLite: dataIndex => formatNumber(foodDonorsData[dataIndex].meals),
                        },
                      },
                      {
                        name: 'meals_lbs',
                        label: 'Lbs',
                        options: {
                          filter: false,
                          customHeadLabelRender: () => (
                            <Typography
                              style={{ cursor: 'pointer', display: 'flex' }}
                              variant="subtitle2"
                              onClick={() => setFoodDonorsSort('lbs')}
                            >
                              Lbs {foodDonorsSort === 'lbs' && <ArrowDownwardIcon fontSize="small" />}
                            </Typography>
                          ),
                          customBodyRenderLite: dataIndex => formatNumber(foodDonorsData[dataIndex].lbs),
                        },
                      },
                      {
                        name: 'lbs_value',
                        options: {
                          filter: false,
                          customHeadLabelRender: () => (
                            <Typography
                              style={{ cursor: 'pointer', display: 'flex' }}
                              variant="subtitle2"
                              onClick={() => setFoodDonorsSort('value')}
                            >
                              $ {foodDonorsSort === 'value' && <ArrowDownwardIcon fontSize="small" />}
                            </Typography>
                          ),
                          customBodyRenderLite: dataIndex => formatValueOfMeals(foodDonorsData[dataIndex].value),
                        },
                      },
                    ]}
                  />
                </CardContent>
              </BaseCard>
            </OverlayLoader>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={6} lg={showFoodDonorsLeaderboard ? 4 : 6}>
          <OverlayLoader isLoading={inflight}>
            <BaseCard>
              <BaseCardHeader title="Top Receiving Agencies" />

              <CardContent>
                <CompactWidgetTable
                  data={receivingAgenciesData}
                  columns={[
                    {
                      name: 'name',
                      label: 'Name',
                      options: {
                        filter: false,
                        customBodyRender: (value, tableMeta) => {
                          const receiver = receivingAgenciesData[getMuiTableDataIndex(tableMeta)];

                          if (!showLinks) {
                            return receiver.name;
                          }

                          return (
                            <Link
                              onClick={() => history.push(generatePath(routes.receiver, { id: receiver.id }))}
                              style={{ cursor: 'pointer', display: 'flex' }}
                            >
                              {receiver.name}
                            </Link>
                          );
                        },
                      },
                    },
                    {
                      name: 'rescues',
                      label: 'Rescues',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setReceivingAgenciesSort('rescues')}
                          >
                            Rescues {receivingAgenciesSort === 'rescues' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatNumber(receivingAgenciesData[dataIndex].rescues),
                      },
                    },
                    {
                      name: 'meals',
                      label: 'Meals',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setReceivingAgenciesSort('meals')}
                          >
                            Meals {receivingAgenciesSort === 'meals' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatNumber(receivingAgenciesData[dataIndex].meals),
                      },
                    },
                    {
                      name: 'meals_lbs',
                      label: 'Lbs',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setReceivingAgenciesSort('lbs')}
                          >
                            Lbs {receivingAgenciesSort === 'lbs' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatNumber(receivingAgenciesData[dataIndex].lbs),
                      },
                    },
                    {
                      name: 'lbs_value',
                      label: '$',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setReceivingAgenciesSort('value')}
                          >
                            $ {receivingAgenciesSort === 'value' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatValueOfMeals(receivingAgenciesData[dataIndex].value),
                      },
                    },
                  ]}
                />
              </CardContent>
            </BaseCard>
          </OverlayLoader>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={showFoodDonorsLeaderboard ? 4 : 6}>
          <OverlayLoader isLoading={inflight}>
            <BaseCard>
              <BaseCardHeader title="Top Rescuers" />

              <CardContent>
                <CompactWidgetTable
                  data={rescuersData}
                  columns={[
                    {
                      name: 'name',
                      label: 'Name',
                      options: {
                        filter: false,
                        customBodyRender: (value, tableMeta) => {
                          const rescuer = rescuersData[getMuiTableDataIndex(tableMeta)];

                          if (!showLinks) {
                            return rescuer.name;
                          }

                          return (
                            <Link
                              onClick={() => history.push(generatePath(routes.userEditNew, { id: rescuer.id }))}
                              style={{ cursor: 'pointer', display: 'flex' }}
                            >
                              {rescuer.name}
                            </Link>
                          );
                        },
                      },
                    },
                    {
                      name: 'rescues',
                      label: 'Rescues',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setRescuersSort('rescues')}
                          >
                            Rescues {rescuersSort === 'rescues' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatNumber(rescuersData[dataIndex].rescues),
                      },
                    },
                    {
                      name: 'meals',
                      label: 'Meals',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setRescuersSort('meals')}
                          >
                            Meals {rescuersSort === 'meals' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatNumber(rescuersData[dataIndex].meals),
                      },
                    },
                    {
                      name: 'meals_lbs',
                      label: 'Lbs',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setRescuersSort('lbs')}
                          >
                            Lbs {rescuersSort === 'lbs' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatNumber(rescuersData[dataIndex].lbs),
                      },
                    },
                    {
                      name: 'lbs_value',
                      label: '$',
                      options: {
                        filter: false,
                        customHeadLabelRender: () => (
                          <Typography
                            style={{ cursor: 'pointer', display: 'flex' }}
                            variant="subtitle2"
                            onClick={() => setRescuersSort('value')}
                          >
                            $ {rescuersSort === 'value' && <ArrowDownwardIcon fontSize="small" />}
                          </Typography>
                        ),
                        customBodyRenderLite: dataIndex => formatValueOfMeals(rescuersData[dataIndex].value),
                      },
                    },
                  ]}
                />
              </CardContent>
            </BaseCard>
          </OverlayLoader>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeaderboardsTables;
