import React, { useEffect, lazy, Suspense, useState } from 'react';
import { generatePath, Link as RouterLink, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Bluebird from 'bluebird';
import { Box, Breadcrumbs } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import routes from '../../../routes';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import MarketingHubDestkopAppBar from './Components/MarketingHubDesktopAppBar';
import {
  fetchUserLinkedAccounts,
  fetchUserLinkedAccountsIfNeeded,
  deleteFacebookPost,
  INSTAGRAM_TYPE,
} from '../../../actions/marketingHub';
import useNotificationService from '../../../hooks/useNotificationService';

const MarketingHubAccounts = lazy(() => import('./Components/MarketingHubAccounts'));
const MarketingHubFeed = lazy(() => import('./Components/MarketingHubFeed'));
const MarketingHubCreate = lazy(() => import('./Components/MarketingHubCreate'));
const MarketingHubMetrics = lazy(() => import('./Components/MarketingHubMetrics'));
const MarketingHubCalendar = lazy(() => import('./Components/MarketingHubCalendar'));

export const MARKETING_HUB_DESKTOP_TAB_FEED = 'feed';
export const MARKETING_HUB_DESKTOP_TAB_CREATE = 'create';
export const MARKETING_HUB_DESKTOP_TAB_METRICS = 'metrics';
export const MARKETING_HUB_DESKTOP_TAB_CALENDAR = 'calendar';

export const paths = [
  generatePath(routes.marketingHub),
  generatePath(routes.marketingHub, { tab: MARKETING_HUB_DESKTOP_TAB_FEED }),
  generatePath(routes.marketingHub, { tab: MARKETING_HUB_DESKTOP_TAB_CREATE }),
  generatePath(routes.marketingHub, { tab: MARKETING_HUB_DESKTOP_TAB_METRICS }),
  generatePath(routes.marketingHub, { tab: MARKETING_HUB_DESKTOP_TAB_CALENDAR }),
];

const MarketingHubDesktop = () => {
  const dispatch = useDispatch();
  const sdkInitialized = useSelector((state) => state.entities.marketingHub.sdkInit);
  const accounts = useSelector((state) => state.entities.marketingHub.accounts.all);
  const { addErrorNotification, addSuccessNotification } = useNotificationService();
  const [isLoading, setIsLoading] = useState(false);

  // Placeholder for API
  useEffect(() => {
    if (sdkInitialized) {
      window.FB.getLoginStatus((res) => {
        if (res.status === 'connected') {
          dispatch(fetchUserLinkedAccountsIfNeeded(res.authResponse.userID, res.authResponse.accessToken));
          return console.log('Connected!', res);
        }
        return console.log('Not connected', res);
      });
    }
  }, [sdkInitialized]);

  const handleAddClick = () => {
    window.FB.login(
      (res) => {
        if (res.authResponse) {
          console.log('Welcome!  Fetching your information.... ');
          dispatch(fetchUserLinkedAccounts(res.authResponse.userID, res.authResponse.accessToken, true));
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope:
          'pages_show_list, public_profile, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_posts, pages_manage_engagement, read_insights',
        return_scopes: true,
      }
    );
  };

  const handleAddInstagramClick = () => {
    window.FB.login(
      (res) => {
        if (res.authResponse) {
          console.log('Welcome!  Fetching your information.... ');
          dispatch(fetchUserLinkedAccounts(res.authResponse.userID, res.authResponse.accessToken, true));
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope:
          'instagram_basic, pages_show_list, public_profile, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_posts, pages_manage_engagement, instagram_manage_insights, read_insights, instagram_content_publish',
        return_scopes: true,
      }
    );
  };

  const scrollTop = () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  const handleDeletePost = (postId, accountId) => {
    setIsLoading(true);
    // Get post account for access token
    const account = accounts.find((a) => a.id === accountId);
    // Deleting posts is not yet available in Instagram API
    if (!account || account.type === INSTAGRAM_TYPE) {
      setIsLoading(false);
      return null;
    }

    return Bluebird.try(() =>
      dispatch(deleteFacebookPost(postId, account.access_token))
        .then(() => addSuccessNotification('Post deleted'))
        .catch((err) => addErrorNotification(err))
        .finally(() => {
          setIsLoading(false);
          scrollTop();
        })
    );
  };

  return (
    <div data-testid="marketing-hub-desktop-div">
      <Box boxShadow={0}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledRescuerBreadcrumb
            component={RouterLink}
            to={generatePath(routes.index)}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <StyledRescuerTextBreadcrumb component="a" href="#" label="Marketing Hub" />
        </Breadcrumbs>
      </Box>

      <MarketingHubDestkopAppBar />

      <Suspense fallback={null}>
        <Switch>
          <Route
            exact
            path={paths[0]}
            render={() => (
              <MarketingHubAccounts handleAddClick={handleAddClick} handleAddInstagramClick={handleAddInstagramClick} />
            )}
          />
          <Route path={paths[1]} component={MarketingHubFeed} />
          <Route path={paths[2]} render={() => <MarketingHubCreate />} />
          <Route path={paths[3]} component={MarketingHubMetrics} />
          <Route
            path={paths[4]}
            render={() => <MarketingHubCalendar handleDeletePost={handleDeletePost} isLoading={isLoading} />}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

export default MarketingHubDesktop;
