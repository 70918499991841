export const foodTypes = [
  { name: 'beverages', label: 'Beverages' },
  { name: 'bread-bagels', label: 'Bread/Bagels' },
  { name: 'dairy-milk-eggs', label: 'Dairy/Milk/Eggs' },
  { name: 'donuts-pastries-cakes', label: 'Donuts/Pastries/Cakes' },
  { name: 'fruits-and-vegetables', label: 'Fruits & Vegetables' },
  { name: 'meat-poultry-seafood', label: 'Meat/Poultry/Seafood' },
  { name: 'non-food', label: 'Non-food' },
  { name: 'non-perishables', label: 'Non-perishables' },
  { name: 'pizza', label: 'Pizza' },
  { name: 'prepared-food', label: 'Prepared food' },
  { name: 'sandwiches', label: 'Sandwiches' },
  { name: 'soup', label: 'Soup' },
];

export const newFoodTypes = [
  { name: 'beverages', label: 'Beverages' },
  { name: 'baked-goods', label: 'Baked Goods' },
  { name: 'produce', label: 'Produce' },
  { name: 'dairy', label: 'Dairy' },
  { name: 'eggs', label: 'Eggs' },
  { name: 'meat', label: 'Meat' },
  { name: 'non-perishable', label: 'Non-perishable' },
  { name: 'non-food', label: 'Non-food' },
  { name: 'prepared-ready-to-eat', label: 'Prepared/Ready to Eat' },
];

export const foodTypesSubCategory = [
  { name: 'soft-drinks', label: 'Soft Drinks', type: 'beverages' },
  { name: 'water', label: 'Water', type: 'beverages' },
  { name: 'fruit-juice', label: 'Fruit Juice', type: 'beverages' },
  { name: 'sports-drinks', label: 'Sports Drinks', type: 'beverages' },
  { name: 'bread', label: 'Bread', type: 'baked-goods' },
  { name: 'bagels', label: 'Bagels', type: 'baked-goods' },
  { name: 'pastries', label: 'Pastries', type: 'baked-goods' },
  { name: 'cookies', label: 'Cookies', type: 'baked-goods' },
  { name: 'cakes', label: 'Cakes', type: 'baked-goods' },
  { name: 'fruits', label: 'Fruits', type: 'produce' },
  { name: 'vegetables', label: 'Vegetables', type: 'produce' },
  { name: 'leafy-vegetables', label: 'Leafy Vegetables', type: 'produce' },
  { name: 'milk', label: 'Milk', type: 'dairy' },
  { name: 'yogurt', label: 'Yogurt', type: 'dairy' },
  { name: 'cheese', label: 'Cheese', type: 'dairy' },
  { name: 'butter', label: 'Butter', type: 'dairy' },
  { name: 'ice-cream', label: 'Ice Cream', type: 'dairy' },
  { name: 'beef', label: 'Beef', type: 'meats' },
  { name: 'pork', label: 'Pork', type: 'meats' },
  { name: 'poultry', label: 'Poultry', type: 'meats' },
  { name: 'seafood', label: 'Seafood', type: 'meats' },
  { name: 'plant-based-meat', label: 'Plant-based meat', type: 'meats' },
  { name: 'canned-goods', label: 'Canned Goods', type: 'non-perishables' },
  { name: 'dry-pasta', label: 'Dry Pasta', type: 'non-perishables' },
  { name: 'rice', label: 'Rice', type: 'non-perishables' },
  { name: 'peanut-butter', label: 'Peanut Butter', type: 'non-perishables' },
  { name: 'jam-jelly', label: 'Jam/Jelly', type: 'non-perishables' },
  { name: 'pizza-frozen', label: 'Pizza (frozen)', type: 'prepared-ready-to-eat' },
  { name: 'pizza-prepared', label: 'Pizza (prepared)', type: 'prepared-ready-to-eat' },
  { name: 'soup', label: 'Soup', type: 'prepared-ready-to-eat' },
  { name: 'sandwiches', label: 'Sandwiches', type: 'prepared-ready-to-eat' },
  { name: 'salad', label: 'Salad', type: 'prepared-ready-to-eat' },
];
