import Bluebird from 'bluebird';
import * as siteGoalsApi from '../api/siteGoals';

export const REQUEST_SITE_GOALS = 'REQUEST_SITE_GOALS';
export const RECEIVE_SITE_GOALS = 'RECEIVE_SITE_GOALS';
export const ADD_SITE_GOAL = 'ADD_SITE_GOAL';

export const requestSiteGoals = () => ({
  type: REQUEST_SITE_GOALS,
});

export const receiveSiteGoals = (data) => ({
  type: RECEIVE_SITE_GOALS,
  goals: data,
  receivedAt: Date.now(),
});

export const fetchSiteGoals = (params, quietMode) => (dispatch) => {
  if (!quietMode) {
    dispatch(requestSiteGoals());
  }

  return Bluebird.try(() => siteGoalsApi.getSiteGoals(params))
    .then((res) => res.json())
    .then((json) => dispatch(receiveSiteGoals(json.data)));
};
