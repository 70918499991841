import React from 'react';
import { withStyles } from '@material-ui/core';

const TableActionsButtonsWrapper = ({ children, classes }) => ((
  <div className={classes.tableActionsWrapper}>
    {children}
  </div>
));

const styles = () => ({
  tableActionsWrapper: {
    '& > button:first-child': {
      marginLeft: 0,
    },
  },
});

export default withStyles(styles)(TableActionsButtonsWrapper);
