import { apiPOST } from './api';

export const login = (email, password) => apiPOST(
  '/login',
  {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: email,
    password: password,
  }
);

export const loginViaSocial = (accessToken, idToken) => apiPOST(
  '/login-via-social',
  {
    access_token: accessToken,
    id_token: idToken,
  }
);

export const register = (email, password, invitationHash = null) => apiPOST(
  '/register',
  {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: email,
    password: password,
    invitation_hash: invitationHash,
  }
);

export const forgotPassword = email => apiPOST('/forgot_password', { email: email });

export const resetPassword = (token, password) => apiPOST('/reset_password', { token: token, password: password });
