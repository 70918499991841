import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Bluebird from 'bluebird';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  Save as SaveIcon,
  RotateLeft as RotateLeftIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Description from '../Components/Description';
import Schedule from '../Components/Schedule';
import { fetchRescueSizesIfNeeded } from '../../../../actions/rescueSizes';
import {
  fetchSiteDonorsLocationsIfNeeded,
  fetchSitePickupLocationsIfNeeded,
  fetchSiteReceiversIfNeeded,
  fetchSiteRescuersIfNeeded,
} from '../../../../actions/sites';
import useActiveSite from '../../../../hooks/useActiveSite';
import {
  createDraftDonation,
  diffDonation,
  DONATION_TYPE_SD,
  saveDonation,
  setDonationDiffInflight,
} from '../../../../actions/donationNew';
import DonationContext from '../../../../context/DonationContext/DonationContext';
import useDonationManageActions from '../../../../hooks/useDonationManageActions';
import Pickups from '../Components/Pickups';
import Calendar from '../Components/Calendar';
import { donationManageActions } from '../../../../helpers/donations';
import OverlayLoader from '../../../../components/OverlayLoader';
import useNotificationService from '../../../../hooks/useNotificationService';
import { generatePath, Link } from 'react-router-dom';
import routes from '../../../../routes';
import usePermission from '../../../../hooks/usePermission';
import ACLService from '../../../../services/acl';
import { FREQUENCY_ONCE } from '../../../../models/donationsNew';
import { fetchSystemSettingsIfNeeded } from '../../../../actions/systemSettings';
import errorMessages from '../../../../assets/errorMessages';
import { validateDonation } from '../donationValidator';
import * as authService from '../../../../services/auth';
import { Roles } from '../../../../models/roles';
import { fetchReceiversIfNeeded } from '../../../../actions/receivers';

const useStyles = makeStyles(({ typography, palette, breakpoints, spacing }) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    display: 'flex',
    columnGap: spacing(1),
    alignItems: 'baseline',
  },
  secondaryHeading: {
    fontSize: typography.pxToRem(15),
    color: palette.text.secondary,
  },
  buttonsFlex: {
    marginTop: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  buttonsRow: {
    display: 'flex',
    gridColumnGap: '8px',
    [breakpoints.only('xs')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
    [breakpoints.only('md')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
  },
}));

const AGENDA_DESCRIPTION = 'Description';
const AGENDA_SCHEDULE = 'Schedule';

const hasDescriptionValidationErrors = errors => ['lbs'].some(field => errors.includes(field));

const hasScheduleValidationErrors = errors => errors.length > 0 && !hasDescriptionValidationErrors(errors);

const DonationCreate = ({ isMobileView, match, location, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const [firstFoodDonorChoice, setFirstFoodDonorChoice] = useState(
    match.params.location_id ? parseInt(match.params.location_id, 10) : null
  );
  const [expanded, setExpanded] = React.useState(AGENDA_DESCRIPTION);
  const rescueSizesInflight = useSelector(state => state.entities.rescueSizes.inflight);
  const pickupLocations = useSelector(state => state.entities.sites.pickupLocations);
  const foodDonors = useSelector(state => state.entities.sites.donorsLocations);
  const receivers = useSelector(state => state.entities.sites.receivers);
  const foodDonor = foodDonors.byId[match.params.location_id];
  const pickupLocation = pickupLocations.byId[match.params.pickup_location_id];
  const receiver = receivers.byId[match.params.receiver_id];
  const donationData = useSelector(state => state.ui.donation_new.draft);
  const currentDonationState = useSelector(state => state.ui.donation_new.currentState);
  const futureDonationState = useSelector(state => state.ui.donation_new.futureState);
  const diffInflight = useSelector(state => state.ui.donation_new.diffInflight);
  const donationFormErrors = useSelector(state => state.ui.donation_new.errors);
  const { addErrorNotification, addSuccessNotification } = useNotificationService();
  const hasFoodDonorsList = usePermission({
    resource: ACLService.resources.foodDonorsList,
  });

  const hasFoodDonorDefined = donationData && !!donationData.location;
  const hasDefinedDonorAndLocation = match.path === routes.donation_add_new2;
  const hasDefinedDonorLocationAndReceiver = match.path === routes.donation_add_from_map;

  const handleFoodDonorChange = foodDonorId => {
    if (firstFoodDonorChoice) {
      return;
    }

    setFirstFoodDonorChoice(foodDonorId);
  };

  const {
    clearDonation,
    undoLastAction,
    redoLastAction,
    resetDonation,

    setError,
    removeError,

    setFoodDonor,
    setPickupLocation,
    setDescription,
    setLBSValue,
    setAddFoodTypes,
    setRemoveFoodTypes,
    setAddFoodSubCategory,
    setRemoveFoodSubCategory,
    setFoodTypeOther,
    setRescueSize,
    setFoodSize,
    setAddDayOfWeek,
    setRemoveDayOfWeek,

    setAddDayOfMonth,
    setDayOfMonthDay,
    setDayOfMonthOrdinal,
    setRemoveDayOfMonth,

    setStartDate,
    setEndDate,
    setPauseDate,
    setResumeDate,
    setFrequency,
    setAppendPickup,
    setRemovePickup,
    setPickupReceiver,
    setPickupAdopter,
    setPickupRescuer,
    setPickupRescuerNotes,
    setPickupBegin,
    setPickupEnd,
    setAllDaysTheSame,
    clearAllDaysTheSame,
    setAllPickupsTheSame,
    clearAllPickupsTheSame,

    setRescuePickupBegin,
    setRescuePickupEnd,
    setRescueRescuerNotes,
    setRescueCanceller,
    setRescueRescuer,
    setNationalDonation,
  } = useDonationManageActions();

  useEffect(() => {
    dispatch(
      createDraftDonation({
        donation: {
          description: '',
          lbs: null,
          start_date: {
            value: moment().format('YYYY-MM-DD'),
            format: 'YYYY-MM-DD',
          },
          end_date: null,
          pause_date: null,
          resume_date: null,
          frequency: FREQUENCY_ONCE,
          frequency_every: null,
          days_of_week: {
            mon: {
              checked: false,
              allow_uncheck: null,
            },
            tue: {
              checked: false,
              allow_uncheck: null,
            },
            wed: {
              checked: false,
              allow_uncheck: null,
            },
            thu: {
              checked: false,
              allow_uncheck: null,
            },
            fri: {
              checked: false,
              allow_uncheck: null,
            },
            sat: {
              checked: false,
              allow_uncheck: null,
            },
            sun: {
              checked: false,
              allow_uncheck: null,
            },
          },
          days_of_month: {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: [],
          },
          all_days_are_the_same: true,
          all_pickups_are_the_same: true,
          food_types: ['fresh'],
          food_type_other: null,
          rescue_size: null,
          location: null,
          pickup_location: null,
        },
      })
    );
    dispatch(fetchSiteReceiversIfNeeded(activeSite.id));
    dispatch(fetchSiteDonorsLocationsIfNeeded(activeSite.id));
    dispatch(fetchSitePickupLocationsIfNeeded(activeSite.id));
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSiteRescuersIfNeeded(activeSite.id));
    dispatch(fetchSystemSettingsIfNeeded()); // used in LBSSelector
    dispatch(fetchReceiversIfNeeded());

    if (location.state && location.state.copy && location.state.rescue) {
      handleFoodDonorChange(location.state.rescue.location_id);
      setFoodDonor({
        id: location.state.rescue.location_id,
        name: location.state.rescue.location,
      });
      setPickupLocation({
        name: location.state.rescue.pickup_location_name,
        id: location.state.rescue.pickup_location_id,
      })
      setRescueSize({ id: location.state.rescue.rescue_size_id });
      location.state.rescue.food_types.split(',').forEach(foodType => setAddFoodTypes(foodType));
      location.state.rescue.food_type_other && setFoodTypeOther(location.state.rescue.food_type_other);
      location.state.rescue.slug && setDescription(location.state.rescue.slug);
      setPickupRescuerNotes({
        rescuer_notes: location.state.rescue.rescuer_notes,
      });

      // clear location.state
      history.replace({
        ...location,
        state: {
          ...location.state,
          copy: false,
          rescue: undefined,
        },
      });
    }

    return () => clearDonation();
  }, [dispatch]);

  useEffect(() => {
    if (!foodDonors.inflight && foodDonor) {
      setFoodDonor(foodDonor);
    }
  }, [foodDonors.inflight, Object.keys(foodDonors.byId).length]);

  useEffect(() => {
    if (!pickupLocations.inflight && pickupLocation) {
      setPickupLocation(pickupLocation);
      triggerFormChange();
    }
  }, [pickupLocations.inflight, Object.keys(pickupLocations.byId).length]);

  useEffect(() => {
    if (!receivers.inflight && receiver) {
      setPickupReceiver({ receiver_id: receiver.id });
    }
  }, [receivers.inflight, Object.keys(receivers.byId).length]);

  const onSubmit = async () => {
    try {
      const validation = validateDonation(donationData);

      if (!validation.isValid) {
        return Object.keys(validation.errors).forEach(error => setError(error, validation.errors[error]));
      }
      const donation = await dispatch(saveDonation(null, donationData.location.id, currentDonationState));
      addSuccessNotification('Donation created!');
      history.push(generatePath(routes.donation_edit, { donationId: donation.id }));
    } catch (e) {
      dispatch(setDonationDiffInflight(false));

      return addErrorNotification(e, 'Unable to create donation');
    }
  };

  useEffect(() => {
    // Do not triggerFormChange here if donor, location and receiver are predefined otherwise it will overwrite them
    if (!hasDefinedDonorLocationAndReceiver) {
      // triggerFormChange after firstFoodDonorChoice is defined
      triggerFormChange();
    }
  }, [firstFoodDonorChoice]);

  const triggerFormChange = () => {
    if (firstFoodDonorChoice) {
      return Bluebird.try(() => dispatch(diffDonation(null, firstFoodDonorChoice))).catch(e => {
        addErrorNotification(e, e.message || 'Unknown error during modifying donation');
        dispatch(setDonationDiffInflight(false));
        undoLastAction();
      });
    }
  };

  const handleFieldChange = (actionName, fieldValue) => {
    switch (actionName) {
      case donationManageActions.set_food_donor:
        handleFoodDonorChange(fieldValue.id);
        setFoodDonor(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_location:
        setPickupLocation(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_description:
        setDescription(fieldValue);
        break;

      case donationManageActions.set_lbs_value:
        setLBSValue(fieldValue);
        if (fieldValue === '') {
          setError('lbs', errorMessages.REQUIRED.message);
        } else {
          removeError('lbs');
        }
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_size:
        setRescueSize(fieldValue);
        break;

      case donationManageActions.set_food_size:
        setFoodSize(fieldValue);
        break;

      case donationManageActions.add_food_type:
        setAddFoodTypes(fieldValue);
        break;

      case donationManageActions.remove_food_type:
        setRemoveFoodTypes(fieldValue);
        break;

      case donationManageActions.add_food_sub_category:
        setAddFoodSubCategory(fieldValue);
        break;

      case donationManageActions.remove_food_sub_category:
        setRemoveFoodSubCategory(fieldValue);
        break;

      case donationManageActions.set_food_type_other:
        setFoodTypeOther(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_frequency:
        setFrequency(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_week:
        setAddDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_week:
        setRemoveDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_month:
        setAddDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_day:
        setDayOfMonthDay(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_ordinal:
        setDayOfMonthOrdinal(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_month:
        setRemoveDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_start_date:
        setStartDate({
          startDate: fieldValue,
        });
        triggerFormChange();
        break;

      case donationManageActions.set_end_date:
        setEndDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pause_date:
        setPauseDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_resume_date:
        setResumeDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_all_days_are_the_same:
        setAllDaysTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_days_are_the_same:
        clearAllDaysTheSame();
        triggerFormChange();

        break;
      case donationManageActions.set_all_pickups_are_the_same:
        setAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_pickups_are_the_same:
        clearAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.add_pickup:
        setAppendPickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_pickup:
        setRemovePickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_receiver:
        setPickupReceiver(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_adopter:
        setPickupAdopter(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_rescuer:
        setPickupRescuer(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_rescuer_notes:
        setPickupRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_begin:
        setPickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_end:
        setPickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_begin:
        setRescuePickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_end:
        setRescuePickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer_notes:
        setRescueRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_canceller:
        setRescueCanceller(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer:
        setRescueRescuer(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_national_donation:
        setNationalDonation(fieldValue);
        break;

      default:
        console.error(`---->>>> #Unknown donation field "${actionName}" with given value "${fieldValue}"`);
    }
  };

  const handleAccordionChange = panel => (event, isExpanded) => {
    if (!hasFoodDonorDefined) {
      return setExpanded(AGENDA_DESCRIPTION);
    }
    setExpanded(isExpanded ? panel : expanded === AGENDA_DESCRIPTION ? AGENDA_SCHEDULE : AGENDA_DESCRIPTION);
  };

  if (
    !authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
      Roles.Admin,
      Roles.NationalSiteDirector,
      Roles.SiteDirector,
      Roles.SiteCoordinator,
    ])
  ) {
    return history.push(routes.index);
  }

  if (donationData === null || rescueSizesInflight || foodDonors.inflight || pickupLocations.inflight) {
    return 'loading...';
  }

  const renderDonationButtons = () => (
    <Box className={classes.buttonsFlex}>
      <Box className={classes.buttonsRow}>
        <Button
          disabled={currentDonationState.length === 0}
          color="primary"
          variant="contained"
          type="button"
          onClick={onSubmit}
          startIcon={<SaveIcon />}
          data-testid="save-schedule"
        >
          Save {expanded}
        </Button>
      </Box>
      <Box className={classes.buttonsRow}>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          startIcon={<RotateLeftIcon />}
          disabled={currentDonationState.length === 0}
          onClick={() => {
            resetDonation();
            triggerFormChange();
          }}
        >
          Reset
        </Button>

        <Button
          startIcon={<UndoIcon />}
          color="secondary"
          variant="contained"
          disabled={currentDonationState.length === 0}
          onClick={() => {
            undoLastAction();
            triggerFormChange();
          }}
        >
          Undo
        </Button>

        <Button
          startIcon={<RedoIcon />}
          color="secondary"
          variant="contained"
          disabled={futureDonationState.length === 0}
          onClick={() => {
            redoLastAction();
            triggerFormChange();
          }}
        >
          Redo
        </Button>
      </Box>
    </Box>
  );

  return (
    <DonationContext.Provider
      value={{
        donationType: DONATION_TYPE_SD,
        onFieldChange: handleFieldChange,
        isLoading: diffInflight,
        hasFoodDonorDefined: hasFoodDonorDefined,
        showFoodDonorSelector: !hasDefinedDonorAndLocation,
        isMobileView: isMobileView,
        isNew: true,
        errors: donationFormErrors,
      }}
    >
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        {!isMobileView && hasFoodDonorsList ? (
          <Link color="inherit" to={routes.foodDonors}>
            Food Donors
          </Link>
        ) : (
          <Typography color="textPrimary">Food Donors</Typography>
        )}

        {hasDefinedDonorAndLocation && (
          <Link
            color="inherit"
            to={generatePath(routes.foodDonor, {
              foodDonorId: match.params.location_id,
            })}
          >
            {foodDonor.name}
          </Link>
        )}

        <Typography color="textPrimary">Add New Donation</Typography>
      </Breadcrumbs>

      <Grid container spacing={3}>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} md={6} lg={8}>
          <Grid item xs={12}>
            <Accordion
              elevation={25}
              expanded={expanded === AGENDA_DESCRIPTION}
              onChange={handleAccordionChange(AGENDA_DESCRIPTION)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-description"
                id="description-header"
              >
                <Typography className={classes.heading}>
                  Description
                  {hasDescriptionValidationErrors(Object.keys(donationFormErrors)) && (
                    <Typography component="span" color="error">
                      Missing info
                    </Typography>
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Description />
              </AccordionDetails>
            </Accordion>

            <Accordion
              disabled={!hasFoodDonorDefined}
              elevation={25}
              expanded={expanded === AGENDA_SCHEDULE}
              onChange={handleAccordionChange(AGENDA_SCHEDULE)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-schedule" id="schedule-header">
                <Typography className={classes.heading}>
                  Schedule
                  {hasScheduleValidationErrors(Object.keys(donationFormErrors)) && (
                    <Typography component="span" color="error">
                      Validation error
                    </Typography>
                  )}
                </Typography>

                {!hasFoodDonorDefined && (
                  <Typography className={classes.secondaryHeading}>Please select Food Donor first!</Typography>
                )}
              </AccordionSummary>

              <AccordionDetails>
                <OverlayLoader isLoading={diffInflight} wrapperStyles={{ width: '100%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Schedule receiverPreselected={receiver} />
                    </Grid>

                    <Grid item xs={12}>
                      <Pickups />
                    </Grid>
                  </Grid>
                </OverlayLoader>
              </AccordionDetails>
            </Accordion>

            {!isMobileView && renderDonationButtons()}
          </Grid>
        </Grid>

        <Grid item xs>
          <Card square elevation={25}>
            <CardContent>
              <Calendar />
            </CardContent>
          </Card>
        </Grid>

        {isMobileView && (
          <Grid item xs>
            {renderDonationButtons()}
          </Grid>
        )}
      </Grid>
    </DonationContext.Provider>
  );
};

export default DonationCreate;
