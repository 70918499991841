import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

const PhoneTypeSelectNew = ({
  id,
  name,
  className,
  label,
  value,
  onChange,
  error,
  helperText,
  disabled,
  InputLabelProps,
  fullWidth,
}) => (
  <TextField
    id={id}
    name={name}
    className={className}
    label={label}
    variant="outlined"
    size="small"
    value={value}
    onChange={onChange}
    error={error}
    helperText={helperText}
    disabled={disabled}
    InputLabelProps={InputLabelProps}
    select
    data-testid="phone-type-select"
    fullWidth={fullWidth}
  >
    {[{ id: 'mobile', name: 'Mobile' }, { id: 'office', name: 'Office' }].map(phoneType => (
      <MenuItem key={phoneType.id} value={phoneType.id}>
        {phoneType.name}
      </MenuItem>
    ))}
  </TextField>
);

export default PhoneTypeSelectNew;
