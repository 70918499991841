import Bluebird from 'bluebird';
import * as donationsApi from '../api/donations';
import { mapFormToQuickCreateOneTimeDonationPOST } from '../models/donations';

export const REQUEST_DONATION_DELETE = 'REQUEST_DONATION_DELETE';
export const RECEIVE_DONATION_DELETE = 'RECEIVE_DONATION_DELETE';
export const REQUEST_DONATIONS = 'REQUEST_DONATIONS';
export const RECEIVE_DONATIONS = 'RECEIVE_DONATIONS';

export const requestDonations = () => ({
  type: REQUEST_DONATIONS,
});

export const requestDonationDelete = () => ({
  type: REQUEST_DONATION_DELETE,
});

export const receiveDonationDelete = () => ({
  type: RECEIVE_DONATION_DELETE,
});

export const createQuickDonation = donation => donationsApi
  .createDonation(mapFormToQuickCreateOneTimeDonationPOST(donation))
  .then(response => response.json())

export const deleteDonation = donationId => dispatch => {
  dispatch(requestDonationDelete());

  return Bluebird.try(() => donationsApi.deleteDonation(donationId)).then(() => dispatch(receiveDonationDelete()));
};
