import React from 'react';
import { useParams, matchPath } from 'react-router';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import routes from '../../../routes';

const RenderDashboardBreadcrumb = ({ eventId }) => (
  <Breadcrumbs aria-label="Breadcrumbs">
    <Link color="inherit" to={routes.index}>
      Dashboard
    </Link>

    <Typography color="textPrimary">{eventId}</Typography>
  </Breadcrumbs>
);

const EventDetailsBreadcrumb = () => {
  const { eventId } = useParams();
  const location = useLocation();

  if (!get(location, 'state.prevPath')) {
    return <RenderDashboardBreadcrumb eventId={eventId} />;
  }

  const {
    state: { prevPath },
  } = location;

  let origin = matchPath(prevPath, {
    path: routes.eventsList,
    exact: true,
  })
    ? 'Events'
    : undefined;

  origin = matchPath(prevPath, {
    path: routes.rescuesSchedule,
    exact: true,
  })
    ? 'Schedule'
    : origin;

  origin =
    matchPath(prevPath, {
      path: routes.dashboardRescuer,
      exact: true,
    }) ||
    matchPath(prevPath, {
      path: routes.dashboardSC,
      exact: true,
    })
      ? 'Dashboard'
      : origin;

  if (!origin) {
    return <RenderDashboardBreadcrumb eventId={eventId} />;
  }

  return (
    <Breadcrumbs aria-label="Breadcrumbs">
      <Link color="inherit" to={prevPath}>
        {origin}
      </Link>

      <Typography color="textPrimary">{eventId}</Typography>
    </Breadcrumbs>
  );
};

export default EventDetailsBreadcrumb;
