import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Paper } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import RescueReport from '../components/Rescue/Report/RescueReportForm';
import * as siteActions from '../actions/sites';
import OverlaySpinner from './OverlaySpinner';
import RescueReportTable from '../components/Rescue/Report/RescueReportTable';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import { assign, get } from 'lodash';
import { sortAlphabetically } from '../helpers/sorters';
import useActiveUser from '../hooks/useActiveUser';
import useUIFilter from '../hooks/useUIFilter';
import {
  dateRangeOptionsList,
  defaultGranularityOptions,
} from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import { fetchRescueReportIfNeeded } from '../actions/reports';
import { Colors } from '../assets/theme/Colors';

export const RESCUE_REPORT_UI_FILTER = 'report-rescues';
const RescueReportView = () => {
  const activeUser = useActiveUser();
  const dispatch = useDispatch();
  const isMediumHeight = useMediaQuery('(min-height: 680px)');
  const [filtersExpanded, setFiltersExpanded] = useState(true);
  const sites = useSelector(state => state.entities.sites);
  const rescuesData = useSelector(state => state.entities.reports.rescues);
  const { setUIFilter, getUIFilter } = useUIFilter();
  const filters = assign(
    {
      period: dateRangeOptionsList.thisMTD.value,
      showLegend: true,
      byRescueSize: false,
      startDate: null,
      endDate: null,
      granularity: defaultGranularityOptions.monthly.value,
      siteIds: [],
    },
    getUIFilter(RESCUE_REPORT_UI_FILTER)
  );

  const sitesWithSDSCAccess = authService.getUserSitesByRoles(activeUser.role_assignments, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);
  const isAdmin = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);

  let sitesList = isAdmin
    ? Object.values(sites.byId)
    : Object.values(sites.byId).filter(site => sitesWithSDSCAccess.includes(site.id));

  sitesList = sitesList.sort((a, b) => sortAlphabetically('asc', b.name, a.name));

  // fires on component mount
  useEffect(() => {
    dispatch(siteActions.fetchSitesIfNeeded());
  }, [dispatch]);

  const setReportTableHeight = () => {
    const filtersHeight = filtersExpanded ? 275 : 48;
    const tableContainer = document.querySelector('div.table-container');
    if (tableContainer) {
      tableContainer.style.height =
        isMediumHeight || !filtersExpanded ? `calc(100vh - ${225 + filtersHeight}px)` : '50vh';
    }
  };

  const handleChange = (event, isExpanded) => setFiltersExpanded(isExpanded);

  const fetchReport = () => {
    // prevent fetching (future) data when dateRange not defined
    if (filters.startDate !== null && filters.endDate !== null) {
      const fetchSiteList = isAdmin ? [] : sitesWithSDSCAccess;
      const siteIdsToFetch = filters.siteIds.length === 0 ? fetchSiteList : filters.siteIds.map(site => site.id);

      dispatch(fetchRescueReportIfNeeded(filters.startDate, filters.endDate, filters.granularity, siteIdsToFetch));
    }
  };

  useEffect(() => {
    setReportTableHeight();
  }, [filtersExpanded]);

  return (
    <OverlaySpinner isLoading={get(rescuesData, 'inflight', true) || sites.inflight}>
      <Grid container>
        <Grid item xs={12}>
          <Accordion expanded={filtersExpanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" color="textPrimary">
                Rescue Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RescueReport
                isAdmin={isAdmin}
                defaultValues={filters}
                setFilter={filterData => setUIFilter(RESCUE_REPORT_UI_FILTER, filterData)}
                fetchReport={fetchReport}
                sitesList={sitesList}
              />
            </AccordionDetails>
          </Accordion>

          <RescueReportTable
            isAdmin={isAdmin}
            rescueReportData={{
              sites: rescuesData.sites,
              periods: rescuesData.periods,
              total: rescuesData.total,
            }}
            sitesList={sitesList}
            filters={filters}
          />
        </Grid>
      </Grid>
    </OverlaySpinner>
  );
};

export default RescueReportView;
