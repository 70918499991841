import React from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs, Typography, Paper, makeStyles, Button, Link } from '@material-ui/core';
import { isInPWAView } from '../components/Add2HS/PWAInstallButton';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
}));

const TermsOfService = ({ type }) => {
  const classes = useStyles();

  if (type === 'food-donor') {
    return (
      <>
        <Breadcrumbs aria-label="Breadcrumbs">
          <Typography color="textPrimary">Terms of Service</Typography>
        </Breadcrumbs>

        <Paper className={classes.paper} elevation={24}>
          <Typography gutterBottom variant="body1">
            Food Rescue US is dedicated to ending food-insecurity through the rescue and direct transfer of nutritious,
            fresh foods. Our innovative technology and volunteer-driven platform enables us to streamline the food
            rescue process, maximizing the quality and quantity of food we deliver to hungry families. Our core values
            guide every aspect of our work: Community, Passion, Creativity, Simplicity, Gratitude.
          </Typography>
          <ol>
            <Typography component="li" gutterBottom variant="body1">
              By working together, Food Rescue US (f/k/a Community Plates), food donors like you, our volunteers and the
              receiving agencies that distribute and serve rescued food, we can help end food insecurity.
            </Typography>

            <Typography component="li" gutterBottom variant="body1">
              It is understood that the undersigned Food Donor may donate prepared, perishable or non-perishable food to
              Food Rescue US or our designee. Food Rescue US will deliver food donated by the Food Donor to recipient
              agencies.
            </Typography>
            <Typography component="li" gutterBottom variant="body1">
              Food Rescue US is dedicated to ending food-insecurity through the rescue and direct transfer of
              nutritious, fresh foods. Our innovative technology and volunteer In order that we work together
              effectively to accomplish this mission we believe it is good to set forth our basic terms of cooperation.
            </Typography>
            <li>
              <ul>
                <Typography component="li" gutterBottom variant="body1">
                  The Food Donor and Food Rescue US agree to the following:
                </Typography>

                <Typography component="li" gutterBottom variant="body1">
                  Compliance with Guidelines
                </Typography>
                <Typography component="li" gutterBottom variant="body1">
                  Obligation of Food Rescue US
                </Typography>
                <Typography component="li" gutterBottom variant="body1">
                  Food Rescue US will distribute donated food free of charge and represents and warrants that
                </Typography>
                <Typography component="li" gutterBottom variant="body1">
                  The donated food will be distributed for free to receiving organizations Food Rescue US is a nonprofit
                  organization that is operating for religious, charitable, or educational purposes
                </Typography>
              </ul>
            </li>

            <Typography component="li" gutterBottom variant="body1">
              Donor hereby expressly disclaims all warranties, written or oral, statutory, express or implied, including
              any warranty of wholesomeness, merchantability, condition, quality, fitness for use, including any
              warranty regarding the absence of any defects therein, whether latent or patent; it being understood and
              agreed that the food being donated in their current condition as of the date of donation. In connection
              with Food Donor’s donation of food, Food Donor shall in no event be liable for any claim whatsoever by or
              through Food Rescue US, or any third party, for any issue or problem with the food.
            </Typography>
          </ol>
        </Paper>
      </>
    );
  }

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Typography color="textPrimary">Terms of Service</Typography>
      </Breadcrumbs>

      <Paper className={classes.paper} elevation={24}>
        <Typography gutterBottom variant="body1">
          Food Rescue US is dedicated to ending food insecurity and mitigating the environmental impact of food waste
          through the rescue and direct transfer of surplus, nutritious, fresh foods. Our innovative technology and
          volunteer-driven platform enables us to streamline the food rescue process, maximizing our ability to deliver
          food to hungry families. Our core values guide every aspect of our work: Community, Passion, Creativity,
          Simplicity, Gratitude.
        </Typography>
        <ol>
          <Typography component="li" gutterBottom variant="body1">
            By offering to volunteer with Food Rescue US , Volunteer acknowledges that she/he is both physically and
            mentally able to engage in picking up food from food donor businesses and driving to deliver this food to
            recipient social service agencies. There will be some lifting involved in the activities of a Food Rescue US
            volunteer food rescuer. You will be required to use our proprietary software to manage your volunteer
            activity with Food Rescue US. The aforementioned are the “Volunteer Activities”. If there is any Volunteer
            Activity in which Volunteer feels that she/he cannot participate, Volunteer should immediately inform your
            Food Rescue US Site Director.
          </Typography>

          <Typography component="li" gutterBottom variant="body1">
            Volunteer understands and agrees that there may be some risks in performing Volunteer Activities and she/he
            knowingly and voluntarily assumes all risks associated with the Volunteer Activities she/he performs,
            including the risk of personal injury, illness, disability, property damage or loss, or death. To the extent
            permitted under law, Volunteer agrees to release, discharge and hold harmless Food Rescue US, its
            directors, officers, successors and assigns from any and all liability, claims or demands which may arise from
            the Volunteer Activities she/he performs.
          </Typography>
          <Typography component="li" gutterBottom variant="body1">
            Please note that Food Rescue US does not maintain health, medical or disability insurance coverage for its
            Volunteers. Also, since Volunteer is not a Food Rescue US employee, Volunteer is not covered by any workers’
            compensation insurance coverage for any injuries she/he may sustain while engaging in volunteer activities
            and travel to and from such activities.
          </Typography>
          <Typography component="li" gutterBottom variant="body1">
            Volunteer hereby acknowledges and agrees that if he/she chooses to utilize a personal vehicle for
            transportation to or from or in connection with the Volunteer Activities, Volunteer has and will maintain a
            valid driver’s license and the vehicle is adequately insured and Volunteer understands that in the event of
            an automobile accident, Volunteer’s personal automobile insurance will respond as primary insurance
            coverage. Volunteer recognizes, accepts and agrees that Food Rescue US will not provide coverage for or
            respond to any claim for damages whatsoever related to use of Volunteer’s vehicle, whether insured or not
            insured, whether for personal injury or property damage, including deductible.
          </Typography>
          <Typography component="li" gutterBottom variant="body1">
            Volunteer agrees that she/he will perform Volunteer activities in a careful and prudent manner, and will
            avoid any careless and reckless behavior.
          </Typography>
          <Typography component="li" gutterBottom variant="body1">
            During the course of Volunteering with Food Rescue US, Volunteer may come in contact with confidential
            information about Food Rescue US, its staff, its donors, its other volunteers, and its clients. Both during
            and after Volunteer’s service with Food Rescue US, Volunteer agrees to keep confidential all such
            information, including keeping confidential the identity of Food Rescue US clients.
          </Typography>

          <Typography component="li" gutterBottom variant="body1">
            By volunteering, Volunteer grants Food Rescue US the unrestricted right to own, use and sell all photographic
            images and video or audio recordings made by or on behalf of Food Rescue US during his/her volunteer
            activities; and Volunteer agrees that Food Rescue US may use his/her name in connection with any activities
            associated with the operations of Food Rescue.
          </Typography>
          <Typography component="li" gutterBottom variant="body1">
            Code of Conduct: Volunteer agrees that he/she will conduct himself/herself at all times in accordance with
            the highest ethical standards, avoid any conflicts of interest, will treat all people encountered in
            volunteer activities with dignity and respect and will avoid any discrimination or harassment based on age,
            race, national origin, gender, sexual orientation, disability or any other characteristic protected by
            applicable law.
          </Typography>
        </ol>
      </Paper>
    </>
  );
};

export default TermsOfService;
