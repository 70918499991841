import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSitesIfNeeded } from '../../../../actions/sites';
import { fetchInsightsIfNeeded } from '../../../../actions/insights';
import DashboardInsightsCards from '../../../../components/Dashboard/DashboardInsightsCards';
import PagePaper from '../../../../components/Common/PagePaper';
import SitesAndDateFilter from './SitesAndDateFilter';
import { getEarliestSiteDate } from '../../../../helpers/getters';
import { fetchRescueSizesIfNeeded } from '../../../../actions/rescueSizes';

const NSDInsights = () => {
  const dispatch = useDispatch();
  const sites = useSelector(state => state.entities.sites);
  const insights = useSelector(state => state.entities.insights);
  const rescueSizes = useSelector(state => state.entities.rescueSizes.sorted);
  const sitesList = sites.allIds.map(id => sites.byId[id]);
  const [selectedSites, setSelectedSites] = useState([]);
  const selectedSitesWithAllData = selectedSites.map(id => sites.byId[id]);

  useEffect(() => {
    dispatch(fetchSitesIfNeeded());
    dispatch(fetchRescueSizesIfNeeded());
  }, [dispatch]);

  // Show only active rescue sizes on the chart
  const mealsWithOnlyActiveRescueSizes = insights.all?.meals?.rescue_size.filter((size) => rescueSizes.some((s) => s.name === size.name));
  const filteredMeals = insights.all.meals
    ? { ...insights.all.meals, rescue_size: mealsWithOnlyActiveRescueSizes }
    : null;

  return (
    <div>
      <PagePaper mt={1}>
        <SitesAndDateFilter
          customDateRangeOptions={{
            minDate: getEarliestSiteDate(selectedSitesWithAllData, sitesList),
          }}
          sites={sitesList}
          disabled={insights.inflight}
          handleChange={state => {
            setSelectedSites(state.sites);
            return dispatch(fetchInsightsIfNeeded(state.dateRange, state.sites))
          }}
        />
      </PagePaper>

      <DashboardInsightsCards
        inflight={insights.inflight}
        meals={filteredMeals}
        rescuedFood={insights.all.rescued_food}
      />
    </div>
  );
};

export default NSDInsights;
