import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, ListItem, ListItemText, Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { fetchSitesIfNeeded } from '../../../actions/sites';
import FilterComponent from './FilterComponent';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  autocomplete: {
    width: '100%',
    padding: '5px 15px',
    // Do not show the input text element but leave the checkboxes
    display: 'none',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
    width: '100%',
  },
  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
  },
  flexColumn: {
    flexDirection: 'column',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const SiteSelectorFilter = ({ sitesWithRescuerRoles, value, handleSiteIdsChange }) => {
  const classes = useStyles();
  const sites = useSelector(state => state.entities.sites);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const selectedValues = value.map(siteId => sites.byId[siteId]);
  const options = sitesWithRescuerRoles.map(siteId => sites.byId[siteId]);

  useEffect(() => {
    if (sitesWithRescuerRoles.length > 1) {
      dispatch(fetchSitesIfNeeded());
    }
  }, []);

  const getLabel = selectedSites => {
    const selectedSitesFilter = sitesWithRescuerRoles.filter(siteId => selectedSites.includes(siteId));
    const sitesSelected = selectedSitesFilter.length;

    if (sitesSelected === 0 || sitesSelected === sitesWithRescuerRoles.length) {
      return 'Sites: all';
    }

    return `Sites: ${sitesSelected} selected`;
  };

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getLabel(value)} />
        {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </ListItem>
      <FilterComponent isOpen={isOpen}>
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Autocomplete
            open
            onClose={() => {}}
            value={selectedValues}
            onChange={(event, newValue) => handleSiteIdsChange(newValue.map(site => site.id))}
            multiple
            classes={{
              root: classes.autocomplete,
              option: classes.option,
              paper: classes.paper,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No labels"
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </>
            )}
            options={options}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField variant="outlined" fullWidth ref={params.InputProps.ref} inputProps={params.inputProps} />
            )}
          />
        </Box>
      </FilterComponent>
    </>
  );
};

export default SiteSelectorFilter;
