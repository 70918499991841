import React, { Component } from 'react';
import { FormControlLabel, Box, Button, Grid } from '@material-ui/core';
import { Field, Form, FormSpy } from 'react-final-form';
import ReactSelect from 'react-select';
import { FORM_ERROR } from 'final-form';
import { Checkbox } from 'final-form-material-ui';
import moment from 'moment';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import snackbarHelper from '../../../helpers/snackbarHelper';
import DateAndGranularityPicker, {
  dateRangeOptionsList,
} from '../../DateAndGranularityPicker/DateAndGranularityPicker';
import ReportLegend, { shouldShowLegend } from '../../../containers/ReportLegend';
import { getEarliestSiteDate } from '../../../helpers/getters';
import { Colors } from '../../../assets/theme/Colors';

class RescueReportForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteId: [],
      period: props.defaultValues.period,
      startDate: props.defaultValues.startDate ? moment(props.defaultValues.startDate).toDate() : null,
      endDate: props.defaultValues.endDate ? moment(props.defaultValues.endDate).toDate() : null,
      granularity: props.defaultValues.granularity,
    };
  }

  handleReportRun = () => {
    const { fetchReport } = this.props;

    try {
      return fetchReport();
    } catch (e) {
      snackbarHelper.error(e.message);

      return {
        [FORM_ERROR]: 'POST failed',
      };
    }
  };

  handleSubmit = values => {
    const { setFilter, defaultValues } = this.props;
    const { startDate, endDate, granularity, period } = this.state;

    const dimension = ['site'];
    if (values.byRescueSize) {
      dimension.push('rescue_size');
    }

    try {
      setFilter({
        dimension: dimension.join(','),
        byRescueSize: values.byRescueSize,
        granularity,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        siteIds: values.siteId || [],
        period: period,
        showLegend: shouldShowLegend(defaultValues.showLegend),
      });
    } catch (e) {
      snackbarHelper.error(e.message);

      return {
        [FORM_ERROR]: 'POST failed',
      };
    }
  };

  render() {
    const { sitesList, isAdmin, defaultValues, setFilter } = this.props;

    return (
      <Box width={1}>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{
            byRescueSize: defaultValues.byRescueSize,
            siteId: defaultValues.siteIds,
          }}
          render={({ submitError, handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ values: true }}
                onChange={props => {
                  if (Object.keys(props.values).length > 0) {
                    form.submit();
                  }
                }}
              />
              <Box display="flex" alignItems="center" gridGap="16px">
                <Box display="flex" alignItems="center" gridGap="5px">
                  <FilterListIcon htmlColor={Colors.inputBorder} />
                  <span>Filters</span>
                </Box>
                {(isAdmin || sitesList.length > 1) && (
                  <Box width="100%">
                    <Field
                      name="siteId"
                      render={({ input }) => (
                        <ReactSelect
                          isMulti
                          name={input.name}
                          placeholder="Select site(s) or leave blank to show data for all sites"
                          isClearable
                          value={input.value}
                          styles={{
                            menu: provided => ({
                              ...provided,
                              zIndex: 101,
                            }),
                            width: '100%',
                          }}
                          options={[{ id: -1, name: 'All' }, ...sitesList]}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => option.name}
                          onChange={option => {
                            let choices = option;
                            if (option && option.some(site => site.id === -1)) {
                              choices = [];
                            }

                            input.onChange(choices || []);

                            this.setState({
                              siteId: choices || [],
                            });
                          }}
                          onBlur={input.onBlur}
                          onFocus={input.onFocus}
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>

              <Box mt={2} mb={0.5}>
                <DateAndGranularityPicker
                  customDateRangeValue={{
                    startDate: defaultValues.startDate,
                    endDate: defaultValues.endDate,
                  }}
                  defaultDateRange={defaultValues.period}
                  defaultGranularity={defaultValues.granularity}
                  dateRangeOptions={{
                    lastWeek: dateRangeOptionsList.lastWeek,
                    last7Days: dateRangeOptionsList.last7Days,
                    last90Days: dateRangeOptionsList.last90Days,
                    thisMTD: dateRangeOptionsList.thisMTD,
                    thisQTD: dateRangeOptionsList.thisQTD,
                    thisYTD: dateRangeOptionsList.thisYTD,
                  }}
                  showCustomDateRange
                  customDateRangeOptions={{
                    maxDate: new Date(),
                    minDate: getEarliestSiteDate(defaultValues.siteIds, sitesList),
                  }}
                  onChange={data => {
                    this.setState(
                      {
                        startDate: data.dateRange ? data.dateRange.startDate : null,
                        endDate: data.dateRange ? data.dateRange.endDate : null,
                        granularity: data.granularity,
                        period: data.period,
                      },
                      () => {
                        this.handleSubmit({
                          ...values,
                          startDate: data.dateRange ? data.dateRange.startDate : null,
                          endDate: data.dateRange ? data.dateRange.endDate : null,
                          granularity: data.granularity,
                          period: data.period,
                        });
                      }
                    );
                  }}
                />
              </Box>

              <Box css={{ marginBottom: -12 }}>
                <FormControlLabel
                  control={<Field name="byRescueSize" type="checkbox" component={Checkbox} />}
                  label="By Rescue Vehicle Size"
                  labelPlacement="end"
                />
              </Box>

              <Box textAlign="right" my={2}>
                <Button variant="contained" color="primary" onClick={this.handleReportRun}>
                  Run
                </Button>
              </Box>

              {submitError && <div className="error">{submitError}</div>}
            </form>
          )}
        />

        <ReportLegend
          isOpen={defaultValues.showLegend}
          onExpandChange={state =>
            setFilter({
              ...defaultValues,
              showLegend: state,
            })
          }
        />
      </Box>
    );
  }
}

export default RescueReportForm;
