import React, { useEffect, useState } from 'react';
import { Box, Typography, GridList, Grid, TablePagination } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { DevTool } from 'react-hook-form-devtools';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

import { VirtuosoGrid } from 'react-virtuoso';
import moment from 'moment';
import GalleryGridItem from './Components/GalleryGridItem';
import PhotoDialog from './Components/Lightbox/PhotoDialog';
import { fetchSitesIfNeeded, fetchSitesPhotos } from '../../../actions/sites';
import useIsAdminInSelectedSite from '../../../hooks/useIsAdminInSelectedSite';
import * as authService from '../../../services/auth';
import { Roles } from '../../../models/roles';
import useActiveUser from '../../../hooks/useActiveUser';
import { fetchUnitsIfNeeded } from '../../../actions/units';
import { fetchFoodDescriptionsIfNeeded } from '../../../actions/foodDescriptions';
import useActiveSite from '../../../hooks/useActiveSite';
import { formatDate } from '../../../helpers/formatters';
import { getRescuePickupLocationFullName } from '../../../helpers/RescuesHelper';
import PagePaper from '../../../components/Common/PagePaper';
import DateAndGranularityPicker, { dateRangeOptionsList } from '../../../components/DateAndGranularityPicker/DateAndGranularityPicker';
import OverlayLoader from '../../../components/OverlayLoader';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  titleBar: {
    padding: spacing(1),
    color: palette.common.white,
    minHeight: 80,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    right: 0,
    bottom: 0,
  },
  root: {
    width: '100%',
    height: '200px !important',
    [breakpoints.up('xs')]: {
      height: 'calc(100vh - 30px) !important',
    },
    [breakpoints.up('md')]: {
      height: 'calc(100vh - 70px) !important',
    },
  },
  withFilters: {
    [breakpoints.up('xs')]: {
      height: 'calc(100vh - 100px) !important',
    },
    [breakpoints.up('md')]: {
      height: 'calc(100vh - 140px) !important',
    },
  },
}));

const useGridListTileStyles = makeStyles(({ breakpoints }) => ({
  root: {
    overflow: 'hidden',
    padding: '0.1rem',
    width: '25%',
    height: '20vw',
    background: '#f5f5f5',
    display: 'flex',
    flex: 'none',
    alignContent: 'stretch',
    [breakpoints.up('xs')]: {
      width: '50%',
      height: '50vw',
    },
    [breakpoints.up('sm')]: {
      width: '33.33%',
      height: '20vw',
    },
    [breakpoints.up('md')]: {
      width: '33.33%',
      height: 'clamp(20vw, 220px, 240px)',
    },
    [breakpoints.up('lg')]: {
      width: '20%',
      height: 'clamp(13vw, 170px, 230px)',
    },
    [breakpoints.up('xl')]: {
      width: '20%',
      height: '12vw',
    },
  },
}));

const ItemContainer = ({ children }) => {
  const styles = useGridListTileStyles();
  return (
    <Box component="li" className={styles.root}>
      {children}
    </Box>
  );
};

const validationResolver = data => {
  const errors = {};

  return {
    values: Object.keys(errors).length ? {} : data,
    errors,
  };
};

const isSiteActive = site => site.disabled_at === null && site.deleted_at === null;

const sortOptions = [
  { id: 0, label: 'Sort by most recent photos', value: [ ['created_at', 'desc'] ]},
  { id: 1, label: 'Sort by oldest photos', value: [ ['created_at', 'asc'] ]},
]

const RescuePhotoGalleryView = ({ isMobileView }) => {
  const classes = useStyles();
  const sitesEntities = useSelector(state => state.entities.sites);
  const photosEntities = useSelector(state => state.entities.sites.photos);
  const [showPhoto, setShowPhoto] = useState();
  const [dateRange, setDateRange] = useState(dateRangeOptionsList.last7Days.range);
  const [page, setPage] = useState(0);
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const sitesWithSDSCAccess = authService.getUserSitesByRoles(activeUser.role_assignments, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);
  const images = Object.values(photosEntities.allIds.map(id => photosEntities.byId[id]));

  const filteredImages = images.filter((image) =>
    !dateRange
      ? images
      : moment(image.rescue?.date.value, image.rescue?.date.format).isBetween(
          moment(dateRange.startDate, image.rescue?.date.format),
          moment(dateRange.endDate, image.rescue?.date.format),
          undefined,
          '[]'
        ) && image.rescue
  );

  const itemsPerPage = 20;

  const indexOfLastImage = (page + 1) * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = filteredImages.slice(indexOfFirstImage, indexOfLastImage);

  const isAdmin = useIsAdminInSelectedSite();
  const sitesList = isAdmin
    ? Object.values(sitesEntities.byId).filter(isSiteActive)
    : Object.values(sitesEntities.byId).filter(site => sitesWithSDSCAccess.includes(site.id) && isSiteActive(site));

  const dispatch = useDispatch();
  const { handleSubmit, getValues, control } = useForm({
    defaultValues: {
      sites: [activeSite],
      order_by: sortOptions[0],
    },
    validationResolver: validationResolver,
  });

  const formValues = getValues();

  const handlePrevPhotoChange = () => {
    const prevPhoto = showPhoto - 1;

    if (prevPhoto >= 0) {
      setShowPhoto(prevPhoto);
    }
  };

  const handleNextPhotoChange = () => {
    const nextPhoto = showPhoto + 1;

    if (nextPhoto < filteredImages.length) {
      setShowPhoto(nextPhoto);
    }
  };

  const fetchSelectedSitesPhotos = (sitesIds, orderBy) => dispatch(fetchSitesPhotos(sitesIds, orderBy));
  useEffect(() => {
    fetchSelectedSitesPhotos(formValues.sites.map(site => site.id), formValues.order_by.value);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUnitsIfNeeded());
    dispatch(fetchFoodDescriptionsIfNeeded());
    dispatch(fetchSitesIfNeeded());
  }, [dispatch]);

  const onSubmit = values => console.log(values);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box pb={isMobileView ? 8 : 0}>
      <PhotoDialog
        disablePrevButton={showPhoto === 0}
        disableNextButton={showPhoto === filteredImages.length - 1}
        fullScreen={isMobileView}
        rescuePhotoData={filteredImages[showPhoto]}
        handlePrevPhoto={handlePrevPhotoChange}
        handleNextPhoto={handleNextPhotoChange}
        open={!isNaN(showPhoto)}
        handleClose={() => setShowPhoto(false)}
      />

      <Box boxShadow={25} p={2} bgcolor="background.paper">
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {sitesList.length > 1 && (
              <Grid item xs={12} sm={4}>
                <Controller
                  name="sites"
                  control={control}
                  as={({ value, onChange }) => (
                    <ReactSelect
                      isDisabled={sitesEntities.inflight || photosEntities.inflight}
                      placeholder="Select site(s) or leave blank to show data for all sites"
                      value={value}
                      isMulti
                      isClearable
                      options={sitesList}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      onChange={option => {
                        const selectedValues = option || [];
                        onChange(selectedValues);
                        setPage(0);
                        fetchSelectedSitesPhotos(
                          selectedValues.map(site => site.id),
                          getValues('order_by') ? getValues('order_by').value : null
                        );
                      }}
                      styles={{
                        menu: provided => ({
                          ...provided,
                          zIndex: 101,
                        }),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={3}>
              <Controller
                name="order_by"
                control={control}
                as={({ value, onChange }) => (
                  <ReactSelect
                    isDisabled={sitesEntities.inflight || photosEntities.inflight}
                    placeholder="Sort photos by:"
                    value={value}
                    options={sortOptions}
                    getOptionValue={option => option.id}
                    onChange={option => {
                      onChange(option);
                      fetchSelectedSitesPhotos(
                        getValues('sites').map(site => site.id),
                        option.value
                      );
                    }}
                    styles={{
                      menu: provided => ({
                        ...provided,
                        zIndex: 101,
                      }),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <DateAndGranularityPicker
                isDisabled={sitesEntities.inflight || photosEntities.inflight}
                showGranularity={false}
                showCustomDateRange
                showAllDateRange
                customDateRangeOptions={{
                  maxDate: moment().toDate(),
                }}
                defaultDateRange={dateRangeOptionsList.last7Days.value}
                onChange={(data) => {
                  setPage(0);
                  setDateRange(data.dateRange);
                }}
              />
            </Grid>
          </Grid>
          {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
        </form>
      </Box>
      <OverlayLoader isLoading={photosEntities.inflight}>
        {!photosEntities.inflight && images.length === 0 && (
          <PagePaper>
            <Typography variant="subtitle1" color="textSecondary">
              Please wait until rescuers upload some photos.
            </Typography>
          </PagePaper>
        )}
        {!photosEntities.inflight && filteredImages.length === 0 && images.length > 0 && (
          <PagePaper>
            <Typography variant="subtitle1" color="textSecondary">
              Select some filter options to view photos!
            </Typography>
          </PagePaper>
        )}
        {filteredImages.length > 0 && (
          <VirtuosoGrid
            className={classNames(classes.root, {
              [classes.withFilters]: sitesList.length > 1,
            })}
            totalCount={currentImages.length}
            ListContainer={GridList}
            ItemContainer={ItemContainer}
            style={{ height: '500px' }}
            item={index => {
              const tile = currentImages[index];
              const filteredImagesIndex = filteredImages.findIndex((image) => image.id === tile.id);
              return (
                <GalleryGridItem handleDialogOpen={() => setShowPhoto(filteredImagesIndex)} tile={tile}>
                  <Box className={classes.titleBar}>
                    <Typography variant="body2">
                      <strong>Date:</strong> {tile.rescue ? formatDate(tile.rescue.date.value) : 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Food Donor:</strong>{' '}
                      {tile.rescue
                        ? getRescuePickupLocationFullName({
                            pickup_location_name: tile.rescue.food_donor.pickup_location.name,
                            location: tile.rescue.food_donor.name,
                          })
                        : 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Receiver:</strong> {tile.rescue ? tile.rescue.receiver.name : 'N/A'}
                    </Typography>
                  </Box>
                </GalleryGridItem>
              );
            }}
          />
        )}
      </OverlayLoader>

      <TablePagination
        component="div"
        count={filteredImages.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={itemsPerPage}
        labelRowsPerPage={null}
        rowsPerPageOptions={[]}
      />
    </Box>
  );
};

export default RescuePhotoGalleryView;
