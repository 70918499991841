import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Badge } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Check as CheckIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Bluebird from 'bluebird';
import OverlayLoader from '../../../OverlayLoader';
import TableActionsButtonsWrapper from '../../../TableActionsButtonsWrapper';
import TableActionButton from '../../../TableActionButton';
import NotificationsTable from './NotificationsTable';
import * as notificationsActions from '../../../../actions/notifications';
import routes from '../../../../routes';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import * as errorsHelper from '../../../../helpers/errors';

const useStyles = makeStyles(({ breakpoints }) => ({
  accordionDetails: {
    [breakpoints.only('xs')]: {
      padding: '8px 8px 24px',
    },
    '& > div': {
      width: '100%',
    },
  },
}));

const NotificationsListDesktop = ({ isLoading, read = [], unread = [] }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [unreadAccordionOpen, setUnreadAccordionOpen] = useState(true);

  const handleChange = () => setUnreadAccordionOpen(!unreadAccordionOpen);

  const goToNotification = notificationId => history.push(generatePath(routes.notification, { notificationId }));

  const markAsRead = notificationId => dispatch(notificationsActions.markNotificationAsRead(notificationId));

  const markAsReadBulk = (rowsDeleted, notifications) =>
    dispatch(
      notificationsActions.markNotificationsAsRead(
        rowsDeleted.data.map(rowDeleted => notifications[rowDeleted.dataIndex].id)
      )
    );

  const deleteNotification = notificationId => {
    return Bluebird
      .try(() => dispatch(notificationsActions.deleteNotification(notificationId)))
      .then(() => snackbarHelper.success('Notification permanently deleted!'))
      .catch(err => errorsHelper.handleError(err, 'Unable to delete notification'));
  };

  const deleteNotifications = (rowsDeleted, notifications, setSelectedRows) => {
    let notificationsToBeDeletedIds = [];

    return Bluebird.try(() =>
      Bluebird.map(rowsDeleted.data, row => {
        const foundNotification = notifications[row.dataIndex];

        if (!foundNotification) {
          return false;
        }

        return notificationsToBeDeletedIds.push(foundNotification.id);
      })
    )
      .then(() => dispatch(notificationsActions.deleteNotifications(notificationsToBeDeletedIds)))
      .then(() => setSelectedRows([]))
      .then(() => snackbarHelper.success('Notifications permanently deleted!'))
      .catch(err => errorsHelper.handleError(err, 'Unable to delete notification'));
  };

  return (
    <OverlayLoader isLoading={isLoading}>
      <Accordion expanded={unreadAccordionOpen} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge color="primary" badgeContent={unread.length} style={{ paddingRight: 0 }}>
            <Typography style={{ marginRight: 10 }}>Unread</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <NotificationsTable
            isLoading={isLoading}
            tableId="#notifications/unread"
            notifications={unread}
            title="Unread"
            onRowClick={(rowData, rowMeta, e) => {
              if (e.target.tagName !== 'path' && e.target.tagName !== 'svg' && e.target.tagName !== 'BUTTON') {
                goToNotification(rowData[0]);
              }
            }}
            additionalColumns={[
              {
                name: 'actions',
                label: 'Actions',
                options: {
                  download: false,
                  filter: false,
                  sort: false,
                  customBodyRender: (meta, row) => (
                    <TableActionsButtonsWrapper>
                      <TableActionButton
                        title="Mark As Read"
                        icon={CheckIcon}
                        onClick={() => markAsRead(row.rowData[0])}
                      />
                    </TableActionsButtonsWrapper>
                  ),
                },
              },
            ]}
            customToolbarSelect={selectedRows => (
              <Box pr={2}>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
                  <Grid item>
                    <TableActionButton
                      title="Mark As Read"
                      icon={CheckIcon}
                      onClick={() => markAsReadBulk(selectedRows, unread)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={!unreadAccordionOpen} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge color="primary" badgeContent={read.length} style={{ paddingRight: 0 }}>
            <Typography style={{ marginRight: 10 }}>Last 30 Days</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <NotificationsTable
            isLoading={isLoading}
            tableId="#notifications/read"
            title="Last 30 Days"
            notifications={read}
            onRowClick={(rowData, rowMeta, e) => {
              if (e.target.tagName !== 'path' && e.target.tagName !== 'svg' && e.target.tagName !== 'BUTTON') {
                goToNotification(rowData[0]);
              }
            }}
            additionalColumns={[
              {
                name: 'actions',
                label: 'Actions',
                options: {
                  download: false,
                  filter: false,
                  sort: false,
                  customBodyRender: (meta, row) => (
                    <TableActionsButtonsWrapper>
                      <TableActionButton
                        title="Remove permanently"
                        icon={DeleteIcon}
                        onClick={() => deleteNotification(row.rowData[0])}
                      />
                    </TableActionsButtonsWrapper>
                  ),
                },
              },
            ]}
            customToolbarSelect={(selectedRows, displayData, setSelectedRows) => (
              <Box pr={2}>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
                  <Grid item>
                    <TableActionButton
                      title="Remove permanently"
                      icon={DeleteIcon}
                      onClick={() => deleteNotifications(selectedRows, read, setSelectedRows)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          />
        </AccordionDetails>
      </Accordion>
    </OverlayLoader>
  );
};

export default NotificationsListDesktop;
