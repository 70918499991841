import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { OnChange } from 'react-final-form-listeners';
import { Checkbox } from 'final-form-material-ui';
import { withStyles, Paper, FormControlLabel, Box, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Bluebird from 'bluebird';
import snackbarHelper from '../helpers/snackbarHelper';
import RoleAssignmentsTable from './RoleAssignmentsTable';
import SelectPrimaryContactUser, {
  isEmailChanged,
  getSelectPrimaryContactUserValidationErrors,
} from './SelectPrimaryContactUser';
import * as usersApi from '../api/users';
import errorMessages from '../assets/errorMessages';
import * as usersModel from '../models/users';
import { Roles } from '../models/roles';
import OverlayLoader from './OverlayLoader';
import SubmitWithSendEmailConfirmation from './SubmitWithSendEmailConfirmation';
import { setFieldData, setValue } from '../helpers/finalFormMutators';
import arrayMutators from 'final-form-arrays';
import LocationFields, { getLocationFieldsValidationErrors } from './LocationFields';
import get from 'lodash/get';
import RescuerRoleAssignmentsTable from './RescuerRoleAssignmentsTable';
import { withNotificationService } from '../context/NotificationsContext/NotificationServiceHOC';

export const RESEND_EMAIL_BUTTON_STATE = {
  active: 'active',
  disabled: 'disabled',
  inflight: 'inflight',
};

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.formObj = undefined;
    this.formRef = createRef();

    const { user = {} } = props;
    const isUpdate = !!user.id;

    this.state = {
      loading: false,
      sendInvitationEmail: false,
      resendVerificationEmailButtonState: RESEND_EMAIL_BUTTON_STATE.active,
      roleAssignments: isUpdate ? [...user.role_assignments] : '',
      formInitialValues: isUpdate
        ? {
            primary_contact_first_name: user.firstname,
            primary_contact_last_name: user.lastname,
            primary_contact_email: user.email,
            primary_contact_phone: user.phone,
            primary_contact_zip_code: user.zip_code,
            comm_email: user.comm_email,
            comm_sms: user.comm_sms,
            active: !!user.active,
            email_verified: !!user.email_verified,
            locations: user.locations.length > 0 ? user.locations : [{}],
          }
        : {},
    };
  }

  findRoleAssignment = (roleAssignment, roleAssignments) => {
    if ([Roles.SiteDirector, Roles.SiteCoordinator, Roles.Rescuer].indexOf(roleAssignment.role_name) >= 0) {
      return roleAssignments.find(
        ra => ra.role_name === roleAssignment.role_name && ra.site_id === roleAssignment.site_id
      );
    }

    if ([Roles.DonorAdmin, Roles.DonorStaff].indexOf(roleAssignment.role_name) >= 0) {
      return roleAssignments.find(
        ra =>
          ra.role_name === roleAssignment.role_name &&
          ra.site_id === roleAssignment.site_id &&
          ra.donor_id === roleAssignment.donor_id
      );
    }

    if ([Roles.ReceiverAdmin, Roles.ReceiverStaff].indexOf(roleAssignment.role_name) >= 0) {
      return roleAssignments.find(
        ra =>
          ra.role_name === roleAssignment.role_name &&
          ra.site_id === roleAssignment.site_id &&
          ra.receiver_id === roleAssignment.receiver_id
      );
    }

    if ([Roles.DonorLocationAdmin, Roles.DonorLocationStaff].indexOf(roleAssignment.role_name) >= 0) {
      return roleAssignments.find(
        ra =>
          ra.role_name === roleAssignment.role_name &&
          ra.site_id === roleAssignment.site_id &&
          ra.donor_id === roleAssignment.donor_id &&
          ra.donor_location_id === roleAssignment.donor_location_id
      );
    }

    if ([Roles.ReceiverLocationAdmin, Roles.ReceiverLocationStaff].indexOf(roleAssignment.role_name) >= 0) {
      return roleAssignments.find(
        ra =>
          ra.role_name === roleAssignment.role_name &&
          ra.site_id === roleAssignment.site_id &&
          ra.receiver_id === roleAssignment.receiver_id &&
          ra.receiver_location_id === roleAssignment.receiver_location_id
      );
    }

    return false;
  };

  handleSubmit = values => {
    const {
      user = {},
      redirectPath,
      showIsAdminCheckbox,
      showIsActiveCheckbox,
      showRoleAssignmentsTable,
      showRescuerRoleAssignmentsTable,
      history,
      submitCallback,
      customErrorMessages,
    } = this.props;
    const { roleAssignments, sendInvitationEmail } = this.state;
    const isUpdate = !!user.id;

    return Bluebird.try(() => {
      const userData = {
        firstname: values.primary_contact_first_name,
        lastname: values.primary_contact_last_name,
        phone: values.primary_contact_phone,
        email: values.primary_contact_email,
        password: values.primary_contact_password,
        zip_code: values.primary_contact_zip_code,
        comm_sms: values.comm_sms,
        comm_email: values.comm_email,
        send_invitation: sendInvitationEmail,
        locations: values.locations,
      };
      let dataToSend;

      // not updating real user
      if (isUpdate) {
        dataToSend = userData;
      } else {
        if (!values.primary_contact_id) {
          dataToSend = {
            user: userData,
            user_id: null,
          };
        } else {
          dataToSend = {
            user: null,
            user_id: values.primary_contact_id,
            locations: userData.locations,
          };
        }
      }

      if (showIsAdminCheckbox) {
        dataToSend = {
          ...dataToSend,
          is_admin: values.is_admin,
        };
      }

      if (showIsActiveCheckbox) {
        dataToSend = {
          ...dataToSend,
          active: values.active,
        };
      }

      if (isUpdate && (showRoleAssignmentsTable || showRescuerRoleAssignmentsTable)) {
        const rolesToDetach = [];

        user.role_assignments
          .filter(
            userRoleAssignment =>
              [Roles.Admin, Roles.NationalSiteDirector].indexOf(userRoleAssignment.role_name) < 0 &&
              !this.findRoleAssignment(userRoleAssignment, roleAssignments)
          )
          .map(userRoleAssignment => rolesToDetach.push(userRoleAssignment));

        dataToSend = {
          ...dataToSend,
          roles_to_detach: usersModel.transformRolesToApi(rolesToDetach),
        };
      }

      this.setState({
        loading: true,
      });

      if (isUpdate) {
        return submitCallback(user.id, dataToSend, isEmailChanged(user, values.primary_contact_email));
      }

      return submitCallback(dataToSend);
    })
      .then(() => {
        this.setState({
          loading: false,
        });

        history.push(redirectPath);

        snackbarHelper.success(`User has been ${user ? 'updated' : 'created'} successfully!`);
      })
      .catch(err => {
        let formErrors = {
          [FORM_ERROR]: 'POST failed',
        };

        switch (err.code) {
          case errorMessages.ERR_DUPLICATED_PHONE.code:
            formErrors = {
              ...formErrors,
              primary_contact_phone: errorMessages.ERR_DUPLICATED_PHONE.message,
            };
            break;
          case errorMessages.ERR_DUPLICATED_EMAIL.code:
            formErrors = {
              ...formErrors,
              primary_contact_email: errorMessages.ERR_DUPLICATED_EMAIL.message,
            };
            break;
          default:
            const errorTranslation = get(customErrorMessages, err.code, { message: err.message, type: 'error' });
            snackbarHelper.toast(errorTranslation.message, errorTranslation.type);
        }

        this.setState({
          loading: false,
        });

        return formErrors;
      });
  };

  handleDeleteRoleAssignment = (roleAssignmentToDelete, input) => {
    const { roleAssignments } = this.state;
    const temp = [];

    roleAssignments.map(ra => {
      if (
        [Roles.SiteDirector, Roles.SiteCoordinator, Roles.Rescuer].indexOf(roleAssignmentToDelete.role_name) >= 0 &&
        ra.role_name === roleAssignmentToDelete.role_name &&
        ra.site_id === roleAssignmentToDelete.site_id
      ) {
        return false;
      }

      if (
        [Roles.DonorAdmin, Roles.DonorStaff].indexOf(roleAssignmentToDelete.role_name) >= 0 &&
        ra.role_name === roleAssignmentToDelete.role_name &&
        ra.site_id === roleAssignmentToDelete.site_id &&
        ra.donor_id === roleAssignmentToDelete.donor_id
      ) {
        return false;
      }

      if (
        [Roles.ReceiverAdmin, Roles.ReceiverStaff].indexOf(roleAssignmentToDelete.role_name) >= 0 &&
        ra.role_name === roleAssignmentToDelete.role_name &&
        ra.site_id === roleAssignmentToDelete.site_id &&
        ra.receiver_id === roleAssignmentToDelete.receiver_id
      ) {
        return false;
      }

      if (
        [Roles.DonorLocationAdmin, Roles.DonorLocationStaff].indexOf(roleAssignmentToDelete.role_name) >= 0 &&
        ra.role_name === roleAssignmentToDelete.role_name &&
        ra.site_id === roleAssignmentToDelete.site_id &&
        ra.donor_id === roleAssignmentToDelete.donor_id &&
        ra.donor_location_id === roleAssignmentToDelete.donor_location_id
      ) {
        return false;
      }

      if (
        [Roles.ReceiverLocationAdmin, Roles.ReceiverLocationStaff].indexOf(roleAssignmentToDelete.role_name) >= 0 &&
        ra.role_name === roleAssignmentToDelete.role_name &&
        ra.site_id === roleAssignmentToDelete.site_id &&
        ra.receiver_id === roleAssignmentToDelete.receiver_id &&
        ra.receiver_location_id === roleAssignmentToDelete.receiver_location_id
      ) {
        return false;
      }

      temp.push(ra);
    });

    this.setState({
      roleAssignments: temp,
    });

    input.onChange(temp);
  };

  validate = values => {
    const { user, showZipCodeField, showPasswordFieldsOnEmailChange, customValidators } = this.props;

    let errors = getSelectPrimaryContactUserValidationErrors(
      values,

      // validate passwords fields only when flag is true and email is changed
      showPasswordFieldsOnEmailChange && isEmailChanged(user, values.primary_contact_email),
      customValidators,

      // validate zip code only when zip code field is visible
      !!showZipCodeField
    );

    return {
      ...errors,
      ...getLocationFieldsValidationErrors(values),
    };
  };

  openVerificationNumber = phoneNumber => {
    const { user, updateUser, showPhoneVerificationDialog } = this.props;

    return Bluebird.try(() => updateUser(user.id, { phone: phoneNumber }))
      .then(() => usersApi.sendSmsVerifyRequest(user.id))
      .then(() => showPhoneVerificationDialog())
      .catch(e => {
        if (e.code === errorMessages.ERR_DUPLICATED_PHONE.code) {
          this.formObj.mutators.setFieldData('primary_contact_phone', {
            error: errorMessages.ERR_DUPLICATED_PHONE.message,
          });
        } else {
          snackbarHelper.error(e.message);
        }
      });
  };

  isUpdatePristine = values => {
    const { user } = this.props;
    const { roleAssignments, formInitialValues } = this.state;

    const hasAddressChanged = (initialValues, formValues) => {
      if (
        initialValues.length !== formValues.length &&
        // at least one address row & last row has at least one field filled
        (formValues.length > 0 && Object.keys(formValues[formValues.length - 1]).length > 0)
      ) {
        return true;
      }
      const check = initialValues.filter(
        row =>
          !formValues.some(
            formRow =>
              row.address === formRow.address &&
              row.address2 === formRow.address2 &&
              row.city === formRow.city &&
              row.st === formRow.st &&
              row.zip === formRow.zip &&
              row.is_mailing_address === formRow.is_mailing_address &&
              row.type_id === formRow.type_id
          )
      );
      return check.length !== 0;
    };

    if (
      user.firstname !== values.primary_contact_first_name ||
      user.lastname !== values.primary_contact_last_name ||
      user.email !== values.primary_contact_email ||
      user.phone !== values.primary_contact_phone ||
      user.zip_code !== values.primary_contact_zip_code ||
      !!user.comm_email !== !!values.comm_email ||
      !!user.comm_sms !== !!values.comm_sms ||
      !!user.active !== !!values.active ||
      !!user.email_verified !== !!values.email_verified ||
      user.role_assignments.length !== roleAssignments.length ||
      hasAddressChanged(get(formInitialValues, 'locations', []), get(values, 'locations', []))
    ) {
      return false;
    }

    return true;
  };

  userEmailVerificationRequest = async () => {
    const { match, notificationService, user = {} } = this.props;
    try {
      this.setState({
        resendVerificationEmailButtonState: RESEND_EMAIL_BUTTON_STATE.inflight,
      });
      await usersApi.requestUserVerificationEmail(user.id ? user.id : match.params.id);
      this.setState({
        resendVerificationEmailButtonState: RESEND_EMAIL_BUTTON_STATE.disabled,
      });
      notificationService.addSuccessNotification('A verification email has been sent.');
    } catch (error) {
      notificationService.addErrorNotification(error, error.message);
      console.debug('error during sending verification email', error.message, error);
    }
  };

  userInvitatinRequest = async () => {
    const { match, notificationService, user = {}, sendInvitationEmail, site } = this.props;
    try {
      this.setState({
        resendVerificationEmailButtonState: RESEND_EMAIL_BUTTON_STATE.inflight,
      });
      await sendInvitationEmail(user.id ? user.id : match.params.id, site.id);
      this.setState({
        resendVerificationEmailButtonState: RESEND_EMAIL_BUTTON_STATE.disabled,
      });
      notificationService.addSuccessNotification('An invitation email has been sent.');
    } catch (error) {
      notificationService.addErrorNotification(error, error.message);
      console.debug('error during sending invitation email', error.message, error);
    }
  };

  render() {
    const {
      user = {},
      pauseCallback,
      resumeCallback,
      rescuerLocationTypes,
      showVerifyPhoneNumberButton,
      showCommunicationCheckboxes = true,
      showIsAdminCheckbox,
      isAdminCheckboxLabel = 'Is Site Director',
      ShowPauseRescue,
      showRoleAssignmentsTable,
      showRescuerRoleAssignmentsTable,
      submitButtonText,
      showIsActiveCheckbox = false,
      showZipCodeField = true,
      showUserSelector,
      showPasswordFieldsOnEmailChange = false,
      showVerifyEmailButton = false,
      showInviteEmailButton = false,
      onUserFormChange,
      isMobileView,
      classes,
      formCustomProps,
      customValidators,
      showLocationFields = true,
    } = this.props;
    const { loading, roleAssignments, formInitialValues, resendVerificationEmailButtonState } = this.state;
    const isUpdate = !!user.id;
    const submitButtonTextToBeDisplayed = submitButtonText
      ? submitButtonText
      : `${isUpdate ? 'Update' : 'Create'} User`;

    const hasRescuerRole =
      roleAssignments.length > 0 && roleAssignments.filter((role) => role.role_name === Roles.Rescuer).length > 0;

    return (
      <OverlayLoader isLoading={loading}>
        <Paper className={classes.paper}>
          <Form
            {...formCustomProps}
            mutators={{
              ...setValue,
              ...setFieldData,
              ...arrayMutators,
            }}
            initialValues={formInitialValues}
            onSubmit={this.handleSubmit}
            validate={this.validate}
            render={({ handleSubmit, dirty, submitting, pristine, invalid, dirtySinceLastSubmit, values, form }) => {
              this.formObj = form;

              const reSendEmailVerificationButtonState = (() => {
                if (resendVerificationEmailButtonState === RESEND_EMAIL_BUTTON_STATE.disabled || dirty || submitting) {
                  return RESEND_EMAIL_BUTTON_STATE.disabled;
                }

                return resendVerificationEmailButtonState;
              })();

              return (
                <form className={classes.form} onSubmit={handleSubmit} ref={this.formRef}>
                  <SelectPrimaryContactUser
                    firstNameLabel="First Name *"
                    lastNameLabel="Last Name *"
                    emailLabel="Email *"
                    zipCodeLabel="Zip Code *"
                    phoneLabel={customValidators.validatePhone ? 'Phone#' : 'Phone#'}
                    user={isUpdate ? user : null}
                    showUserSelector={showUserSelector}
                    showVerifyPhoneNumberButton={showVerifyPhoneNumberButton}
                    handleResendVerificationEmail={this.userEmailVerificationRequest}
                    handleSendInvitation={this.userInvitatinRequest}
                    disableReSendEmailButtonState={reSendEmailVerificationButtonState}
                    showCommunicationCheckboxes={showCommunicationCheckboxes}
                    values={values}
                    formApi={form}
                    openVerificationNumber={this.openVerificationNumber}
                    showZipCodeField={showZipCodeField}
                    showPasswordFieldsOnEmailChange={showPasswordFieldsOnEmailChange}
                    showVerifyEmailButton={showVerifyEmailButton}
                    showInviteEmailButton={showInviteEmailButton}
                    onUserSelect={() => {
                      const elem = document.querySelectorAll(
                        `[name='is_admin'], [name='active'], [name='role_assignments']`
                      );
                      return elem.length > 0 && elem[0].focus();
                    }}
                  />

                  {showLocationFields && (
                    <LocationFields
                      isRescuer={hasRescuerRole}
                      rescuerLocationTypes={rescuerLocationTypes}
                      isMobileView={isMobileView}
                      user={isUpdate ? user : null}
                    />
                  )}

                  {showIsAdminCheckbox && (
                    <Box>
                      <FormControlLabel
                        control={<Field name="is_admin" type="checkbox" color="primary" component={Checkbox} />}
                        label={isAdminCheckboxLabel}
                        labelPlacement="end"
                      />
                      <OnChange name="is_admin">
                        {(value, previous) => onUserFormChange({ is_admin: { value, previous } })}
                      </OnChange>
                    </Box>
                  )}

                  {showIsActiveCheckbox && (
                    <Box>
                      <FormControlLabel
                        control={<Field name="active" type="checkbox" color="primary" component={Checkbox} />}
                        label="Is active?"
                        labelPlacement="end"
                      />
                    </Box>
                  )}

                  {showRoleAssignmentsTable && (
                    <Field
                      name="role_assignments"
                      render={({ input }) => (
                        <RoleAssignmentsTable
                          roleAssignments={roleAssignments}
                          onDeleteRoleAssignmentButtonClick={roleAssignment =>
                            this.handleDeleteRoleAssignment(roleAssignment, input)
                          }
                        />
                      )}
                    />
                  )}

                  {showRescuerRoleAssignmentsTable && (
                    <Field
                      name="role_assignments"
                      render={({ input }) => (
                        <RescuerRoleAssignmentsTable
                          roleAssignments={roleAssignments}
                          onDeleteRoleAssignmentButtonClick={roleAssignment =>
                            this.handleDeleteRoleAssignment(roleAssignment, input)
                          }
                        />
                      )}
                    />
                  )}

                  <SubmitWithSendEmailConfirmation
                    data-testid="submit-user-form-button"
                    label={submitButtonTextToBeDisplayed}
                    showConfirmation={showUserSelector && !values.primary_contact_id}
                    disabled={
                      isUpdate
                        ? this.isUpdatePristine(values)
                        : pristine || (invalid && !dirtySinceLastSubmit) || submitting
                    }
                    className={classes.button}
                    onButtonNotSend={() => {
                      this.setState({ sendInvitationEmail: false }, () => {
                        this.formRef.current.dispatchEvent(
                          new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                          })
                        );
                      });
                    }}
                    onButtonSend={() => {
                      this.setState({ sendInvitationEmail: true }, () => {
                        this.formRef.current.dispatchEvent(
                          new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                          })
                        );
                      });
                    }}
                  />

                  {/* {process.env.NODE_ENV === 'development' && <pre>{JSON.stringify(values, 0, 2)}</pre>} */}
                </form>
              );
            }}
          />
          {ShowPauseRescue && (
            <Accordion elevation={25} className={classes.divider}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Pause or Resume Rescuing
              </AccordionSummary>
              <AccordionDetails className={classes.buttonContainer}>
                {!user.paused_at && (
                  <Button onClick={pauseCallback} className={classes.pauseButton} variant="contained">
                    Pause Rescuing
                  </Button>
                )}

                {user.paused_at && (
                  <Button onClick={resumeCallback} className={classes.resumeButton} variant="contained">
                    Resume Rescuing
                  </Button>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </Paper>
      </OverlayLoader>
    );
  }
}

UserForm.defaultProps = {
  showVerifyEmailButton: false,
  showUserSelector: false,
  isMobileView: false,
  showRoleAssignmentsTable: false,
  showRescuerRoleAssignmentsTable: false,
  onUserFormChange: (value, previous) => {},
  customErrorMessages: {},
  rescuerLocationTypes: {},
  customValidators: {
    validatePhone: true,
  },
};

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    maxWidth: 500,
  },
  button: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(3),
    maxWidth: 500,
  },
  pauseButton: {
    marginTop: theme.spacing(1),
    backgroundColor: '#F44336',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#D32F2F',
    },
  },
  resumeButton: {
    marginTop: theme.spacing(1),
    backgroundColor: '#e9901d',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#c77912',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});

export default withStyles(styles)(withRouter(withNotificationService(UserForm)));
