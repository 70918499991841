import * as umbrellaFoodDonorsActions from '../actions/umbrellaFoodDonors';
import { objectFromArray } from '../helpers';

const initialState = {
  byId: {},
  allIds: [],
  modifiedIds: [],
  modifiedById: {},
  inflight: false,
  lastUpdated: null,
  staff: {
    byId: {},
    allIds: [],
    byUmbrellaFoodDonorId: {},
    inflight: false,
    lastUpdated: null,
  },
};

const umbrellaFoodDonorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case umbrellaFoodDonorsActions.REQUEST_UMBRELLA_FOOD_DONORS_STAFF:
      return {
        ...state,
        staff: {
          ...state.staff,
          inflight: true,
        },
      };
    case umbrellaFoodDonorsActions.RECEIVE_UMBRELLA_FOOD_DONOR_STAFF:
      return {
        ...state,
        staff: {
          byId: {
            ...state.staff.byId,
            ...objectFromArray(action.umbrellaFoodDonorsStaff, umbrellaFoodDonorStaff => umbrellaFoodDonorStaff.id),
          },
          allIds: action.umbrellaFoodDonorsStaff.map(umbrellaFoodDonorStaff => umbrellaFoodDonorStaff.id),
          byUmbrellaFoodDonorId: {
            ...state.staff.byUmbrellaFoodDonorId,
            [action.umbrellaFoodDonorId]: action.umbrellaFoodDonorsStaff,
          },
          inflight: false,
          lastUpdated: null,
        },
      };
    case umbrellaFoodDonorsActions.REQUEST_UMBRELLA_FOOD_DONORS:
      return {
        ...state,
        inflight: true,
      };
    case umbrellaFoodDonorsActions.RECEIVE_UMBRELLA_FOOD_DONORS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.umbrellaFoodDonors, umbrellaFoodDonor => umbrellaFoodDonor.id),
        allIds: action.umbrellaFoodDonors.map(umbrellaFoodDonor => umbrellaFoodDonor.id),
        lastUpdated: action.receivedAt,
      };
    case umbrellaFoodDonorsActions.RECEIVE_UMBRELLA_FOOD_DONOR:
      const { byId, allIds } = addUmbrellaFoodDonor(state.byId, state.allIds, action.umbrellaFoodDonor);

      return {
        ...state,
        inflight: false,
        lastUpdated: action.receivedAt,
        byId,
        allIds,
      };
    default:
      return state;
  }
};

const addUmbrellaFoodDonor = (_byId, _allIds, donor) => {
  const byId = Object.assign({}, _byId, { [donor.id]: donor });
  const allIds = _allIds.concat(donor.id);

  return {
    byId,
    allIds,
  };
};

export default umbrellaFoodDonorsReducer;
