import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Button, MuiThemeProvider } from '@material-ui/core';
import { mainTheme } from '../assets/theme';

const mountPoint = document.createElement('div');

document.body.appendChild(mountPoint);

const styles = theme => ({
  anchorOriginBottomLeft: {
    bottom: '83px !important',
  },
  icon: {
    color: '#ffffff',
  },
  message: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.subtitle1,
    },
  },
});

function NotistackProvider({ classes, variant, duration, msg, toastButton, persist, showCloseButton = true }) {
  const ShowSnackbar = ({ message, variant }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    enqueueSnackbar(message, {
      variant,
      persist: persist,
      preventDuplicate: true,
      action: key => {
        return (
          <>
            {toastButton && (
              <Button
                {...toastButton}
                onClick={() => {
                  if (toastButton.onClick) {
                    toastButton.onClick();
                  }
                  closeSnackbar(key);
                }}
              >
                {toastButton.label}
              </Button>
            )}

            {showCloseButton && (
              <IconButton onClick={() => closeSnackbar(key)}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            )}
          </>
        );
      },
    });

    return null;
  };

  const isMobileBottomMenuVisible = document.getElementById('rescuer-mobile-app-bar');

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        message: classes.message,
        anchorOriginBottomLeft: isMobileBottomMenuVisible ? classes.anchorOriginBottomLeft : undefined,
      }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={duration}
    >
      <ShowSnackbar message={msg} variant={variant} />
    </SnackbarProvider>
  );
}

const MySnackbarProvider = withStyles(styles)(NotistackProvider);

export default {
  success(msg, persist = false, toastButton = undefined) {
    this.toast(msg, 'success', 3000, persist, toastButton);
  },

  warning(msg) {
    this.toast(msg, 'warning');
  },

  info(msg, duration = 5000, showCloseButton = true) {
    this.toast(msg, 'info', duration, false, undefined, showCloseButton);
  },

  error(msg, duration = 5000) {
    this.toast(msg, 'error', duration);
  },

  toast(msg, variant = 'default', duration = 3000, persist = false, toastButton = undefined, showCloseButton = true) {
    ReactDOM.render(
      <MuiThemeProvider theme={mainTheme}>
        <MySnackbarProvider
          toastButton={toastButton}
          showCloseButton={showCloseButton}
          persist={persist}
          msg={msg}
          variant={variant}
          duration={duration}
        />
      </MuiThemeProvider>,
      mountPoint
    );
  },
};
