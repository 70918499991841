import React from 'react';
import moment from 'moment';
import { makeStyles, createMuiTheme, MuiThemeProvider, Tooltip, Button } from '@material-ui/core';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { Colors } from '../assets/theme/Colors';
import { formatDate, formatTime } from '../helpers/formatters';
import { getMuiTableDataIndex } from '../helpers/getters';
import { getRescuePickupLocationFullName, showCloseRescueButtons, showICouldnotMakeIt } from '../helpers/RescuesHelper';

const useStyles = makeStyles(() => ({
  dateCell: {
    width: 125,
  },
  dayCell: {
    width: 125,
  },
  pickupBeginCell: {
    width: 125,
  },
  pickupEndCell: {
    width: 125,
  },
  customActionsButtons: {
    marginRight: 6,
    fontSize: 11,
    padding: 6,
  },
  cancelledRescueRow: {
    '& > td': {
      opacity: 0.25,
    },
  },
}));

const RescuerRecentRescuesTable = ({
  tableId,
  rescues,
  isLoading,
  onCloseRescueButtonClick,
  onCloseNoShowRescueButtonClick,
  onCellClick,
  user,
}) => {
  const classes = useStyles();

  const foodDonorCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return null;
    }

    return getRescuePickupLocationFullName(rescue);
  };

  return (
    <MuiThemeProvider
      theme={theme =>
        createMuiTheme({
          ...theme,
          overrides: {
            ...theme.overrides,
            MUIDataTableHeadCell: {
              root: {
                ...theme.overrides.MUIDataTableHeadCell.root,
                '&:last-child': {
                  width: 255,
                },
              },
            },
          },
        })
      }
    >
      <BaseMUIDataTable
        tableId={tableId}
        isLoading={isLoading}
        data={rescues}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              sort: false,
              display: false,
            },
          },
          {
            name: 'date',
            label: 'Date',
            options: {
              filterOptions: {
                renderValue: formatDate,
              },
              setCellProps: () => ({ className: classes.dateCell }),
              customBodyRenderCSV: formatDate,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                return (
                  <>
                    <span>
                      {moment(value, 'YYYYMMDD').format('MM/DD/YYYY')}
                    </span>

                    {rescue && rescue.cancelled_by_id && (
                      <>
                        <br />

                        <span style={{ color: Colors.errorText, fontWeight: 'bold' }}>
                          Cancelled
                        </span>
                      </>
                    )}
                  </>
                );
              },
            },
          },
          {
            name: 'day',
            label: 'Day',
            options: {
              setCellProps: () => ({ className: classes.dayCell }),
              customBodyRenderCSV: (value, tableMeta) =>
                moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd'),
              customBodyRender: (value, tableMeta) =>
                moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd'),
            },
          },
          {
            name: 'pickup_begin',
            label: 'Begin',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupBeginCell }),
              customBodyRenderCSV: formatTime,
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'pickup_end',
            label: 'End',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupEndCell }),
              customBodyRenderCSV: formatTime,
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'location',
            label: 'Food Donor',
            options: {
              customFilterListOptions: {
                render: value => `Food Donor: ${value}`,
              },
              customBodyRenderCSV: foodDonorCustomBodyRender,
              customBodyRender: foodDonorCustomBodyRender,
            },
          },
          {
            name: 'receiver',
            label: 'Receiving Agency',
            options: {
              customBodyRender: value => value || '',
            },
          },
          {
            name: 'slug',
            label: 'Description',
            options: {
              filter: false,
            },
          },
          {
            name: 'notes',
            label: 'Pickup Notes',
            options: {
              filter: false,
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              Empty: true,
              filter: false,
              searchable: false,
              viewColumns: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue || rescue.closed_by_id !== null) {
                  return false;
                }

                return (
                  <>
                    {showCloseRescueButtons(rescue, user) && (
                      <Tooltip
                        placement="top"
                        key="Yes I did it!"
                        title="Close rescue"
                        className={classes.customActionsButtons}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => onCloseRescueButtonClick(rescue)}
                        >
                          Yes I did it!
                        </Button>
                      </Tooltip>
                    )}

                    {showICouldnotMakeIt(rescue, user) && (
                      <Tooltip
                        placement="top"
                        key="I couldn't make it"
                        title="Close rescue"
                        className={classes.customActionsButtons}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => onCloseNoShowRescueButtonClick(rescue)}
                        >
                          I couldn't make it
                        </Button>
                      </Tooltip>
                    )}
                  </>
                );
              },
            },
          },
        ]}
        options={{
          elevation: 0,
          rowsPerPage: 15,
          selectableRows: 'none',
          responsive: 'simple',
          onCellClick: (colData, cellMeta) => {
            // ignore click on "actions" column cell
            if (cellMeta.colIndex >= 9) {
              return false;
            }

            const rescue = rescues[getMuiTableDataIndex(cellMeta)];

            if (!rescue) {
              return false;
            }

            return onCellClick(rescue);
          },
          setRowProps: (row, dataIndex) => {
            const rescue = rescues[dataIndex];

            if (!rescue || !rescue.cancelled_by_id) {
              return false;
            }

            return {
              className: classes.cancelledRescueRow,
            };
          },
        }}
      />
    </MuiThemeProvider>
  );
};

export default RescuerRecentRescuesTable;
