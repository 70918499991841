import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Paper, Typography } from '@material-ui/core';
import { getCreateQuickSightEmbedUrl } from '../api/quickSight';

const QuicksightReportsView = () => {
  const [report, setReport] = useState('');
  const getQuickSightEmbedUrl = async () => {
    const getCreateQuickSightEmbedUrlResponse = await getCreateQuickSightEmbedUrl();
    const getCreateQuickSightEmbedUrlResponseData = await getCreateQuickSightEmbedUrlResponse.json();

    if (getCreateQuickSightEmbedUrlResponse.ok) {
      setReport(getCreateQuickSightEmbedUrlResponseData.data);
    }
  };

  useEffect(() => {
    getQuickSightEmbedUrl();
  }, []);

  return (
    <Paper>
      <Box py="20px" pl={2}>
        <Typography variant="h6" color="textPrimary">
          Quicksight Reports
        </Typography>
      </Box>

      <iframe src={report} style={{ border: 'none', width: '100%', height: '80vh' }} title="Quicksight Reports" />
    </Paper>
  );
};

export default QuicksightReportsView;
