import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { getUserFullName } from '../../helpers/user';
import { formatPhoneNumber } from '../../helpers/formatters';
import { getChromaColor } from '../../helpers/colors';

export const renderUnassignedUserTooltip = user => (
  <Box display="grid" gridAutoFlow="row" gridRowGap={4}>
    <Box>
      <Typography gutterBottom variant="subtitle1">
        {getUserFullName(user)}
      </Typography>
    </Box>
    <Box>
      <Typography variant="subtitle2">E-mail</Typography>
      <Typography variant="body2">{user.email}</Typography>
    </Box>

    {user.phone && (
      <Box>
        <Typography variant="subtitle2">Phone</Typography>
        <Typography gutterBottom variant="body2">
          {formatPhoneNumber(user.phone)}
        </Typography>
      </Box>
    )}

    {user.zip_code && (
      <Box>
        <Typography variant="subtitle2">Zip Code</Typography>
        <Typography gutterBottom variant="body2">
          {formatPhoneNumber(user.zip_code)}
        </Typography>
      </Box>
    )}
  </Box>
);

export const renderSiteZipCode = ({ site, zipCode }) => (
  <Box display="grid" gridAutoFlow="row" gridRowGap={4}>
    <Box>
      <Typography variant="subtitle2">Zip Code</Typography>
      <Box
        style={{
          margin: 2,
          padding: '4px 8px',
        }}
      >
        <Typography gutterBottom variant="body2">
          {zipCode.zip_code}
        </Typography>
      </Box>
    </Box>
    <Box>
      <Typography variant="subtitle2">Site</Typography>
      <Box
        style={{
          margin: 2,
          padding: '4px 8px',
          backgroundColor: getChromaColor(site.color).backgroundColor,
          color: getChromaColor(site.color).color,
        }}
      >
        <Typography gutterBottom variant="body2">
          {site.name}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export const renderLocationInfoWindow = ({ text, address, contact }) => {
  return (
    <Box display="grid" gridAutoFlow="row" gridRowGap={4}>
      <Typography gutterBottom={!!address || !!contact} variant="subtitle1">
        {text}
      </Typography>
      <Box>
        {address && (
          <>
            <Typography variant="subtitle2">Address</Typography>
            <Typography gutterBottom variant="body2">
              {address}
            </Typography>
          </>
        )}

        {contact.name && (
          <>
            <Typography variant="subtitle2">Contact person</Typography>
            <Typography gutterBottom variant="body2">
              {contact.name}
            </Typography>
          </>
        )}

        {contact.phone && (
          <>
            <Typography variant="subtitle2">Phone#</Typography>
            <Typography gutterBottom variant="body2">
              {formatPhoneNumber(contact.phone)}
            </Typography>
          </>
        )}

        {contact.email && (
          <>
            <Typography variant="subtitle2">E-mail</Typography>
            <Typography gutterBottom variant="body2">
              {contact.email}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export const renderRescuerInfoWindow = location => {
  return (
    <Box display="grid" gridAutoFlow="row" gridRowGap={4}>
      <Box>
        <Typography gutterBottom variant="subtitle1">
          {getUserFullName(location)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">E-mail</Typography>
        <Typography variant="body2">{location.email}</Typography>
      </Box>

      {location.phone && (
        <Box>
          <Typography variant="subtitle2">Phone</Typography>
          <Typography gutterBottom variant="body2">
            {formatPhoneNumber(location.phone)}
          </Typography>
        </Box>
      )}

      <Box>
        <Typography variant="subtitle2">Sites</Typography>
        <ul style={{ margin: 0, paddingLeft: 0, listStyle: 'none' }}>
          {location.sites.map(site => {
            if (!site) {
              return null;
            }
            return (
              <li
                key={site.id}
                style={{
                  margin: 2,
                  padding: '4px 8px',
                  backgroundColor: getChromaColor(site.color).backgroundColor,
                  color: getChromaColor(site.color).color,
                }}
              >
                <Typography gutterBottom variant="body2">
                  {site.name}
                </Typography>
              </li>
            );
          })}
        </ul>
      </Box>
    </Box>
  );
};
