import React, { useContext, useState } from 'react';
import { Link, generatePath, useHistory } from 'react-router-dom';
import Bluebird from 'bluebird';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import {
  AppBar,
  Menu,
  Fade,
  MenuItem,
  Hidden,
  Toolbar,
  Avatar,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Divider,
  ListItemIcon,
  Badge,
  Link as MuiLink,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import {
  PriorityHigh as PriorityHighIcon,
  Menu as MenuIcon,
  Help as HelpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';
import frusLogo from '../assets/images/frus-logo-oval.png';
import NotificationsContainer from './NotificationsContainer';
import * as authService from '../services/auth';
import routes from '../routes';
import MainDrawer from './MainDrawer';
import * as uiActions from '../actions/ui';
import * as uiPersistedActions from '../actions/uiPersisted';
import { siteDirectorTour } from '../helpers/tours';
import { Colors } from '../assets/theme/Colors';
import { Roles } from '../models/roles';
import {
  getCurrentlyLoggedInOrImpersonatingUser,
  getCurrentlyLoggedInOrImpersonatingUserSitesByRole,
  hasRoleInCurrentlySelectedSite,
} from '../services/auth';
import { getUserFullName } from '../helpers/user';
import * as authActions from '../actions/auth';
import FABContext from '../context/FABContext/FABContext';
import { FF_EVENTS, FF_MARKETING_HUB, FF_RECEIVING_AGENCY_REPORT } from './FeatureFlagWrapper';
import { isInPWAView, isSupportedDevice } from './Add2HS/PWAInstallButton';
import { setFABActions } from '../helpers/fab';
import useActiveSite from '../hooks/useActiveSite';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';

const getCustomAppBarTextColor = (props, theme) => {
  if (props.customBranding?.toolbar_text_color && props.hasCustomBranding) {
    return props.customBranding.toolbar_text_color;
  }
  return theme.palette.getContrastText(props.appBarColor);
};

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 58,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 5,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 8,
      paddingLeft: 8,
    },
  },
  newReleasesIcon: {
    color: (props) => (props.hasCustomBranding ? getCustomAppBarTextColor(props, theme) : 'yellow'),
  },
  appBar: {
    paddingTop: 3,
    paddingBottom: 3,
    background: (props) => props.appBarColor || Colors.appBar.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: (props) => getCustomAppBarTextColor(props, theme),
  },
  menuButtonHidden: {
    display: 'none',
  },
  appBarLoginButton: {
    marginRight: 5,
  },
  appBarButton: {
    marginLeft: 0,
    marginRight: 0,
  },
  username: {
    fontSize: 16,
    marginLeft: 6,
    color: (props) => getCustomAppBarTextColor(props, theme),
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  xsPageTitle: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
  frusLogo: {
    width: 30,
    height: 30,
    marginRight: 3,
    marginLeft: 5,
  },
  brandLogo: {
    height: 30,
    width: 30,
    objectFit: 'cover',
    marginRight: 3,
    marginLeft: 3,
  },
  siteName: {
    '@media (max-width: 649px)': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100vw - 190px)',
    },
  },
  siteNameHyphen: {
    '@media (max-width: 649px)': {
      display: 'none',
    },
  },
  fullName: {
    marginLeft: 12,
    display: 'none',
    '@media (min-width: 650px)': {
      display: 'block',
    },
    color: (props) => getCustomAppBarTextColor(props, theme),
  },
  licenseeName: {
    '@media (max-width: 649px)': {
      marginLeft: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100vw - 230px)',
    },
    marginLeft: 12,
    color: (props) => getCustomAppBarTextColor(props, theme),
  },
  pipe: {
    color: (props) => getCustomAppBarTextColor(props, theme),
    marginLeft: 6,
    marginRight: 6,
    '@media (max-width: 649px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatarAndName: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 5,
    marginLeft: 5,
    borderRadius: 5,
  },
  impersonatingBadge: {
    top: 5,
    right: 5,
  },
  arrowDropDownIcon: {
    color: (props) => getCustomAppBarTextColor(props, theme),
  },
}));

const ButtonAppBar = ({ onLogoutClick, location, hasCustomBranding, customBranding = {} }) => {
  const appBarColor = hasCustomBranding && customBranding?.primary_color ? customBranding.primary_color : Colors.appBar.main;
  const classes = useStyles({ appBarColor, hasCustomBranding, customBranding });
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const site = useActiveSite();
  const context = useContext(FABContext);
  const history = useHistory();
  const hasDisableRequestToDonate = useSelector((state) => state.ui.uiDisabled);
  const isDrawerOpen = useSelector((state) => state.ui.persisted.isDrawerOpen);
  const idTokenPayload = useSelector((state) => state.app.idTokenPayload);
  const loggedUser = useSelector((state) => ({
    ...state.app.loggedInUser,
    // for now support only Google avatars
    picture: idTokenPayload && idTokenPayload.picture && idTokenPayload.picture.includes('googleusercontent')
      ? idTokenPayload.picture
      : null,
  }));
  const impersonatedUser = useSelector((state) => state.app.impersonating);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));
  const hasEventsFF = useHasActiveFeatureFlag(FF_EVENTS);
  const hasReceivingAgencyReportFF = useHasActiveFeatureFlag(FF_RECEIVING_AGENCY_REPORT);
  const hasMarketingHubFF = useHasActiveFeatureFlag(FF_MARKETING_HUB);

  const openDrawer = () => dispatch(uiPersistedActions.openDrawer());
  const closeDrawer = () => dispatch(uiPersistedActions.closeDrawer());
  const setTourStepper = (enabled, payload) => dispatch(uiActions.setTourStepper(enabled, payload));
  const showA2HSTutorial = () => dispatch(uiActions.showA2HSTutorial());
  const endImpersonation = (loggedInUser) => dispatch(authActions.changeUser(loggedInUser.id));

  const closeRightSideMenu = () => {
    setMenuAnchorEl(null);
  };

  const showAdd2HomeScreenTutorial = () => {
    closeRightSideMenu();
    return showA2HSTutorial();
  };

  const openRightSideMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const openHelpMenu = event => {
    setHelpMenuAnchorEl(event.currentTarget);
  };

  const closeHelpMenu = () => {
    setHelpMenuAnchorEl(null);
  };

  // for now there are 3 states:
  // 1 - not logged in: (idTokenPayload null)
  // 2 - logged in, not impersonating: (idTokenPayload !== null, impersonating null)
  // 3 - impersonating: (idTokenPayload !== null, impersonating !== null )
  // we need the name, avatar, and roles for now
  const user = impersonatedUser || loggedUser;
  const isImpersonating = Boolean(impersonatedUser);

  const isCompleteRegistrationProcessPending = authService.isCompleteRegistrationProcessPending();
  const shouldHideMainDrawer = authService.shouldHideMainDrawer(user, isMobileView);
  const termsOfServiceLink = hasRoleInCurrentlySelectedSite(user, Roles.DonorLocationAdmin)
    ? generatePath(routes.termsOfService, { type: 'food-donor' })
    : generatePath(routes.termsOfService, { type: 'rescuer' });

  // Do not display AppBar when in ID mode
  if (location.pathname === routes.idMode) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classNames(classes.appBar, isDrawerOpen && classes.appBarShift)}>
        <Toolbar disableGutters={!isDrawerOpen} className={classes.toolbar} variant="dense">
          {user && !shouldHideMainDrawer && (
            <IconButton
              className={isDrawerOpen ? classes.menuButtonHidden : classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={openDrawer}
            >
              <MenuIcon data-introid="hamburger-menu" />
            </IconButton>
          )}

          <div className={classNames(classes.grow)}>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.xsPageTitle}
              component={Link}
              to={routes.index}
              data-testid="appbar-logo"
              data-introid="page-title"
            >
              <img alt="frus logo" className={classes.frusLogo} src={frusLogo} />

              {hasCustomBranding && customBranding?.logo_url && (
                <>
                  <span className={classes.pipe}>|</span>
                  <img alt="brand logo" className={classes.brandLogo} src={customBranding?.logo_url} />
                </>
              )}

              {isCompleteRegistrationProcessPending ? (
                <div style={{ marginLeft: 12 }}>Food Rescue US</div>
              ) : (
                <>
                  {site?.type !== 'licensee' && <div className={classes.fullName}>Food Rescue US</div>}
                  {(isMobileView &&
                      getCurrentlyLoggedInOrImpersonatingUserSitesByRole(Roles.Rescuer).length > 1 &&
                      getCurrentlyLoggedInOrImpersonatingUser().role_assignments.filter(
                        (r) => r.role_name !== Roles.Rescuer
                      ).length === 0) === false &&
                    user && (
                      <span
                        className={site?.type !== 'licensee' ? classes.siteName : classes.licenseeName}
                        data-testid="active-site-span"
                      >
                        {site?.type !== 'licensee' && <span className={classes.siteNameHyphen}>&nbsp;-&nbsp;</span>}

                        {site && site.name ? site.name : 'Supporter Community'}
                      </span>
                    )}
                </>
              )}
            </Typography>
          </div>

          {user && (
            <>
              <NotificationsContainer hasCustomBranding={hasCustomBranding} customBranding={customBranding} />

              {!isMobileView && (
                <>
                  <Tooltip title="Help">
                    <IconButton color="inherit" aria-label="help" onClick={openHelpMenu}>
                      <HelpIcon className={classes.newReleasesIcon} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="help-menu"
                    keepMounted
                    anchorEl={helpMenuAnchorEl}
                    open={Boolean(helpMenuAnchorEl)}
                    onClose={closeHelpMenu}
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <MenuItem
                      onClick={closeRightSideMenu}
                      component={MuiLink}
                      target="_blank"
                      href="https://support.foodrescue.us/hc/en-us"
                      key="documentation"
                    >
                      {formatMessage({ id: 'app-bar.helpMenu.documentation' })}
                    </MenuItem>
                    <MenuItem
                      onClick={closeRightSideMenu}
                      component={MuiLink}
                      target="_blank"
                      href="https://app.foodrescue.us/contact-us"
                      key="contact-us"
                    >
                      {formatMessage({ id: 'app-bar.helpMenu.contact-us' })}
                    </MenuItem>
                  </Menu>
                </>
              )}

              <IconButton
                onClick={openRightSideMenu}
                className={classes.avatarAndName}
                aria-controls="avatar-menu"
                aria-haspopup="true"
                data-testid="profile-menu-toggle"
                data-introid="profile-menu-toggle"
                disableRipple
              >
                <Badge
                  color="secondary"
                  invisible={!isImpersonating}
                  badgeContent="!"
                  classes={{ anchorOriginTopRightRectangle: classes.impersonatingBadge }}
                >
                  <Avatar
                    aria-label="User avatar"
                    style={
                      hasCustomBranding && customBranding?.primary_color
                        ? {
                          backgroundColor: customBranding.primary_color,
                          backgroundImage: 'linear-gradient(rgb(0 0 0/20%) 0 0)',
                        }
                        : { backgroundColor: '#c7392f' }
                    }
                    src={user.picture}
                  />
                </Badge>

                <Hidden xsDown>
                  <Typography className={classes.username} variant="h6" color="inherit">
                    {getUserFullName(user)}
                  </Typography>
                </Hidden>

                <ArrowDropDownIcon className={classes.arrowDropDownIcon} />
              </IconButton>
              <Menu
                id="avatar-menu"
                keepMounted
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={closeRightSideMenu}
                TransitionComponent={Fade}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MenuItem onClick={closeRightSideMenu} component={Link} to={generatePath(routes.profile)} key="profile">
                  {formatMessage({ id: 'app-bar.menu.profile' })}
                </MenuItem>
                {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
                    Roles.SiteDirector,
                    Roles.SiteCoordinator,
                  ]) &&
                  !isMobileView && (
                    <MenuItem
                      onClick={() => {
                        closeRightSideMenu();
                        if (isDrawerOpen) {
                          closeDrawer();
                          return setTimeout(() => {
                            setTourStepper(true, siteDirectorTour);
                          }, 500); // Allow closing drawer animation to finish before the tour
                        }
                        return setTourStepper(true, siteDirectorTour);
                      }}
                      key="start-tour"
                    >
                      Start tour
                    </MenuItem>
                  )}
                <MenuItem
                  onClick={closeRightSideMenu}
                  component={Link}
                  to={routes.notificationsPreferences}
                  key="preferences"
                >
                  {formatMessage({ id: 'app-bar.menu.preferences' })}
                </MenuItem>
                {hasCustomBranding && (
                  <MenuItem
                    onClick={closeRightSideMenu}
                    component={MuiLink}
                    to={routes.notificationsPreferences}
                    key="about-the-tech"
                    href="https://foodrescue.us/our-app/"
                    target="_blank"
                  >
                    {formatMessage({ id: 'app-bar.menu.about-the-tech' })}
                  </MenuItem>
                )
                }

                {isMobileView && authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
                  Roles.SiteDirector,
                  Roles.SiteCoordinator,
                ]) && (
                  <MenuItem
                    onClick={closeRightSideMenu}
                    component={Link}
                    to={generatePath(routes.contactList, { tab: 'receivers' })}
                    key="contact-list"
                  >
                    {formatMessage({ id: 'app-bar.menu.contact-list' })}
                  </MenuItem>
                )}
                {isMobileView &&
                  authService.hasAnyRoleInCurrentlySelectedSite(loggedUser, [
                    Roles.Admin,
                    Roles.NationalSiteDirector,
                  ]) && (
                    <MenuItem
                      onClick={closeRightSideMenu}
                      component={Link}
                      to={routes.switchUser}
                      key="impersonate"
                    >
                      {formatMessage({ id: 'app-bar.menu.switch-user' })}
                    </MenuItem>
                  )}
                {isMobileView && !isInPWAView && isSupportedDevice &&
                  <MenuItem onClick={showAdd2HomeScreenTutorial} key="show-a2hd-steps">
                    {formatMessage({ id: 'app-bar.menu.add-to-homescreen' })}
                  </MenuItem>
                }
                {isImpersonating && (
                  <MenuItem
                    key="end-impersonate"
                    fontSize="small"
                    onClick={() => Bluebird
                      .try(() => endImpersonation(loggedUser))
                      .then(() => setFABActions(loggedUser, history, context))
                    }
                  >
                    <ListItemIcon style={{ minWidth: 25 }}>
                      <PriorityHighIcon fontSize="small" />
                    </ListItemIcon>
                    {formatMessage({ id: 'app-bar.menu.end-impersonation' })}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={closeRightSideMenu}
                  component={Link}
                  to={routes.contactUs}
                  key="contact-us"
                  data-testid="contactus-link"
                >
                  {formatMessage({ id: 'app-bar.menu.contact-us' })}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    closeRightSideMenu();
                    onLogoutClick();
                  }}
                  key="logout"
                  data-testid="logout-link"
                >
                  {formatMessage({ id: 'app-bar.menu.logout' })}
                </MenuItem>

                <Divider light />

                <MenuItem
                  onClick={closeRightSideMenu}
                  component={Link}
                  to={termsOfServiceLink}
                  key="tos"
                  data-testid="terms-of-service"
                >
                  {formatMessage({ id: 'app-bar.menu.terms-of-service' })}
                </MenuItem>

                <Divider light />

                <MenuItem key="version" fontSize="small" style={{ minHeight: 42, fontSize: '.9rem' }}>
                  <ListItemIcon>
                    <img alt="frus logo" className={classes.frusLogo} src={frusLogo} />
                  </ListItemIcon>

                  {process.env.REACT_APP_VERSION}
                </MenuItem>
              </Menu>
            </>
          )}

          {!user && (
            <>
              <Button
                component={Link}
                className={classes.appBarButton}
                color="inherit"
                to={routes.contactUs}
                data-testid="contact_us-button"
              >
                Contact Us
              </Button>

              {!(location.pathname === routes.login || location.pathname === routes.index) && (
                <Button
                  component={Link}
                  to={routes.login}
                  className={classes.appBarLoginButton}
                  color="inherit"
                  data-testid="login-button"
                >
                  Login
                </Button>
              )}

              <Button
                component={Link}
                to={routes.signup}
                className={classes.appBarButton}
                color="inherit"
                data-testid="register-button"
                variant="outlined"
              >
                Sign Up
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <nav>
        {user && !isCompleteRegistrationProcessPending && !shouldHideMainDrawer && (
          <MainDrawer
            featureFlagsList={{
              events: hasEventsFF,
              receivingAgencyReport: hasReceivingAgencyReportFF,
              marketingHubFF: hasMarketingHubFF,
            }}
            disableRequestToDonate={hasDisableRequestToDonate}
            isMobileView={isMobileView}
            user={user}
            isDrawerOpen={isDrawerOpen}
            closeDrawer={closeDrawer}
          />
        )}
      </nav>
    </div>
  );
};

export default ButtonAppBar;
