export const HIDE_FREQUENCY_CHANGE_DIALOG = 'HIDE_FREQUENCY_CHANGE_DIALOG';
export const RESET_PERSISTED_UI = 'RESET_PERSISTED_UI';
export const DISMISS_EMAIL_VERIFICATION = 'DISMISS_EMAIL_VERIFICATION';
export const SHOW_EMAIL_VERIFICATION = 'SHOW_EMAIL_VERIFICATION';
export const DISMISS_PHONE_VERIFICATION = 'DISMISS_PHONE_VERIFICATION';
export const DISMISS_CLOSE_RESCUE_REMINDER_NOTIFICATION = 'DISMISS_CLOSE_RESCUE_REMINDER_NOTIFICATION';
export const DISMISS_ALLOW_PUSH_NOTIFICATION = 'DISMISS_ALLOW_PUSH_NOTIFICATION';
export const SHOW_PHONE_VERIFICATION = 'SHOW_PHONE_VERIFICATION';
export const SET_DEFAULT_DASHBOARD = 'SET_DEFAULT_DASHBOARD';
export const RESET_DEFAULT_DASHBOARD = 'RESET_DEFAULT_DASHBOARD';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const HIDE_UNNASIGNED_USER_BANNER = 'HIDE_UNNASIGNED_USER_BANNER';
export const HIDE_ANNUAL_APPEAR_BANNER = 'HIDE_ANNUAL_APPEAR_BANNER';

export const openDrawer = () => ({
  type: OPEN_DRAWER,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

export const resetUI = () => ({
  type: RESET_PERSISTED_UI,
});

export const dismissEmailVerificationNotification = () => ({
  type: DISMISS_EMAIL_VERIFICATION,
});

export const showEmailVerificationNotification = () => ({
  type: SHOW_EMAIL_VERIFICATION,
});

export const dismissPhoneVerificationNotification = () => ({
  type: DISMISS_PHONE_VERIFICATION,
});

export const showPhoneVerificationNotification = () => ({
  type: SHOW_PHONE_VERIFICATION,
});

export const dismissCloseRescueReminderNotification = () => ({
  type: DISMISS_CLOSE_RESCUE_REMINDER_NOTIFICATION,
});

export const dismissAllowPushNotification = () => ({
  type: DISMISS_ALLOW_PUSH_NOTIFICATION,
});

export const hideDonationFrequencyChangeConfirmation = () => ({
  type: HIDE_FREQUENCY_CHANGE_DIALOG,
});

export const setDefaultDashboard = payload => ({
  type: SET_DEFAULT_DASHBOARD,
  payload,
});

export const resetDefaultDashboard = () => ({
  type: RESET_DEFAULT_DASHBOARD,
});

export const hideUnnasignedUserBanner = () => ({
  type: HIDE_UNNASIGNED_USER_BANNER,
});

export const hideAnnualAppearBanner = () => ({
  type: HIDE_ANNUAL_APPEAR_BANNER,
});
