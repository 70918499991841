import React from 'react';
import UserSelectorView from './Desktop/UserSelectorViewDesktop';
import UserSelectorViewMobile from './Mobile/UserSelectorViewMobile';
import useLoggedUser from '../../hooks/useLoggedUser';
import { hasAnyRoleInCurrentlySelectedSite } from '../../services/auth';
import { Roles } from '../../models/roles';
import AccessDenied from '../../components/AccessDenied';

const SwitchUserContainer = ({ isMobileView }) => {
  const loggedUser = useLoggedUser();

  if (!hasAnyRoleInCurrentlySelectedSite(loggedUser, [Roles.Admin, Roles.NationalSiteDirector])) {
    return <AccessDenied />;
  }

  if (isMobileView) {
    return <UserSelectorViewMobile />;
  }

  return <UserSelectorView />;
};

export default SwitchUserContainer;
