import Bluebird from 'bluebird';
import * as userRegistrationsApi from '../api/userRegistrations';

export const REQUEST_USER_REGISTRATIONS = 'REQUEST_USER_REGISTRATIONS';
export const RECEIVE_USER_REGISTRATIONS = 'RECEIVE_USER_REGISTRATIONS';
export const REQUEST_USER_REGISTRATION_DELETE = 'REQUEST_USER_REGISTRATION_DELETE';
export const RECEIVE_USER_REGISTRATION_DELETE = 'RECEIVE_USER_REGISTRATION_DELETE';

export const requestUserRegistrations = () => ({
  type: REQUEST_USER_REGISTRATIONS,
});

export const receiveUserRegistrations = data => ({
  type: RECEIVE_USER_REGISTRATIONS,
  userRegistrations: data,
  receivedAt: Date.now(),
});

export const requestUserRegistrationDelete = () => ({
  type: REQUEST_USER_REGISTRATION_DELETE,
});

export const receiveUserRegistrationDelete = userRegistrationId => ({
  type: RECEIVE_USER_REGISTRATION_DELETE,
  userRegistrationId: userRegistrationId,
});

export const deleteUserRegistration = userRegistrationId => dispatch => {
  dispatch(requestUserRegistrationDelete());

  return Bluebird
    .try(() => userRegistrationsApi.deleteUserRegistration(userRegistrationId))
    .then(() => dispatch(receiveUserRegistrationDelete(userRegistrationId)));
};

export const fetchUserRegistrations = () => dispatch => {
  dispatch(requestUserRegistrations());

  return Bluebird
    .try(() => userRegistrationsApi.getUserRegistrations())
    .then(res => res.json())
    .then(res => res.data)
    .then(data => dispatch(receiveUserRegistrations(data)));
};

export const fetchUserRegistrationsIfNeeded = () => dispatch => dispatch(fetchUserRegistrations());

export const resendVerificationEmail = (
  userRegistrationId,
  data,
  accessTokenOverride
) => () => userRegistrationsApi.requestVerificationEmail(userRegistrationId, data, accessTokenOverride);
