import React, { useState } from 'react';
import ReactSelect from 'react-select';
import moment from 'moment';
import { Box, Grid } from '@material-ui/core';
import DateAndGranularityPicker from '../../../../components/DateAndGranularityPicker/DateAndGranularityPicker';

const SitesAndDateFilter = ({
  sites,
  handleChange,
  customDateRangeOptions = {},
  showCustomDateRange = true,
  showDateRangeFilter = true,
  disabled = false,
}) => {
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  return (
    <Box p={1} my={1}>
      <Grid container spacing={2}>
        <Grid container>
          <Grid item xs={12}>
            <ReactSelect
              isDisabled={disabled}
              placeholder="Select site(s) or leave blank to show data for all sites"
              value={selectedSites}
              options={[{ id: -1, name: 'All' }, ...sites]}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              onChange={option => {
                let choices = option !== null ? option : [];

                if (choices.some(site => site.id === -1)) {
                  choices = [];
                }

                setSelectedSites(choices);

                handleChange({
                  sites: choices.map(choice => choice.id),
                  dateRange: selectedDateRange,
                });
              }}
              styles={{ menu: base => ({ ...base, zIndex: 101 }) }}
              isClearable
              isMulti
            />
          </Grid>
        </Grid>

        {showDateRangeFilter && (
          <Grid container>
            <Grid item xs={6}>
              <Box mt={2}>
                <DateAndGranularityPicker
                  customDateRangeOptions={customDateRangeOptions}
                  showGranularity={false}
                  showCustomDateRange={showCustomDateRange}
                  isDisabled={disabled}
                  onChange={({ dateRange, period }) => {
                    const formattedDateRange = dateRange
                      ? {
                          startDate: moment(dateRange.startDate).format('YYYYMMDD'),
                          endDate: moment(dateRange.endDate).format('YYYYMMDD'),
                          period: period,
                        }
                      : null;

                    setSelectedDateRange(formattedDateRange);

                    handleChange({
                      sites: selectedSites.map(selectedSite => selectedSite.id),
                      dateRange: formattedDateRange,
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SitesAndDateFilter;
