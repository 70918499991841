import React, { useState } from 'react';
import { getUserFullName } from '../../../helpers/user';
import { Box, Typography, List, ListItem, ListItemText, Divider, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { formatAddress, formatTime } from '../../../helpers/formatters';
import { get } from 'lodash';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab';
import { Schedule as ScheduleIcon } from '@material-ui/icons';
import Bluebird from 'bluebird';
import { Colors } from '../../../assets/theme/Colors';
import moment from 'moment';
import ReceiverChip from '../../../components/ReceiverChip';
import AdopterSelector from '../../../components/AdopterSelector';
import { createEventShiftRegistration, deleteRescuerEventShiftRegistration } from '../../../api/events';
import useActiveUser from '../../../hooks/useActiveUser';
import errorMessages from '../../../assets/errorMessages';
import useNotificationService from '../../../hooks/useNotificationService';
import { useDispatch } from 'react-redux';
import { fetchSiteEventsList } from '../../../actions/sites';
import useActiveSite from '../../../hooks/useActiveSite';
import OverlayLoader from '../../../components/OverlayLoader';

const useStyles = makeStyles(({ spacing, shadows }) => ({
  agendaHeader: {
    boxShadow: shadows[25],
    padding: spacing(2),
    display: 'flex',
    flexFlow: 'row wrap',
    '& > *:first-child': {
      marginRight: spacing(3),
    },
    '& > *:not(:first-child):not(:last-child)': {
      marginRight: spacing(1),
    },
    alignItems: 'center',
  },
  volunteerRow: {
    display: 'flex',
    flexFlow: 'row wrap',
    '& > *:not(:last-child)': {
      marginRight: spacing(1),
    },
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
  },
  timeline: {
    padding: 0,
  },
  timelineContent: {
    padding: spacing(0, 0, 2, 2),
  },
  timelineDot: {
    marginTop: 0,
  },
}));

const EventTimelineRegistrations = ({ registrations, rescuers, shift, eventId, setIsSubmitting }) => {
  // const classes = useStyles();
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const notificationService = useNotificationService();
  const dispatch = useDispatch();

  const handleRescuerSelect = (rescuerId) => {
    setIsSubmitting(true);
    return Bluebird.try(() =>
      createEventShiftRegistration(eventId, shift.id, {
        user_id: rescuerId,
        created_by_id: activeUser.id,
      })
    )
      .then(() => dispatch(fetchSiteEventsList(activeSite.id)))
      .then(() => notificationService.addSuccessNotification('Rescuer assigned to the shift'))
      .catch((error) => {
        switch (error.code) {
          case errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.code:
            notificationService.addNotification(errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.message, 'info');
            break;
          case errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.code:
            notificationService.addNotification(
              errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.message,
              'info'
            );
            break;
          default:
            notificationService.addErrorNotification(error, error.message);
            break;
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleRemoveRescuer = (rescuerId) => {
    setIsSubmitting(true);
    return Bluebird.try(() => deleteRescuerEventShiftRegistration(rescuerId, eventId, shift.id))
      .then(() => dispatch(fetchSiteEventsList(activeSite.id)))
      .then(() => notificationService.addSuccessNotification('Rescuer removed from the shift'))
      .catch((error) => notificationService.addErrorNotification(null, error.message))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <List>
      {registrations.map(({ id, user }, index, { length }) => (
        <React.Fragment key={id}>
          <ListItem dense alignItems="flex-start">
            <ListItemText
              disableTypography
              primary={
                <ReceiverChip
                  value={getUserFullName(user)}
                  onClick={() => handleRemoveRescuer(user.id, id)}
                />
              }
            />
          </ListItem>
          {length - 1 !== index && <Divider />}
        </React.Fragment>
      ))}
      {registrations.length === 0 && (
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="body2" color="textSecondary">
                No volunteers assigned
              </Typography>
            }
          />
        </ListItem>
      )}
      {(registrations.length < shift?.max_volunteers || shift?.max_volunteers === null) && (
        <>
          <Divider />
          <ListItem>
            <Box width="100%">
              <AdopterSelector
                value=""
                placeholder="Select rescuer"
                noOptionsText="There are no active rescuers in the Site"
                rescuersList={rescuers}
                onChange={({ value }) => handleRescuerSelect(value.id)}
              />
            </Box>
          </ListItem>
        </>
      )}
    </List>
  );
};

const EventAgendaView = ({ eventsList, inflight, rescuers }) => {
  const classes = useStyles();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const isLoading = inflight;
  const [index, setIndex] = useState(0);

  if (!isLoading && !eventsList.length) {
    return (
      <Box className={classes.agendaHeader}>
        <Typography variant="subtitle1">No upcoming events</Typography>
      </Box>
    );
  }

  const eventTime = isLoading ? (
    <Skeleton width="100px" />
  ) : (
    <strong>{`${formatTime(eventsList[index].begin.value)} - ${formatTime(eventsList[index].end.value)}`}</strong>
  );

  const eventAddress = isLoading ? (
    <Typography variant="subtitle1">
      <Skeleton width="340px" />
    </Typography>
  ) : (
    <>
      <Typography variant="subtitle1">{eventTime}</Typography>
      <Typography variant="subtitle1">
        <strong>
          {eventsList[index].name}, {moment(eventsList[index].date.value).format('M/DD')},
        </strong>
      </Typography>
      <Typography variant="subtitle1">{formatAddress(eventsList[index].location)}</Typography>
    </>
  );

  return (
    <OverlayLoader isLoading={isSubmiting}>
      <Box className={classes.agendaHeader}>
        {eventAddress}
        {eventsList.length > 1 && (
          <Box display="flex" justifyContent="flex-end" flexGrow={1}>
            <Button disabled={index === 0} onClick={() => setIndex(index - 1)}>
              Previous
            </Button>
            <Button disabled={index === eventsList.length - 1} onClick={() => setIndex(index + 1)}>
              Next
            </Button>
          </Box>
        )}
      </Box>
      <Timeline className={classes.timeline}>
        {get(eventsList[index], 'shifts', []).map((shift, shiftIndex, { length }) => {
          const registrations = get(shift, 'registrations', []).filter((r) => r.deleted_at === null);
          return (
            <TimelineItem
              classes={{
                root: classes.timelineItem,
              }}
              key={shift.id}
            >
              <TimelineSeparator>
                <TimelineDot
                  classes={{
                    root: classes.timelineDot,
                  }}
                >
                  <ScheduleIcon />
                </TimelineDot>
                {length - 1 !== shiftIndex && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent classes={{ root: classes.timelineContent }}>
                <Box boxShadow={25}>
                  <Box bgcolor={Colors.secondaryColor} p={1} color="white">
                    <Typography variant="body1">
                      <strong>{`${formatTime(shift.begin.value)} - ${formatTime(shift.end.value)}`}</strong>
                    </Typography>
                  </Box>
                  <Box px={1}>
                    <EventTimelineRegistrations
                      registrations={registrations}
                      rescuers={rescuers}
                      shift={shift}
                      eventId={eventsList[index].id}
                      setIsSubmitting={setIsSubmitting}
                    />
                  </Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </OverlayLoader>
  );
};

export default EventAgendaView;
