import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import {
  FREQUENCY_ONCE,
  FREQUENCY_WEEKLY,
  FREQUENCY_MONTHLY,
  FREQUENCY_EVERY_OTHER_WEEK,
} from '../../../../../models/donationsNew';

const EventFrequencySelector = ({
  frequency,
  disabled,
  onChange,
  disableWeekly = false,
  disableMonthly = false,
  disableEveryOtherWeek = false,
}) => (
  <FormControl data-testid="frequency-selector">
    <FormLabel component="legend">Frequency</FormLabel>
    <RadioGroup name="frequency" value={frequency} row onChange={event => onChange(parseInt(event.target.value, 10))}>
      <FormControlLabel
        data-testid="frequency-once"
        control={<Radio disabled={disabled} checked={frequency === FREQUENCY_ONCE} value={FREQUENCY_ONCE} />}
        label="One time only"
      />
      <FormControlLabel
        data-testid="frequency-weekly"
        control={
          <Radio
            disabled={disabled || disableWeekly}
            checked={frequency === FREQUENCY_WEEKLY}
            value={FREQUENCY_WEEKLY}
          />
        }
        label="Weekly"
      />
      <FormControlLabel
        data-testid="frequency-monthly"
        control={
          <Radio
            disabled={disabled || disableMonthly}
            checked={frequency === FREQUENCY_MONTHLY}
            value={FREQUENCY_MONTHLY}
          />
        }
        label="Monthly"
      />
      <FormControlLabel
        data-testid="frequency-every_other_week"
        control={
          <Radio
            disabled={disabled || disableEveryOtherWeek}
            checked={frequency === FREQUENCY_EVERY_OTHER_WEEK}
            value={FREQUENCY_EVERY_OTHER_WEEK}
          />
        }
        label="Every other week"
      />
    </RadioGroup>
  </FormControl>
);

export default EventFrequencySelector;
