import React, { useEffect, useContext }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, generatePath, useRouteMatch } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../routes';
import * as foodDonorsActions from '../actions/foodDonors';
import OverlayLoader from '../components/OverlayLoader';
import FoodDonorPickupLocationAddForm from '../components/FoodDonorPickupLocationAddForm';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import * as notificationsHelper from '../helpers/notifications';
import usePermission from '../hooks/usePermission';
import ACLService from '../services/acl';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
}));

const FoodDonorPickupLocationAddView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const donorId = match.params.donor_id;
  const locationId = match.params.foodDonorId;
  const foodDonors = useSelector(state => state.entities.foodDonors);
  const foodDonor = foodDonors.byId[locationId];
  const notificationsContext = useContext(NotificationsContext);
  const hasFoodDonorsList = usePermission({
    resource: ACLService.resources.foodDonorsList,
  });

  useEffect(() => {
    dispatch(foodDonorsActions.fetchFoodDonorIfNeeded(locationId));
  }, []);

  if (!foodDonor) {
    return 'Loading...';
  }

  const onCreateFoodDonorPickupLocation = data => Bluebird
    .try(() => dispatch(foodDonorsActions.createFoodDonorPickupLocation(foodDonor.id, data)))
    .then(() => {
      notificationsHelper.addNotification('Food Donor Pickup Location added successfully!', notificationsContext);

      return history.push(generatePath(routes.foodDonor, { foodDonorId: locationId }));
    });

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        {hasFoodDonorsList ? (
          <Link color="inherit" to={routes.foodDonors}>
            Food Donors
          </Link>
        ) : (
          <Typography color="textPrimary">Food Donors</Typography>
        )}

        <Link
          color="inherit"
          to={generatePath(routes.foodDonor, { foodDonorId: locationId })}
        >
          {foodDonor.name}
        </Link>

        <Typography color="textPrimary">
          Add a Food Donor Pickup Location
        </Typography>
      </Breadcrumbs>

      <OverlayLoader isLoading={foodDonors.inflight}>
        <Paper className={classes.paper}>
          <FoodDonorPickupLocationAddForm createFoodDonorPickupLocation={onCreateFoodDonorPickupLocation} />
        </Paper>
      </OverlayLoader>
    </>
  );
};

export default FoodDonorPickupLocationAddView;
