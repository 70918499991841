import Bluebird from 'bluebird';
import * as metricsApi from '../api/metrics';
import * as insightsApi from '../api/insights';
import * as sitesApi from '../api/sites';
import * as foodDonorsApi from '../api/foodDonors';
import * as receiversApi from '../api/receivers';
import * as rescuesApi from '../api/rescues';
import * as donationsApi from '../api/donations';
import * as sitesModel from '../models/sites';
import * as donorsModel from '../models/donors';
import * as usersActions from '../actions/users';
import * as rescuesActions from './rescues';
import { getRescue } from '../api/rescues';
import { shouldFetch } from '../helpers/cache';
import { mapFormDonationsPATCH } from '../models/donations';

export const REQUEST_SITES = 'REQUEST_SITES';
export const RECEIVE_SITES = 'RECEIVE_SITES';
export const RECEIVE_ONE_SITE = 'RECEIVE_ONE_SITE';
export const RECEIVE_ONE_SITE_DONOR = 'RECEIVE_ONE_SITE_DONOR';
export const REQUEST_SITE_METRICS = 'REQUEST_SITE_METRICS';
export const INVALIDATE_SITE_METRICS = 'INVALIDATE_SITE_METRICS';
export const RECEIVE_SITE_METRICS = 'RECEIVE_SITE_METRICS';
export const REQUEST_SITE_INSIGHTS = 'REQUEST_SITE_INSIGHTS';
export const INVALIDATE_SITE_INSIGHTS = 'INVALIDATE_SITE_INSIGHTS';
export const RECEIVE_SITE_INSIGHTS = 'RECEIVE_SITE_INSIGHTS';
export const REQUEST_SITE_RESCUES = 'REQUEST_SITE_RESCUES';
export const RECEIVE_SITE_RESCUE = 'RECEIVE_SITE_RESCUE';
export const RECEIVE_SITE_RESCUES = 'RECEIVE_SITE_RESCUES';
export const MODIFY_SITE_RESCUE = 'MODIFY_SITE_RESCUE';
export const RESET_SITE_RESCUE = 'RESET_SITE_RESCUE';
export const RECEIVE_SITE_RESCUE_POST = 'RECEIVE_SITE_RESCUE_POST';
export const REQUEST_SITE_USERS = 'REQUEST_SITE_USERS';
export const RECEIVE_SITE_USERS = 'RECEIVE_SITE_USERS';
export const REQUEST_SITE_DONORS_LOCATIONS = 'REQUEST_SITE_DONORS_LOCATIONS';
export const RECEIVE_SITE_DONORS_LOCATIONS = 'RECEIVE_SITE_DONORS_LOCATIONS';
export const REQUEST_SITE_RECEIVERS = 'REQUEST_SITE_RECEIVERS';
export const RECEIVE_SITE_RECEIVERS = 'RECEIVE_SITE_RECEIVERS';
export const REQUEST_SITE_RESCUERS = 'REQUEST_SITE_RESCUERS';
export const DELETE_SITE_RESCUER = 'DELETE_SITE_RESCUER';
export const RECEIVE_SITE_RESCUER = 'RECEIVE_SITE_RESCUER';
export const RECEIVE_SITE_RESCUER_DELETE = 'RECEIVE_SITE_RESCUER_DELETE';
export const RECEIVE_SITE_FOOD_DONOR_DELETE = 'RECEIVE_SITE_FOOD_DONOR_DELETE';
export const RECEIVE_SITE_RECEIVER_DELETE = 'RECEIVE_SITE_RECEIVER_DELETE';
export const RECEIVE_SITE_RESCUERS = 'RECEIVE_SITE_RESCUERS';
export const UPDATE_SITE_RESCUER_NOTES = 'UPDATE_SITE_RESCUER_NOTES';
export const REQUEST_SITE_DONATIONS = 'REQUEST_SITE_DONATIONS';
export const RECEIVE_SITE_DONATIONS = 'RECEIVE_SITE_DONATIONS';
export const REQUEST_SITE_PAST_RESCUES = 'REQUEST_SITE_PAST_RESCUES';
export const RECEIVE_SITE_PAST_RESCUES = 'RECEIVE_SITE_PAST_RESCUES';
export const REQUEST_SITE_UNCLAIMED_RESCUES = 'REQUEST_SITE_UNCLAIMED_RESCUES';
export const RECEIVE_SITE_UNCLAIMED_RESCUES = 'RECEIVE_SITE_UNCLAIMED_RESCUES';
export const REQUEST_SITE_UNCLAIMED_RESCUES_FAILED = 'REQUEST_SITE_UNCLAIMED_RESCUES_FAILED';
export const REQUEST_SITE_PICKUP_LOCATIONS = 'REQUEST_SITE_PICKUP_LOCATIONS';
export const RECEIVE_SITE_PICKUP_LOCATIONS = 'RECEIVE_SITE_PICKUP_LOCATIONS';
export const REQUEST_SITE_PICKUP_SPECS = 'REQUEST_SITE_PICKUP_SPECS';
export const RECEIVE_SITE_PICKUP_SPECS = 'RECEIVE_SITE_PICKUP_SPECS';
export const REQUEST_SITE_EVENTS = 'REQUEST_SITE_EVENTS';
export const RECEIVE_SITE_EVENTS = 'RECEIVE_SITE_EVENTS';
export const REQUEST_SITE_EVENTS_LIST = 'REQUEST_SITE_EVENTS_LIST';
export const RECEIVE_SITE_EVENTS_LIST = 'RECEIVE_SITE_EVENTS_LIST';
export const REQUEST_SITE_EVENT = 'REQUEST_SITE_EVENT';
export const RECEIVE_SITE_EVENT = 'RECEIVE_SITE_EVENT';
export const REQUEST_SITE_RESCUER_EVENTS = 'REQUEST_SITE_RESCUER_EVENTS';
export const RECEIVE_SITE_RESCUER_EVENTS = 'RECEIVE_SITE_RESCUER_EVENTS';
export const REQUEST_SITE_EMAILS = 'REQUEST_SITE_EMAILS';
export const RECEIVE_SITE_EMAILS = 'RECEIVE_SITE_EMAILS';
export const REQUEST_SITE_EMAIL = 'REQUEST_SITE_EMAIL';
export const RECEIVE_SITE_EMAIL = 'RECEIVE_SITE_EMAIL';
export const REQUEST_SITE_SMS = 'REQUEST_SITE_SMS';
export const RECEIVE_SITE_SMS = 'RECEIVE_SITE_SMS';
export const SET_SITE_EVENT_REGISTRATION = 'SET_SITE_EVENT_REGISTRATION';
export const SET_SITE_EVENT_ADOPTION = 'SET_SITE_EVENT_ADOPTION';
export const DELETE_SITE_EVENT_REGISTRATION = 'DELETE_SITE_EVENT_REGISTRATION';
export const DELETE_SITE_EVENT_ADOPTION = 'DELETE_SITE_EVENT_ADOPTION';
export const REQUEST_SITES_PHOTOS = 'REQUEST_SITES_PHOTOS';
export const RECEIVE_SITES_PHOTOS = 'RECEIVE_SITES_PHOTOS';
export const REQUEST_SITE_LEADERBOARDS = 'REQUEST_SITE_LEADERBOARDS';
export const RECEIVE_SITE_LEADERBOARDS = 'RECEIVE_SITE_LEADERBOARDS';
export const CANCEL_SITE_EVENT = 'CANCEL_SITE_EVENT';

export const requestSites = () => ({
  type: REQUEST_SITES,
});

export const receiveSites = sites => ({
  type: RECEIVE_SITES,
  sites,
  receivedAt: Date.now(),
});

export const receiveOneSite = site => ({
  type: RECEIVE_ONE_SITE,
  site,
  receivedAt: Date.now(),
});

export const receiveOneSiteDonor = donor => ({
  type: RECEIVE_ONE_SITE_DONOR,
  donor,
  receivedAt: Date.now(),
});

export const requestSiteMetrics = siteId => ({
  type: REQUEST_SITE_METRICS,
  siteId,
});

export const invalidateSiteMetrics = siteId => ({
  type: INVALIDATE_SITE_METRICS,
  siteId,
});

export const receiveSiteMetrics = (siteId, metrics) => ({
  type: RECEIVE_SITE_METRICS,
  metrics: metrics,
  siteId: siteId,
  receivedAt: Date.now(),
});

export const requestSiteInsights = siteId => ({
  type: REQUEST_SITE_INSIGHTS,
  siteId,
});

export const invalidateSiteInsights = siteId => ({
  type: INVALIDATE_SITE_INSIGHTS,
  siteId,
});

export const receiveSiteInsights = (siteId, insights) => ({
  type: RECEIVE_SITE_INSIGHTS,
  insights: insights,
  siteId: siteId,
  receivedAt: Date.now(),
});

export const requestSiteRescues = () => ({
  type: REQUEST_SITE_RESCUES,
});

export const receiveSiteRescues = rescues => ({
  type: RECEIVE_SITE_RESCUES,
  rescues: rescues,
  receivedAt: Date.now(),
});

export const receiveSiteRescue = rescue => ({
  type: RECEIVE_SITE_RESCUE,
  rescue: rescue,
  receivedAt: Date.now(),
});

export const modifySiteRescue = (id, attrs) => ({
  type: MODIFY_SITE_RESCUE,
  id,
  attrs,
});

export const resetSiteRescue = id => ({
  type: RESET_SITE_RESCUE,
  id,
});

export const receiveSiteRescuePost = json => ({
  type: RECEIVE_SITE_RESCUE_POST,
  rescue: json.rescue,
  receivedAt: Date.now(),
});

export const requestSiteUsers = () => ({
  type: REQUEST_SITE_USERS,
});

export const receiveSiteUsers = siteUsers => ({
  type: RECEIVE_SITE_USERS,
  siteUsers,
  receivedAt: Date.now(),
});

export const requestSiteDonorsLocations = () => ({
  type: REQUEST_SITE_DONORS_LOCATIONS,
});

export const receiveSiteDonorsLocations = siteDonorsLocations => ({
  type: RECEIVE_SITE_DONORS_LOCATIONS,
  siteDonorsLocations,
  receivedAt: Date.now(),
});

export const requestSiteReceivers = () => ({
  type: REQUEST_SITE_RECEIVERS,
});

export const receiveSiteReceivers = siteReceivers => ({
  type: RECEIVE_SITE_RECEIVERS,
  siteReceivers,
  receivedAt: Date.now(),
});

export const requestSiteRescuers = () => ({
  type: REQUEST_SITE_RESCUERS,
});

export const receiveSiteRescuer = siteRescuer => ({
  type: RECEIVE_SITE_RESCUER,
  siteRescuer,
  receivedAt: Date.now(),
});

export const deleteSiteRescuer = siteRescuer => ({
  type: DELETE_SITE_RESCUER,
  siteRescuer,
  receivedAt: Date.now(),
});

export const receiveSiteRescuerDelete = (siteId, rescuerId) => ({
  type: RECEIVE_SITE_RESCUER_DELETE,
  siteId: siteId,
  rescuerId: rescuerId,
});

export const receiveSiteFoodDonorDelete = (siteId, foodDonorId) => ({
  type: RECEIVE_SITE_FOOD_DONOR_DELETE,
  siteId,
  foodDonorId,
});

export const receiveSiteReceiverDelete = (siteId, receiverId) => ({
  type: RECEIVE_SITE_RECEIVER_DELETE,
  siteId,
  receiverId,
});

export const receiveSiteRescuers = siteRescuers => ({
  type: RECEIVE_SITE_RESCUERS,
  siteRescuers,
  receivedAt: Date.now(),
});

export const updateSiteRescuerNotes = (siteId, rescuerId, notes) => ({
  type: UPDATE_SITE_RESCUER_NOTES,
  siteId,
  rescuerId,
  notes,
});

export const requestSiteDonations = () => ({
  type: REQUEST_SITE_DONATIONS,
});

export const receiveSiteDonations = siteDonations => ({
  type: RECEIVE_SITE_DONATIONS,
  siteDonations,
});

export const requestSitePastRescues = () => ({
  type: REQUEST_SITE_PAST_RESCUES,
});

export const receiveSitePastRescues = sitePastRescues => ({
  type: RECEIVE_SITE_PAST_RESCUES,
  sitePastRescues,
});

export const requestSiteUnclaimedRescues = () => ({
  type: REQUEST_SITE_UNCLAIMED_RESCUES,
});

export const receiveSiteUnclaimedRescues = siteUnclaimedRescues => ({
  type: RECEIVE_SITE_UNCLAIMED_RESCUES,
  siteUnclaimedRescues,
});

export const fetchSiteUnclaimedRescuesFailed = () => ({
  type: REQUEST_SITE_UNCLAIMED_RESCUES_FAILED,
});

export const requestSiteEvents = () => ({
  type: REQUEST_SITE_EVENTS,
});

export const requestSiteEventsList = () => ({
  type: REQUEST_SITE_EVENTS_LIST,
});

export const receiveSiteEvents = events => ({
  type: RECEIVE_SITE_EVENTS,
  events: events,
  receivedAt: Date.now(),
});

export const receiveSiteEventsList = events => ({
  type: RECEIVE_SITE_EVENTS_LIST,
  events: events,
  receivedAt: Date.now(),
});

export const requestSiteEvent = eventId => ({
  type: REQUEST_SITE_EVENT,
  eventId: eventId,
});

export const receiveSiteEvent = event => ({
  type: RECEIVE_SITE_EVENT,
  event: event,
  receivedAt: Date.now(),
});

export const requestSiteRescuerEvents = () => ({
  type: REQUEST_SITE_RESCUER_EVENTS,
});

export const receiveSiteRescuerEvents = events => ({
  type: RECEIVE_SITE_RESCUER_EVENTS,
  events: events,
  receivedAt: Date.now(),
});

export const receiveSiteEventShift = (siteId, eventId, eventShiftId, eventShift) => ({
  type: SET_SITE_EVENT_REGISTRATION,
  siteId,
  eventId,
  eventShiftId,
  eventShift,
});

export const receiveSiteEventAdoption = (siteId, eventId, eventShiftId, eventShift) => ({
  type: SET_SITE_EVENT_ADOPTION,
  siteId,
  eventId,
  eventShiftId,
  eventShift,
});

export const receiveSiteDeleteEventShiftRegistration = (siteId, eventId, eventShiftId, eventShiftRegistrationId) => ({
  type: DELETE_SITE_EVENT_REGISTRATION,
  siteId,
  eventId,
  eventShiftId,
  eventShiftRegistrationId,
});

export const receiveSiteDeleteEventShiftAdoption = (siteId, eventId, eventShiftId, eventShiftRegistrationId) => ({
  type: DELETE_SITE_EVENT_ADOPTION,
  siteId,
  eventId,
  eventShiftId,
  eventShiftRegistrationId,
});

export const receiveCancelSiteEvent = (event) => ({
  type: CANCEL_SITE_EVENT,
  event,
  receivedAt: Date.now(),
});

export const requestSiteEmails = () => ({
  type: REQUEST_SITE_EMAILS,
});

export const receiveSiteEmails = emails => ({
  type: RECEIVE_SITE_EMAILS,
  emails: emails,
  receivedAt: Date.now(),
});

export const requestSiteEmail = () => ({
  type: REQUEST_SITE_EMAIL,
});

export const receiveSiteEmail = email => ({
  type: RECEIVE_SITE_EMAIL,
  email: email,
  receivedAt: Date.now(),
});

export const requestSiteSms = () => ({
  type: REQUEST_SITE_SMS,
});

export const receiveSiteSms = sms => ({
  type: RECEIVE_SITE_SMS,
  sms: sms,
  receivedAt: Date.now(),
});

export const requestSitesPhotos = () => ({
  type: REQUEST_SITES_PHOTOS,
});

export const receiveSitesPhotos = sitePhotos => ({
  type: RECEIVE_SITES_PHOTOS,
  sitePhotos,
  receivedAt: Date.now(),
});

export const requestSiteLeaderboards = () => ({
  type: REQUEST_SITE_LEADERBOARDS,
});

export const receiveSiteLeaderboards = data => ({
  type: RECEIVE_SITE_LEADERBOARDS,
  data: data,
  receivedAt: Date.now(),
});

export const fetchSites = (disabled) => dispatch => {
  dispatch(requestSites());

  return Bluebird
    .try(() => sitesApi.getSites(undefined, disabled))
    .then(res => res.json())
    .then(json => dispatch(receiveSites(json.data)));
};

export const fetchSite = siteId => dispatch => {
  dispatch(requestSites());

  return Bluebird
    .try(() => sitesApi.getSite(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveOneSite(json.data)));
};

export const createSite = site => dispatch => Bluebird
  .try(() => sitesApi.createSite(sitesModel.mapFormToCreateSitePOST(site)))
  .then(res => res.json())
  .then(json => dispatch(receiveOneSite(sitesModel.mapApiPUTResponseToStore(json.data))));

export const updateSite = (siteId, siteData) => dispatch => Bluebird
  .try(() => sitesApi.updateSite(siteId, sitesModel.mapFormToUpdateSitePUT(siteData)))
  .then(res => res.json())
  .then(json => dispatch(receiveOneSite(sitesModel.mapApiPUTResponseToStore(json.data))));

export const updateSiteFeatureFlags = (siteId, featureFlags) => () => Bluebird
  .try(() => sitesApi.updateSiteFeatureFlags(siteId, featureFlags))
  .then(res => res.json());

export const updateSiteSettings = (siteId, settings) => () => Bluebird
  .try(() => sitesApi.updateSiteSettings(siteId, settings))
  .then(res => res.json());

const shouldFetchSites = (state) => {
  const { sites } = state.entities;

  if (sites.allIds.length === 0) {
    return true;
  }

  if (sites.inflight) {
    return false;
  }

  return shouldFetch(sites.lastUpdated, { minutes: 5 });
};

export const fetchSitesIfNeeded = (disabled) => (dispatch, getState) => {
  if (shouldFetchSites(getState())) {
    return dispatch(fetchSites(disabled));
  }

  return null;
};

export const fetchSiteUsers = siteId => dispatch => {
  dispatch(requestSiteUsers());

  return Bluebird
    .try(() => sitesApi.getSiteUsers(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteUsers(json.data)));
};

export const fetchSiteUsersIfNeeded = siteId => dispatch => dispatch(fetchSiteUsers(siteId));


export const fetchSiteDonorsLocations = siteId => dispatch => {
  dispatch(requestSiteDonorsLocations());

  return Bluebird
    .try(() => sitesApi.getSiteDonorsLocations(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteDonorsLocations(json.data)));
};

export const fetchSiteDonorsLocationsIfNeeded = siteId => dispatch => dispatch(fetchSiteDonorsLocations(siteId));

export const fetchSiteReceivers = (siteId, quietMode = false) => dispatch => {
  if(!quietMode) {
    dispatch(requestSiteReceivers());
  }

  return Bluebird
    .try(() => sitesApi.getSiteReceivers(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteReceivers(json.data)));
};

function shouldFetchSiteReceivers(state, siteId) {
  const receivers = state.entities.sites.receivers.bySiteId[siteId];

  if (!receivers) {
    return true;
  }

  if (receivers.inflight) {
    return false;
  }

  return true; // fetch always, without checking cache time
}

export const fetchSiteReceiversIfNeeded = (siteId, quietMode = false) => (dispatch, getState) => {
  if (shouldFetchSiteReceivers(getState(), siteId)) {
    return dispatch(fetchSiteReceivers(siteId, quietMode));
  }

  return null;
};

export const fetchSiteMetrics = (siteId, priorDateRange, dateRange, quietMode = false) => dispatch => {
  if (!quietMode) {
    dispatch(requestSiteMetrics(siteId));
  }

  return Bluebird.try(() => {
    if (siteId === -1) {
      return metricsApi.getMetrics(priorDateRange, dateRange);
    }
    return sitesApi.getSiteMetrics(siteId, priorDateRange, dateRange);
  })
    .then(res => res.json())
    .then(json => dispatch(receiveSiteMetrics(siteId, json.data)));
};

function shouldFetchSiteMetrics(state, siteId) {
  const metrics = state.entities.sites.metrics.bySiteId[siteId];

  if (!metrics) {
    return true;
  }

  if (metrics.inflight) {
    return false;
  }

  return shouldFetch(metrics.lastUpdated, { hours: 6 });
}

export const fetchSiteMetricsIfNeeded = (siteId, priorDateRange, dateRange, quietMode = false) => (
  dispatch,
  getState
) => {
  if (shouldFetchSiteMetrics(getState(), siteId)) {
    return dispatch(fetchSiteMetrics(siteId, priorDateRange, dateRange, quietMode));
  }

  return null;
};

export const fetchSiteInsights = (siteId, quietMode = false) => dispatch => {
  if (!quietMode) {
    dispatch(requestSiteInsights(siteId));
  }

  return Bluebird
    .try(() => {
      if (siteId === -1) {
        return insightsApi.getInsights();
      }

      return sitesApi.getSiteInsights(siteId);
    })
    .then(res => res.json())
    .then(json => dispatch(receiveSiteInsights(siteId, json.data)));
};

function shouldFetchSiteInsights(state, siteId) {
  const insights = state.entities.sites.insights.bySiteId[siteId];

  if (!insights) {
    return true;
  }

  if (insights.inflight) {
    return false;
  }

  return shouldFetch(insights.lastUpdated, { hours: 6 });
}

export const fetchSiteInsightsIfNeeded = (siteId, quietMode = false) => (dispatch, getState) => {
  if (shouldFetchSiteInsights(getState(), siteId)) {
    return dispatch(fetchSiteInsights(siteId, quietMode));
  }

  return null;
};

export const fetchSiteRescuers = (siteId, quietMode) => dispatch => {
  if (!quietMode) {
    dispatch(requestSiteRescuers());
  }

  return Bluebird
    .try(() => sitesApi.getSiteRescuers(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteRescuers(json.data)));
};

export const fetchSiteRescueIfNeeded = (siteId, rescueId) => dispatch => {
  dispatch(requestSiteRescues());

  return Bluebird
    .try(() => getRescue(rescueId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteRescue(json.data)));
};

export const fetchSiteRescues = (from, to, siteId) => dispatch => {
  dispatch(requestSiteRescues());

  return Bluebird
    .try(() => sitesApi.getSiteRescues(from, to, siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteRescues(json.data)));
};

export const fetchSiteUnclaimedRescues = (from, to, siteId) => dispatch => {
  dispatch(requestSiteUnclaimedRescues());

  return Bluebird
    .try(() => rescuesApi.getRescues(from, to, siteId, true, false))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteUnclaimedRescues(json.data)));
};

export const saveSiteRescue = (id, modifiedRescue = null) => (dispatch, getState) => {
  let attrs = {};

  if (modifiedRescue) {
    attrs = { ...modifiedRescue };
  } else {
    attrs = { ...getState().entities.sites.rescues.modifiedById[id] };
  }

  const diff = rescuesActions.diff(getState().entities.sites.rescues.byId[id], attrs);

  if (Object.values(diff).length === 0) {
    console.info('rescue not saved, no data to save');

    return;
  }

  return Bluebird
    .try(() => rescuesApi.updateRescue(id, diff))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteRescuePost(json)));
};

export const fetchSiteRescuersIfNeeded = (siteId, quietMode) => (dispatch, getState) => {
  const { inflight, lastUpdated } = getState().entities.sites.rescuers;
  const fetch = (!inflight && (!lastUpdated || (lastUpdated + 60000) < Date.now()));

  return fetch ? dispatch(fetchSiteRescuers(siteId, quietMode)) : null;
};

export const fetchSiteDonations = siteId => dispatch => {
  dispatch(requestSiteDonations());

  return Bluebird
    .try(() => sitesApi.getSiteDonations(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteDonations(json.data)));
};

export const fetchSiteDonationsIfNeeded = siteId => dispatch => dispatch(fetchSiteDonations(siteId));

export const updateDonations = (donations, siteId) => (dispatch) => {
  return Bluebird
  .try(() => donationsApi.updateDonations(mapFormDonationsPATCH(donations)))
  .then(() => dispatch(fetchSiteDonations(siteId)));
};

export const fetchSitePastRescues = (siteId, from, to) => dispatch => {
  dispatch(requestSitePastRescues());

  return Bluebird
    .try(() => sitesApi.getSitePastRescues(siteId, from, to))
    .then(res => res.json())
    .then(json => dispatch(receiveSitePastRescues(json.data)));
};

export const fetchSitePastRescuesIfNeeded = (siteId, from, to) => dispatch => dispatch(
  fetchSitePastRescues(siteId, from, to)
);

export const deleteSiteRescuerNew =
  (siteId, rescuerId, data = {}) =>
  (dispatch) =>
    Bluebird.try(() => sitesApi.deleteSiteRescuer(siteId, rescuerId, data)).then(() =>
      dispatch(receiveSiteRescuerDelete(siteId, rescuerId))
    );

export const saveSiteRescuerNotes = (siteId, rescuerId, notes) => dispatch =>
  Bluebird.try(() => sitesApi.updateSiteRescuer(siteId, rescuerId, { rescuer_notes: notes })).then(() =>
    dispatch(updateSiteRescuerNotes(siteId, rescuerId, notes))
  );

export const deleteSiteFoodDonor = (siteId, foodDonorId) => (dispatch) =>
  Bluebird.try(() => foodDonorsApi.deleteFoodDonor(foodDonorId)).then(() =>
    dispatch(receiveSiteFoodDonorDelete(siteId, foodDonorId))
  );

export const deleteSiteReceiver = (siteId, receiverId) => (dispatch) =>
  Bluebird.try(() => receiversApi.deleteReceiver(receiverId)).then(() =>
    dispatch(receiveSiteReceiverDelete(siteId, receiverId))
  );

export const normalizeSite = site => {
  const siteData = { ...site };

  return {
    name: siteData.name,
    timezone: siteData.timezone,
    zip_codes: siteData.zip_codes,
    notes: siteData.notes,
    user_id: siteData.primary_contact_id,
    user: siteData.primary_contact_id ? null : {
      firstname: siteData.primary_contact_first_name,
      lastname: siteData.primary_contact_last_name,
      email: siteData.primary_contact_email,
      phone: siteData.primary_contact_phone,
      send_invitation: siteData.send_invitation,
    },
  };
};

export const receiveSitePickupLocations = sitePickupLocations => ({
  type: RECEIVE_SITE_PICKUP_LOCATIONS,
  sitePickupLocations,
  receivedAt: Date.now(),
});

export const requestSitePickupLocations = () => ({
  type: REQUEST_SITE_PICKUP_LOCATIONS,
});

export const requestSitePickupSpecs = () => ({
  type: REQUEST_SITE_PICKUP_SPECS,
});

export const receiveSitePickupSpecs = (siteId, sitePickupSpecs) => ({
  type: RECEIVE_SITE_PICKUP_SPECS,
  sitePickupSpecs: sitePickupSpecs,
  siteId: siteId,
  receivedAt: Date.now(),
});


export const fetchSitePickupLocations = siteId => dispatch => {
  dispatch(requestSitePickupLocations());

  return Bluebird
    .try(() => sitesApi.getSitePickupLocations(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSitePickupLocations(json.data)));
};

export const fetchSitePickupLocationsIfNeeded = siteId => dispatch => dispatch(fetchSitePickupLocations(siteId));

export const fetchSitesPhotos = (sites, orderBy) => dispatch => {
  dispatch(requestSitesPhotos());

  return Bluebird
    .try(() => sitesApi.getSitesPhotos(sites, orderBy))
    .then(res => res.json())
    .then(json => dispatch(receiveSitesPhotos(json.data)));
};

const shouldFetchSiteEvents = (state, siteId) => {
  const events = state.entities.sites.events;

  if (!events.bySiteId[siteId]) {
    return true;
  }

  if (events.inflight) {
    return false;
  }

  return true; // fetch always, without checking cache time
}

const shouldFetchSiteEventsList = (state, siteId) => {
  const events = state.entities.sites.eventsList;

  if (!events.bySiteId[siteId]) {
    return true;
  }

  if (events.inflight) {
    return false;
  }

  return true; // fetch always, without checking cache time
}

export const fetchSiteEvents = (siteId, quietMode = false) => dispatch => {
  if(!quietMode) {
    dispatch(requestSiteEvents());
  }

  return Bluebird
    .try(() => sitesApi.getSiteEvents(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteEvents(json.data)));
};

export const fetchSiteEventsList = (siteId, quietMode = false) => dispatch => {
  if(!quietMode) {
    dispatch(requestSiteEventsList());
  }

  return Bluebird
    .try(() => sitesApi.getSiteEventsList(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteEventsList(json.data)));
};

export const fetchSiteEventsIfNeeded = (siteId, quietMode = false) => (dispatch, getState) => {
  if (shouldFetchSiteEvents(getState(), siteId)) {
    return dispatch(fetchSiteEvents(siteId, quietMode));
  }

  return null;
};

export const fetchSiteEventsListIfNeeded = (siteId, quietMode = false) => (dispatch, getState) => {
  if (shouldFetchSiteEventsList(getState(), siteId)) {
    return dispatch(fetchSiteEventsList(siteId, quietMode));
  }

  return null;
};

export const fetchSiteEvent = (siteId, eventId, quietMode = false) => dispatch => {
  if(!quietMode) {
    dispatch(requestSiteEvent(eventId));
  }

  return Bluebird
    .try(() => sitesApi.getSiteEvent(siteId, eventId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteEvent(json.data)));
};

export const cancelSiteEvent = (event) => (dispatch) => {
  return Bluebird
    .try(() => sitesApi.cancelSiteEvent(event.site_id, event.id))
    .then(res => res.json())
    .then(json => dispatch(receiveCancelSiteEvent(json.data)))
};

export const fetchSiteEventIfNeeded = (siteId, eventId, quietMode = false) => (dispatch, getState) => {
  return dispatch(fetchSiteEvent(siteId, eventId, quietMode))
};

export const fetchSiteRescuerEventsIfNeeded = (siteId, quietMode) => (dispatch, getState) => {
  const { inflight, lastUpdated } = getState().entities.sites.rescuers;
  const fetch = (!inflight && (!lastUpdated || (lastUpdated + 60000) < Date.now()));

  return fetch ? dispatch(fetchSiteRescuers(siteId, quietMode)) : null;
};

export const fetchSiteSms = siteId => dispatch => {
  dispatch(requestSiteSms());

  return Bluebird
    .try(() => sitesApi.getSiteSms(siteId))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteSms(json.data)));
};

export const fetchSiteSmsIfNeeded = siteId => dispatch => dispatch(fetchSiteSms(siteId));

function shouldFetchSitePickupSpecs(state, siteId) {
  const pickupSpecs = state.entities.sites.pickupSpecs;

  if (!pickupSpecs.bySiteId[siteId]) {
    return true;
  }

  if (pickupSpecs.inflight) {
    return false;
  }

  return true; // fetch always, without checking cache time
}

export const fetchSitePickupSpecs = (siteId, quietMode = false, params) => dispatch => {
  if(!quietMode) {
    dispatch(requestSitePickupSpecs());
  }

  return Bluebird
    .try(() => sitesApi.getSitePickupSpecs(siteId, params))
    .then(res => res.json())
    .then(json => dispatch(receiveSitePickupSpecs(siteId, json.data)));
};

export const fetchSitePickupSpecsIfNeeded = (siteId, quietMode = false, params) => (dispatch, getState) => {
  if (shouldFetchSitePickupSpecs(getState(), siteId)) {
    return dispatch(fetchSitePickupSpecs(siteId, quietMode, params));
  }

  return null;
};

export const fetchSiteLeaderboards = (siteId, dateRange, quietMode = false) => dispatch => {
  if (!quietMode) {
    dispatch(requestSiteLeaderboards());
  }

  return Bluebird
    .try(() => sitesApi.getSiteLeaderboards(siteId, dateRange))
    .then(res => res.json())
    .then(json => dispatch(receiveSiteLeaderboards(json.data)));
};

export const fetchSiteLeaderboardsIfNeeded = (siteId, dateRange, quietMode = false) => (dispatch, getState) => {
  return dispatch(fetchSiteLeaderboards(siteId, dateRange, quietMode));
};
