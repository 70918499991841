import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

function ViewNotSupportedCard() {
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          View not supported
        </Typography>
        <Typography variant="body2" component="p">
          At the moment, we only support Rescuer activities on mobile devices.
          Please increase your window width or use a larger device.
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ViewNotSupportedCard;
