import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    width: '100%',
  },
  month: {
    textTransform: 'uppercase',
  },
  day: {
    fontSize: '1.5rem',
    fontWeight: 300,
  },
}));

const EventDate = ({ date, ...rest }) => {
  const classes = useStyles();
  const isLoading = !date;

  return (
    <Box className={classes.root} {...rest}>
      <Typography className={classes.month}>{isLoading ? <Skeleton /> : date.format('MMM')}</Typography>
      <Typography className={classes.day}>{isLoading ? <Skeleton /> : date.format('DD')}</Typography>
    </Box>
  );
};

export default EventDate;
