import React from 'react';
import { Collapse, List, ListItem, withStyles } from '@material-ui/core';

const ListItemNested = withStyles({
  root: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(({ children, classes }) => (
  <ListItem button disableRipple className={classes.root}>
    {children}
  </ListItem>
));

const FilterComponent = ({ isOpen, children }) => {
  return (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List component="div">
        <ListItemNested>{children}</ListItemNested>
      </List>
    </Collapse>
  );
};

export default FilterComponent;
