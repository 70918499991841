import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import FilterComponent from './FilterComponent';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { fetchRescueSizesIfNeeded } from '../../../actions/rescueSizes';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
  truckButton: {
    borderTop: `1px solid ${palette.divider} !important`,
  },
}));

const RescueSizeButton = withStyles(({ palette }) => ({
  day: {
    padding: 0,
    height: 55,
    minWidth: 50,
    '&.Mui-selected': {
      backgroundColor: `${palette.primary.main} !important`,
      color: '#fff',
    },
    '& .MuiGrid-container': {
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
}))(({ children, classes, ...rest }) => (
  <ToggleButton className={classes.button} {...rest}>
    {children}
  </ToggleButton>
));

export const getLabel = selectedDays => rescueSizesList => {
  const daysSelected = selectedDays.length;
  if (daysSelected === 0 || daysSelected === rescueSizesList.length) {
    return 'Vehicle Size: all';
  }

  return `Vehicle Size: ${selectedDays.length} selected`;
};

const isTruckVanSize = sizeName => sizeName === 'Truck / Van';

const RescueSizeFilter = ({ value, handleSizesChange }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [listValue, setListValue] = useState(value);
  const [lastSelectedRescueSize, setLastSelectedRescueSize] = useState();
  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const rescueSizesList = rescueSizes.sorted;
  const truckSize = rescueSizesList.find(size => isTruckVanSize(size.name));
  useEffect(() => {
    dispatch(fetchRescueSizesIfNeeded());
  }, []);

  useEffect(() => {
    handleSizesChange(listValue);
  }, [listValue]);

  const handleVehicleSizeChange = (event, newValue) => {
    const buttonDataSet = event.currentTarget.closest('.size-button').dataset;
    const rescueSizeId = +buttonDataSet.sizeId;
    const rescueMealsPerRescue = +buttonDataSet.mealsPerRescue;
    const isSelectedTruck = isTruckVanSize(buttonDataSet.sizeName);

    // if user click twice on the same button, clear all values
    if (rescueSizeId === lastSelectedRescueSize || newValue.length === 0) {
      setLastSelectedRescueSize(+buttonDataSet.sizeId);
      return setListValue([]);
    }

    // if user select truck, check only this option
    if (isSelectedTruck && newValue.includes(truckSize.id)) {
      setLastSelectedRescueSize(+buttonDataSet.sizeId);
      return setListValue([rescueSizeId]);
    }

    // check all options till last rescue size
    const newVals = rescueSizesList.reduce((acc, curr) => {
      if (curr.meals_per_rescue <= rescueMealsPerRescue) {
        acc.push(curr.id);
      }
      return acc;
    }, []);

    setLastSelectedRescueSize(+buttonDataSet.sizeId);
    return setListValue(newVals);
  };

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getLabel(listValue)(rescueSizesList)} />
        {isOpen ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <FilterComponent isOpen={isOpen}>
        <Box
          py={2}
          px={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="row"
          flexGrow={1}
          flexBasis={0}
        >
          <ToggleButtonGroup
            size="medium"
            orientation="vertical"
            value={value}
            onChange={handleVehicleSizeChange}
            aria-label="text alignment"
          >
            {rescueSizesList.map((size, index) => {
              const isTruckVanButton = isTruckVanSize(size.name);
              const row = [];
              if (isTruckVanButton) {
                row.push(<br />);
              }
              row.push(
                <RescueSizeButton
                  key={index}
                  value={size.id}
                  className={classNames('size-button', {
                    [classes.truckButton]: isTruckVanButton,
                  })}
                  data-meals-per-rescue={size.meals_per_rescue}
                  data-size-name={size.name}
                  data-size-id={size.id}
                >
                  <Grid container direction="column" justify="center" alignItems="center">
                    <Grid>{size.name}</Grid>
                  </Grid>
                </RescueSizeButton>
              );

              return row;
            })}
          </ToggleButtonGroup>
        </Box>
      </FilterComponent>
    </>
  );
};

export default RescueSizeFilter;
