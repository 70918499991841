import queryString from 'query-string';
import { apiGET } from './api';

export const getMap = (siteIds = []) => {
  let query = '';

  if (siteIds && siteIds.length > 0) {
    query = queryString.stringify({
      sites_ids: siteIds,
    });
  }

  if (query.length) {
    query = `?${query}`;
  }

  return apiGET(`/map${query}`);
};

export const getMapUnassignedUsers = () => apiGET(`/map/unassigned_users`);

export const getMapUnassignedUser = (userId) => apiGET(`/map/unassigned_users/${userId}`);
