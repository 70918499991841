import React, { useState } from 'react';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
import { Link, useHistory } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Box, Grid, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../../routes';
import * as authService from '../../services/auth';
import * as authApi from '../../api/auth';
import { setValue } from '../../helpers/finalFormMutators';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import { useFormStyles } from './authStyles';
import loginLogo from '../../assets/images/login-logo.svg';
import { Colors } from '../../assets/theme/Colors';
import SocialMediaLinkIcons from '../../components/SocialMediaLinkIcons';

const useStyles = makeStyles(({ spacing }) => ({
  password1: {
    marginBottom: 0,
  },
  socialMediaLinks: {
    paddingTop: spacing(2),
  },
}));

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const authStyles = useFormStyles();
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  let formMutator;

  // redirect to index if user is already logged in
  if (authService.getCurrentlyLoggedInUser()) {
    history.push(routes.index);
  }

  const onSubmit = values => {
    const { email } = values;

    setSubmitting(true);

    return Bluebird.try(() => authApi.forgotPassword(email))
      .then(res => res.json())
      .catch(() => true) // ignore any errors to not show information that might interest attackers...
      .finally(() => {
        setSubmitting(false);
        setShowDialog(true);

        formMutator('email', '');
      });
  };

  return (
    <Box className={authStyles.pageBackground}>
      <Container maxWidth="md">
        {showDialog && (
          <ConfirmationDialog
            message="An email with instructions was sent. Please check your inbox."
            buttons={[{ label: 'Got it', onClick: () => history.push(routes.login) }]}
            closeDialog={() => setShowDialog(false)}
          />
        )}

        <Grid container spacing={2} className={authStyles.pageContainer} data-testid="welcome-index-page">
          <Grid item xs className={authStyles.logoWrapper}>
            <img src={loginLogo} alt="logo" className={authStyles.logo} />
          </Grid>
          <Grid item xs className={authStyles.loginFormWrapper}>
            <Typography className={authStyles.headerText} data-testid="signup-header">
              Password recovery
            </Typography>
            <Form
              mutators={{
                ...setValue,
              }}
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, pristine, invalid, dirtySinceLastSubmit, form, values }) => {
                formMutator = form.mutators.setValue;

                return (
                  <form className={authStyles.form} onSubmit={handleSubmit}>
                    <Field
                      className={authStyles.input}
                      margin="normal"
                      name="email"
                      component={TextField}
                      type="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email Address"
                      autoComplete="email"
                    />

                    <ButtonWithLoading
                      className={authStyles.submitButton}
                      fullWidth
                      type="submit"
                      isLoading={submitting || isSubmitting}
                      disabled={pristine || (invalid && !dirtySinceLastSubmit) || values.email.length === 0}
                    >
                      Send Reset Password Email
                    </ButtonWithLoading>
                  </form>
                );
              }}
            />
            <Grid container>
              <Grid item>
                <Link to={routes.login} variant="body2" style={{ color: Colors.white }}>
                  Already have an account? Login
                </Link>
              </Grid>
            </Grid>

            <Grid container direction="row" justify="center" alignItems="center" className={classes.socialMediaLinks}>
              <SocialMediaLinkIcons iconColor="#fff" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ForgotPasswordPage;
