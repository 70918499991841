import React from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from 'react-hook-form-devtools';
import Joi from '@hapi/joi';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { TextFieldWrapper } from '../../../../components/Form/MuiFormWrapper';
import errorMessages from '../../../../assets/errorMessages';

const validationResolver = data => {
  const errors = {};

  const errorList = Joi.object()
    .keys({
      reason: Joi.string()
        .required()
        .min(15)
        .messages({
          'any.required': errorMessages.REQUIRED.message,
          'string.empty': errorMessages.REQUIRED.message,
        }),
    })
    .validate(
      {
        reason: data.reason,
      },
      { abortEarly: false }
    );

  if (errorList.error) {
    errorList.error.details.forEach(error => {
      errors[error.context.key] = { message: error.message };
    });
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors: errors,
  };
};

const DonationRequestDeclineDialog = ({ isOpen, onClose, handleCancel, handleDecline }) => {
  const {
    handleSubmit,
    register,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    validationResolver,
    defaultValues: {
      reason: '',
    },
  });

  const onSubmit = ({ reason }) => handleDecline(reason);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Decline Request to Donate</DialogTitle>
        <DialogContent>
          <DialogContentText>Please let us know the reason for declining.</DialogContentText>
          <DialogContentText>The food donor will see the message for your reason for declining.</DialogContentText>
          <TextFieldWrapper
            inputRef={register}
            name="reason"
            multiline
            rows={3}
            required
            autoFocus
            label="Reason"
            fullWidth
            variant="outlined"
            error={!!errors.reason}
            helperText={errors.reason ? errors.reason.message : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit">
            Decline
          </Button>
        </DialogActions>
        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </form>
    </Dialog>
  );
};

export default DonationRequestDeclineDialog;
