import React, { useRef, useState } from 'react';
import { withStyles, IconButton, Tooltip, Box, TextField } from '@material-ui/core';
import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons';
import { Colors } from '../assets/theme/Colors';

const NotesActionButton = ({ title, icon: Component, onClick, disabled, classes, buttonProps = {} }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [tooltipIsEdited, setTooltipIsEdited] = useState(false);
  const noteRef = useRef();

  const iconProps = {
    fontSize: 'small',
  };

  const handleButtonClick = () => {
    setTooltipIsEdited(!tooltipIsEdited);
    setTooltipIsOpen(true);
  };

  const handleDoneClick = () => {
    if (noteRef.current === undefined) {
      noteRef.current = title;
    }
    onClick(noteRef.current);
    setTooltipIsEdited(false);
    setTooltipIsOpen(false);
  };

  const handleNoteChange = (value) => {
    noteRef.current = value;
  };

  const NotesTooltip = withStyles((theme) => ({
    arrow: {
      '&:before': {
        border: tooltipIsEdited ? '1px solid #E6E8ED' : '',
      },
      color: tooltipIsEdited ? theme.palette.common.white : '',
    },
    tooltip: {
      backgroundColor: tooltipIsEdited ? theme.palette.common.white : '',
      color: tooltipIsEdited ? theme.palette.common.black : '',
      border: tooltipIsEdited ? '1px solid #E6E8ED' : '',
    },
  }))(Tooltip);

  return (
    <NotesTooltip
      title={(
        <Box>
          {!tooltipIsEdited ? (
            title || '-'
          ) : (
            <TextField
              name="note"
              variant="outlined"
              defaultValue={title}
              multiline
              rows={7}
              onChange={({ target }) => handleNoteChange(target.value)}
              inputProps={{
                maxLength: 140,
              }}
            />
          )}
          {tooltipIsEdited && (
            <Box display="flex" justifyContent="end" mt={1}>
              <Tooltip title="Update note">
                <IconButton className={classes.confirmButton} onClick={handleDoneClick}>
                  <DoneIcon {...iconProps} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton
                  className={classes.closeButton}
                  onClick={() => {
                    setTooltipIsEdited(false);
                    setTooltipIsOpen(false);
                  }}
                >
                  <CloseIcon {...iconProps} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
      placement="top"
      interactive={!!tooltipIsEdited}
      open={tooltipIsOpen}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => (tooltipIsEdited ? null : setTooltipIsOpen(false))}
    >
      <span className={classes.span}>
        <IconButton
          className={classes.button}
          color="primary"
          onClick={handleButtonClick}
          disabled={disabled}
          {...buttonProps}
        >
          {Component && (
            <Component {...iconProps} />
          )}
        </IconButton>
      </span>
    </NotesTooltip>
  );
};

const styles = (theme) => ({
  span: ({ disabled }) => {
    const spanStyles = {
      display: 'inline-block',
    };

    if (disabled) {
      spanStyles.cursor = 'not-allowed';
    }

    return spanStyles;
  },
  button: {
    color: Colors.primaryColor,
    margin: theme.spacing(0.5),
  },
  confirmButton: {
    color: Colors.green.main,
  },
  closeButton: {
    color: Colors.errorText,
  },
});

export default withStyles(styles)(NotesActionButton);
