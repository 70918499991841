import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import routes from '../routes';

const SiteCoordinatorFoodDonorRegistrations = ({ tableId, foodDonors, isLoading }) => (
  <MuiThemeProvider
    theme={theme =>
      createMuiTheme({
        ...theme,
        overrides: {
          ...theme.overrides,
          MUIDataTableHeadCell: {
            root: {
              ...theme.overrides.MUIDataTableHeadCell.root,
              '&:last-child': {
                width: 130,
              },
            },
          },
        },
      })
    }
  >
    <BaseMUIDataTable
      isLoading={isLoading}
      tableId={tableId}
      data={foodDonors}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'date',
          label: 'Date',
          options: {
            customBodyRenderLite: index => moment(foodDonors[index].date).format('MM/DD/YYYY h:mm:ss'),
          },
        },
        {
          name: 'name',
          label: 'Food Donor Name',
        },
        {
          name: 'email',
          label: 'E-mail',
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            empty: true,
            filter: false,
            searchable: false,
            viewColumns: false,
            customBodyRender: (value, tableMeta) => {
              const foodDonorRequest = foodDonors[getMuiTableDataIndex(tableMeta)];

              return (
                <TableActionsButtonsWrapper>
                  <TableActionButton
                    buttonProps={{
                      component: Link,
                      to: generatePath(routes.foodDonorRegistrationReview, { foodDonorId: foodDonorRequest.id }),
                    }}
                    icon={VisibilityIcon}
                    title="Review registration"
                  />
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        sortOrder: {
          name: 'date',
          direction: 'asc',
        },
        rowsPerPage: 15,
        selectableRows: 'none',
        responsive: 'simple',
      }}
    />
  </MuiThemeProvider>
);

export default SiteCoordinatorFoodDonorRegistrations;
