import React, { useCallback, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { shouldLogErrors } from '../helpers/errors';
import snackbarHelper from '../helpers/snackbarHelper';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';

export default function useNotificationService() {
  const { addNotification } = useContext(NotificationsContext);

  /* deprecated */
  const handleErrorOld = useCallback((error, message = false) => {
    if (shouldLogErrors() && error) {
      Sentry.captureException(error);
    }

    snackbarHelper.error(message || error.message);
  }, []);

  const handleErrorNew = useCallback(
    (error, message = false) => {
      if (shouldLogErrors() && error) {
        Sentry.captureException(error);
      }
      addNotification(message || error.message, 'error');

      return 1;
    },
    [addNotification]
  );

  const addSuccessNotification = useCallback(message => addNotification(message, 'success'), [addNotification]);
  const addCustomNotification = useCallback((message, type) => addNotification(message, type), [addNotification]);

  return {
    addErrorNotification: handleErrorNew,
    addErrorNotificationOLD: handleErrorOld,
    addSuccessNotification: addSuccessNotification,
    addNotification: addCustomNotification,
  };
}
