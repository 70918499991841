import React from 'react';
import { Box, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OverlayLoader from '../../../../../components/OverlayLoader';

const LocationForm = ({ defaultValue, activeSiteId, pickupLocations, donorLocations, onFoodDonorChange, errors }) => {
  const options =
    donorLocations.inflight || !donorLocations.bySiteId[activeSiteId] ? [] : donorLocations.bySiteId[activeSiteId];

  const optionsWithoutPending = options.filter((donor) => donor.verification_status !== "pending");

  const getPlaceholderText = () => {
    if (donorLocations.inflight) {
      return 'Loading...';
    }

    if (options.length === 0) {
      return 'No Food Donors';
    }

    return 'Select Location';
  };

  return (
    <OverlayLoader wrapperStyles={{ width: '100%' }} isLoading={pickupLocations.inflight}>
      <Grid container spacing={2} data-testid="location-selector">
        <Grid item xs={12}>
          <InputLabel shrink error={!!errors.location_id}>
            Food Donor
          </InputLabel>
          <Autocomplete
            value={defaultValue}
            loading={donorLocations.inflight}
            size="small"
            name="food_donor_id"
            data-testid="food_donor_id-selector"
            placeholder="Select location"
            options={optionsWithoutPending}
            disableClearable
            onChange={(event, value) => {
              return onFoodDonorChange({
                name: value.name,
                id: value.id,
              });
            }}
            getOptionLabel={option => option.name}
            getOptionDisabled={option => !option.primary_contact_id}
            renderOption={option => (
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">{option.name}</Typography>
                {!option.primary_contact_id && (
                  <Typography color="textSecondary" variant="body2">
                    Please add Primary Contact first!
                  </Typography>
                )}
              </Box>
            )}
            renderInput={params => (
              <TextField
                error={!!errors.location_id}
                helperText={errors.location_id ? errors.location_id.message : ''}
                {...params}
                placeholder={getPlaceholderText()}
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </OverlayLoader>
  );
};

export default LocationForm;
