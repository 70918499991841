import { apiGET, apiPATCH, apiPUT } from './api';
import queryString from 'query-string';

export const getRescues = (from, to, site_id, unclaimed, cancelled, umbrellaFoodDonorId = null) => {
  let params = {
    from,
    to,
  };

  if (site_id) {
    if (Array.isArray(site_id)) {
      params = {
        ...params,
        sites_ids: site_id,
      };
    } else {
      params = {
        ...params,
        site_id,
      };
    }
  }

  if (umbrellaFoodDonorId) {
    params.donor_id = umbrellaFoodDonorId;
  }

  if (unclaimed !== undefined) {
    params.unclaimed = unclaimed;
  }

  if (cancelled !== undefined) {
    params.cancelled = cancelled;
  }

  const query = queryString.stringify(params);

  return apiGET(`/rescues?${query}`);
};

export const getRescuesPaginated = (from, page, limit, site_id) => {
  let params = {
    from: from,
    page: page,
    limit: limit,
    cancelled: false,
  };

  if (site_id) {
    if (Array.isArray(site_id)) {
      params = {
        ...params,
        sites_ids: site_id,
      };
    } else {
      params = {
        ...params,
        site_id,
      };
    }
  }
  const query = queryString.stringify(params);

  return apiGET(`/rescues?${query}`);
};

export const getRescue = id => {
  return apiGET(`/rescues/${id}?next_rescues_dates=true`);
};

export const updateRescue = (rescueId, rescue) => {
  return apiPATCH(`/rescues/${rescueId}`, rescue);
};

export const updateRescueNew = (rescueId, rescue) => apiPUT(`/rescues/${rescueId}`, rescue);

export const updateRescues = (rescues) => apiPATCH('/rescues', rescues);
