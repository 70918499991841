import React, { useEffect } from 'react';
import { useRouteMatch, generatePath, Link, Route, Switch } from 'react-router-dom';
import { Breadcrumbs, makeStyles } from '@material-ui/core';
import routes from '../../../routes';
import { Steps } from 'intro.js-react';
import EventsDesktop from './events/EventsDesktop';
import MyFrusDesktop from './myFrus/MyFrusDesktop';
import RescuerDashboardDesktopAppBar from './Components/RescuerDashboardDesktopAppBar';
import MyRescuesDesktop from './myRescues/MyRescuesDesktop';
import RescuerScheduleDesktop from './rescuerSchedule/RescuerScheduleDesktop';
import useRescuerDashboardActions from '../../../hooks/useRescuerDashboardActions';
import { RESCUER_SCHEDULE_FILTER } from '../rescuerDashboardMobile/Schedule/ScheduleMobile';
import { scheduleTour } from '../../../helpers/tours';
import { assign } from 'lodash';
import { initialRescuerScheduleFilters } from '../../../reducers/ui';
import useUIFilter from '../../../hooks/useUIFilter';
import SiteNewsDesktop from './siteNews/SiteNewsDesktop';

export const RESCUER_DESKTOP_DASHBOARD_TAB_MY_RESCUES = 'my-rescues';
export const RESCUER_DESKTOP_DASHBOARD_TAB_SCHEDULE = 'schedule';
export const RESCUER_DESKTOP_DASHBOARD_TAB_EVENTS = 'events';
export const RESCUER_DESKTOP_DASHBOARD_TAB_SITE_NEWS = 'site-news';

export const paths = [
  generatePath(routes.dashboardRescuer),
  generatePath(routes.dashboardRescuer, { tab: RESCUER_DESKTOP_DASHBOARD_TAB_MY_RESCUES }),
  generatePath(routes.dashboardRescuer, { tab: RESCUER_DESKTOP_DASHBOARD_TAB_SCHEDULE }),
  generatePath(routes.dashboardRescuer, { tab: RESCUER_DESKTOP_DASHBOARD_TAB_EVENTS }),
  generatePath(routes.dashboardRescuer, { tab: RESCUER_DESKTOP_DASHBOARD_TAB_SITE_NEWS }),
];

const useStyles = makeStyles({
  root: {
    marginTop: 10,
    height: '100%',
  },
});

const RescuerDashboardDesktopContainer = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { setUIFilter, getUIFilter } = useUIFilter();

  const scheduleFilters = assign(initialRescuerScheduleFilters, getUIFilter(RESCUER_SCHEDULE_FILTER));

  const { fetchMyFrusTab, fetchScheduleTab, fetchEventsTab } = useRescuerDashboardActions();

  useEffect(() => {
    if (url !== paths[0] && url !== paths[1]) {
      // my frus & my rescues
      fetchMyFrusTab(false, true); //quietMode, force
    }

    if (url !== paths[2]) {
      // schedule
      fetchScheduleTab(false, true);
    }

    if (url !== paths[3]) {
      // events
      fetchEventsTab(false, true);
    }
  }, [fetchMyFrusTab, fetchScheduleTab, fetchEventsTab]);

  return (
    <div data-testid="rescuer-dashboard-desktop-div">
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={routes.index}>
          Dashboard
        </Link>
      </Breadcrumbs>

      <RescuerDashboardDesktopAppBar />

      <Steps
        enabled={paths[2] === url && scheduleFilters.showInitialTourStepper.value}
        options={{
          nextToDone: true,
          hidePrev: true,
        }}
        onExit={() =>
          setUIFilter(RESCUER_SCHEDULE_FILTER, {
            ...scheduleFilters,
            showInitialTourStepper: { value: false },
          })
        }
        initialStep={0}
        steps={scheduleTour}
      />

      <div className={classes.root}>
        <Switch>
          <Route exact path={[paths[0], generatePath(routes.index)]} component={MyFrusDesktop} />
          <Route path={paths[1]} component={MyRescuesDesktop} />
          <Route path={paths[2]} component={RescuerScheduleDesktop} />
          <Route path={paths[3]} component={EventsDesktop} />
          <Route path={paths[4]} component={SiteNewsDesktop} />
        </Switch>
      </div>
    </div>
  );
};

export default RescuerDashboardDesktopContainer;
