import * as Sentry from '@sentry/react';
import snackbarHelper from './snackbarHelper';
import * as notificationsHelper from './notifications';

export const shouldLogErrors = () => ['staging', 'test', 'production'].indexOf(process.env.NODE_ENV) >= 0;

export const handleError = (error, message = false) => {
  if (shouldLogErrors()) {
    Sentry.captureException(error);
  }

  snackbarHelper.error(message ? message : error.message);
};

/**
 * @deprecated Please use withNotificationServiceHOC or useNotificationService instead
 * @param {Error} error
 * @param {Context} notificationsContext
 * @param {string} message
 * @returns {*}
 */
export const handleErrorNew = (error, notificationsContext, message = undefined) => {
  if (shouldLogErrors()) {
    Sentry.captureException(error);
  }

  return notificationsHelper.addNotification(message ? message : error.message, notificationsContext, 'error');
};
