import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import ReactLoading from 'react-loading';
import { TextField } from 'final-form-material-ui';
import Bluebird from 'bluebird';
import queryString from 'query-string';
import classNames from 'classnames';
import { Box, Grid, Container, FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import loginLogo from '../../assets/images/login-logo.svg';
import { GoogleLogoIcon } from '../../assets/SvgIcons';
import routes from '../../routes';
import { DividerLine } from './DividerLine';
import { getPasswordsValidationErrors } from '../../helpers/validators';
import { Colors } from '../../assets/theme/Colors';
import * as authService from '../../services/auth';
import * as authApi from '../../api/auth';
import * as usersApi from '../../api/users';
import snackbarHelper from '../../helpers/snackbarHelper';
import * as errorsHelper from '../../helpers/errors';
import { useFormStyles } from './authStyles';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import errorMessages from '../../assets/errorMessages';
import { setFABActionsForCurrentlyLoggedInUser } from '../../services/auth';
import FABContext from '../../context/FABContext/FABContext';
import SocialMediaLinkIcons from '../../components/SocialMediaLinkIcons';


const validateForm = values => {
  const { email = '' } = values;
  const errors = getPasswordsValidationErrors(values, 'password1', 'password2');

  if (email.length === 0) {
    errors.email = 'Required';
  }

  return errors;
};

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  password1: {
    marginBottom: 0,
  },
  loginButtonsContainer: {
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gridGap: spacing(1),
      marginTop: spacing(1),
    },
  },
  socialMediaLinks: {
    paddingTop: spacing(2),
  },
}));

const RegisterPage = () => {
  const classes = useStyles();
  const authStyles = useFormStyles();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const isLoggingIn = useSelector(state => state.app.loggingIn);
  const [user, setUser] = useState(null);
  const fabContext = useContext(FABContext);
  const { formatMessage } = useIntl();

  // redirect to index if user is already logged in
  if (authService.getCurrentlyLoggedInUser()) {
    history.push(routes.index);
  }

  const parsedSearch = queryString.parse(location.search);
  const { invitation_hash } = parsedSearch;

  // this code is not suitable for an effect. effects run on component
  // mount and after EVERY update. this form isn't updated much, but logically
  // this just the wrong place for this code.
  useEffect(() => {
    if (!invitation_hash) {
      return;
    }

    setLoading(true);

    Bluebird.try(() => usersApi.getUserByInvitationHash(invitation_hash, null))
      .then(res => res.json())
      .then(res => {
        setLoading(false);

        const { user } = res;

        if (!user) {
          snackbarHelper.info('Invalid invitation code.');

          return false;
        }

        if (user.completed_registration === null) {
          return setUser(user);
        }

        snackbarHelper.info('This email address is already in use, please log in.');
      });
  }, [invitation_hash]);

  const onSubmit = values => {
    const { email, password1 } = values;

    setSubmitting(true);

    return Bluebird.try(() =>
      authApi.register(email, password1, user ? user.invitation_hash : null)
    )
      .then(res => res.json())
      .then(json => authService.handleAuthentication(history, json.data))
      .then(() => setFABActionsForCurrentlyLoggedInUser(fabContext))
      .catch(err => {
        setSubmitting(false);

        if (errorMessages.ERR_DUPLICATED_EMAIL.code === err.code) {
          return { email: errorMessages.ERR_DUPLICATED_EMAIL.message };
        }

        return errorsHelper.handleError(err);
      });
  };

  const handleLoginWithGoogle = () => {
    let options = {};

    if (user) {
      options.invitation_hash = user.invitation_hash;
    }

    return authService.authorizeWithGoogle(options, fabContext);
  };

  const handleSignUpUsingDifferentEmailClick = e => {
    e.preventDefault();

    setUser(null);

    history.push(routes.signup);
  };

  if (isLoading) {
    return <ReactLoading color={Colors.appBar.main} type="bubbles" />;
  }

  return (
    <Box className={authStyles.pageBackground}>
      <Container maxWidth="md">
        <Grid container spacing={2} className={authStyles.pageContainer} data-testid="welcome-index-page">
          <Grid item xs className={authStyles.logoWrapper}>
            <img src={loginLogo} alt="logo" className={authStyles.logo} />
          </Grid>
          <Grid item xs className={authStyles.loginFormWrapper}>
            <Typography className={authStyles.headerText} data-testid="signup-header">
              {user
                ? `${formatMessage({ id: 'signup.header.hello' })}, ${user.firstname}!`
                : formatMessage({ id: 'signup.header.sign-up' })}
            </Typography>

            <Form
              onSubmit={onSubmit}
              validate={validateForm}
              initialValues={{
                email: user ? user.email : '',
              }}
              render={({ handleSubmit, submitting, pristine, invalid, dirtySinceLastSubmit }) => {
                return (
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Field
                      className={authStyles.input}
                      margin="normal"
                      name="email"
                      component={TextField}
                      type="email"
                      variant="outlined"
                      fullWidth
                      placeholder={user ? 'Claim Profile' : formatMessage({ id: 'signup.form.email-label' })}
                      autoComplete="email"
                      data-testid="register-email-input"
                      disabled={user !== null || isLoggingIn}
                    />

                    <Field
                      className={classNames(classes.password1, authStyles.input)}
                      margin="normal"
                      name="password1"
                      component={TextField}
                      disabled={isLoggingIn}
                      type="password"
                      variant="outlined"
                      fullWidth
                      placeholder={formatMessage({ id: 'signup.form.password-label' })}
                      autoComplete="new-password"
                      data-testid="register-password-input"
                    />
                    <FormHelperText className={authStyles.helpText}>
                      {formatMessage({ id: 'signup.form.password-helper-text' })}
                    </FormHelperText>

                    <Field
                      className={authStyles.input}
                      margin="normal"
                      name="password2"
                      component={TextField}
                      disabled={isLoggingIn}
                      type="password"
                      variant="outlined"
                      fullWidth
                      placeholder={formatMessage({ id: 'signup.form.password-repeat-label' })}
                      autoComplete="new-password"
                      data-testid="register-password-confirmation-input"
                    />

                    <ButtonWithLoading
                      className={authStyles.submitButton}
                      fullWidth
                      type="submit"
                      isLoading={submitting || isSubmitting || isLoggingIn}
                      disabled={pristine || (invalid && !dirtySinceLastSubmit) || isLoggingIn}
                      data-testid="register-submit-button"
                    >
                      {formatMessage({ id: 'signup.form.submit-sign-up' })}
                    </ButtonWithLoading>
                  </form>
                );
              }}
            />

            <DividerLine />

            <ButtonWithLoading
              className={authStyles.googleButton}
              startIcon={<GoogleLogoIcon width={36} height={36} />}
              isLoading={isSubmitting || isLoggingIn}
              disabled={isSubmitting || isLoggingIn}
              fullWidth
              type="submit"
              onClick={() => handleLoginWithGoogle()}
            >
              {formatMessage({ id: 'signup.form.sign-up-with-google' })}
            </ButtonWithLoading>

            <Grid container className={classes.loginButtonsContainer}>
              <Grid item>
                <Link to={routes.login} variant="body2" style={{ color: '#fff' }}>
                  {formatMessage({ id: 'signup.form.have-account' })}
                </Link>
              </Grid>
              {user && (
                <Grid item>
                  <Link to="#" style={{ color: '#fff' }} onClick={handleSignUpUsingDifferentEmailClick}>
                    Sign up using a different email address
                  </Link>
                </Grid>
              )}
            </Grid>

            <Grid container direction="row" justify="center" alignItems="center" className={classes.socialMediaLinks}>
              <SocialMediaLinkIcons iconColor="#fff" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RegisterPage;
