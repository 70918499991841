import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Breadcrumbs, Button, Grid, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import ReceiversTable from '../components/ReceiversTable';
import routes from '../routes';
import * as sitesActions from '../actions/sites';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import { Roles } from '../models/roles';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { withNotificationService } from '../context/NotificationsContext/NotificationServiceHOC';

class ReceiversListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };
  }

  componentDidMount() {
    const { fetchSiteReceivers, fetchSiteRescues, site } = this.props;

    fetchSiteReceivers(site.id);
    fetchSiteRescues(moment().format('YYYYMMDD'), null, site.id); // logic to check receiver future rescues
  }

  renderCustomDonationsTableToolbar = () => {
    const { history } = this.props;
    return (
      <Button
        startIcon={<Add />}
        type="button"
        variant="contained"
        color="primary"
        size="small"
        onClick={() => history.push(routes.receivers_add)}
        data-testid="add-new-receiver-button"
      >
        Add New Receiving Agency
      </Button>
    );
  };

  handleDeleteClick = (receiverId, receiverName) => {
    const { siteRescues, site, deleteReceiver, notificationService } = this.props;

    const receiverRescues = (siteRescues.bySiteId[site.id] || []).filter((rescue) => rescue.receiver_id === receiverId);

    if (!receiverRescues.length) {
      return confirmAlert({
        title: 'Are you sure you want to do this?',
        message: `Deleting "${receiverName}" will remove them from your site. If you're sure, please type "DELETE" into the field below and hit "Confirm".`,
        buttons: [
          {
            label: 'Cancel',
            color: 'primary',
          },
          {
            label: 'Confirm',
            color: 'primary',
            requireDeleteInput: true,
            onClick: () => {
              this.setState({ isDeleting: true });

              return Bluebird.try(() => deleteReceiver(site.id, receiverId))
                .then(() => this.setState({ isDeleting: false }))
                .then(() =>
                  notificationService.addSuccessNotification(`${receiverName} receiving agency deleted successfully!`)
                );
            },
          },
        ],
        customUI: ({ title, message, onClose, buttons }) => (
          <ConfirmationDialog
            buttons={buttons}
            closeDialog={onClose}
            title={title}
            message={message}
          />
        ),
      });
    }

    return confirmAlert({
      message: `${receiverName} receiving agency is associated with upcoming rescues and cannot be deleted. Please remove any upcoming rescues associated with this agency to continue.`,
      buttons: [
        {
          label: 'OK',
          color: 'primary',
        },
      ],
      customUI: ({ message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          message={message}
        />
      ),
    });
  };

  render() {
    const { history, siteReceivers, isFetchingReceivers, siteRescues, site } = this.props;
    const { isDeleting } = this.state;

    const isAdmin = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
      Roles.Admin,
      Roles.NationalSiteDirector,
      Roles.SiteDirector,
    ]);

    if (isFetchingReceivers) {
      return <div>fetching receiving agencies</div>;
    }

    return (
      <>
        <Breadcrumbs aria-label="Breadcrumbs">
          <Typography color="textPrimary">Receiving Agencies</Typography>
        </Breadcrumbs>

        <Grid container>
          <Grid item xs={12}>
            <ReceiversTable
              tableId="#receivers/receivers-table"
              receiversList={siteReceivers}
              customToolbar={this.renderCustomDonationsTableToolbar}
              onRowClick={id => history.push(`/receivers/${id}`)}
              onPrimaryContactUserClick={userId => history.push(generatePath(routes.userEdit, { id: userId }))}
              isAdmin={isAdmin}
              onDeleteClick={(receiverId, receiverName) => this.handleDeleteClick(receiverId, receiverName)}
              isLoading={isDeleting || siteRescues.inflight}
              siteRescues={siteRescues.bySiteId[site.id]}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({
  app: { site },
  entities: {
    sites: { receivers, rescues },
  },
}) => ({
  site,
  isFetchingReceivers: receivers.inflight,
  siteReceivers: receivers.bySiteId[site.id],
  siteRescues: rescues,
});
const mapDispatchToProps = dispatch => ({
  fetchSiteReceivers: siteId => dispatch(sitesActions.fetchSiteReceiversIfNeeded(siteId)),
  fetchSiteRescues: (from, to, siteId) => dispatch(sitesActions.fetchSiteRescues(from, to, siteId)),
  deleteReceiver: (siteId, receiverId) => dispatch(sitesActions.deleteSiteReceiver(siteId, receiverId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotificationService(ReceiversListView));
