import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as foodDonorsActions from '../actions/foodDonors';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import UnassignedFoodDonorsTable from '../components/UnassignedFoodDonorsTable';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
}));

const FoodDonorsUnassignedListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const unassigedFoodDonors = useSelector(state => state.entities.foodDonors.unassigned);
  const unassigedFoodDonorsList = unassigedFoodDonors.allIds.map(id => unassigedFoodDonors.byId[id]);
  const currentlyLoggedInUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const isAllowedToDeleteDonor = authService.hasAnyRoleInCurrentlySelectedSite(currentlyLoggedInUser, [
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);

  useEffect(() => {
    dispatch(foodDonorsActions.fetchUnassignedFoodDonors());
  }, []);

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        <Typography color="textPrimary">Unassigned Food Donors</Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12}>
          <UnassignedFoodDonorsTable
            isLoading={unassigedFoodDonors.inflight}
            unassignedFoodDonors={unassigedFoodDonorsList}
            showDeleteDonorButton={isAllowedToDeleteDonor}
            onFoodDonorDelete={foodDonor => dispatch(foodDonorsActions.deleteUnassignedFoodDonor(foodDonor))}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FoodDonorsUnassignedListView;
