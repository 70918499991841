import React, { useState } from 'react';
import {
  makeStyles,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  Typography,
  Checkbox,
  Table,
  TableRow,
  Grid,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonsWrapper: {
    float: 'right',
    '& button:first-child': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const SiteFeatureFlags = props => {
  const classes = useStyles();
  const { featureFlags, site, submitCallback } = props;

  const [features, setFeatures] = useState(site.feature_flags || []);

  const TableCols = () => (
    <TableRow>
      <TableCell>Feature</TableCell>
      <TableCell>Is Active</TableCell>
    </TableRow>
  );

  const updateFeatureList = (f, isEnabled) => {
    const tempList = features.filter(feature => feature !== f.id);

    if (isEnabled) {
      tempList.push(f.id);
    }

    setFeatures(tempList);
  };

  return (
    <>
      <Grid spacing={3} container direction="row" justify="space-between" alignItems="center">
        <Grid item xs>
          <Typography component="h4">
            Site's features list
          </Typography>
        </Grid>

        <Grid item xs>
          <div className={classes.buttonsWrapper}>
            <Button
              data-testid="site-feature-flag-reset-button"
              onClick={() => setFeatures(site.feature_flags)}
              variant="outlined"
              color="primary"
            >
              Reset
            </Button>

            <Button
              data-testid="site-feature-flag-submit-button"
              variant="contained"
              color="primary"
              onClick={() => submitCallback(site.id, features)}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>

      <Table data-testid="site-feature-flag-table">
        <TableHead>
          <TableCols />
        </TableHead>

        <TableFooter>
          <TableCols />
        </TableFooter>

        <TableBody>
          {featureFlags.map(f => (
            <TableRow key={f.id}>
              <TableCell component="th" scope="row">
                {f.name}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={features.includes(f.id)}
                  onChange={event => updateFeatureList(f, event.target.checked)}
                  value="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default SiteFeatureFlags;
