import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { getMuiTableDataIndex } from '../helpers/getters';
import { getRescuePickupLocationFullName } from '../helpers/RescuesHelper';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import ButtonWithLoading from './ButtonWithLoading';

const DonationsBulkEditReviewAndSave = ({
  onExit,
  onSave,
  dialogOpen,
  editedDonations,
  donations,
  isLoading,
}) => {
  const editedDonationsPreviousData = donations.filter((donation) => editedDonations.some((e) => e.id === donation.id));

  const newLocal =
    editedDonationsPreviousData &&
    editedDonationsPreviousData.map((donation) => {
      const editedRescue = editedDonations.find((e) => e.id === donation.id);
      return { ...donation, newData: editedRescue };
    });
  const donationsData = newLocal;

  const dateCustomBodyRender = (value) => {
    const formattedDate = moment(value, 'YYYYMMDD').format('MM/DD/YYYY');

    return <span>{formattedDate}</span>;
  };

  const locationCustomBodyRender = (value, tableMeta) => {
    const rescue = donationsData[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescuePickupLocationFullName = getRescuePickupLocationFullName(rescue) || '';

    return rescuePickupLocationFullName;
  };

  const frequencyCustomBodyRender = (value, tableMeta) => {
    const donation = donations[getMuiTableDataIndex(tableMeta)];

    switch (donation.frequency) {
      case 0:
        return 'one-time';
      case 1:
        return donation.frequency_every === 1 ? 'weekly' : 'every other week';
      case 2:
        return 'monthly';
      default:
        return 'unknown';
    }
  };

  const statusRender = (status) => {
    switch (status) {
      case 'CURRENT':
        return `Resumed on Date: ${moment().format('MM-DD-YYYY')}`;
      case 'PAUSED':
        return `Paused on Date: ${moment().add('day', 1).format('MM-DD-YYYY')}`;
      default:
        return 'N/A';
    }
  };

  const editsCustomBodyRender = (value, tableMeta) => {
    const donation = donationsData[getMuiTableDataIndex(tableMeta)];
    const edits = [];
    if ('status' in value) {
      const oldStatus = donation.status || 'N/A';
      edits.push({ old: oldStatus, new: statusRender(value.status), fieldName: 'Status' });
    }

    if ('lbs' in value) {
      edits.push({
        old: donation.lbs !== null ? donation.lbs : donation.newData.estimated,
        new: value.lbs,
        fieldName: 'Weight',
      });
    }

    const modifiedFields = edits.reduce(
      (acc, field) => [
        ...acc,
        <Box key={field.fieldName}>
          <Box py={1}>
            <u>{field.fieldName}</u>:
          </Box>
          <Box>
            <strike>{field.old}</strike> <strong>{field.new}</strong>
          </Box>
        </Box>,
      ],
      []
    );

    return <Box>{modifiedFields}</Box>;
  };

  return (
    <Dialog open={dialogOpen} maxWidth="md" fullWidth onBackdropClick={onExit}>
      <DialogTitle>Review and Save</DialogTitle>
      <DialogContent>
        <BaseMUIDataTable
          isLoading={!donationsData.length}
          tableId="#pastrescues/editedRescues"
          data={donationsData}
          columns={[
            {
              name: 'location',
              label: 'Food Donor',
              options: {
                forceDisplay: true,
                customBodyRender: (value, tableMeta) => locationCustomBodyRender(value, tableMeta),
              },
            },
            {
              name: 'donation',
              label: 'Description',
            },
            {
              name: 'frequency',
              label: 'Frequency',
              options: {
                forceDisplay: true,
                customBodyRender: (value, tableMeta) => frequencyCustomBodyRender(value, tableMeta),
              },
            },
            {
              name: 'start_date',
              label: 'Start Date',
              options: {
                forceDisplay: true,
                customBodyRender: (value, tableMeta) => dateCustomBodyRender(value, tableMeta),
              },
            },
            {
              name: 'newData',
              label: 'Edits',
              options: {
                forceDisplay: true,
                customBodyRender: (value, tableMeta) => editsCustomBodyRender(value, tableMeta),
              },
            },
          ]}
          options={{
            sortOrder: {
              name: 'date',
              direction: 'desc',
            },
            sort: false,
            filter: false,
            download: false,
            search: false,
            viewColumns: false,
            pagination: false,
            elevation: 0,
            selectableRows: 'none',
            responsive: 'simple',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onExit}>Back</Button>
        <ButtonWithLoading isLoading={isLoading} onClick={() => onSave(donationsData)}>
          Save
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
};

export default DonationsBulkEditReviewAndSave;
