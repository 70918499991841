import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationDialog from '../components/ConfirmationDialog';

export const showSendInvitationEmailConfirmationDialog = (data, onClickCallback) => confirmAlert({
  title: 'Send invitation email?',
  message: 'Should the system send an invitation email now? You can send an invitation email to them anytime from the People tab.',
  buttons: [
    {
      label: 'No',
      color: 'primary',
      onClick: () => onClickCallback(data),
    },
    {
      label: 'Send Email',
      color: 'primary',
      onClick: () => onClickCallback(data, true),
    },
  ],
  customUI: ({ title, message, onClose, buttons }) => (
    <ConfirmationDialog buttons={buttons} closeDialog={onClose} title={title} message={message} />
  ),
});
