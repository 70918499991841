import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import BaseMUIDataTable from '../../../BaseMUIDataTable/BaseMUIDataTable';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      '& table': {
        '& tr': {
          '&:hover': {
            cursor: 'pointer',
          },
          '& td:last-child': {
            borderBottom: 0,
          },
          '& td[class*="MUIDataTableBodyCell-cellStacked"]': {
            '@media (max-width:959.95px)': {
              width: '100% !important',
              fontWeight: 900,
              borderBottom: 0,
              paddingBottom: 0,
            },
          },
          '& td[class*="MUIDataTableBodyCell-responsiveStacked"]': {
            '@media (max-width:959.95px)': {
              '@media (max-width:959.95px)': {
                width: '100% !important',
                height: 'unset !important',
                whiteSpace: 'initial !important',
              },
            },
          },
        },
      },
    },
  },
}));

const NotificationsTable = ({
  notifications,
  onRowClick,
  onRowsDelete,
  title,
  additionalColumns,
  tableId,
  customToolbarSelect,
}) => {
  const classes = useStyles();

  const columns = [
    {
      name: 'id',
      label: '#',
      options: {
        display: false,
      },
    },
    {
      name: 'created_at',
      label: 'Date',
      options: {
        customBodyRenderLite: index => moment(notifications[index].created_at).format('MM/DD/YYYY h:mm A'),
      },
    },
    {
      name: 'subject',
      label: 'Subject',
      options: {
        customBodyRenderLite: index => notifications[index].subject,
      },
    },
  ];

  if (additionalColumns) {
    columns.push(...additionalColumns);
  }

  return (
    <BaseMUIDataTable
      tableId={tableId}
      elevation={0}
      className={classes.root}
      title={title}
      data={notifications}
      columns={columns}
      options={{
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        onRowClick,
        onRowsDelete,
        sortOrder: {
          name: 'created_at',
          direction: 'desc',
        },
        responsive: 'simple',
        selectToolbarPlacement: 'above',
        customToolbarSelect: customToolbarSelect,
        textLabels: {
          selectedRows: {
            text: 'notification(s) selected',
          },
        },
      }}
    />
  );
};

export default NotificationsTable;
