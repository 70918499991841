import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import Bluebird from 'bluebird';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import {
  Breadcrumbs,
  FormControl,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import RescuersTable from '../components/RescuersTable';
import * as sitesActions from '../actions/sites';
import * as badgesActions from '../actions/badges';
import routes from '../routes';
import { setUserTableOptions } from '../actions/tableOptions';
import snackbarHelper from '../helpers/snackbarHelper';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import * as authActions from '../actions/auth';
import { withNotificationService } from '../context/NotificationsContext/NotificationServiceHOC';
import FABContext from '../context/FABContext/FABContext';
import { setFABActions } from '../helpers/fab';
import { RegistrationProcessFilter } from '../helpers/MuiTableCustomFilters';
import { hasActiveFeatureFlag } from '../helpers/fetureFlagsHelper';
import { FF_SITE_GOALS } from '../components/FeatureFlagWrapper';
import { fetchZipCodesIfNeeded } from '../actions/zipCodes';

export const RescuersFilterTypes = {
  all: 'All Rescuers',
  active: 'All time',
  inactive: 'Never',
  quarter: 'Last 90 days',
};

export const RescuerStatusFilterTypes = {
  all: 'Total Rescuers',
  paused: 'Paused Rescuers',
  active: 'Active Rescuers',
};

export const ActivityFrequencyFilterTypes = {
  all: 'All Rescuers',
  rescuers30Day: '30-Day Active Rescuers',
  adopters30Day: '30-Day Active Adopters',
  rescuers60Day: '60-Day Active Rescuers',
  adopters60Day: '60-Day Active Adopters',
  rescuers90Day: '90-Day Active Rescuers',
  adopters90Day: '90-Day Active Adopters',
  quartile: 'Top-Quartile Rescuers',
};

const TABLE_ID = '#rescuers/rescuers-table';

class RescuersListView extends Component {
  componentDidMount() {
    const { fetchSiteRescuers, fetchSiteBadges, fetchZipCodesIfNeeded, site } = this.props;

    fetchSiteRescuers(site.id);
    fetchZipCodesIfNeeded();
    fetchSiteBadges(site.id);
  }

  handleEditRescuerButtonClick = userId => {
    const { history } = this.props;

    return history.push(generatePath(routes.userEditNew, { id: userId }));
  };

  handleDeleteRescuerButtonClick = (user, data = {}) => {
    const { site, deleteSiteRescuer, notificationService } = this.props;

    return Bluebird
        .try(() => deleteSiteRescuer(site.id, user.id, data))
        .then(() => notificationService.addSuccessNotification('Rescuer deleted successfully!')
    );
  };

  handleSendEmailButtonClick = rescuers => {
    const { history } = this.props;

    if (rescuers.filter(r => r.email_verified).length <= 0) {
      snackbarHelper.info(
        `You don't have any recipients with verified e-mail address selected` +
          ` therefore you will not be able to send the e-mail.`
      );
    }

    return history.push(routes.sendEmail, { usersIds: rescuers.map(r => r.id) });
  };

  handleSendSMSButtonClick = rescuers => {
    const { history } = this.props;

    if (rescuers.filter(r => r.phone_verified).length <= 0) {
      snackbarHelper.info(
        `You don't have any recipients with verified phone# selected` +
          ` therefore you will not be able to send the SMS.`
      );
    }

    return history.push(routes.sendSms, { usersIds: rescuers.map(r => r.id) });
  };

  handleImpersonateUserButtonClick = userId => {
    const { rescuers, impersonateUser, history } = this.props;

    impersonateUser(userId);

    setFABActions(rescuers.byId[userId], history, this.context);
  };

  handleNoteButtonClick = (userId, note) => {
    const { site, updateSiteRescuerNotes } = this.props;

    updateSiteRescuerNotes(site.id, userId, note);
  };

  getTableSelectedRescuerFilter = () => {
    const { previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'last_rescue_claimed');
    return get(previousTableState, ['filterList', columnIndex, 0], RescuersFilterTypes.all);
  };

  getTableSelectedRescuerStatusFilter = () => {
    const { previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'paused_at');
    return get(previousTableState, ['filterList', columnIndex, 0], RescuerStatusFilterTypes.all);
  };

  getTableSelectedActivityFrequencyFilter = () => {
    const { previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'activity_frequency');
    return get(previousTableState, ['filterList', columnIndex, 0], ActivityFrequencyFilterTypes.all);
  };

  getTableSelectedRegistrationCompleteFilter = () => {
    const { previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'completed_registration');
    return get(previousTableState, ['filterList', columnIndex, 1], RegistrationProcessFilter.all);
  };

  getRescuerBadgeTypes = () => {
    const { previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'badges');
    const value = get(previousTableState, ['filterList', columnIndex], []);

    return value.length > 0 ? value : ['All'];
  };

  onRescuersBadgeChange = currentTarget => {
    const { dataset } = currentTarget;
    const choiceName = dataset.name;

    const { setUserTableOptions, activeUser, previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'badges');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choiceName === 'all') {
      previousFilters[columnIndex] = [];
      return setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    previousFilters[columnIndex] = previousFilters[columnIndex].includes(choiceName)
      ? previousFilters[columnIndex].filter(row => row !== choiceName)
      : [...previousFilters[columnIndex], choiceName];

    setUserTableOptions(activeUser.id, TABLE_ID, {
      ...previousTableState,
      filterList: previousFilters,
    });
  };

  onLastClaimChange = choice => {
    const { setUserTableOptions, activeUser, previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'last_rescue_claimed');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === RescuersFilterTypes.all) {
      previousFilters[columnIndex] = [];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === RescuersFilterTypes.active) {
      previousFilters[columnIndex] = [RescuersFilterTypes.active];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === RescuersFilterTypes.inactive) {
      previousFilters[columnIndex] = [RescuersFilterTypes.inactive];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === RescuersFilterTypes.quarter) {
      previousFilters[columnIndex] = [RescuersFilterTypes.quarter];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }
  };

  onRescuerStatusChange = (choice) => {
    const { setUserTableOptions, activeUser, previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'paused_at');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (choice === RescuerStatusFilterTypes.all) {
      previousFilters[columnIndex] = [];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === RescuerStatusFilterTypes.paused) {
      previousFilters[columnIndex] = [RescuerStatusFilterTypes.paused];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === RescuerStatusFilterTypes.active) {
      previousFilters[columnIndex] = [RescuerStatusFilterTypes.active];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }
  };

  onActivityFrequencyStatusChange = choice => {
    const { setUserTableOptions, activeUser, previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'activity_frequency');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (choice === ActivityFrequencyFilterTypes.all) {
      previousFilters[columnIndex] = [];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === ActivityFrequencyFilterTypes.rescuers30Day) {
      previousFilters[columnIndex] = [ActivityFrequencyFilterTypes.rescuers30Day];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === ActivityFrequencyFilterTypes.adopters30Day) {
      previousFilters[columnIndex] = [ActivityFrequencyFilterTypes.adopters30Day];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === ActivityFrequencyFilterTypes.rescuers60Day) {
      previousFilters[columnIndex] = [ActivityFrequencyFilterTypes.rescuers60Day];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === ActivityFrequencyFilterTypes.adopters60Day) {
      previousFilters[columnIndex] = [ActivityFrequencyFilterTypes.adopters60Day];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === ActivityFrequencyFilterTypes.rescuers90Day) {
      previousFilters[columnIndex] = [ActivityFrequencyFilterTypes.rescuers90Day];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === ActivityFrequencyFilterTypes.adopters90Day) {
      previousFilters[columnIndex] = [ActivityFrequencyFilterTypes.adopters90Day];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (choice === ActivityFrequencyFilterTypes.quartile) {
      previousFilters[columnIndex] = [ActivityFrequencyFilterTypes.quartile];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }
  };

  onRegistrationCompleteChange = checked => {
    const { setUserTableOptions, activeUser, previousTableState } = this.props;
    const columnIndex = get(previousTableState, 'columns', []).findIndex(c => c.name === 'completed_registration');
    const previousFilters = get(previousTableState, 'filterList', []);

    if (columnIndex < 0 || previousFilters.length === 0) {
      return;
    }

    if (checked) {
      previousFilters[columnIndex] = ['static', RegistrationProcessFilter.completed];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }

    if (!checked) {
      previousFilters[columnIndex] = [];
      setUserTableOptions(activeUser.id, TABLE_ID, {
        ...previousTableState,
        filterList: previousFilters,
      });
    }
  };

  render() {
    const rescuerType = this.getTableSelectedRescuerFilter();
    const rescuerStatusType = this.getTableSelectedRescuerStatusFilter();
    const activityFrequencyType = this.getTableSelectedActivityFrequencyFilter();
    const completedRegistrationType = this.getTableSelectedRegistrationCompleteFilter();
    const completeRegistrationChecked =
      completedRegistrationType === RegistrationProcessFilter.all ||
      completedRegistrationType === RegistrationProcessFilter.notCompleted
        ? false
        : true;
    const rescuerBadgeTypes = this.getRescuerBadgeTypes();
    const { classes, siteRescuers, rescuersInflight, badgesList } = this.props;

    if (rescuersInflight) {
      return <div>fetching rescuers</div>;
    }

    return (
      <>
        <Breadcrumbs aria-label="Breadcrumbs">
          <Typography color="textPrimary">Rescuers</Typography>
        </Breadcrumbs>

        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <Paper className={classes.paper}>
              <FormControl className={classes.formControl}>
                <InputLabel>Last Rescue Date</InputLabel>
                <Select value={rescuerType} onChange={event => this.onLastClaimChange(event.target.value)}>
                  {[
                    RescuersFilterTypes.all,
                    RescuersFilterTypes.quarter,
                    RescuersFilterTypes.active,
                    RescuersFilterTypes.inactive,
                  ].map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item>
            <Paper className={classes.paper}>
              <FormControl className={classes.formControl}>
                <InputLabel>Rescuer's Badges</InputLabel>
                <Select
                  multiple
                  value={rescuerBadgeTypes}
                  onChange={event => this.onRescuersBadgeChange(event.currentTarget)}
                  renderValue={value => {
                    if (value.length > 2) {
                      return `${value.length} selected`;
                    }
                    return value.join(', ');
                  }}
                >
                  <MenuItem value="all" data-name="all">
                    All
                  </MenuItem>
                  {badgesList.map(badge => (
                    <MenuItem key={badge.id} value={badge.name} data-name={badge.name}>
                      <Box gridColumnGap={8} display="flex" alignItems="center">
                        <img src={badge.image_uri} alt={badge.name} width="30" />
                        <span>{badge.name}</span>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
          </Grid>

          <>
            <Grid item>
              <Paper className={classes.paper}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Rescuer Status</InputLabel>
                  <Select
                    value={rescuerStatusType}
                    onChange={(event) => this.onRescuerStatusChange(event.target.value)}
                  >
                    {[
                      RescuerStatusFilterTypes.all,
                      RescuerStatusFilterTypes.paused,
                      RescuerStatusFilterTypes.active,
                    ].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>

            <Grid item>
              <Paper className={classes.paper}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Activity Frequency</InputLabel>
                  <Select
                    value={activityFrequencyType}
                    onChange={(event) => this.onActivityFrequencyStatusChange(event.target.value)}
                  >
                    {[
                      ActivityFrequencyFilterTypes.all,
                      ActivityFrequencyFilterTypes.rescuers30Day,
                      ActivityFrequencyFilterTypes.adopters30Day,
                      ActivityFrequencyFilterTypes.rescuers60Day,
                      ActivityFrequencyFilterTypes.adopters60Day,
                      ActivityFrequencyFilterTypes.rescuers90Day,
                      ActivityFrequencyFilterTypes.adopters90Day,
                      ActivityFrequencyFilterTypes.quartile,
                    ].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={completeRegistrationChecked}
                    onChange={(event) =>
                      this.onRegistrationCompleteChange(event.target.checked)
                    }
                  />
                }
                label="Registration Complete"
              />
            </Grid>
          </>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <RescuersTable
              tableId={TABLE_ID}
              rescuers={siteRescuers}
              onSendEmailButtonClick={this.handleSendEmailButtonClick}
              onSendSMSButtonClick={this.handleSendSMSButtonClick}
              onEditRescuerButtonClick={this.handleEditRescuerButtonClick}
              onDeleteRescuerButtonClick={this.handleDeleteRescuerButtonClick}
              onImpersonateUserButtonClick={this.handleImpersonateUserButtonClick}
              onNoteButtonClick={this.handleNoteButtonClick}
              showDeleteRescuerButton={
                authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
                  Roles.Admin,
                  Roles.NationalSiteDirector,
                  Roles.SiteDirector,
                  Roles.SiteCoordinator,
                ])
              }
              showImpersonateUserButton={
                authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([Roles.Admin])
              }
              badges={badgesList}
              showBadgesColumns
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({
  app: { loggedInUser, impersonating, site },
  tableOptions,
  entities: {
    featureFlags,
    badges,
    sites: { rescuers },
  },
}) => {
  const activeUser = impersonating || loggedInUser;

  return {
    site,
    activeUser: activeUser,
    goalsFF: site ? hasActiveFeatureFlag(FF_SITE_GOALS, site, Object.values(featureFlags.byId)) : false,
    previousTableState: get(tableOptions, [activeUser.id, TABLE_ID], {}),
    rescuersInflight: rescuers.inflight,
    rescuers: rescuers,
    badgesList: Object.values(badges.byId),
    siteRescuers: rescuers.bySiteId[site.id] || [],
  };
};
const mapDispatchToProps = dispatch => ({
  setUserTableOptions: (userId, tableId, data) => dispatch(setUserTableOptions(userId, tableId, data)),
  fetchSiteRescuers: siteId => dispatch(sitesActions.fetchSiteRescuers(siteId)),
  fetchSiteBadges: siteId => dispatch(badgesActions.fetchBadges()),
  fetchZipCodesIfNeeded: () => dispatch(fetchZipCodesIfNeeded()),
  deleteSiteRescuer: (siteId, rescuerId, data) => dispatch(sitesActions.deleteSiteRescuerNew(siteId, rescuerId, data)),
  updateSiteRescuerNotes: (siteId, rescuerId, note) => dispatch(sitesActions.saveSiteRescuerNotes(siteId, rescuerId, note)),
  impersonateUser: userId => dispatch(authActions.changeUser(userId)),
});
const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1),
  },
});

RescuersListView.contextType = FABContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withNotificationService(RescuersListView))));
