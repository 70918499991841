import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { getRoleAssignment } from '../helpers/getters';
import { Roles } from '../models/roles';

const RescuerRoleAssignmentsTable = ({ roleAssignments, onDeleteRoleAssignmentButtonClick }) => {
  const getMuiTheme = theme =>
    createMuiTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MuiTableCell: {
          root: {
            ...theme.overrides.MuiTableCell.root,
            padding: '4px 4px 4px 12px',
          },
        },
        MuiTableRow: {
          head: {
            ...theme.overrides.MuiTableRow.root,
            height: '36px',
          },
        },
        MUIDataTableHeadCell: {
          root: {
            ...theme.overrides.MUIDataTableHeadCell.root,
            padding: '4px 4px 4px 12px',
          },
        },
      },
    });

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        title="Sites"
        data={roleAssignments}
        columns={[
          {
            name: 'role_name',
            label: 'Role',
            options: {
              filter: false,
              sort: false,
            },
          },
          {
            name: 'assignment',
            label: 'Assignment',
            options: {
              filter: false,
              sort: false,
              customBodyRenderLite: dataIndex => {
                const roleAssignment = roleAssignments[dataIndex];
                return getRoleAssignment(roleAssignment);
              },
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              filter: false,
              sort: false,
              customBodyRenderLite: dataIndex => {
                const roleRow = roleAssignments[dataIndex];
                const sitesWithRescuerRoles = roleAssignments.filter(row => row.role_name === Roles.Rescuer);

                if (roleRow.role_name !== Roles.Rescuer) {
                  return null;
                }

                return (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={sitesWithRescuerRoles.length <= 1}
                    onClick={() => onDeleteRoleAssignmentButtonClick(roleRow)}
                    startIcon={<ExitToAppIcon />}
                  >
                    Leave Site
                  </Button>
                );
              },
            },
          },
        ]}
        options={{
          responsive: 'standard',
          selectableRows: 'none',
          pagination: false,
          search: false,
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
        }}
      />
    </MuiThemeProvider>
  );
};

export default RescuerRoleAssignmentsTable;
