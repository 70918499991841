import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from '@material-ui/core';

const RescueSizeSelector = ({
  value,
  onChange,
  disabled,
  rescueSizes = [],
  rescueSizeLabel = 'Please select a vehicle size and adjust the estimate weight manually if necessary',
}) => (
  <FormControl data-testid="rescue-size">
    <Box display="flex" flexWrap="wrap" alignItems="center" gridGap="8px">
      <FormLabel component="legend">{rescueSizeLabel}</FormLabel>
    </Box>
    <RadioGroup
      name="rescue_size_id"
      aria-label="Rescue Vehicle size"
      value={value}
      row
      onChange={event =>
        onChange({
          name: event.target.name,
          id: parseInt(event.target.value, 10),
        })
      }
    >
      {rescueSizes.map(rescueSize => (
        <FormControlLabel
          control={
            <Radio
              disabled={disabled}
              name={rescueSize.name}
              checked={value === rescueSize.id}
              value={rescueSize.id}
            />
          }
          key={rescueSize.id}
          label={rescueSize.name}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default RescueSizeSelector;
