import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Button } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import * as umbrellaFoodDonorsActions from '../../actions/umbrellaFoodDonors';
import UmbrellaFoodDonorStaffTable from '../../components/UmbrellaFoodDonorStaffTable';
import { generatePath, Link } from 'react-router-dom';
import routes from '../../routes';
import TableActionButton from '../../components/TableActionButton';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import TableActionsButtonsWrapper from '../../components/TableActionsButtonsWrapper';
import { deleteUmbrellaStaff } from '../../api/umbrellaFoodDonors';
import useNotificationService from '../../hooks/useNotificationService';

export const donorStaffTableId = '#donors/staff-table';

const UmbrellaFoodDonorStaffView = ({ umbrellaFoodDonorId }) => {
  const dispatch = useDispatch();
  const { addErrorNotification } = useNotificationService();
  const umbrellaFoodDonorsStaffEntities = useSelector(state => state.entities.umbrellaFoodDonors.staff);

  useEffect(() => {
    dispatch(umbrellaFoodDonorsActions.fetchUmbrellaFoodDonorsStaffIfNeeded(umbrellaFoodDonorId));
  }, [dispatch]);

  const deleteStaff = async staff => {
    try {
      await deleteUmbrellaStaff(umbrellaFoodDonorId, staff.id);
      dispatch(umbrellaFoodDonorsActions.fetchUmbrellaFoodDonorsStaffIfNeeded(umbrellaFoodDonorId));
    } catch (error) {
      addErrorNotification(error, `Unable to delete staff. Reason: ${error.message}`);
    }
  };

  const customToolbar = () => (
    <span>
      <Button
        startIcon={<AddIcon fontSize="small" />}
        type="button"
        variant="contained"
        color="primary"
        size="small"
        component={Link}
        to={generatePath(routes.umbrellaFoodDonorsStaffAdd, { id: umbrellaFoodDonorId })}
      >
        Invite to register as Food Donor Staff
      </Button>
    </span>
  );

  const actionButtons = staff => (
    <TableActionsButtonsWrapper>
      <TableActionButton
        title="Edit Umbrella Food Donor Staff"
        buttonProps={{
          component: Link,
          to: generatePath(routes.userEditNew, { id: staff.id }),
        }}
      />

      <TableActionButton
        title="Delete Umbrella Food Donor staff"
        icon={DeleteIcon}
        onClick={() => {
          confirmAlert({
            title: 'Delete this Umbrella Food Donor staff?',
            message: 'Are you sure you want to delete this staff?',
            buttons: [
              {
                label: 'No',
                color: 'primary',
              },
              {
                label: 'Yes',
                color: 'primary',
                onClick: () => deleteStaff(staff),
              },
            ],
            customUI: ({ title, message, onClose, buttons }) => (
              <ConfirmationDialog buttons={buttons} closeDialog={onClose} title={title} message={message} />
            ),
          });
        }}
      />
    </TableActionsButtonsWrapper>
  );

  return (
    <UmbrellaFoodDonorStaffTable
      tableId={donorStaffTableId}
      umbrellaFoodDonorStaff={get(umbrellaFoodDonorsStaffEntities, ['byUmbrellaFoodDonorId', umbrellaFoodDonorId], [])}
      customToolbar={customToolbar}
      actionButtons={actionButtons}
    />
  );
};

export default UmbrellaFoodDonorStaffView;
