import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Bluebird from 'bluebird';
import moment from 'moment';
import routes from '../../../routes';
import { Breadcrumbs, Typography, Grid, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { cancelSiteEvent, fetchSiteEvent, fetchSiteEventsList, fetchSiteRescuersIfNeeded } from '../../../actions/sites';
import useActiveSite from '../../../hooks/useActiveSite';
import { mapStoreToFormEventEdit } from '../../../models/events';
import EventAgendaView from './EventAgendaView';
import { updateEvent } from '../../../actions/events';
import useActiveUser from '../../../hooks/useActiveUser';
import OverlayLoader from '../../../components/OverlayLoader';
import EventEditForm from './EventEditForm';
import useNotificationService from '../../../hooks/useNotificationService';
import { Alert } from '@material-ui/lab';
import { getRescuersSelectOptions } from '../../../helpers/getters';
import { sortAlphabetically } from '../../../helpers/sorters';

const EventEditView = ({ match, isMobileView }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const activeSite = useActiveSite();
  const activeUser = useActiveUser();
  const siteEventsSpecs = useSelector(state => state.entities.sites.events);
  const siteEventsListEntites = useSelector(state => state.entities.sites.eventsList);
  const siteEventsList = siteEventsListEntites?.bySiteId[activeSite.id];
  const siteRescuersEntities = useSelector(state => state.entities.sites.rescuers);
  const rescuers = siteRescuersEntities.bySiteId[activeSite.id];
  const rescuersList = getRescuersSelectOptions(rescuers || []).sort((a, b) =>
  sortAlphabetically('asc', b.label, a.label)
);
  const { eventId } = match.params;

  const eventData = siteEventsSpecs.byId[eventId];
  const eventSpecsEventList = eventData && siteEventsList ? siteEventsList.filter((event => event.event_spec_id === eventData.id && moment(event.date.value).isSameOrAfter(moment(), 'day'))) : [];

  const { addSuccessNotification, addErrorNotification } = useNotificationService();
  const history = useHistory();

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  useEffect(() => {
    dispatch(fetchSiteEvent(activeSite.id, eventId));
    dispatch(fetchSiteEventsList(activeSite.id));
  }, [dispatch]);

  const editEventHandler = eventData => {
    setIsSubmitting(true);
    return Bluebird.try(() =>
      dispatch(
        updateEvent(activeSite.id, eventId, {
          ...eventData,
          user: activeUser,
        })
      )
    )
      .then(eventData => mapStoreToFormEventEdit(eventData))
      .then(() => addSuccessNotification('Event updated successfully!'))
      .then(() => history.push(routes.eventsList))
      .catch(error => addErrorNotification(error, 'Unable to edit event!'))
      .finally(() => setIsSubmitting(false));
  };

  const handleEventCancel = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(cancelSiteEvent(eventData));
      addSuccessNotification(`Event ${eventData.name} has been canceled!`);
      history.push(routes.eventsList);
      setIsSubmitting(false);
    } catch (error) {
      addErrorNotification(error);
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link to={routes.index} color="inherit">
          Home
        </Link>

        <Link to={routes.eventsList} color="inherit">
          Events
        </Link>

        <Typography color="textPrimary">Edit Event</Typography>
      </Breadcrumbs>

      {eventData && eventData.cancelled_at && (
        <Alert severity="warning">Event is canceled. Canceled events cannot be modified.</Alert>
      )}

      <AppBar position="static">
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Event" />
          <Tab label="Shift Manager" />
        </Tabs>
      </AppBar>

      <Grid container>
        {activeTab === 0 && (
          <Grid item xs={12} sm={8} md={6}>
            <Box mt={1}>
              {!eventData && <Typography variant="body2">Loading data</Typography>}
              {eventData && (
                <OverlayLoader isLoading={isSubmitting}>
                  <EventEditForm
                    defaultValues={mapStoreToFormEventEdit(eventData)}
                    eventData={eventData}
                    isCanceled={eventData.cancelled_at}
                    submitHandler={editEventHandler}
                    isMobileView={isMobileView}
                    onCancelEventButtonClick={handleEventCancel}
                  />
                </OverlayLoader>
              )}
            </Box>
          </Grid>
        )}
        {activeTab === 1 && (
          <Grid item xs={12} md={10}>
            <Box mt={1}>
              <EventAgendaView
                isMobileView={isMobileView}
                eventsList={eventSpecsEventList}
                inflight={siteEventsListEntites.inflight || siteEventsListEntites.inflight}
                rescuers={rescuersList}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EventEditView;
