import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles, Paper, Grid, FormControlLabel, TextField as TextFieldMaterial } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { Checkbox, TextField } from 'final-form-material-ui';
import Box from '@material-ui/core/Box';
import { setValue } from '../helpers/finalFormMutators';
import WeeklyHoursSelector from './WeeklyHoursSelector';
import { convertApiDataToComponentFormat } from '../helpers/weeklyHoursConverter';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import SelectPrimaryContactUser, {
  getPrimaryContactUserForm,
  getSelectPrimaryContactUserValidationErrors,
} from './SelectPrimaryContactUser';
import LocationNotes from './Common/LocationNotes';
import DropoffNotes from './Common/DropoffNotes';
import OrganizationTypeSelect from './Common/OrganizationTypeSelect';
import SubmitWithSendEmailConfirmation from './SubmitWithSendEmailConfirmation';
import AddressField from './AddressField';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import get from 'lodash/get';
import errorMessages from '../assets/errorMessages';
import RenderFormErrorText from './Form/RenderFormErrorText';
import { getFormFieldError, isFormFieldInvalid, isPhoneMaskValid } from '../helpers/validators';
import { getPhoneNumberFromMask } from '../helpers/getters';
import PhoneTypeSelect from './PhoneTypeSelect';
import { PHONE_EXT_MAX_LENGTH } from './Form/MuiFormWrapper';
import { RECEIVING_HOURS_HASH } from '../pages/Donation/DonationManage/Components/PickupsFormVariant/AllDaysTheSame/AllDaysTheSame';
import ZipCodeSelect from './ZipCodeSelect';

class ReceiverLocationForm extends Component {
  constructor(props) {
    super(props);

    const { hoursInitialValues } = props;

    this.formRef = createRef();
    this.receivingHoursRef = createRef();
    this.state = {
      selectedUser: props.user,
      weeklyHours: hoursInitialValues,
      handleCreateNewTypeFieldFlag: undefined,
      sendInvitationEmail: false,
    };
  }

  componentDidMount() {
    const { hash } = this.props;

    // Scroll to the element
    if (hash === RECEIVING_HOURS_HASH && this.receivingHoursRef?.current) {
      const yOffset = -70;
      const element = this.receivingHoursRef.current;
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    // update selectedUser after form submit
    if (get(prevProps, 'user.id') !== get(user, 'id')) {
      this.setState({ selectedUser: user });
    }
  }

  handleSubmit = values => {
    const { onSubmit } = this.props;
    const { weeklyHours, sendInvitationEmail } = this.state;

    return Bluebird
      .try(() => onSubmit({
        ...values,
        weekly_receiving_hours: weeklyHours,
        send_invitation: sendInvitationEmail,
      }))
      .then(() => snackbarHelper.success('Receiving Agency profile was updated successfully!'))
      .catch(err => {
        let formErrors = {
          [FORM_ERROR]: 'Receiving Agency update failed',
        };

        switch (err.code) {
          case errorMessages.ERR_DUPLICATED_PHONE.code:
            formErrors = {
              ...formErrors,
              primary_contact_phone: errorMessages.ERR_DUPLICATED_PHONE.message,
            };
            break;
          case errorMessages.ERR_DUPLICATED_EMAIL.code:
            formErrors = {
              ...formErrors,
              primary_contact_email: errorMessages.ERR_DUPLICATED_EMAIL.message,
            };
            break;
          default:
            errorsHelper.handleError(err);

            break;
        }

        return formErrors;
      });
  };

  setWeeklyHours = hours => {
    this.setState(state => ({
      ...state,
      weeklyHours: hours,
    }));
  };

  validate = values => {
    const { handleCreateNewTypeFieldFlag } = this.state;
    const errors = {};

    if (!values.receiver_name) {
      errors.receiver_name = 'Required';
    }

    if (!values.type_id) {
      errors.type_id = 'Required';
    }

    if(getPhoneNumberFromMask(values.phone).length > 0 && !isPhoneMaskValid(values.phone)) {
      errors.phone = errorMessages.ERR_INVALID_PHONE.message;
    }

    if (handleCreateNewTypeFieldFlag && !values.type_other) {
      errors.type_other = 'Required';
    }

    if (!values.address1) {
      errors.address1 = 'Required';
    }

    if (!values.city) {
      errors.city = 'Required';
    }

    if (!values.st) {
      errors.st = 'Required';
    }

    if (!values.zip) {
      errors.zip = 'Required';
    }

    return {
      ...errors,
      ...getSelectPrimaryContactUserValidationErrors(values),
    };
  };

  render() {
    const { selectedUser } = this.state;
    const { classes, location, hoursInitialValues, receiverTypes, receiver, showNotes } = this.props;

    const initialValues = {
      ...location,
      receiver_name: receiver.name,
      type_id: receiver.type_id,
      type_other: receiver.type_other,
      ein: receiver.ein,
      active: receiver.active,
      ...getPrimaryContactUserForm(selectedUser),
    };
    const buttonText = location ? 'Update Receiving Agency' : 'Create Receiving Agency';

    return (
      <Paper className={classes.paper} data-testid="receiver-location-edit-form">
        <Form
          mutators={{
            ...setValue,
          }}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            submitting,
            dirty,
            pristine,
            form,
            values,
          }) => (
            <form className={classes.form} onSubmit={handleSubmit} ref={this.formRef} data-testid="receiver-edit-form">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    name="receiver_name"
                    fullWidth
                    required
                    component={TextField}
                    type="text"
                    label="Receiving Agency Name"
                    autoFocus
                  />
                </Grid>

                <Grid item xs={12}>
                  <OrganizationTypeSelect
                    selectedTypeId={initialValues.type_id}
                    list={receiverTypes}
                    handleCreateNewTypeFieldFlag={value => this.setState({ handleCreateNewTypeFieldFlag: value })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field name="ein" fullWidth component={TextField} label="EIN (optional)" />
                </Grid>

                <Grid item xs={12}>
                  <AddressField
                    name="address1"
                    fullWidth
                    required
                    component={TextField}
                    type="text"
                    label="Address"
                  />
                </Grid>

                <Grid item xs={12}>
                  <AddressField
                    fullWidth
                    variant="standard"
                    name="zip"
                    required
                    formValue={values.zip}
                    onChangeZipCode={(value) => {
                      form.mutators.setValue('zip', value ? value.data.zip_code : '');
                      form.mutators.setValue('city', value ? value.data.city : '');
                      form.mutators.setValue('st', value ? value.data.state : '');
                    }}
                    showValueOnly
                    component={ZipCodeSelect}
                    type="text"
                    label="Zip Code"
                  />
                </Grid>

                <Grid item xs={12}>
                  <AddressField
                    name="city"
                    fullWidth
                    required
                    component={TextField}
                    type="text"
                    label="City"
                    disabled={!!values.zip}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AddressField
                    name="st"
                    fullWidth
                    required
                    component={TextField}
                    type="text"
                    label="State"
                    disabled={!!values.zip}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="phone"
                    render={({ input, meta }) => (
                      <InputMask
                        mask="999-999-9999"
                        value={input.value}
                        onChange={event => input.onChange(event)}
                        onFocus={event => input.onFocus(event)}
                        onBlur={event => input.onBlur(event)}
                      >
                        <TextFieldMaterial
                          name={input.name}
                          fullWidth
                          label="Phone Number, e.g.: 555-555-5555"
                          margin="normal"
                          style={{ marginTop: 0, marginBottom: 0 }}
                          error={isFormFieldInvalid(meta)}
                          helperText={isFormFieldInvalid(meta) ? getFormFieldError(meta) : null}
                        />
                      </InputMask>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AddressField
                    fullWidth
                    name="phone_ext"
                    InputProps={{
                      inputProps: {
                        maxLength: PHONE_EXT_MAX_LENGTH,
                      },
                    }}
                    component={TextField}
                    type="text"
                    label="Phone Number Extension"
                  />
                </Grid>

                <Grid item xs={12}>
                  <PhoneTypeSelect value={values.phone_type} />
                </Grid>

                <Grid item xs={12}>
                  <SelectPrimaryContactUser
                    values={values}
                    formApi={form}
                    firstNameLabel="Rescues Contact Person: First Name *"
                    lastNameLabel="Rescues Contact Person: Last Name *"
                    emailLabel="Rescues Contact Person: Email *"
                    phoneLabel="Rescues Contact Person: Phone#"
                    helpText="Select the person to contact to coordinate details about rescues"
                    showZipCodeField={false}
                    showUserSelector
                    onUserSelect={() => {
                      const elem = document.querySelector(`[name='notes']`);
                      return elem && elem.focus();
                    }}
                  />
                </Grid>

                {showNotes && (
                  <Grid item xs={12}>
                    <Box my={1} width="100%">
                      <LocationNotes tooltipLabel="These notes are like a post-it note about the Receiving Agency" />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box my={1} width="100%">
                    <DropoffNotes />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Field name="active" type="checkbox" color="primary" component={Checkbox} />}
                    label="Is Accepting Donations?"
                    labelPlacement="end"
                  />
                </Grid>

                <Grid item xs={12} ref={this.receivingHoursRef}>
                  <Field
                    name="weekly_receiving_hours"
                    render={({ input }) => (
                      <WeeklyHoursSelector
                        onChange={hours => {
                          input.onChange(hours !== null ? hours : null);
                          this.setWeeklyHours(hours);
                        }}
                        initialValues={hoursInitialValues}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SubmitWithSendEmailConfirmation
                    data-testid="submit-edit-receiver-location"
                    label={buttonText}
                    showConfirmation={
                      !values.primary_contact_id &&
                      Object.keys(getSelectPrimaryContactUserValidationErrors(values)).length === 0
                    } // invite new user?
                    disabled={pristine || !dirty || submitting}
                    className={classes.button}
                    onButtonNotSend={() => {
                      this.setState({ sendInvitationEmail: false }, () => {
                        this.formRef.current.dispatchEvent(
                          new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                          })
                        );
                      });
                    }}
                    onButtonSend={() => {
                      this.setState({ sendInvitationEmail: true }, () => {
                        this.formRef.current.dispatchEvent(
                          new Event('submit', {
                            bubbles: true,
                            cancelable: true,
                          })
                        );
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <RenderFormErrorText formState={form.getState()} />
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Paper>
    );
  }
}

ReceiverLocationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  showNotes: PropTypes.bool,
};

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    maxWidth: 500,
  },
  textWide: {
    minWidth: 480,
  },
  textState: {
    width: 60,
  },
  textZip: {
    width: 120,
  },
  button: {
    marginTop: theme.spacing(1),
  },
});

const mapStateToProps = (state, { location }) => ({
  hoursInitialValues: convertApiDataToComponentFormat(location.weekly_receiving_hours),
});

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  withStyles(styles)
)(ReceiverLocationForm);
