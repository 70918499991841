import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Card, CardHeader } from '@material-ui/core';

export const BaseCard = ({ children, ...rest }) => (
  <Card square elevation={24} {...rest}>
    {children}
  </Card>
);

export const BaseCardHeader = withStyles({
  title: {
    lineHeight: 1.2,
  },
})(({ title, ...rest }) => (
  <CardHeader titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }} {...rest} title={title} />
));
