import { Box, CircularProgress, Fade, makeStyles, Slide, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from '../../../../routes';
import frusLogo from '../../../../assets/images/frus-logo-with-title.png';
import { Colors } from '../../../../assets/theme/Colors';
import useActiveSite from '../../../../hooks/useActiveSite';

const useStyle = makeStyles(() => ({
  frusLogo: {
    width: 250,
    animation: '$bounce 1000ms 100ms ease',
  },
  '@keyframes bounce': {
    'from, to': { transform: 'scale(1, 1) translateY(0)' },
    '25%': { transform: 'scale(0.9, 1.1) translateY(-30px)' },
    '50%': { transform: 'scale(1.1, 0.9) translateY(0)' },
    '75%': { transform: 'scale(0.95, 1.05) translateY(-15px)' },
  },
}));

const ThankYouScreen = ({ showThankYouScreen }) => {
  const classes = useStyle();
  const history = useHistory();
  const [progress, setProgress] = useState(100);
  const sites = useSelector((state) => state.entities.sites.byId);
  const settings = useSelector((state) => state.entities.settings);
  const site = useActiveSite();

  useEffect(() => {
    if (showThankYouScreen) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 2));
      }, 60);
      return () => {
        clearInterval(timer);
      };
    }
    return () => null;
  }, [showThankYouScreen]);

  useEffect(() => {
    if (progress <= 0) {
      // Wait for animation to finish
      setTimeout(() => {
        history.push(generatePath(routes.mobileDashboardRescues));
      }, 250);
    }
  }, [progress]);

  // Check for custom branding setting
  const currentSite = Object.values(sites).find((s) => s.id === site?.id);
  const settingsList = Object.values(settings.byId);
  const customBrandingSetting = settingsList.find((setting) => setting.name === 'custom_branding');
  const hasCustomBranding =
    currentSite?.settings.find((setting) => setting.id === customBrandingSetting?.id)?.value === '1';

  return (
    <Slide
      direction="up"
      in={showThankYouScreen}
      mountOnEnter
      unmountOnExit
      {...(showThankYouScreen ? { timeout: 300 } : {})}
    >
      <Box
        bgcolor="background.paper"
        width="100%"
        height="100%"
        position="fixed"
        top="0"
        left="0"
        zIndex="modal"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => history.push(generatePath(routes.mobileDashboardRescues))}
      >
        <Box display="flex" flex={1} justifyContent="center" alignItems="flex-end">
          {hasCustomBranding && currentSite?.logo_url ? (
            <img alt="logo" className={classes.frusLogo} src={currentSite.logo_url} />
          ) : (
            <img alt="frus logo" className={classes.frusLogo} src={frusLogo} />
          )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h3" style={{lineHeight: 1.5}}>Thank you!</Typography>
          <Typography variant="h4">For being the rescue.</Typography>
          <Fade
            in={showThankYouScreen}
            style={{ transitionDelay: showThankYouScreen ? '1000ms' : '0ms', marginTop: '1rem' }}
            {...(showThankYouScreen ? { timeout: 700 } : {})}
          >
            <Typography variant="body2">(Click anywhere to return)</Typography>
          </Fade>
        </Box>
        <Box flex={1} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress
            variant="static"
            value={progress}
            style={{ transform: 'scaleX(-1) rotate(-90deg', color: Colors.appBar.main }}
            size={60}
            thickness={5}
          />
        </Box>
      </Box>
    </Slide>
  );
};

export default ThankYouScreen;
