import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './App.css';
import './index.css';
import './assets/scss/architectui-react-pro/base.css';
import 'intro.js/introjs.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import TagManager from 'react-gtm-module';
import { store } from './store';
import Loading from './components/Loading';
import App from './App';
import history from './history';
import * as errorsHelper from './helpers/errors';
import ContextStateWrapper from './context/ContextStateWrapper';
import { tokenExpiredErrorName } from './errors/TokenExpiredError';
import langEN from './lang/en.json';
import langES from './lang/es.json';

if (errorsHelper.shouldLogErrors()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      const url = window?.location?.href || '';

      // let's check if the URL includes "localhost", if the event is from localhost, return null to ignore it
      if (url.includes('localhost')) {
        return null;
      }

      return event;
    },
  });
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

if (process.env.REACT_APP_GTM_ID !== null) {
  TagManager.initialize(tagManagerArgs);
}

// whitelist of supported app languages
const messages = {
  en: langEN,
  es: langES,
};
const browserLang = navigator.language.split(/[-_]/)[0];

// set moment's locale based on the browser language
moment.locale(browserLang);

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <ContextStateWrapper>
        <PersistGate loading={<Loading />} persistor={persistStore(store)}>
          <Router history={history}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <IntlProvider
                messages={messages[browserLang] ? messages[browserLang] : messages['en']}
                locale={messages[browserLang] ? browserLang : 'en'}
                defaultLocale="en"
              >
                <App />
              </IntlProvider>
            </MuiPickersUtilsProvider>
          </Router>
        </PersistGate>
      </ContextStateWrapper>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

// register service worker for A2HS and offline mode
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js').then(() => {
    console.log('Service Worker Registered');
  });
}

window.addEventListener('unhandledrejection', e => {
  if (e.reason === tokenExpiredErrorName) {
    e.preventDefault();
  }
});
