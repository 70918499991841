import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Breadcrumbs, Paper, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../routes';
import * as donorsActions from '../actions/donors';
import * as foodDonorsActions from '../actions/foodDonors';
import OverlayLoader from '../components/OverlayLoader';
import FoodDonorAddForm from '../components/FoodDonorAddForm';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import * as notificationsHelper from '../helpers/notifications';
import { fetchUmbrellaFoodDonorsIfNeeded } from '../actions/umbrellaFoodDonors';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
}));

const FoodDonorAddView = ({ isMobileView, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notificationsContext = useContext(NotificationsContext);
  const site = useSelector(state => state.app.site);
  const donorTypes = useSelector(state => state.entities.donors.types);
  const donorTypesList = donorTypes.allIds.map(donorTypeId => donorTypes.byId[donorTypeId]);
  const umbrellaFoodDonorsEntities = useSelector(state => state.entities.umbrellaFoodDonors);
  const umbrellaFoodDonorsList = Object.values(umbrellaFoodDonorsEntities.byId);
  useEffect(() => {
    dispatch(donorsActions.fetchDonorTypes());
    dispatch(fetchUmbrellaFoodDonorsIfNeeded());
  }, []);

  const onCreateFoodDonor = data => Bluebird
    .try(() => dispatch(foodDonorsActions.createFoodDonor({
      ...data,
      site_id: site.id,
    })))
    .then(() => {
      notificationsHelper.addNotification('Food Donor added successfully!', notificationsContext);

      if (isMobileView) {
        return history.push(routes.index);
      }

      return history.push(routes.foodDonors);
    });

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        {isMobileView ? (
          <Typography color="textPrimary">Food Donors</Typography>
        ) : (
          <Link color="inherit" to={routes.foodDonors}>
            Food Donors
          </Link>
        )}

        <Typography color="textPrimary">Add a Food Donor</Typography>
      </Breadcrumbs>

      <OverlayLoader isLoading={donorTypes.inflight || umbrellaFoodDonorsEntities.inflight}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <FoodDonorAddForm
                isMobileView={isMobileView}
                createFoodDonor={onCreateFoodDonor}
                donorTypes={donorTypesList}
                umbrellaFoodDonors={umbrellaFoodDonorsList}
              />
            </Grid>
          </Grid>
        </Paper>
      </OverlayLoader>
    </>
  );
};

export default FoodDonorAddView;
