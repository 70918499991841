import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: '28px',
  },
}));

const CategoryButton = ({ variant, disabled, onCategoryClick, name = '', children }) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant instanceof Function ? variant() : variant || 'outlined'}
      size="small"
      color="secondary"
      disabled={disabled}
      onClick={onCategoryClick}
      className={classes.button}
      data-testid={name}
    >
      {children}
    </Button>
  );
};

export default CategoryButton;
