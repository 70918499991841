import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const PagePaper = withStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    backgroundColor: palette.background.paper,
    padding: spacing(1),
    marginBottom: spacing(3),
    [breakpoints.up(600 + spacing(3) * 2)]: {
      padding: spacing(2),
    },
  },
}))(({ children, classes, ...rest }) => (
  <Box boxShadow={25} className={classes.root} {...rest}>
    {children}
  </Box>
));

export default PagePaper;
