import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Divider } from '@material-ui/core';
import { useFormContext, useFieldArray } from 'react-hook-form';
import moment from 'moment';
import PagePaper from '../../../../../components/Common/PagePaper';
import EventShiftRowForm from './EventShiftRowForm';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import { confirmAlert } from 'react-confirm-alert';
import { getRescuersSelectOptions } from '../../../../../helpers/getters';
import { sortAlphabetically } from '../../../../../helpers/sorters';
import useActiveSite from '../../../../../hooks/useActiveSite';
import { fetchSiteRescuers } from '../../../../../actions/sites';

const EventShiftsForm = ({ isEditView, isMobileView, eventShiftsData = [] }) => {
  const { control, getValues } = useFormContext();
  const dispatch = useDispatch();
  const { fields: shifts, remove: removeShift, append: appendShift } = useFieldArray({
    control,
    name: 'shifts',
    keyName: 'uniqId',
  });
  const activeSite = useActiveSite();
  const rescuersEntities = useSelector(state => state.entities.sites.rescuers.bySiteId[activeSite.id]);
  const rescuersList = getRescuersSelectOptions(rescuersEntities || []).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );

  useEffect(() => {
    dispatch(fetchSiteRescuers(activeSite.id));
  }, []);

  const handleShiftRemove = index => {
    if (!shifts[index].id) {
      return removeShift(index);
    }

    confirmAlert({
      title: 'Are you sure you want to delete this shift?',
      message: 'All assigned volunteers will be removed.',
      buttons: [
        {
          label: 'No',
          color: 'primary',
          onClick: () => {},
        },
        {
          label: 'Delete',
          color: 'primary',
          onClick: () => removeShift(index),
        },
      ],
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog buttons={buttons} closeDialog={onClose} title={title} message={message} />
      ),
    });
  };

  const addShift = () => {
    const formValues = getValues();
    appendShift({
      begin: formValues.begin || moment(8, 'HH'),
      end: formValues.end || moment(12, 'HH'),
    });
    setTimeout(() => window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' }), 100);
  };

  return (
    <PagePaper style={{ marginBottom: isMobileView ? 120 : 8 }}>
      <Typography variant="h6" gutterBottom>
        Shifts
      </Typography>
      <Box display="flex" flexDirection="column">
        {shifts.map((shift, index, { length }) => (
          <React.Fragment key={shift.uniqId}>
            <EventShiftRowForm
              eventShiftData={eventShiftsData[index]}
              index={index}
              isEditView={isEditView}
              shift={shift}
              allowAdd={index + 1 === length}
              allowDelete={index !== 0 || length > 1}
              isMobileView={isMobileView}
              handleAdd={addShift}
              handleRemove={() => handleShiftRemove(index)}
              rescuersList={rescuersList}
            />
            {length - 1 !== index && <Divider style={{ margin: '16px 0' }} />}
          </React.Fragment>
        ))}
      </Box>
    </PagePaper>
  );
};

export default EventShiftsForm;
