import React from 'react';
import { Typography } from '@material-ui/core';
import TableSkeletonLoader from '../../../../../components/Common/TableSkeletonLoader';
import RescuerUpcomingRescuesTable from './RescuerUpcomingRescuesTable';

const RescuerUpcomingRescues = ({ upcomingRescues, user, isLoading = false, onCellClick }) => {

  if (upcomingRescues.length === 0 && !isLoading) {
    return (
      <Typography color="textSecondary" variant="body1">
        You have no upcoming rescues.
      </Typography>
    );
  }

  if (isLoading) {
    return <TableSkeletonLoader cols={7} rows={3} />;
  }

  return (
    <RescuerUpcomingRescuesTable
      tableId="#rescuer-dashboard-desktop/upcoming-rescues"
      rescues={upcomingRescues}
      user={user}
      onCellClick={onCellClick}
    />
  );
};

export default RescuerUpcomingRescues;
