import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { getMuiTableDataIndex } from '../helpers/getters';
import { getRescuePickupLocationFullName } from '../helpers/RescuesHelper';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import ButtonWithLoading from './ButtonWithLoading';
import RescuesTableStatusBox from './Common/RescuesTableStatusBox';
import { RESCUE_CLOSED, RESCUE_CLOSED_NO_SHOW, RESCUE_NOT_CLOSED } from './PastRescuesTable';

const BulkEditReviewAndSave = ({
  onExit,
  onSave,
  dialogOpen,
  editedRescues,
  rescues,
  showSiteColumn = false,
  isLoading,
}) => {
  const editedRescuesPreviousData = rescues.filter((rescue) => editedRescues.some((r) => r.rescue_id === rescue.id));

  const rescuesData =
    editedRescuesPreviousData &&
    editedRescuesPreviousData.map((rescue) => {
      const editedRescue = editedRescues.find((r) => r.rescue_id === rescue.id);
      return { ...rescue, newData: editedRescue };
    });

  const dateCustomBodyRender = (value, tableMeta ) => {
    const formattedDate = moment(value, 'YYYYMMDD').format('MM/DD/YYYY');

    const rescue = rescuesData[getMuiTableDataIndex(tableMeta)];

    return (
      <>
        <span>{formattedDate}</span>

        <RescuesTableStatusBox rescue={rescue} />
      </>
    );
  };

  const locationCustomBodyRender = (value, tableMeta) => {
    const rescue = rescuesData[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescuePickupLocationFullName = getRescuePickupLocationFullName(rescue) || '';

    return rescuePickupLocationFullName;
  };

  const editsCustomBodyRender = (value, tableMeta) => {
    const rescue = rescuesData[getMuiTableDataIndex(tableMeta)];
    const edits = [];
    if ('status' in value) {
      const oldStatus = rescue.completed !== null ? (rescue.completed ? RESCUE_CLOSED: RESCUE_CLOSED_NO_SHOW) : RESCUE_NOT_CLOSED;
      edits.push({ old: oldStatus, new: value.status, fieldName: 'Status' });
    }

    if (value.receiver) {
      edits.push({ old: rescue.receiver, new: value.receiver, fieldName: 'Receiving Agency' });
    }

    if ('lbs' in value) {
      edits.push({ old: Math.round(rescue.weight_lbs), new: value.lbs, fieldName: 'Weight' });
    }

    const modifiedFields = edits.reduce(
      (acc, field) => [
        ...acc,
        <Box key={field.fieldName}>
          <Box py={1}>
            <u>{field.fieldName}</u>:
          </Box>
          <Box>
            <strike>{field.old}</strike> <strong>{field.new}</strong>
          </Box>
        </Box>,
      ],
      []
    );

    return <Box>{modifiedFields}</Box>;
  };

  return (
    <Dialog open={dialogOpen} maxWidth="md" fullWidth onBackdropClick={onExit}>
      <DialogTitle>Review and Save</DialogTitle>
      <DialogContent>
        <BaseMUIDataTable
          isLoading={!rescuesData.length}
          tableId="#pastrescues/editedRescues"
          data={rescuesData}
          columns={[
            {
              name: 'site',
              label: 'Site',
              options: {
                forceDisplay: true,
                display: showSiteColumn ? true : 'excluded',
                customFilterListOptions: {
                  render: (value) => `Site: ${value}`,
                },
              },
            },
            {
              name: 'date',
              label: 'Date',
              options: {
                forceDisplay: true,
                customBodyRender: (value, tableMeta) => dateCustomBodyRender(value, tableMeta),
              },
            },
            {
              name: 'location',
              label: 'Food Donor',
              options: {
                forceDisplay: true,
                customBodyRender: (value, tableMeta) => locationCustomBodyRender(value, tableMeta),
              },
            },
            {
              name: 'newData',
              label: 'Edits',
              options: {
                forceDisplay: true,
                customBodyRender: (value, tableMeta) => editsCustomBodyRender(value, tableMeta),
              },
            },
          ]}
          options={{
            sortOrder: {
              name: 'date',
              direction: 'desc',
            },
            sort: false,
            filter: false,
            download: false,
            search: false,
            viewColumns: false,
            pagination: false,
            elevation: 0,
            selectableRows: 'none',
            responsive: 'simple',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onExit}>Back</Button>
        <ButtonWithLoading isLoading={isLoading} onClick={() => onSave(rescuesData)}>
          Save
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
)};

export default BulkEditReviewAndSave;
