import React, { useEffect } from 'react';
import Description from '../Components/Description';
import Schedule from '../Components/Schedule';
import Bluebird from 'bluebird';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  RotateLeft as RotateLeftIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRescueSizesIfNeeded } from '../../../../actions/rescueSizes';
import {
  fetchSiteDonorsLocationsIfNeeded,
  fetchSitePickupLocationsIfNeeded,
  fetchSiteReceiversIfNeeded,
  fetchSiteRescuersIfNeeded,
} from '../../../../actions/sites';
import useActiveSite from '../../../../hooks/useActiveSite';
import {
  diffCreateDonation,
  diffDonation,
  DONATION_TYPE_SD,
  saveDonation,
  setDonationDiffInflight,
} from '../../../../actions/donationNew';
import DonationContext from '../../../../context/DonationContext/DonationContext';
import useDonationManageActions from '../../../../hooks/useDonationManageActions';
import Pickups from '../Components/Pickups';
import Calendar from '../Components/Calendar';
import { donationManageActions } from '../../../../helpers/donations';
import OverlayLoader from '../../../../components/OverlayLoader';
import useNotificationService from '../../../../hooks/useNotificationService';
import { generatePath, Link } from 'react-router-dom';
import routes from '../../../../routes';
import usePermission from '../../../../hooks/usePermission';
import ACLService from '../../../../services/acl';
import { confirmAlert } from 'react-confirm-alert';
import * as errorsHelper from '../../../../helpers/errors';
import * as donationsActions from '../../../../actions/donations';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { fetchSystemSettingsIfNeeded } from '../../../../actions/systemSettings';
import errorMessages from '../../../../assets/errorMessages';
import { validateDonation } from '../donationValidator';
import { fetchReceiversIfNeeded } from '../../../../actions/receivers';

const useStyles = makeStyles(({ typography, spacing, breakpoints }) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    display: 'flex',
    columnGap: spacing(1),
    alignItems: 'baseline',
  },
  buttonsFlex: {
    marginTop: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  buttonsRow: {
    display: 'flex',
    gridColumnGap: '8px',
    [breakpoints.only('xs')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
    [breakpoints.only('md')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
  },
}));

const AGENDA_DESCRIPTION = 'Description';
const AGENDA_SCHEDULE = 'Schedule';

const hasDescriptionValidationErrors = errors => ['lbs'].some(field => errors.includes(field));

const hasScheduleValidationErrors = errors => errors.length > 0 && !hasDescriptionValidationErrors(errors);

const DonationEdit = ({ isMobileView, match, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const [expanded, setExpanded] = React.useState(AGENDA_SCHEDULE);
  const editDonationId = parseInt(match.params.donationId, 10);
  const rescueSizesInflight = useSelector(state => state.entities.rescueSizes.inflight);
  const pickupLocationsInflight = useSelector(state => state.entities.sites.pickupLocations.inflight);
  const donationData = useSelector(state => state.ui.donation_new.draft);
  const currentDonationState = useSelector(state => state.ui.donation_new.currentState);
  const futureDonationState = useSelector(state => state.ui.donation_new.futureState);
  const diffInflight = useSelector(state => state.ui.donation_new.diffInflight);
  const donationFormErrors = useSelector(state => state.ui.donation_new.errors);
  const { addErrorNotification, addSuccessNotification } = useNotificationService();
  const hasFoodDonorsList = usePermission({
    resource: ACLService.resources.foodDonorsList,
  });

  const handleDeleteDonation = () => {
    let fallbackUrl;
    if (isMobileView) {
      fallbackUrl = generatePath(routes.index);
    } else {
      fallbackUrl = generatePath(routes.donations);
      if (!hasFoodDonorsList) {
        fallbackUrl = generatePath(routes.foodDonor, {
          foodDonorId: donationData.pickup_location.id,
        });
      }
    }

    confirmAlert({
      title: 'Deleting this donation will make changes to the schedule.',
      message: 'Please confirm or cancel.',
      buttons: [
        {
          label: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          startIcon: <ClearIcon />,
        },
        {
          label: 'Yes, delete',
          color: 'primary',
          startIcon: <DeleteIcon />,
          onClick: () =>
            Bluebird.try(() => dispatch(donationsActions.deleteDonation(editDonationId)))
              .then(() => addSuccessNotification('Donation has been deleted successfully.'))
              .then(() => history.push(fallbackUrl))
              .catch(err => errorsHelper.handleError(err, err.message || 'Donation delete failed')),
        },
      ],
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog buttons={buttons} closeDialog={onClose} title={title} message={message} />
      ),
    });
  };

  const {
    undoLastAction,
    clearDonation,
    redoLastAction,
    resetDonation,

    setError,
    removeError,

    setDescription,
    setLBSValue,
    setAddFoodTypes,
    setRemoveFoodTypes,
    setFoodTypeOther,
    setRescueSize,
    setFoodSize,
    setAddDayOfWeek,
    setRemoveDayOfWeek,

    setAddDayOfMonth,
    setDayOfMonthDay,
    setDayOfMonthOrdinal,
    setRemoveDayOfMonth,

    setStartDate,
    setEndDate,
    setPauseDate,
    setResumeDate,
    setFrequency,
    setAppendPickup,
    setRemovePickup,
    setPickupReceiver,
    setPickupAdopter,
    setPickupRescuer,
    setPickupRescuerNotes,
    setPickupBegin,
    setPickupEnd,
    setAllDaysTheSame,
    clearAllDaysTheSame,
    setAllPickupsTheSame,
    clearAllPickupsTheSame,

    setRescuePickupBegin,
    setRescuePickupEnd,
    setRescueRescuerNotes,
    setRescueCanceller,
    setRescueRescuer,
    setNationalDonation,
  } = useDonationManageActions();

  const handleAccordionChange = panel => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : expanded === AGENDA_DESCRIPTION ? AGENDA_SCHEDULE : AGENDA_DESCRIPTION);

  const triggerFormChange = () => {
    return Bluebird.try(() => dispatch(diffDonation(editDonationId))).catch(e => {
      addErrorNotification(e, e.message || 'Unknown error during modifying donation');
      dispatch(setDonationDiffInflight(false));
      undoLastAction();
    });
  };

  const createDraftDonation = () => {
    return Bluebird.try(() => dispatch(diffCreateDonation(editDonationId))).catch(e => {
      if (e.statusCode === 404) {
        addErrorNotification(e, 'Donation not found');
        return history.push(generatePath(isMobileView ? routes.index : routes.donations));
      }

      addErrorNotification(e, e.message || 'Unknown error during modifying donation');
      dispatch(setDonationDiffInflight(false));
      undoLastAction();
    });
  };

  useEffect(() => {
    createDraftDonation();
    dispatch(fetchSiteReceiversIfNeeded(activeSite.id));
    dispatch(fetchSiteDonorsLocationsIfNeeded(activeSite.id));
    dispatch(fetchSitePickupLocationsIfNeeded(activeSite.id));
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSiteRescuersIfNeeded(activeSite.id));
    dispatch(fetchSystemSettingsIfNeeded()); // used in LBSSelector
    dispatch(fetchReceiversIfNeeded());

    return () => clearDonation();
  }, [dispatch]);

  const onSubmit = async () => {
    try {
      const validation = validateDonation(donationData);

      if (!validation.isValid) {
        return Object.keys(validation.errors).forEach(error => setError(error, validation.errors[error]));
      }
      await dispatch(saveDonation(editDonationId, donationData.location.id, currentDonationState));
      addSuccessNotification('Donation saved!');
    } catch (e) {
      dispatch(setDonationDiffInflight(false));
      return addErrorNotification(e, 'Unable to save donation');
    }
  };

  const handleFieldChange = (actionName, fieldValue) => {
    switch (actionName) {
      case donationManageActions.set_description:
        setDescription(fieldValue);
        break;

      case donationManageActions.set_lbs_value:
        setLBSValue(fieldValue);
        if (fieldValue === '') {
          setError('lbs', errorMessages.REQUIRED.message);
        } else {
          removeError('lbs');
        }
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_size:
        setRescueSize(fieldValue);
        break;

      case donationManageActions.set_food_size:
        setFoodSize(fieldValue);
        break;

      case donationManageActions.add_food_type:
        setAddFoodTypes(fieldValue);
        break;

      case donationManageActions.remove_food_type:
        setRemoveFoodTypes(fieldValue);
        break;

      case donationManageActions.set_food_type_other:
        setFoodTypeOther(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_frequency:
        setFrequency(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_week:
        setAddDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_week:
        setRemoveDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_month:
        setAddDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_day:
        setDayOfMonthDay(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_ordinal:
        setDayOfMonthOrdinal(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_month:
        setRemoveDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_start_date:
        setStartDate({
          startDate: fieldValue,
        });
        triggerFormChange();
        break;

      case donationManageActions.set_end_date:
        setEndDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pause_date:
        setPauseDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_resume_date:
        setResumeDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_all_days_are_the_same:
        setAllDaysTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_days_are_the_same:
        clearAllDaysTheSame();
        triggerFormChange();

        break;
      case donationManageActions.set_all_pickups_are_the_same:
        setAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_pickups_are_the_same:
        clearAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.add_pickup:
        setAppendPickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_pickup:
        setRemovePickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_receiver:
        setPickupReceiver(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_adopter:
        setPickupAdopter(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_rescuer:
        setPickupRescuer(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_rescuer_notes:
        setPickupRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_begin:
        setPickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_end:
        setPickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_begin:
        setRescuePickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_end:
        setRescuePickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer_notes:
        setRescueRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_canceller:
        setRescueCanceller(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer:
        setRescueRescuer(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_national_donation:
        setNationalDonation(fieldValue);
        break;

      default:
        console.error(`---->>>> #Unknown donation field "${actionName}" with given value "${fieldValue}"`);
    }
  };

  if (donationData === null || donationData.inflight === true || rescueSizesInflight || pickupLocationsInflight) {
    return 'loading...';
  }

  const renderDonationButtons = () => (
    <Box className={classes.buttonsFlex}>
      <Box className={classes.buttonsRow}>
        <Button fullWidth color="primary" variant="contained" type="button" onClick={onSubmit} startIcon={<SaveIcon />}>
          Save {expanded}
        </Button>

        <Button
          fullWidth
          color="secondary"
          variant="contained"
          type="button"
          startIcon={<DeleteIcon />}
          onClick={handleDeleteDonation}
        >
          Delete donation
        </Button>
      </Box>
      <Box className={classes.buttonsRow}>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          type="button"
          startIcon={<RotateLeftIcon />}
          disabled={currentDonationState.length === 0}
          onClick={() => {
            resetDonation();
            triggerFormChange();
          }}
        >
          Reset
        </Button>
        <Button
          fullWidth
          startIcon={<UndoIcon />}
          color="secondary"
          variant="contained"
          disabled={currentDonationState.length === 0}
          onClick={() => {
            undoLastAction();
            triggerFormChange();
          }}
        >
          Undo
        </Button>
        <Button
          fullWidth
          startIcon={<RedoIcon />}
          color="secondary"
          variant="contained"
          disabled={futureDonationState.length === 0}
          onClick={() => {
            redoLastAction();
            triggerFormChange();
          }}
        >
          Redo
        </Button>
      </Box>
    </Box>
  );

  return (
    <DonationContext.Provider
      value={{
        donationType: DONATION_TYPE_SD,
        onFieldChange: handleFieldChange,
        hasFoodDonorDefined: donationData && !!donationData.location,
        isLoading: diffInflight,
        isNew: false,
        isMobileView: isMobileView,
        errors: donationFormErrors,
      }}
    >
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        {!isMobileView && hasFoodDonorsList ? (
          <Link color="inherit" to={routes.foodDonors}>
            Food Donors
          </Link>
        ) : (
          <Typography color="textPrimary">Food Donors</Typography>
        )}

        {isMobileView ? (
          <Typography color="textPrimary">{donationData.location.name}</Typography>
        ) : (
          <Link
            color="inherit"
            to={generatePath(routes.foodDonor, {
              foodDonorId: donationData.location.id,
            })}
          >
            {donationData.location.name}
          </Link>
        )}

        <Typography color="textPrimary">Edit Donation</Typography>
      </Breadcrumbs>

      <Grid container spacing={3}>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} md={6} lg={8}>
          <Grid item xs={12}>
            <Accordion
              elevation={25}
              expanded={expanded === AGENDA_DESCRIPTION}
              onChange={handleAccordionChange(AGENDA_DESCRIPTION)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-description"
                id="description-header"
              >
                <Typography className={classes.heading}>
                  Description
                  {hasDescriptionValidationErrors(Object.keys(donationFormErrors)) && (
                    <Typography component="span" color="error">
                      Missing info
                    </Typography>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Description />
              </AccordionDetails>
            </Accordion>
            <Accordion
              elevation={25}
              expanded={expanded === AGENDA_SCHEDULE}
              onChange={handleAccordionChange(AGENDA_SCHEDULE)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-schedule" id="schedule-header">
                <Typography className={classes.heading}>Schedule</Typography>
                {hasScheduleValidationErrors(Object.keys(donationFormErrors)) && (
                  <Typography component="span" color="error">
                    Validation error
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <OverlayLoader isLoading={diffInflight} wrapperStyles={{ width: '100%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Schedule />
                    </Grid>
                    <Grid item xs={12}>
                      <Pickups />
                    </Grid>
                  </Grid>
                </OverlayLoader>
              </AccordionDetails>
            </Accordion>
            {!isMobileView && renderDonationButtons()}
          </Grid>
        </Grid>
        <Grid item xs>
          <Card square elevation={25}>
            <CardContent>
              <Calendar />
            </CardContent>
          </Card>
        </Grid>

        {isMobileView && (
          <Grid item xs>
            {renderDonationButtons()}
          </Grid>
        )}
      </Grid>
    </DonationContext.Provider>
  );
};

export default DonationEdit;
