import React, { Component } from 'react';
import { Link } from '@material-ui/core';
import moment from 'moment';
import { Delete as DeleteIcon } from '@material-ui/icons';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import { getUserFullName } from '../helpers/user';
import { isUndefinedOrNull } from '../helpers';
import { userRegistrationFilterOptions } from '../helpers/MuiTableCustomFilters';
import { formatPhoneNumber, formatCsvFileName } from '../helpers/formatters';

class ReceiversTable extends Component {
  render() {
    const {
      receiversList,
      onRowClick,
      onPrimaryContactUserClick,
      tableId,
      customToolbar = () => null,
      onDeleteClick,
      isAdmin,
      isLoading,
      siteRescues = [],
    } = this.props;

    const primaryContactCustomBodyRender = (value, tableMeta, csv = false) => {
      const primaryContactFirstName = receiversList[getMuiTableDataIndex(tableMeta)].primary_contact_firstname;
      const primaryContactLastName = receiversList[getMuiTableDataIndex(tableMeta)].primary_contact_lastname;
  
      if (!primaryContactFirstName && !primaryContactLastName) {
        return null;
      }
      
      const userFullName = getUserFullName({
        firstname: primaryContactFirstName,
        lastname: primaryContactLastName,
      });
      
      if (csv) {
        return userFullName;
      }
  
      return (
        <Link
          href="#"
          onClick={event => {
            event.preventDefault();
        
            return onPrimaryContactUserClick(receiversList[getMuiTableDataIndex(tableMeta)].primary_contact_id);
          }}
        >
          {userFullName}
        </Link>
      );
    };
    
    const primaryContactPhoneCustomBodyRender = (value, csv = false) => {
      if (!value) {
        return null;
      }
      
      const formattedPhoneNumber = formatPhoneNumber(value);
      
      if (csv) {
        return formattedPhoneNumber;
      }
      
      return <Link href={`tel:${value}`}>
        {formattedPhoneNumber}
      </Link>;
    };
    
    const activeCustomBodyRender = value => value === 1 ? 'Accepting Donations' : 'Not Accepting Donations';
    
    const createdAtCustomBodyRender = value => !isUndefinedOrNull(value)
      ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      : null;
    
    const actionsCustomBodyRender = (tableMeta) => {
      const receiverId = tableMeta.rowData[0];
      const receiverName = tableMeta.rowData[1];
      const receivierRescues = siteRescues.filter((rescue) => rescue.receiver_id === receiverId);
      return (
        <TableActionsButtonsWrapper>
          <TableActionButton
            title="Edit Receiving Agency"
            onClick={() => onRowClick(tableMeta.rowData[0])}
            data-testid="edit-receiver-button"
          />
          {isAdmin && (
            <TableActionButton
              disabled={!!receivierRescues.length}
              title={
                receivierRescues.length
                  ? `This Receiving Agency is associated with upcoming rescues and cannot be deleted. Please remove any upcoming rescues associated with this agency to continue.`
                  : 'Delete Receiving Agency'
              }
              icon={DeleteIcon}
              onClick={() => onDeleteClick(tableMeta.rowData[0], receiverName)}
            />
          )}
        </TableActionsButtonsWrapper>
      );
    };

    return (
      <BaseMUIDataTable
        isLoading={isLoading}
        tableId={tableId}
        title="Receiving Agencies"
        data={receiversList}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              display: false,
            },
          },
          {
            name: 'name',
            label: 'Name',
          },
          {
            name: 'address1',
            label: 'Address',
          },
          {
            name: 'city',
            label: 'City',
          },
          {
            name: 'st',
            label: 'State',
          },
          {
            name: 'zip',
            label: 'Zip Code',
          },
          {
            name: 'primary_contact',
            label: 'Contact',
            options: {
              customBodyRender: (value, tableMeta) => primaryContactCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => primaryContactCustomBodyRender(value, tableMeta, true),
            },
          },
          {
            name: 'primary_contact_email',
            label: 'Contact email',
            options: {
              filter: false,
              searchable: true,
              display: false,
              sort: false,
              customBodyRender: (value, tableMeta) => {
                const primaryContactEmail = receiversList[getMuiTableDataIndex(tableMeta)].primary_contact_email;

                if (!primaryContactEmail) {
                  return null;
                }

                return (
                  <Link href={`mailto:${primaryContactEmail}`}>
                    {primaryContactEmail}
                  </Link>
                );
              },
            },
          },
          {
            name: 'primary_contact_phone',
            label: 'Contact phone',
            options: {
              filter: false,
              searchable: true,
              display: false,
              sort: false,
              customBodyRender: value => primaryContactPhoneCustomBodyRender(value),
              customBodyRenderCSV: value => primaryContactPhoneCustomBodyRender(value, true),
            },
          },
          {
            name: 'active',
            label: 'Active',
            options: {
              display: false,
              filterList: ['Accepting Donations'],
              filterOptions: {
                names: ['Accepting Donations', 'Not Accepting Donations']
              },
              customBodyRender: value => activeCustomBodyRender(value),
              customBodyRenderCSV: value => activeCustomBodyRender(value),
            },
          },
          {
            name: 'created_at',
            label: 'Completed Registration',
            options: {
              filter: true,
              display: false,
              customBodyRender: value => createdAtCustomBodyRender(value),
              customBodyRenderCSV: value => createdAtCustomBodyRender(value),
              ...userRegistrationFilterOptions,
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              filter: false,
              sort: false,
              display: true,
              customBodyRender: (value, tableMeta) => actionsCustomBodyRender(tableMeta),
            },
          },
        ]}
        options={{
          customToolbar: customToolbar,
          responsive: 'simple',
          selectableRows: 'none',
          downloadOptions: {
            filename: formatCsvFileName('Receiving Agencies'),
          },
        }}
      />
    );
  }
}

export default ReceiversTable;
