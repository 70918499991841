import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { Field, Form } from 'react-final-form';
import { Box, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';

import DateAndGranularityPicker, { dateRangeOptionsList } from './DateAndGranularityPicker/DateAndGranularityPicker';
import ReportLegend, { shouldShowLegend } from '../containers/ReportLegend';
import { getEarliestSiteDate } from '../helpers/getters';
import { Colors } from '../assets/theme/Colors';

const GrowthReportForm = ({ isAdmin, sitesList, onSubmit, defaultValues, fetchReport }) => {
  const [selectedSite, setSelectedSite] = useState(defaultValues.sites);
  const [dateRange, setDateRange] = useState(defaultValues.dateRange);
  const [supressInactiveSites, setSupressInactiveSites] = useState(defaultValues.supressInactiveSites);
  const [completedRegistration, setCompletedRegistration] = useState(defaultValues.completedRegistration);

  const handleSubmit = values => {
    onSubmit({
      sites: values.sites,
      startDate: values.startDate ? values.startDate : null,
      endDate: values.endDate ? values.endDate : null,
      granularity: values.granularity ? values.granularity : null,
      period: values.period ? values.period : null,
      showRescueSizeLegend: shouldShowLegend(defaultValues.showRescueSizeLegend),
      supressInactiveSites: values.supressInactiveSites ? values.supressInactiveSites : null,
      completedRegistration: values.completedRegistration ? values.completedRegistration : null,
    });
  };

  const handleChangeSuppressInactiveSites = event => {
    setSupressInactiveSites(event.target.checked);

    return handleSubmit({
      sites: selectedSite || [],
      startDate: dateRange.startDate ? dateRange.startDate : null,
      endDate: dateRange.endDate ? dateRange.endDate : null,
      granularity: dateRange.granularity ? dateRange.granularity : null,
      period: dateRange.period ? dateRange.period : null,
      showRescueSizeLegend: shouldShowLegend(defaultValues.showRescueSizeLegend),
      supressInactiveSites: event.target.checked ? event.target.checked : null,
      completedRegistration: completedRegistration || null,
    });
  };

  const handleChangeCompletedRegistration = event => {
    setCompletedRegistration(event.target.checked);

    return handleSubmit({
      sites: selectedSite || [],
      startDate: dateRange.startDate ? dateRange.startDate : null,
      endDate: dateRange.endDate ? dateRange.endDate : null,
      granularity: dateRange.granularity ? dateRange.granularity : null,
      period: dateRange.period ? dateRange.period : null,
      showRescueSizeLegend: shouldShowLegend(defaultValues.showRescueSizeLegend),
      supressInactiveSites: supressInactiveSites ? supressInactiveSites : null,
      completedRegistration: event.target.checked ? event.target.checked : null,
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={() => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box display="flex" alignItems="center" gridGap="16px">
            <Box display="flex" alignItems="center" gridGap="5px">
              <FilterListIcon htmlColor={Colors.inputBorder} />
              <span>Filters</span>
            </Box>
            {(isAdmin || sitesList.length > 1) && (
              <Box width="100%">
                <Field
                  name="site_id"
                  render={({ input }) => (
                    <ReactSelect
                      isMulti
                      name={input.name}
                      placeholder="Select site(s) or leave blank to show data for all sites"
                      value={selectedSite}
                      options={[{ id: -1, name: 'All' }, ...sitesList]}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      onChange={option => {
                        let choices = option !== null ? option : [];
                        if (choices.some(site => site.id === -1)) {
                          choices = [];
                        }
                        input.onChange(choices);

                        setSelectedSite(choices);

                        return handleSubmit({
                          sites: choices,
                          startDate: dateRange.startDate,
                          endDate: dateRange.endDate,
                          granularity: dateRange.granularity,
                          period: dateRange.period,
                          supressInactiveSites: supressInactiveSites,
                          completedRegistration: completedRegistration,
                        });
                      }}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                      styles={{
                        menu: provided => ({
                          ...provided,
                          zIndex: 101,
                        }),
                      }}
                    />
                  )}
                />
              </Box>
            )}
          </Box>

          <Box my={2} style={{ marginBottom: 0 }}>
            <DateAndGranularityPicker
              customDateRangeValue={defaultValues.dateRange}
              defaultDateRange={defaultValues.period}
              defaultGranularity={defaultValues.granularity}
              dateRangeOptions={{
                lastWeek: dateRangeOptionsList.lastWeek,
                last7Days: dateRangeOptionsList.last7Days,
                last90Days: dateRangeOptionsList.last90Days,
                thisMTD: dateRangeOptionsList.thisMTD,
                thisQTD: dateRangeOptionsList.thisQTD,
                thisYTD: dateRangeOptionsList.thisYTD,
              }}
              showCustomDateRange
              customDateRangeOptions={{
                maxDate: new Date(),
                minDate: getEarliestSiteDate(selectedSite, sitesList),
              }}
              returnDateObject={false}
              onChange={data => {
                const dateRange = {
                  startDate: data.dateRange ? data.dateRange.startDate : null,
                  endDate: data.dateRange ? data.dateRange.endDate : null,
                  granularity: data.granularity ? data.granularity : null,
                  period: data.period,
                };

                setDateRange(dateRange);

                return handleSubmit({
                  sites: selectedSite,
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                  granularity: dateRange.granularity,
                  period: dateRange.period,
                  supressInactiveSites: supressInactiveSites,
                  completedRegistration: completedRegistration,
                });
              }}
            />
          </Box>

          {isAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={supressInactiveSites || false}
                  onChange={handleChangeSuppressInactiveSites}
                  value="supress_inactive_sites"
                  style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
                />
              }
              label="Enabled Sites Only"
            />
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={completedRegistration || false}
                onChange={handleChangeCompletedRegistration}
                value="completed_registration"
                style={{ paddingTop: 2.5, paddingBottom: 2.5 }}
              />
            }
            label="Registration Complete"
          />

          <Box textAlign="right" my={2}>
            <Button variant="contained" color="primary" onClick={fetchReport}>
              Run
            </Button>
          </Box>

          <ReportLegend
            label="Rescue Size legend"
            isOpen={defaultValues.showRescueSizeLegend}
            onExpandChange={state =>
              onSubmit({
                ...defaultValues,
                ...defaultValues.dateRange,
                showRescueSizeLegend: state,
              })
            }
          />
        </form>
      )}
    />
  );
};

export default GrowthReportForm;
