export const setValue = {
  setValue: ([field, value], state, { changeValue }) => {
    changeValue(state, field, () => value);
  },
};

export const setFieldData = {
  setFieldData: (args, state) => {
    const [name, data] = args;
    const field = state.fields[name];
    if (field) {
      field.data = { ...field.data, ...data };
    }
  },
};