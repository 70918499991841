import React, { useState } from 'react';
import { TimePicker } from '@material-ui/pickers';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import moment from 'moment';
import { startEndTimeValidator } from '../helpers';
import { mainTheme } from '../assets/theme';
import { Colors } from '../assets/theme/Colors';

const MinMaxTimePicker = props => {
  const {
    showFieldErrors = false,
    minTime,
    maxTime,
    onTimeChange,
    value,
    hasHoursForSelectedDay = false,
    weeklyHours = [],
    pickupSpecs = [],
    startDate = {},
    showWarning = false,
    ...rest
  } = props;
  const initialValue = value;
  const [errorText, setErrorText] = useState(undefined);

  const isInReceivingHours = pickupSpecs.every((spec) => {
    const receivingHours = weeklyHours.filter((time) =>
      spec.day === null ? time.day_of_week === moment(startDate.value).day() : spec.day === time.day_of_week
    );

    if (receivingHours?.length) {
      return receivingHours.find((data) =>
        moment(value, 'HH:mm:ss').isBetween(moment(data.start, 'HH:mm:ss'), moment(data.end, 'HH:mm:ss'), undefined, '[]')
      );
    }

    return false;
  });

  const clockTheme = createMuiTheme({
    ...mainTheme,
    overrides: {
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: isInReceivingHours ? Colors.green.light : Colors.red,
        },
        thumb: {
          borderColor: isInReceivingHours ? Colors.green.light : Colors.red,
        },
        noPoint: {
          backgroundColor: isInReceivingHours ? Colors.green.light : Colors.red,
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: showWarning ? Colors.red : 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
  });

  return (
    <ThemeProvider theme={hasHoursForSelectedDay ? clockTheme : mainTheme}>
      <TimePicker
        value={value}
        error={(errorText && showFieldErrors) || showWarning}
        helperText={errorText}
        minutesStep={5}
        onChange={(momentObj) => {
          setErrorText(undefined);
          let durationDiff;
          let violationErrorList;

          if (minTime) {
            violationErrorList = startEndTimeValidator(minTime, momentObj);
            if (!violationErrorList.end.isValid && showFieldErrors) {
              setErrorText(violationErrorList.end.message);
            }

            onTimeChange({
              event: momentObj,
              isValid: violationErrorList.end.isValid,
              duration: undefined,
            });
          }

          if (maxTime) {
            violationErrorList = startEndTimeValidator(momentObj, maxTime);

            if (!violationErrorList.begin.isValid && showFieldErrors) {
              setErrorText(violationErrorList.begin.message);
            }

            durationDiff = moment.duration(moment(maxTime, 'HH:mm').diff(moment(initialValue, 'HH:mm')));
            onTimeChange({
              event: momentObj,
              isValid: violationErrorList.begin.isValid,
              duration: durationDiff,
            });
          }
        }}
        {...rest}
      />
    </ThemeProvider>
  );
};

export default MinMaxTimePicker;
