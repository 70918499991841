import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Box } from '@material-ui/core';
import { FormContainer, GridRow } from '../FormGrid';
import { matchPath } from 'react-router';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import routes from '../../../../routes';
import Bluebird from 'bluebird';
import {
  showAdoptRescueButton,
  showClaimRescueButton,
  showCloseRescueButtons,
  showICouldnotMakeIt,
  showReleaseRescueButton,
  showUnAdoptRescueButton,
  showUnClaimRescueButtons,
} from '../../../../helpers/RescuesHelper';
import * as rescuesActions from '../../../../actions/rescues';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import { adoptPickupSpec } from '../../../../actions/pickupSpecs';
import { RESCUE_BTN_ADOPTED } from '../../../rescueAdoptConfirmation/RescueAdoptConfirmation';
import useRescuerRescueActions from '../../../../hooks/useRescuerRescueActions';

const RescueEditRescuerForm = ({ user, rescue, isMobileView, refreshData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [getSubmittingButton, setSubmittingButton] = useState();
  const { rescueClaim, rescueUnclaim, rescueUnadopt } = useRescuerRescueActions();

  useEffect(() => {
    if (
      location.state &&
      location.state.adoptButton &&
      matchPath(location.state.prevPath, {
        path: routes.rescueAdoptConfirmation,
        exact: true,
      })
    ) {
      const { adoptButton, ...rest } = location.state;
      history.replace(location.pathname, { ...rest }); //remove adoptButton

      if (adoptButton !== RESCUE_BTN_ADOPTED) {
        return;
      }

      let toastButton = {};

      if (isMobileView) {
        toastButton = {
          color: 'primary',
          variant: 'contained',
          label: 'Undo',
          onClick: () => {
            Bluebird
              .try(() => dispatch(adoptPickupSpec(rescue.pickup_spec_id, { adopter_id: null })))
              .then(() => refreshData());
          },
        };
      }

      snackbarHelper.success('Rescue adopted', isMobileView, toastButton);
    }
  }, [location]);

  const handleClaimClick = () => {
    setSubmittingButton('claim');
    return Bluebird
      .try(() => rescueClaim(rescue))
      .finally(() => setSubmittingButton(null));
  };

  const handleUnClaimClick = () => {
    setSubmittingButton('unclaim');
    return Bluebird
      .try(() => rescueUnclaim(rescue))
      .finally(() => setSubmittingButton(null));
  };

  const handleAdoptClick = () =>
    history.push(
      generatePath(routes.rescueAdoptConfirmation, {
        rescueId: rescue.id,
      })
    );

  const handleUnAdoptClick = () => {
    setSubmittingButton('unadopt');
    return Bluebird
      .try(() => rescueUnadopt(rescue))
      .finally(() => setSubmittingButton(null));
  };

  const actions = [];

  if (showClaimRescueButton(rescue)) {
    actions.push({
      jsx: (
        <ButtonWithLoading
          disabled={!!getSubmittingButton || user.paused_at}
          fullWidth={isMobileView}
          color="primary"
          isLoading={getSubmittingButton === 'claim'}
          data-testid="claim-button"
          onClick={() => handleClaimClick()}
        >
          I'll do it
        </ButtonWithLoading>
      ),
      key: 'claim',
    });
  }

  if (showUnClaimRescueButtons(rescue, user)) {
    actions.push({
      jsx: (
        <ButtonWithLoading
          disabled={!!getSubmittingButton}
          fullWidth={isMobileView}
          color="primary"
          isLoading={getSubmittingButton === 'unclaim'}
          data-testid="rescue-edit-rescuer-unclaim"
          onClick={() => handleUnClaimClick()}
        >
          Unclaim Rescue
        </ButtonWithLoading>
      ),
      key: 'unclaim',
    });
  }

  if (showAdoptRescueButton(rescue)) {
    actions.push({
      jsx: (
        <ButtonWithLoading
          disabled={!!getSubmittingButton || user.paused_at}
          fullWidth={isMobileView}
          color="primary"
          type="submit"
          isLoading={getSubmittingButton === 'adopt'}
          data-testid="rescue-edit-rescuer-adopt"
          onClick={() => handleAdoptClick()}
        >
          Adopt Rescue
        </ButtonWithLoading>
      ),
      key: 'adopt',
    });
  }

  if (showUnAdoptRescueButton(rescue, user)) {
    actions.push({
      jsx: (
        <ButtonWithLoading
          disabled={!!getSubmittingButton}
          fullWidth={isMobileView}
          color="primary"
          type="submit"
          isLoading={getSubmittingButton === 'unadopt'}
          data-testid="rescue-edit-rescuer-claim"
          onClick={() => handleUnAdoptClick()}
        >
          Unadopt Rescue
        </ButtonWithLoading>
      ),
      key: 'unadopt',
    });
  }

  if (showReleaseRescueButton(rescue, user)) {
    actions.push({
      jsx: (
        <ButtonWithLoading
          disabled={!!getSubmittingButton}
          fullWidth={isMobileView}
          color="primary"
          type="submit"
          isLoading={getSubmittingButton === 'release'}
          data-testid="release-button"
          onClick={() => dispatch(rescuesActions.releaseRescue(user.id, rescue))}
        >
          Sorry, I can't make it
        </ButtonWithLoading>
      ),
      key: 'release',
    });
  }

  if (showCloseRescueButtons(rescue, user)) {
    actions.push({
      jsx: (
        <ButtonWithLoading
          disabled={!!getSubmittingButton}
          fullWidth={isMobileView}
          color="primary"
          type="submit"
          isLoading={getSubmittingButton === 'close'}
          data-testid="rescue-edit-rescuer-adopt"
          onClick={() => history.push(`/rescue/close/${rescue.id}`)}
        >
          Yes I did it!
        </ButtonWithLoading>
      ),
      key: 'close',
    });
  }

  if (showICouldnotMakeIt(rescue, user)) {
    actions.push({
      jsx: (
        <ButtonWithLoading
          disabled={!!getSubmittingButton}
          fullWidth={isMobileView}
          color="primary"
          type="submit"
          isLoading={getSubmittingButton === 'close-no-show'}
          data-testid="rescue-edit-rescuer-adopt"
          onClick={() => history.push(`/rescue/close/noshow/${rescue.id}`)}
        >
          I couldn't make it
        </ButtonWithLoading>
      ),
      key: 'close-no-show',
    });
  }

  if (actions.length === 0) {
    return null;
  }

  return (
    <Box borderColor={rescue.cancelled_by_id ? 'error.main' : 'text.primary'} borderRadius={4} border={1} p={2} mt={1}>
      <FormContainer>
        {actions.map(({ jsx, key }) => (
          <GridRow key={key}>
            <Grid item xs={12}>
              {jsx}
            </Grid>
          </GridRow>
        ))}
      </FormContainer>
    </Box>
  );
};

export default RescueEditRescuerForm;
