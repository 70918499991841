import Bluebird from 'bluebird';
import * as insightsApi from '../api/insights';

export const REQUEST_INSIGHTS = 'REQUEST_INSIGHTS';
export const RECEIVE_INSIGHTS = 'RECEIVE_INSIGHTS';

export const requestInsights = () => ({
  type: REQUEST_INSIGHTS,
});

export const receiveInsights = insights => ({
  type: RECEIVE_INSIGHTS,
  insights: insights,
  receivedAt: Date.now(),
});

export const fetchInsights = (dateRange, sitesIds = []) => dispatch => {
  dispatch(requestInsights());

  return Bluebird
    .try(() => insightsApi.getInsights(dateRange, sitesIds))
    .then(res => res.json())
    .then(json => dispatch(receiveInsights(json.data)));
};

export const fetchInsightsIfNeeded = (dateRange, sitesIds = []) => dispatch => dispatch(
  fetchInsights(dateRange, sitesIds)
);
