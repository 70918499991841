import React, { useEffect } from 'react';
import { generatePath, Route, Switch } from 'react-router-dom';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Bluebird from 'bluebird';
import routes from '../../../routes';
import SCDashboardAtGlance from './SCDashboardAtGlance';
import SCDashboardLeaderboards from './SCDashboardLeaderboards';
import SCDashboardAppBar from './SCDashboardAppBar';
import SCDashboardToDo from './SCDashboardToDo';
import { useDispatch, useSelector } from 'react-redux';
import { updateSCToDoCounterBadge } from '../../../actions/misc';
import SCDashboardInsights from './SCDashboardInsights';
import SCDashboardSiteGoals from './SCDashboardSiteGoals';
import { fetchSiteUnclaimedRescuesFailed, fetchSiteUnclaimedRescues } from '../../../actions/sites';
import useActiveSite from '../../../hooks/useActiveSite';
import { get } from 'lodash';
import SCDashboardEnvironmentImpact from './SCDashboardEnvironmentImpact';
import SCDashboardAnalytics from './SCDashboardAnalytics';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    height: '100%',
  },
}));

export const scDashboardPaths = [
  generatePath(routes.dashboardSC),
  generatePath(routes.dashboardSC, { tab: 'at-glance' }),
  generatePath(routes.dashboardSC, { tab: 'leaderboards' }),
  generatePath(routes.dashboardSC, { tab: 'insights' }),
  generatePath(routes.dashboardSC, { tab: 'site-goals' }),
  generatePath(routes.dashboardSC, { tab: 'environment-impact' }),
  generatePath(routes.dashboardSC, { tab: 'analytics' }),
];

const SCDashboardDesktopContainer = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const unclaimedRescues = useSelector(state => state.entities.sites.unclaimedRescues);
  const totalUnclaimedRescues = get(unclaimedRescues, `bySiteId[${activeSite.id}].length`);

  useEffect(() => {
    // re-fetch rescues everytime we change the tab to update badge counter
    Bluebird
      .try(() =>
        dispatch(
          fetchSiteUnclaimedRescues(
            moment().format('YYYYMMDD'),
            moment().add(10, 'day').format('YYYYMMDD'),
            activeSite.id
          )
        )
      )
      .catch(() => dispatch(fetchSiteUnclaimedRescuesFailed()));
  }, [params.tab]);

  useEffect(() => {
    // update badge count on rescues length change
    dispatch(updateSCToDoCounterBadge('todo', totalUnclaimedRescues));
  }, [totalUnclaimedRescues]);

  return (
    <>
      <SCDashboardAppBar />

      <div className={classes.root} data-testid="sc-dashboard-desktop">
        <Switch>
          <Route path={[scDashboardPaths[0], generatePath(routes.index)]} exact component={SCDashboardToDo} />
          <Route path={scDashboardPaths[1]} exact render={props => <SCDashboardAtGlance {...props} siteId={activeSite.id} />} />
          <Route path={scDashboardPaths[2]} exact component={SCDashboardLeaderboards} />
          <Route path={scDashboardPaths[3]} exact render={props => <SCDashboardInsights {...props} siteId={activeSite.id} />} />
          <Route path={scDashboardPaths[4]} exact render={() => <SCDashboardSiteGoals siteId={activeSite.id} />} />
          <Route path={scDashboardPaths[5]} exact render={() => <SCDashboardEnvironmentImpact siteId={activeSite.id} />} />
          <Route path={scDashboardPaths[6]} exact render={() => <SCDashboardAnalytics />} />
        </Switch>
      </div>
    </>
  );
};

export default SCDashboardDesktopContainer;
