import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import DonationRequestsTable from './DonationRequestsTable';
import routes from '../../../../routes';
import { fetchRequestDonationList } from '../../../../actions/requestDonations';
import DonationRequestsCards from './DonationRequestsCards';
import { Roles } from '../../../../models/roles';
import useActiveUser from '../../../../hooks/useActiveUser';

const DonationRequestsListView = ({ history, isMobileView }) => {
  const dispatch = useDispatch();
  const requestDonationsEntities = useSelector(state => state.entities.requestDonations.byId);
  const isLoading = useSelector(state => state.entities.requestDonations.inflight);
  const isUIDisabled = useSelector(state => state.ui.uiDisabled);
  const activeUser = useActiveUser();
  const requestDonationsList = Object.values(requestDonationsEntities);
  const donorLocation = activeUser.role_assignments.find(role => role.role_name === Roles.DonorLocationAdmin);

  useEffect(() => {
    if (donorLocation && donorLocation.donor_location_id) {
      dispatch(fetchRequestDonationList({ locationId: donorLocation.donor_location_id }));
    }
  }, [dispatch, donorLocation && donorLocation.donor_location_id]);

  const onEditClick = requestDonationId =>
    history.push(generatePath(routes.donation_edit_request, { donationId: requestDonationId }));

  if (isMobileView) {
    return <DonationRequestsCards isLoading={isLoading} onEditClick={onEditClick} donations={requestDonationsList} />;
  }

  const renderCustomTableToolbar = () => (
    <Button
      startIcon={<Add />}
      type="button"
      variant="contained"
      color="primary"
      size="small"
      disabled={isUIDisabled}
      onClick={() => history.push(routes.donation_create_request)}
    >
      Request to Donate
    </Button>
  );

  return (
    <div>
      <DonationRequestsTable
        customToolbar={renderCustomTableToolbar}
        onEditClick={onEditClick}
        donations={requestDonationsList}
      />
    </div>
  );
};

export default DonationRequestsListView;
