import React from 'react';
import { isFormStateValid } from '../../helpers/validators';
import { Typography, Grid, Box } from '@material-ui/core';
import pick from 'lodash/pick';

const RenderFormErrorText = ({ formState, showValues = undefined }) => {
  const renderData = [];

  if (!isFormStateValid(formState) && formState.submitFailed) {
    renderData.push(
      <Typography key="basic-form-error" data-testid="form-submit-errors" color="error">
        Form has an error.
      </Typography>
    );
  }

  if (process.env.NODE_ENV !== 'development') {
    return renderData;
  }

  renderData.push(
    <Grid container key="development-errors">
      {!isFormStateValid(formState) && (
        <Grid item xs={12}>
          {formState.hasValidationErrors && (
            <Box p={1} border={1} borderColor="info.dark">
              <Typography variant="caption">validation errors</Typography>
              <pre>{JSON.stringify(formState.errors, 0, 2)}</pre>
            </Box>
          )}

          {formState.hasSubmitErrors && (
            <Box p={1} border={1} borderColor="warning.dark">
              <Typography variant="caption">submit errors</Typography>
              <pre>{JSON.stringify(formState.submitErrors, 0, 2)}</pre>
            </Box>
          )}
        </Grid>
      )}

      <Grid item xs={12}>
        <pre>{JSON.stringify(showValues ? pick(formState.values, showValues) : formState.values, 0, 2)}</pre>
      </Grid>
    </Grid>
  );

  return renderData;
};

export default RenderFormErrorText;
