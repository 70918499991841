import React, { useState, useEffect } from 'react';
import { Box, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { formatAddress } from '../../../../../helpers/formatters';

const getPickupLocationLabel = option => {
  const formattedAddress = option
    ? formatAddress({
        address: option.address,
        city: option.city,
        st: option.st,
        zip: option.zip,
      })
    : null;

  if (formattedAddress && !option.name) {
    return formattedAddress;
  }

  return `${option.name} (${formattedAddress})`;
};

const renderOption = option => {
  if (!option.name) {
    return (
      <Typography gutterBottom variant="body1">
        {formatAddress({
          address: option.address,
          city: option.city,
          st: option.st,
          zip: option.zip,
        })}
      </Typography>
    );
  }

  return (
    <Box display="flex" flexDirection="column" pb={1}>
      <Typography variant="body1">{option.name}</Typography>
      <Typography color="textSecondary" variant="body2">
        {formatAddress({
          address: option.address,
          city: option.city,
          st: option.st,
          zip: option.zip,
        })}
      </Typography>
    </Box>
  );
};

const PickupLocationForm = ({ pickupLocations, pickupLocationId, foodDonorId, onPickupLocationChange }) => {
  const [pickupLocation, setPickupLocation] = useState(null);
  const pickupLocationsList = pickupLocations.byFoodDonorId[foodDonorId] || [];

  useEffect(() => {
    if (pickupLocationId) {
      return setPickupLocation(pickupLocations.byId[pickupLocationId]);
    }

    return setPickupLocation(null);
  }, [pickupLocationId]);

  return (
    <Grid item xs={12} data-testid="pickup_location-selector">
      <InputLabel shrink>Pickup Location</InputLabel>
      <Autocomplete
        size="small"
        name="pickup_location_id"
        placeholder="Select pickup location"
        options={pickupLocationsList}
        getOptionLabel={option => getPickupLocationLabel(option)}
        renderOption={renderOption}
        value={pickupLocation}
        disableClearable
        onChange={(event, option, reason) => {
          if (reason === 'select-option') {
            setPickupLocation(option);
            return onPickupLocationChange({
              name: option.name,
              id: option.id,
            });
          }
        }}
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </Grid>
  );
};

export default PickupLocationForm;
