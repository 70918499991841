/* eslint-disable import/no-unresolved */
import { objectFromArray } from '../helpers';
import * as donationSizesActions from '../actions/donationSizes';

export const initialState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const DonationSizesReducer = (state = initialState, action) => {
  switch (action.type) {
    case donationSizesActions.REQUEST_DONATION_SIZES:
      return {
        ...state,
        inflight: true,
      };
    case donationSizesActions.RECEIVE_DONATION_SIZES:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.donationSizes, (donationSize) => donationSize.id),
        allIds: action.donationSizes.map((donationSize) => donationSize.id),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default DonationSizesReducer;
