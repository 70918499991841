import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Breadcrumbs, Typography } from '@material-ui/core';
import routes from '../routes';
import ReceiverAddForm from '../components/ReceiverAddForm';
import * as receiversActions from '../actions/receivers';
import OverlaySpinner from './OverlaySpinner';

class ReceiverAddView extends Component {
  componentDidMount() {
    const { fetchReceiverTypes } = this.props;

    fetchReceiverTypes();
  }

  onCreateReceiver = values => {
    const { site, createReceiver, history, isMobileView } = this.props;

    return Bluebird
      .try(() => createReceiver({
        ...values,
        site_id: site.id,
      }))
      .then(() => history.push(isMobileView ? routes.index : routes.receivers))
      .catch(err => {
        throw err;
      });
  };

  render() {
    const { receiverTypes, isMobileView } = this.props;
    const isLoading = receiverTypes.inflight;
    const receiverTypesList = receiverTypes.allIds.map(id => receiverTypes.byId[id]);

    return (
      <>
        <Breadcrumbs aria-label="Breadcrumbs">
          {isMobileView ? (
            <Typography color="textPrimary">Receiving Agencies</Typography>
          ) : (
            <Link color="inherit" to={routes.receivers}>
              Receiving Agencies
            </Link>
          )}

          <Typography color="textPrimary">Add a Receiving Agency</Typography>
        </Breadcrumbs>

        <OverlaySpinner isLoading={isLoading}>
          <ReceiverAddForm receiverTypeList={receiverTypesList} createReceiver={this.onCreateReceiver} />
        </OverlaySpinner>
      </>
    );
  }
}

const mapStateToProps = ({ app: { site }, entities: { receivers } }, { isMobileView }) => ({
  site,
  receiverTypes: receivers.types,
  isMobileView,
});
const mapDispatchToProps = dispatch => {
  return {
    createReceiver: r => dispatch(receiversActions.createReceiver(r)),
    fetchReceiverTypes: () => dispatch(receiversActions.fetchReceiverTypes()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReceiverAddView)
);
