import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchSiteLeaderboardsIfNeeded } from '../../../actions/sites';
import moment from 'moment';
import DateAndGranularityPicker, {
  dateRangeOptionsList,
} from '../../../components/DateAndGranularityPicker/DateAndGranularityPicker';
import useActiveSite from '../../../hooks/useActiveSite';
import LeaderboardsTables from '../../../components/LeaderboardsTables';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

const SCDashboardLeaderboards = () => {
  const classes = useStyles();
  const leaderboards = useSelector(state => state.entities.sites.leaderboards);
  const activeSite = useActiveSite();
  const dispatch = useDispatch();

  const showLoading = leaderboards.inflight;

  const onDateAndGranularityPickerChange = ({ dateRange }) => {
    const _dateRange = !dateRange
      ? null
      : {
          startDate: moment(dateRange.startDate).format('YYYYMMDD'),
          endDate: moment(dateRange.endDate).format('YYYYMMDD'),
        };
    dispatch(fetchSiteLeaderboardsIfNeeded(activeSite.id, _dateRange));
  };

  return (
    <>
      <Grid container>
        <Grid item>
          <Paper className={classes.paper}>
            <FormControl component="fieldset">
              <DateAndGranularityPicker
                showAllDateRange
                defaultDateRange={dateRangeOptionsList.thisQTD.value}
                dateRangeOptions={{
                  lastWeek: dateRangeOptionsList.lastWeek,
                  last7Days: dateRangeOptionsList.last7Days,
                  last90Days: dateRangeOptionsList.last90Days,
                  today: dateRangeOptionsList.today,
                  thisMTD: dateRangeOptionsList.thisMTD,
                  thisQTD: dateRangeOptionsList.thisQTD,
                  thisYTD: dateRangeOptionsList.thisYTD,
                }}
                customDateRangeOptions={{
                  maxDate: new Date(),
                }}
                showCustomDateRange
                showGranularity={false}
                isDisabled={showLoading}
                onChange={onDateAndGranularityPickerChange}
              />
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      <LeaderboardsTables inflight={showLoading} data={leaderboards} />
    </>
  );
};

export default SCDashboardLeaderboards;
