import React, { useState } from 'react';
import { DevTool } from 'react-hook-form-devtools';
import { FormContext, useForm } from 'react-hook-form';
import Bluebird from 'bluebird';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { confirmAlert } from 'react-confirm-alert';
import EventDetailsForm from '../Components/EventForm/EventDetails/EventDetailsForm';
import EventShiftsForm from '../Components/EventForm/EventShifts/EventShiftsForm';
import ButtonWithLoading from '../../../components/ButtonWithLoading';
import SubmitButtonWrapper from '../../../components/Common/MobileSubmitWrapper';
import eventFormValidationResolver from '../Components/eventFormValidationResolver';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import useHasActiveFeatureFlag from '../../../hooks/useFeatureFlags';
import { FF_COMMUNITY_AND_ENGAGEMENT } from '../../../components/FeatureFlagWrapper';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
}));

const EventEditForm = ({ submitHandler, isMobileView, defaultValues, onCancelEventButtonClick, isCanceled, eventData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasCommunityEngagementFF = useHasActiveFeatureFlag(FF_COMMUNITY_AND_ENGAGEMENT);
  const formMethods = useForm({
    defaultValues,
    validationResolver: eventFormValidationResolver,
    validationContext: {},
  });
  const { handleSubmit, control, reset } = formMethods;
  const classes = useStyles();

  const onSubmit = (values) => {
    const beginDates = values.shifts.map((shift) => moment(shift.begin));
    const endDates = values.shifts.map((shift) => moment(shift.end));

    setIsSubmitting(true);

    return Bluebird
      .try(() => submitHandler({
        ...values,
        location_id: defaultValues.location_id,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        begin: values.begin ? values.begin.format('HH:mm:ss') : moment.min(beginDates).format('HH:mm:ss'),
        end: values.end ? values.end.format('HH:mm:ss') : moment.max(endDates).format('HH:mm:ss'),
        shifts: values.shifts.map(shift => ({
          ...shift,
          begin: shift.begin.format('HH:mm:ss'),
          end: shift.end.format('HH:mm:ss'),
        })),
      })
    ).finally(() => setIsSubmitting(false));
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EventDetailsForm isCanceled={isCanceled} isMobileView={isMobileView} isEdited={true} />
        <EventShiftsForm isEditView isMobileView={isMobileView} eventShiftsData={eventData.shifts} />
        <SubmitButtonWrapper>
          <ButtonWithLoading
            isLoading={isSubmitting}
            disabled={isCanceled ? true : false}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="medium"
            aria-label="save"
          >
            Submit
          </ButtonWithLoading>
          {hasCommunityEngagementFF && (
            <ButtonWithLoading
              className={classes.button}
              disabled={isCanceled ? true : false}
              isLoading={isSubmitting}
              variant="outlined"
              fullWidth
              size="medium"
              aria-label="save"
              onClick={() => {
                confirmAlert({
                  title: 'Cancel this event?',
                  message: 'Are you sure you want to cancel this event?',
                  buttons: [
                    {
                      label: 'No',
                      color: 'primary',
                    },
                    {
                      label: 'Yes',
                      color: 'primary',
                      onClick: () => onCancelEventButtonClick(),
                    },
                  ],
                  customUI: ({ title, message, onClose, buttons }) => (
                    <ConfirmationDialog
                      buttons={buttons}
                      closeDialog={onClose}
                      title={title}
                      message={message}
                    />
                  ),
                });
              }}
            >
             Cancel Event
          </ButtonWithLoading>
          )}
        </SubmitButtonWrapper>
        {process.env.NODE_ENV === 'development' && <DevTool control={control} />}
      </form>
    </FormContext>
  ); 
};

export default EventEditForm;
