import * as insightsActions from '../actions/insights';

export const initialSitesState = {
  inflight: false,
  all: [],
  lastUpdated: null,
};

const insightsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case insightsActions.REQUEST_INSIGHTS:
      return {
        ...state,
        inflight: true,
      };
    case insightsActions.RECEIVE_INSIGHTS:
      return {
        ...state,
        inflight: false,
        all: action.insights,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default insightsReducer;
