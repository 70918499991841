import Bluebird from 'bluebird';
import * as siteCoordinatorsApi from '../api/siteCoordinators';
import * as usersActions from './users';

export const RECEIVE_SITE_COORDINATOR_POST = 'RECEIVE_SITE_COORDINATOR_POST';

export const createSiteCoordinator = siteCoordinator => dispatch => Bluebird
  .try(() => siteCoordinatorsApi.createSiteCoordinator(siteCoordinator))
  .then(res => res.json())
  .then(json => {
    dispatch(receiveSiteCoordinatorPost(json));

    usersActions.receiveUserAndReflectAllUserStates(json.data);
  });

export const receiveSiteCoordinatorPost = json => ({
  type: RECEIVE_SITE_COORDINATOR_POST,
  siteCoordinator: json,
  receivedAt: Date.now(),
});
