import * as umbrellaFoodDonorsApi from '../api/umbrellaFoodDonors';
import * as umbrellaFoodDonorsModel from '../models/umbrellaFoodDonors';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_UMBRELLA_FOOD_DONORS = 'REQUEST_UMBRELLA_FOOD_DONORS';
export const RECEIVE_UMBRELLA_FOOD_DONORS = 'RECEIVE_UMBRELLA_FOOD_DONORS';
export const RECEIVE_UMBRELLA_FOOD_DONOR = 'RECEIVE_UMBRELLA_FOOD_DONOR';
export const REQUEST_UMBRELLA_FOOD_DONORS_STAFF = 'REQUEST_UMBRELLA_FOOD_DONORS_STAFF';
export const RECEIVE_UMBRELLA_FOOD_DONOR_STAFF = 'RECEIVE_UMBRELLA_FOOD_DONOR_STAFF';

export const requestUmbrellaFoodDonors = () => ({
  type: REQUEST_UMBRELLA_FOOD_DONORS,
});

export const receiveUmbrellaFoodDonors = data => ({
  type: RECEIVE_UMBRELLA_FOOD_DONORS,
  umbrellaFoodDonors: data,
  receivedAt: Date.now(),
});

export const requestUmbrellaFoodDonorsStaff = () => ({
  type: REQUEST_UMBRELLA_FOOD_DONORS_STAFF,
});

export const receiveUmbrellaFoodDonorsStaff = (umbrellaFoodDonorId, data) => ({
  type: RECEIVE_UMBRELLA_FOOD_DONOR_STAFF,
  umbrellaFoodDonorsStaff: data,
  umbrellaFoodDonorId: umbrellaFoodDonorId,
  receivedAt: Date.now(),
});

export const receiveUmbrellaFoodDonor = data => ({
  type: RECEIVE_UMBRELLA_FOOD_DONOR,
  umbrellaFoodDonor: data,
  receivedAt: Date.now(),
});

export const fetchUmbrellaFoodDonors = () => async dispatch => {
  dispatch(requestUmbrellaFoodDonors());

  const res = await umbrellaFoodDonorsApi.getUmbrellaFoodDonors();
  const json = await res.json();

  return dispatch(receiveUmbrellaFoodDonors(json.data));
};

export const fetchUmbrellaFoodDonorsIfNeeded = () => dispatch => dispatch(fetchUmbrellaFoodDonors());

export const fetchUmbrellaFoodDonor = umbrellaFoodDonorId => async dispatch => {
  dispatch(requestUmbrellaFoodDonors());

  const res = await umbrellaFoodDonorsApi.getUmbrellaFoodDonor(umbrellaFoodDonorId);
  const json = await res.json();

  return dispatch(receiveUmbrellaFoodDonor(json.data));
};

export const fetchUmbrellaFoodDonorIfNeeded = umbrellaFoodDonorId => dispatch =>
  dispatch(fetchUmbrellaFoodDonor(umbrellaFoodDonorId));

export const fetchUmbrellaFoodDonorsStaff = umbrellaFoodDonorId => async dispatch => {
  dispatch(requestUmbrellaFoodDonorsStaff());

  const res = await umbrellaFoodDonorsApi.getUmbrellaFoodDonorStaff(umbrellaFoodDonorId);
  const json = await res.json();

  return dispatch(receiveUmbrellaFoodDonorsStaff(umbrellaFoodDonorId, json.data));

}

function shouldFetchUmbrellaFoodDonorStaff(state) {
  const staff = state.entities.umbrellaFoodDonors.staff;


  if (!staff.allIds.length) {
    return true;
  }

  if (staff.inflight) {
    return false;
  }

  return shouldFetch(staff.lastUpdated, { minutes: 5 });
}

export const fetchUmbrellaFoodDonorsStaffIfNeeded = umbrellaFoodDonorId => async (dispatch, getState) => {
  if (shouldFetchUmbrellaFoodDonorStaff(getState())) {
    return dispatch(fetchUmbrellaFoodDonorsStaff(umbrellaFoodDonorId));
  }

  return null;
};

export const createUmbrellaFoodDonor = data => async dispatch => {
  const res = await umbrellaFoodDonorsApi.createUmbrellaFoodDonor(
    umbrellaFoodDonorsModel.mapFormToCreateUmbrellaFoodDonorPOST(data)
  );
  const json = await res.json();

  return dispatch(receiveUmbrellaFoodDonor(json.data));
};

export const updateUmbrellaFoodDonor = (umbrellaFoodDonorId, data) => async dispatch => {
  const res = await umbrellaFoodDonorsApi.updateUmbrellaFoodDonor(
    umbrellaFoodDonorId,
    umbrellaFoodDonorsModel.mapFormToUpdateUmbrellaFoodDonorPUT(data)
  );
  const json = await res.json();

  return dispatch(receiveUmbrellaFoodDonor(json.data));
};
