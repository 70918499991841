import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  LocalDrink as LocalDrinkIcon,
  NaturePeople as NaturePeopleIcon,
  Restaurant as RestaurantIcon,
} from '@material-ui/icons';
import EnviroImpactCard from '../../../../../components/EnviroImpactCard';
import { ReactComponent as FoodIcon } from '../../../../../assets/svg/food.svg';
import { Colors } from '../../../../../assets/theme/Colors';
import { formatNumber } from '../../../../../helpers/formatters';

const useStyles = makeStyles(() => ({
  icon: {
    color: Colors.appBar.main,
  },
  food: {
    fill: Colors.appBar.main,
  },
  card: {
    height: '100%',
  },
}));

const MyEnviroImpact = ({
  isLoading,
  environmentImpact,
  iconSize = 72,
  valueFontVariant = 'h5',
  textStyle = '',
  textVariant = 'body2',
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <EnviroImpactCard
          value={
            environmentImpact && environmentImpact.weight_lbs ? formatNumber(environmentImpact.weight_lbs) : 0
          }
          title="Lbs of food rescued"
          isLoading={isLoading}
          cardStyle={classes.card}
          iconSize={iconSize}
          valueFontVariant={valueFontVariant}
          textStyle={textStyle}
          textVariant={textVariant}
        >
          {/* Sets size to the same values as icons from mui */}
          <FoodIcon viewBox="0 0 48 48" width={iconSize} height={iconSize} className={classes.food} />
        </EnviroImpactCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <EnviroImpactCard
          value={environmentImpact && environmentImpact.meals ? formatNumber(environmentImpact.meals) : 0}
          title="Meals rescued"
          isLoading={isLoading}
          cardStyle={classes.card}
          iconSize={iconSize}
          valueFontVariant={valueFontVariant}
          textStyle={textStyle}
          textVariant={textVariant}
        >
          <RestaurantIcon className={classes.icon} fontSize="inherit" />
        </EnviroImpactCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <EnviroImpactCard
          value={environmentImpact && environmentImpact.co2eq ? formatNumber(environmentImpact.co2eq) : 0}
          title="Lbs of CO2eq offset"
          isLoading={isLoading}
          cardStyle={classes.card}
          iconSize={iconSize}
          valueFontVariant={valueFontVariant}
          textStyle={textStyle}
          textVariant={textVariant}
        >
          <NaturePeopleIcon className={classes.icon} fontSize="inherit" />
        </EnviroImpactCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <EnviroImpactCard
          value={environmentImpact && environmentImpact.water ? formatNumber(environmentImpact.water) : 0}
          title="Gallons of water wasted offset"
          isLoading={isLoading}
          cardStyle={classes.card}
          iconSize={iconSize}
          valueFontVariant={valueFontVariant}
          textStyle={textStyle}
          textVariant={textVariant}
        >
          <LocalDrinkIcon className={classes.icon} fontSize="inherit" />
        </EnviroImpactCard>
      </Grid>
    </Grid>
  );
};
export default MyEnviroImpact;
