import React from 'react';
import { Checkbox, DialogContentText, FormControlLabel, FormGroup } from '@material-ui/core';
import ConfirmationDialog from './ConfirmationDialog';

const styles = {
  siteName: {
    fontWeight: 'bold',
  },
};

const VerificationPauseRescueConfirmationDialog = ({
  onCloseDialog,
  onPauseButtonClick,
  isSD = false,
}) => (
  <ConfirmationDialog
    dialogProps={{
      fullWidth: true,
    }}
    title="Pause rescuing"
    customMessage={
      isSD ? (
        <>
          <DialogContentText>Are you sure you want to pause rescuing for this user?</DialogContentText>
          <DialogContentText>Pausing it will unclaim all currently claimed rescues by this user.</DialogContentText>
        </>
      )
      : (
        <>
          <DialogContentText>Are you sure you want to pause rescuing?</DialogContentText>
          <DialogContentText>Pausing it will unclaim all your currently claimed rescues.</DialogContentText>
        </>
      )
    }
    closeDialog={onCloseDialog}
    buttons={[
      { variant: 'outlined', label: 'Cancel', color: 'primary', onClick: onCloseDialog },
      { variant: 'contained', label: 'Pause rescuing', color: 'primary', onClick: onPauseButtonClick },
    ]}
  />
);

export default VerificationPauseRescueConfirmationDialog;
