import React, { useContext, useEffect } from 'react';
import { Typography, Button, Card, CardContent, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as uiPersistedActions from '../actions/uiPersisted';
import routes from '../routes';
import * as uiActions from '../actions/ui';
import { siteDirectorTour } from '../helpers/tours';
import { useHistory } from 'react-router-dom';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import Grid from '@material-ui/core/Grid';
import welcomeInfographic from '../assets/images/Infographic.png';
import { makeStyles } from '@material-ui/core/styles';
import useActiveUser from '../hooks/useActiveUser';
import { hasRoleInCurrentlySelectedSite, shouldHideMainDrawer } from '../services/auth';
import { setFABActions } from '../helpers/fab';
import FABContext from '../context/FABContext/FABContext';
import { getVerificationStatus } from '../api/verification';
import { setUIDisabled } from '../actions/ui';

const useStyles = makeStyles(theme => ({
  buttonsWrapper: {
    '& button:not(:first-child)': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const WelcomePageView = ({ isMobileView }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeUser = useActiveUser();
  const fabContext = useContext(FABContext);

  const setTourStepper = (enabled, payload) => dispatch(uiActions.setTourStepper(enabled, payload));

  useEffect(() => {
    // reset default dashboard just in case if user re-login to different account
    dispatch(uiPersistedActions.resetDefaultDashboard());
    if (!shouldHideMainDrawer(activeUser, isMobileView)) {
      dispatch(uiPersistedActions.openDrawer());
    }

    const isFoodDonorAdmin = hasRoleInCurrentlySelectedSite(activeUser, Roles.DonorLocationAdmin);
    if (isFoodDonorAdmin) {
      const donorLocation = activeUser.role_assignments.find(role => role.role_name === Roles.DonorLocationAdmin);

      (async () => {
        const verificationStatusResponse = await getVerificationStatus({
          entityId: donorLocation.donor_location_id,
          entityType: 'food_donor',
        });

        const verificationStatusResponseData = await verificationStatusResponse.json();
        dispatch(
          setUIDisabled({
            status:
              verificationStatusResponseData.data.length >= 1 &&
              verificationStatusResponseData.data[0].status !== 'approved',
          })
        );
      })();
    }

    return () => {
      // trigger set floating action button actions on component will unmount
      // that way we can cover all of the possible cases from exiting / skipping the "Welcome Page"
      return setFABActions(activeUser, history, fabContext);
    };
  }, [dispatch]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom data-introid="welcome-message">
          Welcome to Food Rescue US
        </Typography>

        <Box mt={1} mb={2} className={classes.buttonsWrapper}>
          <Button
            data-introid="continue-button"
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.index)}
          >
            Get started
          </Button>

          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.SiteDirector,
            Roles.SiteCoordinator,
          ]) && (
            <Button variant="outlined" color="default" onClick={() => setTourStepper(true, siteDirectorTour)}>
              Take a Tour
            </Button>
          )}
        </Box>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} lg={10} xl={8}>
            <img width="100%" alt="welcome graphic" src={welcomeInfographic} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WelcomePageView;
