import React from 'react';
import { Grid, Chip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';

/**
 * a single chip with the clear icon. calls onClear() when clicked
 */

const useStyles = makeStyles(() => ({
  chipStrip: {},
  chipWrapper: {},
}));

const ClearChip = ({ value, onClick }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.chipStrip}>
      <Grid item className={classes.chipWrapper}>
        <Chip label={value} icon={<ClearIcon onClick={onClick} />} style={{ cursor: 'pointer' }} />
      </Grid>
    </Grid>
  );
};

export default ClearChip;
