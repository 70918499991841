import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../assets/theme/Colors';
import { get } from 'lodash';
import RescueSizesTable from '../components/Common/RescueSizesTable';
import { fetchRescueSizesIfNeeded } from '../actions/rescueSizes';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Info as InfoIcon } from '@material-ui/icons';
import { fetchSystemSettingsIfNeeded } from '../actions/systemSettings';
import { LBS_PER_MEAL } from '../models/systemSettings';

export const shouldShowLegend = isOpen => (isOpen === undefined ? true : isOpen);

const ReportLegend = ({ label = 'Legend', isOpen, onExpandChange }) => {
  const dispatch = useDispatch();

  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const systemSettingsEntities = useSelector(state => state.entities.systemSettings);
  const rescueSizesLegend = rescueSizes.sorted;
  const lbsPerMeal = Object.values(systemSettingsEntities.byId).find(
    systemSetting => systemSetting.name === LBS_PER_MEAL
  );

  useEffect(() => {
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSystemSettingsIfNeeded());
  }, [dispatch]);

  return (
    <Box>
      <Accordion
        elevation={0}
        expanded={shouldShowLegend(isOpen)}
        onChange={(event, isExpanded) => onExpandChange(isExpanded)}
      >
        <AccordionSummary
          style={{ padding: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" alignItems="center">
            <InfoIcon fontSize="large" htmlColor={Colors.blue.main} style={{ paddingRight: '8px' }} />
            <Typography>{label}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box maxWidth={400}>
            <Typography variant="subtitle1" component="p">
              Pounds in reporting indicate default car size or, when provided, custom input by Rescuer or Site Director
            </Typography>
            <Typography variant="subtitle1" component="p">
              Lbs per meal used in calculations: <strong>{get(lbsPerMeal, 'value')}</strong>
            </Typography>
            <RescueSizesTable lbsPerMeal={get(lbsPerMeal, 'value')} rescueSizes={rescueSizesLegend} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ReportLegend;
