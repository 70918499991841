import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import { Roles } from '../models/roles';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getRoleAssignment } from '../helpers/getters';

class RoleAssignmentsTable extends Component {
  constructor(props) {
    super(props);

    const { roleAssignments } = this.props;

    this.state = {
      roleAssignments,
    };
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          padding: '4px 4px 4px 12px',
        },
      },
      MuiTableRow: {
        head: {
          height: '36px',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          padding: '4px 4px 4px 12px',
        },
      },
    },
  });

  render() {
    const { roleAssignments, onDeleteRoleAssignmentButtonClick } = this.props;

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
          title="Role Assignments"
          data={roleAssignments}
          columns={[
            {
              name: 'role_name',
              label: 'Role',
              options: {
                filter: false,
                sort: false,
              },
            },
            {
              name: 'assignment',
              label: 'Assignment',
              options: {
                filter: false,
                sort: false,
                customBodyRenderLite: dataIndex => {
                  const roleAssignment = roleAssignments[dataIndex];
                  return getRoleAssignment(roleAssignment);
                },
              },
            },
            {
              name: 'actions',
              label: 'Actions',
              options: {
                download: false,
                filter: false,
                sort: false,
                display: true,
                customBodyRenderLite: dataIndex => {
                  const value = roleAssignments[dataIndex].actions;
                  return (
                    <TableActionsButtonsWrapper>
                      <TableActionButton
                        title="Delete Assignment"
                        icon={Delete}
                        onClick={() => onDeleteRoleAssignmentButtonClick(roleAssignments[dataIndex])}
                        disabled={[Roles.Admin, Roles.NationalSiteDirector].indexOf(value) >= 0}
                      />
                    </TableActionsButtonsWrapper>
                  );
                },
              },
            },
          ]}
          options={{
            responsive: 'simple',
            selectableRows: 'none',
            pagination: false,
            search: false,
            filter: false,
            download: false,
            print: false,
            viewColumns: false,
          }}
        />
      </MuiThemeProvider>
   );
  }
}

export default RoleAssignmentsTable;
