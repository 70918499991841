import { flatten } from 'lodash';
import moment from 'moment';
import * as marketingHubApi from '../api/marketingHub';
import { shouldFetch } from '../helpers/cache';
import { formatMetricTitle } from '../helpers/formatters';
import { sortByDateAndTime } from '../helpers/sorters';

export const SDK_INIT = 'SDK_INIT';
export const SET_DISPLAY_LINKED_ACCOUNTS = 'SET_DISPLAY_LINKED_ACCOUNTS';
export const REQUEST_USER_LINKED_ACCOUNTS = 'REQUEST_USER_LINKED_ACCOUNTS';
export const RECEIVE_USER_LINKED_ACCOUNTS = 'RECEIVE_USER_LINKED_ACCOUNTS';
export const REQUEST_USER_LINKED_PAGES = 'REQUEST_USER_LINKED_PAGES';
export const RECEIVE_USER_LINKED_PAGES = 'RECEIVE_USER_LINKED_PAGES';
export const REQUEST_USER_FEED = 'REQUEST_USER_FEED';
export const RECEIVE_USER_FEED = 'RECEIVE_USER_FEED';
export const REQUEST_USER_METRICS = 'REQUEST_USER_METRICS';
export const RECEIVE_USER_METRICS = 'RECEIVE_USER_METRICS';
export const REQUEST_USER_POSTS = 'REQUEST_USER_POSTS';
export const RECEIVE_USER_POSTS = 'RECEIVE_USER_POSTS';
export const RECEIVE_FACEBOOK_POST_DELETE = 'RECEIVE_FACEBOOK_POST_DELETE';

export const FACEBOOK_TYPE = 'facebook';
export const INSTAGRAM_TYPE = 'instagram';
export const MESSENGER_TYPE = 'messenger';
export const MARKETING_HUB_ACCOUNT_LINKED = 'MARKETING_HUB_ACCOUNT_LINKED';
export const MARKETING_HUB_FEED_ENGAGED = 'MARKETING_HUB_FEED_ENGAGED';
export const MARKETING_HUB_POST_PUBLISHED = 'MARKETING_HUB_POST_PUBLISHED';
export const MARKETING_HUB_POST_CREATED = 'MARKETING_HUB_POST_CREATED';

export const setSDKInitialized = () => ({
  type: SDK_INIT,
});

export const setDisplayLinkedAccountsOnRescuerDashboard = () => ({
  type: SET_DISPLAY_LINKED_ACCOUNTS,
});

export const requestUserLinkedAccounts = () => ({
  type: REQUEST_USER_LINKED_ACCOUNTS,
});

export const receiveUserLinkedAccounts = (accounts) => ({
  type: RECEIVE_USER_LINKED_ACCOUNTS,
  accounts,
});

export const requestUserLinkedPages = () => ({
  type: REQUEST_USER_LINKED_PAGES,
});

export const receiveUserLinkedPages = (pages) => ({
  type: RECEIVE_USER_LINKED_PAGES,
  pages,
});
export const requestUserFeed = () => ({
  type: REQUEST_USER_FEED,
});

export const receiveUserFeed = (feed) => ({
  type: RECEIVE_USER_FEED,
  feed,
  receivedAt: Date.now(),
});

export const requestUserMetrics = () => ({
  type: REQUEST_USER_METRICS,
});

export const receiveUserMetrics = (metrics) => ({
  type: RECEIVE_USER_METRICS,
  metrics,
  receivedAt: Date.now(),
});

export const requestUserPosts = () => ({
  type: REQUEST_USER_POSTS,
});

export const receiveUserPosts = (posts) => ({
  type: RECEIVE_USER_POSTS,
  posts,
  receivedAt: Date.now(),
});

export const receiveFacebookPostDelete = (postId) => ({
  type: RECEIVE_FACEBOOK_POST_DELETE,
  deletedPostId: postId,
  receivedAt: Date.now(),
});

export const fetchUserLinkedAccounts =
  (userId, accessToken, crud = false) =>
  async (dispatch) => {
    dispatch(requestUserLinkedAccounts());

    const accounts = [];
    // Facebook account
    // const profileResponse = await marketingHubApi.getUserProfileInfo(userId, accessToken);
    // const userInfo = await profileResponse.json();
    // if (userInfo?.name) {
    //   const pictureResponse = await marketingHubApi.getProfilePicture(userId, accessToken);
    //   const profilePicture = await pictureResponse.json();
    //   accounts.push({ ...userInfo, picture: profilePicture.data.url, type: FACEBOOK_TYPE });
    // }

    // Facebook pages
    const pagesResponse = await marketingHubApi.getUserPages(userId, accessToken);
    const pages = await pagesResponse.json();

    if (pages.data?.length) {
      const pagesWithPicture = pages.data.map((page) => ({
        ...page,
        picture: page.picture.data.url,
        type: FACEBOOK_TYPE,
      }));

      accounts.push(...pagesWithPicture);
      if (accounts.length > 0 && crud) {
        marketingHubApi.postCrudAction(MARKETING_HUB_ACCOUNT_LINKED, { account_type: FACEBOOK_TYPE });
      }
    }

    // Instagram accounts
    if (pages.data?.length) {
      const instagramAccounts = await Promise.all(
        pages.data.map(async (page) => {
        const instagramResponse = await marketingHubApi.getInstagramBussinessAccount(page.id, accessToken);
          const instagramAccount = await instagramResponse.json();
          if (instagramAccount?.instagram_business_account) {
            return {
              id: instagramAccount.instagram_business_account.id,
              access_token: accessToken,
              picture: instagramAccount.instagram_business_account?.profile_picture_url,
              name: instagramAccount.instagram_business_account.username,
              type: INSTAGRAM_TYPE,
              link: `https://www.instagram.com/${instagramAccount.instagram_business_account.username}`,
            };
          }

          return null;
        })
      );

      const instagramAccountsFiltered = instagramAccounts.filter((account) => account?.id);

      accounts.push(...instagramAccountsFiltered);

      if (instagramAccountsFiltered.length > 0 && crud) {
        marketingHubApi.postCrudAction(MARKETING_HUB_ACCOUNT_LINKED, { account_type: INSTAGRAM_TYPE });
      }
    }
    return dispatch(receiveUserLinkedAccounts(accounts));
  };

const shouldFetchLinkedAccounts = (state) => {
  const marketingHub = state.entities.marketingHub;
  // TO DO: check for date
  if (marketingHub.accounts.all.length === 0) {
    return true;
  }
  return false;
};

export const fetchUserLinkedAccountsIfNeeded = (userId, accessToken) => (dispatch, getState) => {
  if (shouldFetchLinkedAccounts(getState())) {
    return dispatch(fetchUserLinkedAccounts(userId, accessToken));
  }
  return null;
};

export const fetchUserLinkedPages = (userId, accessToken) => async (dispatch) => {
  dispatch(requestUserLinkedPages());

  const pagesResponse = await marketingHubApi.getUserPages(userId, accessToken);
  const pages = await pagesResponse.json();

  if (pages.data.length) {
    const pagesWithPicture = pages.data.map((page) => ({
      ...page,
      picture: page.picture.data.url,
      type: FACEBOOK_TYPE,
    }));

    return dispatch(receiveUserLinkedPages(pagesWithPicture));
  }

  return null;
};

const shouldFetchLinkedPages = (state) => {
  const marketingHub = state.entities.marketingHub;
  // TO DO: check for date
  if (marketingHub.pages.all.length === 0) {
    return true;
  }
  return false;
};

export const fetchUserLinkedPagesIfNeeded = (userId, accessToken) => (dispatch, getState) => {
  if (shouldFetchLinkedPages(getState())) {
    return dispatch(fetchUserLinkedPages(userId, accessToken));
  }
  return null;
};

export const publishPosts =
  (data, published = true, scheduled_publish_time = null) =>
  async (dispatch) => {
    const fbData = data.filter((account) => account.type === FACEBOOK_TYPE);
    const instagramData = data.filter((account) => account.type === INSTAGRAM_TYPE);

    // FB POST LOGIC
    if (fbData.length > 0) {
      await Promise.all(
        fbData.map(async (page) => {
          // Image post
          if (page.images?.length > 0) {
            const imagesId = [];
            await Promise.all(
              page.images.map(async (image) => {
                const imageResponse = await marketingHubApi.postUnpublishedImage(page.id, page.access_token, {
                  // Check if image is uploaded or added from the assets
                  url: image.uploaded ? image.src : `${window.location.origin}${image.src}`,
                });
                const imageId = await imageResponse.json();

                if (imageId.error) {
                  throw new Error(imageId.error.message);
                }
                imagesId.push(imageId);
              })
            );

            const multipleImagePostResponse = await marketingHubApi.publishPagePostWithMultipleImages(
              page.id,
              page.access_token,
              {
                message: page.content,
                images: imagesId,
                published,
                scheduled_publish_time,
              }
            );
            const multipleImagePostData = await multipleImagePostResponse.json();

            if (multipleImagePostData.error) {
              throw new Error(multipleImagePostData.error.message);
            }

            marketingHubApi.postCrudAction(MARKETING_HUB_POST_CREATED, {
              post_type: FACEBOOK_TYPE,
              id: multipleImagePostData?.id,
            });

            return multipleImagePostData;
          }

          // No image post
          const textPostResponse = await marketingHubApi.publishPagePost(page.id, page.access_token, {
            message: page.content,
            published,
            scheduled_publish_time,
          });
          const textPostData = await textPostResponse.json();

          if (textPostData.error) {
            throw new Error(textPostData.error.message);
          }

          marketingHubApi.postCrudAction(MARKETING_HUB_POST_CREATED, {
            post_type: FACEBOOK_TYPE,
            id: textPostData?.id,
          });

          return textPostData;
        })
      );
    }

    // INSTAGRAM POST LOGIC
    if (instagramData.length > 0) {
      await Promise.all(
        instagramData.map(async (account) => {
          // Single image post
          if (account.images.length === 1) {
            const containerResponse = await marketingHubApi.createInstagramContainer(account.id, account.access_token, {
              // Check if image is uploaded or added from the assets
              url: account.images[0].uploaded
                ? account.images[0].src
                : `${window.location.origin}${account.images[0].src}`,
              caption: account.content,
            });
            const container = await containerResponse.json();

            if (container.error) {
              throw new Error(container.error.message);
            }

            const singePostResponse = await marketingHubApi.postInstagramSingleImage(
              account.id,
              account.access_token,
              container.id
            );
            const singlePostData = await singePostResponse.json();

            if (singlePostData.error) {
              throw new Error(singlePostData.error.message);
            }

            marketingHubApi.postCrudAction(MARKETING_HUB_POST_CREATED, {
              post_type: INSTAGRAM_TYPE,
              id: singlePostData?.id,
            });

            return singlePostData;
          }

          // Multiple image post
          if (account.images.length > 1) {
            const imagesId = [];
            await Promise.all(
              account.images.map(async (image) => {
                const containerResponse = await marketingHubApi.createInstagramMultipleImageContainer(
                  account.id,
                  account.access_token,
                  {
                    // Check if image is uploaded or added from the assets
                    url: image.uploaded ? image.src : `${window.location.origin}${image.src}`,
                  }
                );
                const container = await containerResponse.json();

                if (container.error) {
                  throw new Error(container.error.message);
                }

                imagesId.push(container.id);
              })
            );

            const carouselContainerResponse = await marketingHubApi.createInstagramCarouselContainer(
              account.id,
              account.access_token,
              {
                caption: account.content,
                imagesId: imagesId,
              }
            );

            const carouselContainerData = await carouselContainerResponse.json();

            const carouselResponse = await marketingHubApi.postInstagramCarousel(
              account.id,
              account.access_token,
              carouselContainerData.id
            );

            const carouselData = await carouselResponse.json();

            if (carouselData.error) {
              throw new Error(carouselData.error.message);
            }

            marketingHubApi.postCrudAction(MARKETING_HUB_POST_CREATED, {
              post_type: INSTAGRAM_TYPE,
              id: carouselData?.id,
            });

            return carouselData;
          }

          throw new Error('Instagram post must contain image');
        })
      );
    }
  };

export const fetchUserFeed =
  (accounts = []) =>
  async (dispatch) => {
    dispatch(requestUserFeed());

    const feed = [];
    await Promise.all(
      accounts.map(async (account) => {
        if (account.type === FACEBOOK_TYPE) {
          const response = await marketingHubApi.getFacebookPostsWithComments(account.id, account.access_token)
          const data = await response.json();

          if (data.error) {
            throw new Error(data.error.message);
          }

          const facebookComments = flatten(
            data.data.filter((post) => post.comments?.data?.length > 0).map((post) => post.comments.data)
          );

          const facebookReplies = flatten(
            facebookComments
              .filter((comment) => comment.comments?.data?.length > 0)
              .map((comment) => comment.comments.data)
          );

          const facebookCommentsWithReplies = [...facebookComments, ...facebookReplies];

          facebookCommentsWithReplies
            .filter((comment) => comment.from?.id !== account.id)
            .forEach((comment) => {
              feed.push({
                id: comment.id,
                account_id: account.id,
                type: FACEBOOK_TYPE,
                account_name: account.name,
                created_time: comment.created_time,
                message: comment.message,
                url: comment.permalink_url,
                user_id: comment.from?.id,
                username: comment.from?.name,
                picture_url: comment.from?.picture.data?.url,
              });
            });

          const messagesResponse = await marketingHubApi.getFacebookMessages(account.id, account.access_token);
          const messagesData = await messagesResponse.json();

          if (messagesData.error) {
            throw new Error(messagesData.error.message);
          }

          messagesData.data
            .filter((message) => message.unread_count > 0)
            .forEach((message) => {
              feed.push({
                id: message.id,
                account_id: account.id,
                type: MESSENGER_TYPE,
                account_name: account.name,
                created_time: message.updated_time,
                message: message.snippet,
                url: `https://www.facebook.com${message.link}`,
                user_id: message.messages.data[0]?.from?.id,
                username: message.messages.data[0]?.from?.name,
                picture_url: account.picture,
                unread_messages: message.unread_count,
              });
            });
        }

        if (account.type === INSTAGRAM_TYPE) {
          const response = await marketingHubApi.getInstagramPostsWithComments(account.id, account.access_token)
          const data = await response.json();

          if (data.error) {
            throw new Error(data.error.message);
          }

          const instagramComments = flatten(
            data.media.data
              .filter((post) => post.comments?.data?.length > 0)
              .map((post) => post.comments.data.map((comment) => ({ ...comment, url: post.permalink })))
          );

          instagramComments
            .filter((comment) => comment.user?.id !== account.id)
            .forEach((comment) => {
              feed.push({
                id: comment.id,
                account_id: account.id,
                type: INSTAGRAM_TYPE,
                account_name: account.name,
                created_time: comment.timestamp,
                message: comment.text,
                url: comment.url,
                user_id: comment.user?.id,
                username: comment?.username,
                picture_url: comment.user?.profile_picture_url,
              });
            });
        }
      })
    );

    const sortFeedByDate = (a, b) => sortByDateAndTime('asc', a.created_time, b.created_time);
    const feedByDate = feed.sort(sortFeedByDate);

    dispatch(receiveUserFeed(feedByDate));
  };

const shouldFetchFeed = (state) => {
  const marketingHub = state.entities.marketingHub;
  if (marketingHub.feed.all.length === 0) {
    return true;
  }

  if (marketingHub.inflight) {
    return false;
  }
  return shouldFetch(marketingHub.feed.lastUpdated, { minutes: 5 });
};

export const fetchUserFeedIfNeeded =
  (accounts = []) =>
  (dispatch) =>
    dispatch(fetchUserFeed(accounts));

export const fetchUserMetrics =
  (accounts = [], since = moment().subtract(29, 'days').format('YYYY-MM-DD'), until = '') =>
  async (dispatch) => {
    dispatch(requestUserMetrics());

    const metrics = [];

    await Promise.all(
      accounts.map(async (account) => {
        if (account.type === FACEBOOK_TYPE) {
          const response = await marketingHubApi.getFacebookMetrics(
            account.id,
            account.access_token,
            // Facebook API does not include the first day of date range,
            // so to bypass that and display the correct date range we need to substract 1 day
            moment(since).subtract(1, 'day').format('YYYY-MM-DD'),
            until
          );
          const data = await response.json();

          if (data.error) {
            throw new Error(data.error.message);
          }

          if (data.data.length > 0) {
            const facebookMetrics = data.data.map((metric) => ({
              ...metric,
              account_id: account.id,
              type: account.type,
              title: formatMetricTitle(metric),
            }));
            metrics.push(...facebookMetrics);
          }
        }

        if (account.type === INSTAGRAM_TYPE) {
          const response = await marketingHubApi.getInstagramMetrics(
            account.id,
            account.access_token,
            // Only last 30 day data available due to instagram api limitations
            moment(since).isBefore(moment().subtract(29, 'days'))
              ? moment().subtract(29, 'days').format('YYYY-MM-DD')
              : since,
            until
          );
          const data = await response.json();

          if (data.error) {
            throw new Error(data.error.message);
          }

          if (data.data.length > 0) {
            const instagramMetrics = data.data.map((metric) => ({
              ...metric,
              account_id: account.id,
              type: account.type,
              title: formatMetricTitle(metric),
            }));
            metrics.push(...instagramMetrics);
          }
        }
      })
    );

    return dispatch(receiveUserMetrics(metrics));
  };

export const fetchUserPosts =
  (accounts = []) =>
  async (dispatch) => {
    dispatch(requestUserPosts());

    const posts = [];
    await Promise.all(
      accounts.map(async (account) => {
        if (account.type === FACEBOOK_TYPE) {
          const response = await marketingHubApi.getFacebookPosts(account.id, account.access_token)
          const postsData = await response.json();

          if (postsData.error) {
            throw new Error(postsData.error.message);
          }

          postsData.data.forEach((post) => {
            posts.push({
              ...post,
              date: post.created_time,
              text: post.message,
              post_url: post.permalink_url,
              account_name: account.name,
              account_id: account.id,
              published: true,
              type: FACEBOOK_TYPE,
            });
          });

          const scheduledPostsResponse = await marketingHubApi.getFacebookScheduledPosts(
            account.id,
            account.access_token
          );
          const scheduledPostsData = await scheduledPostsResponse.json();

          if (scheduledPostsData.error) {
            throw new Error(scheduledPostsData.error.message);
          }

          scheduledPostsData.data.forEach((post) => {
            posts.push({
              ...post,
              date: post.created_time,
              text: post.message,
              post_url: post.permalink_url,
              account_name: account.name,
              account_id: account.id,
              published: false,
              type: FACEBOOK_TYPE,
            });
          });
        }

        if (account.type === INSTAGRAM_TYPE) {
          const response = await marketingHubApi.getInstagramPosts(account.id, account.access_token);
          const data = await response.json();

          if (data.error) {
            throw new Error(data.error.message);
          }

          data.media.data.forEach((post) => {
            posts.push({
              ...post,
              date: post.timestamp,
              text: post.caption,
              post_url: post.permalink,
              account_name: account.name,
              account_id: account.id,
              published: true,
              type: INSTAGRAM_TYPE,
            });
          });
        }
      })
    );

    dispatch(receiveUserPosts(posts));
  };

export const fetchUserPostsIfNeeded =
  (accounts = []) =>
  (dispatch) =>
    dispatch(fetchUserPosts(accounts));

export const deleteFacebookPost = (postId, accessToken) => async (dispatch) => {
  const res = await marketingHubApi.deleteFacebookPost(postId, accessToken);
  const json = await res.json();

  if (json.error) {
    throw new Error(json.error.message);
  }

  dispatch(receiveFacebookPostDelete(postId));
};
