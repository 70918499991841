import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add as AddIcon } from '@material-ui/icons';
import { generatePath, Link } from 'react-router-dom';
import { Breadcrumbs, Button, Grid, Typography } from '@material-ui/core';
import routes from '../../../routes';
import EventsTable from './Components/EventsTable';
import { cancelSiteEvent, fetchSiteEventsIfNeeded } from '../../../actions/sites';
import useActiveSite from '../../../hooks/useActiveSite';
import useNotificationService from '../../../hooks/useNotificationService';

const renderCustomEventsTableToolbar = () => {
  return (
    <Button
      component={Link}
      startIcon={<AddIcon />}
      type="button"
      variant="contained"
      color="primary"
      size="small"
      to={generatePath(routes.eventAdd)}
    >
      Add new Event
    </Button>
  );
};

const EventsListView = () => {
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const siteEvents = useSelector((state) => state.entities.sites.events);
  const notificationService = useNotificationService();

  useEffect(() => {
    dispatch(fetchSiteEventsIfNeeded(activeSite.id));
  }, [dispatch]);

  const handleEventCancel = async (event) => {
    try {
      await dispatch(cancelSiteEvent(event));
      notificationService.addSuccessNotification(`Event ${event.name} has been canceled!`);
    } catch (error) {
      notificationService.addErrorNotification(error);
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link to={routes.index} color="inherit">
          Home
        </Link>

        <Typography color="textPrimary">Events</Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12}>
          <EventsTable
            tableId="#events/list-table"
            isLoading={siteEvents.inflight}
            eventsList={siteEvents.bySiteId[activeSite.id] || []}
            customToolbar={renderCustomEventsTableToolbar}
            onCancelEventButtonClick={handleEventCancel}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EventsListView;
