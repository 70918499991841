import Bluebird from 'bluebird';
import * as rescueSizesApi from '../api/rescueSizes';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_RESCUE_SIZES = 'REQUEST_RESCUE_SIZES';
export const RECEIVE_RESCUE_SIZES = 'RECEIVE_RESCUE_SIZES';

export const requestRescueSizes = () => ({
  type: REQUEST_RESCUE_SIZES,
});

export const receiveRescueSizes = rescueSizes => ({
  type: RECEIVE_RESCUE_SIZES,
  rescueSizes,
});

export const fetchRescueSizes = () => dispatch => {
  dispatch(requestRescueSizes());

  return Bluebird
    .try(() => rescueSizesApi.getRescueSizes())
    .then(res => res.json())
    .then(json => dispatch(receiveRescueSizes(json.data)));
};

function shouldFetchRescueSizes(state) {
  const { rescueSizes } = state.entities;

  if (rescueSizes.inflight) {
    return false;
  }

  if (rescueSizes.allIds.length === 0) {
    return true;
  }

  return shouldFetch(rescueSizes.lastUpdated, { hours: 6 });
};

export const fetchRescueSizesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchRescueSizes(getState())) {
    return dispatch(fetchRescueSizes());
  }

  return null;
};
