import * as React from 'react';
import * as authService from '../services/auth';
import frusLogo from '../assets/images/frus-logo-oval.png';
import { makeStyles } from '@material-ui/core/styles';

const backgroundZIndex = 10000;
const useStyles = makeStyles(() => ({
  background: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: '#f4f4f4',
    zIndex: backgroundZIndex,
  },
  imgContainer: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: backgroundZIndex + 1,
  },
}));

const OAuthCallback = () => {
  const classes = useStyles();
  
  authService.handleAuthenticationPopupCallback();
  
  return (
    <>
      <div className={classes.background} />
  
      <div className={classes.imgContainer}>
        <img className="splashscreen" src={frusLogo} alt="splash screen" />
      </div>
    </>
  );
};

export default OAuthCallback;
