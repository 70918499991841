import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import DashboardBadge from '../../../../components/Common/TabBadgeCounter';
import { paths } from '../NSDDashboardDesktop';

const NSDDashboardDesktopAppBar = () => {
  const { url } = useRouteMatch();

  const tabs = [
    { label: 'At a glance', url: paths[0], badgeCounter: null, testId: 'nsdDesktopTab0' },
    { label: 'Leaderboards', url: paths[1], badgeCounter: null, testId: 'nsdDesktopTab1' },
    { label: 'Insight', url: paths[2], badgeCounter: null, testId: 'nsdDesktopTab2' },
    { label: 'Environment Impact', url: paths[3], badgeCounter: null, testId: 'nsdDesktopTab3' },
  ];

  const getActiveTab = () => Math.max(paths.indexOf(url), 0);

  const RenderTabWithBadge = ({ label, url, badgeCounter, testId }) => (
    <Tab
      component={Link}
      to={url}
      label={
        <div>
          <DashboardBadge badgeContent={badgeCounter} color="primary">
            {label}
          </DashboardBadge>
        </div>
      }
      key={testId}
      data-testid={testId}
    />
  );

  return (
    <AppBar position="static">
      <Tabs value={getActiveTab()} aria-label="rescuer dashboard tabs" indicatorColor="primary">
        {tabs.map(RenderTabWithBadge)}
      </Tabs>
    </AppBar>
  );
};

export default NSDDashboardDesktopAppBar;
