import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSiteInsightsIfNeeded } from '../../../actions/sites';
import DashboardInsightsCards from '../../../components/Dashboard/DashboardInsightsCards';
import { fetchRescueSizesIfNeeded } from '../../../actions/rescueSizes';

const SCDashboardInsights = ({ siteId }) => {
  const insights = useSelector(state => state.entities.sites.insights);
  const rescueSizes = useSelector(state => state.entities.rescueSizes.sorted);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSiteInsightsIfNeeded(siteId));
    dispatch(fetchRescueSizesIfNeeded());
  }, []);

  // Show only active rescue sizes on the chart
  const mealsWithOnlyActiveRescueSizes = insights.bySiteId[siteId]?.meals?.rescue_size.filter((size) => rescueSizes.some((s) => s.name === size.name));
  const filteredMeals = insights.bySiteId[siteId]?.meals
    ? { ...insights.bySiteId[siteId]?.meals, rescue_size: mealsWithOnlyActiveRescueSizes }
    : null;

  return (
    <DashboardInsightsCards
      inflight={insights.bySiteId[siteId] ? insights.bySiteId[siteId].inflight : true}
      meals={insights.bySiteId[siteId] ? filteredMeals : null}
      rescuedFood={insights.bySiteId[siteId] ? insights.bySiteId[siteId].rescued_food : null}
    />
  );
};

export default SCDashboardInsights;
