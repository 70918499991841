import Bluebird from 'bluebird';
import * as foodDonorsApi from '../api/foodDonors';
import * as foodDonorsModel from '../models/foodDonors';
import * as usersActions from './users';
import { receiveDonorLocationPost } from './donors';
import * as usersModel from '../models/users';
import { Roles } from '../models/roles';

export const REQUEST_FOOD_DONORS = 'REQUEST_FOOD_DONORS';
export const REQUEST_FOOD_DONOR = 'REQUEST_FOOD_DONOR';
export const REQUEST_FOOD_DONOR_STAFF = 'REQUEST_FOOD_DONOR_STAFF';
export const REQUEST_FOOD_DONOR_PICKUP_LOCATIONS = 'REQUEST_FOOD_DONOR_PICKUP_LOCATIONS';
export const REQUEST_UNASSIGNED_FOOD_DONORS = 'REQUEST_UNASSIGNED_FOOD_DONORS';
export const RECEIVE_FOOD_DONOR = 'RECEIVE_FOOD_DONOR';
export const RECEIVE_FOOD_DONORS = 'RECEIVE_FOOD_DONORS';
export const RECEIVE_FOOD_DONOR_STAFF = 'RECEIVE_FOOD_DONOR_STAFF';
export const RECEIVE_FOOD_DONOR_PICKUP_LOCATIONS = 'RECEIVE_FOOD_DONOR_PICKUP_LOCATIONS';
export const RECEIVE_UNASSIGNED_FOOD_DONORS = 'RECEIVE_UNASSIGNED_FOOD_DONORS';
export const DELETE_UNASSIGNED_FOOD_DONOR = 'DELETE_UNASSIGNED_FOOD_DONOR';

export const requestFoodDonors = () => ({
  type: REQUEST_FOOD_DONORS,
});

export const requestFoodDonor = () => ({
  type: REQUEST_FOOD_DONOR,
});

export const requestFoodDonorStaff = () => ({
  type: REQUEST_FOOD_DONOR_STAFF,
});

export const requestFoodDonorPickupLocations = () => ({
  type: REQUEST_FOOD_DONOR_PICKUP_LOCATIONS,
});

export const requestUnassignedFoodDonors = () => ({
  type: REQUEST_UNASSIGNED_FOOD_DONORS,
});

export const requestUnassignedFoodDonorDelete = foodDonor => ({
  type: DELETE_UNASSIGNED_FOOD_DONOR,
  foodDonor,
  receivedAt: Date.now(),
});

export const receiveFoodDonor = data => ({
  type: RECEIVE_FOOD_DONOR,
  foodDonor: data,
  receivedAt: Date.now(),
});

export const receiveFoodDonors = data => ({
  type: RECEIVE_FOOD_DONORS,
  foodDonors: data,
  receivedAt: Date.now(),
});

export const receiveFoodDonorStaff = (foodDonorId, data) => ({
  type: RECEIVE_FOOD_DONOR_STAFF,
  foodDonorId: foodDonorId,
  foodDonorStaff: data,
  receivedAt: Date.now(),
});

export const receiveFoodDonorPickupLocations = data => ({
  type: RECEIVE_FOOD_DONOR_PICKUP_LOCATIONS,
  foodDonorPickupLocations: data,
  receivedAt: Date.now(),
});

export const receiveUnassignedFoodDonors = data => ({
  type: RECEIVE_UNASSIGNED_FOOD_DONORS,
  foodDonors: data,
  receivedAt: Date.now(),
});

export const fetchFoodDonors = (umbrellaFoodDonorId = null) => dispatch => {
  dispatch(requestFoodDonors());

  return Bluebird
    .try(() => foodDonorsApi.getFoodDonors(umbrellaFoodDonorId))
    .then(res => res.json())
    .then(res => res.data)
    .then(data => dispatch(receiveFoodDonors(data)));
};

export const fetchFoodDonor = foodDonorId => dispatch => {
  dispatch(requestFoodDonor());

  return Bluebird
    .try(() => foodDonorsApi.getFoodDonor(foodDonorId))
    .then(res => res.json())
    .then(res => res.data)
    .then(data => dispatch(receiveFoodDonor(data)));
};

export const fetchFoodDonorsWithPendingVerifications = siteIds => dispatch => {
  dispatch(requestFoodDonor());

  return Bluebird
    .try(() => foodDonorsApi.getFoodDonorsWithPendingVerification(siteIds))
    .then(res => res.json())
    .then(res => res.data)
    .then(data => dispatch(receiveFoodDonors(data)));
};

export const fetchFoodDonorIfNeeded = foodDonorId => dispatch => dispatch(fetchFoodDonor(foodDonorId));

export const fetchFoodDonorStaff = foodDonorId => dispatch => {
  dispatch(requestFoodDonorStaff());

  return Bluebird
    .try(() => foodDonorsApi.getFoodDonorStaff(foodDonorId))
    .then(res => res.json())
    .then(res => res.data)
    .then(data => dispatch(receiveFoodDonorStaff(foodDonorId, data)));
};

export const fetchFoodDonorStaffIfNeeded = foodDonorId => dispatch => dispatch(
  fetchFoodDonorStaff(foodDonorId)
);

export const fetchFoodDonorPickupLocations = foodDonorId => dispatch => {
  dispatch(requestFoodDonorPickupLocations());

  return Bluebird
    .try(() => foodDonorsApi.getFoodDonorPickupLocations(foodDonorId))
    .then(res => res.json())
    .then(res => res.data)
    .then(data => dispatch(receiveFoodDonorPickupLocations(data)));
};

export const fetchFoodDonorPickupLocationsIfNeeded = foodDonorId => dispatch => dispatch(
  fetchFoodDonorPickupLocations(foodDonorId)
);

export const createFoodDonor = data => () => Bluebird
  .try(() => foodDonorsApi.createFoodDonor(foodDonorsModel.mapFormToCreateFoodDonorPOST(data)))
  .then(res => res.json())
  .then(res => res.data)
  .then(data => {
    if (!data.user) {
      return false;
    }

    return usersActions.receiveUserAndReflectAllUserStates(data.user);
  });

export const updateFoodDonor = (foodDonorId, data) => dispatch => Bluebird
  .try(() => foodDonorsApi.updateFoodDonor(foodDonorId, foodDonorsModel.mapFormToUpdateFoodDonorPATCH(data)))
  .then(res => res.json())
  .then(json => {
    dispatch(receiveDonorLocationPost(json));
    dispatch(fetchFoodDonor(foodDonorId));

    usersActions.receiveUserAndReflectAllUserStates(json.user);
  });

export const createFoodDonorPickupLocation = (foodDonorId, data) => () => foodDonorsApi.createFoodDonorPickupLocation(
  foodDonorId,
  foodDonorsModel.mapFormToCreateFoodDonorPickupLocationPOST(data)
);

export const createFoodDonorStuff = (foodDonorId, data) => () =>
  foodDonorsApi.createFoodDonorStuff(foodDonorId, foodDonorsModel.mapFormToCreateFoodDonorStuffPost(data));

export const deleteFoodDonorStuff = (foodDonorId, staffId, { isAdmin }) => async () =>
  foodDonorsApi.deleteFoodDonorStuff(foodDonorId, staffId, {
    role_to_detach: usersModel.transformRoleToApi(isAdmin ? Roles.DonorLocationAdmin : Roles.DonorLocationStaff),
  });

export const updateFoodDonorPickupLocation = (foodDonorId, pickupLocationId, data) => () =>
  foodDonorsApi.updateFoodDonorPickupLocation(
    foodDonorId,
    pickupLocationId,
    foodDonorsModel.mapFormToUpdateFoodDonorPickupLocationPUT(data)
  );

export const fetchUnassignedFoodDonors = () => dispatch => {
  dispatch(requestUnassignedFoodDonors());

  return foodDonorsApi
    .getUnassignedFoodDonors()
    .then(response => response.json())
    .then(response => response.data)
    .then(data => dispatch(receiveUnassignedFoodDonors(data)));
};

export const deleteUnassignedFoodDonor = foodDonor => dispatch =>
  Bluebird.try(() => foodDonorsApi.deleteUnassignedFoodDonor(foodDonor.id)).then(() => {
    dispatch(requestUnassignedFoodDonorDelete(foodDonor));
  });

export const deleteFoodDonorPickupLocationContact = (foodDonorId, pickupLocationId) => async () =>
  foodDonorsApi.updateFoodDonorPickupLocation(foodDonorId, pickupLocationId, {
    user: {
      email: null,
      firstname: null,
      lastname: null,
      phone: null,
      phone_ext: null,
      phone_type: null,
      send_invitation: null,
    },
    user_id: null,
  });
