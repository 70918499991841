import Bluebird from 'bluebird';
import * as authService from '../services/auth';
import {
  dismissEmailVerificationNotification,
  dismissPhoneVerificationNotification,
  resetUI,
  showEmailVerificationNotification, showPhoneVerificationNotification,
} from './uiPersisted';
import { Roles } from '../models/roles';
import * as usersActions from './users';
import { fetchNotifications } from './notifications';
import * as uiPersistedActions from './uiPersisted';
import { invalidateRescuesData } from './rescues';
import { invalidateRescuerEvents } from './rescuers';

export const SET_LOGGING_IN = 'SET_LOGGING_IN';
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const SET_USER_REGISTRATION = 'SET_USER_REGISTRATION';
export const SET_USER_REGISTRATION_DATA = 'SET_USER_REGISTRATION_DATA';
export const SET_IMPERSONATED_USER = 'SET_IMPERSONATED_USER';
export const RESET_STATE_TO_DEFAULT = 'RESET_STATE_TO_DEFAULT';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';

export const setLoggingIn = loggingIn => ({
  type: SET_LOGGING_IN,
  loggingIn,
});

export const setAuthenticatedUser = (accessToken = null, idToken = null, idTokenPayload = null) => ({
  type: SET_AUTHENTICATED_USER,
  accessToken: accessToken,
  idToken: idToken,
  idTokenPayload: idTokenPayload,
});

// @todo this is not an 'auth action' => it changes the application state
// it should be an app action handled by the app reducer
export const setImpersonatedUser = (user = null, resetUiState = true) => dispatch => {
  dispatch({
    type: SET_IMPERSONATED_USER,
    user,
  });

  if (resetUiState) {
    dispatch(uiPersistedActions.resetDefaultDashboard());
    dispatch(invalidateRescuesData()); // invalidate rescues data in case admin switches between rescuers
    
    if (user) {
      dispatch(invalidateRescuerEvents(user.id)); // invalidate rescues data in case admin switches between rescuers
    }
    
    dispatch(resetUI());
  }
};

// @todo this is not an 'auth action' => it changes the application state
// it should be an app action handled by the app reducer
export const setLoggedInUser = data => ({
  type: SET_LOGGED_IN_USER,
  user: data,
});

export const setUserRegistration = (
  accessToken = null,
  idToken = null,
  idTokenPayload = null,
  data = null
) => ({
  type: SET_USER_REGISTRATION,
  accessToken,
  idToken,
  idTokenPayload,
  data,
});

export const setUserRegistrationData = data => ({
  type: SET_USER_REGISTRATION_DATA,
  data,
});

export const resetStateToDefault = () => ({
  type: RESET_STATE_TO_DEFAULT,
});

export const changeUser = userId => (dispatch, getState) => {
  const currentlyLoggedInUser = authService.getCurrentlyLoggedInUser();
  let user;

  return Bluebird
    .try(() => dispatch(usersActions.fetchUser(userId))) // fetch user to get detailed info about role_assignments
    .then(() => {
      user = getState().entities.users.byId[userId];

      return Promise.all([
        dispatch(setImpersonatedUser(currentlyLoggedInUser.id === userId ? null : user, true)),
        authService.setDefaultSiteAndSites(user.role_assignments),
      ]);
    })
    .then(() => {
      if (!authService.hasRoleInCurrentlySelectedSite(user, Roles.Rescuer)) {
        dispatch(dismissEmailVerificationNotification());
        dispatch(dismissPhoneVerificationNotification());
      } else {
        dispatch(showEmailVerificationNotification());
        dispatch(showPhoneVerificationNotification());
      }
      
      dispatch(fetchNotifications());
    });
};
