import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button, makeStyles, Paper, Tooltip, Typography, Link } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { apiPOST } from '../api/api';
import routes from '../routes';
import snackbarHelper from '../helpers/snackbarHelper';
import { Colors } from '../assets/theme/Colors';
import SubmitWithSendEmailConfirmation from './SubmitWithSendEmailConfirmation';
import OverlayLoader from './OverlayLoader';
import { getUserFullNameWithEmail } from '../helpers/user';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}));

const SendEmailPreview = ({ content, handleClosePreview, recipients, submitCallback }) => {
  const [emailConent, setEmailContent] = useState();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const getRenderedEmail = async () => {
    try {
      const response = await apiPOST('/notifications/render_email', {
        type: 'SITE_DIRECTOR_EMAIL',
        params: {
          body: content.body,
        },
      });

      const data = await response.json();

      if (!data.data.content) {
        return snackbarHelper.error('Unable to preview this email. Please try again');
      }

      return setEmailContent(data.data.content);
    } catch (error) {
      handleClosePreview();
      return snackbarHelper.error(error);
    }
  };

  useEffect(() => {
    getRenderedEmail();
  }, [content]);

  const onEmailSend = async () => {
    try {
      setLoading(true);
      await submitCallback(content);
      snackbarHelper.success(`E-mail send successfully out to ${recipients.length} rescuer(s)!`);
      return history.push(routes.rescuers);
    } catch (error) {
      setLoading(false);
      return snackbarHelper.error(error);
    }
  };

  const renderUserProfileLink = (user) => {
    if (!user) {
      return '-';
    }

    return (
      <Link onClick={() => history.push(generatePath(routes.userEditNew, { id: user.id }))} style={{ cursor: 'pointer' }}>
        {getUserFullNameWithEmail(user)}
      </Link>
    );
  };

  return (
    <OverlayLoader isLoading={loading}>
      <Paper className={classes.paper} data-testid="email-recipients-paper">
        <Typography variant="h6">
          {`Recipients (${recipients.length}):`}

          <Tooltip arrow placement="top" title="List of users who are going to receive this e-mail.">
            <HelpIcon htmlColor={Colors.blue.main} style={{ position: 'relative', top: 5, marginLeft: 5 }} />
          </Tooltip>
        </Typography>

        {recipients.length > 0 ? (
          <ul>
            {recipients.slice(0, 10).map((recipient) => (
              <li key={recipient.id}>{renderUserProfileLink(recipient)}</li>
            ))}

            {recipients.length > 10 && (
              <li>
                <em>{`+${recipients.length - 10} recipients more...`}</em>
              </li>
            )}
          </ul>
        ) : (
          <Typography data-testid="no-verified-recipients-help" color="error">
            You don't have any recipients with verified e-mail address selected therefore you will
            not be able to send the e-mail.
          </Typography>
        )}
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="h6">Reply To:</Typography>
        <Typography>{content.reply_to}</Typography>
      </Paper>
      <Paper className={classes.paper}>
        <Typography variant="h6">Subject:</Typography>
        <Typography>{content.subject}</Typography>
      </Paper>
      <Paper className={classes.paper}>
        <Typography variant="h6">Preview</Typography>
        {emailConent ? <div dangerouslySetInnerHTML={{ __html: emailConent }} /> : <div>Template loading...</div>}
        <Button className={classes.button} variant="contained" color="primary" onClick={handleClosePreview}>
          Go Back
        </Button>
        <SubmitWithSendEmailConfirmation
          className={classes.button}
          title="Heads up!"
          message={`Do you really want to send this e-mail out to ${recipients.length} recipient(s)?`}
          confirmButtonLabel="Send e-mail"
          label="Send E-mail"
          onButtonNotSend={() => true}
          onButtonSend={() => onEmailSend()}
          data-testid="send-email-submit-button"
          showConfirmation
        />
      </Paper>
    </OverlayLoader>
  );
};
export default SendEmailPreview;
