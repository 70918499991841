import React, { useContext } from 'react';
import { withStyles, Box } from '@material-ui/core';
import FABContext from '../../context/FABContext/FABContext';

const SubmitButtonWrapper = withStyles(({ palette, spacing, shadows, breakpoints }) => ({
  root: {
    background: '#fff',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    left: 0,
    padding: spacing(2, 3),
    boxShadow: shadows[25],
    borderTop: `1px solid ${palette.divider}`,
    zIndex: 1,
    [breakpoints.up('sm')]: {
      position: 'unset',
      padding: spacing(2, 2),
      borderTop: 'unset',
    },
  },
}))(({ children, ...props }) => {
  const context = useContext(FABContext);
  return (
    <Box {...props} data-testid="save-btn-wrapper">
      <Box pr={context.isVisible ? 8 : 0}>{children}</Box>
    </Box>
  );
});

export default SubmitButtonWrapper;
