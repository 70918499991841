import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs, Typography, Grid } from '@material-ui/core';
import * as sitesActions from '../actions/sites';
import SmsTable from '../components/SmsTable';

const SmsView = () => {
  const dispatch = useDispatch();
  const site = useSelector(state => state.app.site);
  const siteSms = useSelector(state => state.entities.sites.sms);
  const siteSmsList = siteSms.bySiteId[site.id] || [];

  const data = siteSmsList.map(row => ({
    ...row,
    number_of_recipients: row.users_ids.length,
  }));

  useEffect(() => {
    dispatch(sitesActions.fetchSiteSmsIfNeeded(site.id));
  }, []);

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Typography color="textPrimary">
          Sent SMS
        </Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12}>
          <SmsTable
            tableId="#sms-table"
            isLoading={siteSms.inflight}
            emails={data}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SmsView;
