import React, { useEffect, useContext } from 'react';
import { Box, makeStyles, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { generatePath, Link, useLocation } from 'react-router-dom';
import DashboardMobileTabBadgeCounter from './DashboardMobileTabBadgeCounter';
import FABContext from '../../context/FABContext/FABContext';
import routes from '../../routes';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    position: 'absolute',
    height: 'calc(100% - calc(58px * 2) - var(--safe-area-inset-bottom))', // page height - (navbar + bottombar) - iphone spacing
    width: '100%',
    margin: -8,
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    marginBottom: `calc(56px + var(safe-area-inset-bottom))`,
  },
  bottomWrapper: {
    position: 'fixed',
    backgroundColor: '#fafafa',
    paddingBottom: 'var(--safe-area-inset-bottom)',
    zIndex: 2,
    bottom: 0,
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    borderTop: '1px solid #d4d4d4',
  },
  bottomNavigationRoot: {
    backgroundColor: '#fafafa',
    zIndex: 1,
    width: '100%',
    margin: '0 auto',
  },
  bottomNavigationActionSelected: {
    transition: 'background-color 0.2s ease, color 0.2s ease',
    color: palette.secondary.main,
    '& svg': {
      color: palette.secondary.main,
    },
  },
  bottomNavigationActionLabel: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    '&.Mui-selected': {
      fontSize: '0.75rem',
    },
  },
  bottomNavigationActionRoot: {
    textAlign: 'center',
  },
  badge: {
    top: 10,
    right: -5,
  },
}));

const RenderTabWithBadge = ({ label, url, icon, badgeCounter, testId }) => {
  const classes = useStyles();

  return (
    <BottomNavigationAction
      classes={{
        root: classes.bottomNavigationActionRoot,
        selected: classes.bottomNavigationActionSelected,
        label: classes.bottomNavigationActionLabel,
      }}
      component={Link}
      to={url}
      icon={
        <DashboardMobileTabBadgeCounter
          classes={{
            badge: classes.badge,
          }}
          badgeContent={badgeCounter}
          color="primary"
        >
          {icon}
        </DashboardMobileTabBadgeCounter>
      }
      label={label}
      key={testId}
      data-testid={testId}
    />
  );
};

const MobileWrapper = ({
  activeTab,
  breadcrumb,
  bottomTabs,
  pageContent,
  containerId = 'dashboard-mobile-div',
  bottomBarId = 'mobile-app-bar',
}) => {
  const classes = useStyles();
  const fabContext = useContext(FABContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === generatePath(routes.mobileDashboardRescues, { tab: 'schedule' })) {
      // hide FAB on schedule tab if FF active
      // remove this code once FF is active in all sites
      fabContext.updateFABStyles({
        speedDial: {
          display: 'none',
        },
      });
    } else {
      // move FAB above bottom tabs
      fabContext.updateFABStyles({
        speedDial: {
          display: 'flex',
          bottom: `calc(56px + 15px + ${getComputedStyle(document.documentElement).getPropertyValue(
            '--safe-area-inset-bottom'
          )})`,
        },
      });
    }

    // reset FAB styles on component unmount
    return () => fabContext.updateFABStyles({});
  }, [location.pathname]);

  return (
    <Box data-testid={containerId} className={classes.container}>
      {breadcrumb}

      <Box className={classes.wrapper}>{pageContent}</Box>

      {bottomTabs.length > 0 && (
        <Box className={classes.bottomWrapper}>
          <BottomNavigation
            classes={{
              root: classes.bottomNavigationRoot,
            }}
            value={activeTab}
            showLabels
            id={bottomBarId}
          >
            {bottomTabs.map(RenderTabWithBadge)}
          </BottomNavigation>
        </Box>
      )}
    </Box>
  );
};

export default MobileWrapper;
