import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box, CardContent, Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import OverlayLoader from '../OverlayLoader';
import { BaseCard, BaseCardHeader } from '../Common/BaseCard';

const CardValue = withStyles({
  root: {
    color: '#495057',
    lineHeight: 1.2,
    letterSpacing: -2,
  },
})(({ children, isLoading, tooltipTitle = '', ...rest }) => (
  <Tooltip title={tooltipTitle}>
    <Typography variant="h3" component="div" {...rest}>
      {isLoading ? <Skeleton width={120} /> : children}
    </Typography>
  </Tooltip>
));

const StyledBaseCard = withStyles({
  root: {
    height: '100%',
  },
})(BaseCard);

const CardDiffValue = withStyles({
  root: {
    lineHeight: 1.2,
    float: 'right',
    letterSpacing: -2,
  },
})(({ children, isLoading, tooltipTitle = '', ...rest }) => (
  <Tooltip title={tooltipTitle}>
    <Typography variant="h4" component="div" {...rest}>
      {isLoading ? <Skeleton width={70} /> : children}
    </Typography>
  </Tooltip>
));

export const cardColors = {
  '-1': '#d92550',
  '0': '#30b1ff',
  '1': '#3ac47d',
};

export const DashboardCard = ({
  title,
  totalValue,
  currentValue,
  totalValueTooltip = '',
  currentValueTooltip = '',
  isLoading,
}) => {
  return (
    <OverlayLoader isLoading={isLoading} wrapperStyles={{ height: '100%' }}>
      <StyledBaseCard>
        <BaseCardHeader titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }} title={title} />
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="nowrap" gridColumnGap={8}>
            <Box flexGrow={1}>
              <CardValue isLoading={isLoading} tooltipTitle={totalValueTooltip}>
                {totalValue}
              </CardValue>
            </Box>
            {currentValue && currentValue.value !== null && (
              <Box flexGrow={1}>
                <CardDiffValue isLoading={isLoading} tooltipTitle={currentValueTooltip}>
                  <Box color={currentValue.color}>{currentValue.value}</Box>
                </CardDiffValue>
              </Box>
            )}
          </Box>
        </CardContent>
      </StyledBaseCard>
    </OverlayLoader>
  );
};
