import React, { useState, useEffect } from 'react';
import { FormControl, FormLabel, FormControlLabel, Checkbox, FormGroup, FormHelperText, Grid } from '@material-ui/core';
import { CheckBox as CheckBoxIcon, IndeterminateCheckBox as IndeterminateCheckBoxIcon } from '@material-ui/icons';
import { TextFieldWrapper } from '../../../../../components/Form/MuiFormWrapper';
import {
  foodTypes as foodTypesModel,
  newFoodTypes as newFoodTypesModel,
  foodTypesSubCategory,
} from '../../../../../models/foodTypes';

const FooTypeListItem = ({ foodTypes, foodType, disabled, handleCheck }) => (
  <FormGroup>
    <FormControlLabel
      key={foodType.name}
      label={foodType.label}
      data-testid={foodType.label}
      control={
        <Checkbox
          disabled={disabled}
          name={foodType.name}
          // checkedIcon={foodType.name === 'eggs' || foodType.name === 'non-food' ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
          checked={(foodTypes || []).includes(foodType.name)}
          value={foodType.name}
          onChange={handleCheck}
        />
      }
    />
    {/* {(foodTypes || []).includes(foodType.name) && (
      <FormGroup style={{ marginLeft: 15 }}>
        {foodTypesSubCategory
          .filter((subCategory) => subCategory.type === foodType.name)
          .map((subCategory) => (
            <FormControlLabel
              key={subCategory.name}
              label={subCategory.label}
              control={
                <Checkbox
                  disabled={disabled}
                  name={subCategory.name}
                  checked={(foodTypes || []).includes(subCategory.name)}
                  value={subCategory.name}
                  onChange={handleCheck}
                />
              }
            />
          ))}
      </FormGroup>
    )} */}
  </FormGroup>
);

const FoodTypes = ({
  errors = {},
  foodTypeOther,
  disabled,
  foodTypes,
  onFoodTypeChange,
  onFoodTypeOtherChange,
  onSubCategoryChange,
}) => {
  const [foodTypeOtherValue, setFoodTypeOtherValue] = useState(foodTypeOther);

  useEffect(() => {
    setFoodTypeOtherValue(foodTypeOther); //update value on redux change
  }, [foodTypeOther]);

  const handleCheck = event =>
    onFoodTypeChange({
      foodType: event.target.name,
      checked: event.target.checked,
    });

  const handleSubCheck = event => 
    onSubCategoryChange({
      category: event.target.name,
      checked: event.target.checked,
    });

  return (
    <FormControl error={errors.hasOwnProperty('food_types') || errors.hasOwnProperty('food_type_other')} data-testid="food_types">
      <FormLabel component="legend" id="legend-food_types">
        Rescue Contents
      </FormLabel>

      <Grid container spacing={1}>
        {/*  Render array in two seperate columns */}
        <Grid item sm={6}>
        {newFoodTypesModel.slice(0, Math.ceil(newFoodTypesModel.length / 2)).map((foodType) => (
            <FooTypeListItem
              key={foodType.name}
              foodTypes={foodTypes}
              foodType={foodType}
              disabled={disabled}
              handleCheck={handleCheck}
            />
          ))}
        </Grid>
        <Grid item sm={6}>
          {newFoodTypesModel.slice(Math.ceil(newFoodTypesModel.length / 2)).map((foodType) => (
            <FooTypeListItem
              key={foodType.name}
              foodTypes={foodTypes}
              foodType={foodType}
              disabled={disabled}
              handleCheck={handleCheck}
            />
          ))}
        </Grid>
      </Grid>

      {errors.hasOwnProperty('food_types') && (
        <FormHelperText error id="food_types-error">
          {errors.food_types.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FoodTypes;
