import React from 'react';
import { Typography, Box, Tooltip } from '@material-ui/core';
import moment from 'moment';
import CircularProgressWithLabel from '../../../components/CircularProgressWithValue';
import { getLabelFromType } from '../../../helpers/siteGoals';
import { Colors } from '../../../assets/theme/Colors';
import { getPercentage } from '../../../helpers/math';

const SCDashboardSiteGoalsProgress = ({ siteGoalsList = [] }) => {
  if (!siteGoalsList) {
    return 'No site goals available to display.';
  }

  return (
    <Box display="flex" flexWrap="wrap" gridColumnGap={16} gridRowGap={16}>
      {siteGoalsList &&
        siteGoalsList.length > 0 &&
        siteGoalsList.map((siteGoal) => (
          <Tooltip
            key={siteGoal.id}
            arrow
            placement="top"
            title={
              <Box display="flex" flexDirection="column">
                <Typography variant="caption">
                  {getLabelFromType(siteGoal.type)}: {Math.round(siteGoal.value)}/{siteGoal.goal}
                </Typography>
                {getPercentage(siteGoal.value, siteGoal.goal) < 100 ? (
                  <Typography variant="caption">
                    {getPercentage(siteGoal.value, siteGoal.goal)}% completed to achieve goal
                  </Typography>
                ) : (
                  <Typography variant="caption">Goal achieved</Typography>
                )}
              </Box>
            }
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgressWithLabel
                style={getPercentage(siteGoal.value, siteGoal.goal) >= 100 ? { color: Colors.green.light } : {}}
                value={getPercentage(siteGoal.value, siteGoal.goal)}
                size={140}
                thickness={2.5}
              >
                <div>
                  <Typography variant="caption" component="p" color="textPrimary">
                    {moment(siteGoal.date).format('MMMM YYYY')}
                  </Typography>
                  <Typography variant="caption" component="p" color="textSecondary">
                    {getLabelFromType(siteGoal.type)}
                  </Typography>
                  <Typography variant="caption" component="p" color="textSecondary">
                    {Math.round(siteGoal.value) }/{siteGoal.goal}
                  </Typography>
                </div>
              </CircularProgressWithLabel>
            </Box>
          </Tooltip>
        ))}
    </Box>
  );
};

export default SCDashboardSiteGoalsProgress;
