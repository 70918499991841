import auth0 from 'auth0-js';
import Bluebird from 'bluebird';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { generatePath } from 'react-router-dom';
import { store } from '../store/index';
import * as authActions from '../actions/auth';
import * as appActions from '../actions/app';
import * as usersActions from '../actions/users';
import * as sitesActions from '../actions/sites';
import * as uiPersistedActions from '../actions/uiPersisted';
import * as authApi from '../api/auth';
import * as usersModel from '../models/users';
import snackbarHelper from '../helpers/snackbarHelper';
import routes from '../routes/index';
import { resetUI } from '../actions/uiPersisted';
import * as userRegistrationsApi from '../api/userRegistrations';
import { Roles } from '../models/roles';
import errorMessages from '../assets/errorMessages';
import history from '../history';
import { setFABActions } from '../helpers/fab';

const customClaimsUrl = 'https://app.foodrescue.us/claims';
let authPopup;

const auth = new auth0.WebAuth({
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: 'frus.auth0.com',
  responseType: 'token id_token',
  audience: 'https://api.foodrescue.us',
  redirectUri: `${window.location.origin.toString()}/callback`,
  scope: 'openid profile email'
});

export const getIdTokenPayloadCustomClaim = (idTokenPayload, claimName) => {
  return idTokenPayload[`${customClaimsUrl}/${claimName}`];
};

export const handleAuthenticationPopupCallback = () => auth.popup.callback(); // goes to authorizeWithGoogle callback

export const setFABActionsForCurrentlyLoggedInUser = fabContext => {
  const { app: { loggedInUser } } = store.getState();

  // let's trigger set floating action button actions only for users who completed registration process
  if (!loggedInUser || !loggedInUser.completed_registration) {
    return false;
  }

  return setFABActions(loggedInUser, history, fabContext);
};

// return promise from auth0 call (called from LoginPage.handleLoginWithGoogle()
export const authorizeWithGoogle = (options, fabContext = false) => {
  // force close auth popup - to fix "minimizing" behaviour in PWA
  if (authPopup) {
    authPopup.close();
  }

  authPopup = auth.popup.authorize(
    {
      ...options,
      prompt: 'login',
      connection: 'google-oauth2',
      redirectUri: `${window.location.origin.toString()}/oauth/callback`,
    },
    (error, authResults) => {
      if (error) {
        if (error.original !== 'User closed the popup window' && error.hasOwnProperty('errorDescription')) {
          snackbarHelper.error(error.errorDescription);
        }

        return logout();
      }

      return Bluebird
        .try(() => store.dispatch(authActions.setLoggingIn(true)))
        .then(() => authApi.loginViaSocial(authResults.accessToken, authResults.idToken))
        .then(res => res.json())
        .then(res => res.data)
        .then(data => handleAuthentication(history, data))
        .then(() => {
          if (!fabContext) {
            return false;
          }

          return setFABActionsForCurrentlyLoggedInUser(fabContext);
        })
        .catch(err => {
          if (err.code === errorMessages.ERR_SYSTEM_NOT_AVAILABLE.code) {
            return history.push(routes.systemNotAvailable);
          }

          console.error('authorizeWithGoogle error caught: ', err);
        })
        .finally(() => store.dispatch(authActions.setLoggingIn(false)));
    }
  );
};

export const userInfo = (accessToken, callback) => auth.client.userInfo(accessToken, callback);

// called from Login page onSubmit() with user return from our API (getUserByEmail)
export const handleAuthentication = async (history, authResults) => {
  if (authResults && authResults.access_token && authResults.id_token) {
    const { user } = authResults;

    console.log('handleAuthentication: ', {user} );
    // @todo why are the parms user.id and user.role_assignments and not just
    // the user {} ?
    if (user && user.completed_registration) {
      return login(
        authResults.access_token,
        authResults.id_token,
        authResults.id_token_payload,
        user.id,
        user.role_assignments,
        history,
        false,
      );
    }

    let idTokenPayloadData = authResults.id_token_payload;

    if (user && user.invitation_hash) {
      idTokenPayloadData = {
        ...idTokenPayloadData,
        id: user.id,
        invitation_hash: user.invitation_hash,
      }
    }

    store.dispatch(authActions.setAuthenticatedUser());
    store.dispatch(authActions.setImpersonatedUser());
    store.dispatch(authActions.setUserRegistration(authResults.access_token, authResults.id_token, idTokenPayloadData));

    // here's the redirect if an email+passowrd authentication is successful,
    // the user has an incomplete registration
    // the registration complete path is hidden in login()
    const userRegistrationType = await (async () => {
      const res = await userRegistrationsApi.getUserRegistrationByEmail(
        idTokenPayloadData.email,
        authResults.access_token
      );
      const json = await res.json();

      if (!json.data.length) {
        return 'rescuer';
      }

      return json.data[0].type;
    })();

    history.push(generatePath(routes.completeRegistrationNew));

  } else {
    snackbarHelper.error('Error occurred during authentication process.');

    return logout();
  }
};

export const login = (accessToken, idToken, idTokenPayload, userId, roleAssignments, history, firstVisit) => {
  console.log('login: ', { roleAssignments } );
  store.dispatch(authActions.setUserRegistration());

  store.dispatch(
    authActions.setAuthenticatedUser(
      accessToken,
      idToken,
      usersModel.apiToStore({
        ...idTokenPayload,
        ...{
          id: userId,
          role_assignments: roleAssignments,
        }
      })
    )
  );

  // fetchAndSetLoggedInUser requires fetchAndSetLoggedInUser to be called first

  // and, finally, we see the redirect for a successful email+password login
  // for a user with a completed registration
  return Bluebird
    .try(() => setDefaultSiteAndSites(roleAssignments))
    .then(() => store.dispatch(usersActions.fetchAndSetLoggedInUser(userId)))
    .then(() => {
      if (firstVisit) {
        return history.push(routes.welcomePage);
      }

      return history.push(routes.index);
    });
};

export const createProfile = (userRegistration, history) =>
  Bluebird.try(() =>
    userRegistrationsApi.updateUserRegistration(
      userRegistration.data.id,
      {
        create_profile: true,
      },
      userRegistration.accessToken,
    ),
  )
    .then(res => res.json())
    .then(res =>
      login(
        userRegistration.accessToken,
        userRegistration.idToken,
        userRegistration.idTokenPayload,
        res.id,
        res.role_assignments,
        history,
        true,
      ),
    );

export const logout = () => {
  store.dispatch(authActions.resetStateToDefault());
  store.dispatch(resetUI());
  store.dispatch(uiPersistedActions.closeDrawer());
};

export const hasRoleInCurrentlySelectedSite = (user, role) => {
  const { app } = store.getState();

  if (!user || !user.role_assignments || !app.site) {
    return false;
  }

  return getUserRolesInCurrentlySelectedSite(user)
    .filter(roleAssignment => roleAssignment.role_name === role)
    .length > 0;
};

export const hasAnyRoleInCurrentlySelectedSite = (user, roles) => {
  return roles.some(role => hasRoleInCurrentlySelectedSite(user, role));
};

export const hasAnyRoleInSite = (user, siteId, roles) => {
  if (!user || !user.role_assignments) {
    return false;
  }

  const userRoles = getUserRolesInSite(user, siteId);

  return roles.some(role => userRoles.find(ra => ra.role_name === role));
};

export const hasRoleInAnySite = (user, role) => {
  if(!user || !user.role_assignments) {
    return false;
  }

  return getUserSitesByRole(user.role_assignments, role).length > 0
}

export const hasOnlyRoleInCurrentlySelectedSite = (user, role) => {
  if (user === undefined || user.role_assignments === undefined) {
    return false;
  }

  const roles = getUserRolesInCurrentlySelectedSite(user);

  return roles.length === 1 && hasRoleInCurrentlySelectedSite(user, role);
};

export const getUserRolesInSite = (user, siteId) => _getGroupedGlobalAndNonGlobalRoles(user.role_assignments, siteId);

export const getUserRolesInCurrentlySelectedSite = user => {
  const { app } = store.getState();

  if (!app.site) {
    return [];
  }

  return _getGroupedGlobalAndNonGlobalRoles(user.role_assignments, app.site.id);
};

export const currentlyLoggedInOrImpersonatingUserHasRoleInCurrentlySelectedSite = role => {
  return hasRoleInCurrentlySelectedSite(getCurrentlyLoggedInOrImpersonatingUser(), role);
};

export const currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite = roles => {
  return roles.some( role => (currentlyLoggedInOrImpersonatingUserHasRoleInCurrentlySelectedSite(role)));
};

export const setCurrentlyLoggedInOrImpersonatingUser = user => {
  if (isInImpersonatingMode()) {
    store.dispatch(
      authActions.setImpersonatedUser(usersModel.apiToStore(user), false)
    );
  } else {
    store.dispatch(
      authActions.setLoggedInUser(usersModel.apiToStore(user))
    );
  }
};

export const updateCurrentlyLoggedInAndImpersonatingUserIfNeeded = user => {
  const { app } = store.getState();
  const transformedUser = usersModel.apiToStore(user);

  if (app.loggedInUser.id === user.id) {
    store.dispatch(
      authActions.setLoggedInUser(transformedUser)
    );
  }

  if (app.impersonating && app.impersonating.id === user.id) {
    store.dispatch(
      authActions.setImpersonatedUser(transformedUser, false)
    );
  }
};

export const getCurrentlyLoggedInOrImpersonatingUser = () => {
  const currentlyLoggedInUser = getCurrentlyLoggedInUser();

  if (!currentlyLoggedInUser) {
    return false;
  }

  const impersonatingUser = getImpersonatingUser();

  return impersonatingUser ? impersonatingUser : currentlyLoggedInUser;
};

export const getCurrentlyLoggedInOrImpersonatingUserAvailableSitesIds = () => {
  const user = getCurrentlyLoggedInOrImpersonatingUser();
  let availableSitesIds = new Set();

  if (user) {
    user.role_assignments.forEach(roleAssignment => availableSitesIds.add(roleAssignment.site_id));
  }

  const siteIds = Array.from(availableSitesIds.values());
  console.log( 'availableSiteIds: ', siteIds );
  return siteIds;
};

export const getUserSitesByRole = (roleAssignments, role) => roleAssignments
  .filter(roleAssignment => roleAssignment.role_name === role)
  .map(roleAssignment => roleAssignment.site_id);

export const getUserSitesByRoles = (roleAssignments, roles) => roleAssignments
  .filter(roleAssignment => roles.indexOf(roleAssignment.role_name) !== -1)
  .map(roleAssignment => roleAssignment.site_id);

export const getCurrentlyLoggedInOrImpersonatingUserSitesByRole = (role) => {
  const loggedUser = getCurrentlyLoggedInOrImpersonatingUser();

  if(!loggedUser) {
    return [];
  }

  return (loggedUser.role_assignments || [])
    .filter(roleAssignment => roleAssignment.role_name === role)
    .map(roleAssignment => roleAssignment.site_id);
};

export const isCompleteRegistrationProcessPending = () => {
  if (getCurrentlyLoggedInOrImpersonatingUser()) {
    return false;
  }

  return store.getState().app.userRegistration.idTokenPayload !== null;
};

export const getCurrentlyLoggedInUser = () => store.getState().app.loggedInUser;

export const getImpersonatingUser = () => store.getState().app.impersonating;

export const isInImpersonatingMode = () => {
  const state = store.getState();
  const activeUser = getCurrentlyLoggedInOrImpersonatingUser();

  if (!state || !state.app || !state.app.idTokenPayload || !state.app.idTokenPayload.id || !activeUser) {
    return false;
  }

  return state.app.idTokenPayload.id !== activeUser.id;
};

export const setDefaultSiteAndSites = roleAssignments => Bluebird
  .try(() => store.dispatch(sitesActions.fetchSites()))
  .then(() => {
    // the sites aren't back yet - only the fetch dispatch has completed...
    const storeSites = store.getState().entities.sites;

    // let's check if user has any roles at all to play with
    if (!roleAssignments.length) {
      return store.dispatch(appActions.switchCurrentSite(null));
    }

    // role assignments are sorted by how the role is important in the reducer, so we can rely on that here
    const firstRoleAssignmentWithSiteId = roleAssignments.find(
      roleAssignment =>
        roleAssignment.hasOwnProperty('site_id') && typeof roleAssignment.site_id === 'number'
    );
    const defaultSiteId = firstRoleAssignmentWithSiteId ? firstRoleAssignmentWithSiteId.site_id : null;

    console.log('setDefaultSiteAndSites: ', roleAssignments, defaultSiteId );

    return store.dispatch(
      appActions.switchCurrentSite(
        defaultSiteId && storeSites.byId[defaultSiteId]
          ? storeSites.byId[defaultSiteId]
          : storeSites.byId[storeSites.allIds[0]]
      )
    );
  });

export const shouldHideMainDrawer = (user, isMobile = false) => {
  const checkedUserRoles = get(user, 'role_assignments', []);

  const isRescuerOnly = getUserSitesByRole(checkedUserRoles, Roles.Rescuer).length === checkedUserRoles.length;
  const hasRoleAssigned = checkedUserRoles.length > 0;
  const isAdmin = hasAnyRoleInCurrentlySelectedSite(getCurrentlyLoggedInUser(), [
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);

  // on mobile: hide menu for all users
  // on desktop: hide menu for rescuers and users without roles
  return isMobile || (!isAdmin && (isRescuerOnly || !hasRoleAssigned));
};

const _getGroupedGlobalAndNonGlobalRoles = (roleAssignments, siteId) => {
  const groupedRoles = groupBy(roleAssignments, ra => ra.site_id ? ra.site_id : 'global');

  return [
    ...get(groupedRoles, 'global', []),
    ...get(groupedRoles, `[${siteId}]`, []),
  ];
};
