import * as React from 'react';
import useRescuerRescueActions from './useRescuerRescueActions';

export const withRescuerRescueActions = function(Component) {
  function WithRescuerRescueActionsComponent(props) {
    const rescuerRescueActions = useRescuerRescueActions();

    return <Component {...props} rescuerRescueActions={rescuerRescueActions} />;
  }

  WithRescuerRescueActionsComponent.displayName = `withRescuerRescueActions(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithRescuerRescueActionsComponent;
};
