import Bluebird from 'bluebird';
import * as receiversApi from '../api/receivers';
import * as receiversLocationsApi from '../api/receiversLocations';
import * as receiversStaffApi from '../api/receiversStaff';
import * as usersActions from '../actions/users';
import * as receiversModel from '../models/receivers';
import * as usersModel from '../models/users';
import { Roles } from '../models/roles';

export const REQUEST_RECEIVERS = 'REQUEST_RECEIVERS';
export const REQUEST_RECEIVER_TYPES = 'REQUEST_RECEIVER_TYPES';
export const REQUEST_RECEIVER_NEEDS_PROFILE = 'REQUEST_RECEIVER_NEEDS_PROFILE';
export const RECEIVE_RECEIVERS = 'RECEIVE_RECEIVERS';
export const RECEIVE_RECEIVER_TYPES = 'RECEIVE_RECEIVER_TYPES';
export const RECEIVE_RECEIVER_POST = 'RECEIVE_RECEIVER_POST';
export const RECEIVE_RECEIVER_LOCATION_POST = 'RECEIVE_RECEIVER_LOCATION_POST';
export const RECEIVE_RECEIVER_STAFF_DELETE = 'RECEIVE_RECEIVER_STAFF_DELETE';
export const RECEIVE_RECEIVER_NEEDS_PROFILE = 'RECEIVE_RECEIVER_NEEDS_PROFILE';

export const requestReceivers = () => ({
  type: REQUEST_RECEIVERS,
});

export const requestReceiverTypes = () => ({
  type: REQUEST_RECEIVER_TYPES,
});

export const requestReceiverNeedsProfile = (receiverLocationId) => ({
  type: REQUEST_RECEIVER_NEEDS_PROFILE,
  receiverLocationId,
});

export const receiveReceivers = json => ({
  type: RECEIVE_RECEIVERS,
  receivers: json.receivers,
  locations: json.locations,
  staff: json.staff,
  receivedAt: Date.now(),
});

export const receiveReceiverTypes = json => ({
  type: RECEIVE_RECEIVER_TYPES,
  receiverTypes: json.data,
  receivedAt: Date.now(),
});

export const receiveReceiverPost = json => ({
  type: RECEIVE_RECEIVER_POST,
  receiver: json.receiver,
  location: json.location,
  receivedAt: Date.now(),
});

export const receiveReceiverLocationPost = json => ({
  type: RECEIVE_RECEIVER_LOCATION_POST,
  receiver: json.receiver,
  location: json.location,
  user: json.user,
  receivedAt: Date.now(),
});

export const receiveReceiverStaffDelete = (receiverLocationId, userId, isAdmin) => ({
  type: RECEIVE_RECEIVER_STAFF_DELETE,
  receiverLocationId: receiverLocationId,
  userId: userId,
  isAdmin: isAdmin,
});

export const receiveReceiverNeedsProfile = (json, receiverLocationId) => ({
  type: RECEIVE_RECEIVER_NEEDS_PROFILE,
  needsProfile: json.data,
  receiverLocationId,
  receivedAt: Date.now(),
});

export const fetchReceivers = () => dispatch => {
  dispatch(requestReceivers());

  return Bluebird
    .try(() =>  receiversApi.getReceivers())
    .then(res => res.json())
    .then(json => dispatch(receiveReceivers(json)));
};

const shouldFetchReceivers = (state) => {
  const { receivers } = state.entities;
  if (receivers.inflight) {
    return false;
  }

  if (receivers.allIds.length === 0 || !receivers.lastUpdated) {
    return true;
  }

  return !receivers.lastUpdated || receivers.lastUpdated + 0.5 * 60 * 60 * 1000 < Date.now(); // older than 0.5h
};

export const fetchReceiversIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchReceivers(getState())) {
    return dispatch(fetchReceivers());
  }

  return null;
};

export const createReceiver = receiver => dispatch => Bluebird
  .try(() => receiversApi.createReceiver(receiversModel.mapFormToPOST(receiver)))
  .then(res => res.json())
  .then(json => {
    dispatch(receiveReceiverPost(json.data));

    usersActions.receiveUserAndReflectAllUserStates(json.data.user);
  });

export const createReceiverStaff = (receiverId, locationId, data) => () => Bluebird
  .try(() => receiversStaffApi.addReceiverStaff(receiverId, locationId, data));

export const deleteReceiverStaff = (receiverId, locationId, userId, isAdmin) => dispatch => Bluebird
  .try(() => receiversStaffApi.deleteReceiverStaff(
    receiverId,
    locationId,
    userId,
    {
      role_to_detach: usersModel.transformRoleToApi(
        isAdmin ? Roles.ReceiverLocationAdmin : Roles.ReceiverLocationStaff
      ),
    }
  ))
  .then(() => dispatch(receiveReceiverStaffDelete(locationId, userId, isAdmin)));

export const updateReceiverLocation = (receiverId, receiverLocationId, receiverLocation) => dispatch => Bluebird
  .try(() => receiversLocationsApi.updateReceiverLocation(
    receiverId, receiverLocationId, receiversModel.mapFormToPATCH(receiverLocation)
  ))
  .then(res => res.json())
  .then(json => {
    dispatch(receiveReceiverLocationPost(json.data));

    usersActions.receiveUserAndReflectAllUserStates(json.data.user);
  });

export const fetchReceiverTypes = () => dispatch => {
  dispatch(requestReceiverTypes());

  return Bluebird
    .try(() => receiversApi.getReceiverTypes())
    .then(res => res.json())
    .then(json => dispatch(receiveReceiverTypes(json)));
};

export const fetchReceiverNeedsProfile = (receiverId, receiverLocationId) => dispatch => {
  dispatch(requestReceiverNeedsProfile(receiverLocationId));

  return Bluebird.try(() => receiversLocationsApi.getReceiverLocationNeedsProfile(receiverId, receiverLocationId))
  .then(res => res.json())
  .then(json => dispatch(receiveReceiverNeedsProfile(json, receiverLocationId)));
};

export const updateReceiverNeedProfile = (receiverId, receiverLocationId, data) => dispatch => Bluebird
  .try(() => receiversLocationsApi.updateReceiverLocationNeedsProfile(receiverId, receiverLocationId, data))
  .then(res => res.json())