export const mapFormToCreateUmbrellaFoodDonorPOST = umbrellaFoodDonor => {
  const umbrellaFoodDonorData = { ...umbrellaFoodDonor };

  return {
    name: umbrellaFoodDonorData.name,
  };
};

export const mapFormToUpdateUmbrellaFoodDonorPUT = umbrellaFoodDonor => {
  const umbrellaFoodDonorData = { ...umbrellaFoodDonor };

  return {
    name: umbrellaFoodDonorData.name,
  };
};
