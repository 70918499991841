import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Box, Breadcrumbs, Tab, Tabs, Typography } from '@material-ui/core';
import SettingsEditForm from '../components/SettingsEditForm';
import SystemSettingsForm from '../components/SystemSettingsForm';
import * as settingsActions from '../actions/settings';
import * as systemSettingsActions from '../actions/systemSettings';
import * as foodDonorsActions from '../actions/foodDonors';
import * as sitesActions from '../actions/sites';
import routes from '../routes';
import RescueSizing from '../components/RescueSizing';
import { TabLink } from '../components/Common/BottomNavTabs';
import { hasActiveFeatureFlag } from '../helpers/fetureFlagsHelper';
import { FF_RESCUE_SIZING } from '../components/FeatureFlagWrapper';

export const paths = [
  generatePath(routes.settings),
  generatePath(routes.settings, { tab: 'rescue-sizing' }),
];

class ManageSettingsView extends Component {
  componentDidMount() {
    const { fetchSettings, fetchSystemSettings, fetchSites, fetchFoodDonors } = this.props;

    fetchSettings();
    fetchSystemSettings();
    fetchSites();
    fetchFoodDonors();
  }

  render() {
    const { settings, systemSettings, updateSettings, updateSystemSettings, classes, location, hasRescueSizingFF } = this.props;

    if (settings.inflight || systemSettings.inflight) {
      return 'Loading...';
    }

    const settingsList = settings.allIds.map(settingId => settings.byId[settingId]);
    const systemSettingsList = systemSettings.allIds.map(systemSettingId => systemSettings.byId[systemSettingId]);

    return (
      <>
        <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
          <Typography color="textPrimary">Manage Settings</Typography>
        </Breadcrumbs>

        {hasRescueSizingFF && (
          <AppBar position="static">
            <Tabs value={location.pathname}>
              <TabLink label="Settings" value={paths[0]} to={paths[0]} />
              <TabLink label="Rescue Sizing" value={paths[1]} to={paths[1]} />
            </Tabs>
          </AppBar>
        )}

        <Switch>
          <Route
            path={paths[0]}
            exact
            render={() => (
              <>
                <Box mb={2}>
                  <SystemSettingsForm systemSettings={systemSettingsList} onSubmit={updateSystemSettings} />
                </Box>

                <SettingsEditForm settings={settingsList} title="Default Sites Settings" onSubmit={updateSettings} />
              </>
            )}
          />
          <Route path={paths[1]} exact render={() => <RescueSizing />} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = ({ app: { site }, entities: { settings, systemSettings, featureFlags } }) => ({
  settings,
  systemSettings,
  hasRescueSizingFF: site ? hasActiveFeatureFlag(FF_RESCUE_SIZING, site, Object.values(featureFlags.byId)) : false,
});
const mapDispatchToProps = dispatch => ({
  fetchFoodDonors: () => dispatch(foodDonorsActions.fetchFoodDonors()),
  fetchSettings: () => dispatch(settingsActions.fetchSettingsIfNeeded()),
  fetchSystemSettings: () => dispatch(systemSettingsActions.fetchSystemSettingsIfNeeded()),
  fetchSites: () => dispatch(sitesActions.fetchSitesIfNeeded()),
  updateSettings: values => dispatch(settingsActions.updateSettings(values)),
  updateSystemSettings: values => dispatch(systemSettingsActions.updateSystemSettings(values)),
});
const styles = theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles),
)(ManageSettingsView);
