import * as React from 'react';
import useNotificationService from '../../hooks/useNotificationService';

export const withNotificationService = function(Component) {
  function WithNotificationServiceComponent(props) {
    const notificationService = useNotificationService();

    return <Component {...props} notificationService={notificationService} />;
  }

  WithNotificationServiceComponent.displayName = `withNotificationService(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithNotificationServiceComponent;
};
