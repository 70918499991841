import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Colors } from '../assets/theme/Colors';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  wrapper: {
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  buttonProgress: {
    color: Colors.appBar.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ButtonWithLoading = props => {
  const { isLoading = false, disabled = false, color = 'primary', fullWidth, children, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, { [classes.fullWidth]: fullWidth })}>
      <div className={classNames(classes.wrapper, { [classes.fullWidth]: fullWidth })}>
        <Button variant="contained" fullWidth={fullWidth} color={color} disabled={isLoading || disabled} {...rest}>
          {children}
        </Button>
        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
};

export default ButtonWithLoading;
