import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEnvironmentImpact } from '../../../../actions/environmentImpact';
import { fetchSitesIfNeeded } from '../../../../actions/sites';
import PagePaper from '../../../../components/Common/PagePaper';
import MyEnviroImpact from '../../../rescuerDashboard/rescuerDashboardDesktop/myFrus/Components/MyEnviroImpact';
import SitesAndDateFilter from './SitesAndDateFilter';

const NSDEnvironmentImpact = () => {
  const dispatch = useDispatch();
  const sites = useSelector((state) => state.entities.sites);
  const environmentImpact = useSelector((state) => state.entities.environmentImpact);
  const sitesList = sites.allIds.map((id) => sites.byId[id]);

  useEffect(() => {
    dispatch(fetchSitesIfNeeded());
    dispatch(fetchEnvironmentImpact());
  }, [dispatch]);

  return (
    <Box>
      <PagePaper mt={1}>
        <SitesAndDateFilter
          showDateRangeFilter={false}
          sites={sitesList}
          disabled={environmentImpact.inflight}
          handleChange={(state) => dispatch(fetchEnvironmentImpact({ sites_ids: state.sites }))}
        />
      </PagePaper>
      <Box mt={1} mb={2}>
        <Typography variant="h6" color="textSecondary">
          Environment Impact
        </Typography>
      </Box>
      <Box display="flex">
        <MyEnviroImpact isLoading={environmentImpact.inflight} environmentImpact={environmentImpact.all} />
      </Box>
    </Box>
  );
};

export default NSDEnvironmentImpact;
