import React, { useContext, useState, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormHelperText, Grid, Tooltip, Box, Button, IconButton, Typography, makeStyles } from '@material-ui/core';
import DaysOfWeekSelector from './DaysSelectors/DaysOfWeekSelector';
import DonationContext from '../../../../context/DonationContext/DonationContext';
import DaysOfMonthSelector from './DaysSelectors/DaysOfMonthSelector/DaysOfMonthSelector';
import {
  FREQUENCY_EVERY_OTHER_WEEK,
  FREQUENCY_MONTHLY,
  FREQUENCY_ONCE,
  FREQUENCY_WEEKLY,
} from '../../../../models/donationsNew';
import { donationManageActions } from '../../../../helpers/donations';
import { Colors } from '../../../../assets/theme/Colors';
import { Help as HelpIcon, PlayCircleOutline as PlayCircleOutlineIcon, Clear as ClearIcon } from '@material-ui/icons';
import FrequencySelector from './Frequency/FrequencySelector';
import moment from 'moment';
import { DONATION_TYPE_REQUEST } from '../../../../actions/donationNew';
import ShowContactInfoDialog from './ShowContactInfoDialog';
import * as sitesApi from '../../../../api/sites';
import useActiveSite from '../../../../hooks/useActiveSite';

const useStyles = makeStyles({
  contactInfo: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const Schedule = ({ disableWeekly = false, disableMonthly = false, disableEveryOtherWeek = false }) => {
  const {
    frequency: frequency,
    frequency_every: frequencyEvery,
    days_of_week: daysOfWeek,
    days_of_month: daysOfMonth,
    start_date: startDate,
    end_date: endDate,
    pause_date: pauseDate,
    resume_date: resumeDate,
  } = useSelector(state => state.ui.donation_new.draft);
  const { start_date: originalStartDate } = useSelector(state => state.ui.donation_new.original);
  const classes = useStyles();
  const [siteDirectors, setSiteDirectors] = useState([]);
  const [siteCoordinators, setSiteCoordinators] = useState([]);
  const [siteDirectorModal, setSiteDirectorModal] = useState(false);
  const [siteCoordinatorModal, setSiteCoordinatorModal] = useState(false);
  const { errors, onFieldChange, isMobileView, isNew, isLoading, donationType } = useContext(DonationContext);
  const activeSite = useActiveSite();

  const handleDaysOfWeekChange = event => {
    const { checked, value } = event.target;
    const valueInt = parseInt(value, 10);

    if (checked) {
      return onFieldChange(donationManageActions.add_day_of_week, valueInt);
    }

    return onFieldChange(donationManageActions.remove_day_of_week, valueInt);
  };

  const handleDaysOfMonthChange = ({ newOrdinaryValue, newDayValue, oldOrdinaryValue, oldDayValue }) => {
    if (newOrdinaryValue === null && newDayValue === null && oldOrdinaryValue && (oldDayValue || oldDayValue === 0)) {
      return onFieldChange(donationManageActions.remove_day_of_month, {
        day: oldDayValue,
        ordinal: oldOrdinaryValue,
      });
    }

    if (
      newOrdinaryValue &&
      (newDayValue || newDayValue === 0) &&
      !oldOrdinaryValue &&
      !oldDayValue &&
      oldDayValue !== 0
    ) {
      return onFieldChange(donationManageActions.add_day_of_month, {
        day: newDayValue,
        ordinal: newOrdinaryValue,
      });
    }

    if (newDayValue !== oldDayValue && newOrdinaryValue === oldOrdinaryValue) {
      return onFieldChange(donationManageActions.set_day_of_month_day, {
        day: oldDayValue,
        ordinal: oldOrdinaryValue,
        newDay: newDayValue,
      });
    }

    if (newDayValue === oldDayValue && newOrdinaryValue !== oldOrdinaryValue) {
      return onFieldChange(donationManageActions.set_day_of_month_ordinal, {
        day: oldDayValue,
        ordinal: oldOrdinaryValue,
        newOrdinal: newOrdinaryValue,
      });
    }
  };

  const minDonationDate = (() => {
    if (DONATION_TYPE_REQUEST === donationType) {
      return moment().add(1, 'day');
    }

    if (isNew) {
      return moment();
    }

    if (moment().isBefore(originalStartDate.value)) {
      return moment();
    }

    return moment(originalStartDate.value);
  })();

  const showSiteDirectors = () => {
    setSiteDirectorModal(true);
  };

  const showSiteCoordinators = () => {
    setSiteCoordinatorModal(true);
  };

  useEffect(() => {
    (async () => {
      // fetch site directors and site coordinators
      const getSiteUsersResponse = await sitesApi.getSiteUsers(activeSite?.id, ['site_admin', 'site_coordinator']);
      const getSiteUsersResponseJson = await getSiteUsersResponse.json();

      setSiteDirectors((getSiteUsersResponseJson.data || []).filter(
        user => user.role_assignments.some(ra => ra.role_name === 'site_admin')
      ));
      setSiteCoordinators((getSiteUsersResponseJson.data || []).filter(
        user => user.role_assignments.some(ra => ra.role_name === 'site_coordinator')
      ));
    })();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container item>
        <Grid item xs={12}>
          <FrequencySelector
            disabled={isLoading}
            frequency={frequency}
            frequencyEvery={frequencyEvery}
            onChange={value => onFieldChange(donationManageActions.set_frequency, value)}
            disableWeekly={disableWeekly}
            disableMonthly={disableMonthly}
            disableEveryOtherWeek={disableEveryOtherWeek}
          />
        </Grid>
      </Grid>
      {(frequency === FREQUENCY_WEEKLY || frequency === FREQUENCY_EVERY_OTHER_WEEK) && (
        <Grid container item>
          <Grid item xs={12}>
            <DaysOfWeekSelector
              disabled={isLoading}
              dow={daysOfWeek}
              onChange={handleDaysOfWeekChange}
              error={
                (daysOfWeek || []).length === 0 ? { message: 'You must select at least 1 day of the week' } : false
              }
            />
          </Grid>
        </Grid>
      )}
      {frequency === FREQUENCY_MONTHLY && (
        <Grid container item>
          <Grid item xs={12}>
            <DaysOfMonthSelector disabled={isLoading} dom={daysOfMonth} onChange={handleDaysOfMonthChange} />
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={2}>
        <Grid container item spacing={2}>
          <Grid container item md={12} lg={8} xl={6} spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="no-wrap"
                alignItems="center"
                gridGap="8px"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <DatePicker
                    disabled={
                      isLoading || frequency === FREQUENCY_ONCE
                        ? moment(originalStartDate.value, originalStartDate.format).isBefore(moment(), 'day')
                        : false
                    }
                    minDate={minDonationDate}
                    variant={isMobileView ? 'dialog' : 'inline'}
                    inputVariant="outlined"
                    value={startDate.value}
                    onChange={momentObject => {
                      const stringDate = momentObject.format('YYYY-MM-DD');
                      onFieldChange(donationManageActions.set_start_date, stringDate);
                    }}
                    label={frequency === FREQUENCY_ONCE ? 'Date' : 'Start Date'}
                    size={isMobileView ? 'medium' : 'small'}
                    fullWidth
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    error={!!errors.start_date}
                    data-testid="start_date"
                  />

                  {DONATION_TYPE_REQUEST === donationType && (
                    <FormHelperText error={!!errors.start_date}>
                      Pickup window must be requested at least 24 hours in advance. For pickups in the next 24 hours,
                      please contact your{' '}
                      <Typography onClick={showSiteDirectors} className={classes.contactInfo} variant="caption">
                        Site Director
                      </Typography>{' '}
                      or{' '}
                      <Typography onClick={showSiteCoordinators} className={classes.contactInfo} variant="caption">
                        Site Coordinator
                      </Typography>
                      .
                    </FormHelperText>
                  )}
                </Box>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    frequency === FREQUENCY_ONCE
                      ? 'Rescue will occur on this date.'
                      : 'Rescues will begin after this date.'
                  }
                >
                  <HelpIcon htmlColor={Colors.blue.main} />
                </Tooltip>
              </Box>
            </Grid>
            {frequency !== FREQUENCY_ONCE && (
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="row" flexWrap="no-wrap" alignItems="center" gridGap="8px">
                  <DatePicker
                    disabled={isLoading}
                    minDate={moment(startDate.value)}
                    value={endDate ? moment(endDate.value) : null}
                    variant={isMobileView ? 'dialog' : 'inline'}
                    onChange={momentObject => {
                      const stringDate = momentObject.format('YYYY-MM-DD');
                      onFieldChange(donationManageActions.set_end_date, stringDate);
                    }}
                    label="End Date"
                    inputVariant="outlined"
                    size={isMobileView ? 'medium' : 'small'}
                    fullWidth
                    format="MM/DD/YYYY"
                    placeholder="mm/dd/yyyy"
                    data-testid="start_date"
                  />
                  {endDate && (
                    <IconButton size="small" onClick={() => onFieldChange(donationManageActions.set_end_date, null)}>
                      <ClearIcon />
                    </IconButton>
                  )}
                  <Tooltip arrow placement="top" title="Rescues will end after this date.">
                    <HelpIcon htmlColor={Colors.blue.main} />
                  </Tooltip>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        {frequency !== FREQUENCY_ONCE && (
          <Grid container item spacing={2}>
            <Grid container item md={12} lg={8} xl={6} spacing={2}>
              <Grid item xs={12} sm={6}>
                {pauseDate ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="no-wrap"
                    alignItems="center"
                    gridGap="8px"
                    justifyContent="space-between"
                  >
                    <DatePicker
                      disabled={isLoading}
                      variant={isMobileView ? 'dialog' : 'inline'}
                      inputVariant="outlined"
                      size={isMobileView ? 'medium' : 'small'}
                      fullWidth
                      minDate={moment(startDate.value)}
                      minDateMessage="Pause date should be after start date"
                      maxDate={endDate ? moment(endDate.value) : undefined}
                      maxDateMessage="Pause date should be before end date"
                      value={pauseDate ? moment(pauseDate.value) : null}
                      format="MM/DD/YYYY"
                      placeholder="mm/dd/yyyy"
                      label="Pause after date"
                      onChange={momentObject => {
                        const stringDate = momentObject.format('YYYY-MM-DD');
                        onFieldChange(donationManageActions.set_pause_date, stringDate);
                      }}
                    />
                    <Tooltip arrow placement="top" title="Rescue will pause after this date.">
                      <HelpIcon htmlColor={Colors.blue.main} />
                    </Tooltip>
                  </Box>
                ) : (
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    startIcon={<PlayCircleOutlineIcon />}
                    variant="contained"
                    onClick={() =>
                      onFieldChange(
                        donationManageActions.set_pause_date,
                        (moment().isBefore(startDate.value) ? moment(startDate.value) : moment()).format('YYYY-MM-DD')
                      )
                    }
                  >
                    Pause now
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="no-wrap"
                  alignItems="center"
                  gridGap="8px"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isLoading}
                        onChange={event => {
                          // when unchecked & there was pauseDate before, clear it
                          if (event.target.checked) {
                            // make sure that pause date is after start date (eg. when donation is from the future)
                            return onFieldChange(
                              donationManageActions.set_pause_date,
                              (moment().isBefore(startDate.value) ? moment(startDate.value) : moment()).format(
                                'YYYY-MM-DD'
                              )
                            );
                          } else {
                            onFieldChange(donationManageActions.set_pause_date, null);
                          }
                        }}
                        checked={!!pauseDate}
                        name="pause_date"
                      />
                    }
                    label="Pause after date"
                  />
                  <Tooltip arrow placement="top" title="Rescue will pause after this date.">
                    <HelpIcon htmlColor={Colors.blue.main} />
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!!pauseDate && (
          <Grid container item spacing={2}>
            <Grid container item md={12} lg={8} xl={6} spacing={2}>
              <Grid item xs={12} sm={6}>
                {resumeDate ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="no-wrap"
                    alignItems="center"
                    gridGap="8px"
                    justifyContent="space-between"
                  >
                    <DatePicker
                      disabled={isLoading}
                      variant={isMobileView ? 'dialog' : 'inline'}
                      inputVariant="outlined"
                      size={isMobileView ? 'medium' : 'small'}
                      fullWidth
                      minDate={moment(pauseDate.value)}
                      minDateMessage="Resume date should be after pause date"
                      maxDate={endDate ? moment(endDate.value) : undefined}
                      maxDateMessage="Resume date should be before end date"
                      value={resumeDate ? moment(resumeDate.value) : null}
                      format="MM/DD/YYYY"
                      placeholder="mm/dd/yyyy"
                      label="Resume on date"
                      onChange={momentObject => {
                        const stringDate = momentObject.format('YYYY-MM-DD');
                        onFieldChange(donationManageActions.set_resume_date, stringDate);
                      }}
                    />
                    <Tooltip arrow placement="top" title="Rescue will resume on this date.">
                      <HelpIcon htmlColor={Colors.blue.main} />
                    </Tooltip>
                  </Box>
                ) : (
                  <Button
                    disabled={isLoading}
                    color="secondary"
                    startIcon={<PlayCircleOutlineIcon />}
                    variant="contained"
                    onClick={() => {
                      // make sure that resume date is after start date (eg. when donation is from the future)
                      return onFieldChange(
                        donationManageActions.set_resume_date,
                        (moment().isBefore(startDate.value) ? moment(startDate.value) : moment()).format('YYYY-MM-DD')
                      );
                    }}
                  >
                    Resume now
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="no-wrap"
                  alignItems="center"
                  gridGap="8px"
                  justifyContent="space-between"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isLoading}
                        onChange={event => {
                          if (event.target.checked) {
                            // make sure that resume date is after start date (eg. when donation is from the future)
                            return onFieldChange(
                              donationManageActions.set_resume_date,
                              (moment().isBefore(startDate.value) ? moment(startDate.value) : moment()).format(
                                'YYYY-MM-DD'
                              )
                            );
                          } else {
                            onFieldChange(donationManageActions.set_resume_date, null);
                          }
                        }}
                        checked={!!resumeDate}
                        name="resume_date"
                      />
                    }
                    label="Resume on date"
                  />
                  <Tooltip arrow placement="top" title="Rescue will resume on this date.">
                    <HelpIcon htmlColor={Colors.blue.main} />
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ShowContactInfoDialog
        siteRole="Site Director(s)"
        data={siteDirectors}
        isOpen={siteDirectorModal}
        handleClose={() => setSiteDirectorModal(false)}
      />
      <ShowContactInfoDialog
        siteRole="Site Coordinator(s)"
        data={siteCoordinators}
        isOpen={siteCoordinatorModal}
        handleClose={() => setSiteCoordinatorModal(false)}
      />
    </Grid>
  );
};

export default Schedule;
