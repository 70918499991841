import React from 'react';
import { useIntl } from 'react-intl';
import ConfirmationDialog from './ConfirmationDialog';

const VerificationEmailSentConfirmationDialog = ({ onCloseDialog, onGotItButtonClick }) =>
{
  const { formatMessage } = useIntl();
  return (
    <ConfirmationDialog
      dialogProps={{
        fullWidth: true,
      }}
      title={formatMessage({ id: 'verification-email-sent-confirmation-dialog.title' })}
      message={formatMessage({ id: 'verification-email-sent-confirmation-dialog.message' })}
      closeDialog={onCloseDialog}
      buttons={[
        {
          label: formatMessage({ id: 'verification-email-sent-confirmation-dialog.button' }),
          onClick: onGotItButtonClick,
        },
      ]}
    />
  );
};

export default VerificationEmailSentConfirmationDialog;
