import React from 'react';
import Select from 'react-select';

const ReceiverSelector = ({ name, receivers, onChange, onFocus, error, ...rest }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: error ? 'red' : '#0000003b',
      '&:hover': {
        borderColor: error ? 'red' : '#0000003b',
      },
    }),
  };

  return (
    <Select
      menuPosition="fixed"
      placeholder="Select Receiving Agency"
      name={name}
      options={receivers}
      styles={customStyles}
      menuPortalTarget={document.body}
      onChange={event => {
        if (onChange) {
          onChange(event);
        }
      }}
      {...rest}
    />
  );
};

export default ReceiverSelector;
