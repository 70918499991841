import React from 'react';
import { Tooltip } from '@material-ui/core';

const DisabledDayFrequencyTooltip = ({ children, disabled, placement }) => {
  return (
    <Tooltip
      arrow
      placement={placement || 'top'}
      title={disabled ? 'This day has active rescues. Please uncheck the ‘All Days Are the Same’ checkbox and select the trash can icon next to the day you’d like to remove.' : ''}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default DisabledDayFrequencyTooltip;
