import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextAreaStyles } from '../../assets/theme/TextAreaStyles';

export const TextAreaField = withStyles({ ...TextAreaStyles() })(({ classes, ...rest }) => (
  <TextField variant="outlined" multiline classes={classes} {...rest} />
));

export default TextAreaField;
