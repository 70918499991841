import React, { useEffect, useState } from 'react';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { ButtonGroup, Button, Grid, Paper, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import useUIFilter from '../../../hooks/useUIFilter';
import { RESCUER_SCHEDULE_FILTER } from '../../../pages/rescuerDashboard/rescuerDashboardMobile/Schedule/ScheduleMobile';
import { initialRescuerScheduleFilters } from '../../../reducers/ui';
import FilterComponent from './FilterComponent';

const useStyles = makeStyles(() => ({
  filter: {
    textAlign: 'left',
    padding: '6px 16px',
    fontSize: '0.875rem',
    fontWeight: 500,
    userSelect: 'none',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.45)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    lineHeight: 1.75,
    fontFamily: 'Roboto, Helvetica, Arial',
    borderRadius: 4,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
}));

const RescuerScheduleDateFilter = ({ disableNextDate, value, handleDateChange }) => {
  const classes = useStyles();
  const [isTodayDate, setIsTodayDate] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { getUIFilter } = useUIFilter();
  const rescuerScheduleFilters = getUIFilter(RESCUER_SCHEDULE_FILTER) || initialRescuerScheduleFilters;

  const [date, setDate] = useState(value || moment().startOf('day'));

  const disablePreviousDate = value <= moment().startOf('day');

  const getLabel = (selectedDate) => {
    if (!selectedDate) {
      return 'Date: all';
    }

    return `Date: ${moment(selectedDate).format('MM/DD')}`;
  };

  useEffect(() => {
    handleDateChange(date);
    setIsTodayDate(moment().startOf('day').format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'));
  }, [date]);

  const getPreviousDate = () => {
    const selectedDays = rescuerScheduleFilters.days.value;

    if (selectedDays.length === 0) {
      setDate(moment(date).subtract(1, 'd'));
      return;
    }
    let nextDate = null;
    let tempDate = moment(date).clone();
    do {
      tempDate.subtract(1, 'd');
      selectedDays.forEach((dayName) => {
        if (!nextDate && tempDate.format('ddd') === dayName) {
          nextDate = tempDate.clone();
        }
      });
    } while (!nextDate);

    setDate(nextDate);
  };

  const getNextDate = () => {
    const selectedDays = rescuerScheduleFilters.days.value;

    if (!date) {
      setDate(moment().startOf('day').add(1, 'd'));
      return;
    }

    if (selectedDays.length === 0) {
      setDate(moment(date).add(1, 'd'));
      return;
    }
    let nextDate = null;
    let tempDate = moment(date).clone();
    do {
      tempDate.add(1, 'd');
      selectedDays.forEach(dayName => {
        if (!nextDate && tempDate.format('ddd') === dayName) {
          nextDate = tempDate.clone();
        }
      });
    } while (!nextDate);

    setDate(nextDate);
  };

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getLabel(value)} />
        {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </ListItem>
      <FilterComponent isOpen={isOpen}>
        <Grid spacing={2} container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Paper variant="outlined" className={classes.filter}>
              {`Date: ${value ? moment(value).format('MM/DD') : 'All'}`}
            </Paper>
          </Grid>
          <Grid item >
            <ButtonGroup variant="contained" color="primary" size="medium" disableElevation>
              <Button variant="contained" color="primary" size="medium" disabled={!value} onClick={() => setDate(null)}>
                All
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled={isTodayDate && !!value}
                onClick={() => setDate(moment().startOf('day'))}
              >
                Today
              </Button>
              <Button onClick={getPreviousDate} disabled={isTodayDate || !value || disablePreviousDate}>
                <ArrowBackIosIcon />
              </Button>
              <Button onClick={getNextDate} disabled={disableNextDate}>
                <ArrowForwardIosIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </FilterComponent>
    </>
  );
};

export default RescuerScheduleDateFilter;
