import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Button, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  getRescuePickupLocationFullName,
  showReleaseRescueButton,
  showUnClaimRescueButtons,
} from '../../helpers/RescuesHelper';
import useRescuerRescueActions from '../../hooks/useRescuerRescueActions';
import ConfirmationDialog from '../ConfirmationDialog';
import { formatTime } from '../../helpers/formatters';
import useActiveUser from '../../hooks/useActiveUser';

const ReleaseRescueButtons = ({ rescue, buttonVariant = 'contained' }) => {
  const { releaseRescue, rescueUnclaim } = useRescuerRescueActions();
  const rescuer = useActiveUser();
  const { formatMessage } = useIntl();

  const handleOnUnClaimClick = () => rescueUnclaim(rescue, true);

  const handleReleaseClick = () =>
    confirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          variant: 'outlined',
          label: 'Never Mind',
          color: 'primary',
          onClick: () => {},
        },
        {
          variant: 'contained',
          label: 'Release',
          color: 'primary',
          onClick: () => releaseRescue(rescue, true),
        },
      ],
      message: 'Releasing this rescue may result in this food going to waste, but we understand that life happens!',
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
        />
      ),
    });

  return (
    <>
      {showUnClaimRescueButtons(rescue, rescuer) && (
        <Button
          fullWidth
          startIcon={<CloseIcon />}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleOnUnClaimClick}
        >
          {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.release-rescue' })}
        </Button>
      )}

      {showReleaseRescueButton(rescue, rescuer) && (
        <Button
          fullWidth
          startIcon={<CloseIcon />}
          size="large"
          color="primary"
          variant={buttonVariant}
          onClick={handleReleaseClick}
        >
          {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.release-rescue' })}
        </Button>
      )}
    </>
  );
};

export default ReleaseRescueButtons;
