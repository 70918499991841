import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { getUserFullName } from '../helpers/user';
import useRescuerDashboardActions from '../hooks/useRescuerDashboardActions';
import { getPercentage } from '../helpers/math';
import routes from '../routes';
import { getLabelFromType } from '../helpers/siteGoals';
import OverlayLoader from './OverlayLoader';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const ClaimRescueBasedOnSiteGoalDialog = ({ rescuer }) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [localData, setLocalData] = useState({});
  const classes = useStyles();
  const history = useHistory();
  const siteGoalsEntities = useSelector((state) => state.entities.siteGoals);
  const { fetchGoals } = useRescuerDashboardActions();
  const SITE_GOAL_LOCAL_STORAGE_NAME = 'siteGoalPrompt';
  const user = getUserFullName(rescuer);

  useEffect(() => {
    fetchGoals(false);
    const savedLocalData = localStorage.getItem(SITE_GOAL_LOCAL_STORAGE_NAME);
    if (!savedLocalData) {
      return;
    }
    setLocalData(JSON.parse(savedLocalData));
    setDialogOpen(true);
  }, [user]);

  const siteGoalsInProgress = Object.values(siteGoalsEntities.byId)
    .filter((siteGoal) => siteGoal.goal > siteGoal.value)
    .sort((siteGoal1, siteGoal2) => siteGoal1.site_id - siteGoal2.site_id);

  const handleClaimRescue = () => {
    setDialogOpen(false);
    localStorage.setItem(
      SITE_GOAL_LOCAL_STORAGE_NAME,
      JSON.stringify({ ...localData, [rescuer.id]: { user_id: rescuer.id, viewDate: moment() } })
    );
    history.push(generatePath(routes.dashboardRescuer, { tab: 'schedule' }));
  };

  const handleClose = () => {
    setDialogOpen(false);
    localStorage.setItem(
      SITE_GOAL_LOCAL_STORAGE_NAME,
      JSON.stringify({ ...localData, [rescuer.id]: { user_id: rescuer.id, viewDate: moment() } })
    );
  };

  const savedDateIsSameAsCurrentMonth =
    localData && localData[rescuer.id] ? moment(localData[rescuer.id].viewDate).isSame(moment(), 'month') : false;
  // Display dialog during the last week of the month
  const displayStartDate = moment().endOf('month').subtract(7, 'days');
  const displayEndDate = moment().endOf('month');
  const currentDateIsBetweenLastWeek = moment().isBetween(displayStartDate, displayEndDate, 'days', '[]');

  if (
    (siteGoalsInProgress && siteGoalsInProgress.length < 1) ||
    (localData &&
      localData[rescuer.id] &&
      localData[rescuer.id].user_id === rescuer.id &&
      savedDateIsSameAsCurrentMonth) ||
    !currentDateIsBetweenLastWeek
  )
    return null;

  return (
    <Dialog
      open={dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      onClose={handleClose}
    >
      <OverlayLoader isLoading={siteGoalsEntities.inflight}>
        <DialogTitle className={classes.container}>
          Hello, {user}!
          <CloseIcon className={classes.icon} onClick={handleClose} />
        </DialogTitle>
        <DialogContent className={classes.container}>
          {siteGoalsInProgress.map((siteGoal) => (
            <Typography key={siteGoal.id} className={classes.text}>
              {siteGoal.name} is {getPercentage(siteGoal.value, siteGoal.goal)}% to meeting their goal of{' '}
              {getLabelFromType(siteGoal.type)}.
            </Typography>
          ))}
          <Typography className={classes.text}>Can you claim a rescue to help?</Typography>
        </DialogContent>
        <DialogActions className={classes.container}>
          <Button variant="contained" color="primary" onClick={handleClaimRescue}>
            Claim Rescue
          </Button>
        </DialogActions>
      </OverlayLoader>
    </Dialog>
  );
};

export default ClaimRescueBasedOnSiteGoalDialog;
