import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Replay as ReplayIcon } from '@material-ui/icons';
import React from 'react';

const UndoButton = ({ handleClick }) => (
  <Box display="flex" justifyContent="flex-end">
    <Tooltip title="Undo edit">
      <IconButton size="small" onClick={handleClick}>
        <ReplayIcon />
      </IconButton>
    </Tooltip>
  </Box>
);

export default UndoButton;
