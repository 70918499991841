import React, { useState } from 'react';
import Select from 'react-select';
import { Box, Typography } from '@material-ui/core';
import ClearChip from './ClearChip';

const RescuerSelector = ({
  rescue,
  onChange,
  rescuersList,
  placeholder = 'Rescuer',
  noOptionsText = 'There are no active rescuers in the Site',
  isDisabled = false,
  ...rest
}) => {
  const [showClaimerChip, setShowClaimerChip] = useState(!!rescue.claimer);

  const releaseRescue = () => {
    onChange(null);

    return setShowClaimerChip(false);
  };

  if (showClaimerChip && rescue.claimer) {
    return (
      <Box display="flex" gridColumnGap="8px" alignItems="center">
        <Typography>
          <strong>Claimer:</strong>
        </Typography>

        <ClearChip isDisabled={isDisabled} value={rescue.claimer.name} onClick={releaseRescue} />
      </Box>
    );
  }

  return (
    <Select
      isDisabled={isDisabled}
      menuPosition="fixed"
      placeholder={placeholder}
      options={rescuersList}
      noOptionsMessage={() => <Typography>{noOptionsText}</Typography>}
      onChange={choice => {
        setShowClaimerChip(true);

        onChange(choice.value);
      }}
      {...rest}
    />
  );
};

export default RescuerSelector;
