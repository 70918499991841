import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Breadcrumbs, Button, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Home, LocalDrink, NaturePeople, Restaurant } from '@material-ui/icons';
import { ReactComponent as FoodIcon } from '../../../assets/svg/food.svg';
import PagePaper from '../../../components/Common/PagePaper';
import routes from '../../../routes';
import EnviroCard from './Components/EnviroCard';
import { Colors } from '../../../assets/theme/Colors';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import { formatNumber } from '../../../helpers/formatters';

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    color: Colors.appBar.main,
  },
  food: {
    fill: Colors.appBar.main,
  },
  button: {
    marginTop: spacing(2),
  },
  container: {
    maxWidth: 960,
  },
  card: {
    height: '100%',
  },
}));

const RescueClosedEnviroImpact = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));
  const history = useHistory();
  const { rescueId } = useParams();
  const rescues = useSelector((state) => state.entities.rescues);
  const rescue = rescues.byId[rescueId] || {};
  const isLoading = Object.keys(rescue).length < 1;

  const handleBackClick = () => {
    history.push(routes.index);
  };

  return (
    <Box className={classes.container}>
      {isMobileView ? (
        <Breadcrumbs aria-label="Breadcrumbs">
          <StyledRescuerBreadcrumb
            component={Link}
            to={routes.mobileDashboard}
            label="Home"
            icon={<Home style={{ color: '#fff' }} fontSize="small" />}
          />
          <StyledRescuerTextBreadcrumb component={Typography} label="Environmental Impact" />
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="Breadcrumbs">
          <Link color="inherit" to={routes.index}>
            Dashboard
          </Link>
          <Typography color="textPrimary">My Environmental Impact</Typography>
        </Breadcrumbs>
      )}
      <PagePaper mt={1} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6" color="textPrimary" align="center">
          Thank you for closing your rescue!
        </Typography>
        <Typography variant="body1" color="textPrimary" align="center">
          This is the impact your rescue has made for us and our environment:
        </Typography>
      </PagePaper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EnviroCard
            value={rescue.weight_lbs ? formatNumber(rescue.weight_lbs) : 0}
            title="Lbs of food rescued"
            isLoading={isLoading}
            cardStyle={classes.card}
          >
            {/* Sets size to the same values as icons from mui */}
            <FoodIcon
              viewBox="0 0 48 48"
              width={isMobileView ? 72 : 96}
              height={isMobileView ? 72 : 96}
              className={classes.food}
            />
          </EnviroCard>
        </Grid>
        <Grid item xs={6}>
          <EnviroCard
            value={rescue.meals ? formatNumber(rescue.meals) : 0}
            title="Meals rescued"
            isLoading={isLoading}
            cardStyle={classes.card}
          >
            <Restaurant className={classes.icon} fontSize="inherit" />
          </EnviroCard>
        </Grid>
        <Grid item xs={6}>
          <EnviroCard
            value={rescue.co2eq ? formatNumber(rescue.co2eq) : 0}
            title="Lbs of CO2eq offset"
            isLoading={isLoading}
            cardStyle={classes.card}
          >
            <NaturePeople className={classes.icon} fontSize="inherit" />
          </EnviroCard>
        </Grid>
        <Grid item xs={6}>
          <EnviroCard
            value={rescue.water ? formatNumber(rescue.water) : 0}
            title="Gallons of water wasted offset"
            isLoading={isLoading}
            cardStyle={classes.card}
          >
            <LocalDrink className={classes.icon} fontSize="inherit" />
          </EnviroCard>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Button variant="contained" color="secondary" className={classes.button} onClick={handleBackClick}>
          Back to Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default RescueClosedEnviroImpact;
