import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { getUserFullName, getUserFullNameWithEmail, isUserAbleToClaimRescue } from './user';
import { Roles } from '../models/roles';
import { formatAddress } from './formatters';

export const parseBoolean = variable => {
  if (typeof variable === 'boolean') {
    return variable;
  }

  if (typeof variable === 'object' && variable !== null) {
    return true;
  }

  return `${variable}`.toUpperCase() === 'TRUE';
};

export const parsePrimitive = variable => {
  if (variable === null || `${variable}`.toUpperCase() === 'NULL') {
    return null;
  }

  return parseBoolean(variable);
};

export const getPhoneNumberFromMask = phoneNumber => (phoneNumber ? phoneNumber.replace(/[_\-]/g, '') : '');

export const getZipCodeFromMask = zipCode => (zipCode ? zipCode.replace(/[\s_]/g, '') : '');

export const getZipCodeSelectOption = zipCode => ({
  label: `${zipCode.zip_code}, ${zipCode.city}, ${zipCode.state}`,
  value: zipCode.zip_code,
  data: zipCode,
});

export const getReceiversSelectOptions = receivers => receivers.reduce((acc, curr) => {
  if (curr.active) {
    acc.push({
      value: {
        receiver_id: curr.id,
        receiver: curr.name,
      },
      label: curr.name,
    });
  }
  return acc;
}, []);

export const getFoodDonorsSelectOptions = foodDonors => foodDonors.reduce((acc, curr) => {
  if (curr.makes_donations && curr.donor_active) {
    acc.push({
      value: {
        id: curr.id,
        name: curr.name,
      },
      label: curr.name,
    });
  }
  return acc;
}, []);

export const getPickupLocationsSelectOptions = locations => {
  let pickupLocations = {};
  for (const donor in locations) {
    if (Object.hasOwn(locations, donor)) {
      pickupLocations = { 
        ...pickupLocations, 
        [donor]: locations[donor].reduce((acc, curr) => {
          if (curr.active) {
            acc.push({
              value: {
                id: curr.id,
                name: curr.name ? `${curr.name} - ${formatAddress(curr)}` : formatAddress(curr),
              },
              label: curr.name ? `${curr.name} - ${formatAddress(curr)}` : formatAddress(curr),
              food_donor_id: curr.food_donor_id,
              id: curr.id,
            });
          }
          return acc;
        }, [])
      };
    }
  }
  return pickupLocations;
};

export const getReceiversWithSiteOptions = (receivers) =>
  receivers.reduce((acc, curr) => {
    if (curr.active) {
      acc.push({
        id: curr.id,
        name: curr.name,
        site_id: curr.site_id,
      });
    }
    return acc;
  }, []);

export const getRescuersSelectOptions = rescuers => rescuers.reduce((acc, curr) => {
  if (isUserAbleToClaimRescue(curr)) {
    acc.push({
      value: {
        id: curr.id,
        name: getUserFullName(curr),
      },
      label: getUserFullNameWithEmail(curr),
    });
  }
  return acc;
}, []);

export const getPrimaryContactSelectOption = user => ({
  label: getUserFullNameWithEmail(user),
  value: user.id,
  meta: user,
});

export const getMuiTableDataIndex = meta => {
  // test for cellMeta
  if ('dataIndex' in meta && !Number.isNaN(meta.dataIndex)) {
    return meta.dataIndex;
  }

  // test for tableMeta
  if ('currentTableData' in meta && 'rowIndex' in meta) {
    return meta.currentTableData[meta.rowIndex].index;
  }

  return undefined;
};

export const getActiveFoodDonors = foodDonors => (foodDonors || []).filter(fd => fd.makes_donations && fd.donor_active);

export const getTopDonorLocationReceiversTableData = (
  {
    donorLocationId,
    receivers,
    aggregates,
  },
  LBS_PER_MEAL
) => {
  let donorLocationReceiversData = get(
    aggregates, "rescues.count['donor_location,receiver'].none", []
  ).filter(r => r.location_id === donorLocationId);
  let topDonorLocationReceiversTableData = [];

  donorLocationReceiversData.map(dlr => {
    topDonorLocationReceiversTableData.push({
      name: get(receivers, `byId[${dlr.receiver_id}].name`, ''),
      rescues: dlr.count,
      meals: dlr.count_meals,
      lbs: Math.round(dlr.count_meals * LBS_PER_MEAL),
    });
  });

  return sortBy(topDonorLocationReceiversTableData, ['meals']).reverse().slice(0, 10);
};

export const getTopReceiverDonorsTableData = (
  {
    receiverId,
    foodDonors,
    aggregates,
  },
  LBS_PER_MEAL
) => {
  let receiverDonorLocationsData = get(
    aggregates, "rescues.count['receiver,donor_location'].none", []
  ).filter(r => r.receiver_id === receiverId);
  let topReceiverDonorLocationsTableData = [];

  receiverDonorLocationsData.map(rdl => {
    topReceiverDonorLocationsTableData.push({
      name: get(foodDonors, `byId[${rdl.location_id}].name`, ''),
      rescues: rdl.count,
      meals: rdl.count_meals,
      lbs: Math.round(rdl.count_meals * LBS_PER_MEAL),
    });
  });

  return sortBy(topReceiverDonorLocationsTableData, ['meals']).reverse().slice(0, 10);
};

export const getTopRescuersTableData = ({ siteId, rescuers, aggregates, granularity }, LBS_PER_MEAL) => {
  let rescuersData = get(aggregates, `rescues.count['site,rescuer'].${granularity}`, []);
  if (siteId) {
    rescuersData = rescuersData.filter(e => parseInt(e.site_id, 10) === parseInt(siteId, 10));
  }

  const groupedRescuesByRescuer = groupBy(rescuersData, item => item.rescuer_id);
  let topRescuersTableData = [];
  Object.entries(groupedRescuesByRescuer).forEach(([rescuerId, entries]) => {
    if (+rescuerId) {
      topRescuersTableData.push({
        name: get(rescuers, `byId[${rescuerId}].firstname`, '')
          + ' '
          + get(rescuers, `byId[${rescuerId}].lastname`, ''),
        rescues: entries.reduce((accumulator, {count}) => accumulator + count, 0),
        meals: entries.reduce((accumulator, {count_meals}) => accumulator + count_meals, 0),
        lbs: entries.reduce((accumulator, {count_meals}) => accumulator + Math.round(count_meals * LBS_PER_MEAL), 0),
      });
    }
  });
  return sortBy(topRescuersTableData, ['meals'])
    .reverse()
    .slice(0, 10);
};

export const getTopDonorLocationRescuersTableData = (
  {
    donorLocationId,
    rescuers,
    aggregates,
  },
  LBS_PER_MEAL
) => {
  let donorLocationReceiversData = get(
    aggregates, "rescues.count['donor_location,rescuer'].none", []
  ).filter(r => r.location_id === donorLocationId);
  let topDonorLocationReceiversTableData = [];

  donorLocationReceiversData.map(dlr => {
    if (dlr.rescuer_id !== null) {
      const rescuer = get(rescuers, `byId[${dlr.rescuer_id}]`, {});

      topDonorLocationReceiversTableData.push({
        name: `${rescuer.firstname} ${rescuer.lastname}`,
        rescues: dlr.count,
        meals: dlr.count_meals,
        lbs: Math.round(dlr.count_meals * LBS_PER_MEAL),
      });
    }
  });

  return sortBy(topDonorLocationReceiversTableData, ['meals']).reverse().slice(0, 10);
};

export const getTopReceiverRescuersTableData = (
  {
    receiverId,
    rescuers,
    aggregates,
  },
  LBS_PER_MEAL
) => {
  let donorLocationReceiversData = get(
    aggregates, "rescues.count['receiver,rescuer'].none", []
  ).filter(r => r.receiver_id === receiverId);
  let topDonorLocationReceiversTableData = [];

  donorLocationReceiversData.map(dlr => {
    if (dlr.rescuer_id !== null) {
      const rescuer = get(rescuers, `byId[${dlr.rescuer_id}]`, {});

      topDonorLocationReceiversTableData.push({
        name: `${rescuer.firstname} ${rescuer.lastname}`,
        rescues: dlr.count,
        meals: dlr.count_meals,
        lbs: Math.round(dlr.count_meals * LBS_PER_MEAL),
      });
    }
  });

  return sortBy(topDonorLocationReceiversTableData, ['meals']).reverse().slice(0, 10);
};

export const getRoleAssignment = roleAssignment => {
  switch (roleAssignment.role_name) {
    case Roles.SiteDirector:
    case Roles.SiteCoordinator:
    case Roles.Rescuer:
      return roleAssignment.site_name;
    case Roles.DonorAdmin:
    case Roles.DonorStaff:
      return roleAssignment.donor_name;
    case Roles.DonorLocationAdmin:
    case Roles.DonorLocationStaff:
      return roleAssignment.donor_location_name;
    case Roles.ReceiverAdmin:
    case Roles.ReceiverStaff:
      return roleAssignment.receiver_name;
    case Roles.ReceiverLocationAdmin:
    case Roles.ReceiverLocationStaff:
      return roleAssignment.receiver_location_name;
    default:
      return '-';
  }
}

export const getEarliestSiteDate = (selectedSite, sitesList) => {
  // Check if selected site is not an array and return creation date
  if (!Array.isArray(selectedSite) && selectedSite !== null && selectedSite.id !== -1) {
    return moment(selectedSite.created_at).toDate();
  }

  // Check dates for selected sites and return the earliest creation date
  if (selectedSite !== null && selectedSite.length > 0) {
    const siteWithEarliestDate = selectedSite.reduce((prev, curr) => {
      const findSelectedSite = sitesList.find(site => site.id === curr.id);
      if (findSelectedSite && moment(findSelectedSite.created_at).isBefore(prev.created_at)) {
        return findSelectedSite;
      }
      return prev;
    }, []);
    return moment(siteWithEarliestDate.created_at).toDate();
  }
  // If there is no sites selected search through all sites and return the earliest creation date
  const siteWithEarliestDate = sitesList.reduce((prev, curr) => {
    if (moment(curr.created_at).isBefore(prev.created_at)) {
      return curr;
    }
    return prev;
  }, []);
  return moment(siteWithEarliestDate.created_at).toDate();
};
