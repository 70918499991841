import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Breadcrumbs, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Home as HomeIcon } from '@material-ui/icons';
import useActiveUser from '../../../../hooks/useActiveUser';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import routes from '../../../../routes';
import { StyledRescuerBreadcrumb, StyledRescuerTextBreadcrumb } from '../Components/StyledRescuerBreadcrumb';
import MyEnviroImpact from '../../rescuerDashboardDesktop/myFrus/Components/MyEnviroImpact';

const useStyles = makeStyles(({ palette }) => ({
  enviroImpactContainer: {
    height: 'calc(100vh - 100px)', // -100 to remove navbar & breadcrumbs
    overflow: 'scroll',
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const EnviroImpactBreadcrumb = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={RouterLink}
        to={routes.index}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="My Environment Impact" />
    </Breadcrumbs>
  </Box>
);

const EnvironmentImpactDashboardMobileContainer = () => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const environmentImpactEntities = useSelector((state) => state.entities.environmentImpact);
  const environmentImpactLoading = environmentImpactEntities.inflight;
  const { fetchEnviromentImpact } = useRescuerDashboardActions();

  useEffect(() => {
    fetchEnviromentImpact(false); //quietMode, force
  }, [activeUser.id]);

  return (
    <>
      <EnviroImpactBreadcrumb />
      <Box className={classes.badgesContainer}>
        <Box display="flex" py={2} overflow="scroll">
          <MyEnviroImpact isLoading={environmentImpactLoading} environmentImpact={environmentImpactEntities.all} />
        </Box>
      </Box>
    </>
  );
};

export default EnvironmentImpactDashboardMobileContainer;
