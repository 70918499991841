import {
  INSTAGRAM_TYPE,
  RECEIVE_USER_LINKED_ACCOUNTS,
  RECEIVE_USER_LINKED_PAGES,
  REQUEST_USER_LINKED_ACCOUNTS,
  REQUEST_USER_LINKED_PAGES,
  REQUEST_USER_FEED,
  RECEIVE_USER_FEED,
  REQUEST_USER_METRICS,
  RECEIVE_USER_METRICS,
  SDK_INIT,
  SET_DISPLAY_LINKED_ACCOUNTS,
  FACEBOOK_TYPE,
  RECEIVE_USER_POSTS,
  REQUEST_USER_POSTS,
  RECEIVE_FACEBOOK_POST_DELETE,
} from '../actions/marketingHub';

export const initialState = {
  inflight: false,
  accounts: {
    all: [],
    instagram: [],
    inflight: false,
  },
  pages: {
    all: [],
    inflight: false,
  },
  feed: {
    all: [],
    lastUpdated: null,
  },
  posts: {
    all: [],
    scheduled: [],
    published: [],
    lastUpdated: null,
  },
  metrics: {
    all: [],
    byAccountId: {},
    lastUpdated: null,
  },
  sdkInit: false,
  displayLinkedAccounts: false,
  lastUpdated: null,
};

const marketingHubReducer = (state = initialState, action) => {
  switch (action.type) {
    case SDK_INIT:
      return {
        ...state,
        sdkInit: true,
      };
    case REQUEST_USER_LINKED_ACCOUNTS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          inflight: true,
        },
        inflight: true,
      };
    case RECEIVE_USER_LINKED_ACCOUNTS:
      return {
        ...state,
        accounts: {
          all: action.accounts,
          instagram: action.accounts.filter((account) => account.type === INSTAGRAM_TYPE),
          pages: action.accounts.filter((account) => account.type === FACEBOOK_TYPE),
          inflight: false,
        },
        inflight: false,
      };
    case REQUEST_USER_LINKED_PAGES:
      return {
        ...state,
        pages: {
          ...state.pages,
          inflight: true,
        },
        inflight: true,
      };
    case RECEIVE_USER_LINKED_PAGES:
      return {
        ...state,
        pages: {
          all: action.pages,
          inflight: false,
        },
        inflight: false,
      };
    case REQUEST_USER_FEED:
      return {
        ...state,
        inflight: true,
      };
    case RECEIVE_USER_FEED:
      return {
        ...state,
        feed: {
          all: action.feed,
          lastUpdated: action.receivedAt,
        },
        inflight: false,
      };
    case REQUEST_USER_METRICS:
      return {
        ...state,
        inflight: true,
      };
    case RECEIVE_USER_METRICS:
      return {
        ...state,
        metrics: {
          all: action.metrics,
          byAccountId: action.metrics.reduce((acc, curr) => {
            if (acc[`${curr.account_id}`]?.length) {
              return { ...acc, [curr.account_id]: [...acc[`${curr.account_id}`], curr] };
            }
            return { ...acc, [curr.account_id]: [curr] };
          }, {}),
          lastUpdated: action.receivedAt,
        },
        inflight: false,
      };
    case REQUEST_USER_POSTS:
      return {
        ...state,
        inflight: true,
      };
    case RECEIVE_USER_POSTS:
      return {
        ...state,
        posts: {
          all: action.posts,
          scheduled: action.posts.filter((post) => !post.published),
          published: action.posts.filter((post) => post.published),
          lastUpdated: action.receivedAt,
        },
        inflight: false,
      };
    case RECEIVE_FACEBOOK_POST_DELETE:
      return {
        ...state,
        posts: {
          all: state.posts.all.filter((post) => post.id !== action.deletedPostId),
          scheduled: state.posts.scheduled.filter((post) => post.id !== action.deletedPostId),
          published: state.posts.published.filter((post) => post.id !== action.deletedPostId),
          lastUpdated: action.receivedAt,
        },
        inflight: false,
      };
    case SET_DISPLAY_LINKED_ACCOUNTS:
      return {
        ...state,
        displayLinkedAccounts: !state.displayLinkedAccounts,
      };
    default:
      return state;
  }
};

export default marketingHubReducer;
