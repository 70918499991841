import React, { useState } from 'react';
import UserForm from '../../components/UserForm';
import routes from '../../routes';
import PhoneConfirmationCodeDialog from '../../components/PhoneConfirmationCodeDialog';
import Bluebird from 'bluebird';
import snackbarHelper from '../../helpers/snackbarHelper';
import * as usersActions from '../../actions/users';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as usersApi from '../../api/users';
import { formatPhoneNumber } from '../../helpers/formatters';
import VerificationEmailSentConfirmationDialog from '../../components/VerificationEmailSentConfirmationDialog';
import { getUserSitesByRoles, setDefaultSiteAndSites, currentlyLoggedInOrImpersonatingUserHasRoleInCurrentlySelectedSite } from '../../services/auth';
import { Roles } from '../../models/roles';
import VerificationPauseRescueConfirmationDialog from '../../components/VerificationPauseRescueConfirmationDialog';
import VerificationResumeRescueConfirmationDialog from '../../components/VerificationResumeRescueConfirmationDialog';

const ProfileView = props => {
  const { user, userIdentities, rescuerLocationTypes } = props;
  const [showVerificationEmailSentDialog, setShowVerificationEmailSentDialog] = useState(false);
  const [showPhoneNumberVerificationDialog, setShowPhoneNumberVerificationDialog] = useState(false);
  const [showVerificationPauseRescueDialog, setShowVerificationPauseRescueDialog] = useState(false);
  const [showVerificationResumeRescueDialog, setShowVerificationResumeRescueDialog] = useState(false);
  const [verificationDialogLoading, setVerificationDialogLoading] = useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));
  const { role_assignments: userRoleAssignments } = user;
  const isRescuer = currentlyLoggedInOrImpersonatingUserHasRoleInCurrentlySelectedSite(Roles.Rescuer);
  const sitesWithRescuerRoles = getUserSitesByRoles(userRoleAssignments, Roles.Rescuer).length;
  const isRescuerInMultipleSites = sitesWithRescuerRoles > 1;
  const updateUser = (userId, data) => dispatch(usersActions.updateUser(userId, data));

  const handleCodeNotReceived = () => {
    setVerificationDialogLoading(true);

    return Bluebird
      .try(() => usersApi.sendSmsVerifyRequest(user.id))
      .then(() => setVerificationDialogLoading(false))
      .then(() => setConfirmationCodeError(false));
  };

  const handleVerify = code => Bluebird
    .try(() => updateUser(user.id, { phone_verification_code: code }))
    .then(user => {
      if (user.phone_verified === null) {
        return Promise.reject();
      }

      handleClose();
      snackbarHelper.success('You have successfully verified your phone number!', 10000);
    })
    .catch(() => {
      setVerificationDialogLoading(false);
      setConfirmationCodeError(true);
    });

  const showPhoneVerificationDialog = () => setShowPhoneNumberVerificationDialog(true);

  const handleClose = () => {
    setShowPhoneNumberVerificationDialog(false);
    setVerificationDialogLoading(false);
    setConfirmationCodeError(false);
  };

  const handleSubmit = (userId, values, isEmailChanged) => Bluebird
    .try(() => updateUser(
      userId,
      {
        ...values,
        send_email_verification: true, // pass a flag, so API can (potentially - email change) send email verification
      }
    ))
    .then(response => {
      // user changed his roles (eg. leave site)
      if (values.hasOwnProperty('roles_to_detach')) {
        setDefaultSiteAndSites(response.role_assignments);
      }

      if (!isEmailChanged) {
        return false;
      }
    
      return setShowVerificationEmailSentDialog(true);
    });

  const handlePause = () => {
    setShowVerificationPauseRescueDialog(true);
  };

  const handleResume = () => {
    setShowVerificationResumeRescueDialog(true);
  };

  const handleRescuePause = () => {
    Bluebird.try(() =>
      updateUser(user.id, {
        paused: true,
      })
    );
  };

  const handleRescueResume = () => {
    Bluebird.try(() =>
      updateUser(user.id, {
        paused: false,
      })
    );
  };

  return (
    <>
      <UserForm
        user={user}
        isMobileView={isMobileView}
        ShowPauseRescue={isRescuer}
        updateUser={updateUser}
        pauseCallback={handlePause}
        resumeCallback={handleResume}
        redirectPath={generatePath(routes.profile)}
        submitCallback={handleSubmit}
        submitButtonText="Update Profile"
        showVerifyPhoneNumberButton
        rescuerLocationTypes={rescuerLocationTypes}
        showCommunicationCheckboxes={false}
        showPhoneVerificationDialog={showPhoneVerificationDialog}
        showVerifyEmailButton
        showRescuerRoleAssignmentsTable={isRescuerInMultipleSites}
        showPasswordFieldsOnEmailChange={
          !userIdentities
          || (userIdentities && !userIdentities.find(userIdentity => !userIdentity.is_social))
        }
      />

      {showVerificationPauseRescueDialog && (
        <VerificationPauseRescueConfirmationDialog 
          onCloseDialog={() => setShowVerificationPauseRescueDialog(false)}
          onPauseButtonClick={handleRescuePause}
        />
      )}

      {showVerificationResumeRescueDialog && (
        <VerificationResumeRescueConfirmationDialog 
          onCloseDialog={() => setShowVerificationResumeRescueDialog(false)}
          onResumeButtonClick={handleRescueResume}
        />
      )}

      {showVerificationEmailSentDialog && (
        <VerificationEmailSentConfirmationDialog
          onCloseDialog={() => setShowVerificationEmailSentDialog(false)}
          onGotItButtonClick={() => setShowVerificationEmailSentDialog(false)}
        />
      )}

      <PhoneConfirmationCodeDialog
        phoneNo={formatPhoneNumber(user.phone)}
        handleCodeNotReceived={handleCodeNotReceived}
        handleClose={handleClose}
        handleVerify={handleVerify}
        open={showPhoneNumberVerificationDialog}
        loading={verificationDialogLoading}
        confirmationCodeError={confirmationCodeError}
      />
    </>
  );
};

export default ProfileView;
