import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import Bluebird from 'bluebird';
import OverlayLoader from '../../../OverlayLoader';
import NotificationsCardList from './NotificationsCardList';
import { generatePath, useHistory } from 'react-router-dom';
import routes from '../../../../routes';
import * as notificationsActions from '../../../../actions/notifications';
import snackbarHelper from '../../../../helpers/snackbarHelper';
import * as errorsHelper from '../../../../helpers/errors';

const useStyles = makeStyles(() => ({
  accordionDetails: {
    height: `calc(100vh - 260px)`,
  },
}));

const NotificationsListMobile = ({ isLoading, read = [], unread = [] }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [unreadAccordionOpen, setUnreadAccordionOpen] = useState(true);
  const handleChange = () => setUnreadAccordionOpen(!unreadAccordionOpen);
  const goToNotification = notificationId => history.push(generatePath(routes.notification, { notificationId }));

  const markAsRead = notificationId => Bluebird
    .try(() => dispatch(notificationsActions.markNotificationAsRead(notificationId)))
    .then(() => snackbarHelper.success('Notification has been marked as read successfully.'))
    .catch(err => errorsHelper.handleError(err, 'Error occured during marking notification as read.'))

  const classes = useStyles();

  return (
    <OverlayLoader isLoading={isLoading}>
      <Accordion expanded={unreadAccordionOpen} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge color="primary" badgeContent={unread.length} style={{ paddingRight: 0 }}>
            <Typography style={{ marginRight: 10 }}>Unread</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <NotificationsCardList
            isLoading={isLoading}
            showMarkNotificationAsReadButton
            goToNotificationDetails={goToNotification}
            markAsRead={markAsRead}
            notifications={unread}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={!unreadAccordionOpen} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge color="primary" badgeContent={read.length} style={{ paddingRight: 0 }}>
            <Typography style={{ marginRight: 10 }}>Last 30 Days</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <NotificationsCardList
            isLoading={isLoading}
            goToNotificationDetails={goToNotification}
            markAsRead={markAsRead}
            notifications={read}
          />
        </AccordionDetails>
      </Accordion>
    </OverlayLoader>
  );
};

export default NotificationsListMobile;
