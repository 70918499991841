import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box, FormHelperText } from '@material-ui/core';

// https://github.com/dozoisch/react-google-recaptcha#global-properties-used-by-recaptcha
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

const ReCaptchaToken = ({ onCaptchaLoad, onChange, helperText, error = false }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const captchaRef = useRef();

  useEffect(() => {
    onCaptchaLoad();
  }, [isLoaded]);

  return (
    <Box>
      <ReCAPTCHA
        ref={captchaRef}
        asyncScriptOnLoad={() => setIsLoaded(true)}
        onChange={onChange}
        sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
      />
      <FormHelperText error={error} hidden={!isLoaded}>
        {helperText}
      </FormHelperText>
    </Box>
  );
};

export default ReCaptchaToken;
