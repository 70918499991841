import { Avatar, Box, Card, makeStyles, Typography } from '@material-ui/core';
import { Facebook as FacebookIcon, Instagram as InstagramIcon } from '@material-ui/icons';
import React from 'react';
import { FACEBOOK_TYPE, INSTAGRAM_TYPE } from '../../../../actions/marketingHub';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  title: {
    textAlign: 'center',
  }
}));

const ProfileCard = ({ type = '', name = '', picture = '' }) => {
  const classes = useStyles();

  return (
    <Box width={150} minHeight={162} height="100%" boxShadow={25}>
      <Card variant="outlined" style={{ height: '100%' }}>
        <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center" height="100%">
          <Box display="flex" justifyContent="center">
            {type === FACEBOOK_TYPE && <FacebookIcon />}
            {type === INSTAGRAM_TYPE && <InstagramIcon />}
            <Typography>{type}</Typography>
          </Box>
          <Box mt={1}>
            <Avatar src={picture} className={classes.large} />
          </Box>
          <Typography className={classes.title}>{name}</Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default ProfileCard;
