import React from 'react';
import {
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
  Typography,
} from '@material-ui/core';
import { Close, Phone } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import OverlayLoader from './OverlayLoader';
import Bluebird from 'bluebird';

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    flexWrap: 'wrap',
    rowGap: `${spacing(1)}px`,
  },
}))(MuiDialogActions);

class PhoneConfirmationCodeDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationCode: '',
    };
  }

  handleChangeConfirmationCode = event => {
    this.setState({
      confirmationCode: event.target.value,
    });
  };

  handleVerify = () => {
    const { handleVerify } = this.props;
    const { confirmationCode } = this.state;

    handleVerify(confirmationCode);
  };

  handleCodeNotReceived = () => {
    const { handleCodeNotReceived } = this.props;
    return Bluebird
      .try(() => handleCodeNotReceived())
      .then(() => this.setState({ confirmationCode: '' }))
  };

  render() {
    const { handleClose, open, loading, confirmationCodeError, phoneNo } = this.props;
    const { confirmationCode } = this.state;

    return (
      <Dialog open={open} onClose={handleClose} disableBackdropClick>
        <OverlayLoader isLoading={loading}>
          <DialogTitle id="form-dialog-title">Enter confirmation code</DialogTitle>

          <DialogContent>
            <Typography gutterBottom color="textSecondary">
              We’ve just sent a code to phone number <strong>{phoneNo}</strong>.
            </Typography>
            <Typography color="textSecondary">
              Please check your phone and enter the verification code below.
            </Typography>

            <TextField
              id="outlined-name"
              label="Confirmation Code"
              margin="normal"
              value={confirmationCode}
              onChange={this.handleChangeConfirmationCode}
              error={confirmationCodeError}
              inputProps={{
                autoComplete: 'one-time-code',
              }}
              helperText={
                confirmationCodeError ?
                  `Invalid confirmation code provided.` :
                  `Enter the verification code that we’ve just sent to ${phoneNo}`}
              fullWidth
              autoFocus
            />

          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary" startIcon={<Close fontSize="small" />}>
              Cancel
            </Button>

            <Button
              onClick={this.handleVerify}
              variant="contained"
              color="primary"
              startIcon={<Phone fontSize="small" />}
            >
              Verify
            </Button>

            <Button
              onClick={this.handleCodeNotReceived}
              variant="contained"
              color="secondary"
              startIcon={<Close fontSize="small" />}
            >
              I didn't get a text
            </Button>
          </DialogActions>
        </OverlayLoader>
      </Dialog>
    );
  }
}

PhoneConfirmationCodeDialog.propTypes = {
  handleClose: PropTypes.any.isRequired,
  handleVerify: PropTypes.any.isRequired,
  handleCodeNotReceived: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  loading: PropTypes.any.isRequired,
};

export default PhoneConfirmationCodeDialog;
