export const downloadFileByURL = ({ url, fileName }) => {
  if (!url) {
    return null;
  }

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName || 'file';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
