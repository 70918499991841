import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReceiverNeedsProfile from '../components/ReceiverNeedsProfile';
import { fetchReceiverNeedsProfile } from '../actions/receivers';
import { newFoodTypes } from '../models/foodTypes';

const ReceiverNeedsProfileView = ({ receiverId, receiverLocationId }) => {
  const dispatch = useDispatch();
  const foodDescriptionsEntities = useSelector(state => state.entities.foodDescriptions);
  const receiverNeedsProfileEntities = useSelector((state) => state.entities.receivers.needsProfile);
  const receiverNeedsProfile = receiverNeedsProfileEntities.byLocationId[receiverLocationId] || [];
  const foodDescriptions = Object.values(foodDescriptionsEntities.byId);
  const categories = foodDescriptions.filter((fd) => newFoodTypes.some((ft) => ft.label === fd.name));

  const initialValues = Object.assign(
    {},
    ...categories.map((fd) => {
      const need = receiverNeedsProfile.find((n) => n.food_description_id === fd.id);
      return { [fd.id]: need?.rating || 0 };
    })
  );

  useEffect(() => {
    dispatch(fetchReceiverNeedsProfile(receiverId, receiverLocationId));
  }, []);

  if (receiverNeedsProfileEntities.inflight || foodDescriptionsEntities.inflight) {
    return '...loading';
  }

  return (
    <ReceiverNeedsProfile
      receiverId={receiverId}
      receiverLocationId={receiverLocationId}
      categories={categories}
      initialValues={initialValues}
    />
  );
};

export default ReceiverNeedsProfileView;
