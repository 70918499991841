import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ReactLoading from 'react-loading';
import Bluebird from 'bluebird';
import queryString from 'query-string';
import moment from 'moment';
import { generatePath } from 'react-router-dom';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import * as authService from '../services/auth';
import * as authActions from '../actions/auth';
import * as usersActions from '../actions/users';
import * as userRegistrationsApi from '../api/userRegistrations';
import routes from '../routes';
import { Colors } from '../assets/theme/Colors';
import { store } from '../store';

class Callback extends React.Component {
  componentDidMount() {
    const { accessToken, userRegistration, setUserRegistration, history, location } = this.props;

    if (location.search) {
      if (authService.isCompleteRegistrationProcessPending()) {
        if (!userRegistration.accessToken) {
          return history.push(routes.login);
        }

        const parsedSearch = queryString.parse(location.search);
        const completeRegistrationPath = generatePath(routes.completeRegistrationNew);

        if (parsedSearch.supportSignUp === 'true' && parsedSearch.supportForgotPassword === 'true') {
          if (parsedSearch.code === 'success' && parsedSearch.success === 'true') {
            authService.userInfo(userRegistration.accessToken, (error, user) => {
              if (user && user.email_verified) {
                return Bluebird.try(() =>
                  userRegistrationsApi.updateUserRegistration(
                    userRegistration.data.id,
                    {
                      email_verified: true,
                    },
                    userRegistration.accessToken
                  )
                )
                  .then(() =>
                    setUserRegistration(userRegistration.accessToken, userRegistration.idToken, {
                      ...userRegistration.idTokenPayload,
                      email_verified: true,
                    })
                  )
                  .then(() =>
                    Bluebird.try(() => {
                      if (userRegistration.data.tos_accepted !== null) {
                        return authService.createProfile(userRegistration, history);
                      }

                      snackbarHelper.success(parsedSearch.message);
                      history.push(completeRegistrationPath);
                    })
                  )
                  .catch(err => errorsHelper.handleError(err));
              }

              snackbarHelper.error(error.errorDescription);
            });
          } else if (parsedSearch.code === 'already-verified' && parsedSearch.success === 'false') {
            snackbarHelper.info(parsedSearch.message);
            history.push(completeRegistrationPath);
          }
        } else if (parsedSearch.success === 'false') {
          snackbarHelper.info(parsedSearch.message);
          history.push(completeRegistrationPath);
        }
      } else {
        if (!accessToken) {
          return history.push(routes.login);
        }

        const parsedSearch = queryString.parse(location.search);

        if (parsedSearch.supportSignUp === 'true' && parsedSearch.supportForgotPassword === 'true') {
          if (parsedSearch.code === 'success' && parsedSearch.success === 'true') {
            authService.userInfo(accessToken, (error, user) => {
              if (user && user.email_verified) {
                const currentlyLoggedInOrImpersonatingUser = authService.getCurrentlyLoggedInOrImpersonatingUser();

                return Bluebird
                  .try(() => store.dispatch(
                    usersActions.updateUser(
                      currentlyLoggedInOrImpersonatingUser.id,
                      {
                        email_verified: moment().format('YYYY-MM-DD HH:mm:ss'),
                      }
                    )
                  ))
                  .then(() => {
                    snackbarHelper.success(parsedSearch.message);

                    history.push(routes.index);
                  });
              }

              snackbarHelper.error(error.errorDescription);
            });
          } else if (parsedSearch.code === 'already-verified' && parsedSearch.success === 'false') {
            snackbarHelper.info(parsedSearch.message);

            history.push(routes.index);
          }
        } else if (parsedSearch.success === 'false') {
          snackbarHelper.info(parsedSearch.message);

          history.push(routes.index);
        }
      }
    }
  };

  render() {
    return (
      <ReactLoading color={Colors.appBar.main} type="bubbles" />
    );
  }
}

const mapStateToProps = ({ app: { accessToken, userRegistration  } }) => ({
  accessToken,
  userRegistration,
});
const mapDispatchToProps = dispatch => ({
  setUserRegistration: (accessToken, idToken, idTokenPayload, data) => dispatch(
    authActions.setUserRegistration(accessToken, idToken, idTokenPayload, data)
  ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Callback)
);
