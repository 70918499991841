import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { Field, Form } from 'react-final-form';
import { TextFieldWrapper as TextField } from './Form/MuiFormWrapper';
import { FormControl, MenuItem } from '@material-ui/core';
import PrimaryContactUserSelect from './PrimaryContactUserSelect';

const EmailsForm = ({ isAdmin, sitesList, onSubmit, defaultValues }) => {
  const [selectedSite, setSelectedSite] = useState(defaultValues.sites);
  const [selectedUser, setSelectedUser] = useState(defaultValues.user);
  const [selectedType, setSelectedType] = useState(defaultValues.type);

  const handleSubmit = values => onSubmit({
    sites: values.sites,
    type: values.type,
    user: values.user,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      render={() => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          {(isAdmin || sitesList.length > 1) && (
            <Field
              name="site_id"
              render={({ input }) => (
                <ReactSelect
                  isMulti
                  name={input.name}
                  placeholder="Select site(s) or leave blank to show data for all sites"
                  value={selectedSite}
                  options={[{ id: -1, name: 'All' }, ...sitesList]}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  onChange={option => {
                    let choices = option !== null ? option : [];
                    if (choices.some(site => site.id === -1)) {
                      choices = [];
                    }
                    input.onChange(choices);

                    setSelectedSite(choices);

                    return handleSubmit({
                      sites: choices,
                      type: selectedType,
                      user: selectedUser,
                    });
                  }}
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      zIndex: 101,
                    }),
                  }}
                />
              )}
            />
          )}

          {isAdmin && (
            <div style={{ marginTop: 16 }}>
              <PrimaryContactUserSelect
                name="email_recipient"
                fullWidth
                onChangeUser={(user) => {
                  setSelectedUser(user);

                  return handleSubmit({
                    sites: selectedSite,
                    type: selectedType,
                    user: user,
                  });
                }}
                formValue={selectedUser ? selectedUser.label : null}
                label="Search for email recipient or leave blank to show data for all recipients"
                required
              />
            </div>
          )}

          {isAdmin && (
            <div style={{ marginTop: 16 }}>
              <FormControl>
                <TextField
                  required
                  onChange={event => {
                    setSelectedType(event.target.value);

                    return handleSubmit({
                      sites: selectedSite,
                      type: event.target.value,
                      user: selectedUser,
                    });
                  }}
                  select
                  id="granularity_id"
                  data-testid="granularity"
                  label="Email Type"
                  variant="outlined"
                  size="small"
                  value={selectedType}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    name: 'granularity',
                    id: 'granularity_id',
                  }}
                  fullWidth
                >
                  {[
                    { label: 'Donation Created', value: 'DONATION_CREATED' },
                    { label: 'Donation Modified', value: 'DONATION_MODIFIED' },
                    { label: 'Donation Deleted', value: 'DONATION_DELETED' },
                    { label: 'Rescue Cancelled', value: 'RESCUE_CANCELLED' },
                    { label: 'Rescue Claimed', value: 'RESCUE_CLAIMED' },
                    { label: 'Rescue Unclaimed', value: 'RESCUE_UNCLAIMED' },
                    { label: 'Rescue Adopted', value: 'RESCUE_ADOPTED' },
                    { label: 'Rescue Unadopted', value: 'RESCUE_UNADOPTED' },
                    { label: 'Rescue Released', value: 'RESCUE_RELEASED' },
                    { label: 'Rescuer Comments', value: 'RESCUER_COMMENTS' },
                    { label: 'Unclaimed Rescues', value: 'UNCLAIMED_RESCUES' },
                    { label: 'Rescues Reminder', value: 'RESCUES_REMINDER' },
                    { label: 'Site Director Email', value: 'SITE_DIRECTOR_EMAIL' },
                  ].map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div>
          )}
        </form>
      )}
    />
  );
};

export default EmailsForm;
