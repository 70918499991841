import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { DashboardTableCard } from '../../../../components/Dashboard/DashboardTableCard';
import { CardContent, CardHeader, Typography } from '@material-ui/core';
import { getMyRescues } from '../../../../helpers/RescuesHelper';
import MyClaimedRescues from './Component/MyClaimedRescues';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import useRescuerRescueActions from '../../../../hooks/useRescuerRescueActions';
import routes from '../../../../routes';
import { get } from 'lodash';
import useActiveUser from '../../../../hooks/useActiveUser';

const MyRescuesDesktop = ({ history }) => {
  const dispatch = useDispatch();
  const activeUser = useActiveUser();
  const rescuesEntities = useSelector(state => state.entities.rescues);
  const claimedRescues = getMyRescues(activeUser.id, rescuesEntities);
  const { fetchMyFrusTab } = useRescuerDashboardActions();
  const isInFlight = rescuesEntities.inflight || get(rescuesEntities, ['claimedAndAdoptedIds', activeUser.id, 'inflight'], true);

  useEffect(() => {
    fetchMyFrusTab(false, false);
  }, [dispatch]);

  const onCellClick = (rescue) => history.push(generatePath(routes.rescue, { rescueId: rescue.id }));

  return (
    <DashboardTableCard isLoading={isInFlight}>
      <CardHeader
        subheader={
          claimedRescues.length > 0 ? (
            <Typography variant="body2" color="textSecondary">
              Showing all of your upcoming rescues.
            </Typography>
          ) : null
        }
      />
      <CardContent>
        <MyClaimedRescues
          isLoading={isInFlight}
          claimedRescues={claimedRescues}
          handleCellClick={onCellClick}
        />
      </CardContent>
    </DashboardTableCard>
  );
};

export default MyRescuesDesktop;
