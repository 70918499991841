import React from 'react';
import { useSelector } from 'react-redux';
import useActiveSite from './useActiveSite';
import { hasActiveFeatureFlag } from '../helpers/fetureFlagsHelper';
import useActiveUser from './useActiveUser';
import { getUserSitesByRoles } from '../services/auth';
import { Roles } from '../models/roles';

const useHasActiveFeatureFlag = flagName => {
  const activeSite = useActiveSite();
  const activeUser = useActiveUser();
  const featureFlags = useSelector(state => state.entities.featureFlags);
  const featureFlagsList = Object.values(featureFlags.byId);
  const sitesEntities = useSelector(state => state.entities.sites);

  if (!activeUser) {
    return false;
  }

  const { role_assignments: userRoleAssignments } = activeUser;
  const sitesWithRescuerRoles = getUserSitesByRoles(userRoleAssignments, Roles.Rescuer);
  const isRescuerOnlyInMultipleSites =
    sitesWithRescuerRoles.length > 1 && sitesWithRescuerRoles.length === userRoleAssignments.length;

  // check if rescuer is a rescuer in > 1 sites
  if (isRescuerOnlyInMultipleSites) {
    // effective feature flags are those enabled in both sites
    return sitesWithRescuerRoles.every(siteId =>
      hasActiveFeatureFlag(flagName, sitesEntities.byId[siteId], featureFlagsList)
    );
  }

  // non-rescuer users and rescuers with single site
  return hasActiveFeatureFlag(flagName, activeSite, featureFlagsList);
};

export default useHasActiveFeatureFlag;
