import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { Breadcrumbs, Typography, Grid } from '@material-ui/core';
import Bluebird from 'bluebird';
import routes from '../../../routes';
import { createEvent } from '../../../actions/events';
import useActiveUser from '../../../hooks/useActiveUser';
import useActiveSite from '../../../hooks/useActiveSite';
import EventCreateForm from './EventCreateForm';
import useNotificationService from '../../../hooks/useNotificationService';

const EventCreateView = ({ isMobileView, history }) => {
  const dispatch = useDispatch();
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const { addSuccessNotification, addErrorNotification } = useNotificationService();

  const createEventHandler = (eventData) =>
    Bluebird.try(() =>
      dispatch(
        createEvent(activeSite.id, {
          ...eventData,
          user: activeUser,
        })
      )
    )
      .then((eventResponse) => history.push(generatePath(routes.eventEdit, { eventId: eventResponse.id })))
      .then(() => addSuccessNotification('Event added successfully!'))
      .catch((error) => addErrorNotification(error, 'Unable to create event!'));

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link to={routes.index} color="inherit">
          Home
        </Link>

        <Link to={routes.eventsList} color="inherit">
          Events
        </Link>

        <Typography color="textPrimary">Event add</Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12} sm={8} md={6}>
          <EventCreateForm
            submitHandler={createEventHandler}
            isMobileView={isMobileView}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EventCreateView;
