import React, { useState, Fragment, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Collapse, Box, Divider } from '@material-ui/core';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import RescuerSelector from '../../../../../components/RescuerSelector';
import useActiveSite from '../../../../../hooks/useActiveSite';
import { formatTime } from '../../../../../helpers/formatters';
import OverlayLoader from '../../../../../components/OverlayLoader';
import CalendarBox from './CalendarBox';
import MinMaxTimePicker from '../../../../../components/MinMaxTimePicker';
import { donationManageActions } from '../../../../../helpers/donations';
import DonationContext from '../../../../../context/DonationContext/DonationContext';
import { TextFieldWrapper } from '../../../../../components/Form/MuiFormWrapper';
import useActiveUser from '../../../../../hooks/useActiveUser';
import { getRescuersSelectOptions } from '../../../../../helpers/getters';
import { FREQUENCY_ONCE, SCHEDULE_STATUS_DELETED } from '../../../../../models/donationsNew';
import { Colors } from '../../../../../assets/theme/Colors';
import moment from 'moment';
import { hasAnyRoleInCurrentlySelectedSite } from '../../../../../services/auth';
import { Roles } from '../../../../../models/roles';
import { getRescuePickupLocationFullName } from '../../../../../helpers/RescuesHelper';
import { SelectorMobileDialog } from '../../../../../components/Common/SelectorMobileDialog';

const useAgendaRowStyles = makeStyles(() => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '60px 25px 0.1fr 1fr 20px',
    gridColumnGap: '8px',
    alignItems: 'center',
  },
  headerExpandable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  square: {
    position: 'relative',
    width: 25,
    height: 25,
  },
}));

const ExpandedPanel = ({ rescue, rescuersList, disabled, isNotSCOrGreaterRoleUser }) => {
  const [isRescuerSelectorOpen, setRescuerSelectorOpen] = useState(false);
  const [pickupBeginValue, setPickupBeginValue] = useState(rescue.pickup_begin.value);
  const [pickupEndValue, setPickupEndValue] = useState(rescue.pickup_end.value);
  const [rescuerNotesValue, setRescuerNotesValue] = useState(rescue.rescuer_notes || '');
  const [pickupEndTimeValid, setPickupEndTimeValid] = useState(false);
  const { onFieldChange, isMobileView } = useContext(DonationContext);

  // update state value on redux data change
  useEffect(() => setRescuerNotesValue(rescue.rescuer_notes || ''), [rescue.rescuer_notes]);
  useEffect(() => setPickupBeginValue(rescue.pickup_begin.value), [rescue.pickup_begin.value]);
  useEffect(() => {
    setPickupEndTimeValid(false);
    setPickupEndValue(rescue.pickup_end.value);
  }, [rescue.pickup_end.value]);

  const handlePickupBeginChange = ({ event }) => {
    setPickupBeginValue(event.format('HH:mm:00'));

    if (isMobileView) {
      onFieldChange(donationManageActions.set_rescue_pickup_begin, {
        date: rescue.date.value,
        day: rescue.day,
        ordinal: rescue.ordinal,
        position: rescue.position,
        begin: event.format('HH:mm:00'),
      });
    }
  };

  const handlePickupBeginUpdate = () => {
    if (isMobileView) {
      return;
    }
    onFieldChange(donationManageActions.set_rescue_pickup_begin, {
      date: rescue.date.value,
      day: rescue.day,
      ordinal: rescue.ordinal,
      position: rescue.position,
      begin: pickupBeginValue,
    });
  };

  const handlePickupEndChange = ({ event, isValid }) => {
    if (isValid) {
      setPickupEndValue(event.format('HH:mm:00'));
    }
    setPickupEndTimeValid(isValid);

    if (isMobileView && isValid) {
      onFieldChange(donationManageActions.set_rescue_pickup_end, {
        date: rescue.date.value,
        day: rescue.day,
        ordinal: rescue.ordinal,
        position: rescue.position,
        end: event.format('HH:mm:00'),
      });
    }
  };

  const handlePickupEndUpdate = () => {
    if (isMobileView) {
      return;
    }

    if (pickupEndTimeValid) {
      onFieldChange(donationManageActions.set_rescue_pickup_end, {
        date: rescue.date.value,
        day: rescue.day,
        ordinal: rescue.ordinal,
        position: rescue.position,
        end: pickupEndValue,
      });
    }
  };

  const handleNotesChange = event => setRescuerNotesValue(event.target.value);
  const handleNotesBlur = event =>
    onFieldChange(donationManageActions.set_rescue_rescuer_notes, {
      date: rescue.date.value,
      day: rescue.day,
      ordinal: rescue.ordinal,
      position: rescue.position,
      rescuer_notes: event.target.value,
    });

  const handleRescuerUpdate = choice =>
    onFieldChange(donationManageActions.set_rescue_rescuer, {
      date: rescue.date.value,
      day: rescue.day,
      ordinal: rescue.ordinal,
      position: rescue.position,
      rescuer_id: choice ? choice.id : null,
    });

  return (
    <>
      <SelectorMobileDialog
        dialogTitle="Rescuer:"
        getOptionId={adopter => adopter.value.id}
        getOptionLabel={adopter => adopter.label}
        options={rescuersList}
        disabled={isNotSCOrGreaterRoleUser}
        open={isRescuerSelectorOpen}
        onClose={value => {
          if (value) {
            handleRescuerUpdate(value);
          }
          setRescuerSelectorOpen(false);
        }}
      />
      <Box
        css={{
          display: 'grid',
          gridColumnGap: 16,
          gridRowGap: 16,
        }}
      >
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <MinMaxTimePicker
            fullWidth
            disabled={disabled}
            inputVariant="outlined"
            size="small"
            label="Begin"
            variant={isMobileView ? 'dialog' : 'inline'}
            value={moment(pickupBeginValue, 'HH:mm:ss')}
            maxTime={moment(pickupEndValue, 'HH:mm:ss')}
            onTimeChange={handlePickupBeginChange}
            onClose={handlePickupBeginUpdate}
          />
          <Box mx={2}>-</Box>
          <MinMaxTimePicker
            fullWidth
            disabled={disabled}
            showFieldErrors
            inputVariant="outlined"
            size="small"
            label="End"
            variant={isMobileView ? 'dialog' : 'inline'}
            value={moment(pickupEndValue, 'HH:mm:ss')}
            minTime={moment(pickupBeginValue, 'HH:mm:ss')}
            onTimeChange={handlePickupEndChange}
            onClose={handlePickupEndUpdate}
          />
        </Box>
        <TextFieldWrapper
          disabled={disabled}
          rowsMax={3}
          multiline
          label="Rescuer notes"
          name={`notes`}
          variant="outlined"
          size="small"
          fullWidth
          InputLabelProps={{ shrink: !!rescuerNotesValue || undefined }}
          value={rescuerNotesValue}
          onChange={handleNotesChange}
          onBlur={handleNotesBlur}
        />

        <RescuerSelector
          isDisabled={isNotSCOrGreaterRoleUser}
          rescuersList={rescuersList}
          onChange={handleRescuerUpdate}
          onMenuOpen={() => {
            if (isMobileView) {
              setRescuerSelectorOpen(true);
            }
          }}
          rescue={rescue}
        />
      </Box>
    </>
  );
};

const AgendaRow = ({ event, rescuersList, positions, disabled }) => {
  const activeUser = useActiveUser();
  const { extendedProps: eventData } = event;
  const classes = useAgendaRowStyles();
  const isDeleted = eventData.meta.schedule_status === SCHEDULE_STATUS_DELETED;
  const isOneTimeOnlyFrequency = eventData.meta.frequency === FREQUENCY_ONCE;
  const isExpandable = !isDeleted && !isOneTimeOnlyFrequency;
  const [isOpen, setIsOpen] = useState(false);
  const isNotSCOrGreaterRoleUser = !hasAnyRoleInCurrentlySelectedSite(activeUser, [
    Roles.NationalSiteDirector,
    Roles.Admin,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const pickupLocationFullName = getRescuePickupLocationFullName({
    pickup_location_name: eventData.meta.food_donor.pickup_location
      ? eventData.meta.food_donor.pickup_location.name
      : null,
    location: eventData.meta.food_donor ? eventData.meta.food_donor.name : 'N/A',
  });

  return (
    <Box data-eventid={event.id}>
      <Box
        className={classNames({
          [classes.header]: true,
          [classes.headerExpandable]: isExpandable,
        })}
        onClick={() => setIsOpen(!isOpen)}
        padding={1}
      >
        <Box display="flex" flexDirection="column">
          <Box>{formatTime(eventData.meta.pickup_begin.value)}</Box>
          <Box>{formatTime(eventData.meta.pickup_end.value)}</Box>
        </Box>
        <Box mr={2}>
          <Box className={classes.square}>
            <CalendarBox
              isCancelled={eventData.isCancelled}
              noReceiver={eventData.noReceiver}
              claimColor={eventData.claimColor}
              statusColor={eventData.statusColor}
            />
          </Box>
        </Box>
        <Box textAlign="center">
          {eventData.meta.position}/{positions}
        </Box>
        <Box
          css={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography noWrap>
            <strong>From:</strong> <span>{pickupLocationFullName}</span>
          </Typography>
          <Typography noWrap>
            <strong>To:</strong> <span>{eventData.meta.receiver ? eventData.meta.receiver.name : 'N/A'}</span>
          </Typography>
          {event.title && (
            <Typography noWrap>
              <strong>Rescuer notes:</strong> {event.title}
            </Typography>
          )}
        </Box>

        {isExpandable && <Box>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>}

        {isDeleted && (
          <Typography
            variant="caption"
            style={{
              color: Colors.donation.pickups.deleted.color,
            }}
          >
            <strong>DELETED</strong>
          </Typography>
        )}
      </Box>

      {isExpandable && (
        <Collapse in={isOpen}>
          <Box p={2}>
            <ExpandedPanel
              isNotSCOrGreaterRoleUser={isNotSCOrGreaterRoleUser}
              rescue={eventData.meta}
              rescuersList={rescuersList}
              disabled={disabled}
            />
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

const AGENDA_HEIGHT = 260;

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid var(--fc-border-color, #ddd)',
  },
  eventsContainer: {
    overflow: 'scroll',
    height: AGENDA_HEIGHT,
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '0.65rem',
      height: '0.65rem',
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  noData: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dayContainer: {
    margin: theme.spacing(-1, -1, 1, -1),
    padding: theme.spacing(1),
    background: '#ccc',
    color: '#000',
  },
}));

const TabledAgenda = ({ events, isLoading }) => {
  const activeSite = useActiveSite();
  const classes = useStyles();
  const rescuersEntities = useSelector(state => state.entities.sites.rescuers.bySiteId[activeSite.id]);
  const rescuersList = getRescuersSelectOptions(rescuersEntities || []);

  return (
    <Box position="relative">
      <OverlayLoader isLoading={isLoading} wrapperStyles={{ width: '100%' }}>
        <Box className={classes.container}>
          {Array.isArray(events) &&
            (events.length > 0 && (
              <Box className={classes.dayContainer}>
                <Typography variant="body1">
                  <strong>{moment(events[0].extendedProps.meta.date.value).format('dddd, MM/DD/YYYY')}</strong>
                </Typography>
              </Box>
            ))}
          <Box className={classes.eventsContainer}>
            {!Array.isArray(events) ||
              (events.length === 0 && (
                <Box className={classes.noData}>
                  <Typography variant="subtitle2">NO EVENTS FOR SELECTED DAY</Typography>
                </Box>
              ))}

            {events.map((event, index, { length }) => (
              <Fragment key={event.id}>
                <AgendaRow event={event} rescuersList={rescuersList} positions={length} disabled={isLoading} />

                {index !== length - 1 && <Divider component="hr" />}
              </Fragment>
            ))}
          </Box>
        </Box>
      </OverlayLoader>
    </Box>
  );
};

export default TabledAgenda;
