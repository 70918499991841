import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Paper,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
} from '@material-ui/icons';
import { getCurrentlyLoggedInOrImpersonatingUser } from '../../services/auth';
import MyEnviroImpact from '../rescuerDashboard/rescuerDashboardDesktop/myFrus/Components/MyEnviroImpact';
import { Colors } from '../../assets/theme/Colors';
import { fetchEnvironmentImpact } from '../../actions/environmentImpact';
import { hideUnnasignedUserBanner } from '../../actions/uiPersisted';
import HtmlTooltip from '../../components/Common/HtmlTooltip';
import { fetchInstagramDataIfNeeded } from '../../actions/instagram';
import UnassignedUserMap from './components/UnassignedUserMap';

const useStyles = makeStyles((theme) => ({
  banner: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
  },
  text: {
    lineHeight: 1,
  },
  textLink: {
    color: Colors.secondaryColor,
  },
  icon: {
    color: Colors.primaryColor,
  },
  startSiteContainer: {
    backgroundColor: Colors.primaryColor,
    clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0% 100%)',
  },
  startSiteText: {
    color: Colors.white,
  },
  container: {
    padding: theme.spacing(2),
  },
  instagramImage: {
    objectFit: 'cover',
    display: 'block',
    width: '100%',
    height: '100%',
    aspectRatio: '1/ 1',
  },
}));

const UnassignedUserDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const environmentImpact = useSelector((state) => state.entities.environmentImpact);
  const hideBanner = useSelector((state) => state.ui.persisted.hideUnnasignedUserBanner);
  const instagramEntities = useSelector((state) => state.entities.instagramItems);
  const insagramItems = Object.values(instagramEntities.byId)
    .filter((item) => item.media_type === 'IMAGE')
    .slice(0, 6);
  const [showInfoBanner, setShowInfoBanner] = useState(!hideBanner);
  const user = getCurrentlyLoggedInOrImpersonatingUser();
  const currentYear = moment().year();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    dispatch(
      fetchEnvironmentImpact(
        false,
        moment().startOf('year').format('YYYY-MM-DD'),
        moment().endOf('year').format('YYYY-MM-DD')
      )
    );
    dispatch(fetchInstagramDataIfNeeded());
  }, []);

  const onClose = () => {
    setShowInfoBanner(false);
    dispatch(hideUnnasignedUserBanner());
  };

  const StartSiteButton = styled(Button)({
    textTransform: 'none',
    padding: '12px 48px',
    borderRadius: 0,
    backgroundColor: Colors.white,
  });

  const RedButton = styled(Button)({
    backgroundColor: Colors.appBar.main,
    textTransform: 'none',
    boxShadow: '4px 4px 0px 0px rgba(66, 68, 90, 1)',
    color: Colors.white,
    borderRadius: 0,
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: '#C7382E',
      boxShadow: '4px 4px 0px 0px rgba(66, 68, 90, 0.8)',
    },
  });

  return (
    <Box display="grid" gridRowGap={16} mb={2}>
      {showInfoBanner && (
        <Paper className={classes.banner}>
          <Typography variant="body2">
            It looks like we don't currently have a site in the area you indicated. However, there are still ways you
            can contribute to fighting hunger in your community!
          </Typography>
          <IconButton className={classes.icon} size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Paper>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h5">
              {user.firstname} {user.lastname}, we're so glad you're here!
            </Typography>
            <Box py={2}>
              <Typography variant="body1">
                Food Rescue US is committed to ending hunger and reducing food waste by directly transferring fresh,
                usable food that would have otherwise been thrown away from grocers, restaurants, and other food
                industry sources to food insecure families throughout the U.S.{' '}
                <Link href="https://foodrescue.us/history/" className={classes.textLink} target="_blank">
                  Learn how we got started.
                </Link>
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">{currentYear} by the numbers</Typography>
            <Box py={2}>
              <MyEnviroImpact
                isLoading={environmentImpact.inflight} // To do: fetch yearly data
                environmentImpact={environmentImpact.all}
                iconSize={32}
                valueFontVariant="subtitle2"
                textStyle={classes.text}
                textVariant="caption"
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper style={{ height: '100%' }}>
            <Grid container>
              <Grid item xs={12} md={7} lg={8}>
                <Box className={`${classes.startSiteContainer} ${classes.container}`}>
                  <Typography variant="h5" className={classes.startSiteText}>
                    Start a site in your community
                  </Typography>
                  <Box py={2} maxWidth="85%">
                    <Typography variant="body1" className={classes.startSiteText}>
                      Food Rescue US Site Directors bring different skill sets and experiences to our work, but they all
                      share a passion for their community and our mission!
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" marginRight={isMobileView ? 6 : 10}>
                    <Link href="https://foodrescue.us/rescue-food/become-a-site-director/" target="_blank">
                      <StartSiteButton variant="contained">Learn more</StartSiteButton>
                    </Link>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={5} lg={4}>
                <Box className={classes.container}>
                  <Box display="flex" justifyContent="center">
                    <Typography variant="h5">Be the rescue!</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection='column'
                    justifyContent="space-around"
                    alignItems="center"
                    py={1}
                    flexWrap="wrap"
                  >
                    <Link href="https://secure.givelively.org/donate/food-rescue-us" target="_blank">
                      <RedButton style={{marginRight: 5, marginTop: 10}}>Donate</RedButton>
                    </Link>

                    <Link href="https://secure.givelively.org/donate/food-rescue-us/fundraisers/new" target="_blank">
                      <RedButton style={{marginRight: 5, marginTop: 10}}>Host a Virtual Fundraiser</RedButton>
                    </Link>

                    <Link href="https://foodrescue.us/about/the-hunger-food-waste-crisis/" target="_blank">
                      <RedButton style={{marginRight: 5, marginTop: 10}}>Learn About Hunger</RedButton>
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.paper}>
                  <Typography variant="h5">Nearest sites</Typography>
                  <UnassignedUserMap />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Box display="flex" justifyContent="center" pb={2}>
              <Typography variant="h6">Keep up with Food Rescue US!</Typography>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={3} style={{display: 'flex', justifyContent: 'center'}}>
                  <HtmlTooltip title="Facebook" withBorder>
                    <Link href="https://www.facebook.com/FoodRescueUS" target="_blank">
                      <FacebookIcon fontSize="large" className={classes.icon} />
                    </Link>
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={3} style={{display: 'flex', justifyContent: 'center'}}>
                  <HtmlTooltip title="Instagram" withBorder>
                    <Link href="https://www.instagram.com/foodrescueus/" target="_blank">
                      <InstagramIcon fontSize="large" className={classes.icon} />
                    </Link>
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={3} style={{display: 'flex', justifyContent: 'center'}}>
                  <HtmlTooltip title="Twitter" withBorder>
                    <Link href="https://twitter.com/foodrescueUS" target="_blank">
                      <TwitterIcon fontSize="large" className={classes.icon} />
                    </Link>
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={3} style={{display: 'flex', justifyContent: 'center'}}>
                  <HtmlTooltip title="LinkedIn" withBorder>
                    <Link href="https://www.linkedin.com/company/food-rescue-us/" target="_blank">
                      <LinkedInIcon fontSize="large" className={classes.icon} />
                    </Link>
                  </HtmlTooltip>
                </Grid>

                {insagramItems.map((item) => (
                  <Grid item xs={4} key={item.id}>
                    <Link href={item.permalink} target="_blank">
                      <img src={item.media_url} className={classes.instagramImage} alt="instagram" />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnassignedUserDashboard;
