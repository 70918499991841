import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const UploadButton = ({ disabled, onChange }) => {
  const classes = useStyles();

  return (
    <>
      <input
        accept="image/*"
        name="photo"
        onClick={event => (event.target.value = '')}
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={onChange}
      />
      <label htmlFor="contained-button-file">
        <Button disabled={disabled} fullWidth variant="contained" color="secondary" component="span">
          Add rescue photo
        </Button>
      </label>
    </>
  );
};

export default UploadButton;
