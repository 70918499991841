import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import Bluebird from 'bluebird';
import moment from 'moment';
import { get } from 'lodash';
import { debounceSearchRender } from 'mui-datatables';
import { TableToolbarSelect } from 'mui-datatables';
import { Button, Grid, Box } from '@material-ui/core';
import { Add, Delete as DeleteIcon, SyncAlt, Note as NoteIcon } from '@material-ui/icons';
import { isUndefinedOrNull } from '../helpers';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { ActivityFrequencyFilterTypes, RescuersFilterTypes, RescuerStatusFilterTypes } from '../containers/RescuersListView';
import { getMuiTableDataIndex } from '../helpers/getters';
import { getUserFullName } from '../helpers/user';
import ConfirmationDialog from './ConfirmationDialog';
import routes from '../routes';
import { noneAnyFilter, userRegistrationFilterOptions } from '../helpers/MuiTableCustomFilters.jsx';
import { formatCsvFileName } from '../helpers/formatters';
import HtmlTooltip from './Common/HtmlTooltip';
import { useSelector } from 'react-redux';
import NotesActionButton from './NotesActionButton';

const RescuersTable = ({
  tableId,
  rescuers,
  showSendEmailButton = true,
  showSendSmsButton = true,
  showDeleteRescuerButton,
  showImpersonateUserButton = false,
  onSendEmailButtonClick,
  onSendSMSButtonClick,
  onEditRescuerButtonClick,
  onDeleteRescuerButtonClick,
  onImpersonateUserButtonClick,
  onNoteButtonClick,
  badges = [],
  showBadgesColumns = false,
}) => {
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const zipCodesEntities = useSelector((state) => state.entities.zipCodes);
  const zipCodesArray = Object.values(zipCodesEntities.all);

  const renderCustomToolbarSelector = selectedRows => {
    const selectedRescuers = [];

    selectedRows.data.map(selectedRow => {
      const foundRescuer = rescuers.find(r => r.id === selectedRow);

      if (!foundRescuer) {
        return false;
      }

      selectedRescuers.push({
        id: foundRescuer.id,
        email_verified: foundRescuer.email_verified !== null,
        phone_verified: foundRescuer.phone_verified !== null,
      });
    });

    return (
      <Box pr={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
          {showSendEmailButton && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => onSendEmailButtonClick(selectedRescuers)}>
                Send Email
              </Button>
            </Grid>
          )}

          {showSendSmsButton && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => onSendSMSButtonClick(selectedRescuers)}>
                Send SMS
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  const fullNameCustomBodyRender = (value, tableMeta) => {
    const foundRescuer = rescuers[getMuiTableDataIndex(tableMeta)];

    if (!foundRescuer) {
      return '';
    }

    return getUserFullName(foundRescuer);
  };

  const emailVerifiedCustomBodyRender = value => !isUndefinedOrNull(value)
      ? moment(value, moment.ISO_8601).format('MM/DD/YYYY')
      : 'Not Verified';

  const phoneVerifiedCustomBodyRender = value => !isUndefinedOrNull(value)
      ? moment(value, moment.ISO_8601).format('MM/DD/YYYY')
      : 'Not Verified';

  const lastRescueClaimedCustomBodyRender = value => !isUndefinedOrNull(value)
    ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : '-';
  
  const rescuerPausedCustomBodyRender = value => !isUndefinedOrNull(value)
  ? 'Paused'
  : 'Active';

  const activityFrequencyCustomBodyRender = (value) => {
    if (isUndefinedOrNull(value)) return '-';

    const body = [];
    if (value.active30DayAdopter) body.push(ActivityFrequencyFilterTypes.adopters30Day);
    if (value.active30DayCompleter) body.push(ActivityFrequencyFilterTypes.rescuers30Day);
    if (value.active60DayAdopter) body.push(ActivityFrequencyFilterTypes.adopters60Day);
    if (value.active60DayCompleter) body.push(ActivityFrequencyFilterTypes.rescuers60Day);
    if (value.active90DayAdopter) body.push(ActivityFrequencyFilterTypes.adopters90Day);
    if (value.active90DayCompleter) body.push(ActivityFrequencyFilterTypes.rescuers90Day);
    if (value.topQuartile) body.push(ActivityFrequencyFilterTypes.quartile);
    return body.join(', ');
  };
  const activeCustomBodyRender = value => value === 1 ? 'yes' : 'no';

  const completedRegistrationCustomBodyRender = value => !isUndefinedOrNull(value)
      ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
      : null;

  const emailCustomBodyRender = (value, tableMeta) => {
    const rescuer = rescuers[getMuiTableDataIndex(tableMeta)];
    if (!rescuer?.active) {
      return 'Deactivated';
    }

    return value || '-';
  };

  const phoneCustomBodyRender = (value, tableMeta) => {
    const rescuer = rescuers[getMuiTableDataIndex(tableMeta)];
    if (!rescuer?.active) {
      return 'Deactivated';
    }
    return !isUndefinedOrNull(value)
      ? value.replace(/^([0-9]{3})([0-9]{3})([0-9]{4})$/, '$1-$2-$3')
      : null;
}
  const cityCustomBodyRender = (value, tableMeta, csv = false) => {
    if (!value) {
      let zipCodeRowData = null;
      if (csv) {
        zipCodeRowData = rescuers[tableMeta.dataIndex].zip_code;
      } else {
        zipCodeRowData = tableMeta.rowData[11];
      }
      const zipCode = zipCodesArray.find((data) => data.zip_code === zipCodeRowData);
      if (zipCode && zipCode.city) {
        return zipCode.city;
      }
    }

    return value || '-';
  };

  const stateCustomBodyRender = (value, tableMeta, csv = false) => {
    if (!value) {
      let zipCodeRowData = null;
      if (csv) {
        zipCodeRowData = rescuers[tableMeta.dataIndex].zip_code;
      } else {
        zipCodeRowData = tableMeta.rowData[11];
      }
      const zipCode = zipCodesArray.find((data) => data.zip_code === zipCodeRowData);
      if (zipCode && zipCode.state) {
        return zipCode.state;
      }
    }

    return value || '-';
  };

  const addressCustomBodyRender = (value, tableMeta, csv = false) => {
    let id = null;
    if (!csv) {
      id = tableMeta.rowData[0];
    } else {
      id = rescuers[tableMeta.dataIndex].id;
    }

    const rescuerData = rescuers.find((rescuer) => rescuer.id === id);
    if (rescuerData?.address2) {
      return `${value || ''} ${rescuerData.address2}`;
    }
    return value || '-';
  };

  return (
    <>
      {selectedRows.length > 0 && (
        <TableToolbarSelect
          selectedRows={{
            data: selectedRows,
          }}
          options={{
            customToolbarSelect: renderCustomToolbarSelector,
            textLabels: {
              selectedRows: {
                text: 'rescuer(s) selected',
              },
            },
          }}
        />
      )}

      <BaseMUIDataTable
        tableId={tableId}
        isLoading={isDeleting || zipCodesEntities.inflight}
        data={rescuers}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              display: false,
            },
          },
          {
            name: 'firstname',
            label: 'First Name',
            options: {
              filter: false,
              display: false,
            },
          },
          {
            name: 'lastname',
            label: 'Last Name',
            options: {
              filter: false,
              display: false,
            },
          },
          {
            name: 'fullname',
            label: 'Full Name',
            options: {
              filter: false,
              customBodyRender: (value, tableMeta) => fullNameCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => fullNameCustomBodyRender(value, tableMeta),
            },
          },
          {
            name: 'email',
            label: 'Email',
            options: {
              filter: false,
              customBodyRender: (value, tableMeta) => emailCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => emailCustomBodyRender(value, tableMeta),
            },
          },
          {
            name: 'email_verified',
            label: 'Email Verified',
            options: {
              filter: true,
              display: false,
              customBodyRender: value => emailVerifiedCustomBodyRender(value),
              customBodyRenderCSV: value => emailVerifiedCustomBodyRender(value),
              customFilterListOptions: {
                render: value => `Email Verified: ${value}`,
              },
            },
          },
          {
            name: 'phone',
            label: 'Phone',
            options: {
              filter: false,
              customBodyRender: (value, tableMeta) => phoneCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => phoneCustomBodyRender(value, tableMeta),
            },
          },
          {
            name: 'phone_verified',
            label: 'Phone Verified',
            options: {
              filter: true,
              display: false,
              customBodyRender: (value) => phoneVerifiedCustomBodyRender(value),
              customBodyRenderCSV: (value) => phoneVerifiedCustomBodyRender(value),
              customFilterListOptions: {
                render: value => `Phone Verified: ${value}`,
              },
            },
          },
          {
            name: 'city',
            label: 'City',
            options: {
              customBodyRender: (value, tableMeta) => cityCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => cityCustomBodyRender(value, tableMeta, true),
              customFilterListOptions: {
                render: (value) => `City: ${value}`,
              },
              filter: true,
              filterType: 'multiselect',
              display: true,
            },
          },
          {
            name: 'st',
            label: 'State',
            options: {
              customBodyRender: (value, tableMeta) => stateCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => stateCustomBodyRender(value, tableMeta, true),
              filter: true,
              display: true,
            },
          },
          {
            name: 'address',
            label: 'Address',
            options: {
              customBodyRender: (value, tableMeta) => addressCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => addressCustomBodyRender(value, tableMeta, true),
              filter: true,
              display: false,
            },
          },
          {
            name: 'zip_code',
            label: 'Zip Code',
            options: {
              filter: true,
              filterType: 'multiselect',
              display: false,
              customBodyRender: value => value || '-',
            },
          },
          {
            name: 'claims',
            label: '# Claims',
            options: {
              filter: true,
              customFilterListOptions: {
                render: value => `# Claims: ${value}`,
              },
              filterOptions: {
                names: noneAnyFilter.names,
                logic: (claimsNo, filters) => noneAnyFilter.logic(claimsNo, get(filters, [0], '')),
              },
            },
          },
          {
            name: 'closed',
            label: '# Closed',
            options: {
              filter: true,
              customFilterListOptions: {
                render: value => `# Closed: ${value}`,
              },
              filterOptions: {
                names: noneAnyFilter.names,
                logic: (closedNo, filters) => noneAnyFilter.logic(closedNo, get(filters, [0], '')),
              },
            },
          },
          {
            name: 'completions',
            label: '# Completed',
            options: {
              filter: true,
              customFilterListOptions: {
                render: value => `# Completed: ${value}`,
              },
              filterOptions: {
                names: noneAnyFilter.names,
                logic: (completedNo, filters) => noneAnyFilter.logic(completedNo, get(filters, [0], '')),
              },
            },
          },
          {
            name: 'noshows',
            label: '# No Shows',
            options: {
              filter: true,
              customFilterListOptions: {
                render: value => `# No Shows: ${value}`,
              },
              filterOptions: {
                names: noneAnyFilter.names,
                logic: (noShowsNo, filters) => noneAnyFilter.logic(noShowsNo, get(filters, [0], '')),
              },
            },
          },
          {
            name: 'badges',
            label: 'Badges',
            options: {
              display: showBadgesColumns,
              forceDisplay: true,
              customBodyRender: (value) =>
                value &&
                value.map((badge) => (
                  <HtmlTooltip title={badge.name} key={badge.id}>
                    <img alt={badge.name} src={badge.image_uri} width="25" style={{ marginRight: 5 }} />
                  </HtmlTooltip>
                )),
              customBodyRenderCSV: (value) => value && value.map((badge) => badge.name),
              customFilterListOptions: {
                render: (value) => `Badges: ${value}`,
              },
              filterOptions: {
                names: badges.map((row) => row.name),
                logic(value, filterVal) {
                  return !value.some((v) => filterVal.includes(v.name));
                },
              },
            },
          },
          {
            name: 'last_rescue_claimed',
            label: 'Last Rescue',
            options: {
              filter: true,
              display: false,
              customBodyRender: value => lastRescueClaimedCustomBodyRender(value),
              customBodyRenderCSV: value => lastRescueClaimedCustomBodyRender(value),
              customFilterListOptions: {
                render: value => `Last Rescue: ${value}`,
              },
              filterOptions: {
                names: [RescuersFilterTypes.active, RescuersFilterTypes.inactive, RescuersFilterTypes.quarter],
                logic(value, filterVal) {
                  // WARNING: datatables logic requires reverted state ¯\_(ツ)_/¯

                  // get rescuers with rescue claimed in last 90 days
                  if (filterVal.includes(RescuersFilterTypes.quarter)) {
                    return !moment(value, 'MM/DD/YYYY').isSameOrAfter(moment().subtract(90, 'd'));
                  }

                  if (filterVal.includes(RescuersFilterTypes.active)) {
                    return value === '-';
                  }

                  if (filterVal.includes(RescuersFilterTypes.inactive)) {
                    return value !== '-';
                  }

                  return !filterVal.includes(value);
                },
              },
            },
          },
          {
            name: 'paused_at',
            label: 'Rescuer Status',
            options: {
              filter: true,
              display: false,
              customBodyRender: value => rescuerPausedCustomBodyRender(value),
              customBodyRenderCSV: value => rescuerPausedCustomBodyRender(value),
              customFilterListOptions: {
                render: value => `Rescuer Status: ${value}`,
              },
              filterOptions: {
                names: [RescuerStatusFilterTypes.paused, RescuerStatusFilterTypes.active],
                logic(value, filterVal) {
                  // WARNING: datatables logic requires reverted state ¯\_(ツ)_/¯

                  if (filterVal.includes(RescuerStatusFilterTypes.paused)) {
                    return value === 'Active';
                  }
                  if (filterVal.includes(RescuerStatusFilterTypes.active)) {
                    return value !== 'Active';
                  }

                  return !filterVal.includes(value);
                },
              },
            },
          },
          {
            name: 'activity_frequency',
            label: 'Activity Frequency',
            options: {
              filter: true,
              display: false,
              customBodyRender: value => activityFrequencyCustomBodyRender(value),
              customBodyRenderCSV: value => activityFrequencyCustomBodyRender(value),
              customFilterListOptions: {
                render: value => `Activity Frequency: ${value}`,
              },
              filterOptions: {
                names: [ActivityFrequencyFilterTypes.monthRescuers, ActivityFrequencyFilterTypes.monthAdopters, ActivityFrequencyFilterTypes.quartile],
                logic(value, filterVal) {
                  // WARNING: datatables logic requires reverted state ¯\_(ツ)_/¯

                  if (filterVal.includes(ActivityFrequencyFilterTypes.rescuers30Day)) {
                    return !value.includes(ActivityFrequencyFilterTypes.rescuers30Day);
                  }
                  if (filterVal.includes(ActivityFrequencyFilterTypes.adopters30Day)) {
                    return !value.includes(ActivityFrequencyFilterTypes.adopters30Day);
                  }
                  if (filterVal.includes(ActivityFrequencyFilterTypes.rescuers60Day)) {
                    return !value.includes(ActivityFrequencyFilterTypes.rescuers60Day);
                  }
                  if (filterVal.includes(ActivityFrequencyFilterTypes.adopters60Day)) {
                    return !value.includes(ActivityFrequencyFilterTypes.adopters60Day);
                  }
                  if (filterVal.includes(ActivityFrequencyFilterTypes.rescuers90Day)) {
                    return !value.includes(ActivityFrequencyFilterTypes.rescuers90Day);
                  }
                  if (filterVal.includes(ActivityFrequencyFilterTypes.adopters90Day)) {
                    return !value.includes(ActivityFrequencyFilterTypes.adopters90Day);
                  }
                  if (filterVal.includes(ActivityFrequencyFilterTypes.quartile)) {
                    return !value.includes(ActivityFrequencyFilterTypes.quartile);
                  }

                  return !filterVal.includes(value);
                },
              },
            },
          },
          {
            name: 'active',
            label: 'Active',
            options: {
              filter: true,
              customBodyRender: value => activeCustomBodyRender(value),
              customBodyRenderCSV: value => activeCustomBodyRender(value),
            },
          },
          {
            name: 'completed_registration',
            label: 'Completed Registration',
            options: {
              filter: true,
              display: false,
              customBodyRender: value => completedRegistrationCustomBodyRender(value),
              customBodyRenderCSV: value => completedRegistrationCustomBodyRender(value),
              ...userRegistrationFilterOptions,
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              filter: false,
              sort: false,
              display: true,
              customBodyRender: (value, tableMeta) => {
                const rescuer = rescuers[getMuiTableDataIndex(tableMeta)];

                return (
                  <TableActionsButtonsWrapper>
                    <TableActionButton
                      title="Edit Rescuer"
                      onClick={() => onEditRescuerButtonClick(tableMeta.rowData[0])}
                    />

                    {showDeleteRescuerButton && (
                      <TableActionButton
                        title="Delete Rescuer"
                        icon={DeleteIcon}
                        onClick={() => {
                          confirmAlert({
                            title: 'Are you sure you want to do this?',
                            message: `Deleting this rescuer will remove them from any upcoming rescues. If you're sure, please type "DELETE" into the field below and hit "Confirm".`,
                            buttons: [
                              {
                                label: 'Cancel',
                                color: 'primary',
                              },
                              {
                                label: 'Confirm',
                                color: 'primary',
                                requireDeleteInput: true,
                                onClick: (deleteReason = null, additionNotes = null) => {
                                  setIsDeleting(true);

                                    return Bluebird
                                      .try(() => onDeleteRescuerButtonClick(rescuer, {role_delete_main_reason: deleteReason, role_delete_reason: additionNotes}))
                                      .then(() => setIsDeleting(false)
                                    );
                                },
                              },
                            ],
                            customUI: ({ title, message, onClose, buttons }) => (
                              <ConfirmationDialog
                                buttons={buttons}
                                closeDialog={onClose}
                                title={title}
                                message={message}
                                secondStep={{
                                  display: true,
                                  title: 'Please select reason to continue',
                                  selectOptions: [
                                    {
                                      label: 'Location',
                                      value: 'LOCATION',
                                    },
                                    {
                                      label: 'Time',
                                      value: 'TIME',
                                    },
                                    {
                                      label: 'Transportation',
                                      value: 'TRANSPORTATION',
                                    },
                                    {
                                      label: 'Physically Unable',
                                      value: 'PHYSICALLY_UNABLE',
                                    },
                                    {
                                      label: 'Behavioral concerns',
                                      value: 'BEHAVIORAL_CONCERNS',
                                    },
                                    {
                                      label: 'Inactive/Unknown',
                                      value: 'INACTIVE_UNKNOWN',
                                    },
                                  ],
                                }}
                              />
                            ),
                          });
                        }}
                      />
                    )}

                    {showImpersonateUserButton && (
                      <TableActionButton
                        title="Impersonate User"
                        icon={SyncAlt}
                        onClick={() => onImpersonateUserButtonClick(tableMeta.rowData[0])}
                      />
                    )}

                    <NotesActionButton
                      title={rescuers.find((r) => r.id === tableMeta.rowData[0]).rescuer_notes}
                      icon={NoteIcon}
                      onClick={(newNote) => onNoteButtonClick(tableMeta.rowData[0], newNote)}
                    />
                  </TableActionsButtonsWrapper>
                );
              },
            },
          },
        ]}
        options={{
          customSearchRender: debounceSearchRender(500),
          customToolbar: () => (
            <Button
              startIcon={<Add />}
              type="button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push(routes.rescuersAdd)}
              data-testid="add-new-rescuer-button"
            >
              Add New Rescuer
            </Button>
          ),
          responsive: 'simple',
          selectableRows: 'multiple',
          selectToolbarPlacement: 'none',
          onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
            const selectedRescuers = allRowsSelected.map(row => rescuers[row.dataIndex].id);

            setSelectedRows(selectedRescuers);
          },
          downloadOptions: {
            filename: formatCsvFileName('Rescuers'),
          },
        }}
      />
    </>
  );
};

export default RescuersTable;
