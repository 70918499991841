import { Roles } from '../models/roles';

const resources = {
  usersList: 'users:list',
  foodDonorsList: 'foodDonors:list',
  receiversList: 'receivers:list',
  reportReceivingAgencyList: 'receivingAgency:list',
};

/**
 * Access Control List Resources
 * @type {Object.<string, Array<string>>}
 */
const ACL = {
  [resources.usersList]: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
  [resources.foodDonorsList]: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
  [resources.receiversList]: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
  [resources.reportReceivingAgencyList]: [Roles.SiteDirector, Roles.DonorAdmin, Roles.DonorStaff],
};

/**
 * ACLService for checking user permissions in given resource
 */
const ACLService = {
  /**
   * @type {Object.<string, string>}
   */
  resources: resources,

  /**
   *
   * @param {!Object} [activeUser]
   * @param {!string} [resource]
   * @param {?number} [inSite]
   * @param {?boolean} [singleRoleCheck=true]
   * @returns {boolean}
   */
  hasAccess: (activeUser, resource, inSite, singleRoleCheck = true) => {
    if (!activeUser || !activeUser.hasOwnProperty('role_assignments') || !resource) {
      return false;
    }

    // as Admin or NSD I have access to all resources
    if (activeUser.role_assignments.some(r => [Roles.Admin, Roles.NationalSiteDirector].indexOf(r.role_name) !== -1)) {
      return true;
    }

    // get roles in providedSite or in all sites
    const rolesToCheck = activeUser.role_assignments.reduce((acc, role) => {
      if (inSite && role.site_id === inSite) {
        acc.push(role.role_name);
      }

      if (!inSite) {
        acc.push(role.role_name);
      }
      return acc;
    }, []);

    if (!ACL.hasOwnProperty(resource)) {
      return false;
    }

    if (singleRoleCheck) {
      return rolesToCheck.some(role => ACL[resource].indexOf(role) !== -1);
    }

    // check for every role
    return rolesToCheck.every(role => ACL[resource].indexOf(role) !== -1);
  },
};

export default ACLService;
