import React from 'react';
import { Badge, colors, withStyles } from '@material-ui/core';

const DashboardBadge = withStyles({
  badge: {
    background: colors.amber[600],
    color: colors.grey[900],
    right: -10,
  },
})(Badge);

export default DashboardBadge;
