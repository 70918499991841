import React, { useEffect } from 'react';
import { generatePath, useHistory, Link as DOMLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FileCopy as FileCopyIcon, BorderColor as BorderColorIcon } from '@material-ui/icons';
import moment from 'moment';
import {
  FormControl,
  Link,
  makeStyles,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  Box,
  Typography,
  TextField,
  TableCell,
  TableRow,
  TableFooter,
  IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { confirmAlert } from 'react-confirm-alert';
import { omit } from 'lodash';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import RescuesTableStatusBox, { useRescuesTableRowColorStyles } from './Common/RescuesTableStatusBox';
import { formatCsvFileName, formatDate } from '../helpers/formatters';
import {
  calculatedLbsSource,
  getRescueAdopter,
  getRescueClaimer,
  getRescuePickupLocationFullName,
  getRescueRescuer,
  isRescueClaimed,
  renderPickupLocationNameOrAdress,
} from '../helpers/RescuesHelper';
import { getMuiTableDataIndex, getReceiversWithSiteOptions } from '../helpers/getters';
import routes from '../routes';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import TableActionButton from './TableActionButton';
import { sortAlphabetically } from '../helpers/sorters';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_BULK_EDIT } from './FeatureFlagWrapper';
import { fetchReceiversIfNeeded } from '../actions/receivers';
import ConfirmationDialog from './ConfirmationDialog';
import { Colors } from '../assets/theme/Colors';
import UndoButton from './UndoButton';

const useStyles = makeStyles(() => ({
  toolPalette: {
    height: 'auto',
    padding: '2px 2px 2px 2px',
  },
  toolPaletteCell: {
    padding: '2px 2px 2px 2px',
    border: 'none',
    display: 'flex',
  },
  editableField: {
    backgroundColor: 'white',
  },
  editedField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `3px solid ${Colors.bulkEdit.main}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${Colors.bulkEdit.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `3px solid ${Colors.bulkEdit.main}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.bulkEdit.main,
    },
    marginRight: '1rem',
    backgroundColor: 'white',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
  },
  editedRow: {
    backgroundColor: Colors.bulkEdit.light,
    '&.MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: Colors.bulkEdit.highlight,
    },
  },
}));

const customEditToolbar = ({
  handleEditClick,
  editMode,
  showEditRescueButton,
  hasActiveBulkEditFeatureFlag,
  handleReviewClick,
  reviewButtonEnabled,
}) => (
  <>
    {!editMode && showEditRescueButton && hasActiveBulkEditFeatureFlag && (
      <Tooltip title="Edit Mode">
        <IconButton onClick={handleEditClick}>
          <BorderColorIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    )}
    {editMode && (
      <Box display="flex" justifyContent="flex-end" flexWrap="no-wrap">
        <Button onClick={handleEditClick}>Exit Edit Mode</Button>
        <Button
          disabled={!reviewButtonEnabled}
          onClick={handleReviewClick}
          style={{ marginLeft: '1rem' }}
          variant="contained"
          color="primary"
        >
          Review and Save
        </Button>
      </Box>
    )}
  </>
);

const customEditFooter = ({ handleEditClick, handleReviewClick, reviewButtonEnabled }) => (
  <TableFooter>
    <TableRow>
      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'nowrap',
          marginRight: '24px',
          minHeight: '64px',
        }}
      >
        <Typography variant="body2" style={{ marginRight: '1rem' }}>
          Exit Edit Mode to select a different page
        </Typography>
        <Button onClick={handleEditClick}>Exit Edit Mode</Button>
        <Button
          disabled={!reviewButtonEnabled}
          onClick={handleReviewClick}
          style={{ marginLeft: '1rem' }}
          variant="contained"
          color="primary"
        >
          Review and Save
        </Button>
      </TableCell>
    </TableRow>
  </TableFooter>
);

const CLAIMER_NOT_CLAIMED = 'Unclaimed';
export const RESCUE_CLOSED = 'Closed';
export const RESCUE_CLOSED_NO_SHOW = 'Closed (No Show)';
export const RESCUE_NOT_CLOSED = 'Not Closed';

const PastRescuesTable = ({
  showSiteColumn = false,
  filterColumns = [],
  rescues,
  tableId,
  isLoading,
  showEditRescueButton,
  renderRescuerAsProfileLink = false,
  showCopyRescueButton = true,
  renderNamesAsLinks = true,
  handleReviewClick,
  editedRescues,
  setEditedRescues,
  editMode,
  setEditMode,
  removedFilters = [],
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableStyles = useRescuesTableRowColorStyles();
  const hasActiveBulkEditFeatureFlag = useHasActiveFeatureFlag(FF_BULK_EDIT);

  const receiversEntities = useSelector(state => state.entities.receivers);
  const receiversList = getReceiversWithSiteOptions(Object.values(receiversEntities.byId).sort((a, b) =>
  sortAlphabetically('asc', b.name, a.name)
));

  let rescuesClaimersList = [];

  useEffect(() => {
    rescuesClaimersList = Object.values(
      rescues.reduce((acc, rescue) => {
        if (rescue.claimer_id) {
          acc[rescue.claimer_id] = getRescueClaimer(rescue);
        }
        return acc;
      }, {})
    ).sort((a, b) => sortAlphabetically('asc', b.name, a.name));
  }, [rescues]);

  useEffect(() => {
    dispatch(fetchReceiversIfNeeded());
  }, []);

  const onEditableFieldChange = (rescueEdited, rescue, data) => {
    if (!rescueEdited) {
      return setEditedRescues([...editedRescues, { rescue_id: rescue.id, ...data }]);
    }
    // Check if new status is the same as old and if so remove it from edited rescues
    if (data.status) {
      const prevStatus = rescue.completed !== null ? (rescue.completed ? RESCUE_CLOSED : RESCUE_CLOSED_NO_SHOW) : RESCUE_NOT_CLOSED;
      if (data.status === prevStatus) {
        return handleUndoClick(rescueEdited, 'status');
      }
    }
    return setEditedRescues(editedRescues.map((r) => (r.rescue_id !== rescue.id ? r : { ...rescueEdited, ...data })));
  };

  const handleUndoClick = (rescue, field, previousData = 0) => {
    const editedRescuesReduced = editedRescues.reduce((acc, curr) => {
      if (curr.rescue_id === rescue.rescue_id) {
        let reducedRescue = omit(curr, [field]);

        if (field === 'receiving_agency_id') {
          reducedRescue = omit(reducedRescue, ['receiver']);
        }

        if (Object.keys(reducedRescue).length === 1) {
          return acc;
        }

        acc.push(reducedRescue);

        return acc;
      };
      acc.push(curr);
      return acc;
    }, []);

    // Reset value for uncontrolled lbs input
    if (field === 'lbs') {
      const currentLbsInput = document.getElementById(rescue.rescue_id);
      currentLbsInput.value = Math.round(previousData.weight_lbs);
    }

    setEditedRescues(editedRescuesReduced);
  };

  const renderUserProfileLink = user => {
    if (!user) {
      return '-';
    }

    return (
      <Link
        onClick={() => history.push(generatePath(routes.userEditNew, { id: user.id }))}
        style={{ cursor: 'pointer' }}
      >
        {user.name}
      </Link>
    );
  };

  const renderClaimerList = (filterList, onChange, index, column) => (
    <FormControl>
      <InputLabel htmlFor="rescuer-selector">{column.label}</InputLabel>
      <Select
        id="rescuer-selector"
        multiple
        value={filterList[index]}
        renderValue={selected => selected.join(', ')}
        onChange={event => {
          filterList[index] = event.target.value;
          onChange(filterList[index], index, column);
        }}
      >
        <MenuItem value={CLAIMER_NOT_CLAIMED}>
          <Checkbox color="primary" checked={filterList[index].indexOf(CLAIMER_NOT_CLAIMED) > -1} />
          <ListItemText primary={CLAIMER_NOT_CLAIMED} />
        </MenuItem>

        {rescuesClaimersList.map(rescuer => (
          <MenuItem key={rescuer.id} value={rescuer.name}>
            <Checkbox color="primary" checked={filterList[index].indexOf(rescuer.name) > -1} />
            <ListItemText primary={rescuer.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const dateCustomBodyRender = (value, tableMeta, csv = false) => {
    const formattedDate = moment(value, 'YYYYMMDD').format('MM/DD/YYYY');

    if (csv) {
      return formattedDate;
    }

    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    return (
      <>
        <span>{formattedDate}</span>

        <RescuesTableStatusBox rescue={rescue} />
      </>
    );
  };

  const locationCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescuePickupLocationFullName = getRescuePickupLocationFullName(rescue) || '';

    if (csv || !renderNamesAsLinks || !!rescue.donor_deleted_at) {
      return rescuePickupLocationFullName;
    }

    return (
      <Link
        onClick={() =>
          history.push(
            generatePath(routes.foodDonor, {
              foodDonorId: rescue.location_id,
            })
          )
        }
        style={{ cursor: 'pointer' }}
      >
        {rescuePickupLocationFullName}
      </Link>
    );
  };

  const pickupLocationCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescuePickupLocationFullName = renderPickupLocationNameOrAdress(rescue) || '';

    return rescuePickupLocationFullName;
  };

  const adopterCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescueAdopter = getRescueAdopter(rescue);

    if (csv || !renderRescuerAsProfileLink) {
      return rescueAdopter ? rescueAdopter.name : null;
    }

    return renderUserProfileLink(rescueAdopter);
  };

  const rescuerCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescueRescuer = getRescueRescuer(rescue);

    if (csv || !renderRescuerAsProfileLink) {
      return rescueRescuer ? rescueRescuer.name : null;
    }

    return renderUserProfileLink(rescueRescuer);
  };

  const claimerCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescueClaimer = getRescueClaimer(rescues[getMuiTableDataIndex(tableMeta)]);
    if (csv || !renderRescuerAsProfileLink) {
      return rescueClaimer ? rescueClaimer.name : null;
    }

    return renderUserProfileLink(rescueClaimer);
  };

  const claimerEmailCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];
    if (value && !rescue?.claimer_active) {
      return 'Deactivated';
    }

    return value || '-';
  };

  const cancellerCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue || !rescue.canceller) {
      return !csv ? '-' : null;
    }

    if (csv || !renderRescuerAsProfileLink) {
      return rescue.canceller;
    }

    return renderUserProfileLink({
      id: rescue.cancelled_by_id,
      name: rescue.canceller,
    });
  };

  const releaserCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue || !rescue.releaser) {
      return !csv ? '-' : null;
    }

    if (csv || !renderRescuerAsProfileLink) {
      return rescue.releaser;
    }

    return renderUserProfileLink({
      id: rescue.released_by_id,
      name: rescue.releaser,
    });
  };

  const completedCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (rescue && rescue.cancelled_by_id !== null) {
      return 'Cancelled';
    }

    if (editMode) {
      const rescueEdited = editedRescues.find((r) => r.rescue_id === rescue.id);

      const currentValue = () => {
        if (rescueEdited && rescueEdited.status) {
          return rescueEdited.status;
        }

        if (rescue.completed !== null) {
          return rescue.completed ? RESCUE_CLOSED : RESCUE_CLOSED_NO_SHOW;
        }
        return RESCUE_NOT_CLOSED;
      };

      return (
        <FormControl variant="outlined" size="small" className={classes.formControl}>
          <Tooltip
            title={
              rescue.is_past
                ? ''
                : "Rescues that occur on today's date aren't able to be edited until midnight tomorrow."
            }
          >
            <Select
              className={rescueEdited && rescueEdited.status ? classes.editedField : classes.editableField}
              style={{ height: 40, overflow: 'hidden' }}
              value={currentValue()}
              disabled={!rescue.is_past}
              onChange={(event) => {
                const targetValue = event.target.value;
                const currentLbsInput = document.getElementById(rescue.id);

                // Set lbs value to 0 if the status is 'Closed (No Show)'
                if (targetValue === RESCUE_CLOSED_NO_SHOW && Number(currentLbsInput.value) !== 0) {
                  currentLbsInput.value = 0;
                  return onEditableFieldChange(rescueEdited, rescue, { lbs: 0, status: targetValue });
                }

                // Reset lbs values to original state so the user do not need to restore values
                // manually after selecting 'Closed (No Show)' first
                if (targetValue === RESCUE_CLOSED && rescueEdited) {
                  currentLbsInput.value = Math.round(rescue.weight_lbs);
                  if (rescueEdited) {
                    delete rescueEdited.lbs;
                  }
                  handleUndoClick(rescueEdited, 'lbs', rescue);
                }

                // Reset the lbs value if the status is 'Not Closed'
                if (targetValue === RESCUE_NOT_CLOSED && rescueEdited) {
                  delete rescueEdited.lbs;
                  handleUndoClick(rescueEdited, 'lbs', rescue);
                }

                return onEditableFieldChange(rescueEdited, rescue, {
                  status: targetValue,
                });
              }}
            >
              <MenuItem value={RESCUE_CLOSED}>
                <ListItemText primary={RESCUE_CLOSED} />
              </MenuItem>
              <MenuItem value={RESCUE_CLOSED_NO_SHOW}>
                <ListItemText primary={RESCUE_CLOSED_NO_SHOW} />
              </MenuItem>
              <MenuItem value={RESCUE_NOT_CLOSED}>
                <ListItemText primary={RESCUE_NOT_CLOSED} />
              </MenuItem>
            </Select>
          </Tooltip>
          {rescueEdited?.status && (
            <UndoButton
              handleClick={() => {
                // If lbs value was changed before status change remove it from edited rescues
                if ('lbs' in rescueEdited) {
                  delete rescueEdited.lbs;
                  handleUndoClick(rescueEdited, 'lbs', rescue);
                }
                handleUndoClick(rescueEdited, 'status');
              }}
            />
          )}
        </FormControl>
      );
    }

    return value !== null ? (value ? RESCUE_CLOSED : RESCUE_CLOSED_NO_SHOW) : RESCUE_NOT_CLOSED;
  };

  const noFoodCustomBodyRender = value => (value ? 'No Food Available' : '-');

  const renderWeightLbsTooltip = (rescue, rescueEdited) => {
    if (!rescue.is_past) {
      return "Rescues that occur on today's date aren't able to be edited until midnight tomorrow.";
    }

    if (
      (rescueEdited && 'status' in rescueEdited && rescueEdited.status !== RESCUE_CLOSED) ||
      (!rescueEdited && !rescue.completed)
    ) {
      return "Weight can only be modified for rescues with status 'Closed'.";
    }

    if (!rescue.claimer_id) {
      return 'A rescue must have a rescuer assigned before the weight can be edited.';
    }

    return '';
  };

  const weightLbsCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];
    if (editMode) {
      const rescueEdited = editedRescues.find((r) => r.rescue_id === rescue.id);

      return (
        <Box display="flex">
          <Tooltip title={renderWeightLbsTooltip(rescue, rescueEdited)}>
            <TextField
              id={rescue.id}
              className={rescueEdited && 'lbs' in rescueEdited ? classes.editedField : classes.editableField}
              style={{ width: 100 }}
              type="number"
              variant="outlined"
              size="small"
              disabled={
                (rescueEdited && 'status' in rescueEdited ? rescueEdited.status !== RESCUE_CLOSED : !rescue.completed) ||
                !rescue.is_past ||
                !rescue.claimer_id
              }
              defaultValue={rescueEdited && 'lbs' in rescueEdited ? rescueEdited.lbs : Math.round(rescue.weight_lbs)}
              InputProps={{
                inputProps: { min: 0 },
              }}
              onBlur={(event) => {
                onEditableFieldChange(rescueEdited, rescue, { lbs: event.target.value });
              }}
            />
          </Tooltip>
          {rescueEdited &&
            'lbs' in rescueEdited &&
            (rescueEdited.status ? rescueEdited.status === RESCUE_CLOSED : rescue.completed) && (
              <UndoButton handleClick={() => handleUndoClick(rescueEdited, 'lbs', rescue)} />
            )}
        </Box>
      );
    }
    return value !== null && !rescue.no_food && !rescue.cancelled_by_id && rescue.completed !== false ? Math.round(value) : '-';
  };

  const receiverCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];
    if (!rescue) {
      return false;
    }

    const siteReceivers = receiversList.filter((receiver) => receiver.site_id === rescue.site_id);

    if (editMode) {
      const rescueEdited = editedRescues.find((r) => r.rescue_id === rescue.id);
      return (
        <Box display="flex">
          <Tooltip
            title={
              rescue.is_past
                ? ''
                : "Rescues that occur on today's date aren't able to be edited until midnight tomorrow."
            }
          >
            <Autocomplete
              className={rescueEdited?.receiving_agency_id ? classes.editedField : classes.editableField}
              style={{ width: 250 }}
              value={{
                id: rescueEdited?.receiving_agency_id || rescue.receiver_id,
                name: rescueEdited?.receiving_agency_id ? rescueEdited?.receiver : rescue.receiver,
                site_id: rescue.site_id,
              }}
              fullWidth
              size="small"
              loading={receiversEntities.inflight}
              disabled={!rescue.is_past}
              name="receiver_id"
              placeholder="Select receiver"
              options={siteReceivers}
              disableClearable
              onChange={(event, value) => {
                onEditableFieldChange(rescueEdited, rescue, { receiving_agency_id: value.id, receiver: value.name });
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => !option.id}
              renderOption={(option) => (
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">{option.name}</Typography>
                </Box>
              )}
              renderInput={(params) => <TextField {...params} placeholder="Select receiver" variant="outlined" />}
            />
          </Tooltip>
          {rescueEdited?.receiving_agency_id && (
            <UndoButton handleClick={() => handleUndoClick(rescueEdited, 'receiving_agency_id')} />
          )}
        </Box>
      );
    }

    if (!renderNamesAsLinks) {
      return value || '';
    }

    return (
      <Link
        onClick={() => history.push(generatePath(routes.receiver, { id: rescue.receiver_id }))}
        style={{ cursor: 'pointer' }}
      >
        {value || ''}
      </Link>
    );

  }

  const cO2EquivalentOffsetRender = (value) => {
    const cO2EquivalentOffset = Math.abs(Math.round(value));
    return value !== null && cO2EquivalentOffset > 0 ? `${cO2EquivalentOffset} lbs of CO2eq avoided` : '-';
  };

  const waterGallonsSavedRender = (value) => {
    const waterGallonsSaved = Math.abs(Math.round(value));
    return value !== null && waterGallonsSaved > 0 ? `${waterGallonsSaved} gallons saved` : '-';
  };

  const commentsCustomBodyRender = (value, csv = false) => {
    if (!value || value.trim().length === 0) {
      return csv ? null : '-';
    }

    if (value.length > 30 && !csv) {
      return (
        <Tooltip arrow placement="top" title={value}>
          <div>{`${value.substring(0, 27).trimEnd()}...`}</div>
        </Tooltip>
      );
    }

    return value;
  };

  const dayCustomBodyRender = (value, tableMeta) =>
    moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd');

  const onCopy = async rescue => history.push(generatePath(routes.donation_add_new), { copy: true, rescue: rescue });

  const handleEditClick = () => {
    if (!showEditRescueButton || !hasActiveBulkEditFeatureFlag) {
      setEditMode(false);
      return setEditedRescues([]);
    }

    if (editedRescues.length > 0) {
      return confirmAlert({
        message: 'Are you sure you want to exit without saving?',
        buttons: [
          {
            label: 'No',
            color: 'primary',
          },
          {
            label: 'Yes',
            color: 'primary',
            onClick: () => {
              setEditMode(!editMode);
              setEditedRescues([]);
            },
          },
        ],
        customUI: ({ message, onClose, buttons }) => (
          <ConfirmationDialog buttons={buttons} closeDialog={onClose} message={message} />
        ),
      });
    }

    setEditMode(!editMode);
    return setEditedRescues([]);
  };

  const weightSourceBodyRender = (value) => calculatedLbsSource(value) || 'N/A';

  return (
    <BaseMUIDataTable
      isLoading={isLoading}
      tableId={tableId}
      data={rescues}
      columns={[
        {
          name: 'pickup_spec_id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'site',
          label: 'Site',
          options: {
            forceDisplay: true,
            viewColumns: false,
            filter: false,
            display: showSiteColumn ? true : 'excluded',
            customFilterListOptions: {
              render: value => `Site: ${value}`,
            },
          },
        },
        {
          name: 'date',
          label: 'Date',
          options: {
            customBodyRender: (value, tableMeta) => dateCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => dateCustomBodyRender(value, tableMeta, true),
            customFilterListOptions: {
              render: value => `Date: ${formatDate(value)}`,
            },
            filterOptions: {
              renderValue: formatDate,
            },
          },
        },
        {
          name: 'day',
          label: 'Day',
          options: {
            customBodyRender: (value, tableMeta) => dayCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => dayCustomBodyRender(value, tableMeta),
            customFilterListOptions: {
              render: value => `Day: ${value}`,
            },
            filterType: 'multiselect',
            sort: false,
          },
        },
        {
          name: 'location',
          label: 'Food Donor',
          options: {
            customBodyRender: (value, tableMeta) => locationCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => locationCustomBodyRender(value, tableMeta, true),
            customFilterListOptions: {
              render: value => `Food Donor: ${value}`,
            },
            filter: !removedFilters.includes('location'),
          },
        },
        {
          name: 'pickup_location_name',
          label: 'Pickup Location',
          options: {
            customBodyRender: (value, tableMeta) => pickupLocationCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => pickupLocationCustomBodyRender(value, tableMeta),
            customFilterListOptions: {
              render: value => `Pickup Location ${value}`,
            },
            filter: false,
            display: false,
          },
        },
        {
          name: 'receiver',
          label: 'Receiving Agency',
          options: {
            customBodyRender: (value, tableMeta) => receiverCustomBodyRender(value, tableMeta),
            customFilterListOptions: {
              render: value => `Receiver: ${value}`,
            },
            forceDisplay: !!editMode,
          },
        },
        {
          name: 'pickup_city',
          label: 'Pickup City',
          options: {
            forceDisplay: !!editMode,
            customBodyRender: (value) => value || '',
            customFilterListOptions: {
              render: value => `Pickup City: ${value}`,
            },
          },
        },
        {
          name: 'dropoff_city',
          label: 'Dropoff City',
          options: {
            forceDisplay: !!editMode,
            customBodyRender: (value) => value || '',
            customFilterListOptions: {
              render: value => `Dropoff City: ${value}`,
            },
          },
        },
        {
          name: 'slug',
          label: 'Description',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'adopter',
          label: 'Adopter',
          options: {
            customBodyRender: (value, tableMeta) => adopterCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => adopterCustomBodyRender(value, tableMeta, true),
            customFilterListOptions: {
              render: value => `Adopter: ${value}`,
            },
          },
        },
        {
          name: 'rescuer',
          label: 'Rescuer',
          options: {
            customBodyRender: (value, tableMeta) => rescuerCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => rescuerCustomBodyRender(value, tableMeta, true),
            customFilterListOptions: {
              render: value => `Rescuer: ${value}`,
            },
          },
        },
        {
          name: 'claimer',
          label: 'Claimer',
          options: {
            filterType: 'custom',
            customBodyRender: (value, tableMeta) => claimerCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => claimerCustomBodyRender(value, tableMeta, true),
            customFilterListOptions: {
              render: value => `Claimer: ${value.join(', ')}`,
              update: (filterList, filterPos, index) => {
                filterList[index] = [];
                return filterList;
              },
            },
            filterOptions: {
              logic: (claimer, filters) => {
                if (!filters || filters.length === 0) {
                  return false;
                }

                if (claimer !== '-') {
                  return !filters.includes(claimer);
                }

                if (claimer === '-') {
                  return !filters.includes(CLAIMER_NOT_CLAIMED);
                }

                return true;
              },
              display: renderClaimerList,
            },
          },
        },
        {
          name: 'claimer_email',
          label: 'Claimer Email',
          options: {
            customBodyRender: (value, tableMeta) => claimerEmailCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => claimerEmailCustomBodyRender(value, tableMeta),
            customFilterListOptions: {
              render: (value) => `Claimer Email: ${value}`,
            },
          },
        },
        {
          name: 'canceller',
          label: 'Cancelled By',
          options: {
            display: false,
            customBodyRender: (value, tableMeta) => cancellerCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => cancellerCustomBodyRender(value, tableMeta, true),
            customFilterListOptions: {
              render: value => `Cancelled By: ${value}`,
            },
          },
        },
        {
          name: 'releaser',
          label: 'Released By',
          options: {
            display: false,
            customBodyRender: (value, tableMeta) => releaserCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => releaserCustomBodyRender(value, tableMeta, true),
            customFilterListOptions: {
              render: value => `Released By: ${value}`,
            },
          },
        },
        {
          name: 'completed',
          label: 'Status',
          options: {
            sort: false,
            customBodyRender: (value, tableMeta) => completedCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => completedCustomBodyRender(value, tableMeta),
            filterOptions: {
              logic: (status, filters) => {
                if (!filters || filters.length === 0) {
                  return false;
                }
                if (status === RESCUE_CLOSED || status === RESCUE_NOT_CLOSED || status === RESCUE_CLOSED_NO_SHOW) {
                  return !filters.includes(status);
                }

                if (status === true) {
                  return !filters.includes(RESCUE_CLOSED);
                }

                if (status === false) {
                  return !filters.includes(RESCUE_CLOSED_NO_SHOW);
                }

                if (status === null) {
                  return !filters.includes(RESCUE_NOT_CLOSED);
                }

                return true;
              },
            },
            customFilterListOptions: {
              render: (value) => `Closed: ${value}`,
            },
            forceDisplay: !!editMode,
          },
        },
        {
          name: 'no_food',
          label: 'No Food Available',
          options: {
            display: false,
            sort: true,
            customBodyRender: value => noFoodCustomBodyRender(value),
            customBodyRenderCSV: value => noFoodCustomBodyRender(value),
            customFilterListOptions: {
              render: value => `No Food Available: ${value}`,
            },
          },
        },
        {
          name: 'weight_lbs',
          label: 'Weight',
          options: {
            display: true,
            forceDisplay: !!editMode,
            sort: false,
            customBodyRender: (value, tableMeta) => weightLbsCustomBodyRender(value, tableMeta),
            customBodyRenderCSV: (value, tableMeta) => weightLbsCustomBodyRender(value, tableMeta),
            customFilterListOptions: {
              render: value => `Weight (lbs): ${value}`,
            },
          },
        },
        {
          name: 'calculated_lbs_source',
          label: 'Weight Source',
          options: {
            display: true,
            sort: false,
            customBodyRender: (value) => weightSourceBodyRender(value),
          },
        },
        {
          name: 'co2eq',
          label: 'CO2 Equivalent Offset',
          options: {
            forceDisplay: editMode,
            display: !editMode,
            sort: false,
            filter: false,
            customBodyRender: (value) => cO2EquivalentOffsetRender(value),
            customBodyRenderCSV: (value) => cO2EquivalentOffsetRender(value),
          },
        },
        {
          name: 'water',
          label: 'Water Gallons Saved',
          options: {
            // Remove FF when ready
            forceDisplay: editMode,
            display: !editMode,
            sort: false,
            filter: false,
            customBodyRender: (value) => waterGallonsSavedRender(value),
            customBodyRenderCSV: (value) => waterGallonsSavedRender(value),
          },
        },
        {
          name: 'close_comments',
          label: 'Comments',
          options: {
            display: filterColumns.includes('close_comments') ? 'excluded' : true,
            sort: false,
            filter: false,
            customBodyRender: (value) => commentsCustomBodyRender(value),
            customBodyRenderCSV: (value) => commentsCustomBodyRender(value, true),
            customFilterListOptions: {
              render: (value) => `Comments: ${value}`,
            },
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            empty: true,
            display: showEditRescueButton && !editMode,
            forceDisplay: true,
            filter: false,
            searchable: false,
            viewColumns: false,
            customBodyRender: (value, tableMeta) => {
              const rescue = rescues[getMuiTableDataIndex(tableMeta)];

              if (!rescue) {
                return false;
              }

              return (
                <TableActionsButtonsWrapper>
                  {showEditRescueButton && (
                    <TableActionButton
                      title={
                        rescue.is_past
                          ? 'Edit'
                          : "Rescues that occur on today's date aren't able to be edited until midnight tomorrow."
                      }
                      disabled={!rescue.is_past || !!rescue.donor_deleted_at} // disable edit of the not yet archived rescues (today closed rescues)
                      buttonProps={{
                        component: DOMLink,
                        to: generatePath(routes.rescue, { rescueId: rescue.id }),
                      }}
                    />
                  )}

                  {showCopyRescueButton && (
                    <TableActionButton
                      icon={FileCopyIcon}
                      title="Copy Rescue"
                      disabled={!!rescue.donor_deleted_at}
                      onClick={() => onCopy(rescue)}
                    />
                  )}
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      title={editMode ? 'Edit Mode' : ''}
      options={{
        selectableRows: 'none',
        responsive: 'simple',
        download: !editMode,
        filter: !editMode,
        search: !editMode,
        viewColumns: !editMode,
        setRowProps: (row, dataIndex) => {
          const rescue = rescues[dataIndex];

          let colorClass = classes.unclaimedRescueRow;
          const rescueEdited = editedRescues.find((r) => r.rescue_id === rescue.id);

          if (rescue.cancelled_by_id) {
            colorClass = tableStyles.cancelledRescueRow;
          } else if (rescue.closed_by_id || rescue.completed !== null) {
            colorClass = tableStyles.closedRescueRow;
          } else if (isRescueClaimed(rescue)) {
            colorClass = tableStyles.claimedRescueRow;
          } else if (!isRescueClaimed(rescue)) {
            colorClass = tableStyles.unclaimedRescueRow;
          }

          return {
            className: rescueEdited ? `${classes.editedRow} ${colorClass}` : colorClass,
          };
        },
        setFilterChipProps: () => ({
          style: {
            height: 'auto',
            minHeight: '32px',
            whiteSpace: 'break-spaces',
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        }),
        downloadOptions: {
          filename: formatCsvFileName('Past Rescues'),
        },
        customToolbar: () =>
          customEditToolbar({
            handleEditClick,
            editMode,
            showEditRescueButton,
            hasActiveBulkEditFeatureFlag,
            handleReviewClick,
            reviewButtonEnabled: editedRescues.length,
          }),
        customFooter: editMode
          ? () => customEditFooter({ handleEditClick, handleReviewClick, reviewButtonEnabled: editedRescues.length })
          : null,
      }}
    />
  );
};

export default PastRescuesTable;
