import React, { Component } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as authService from '../services/auth';
import * as usersModel from '../models/users';
import { Roles } from '../models/roles';
import routes from '../routes';
import DefaultDashboard from '../components/Dashboard/DefaultDashboard';
import { withWidth } from '@material-ui/core';
import { RescuerDashboardContainer } from '../pages/rescuerDashboard/RescuerDashboardContainer';
import UnassignedUserDashboard from '../pages/UnassignedUser/UnassignedUserDashboard';

class DashboardView extends Component {
  noRoleAssignedView() {
    return <UnassignedUserDashboard />;
  }

  render() {
    const { width, defaultDashboard } = this.props;

    const currentlyLoggedInUser = authService.getCurrentlyLoggedInOrImpersonatingUser();

    const roleAssignments = authService.getUserRolesInCurrentlySelectedSite(currentlyLoggedInUser);
    const userRoles = usersModel.getRolesFromRoleAssignments(roleAssignments);
    let userRole;

    if (currentlyLoggedInUser) {
      if (userRoles.length) {
        userRole = userRoles[0];
      }
    }

    if (!userRole) {
      console.log('no role in current site: ', currentlyLoggedInUser);
      return this.noRoleAssignedView();
    }

    const hasRole = role => authService.hasRoleInCurrentlySelectedSite(currentlyLoggedInUser, role);
    const isOnMobileWidth = width === 'xs';

    // redirect to last viewed dashboard
    if (defaultDashboard && defaultDashboard.route && defaultDashboard.route !== routes.index) {
      return <Redirect to={generatePath(defaultDashboard.route, defaultDashboard.params)} />;
    }

    switch (true) {
      case !isOnMobileWidth && hasRole(Roles.NationalSiteDirector):
        return <Redirect to={generatePath(routes.dashboardNSD)} />;

      case hasRole(Roles.SiteCoordinator):
      case hasRole(Roles.SiteDirector):
      case isOnMobileWidth &&
        (authService.hasRoleInAnySite(currentlyLoggedInUser, Roles.SiteDirector) ||
          authService.hasRoleInAnySite(currentlyLoggedInUser, Roles.SiteCoordinator)):
        return <Redirect to={generatePath(routes.dashboardSC)} />;

      case authService.hasOnlyRoleInCurrentlySelectedSite(currentlyLoggedInUser, Roles.Rescuer):
      case hasRole(Roles.Rescuer) && userRoles.length > 1 && isOnMobileWidth:
        return <RescuerDashboardContainer isMobileView={isOnMobileWidth} />;

      case hasRole(Roles.DonorAdmin):
      case hasRole(Roles.DonorStaff):
      case hasRole(Roles.DonorLocationAdmin):
      case hasRole(Roles.DonorLocationStaff):
        return <Redirect to={generatePath(routes.dashboardFoodDonor)} />;
      case hasRole(Roles.ReceiverAdmin):
      case hasRole(Roles.ReceiverStaff):
      case hasRole(Roles.ReceiverLocationAdmin):
      case hasRole(Roles.ReceiverLocationStaff):
        return <Redirect to={generatePath(routes.dashboardReceiver)} />;
      default:
        return <DefaultDashboard userRole={userRole} />;
    }
  }
}

const mapStateToProps = ({ ui: { persisted } }) => {
  return {
    defaultDashboard: persisted.defaultDashboard,
  };
};

export default compose(
  withWidth(),
  connect(mapStateToProps)
)(DashboardView);
