import React, { useEffect, useState } from 'react';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import { pick, get, assign } from 'lodash';
import OverlaySpinner from './OverlaySpinner';
import { fetchSitesIfNeeded } from '../actions/sites';
import useUIFilter from '../hooks/useUIFilter';
import { fetchRescueSizesIfNeeded } from '../actions/rescueSizes';
import { fetchReceivingAgencyReportIfNeeded } from '../actions/reports';
import {
  dateRangeOptionsList,
  defaultGranularityOptions,
} from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import useActiveUser from '../hooks/useActiveUser';
import { Colors } from '../assets/theme/Colors';
import ReceiverReportForm from '../components/ReceiverReportForm';
import ReceiverReportTable from '../components/ReceiverReportTable';
import { FF_RECEIVING_AGENCY_REPORT } from '../components/FeatureFlagWrapper';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';

export const RECEIVER_REPORT_UI_FILTER = 'report-receivers';
const ReceiverReportView = () => {
  const dispatch = useDispatch();
  const user = useActiveUser();
  const isMediumHeight = useMediaQuery('(min-height: 680px)');
  const [filtersExpanded, setFiltersExpanded] = useState(true);
  const sitesObject = useSelector(state => state.entities.sites.byId);
  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const rescuesData = useSelector(state => state.entities.reports.receiving_agency);
  const hasReceivingAgencyReportFF = useHasActiveFeatureFlag(FF_RECEIVING_AGENCY_REPORT);
  const { setUIFilter, getUIFilter } = useUIFilter();
  const filters = assign(
    {
      period: dateRangeOptionsList.thisMTD.value,
      startDate: null,
      endDate: null,
      showLegend: true,
      granularity: defaultGranularityOptions.monthly.value,
      sitesIds: [],
    },
    getUIFilter(RECEIVER_REPORT_UI_FILTER)
  );
  const rescueSizesLegend = rescueSizes.sorted;

  const sitesWithDAccess = [
    ...new Set([
      ...authService.getUserSitesByRole(user.role_assignments, Roles.DonorAdmin),
      ...authService.getUserSitesByRole(user.role_assignments, Roles.DonorStaff),
      ...authService.getUserSitesByRole(user.role_assignments, Roles.SiteDirector),
      ...authService.getUserSitesByRole(user.role_assignments, Roles.SiteCoordinator),
      // Remove feature flag when ready
      ...authService.getUserSitesByRole(user.role_assignments, hasReceivingAgencyReportFF ? Roles.ReceiverLocationAdmin : null),
    ]),
  ];

  const isAdmin = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);

  const hasAccess = isAdmin || sitesWithDAccess.length > 0;

  const hasAcessToFullData = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
    Roles.DonorAdmin,
    Roles.DonorStaff,
  ]);

  const sitesList = isAdmin ? Object.values(sitesObject) : Object.values(pick(sitesObject, sitesWithDAccess));

  useEffect(() => {
    dispatch(fetchSitesIfNeeded());
    dispatch(fetchRescueSizesIfNeeded());
  }, [dispatch]);

  const setReportTableHeight = () => {
    const filtersHeight = filtersExpanded ? 275 : 48;
    const tableContainer = document.querySelector('div.table-container');

    if (tableContainer) {
      tableContainer.style.height =
        isMediumHeight || !filtersExpanded ? `calc(100vh - ${225 + filtersHeight}px)` : '50vh';
    }
  };

  useEffect(() => {
    setReportTableHeight();
  }, [filtersExpanded]);

  if (!hasAccess) {
    return 'no access';
  }

  const handleChange = (event, isExpanded) => setFiltersExpanded(isExpanded);

  const fetchReport = () => {
    if (sitesList.length !== 0 && hasAccess && filters.startDate !== null && filters.endDate !== null) {
      const siteIds = (() => {
        if (get(filters, 'sitesIds', []).length === 0) {
          return isAdmin ? [] : sitesList.map(site => site.id);
        }

        return get(filters, 'sitesIds', []).map(site => site.id);
      })();
      // TO DO: API call for Receiving Agency Admin
      dispatch(fetchReceivingAgencyReportIfNeeded(filters.startDate, filters.endDate, filters.granularity, siteIds));
    }
  };

  const currentlyLoggedInUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const getReceiversIdIfUserIsAdminOf = currentlyLoggedInUser.role_assignments
    .filter((role) => role.role_name === 'ReceiverLocationAdmin')
    .map((receiver) => receiver.receiver_id);

  const rescueReport = {
    sites: rescuesData.sites,
    periods: rescuesData.periods,
    total: rescuesData.total,
  };

  const totalreportDataForReceiversAdmin = () => {
    const total = {
      lbs: { periods: rescueReport.periods.map((period) => ({ count: 0, period })) },
      meals: { periods: rescueReport.periods.map((period) => ({ count: 0, period })) },
      rescues: { periods: rescueReport.periods.map((period) => ({ count: 0, period })) },
      value: { periods: rescueReport.periods.map((period) => ({ count: 0, period })) },
    };
    const sites = rescueReport.sites.map((siteRow) => siteRow.receiving_agencies);
    const receivingAgencies = []
      .concat([], ...sites)
      .filter((receiver) => getReceiversIdIfUserIsAdminOf.includes(receiver.receiving_agency.id));
    const totalSum = receivingAgencies.reduce((acc, curr) => {
      const lbs = get(curr, 'lbs.periods', 0);
      const meals = get(curr, 'meals.periods', 0);
      const rescues = get(curr, 'rescues.periods', 0);
      const value = get(curr, 'value.periods', 0);
      lbs.forEach((period) => {
        const currentIndex = acc.lbs.periods.findIndex((p) => p.period === period.period);
        acc.lbs.periods[currentIndex].count += period.count;
      });
      meals.forEach((period) => {
        const currentIndex = acc.meals.periods.findIndex((p) => p.period === period.period);
        acc.meals.periods[currentIndex].count += period.count;
      });
      rescues.forEach((period) => {
        const currentIndex = acc.rescues.periods.findIndex((p) => p.period === period.period);
        acc.rescues.periods[currentIndex].count += period.count;
      });
      value.forEach((period) => {
        const currentIndex = acc.value.periods.findIndex((p) => p.period === period.period);
        acc.value.periods[currentIndex].count += period.count;
      });
      return acc;
    }, total);
    return totalSum;
  };

  const reportDataFilteredForReceiversAdmin = {
    ...rescueReport,
    sites: rescueReport.sites.map((siteRow) => ({
      ...siteRow,
      receiving_agencies:
        siteRow.receiving_agencies &&
        siteRow.receiving_agencies.filter((receiver) =>
          getReceiversIdIfUserIsAdminOf.includes(receiver.receiving_agency.id)
        ),
    })),
    total: totalreportDataForReceiversAdmin(),
  };

  return (
    <OverlaySpinner isLoading={get(rescuesData, 'inflight', true)}>
      <Grid container>
        <Grid item xs={12}>
          <Accordion expanded={filtersExpanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" color="textPrimary">
                Receiving Agency Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ReceiverReportForm
                isAdmin={isAdmin}
                defaultValues={filters}
                setFilter={(filterData) => setUIFilter(RECEIVER_REPORT_UI_FILTER, filterData)}
                fetchReport={fetchReport}
                sitesList={sitesList}
                rescueSizesLegend={rescueSizesLegend}
              />
            </AccordionDetails>
          </Accordion>

          <ReceiverReportTable
            label="Receiving Agency"
            isAdmin={isAdmin}
            rescueReportData={hasAcessToFullData ? rescueReport : reportDataFilteredForReceiversAdmin}
            filters={filters}
            showSiteDataColumns={hasAcessToFullData}
          />
        </Grid>
      </Grid>
    </OverlaySpinner>
  );
};

export default ReceiverReportView;
