import Bluebird from 'bluebird';
import * as reportsApi from '../api/reports';

export const REQUEST_GROWTH = 'REQUEST_GROWTH';
export const RECEIVE_GROWTH = 'RECEIVE_GROWTH';

export const requestGrowth = () => ({
  type: REQUEST_GROWTH,
});

export const receiveGrowth = growth => ({
  type: RECEIVE_GROWTH,
  growth: growth,
  receivedAt: Date.now(),
});

export const fetchGrowth = (from, to, granularity, sitesIds = [], supressInactiveSites, completedRegistration) => dispatch => {
  dispatch(requestGrowth());

  return Bluebird.try(() => reportsApi.getGrowth(from, to, granularity, sitesIds, supressInactiveSites, completedRegistration))
    .then(res => res.json())
    .then(json => dispatch(receiveGrowth(json.data)));
};

export const fetchGrowthIfNeeded = (from, to, granularity, sitesIds = [], supressInactiveSites, completedRegistration) => dispatch =>
  dispatch(fetchGrowth(from, to, granularity, sitesIds, supressInactiveSites, completedRegistration));
