import React from 'react';
import RescuerDashboardMyRescuesTable from './RescuerDashboardMyRescuesTable';
import TableSkeletonLoader from '../../../../../components/Common/TableSkeletonLoader';
import { Typography } from '@material-ui/core';

const MyClaimedRescues = ({
  isLoading,
  claimedRescues,
  handleCellClick,
}) => {
  if (isLoading) {
    return <TableSkeletonLoader cols={6} rows={3} actionButtons={4} />;
  }

  if (claimedRescues.length === 0 && !isLoading) {
    return (
      <Typography color="textSecondary" variant="body1">
        You have no claimed rescues.
      </Typography>
    );
  }

  return (
    <RescuerDashboardMyRescuesTable
      tableId="#rescuer-dashboard-desktop/my-rescues"
      rescues={claimedRescues}
      onCellClick={handleCellClick}
    />
  );
};

export default MyClaimedRescues;
