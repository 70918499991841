import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import UmbrellaFoodDonorAddForm from '../components/UmbrellaFoodDonorAddForm';
import * as umbrellaFoodDonorsActions from '../actions/umbrellaFoodDonors';
import routes from '../routes';
import OverlayLoader from '../components/OverlayLoader';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import * as notificationsHelper from '../helpers/notifications';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
}));

const UmbrellaFoodDonorAddView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationsContext = useContext(NotificationsContext);

  const createUmbrellaFoodDonor = async (umbrellaFoodDonorData) => {
    await dispatch(umbrellaFoodDonorsActions.createUmbrellaFoodDonor(umbrellaFoodDonorData));

    notificationsHelper.addNotification('Umbrella Food Donor added successfully!', notificationsContext);

    return history.push(routes.umbrellaFoodDonors);
  };

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        <Link color="inherit" to={routes.umbrellaFoodDonors}>
          Umbrella Food Donors
        </Link>

        <Typography color="textPrimary">
          Add an Umbrella Food Donor
        </Typography>
      </Breadcrumbs>

      <OverlayLoader isLoading={false}>
        <Paper className={classes.paper}>
          <UmbrellaFoodDonorAddForm createUmbrellaFoodDonor={createUmbrellaFoodDonor} />
        </Paper>
      </OverlayLoader>
    </>
  );
};

export default UmbrellaFoodDonorAddView;
