import React, { forwardRef, useEffect } from 'react';
import OverlayLoader from './OverlayLoader';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { isSafari } from 'react-device-detect';
import '../assets/scss/fullCalendar.css';
import { Colors } from '../assets/theme/Colors';
import { Box, FormControlLabel, Checkbox, Typography, InputAdornment, IconButton } from '@material-ui/core';
import FeatureFlagWrapper, { FF_EVENTS } from './FeatureFlagWrapper';
import { TextFieldWrapper } from './Form/MuiFormWrapper';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';
import DateAndGranularityPicker from './DateAndGranularityPicker/DateAndGranularityPicker';

const TOP_PADDING = 100;
const BUTTONS_ROW = 42;
export const REDUX_KEY = '#rescues/schedule';

const eventContentRender = props => {
  const {
    event,
    timeText,
    backgroundColor,
    textColor,
    view: { type },
  } = props;

  const isCancelled = event.extendedProps.isCancelled;

  let baseStyles = {
    whiteSpace: 'normal',
    cursor: 'pointer',
    padding: 2,
    filter: event.extendedProps.isPast ? 'brightness(0.8)' : 'unset',
    textDecoration: isCancelled ? 'line-through' : 'none',
    textDecorationColor: isCancelled ? backgroundColor : 'none',
  };

  switch (type) {
    case 'timeGridDay':
    case 'timeGridWeek':
      return (
        <div
          style={{
            ...baseStyles,
            backgroundColor: isCancelled ? Colors.white : backgroundColor,
            color: isCancelled ? Colors.black : textColor,
            border: isCancelled ? `1px solid ${backgroundColor}` : 'initial',
            height: '100%',
            width: '100%',
            position: 'absolute',
            marginTop: -1,
            marginLeft: -1,
          }}
        >
          <div className="fc-event-main-frame">
            <div className="fc-event-time">{timeText}</div>
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">{event.title}</div>
            </div>
          </div>
        </div>
      );
    case 'dayGridDay':
    case 'dayGridWeek':
      return (
        <div
          style={{
            ...baseStyles,
            backgroundColor: isCancelled ? Colors.white : backgroundColor,
            color: isCancelled ? Colors.black : textColor,
            border: isCancelled ? `1px solid ${backgroundColor}` : 'initial',
            width: '100%',
          }}
        >
          <strong className="fc-event-time">{timeText}</strong>
          <span>{event.title}</span>
        </div>
      );
    case 'dayGridMonth':
      return (
        <div
          style={{
            ...baseStyles,
            backgroundColor: isCancelled ? Colors.white : backgroundColor,
            color: isCancelled ? Colors.black : textColor,
            border: isCancelled ? `1px solid ${backgroundColor}` : 'initial',
            width: '100%',
          }}
        >
          <strong className="fc-event-time">{timeText}</strong>
          <span>{event.title}</span>
        </div>
      );
    case 'listWeek':
      const claimer = event.extendedProps.claimer;
      if (claimer) {
        const claimerType = claimer.claimerType[0].toUpperCase() + claimer.claimerType.substring(1);
        return (
          <div style={baseStyles}>
            <span>{event.title}</span>
            <div>
              <strong>{claimerType}</strong>: {claimer.name}
            </div>
          </div>
        );
      }

      return <div style={baseStyles}>{event.title}</div>;
  }
};

const handleEventMount = ({ view: { type }, event, el }, isMobileView) => {
  if (type === 'listWeek' && isMobileView && el) {
    el.querySelectorAll('.fc-list-event-time').forEach(node => {
      const [starTime, endTime] = node.innerHTML.split('-');
      node.innerHTML = `${starTime} ${endTime}`;
    });
  }

  return undefined;
};

const dayHeaderContentRender = ({ text, sideText, view: { type } }) => {
  if (type === 'listWeek') {
    return (
      <a className="fc-list-day-text">
        {text}, {sideText}
      </a>
    );
  }

  return undefined;
};

const getButtonTexts = isMobileView =>
  isMobileView
    ? {
        today: 'This week',
      }
    : {
        // fallback to default names
      };

const getHeaderProps = (isMobileView, showTimeGrid) => {
  if (isMobileView) {
    return {
      start: 'title',
      center: '',
      end: 'today prev,next',
    };
  }

  return {
    start: '',
    center: 'title',
    end: `month,week,day,list today prev,next`,
  };
};

const EventLegend = ({ text, styles = {} }) => (
  <Box
    css={{
      ...styles,
      padding: '2px 8px',
      borderRadius: 4,
    }}
  >
    <Typography variant="caption"> {text}</Typography>
  </Box>
);

const RescuesSchedule = forwardRef(
  (
    {
      events,
      isFetching,
      bottomPadding,
      fetchRescues,
      isMobileView,
      handleGridSwitchClick,
      onSearchQueryChange,
      defaultSearchQuery,
      handleViewChange,
      calendarOptions,
      onEventClick,
      onDateRangeChange,
    },
    ref
  ) => {
    const handleWindowResize = () => {
      if (!ref.current) {
        return;
      }

      const cAPI = ref.current.getApi();
      cAPI.batchRendering(() => {
        let newHeight = window.innerHeight - TOP_PADDING - bottomPadding;
        if (!isMobileView) {
          newHeight = newHeight - BUTTONS_ROW;
        }

        cAPI.setOption('height', newHeight);
        if (isMobileView) {
          cAPI.changeView('listWeek');
        }
      });
    };

    const onViewClick = (grid, event) => {
      const buttons = document.querySelectorAll('.fc-button');
      buttons.forEach(button => button.classList.remove('fc-button-active'));

      event.target.classList.add('fc-button-active');
      if (!ref.current) {
        return;
      }
      const cAPI = ref.current.getApi();
      cAPI.changeView(grid);

      handleViewChange({ type: grid });
    };

    const getCustomButtons = showTimeGrid => {
      const viewType = showTimeGrid ? 'time' : 'day';
      return {
        month: {
          text: 'month',
          click: event => onViewClick('dayGridMonth', event),
        },
        week: {
          text: 'week',
          click: event => onViewClick(`${viewType}GridWeek`, event),
        },
        day: {
          text: 'day',
          click: event => onViewClick(`${viewType}GridDay`, event),
        },
        list: {
          text: 'list',
          click: event => onViewClick('listWeek', event),
        },
      };
    };

    useEffect(() => {
      if (!ref.current) {
        return;
      }
      const cAPI = ref.current.getApi();

      const gridType = calendarOptions.showTimeGrid ? 'time' : 'day';
      let newView = cAPI.view.type;

      if (newView.includes('GridWeek')) {
        newView = `${gridType}GridWeek`;
        const viewButton = document.querySelector('.fc-week-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      if (newView.includes('GridDay')) {
        newView = `${gridType}GridDay`;
        const viewButton = document.querySelector('.fc-day-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      if (newView.includes('GridMonth')) {
        const viewButton = document.querySelector('.fc-month-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      if (newView.includes('listWeek')) {
        const viewButton = document.querySelector('.fc-list-button');
        if (viewButton) {
          viewButton.classList.add('fc-button-active');
        }
      }

      cAPI.batchRendering(() => {
        cAPI.changeView(isMobileView ? 'listWeek' : newView);
        cAPI.setOption('headerToolbar', getHeaderProps(isMobileView, calendarOptions.showTimeGrid));
      });
    }, [isMobileView, calendarOptions.showTimeGrid]);

    return (
      <OverlayLoader isLoading={isFetching}>
        <Box
          display={isMobileView ? 'none' : 'flex'}
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="grid" gridAutoFlow="column" gridColumnGap={5}>
            <EventLegend
              styles={{
                backgroundColor: Colors.rescues.unclaimed.color,
                color: Colors.rescues.unclaimed.contrastText,
              }}
              text="Unclaimed"
            />
            <EventLegend
              styles={{
                backgroundColor: Colors.rescues.claimed.color,
                color: Colors.rescues.claimed.contrastText,
              }}
              text="Claimed"
            />
            <EventLegend
              styles={{
                backgroundColor: Colors.rescues.closed.color,
                color: Colors.rescues.closed.contrastText,
              }}
              text="Closed"
            />
            <EventLegend
              styles={{
                backgroundColor: '#fff',
                color: '#000',
                border: `1px solid ${Colors.rescues.cancelled.color}`,
                textDecoration: 'line-through',
                textDecorationColor: Colors.rescues.cancelled.color,
              }}
              text="Cancelled"
            />
            <FeatureFlagWrapper
              flagName={FF_EVENTS}
              renderOn={() => (
                <EventLegend
                  styles={{
                    backgroundColor: Colors.event.color,
                    color: Colors.event.contrastText,
                  }}
                  text="Event"
                />
              )}
            />
          </Box>
          <Box display="flex" flexDirection="row" gridColumnGap={8}>
            <Box bgcolor="background.paper">
              <TextFieldWrapper
                value={defaultSearchQuery}
                onChange={event => onSearchQueryChange(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => onSearchQueryChange('')}>
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search..."
                variant="outlined"
                name="query"
                size="small"
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={calendarOptions.showTimeGrid}
                  onChange={handleGridSwitchClick}
                  name="events"
                  color="primary"
                />
              }
              label="Show Time Grid"
            />
          </Box>
          <Box mt={2} width="100%" flexBasis="100%">
            <DateAndGranularityPicker
              showGranularity={false}
              showCustomDateRange
              showAllDateRange
              defaultDateRange="all"
              onChange={(data) => onDateRangeChange(data)}
            />
          </Box>
        </Box>
        <FullCalendar
          ref={ref}
          noEventsContent={() => (isFetching ? 'Fetching rescues...' : 'No rescues found')}
          height={window.innerHeight - TOP_PADDING - bottomPadding - (isMobileView ? 0 : BUTTONS_ROW)}
          headerToolbar={getHeaderProps(isMobileView, calendarOptions.showTimeGrid || false)}
          customButtons={getCustomButtons(calendarOptions.showTimeGrid || false)}
          buttonText={getButtonTexts(isMobileView)}
          allDaySlot={false}
          datesSet={({ start, end }) => fetchRescues(start, end)}
          eventClick={({ event }) => onEventClick(event)}
          initialView={calendarOptions.view || 'dayGridDay'}
          initialDate={calendarOptions.start ? moment(calendarOptions.start, 'YYYYMMDD').toDate() : new Date()}
          plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          viewDidMount={({ view }) => handleViewChange(view)}
          validRange={
            calendarOptions.viewDateRange || {
              start: new Date(),
              end: new Date(),
            }
          }
          stickyHeaderDates={!isSafari}
          eventClassNames={() => (isMobileView ? ['fc-event-mobile'] : [])}
          dayHeaderContent={dayHeaderContentRender}
          eventContent={eventContentRender}
          eventDidMount={event => handleEventMount(event, isMobileView)}
          windowResize={handleWindowResize}
        />
      </OverlayLoader>
    );
  }
);

RescuesSchedule.propTypes = {
  isMobileView: PropTypes.bool,
  calendarOptions: PropTypes.object.isRequired,
};

RescuesSchedule.defaultProps = {
  isMobileView: false,
  calendarOptions: {},
};

export default RescuesSchedule;
