import React  from 'react';
import { withStyles, IconButton, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Colors } from '../assets/theme/Colors';

const TableActionButton = ({ title, icon: Component, onClick, disabled, classes, buttonProps = {} }) => {
  const iconProps = {
    fontSize: 'small',
  };

  return (
    <Tooltip title={title} placement="top">
      <span className={classes.span}>
        <IconButton
          className={classes.button}
          color="primary"
          onClick={onClick}
          disabled={disabled}
          {...buttonProps}
        >
          {Component && (
            <Component {...iconProps} />
          )}

          {!Component && (
            <Edit {...iconProps} />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

const styles = theme => ({
  span: ({ disabled }) => {
    const spanStyles = {
      display: 'inline-block',
    };

    if (disabled) {
      spanStyles.cursor = 'not-allowed';
    }

    return spanStyles;
  },
  button: {
    margin: theme.spacing(0.5),
  },
});

export default withStyles(styles)(TableActionButton);
