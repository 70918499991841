import { Breadcrumbs, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import routes from '../routes';
import AddNewSiteGoalForm from '../components/AddNewSiteGoalForm';
import { postSiteGoal } from '../api/siteGoals';

const AddNewSiteGoalView = () => {
  const siteId = useSelector((state) => state.app.site.id);

  const handleSubmit = async (values) => {
    await postSiteGoal({ ...values, site_id: siteId });
  };

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={generatePath(routes.dashboardSC, { tab: 'site-goals' })}>
          Dashboard SD/SC
        </Link>
        <Typography color="textPrimary">Add New Site Goal </Typography>
      </Breadcrumbs>
      <AddNewSiteGoalForm submit={handleSubmit} />
    </>
  );
};
export default AddNewSiteGoalView;
