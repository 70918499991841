import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Box, Button, Typography, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { BaseCard } from '../../components/Common/BaseCard';
import routes from '../../routes';

const useStyles = makeStyles(({ spacing }) => ({
  cardActions: {
    padding: spacing(0, 2, 2, 2),
  },
}));

const FoodDonorPendingMobileCard = ({ foodDonor, isLoading }) => {
  const classes = useStyles();

  return (
    <BaseCard>
      <CardContent>
        <Box display="flex">
          <Box flexGrow={1} display="flex" justifyContent="flex-start" flexDirection="row" alignItems="stretch">
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <Typography variant="subtitle1">
                <strong>Food Donor Location Name</strong>
              </Typography>
              <Typography variant="body1"> {isLoading ? <Skeleton /> : foodDonor.name}</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>

      <CardActions disableSpacing className={classes.cardActions}>
        <Button
          data-testid="details-button"
          variant="contained"
          color="primary"
          component={Link}
          to={generatePath(routes.foodDonorRegistrationReview, { foodDonorId: foodDonor.id })}
        >
          Verify
        </Button>
      </CardActions>
    </BaseCard>
  );
};

export default FoodDonorPendingMobileCard;
