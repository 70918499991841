import queryString from 'query-string';
import { apiGET, apiPOST } from './api';

export const getSiteGoals = (params) => {
  const query = queryString.stringify(params, {
    skipNull: true,
  });

  return apiGET(`/sites_goals?${query}`);
};

export const postSiteGoal = (data) => apiPOST(`/sites_goals`, data);
