import React, { useEffect, useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { Box, Breadcrumbs, Button, CardContent, Divider, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import OverlayLoader from '../../components/OverlayLoader';
import routes from '../../routes';
import useNotificationService from '../../hooks/useNotificationService';
import * as umbrellaFoodDonorsActions from '../../actions/umbrellaFoodDonors';
import { fetchFoodDonors, updateFoodDonor } from '../../actions/foodDonors';
import { BaseCard } from '../../components/Common/BaseCard';
import { GridRow, RowContent, RowTitle } from '../Rescue/RescueEdit/FormGrid';
import { formatAddress } from '../../helpers/formatters';
import { fetchSitesIfNeeded } from '../../actions/sites';

const UmbrellaFoodDonorAddToUmbrellaView = ({ match }) => {
  const umbrellaFoodDonorId = match.params.id;
  const dispatch = useDispatch();
  const umbrellaFoodDonors = useSelector((state) => state.entities.umbrellaFoodDonors);
  const foodDonorsEntities = useSelector((state) => state.entities.foodDonors);
  const foodDonors = useSelector((state) => state.entities.foodDonors.byId);
  const foodDonorsList = Object.values(foodDonors);
  // Food donors without the umbrella food donor
  const options = foodDonorsList.filter((foodDonor) => foodDonor.donor_id === null);
  const umbrellaFoodDonor = umbrellaFoodDonors.byId[umbrellaFoodDonorId];
  const { addErrorNotification, addSuccessNotification } = useNotificationService();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [foodDonorOptions, setFoodDonorOptions] = useState([]);
  const sites = useSelector((state) => state.entities.sites.byId);
  const sitesList = Object.values(sites);
  const history = useHistory();
  const disabledAndProbationSites = sitesList.filter((site) => site.disabled_at || site.type === 'probationer');
  const foodDonorsFiltered = options
    .filter((option) => option.makes_donations)
    .filter((option) => !disabledAndProbationSites.some((site) => site.id === option.site_id))
    .filter((option) => sitesList.some((site) => site.id === option.site_id));
  const loadSuggestionsMinChars = 2;

  useEffect(() => {
    dispatch(umbrellaFoodDonorsActions.fetchUmbrellaFoodDonorIfNeeded(umbrellaFoodDonorId));
    dispatch(fetchSitesIfNeeded());
    dispatch(fetchFoodDonors());
  }, [dispatch]);

  useEffect(() => {
    if (inputValue.length <= loadSuggestionsMinChars) {
      return setFoodDonorOptions([]);
    }

    const filteredOptions = foodDonorsFiltered.filter((option) => option.name.toLowerCase().startsWith(inputValue.toLocaleLowerCase()));

    return setFoodDonorOptions(filteredOptions);
  }, [inputValue]);

  const donorLocationPath = generatePath(routes.umbrellaFoodDonor, { id: umbrellaFoodDonorId, tab: 'food-donors' });

  const handleSubmit = async () => {
    setLoading(true);
    await Promise.all(
      selected.map(async (foodDonor) => {
        try {
          await dispatch(
            updateFoodDonor(foodDonor.id, {
              ...foodDonor,
              donor_id: umbrellaFoodDonor.id,
              user_id: foodDonor.primary_contact_id,
            })
          );
          addSuccessNotification(`${foodDonor.name} added to ${umbrellaFoodDonor.name} umbrella.`);
        } catch (error) {
          setLoading(false);
          addErrorNotification(error, `Unable to add food donor to umbrella. Reason: ${error.message}`);
        }
      })
    );
    history.push(donorLocationPath);
  };

  const handleFoodDonorSelect = (foodDonor) => {
    setSelected(foodDonor);
  };

  const renderSite = (foodDonor) => {
    let foodDonorSite = 'N/A';
    const assignedSite = sitesList.filter((site) => site.id === foodDonor.site_id);
    if (assignedSite && assignedSite.length > 0) {
      foodDonorSite = assignedSite[0].name;
    }
    return foodDonorSite;
  };

  if (umbrellaFoodDonors.inflight || !umbrellaFoodDonor) {
    return <div>fetching Umbrella Food Donors</div>;
  }

  return (
    <div>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link color="inherit" to={routes.umbrellaFoodDonors}>
          Umbrella Food Donors
        </Link>

        <Link color="inherit" to={donorLocationPath}>
          {umbrellaFoodDonor.name}
        </Link>

        <Typography color="textPrimary">Add Food Donor to Umbrella</Typography>
      </Breadcrumbs>

      <OverlayLoader isLoading={loading || foodDonorsEntities.inflight}>
        <Grid container>
          <Grid item container md={12} lg={6} spacing={3}>
            <Grid item xs={12}>
              <BaseCard>
                <CardContent>
                  <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                    <InputLabel shrink>Food Donor</InputLabel>
                    <Autocomplete
                      loading={foodDonorsEntities.inflight}
                      size="small"
                      name="food_donor_id"
                      data-testid="food_donor_id-selector"
                      placeholder="Select Food Donor"
                      multiple
                      options={foodDonorOptions}
                      onInputChange={(event, value) => {
                        setInputValue(value);
                      }}
                      disableClearable
                      onChange={(event, value) => handleFoodDonorSelect(value)}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="Type the 3 first letters of the Food Donor name"
                      renderOption={(option) => (
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body1">{option.name}</Typography>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Food Donor" variant="outlined" />
                      )}
                    />
                  </Grid>
                </CardContent>
              </BaseCard>

              {selected.map((fd) => (
                <Grid item container key={fd.id}>
                  <Grid item xs={12}>
                    <BaseCard style={{ marginTop: 5 }} key={fd.id}>
                      <CardContent>
                        <GridRow>
                          <RowTitle>Name:</RowTitle>
                          <RowContent>{fd.name}</RowContent>
                        </GridRow>
                        <GridRow>
                          <RowTitle>Address:</RowTitle>
                          <RowContent>
                            {formatAddress({
                              address: get(fd, 'address1', ''),
                              city: get(fd, 'city', ''),
                              zip: get(fd, 'zip', ''),
                              st: get(fd, 'state', ''),
                            })}
                          </RowContent>
                        </GridRow>
                        <GridRow>
                          <RowTitle>Site:</RowTitle>
                          <RowContent>{renderSite(fd)}</RowContent>
                        </GridRow>
                      </CardContent>
                    </BaseCard>
                    </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item container xs={12} spacing={3}>
              <Grid item>
                <Button type="button" variant="contained" color="primary" onClick={handleSubmit} disabled={!selected.length}>
                  Add Food Donor(s)
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </OverlayLoader>
    </div>
  );
};

export default UmbrellaFoodDonorAddToUmbrellaView;
