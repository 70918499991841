import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { TextField } from '@material-ui/core';

const AddressAutocomplete = ({ onSelect }) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place) => {
      onSelect(place);
    },
    options: {
      types: ['address'],
      fields: ['address_components', 'formatted_address'],
      componentRestrictions: { country: 'us' },
    },
  });

  return <TextField fullWidth id="address-input" label="Address" inputRef={ref} />;
}

export default AddressAutocomplete;
