import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import * as sitesActions from '../actions/sites';
import * as authService from '../services/auth';
import * as sitesApi from '../api/sites';
import { useHistory } from 'react-router-dom';
import routes from '../routes';
import SendEmailPreview from '../components/SendEmailPreview';

const SendEmailForm = lazy(() => import('../components/SendEmailForm'));

const SendEmailView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const activeSite = useSelector(state => state.app.site);
  const sites = useSelector(state => state.entities.sites);
  const site = sites.byId[activeSite.id];
  const siteUsers = useSelector(state => state.entities.sites.users);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [recipientsList, setRecipientsList] = useState(false);
  const [emailContent, setEmailContent] = useState({
    reply_to: '',
    subject: '',
    body: '',
    users_ids: [],
    include_unverified: false,
  });
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!location.state || !location.state.usersIds) {
      history.push(routes.index)
    }

    dispatch(sitesActions.fetchSite(activeSite.id));
    dispatch(sitesActions.fetchSiteUsers(activeSite.id));
  }, [activeSite.id, dispatch]);

  const handleSubmit = values => {
    // TO DO: Add attatchments when API is ready
    const currentlyLoggedInOrImpersonatingUser = authService.getCurrentlyLoggedInOrImpersonatingUser();

    return sitesApi.sendSiteEmails(
      activeSite.id,
      {
        ...values,
        created_by_id: currentlyLoggedInOrImpersonatingUser.id,
      }
    );
  };

  const handlePreview = (values, recipients) => {
    setPreviewVisible(true);
    setEmailContent(values);
    setRecipientsList(recipients);
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
  };

  if (sites.inflight || !site) {
    return 'Loading sites...';
  }

  if (siteUsers.inflight || siteUsers.bySiteId[site.id] === undefined) {
    return 'Loading users...';
  }

  const foundSelectedUsers = siteUsers
    .bySiteId[site.id]
    .filter(r => location.state.usersIds.indexOf(r.id) !== -1);
  const verifiedRecipients = foundSelectedUsers.filter(r => r.email_verified);
  const unverifiedRecipients = foundSelectedUsers.filter(r => !r.email_verified);

  return (
    <Suspense fallback={null}>
      <Box mb={12}>
        <Breadcrumbs aria-label="Breadcrumbs">
          <Typography color="textPrimary">Send E-mail</Typography>
        </Breadcrumbs>

        {!previewVisible && (
          <SendEmailForm
            replyTo={site.reply_to_email}
            verifiedRecipients={verifiedRecipients}
            unverifiedRecipients={unverifiedRecipients}
            previewCallback={handlePreview}
            content={emailContent}
            files={files}
            setFiles={setFiles}
          />
        )}
        {previewVisible && (
          <SendEmailPreview
            content={emailContent}
            handleClosePreview={handleClosePreview}
            submitCallback={handleSubmit}
            recipients={recipientsList}
          />
        )}
      </Box>
    </Suspense>
  );
};

export default SendEmailView;
