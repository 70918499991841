import React from 'react';
import { Table, TableHead, TableRow, TableBody, TableCell, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: 'none',
  },
}));

const RescueSizingTable = ({ rescueSizeData, dataUpdated }) => {
  const classes = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center" className={classes.root}>
            Size
          </TableCell>
          <TableCell align="center" className={classes.root}>
            Current
          </TableCell>
          <TableCell align="center" className={classes.root}>
            Recalculated
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {rescueSizeData.map((row, index) => (
          <TableRow key={index}>
            <TableCell align="center" className={classes.root}>{row.size}</TableCell>
            <TableCell align="center" className={classes.root}>
              <TextField
                size="small"
                variant="outlined"
                value={row.current}
                InputProps={{
                  readOnly: true,
                }}
                style={{ width: 70 }}
              />
            </TableCell>
            <TableCell align="center" className={classes.root}>
              <TextField
                size="small"
                variant="outlined"
                value={row.recalculated}
                disabled={dataUpdated}
                InputProps={{
                  readOnly: true,
                }}
                style={{ width: 70 }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RescueSizingTable;
