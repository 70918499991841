import * as leaderboardsActions from '../actions/leaderboards';

export const initialSitesState = {
  inflight: false,
  lastUpdated: null,
  foodDonors: {
    lbs: [],
    meals: [],
    rescues: [],
    value: [],
  },
  receivingAgencies: {
    lbs: [],
    meals: [],
    rescues: [],
    value: [],
  },
  rescuers: {
    lbs: [],
    meals: [],
    rescues: [],
    value: [],
  },
};

const leaderboardsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case leaderboardsActions.REQUEST_LEADERBOARD:
      return {
        ...state,
        inflight: true,
      };

    case leaderboardsActions.RECEIVE_LEADERBOARD:
      return {
        ...state,
        lastUpdated: action.receivedAt,
        inflight: false,
        foodDonors: action.data.foodDonors,
        receivingAgencies: action.data.receivingAgencies,
        rescuers: action.data.rescuers,
      };
    default:
      return state;
  }
};

export default leaderboardsReducer;
