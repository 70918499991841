import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import { get } from 'lodash';

import { objectFromArray } from '../helpers';

import { apiToStore } from '../models/rescues';

import {
  RECEIVE_RESCUE,
  REQUEST_RESCUES,
  RECEIVE_RESCUES,
  MODIFY_RESCUE,
  RESET_RESCUE,
  RECEIVE_RESCUE_POST,
  REQUEST_RESCUER_AVAILABLE_RESCUES,
  RECEIVE_RESCUER_AVAILABLE_RESCUES,
  RECEIVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES,
  RECEIVE_RESCUER_PAST_RESCUES,
  ADD_RESCUER_CLAIMED_AND_ADOPTED_RESCUE,
  REMOVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES_REGEX,
  REMOVE_RESCUER_AVAILABLE_RESCUE,
  ADD_RESCUER_AVAILABLE_RESCUES,
  REQUEST_RESCUER_CLAIMED_AND_ADOPTED_RESCUES,
  REQUEST_RESCUER_PAST_RESCUES,
  INVALIDATE_RESCUES_DATA,
} from '../actions/rescues';
import { getRescueClaimer } from '../helpers/RescuesHelper';

export const rescuesInitialState = {
  byId: {},
  allIds: [],
  modifiedIds: [],
  modifiedById: {},
  inflight: false,
  availableIds: {},
  claimedAndAdoptedIds: {},
  pastIds: {},
  lastUpdated: null,
};

const prepareRescues = rescues => {
  const _r = rescues.map(r => apiToStore(r));
  return objectFromArray(_r, r => r.id);
};

const prepareAllIdsArray = (current, rescues) => {
  return uniq([...current, ...rescues.map(r => r.id)]);
};

const rescuesReducer = (state = rescuesInitialState, action) => {
  switch (action.type) {
    case INVALIDATE_RESCUES_DATA:
      return {
        ...state,
        ...rescuesInitialState,
      };
    case RECEIVE_RESCUE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.rescue.id]: apiToStore(action.payload.rescue),
        },
        allIds: [...new Set([...state.allIds, action.payload.rescue.id])],
      };
    case REQUEST_RESCUES:
      return {
        ...state,
        inflight: true,
      };
    case RECEIVE_RESCUES: {
      const _r = action.rescues.map(r => apiToStore(r));
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(_r, r => r.id),
        allIds: _r.map(r => r.id),
        lastUpdated: action.receivedAt,
        availableIds: {},
        claimedAndAdoptedIds: {},
        pastIds: {},
      };
    }
    case MODIFY_RESCUE:
      var key = action.id;
      var modifiedRescue = Object.assign({}, state.byId[key], action.attrs);
      // add claimer prop based on mmodified rescue
      var rescueWithClaimer = { ...modifiedRescue, claimer: getRescueClaimer(modifiedRescue) };

      return {
        ...state,
        modifiedIds: state.modifiedIds.indexOf(key) > 0 ? state.modifiedIds : state.modifiedIds.concat(key),
        modifiedById: Object.assign({}, state.modifiedById, { [key]: rescueWithClaimer }),
      };
    case RESET_RESCUE: {
      const key = action.id;
      const { [key]: dontcare, ...newById } = state.modifiedById;
      return {
        ...state,
        modifiedIds: state.modifiedIds.filter(id => id !== key),
        modifiedById: newById,
      };
    }
    // @todo make explicit the decision to not track inflight status for PATCH request... (or change our mind...)
    case RECEIVE_RESCUE_POST: {
      const key = action.rescue.id;
      const { [key]: dontcare, ...newById } = state.modifiedById;
      return {
        ...state,
        byId: { ...state.byId, [key]: apiToStore(action.rescue) },
        modifiedIds: state.modifiedIds.filter(_id => _id !== key),
        modifiedById: newById,
      };
    }
    case ADD_RESCUER_AVAILABLE_RESCUES:
      return {
        ...state,
        availableIds: {
          ...state.availableIds,
          [action.rescuerId]: {
            ...state.availableIds[action.rescuerId],
            data: [...get(state, ['availableIds', action.rescuerId, 'data'], []), action.rescuesIds],
          },
        },
      };
    case REMOVE_RESCUER_AVAILABLE_RESCUE:
      return {
        ...state,
        availableIds: {
          ...state.availableIds,
          [action.rescuerId]: {
            ...state.availableIds[action.rescuerId],
            data: get(state, ['availableIds', action.rescuerId, 'data'], []).filter(id => id !== action.rescueId),
          },
        },
      };
    case REQUEST_RESCUER_AVAILABLE_RESCUES:
      return {
        ...state,
        availableIds: {
          ...state.availableIds,
          [action.rescuerId]: {
            ...state.availableIds[action.rescuerId],
            inflight: true,
          },
        },
      };
    case RECEIVE_RESCUER_AVAILABLE_RESCUES:
      return {
        ...state,
        availableIds: {
          ...state.availableIds,
          [action.rescuerId]: {
            inflight: false,
            lastUpdated: action.receivedAt,
            pagination: action.clearPreviousData
              ? action.pagination
              : {
                  ...get(state, `availableIds[${action.rescuerId}].pagination`, {}),
                  ...action.pagination,
                },
            data: action.clearPreviousData
              ? get(action, 'rescues', []).map(rescue => rescue.id)
              : [
                  ...new Set([
                    ...get(state, `availableIds[${action.rescuerId}].data`, []),
                    ...get(action, 'rescues', []).map(rescue => rescue.id),
                  ]),
                ],
          },
        },
        byId: merge(state.byId, prepareRescues(action.rescues)),
        allIds: prepareAllIdsArray(state.allIds, action.rescues),
      };
    case ADD_RESCUER_CLAIMED_AND_ADOPTED_RESCUE:
      const rescuerClaimedAdoptedRescues = state.claimedAndAdoptedIds[action.rescuerId]
        ? state.claimedAndAdoptedIds[action.rescuerId].data
        : [];
      const sortedClaimedIds = [...rescuerClaimedAdoptedRescues, action.rescueId].sort((a, b) => {
        const [aNumber, aDate] = a.split('_');
        const [bNumber, bDate] = b.split('_');

        return aDate.localeCompare(bDate) || aNumber - bNumber;
      });

      return {
        ...state,
        claimedAndAdoptedIds: {
          ...state.claimedAndAdoptedIds,
          [action.rescuerId]: {
            ...state.claimedAndAdoptedIds[action.rescuerId],
            data: sortedClaimedIds,
          },
        },
      };
    case REMOVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES_REGEX:
      const filterRegex = new RegExp(action.regex);
      const rescuerRemoveClaimedAdoptedRescues = get(state, ['claimedAndAdoptedIds', action.rescuerId, 'data'], []);
      return {
        ...state,
        claimedAndAdoptedIds: {
          ...state.claimedAndAdoptedIds,
          [action.rescuerId]: {
            ...state.claimedAndAdoptedIds[action.rescuerId],
            data: rescuerRemoveClaimedAdoptedRescues.filter(rescueId => !filterRegex.test(rescueId)),
          },
        },
      };
    case REQUEST_RESCUER_CLAIMED_AND_ADOPTED_RESCUES:
      return {
        ...state,
        claimedAndAdoptedIds: {
          ...state.claimedAndAdoptedIds,
          [action.rescuerId]: {
            ...state.claimedAndAdoptedIds[action.rescuerId],
            inflight: true,
          },
        },
      };
    case RECEIVE_RESCUER_CLAIMED_AND_ADOPTED_RESCUES:
      const rescuesIds = action.rescues.map(rescue => rescue.id);
      return {
        ...state,
        inflight: false,
        claimedAndAdoptedIds: {
          ...state.claimedAndAdoptedIds,
          [action.rescuerId]: {
            data: rescuesIds,
            inflight: false,
            lastUpdated: action.receivedAt,
          },
        },
        byId: {
          ...state.byId,
          ...prepareRescues(action.rescues),
        },
        allIds: [...new Set([...state.allIds, ...rescuesIds])],
      };
    case REQUEST_RESCUER_PAST_RESCUES:
      return {
        ...state,
        pastIds: {
          ...state.pastIds,
          [action.rescuerId]: {
            ...state.pastIds[action.rescueId],
            inflight: true,
          },
        },
      };
    case RECEIVE_RESCUER_PAST_RESCUES:
      const rescuesPastIds = action.rescues.map(rescue => rescue.id);
      return {
        ...state,
        inflight: false,
        pastIds: {
          ...state.pastIds,
          [action.rescuerId]: {
            data: rescuesPastIds,
            inflight: false,
            lastUpdated: action.receivedAt,
          },
        },
        byId: {
          ...state.byId,
          ...prepareRescues(action.rescues),
        },
        allIds: [...new Set([...state.allIds, ...rescuesPastIds])],
      };
    default:
      return state;
  }
};

export default rescuesReducer;
