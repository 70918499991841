import { Colors } from './Colors';

export const Tooltip = {
  MuiTooltip: {
    tooltip: {
      padding: '10px 15px',
      fontSize: '13px',
      color: Colors.white,
      lineHeight: '16px',
    },
  },
};