import React, { useEffect } from 'react';
import routes from '../../../../routes';
import { useSelector } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@material-ui/core';
import useActiveUser from '../../../../hooks/useActiveUser';
import RescuerDashboardRecentRescues from './Components/RescuerDashboardRecentRescues';
import RescuerDashboardUpcomingRescues from './Components/RescuerUpcomingRescues';
import RescuerTodayRescues from './Components/RescuerTodayRescues';
import { Box, CardContent, CardHeader } from '@material-ui/core';
import { DashboardTableCard } from '../../../../components/Dashboard/DashboardTableCard';
import useHasActiveFeatureFlag from '../../../../hooks/useFeatureFlags';
import { FF_EVENTS, FF_SITE_GOALS } from '../../../../components/FeatureFlagWrapper';
import { get } from 'lodash';
import MyFrusEvents from './Components/MyFrusEvents';
import {
  getMyFrusMyEvents,
  getRecentRescues,
  getTodayRescues,
  getUpcomingRescues,
} from '../../../../helpers/RescuesHelper';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import { RESCUER_DESKTOP_DASHBOARD_TAB_MY_RESCUES } from '../RescuerDashboardDesktopContainer';
import MyBadges from './Components/MyBadges';
import MySiteGoals from './Components/MySiteGoals';
import MyEnviroImpact from './Components/MyEnviroImpact';

const MyFrusDesktop = ({ history }) => {
  const activeUser = useActiveUser();
  const hasActiveEventsFeatureFlag = useHasActiveFeatureFlag(FF_EVENTS);
  const hasActiveSiteGoalsFeatureFlag = useHasActiveFeatureFlag(FF_SITE_GOALS);
  const { fetchMyFrusTab, fetchMyEvents, fetchMyBadges, fetchGoals, fetchEnviromentImpact } = useRescuerDashboardActions();

  const environmentImpactEntities = useSelector((state) => state.entities.environmentImpact);
  const goalsEntities = useSelector((state) => state.entities.siteGoals.byId);
  const badgesEntities = useSelector((state) => state.entities.badges);
  const siteGoalsEntities = useSelector((state) => state.entities.siteGoals);
  const rescuesEntities = useSelector((state) => state.entities.rescues);
  const eventsEntities = useSelector((state) => state.entities.events);
  const isInFlight =
    rescuesEntities.inflight || get(rescuesEntities, ['claimedAndAdoptedIds', activeUser.id, 'inflight'], true);
  const goalsList = Object.values(goalsEntities);

  const todayRescues = getTodayRescues(activeUser.id, rescuesEntities);
  const recentRescues = getRecentRescues(activeUser.id, rescuesEntities);
  const upcomingRescues = getUpcomingRescues(activeUser.id, rescuesEntities);
  const eventsList = getMyFrusMyEvents(eventsEntities, activeUser.id);
  const eventsLoading = get(eventsEntities, `byRescuerId[${activeUser.id}].inflight`, true);
  const badgesLoading = get(badgesEntities, `byRescuerId[${activeUser.id}].inflight`, true);
  const siteGoalsLoading = siteGoalsEntities.inflight;
  const environmentImpactLoading = environmentImpactEntities.inflight;
  const badgesList = get(badgesEntities, `byRescuerId[${activeUser.id}].badges`, []);

  const onCellClick = (rescue) => history.push(generatePath(routes.rescue, { rescueId: rescue.id }));
  const onMyEventsRowClick = (eventId) => history.push(generatePath(routes.eventSignup, { eventId: eventId }));

  useEffect(() => {
    fetchMyBadges(false, false); //quietMode, force

    if (hasActiveSiteGoalsFeatureFlag) {
      fetchGoals(false); //quietMode
    }

    fetchEnviromentImpact(false); //quietMode
    fetchMyFrusTab(false, false); //quietMode, force
    fetchMyEvents(false, false); //quietMode, force
  }, [activeUser.id, fetchMyFrusTab]);

  return (
    <Box display="grid" gridRowGap={16} mb={2}>
      <DashboardTableCard isLoading={isInFlight}>
        <CardHeader
          title="Today's Rescues"
          subheader={
            todayRescues.length > 0 ? (
              <Typography variant="body2" color="textSecondary">
                Showing your upcoming rescues that are happening today.
              </Typography>
            ) : null
          }
        />
        <CardContent>
          <RescuerTodayRescues isLoading={isInFlight} todayRescues={todayRescues} onCellClick={onCellClick} />
        </CardContent>
      </DashboardTableCard>

      <DashboardTableCard id="recent-rescues" isLoading={isInFlight}>
        <CardHeader
          title="Recent Rescues"
          subheader={
            recentRescues.length > 0 ? (
              <Typography variant="body2" color="textSecondary">
                Showing only your last five recent rescues.
              </Typography>
            ) : null
          }
        />
        <CardContent>
          <RescuerDashboardRecentRescues
            recentRescues={recentRescues}
            isLoading={isInFlight}
            onCellClick={onCellClick}
          />
        </CardContent>
      </DashboardTableCard>

      <DashboardTableCard isLoading={isInFlight}>
        <CardHeader
          title="Upcoming Rescues"
          subheader={
            upcomingRescues.length > 0 ? (
              <Typography variant="body2" color="textSecondary">
                Showing only next five upcoming rescues.{' '}
                <Link
                  component={RouterLink}
                  to={generatePath(routes.dashboardRescuer, { tab: RESCUER_DESKTOP_DASHBOARD_TAB_MY_RESCUES })}
                >
                  See all upcoming rescues.
                </Link>
              </Typography>
            ) : null
          }
        />
        <CardContent>
          <RescuerDashboardUpcomingRescues
            upcomingRescues={upcomingRescues}
            user={activeUser}
            isLoading={isInFlight}
            onCellClick={onCellClick}
          />
        </CardContent>
      </DashboardTableCard>

      {hasActiveEventsFeatureFlag && (
        <DashboardTableCard isLoading={eventsLoading}>
          <CardHeader title="My Events" />
          <CardContent>
            <MyFrusEvents isLoading={eventsLoading} onRowClick={onMyEventsRowClick} eventsList={eventsList.events} />
          </CardContent>
        </DashboardTableCard>
      )}

      {hasActiveSiteGoalsFeatureFlag && (
        <DashboardTableCard isLoading={siteGoalsLoading}>
          <CardHeader title="Site Goals" />
          <CardContent>
            <MySiteGoals goalsList={goalsList} />
          </CardContent>
        </DashboardTableCard>
      )}

      <DashboardTableCard isLoading={badgesLoading}>
        <CardHeader title="My Badges" />
        <CardContent>
          <MyBadges badgesList={badgesList} />
        </CardContent>
      </DashboardTableCard>

      <DashboardTableCard isLoading={environmentImpactLoading}>
        <CardHeader title="My Environment Impact" />
        <CardContent>
          <MyEnviroImpact isLoading={environmentImpactLoading} environmentImpact={environmentImpactEntities.all} />
        </CardContent>
      </DashboardTableCard>
    </Box>
  );
};

export default MyFrusDesktop;
