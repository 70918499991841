import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { formatPhoneNumber } from '../../../../helpers/formatters';

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
  },
}));

const ShowContactInfoDialog = ({ siteRole, handleClose, data = [], isOpen = false }) => {
  const classes = useStyles();
  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle className={classes.title}>{siteRole} contact information</DialogTitle>
      <DialogContent>
        <ul>
          {data.map((contact) => (
            <li key={contact.id}>
              <Typography key={contact.name} className={classes.text}>
                {contact.firstname} {contact.lastname} <Link href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</Link>{' '}
                <Link href={`mailto: ${contact.email}`}>{contact.email}</Link>
              </Typography>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button onClick={handleClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ShowContactInfoDialog;
