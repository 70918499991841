import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const IconBadge = withStyles(() => ({
  anchorOriginBottomLeftRectangle: {
    transform: 'scale(1) translate(20%, 50%)',
    fontSize: '0.65rem',
  },
}))(Badge);

const IconWithText = ({ text, icon }) => (
  <IconBadge
    color="default"
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    badgeContent={text}
  >
    {icon}
  </IconBadge>
);

export default IconWithText;
