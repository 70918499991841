import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import UserForm from '../components/UserForm';
import * as siteCoordinatorsActions from '../actions/siteCoordinators';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import routes from '../routes';

class UserAddView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: 'Coordinator',
    };
  }

  submitCallback = data => {
    const { createSiteCoordinator, site } = this.props;

    return createSiteCoordinator({
      ...data,
      site_id: site.id,
    });
  };

  render() {
    const { userType } = this.state;

    return (
      <>
        <Breadcrumbs>
          <Link color="inherit" to={routes.users}>
            Users
          </Link>

          <Typography color="textPrimary">{`Add New ${userType}`}</Typography>
        </Breadcrumbs>

        <UserForm
          onUserFormChange={({ is_admin }) => {
            this.setState({
              userType: is_admin.value ? 'Director' : 'Coordinator',
            });
          }}
          customErrorMessages={{
            ERR_DUPLICATED_ROLE: {
              message: `Selected user is already a Site ${userType}`,
              type: 'info',
            },
          }}
          showUserSelector
          submitButtonText={`Add ${userType}`}
          redirectPath={routes.users}
          submitCallback={this.submitCallback}
          showIsAdminCheckbox={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.Admin,
            Roles.NationalSiteDirector,
            Roles.SiteDirector,
          ])}
          showCommunicationCheckboxes={authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite(
            [Roles.Admin]
          )}
          showLocationFields={false}
        />
      </>
    );
  }
}

const mapStateToProps = ({ app: { site } }) => ({
  site,
});
const mapDispatchToProps = dispatch => ({
  createSiteCoordinator: data => dispatch(siteCoordinatorsActions.createSiteCoordinator(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserAddView)
);
