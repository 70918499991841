import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const defaultColumns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
    },
  },
  {
    name: 'count',
    label: 'Count',
    options: {
      filter: false,
    },
  },
];

class CompactWidgetTable extends Component {
  getMuiTheme() {
    return createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            padding: '4px 4px 4px 12px',
          },
        },
        MuiTableRow: {
          head: {
            height: '36px',
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: '4px 4px 4px 12px',
          },
        },
      },
    });
  }

  render() {
    const { data, columns } = this.props;

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <MUIDataTable
          title={false}
          data={data}
          columns={columns || defaultColumns}
          options={{
            responsive: 'simple',
            selectableRows: 'none',
            pagination: false,
            filter: false,
            sort: false,
            search: false,
            viewColumns: false,
            print: false,
            download: false,
            elevation: 0,
          }}
        />
      </MuiThemeProvider>
    );
  }
}

export default withRouter(CompactWidgetTable);
