import Bluebird from 'bluebird';
import { setLoggedInUser } from './auth';
import * as usersModel from '../models/users';
import * as usersApi from '../api/users';
import * as usersNotificationsPreferencesApi from '../api/usersNotificationsPreferences';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import {
  dismissEmailVerificationNotification,
  dismissPhoneVerificationNotification,
  showEmailVerificationNotification, showPhoneVerificationNotification,
} from './uiPersisted';
import { store } from '../store';
import * as sitesActions from './sites';

export const REQUEST_USERS = 'REQUEST_USERS';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const REQUEST_USER_NOTIFICATIONS_PREFERENCES = 'REQUEST_USER_NOTIFICATIONS_PREFERENCES';
export const RECEIVE_USER_NOTIFICATIONS_PREFERENCES = 'RECEIVE_USER_NOTIFICATIONS_PREFERENCES';
export const REQUEST_USER_IDENTITIES = 'REQUEST_USER_IDENTITIES';
export const RECEIVE_USER_IDENTITIES = 'RECEIVE_USER_IDENTITIES';
export const REQUEST_USERS_WITHOUT_ROLES = 'REQUEST_USERS_WITHOUT_ROLES';
export const RECEIVE_USERS_WITHOUT_ROLES = 'RECEIVE_USERS_WITHOUT_ROLES';
export const DELETE_USER_WITHOUT_ROLES = 'DELETE_USER_WITHOUT_ROLES';

export const requestUsers = () => ({
  type: REQUEST_USERS,
});

export const requestUser = () => ({
  type: REQUEST_USER,
});

export const requestUsersWithoutRoles = () => ({
  type: REQUEST_USERS_WITHOUT_ROLES,
});

export const requestUserNotificationsPreferences = () => ({
  type: REQUEST_USER_NOTIFICATIONS_PREFERENCES,
});

export const requestUserIdentities = () => ({
  type: REQUEST_USER_IDENTITIES,
});

export const receiveUsers = json => ({
  type: RECEIVE_USERS,
  users: json.data,
  receivedAt: Date.now(),
});

export const receiveUser = json => ({
  type: RECEIVE_USER,
  user: json.data,
});

export const receiveUsersWithoutRoles = json => ({
  type: RECEIVE_USERS_WITHOUT_ROLES,
  users: json.data,
  receivedAt: Date.now(),
});

export const receiveUserIdentities = (userId, json) => ({
  type: RECEIVE_USER_IDENTITIES,
  userId: userId,
  userIdentities: json.data,
  receivedAt: Date.now(),
});

export const updateUserData = (userId, userData) => ({
  type: UPDATE_USER,
  user: { id: userId, ...userData },
  receivedAt: Date.now(),
});

export const requestUserDelete = user => ({
  type: DELETE_USER,
  user,
  receivedAt: Date.now(),
});

export const requestUserWithoutRolesDelete = user => ({
  type: DELETE_USER_WITHOUT_ROLES,
  user,
  receivedAt: Date.now(),
});

export const receiveUserNotificationsPreferences = (userId, userNotificationsPreferences) => {
  userNotificationsPreferences.map(userNotificationPreference => (userNotificationPreference.user_id = userId));

  return {
    type: RECEIVE_USER_NOTIFICATIONS_PREFERENCES,
    userNotificationsPreferences,
  };
};

export const receiveUserAndReflectAllUserStates = (user) => {
  // @todo this should be dispatched by the fetchUser call immediately on receipt of
  // response payload
  // store.dispatch(receiveUser({ data: user }));

  // @todo this is way too complicated. even if a saga-like action is the best
  // answer here - it needs to simpler and more clear

  // @todo the ordinary fetchUser() action is calling THIS method! 
  authService.updateCurrentlyLoggedInAndImpersonatingUserIfNeeded(user);

  const { site } = store.getState().app;

  const userFromApi = {
    ...user,
    role_assignments: usersModel.transformRoles(user.role_assignments),
  };

  
  // update user in store if has rescuer role
  // what if they have NO role in the currently selected site?
  // this next bit is VERY strange, since this method is called by
  // fetchUser() without regard to role. what is this rescuer-specific logic for?
  // maybe just terrible method names?
  if (authService.hasRoleInCurrentlySelectedSite(userFromApi, Roles.Rescuer)) {
    return store.dispatch(
      sitesActions.receiveSiteRescuer({
        ...userFromApi,
        site_id: site?.id,
      })
    );
  } else {
    // this is clearly NOT the correct thing to do if they have no role in the
    // current site
    return store.dispatch(
      sitesActions.deleteSiteRescuer({
        ...userFromApi,
        site_id: site?.id,
      })
    );
  }
};

export const updateUser = (userId, data, site = null) => () => Bluebird
  .try(() => usersApi.updateUser(userId, data))
  .then(res => res.json())
  .then(json => {
    receiveUserAndReflectAllUserStates(json.data, site);

    return json.data;
  });

export const deleteUser = user => dispatch =>
  Bluebird.try(() => usersApi.deleteUser(user.id)).then(() => {
    dispatch(requestUserDelete(user));
  });

export const deleteUserWithoutRoles = user => dispatch =>
  Bluebird.try(() => usersApi.deleteUser(user.id)).then(() => {
    dispatch(requestUserWithoutRolesDelete(user));
  });

export const fetchUsers = () => dispatch => {
  dispatch(requestUsers());

  return usersApi
    .getUsers()
    .then(response => response.json())
    .then(json => dispatch(receiveUsers(json)));
};

export const fetchUsersWithoutRoles = () => dispatch => {
  dispatch(requestUsersWithoutRoles());

  return usersApi
    .getUsersWithoutRoles()
    .then(response => response.json())
    .then(json => dispatch(receiveUsersWithoutRoles(json)));
};

export const fetchUsersIfNeeded = () => dispatch => dispatch(fetchUsers());

export const fetchUser = userId => dispatch => {
  // @todo this action should carry the user id
  console.log(`fetchUser: ${userId}` );
  dispatch(requestUser());

  return Bluebird
    .try(() => usersApi.getUser(userId))
    .then(res => res.json())
    .then(json => {
      console.log('fetchUser: ', json );
      const data = json;
      dispatch( receiveUser({ data : json.user }) );
      // @todo this means fetchUser() can not be used to 'fetch a user, any user'
      // at minimum, this needs to be renamed
      receiveUserAndReflectAllUserStates(data.user);
    });
};

export const fetchUserIfNeeded = userId => dispatch => dispatch(fetchUser(userId));

// this is wasting an extra fetch of the user
// @todo remove this extra call and simply pass in the user
export const fetchAndSetLoggedInUser = userId => dispatch =>
  usersApi
    .getUser(userId)
    .then(response => response.json())
    .then(response => {
      if( response.result !== 'success' ) throw `Panic! - get user failed ${userId}`;
      return response.user;
    })
    .then(_user => {
      const user = usersModel.apiToStore(_user);

      if (
        authService.getUserRolesInCurrentlySelectedSite(user).length > 1 ||
        !authService.hasRoleInCurrentlySelectedSite(user, Roles.Rescuer)
      ) {
        dispatch(dismissEmailVerificationNotification());
        dispatch(dismissPhoneVerificationNotification());
      } else {
        dispatch(showEmailVerificationNotification());
        dispatch(showPhoneVerificationNotification());
      }

      return dispatch(setLoggedInUser(user));
    });

export const resendUserVerificationEmail = (userId, siteId = null) => () => usersApi.requestUserVerificationEmail(
  userId,
  siteId
);

export const resendUserInvitationEmail = (userId, siteId) => dispatch => Bluebird
  .try(() => usersApi.requestUserInvitationEmail(userId, siteId))
  .then(res => res.json())
  .then(json => dispatch(updateUserData(userId, usersModel.apiToStore(json.data))));

export const fetchUserNotificationsPreferences = userId => dispatch => {
  dispatch(requestUserNotificationsPreferences());

  return usersNotificationsPreferencesApi
    .getUserNotificationsPreferences(userId)
    .then(res => res.json())
    .then(json => dispatch(receiveUserNotificationsPreferences(userId, json.data)));
};

export const fetchUserNotificationsPreferencesIfNeeded = userId => dispatch =>
  dispatch(fetchUserNotificationsPreferences(userId));

export const fetchUserIdentities = userId => dispatch => {
  dispatch(requestUserIdentities());
  
  return Bluebird
    .try(() => usersApi.getUserIdentities(userId))
    .then(res => res.json())
    .then(json => dispatch(receiveUserIdentities(userId, json)));
};

export const fetchUserIdentitiesIfNeeded = userId => dispatch => dispatch(fetchUserIdentities(userId));
