import * as foodDonorsActions from '../actions/foodDonors';
import { objectFromArray, objectFromArrayWithDup } from '../helpers';
import * as foodDonorsModel from '../models/foodDonors';
import omit from 'lodash/omit';

export const FOOD_DONORS_INITIAL_STATE = {
  byId: {},
  byUmbrellaFoodDonorId: {},
  allIds: [],
  modifiedIds: [],
  modifiedById: {},
  inflight: false,
  lastUpdated: null,
  staff: {
    byId: {},
    allIds: [],
    byFoodDonorId: {},
    inflight: false,
    lastUpdated: null,
  },
  pickupLocations: {
    byId: {},
    allIds: [],
    byFoodDonorId: {},
    inflight: false,
    lastUpdated: null,
  },
  unassigned: {
    byId: {},
    allIds: [],
    inflight: false,
    lastUpdated: null,
  },
};

const foodDonorsReducer = (state = FOOD_DONORS_INITIAL_STATE, action) => {
  switch (action.type) {
    case foodDonorsActions.REQUEST_FOOD_DONORS:
    case foodDonorsActions.REQUEST_FOOD_DONOR:
      return {
        ...state,
        inflight: true,
      };
    case foodDonorsActions.RECEIVE_FOOD_DONOR:
      const { byId, allIds } = addFoodDonor(state.byId, state.allIds, action.foodDonor);

      return {
        ...state,
        inflight: false,
        byId: byId,
        allIds: allIds,
        lastUpdated: action.receivedAt,
      };
    case foodDonorsActions.RECEIVE_FOOD_DONORS:
      const { byId: receivedFoodDonorsIds, allIds: receivedFoodDonorsAllIds } = action.foodDonors.reduce(
        (acc, foodDonor) => {
          acc.byId[foodDonor.id] = foodDonor;
          acc.allIds.push(foodDonor.id);
          return acc;
        },
        { byId: {}, allIds: [] }
      );

      return {
        ...state,
        inflight: false,
        byId: receivedFoodDonorsIds,
        byUmbrellaFoodDonorId: objectFromArrayWithDup(action.foodDonors, foodDonor => foodDonor.donor_id),
        allIds: receivedFoodDonorsAllIds,
        lastUpdated: action.receivedAt,
      };
    case foodDonorsActions.REQUEST_FOOD_DONOR_STAFF:
      return {
        ...state,
        staff: {
          ...state.staff,
          inflight: true,
        },
      };
    case foodDonorsActions.RECEIVE_FOOD_DONOR_STAFF:
      return {
        ...state,
        staff: {
          inflight: false,
          byId: objectFromArray(action.foodDonorStaff, staff => staff.id),
          allIds: action.foodDonorStaff.map(staff => staff.id),
          byFoodDonorId: objectFromArrayWithDup(action.foodDonorStaff, () => action.foodDonorId),
          lastUpdated: action.receivedAt,
        },
      };
    case foodDonorsActions.REQUEST_FOOD_DONOR_PICKUP_LOCATIONS:
      return {
        ...state,
        pickupLocations: {
          ...state.pickupLocations,
          inflight: true,
        },
      };
    case foodDonorsActions.RECEIVE_FOOD_DONOR_PICKUP_LOCATIONS:
      return {
        ...state,
        pickupLocations: {
          inflight: false,
          byId: objectFromArray(action.foodDonorPickupLocations, foodDonorPickupLocation => foodDonorPickupLocation.id),
          allIds: action.foodDonorPickupLocations.map(foodDonorPickupLocation => foodDonorPickupLocation.id),
          byFoodDonorId: objectFromArrayWithDup(
            action.foodDonorPickupLocations,
            foodDonorPickupLocation => foodDonorPickupLocation.donor_location_id
          ),
          lastUpdated: action.receivedAt,
        },
      };
    case foodDonorsActions.REQUEST_UNASSIGNED_FOOD_DONORS:
      return {
        ...state,
        unassigned: {
          ...state.unassigned,
          inflight: true,
        },
      };
    case foodDonorsActions.RECEIVE_UNASSIGNED_FOOD_DONORS:
      const unassignedFoodDonors = action.foodDonors.map(foodDonor =>
        foodDonorsModel.mapUnassignedFoodDonorToStore(foodDonor)
      );

      return {
        ...state,
        unassigned: {
          ...state.unassigned,
          inflight: false,
          allIds: unassignedFoodDonors.map(d => d.id),
          byId: objectFromArray(unassignedFoodDonors, d => d.id),
          lastUpdated: action.receivedAt,
        },
      };
    case foodDonorsActions.DELETE_UNASSIGNED_FOOD_DONOR:
      return {
        ...state,
        unassigned: {
          ...state.unassigned,
          byId: omit(state.unassigned.byId, action.foodDonor),
          allIds: state.unassigned.allIds.filter(id => id !== action.foodDonor.id),
        },
      };
    default:
      return state;
  }
};

const addFoodDonor = (_byId, _allIds, foodDonor) => {
  const byId = Object.assign({}, _byId, { [foodDonor.id]: foodDonor });
  const allIds = _allIds.concat(foodDonor.id);

  return {
    byId: byId,
    allIds: allIds,
  };
};

export default foodDonorsReducer;
