import React from 'react';
import { generatePath, Route, Switch } from 'react-router-dom';
import routes from '../../../routes';
import { BottomNavTabs, TabLink } from '../../../components/Common/BottomNavTabs';
import ReceiversContactList from './ContactLists/ReceiversContactList';
import RescuersContactList from './ContactLists/RescuersContactList';
import DonorsContactList from './ContactLists/DonorsContactList';

const ContactListMobileView = ({ site, paths }) => {
  return (
    <>
      <Switch>
        <Route
          path={[paths[0], generatePath(routes.contactList)]}
          exact
          render={() => <ReceiversContactList siteId={site.id} />}
        />
        <Route path={paths[1]} exact render={() => <RescuersContactList siteId={site.id} />} />
        <Route path={paths[2]} exact render={() => <DonorsContactList siteId={site.id} />} />
      </Switch>

      <BottomNavTabs>
        <TabLink label="Receivers" to={paths[0]} value={paths[0]} />
        <TabLink label="Rescuers" to={paths[1]} value={paths[1]} />
        <TabLink label="Donors" to={paths[2]} value={paths[2]} />
      </BottomNavTabs>
    </>
  );
};

export default ContactListMobileView;
