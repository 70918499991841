import React, { Suspense, lazy } from 'react';
import { generatePath, Route, Switch } from 'react-router-dom';
import { Box, Grid, Container } from '@material-ui/core';
import routes from '../../routes';
import { useFormStyles } from './completeRegistrationStyles';
import loginLogo from '../../assets/images/login-logo.svg';

const FoodDonorCompleteRegistrationView = lazy(() => import('./FoodDonorCompleteRegistrationView'));
const ReceivingAgencyCompleteRegistrationView = lazy(() => import('./ReceivingAgencyCompleteRegistrationView'));
const CompleteRegistrationAccountChoose = lazy(() => import('./CompleteRegistrationAccountChoose'));

const foodDonors = generatePath(routes.completeRegistrationNew, { accountType: 'food-donor' });
const receivingAgencies = generatePath(routes.completeRegistrationNew, { accountType: 'receiving-agency' });

const Wrapper = ({ children }) => {
  const authStyles = useFormStyles();
  return (
    <Box className={authStyles.pageBackground}>
      <Container maxWidth="md">
        <Grid container spacing={2} className={authStyles.pageContainer} data-testid="welcome-index-page">
          <Grid item xs className={authStyles.logoWrapper}>
            <img src={loginLogo} alt="logo" className={authStyles.logo} />
          </Grid>
          <Grid item xs className={authStyles.loginFormWrapper}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const CompleteRegistrationView = () => (
  <Suspense fallback={null}>
    <Wrapper>
      <Switch>
        <Route exact path={foodDonors} component={FoodDonorCompleteRegistrationView} />
        <Route exact path={receivingAgencies} component={ReceivingAgencyCompleteRegistrationView} />
        <Route component={CompleteRegistrationAccountChoose} />
      </Switch>
    </Wrapper>
  </Suspense>
);

export default CompleteRegistrationView;
