import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Breadcrumbs, Typography } from '@material-ui/core';
import UmbrellaFoodDonorsTable from '../components/UmbrellaFoodDonorsTable';
import * as umbrellaFoodDonorsActions from '../actions/umbrellaFoodDonors';
import { sortAlphabetically } from '../helpers/sorters';

export const umbrellaFoodDonorsTableId = '#donors/donors-list';

const UmbrellaFoodDonorsListView = () => {
  const dispatch = useDispatch();
  const umbrellaFoodDonors = useSelector(state => state.entities.umbrellaFoodDonors);
  const umbrellaFoodDonorsList = umbrellaFoodDonors.allIds
    .map((umbrellaFoodDonorId) => umbrellaFoodDonors.byId[umbrellaFoodDonorId])
    .sort((a, b) => sortAlphabetically('asc', b.name, a.name));

  useEffect(() => {
    dispatch(umbrellaFoodDonorsActions.fetchUmbrellaFoodDonors());
  }, [dispatch]);

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Typography color="textPrimary">
          Umbrella Food Donors
        </Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12}>
          <UmbrellaFoodDonorsTable
            tableId={umbrellaFoodDonorsTableId}
            isLoading={umbrellaFoodDonors.inflight}
            umbrellaFoodDonorsList={umbrellaFoodDonorsList}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UmbrellaFoodDonorsListView;
