import queryString from 'query-string';
import moment from 'moment';
import { apiGET } from './api';

export const getMetrics = (priorDateRange, dateRange, sitesIds = []) => {
  let query = '';

  if (priorDateRange && dateRange) {
    query = queryString.stringify({
      priorDateRange: `${priorDateRange.startDate}/${priorDateRange.endDate}`,
      dateRange: `${dateRange.startDate}/${dateRange.endDate}`,
      sites_ids: sitesIds,
      today: moment().format('YYYY-MM-DD'),
    });
  }

  if (query.length) {
    query = `?${query}`;
  }

  return apiGET(`/metrics${query}`);
};
