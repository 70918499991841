import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core';
import {
  getRescuePickupLocationFullName,
  showReleaseRescueButton,
  showUnAdoptRescueButton,
  showUnClaimRescueButtons,
} from '../../helpers/RescuesHelper';
import useRescuerRescueActions from '../../hooks/useRescuerRescueActions';
import ConfirmationDialog from '../ConfirmationDialog';
import { formatTime } from '../../helpers/formatters';
import useActiveUser from '../../hooks/useActiveUser';

const useStyles = makeStyles(() => ({
  customActionsButtons: {
    margin: 6,
    fontSize: 11,
    padding: 6,
  },
}));

const ReleaseRescueDesktopButtons = ({ rescue }) => {
  const { releaseRescue, rescueUnclaim, rescueUnadopt } = useRescuerRescueActions();
  const rescuer = useActiveUser();
  const classes = useStyles();

  const handleOnUnClaimClick = () => rescueUnclaim(rescue);

  const handleUnadopt = () =>
    confirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          variant: 'outlined',
          label: 'Never mind',
          color: 'primary',
          size: 'small',
          onClick: () => {},
        },
        {
          variant: 'contained',
          label: 'Unadopt',
          color: 'primary',
          size: 'small',
          onClick: () => rescueUnadopt(rescue),
        },
      ],
      message: 'Unadopting this rescue will release all rescues from this adoption. Do you want to continue?',
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
        />
      ),
    });

  const handleReleaseClick = () =>
    confirmAlert({
      title: 'Are you sure?',
      buttons: [
        {
          variant: 'outlined',
          label: 'Never mind',
          color: 'primary',
          size: 'small',
          onClick: () => {},
        },
        {
          variant: 'contained',
          label: 'Release',
          color: 'primary',
          size: 'small',
          onClick: () => releaseRescue(rescue),
        },
      ],
      message: 'Releasing this rescue may result in this food going to waste, but we understand that life happens!',
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
        />
      ),
    });

  return (
    <>
      {showUnClaimRescueButtons(rescue, rescuer) && (
        <Tooltip
          placement="top"
          key="Release Rescue"
          title="Release Rescue"
          className={classes.customActionsButtons}
        >
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleOnUnClaimClick}
          >
            Release Rescue
          </Button>
        </Tooltip>
      )}

      {showReleaseRescueButton(rescue, rescuer) && (
        <Tooltip
          placement="top"
          key="Release Rescue"
          title="Release Rescue"
          className={classes.customActionsButtons}
        >
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleReleaseClick}
          >
            Release Rescue
          </Button>
        </Tooltip>
      )}

      {showUnAdoptRescueButton(rescue, rescuer) && (
        <Tooltip
          placement="top"
          key="Unadopt"
          title="Unadopt"
          className={classes.customActionsButtons}
        >
          <Button type="button" variant="contained" color="primary" size="small" onClick={handleUnadopt}>
            Unadopt
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default ReleaseRescueDesktopButtons;
