import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Bluebird from 'bluebird';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert';
import {
  ExpandMore as ExpandMoreIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  RotateLeft as RotateLeftIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Description from '../Components/Description';
import RequestToDonateSchedule from '../Components/RequestToDonateSchedule';
import { fetchRescueSizesIfNeeded } from '../../../../actions/rescueSizes';
import {
  fetchSiteDonorsLocationsIfNeeded,
  fetchSitePickupLocationsIfNeeded,
  fetchSiteReceiversIfNeeded,
  fetchSiteRescuersIfNeeded,
} from '../../../../actions/sites';
import useActiveSite from '../../../../hooks/useActiveSite';
import { diffDonation, DONATION_TYPE_REQUEST, setDonationDiffInflight } from '../../../../actions/donationNew';
import DonationContext from '../../../../context/DonationContext/DonationContext';
import useDonationManageActions from '../../../../hooks/useDonationManageActions';
import Pickups from '../Components/Pickups';
import Calendar from '../Components/Calendar';
import { donationManageActions, getPickupSpecBaseDay } from '../../../../helpers/donations';
import OverlayLoader from '../../../../components/OverlayLoader';
import useNotificationService from '../../../../hooks/useNotificationService';
import { generatePath, Link } from 'react-router-dom';
import routes from '../../../../routes';
import {
  FREQUENCY_EVERY_OTHER_WEEK,
  FREQUENCY_MONTHLY,
  FREQUENCY_ONCE,
  FREQUENCY_WEEKLY,
  FREQUENCY_WEEKLY_EVERY_TWO_WEEKS,
} from '../../../../models/donationsNew';
import { fetchSystemSettingsIfNeeded } from '../../../../actions/systemSettings';
import errorMessages from '../../../../assets/errorMessages';
import { validateDonation } from '../donationValidator';
import useActiveUser from '../../../../hooks/useActiveUser';
import * as authService from '../../../../services/auth';
import { Roles } from '../../../../models/roles';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import {
  deleteRequestDonation,
  diffCreateRequestDonation,
  updateRequestDonation,
} from '../../../../actions/requestDonations';
import { getDayIndexByShortName } from '../../../../models/donations';
import * as errorsHelper from "../../../../helpers/errors";

const useStyles = makeStyles(({ typography, palette, breakpoints, spacing }) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    display: 'flex',
    columnGap: spacing(1),
    alignItems: 'baseline',
  },
  secondaryHeading: {
    fontSize: typography.pxToRem(15),
    color: palette.text.secondary,
  },
  buttonsFlex: {
    marginTop: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  buttonsRow: {
    display: 'flex',
    gridColumnGap: '8px',
    [breakpoints.only('xs')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
    [breakpoints.only('md')]: {
      marginTop: spacing(1),
      flexGrow: 1,
      flexShrink: 0,
    },
  },
}));

const AGENDA_DESCRIPTION = 'Description';
const AGENDA_SCHEDULE = 'Schedule';

const hasDescriptionValidationErrors = errors => ['lbs'].some(field => errors.includes(field));

const hasScheduleValidationErrors = errors => errors.length > 0 && !hasDescriptionValidationErrors(errors);

const DonationRequestEdit = ({ isMobileView, history, match }) => {
  const { params } = match;
  const editDonationId = params.donationId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const activeUser = useActiveUser();
  const [firstFoodDonorChoice, setFirstFoodDonorChoice] = useState();
  const [initialDonationStateReady, setIinitialDonationStateReady] = React.useState(false);
  const [expanded, setExpanded] = React.useState(AGENDA_DESCRIPTION);
  const rescueSizesInflight = useSelector(state => state.entities.rescueSizes.inflight);
  const pickupLocations = useSelector(state => state.entities.sites.pickupLocations);
  const foodDonors = useSelector(state => state.entities.sites.donorsLocations);
  const donationData = useSelector(state => state.ui.donation_new.draft);
  const currentDonationState = useSelector(state => state.ui.donation_new.currentState);
  const futureDonationState = useSelector(state => state.ui.donation_new.futureState);
  const diffInflight = useSelector(state => state.ui.donation_new.diffInflight);
  const donationFormErrors = useSelector(state => state.ui.donation_new.errors);
  const { addErrorNotification, addSuccessNotification } = useNotificationService();

  const hasFoodDonorDefined = donationData && !!donationData.location;

  const {
    clearDonation,
    undoLastAction,
    redoLastAction,
    resetDonation,

    setError,
    removeError,

    setFoodDonor,
    setPickupLocation,
    setDescription,
    setLBSValue,
    setAddFoodTypes,
    setRemoveFoodTypes,
    setFoodTypeOther,
    setRescueSize,
    setFoodSize,
    setAddDayOfWeek,
    setRemoveDayOfWeek,

    setDonorNotes,

    setAddDayOfMonth,
    setDayOfMonthDay,
    setDayOfMonthOrdinal,
    setRemoveDayOfMonth,

    setStartDate,
    setEndDate,
    setPauseDate,
    setResumeDate,
    setFrequency,
    setAppendPickup,
    setRemovePickup,
    setPickupReceiver,
    setPickupAdopter,
    setPickupRescuerNotes,
    setPickupBegin,
    setPickupEnd,
    setAllDaysTheSame,
    clearAllDaysTheSame,
    setAllPickupsTheSame,
    clearAllPickupsTheSame,

    setRescuePickupBegin,
    setRescuePickupEnd,
    setRescueRescuerNotes,
    setRescueCanceller,
    setRescueRescuer,
  } = useDonationManageActions();

  const createDraftDonation = async () => {
    try {
      const { donation } = await dispatch(diffCreateRequestDonation(editDonationId));
      setFirstFoodDonorChoice(donation.location_id);

      await setRescueSize({
        id: donation.rescue_size_id,
        name: 'foo',
      });

      await setFoodSize({
        id: donation.donation_size_id,
        name: 'foo',
      });

      await donation.food_types.map(setAddFoodTypes);
      await setFoodTypeOther(donation.food_type_other);
      await setDescription(donation.slug);
      donation.lbs && (await setLBSValue(donation.lbs));

      if (donation.frequency === FREQUENCY_WEEKLY && donation.frequency_every === FREQUENCY_WEEKLY_EVERY_TWO_WEEKS) {
        await setFrequency(FREQUENCY_EVERY_OTHER_WEEK);
      } else {
        await setFrequency(donation.frequency);
      }

      if (donation.all_pickups_are_the_same) {
        await setAllPickupsTheSame();
      } else {
        await clearAllPickupsTheSame();
      }

      if (donation.all_days_are_the_same) {
        await setAllDaysTheSame();
      } else {
        await clearAllDaysTheSame();
      }

      switch (donation.frequency) {
        case FREQUENCY_ONCE:
          break;
        case FREQUENCY_WEEKLY:
        case FREQUENCY_EVERY_OTHER_WEEK:
          // clear all selected days first, eg monday is pre-selected by default
          [0, 1, 2, 3, 4, 5, 6].forEach(setRemoveDayOfWeek);
          // now select only days selected while creating donation request
          Object.entries(JSON.parse(donation.days_of_week)).forEach(([dayName, dayValue]) => {
            if (dayValue.checked) {
              const dayIndex = getDayIndexByShortName(dayName);
              setAddDayOfWeek(dayIndex);
            }
          });
          break;

        case FREQUENCY_MONTHLY:
          // clear all selected days first, eg monday is pre-selected by default
          [0, 1, 2, 3, 4, 5, 6].forEach(dayIndex => {
            [1, 2, 3, 4, -1].forEach(ordinal => {
              setRemoveDayOfMonth({
                day: dayIndex,
                ordinal: ordinal,
              });
            });
          });

          Object.entries(JSON.parse(donation.days_of_month)).forEach(([day, value]) => {
            const dayIndex = getDayIndexByShortName(day);
            value.forEach(dayEntry =>
              setAddDayOfMonth({
                day: dayIndex,
                ordinal: dayEntry.ordinal,
              })
            );
          });
          break;
      }

      await setStartDate({
        startDate: donation.start_date ? donation.start_date.value : null,
      });
      if (donation.end_date) {
        await setEndDate(donation.end_date.value);
      }

      if (donation.pause_date) {
        await setPauseDate(donation.pause_date.value);
      }

      if (donation.resume_date) {
        await setResumeDate(donation.resume_date.value);
      }

      if (donation.food_donor_notes) {
        await setDonorNotes(donation.food_donor_notes);
      }

      if (donation.all_days_are_the_same) {
        await setPickupBegin({
          begin: donation.pickup_specs[0].pickup_begin.value,
        });

        await setPickupEnd({
          end: donation.pickup_specs[0].pickup_end.value,
        });

        await setPickupRescuerNotes({
          rescuer_notes: donation.pickup_specs[0].rescuer_notes,
        });
      } else {
        donation.pickup_specs.forEach(pickup => {
          setPickupBegin({
            ...getPickupSpecBaseDay(pickup, donation.frequency),
            begin: pickup.pickup_begin.value,
          });
          setPickupEnd({
            ...getPickupSpecBaseDay(pickup, donation.frequency),
            end: pickup.pickup_end.value,
          });
          setPickupRescuerNotes({
            ...getPickupSpecBaseDay(pickup, donation.frequency),
            rescuer_notes: pickup.rescuer_notes,
          });
        });
      }

      await dispatch(diffDonation(null, donation.location_id));
      setIinitialDonationStateReady(true);
    } catch (error) {
      if (error.statusCode === 404) {
        addErrorNotification(error, 'Donation not found');
        return history.push(generatePath(isMobileView ? routes.index : routes.donations));
      }

      addErrorNotification(error, error.message || 'Unknown error during modifying donation');
      dispatch(setDonationDiffInflight(false));
      undoLastAction();
    }
  };

  useEffect(() => {
    createDraftDonation();
    dispatch(fetchSiteReceiversIfNeeded(activeSite.id));
    dispatch(fetchSiteDonorsLocationsIfNeeded(activeSite.id));
    dispatch(fetchSitePickupLocationsIfNeeded(activeSite.id));
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSiteRescuersIfNeeded(activeSite.id));
    dispatch(fetchSystemSettingsIfNeeded()); // used in LBSSelector

    return () => clearDonation();
  }, [dispatch]);

  const handleDeleteDonation = () => {
    let fallbackUrl;
    if (isMobileView) {
      fallbackUrl = generatePath(routes.index);
    } else {
      fallbackUrl = generatePath(routes.donations);
    }

    confirmAlert({
      title: 'Are you sure you want to delete this request to donate?',
      message: 'Please confirm or cancel.',
      buttons: [
        {
          label: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          startIcon: <ClearIcon />,
        },
        {
          label: 'Yes, delete',
          color: 'primary',
          startIcon: <DeleteIcon />,
          onClick: async () => {
            try {
              await dispatch(deleteRequestDonation(editDonationId));
              addSuccessNotification('Donation request has been deleted successfully.');
              history.push(fallbackUrl);
            } catch (err) {
              errorsHelper.handleError(err, err.message || 'Donation delete failed');
            }
          },
        },
      ],
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog buttons={buttons} closeDialog={onClose} title={title} message={message} />
      ),
    });
  };

  const onSubmit = async () => {
    try {
      const validation = validateDonation(donationData, {
        lbsRecording: false,
        validate24HoursAheadOfTimeWindow: true,
      });

      if (!validation.isValid) {
        return Object.keys(validation.errors).forEach(error => setError(error, validation.errors[error]));
      }
      const donation = await dispatch(updateRequestDonation(editDonationId, donationData));
      addSuccessNotification(
        'Thank you for updating your request to donate! You will be notified when your request is confirmed.'
      );
      history.push(generatePath(routes.donation_edit_request, { donationId: donation.id }));
    } catch (e) {
      console.debug('Unable to update donation', e);
      return addErrorNotification(e, `Unable to update donation. Reason: ${e.message}`);
    } finally {
      dispatch(setDonationDiffInflight(false));
    }
  };

  const triggerFormChange = () => {
    if (firstFoodDonorChoice) {
      return Bluebird.try(() => dispatch(diffDonation(null, firstFoodDonorChoice))).catch(e => {
        addErrorNotification(e, e.message || 'Unknown error during modifying donation');
        dispatch(setDonationDiffInflight(false));
        undoLastAction();
      });
    }
  };

  const handleFieldChange = (actionName, fieldValue) => {
    switch (actionName) {
      case donationManageActions.set_pickup_location:
        setPickupLocation(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_description:
        setDescription(fieldValue);
        break;

      case donationManageActions.set_lbs_value:
        setLBSValue(fieldValue);
        if (fieldValue === '') {
          setError('lbs', errorMessages.REQUIRED.message);
        } else {
          removeError('lbs');
        }
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_size:
        setRescueSize(fieldValue);
        break;

      case donationManageActions.set_food_size:
        setFoodSize(fieldValue);
        break;

      case donationManageActions.add_food_type:
        setAddFoodTypes(fieldValue);
        break;

      case donationManageActions.remove_food_type:
        setRemoveFoodTypes(fieldValue);
        break;

      case donationManageActions.set_food_type_other:
        setFoodTypeOther(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_frequency:
        setFrequency(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_week:
        setAddDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_week:
        setRemoveDayOfWeek(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.add_day_of_month:
        setAddDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_day:
        setDayOfMonthDay(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_day_of_month_ordinal:
        setDayOfMonthOrdinal(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_day_of_month:
        setRemoveDayOfMonth(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_start_date:
        setStartDate({
          startDate: fieldValue,
        });
        triggerFormChange();
        break;

      case donationManageActions.set_end_date:
        setEndDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pause_date:
        setPauseDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_resume_date:
        setResumeDate(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_all_days_are_the_same:
        setAllDaysTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_days_are_the_same:
        clearAllDaysTheSame();
        triggerFormChange();

        break;
      case donationManageActions.set_all_pickups_are_the_same:
        setAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.clear_all_pickups_are_the_same:
        clearAllPickupsTheSame();
        triggerFormChange();
        break;

      case donationManageActions.add_pickup:
        setAppendPickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.remove_pickup:
        setRemovePickup(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_receiver:
        setPickupReceiver(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_adopter:
        setPickupAdopter(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_rescuer_notes:
        setPickupRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_begin:
        setPickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_pickup_end:
        setPickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_begin:
        setRescuePickupBegin(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_pickup_end:
        setRescuePickupEnd(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer_notes:
        setRescueRescuerNotes(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_canceller:
        setRescueCanceller(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_rescue_rescuer:
        setRescueRescuer(fieldValue);
        triggerFormChange();
        break;

      case donationManageActions.set_donor_notes:
        setDonorNotes(fieldValue);
        triggerFormChange();
        break;

      default:
        console.error(`---->>>> #Unknown donation field "${actionName}" with given value "${fieldValue}"`);
    }
  };

  useEffect(() => {
    const foodDonorsLength = Object.keys(foodDonors.byId).length;
    if (foodDonorsLength && !firstFoodDonorChoice) {
      const userRoles = authService.getUserRolesInCurrentlySelectedSite(activeUser);
      const rolesWithAccess = userRoles.filter(
        role =>
          [Roles.DonorLocationAdmin, Roles.DonorAdmin, Roles.DonorLocationStaff, Roles.DonorStaff].indexOf(
            role.role_name
          ) > -1
      );

      if (rolesWithAccess.length) {
        setFirstFoodDonorChoice(rolesWithAccess[0].donor_location_id);
      } else {
        history.push(routes.index);
      }
    }
  }, [activeUser.id, foodDonors.inflight, Object.keys(foodDonors.byId).length]);

  useEffect(() => {
    if (firstFoodDonorChoice) {
      handleFieldChange(donationManageActions.set_food_donor, foodDonors.byId[firstFoodDonorChoice]);
    }
  }, [firstFoodDonorChoice]);

  const handleAccordionChange = panel => (event, isExpanded) => {
    if (!hasFoodDonorDefined) {
      return setExpanded(AGENDA_DESCRIPTION);
    }
    setExpanded(isExpanded ? panel : expanded === AGENDA_DESCRIPTION ? AGENDA_SCHEDULE : AGENDA_DESCRIPTION);
  };

  if (
    !firstFoodDonorChoice ||
    donationData === null ||
    !initialDonationStateReady ||
    rescueSizesInflight ||
    foodDonors.inflight ||
    pickupLocations.inflight
  ) {
    return 'loading...';
  }

  const renderDonationButtons = () => (
    <Box className={classes.buttonsFlex}>
      <Box className={classes.buttonsRow}>
        <Button
          disabled={currentDonationState.length === 0}
          color="primary"
          variant="contained"
          type="button"
          onClick={onSubmit}
          startIcon={<SaveIcon />}
          data-testid="update-donation-request"
        >
          Update Donation Request
        </Button>

        <Button
          fullWidth
          color="secondary"
          variant="contained"
          type="button"
          startIcon={<DeleteIcon />}
          onClick={handleDeleteDonation}
        >
          Delete donation
        </Button>
      </Box>
      <Box className={classes.buttonsRow}>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          startIcon={<RotateLeftIcon />}
          disabled={currentDonationState.length === 0}
          onClick={() => {
            resetDonation();
            triggerFormChange();
          }}
        >
          Reset
        </Button>

        <Button
          startIcon={<UndoIcon />}
          color="secondary"
          variant="contained"
          disabled={currentDonationState.length === 0}
          onClick={() => {
            undoLastAction();
            triggerFormChange();
          }}
        >
          Undo
        </Button>

        <Button
          startIcon={<RedoIcon />}
          color="secondary"
          variant="contained"
          disabled={futureDonationState.length === 0}
          onClick={() => {
            redoLastAction();
            triggerFormChange();
          }}
        >
          Redo
        </Button>
      </Box>
    </Box>
  );

  return (
    <DonationContext.Provider
      value={{
        onFieldChange: handleFieldChange,
        isLoading: diffInflight,
        hasFoodDonorDefined: hasFoodDonorDefined,
        showFoodDonorSelector: false,
        isMobileView: isMobileView,
        isNew: true,
        errors: donationFormErrors,
        donationType: DONATION_TYPE_REQUEST,
      }}
    >
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        <Typography color="textPrimary">Request to Donate</Typography>
      </Breadcrumbs>

      <Grid container spacing={3}>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} md={6} lg={8}>
          <Grid item xs={12}>
            <Accordion
              elevation={25}
              expanded={expanded === AGENDA_DESCRIPTION}
              onChange={handleAccordionChange(AGENDA_DESCRIPTION)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-description"
                id="description-header"
              >
                <Typography className={classes.heading}>
                  Description
                  {hasDescriptionValidationErrors(Object.keys(donationFormErrors)) && (
                    <Typography component="span" color="error">
                      Missing info
                    </Typography>
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Description />
              </AccordionDetails>
            </Accordion>

            <Accordion
              disabled={!hasFoodDonorDefined}
              elevation={25}
              expanded={expanded === AGENDA_SCHEDULE}
              onChange={handleAccordionChange(AGENDA_SCHEDULE)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-schedule" id="schedule-header">
                <Typography className={classes.heading}>
                  Schedule
                  {hasScheduleValidationErrors(Object.keys(donationFormErrors)) && (
                    <Typography component="span" color="error">
                      Validation error
                    </Typography>
                  )}
                </Typography>

                {!hasFoodDonorDefined && (
                  <Typography className={classes.secondaryHeading}>Please select Food Donor first!</Typography>
                )}
              </AccordionSummary>

              <AccordionDetails>
                <OverlayLoader isLoading={diffInflight} wrapperStyles={{ width: '100%' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <RequestToDonateSchedule />
                    </Grid>

                    <Grid item xs={12}>
                      <Pickups />
                    </Grid>
                  </Grid>
                </OverlayLoader>
              </AccordionDetails>
            </Accordion>

            {!isMobileView && renderDonationButtons()}
          </Grid>
        </Grid>

        <Grid item xs>
          <Card square elevation={25}>
            <CardContent>
              <Calendar />
            </CardContent>
          </Card>
        </Grid>

        {isMobileView && (
          <Grid item xs>
            {renderDonationButtons()}
          </Grid>
        )}
      </Grid>
    </DonationContext.Provider>
  );
};

export default DonationRequestEdit;
