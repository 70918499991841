import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  FormControl,
  MenuItem,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { TextFieldWrapper as TextField } from '../Form/MuiFormWrapper';
import { Colors } from '../../assets/theme/Colors';
import get from 'lodash/get';
import { DateRangePicker } from 'react-date-range';
import { formatDate } from '../../helpers/formatters';

export const dateRangeOptionsList = {
  today: {
    value: 'today',
    label: 'Today',
    range: {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
    },
  },
  lastWeek: {
    value: 'last-week',
    label: 'Last week',
    range: {
      startDate: moment()
        .subtract(1, 'weeks')
        .startOf('week'),
      endDate: moment()
        .subtract(1, 'weeks')
        .endOf('week'),
    },
  },
  last7Days: {
    value: 'last-7-days',
    label: 'Last 7 days',
    range: {
      startDate: moment().subtract(7, 'days'),
      endDate: moment().endOf('day'),
    },
  },
  last30Days: {
    value: 'last-30-days',
    label: 'Last 30 days',
    range: {
      startDate: moment().subtract(29, 'days'),
      endDate: moment().endOf('day'),
    },
  },
  last90Days: {
    value: 'last-90-days',
    label: 'Last 90 days',
    range: {
      startDate: moment().subtract(90, 'days'),
      endDate: moment().endOf('day'),
    },
  },
  thisWeek: {
    value: 'this-week',
    label: 'This week',
    range: {
      startDate: moment().startOf('week'),
      endDate: moment().endOf('week'),
    },
  },
  thisMonth: {
    value: 'this-month',
    label: 'This month',
    range: {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    },
  },
  thisQuarter: {
    value: 'this-quarter',
    label: 'This quarter',
    range: {
      startDate: moment()
        .quarter(moment().quarter())
        .startOf('quarter'),
      endDate: moment()
        .quarter(moment().quarter())
        .endOf('quarter'),
    },
  },
  thisYear: {
    value: 'this-year',
    label: 'This year',
    range: {
      startDate: moment().startOf('year'),
      endDate: moment().endOf('year'),
    },
  },
  thisMTD: {
    value: 'this-mtd',
    label: 'Month to date',
    range: {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('day'),
    },
  },
  thisQTD: {
    value: 'this-qtd',
    label: 'Quarter to date',
    range: {
      startDate: moment()
        .quarter(moment().quarter())
        .startOf('quarter'),
      endDate: moment().endOf('day'),
    },
  },
  thisYTD: {
    value: 'this-ytd',
    label: 'Year to date',
    range: {
      startDate: moment().startOf('year'),
      endDate: moment().endOf('day'),
    },
  },
  allHistory: {
    value: 'all-history',
    label: 'All history',
    range: {
      startDate: moment('2020-01-01'),
      endDate: moment().endOf('day'),
    },
  },
};

const defaultDateRangeOptions = {
  lastWeek: dateRangeOptionsList.lastWeek,
  last7Days: dateRangeOptionsList.last7Days,
  last90Days: dateRangeOptionsList.last90Days,
  today: dateRangeOptionsList.today,
  thisWeek: dateRangeOptionsList.thisWeek,
  thisMonth: dateRangeOptionsList.thisMonth,
  thisQuarter: dateRangeOptionsList.thisQuarter,
  thisQTD: dateRangeOptionsList.thisQTD,
  thisYear: dateRangeOptionsList.thisYear,
  thisYTD: dateRangeOptionsList.thisYTD,
};

export const defaultGranularityOptions = {
  weekly: { value: 'weekly', label: 'Week' },
  monthly: { value: 'monthly', label: 'Month' },
};

const useStyles = makeStyles(({ spacing }) => ({
  select: {
    backgroundColor: Colors.white,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    '& > :not(:last-child)': {
      marginRight: spacing(1),
    },
  },
  formControl: {
    minWidth: 150,
  },
}));

const renderRange = range => {
  const formattedStartDate = formatDate(range.startDate);
  const formattedEndDate = formatDate(range.endDate);

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate;
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
};

const DateAndGranularityPicker = ({
  isDisabled,
  onChange,
  showGranularity = true,
  defaultDateRange = defaultDateRangeOptions.thisMonth.value,
  defaultGranularity = defaultGranularityOptions.weekly.value,
  dateRangeOptions = defaultDateRangeOptions,
  customDateRangeOptions,
  returnDateObject = true, // if true returns Date object, if false returns YYYY-MM-DD string
  showAllDateRange = false,
  showCustomDateRange = false,
  customDateRangeValue,
  showOnlyFutureDates = false,
  showPeriod = true,
  showOnlyDatesInLabel = false,
  dateFullWidth = false,
  label = 'Date Range',
  resetOptionValue = null,
}) => {
  const classes = useStyles();
  const filterRef = useRef();
  const [selectedPeriod, setSelectedPeriod] = useState(defaultDateRange);
  const [selectedGranularity, setSelectedGranularity] = useState(defaultGranularity);
  const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false);
  const [isCustomDateRangeSelected, setCustomDateRangeSelected] = useState(selectedPeriod === 'custom');
  const [ranges, setRanges] = useState(
    customDateRangeValue
      ? {
          startDate: moment(customDateRangeValue.startDate).toDate(),
          endDate: moment(customDateRangeValue.endDate).toDate(),
          key: 'selection',
        }
      : {
          startDate: moment()
            .startOf('day')
            .toDate(),
          endDate: moment()
            .endOf('day')
            .toDate(),
          key: 'selection',
        }
  );
  const [customRanges, setCustomRanges] = useState(
    customDateRangeValue
      ? {
        startDate: moment(customDateRangeValue.startDate).toDate(),
        endDate: moment(customDateRangeValue.endDate).toDate(),
        key: 'selection',
      }
      : {
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        key: 'selection',
      }
  );
  const dateRangeOptionsCloned = _.cloneDeep(dateRangeOptions); // clone date range options to avoid overriding globally

  if (showOnlyFutureDates) {
    Object.keys(dateRangeOptionsCloned).forEach(option => dateRangeOptionsCloned[option].range.startDate = moment());
  }

  const dateRangeOptionsList = useMemo(() => Object.values(dateRangeOptionsCloned), [dateRangeOptionsCloned]);
  const dateVal = useMemo(() => dateRangeOptionsList.find(option => option.value === selectedPeriod), [selectedPeriod]);
  const handleRangeChange = ({ selection }) => setCustomRanges(selection);
  const site = useSelector(state => state.app.site);

  const getDateObjectOrFormattedDate = momentDate =>
    returnDateObject ? momentDate.toDate() : momentDate.format('YYYY-MM-DD');

  const handleDateChange = () => {
    if (selectedPeriod === 'custom') {
      return onChange({
        dateRange: {
          startDate: getDateObjectOrFormattedDate(moment(ranges.startDate)),
          endDate: getDateObjectOrFormattedDate(moment(ranges.endDate)),
        },
        period: 'custom',
        granularity: selectedGranularity,
      });
    }

    setCustomDateRangeSelected(false);

    if (selectedPeriod === 'all') {
      return onChange({
        dateRange: null,
        period: 'all',
        granularity: selectedGranularity,
      });
    }

    const date = dateRangeOptionsList.find(option => option.value === selectedPeriod);
    if (date) {
      return onChange({
        dateRange: {
          startDate: getDateObjectOrFormattedDate(date.range.startDate),
          endDate: getDateObjectOrFormattedDate(date.range.endDate),
        },
        period: selectedPeriod,
        granularity: selectedGranularity,
      });
    }

    console.warn('Unhandled date range!');
  };

  useEffect(() => {
    handleDateChange();
  }, [selectedPeriod, selectedGranularity, ranges]);

  const closeCustomDateRange = event => {
    if (filterRef.current && filterRef.current.contains(event.target)) {
      return;
    }

    setIsCustomRangeOpen(false);
    setCustomDateRangeSelected(true);
  };

  useEffect(() => {
    if (
      !Object.values(dateRangeOptions).find((range) => range.value === selectedPeriod) &&
      selectedPeriod !== 'custom' &&
      resetOptionValue !== null
    ) {
      setSelectedPeriod(Object.values(dateRangeOptions)[resetOptionValue].value);
    }
  }, [dateRangeOptions]);

  const customRangeStartDate = moment(ranges.startDate).format('MM/DD/YYYY');
  const customRangeEndDate = moment(ranges.endDate).format('MM/DD/YYYY');
  const siteCreationDate = moment(site.created_at).toDate();
  const next5Years = moment().add(5, 'years').endOf('year').toDate();

  return (
    <Box className={classes.container}>
      <FormControl className={classes.formControl} style={dateFullWidth ? { width: '100%' } : {}} ref={filterRef}>
        <TextField
          required
          onChange={event => {
            if (event.target.value !== 'custom') {
              setSelectedPeriod(event.target.value);
            }
          }}
          classes={{
            root: classes.select,
          }}
          select
          id="date_range_id"
          label={label}
          variant="outlined"
          data-testid="date-range"
          size="small"
          value={selectedPeriod}
          disabled={isDisabled}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            name: 'date_range',
            id: 'date_range_id',
          }}
          fullWidth
        >
          {dateRangeOptionsList.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
          {showAllDateRange && <MenuItem value="all">All</MenuItem>}
          {showCustomDateRange && (
            <MenuItem value="custom" onClick={() => setIsCustomRangeOpen(true)}>
              Custom
            </MenuItem>
          )}
        </TextField>
      </FormControl>

      {showGranularity && (
        <FormControl className={classes.formControl}>
          <TextField
            required
            onChange={event => setSelectedGranularity(event.target.value)}
            classes={{
              root: classes.select,
            }}
            select
            id="granularity_id"
            data-testid="granularity"
            label="Report By"
            variant="outlined"
            size="small"
            value={selectedGranularity}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              name: 'granularity',
              id: 'granularity_id',
            }}
            disabled={isDisabled}
            fullWidth
          >
            {Object.values(defaultGranularityOptions).map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      )}

      {showPeriod && (
        <Box display="flex" flexDirection="row" gridColumnGap={2}>
          {!showOnlyDatesInLabel && (
            <Typography variant="body1" color="textSecondary">
              {selectedPeriod === 'all' || selectedPeriod === 'all-history' ? 'Showing all data' : 'Showing data for:'}
            </Typography>
          )}

          {selectedPeriod !== 'all' && selectedPeriod !== 'all-history' && (
            <>
              {isCustomDateRangeSelected && (
                <Typography variant="body1" color="textSecondary">
                  {renderRange({
                    startDate: customRangeStartDate,
                    endDate: customRangeEndDate,
                  })}
                </Typography>
              )}

              {dateVal && !isCustomDateRangeSelected && selectedPeriod === defaultDateRangeOptions.today.value && (
                <Typography variant="body1" color="textSecondary">
                  {renderRange(dateVal.range)}
                </Typography>
              )}

              {dateVal && !isCustomDateRangeSelected && selectedPeriod !== defaultDateRangeOptions.today.value && (
                <Typography variant="body1" color="textSecondary">
                  {renderRange(dateVal.range)}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}

      <Popper
        open={isCustomRangeOpen}
        anchorEl={get(filterRef, 'current', null)}
        role={undefined}
        placement="bottom-end"
        transition
        style={{ zIndex: 1200 }}
        className="dateAndGranularityPickerModal"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper style={{ padding: 20, border: '1px solid rgb(170, 170, 170)' }}>
              <ClickAwayListener onClickAway={closeCustomDateRange}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <DateRangePicker
                    ranges={[customRanges]}
                    minDate={get(customDateRangeOptions, 'minDate', siteCreationDate)}
                    maxDate={get(customDateRangeOptions, 'maxDate', next5Years)}
                    rangeColors={[Colors.secondaryColor]}
                    onChange={handleRangeChange}
                    staticRanges={[]}
                    inputRanges={[]}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ alignSelf: 'flex-end' }}
                    onClick={event => {
                      closeCustomDateRange(event);
                      setSelectedPeriod('custom');
                      setRanges(customRanges);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default DateAndGranularityPicker;
