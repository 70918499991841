import { objectFromArray, objectFromArrayWithDup } from '../helpers';
import moment from 'moment';

import { REQUEST_DONATIONS, RECEIVE_DONATIONS } from '../actions/donations';

const initialState = {
  byId : {},
  allIds : [],
  modifiedIds : [],
  modifiedById : {},
  byLocationId : {},
  inflight : false,
  lastUpdated : null
};

const donationsReducer = ( state = initialState, action) => {
  switch (action.type) {
  case REQUEST_DONATIONS:
    return {
      ...state,
      inflight : true
    };
  case RECEIVE_DONATIONS:
    {
      const donations = action.donations.map( _d => Object.assign( {}, _d, {
	start_date : moment.utc(_d.start_date).format('YYYYMMDD'),
	food_types : _d.food_types !== null ? _d.food_types.split(',') : [],
	days_of_week : JSON.parse(_d.days_of_week),
        days_of_month : JSON.parse(_d.days_of_month)
      }  ) );
      const allIds = donations.map( d => d.id );
      const byId = objectFromArray( donations, d => d.id );
      const byLocationId = objectFromArrayWithDup( donations, d => d.location_id );
      return {
	...state,
	inflight : false,
        allIds,
	byId,
	byLocationId,
	lastUpdated : action.receivedAt
      };
    }
  default:
      return state;
  }
};

export default donationsReducer;
