import React, { Component } from 'react';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { Paper, Typography, Button, Breadcrumbs } from '@material-ui/core';
import { ArrowBack, Check } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import * as notificationsActions from '../actions/notifications';
import routes from '../routes/index';
import OverlayLoader from './OverlayLoader';
import { get } from 'lodash';

class NotificationDetails extends Component {
  constructor(props) {
    super(props);
    this.notificationContentElement = React.createRef();
  }

  componentDidMount() {
    const { fetchNotificationIfNeeded, match } = this.props;

    fetchNotificationIfNeeded(match.params.notificationId);

    if (this.notificationContentElement.current !== null) {
      this.notificationContentElement.current.addEventListener('click', this.handleClick);
    }
  }

  componentDidUpdate(prevPoprs) {
    const { notifications, match, fetchNotificationIfNeeded } = this.props;
    const notification =
      notifications.unread.byId[match.params.notificationId] || notifications.read.byId[match.params.notificationId];

    const prevNotification =
      prevPoprs.notifications.unread.byId[match.params.notificationId] || prevPoprs.notifications.read.byId[match.params.notificationId];

    // re-fetch notification in case when content missing
    // eg after fetching all notifications from app.jsx
    if (!get(notification, 'content') && get(prevNotification, 'content')) {
      fetchNotificationIfNeeded(match.params.notificationId);
    }

    if (this.notificationContentElement.current !== null) {
      this.notificationContentElement.current.addEventListener('click', this.handleClick);
    }
  }

  componentWillUnmount() {
    if (this.notificationContentElement.current !== null) {
      this.notificationContentElement.current.removeEventListener('click', this.handleClick);
    }
  }

  loadingSkeleton = () => (
    <div>
      <svg width="300" height="100">
        <rect x="0" y="15" rx="4" ry="4" width="117" height="6" fill="#ecebeb" />
        <rect x="0" y="30" rx="3" ry="3" width="85" height="6" fill="#ecebeb" />
        <rect x="0" y="80" rx="3" ry="3" width="350" height="6" fill="#ecebeb" />
      </svg>
    </div>
  );

  handleClick = e => {
    e.preventDefault();

    if (get(e, 'target.nodeName') !== 'A') {
      return;
    }

    const { history } = this.props;
    const routesArray = Object.values(routes);
    const pathName = get(e, 'target.pathname');
    const inAppUrl = routesArray.find(route =>
      matchPath(pathName, {
        path: route,
        exact: true,
        strict: false,
      })
    );

    if (inAppUrl) {
      return history.push(pathName);
    }

    window.open(get(e, 'target.href'));
  };

  render() {
    const { notifications, markNotificationAsRead, match, classes } = this.props;
    const isLoading = notifications.inflight;
    const notification =
      notifications.unread.byId[match.params.notificationId] || notifications.read.byId[match.params.notificationId];

    if (!isLoading && !notification) {
      return <Redirect to={routes.notifications} />;
    }

    const notificationContent = notification && notification.content ? notification.content.replace(/target="_blank"/gi, '') : null;

    return (
      <div style={{ marginBottom: 25 }}>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Link to={routes.index} color="inherit">
            Home
          </Link>

          <Link to={routes.notifications} color="inherit">
            Notifications
          </Link>

          <Typography color="textPrimary">Details</Typography>
        </Breadcrumbs>

        <OverlayLoader isLoading={isLoading}>
          <Paper className={classes.paper} elevation={1}>
            {isLoading && this.loadingSkeleton()}

            {!isLoading && (
              <div>
                <Typography variant="h5" component="h3">
                  {notification.subject}
                </Typography>

                <Typography component="p" variant="subtitle2" color="textSecondary">
                  {moment(notification.created_at).format('MM/DD/YYYY h:mm A')}
                </Typography>

                {notificationContent ? (
                  <Typography component="div" variant="subtitle1" className={classes.content}>
                    <div
                      ref={this.notificationContentElement}
                      dangerouslySetInnerHTML={{ __html: notificationContent }}
                    />
                  </Typography>
                ) : (
                  this.loadingSkeleton()
                )}

                <div className={classes.bottomButtonsWrapper}>
                  <Link to={routes.notifications}>
                    <Button
                      startIcon={<ArrowBack fontSize="small" />}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                  </Link>

                  {notification.read_at === null && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => markNotificationAsRead(notification.id)}
                      startIcon={<Check fontSize="small" />}
                    >
                      Mark as read
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Paper>
        </OverlayLoader>
      </div>
    );
  }
}

const styles = () => ({
  paper: {
    padding: '16px 24px',
  },
  bottomButtonsWrapper: {
    marginTop: 15,
  },
  backButton: {
    marginRight: 5,
  },
});
const mapStateToProps = ({ entities: { notifications } }) => ({
  notifications,
});
const mapDispatchToProps = dispatch => ({
  fetchNotificationIfNeeded: id => dispatch(notificationsActions.fetchNotificationIfNeeded(id)),
  markNotificationAsRead: id => dispatch(notificationsActions.markNotificationAsRead(id)),
});

export default withRouter(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(NotificationDetails)
  )
);
