import React from 'react';
import { Field } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField as TextFieldMaterial } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearChip from '../../../../components/ClearChip';

const useStyles = makeStyles(() => ({
  addressRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  timePicker: {
    backgroundColor: 'pink',
    '& input': {
      textAlign: 'center',
    },
  },
  autocompleteRoot: {
    minWidth: 300,
  },
}));

const ReceivingAgencyField = ({ receivingAgenciesList, form }) => {
  const values = form.getState().values;
  const classes = useStyles();

  return (
    <Field
      name="receiver"
      render={({ input }) => {
        if (values.receiver) {
          return (
            <ClearChip
              value={input.value}
              onClick={() => {
                input.onChange(null);
                form.change('receiver_id', null);
              }}
            />
          );
        }

        return (
          values.receiver_id === null && (
            <Autocomplete
              options={receivingAgenciesList}
              classes={{
                root: classes.autocompleteRoot,
              }}
              getOptionLabel={res => (res ? res.name : '')}
              onChange={(event, rescuer) => {
                input.onChange(rescuer.value);
                form.change('receiver_id', rescuer.id);
              }}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              size="small"
              renderInput={params => (
                <TextFieldMaterial {...params} placeholder="Receiving Agency" variant="outlined" fullWidth />
              )}
              noOptionsText="There are no active receiving agencies in the Site"
            />
          )
        );
      }}
    />
  );
};

export default ReceivingAgencyField;
