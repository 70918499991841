import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Breadcrumbs } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import routes from '../../../routes';
import NotificationsListMobile from './Mobile/NotificationsListMobile';
import NotificationsListDesktop from './Desktop/NotificationsListDesktop';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../../pages/rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';

const NotificationsInbox = ({ isMobileView }) => {
  const notifications = useSelector(state => state.entities.notifications);

  const unreadNotifications = useMemo(() => notifications.unread.allIds.map(id => notifications.unread.byId[id]), [
    notifications.unread.allIds.length,
  ]);

  const readNotifications = useMemo(() => notifications.read.allIds.map(id => notifications.read.byId[id]), [
    notifications.read.allIds.length,
  ]);

  return (
    <div>
      {isMobileView ? (
        <Breadcrumbs aria-label="Breadcrumbs">
          <StyledRescuerBreadcrumb
            component={Link}
            to={routes.mobileDashboard}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <StyledRescuerTextBreadcrumb component={Typography} label="Notifications" />
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="Breadcrumbs">
          <StyledRescuerBreadcrumb
            component={Link}
            to={routes.index}
            label="Home"
            icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
          />
          <Typography color="textPrimary">Notifications</Typography>
        </Breadcrumbs>
      )}

      {isMobileView ? (
        <NotificationsListMobile
          isLoading={notifications.inflight}
          read={readNotifications}
          unread={unreadNotifications}
        />
      ) : (
        <NotificationsListDesktop
          isLoading={notifications.inflight}
          read={readNotifications}
          unread={unreadNotifications}
        />
      )}
    </div>
  );
};

export default NotificationsInbox;
