import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDefaultDashboard } from '../../actions/uiPersisted';
import SCDashboardDesktopContainer from './SCDashboardDesktop/SCDashboardDesktopContainer';
import routes from '../../routes';
import LandingDashboard from '../rescuerDashboard/rescuerDashboardMobile/LandingDashboard';

const SiteCoordinatorDashboardContainer = ({ isMobileView, match: { params } }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // set as default dashboard
    dispatch(setDefaultDashboard({ route: routes.dashboardSC, params }));
  }, [dispatch]);

  if (isMobileView) {
    return <LandingDashboard />;
  }

  return <SCDashboardDesktopContainer />;
};

export default SiteCoordinatorDashboardContainer;
