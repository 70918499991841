import React, { useReducer } from 'react';
import FABContext from './FABContext';
import FABReducer from './FABReducer';
import { SET_FAB_ACTIONS, SET_FAB_ACTIONS_BY_KEY, UPDATE_FAB_STYLES } from '../types';
import { useMediaQuery, useTheme } from '@material-ui/core';

const FABState = props => {
  const initialState = {
    actions: {},
    styles: {},
    isVisible: false,
  };
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.only('xs'), {
    defaultMatches: null,
  });

  const [state, dispatch] = useReducer(FABReducer, initialState);

  const setFABActions = async (actions, isVisible = true) => {
    dispatch({ type: SET_FAB_ACTIONS, payload: { actions, isVisible } });
  };

  const setFABActionsByKey = async (key, actions, isVisible = true) => {
    dispatch({ type: SET_FAB_ACTIONS_BY_KEY, payload: { key, actions, isVisible } });
  };

  const updateFABStyles = async styles => {
    dispatch({ type: UPDATE_FAB_STYLES, payload: { styles } });
  };


  return (
    <FABContext.Provider
      value={{
        styles: state.styles,
        actions: state.actions,
        isVisible: state.isVisible && isMobileView,
        setFABActions,
        setFABActionsByKey,
        updateFABStyles
      }}
    >
      {props.children}
    </FABContext.Provider>
  );
};

export default FABState;
