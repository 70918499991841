export const SET_SITE = 'SET_SITE';
export const SET_SITE_FEATURE_FLAGS = 'SET_SITE_FEATURE_FLAGS';

export const switchCurrentSite = site => ({
  type: SET_SITE,
  site,
});

export const setSiteFeatureFlags = featureFlags => ({
  type: SET_SITE_FEATURE_FLAGS,
  featureFlags: featureFlags,
});
