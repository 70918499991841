import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'final-form-material-ui';
import Bluebird from 'bluebird';
import { withStyles, Paper, Grid, Button, Typography } from '@material-ui/core';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import { ATTACH_SITE_RADIUS_IN_MILES } from '../models/systemSettings';

class SystemSettingsForm extends Component {
  handleSubmit = updateSettings => values => Bluebird
    .try(() => updateSettings(values))
    .then(() => snackbarHelper.success('System settings updated successfully!'))
    .catch(err => {
      errorsHelper.handleError(err);

      return { [FORM_ERROR]: 'error' };
    });

  render() {
    const { systemSettings, onSubmit, classes } = this.props;
    let initialValues = {};

    systemSettings.map(systemSetting => initialValues[systemSetting.name] = systemSetting.value);

    return (
      <Form
        onSubmit={this.handleSubmit(onSubmit)}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography color="textPrimary" variant="h6">
                    System Settings
                  </Typography>

                  <Field
                    name={ATTACH_SITE_RADIUS_IN_MILES}
                    type="number"
                    component={TextField}
                    label="Attach site radius in miles"
                    className={classes.textField}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={pristine || invalid || submitting}
                    className={classes.button}
                  >
                    Update System Settings
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing(1),
    width: 300,
  },
  textField: {
    width: 300,
    marginBottom: 10,
  },
});

export default withStyles(styles)(SystemSettingsForm);
