import { apiGET, apiPATCH } from './api';
import queryString from 'query-string';
import moment from 'moment';

export const getPastRescues = (siteIds, from, to, umbrellaFoodDonorId = null) => {
  const query = queryString.stringify(
    {
      sites_ids: siteIds,
      donor_id: umbrellaFoodDonorId,
      from: from,
      to: to,
      today: moment().format('YYYY-MM-DD'),
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/past_rescues?${query}`);
};

export const updatePastRescues = (pastRescues) => apiPATCH('/past_rescues', pastRescues);
