import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import UserForm from '../components/UserForm';
import * as rescuersActions from '../actions/rescuers';
import { fetchRescuerLocationTypesIfNeeded } from '../actions/rescuerLocations';
import routes from '../routes';

class RescuerAddView extends Component {
  componentDidMount() {
    const { fetchLocationTypes } = this.props;

    fetchLocationTypes();
  }

  submitCallback = data => {
    const { site, createRescuer } = this.props;

    return createRescuer({
      ...data,
      site_id: site.id,
    });
  };

  render() {
    const { rescuerLocationTypes } = this.props;

    return (
      <>
        <Breadcrumbs>
          <Link to={routes.rescuers} color="inherit">
            Rescuers
          </Link>

          <Typography color="textPrimary">Add a Rescuer</Typography>
        </Breadcrumbs>

        <UserForm
          showCommunicationCheckboxes={false}
          showUserSelector
          customErrorMessages={{
            ERR_DUPLICATED_ROLE: {
              message: 'Selected user is already a rescuer.',
              type: 'info',
            }
          }}
          showZipCodeField={false}
          submitButtonText="Add Rescuer"
          redirectPath={routes.rescuers}
          submitCallback={this.submitCallback}
          rescuerLocationTypes={rescuerLocationTypes}
        />
      </>
    );
  }
}

const mapStateToProps = ({ app: { site }, entities: { rescuerLocations } }) => ({
  site,
  rescuerLocationTypes: rescuerLocations.locationTypes.byId,
});
const mapDispatchToProps = dispatch => ({
  createRescuer: rescuer => dispatch(rescuersActions.createRescuer(rescuer)),
  fetchLocationTypes: () => dispatch(fetchRescuerLocationTypesIfNeeded()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RescuerAddView);
