export const TextAreaStyles = () => {
  return {
    root: {
      zIndex: 0,
      '& .MuiInputBase-root': {
        padding: 8,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 10px) scale(1)',
        whiteSpace: 'nowrap',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.75)',
        },
      },
    },
  };
};
