import Bluebird from 'bluebird';
import * as requestDonationsApi from '../api/requestDonations';
import { get } from 'lodash';
import { createDraftDonation, setDonationDiffInflight } from './donationNew';
import { updateDonation } from '../api/requestDonations';

export const REQUEST_REQUEST_DONATION = 'REQUEST_REQUEST_DONATION';
export const REQUEST_REQUEST_DONATION_DELETE = 'REQUEST_REQUEST_DONATION_DELETE';
export const RECEIVE_REQUEST_DONATION_DELETE = 'RECEIVE_REQUEST_DONATION_DELETE';
export const RECEIVE_REQUEST_DONATIONS = 'RECEIVE_REQUEST_DONATIONS';
export const RECEIVE_REQUEST_DONATION = 'RECEIVE_REQUEST_DONATION';

export const saveRequestDonation = (activeSite, activeUser, donationRequestCreateData) => async dispatch => {
  dispatch(setDonationDiffInflight(true));

  const response = await requestDonationsApi.createDonation({
    ...donationRequestCreateData,
    start_date: get(donationRequestCreateData, 'start_date.value'),
    end_date: get(donationRequestCreateData, 'end_date.value'),
    pause_date: get(donationRequestCreateData, 'pause_date.value'),
    resume_date: get(donationRequestCreateData, 'resume_date.value'),
    slug: get(donationRequestCreateData, 'description', '-'),
    location_id: donationRequestCreateData.location.id,
    pickup_location_id: donationRequestCreateData.pickup_location.id,
    rescue_size_id: get(donationRequestCreateData, 'rescue_size.id', null),
    donation_size_id: get(donationRequestCreateData, 'donation_size.id', null),
    site_id: activeSite.id,
    created_by_id: activeUser.id,
    food_donor_notes: donationRequestCreateData.food_donor_notes,
    days_of_month: JSON.stringify(donationRequestCreateData.days_of_month),
    days_of_week: JSON.stringify(donationRequestCreateData.days_of_week),
  });
  const result = await response.json();
  return result.data;
};

export const updateRequestDonation = (donationId, donationRequestCreateData) => async dispatch => {
  dispatch(setDonationDiffInflight(true));

  const response = await requestDonationsApi.updateDonation(donationId,{
    ...donationRequestCreateData,
    start_date: get(donationRequestCreateData, 'start_date.value'),
    end_date: get(donationRequestCreateData, 'end_date.value'),
    pause_date: get(donationRequestCreateData, 'pause_date.value'),
    resume_date: get(donationRequestCreateData, 'resume_date.value'),
    slug: get(donationRequestCreateData, 'description', '-'),
    rescue_size_id: get(donationRequestCreateData, 'rescue_size.id', null),
    donation_size_id: get(donationRequestCreateData, 'donation_size.id', null),
    days_of_month: JSON.stringify(donationRequestCreateData.days_of_month),
    days_of_week: JSON.stringify(donationRequestCreateData.days_of_week),
  });
  const result = await response.json();
  return result.data;
};

export const deleteRequestDonation = (donationId) => async dispatch => {
  dispatch(setDonationDiffInflight(true));

  const response = await requestDonationsApi.deleteDonation(donationId);
  const result = await response.json();
  return result.data;
};

export const requestRequestDonations = () => ({
  type: REQUEST_REQUEST_DONATION,
});

export const receiveRequestDonations = json => {
  return {
    type: RECEIVE_REQUEST_DONATIONS,
    data: json.data,
    receivedAt: Date.now(),
  };
};

export const receiveRequestDonation = json => {
  return {
    type: RECEIVE_REQUEST_DONATION,
    data: json.data,
    receivedAt: Date.now(),
  };
};

export const diffCreateRequestDonation = requestDonationId => dispatch => {
  dispatch(setDonationDiffInflight(true));

  return Bluebird.try(() => requestDonationsApi.getRequestDonation(requestDonationId))
    .then(res => res.json())
    .then(res => res.data)
    .then(data => {
        return dispatch(
          createDraftDonation({
            rescues: [],
            donation: {
              ...data,
              errors: {},
            },
          }),
        );
      }
    );
};

export const fetchRequestDonationList = (params) => dispatch => {
  dispatch(requestRequestDonations());

  return requestDonationsApi
    .getDonationRequests(params)
    .then(response => response.json())
    .then(json => dispatch(receiveRequestDonations(json)));
};

export const fetchRequestDonation = requestDonationId => dispatch => {
  dispatch(requestRequestDonations());

  return requestDonationsApi
    .getRequestDonation(requestDonationId)
    .then(response => response.json())
    .then(json => dispatch(receiveRequestDonation(json)));
};
