import * as metricsActions from '../actions/metrics';

export const initialSitesState = {
  inflight: false,
  all: [],
  lastUpdated: null,
};

const metricsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case metricsActions.REQUEST_METRICS:
      return {
        ...state,
        inflight: true,
      };
    case metricsActions.RECEIVE_METRICS:
      return {
        ...state,
        inflight: false,
        all: action.metrics,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default metricsReducer;
