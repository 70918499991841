import React from 'react';
import FABState from './FABContext/FABState';
import NotificationsState from './NotificationsContext/NotificationsState';

function ContextStateWrapper({ children }) {
  return (
    <NotificationsState>
      <FABState>
        {children}
      </FABState>
    </NotificationsState>
  );
}

export default ContextStateWrapper;