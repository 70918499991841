import * as growthActions from '../actions/growth';

export const initialSitesState = {
  inflight: false,
  all: [],
  lastUpdated: null,
};

const growthReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case growthActions.REQUEST_GROWTH:
      return {
        ...state,
        inflight: true,
      };
    case growthActions.RECEIVE_GROWTH:
      return {
        ...state,
        inflight: false,
        all: action.growth,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default growthReducer;
