import React from 'react';
import RescueMobileCard from '../../pages/Rescue/RescueMobileCard';
import ReleaseRescueButtons from './ReleaseRescueButtons';

const UpcomingRescue = ({ rescue }) => (
  <RescueMobileCard rescue={rescue} isCancelled={rescue.cancelled_by_id !== null}>
    <ReleaseRescueButtons rescue={rescue} />
  </RescueMobileCard>
);

export default UpcomingRescue;
