import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Divider, Typography, Box, Collapse, Button } from '@material-ui/core';
import {
  Help as HelpIcon,
  TimeToLeave,
  NearMe,
  AspectRatio,
  Call,
  Directions,
  Group as GroupIcon,
} from '@material-ui/icons';
import moment from 'moment';
import Linkify from 'react-linkify';
import { Colors } from '../../assets/theme/Colors';
import { formatTime, formatRescueDistance, formatRescueDuration } from '../../helpers/formatters';
import { withStyles, makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import * as grungeRed from '../../assets/images/grunge-red.png';
import * as grungeGreen from '../../assets/images/grunge-green.png';
import { Lock as LockIcon } from '@material-ui/icons';
import {
  getRescueDropoffAddress,
  getRescuePickupAddress,
  getRescuePickupLocationFullName,
  getRescuePosition,
} from '../../helpers/RescuesHelper';
import useActiveUser from '../../hooks/useActiveUser';
import { getUserSitesByRoles } from '../../services/auth';
import { Roles } from '../../models/roles';

const handleAddressClick = address => window.open(`https://maps.google.com/?q=${address}`, '_blank');

export const RescueColor = ({ children, visibility, color }) => (
  <Box
    css={{
      position: 'absolute',
      height: '100%',
      width: '5px',
      backgroundColor: color || Colors.appBar.light,
      borderTopLeftRadius: 16,
      borderBottomLeftRadius: 16,
    }}
    visibility={visibility}
  >
    {children}
  </Box>
);

export const DialButton = withStyles({
  root: {
    width: 55,
    height: 55,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  startIcon: {
    marginTop: 5,
    marginBottom: 2,
    margin: 0,
  },
})(({ classes, phone, text = 'Call' }) => (
  <Button
    classes={classes}
    startIcon={<Call />}
    variant="outlined"
    onClick={() => (window.location.href = `tel:${phone}`)}
  >
    <Box>
      {text}
    </Box>
  </Button>
));

export const MapButton = withStyles({
  root: {
    width: 55,
    height: 55,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  startIcon: {
    marginTop: 5,
    marginBottom: 2,
    margin: 0,
  },
})(({ classes, address, text = 'Map' }) => (
  <Button classes={classes} startIcon={<Directions />} variant="outlined" onClick={() => handleAddressClick(address)}>
    <Box>
      {text}
    </Box>
  </Button>
));

export const RubberStamp = withStyles({
  stamp: {
    textAlign: 'center',
    position: 'absolute',
    display: 'inline-block',
    borderRadius: 0,
    borderWidth: 8,
    borderStyle: 'solid',
    textTransform: 'uppercase',
    margin: '0 auto',
    letterSpacing: -3,
    transformOrigin: '50% 50%',
    '-ms-transform': 'rotate(30deg)',
    '-webkit-transform': 'rotate(30deg)',
    transform: 'rotate(30deg)',
    padding: 5,
    border: '0.5rem solid #F44336',
    '-webkit-mask-position': '13rem 6rem',
    opacity: 0.6,
    top: 90,
    left: 'calc(50% - 90px)',
  },
  cancelled: {
    borderImage: `url(${grungeRed}) 30`,
    color: Colors.rescues.cancelled.color,
    borderColor: Colors.rescues.cancelled.color,
  },
  closed: {
    borderImage: `url(${grungeGreen}) 30`,
    color: Colors.rescues.closed.color,
    borderColor: Colors.rescues.closed.color,
  },
  claimed: {
    borderImage: `url(${grungeGreen}) 30`,
    color: Colors.rescues.claimed.color,
    borderColor: Colors.rescues.claimed.color,
    fontWeight: 'bold',
    top: '35%',
  },
})(({ classes, text, visibility, isCancelled, isClosed, isClaimed }) => (
  <Box
    className={classNames(classes.stamp, {
      [classes.cancelled]: isCancelled,
      [classes.closed]: isClosed,
      [classes.claimed]: isClaimed,
    })}
    visibility={visibility}
  >
    <Box fontSize="body1.fontSize" letterSpacing={1.5}>
      {text}
    </Box>
  </Box>
));

export const AddressLine = withStyles({
  path: {
    borderColor: Colors.appBar.light,
    background: `repeating-linear-gradient(${Colors.appBar.light}, ${Colors.appBar.light} 3px, transparent 3px, transparent 6px)`,
    width: 2,
    marginTop: 12,
    marginBottom: 12,
    marginRight: 5,
    marginLeft: 2,
    position: 'relative',
    '&:before, &::after': {
      content: '""',
      position: 'absolute',
      left: -3,
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#fff',
      border: '2px solid',
      borderColor: 'inherit',
    },
    '&:before': {
      top: 0,
    },
    '&:after': {
      bottom: 0,
    },
  },
})(({ children, classes, ...rest }) => (
  <Box className={classes.path} {...rest}>
    {children}
  </Box>
));

const useStyles = makeStyles(() => {
  return {
    claimed: {
      opacity: 0.3,
    },
    card: ({ highlighted }) => ({
      position: 'relative',
      backgroundColor: '#fff',
      width: '100%',
      borderRadius: 16,
      borderTopLeftRadius: highlighted ? 8 : 16,
      borderBottomLeftRadius: highlighted ? 8 : 16,
    }),
    breakWhiteSpaces: {
      whiteSpace: 'pre-wrap',
    },
  };
});

export default function RescueMobileCard({
  rescue,
  children,
  isLoading,
  highlighted,
  isCancelled = false,
  isClosed = false,
  hideExpandPanel = false,
  isClaimed = false,
  noShadow = false,
}) {
  const classes = useStyles({
    highlighted,
  });
  const activeUser = useActiveUser();
  const [expanded, setExpanded] = useState(false);
  const { formatMessage } = useIntl();

  const dayName = rescue
    ? moment(rescue.date).calendar(null, {
        sameDay: `[${formatMessage({ id: 'dates.today' })}]`,
        nextDay: `[${formatMessage({ id: 'dates.tomorrow' })}]`,
        nextWeek: 'dddd, MMM D',
        lastDay: `[${formatMessage({ id: 'dates.yesterday' })}]`,
        lastWeek: `[${formatMessage({ id: 'dates.last' })}] dddd, MMM D`,
        sameElse: date => (moment().isSame(date, 'year') ? 'dddd, MMM D' : 'dddd, MMM D YYYY'),
      })
    : null;
  const rescueTime = rescue ? `${formatTime(rescue.pickup_begin)} - ${formatTime(rescue.pickup_end)}` : null;
  const rescueName = rescue ? getRescuePickupLocationFullName(rescue) : null;
  const handleExpandClick = () => setExpanded(!expanded);
  const rescuePickupAddress = rescue ? getRescuePickupAddress(rescue) : null;
  const rescueDropoffAddress = rescue ? getRescueDropoffAddress(rescue) : null;
  const sitesWithRescuerRoles = getUserSitesByRoles(activeUser.role_assignments, Roles.Rescuer);

  return (
    <Box boxShadow={noShadow ? 0 : 25} className={classes.card}>
      <RubberStamp
        isCancelled
        visibility={isCancelled ? 'visible' : 'hidden'}
        text={formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.stamps.cancelled' })}
      />
      <RubberStamp
        isClaimed
        visibility={isClaimed ? 'visible' : 'hidden'}
        text={formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.stamps.claimed' })}
      />
      <RubberStamp
        isClosed
        visibility={!isLoading && isClosed ? 'visible' : 'hidden'}
        text={
          <Box display="flex" justifyContent="center">
            <LockIcon /> {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.stamps.closed' })}
          </Box>
        }
      />

      <RescueColor visibility={highlighted ? 'visible' : 'hidden'} />

      <Box display="flex" py={2} className={classNames({ [classes.claimed]: isClaimed })}>
        <Box flexGrow={1} display="flex" justifyContent="flex-start" flexDirection="column" alignItems="stretch">
          <Box pl={2} display="flex" flexDirection="column" pr={2}>
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle1">{isLoading ? <Skeleton /> : dayName}</Typography>

              <Typography variant="subtitle2">{isLoading ? <Skeleton /> : rescueTime}</Typography>

              {!isLoading && sitesWithRescuerRoles.length > 1 && (
                <Typography variant="body2">Site: {rescue.site}</Typography>
              )}
            </Box>

            <Box display="flex" py={1}>
              <AddressLine />
              <Box display="flex" flexDirection="column" flexGrow={1} pl={1}>
                <Box my={0.5}>
                  <Typography variant="body1">{isLoading ? <Skeleton /> : rescueName}</Typography>

                  <Typography variant="body2">
                    {isLoading ? <Skeleton /> : `${rescue.pickup_city}, ${rescue.pickup_st}`}
                  </Typography>
                </Box>

                <Box my={0.5}>
                  <Divider />
                </Box>

                <Box my={0.5}>
                  <Typography variant="body1">{isLoading ? <Skeleton /> : rescue.receiver}</Typography>

                  <Typography variant="body2">
                    {isLoading ? <Skeleton /> : `${rescue.dropoff_city}, ${rescue.dropoff_st}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {!hideExpandPanel && (
            <Box display="block" py={0} pl={2} pr={1}>
              {children}
            </Box>
          )}
        </Box>

        <Box
          px={0.5}
          color="text.secondary"
          borderLeft={1}
          borderColor="grey.500"
          display="flex"
          direction="column"
          alignItems="center"
          width={85}
          maxwidth={85}
          css={{
            overflow: 'hidden',
            wordBreak: 'break-word',
          }}
        >
          <Box display="flex" flexGrow={1} flexDirection="column" alignItems="center">
            <Box flexGrow={1} flexBasis={0} flexDirection="column" color="text.secondary">
              {rescue && rescue.positions > 1 && (
                <Box
                  pb={1}
                  flexGrow={1}
                  flexBasis={0}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  textAlign="center"
                >
                  <GroupIcon />
                  <Typography variant="caption">{isLoading ? <Skeleton /> : getRescuePosition(rescue)}</Typography>
                </Box>
              )}

              <Box
                flexDirection="column"
                textAlign="center"
                pb={1}
                onClick={() => handleAddressClick(rescuePickupAddress)}
              >
                <NearMe />

                <Typography variant="body2">
                  {isLoading ? <Skeleton /> : formatRescueDistance(rescue.distance)}
                </Typography>
              </Box>

              <Box flexDirection="column" textAlign="center" pb={1}>
                <TimeToLeave />

                <Typography variant="body2">
                  {isLoading ? <Skeleton /> : formatRescueDuration(rescue.duration)}
                </Typography>
              </Box>

              <Box
                flexGrow={1}
                flexBasis={0}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <AspectRatio />
                <Typography variant="caption">{isLoading ? <Skeleton /> : rescue.rescue_size || 'n/a'}</Typography>
              </Box>
            </Box>
            {!hideExpandPanel && (
              <Box
                width={1}
                onClick={handleExpandClick}
                style={{
                  backgroundColor: '#efefef',
                  borderRadius: '20%',
                  width: 55,
                  height: 55,
                  paddingTop: 10,
                  marginTop: 16,
                }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
              >
                <HelpIcon htmlColor={Colors.blue.main} />
                <Typography variant="caption">
                  {expanded
                    ? formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.hide' })
                    : formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.info' })
                  }
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {!hideExpandPanel && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box px={2}>
            <Divider />
          </Box>
          <Box p={2} flexDirection="column" color="text.secondary">
            {!isLoading && rescue.rescuer_notes && (
              <>
                <Box>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.notes' })}
                  </Typography>

                  <Typography variant="body1" className={classes.breakWhiteSpaces}>
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {rescue.rescuer_notes}
                    </Linkify>
                  </Typography>
                </Box>

                <Box my={2}>
                  <Divider />
                </Box>
              </>
            )}

            {!isLoading && rescue.slug && (
              <>
                <Box>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.description' })}
                  </Typography>

                  <Typography variant="body1" className={classes.breakWhiteSpaces}>
                    {rescue.slug}
                  </Typography>
                </Box>

                <Box my={2}>
                  <Divider />
                </Box>
              </>
            )}

            <Box mb={2}>
              {!isLoading ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gridGap="8px"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-address' })}
                    </Typography>

                    <Typography variant="body1">{isLoading ? <Skeleton /> : rescuePickupAddress}</Typography>
                  </Box>
                  <Box>
                    <MapButton
                      address={rescuePickupAddress}
                      text={formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.map-button' })}
                    />
                  </Box>
                </Box>
              ) : (
                <Box pt={0.5}>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-address' })}
                  </Typography>
                  <Skeleton width="65%" />
                  <Skeleton width="45%" />
                  <Skeleton width="40%" />
                </Box>
              )}
            </Box>

            <Box>
              {!isLoading ? (
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="subtitle1">
                      {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-contact' })}
                    </Typography>
                    <Typography variant="body1">
                      {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-contact-name' })} {rescue.pickup_contact}
                    </Typography>
                    <Typography variant="body1">
                      <span>{formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-contact-phone' })} </span>
                      <a href={`tel:${rescue.pickup_contact_phone}`}>{rescue.pickup_contact_phone}</a>
                      {rescue.pickup_contact_phone_ext && <span>{` (ext: ${rescue.pickup_contact_phone_ext})`}</span>}
                    </Typography>

                    <Typography variant="body1">
                      {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-contact-email' })} <a href={`mailto:${rescue.pickup_contact_email}`}>{rescue.pickup_contact_email}</a>
                    </Typography>
                  </Box>
                  <Box>
                    <DialButton
                      phone={rescue.pickup_contact_phone}
                      text={formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.call-button' })}
                    />
                  </Box>
                </Box>
              ) : (
                <Box pt={0.5}>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-contact' })}
                  </Typography>
                  <Skeleton width="65%" />
                  <Skeleton width="45%" />
                  <Skeleton width="40%" />
                </Box>
              )}
            </Box>

            {!isLoading && rescue.pickup_notes && (
              <Box pt={2}>
                <Typography variant="subtitle1">
                  {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.pickup-notes' })}
                </Typography>

                <Typography variant="body1" className={classes.breakWhiteSpaces}>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {rescue.pickup_notes}
                  </Linkify>
                </Typography>
              </Box>
            )}

            <Box mx={4} my={2}>
              <Divider />
            </Box>

            <Box mb={2}>
              {!isLoading ? (
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="subtitle1">
                      {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.dropoff-address' })}
                    </Typography>
                    <Typography variant="body1">{isLoading ? <Skeleton /> : rescueDropoffAddress}</Typography>
                  </Box>
                  <Box>
                    <MapButton
                      address={rescueDropoffAddress}
                      text={formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.map-button' })}
                    />
                  </Box>
                </Box>
              ) : (
                <Box pt={0.5}>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.dropoff-address' })}
                  </Typography>
                  <Skeleton width="65%" />
                  <Skeleton width="45%" />
                  <Skeleton width="40%" />
                </Box>
              )}
            </Box>

            <Box>
              <Typography variant="subtitle1">
                {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.dropoff-contact' })}
              </Typography>

              {!isLoading ? (
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="body1">
                      {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.dropoff-contact-name' })} {rescue.dropoff_contact}
                    </Typography>

                    <Typography variant="body1">
                      <span>{formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.dropoff-contact-phone' })} </span>
                      <a href={`tel:${rescue.dropoff_contact_phone}`}>{rescue.dropoff_contact_phone}</a>
                      {rescue.dropoff_contact_phone_ext && <span>{` (ext: ${rescue.dropoff_contact_phone_ext})`}</span>}
                    </Typography>

                    <Typography variant="body1">
                      {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.dropoff-contact-email' })} <a href={`mailto:${rescue.dropoff_contact_email}`}>{rescue.dropoff_contact_email}</a>
                    </Typography>
                  </Box>
                  <Box>
                    <DialButton
                      phone={rescue.dropoff_contact_phone}
                      text={formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.call-button' })}
                    />
                  </Box>
                </Box>
              ) : (
                <Box pt={1}>
                  <Skeleton width="65%" />
                  <Skeleton width="45%" />
                  <Skeleton width="40%" />
                </Box>
              )}
            </Box>

            {!isLoading && rescue.dropoff_notes && (
              <Box pt={2}>
                <Typography variant="subtitle1">
                  {formatMessage({ id: 'rescuer-dashboard-mobile.rescue-card.dropoff-notes' })}
                </Typography>
                <Typography variant="body1" className={classes.breakWhiteSpaces}>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="_blank" rel="noreferrer"  href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {rescue.dropoff_notes}
                  </Linkify>
                </Typography>
              </Box>
            )}
          </Box>
        </Collapse>
      )}
    </Box>
  );
}
