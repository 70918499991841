import { REQUEST_ZIP_CODES, RECEIVE_ZIP_CODES } from '../actions/zipCodes';

const initialSitesState = {
  all: [],
  lastUpdated: null,
};

const zipCodesReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case REQUEST_ZIP_CODES:
      return {
        ...state,
        inflight: true,
      };
    case RECEIVE_ZIP_CODES:
      return {
        ...state,
        inflight: false,
        all: action.zipCodes,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default zipCodesReducer;
