import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, Select, Box, MenuItem, FormLabel, FormControlLabel } from '@material-ui/core';
import { AddCircleOutline as AddCircleIcon, RemoveCircleOutline as RemoveCircleIcon } from '@material-ui/icons';
import moment from 'moment';

class WeeklyHoursSelector extends Component {
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  hourRange = [];

  constructor(props) {
    super(props);
    this.state = {
      hours: props.initialValues || {},
    };
    this.generateHourRange();
  }

  handleDayToggle = name => event => {
    if (event.target.checked) {
      this.addTimeSlot(name, '08:00am', '10:00am');
    } else {
      this.setHoursForDay(name, []);
    }
  };

  addTimeSlot = (key, start, end) => {
    let { [key]: slots } = this.state.hours;
    if (typeof slots !== 'undefined') {
      slots.push({ start, end });
    } else {
      slots = [{ start, end }];
    }

    this.setHoursForDay(key, slots);
  };

  removeTimeSlot = (day, index) => {
    const { [day]: slots } = this.state.hours;

    this.setHoursForDay(day, slots.filter((slot, sIndex) => sIndex !== index));
  };

  setHoursForDay = (day, slots) => {
    this.setState(
      state => ({
        ...state,
        hours: {
          ...state.hours,
          [day]: slots,
        },
      }),
      () => this.props.onChange(this.state.hours)
    );
  };

  onHourChange = (day, slotIndex, saveProperty) => event => {
    const { [day]: slots } = this.state.hours;
    this.setHoursForDay(
      day,
      slots.map((slot, index) => {
        if (index === slotIndex) {
          return {
            ...slot,
            [saveProperty]: event.target.value,
          };
        }

        return slot;
      })
    );
  };

  generateHourRange() {
    const date = moment('12:00am', 'hh:mma');
    let dateFormatted = date.format('hh:mma');

    while (date.isBefore(moment('11:59pm', 'hh:mma'))) {
      this.hourRange.push({ label: dateFormatted, value: dateFormatted });
      date.add(30, 'm');
      dateFormatted = date.format('hh:mma');
    }
  }

  renderTimeSlots = day => {
    const { classes } = this.props;
    const { [day]: timeSlots } = this.state.hours;

    const renderSelect = (label, value, day, slotIndex, saveProperty) => {
      return (
        <Select
          value={value}
          options={this.hourRange}
          placeholder={label}
          onChange={this.onHourChange(day, slotIndex, saveProperty)}
        >
          {this.hourRange.map((hour, index) => (
            <MenuItem key={index} value={hour.value}>
              {hour.label}
            </MenuItem>
          ))}
        </Select>
      );
    };

    return (
      <div>
        {timeSlots &&
          timeSlots.length > 0 &&
          timeSlots.map((slot, index) => (
            <div className={classes.slot} key={index}>
              {renderSelect('Begin', slot.start, day, index, 'start')}
              {renderSelect('End', slot.end, day, index, 'end')}
              {index + 1 < timeSlots.length && (
                <RemoveCircleIcon className={classes.icon} onClick={() => this.removeTimeSlot(day, index)} />
              )}
              {index + 1 === timeSlots.length && (
                <AddCircleIcon className={classes.icon} onClick={() => this.addTimeSlot(day, '08:00am', '10:00am')} />
              )}
            </div>
          ))}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { hours } = this.state;

    return (
      <Box>
        <FormLabel>Receiving hours</FormLabel>
        <Box className={classes.formControl}>
          {this.days.map(day => (
            <Box className={classes.daySelector} key={day}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={this.handleDayToggle(day)}
                    checked={hours.hasOwnProperty(day) && hours[day].length > 0}
                    value={day}
                    color="primary"
                  />
                }
                label={day}
              />
              {this.renderTimeSlots(day)}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}

const styles = ({ spacing, breakpoints }) => ({
  daySelector: {
    flexGrow: 1,
    minWidth: 'fit-content',
    maxWidth: 280,
    flexBasis: 230,
    [breakpoints.down('xs')]: {
      minWidth: '100%',
    },
    borderRight: '2px solid #eaeaea',
    '&:last-child': {
      border: 'none',
    },
  },
  formControl: {
    display: 'flex',
    flexFlow: 'row wrap',
    gridGap: spacing(2),
  },
  slot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 5px',
  },
  icon: {
    cursor: 'pointer',
  },
});

export default withStyles(styles)(WeeklyHoursSelector);
