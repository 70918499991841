/* this file overrides all tables in the system (Table and MUIDataTable) */
export const Table = {
  MuiTableCell: {
    root: {
      padding: '4px 4px 4px 12px',
    },
  },
  MuiTableRow: {
    head: {
      height: '36px',
    },
  },
  MUIDataTableHeadCell: {
    root: {
      padding: '4px 4px 4px 12px',
    },
  },
  MUIDataTableToolbarSelect: {
    root: {
      borderRadius: 0,
    },
  },
};
