import React from 'react';
import Select from 'react-select';
import { Typography } from '@material-ui/core';

const AdopterSelector = ({
  rescuersList,
  onChange,
  placeholder = 'Adopter',
  noOptionsText = 'There are no active adopters in the Site',
  ...rest
}) => (
  <Select
    menuPosition="fixed"
    placeholder={placeholder}
    options={rescuersList}
    noOptionsMessage={() => <Typography>{noOptionsText}</Typography>}
    onChange={event => {
      if (onChange) {
        onChange(event);
      }
    }}
    menuPortalTarget={document.body}
    {...rest}
  />
);

export default AdopterSelector;
