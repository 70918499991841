export const CLEAR_TABLE_OPTIONS = 'CLEAR_TABLE_OPTIONS';
export const SET_USER_TABLE_OPTIONS = 'SET_USER_TABLE_OPTIONS';

export const clearTableOptions = () => ({
  type: CLEAR_TABLE_OPTIONS,
});

export const setUserTableOptions = (userId, tableId, data) => ({
  type: SET_USER_TABLE_OPTIONS,
  payload: {
    userId,
    tableId,
    data,
  },
});
