import { apiGET } from './api';
import queryString from 'query-string';

export const getZipCodes = zipCode => {
  let query = queryString.stringify({
    zip_code: zipCode,
  });

  if (query.length) {
    query = '?' + query;
  }

  return apiGET(`/zip_codes${query}`);
};

export const getZipCode = (zipCode, accessTokenOverride) => {
  return apiGET(`/zip_codes/${zipCode}`, accessTokenOverride);
};
