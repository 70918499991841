import moment from 'moment';

export const convertUtcToLocal = utcDateTime => moment(moment.utc(utcDateTime)).local();

export const formatDateToMonthName = date => {
  if (!date) {
    return '';
  }

  return moment(date).format('MMMM YYYY');
};

export const formatDateToWeekName = date => {
  if (!date) {
    return '';
  }

  return moment(date).format('MM/DD YYYY');
};
