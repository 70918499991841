import React from 'react';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const SystemNotAvailable = () => (
  <Box mt={2}>
    <Alert severity="warning">
      <AlertTitle>
        System not available.
      </AlertTitle>
      
      The system is not available. Please contact your administrator.
    </Alert>
  </Box>
);

export default SystemNotAvailable;
