import * as pastActions from '../actions/pastRescues';
import { objectFromArray } from '../helpers';
import { sortByDateAndTime } from '../helpers/sorters';

export const initialPastRescuesState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const pastRescuesReducer = (state = initialPastRescuesState, action) => {
  switch (action.type) {
    case pastActions.REQUEST_PAST_RESCUES:
      return {
        ...state,
        inflight: true,
      };
    case pastActions.RECEIVE_PAST_RESCUES:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.pastRescues.sort(sortByDateAndTime), pr => pr.id),
        allIds: action.pastRescues.map(pr => pr.id),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default pastRescuesReducer;
