import queryString from 'query-string';
import moment from 'moment';
import { apiGET, apiPOST, apiPUT, apiDELETE, apiPATCH } from './api';

export const getSites = (site, disabled) => {
  let query = queryString.stringify({
    site,
    disabled
  });

  if (query.length) {
    query = '?' + query;
  }

  return apiGET(`/sites${query}`);
};

export const getSite = (siteId, narrow = false) => {
  let query = queryString.stringify(
    {
      narrow: narrow ? narrow : null,
    },
    {
      skipNull: true,
    }
  );

  if (query.length) {
    query = '?' + query;
  }

  return apiGET(`/sites/${siteId}${query}`);
};

export const createSite = site => {
  return apiPOST('/sites', site);
};

export const updateSite = (siteId, siteData) => {
  return apiPUT(`/sites/${siteId}`, siteData);
};

export const getSiteMetrics = (siteId, priorDateRange, dateRange) => {
  const query = queryString.stringify({
    priorDateRange: `${priorDateRange.startDate}/${priorDateRange.endDate}`,
    dateRange: `${dateRange.startDate}/${dateRange.endDate}`,
  });

  return apiGET(`/sites/${siteId}/metrics?${query}`);
};

export const getSiteInsights = (siteId) => {
  return apiGET(`/sites/${siteId}/insights`);
};

export const getSiteUsers = (siteId, roles = []) => {
  const query = queryString.stringify({
    roles: roles,
  }, { skipNull: true });

  return apiGET(`/sites/${siteId}/users${query ? `?${query}` : ''}`);
};

export const createSiteEvent = (siteId, siteEvent) => {
  return apiPOST(`/sites/${siteId}/event_specs`, siteEvent);
};

export const updateSiteEvent = (siteId, eventId, siteEvent) => {
  return apiPATCH(`/sites/${siteId}/event_specs/${eventId}`, siteEvent);
};

export const cancelSiteEvent = (siteId, eventId) => {
  return apiPATCH(`/sites/${siteId}/event_specs/${eventId}`, { cancel: true });
};

export const getSiteEvents = siteId => {
  return apiGET(`/sites/${siteId}/event_specs`);
};

export const getSiteEvent = (siteId, eventId) => {
  return apiGET(`/sites/${siteId}/event_specs/${eventId}`);
};

export const getSiteEventsList = siteId => {
  return apiGET(`/sites/${siteId}/events`)
}

export const adoptSiteEvent = (siteId, eventSpecId, data = {}) => {
  return apiPOST(`/sites/${siteId}/event_specs/${eventSpecId}/shift_registrations`, data)
};

export const deleteEventAdoption = (siteId, eventSpecId, data = {}) => {
  return apiDELETE(`/sites/${siteId}/event_specs/${eventSpecId}/shift_registrations`, data)
};

export const getSiteDonorsLocations = siteId => {
  return apiGET(`/sites/${siteId}/donor_locations`);
};

export const getSiteReceivers = siteId => {
  return apiGET(`/sites/${siteId}/receivers`);
};

export const getSiteRescuers = siteId => {
  return apiGET(`/sites/${siteId}/rescuers`);
};

export const updateSiteRescuer = (siteId, rescuerId, data) => {
  return apiPATCH(`/sites/${siteId}/rescuers/${rescuerId}`, data)
}

export const deleteSiteRescuer = (siteId, rescuerId, data) => apiDELETE(`/sites/${siteId}/rescuers/${rescuerId}`, data);

export const getSiteDonations = siteId => {
  return apiGET(`/sites/${siteId}/donations`);
};

export const getSiteRescues = (from, to, siteId) => {
  const query = queryString.stringify({
    from: from,
    to: to,
    today: moment().format('YYYYMMDD'),
  });

  return apiGET(`/sites/${siteId}/rescues?${query}`);
};

export const getSiteLeaderboards = (siteId, daterange) => {
  let query = '';

  if (daterange) {
    query = queryString.stringify({
      date_range: `${daterange.startDate}/${daterange.endDate}`,
    });
  }

  if (query.length) {
    query = `?${query}`;
  }

  return apiGET(`/sites/${siteId}/leaderboards${query}`);
};

export const getLastSiteRescueDate = (from, siteId) =>
  apiGET(`/sites/${siteId}/rescues?from=${from}&limit=1&columns=["date"]&order_by=["date,desc"]`);

export const getSitePastRescues = (siteId, from, to) => {
  const query = queryString.stringify({
    from: from,
    to: to,
  });

  return apiGET(`/sites/${siteId}/past_rescues?${query}`);
};

export const updateSiteFeatureFlags = (siteId, featureFlags) => apiPUT(`/sites/${siteId}/feature_flags`, featureFlags);

export const updateSiteSettings = (siteId, settings) => apiPUT(`/sites/${siteId}/settings`, settings);

export const sendSiteEmails = (siteId, data) => apiPOST(`/sites/${siteId}/emails`, data);

export const sendSiteSms = (siteId, data) => apiPOST(`/sites/${siteId}/sms`, data);

export const getSitePickupLocations = siteId => apiGET(`/sites/${siteId}/pickup_locations`);

export const getSitePickupSpecs = (siteId, params) => {
  const query = queryString.stringify(params);

  return apiGET(`/sites/${siteId}/pickup_specs${query ? `?${query}` : ''}`);
};

export const getSiteSms = siteId => apiGET(`/sites/${siteId}/sms`);

export const getSitesPhotos = (sites, orderBy) => {
  const query = queryString.stringify(
    {
      sites_ids: sites || null,
      order_by: Array.isArray(orderBy) && orderBy.length > 0 ? JSON.stringify(orderBy) : null,
    },
    {
      skipNull: true,
    }
  );
  return apiGET(`/photos?${query}`);
};
