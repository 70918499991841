import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { Colors } from '../../../assets/theme/Colors';
import { Schedule as ScheduleIcon, Close as CloseIcon, Link as LinkIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { formatTime } from '../../../helpers/formatters';
import ButtonWithLoading from '../../../components/ButtonWithLoading';
import useActiveUser from '../../../hooks/useActiveUser';
import { get } from 'lodash';
import { PanTool as PanToolIcon } from '@material-ui/icons';

const EventShift = ({
  index = 0,
  disabled = false,
  isLoading = false,
  isSubmitting = false,
  shift,
  handleShiftButtonClick,
  handeShiftAdoptClick,
  eventData,
  eventSpecs,
}) => {
  const activeUser = useActiveUser();
  const spotsLeft =
    isLoading || !shift
      ? undefined
      : shift.max_volunteers - get(shift, 'registrations', []).filter((r) => r.deleted_at === null).length;
  const adopterSpotsLeft =
    isLoading || !eventSpecs
      ? undefined
      : shift.max_volunteers - get(eventSpecs?.shifts[index], 'registrations', []).filter((r) => r.deleted_at === null).length;

  const shiftTime =
    isLoading && !shift ? (
      <Skeleton width={170} />
    ) : (
      `${formatTime(shift.begin.value)} - ${formatTime(shift.end.value)}`
    );
  const isSignedUp = isLoading
    ? undefined
    : get(shift, 'registrations', []).some((r) => r.deleted_at === null && r.user_id === activeUser.id);

  const isAdopter = !eventSpecs
    ? null
    : get(eventSpecs, `shifts[${index}].registrations`, []).some(
        (r) => r.deleted_at === null && r.user_id === activeUser.id
      );

  return (
    <>
      <Box display="flex" justifyContent="space-between" data-testid={`event-shift-${shift ? shift.id : index}`}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <ScheduleIcon style={{ color: Colors.appBar.main, marginRight: 10 }} />
            <Box display="flex" flexDirection="column">
              <Typography variant="body1">{shiftTime}</Typography>
              {!isLoading && spotsLeft > 0 && shift.max_volunteers && (
                <Typography variant="body2" color="textSecondary">
                  {`${spotsLeft} spot(s) left!`}
                </Typography>
              )}
              {!isLoading && spotsLeft === 0 && shift.max_volunteers && !isSignedUp && (
                <Typography variant="body2" color="textSecondary">
                  No spots left!
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" style={{ columnGap: '10px', rowGap: '10px' }}>
          <ButtonWithLoading
            startIcon={isSignedUp ? <CloseIcon /> : <PanToolIcon />}
            size="small"
            variant="contained"
            color="primary"
            disabled={disabled || isLoading || (shift.max_volunteers > 0 && spotsLeft === 0 && !isSignedUp)}
            isLoading={isSubmitting}
            onClick={() => handleShiftButtonClick(shift, eventData.id)}
          >
            {!isSignedUp ? 'Signup' : "I can't make it"}
          </ButtonWithLoading>

          {eventSpecs?.frequency > 0 && (
            <ButtonWithLoading
              startIcon={isAdopter ? <CloseIcon /> : <PanToolIcon />}
              size="small"
              variant="contained"
              color="primary"
              disabled={disabled || isLoading || (eventSpecs?.shifts[index].max_volunteers > 0 && adopterSpotsLeft === 0 && !isAdopter)}
              isLoading={isSubmitting}
              onClick={() =>
                handeShiftAdoptClick(
                  eventData.event_spec_id,
                  activeUser.id,
                  shift,
                  eventData.created_by_id,
                  eventSpecs,
                  index,
                  eventData.id
                )
              }
            >
              {!isAdopter ? 'Adopt' : 'Unadopt'}
            </ButtonWithLoading>
          )}
        </Box>
      </Box>
      <Box>
        {eventData && eventData.link && isSignedUp && (
          <Box display="flex">
            <LinkIcon style={{ color: Colors.appBar.main, marginRight: 10 }} />
            <Link
              style={{ marginLeft: 2, overflowWrap: 'anywhere' }}
              variant="body1"
              target="_blank"
              href={eventData.link}
            >
              {eventData.link}
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EventShift;
