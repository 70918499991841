import React from 'react';
import { useSelector } from 'react-redux';

const usersEquals = (prev, next) => (prev ? prev.id : null === next ? next.id : null);

const useImpersonatedUser = () => {
  return useSelector(state => state.app.impersonating, usersEquals);
};

export default useImpersonatedUser;
