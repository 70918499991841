import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import superSearch from '@codewell/super-search';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import { formatCsvFileName } from '../helpers/formatters';
import routes from '../routes';

const UmbrellaFoodDonorsTable = ({ tableId, isLoading, umbrellaFoodDonorsList }) => {
  const history = useHistory();
  const getMuiTheme = theme => createMuiTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MUIDataTableHeadCell: {
        root: {
          ...theme.overrides.MUIDataTableHeadCell.root,
          '&:last-child': {
            width: 150,
          },
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <BaseMUIDataTable
        tableId={tableId}
        title="Umbrella Food Donors"
        isLoading={isLoading}
        data={umbrellaFoodDonorsList}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              display: false,
            },
          },
          {
            name: 'name',
            label: 'Umbrella Food Donor Name',
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              filter: false,
              sort: false,
              display: true,
              customBodyRender: (value, tableMeta) => (
                <TableActionsButtonsWrapper>
                  <TableActionButton
                    title="Edit Umbrella Food Donor"
                    onClick={() => history.push(generatePath(
                      routes.umbrellaFoodDonor,
                      {
                        id: umbrellaFoodDonorsList[getMuiTableDataIndex(tableMeta)].id,
                      }
                    ))}
                  />
                </TableActionsButtonsWrapper>
              ),
            },
          },
        ]}
        options={{
          responsive: 'simple',
          customSearch: (searchQuery, currentRow) => {
            if (searchQuery.length > 2) {
              return superSearch(searchQuery.toLowerCase(), { ...currentRow }).numberOfMatches > 0;
            }
            return true;
          },
          customToolbar: () => (
            <Button
              startIcon={<Add />}
              type="button"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push(routes.umbrellaFoodDonorsAdd)}
            >
              Add New Umbrella Food Donor
            </Button>
          ),
          selectableRows: 'none',
          downloadOptions: {
            filename: formatCsvFileName('Umbrella Food Donors'),
          },
        }}
      />
    </MuiThemeProvider>
  );
};

export default UmbrellaFoodDonorsTable;
