import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Colors } from '../assets/theme/Colors';
import merge from 'lodash/merge';
import FABContext from '../context/FABContext/FABContext';
import { hasFABAvailableActions } from '../helpers/fab';

const useStyles = customStyles =>
  makeStyles(theme =>
    merge(
      {
        speedDial: {
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        },
        fab: {
          backgroundColor: Colors.appBar.main,
          '&:hover': {
            backgroundColor: Colors.appBar.light,
          },
        },
        staticTooltipLabel: {
          color: 'black',
          whiteSpace: 'nowrap',
        },
      },
      customStyles
    )
  );

const FABButton = () => {
  const fabContext = useContext(FABContext)
  const classes = useStyles(fabContext.styles)();

  const [open, setOpen] = React.useState(false);

  const handleClose = action => {
    setOpen(false);

    if (action.onClick) {
      action.onClick();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FABContext.Consumer>
      {({ actions, isVisible }) => {
        return (
          <>
            {isVisible && hasFABAvailableActions(actions) && (
              <SpeedDial
                data-testid="fab-button"
                data-introid="fab-button"
                ariaLabel="SpeedDial"
                classes={{
                  root: classes.speedDial,
                  fab: classes.fab,
                }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
              >
                {Object.values(actions)
                  .map(key =>
                    key.map((action, index) => (
                      <SpeedDialAction
                        classes={{
                          staticTooltipLabel: classes.staticTooltipLabel,
                        }}
                        tooltipOpen
                        key={action.name}
                        data-testid={action.testid || `spedDial-option-${index}`}
                        title={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => handleClose(action)}
                      />
                    )),
                  )}
              </SpeedDial>
            )}
          </>
        );
      }}
    </FABContext.Consumer>
  );
};

export default FABButton;
