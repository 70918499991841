import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import {  useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { BorderColor as BorderColorIcon, Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Bluebird from 'bluebird';
import DonationsTable from '../components/DonationsTable';
import * as sitesActions from '../actions/sites';
import routes from '../routes';
import useActiveSite from '../hooks/useActiveSite';
import DonationsBulkEditReviewAndSave from '../components/DonationsBulkEditReviewAndSave';
import useNotificationService from '../hooks/useNotificationService';
import { updateDonations } from '../actions/sites';
import { fetchRescueSizesIfNeeded } from '../actions/rescueSizes';
import { fetchSystemSettings } from '../actions/systemSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    flexGrow: 1,
  },
  tableContainer: {
    height: 320,
  },
  table: {
    minWidth: 700,
  },
  chipStrip: {
    paddingBottom: '5px',
  },
  breadcrumbs: {
    padding: theme.spacing(1),
  },
}));

const DonationsListView = () => {
  const classes = useStyles();
  const site = useActiveSite();
  const dispatch = useDispatch();
  const history = useHistory();
  const { addSuccessNotification, addErrorNotification } = useNotificationService();
  const siteDonations = useSelector((state) => state.entities.sites.donations);
  const siteDonationsBySiteId = siteDonations.bySiteId[site.id] || [];
  const rescueSizes = useSelector((state) => state.entities.rescueSizes);
  const [editedDonations, setEditedDonations] = useState([]);
  const [inReview, setInReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(sitesActions.fetchSiteDonationsIfNeeded(site.id));
    dispatch(fetchRescueSizesIfNeeded());
    dispatch(fetchSystemSettings());
  }, [site]);

  const renderCustomTableToolbar = ({
    handleEditClick,
    editMode,
    showEditRescueButton = true,
    hasActiveBulkEditFeatureFlag = true,
    handleReviewClick,
    reviewButtonEnabled,
  }) => (
    <>
      {!editMode && showEditRescueButton && hasActiveBulkEditFeatureFlag && (
        <Tooltip title="Edit Mode">
          <IconButton onClick={handleEditClick}>
            <BorderColorIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {editMode && (
        <Box display="flex" justifyContent="flex-end" flexWrap="no-wrap">
          <Button onClick={handleEditClick}>Exit Edit Mode</Button>
          <Button
            disabled={!reviewButtonEnabled}
            onClick={handleReviewClick}
            style={{ marginLeft: '1rem' }}
            variant="contained"
            color="primary"
          >
            Review and Save
          </Button>
        </Box>
      )}
      {!editMode && (
        <Button
          startIcon={<Add />}
          type="button"
          variant="contained"
          color="primary"
          size="small"
          onClick={() => history.push(routes.donation_add_new)}
          data-testid="add-donation"
        >
          Add donation
        </Button>
      )}
    </>
  );

  const scrollTop = () => window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  const handleSaveClick = (data) => {
    setIsLoading(true);
    const updatedData = data.map((d) => d.newData);
    return Bluebird.try(() =>
      dispatch(updateDonations(updatedData, site.id)).then(() => {
        setInReview(false);
        setEditMode(false);
        setEditedDonations([]);
        setIsLoading(false);
        addSuccessNotification('Donation(s) updated.');
        scrollTop();
      })
    ).catch((error) => {
      setIsLoading(false);
      addErrorNotification(error, error.message);
      scrollTop();
    });
  };

  if (siteDonations.inflight === undefined || siteDonations.inflight || rescueSizes.inflight) {
    return <div>fetching donations</div>;
  }

  const siteDonationsWithoutApprovedRequest = siteDonationsBySiteId.filter(
    (donation) => !(donation.verification_status === 'approved' && donation.isRequest === true)
  );

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        <Typography color="textPrimary">Donations</Typography>
      </Breadcrumbs>

      {inReview && (
        <DonationsBulkEditReviewAndSave
          onExit={() => setInReview(false)}
          onSave={handleSaveClick}
          dialogOpen={inReview}
          editedDonations={editedDonations}
          donations={siteDonationsBySiteId}
          isLoading={isLoading}
        />
      )}

      <Grid container>
        <Grid item xs={12}>
          <DonationsTable
            tableId="#donations/donations"
            customToolbar={renderCustomTableToolbar}
            donations={siteDonationsWithoutApprovedRequest}
            handleReviewClick={() => setInReview(true)}
            onEditClick={id => history.push(generatePath(routes.donation_edit, { donationId: id }))}
            onReviewClick={id => history.push(generatePath(routes.donation_request_verify, { donationId: id }))}
            onLocationClick={(donorId, locationId) =>
              history.push(generatePath(routes.foodDonor, { foodDonorId: locationId }))
            }
            customFilters={{ showPendingRegistrationFilter: true }}
            editedDonations={editedDonations}
            setEditedDonations={setEditedDonations}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DonationsListView;
