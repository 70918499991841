import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import * as authService from '../services/auth';
import * as uiPersistedActions from '../actions/uiPersisted';
import { fetchRescuerPastRescuesIfNeeded } from '../actions/rescues';
import useActiveUser from '../hooks/useActiveUser';
import { getRecentRescues } from '../helpers/RescuesHelper';
import routes from '../routes';

const CloseRescueReminderNotification = () => {
  const user = authService.getCurrentlyLoggedInOrImpersonatingUser();

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#e1ebfd',
      width: '100%',
      paddingLeft: () => {
        if (user && !authService.shouldHideMainDrawer(user)) {
          return 70;
        }

        return 16;
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0px !important',
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeUser = useActiveUser();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: null,
  });
  const closeRescueReminderNotificationLastDismissDate = useSelector(
    (state) => state.ui.persisted.closeRescueReminderNotificationLastDismissDate
  );
  const rescuesEntities = useSelector((state) => state.entities.rescues);
  const recentRescues = getRecentRescues(activeUser.id, rescuesEntities);
  const unresolvedRecentRescues = recentRescues.filter(
    (rescue) => rescue.completed === null && rescue.cancelled_by_id === null
  );

  useEffect(() => {
    dispatch(fetchRescuerPastRescuesIfNeeded(activeUser.id, false));
  }, []);

  if (!unresolvedRecentRescues.length) {
    return null;
  }

  if (
    closeRescueReminderNotificationLastDismissDate &&
    moment(closeRescueReminderNotificationLastDismissDate).isAfter(unresolvedRecentRescues[0].date)
  ) {
    return null;
  }

  const onDismiss = () => {
    dispatch(uiPersistedActions.dismissCloseRescueReminderNotification());
  };

  const onRescueClose = () => {
    dispatch(uiPersistedActions.dismissCloseRescueReminderNotification());

    if (unresolvedRecentRescues.length > 1) {
      if (!isMobileView) {
        history.push(generatePath(routes.dashboardRescuer));
        return setTimeout(() => {
          const yOffset = -120;
          const element = document.getElementById('recent-rescues');
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 500);
      }
      history.push(generatePath(routes.mobileDashboardRescues));
      return setTimeout(() => {
        const element = document.getElementById('recent-rescues');
        element.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }

    return history.push(generatePath(routes.rescueClose, { rescueId: unresolvedRecentRescues[0].id }));
  };

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: 0 }} data-testid="close-rescue-reminder-card">
        <div className={classes.title}>
          You have unclosed {unresolvedRecentRescues.length > 1 ? 'rescues' : 'rescue'}
        </div>

        <div className={classes.body}>
          {unresolvedRecentRescues.length === 1
            ? 'Click Close Rescue button to close it.'
            : 'Go to Recent Rescues to close all your unclosed rescues.'}
        </div>
      </CardContent>

      <CardActions>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={onRescueClose}
          data-testid="resend-email-button"
        >
          {unresolvedRecentRescues.length > 1 ? 'Recent Rescues' : 'Close Rescue'}
        </Button>

        <Button size="small" color="primary" onClick={onDismiss}>
          Do this later
        </Button>
      </CardActions>
    </Card>
  );
};

export default CloseRescueReminderNotification;
