import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import Bluebird from 'bluebird';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Typography, Grid, Paper } from '@material-ui/core';
import routes from '../routes';
import * as appActions from '../actions/app';
import * as sitesActions from '../actions/sites';
import * as zipCodesActions from '../actions/zipCodes';
import * as settingsActions from '../actions/settings';
import SiteForm from '../components/SiteForm';
import OverlayLoader from '../components/OverlayLoader';
import SiteFeatureFlags from '../components/Sites/SiteFeatureFlags';
import SettingsEditForm from '../components/SettingsEditForm';
import * as authServices from '../services/auth';
import { Roles } from '../models/roles';
import snackbarHelper from '../helpers/snackbarHelper';

class SiteEditView extends Component {
  componentDidMount() {
    const { fetchSite, fetchSettings, match } = this.props;

    fetchSite(match.params.id);
    fetchSettings();
  }

  submitCallback = (siteId, siteData) => {
    const { updateSite, history } = this.props;

    return Bluebird
      .try(() => updateSite(siteId, siteData))
      .then(() => snackbarHelper.success(`Site updated successfully!`))
      .then(() => history.push(routes.sites))
      .catch(err => {
        snackbarHelper.error(err.message);

        throw err;
      });
  };

  updateSiteFeatureFlags = (siteId, featureFlags) => {
    const { updateSiteFeatureFlags, setSiteFeatureFlags, history } = this.props;

    return Bluebird
      .try(() => updateSiteFeatureFlags(siteId, { feature_flags: featureFlags }))
      .then(() => {
        snackbarHelper.success('Site feature flags updated successfully!');

        setSiteFeatureFlags(featureFlags);

        return history.push(routes.sites);
      })
      .catch(err => {
        snackbarHelper.error(err.message);

        throw err;
      });
  };

  updateSiteSettings = (siteId, settings) => {
    const { updateSiteSettings, history } = this.props;

    return Bluebird
      .try(() => updateSiteSettings(siteId, { settings }))
      .then(() => snackbarHelper.success('Site settings updated successfully!'))
      .then(() => history.push(routes.sites))
      .catch(err => {
        snackbarHelper.error(err.message);

        throw err;
      });
  };

  render() {
    const { sites, users, featureFlags, settings, fetchZipCodes, classes, match } = this.props;
    const isLoading = sites.inflight || users.inflight || featureFlags.inflight || settings.inflight;
    const site = sites.byId[match.params.id];
    const currentlyLoggedInOrImpersonatingUserIsAdmin = authServices
      .currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([Roles.Admin]);
    const currentlyLoggedInOrImpersonatingUserIsAdminOrNSD = authServices
      .currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([Roles.Admin, Roles.NationalSiteDirector]);

    if (isLoading || !site) {
      return 'Loading...';
    }

    const featureFlagsList = featureFlags.allIds.map(featureFlagId => featureFlags.byId[featureFlagId]);
    const settingsList = settings.allIds.map(settingId => settings.byId[settingId]);

    settingsList.map(setting => {
      const foundSiteSetting = site.settings.find(s => s.id === setting.id);

      setting.value = foundSiteSetting ? foundSiteSetting.value : '0';
    });

    const hasCustomBranding = settingsList.find((setting) => setting.name === 'custom_branding')?.value === '1';

    return (
      <>
        <Breadcrumbs aria-label="Breadcrumbs">
          <Link color="inherit" to={routes.sites}>
            Sites
          </Link>

          <Typography color="textPrimary">
            Edit Site
          </Typography>
        </Breadcrumbs>

        <OverlayLoader isLoading={isLoading}>
          <Grid spacing={2} container direction="row" justify="flex-start">
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Paper className={classes.paper}>
                <SiteForm
                  site={site}
                  fetchZipCodes={fetchZipCodes}
                  showDisabledCheckbox={currentlyLoggedInOrImpersonatingUserIsAdminOrNSD}
                  submitCallback={this.submitCallback}
                  hasCustomBranding={hasCustomBranding}
                />
              </Paper>
            </Grid>

            {currentlyLoggedInOrImpersonatingUserIsAdmin && (
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <Paper className={classes.paper}>
                      <SiteFeatureFlags
                        site={site}
                        featureFlags={featureFlagsList}
                        submitCallback={this.updateSiteFeatureFlags}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <SettingsEditForm
                      site={site}
                      settings={settingsList}
                      title="Site's settings"
                      useTopButtonsLayout
                      onSubmit={this.updateSiteSettings}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </OverlayLoader>
      </>
    );
  }
}

const mapStateToProps = ({ entities: { sites, users, featureFlags, settings } }) => ({
  sites,
  users,
  featureFlags,
  settings,
});
const mapDispatchToProps = dispatch => ({
  fetchSite: siteId => dispatch(sitesActions.fetchSite(siteId)),
  fetchZipCodes: zipCode => dispatch(zipCodesActions.fetchZipCodesIfNeeded(zipCode)),
  fetchSettings: () => dispatch(settingsActions.fetchSettingsIfNeeded()),
  updateSite: (siteId, siteData) => dispatch(sitesActions.updateSite(siteId, siteData)),
  updateSiteFeatureFlags: (siteId, featureFlags) => dispatch(sitesActions.updateSiteFeatureFlags(siteId, featureFlags)),
  updateSiteSettings: (siteId, settings) => dispatch(sitesActions.updateSiteSettings(siteId, settings)),
  setSiteFeatureFlags: featureFlags => dispatch(appActions.setSiteFeatureFlags(featureFlags)),
});
const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SiteEditView);
