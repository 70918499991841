import queryString from 'query-string';
import { apiGET } from './api';

export const getEmails = (sitesIds = [], type = null, userId = null) => {
  const query = queryString.stringify(
    {
      sites_ids: Array.isArray(sitesIds) && sitesIds.length ? sitesIds : null,
      type: type,
      user_id: userId,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/emails?${query}`);
};

export const getEmail = (emailId) => apiGET(`/emails/${emailId}`);
