import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getFormFieldError, isFormFieldInvalid } from '../../helpers/validators';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

// use this component with setFieldData rff mutator
const FRUSTextFieldWrapper = ({input: {name, onChange, onFocus, value, ...restInput}, meta, classes, ...rest}) => {
  const showError = isFormFieldInvalid(meta);

  return (
    <TextField
      {...rest}
      name={name}
      helperText={showError ? getFormFieldError(meta) : undefined}
      error={showError}
      inputProps={{
        ...restInput,
        autoComplete: 'chrome-off',
      }}
      className={classNames({
        [classes.invalidField]: showError,
      })}
      onFocus={onFocus}
      onChange={event => {
        meta.data.error = undefined;
        if (onChange) {
          onChange(event);
        }
      }}
      value={value}
    />
  );
};

const styles = () => ({
  invalidField: {
    background: '#f443360f',
    borderRadius: 2,
  },
});

export default withStyles(styles)(FRUSTextFieldWrapper);
