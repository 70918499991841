import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import NotesTypeField from './NotesTypeField';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Colors } from '../../assets/theme/Colors';

const DropoffNotes = props => {
  const { fieldProps, tooltipText } = props;
  return (
    <Grid container direction="row" justify="space-between" alignItems="center" spacing={1}>
      <Grid item xs>
        <Field
          name="dropoff_notes"
          fullWidth
          multiline
          rowsMax={5}
          component={NotesTypeField}
          type="textarea"
          variant="outlined"
          label="Dropoff Notes"
          {...fieldProps}
        />
      </Grid>
      {tooltipText && (
        <Grid item xs={1}>
          <Tooltip arrow placement="top" title={tooltipText}>
            <HelpIcon htmlColor={Colors.blue.main} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default DropoffNotes;
