import Bluebird from 'bluebird';
import { getFoodDescriptionUnitLbs } from '../api/foodDescriptionUnitLbs';
import { shouldFetch } from '../helpers/cache';

export const REQUEST_FOOD_DESCRIPTION_UNIT_LBS = 'REQUEST_FOOD_DESCRIPTION_UNIT_LBS';
export const RECEIVE_FOOD_DESCRIPTION_UNIT_LBS = 'RECEIVE_FOOD_DESCRIPTION_UNIT_LBS';
export const REQUEST_FOOD_DESCRIPTION_UNIT_LBS_FAILED = 'REQUEST_FOOD_DESCRIPTION_UNIT_LBS_FAILED';

export const requestFoodDescriptionUnitLbs = () => ({
  type: REQUEST_FOOD_DESCRIPTION_UNIT_LBS,
});

export const receiveFoodDescriptionUnitLbs = (fd) => ({
  type: RECEIVE_FOOD_DESCRIPTION_UNIT_LBS,
  foodDescriptionUnitLbs: fd,
  receivedAt: Date.now(),
});

export const fetchFoodDescriptionUnitLbsFailed = () => ({
  type: REQUEST_FOOD_DESCRIPTION_UNIT_LBS_FAILED,
});

export const fetchFoodDescriptionUnitLbs = () => (dispatch) => {
  dispatch(requestFoodDescriptionUnitLbs());

  return Bluebird.try(() => getFoodDescriptionUnitLbs())
    .then((res) => res.json())
    .then((json) => dispatch(receiveFoodDescriptionUnitLbs(json.data)))
    .catch(() => dispatch(fetchFoodDescriptionUnitLbsFailed()));
};

const shouldFetchFoodDescriptionUnitLbs = (state) => {
  const { foodDescriptionUnitLbs } = state.entities;

  if (foodDescriptionUnitLbs.all.length === 0) {
    return true;
  }

  if (foodDescriptionUnitLbs.inflight) {
    return false;
  }

  return shouldFetch(foodDescriptionUnitLbs.lastUpdated, { hours: 6 });
};

export const fetchFoodDescriptionUnitLbsIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchFoodDescriptionUnitLbs(getState())) {
    return dispatch(fetchFoodDescriptionUnitLbs());
  }

  return null;
};
