import { objectFromArray } from '../helpers';
import { get } from 'lodash';
import * as rescuersActions from '../actions/requestDonations';

export const REQUEST_DONATIONS_INITIAL_STATE = {
  byId: {},
  allIds: [],
  inflight: false,
};

const requestDonationsReducer = (state = REQUEST_DONATIONS_INITIAL_STATE, action) => {
  switch (action.type) {
    case rescuersActions.REQUEST_REQUEST_DONATION:
      return {
        ...state,
        inflight: true,
      };
    case rescuersActions.RECEIVE_REQUEST_DONATIONS:
      const receiveDonationData = action.data.map(requestDonation => ({
        ...requestDonation,
        created_at: get(requestDonation, 'created_at.value'),
        donation: get(requestDonation, 'slug'),
        start_date: get(requestDonation, 'start_date.value'),
        end_date: get(requestDonation, 'end_date.value'),
        resume_date: get(requestDonation, 'resume_date.value'),
        pause_date: get(requestDonation, 'pause_date.value'),
      }));
      return {
        ...state,
        inflight: false,
        allIds: action.data.map(r => r.id),
        byId: objectFromArray(receiveDonationData, r => r.id),
        lastUpdated: action.receivedAt,
      };
    case rescuersActions.RECEIVE_REQUEST_DONATION:
      return {
        ...state,
        inflight: false,
        allIds: [...state.allIds, action.data.id],
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        },
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default requestDonationsReducer;
