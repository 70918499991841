import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../routes';
import { resetDefaultDashboard } from '../actions/uiPersisted';

const AccessDenied = () => {
  const [isDispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const defaultDashboard = useSelector(state => state.ui.persisted.defaultDashboard);

  // reset dashboard before redirect to prevent from re-redirect loop
  useEffect(() => {
    dispatch(resetDefaultDashboard());
  }, [dispatch, resetDefaultDashboard]);

  // subscribe for defaultDashboard
  useEffect(() => {
    setDispatched(defaultDashboard === undefined);
  }, [defaultDashboard]);

  // wait for default dashboard to be cleared before redirect to index
  if (isDispatched) {
    return <Redirect to={routes.index} />;
  }

  return null;
};

export default AccessDenied;
