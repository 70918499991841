import Bluebird from 'bluebird';
import * as metricsApi from '../api/metrics';

export const REQUEST_METRICS = 'REQUEST_METRICS';
export const RECEIVE_METRICS = 'RECEIVE_METRICS';

export const requestMetrics = () => ({
  type: REQUEST_METRICS,
});

export const receiveMetrics = metrics => ({
  type: RECEIVE_METRICS,
  metrics: metrics,
  receivedAt: Date.now(),
});

export const fetchMetrics = (priorDateRange, dateRange, sitesIds = []) => dispatch => {
  dispatch(requestMetrics());

  return Bluebird
    .try(() => metricsApi.getMetrics(priorDateRange, dateRange, sitesIds))
    .then(res => res.json())
    .then(json => dispatch(receiveMetrics(json.data)));
};

export const fetchMetricsIfNeeded = (priorDateRange, dateRange, sitesIds = []) => dispatch => dispatch(
  fetchMetrics(sitesIds)
);
