import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Box, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import TableToCSVGenerator from './TableToCSVGenerator';
import { formatDate, formatNumber, formatValueOfMeals } from '../helpers/formatters';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { orderBy as _orderBy, map as _map, get as _get } from 'lodash';
import { Colors } from '../assets/theme/Colors';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
  root: {
    borderCollapse: 'separate',
  },
  rowTotal: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  tableTotalRow: {
    fontWeight: 600,
  },
  oddColumn: {
    background: `${indigo[50]} !important`,
  },
  diffOdd: {
    color: `${Colors.tableDiff.odd.contrastText} !important`,
    '-moz-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.odd.color}`,
    '-webkit-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.odd.color}`,
    boxShadow: `inset 0px 0px 1px 100px ${Colors.tableDiff.odd.color}`,
  },
  diffEven: {
    color: `${Colors.tableDiff.even.contrastText} !important`,
    '-moz-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.even.color}`,
    '-webkit-box-shadow': `inset 0px 0px 1px 100px ${Colors.tableDiff.even.color}`,
    boxShadow: `inset 0px 0px 1px 100px ${Colors.tableDiff.even.color}`,
  },
  periodStickyHeader: {
    top: 0,
    position: 'sticky',
    zIndex: 1,
    background: '#fff',
  },
  pivot: {
    '& table': {
      '& thead': {
        '& th': {
          fontWeight: 600,
          padding: '3px 12px',
          whiteSpace: 'nowrap',
        },
      },
      '& tbody': {
        '& td': {
          padding: '3px 12px',
          borderBottom: 'none',
        },
        '& td:first-child': {
          minWidth: 200,
        },
      },
    },
  },
  descriptionsHeader: {
    borderBottom: 'none',
  },
  tableContainer: {
    height: 'calc(100vh - 510px)',
    overflow: 'scroll',
    '&&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '0.65rem',
      height: '0.65rem',
    },
    '&&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
    '@media (max-height: 680px)': {
      height: '50vh',
    },
  },
  totalDeltaColumn: {
    borderLeft: `2px solid ${indigo[300]}`,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  measureStickyHeader: {
    top: 72,
    position: 'sticky',
    zIndex: 1,
    background: '#fff',
    boxShadow: 'inset 0 -2px 0px 0px #7986cb',
    '& span': {
      userSelect: 'none',
    },
  },
  sortByCell: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    cursor: 'pointer',
  },
  footerTotalColumn: {
    left: 0,
    position: 'sticky',
  },
  entityTypeColumn: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    background: '#fff',
    boxShadow: '2px 0 0px 0px #7986cb',
    left: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 250,
  },
  siteColumnHead: {
    position: 'sticky',
    top: 0,
    left: 0,
    background: '#fff',
    zIndex: 2,
    boxShadow: 'inset 0 -2px 0px 0px #7986cb',
  },
  siteColumn: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'sticky',
    left: 0,
    background: '#fff',
  },
  entityTypeColumnHead: {
    position: 'sticky',
    top: 0,
    left: 200,
    background: '#fff',
    boxShadow: '2px 0 0px 0px #7986cb, inset 0 -2px 0px 0px #7986cb',
    zIndex: 2,
  },
  columnBoxShadow: {
    boxShadow: '2px 0 0px 0px #7986cb',
  },
  columnBoxHead: {
    boxShadow: '2px 0 0px 0px #7986cb, inset 0 -2px 0px 0px #7986cb',
  },
  totalDeltaHeader: {
    minWidth: 215,
    textAlign: 'center',
  },
  entityTypeStickyHeader: {
    top: 36,
    position: 'sticky',
    zIndex: 1,
    background: '#fff',
  },
  entityTypeSubheader: {
    top: 72,
    position: 'sticky',
    zIndex: 1,
    background: '#fff',
  },
}));

const VolumeReportTable = ({ type, volume, showSiteColumn }) => {
  const tableRef = useRef();
  const classes = useStyles();
  const [volumeReport, setVolumeReport] = useState(volume);
  const [orderBy, setOrderBy] = useState({
    period: null,
    entityType: 'site',
    measure: null,
    order: 'asc',
  });

  useEffect(() => {
    setVolumeReport(volume);
  }, [volume]);

  if (!volumeReport.sites) {
    return (
      <Box my={2}>
        <Paper className={classNames(classes.paper, classes.pivot)}>
          Please use "RUN" button to generate the report.
        </Paper>
      </Box>
    );
  }

  const renderCount = (count, format = 'number') => {
    if (format === 'value') {
      return formatValueOfMeals(count);
    }

    return formatNumber(count);
  };

  const renderDelta = delta => formatNumber(delta);

  const renderDeltaPercentage = deltaPercentage =>
    deltaPercentage !== null ? `${Number(deltaPercentage.toPrecision(2))}%` : 'n/a';

  const renderVolumeReportEntityType = type => {
    if (type && type.id === 'food_donors') {
      return 'Food Donor';
    } else if (type && type.id === 'receivers') {
      return 'Receiver';
    }

    return 'Rescuer';
  };

  const handleSortBy = (period, entityType, measure) => {
    const foundOrderBy =
      orderBy.period === period && orderBy.entityType === entityType && orderBy.measure === measure
        ? orderBy.order
        : null;
    const order = foundOrderBy ? (foundOrderBy === 'asc' ? 'desc' : 'asc') : 'asc';

    setOrderBy({
      period: period,
      entityType: entityType,
      measure: measure,
      order: order,
    });

    if (entityType === 'site') {
      setVolumeReport({
        ...volumeReport,
        sites: _orderBy(volumeReport.sites, row => _get(row, 'site.name', '').toUpperCase(), order),
      });
    } else {
      setVolumeReport({
        ...volumeReport,
        sites: _map(
          volumeReport.sites,
          row => {
            if (measure !== null) {
              if (period !== null) {
                return {
                  ...row,
                  entities: _orderBy(
                    row.entities,
                    entity => {
                      const value = _get(entity, `periods[${period}][${entityType}][${measure}]`, null);
                      return value !== null ? value : -1;
                    },
                    order
                  ),
                };
              }

              return {
                ...row,
                entities: _orderBy(
                  row.entities,
                  entity => {
                    const value = _get(entity, `total[${entityType}][${measure}]`, null);
                    return value !== null ? value : -1;
                  },
                  order
                ),
              };
            }

            // sort by entityType (Rescuers|Receivers|Food Donors)
            return {
              ...row,
              entities: _orderBy(row.entities, entity => _get(entity, 'entity.name', '').toUpperCase(), order),
            };
          },
          order
        ),
      });
    }

    setOrderBy({
      period: period,
      entityType: entityType,
      measure: measure,
      order: order,
    });
  };

  const renderSortBy = (index, entityType, measure, label) => (
    <Box
      onClick={() => handleSortBy(index, entityType, measure)}
      className={classNames({
        [classes.sortByCell]: true,
        [classes.justifyContentCenter]: ['site', 'food_donors', 'rescuers', 'receivers'].indexOf(entityType) === -1,
      })}
    >
      <>
        <span>
          {measure === 'count' && '#'}

          {measure === 'delta' && 'Δ'}

          {measure === 'delta_percentage' && 'Δ%'}

          {label !== null && label}
        </span>

        {orderBy.period === index && orderBy.entityType === entityType && orderBy.measure === measure && (
          <>{orderBy.order === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}</>
        )}
      </>
    </Box>
  );

  const renderRow = (rowIndex, site, siteVolumeReport) => {
    const _renderRowEntity = (rowIndex, index2, siteVolumeReportEntity, showBorder = false, format = false, isOdd = null) => (
      <>
        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.oddColumn]: rowIndex % 2,
            [classes.diffOdd]: isOdd === true,
            [classes.diffEven]: isOdd === false,
            [classes.totalDeltaColumn]: showBorder,
          })}
          data-period={index2}
        >
          {renderCount(siteVolumeReportEntity.count, format)}
        </TableCell>

        {index2 > 0 && (
          <>
            <TableCell
              variant="head"
              align="right"
              className={classNames({
                [classes.oddColumn]: rowIndex % 2,
                [classes.diffOdd]: isOdd === true,
                [classes.diffEven]: isOdd === false,
              })}
            >
              {renderDelta(siteVolumeReportEntity.delta, format)}
            </TableCell>

            <TableCell
              variant="head"
              align="right"
              className={classNames({
                [classes.oddColumn]: rowIndex % 2,
                [classes.diffOdd]: isOdd === true,
                [classes.diffEven]: isOdd === false,
              })}
            >
              {renderDeltaPercentage(siteVolumeReportEntity.delta_percentage)}
            </TableCell>
          </>
        )}
      </>
    );

    const _renderRowEntityTotal = (rowIndex, siteVolumeReportEntityTotal, showBorder = false, format = false, isOdd = null) => (
      <>
        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.oddColumn]: rowIndex % 2,
            [classes.totalDeltaColumn]: showBorder,
            [classes.diffOdd]: isOdd === true,
            [classes.diffEven]: isOdd === false,
          })}
        >
          {renderDelta(siteVolumeReportEntityTotal.delta, format)}
        </TableCell>

        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.oddColumn]: rowIndex % 2,
            [classes.diffOdd]: isOdd === true,
            [classes.diffEven]: isOdd === false,
          })}
        >
          {renderDeltaPercentage(siteVolumeReportEntityTotal.delta_percentage)}
        </TableCell>
      </>
    );

    return (
      <TableRow>
        {showSiteColumn ? (
          <>
            <TableCell
              className={classNames({
                [classes.oddColumn]: rowIndex % 2,
                [classes.siteColumn]: true,
              })}
            >
              {site.name}
            </TableCell>
            <TableCell
              className={classNames({
                [classes.oddColumn]: rowIndex % 2,
                [classes.entityTypeColumn]: true,
              })}
            >
              {siteVolumeReport.entity.name}
            </TableCell>
          </>
        ) : (
          <TableCell
            className={classNames({
              [classes.oddColumn]: rowIndex % 2,
              [classes.siteColumn]: true,
              [classes.columnBoxShadow]: true,
            })}
          >
            {siteVolumeReport.entity.name}
          </TableCell>
        )}

        <>
          {volumeReport.periods.map((row, index2) => (
            <React.Fragment key={index2}>
              {_renderRowEntity(rowIndex, index2, siteVolumeReport.periods[index2].rescues, index2 > 0)}

              {_renderRowEntity(rowIndex, index2, siteVolumeReport.periods[index2].meals)}

              {_renderRowEntity(rowIndex, index2, siteVolumeReport.periods[index2].lbs)}

              {_renderRowEntity(rowIndex, index2, siteVolumeReport.periods[index2].value, false, 'value')}
            </React.Fragment>
          ))}

          {_renderRowEntityTotal(rowIndex, siteVolumeReport.total.rescues, true)}

          {_renderRowEntityTotal(rowIndex, siteVolumeReport.total.meals)}

          {_renderRowEntityTotal(rowIndex, siteVolumeReport.total.lbs)}

          {_renderRowEntityTotal(rowIndex, siteVolumeReport.total.value, false, 'value')}
        </>
      </TableRow>
    );
  };

  const renderEntitySubColumn = (index, entityType, showBorder = false, isOdd = null) => (
    <>
      <TableCell
        variant="head"
        align="center"
        className={classNames({
          [classes.measureStickyHeader]: true,
          [classes.descriptionsHeader]: true,
          [classes.totalDeltaColumn]: showBorder,
          [classes.diffOdd]: isOdd === true,
          [classes.diffEven]: isOdd === false,
        })}
      >
        {renderSortBy(index, entityType, 'count')}
      </TableCell>

      {index > 0 && (
        <>
          <TableCell
            variant="head"
            align="center"
            className={classNames({
              [classes.measureStickyHeader]: true,
              [classes.descriptionsHeader]: true,
              [classes.diffOdd]: isOdd === true,
              [classes.diffEven]: isOdd === false,
            })}
          >
            {renderSortBy(index, entityType, 'delta')}
          </TableCell>

          <TableCell
            variant="head"
            align="center"
            className={classNames({
              [classes.measureStickyHeader]: true,
              [classes.descriptionsHeader]: true,
              [classes.diffOdd]: isOdd === true,
              [classes.diffEven]: isOdd === false,
            })}
          >
            {renderSortBy(index, entityType, 'delta_percentage')}
          </TableCell>
        </>
      )}
    </>
  );

  const renderEntitySubColumnTotal = (entityType, showBorder = false, isOdd = null) => (
    <>
      <TableCell
        variant="head"
        align="center"
        className={classNames({
          [classes.measureStickyHeader]: true,
          [classes.descriptionsHeader]: true,
          [classes.totalDeltaColumn]: showBorder,
          [classes.diffOdd]: isOdd === true,
          [classes.diffEven]: isOdd === false,
        })}
      >
        {renderSortBy(null, entityType, 'delta')}
      </TableCell>

      <TableCell
        variant="head"
        align="center"
        className={classNames({
          [classes.measureStickyHeader]: true,
          [classes.descriptionsHeader]: true,
          [classes.diffOdd]: isOdd === true,
          [classes.diffEven]: isOdd === false,
        })}
      >
        {renderSortBy(null, entityType, 'delta_percentage')}
      </TableCell>
    </>
  );

  const renderEntityColumn = (index, entityName, showBorder = false, isOdd = null) => (
    <TableCell
      colSpan={index > 0 ? 3 : 1}
      variant="head"
      align="center"
      className={classNames({
        [classes.entityTypeStickyHeader]: true,
        [classes.descriptionsHeader]: true,
        [classes.totalDeltaColumn]: showBorder,
        [classes.diffOdd]: isOdd === true,
        [classes.diffEven]: isOdd === false,
      })}
    >
      {entityName}
    </TableCell>
  );

  const renderEntityColumnTotal = (entityName, showBorder = false, isOdd = null) => (
    <TableCell
      colSpan={2}
      variant="head"
      align="center"
      className={classNames({
        [classes.entityTypeStickyHeader]: true,
        [classes.descriptionsHeader]: true,
        [classes.totalDeltaColumn]: showBorder,
        [classes.diffOdd]: isOdd === true,
        [classes.diffEven]: isOdd === false,
      })}
    >
      {entityName}
    </TableCell>
  );

  const renderSiteTotalRow = (site, total, periods) => {
    const _renderTotalRowEntityPeriod = (index, siteVolumeReportEntityTotalPeriod, format = false) => (
      <>
        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          {renderCount(siteVolumeReportEntityTotalPeriod.count, format)}
        </TableCell>

        {index > 0 && (
          <>
            <TableCell
              variant="head"
              align="right"
              className={classNames({
                [classes.rowTotal]: true,
                [classes.tableTotalRow]: true,
              })}
            >
              {renderDelta(siteVolumeReportEntityTotalPeriod.delta, format)}
            </TableCell>

            <TableCell
              variant="head"
              align="right"
              className={classNames({
                [classes.rowTotal]: true,
                [classes.tableTotalRow]: true,
              })}
            >
              {renderDeltaPercentage(siteVolumeReportEntityTotalPeriod.delta_percentage)}
            </TableCell>
          </>
        )}
      </>
    );

    const _renderTotalRowEntityTotal = (siteVolumehReportEntityTotal, format = false) => (
      <>
        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          {renderDelta(siteVolumehReportEntityTotal.delta, format)}
        </TableCell>

        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          {renderDeltaPercentage(siteVolumehReportEntityTotal.delta_percentage)}
        </TableCell>
      </>
    );

    return (
      <TableRow>
        <TableCell
          colSpan={2}
          variant="head"
          className={classNames({
            [classes.footerTotalColumn]: true,
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          Total - {site.name}
        </TableCell>

        <>
          {periods.map((row, index) => (
            <React.Fragment key={index}>
              {_renderTotalRowEntityPeriod(index, row.rescues)}

              {_renderTotalRowEntityPeriod(index, row.meals)}

              {_renderTotalRowEntityPeriod(index, row.lbs)}

              {_renderTotalRowEntityPeriod(index, row.value, 'value')}
            </React.Fragment>
          ))}

          {_renderTotalRowEntityTotal(total.rescues)}

          {_renderTotalRowEntityTotal(total.meals)}

          {_renderTotalRowEntityTotal(total.lbs)}

          {_renderTotalRowEntityTotal(total.value, 'value')}
        </>
      </TableRow>
    );
  };

  const renderTotalRow = volumeTotal => {
    const _renderTotalRowEntityPeriod = (index, siteVolumeReportEntityTotalPeriod, format = false) => (
      <>
        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          {renderCount(siteVolumeReportEntityTotalPeriod.count, format)}
        </TableCell>

        {index > 0 && (
          <>
            <TableCell
              variant="head"
              align="right"
              className={classNames({
                [classes.rowTotal]: true,
                [classes.tableTotalRow]: true,
              })}
            >
              {renderDelta(siteVolumeReportEntityTotalPeriod.delta, format)}
            </TableCell>

            <TableCell
              variant="head"
              align="right"
              className={classNames({
                [classes.rowTotal]: true,
                [classes.tableTotalRow]: true,
              })}
            >
              {renderDeltaPercentage(siteVolumeReportEntityTotalPeriod.delta_percentage)}
            </TableCell>
          </>
        )}
      </>
    );

    const _renderTotalRowEntityTotal = (siteVolumeReportEntityTotal, format = false) => (
      <>
        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          {renderDelta(siteVolumeReportEntityTotal.delta, format)}
        </TableCell>

        <TableCell
          variant="head"
          align="right"
          className={classNames({
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          {renderDeltaPercentage(siteVolumeReportEntityTotal.delta_percentage)}
        </TableCell>
      </>
    );

    return (
      <TableRow>
        <TableCell
          colSpan={showSiteColumn ? 2 : 1}
          variant="head"
          className={classNames({
            [classes.footerTotalColumn]: true,
            [classes.rowTotal]: true,
            [classes.tableTotalRow]: true,
          })}
        >
          Total
        </TableCell>

        <>
          {volumeReport.periods.map((row, index) => (
            <React.Fragment key={index}>
              {_renderTotalRowEntityPeriod(index, volumeTotal.periods[index].rescues)}

              {_renderTotalRowEntityPeriod(index, volumeTotal.periods[index].meals)}
              {_renderTotalRowEntityPeriod(index, volumeTotal.periods[index].lbs)}
              {_renderTotalRowEntityPeriod(index, volumeTotal.periods[index].value)}
            </React.Fragment>
          ))}

          {_renderTotalRowEntityTotal(volumeTotal.rescues)}

          {_renderTotalRowEntityTotal(volumeTotal.meals)}

          {_renderTotalRowEntityTotal(volumeTotal.lbs)}

          {_renderTotalRowEntityTotal(volumeTotal.value)}
        </>
      </TableRow>
    );
  };

  return (
    <Box my={2}>
      <Paper className={classNames(classes.paper, classes.pivot)}>
        <TableToCSVGenerator tableRef={tableRef} fileName="Volume Report" />

        <TableContainer className={classNames(classes.tableContainer, 'table-container')}>
          <Table
            aria-label="spanning table"
            className={classes.root}
            size="small"
            ref={tableRef}
            id="volume-report-table"
          >
            <TableHead>
              <TableRow>
                {showSiteColumn ? (
                  <>
                    <TableCell
                      rowSpan={3}
                      className={classNames({
                        [classes.descriptionsHeader]: true,
                        [classes.siteColumnHead]: true,
                      })}
                    >
                      {renderSortBy(null, 'site', null, 'Site')}
                    </TableCell>

                    <TableCell
                      rowSpan={3}
                      className={classNames({
                        [classes.descriptionsHeader]: true,
                        [classes.entityTypeColumnHead]: true,
                      })}
                    >
                      {renderSortBy(null, type.id, null, renderVolumeReportEntityType(type))}
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    rowSpan={3}
                    className={classNames({
                      [classes.descriptionsHeader]: true,
                      [classes.siteColumnHead]: true,
                      [classes.columnBoxHead]: true,
                    })}
                  >
                    {renderSortBy(null, type.id, null, renderVolumeReportEntityType(type))}
                  </TableCell>
                )}

                {volumeReport.periods.map((row, index) => {
                  const colSpanLength = index > 0 ? 12 : 4;
                  return (
                    <React.Fragment key={index}>
                      <TableCell
                        colSpan={colSpanLength}
                        variant="head"
                        align="center"
                        className={classNames({
                          [classes.periodStickyHeader]: true,
                          [classes.descriptionsHeader]: true,
                          [classes.totalDeltaColumn]: index > 0,
                        })}
                      >
                        {formatDate(row)}
                      </TableCell>
                    </React.Fragment>
                  );
                })}

                <TableCell
                  colSpan={8}
                  align="center"
                  className={classNames(
                    classes.periodStickyHeader,
                    classes.totalDeltaColumn,
                    classes.descriptionsHeader,
                    classes.totalDeltaHeader
                  )}
                >
                  {`${formatDate(volumeReport.periods[0])} - ${formatDate(
                    volumeReport.periods[volumeReport.periods.length - 1]
                  )}`}
                </TableCell>
              </TableRow>

              <TableRow>
                {volumeReport.periods.map((row, index) =>
                  <React.Fragment key={index}>
                    {renderEntityColumn(index, 'Rescues', true)}
                    {renderEntityColumn(index, 'Meals')}
                    {renderEntityColumn(index, 'Lbs')}
                    {renderEntityColumn(index, '$')}
                  </React.Fragment>
                )}

                <React.Fragment>
                  {renderEntityColumnTotal('Rescues', true)}

                  {renderEntityColumnTotal('Meals')}

                  {renderEntityColumnTotal('Lbs')}

                  {renderEntityColumnTotal('$')}
                </React.Fragment>
              </TableRow>

              <TableRow>
                <React.Fragment>
                  {volumeReport.periods.map((row, index) => (
                    <React.Fragment key={index}>
                      {renderEntitySubColumn(index, 'rescues', true)}

                      {renderEntitySubColumn(index, 'meals')}

                      {renderEntitySubColumn(index, 'lbs')}

                      {renderEntitySubColumn(index, 'value')}
                    </React.Fragment>
                  ))}

                  {renderEntitySubColumnTotal('rescues', true)}
                  {renderEntitySubColumnTotal('meals')}
                  {renderEntitySubColumnTotal('lbs')}
                  {renderEntitySubColumnTotal('value')}
                </React.Fragment>
              </TableRow>
            </TableHead>

            <TableBody>
              {volumeReport.sites.map((siteRow, siteIndex) => (
                <React.Fragment key={siteIndex}>
                  {siteRow.entities.map((entityRow, entityIndex) => (
                    <React.Fragment key={entityIndex}>{renderRow(entityIndex, siteRow.site, entityRow)}</React.Fragment>
                  ))}

                  {showSiteColumn &&
                    siteRow.entities.length > 0 &&
                    renderSiteTotalRow(siteRow.site, siteRow.total, siteRow.periods)}
                </React.Fragment>
              ))}

              {renderTotalRow(volumeReport.total)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default VolumeReportTable;
