export const shouldFetch = (date, timeToAdd = {}) => {
  if (date === null || !date instanceof Date && Number.isNaN(date)) {
    return true;
  }
  
  const timeToAddIsObject = typeof timeToAdd === 'object';
  
  if (!timeToAddIsObject || (timeToAddIsObject && !Object.keys(timeToAdd).length)) {
    return true;
  }
  
  const dateCheck = new Date(date);
  
  if (timeToAdd && timeToAdd.minutes) {
    dateCheck.setMinutes(dateCheck.getMinutes() + timeToAdd.minutes)
  }
  
  if (timeToAdd && timeToAdd.hours) {
    dateCheck.setHours(dateCheck.getHours() + timeToAdd.hours)
  }
  
  return dateCheck.getTime() < Date.now();
}
