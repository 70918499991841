import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Box, Button } from '@material-ui/core';
import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons';
import RescueMobileCard from '../../pages/Rescue/RescueMobileCard';
import { isRescueClaimed } from '../../helpers/RescuesHelper';
import ReleaseRescueButtons from './ReleaseRescueButtons';

const RecentRescue = ({ rescue, onClick }) => {
  const { formatMessage } = useIntl();

  const isCancelled = rescue.cancelled_by_id !== null;
  const isClosed = rescue.closed_by_id !== null;
  const rescueDateTime = moment(`${rescue.date} ${rescue.pickup_end}`, 'YYYYMMDD HH:mm:ss');
  const isRescuePast = moment().isSameOrAfter(rescueDateTime, 'second');

  return (
    <RescueMobileCard
      rescue={rescue}
      onClick={onClick}
      highlighted={rescue.closed_by_id === null}
      isCancelled={isCancelled}
      isClosed={isClosed}
    >
      {!isCancelled && isRescueClaimed(rescue) && !isClosed && (
        <Box display="grid" gridGap={8}>
          <Button
            startIcon={<DoneIcon />}
            size="large"
            color="primary"
            variant="contained"
            component={RouterLink}
            to={`/rescue/close/${rescue.id}`}
          >
            {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.i-did-it' })}
          </Button>

          {isRescuePast && (
            <Button
              startIcon={<CloseIcon />}
              size="large"
              color="primary"
              variant="outlined"
              component={RouterLink}
              to={`/rescue/close/noshow/${rescue.id}`}
            >
              {formatMessage({ id: 'rescuer-dashboard-mobile.buttons.i-could-not-make-it' })}
            </Button>
          )}

          {!isRescuePast && <ReleaseRescueButtons rescue={rescue} buttonVariant="outlined" />}
        </Box>
      )}
    </RescueMobileCard>
  );
};

export default RecentRescue;
