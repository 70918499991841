import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, generatePath, Switch, Route } from 'react-router-dom';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Bluebird from 'bluebird';
import get from 'lodash/get';
import { Breadcrumbs, Typography, Tabs, Tab, AppBar, Grid, FormControl, CardContent, Box } from '@material-ui/core';
import routes from '../routes';
import withContent from '../helpers/withContent';
import { fetchReceiversIfNeeded, updateReceiverLocation } from '../actions/receivers';
import ReceiverStaffUsersTable from '../components/ReceiverStaffUsersTable';
import ReceiverLocationForm from '../components/ReceiverLocationForm';
import * as receiversActions from '../actions/receivers';
import * as foodDonorsActions from '../actions/foodDonors';
import * as rescuersActions from '../actions/rescuers';
import * as aggregatesActions from '../actions/aggregates';
import OverlaySpinner from './OverlaySpinner';
import OverlayLoader from '../components/OverlayLoader';
import CompactWidgetTable from '../components/CompactWidgetTable';
import { getTopReceiverDonorsTableData, getTopReceiverRescuersTableData } from '../helpers/getters';
import * as notificationsHelper from '../helpers/notifications';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import { BaseCard, BaseCardHeader } from '../components/Common/BaseCard';
import DateAndGranularityPicker, {
  dateRangeOptionsList,
} from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import ACLService from '../services/acl';
import PastRescuesListView from './PastRescuesListView';
import FutureRescuesListView from './FutureRescuesListView';
import { TabLink } from '../components/Common/BottomNavTabs';
import { fetchFoodDescriptions } from '../actions/foodDescriptions';
import ReceiverNeedsProfileView from './ReceiverNeedsProfileView';

const TAB_FUTURE_RESCUES = 'future-rescues';
const TAB_PAST_RESCUES = 'past-rescues';
const TAB_STAFF = 'staff';
export const TAB_PROFILE = 'profile';
const TAB_LEADERBOARDS = 'leaderboards';
const TAB_PROFILE_NEEDS = 'needs-profile';

export const dashboardPaths = [
  generatePath(routes.dashboardReceiver),
  generatePath(routes.dashboardReceiver, { tab: TAB_PAST_RESCUES }),
  generatePath(routes.dashboardReceiver, { tab: TAB_STAFF }),
  generatePath(routes.dashboardReceiver, { tab: TAB_PROFILE }),
  generatePath(routes.dashboardReceiver, { tab: TAB_LEADERBOARDS }),
  generatePath(routes.dashboardReceiver, { tab: TAB_PROFILE_NEEDS }),
];

export const locationPaths = id => [
  generatePath(routes.receiver, { id }),
  generatePath(routes.receiver, { id, tab: TAB_PAST_RESCUES }),
  generatePath(routes.receiver, { id, tab: TAB_STAFF }),
  generatePath(routes.receiver, { id, tab: TAB_PROFILE }),
  generatePath(routes.receiver, { id, tab: TAB_LEADERBOARDS }),
  generatePath(routes.receiver, { id, tab: TAB_PROFILE_NEEDS }),
];

class ReceiverDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: TAB_FUTURE_RESCUES,
    };
  }

  componentDidMount() {
    const { fetchFoodDonors, fetchRescuers, fetchReceiverTypes, fetchFoodDescriptions } = this.props;
    fetchFoodDonors();
    fetchRescuers();
    fetchReceiverTypes();
    fetchFoodDescriptions();
  }

  handleChange = (event, newValue) => {
    this.setState({
      currentTab: newValue,
    });
  };

  StaffTab() {
    const { receiver, receivers, receiverLocation, deleteReceiverStaff } = this.props;
    const receiverLocationStaff = receivers.locations.staff.byLocationId[receiverLocation.id];

    const onReceiverLocationStaffDelete = user => Bluebird
      .try(() => deleteReceiverStaff(
        receiver.id,
        receiverLocation.id,
        user.id,
        user.is_admin
      ))
      .then(() => notificationsHelper.addNotification('Receiving Agency staff deleted successfully!', this.context));

    return (
      <Grid container>
        <Grid item xs={12}>
          <ReceiverStaffUsersTable
            tableId="#receiver-dashboard/receiver-staff-users"
            receiverId={receiver.id}
            receiverLocationStaff={receiverLocationStaff}
            showDeleteReceiverLocationStaffButton={
              authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
                Roles.Admin,
                Roles.NationalSiteDirector,
                Roles.SiteDirector,
              ])
            }
            onReceiverLocationStaffDelete={onReceiverLocationStaffDelete}
          />
        </Grid>
      </Grid>
    );
  }

  LocationProfileTab() {
    const { receiverLocation, updateReceiverLocation, receiverTypes, receiver, staff, location } = this.props;
    const receiverTypeList = receiverTypes.allIds.map(id => receiverTypes.byId[id]);
    const isLoading = receiver.inflight;
    const { hash } = location;

    const isAdmin = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
      Roles.Admin,
      Roles.NationalSiteDirector,
      Roles.SiteDirector,
    ]);

    return (
      <Grid container>
        <Grid item xs={12}>
          <OverlaySpinner isLoading={isLoading}>
            <ReceiverLocationForm
              hash={hash}
              showNotes={isAdmin}
              receiverTypes={receiverTypeList}
              location={receiverLocation}
              receiver={receiver}
              user={staff.byId[receiverLocation.primary_contact_id]}
              onSubmit={receiverLocation => updateReceiverLocation(receiver.id, receiverLocation.id, receiverLocation)}
            />
          </OverlaySpinner>
        </Grid>
      </Grid>
    );
  }

  LeaderboardsTab(receiverId) {
    const { aggregates, foodDonors, rescuers, fetchReceiverLocationDashboardAggregates } = this.props;
    const showLoading = aggregates.rescues.inflight > 0;
    const LBS_PER_MEAL = 1.2;

    return (
      <>
        <Grid container>
          <Grid item>
            <Box boxShadow={25} p={2} my={1} bgcolor="background.paper">
              <FormControl component="fieldset">
                <DateAndGranularityPicker
                  defaultDateRange={dateRangeOptionsList.thisQTD.value}
                  dateRangeOptions={{
                    lastWeek: dateRangeOptionsList.lastWeek,
                    last7Days: dateRangeOptionsList.last7Days,
                    last90Days: dateRangeOptionsList.last90Days,
                    today: dateRangeOptionsList.today,
                    thisMTD: dateRangeOptionsList.thisMTD,
                    thisQTD: dateRangeOptionsList.thisQTD,
                    thisYTD: dateRangeOptionsList.thisYTD,
                  }}
                  customDateRangeOptions={{
                    maxDate: new Date(),
                  }}
                  showAllDateRange
                  showCustomDateRange
                  showGranularity={false}
                  isDisabled={showLoading}
                  onChange={({ dateRange }) => fetchReceiverLocationDashboardAggregates(dateRange)}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <OverlayLoader
              isLoading={foodDonors.inflight || get(aggregates, "rescues.count['receiver,donor_location'].inflight", true)}
            >
              <BaseCard>
                <BaseCardHeader title="Top Food Donors" />
                <CardContent>
                  <CompactWidgetTable
                    data={getTopReceiverDonorsTableData(
                      {
                        receiverId,
                        foodDonors,
                        aggregates,
                      },
                      LBS_PER_MEAL
                    )}
                    columns={[
                      {
                        name: 'name',
                        label: 'Name',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'rescues',
                        label: '#Rescues',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'meals',
                        label: '#Meals',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'lbs',
                        label: '#lbs',
                        options: {
                          filter: false,
                        },
                      },
                    ]}
                  />
                </CardContent>
              </BaseCard>
            </OverlayLoader>
          </Grid>

          <Grid item xs={12} sm={6}>
            <OverlayLoader
              isLoading={rescuers.inflight || get(aggregates, "rescues.count['receiver,rescuer'].inflight", true)}
            >
              <BaseCard>
                <BaseCardHeader title="Top Rescuers" />
                <CardContent>
                  <CompactWidgetTable
                    data={getTopReceiverRescuersTableData(
                      {
                        receiverId,
                        rescuers,
                        aggregates,
                      },
                      LBS_PER_MEAL
                    )}
                    columns={[
                      {
                        name: 'name',
                        label: 'Name',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'rescues',
                        label: '#Rescues',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'meals',
                        label: '#Meals',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'lbs',
                        label: '#lbs',
                        options: {
                          filter: false,
                        },
                      },
                    ]}
                  />
                </CardContent>
              </BaseCard>
            </OverlayLoader>
          </Grid>
        </Grid>
      </>
    );
  }

  NeedsProfileTab = () => {
    const { receivers, receiver, receiverLocation } = this.props;
    const isLoading = receivers.inflight;

    return (
      <Grid container>
        <Grid item xs={12}>
          <OverlaySpinner isLoading={isLoading}>
            <ReceiverNeedsProfileView receiverId={receiver.id} receiverLocationId={receiverLocation.id} />
          </OverlaySpinner>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { receiver, receiverLocation, activeUser, site, location } = this.props;
    const { currentTab } = this.state;
    const hasReceiversListAccess = ACLService.hasAccess(activeUser, ACLService.resources.receiversList, site.id);
    if (receiver.id === undefined || receiverLocation.id === undefined) {
      return null;
    }

    const isLocationView = matchPath(location.pathname, {
      path: routes.receiver,
      exact: true,
    });

    const isAdmin = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
      Roles.Admin,
      Roles.NationalSiteDirector,
      Roles.SiteDirector,
      Roles.SiteCoordinator,
    ]);

    const paths = isLocationView ? locationPaths(isLocationView.params.id) : dashboardPaths;

    return (
      <>
        <Breadcrumbs aria-label="Breadcrumbs">
          {hasReceiversListAccess ? (
            <Link color="inherit" to={routes.receivers}>
              Receiving Agencies
            </Link>
          ) : (
            <Typography color="textPrimary">Receiving Agencies</Typography>
          )}

          <Typography color="textPrimary">{receiver.name}</Typography>
        </Breadcrumbs>

        <AppBar position="static">
          <Tabs variant="scrollable" scrollButtons="auto" value={location.pathname}>
            <TabLink to={paths[0]} value={paths[0]} label="Future Rescues" />
            <TabLink to={paths[1]} value={paths[1]} label="Past Rescues" />
            <TabLink to={paths[2]} value={paths[2]} label="Staff" />
            <TabLink to={paths[3]} value={paths[3]} label="Profile" data-testid="receiver-profile-tab-button" />
            <TabLink to={paths[5]} value={paths[5]} label="Needs Profile" />
            <TabLink to={paths[4]} value={paths[4]} label="Leaderboards" />
          </Tabs>
        </AppBar>

        <Switch>
          <Route
            path={paths[0]}
            exact
            render={() => (
              <FutureRescuesListView
                filter={(rescue) => rescue.receiver_id === receiver.id}
                showCopyRescueButton={false}
                showEditRescueButton={isAdmin}
                displayFoodDonorFilters={false}
              />
            )}
          />
          <Route
            path={paths[1]}
            exact
            render={() => (
              <PastRescuesListView
                filter={(rescue) => rescue.receiver_id === receiver.id}
                showCopyRescueButton={false}
                renderNamesAsLinks={false}
                filterColumns={['close_comments']}
                displayFoodDonorFilters={false}
              />
            )}
          />
          <Route path={paths[2]} exact render={() => this.StaffTab()} />
          <Route path={paths[3]} exact render={() => this.LocationProfileTab()} />
          <Route path={paths[4]} exact render={() => this.LeaderboardsTab(receiver.id)} />
          <Route path={paths[5]} exact render={() => this.NeedsProfileTab()} />
        </Switch>
      </>
    );
  }
}

ReceiverDashboard.propTypes = {
  receiver: PropTypes.object,
  receiverLocation: PropTypes.object,
};

ReceiverDashboard.defaultProps = {
  receiver: {},
  receiverLocation: {},
};

const mapStateToProps = (
  {
    app: { loggedInUser, impersonating, site },
    aggregates,
    entities: { foodDonors, receivers, rescuers, featureFlags },
  },
  { match, renderParams }
) => {
  const receiverId = match.params.id || renderParams.id;
  const locations = receivers.locations.byReceiverId[receiverId] || [];
  const staff = receivers.locations.staff;

  return {
    activeUser: impersonating || loggedInUser,
    site,
    receiverTypes: receivers.types,
    foodDonors: foodDonors,
    receivers,
    staff,
    receiver: receivers.byId[receiverId],
    receiverLocation: locations[0],
    aggregates: aggregates,
    rescuers: rescuers,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchContent: () => dispatch(fetchReceiversIfNeeded()),
  updateReceiverLocation: (receiverId, receiverLocationId, receiverLocation) =>
    dispatch(updateReceiverLocation(receiverId, receiverLocationId, receiverLocation)),
  fetchReceiverTypes: () => dispatch(receiversActions.fetchReceiverTypes()),
  fetchFoodDescriptions: () => dispatch(fetchFoodDescriptions()),
  fetchFoodDonors: () => dispatch(foodDonorsActions.fetchFoodDonors()),
  fetchRescuers: () => dispatch(rescuersActions.fetchRescuersIfNeeded()),
  fetchReceiverLocationDashboardAggregates: (dateRange, granularity) =>
    dispatch(aggregatesActions.fetchReceiverLocationDashboardAggregatesIfNeeded(dateRange, granularity)),
  deleteReceiverStaff: (receiverId, locationId, userId, isAdmin) =>
    dispatch(receiversActions.deleteReceiverStaff(receiverId, locationId, userId, isAdmin)),
});

ReceiverDashboard.contextType = NotificationsContext;

export default compose(
  withRouter, // do not remove!
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withContent
)(ReceiverDashboard);
