import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs, Typography, Grid, Box } from '@material-ui/core';
import { assign } from 'lodash';
import * as emailsActions from '../actions/emails';
import * as sitesActions from '../actions/sites';
import * as usersActions from '../actions/users';
import EmailsForm from '../components/EmailsForm';
import EmailsTable from '../components/EmailsTable';
import useUIFilter from '../hooks/useUIFilter';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';

export const EMAILS_UI_FILTER = 'emails';

const EmailsView = () => {
  const dispatch = useDispatch();
  const sites = useSelector(state => state.entities.sites);
  const emails = useSelector(state => state.entities.emails);
  const emailsList = emails.allIds.map(id => emails.byId[id]);
  const data = emailsList.map(row => ({
    ...row,
    number_of_recipients: row.users_ids.length,
  }));
  const { setUIFilter, getUIFilter } = useUIFilter();
  const filters = assign(
    {
      sites: null,
      type: 'SITE_DIRECTOR_EMAIL',
      user: null,
    },
    getUIFilter(EMAILS_UI_FILTER)
  );

  const activeUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const sitesWithSDSCAccess = authService.getUserSitesByRoles(activeUser.role_assignments, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);
  const isAdmin = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);
  const sitesList = isAdmin
    ? Object.values(sites.byId)
    : Object.values(sites.byId).filter(site => sitesWithSDSCAccess.includes(site.id));

  useEffect(() => {
    dispatch(sitesActions.fetchSitesIfNeeded());
  }, []);

  useEffect(() => {
    // if user selected sites, use it and submit
    // otherwise - check if user is an admin -> then send an empty array to receive ALL sites
    // if user is not an admin, send an array with the sites ids in which user is SD/SC
    const sitesIds = (() => {
      if (Array.isArray(filters.sites)) {
        return filters.sites.map(s => s.id);
      }

      if (isAdmin) {
        return [];
      }

      return sitesList.map(s => s.id);
    })();

    dispatch(emailsActions.fetchEmailsIfNeeded(sitesIds, filters.type, filters.user ? filters.user.value : null));
  }, [
    filters.sites && filters.sites.length,
    filters.type,
    filters.user && filters.user.value,
  ]);

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Typography color="textPrimary">
          Sent Emails
        </Typography>
      </Breadcrumbs>

      <Grid container>
        <Grid item xs={12}>
          {(isAdmin || sitesList.length > 1) && (
            <Box my={1} p={2} bgcolor="background.paper" boxShadow={25}>
              <EmailsForm
                defaultValues={filters}
                isAdmin={isAdmin}
                sitesList={sitesList}
                onSubmit={(values) => setUIFilter(EMAILS_UI_FILTER, {
                  sites: values.sites,
                  type: values.type,
                  user: values.user,
                })}
              />
            </Box>
          )}

          <EmailsTable
            tableId="#emails-table"
            isLoading={emails.inflight}
            emails={data}
            sitesList={sitesList}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EmailsView;
