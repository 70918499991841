import Bluebird from 'bluebird';
import * as donationApi from '../api/donations';
import { invalidateRescuesData } from './rescues';

export const CREATE_DRAFT_DONATION = 'CREATE_DRAFT_DONATION';
export const SET_DRAFT_DONATION = 'SET_DRAFT_DONATION';
export const CLEAR_DONATION = 'CLEAR_DONATION';
export const RESET_DONATION = 'RESET_DONATION';
export const SET_DONATION_RESCUES = 'SET_DONATION_RESCUES';
export const SET_DONATION_PICKUPS = 'SET_DONATION_PICKUPS';
export const SET_DONATION_UNDO_LAST_ACTION = 'SET_DONATION_UNDO_LAST_ACTION';
export const SET_DONATION_REDO_LAST_ACTION = 'SET_DONATION_REDO_LAST_ACTION';
export const SET_DONATION_DIFF_INFLIGHT = 'SET_DONATION_DIFF_INFLIGHT';

export const SET_DONATION_FORM_ERROR = 'ADD_DONATION_ERROR';
export const REMOVE_DONATION_FORM_ERROR = 'REMOVE_DONATION_ERROR';
export const CLEAR_DONATION_FORM_ERROR = 'CLEAR_DONATION_FORM_ERROR';

export const SET_DONATION_FOOD_DONOR = 'SET_DONATION_FOOD_DONOR';
export const SET_DONATION_PICKUP_LOCATION = 'SET_DONATION_PICKUP_LOCATION';
export const SET_DONATION_DESCRIPTION = 'SET_DONATION_DESCRIPTION';
export const SET_DONATION_LBS_VALUE = 'SET_DONATION_LBS_VALUE';
export const SET_DONATION_RESCUE_SIZE = 'SET_DONATION_RESCUE_SIZE';
export const SET_DONATION_FOOD_SIZE = 'SET_DONATION_FOOD_SIZE';
export const SET_DONATION_ADD_FOOD_TYPE = 'SET_DONATION_ADD_FOOD_TYPE';
export const SET_DONATION_REMOVE_FOOD_TYPE = 'SET_DONATION_REMOVE_FOOD_TYPE';
export const SET_DONATION_ADD_FOOD_SUB_CATEGORY = 'SET_DONATION_ADD_FOOD_SUB_CATEGORY';
export const SET_DONATION_REMOVE_FOOD_SUB_CATEGORY = 'SET_DONATION_REMOVE_FOOD_SUB_CATEGORY';
export const SET_DONATION_FOOD_TYPE_OTHER = 'SET_DONATION_FOOD_TYPE_OTHER';
export const SET_DONATION_ADD_DAY_OF_WEEK = 'SET_DONATION_ADD_DAY_OF_WEEK';
export const SET_DONATION_REMOVE_DAY_OF_WEEK = 'SET_DONATION_REMOVE_DAY_OF_WEEK';
export const SET_DONATION_ADD_DAY_OF_MONTH = 'SET_DONATION_ADD_DAY_OF_MONTH';
export const SET_DONATION_DAY_OF_MONTH_DAY = 'SET_DONATION_DAY_OF_MONTH_DAY';
export const SET_DONATION_DAY_OF_MONTH_ORDINAL = 'SET_DONATION_DAY_OF_MONTH_ORDINAL';
export const SET_DONATION_REMOVE_DAY_OF_MONTH = 'SET_DONATION_REMOVE_DAY_OF_MONTH';
export const SET_DONATION_START_DATE = 'SET_DONATION_START_DATE';
export const SET_DONATION_END_DATE = 'SET_DONATION_END_DATE';
export const SET_DONATION_PAUSE_DATE = 'SET_DONATION_PAUSE_DATE';
export const SET_DONATION_RESUME_DATE = 'SET_DONATION_RESUME_DATE';
export const SET_DONATION_APPEND_PICKUP = 'SET_DONATION_APPEND_PICKUP';
export const SET_DONATION_REMOVE_PICKUP = 'SET_DONATION_REMOVE_PICKUP';
export const SET_DONATION_PICKUP_RECEIVER = 'SET_DONATION_PICKUP_RECEIVER';
export const SET_DONATION_PICKUP_ADOPTER = 'SET_DONATION_PICKUP_ADOPTER';
export const SET_DONATION_PICKUP_RESCUER = 'SET_DONATION_PICKUP_RESCUER';
export const SET_DONATION_PICKUP_RESCUER_NOTES = 'SET_DONATION_PICKUP_RESCUER_NOTES';
export const SET_DONATION_PICKUP_BEGIN = 'SET_DONATION_PICKUP_BEGIN';
export const SET_DONATION_PICKUP_END = 'SET_DONATION_PICKUP_END';
export const SET_DONATION_FREQUENCY = 'SET_DONATION_FREQUENCY';
export const SET_DONATION_FREQUENCY_INTERVAL = 'SET_DONATION_FREQUENCY_INTERVAL';
export const SET_DONATION_ALL_DAYS_ARE_THE_SAME = 'SET_DONATION_ALL_DAYS_ARE_THE_SAME';
export const CLEAR_DONATION_ALL_DAYS_ARE_THE_SAME = 'CLEAR_DONATION_ALL_DAYS_ARE_THE_SAME';
export const SET_DONATION_ALL_PICKUPS_ARE_THE_SAME = 'SET_DONATION_ALL_PICKUPS_ARE_THE_SAME';
export const CLEAR_DONATION_ALL_PICKUPS_ARE_THE_SAME = 'CLEAR_DONATION_ALL_PICKUPS_ARE_THE_SAME';
export const SET_DONATION_RESCUE_PICKUP_BEGIN = 'SET_DONATION_RESCUE_PICKUP_BEGIN';
export const SET_DONATION_RESCUE_PICKUP_END = 'SET_DONATION_RESCUE_PICKUP_END';
export const SET_DONATION_RESCUE_RESCUER_NOTES = 'SET_DONATION_RESCUE_RESCUER_NOTES';
export const SET_DONATION_RESCUE_RESCUER = 'SET_DONATION_RESCUE_RESCUER';
export const SET_DONATION_RESCUE_CANCELLER = 'SET_DONATION_RESCUE_CANCELLER';
export const SET_DONATION_DONOR_NOTES = 'SET_DONATION_DONOR_NOTES';
export const SET_NATIONAL_DONATION = 'SET_NATIONAL_DONATION';

export const DONATION_TYPE_SD = 'SD';
export const DONATION_TYPE_REQUEST = 'REQUEST';
export const DONATION_TYPE_REQUEST_APPROVAL = 'REQUEST_APPROVAL';

export const saveDonation = (donationId, foodDonorId, modifications, params = {}) => dispatch => {
  dispatch(setDonationDiffInflight(true));

  return Bluebird.try(() =>
    donationApi.diffDonation({
      donation_id: donationId,
      location_id: foodDonorId,
      save: true,
      modifications: modifications,
      ...params,
    })
  )
    .then(res => res.json())
    .then(res => res.data)
    .then(data => {
      dispatch(invalidateRescuesData());
      dispatch(
        createDraftDonation({
          rescues: data.rescues,
          donation: {
            ...data.donation,
            pickup_specs: data.pickup_specs,
          },
        })
      );

      return data.donation;
    });
};

export const diffCreateDonation = (donationId = null, foodDonorId = null) => dispatch => {
  dispatch(setDonationDiffInflight(true));

  return Bluebird.try(() =>
    donationApi.diffDonation({
      donation_id: donationId,
      location_id: foodDonorId,
      save: false,
      modifications: [],
    })
  )
    .then(res => res.json())
    .then(res => res.data)
    .then(data =>
      dispatch(
        createDraftDonation({
          rescues: data.rescues,
          donation: {
            ...data.donation,
            errors: {},
            pickup_specs: data.pickup_specs,
          },
        })
      )
    );
};

export const diffDonation = (donationId = null, foodDonorId = null) => (dispatch, getState) => {
  const {
    ui: { donation_new },
  } = getState();

  dispatch(setDonationDiffInflight(true));

  return Bluebird.try(() =>
    donationApi.diffDonation({
      donation_id: donationId,
      location_id: foodDonorId,
      save: false,
      modifications: donation_new.currentState,
    })
  )
    .then(res => res.json())
    .then(res => res.data)
    .then(data =>
      dispatch(
        setDraftDonation({
          rescues: data.rescues,
          donation: {
            ...data.donation,
            pickup_specs: data.pickup_specs,
          },
        })
      )
    );
};

export const createDraftDonation = ({ donation, rescues }) => ({
  type: CREATE_DRAFT_DONATION,
  donation,
  rescues,
});

export const setDraftDonation = ({ donation, rescues }) => ({
  type: SET_DRAFT_DONATION,
  donation,
  rescues,
});

export const setClearDonation = () => ({
  type: CLEAR_DONATION,
});

export const setResetDonation = () => ({
  type: RESET_DONATION,
});

export const setDraftDonationPickups = pickups => ({
  type: SET_DONATION_PICKUPS,
  pickups,
});

export const setDonationCalendarRescues = rescues => ({
  type: SET_DONATION_RESCUES,
  rescues,
});

export const setDonationFormError = (field, error) => ({
  type: SET_DONATION_FORM_ERROR,
  field,
  error,
});

export const removeDonationError = (field, index) => ({
  type: REMOVE_DONATION_FORM_ERROR,
  field,
  index,
});

export const clearDonationErrors = () => ({
  type: CLEAR_DONATION_FORM_ERROR,
});

export const setDonationUndoLastAction = () => ({
  type: SET_DONATION_UNDO_LAST_ACTION,
});

export const setDonationRedoLastAction = () => ({
  type: SET_DONATION_REDO_LAST_ACTION,
});

export const setDonationDiffInflight = isInflight => ({
  type: SET_DONATION_DIFF_INFLIGHT,
  inflight: isInflight,
});

export const setDonationFoodDonor = ({ id, name }) => ({
  type: SET_DONATION_FOOD_DONOR,
  id,
  name,
});

export const setDonationPickupLocation = ({ id, name }) => ({
  type: SET_DONATION_PICKUP_LOCATION,
  id,
  name,
});

export const setDonationNewDescription = description => ({
  type: SET_DONATION_DESCRIPTION,
  description,
});

export const setDonationLBSValue = lbsValue => ({
  type: SET_DONATION_LBS_VALUE,
  lbsValue,
});

export const setDonationNewRescueSize = rescueSize => ({
  type: SET_DONATION_RESCUE_SIZE,
  rescueSize,
});

export const setDonationNewFoodSize = foodSize => ({
  type: SET_DONATION_FOOD_SIZE,
  foodSize,
});

export const setDonationNewAddFoodType = foodType => ({
  type: SET_DONATION_ADD_FOOD_TYPE,
  foodType,
});

export const setDonationNewRemoveFoodType = foodType => ({
  type: SET_DONATION_REMOVE_FOOD_TYPE,
  foodType,
});

export const setDonationNewAddFoodSubCategory = category => ({
  type: SET_DONATION_ADD_FOOD_SUB_CATEGORY,
  category,
});

export const setDonationNewremoveFoodSubCategory = category => ({
  type: SET_DONATION_REMOVE_FOOD_SUB_CATEGORY,
  category,
});

export const setDonationNewFoodTypeOther = foodTypeOther => ({
  type: SET_DONATION_FOOD_TYPE_OTHER,
  foodTypeOther,
});

export const setDonationAddDayOfWeek = day => ({
  type: SET_DONATION_ADD_DAY_OF_WEEK,
  day: day,
});

export const setDonationRemoveDayOfWeek = day => ({
  type: SET_DONATION_REMOVE_DAY_OF_WEEK,
  day,
});

export const setDonationAddDayOfMonth = ({ day, ordinal }) => ({
  type: SET_DONATION_ADD_DAY_OF_MONTH,
  day,
  ordinal,
});

export const setDonationDayOfMonthDay = ({ day, ordinal, newDay }) => ({
  type: SET_DONATION_DAY_OF_MONTH_DAY,
  day,
  ordinal,
  newDay,
});

export const setDonationDayOfMonthOrdinal = ({ day, ordinal, newOrdinal }) => ({
  type: SET_DONATION_DAY_OF_MONTH_ORDINAL,
  day,
  ordinal,
  newOrdinal,
});

export const setDonationRemoveDayOfMonth = ({ day, ordinal }) => ({
  type: SET_DONATION_REMOVE_DAY_OF_MONTH,
  day,
  ordinal,
});

export const setDonationStartDate = ({ startDate, frequency, frequencyEvery }) => ({
  type: SET_DONATION_START_DATE,
  startDate,
  frequency,
  frequencyEvery,
});

export const setDonationEndDate = endDate => ({
  type: SET_DONATION_END_DATE,
  endDate,
});

export const setDonationPauseDate = pauseDate => ({
  type: SET_DONATION_PAUSE_DATE,
  pauseDate,
});

export const setDonationResumeDate = resumeDate => ({
  type: SET_DONATION_RESUME_DATE,
  resumeDate,
});

export const setDonationAppendPickup = payload => ({
  type: SET_DONATION_APPEND_PICKUP,
  payload,
});

export const setDonationRemovePickup = payload => ({
  type: SET_DONATION_REMOVE_PICKUP,
  payload,
});

export const setDonationPickupReceiver = payload => ({
  type: SET_DONATION_PICKUP_RECEIVER,
  payload,
});

export const setDonationPickupAdopter = payload => ({
  type: SET_DONATION_PICKUP_ADOPTER,
  payload,
});

export const setDonationPickupRescuer = payload => ({
  type: SET_DONATION_PICKUP_RESCUER,
  payload,
});

export const setDonationPickupRescuerNotes = payload => ({
  type: SET_DONATION_PICKUP_RESCUER_NOTES,
  payload,
});

export const setDonationPickupBegin = payload => ({
  type: SET_DONATION_PICKUP_BEGIN,
  payload,
});

export const setDonationPickupEnd = payload => ({
  type: SET_DONATION_PICKUP_END,
  payload,
});

export const setDonationRescuePickupBegin = payload => ({
  type: SET_DONATION_RESCUE_PICKUP_BEGIN,
  payload,
});

export const setDonationRescuePickupEnd = payload => ({
  type: SET_DONATION_RESCUE_PICKUP_END,
  payload,
});

export const setDonationRescueRescuerNotes = payload => ({
  type: SET_DONATION_RESCUE_RESCUER_NOTES,
  payload,
});

export const setDonationRescueRescuer = payload => ({
  type: SET_DONATION_RESCUE_RESCUER,
  payload,
});

export const setDonationRescueCanceller = payload => ({
  type: SET_DONATION_RESCUE_CANCELLER,
  payload,
});

export const setDonationDonorNotes = donorNotes => ({
  type: SET_DONATION_DONOR_NOTES,
  donorNotes,
});

export const setDonationFrequency = frequency => ({
  type: SET_DONATION_FREQUENCY,
  frequency,
});

export const setDonationFrequencyInterval = frequencyInterval => ({
  type: SET_DONATION_FREQUENCY_INTERVAL,
  frequencyInterval,
});

export const setDonationAllDaysTheSame = () => ({
  type: SET_DONATION_ALL_DAYS_ARE_THE_SAME,
});

export const clearDonationAllDaysTheSame = () => ({
  type: CLEAR_DONATION_ALL_DAYS_ARE_THE_SAME,
});

export const setDonationAllPickupsTheSame = () => ({
  type: SET_DONATION_ALL_PICKUPS_ARE_THE_SAME,
});

export const clearDonationAllPickupsTheSame = () => ({
  type: CLEAR_DONATION_ALL_PICKUPS_ARE_THE_SAME,
});

export const setDonationNationalDonation = payload => ({
  type: SET_NATIONAL_DONATION,
  payload,
});
