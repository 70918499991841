import React from 'react';
import PropTypes from 'prop-types';

const withContent = WrappedComponent => {
  class WithContent extends React.Component {
    componentDidMount() {
      const { fetchContent } = this.props;
      fetchContent();
    }

    render() {
      const { fetchContent, ...props } = this.props;
      return <WrappedComponent {...props} />;
    }
  }
  WithContent.propTypes = {
    fetchContent: PropTypes.func.isRequired,
  };

  return WithContent;
};

export default withContent;
