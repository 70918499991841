import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import DashboardChartCard from './DashboardChartCard';
import { getColorByIndex } from '../../helpers/colors';

const DashboardInsightsCards = ({ meals, rescuedFood, inflight }) => {
  const getMealsChartData = key => {
    const data = { labels: [], dataset: { data: [], backgroundColor: [] } };

    if (!meals) {
      return data;
    }

    return meals[key].reduce((acc, curr, idx) => {
      if (curr.name !== null && curr.meals) {
        acc.labels.push(curr.name);
        acc.dataset.data.push(curr.meals);
        acc.dataset.backgroundColor.push(getColorByIndex(idx));
      }

      return acc;
    }, data);
  };

  const getRescuedFoodChartData = key => {
    const data = { labels: [], dataset: { data: [], backgroundColor: [] } };

    if (!rescuedFood) {
      return data;
    }

    return rescuedFood[key].reduce((acc, curr, idx) => {
      if (curr.occurrences) {
        acc.labels.push(curr.name);
        acc.dataset.data.push(curr.occurrences);
        acc.dataset.backgroundColor.push(getColorByIndex(idx));
      }

      return acc;
    }, data);
  };

  const donorData = getMealsChartData('donor_type');
  const foodData = getMealsChartData('food_type');
  const receiverData = getMealsChartData('receiver_type');
  const rescueSizeData = getMealsChartData('rescue_size');
  const unitData = getRescuedFoodChartData('unit');
  const foodDescriptionData = getRescuedFoodChartData('food_description');

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" color="textSecondary">
            Insight
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DashboardChartCard
            isLoading={inflight}
            title="Donor Types"
            data={{
              labels: donorData.labels,
              datasets: [donorData.dataset],
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DashboardChartCard
            isLoading={inflight}
            title="Receiving Agency Types"
            data={{
              labels: receiverData.labels,
              datasets: [receiverData.dataset],
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DashboardChartCard
            isLoading={inflight}
            title="Rescue Vehicle Sizes"
            data={{
              labels: rescueSizeData.labels,
              datasets: [rescueSizeData.dataset],
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <DashboardChartCard
            isLoading={inflight}
            title="Food Categories"
            data={{
              labels: foodDescriptionData.labels,
              datasets: [foodDescriptionData.dataset],
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardInsightsCards;
