import React, { useEffect, useContext }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, generatePath, useRouteMatch } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../routes';
import * as foodDonorsActions from '../actions/foodDonors';
import OverlayLoader from '../components/OverlayLoader';
import FoodDonorPickupLocationEditForm from '../components/FoodDonorPickupLocationEditForm';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import * as notificationsHelper from '../helpers/notifications';
import usePermission from '../hooks/usePermission';
import ACLService from '../services/acl';

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
}));

const FoodDonorPickupLocationEditView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const locationId = match.params.foodDonorId;
  const pickupLocationId = match.params.pickupLocationId;
  const foodDonors = useSelector(state => state.entities.foodDonors);
  const foodDonorsPickupLocations = useSelector(state => state.entities.foodDonors.pickupLocations);
  const foodDonor = foodDonors.byId[locationId];
  const foodDonorPickupLocation = foodDonorsPickupLocations.byId[pickupLocationId];
  const notificationsContext = useContext(NotificationsContext);
  const hasFoodDonorsList = usePermission({
    resource: ACLService.resources.foodDonorsList,
  });
  useEffect(() => {
    dispatch(foodDonorsActions.fetchFoodDonorIfNeeded(locationId));
    dispatch(foodDonorsActions.fetchFoodDonorPickupLocationsIfNeeded(locationId));
  }, []);

  if (!foodDonor || !foodDonorPickupLocation) {
    return 'Loading...';
  }

  const onUpdateFoodDonorPickupLocation = data => Bluebird
    .try(() => dispatch(foodDonorsActions.updateFoodDonorPickupLocation(
      foodDonor.id,
      foodDonorPickupLocation.id,
      data
    )))
    .then(() => {
      notificationsHelper.addNotification('Food Donor Pickup Location updated successfully!', notificationsContext);

      return history.push(generatePath(routes.foodDonor, { foodDonorId: locationId }));
    });

  return (
    <>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
        {hasFoodDonorsList ? (
          <Link color="inherit" to={routes.foodDonors}>
            Food Donors
          </Link>
        ) : (
          <Typography color="textPrimary">Food Donors</Typography>
        )}


        <Link
          color="inherit"
          to={generatePath(routes.foodDonor, { foodDonorId: locationId })}
        >
          {foodDonor.name}
        </Link>

        <Typography color="textPrimary">
          Edit Food Donor Pickup Location
        </Typography>
      </Breadcrumbs>

      <OverlayLoader isLoading={foodDonors.inflight || foodDonorsPickupLocations.inflight}>
        <Paper className={classes.paper}>
          <FoodDonorPickupLocationEditForm
            foodDonorPickupLocation={foodDonorPickupLocation}
            updateFoodDonorPickupLocation={onUpdateFoodDonorPickupLocation}
          />
        </Paper>
      </OverlayLoader>
    </>
  );
};

export default FoodDonorPickupLocationEditView;
