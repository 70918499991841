import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import {  MoreVert as MoreVertIcon } from '@material-ui/icons';
import { AppleShareIcon } from '../../assets/SvgIcons';
import { dismissA2HSTutorial } from '../../actions/ui';
import { isSupportedAndroidDevice, isSupportedIOSDevice } from './PWAInstallButton';

export const IOSDialog = ({ open, onClose }) => (
  <Dialog
    open={open}
    fullWidth
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <Typography variant="body1" component="div">
        To add to your home screen:
        <ol>
          <li>
            Tap <AppleShareIcon width="24px" height="24px" /> (share icon) in Safari menu
          </li>
          <li>Look for and select “Add to home screen”</li>
        </ol>
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export const ChromiumBasedBrowser = ({ onClose }) => (
  <Dialog
    open
    fullWidth
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <Typography variant="body1" component="div">
        To add Food Rescue US to the homescreen:
        <ol>
          <li>
            Please click <MoreVertIcon width="24px" height="24px" /> in the top right corner on the browser
          </li>
          <li>in the drop down menu look for "Install Application"</li>
        </ol>
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export const BrowserNotSupported = ({ onClose }) => (
  <Dialog
    open
    fullWidth
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <Typography variant="body1" component="div">
        It seems that your browser is not supported yet. At this moment we only support iOS with Safari and Android
        Devices with Google Chrome, Opera, Microsoft Edge.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const PWAAdd2HSTutorial = () => {
  const showA2HSTutorial = useSelector(state => state.ui.showA2HSTutorial);
  const dispatch = useDispatch();
  const dismissTutorial = () => dispatch(dismissA2HSTutorial());

  if (!showA2HSTutorial) {
    return null;
  }

  if (isSupportedIOSDevice) {
    return <IOSDialog open onClose={() => dismissTutorial()} />;
  }

  if (isSupportedAndroidDevice) {
    return <ChromiumBasedBrowser onClose={() => dismissTutorial()} />;
  }

  return <BrowserNotSupported onClose={() => dismissTutorial()} />;
};

export default PWAAdd2HSTutorial;
