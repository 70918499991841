import { objectFromArray } from '../helpers';
import * as systemSettingsActions from '../actions/systemSettings';

export const initialSitesState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const featureFlagsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case systemSettingsActions.REQUEST_SYSTEM_SETTINGS:
      return {
        ...state,
        inflight: true,
      };
    case systemSettingsActions.RECEIVE_SYSTEM_SETTINGS:
      return {
        ...state,
        inflight: false,
        byId: objectFromArray(action.systemSettings, systemSetting => systemSetting.id),
        allIds: action.systemSettings.map(systemSetting => systemSetting.id),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default featureFlagsReducer;
