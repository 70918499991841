import queryString from 'query-string';
import { apiGET } from './api';

export function getInsights(dateRange, sitesIds = []) {
  const query = queryString.stringify({
    date_range: `${dateRange.startDate}/${dateRange.endDate}`,
    sites_ids: sitesIds,
  });
  
  return apiGET(`/insights?${query}`);
}
