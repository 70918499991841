import { REQUEST_AGGREGATES, RECEIVE_AGGREGATES } from '../actions/aggregates';

const genericAggregate = {
  inflight: 0,
  count: {
    site: {
      none: [],
      daily: [],
      weekly: [],
      monthly: [],
      yearly: [],
    },
  },
};

const initialState = {
  rescues: {
    inflight: 0,
    count: {
      'site,donor': {},
      'site,receiver': {},
    },
  },
  claimed_rescues: {
    inflight: 0,
    count: {
      'site,rescuer': {},
      'site,donor': {},
      'site,receiver': {},
    },
  },
  donor_registrations: {
    ...genericAggregate,
  },
  rescuer_registrations: {
    ...genericAggregate,
  },
  receiver_registrations: {
    ...genericAggregate,
  },
};

const aggregatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_AGGREGATES: {
      const { fact, measure, dimension } = action;
      return {
        ...state,
        [fact]: {
          ...state[fact],
          inflight: state[fact].inflight + 1,
          [measure]: {
            [dimension]: {
              ...state[fact][measure][dimension],
              inflight: true,
            },
          },
        },
      };
    }
    case RECEIVE_AGGREGATES: {
      const {
        data: { fact, measure, dimension, data },
        granularity,
      } = action.payload;
      return {
        ...state,
        [fact]: {
          ...state[fact],
          inflight: state[fact].inflight - 1,
          [measure]: {
            ...state[fact][measure],
            [dimension]: {
              ...state[fact][measure][dimension],
              [granularity]: data,
              inflight: false,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default aggregatesReducer;
