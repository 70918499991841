import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useNotificationService from './useNotificationService';
import useActiveUser from './useActiveUser';
import {
  addAvailableRescues,
  addRescuerClaimedAndAdoptedRescue,
  claimRescue,
  removeRescuerClaimedAndAdoptedRescuesREGEX,
} from '../actions/rescues';
import * as rescuesActions from '../actions/rescues';
import Bluebird from 'bluebird';
import useRescuerDashboardActions from './useRescuerDashboardActions';
import { showEmailVerificationNotification, showPhoneVerificationNotification } from '../actions/uiPersisted';
import snackbarHelper from '../helpers/snackbarHelper';
import errorMessages from '../assets/errorMessages';

const RESCUE_UNCLAIMED_SUCCESSFULLY = 'Rescue unclaimed successfully!';
const RESCUE_CLAIMED_SUCCESSFULLY = 'Rescue claimed successfully!';
const RESCUE_RELEASED_SUCCESSFULLY = 'Rescue released successfully!';
const RESCUE_UNADOPTED_SUCCESSFULLY = 'Rescue unadopted successfully!';

export default function useRescuerRescueActions() {
  const { addErrorNotification, addSuccessNotification, addErrorNotificationOLD } = useNotificationService();
  const { fetchScheduleTab, fetchMyFrusTab } = useRescuerDashboardActions();
  const dispatch = useDispatch();
  const activeUser = useActiveUser();

  const releaseRescue = useCallback(
    (rescue, isMobileView = false, user = undefined) => {
      return Bluebird.try(() => {
        // for UI performance, assume that release will won't fail
        dispatch(removeRescuerClaimedAndAdoptedRescuesREGEX(user ? user.id : activeUser.id, rescue.id));
        dispatch(addAvailableRescues(user ? user.id : activeUser.id, rescue.id));
        if (isMobileView) {
          snackbarHelper.success(RESCUE_RELEASED_SUCCESSFULLY, true);
        } else {
          addSuccessNotification(RESCUE_RELEASED_SUCCESSFULLY);
        }
        return dispatch(rescuesActions.releaseRescue(user ? user.id : activeUser.id, rescue));
      }).catch(err => {
        // if release fails, revert redux changes
        if (isMobileView) {
          addErrorNotificationOLD(err, err.message);
        } else {
          addErrorNotification(err, err.message);
        }
        fetchScheduleTab(false, true);
        return dispatch(addRescuerClaimedAndAdoptedRescue(user ? user.id : activeUser.id, rescue.id));
      });
    },
    [dispatch]
  );
  const rescueUnclaim = useCallback(
    (rescue, isMobileView = false, user = undefined) => {
      return Bluebird.try(() => {
        // for UI performance reasen, assume that unclaim will won't fail
        dispatch(removeRescuerClaimedAndAdoptedRescuesREGEX(user ? user.id : activeUser.id, rescue.id));

        if (isMobileView) {
          snackbarHelper.success(RESCUE_UNCLAIMED_SUCCESSFULLY);
        } else {
          addSuccessNotification(RESCUE_UNCLAIMED_SUCCESSFULLY);
        }
        return dispatch(rescuesActions.unclaimRescue(rescue));
      }).catch(err => {
        // if unlaimRescue fails, revert redux changes
        dispatch(addRescuerClaimedAndAdoptedRescue(user ? user.id : activeUser.id, rescue.id));

        fetchScheduleTab(false, true);

        if (isMobileView) {
          return addErrorNotificationOLD(err, err.message);
        }

        return addErrorNotification(err, err.message);
      });
    },
    [dispatch]
  );

  // unadopt require to re-fetch schedule tab everty time
  const rescueUnadopt = useCallback(
    (rescue, isMobileView = false, user = undefined) => {
      const [rescueId, rescueDate] = rescue.id.split('_');

      return Bluebird.try(() => {
        dispatch(removeRescuerClaimedAndAdoptedRescuesREGEX(user ? user.id : activeUser.id, `^${rescueId}_`));
        dispatch(rescuesActions.unAdoptRescue(rescue));
        if (isMobileView) {
          snackbarHelper.success(RESCUE_UNADOPTED_SUCCESSFULLY);
        } else {
          addSuccessNotification(RESCUE_UNADOPTED_SUCCESSFULLY);
        }
      })
        .catch(err => {
          // on fail, show loading animation and re-fetch my-rescues data
          if (isMobileView) {
            addErrorNotificationOLD(err, err.message);
          } else {
            addErrorNotification(err, err.message);
          }
          return fetchMyFrusTab(false, true);
        })
        .finally(() => fetchScheduleTab(false, true));
    },
    [dispatch]
  );

  const rescueClaim = useCallback(
    (rescue, isMobileView = false, user = undefined) => {
      return Bluebird.try(() => dispatch(claimRescue(rescue, user ? user.id : activeUser.id)))
        .then(() => {
          dispatch(addRescuerClaimedAndAdoptedRescue(user ? user.id : activeUser.id, rescue.id));

          if (isMobileView) {
            snackbarHelper.success(RESCUE_CLAIMED_SUCCESSFULLY, true);
          } else {
            addSuccessNotification(RESCUE_CLAIMED_SUCCESSFULLY);
          }
        })
        .catch(err => {
          switch (err.code) {
            case errorMessages.ERR_UNABLE_TO_CLAIM_RESCUE_USER_EMAIL_NOT_VERIFIED.code:
              dispatch(showEmailVerificationNotification());
              if (isMobileView) {
                snackbarHelper.error(errorMessages.ERR_UNABLE_TO_CLAIM_RESCUE_USER_EMAIL_NOT_VERIFIED.message);
              } else {
                addErrorNotification(err, errorMessages.ERR_UNABLE_TO_CLAIM_RESCUE_USER_EMAIL_NOT_VERIFIED.message);
              }
              break;
            case errorMessages.ERR_UNABLE_TO_CLAIM_RESCUE_USER_PHONE_NOT_VERIFIED.code:
              dispatch(showPhoneVerificationNotification());
              if (isMobileView) {
                snackbarHelper.error(errorMessages.ERR_UNABLE_TO_CLAIM_RESCUE_USER_PHONE_NOT_VERIFIED.message);
              } else {
                addErrorNotification(err, errorMessages.ERR_UNABLE_TO_CLAIM_RESCUE_USER_PHONE_NOT_VERIFIED.message);
              }

              break;
            default:
              addErrorNotification(err, err.message);
              break;
          }

          dispatch(removeRescuerClaimedAndAdoptedRescuesREGEX(user ? user.id : activeUser.id, rescue.id));
        });
    },
    [dispatch]
  );

  return {
    releaseRescue: releaseRescue,
    rescueUnclaim: rescueUnclaim,
    rescueUnadopt: rescueUnadopt,
    rescueClaim: rescueClaim,
  };
}
