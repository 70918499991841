import { RESCUE_CLOSED, RESCUE_CLOSED_NO_SHOW, RESCUE_NOT_CLOSED } from '../components/PastRescuesTable';
import { parsePrimitive } from '../helpers/getters';
import { RESCUE_LBS_TOTAL } from '../pages/Rescue/RescueEdit/PastRescueForm/PastRescueForm';

export const mapFormPastRescuePUT = data => {
  const isCompleted = parsePrimitive(data.completed);
  const noFood = parsePrimitive(data.no_food);
  const rescueTotalForItems = data.lbs_total_by === RESCUE_LBS_TOTAL;

  return {
    food_types: data.food_types,
    food_type_other: data.food_type_other || null,
    slug: data.slug || null,
    no_food: noFood,
    lbs_type: data.lbs_total_by ? data.lbs_total_by : null,
    weight_lbs:
      !noFood && rescueTotalForItems && data.weight_lbs !== undefined && data.weight_lbs !== ''
        ? +data.weight_lbs
        : null,
    weight_lbs_override:
      !noFood && rescueTotalForItems && data.weight_lbs_override !== undefined && data.weight_lbs_override !== ''
        ? +data.weight_lbs_override
        : null,
    rescue_size_id: data.rescue_size_id,
    donation_size_id: data.donation_size_id || null,
    pickup_begin: data.pickup_begin,
    pickup_end: data.pickup_end,
    rescuer_notes: data.rescuer_notes || null,
    rescuer_id: data.rescuer_id || null,
    cancelled_by_id: data.cancelled_by_id || null,
    lbs: data.lbs === '' ? null : parseInt(data.lbs, 10),
    completed: isCompleted,
    closed_by_id: data.closed_by_id || null,
    close_comments: data.close_comments || null,
    rescued_food: noFood
      ? []
      : (data.items || []).map(item => ({
          id: item.id || null,
          qty: parseInt(item.qty, 10),
          unit_id: parseInt(item.unit_id, 10),
          lbs: rescueTotalForItems || item.lbs === '' ? null : parseInt(item.lbs, 10),
          lbs_override: rescueTotalForItems || item.lbs_override === '' ? null : parseInt(item.lbs_override, 10),
          food_description_id: parseInt(item.food_description_id, 10),
          unit_other: item.unit_other || null,
          food_description_other: item.food_description_other || null,
          lbs_smart_override: item.lbs_smart_override || false,
        })),
    receiver_id: data.receiver_id,
  };
};

export const mapRescueToPastRescueForm = rescue => ({
  food_types: rescue.food_types,
  food_type_other: rescue.food_type_other || '',
  slug: rescue.slug || '',
  lbs: rescue.lbs || '',
  no_food: rescue.no_food === true,
  weight_lbs: rescue.weight_lbs,
  rescue_size_id: rescue.rescue_size_id,
  donation_size_id: rescue.donation_size_id,
  pickup_begin: rescue.pickup_begin,
  weight_lbs_override: rescue.weight_lbs_override,
  pickup_end: rescue.pickup_end,
  rescuer_notes: rescue.rescuer_notes || '',
  rescuer_id: rescue.claimer_id || '',
  cancelled_by_id: rescue.cancelled_by_id || '',
  completed: `${rescue.completed}`,
  closed_by_id: rescue.closed_by_id || '',
  close_comments: rescue.close_comments || '',
  items: rescue.rescued_food || [],
  receiver_id: rescue.receiver_id,
});

export const mapFormPastRescuesPATCH = (rescues = []) =>
  rescues.map((rescue) => {
    let status = null;

    if (rescue.newData.status) {
      switch (rescue.newData.status) {
        case RESCUE_NOT_CLOSED:
          status = 'not-closed';
          break;
        case RESCUE_CLOSED:
          status = 'closed';
          break;
        case RESCUE_CLOSED_NO_SHOW:
          status = 'no-show';
          break;
        default:
          break;
      }
    } else {
      switch (rescue.completed) {
        case null:
          status = 'not-closed';
          break;
        case true:
          status = 'closed';
          break;
        case false:
          status = 'no-show';
          break;
        default:
          break;
      }
    }

    return {
      rescue_id: rescue.newData.rescue_id,
      receiver_id: rescue.newData.receiving_agency_id || rescue.receiver_id,
      lbs: rescue.newData.lbs || rescue.weight_lbs,
      status,
    };
  });
