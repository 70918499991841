import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { debounceSearchRender } from 'mui-datatables';
import { Delete as DeleteIcon } from '@material-ui/icons';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { formatDateTime, formatCsvFileName } from '../helpers/formatters';
import { getMuiTableDataIndex } from '../helpers/getters';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import TableActionButton from './TableActionButton';
import ConfirmationDialog from './ConfirmationDialog';

const InProgressRegistrationsTable = ({ tableId, isLoading, userRegistrations, deleteUserRegistration }) => {
  const emailVerifiedCustomBodyRender = value => value !== null ? 'Yes' : 'No';
  
  const phoneVerifiedCustomBodyRender = value => value !== null ? 'Yes' : 'No';
  
  const socialCustomBodyRender = value => value !== null ? 'Yes' : 'No';
  
  const tosAcceptedCustomBodyRender = value => value !== null ? 'Yes' : 'No';
  
  const createdAtCustomBodyRender = value => formatDateTime(value);
  
  return (
    <BaseMUIDataTable
      isLoading={isLoading}
      tableId={tableId}
      data={userRegistrations}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'firstname',
          label: 'First Name',
          options: {
            customBodyRender: value => value !== null ? value : '-',
          },
        },
        {
          name: 'lastname',
          label: 'Last Name',
          options: {
            customBodyRender: value => value !== null ? value : '-',
          },
        },
        {
          name: 'email',
          label: 'Email',
        },
        {
          name: 'email_verified',
          label: 'Email Verified',
          options: {
            customBodyRender: value => emailVerifiedCustomBodyRender(value),
            customBodyRenderCSV: value => emailVerifiedCustomBodyRender(value),
          },
        },
        {
          name: 'phone',
          label: 'Phone#',
          options: {
            customBodyRender: value => value !== null ? value : '-',
          },
        },
        {
          name: 'phone_verified',
          label: 'Phone# Verified',
          options: {
            customBodyRender: value => phoneVerifiedCustomBodyRender(value),
            customBodyRenderCSV: value => phoneVerifiedCustomBodyRender(value),
          },
        },
        {
          name: 'zip_code',
          label: 'Zip Code',
          options: {
            customBodyRender: value => value !== null ? value : '-',
          },
        },
        {
          name: 'social',
          label: 'Social',
          options: {
            customBodyRender: value => socialCustomBodyRender(value),
            customBodyRenderCSV: value => socialCustomBodyRender(value),
          },
        },
        {
          name: 'tos_accepted',
          label: 'ToS Accepted',
          options: {
            customBodyRender: value => tosAcceptedCustomBodyRender(value),
            customBodyRenderCSV: value => tosAcceptedCustomBodyRender(value),
          },
        },
        {
          name: 'created_at',
          label: 'Created At',
          options: {
            customBodyRender: value => createdAtCustomBodyRender(value),
            customBodyRenderCSV: value => createdAtCustomBodyRender(value),
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            display: true,
            forceDisplay: true,
            customBodyRender: (value, tableMeta) => {
              const userRegistration = userRegistrations[getMuiTableDataIndex(tableMeta)];

              return (
                <TableActionsButtonsWrapper>
                  <TableActionButton
                    title="Delete User Registration"
                    icon={DeleteIcon}
                    onClick={() => {
                      confirmAlert({
                        title: 'Delete this user registration?',
                        message: 'Are you sure you want to delete this user registration?',
                        buttons: [
                          {
                            label: 'No',
                            color: 'primary',
                          },
                          {
                            label: 'Yes',
                            color: 'primary',
                            onClick: () => deleteUserRegistration(userRegistration.id),
                          },
                        ],
                        customUI: ({ title, message, onClose, buttons }) => (
                          <ConfirmationDialog
                            buttons={buttons}
                            closeDialog={onClose}
                            title={title}
                            message={message}
                          />
                        ),
                      });
                    }}
                  />
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        customSearchRender: debounceSearchRender(500),
        sortOrder: {
          name: 'date',
          direction: 'asc',
        },
        rowsPerPage: 15,
        selectableRows: 'none',
        responsive: 'simple',
        downloadOptions: {
          filename: formatCsvFileName('In Progress Registrations'),
        },
      }}
    />
  );
};

export default InProgressRegistrationsTable;
