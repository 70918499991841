import React, { useContext } from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { usePickupSpecsStyles } from '../pickupSpecsStyles';
import { FREQUENCY_MONTHLY, FREQUENCY_ONCE, FREQUENCY_WEEKLY } from '../../../../../../models/donationsNew';
import DonationContext from '../../../../../../context/DonationContext/DonationContext';
import { DONATION_TYPE_SD } from '../../../../../../actions/donationNew';

const AllPickupsTheSameSeparator = ({ frequency, allPickupsTheSame, totalStackedPickups }) => {
  const classes = usePickupSpecsStyles();

  const { donationType } = useContext(DonationContext);

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {frequency === FREQUENCY_WEEKLY && (
          <TableCell align="center" className={classes.dayColumn}>
            Day
          </TableCell>
        )}
        {frequency === FREQUENCY_MONTHLY && (
          <>
            <TableCell align="center" className={classes.dayColumn}>
              Every
            </TableCell>
          </>
        )}
        {totalStackedPickups >= FREQUENCY_WEEKLY && (
          <>
            {!allPickupsTheSame && (
              <TableCell align="center" className={classes.positionColumn}>
                Rescuer#
              </TableCell>
            )}
          </>
        )}
        <TableCell align="center" className={classes.timeColumn}>
          Begin
        </TableCell>
        <TableCell align="center" className={classes.timeColumn}>
          End
        </TableCell>

        {[DONATION_TYPE_SD].includes(donationType) && (
          <TableCell align="center" className={classes.timeColumn}>
            #Rescuers
          </TableCell>
        )}

        <TableCell align="center" className={classes.notesColumn}>
          Rescuer Notes
        </TableCell>

        {[DONATION_TYPE_SD].includes(donationType) && (
          <TableCell align="center" className={classes.receiverColumn}>
            Receiver
          </TableCell>
        )}

        {[DONATION_TYPE_SD].includes(donationType) && frequency !== FREQUENCY_ONCE && (
          <TableCell align="center" className={classes.receiverColumn}>
            Adopter
          </TableCell>
        )}

        {[DONATION_TYPE_SD].includes(donationType) && frequency === FREQUENCY_ONCE && (
          <TableCell align="center" className={classes.receiverColumn}>
            Rescuer
          </TableCell>
        )}
        <TableCell align="center" className={classes.toolsColumn} />
      </TableRow>
    </TableHead>
  );
};

export default AllPickupsTheSameSeparator;
