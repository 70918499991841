import React, { useEffect, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FilterComponent from './FilterComponent';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  autocomplete: {
    width: '100%',
    padding: '5px 15px',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
    width: '100%',
  },
  popperDisablePortal: {
    position: 'relative',
    width: '100% !important',
  },
  flexColumn: {
    flexDirection: 'column',
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const DonorReceiverFilter = ({ value, options, handleDonorReceiverChange }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [listValue, setListValue] = useState(value);

  useEffect(() => {
    handleDonorReceiverChange(listValue);
  }, [listValue]);

  const getLabel = selectedValues => {
    const selectedUniqueValuesList = [...new Set(selectedValues.map(r => r.title))];
    // filter selected values by available options (truncate values out of the list)
    const filteredSelectedValues = options.filter(option => selectedUniqueValuesList.includes(option.title));

    const selectedOptions = filteredSelectedValues.length;
    if (selectedOptions === 0 || selectedOptions === options.length) {
      return 'Donor/Receiving Agency: all';
    }

    return `Donor/Receiving Agency: ${selectedOptions} selected`;
  };

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getLabel(listValue)} />
        {isOpen ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <FilterComponent isOpen={isOpen}>
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Autocomplete
            open
            onClose={() => {}}
            value={listValue}
            onChange={(event, newValue) => setListValue(newValue)}
            multiple
            classes={{
              root: classes.autocomplete,
              option: classes.option,
              paper: classes.paper,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No labels"
            getOptionSelected={(option, value) => option.title === value.title}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.title}
              </>
            )}
            options={options}
            groupBy={option => option.groupName}
            getOptionLabel={option => option.title}
            renderInput={params => (
              <TextField
                variant="outlined"
                fullWidth
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
              />
            )}
          />
        </Box>
      </FilterComponent>
    </>
  );
};

export default DonorReceiverFilter;
