import React from 'react';
import { Typography } from '@material-ui/core';
import RescuerEventsDesktopTable from '../../events/Components/RescuerEventsDesktopTable';
import TableSkeletonLoader from '../../../../../components/Common/TableSkeletonLoader';

const MyFrusEvents = ({ isLoading, onRowClick, eventsList }) => {
  if (eventsList.length === 0 && !isLoading) {
    return (
      <Typography color="textSecondary" variant="body1">
        You are not participating in any event.
      </Typography>
    );
  }

  if (isLoading) {
    return <TableSkeletonLoader cols={5} rows={3} />;
  }

  return <RescuerEventsDesktopTable tableId="#rescuer/events-table" onRowClick={onRowClick} eventsList={eventsList} />;
};

export default MyFrusEvents;
