import React, {useEffect, useState} from 'react';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { getCreateQuickSightEmbedUrl } from '../../../api/quickSight';

const SCDashboardAnalytics = () => {
  const [report, setReport] = useState('');
  const getQuickSightEmbedUrl = async () => {
    const getCreateQuickSightEmbedUrlResponse = await getCreateQuickSightEmbedUrl('sd-analytics');
    const getCreateQuickSightEmbedUrlResponseData = await getCreateQuickSightEmbedUrlResponse.json();

    if (getCreateQuickSightEmbedUrlResponse.ok) {
      setReport(getCreateQuickSightEmbedUrlResponseData.data);
    }
  };

  useEffect(() => {
    getQuickSightEmbedUrl();
  }, []);

  return (
    <>
      <Breadcrumbs aria-label="Breadcrumbs">
        <Typography color="textPrimary">Analytics</Typography>
      </Breadcrumbs>

      <iframe src={report} style={{ border: 'none', width: '100%', height: '80vh' }} title="Analytics" />
    </>
  );
};

export default SCDashboardAnalytics;
