import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MenuItem, Paper, Tooltip, makeStyles } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import { get } from 'lodash';
import * as formsHelpers from '../helpers/forms';
import { getPhoneNumberFromMask } from '../helpers/getters';
import * as errorsHelper from '../helpers/errors';
import { isEmailValid, isPhoneMaskValid } from '../helpers/validators';
import errorMessages from '../assets/errorMessages';
import ZipCodeSelect from './Common/ZipCodeSelect';
import { PHONE_EXT_MAX_LENGTH, TextFieldPhoneExt } from './Form/MuiFormWrapper';
import { TextFieldWrapper as TextField } from './Form/MuiFormWrapper';
import PhoneTypeSelectNew from './PhoneTypeSelectNew';
import PrimaryContactUserSelect from './PrimaryContactUserSelect';
import { Colors } from '../assets/theme/Colors';
import NotificationsContext from '../context/NotificationsContext/NotificationsContext';
import ButtonWithLoading from './ButtonWithLoading';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    maxWidth: 500,
  },
  textWide: {
    minWidth: 480,
  },
  textState: {
    width: 60,
  },
  textZip: {
    width: 120,
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

let formDirtyFields;

const validationResolver = (data, validationContext) => {
  const errors = {};

  if (!data.name) {
    errors.name = { message: errorMessages.REQUIRED.message };
  }

  if (!data.address1) {
    errors.address1 = { message: errorMessages.REQUIRED.message };
  }

  if (!data.zip) {
    errors.zip = { message: errorMessages.REQUIRED.message };
  }

  if ((data.zip && !data.city) || !data.st) {
    errors.zip = { message: errorMessages.ERR_INVALID_ZIP_CODE.message };
  }

  if (getPhoneNumberFromMask(data.phone).length > 0 && !isPhoneMaskValid(data.phone)) {
    errors.phone = { message: errorMessages.ERR_INVALID_PHONE.message };
  }

  if (data.phone_ext && data.phone_ext.length > PHONE_EXT_MAX_LENGTH) {
    errors.phone_ext = { message: errorMessages.ERR_PHONE_EXT_TOO_LONG.message };
  }

  if (!data.type_id) {
    errors.type_id = { message: errorMessages.REQUIRED.message };
  }

  if (
    validationContext.selectedOrganizationType &&
    validationContext.selectedOrganizationType.name === 'Other' &&
    !data.type_other
  ) {
    errors.type_other = { message: errorMessages.REQUIRED.message };
  }

  if (!data.primary_contact_email) {
    errors.primary_contact_email = { message: errorMessages.REQUIRED.message };
  }

  if (data.primary_contact_email && !isEmailValid(data.primary_contact_email)) {
    errors.primary_contact_email = { message: errorMessages.ERR_INVALID_EMAIL.message };
  }

  if (!data.primary_contact_first_name) {
    errors.primary_contact_first_name = { message: errorMessages.REQUIRED.message };
  }

  if (!data.primary_contact_last_name) {
    errors.primary_contact_last_name = { message: errorMessages.REQUIRED.message };
  }

  if (!data.primary_contact_phone) {
    errors.primary_contact_phone = { message: errorMessages.REQUIRED.message };
  }

  if (validationContext.hasDuplicatedPhoneNumberError && !formDirtyFields.has('primary_contact_phone')) {
    errors.primary_contact_phone = {
      message: errorMessages.ERR_DUPLICATED_BUSINESS_ADDRESS_PHONE.message,
    };
  }

  return {
    values: Object.keys(errors).length ? {} : data,
    errors,
  };
};

const ReceiverAddForm = ({ createReceiver, receiverTypeList }) => {
  const classes = useStyles();
  const formRef = useRef();
  const [selectedOrganizationType, setSelectedOrganizationType] = useState(false);
  const [selectedPrimaryContactUser, setSelectedPrimaryContactUser] = useState(false);
  const [phoneNumberFieldFocused, setPhoneNumberFieldFocused] = useState(false);
  const [selectedPhoneType, setSelectedPhoneType] = useState('mobile');
  const [hasDuplicatedPhoneNumberError, setHasDuplicatedPhoneNumberError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    getValues,
    reset,
    errors,
    formState: { dirtyFields, isSubmitted },
  } = useForm({
    validationResolver: validationResolver,
    validationContext: {
      selectedOrganizationType: selectedOrganizationType,
      selectedPrimaryContactUser: selectedPrimaryContactUser,
      hasDuplicatedPhoneNumberError: hasDuplicatedPhoneNumberError,
    },
  });

  const notificationsContext = useContext(NotificationsContext);
  formDirtyFields = dirtyFields;

  const setFormValue = (name, value) => setValue(name, value, isSubmitted);

  const registerControlledInputs = () => {
    register({ name: 'type_id' });
    register({ name: 'city' });
    register({ name: 'st' });
    register({ name: 'primary_contact_email' });
  };

  useEffect(() => registerControlledInputs(), []);

  const onSubmit = (values) => {
    const data = {
      ...values,

      // all of the controlled inputs have to be defined manually
      primary_contact_id:
        selectedPrimaryContactUser && selectedPrimaryContactUser.meta ? selectedPrimaryContactUser.meta.id : null,
      phone_type: selectedPhoneType || null,
    };

    // check if user created any new user in the form
    // if no -> we can just submit the form straight away from this point
    // if yes -> we need to show the invitation email confirmation dialog to the user
    if (!selectedPrimaryContactUser || (selectedPrimaryContactUser && data.primary_contact_id)) {
      return onSubmitInner(data);
    }

    return formsHelpers.showSendInvitationEmailConfirmationDialog(data, onSubmitInner);
  };

  const onSubmitInner = (data, sendInvitationEmail = false) =>
    Bluebird.try(() => {
      setIsSubmitting(true);

      return createReceiver({
        ...data,
        send_invitation: sendInvitationEmail,
      });
    }).catch(err => {
      setIsSubmitting(false);

      switch (err.code) {
        case errorMessages.ERR_DUPLICATED_PHONE.code:
          setHasDuplicatedPhoneNumberError(true);

          break;
        default:
          errorsHelper.handleErrorNew(err, notificationsContext);

          break;
      }

      // reset form with the current values - to clear touched / dirty flags (there is currently no other way to do it)
      // reset form is also unregistering previously custom registered fields with useEffect, so we have to bring
      // these back and then trigger validation to show API errors
      return Bluebird.try(() => reset(getValues(), { isSubmitted: true }))
        .then(() => registerControlledInputs())
        .then(() => triggerValidation());
    });

  const handleOrganizationTypeChange = event => {
    const foundReceiverOrganizationType = receiverTypeList.find(type => type.id === event.target.value);

    setSelectedOrganizationType(foundReceiverOrganizationType);

    setFormValue('type_id', foundReceiverOrganizationType ? foundReceiverOrganizationType.id : '');
  };

  const onChangePrimaryContactUser = (option) => {
    setSelectedPrimaryContactUser(option || false);

    setFormValue('primary_contact_email', get(option, 'meta.email', option ? option.label : ''));

    if (option?.meta) {
      setFormValue('primary_contact_email', get(option, 'meta.email', option ? option.label : ''));
      setFormValue('primary_contact_first_name', get(option, 'meta.firstname', ''));
      setFormValue('primary_contact_last_name', get(option, 'meta.lastname', ''));
      setFormValue('primary_contact_phone', get(option, 'meta.phone', ''));
    }
  };

  const handlePhoneTypeChange = (event) => setSelectedPhoneType(event.target.value);

  return (
    <Paper className={classes.paper}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
        data-testid="receiver-location-add-form"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              fullWidth
              name="name"
              label="Name"
              variant="outlined"
              size="small"
              inputRef={register}
              InputLabelProps={{ shrink: !!getValues('name') || undefined }}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="type_id"
              name="type_id"
              fullWidth
              label="Organization type"
              variant="outlined"
              size="small"
              value={selectedOrganizationType ? selectedOrganizationType.id : ''}
              onChange={handleOrganizationTypeChange}
              error={!!errors.type_id}
              helperText={errors.type_id ? errors.type_id.message : ''}
              InputLabelProps={{ shrink: !!getValues('type_id') || undefined }}
              select
              data-testid="organization-type-select"
            >
              {receiverTypeList.map(receiverType => (
                <MenuItem key={receiverType.id} value={receiverType.id}>
                  {receiverType.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {selectedOrganizationType && selectedOrganizationType.name === 'Other' && (
            <Grid item xs={12}>
              <TextField
                required
                id="type_other"
                name="type_other"
                fullWidth
                label="Other type"
                variant="outlined"
                size="small"
                inputRef={register}
                error={!!errors.type_other}
                helperText={errors.type_other ? errors.type_other.message : ''}
                InputLabelProps={{ shrink: !!getValues('type_other') || undefined }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              name="ein"
              fullWidth
              label="EIN"
              variant="outlined"
              size="small"
              inputRef={register}
              InputLabelProps={{ shrink: !!getValues('ein') || undefined }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              name="address1"
              fullWidth
              label="Address"
              variant="outlined"
              size="small"
              inputRef={register}
              error={!!errors.address1}
              helperText={errors.address1 ? errors.address1.message : ''}
              InputLabelProps={{ shrink: !!getValues('address1') || undefined }}
            />
          </Grid>

          <Grid item xs={12}>
            <ZipCodeSelect
              required
              name="zip"
              label="Zip code"
              fullWidth
              onZipCodeChange={({ zipCode, data }) => {
                if (zipCode.length >= 3) {
                  setFormValue('st', data ? data.state : '');
                } else {
                  setFormValue('st', '');
                }

                if (zipCode.length === 5) {
                  setFormValue('city', data ? data.city : '');
                } else {
                  setFormValue('city', '');
                }
              }}
              error={!!errors.zip}
              helperText={get(errors, 'zip.message', false)}
              inputRef={register}
            />
          </Grid>

          <Grid item xs={12}>
            <InputMask
              mask="999-999-9999"
              onFocus={() => setPhoneNumberFieldFocused(true)}
              onBlur={() => setPhoneNumberFieldFocused(false)}
            >
              <TextField
                id="phone"
                name="phone"
                fullWidth
                label="Phone Number"
                variant="outlined"
                size="small"
                inputRef={register}
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ''}
                autoComplete="chrome-off"
                InputLabelProps={{ shrink: !!getValues('phone') || phoneNumberFieldFocused }}
              />
            </InputMask>
          </Grid>

          <Grid item xs={12}>
            <TextFieldPhoneExt
              name="phone_ext"
              fullWidth
              label="Phone Number Extension"
              variant="outlined"
              size="small"
              error={!!errors.phone_ext}
              helperText={errors.phone_ext ? errors.phone_ext.message : ''}
              inputRef={register}
              InputLabelProps={{ shrink: !!getValues('phone_ext') || undefined }}
            />
          </Grid>

          <Grid item xs={12}>
            <PhoneTypeSelectNew
              id="phone_type"
              name="phone_type"
              fullWidth
              label="Phone Type"
              value={selectedPhoneType || 'mobile'}
              onChange={handlePhoneTypeChange}
              error={!!errors.phone_type}
              helperText={errors.phone_type ? errors.phone_type.message : ''}
              InputLabelProps={{ shrink: !!getValues('phone_type') || undefined }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="grid" gridAutoFlow="row" gridRowGap={16} mt={2} mb={1} >
              <PrimaryContactUserSelect
                required
                label="Rescues Contact Person: Email"
                name="primary_contact_email"
                fullWidth
                onChangeUser={onChangePrimaryContactUser}
                error={errors.primary_contact_email}
                formValue={getValues('primary_contact_email')}
              />

              <TextField
                required
                id="primary_contact_first_name"
                name="primary_contact_first_name"
                fullWidth
                label="Rescues Contact Person: First Name"
                variant="outlined"
                size="small"
                inputRef={register}
                error={!!errors.primary_contact_first_name}
                helperText={
                  errors.primary_contact_first_name
                    ? errors.primary_contact_first_name.message
                    : ''
                }
                disabled={!!selectedPrimaryContactUser.value}
                autoComplete="chrome-off"
                InputLabelProps={{ shrink: !!getValues('primary_contact_first_name') || undefined }}
              />

              <TextField
                required
                id="primary_contact_last_name"
                name="primary_contact_last_name"
                fullWidth
                label="Rescues Contact Person: Last Name"
                variant="outlined"
                size="small"
                inputRef={register}
                error={!!errors.primary_contact_last_name}
                helperText={
                  errors.primary_contact_last_name
                    ? errors.primary_contact_last_name.message
                    : ''
                }
                disabled={!!selectedPrimaryContactUser.value}
                autoComplete="chrome-off"
                InputLabelProps={{ shrink: !!getValues('primary_contact_last_name') || undefined }}
              />

              <InputMask
                mask="999-999-9999"
                disabled={!!selectedPrimaryContactUser.value}
                onFocus={() => setPhoneNumberFieldFocused(true)}
                onBlur={() => setPhoneNumberFieldFocused(false)}
              >
                <TextField
                  required
                  id="primary_contact_phone"
                  name="primary_contact_phone"
                  fullWidth
                  label="Rescues Contact Person: Phone"
                  variant="outlined"
                  size="small"
                  inputRef={register}
                  error={!!errors.primary_contact_phone}
                  helperText={
                    errors.primary_contact_phone
                      ? errors.primary_contact_phone.message
                      : ''
                  }
                  autoComplete="chrome-off"
                  InputLabelProps={{
                    shrink:
                      !!getValues('primary_contact_phone') || phoneNumberFieldFocused,
                  }}
                />
              </InputMask>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <TextField
                name="notes"
                fullWidth
                label="Location notes - admin use"
                variant="outlined"
                size="small"
                inputRef={register}
                InputLabelProps={{ shrink: !!getValues('notes') || undefined }}
                multiline
              />
              <Box mx={1}>
                <Tooltip arrow placement="top" title="These notes are like a post-it note about the Food Donor">
                  <HelpIcon htmlColor={Colors.blue.main} />
                </Tooltip>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <TextField
                name="dropoff_notes"
                fullWidth
                label="Dropoff notes"
                variant="outlined"
                size="small"
                inputRef={register}
                InputLabelProps={{ shrink: !!getValues('dropoff_notes') || undefined }}
                multiline
              />
              <Box mx={1}>
                <Tooltip
                  arrow
                  placement="top"
                  title="These notes are shown to all rescuers dropping off at this location"
                >
                  <HelpIcon htmlColor={Colors.blue.main} />
                </Tooltip>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ButtonWithLoading type="submit" variant="contained" color="primary" isLoading={isSubmitting}>
              Create Receiving Agency
            </ButtonWithLoading>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ReceiverAddForm;
