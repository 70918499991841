import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    boxShadow: theme.shadows[25],
    border: ({ withBorder }) => (withBorder ? `1px solid ${theme.palette.divider}` : 'none'),
  },
}));

const HtmlTooltip = ({ children, title, withBorder = false, ...rest }) => {
  const classes = useStyles({ withBorder: withBorder });
  return (
    <Tooltip classes={classes} title={title} {...rest}>
      {children}
    </Tooltip>
  );
}

export default HtmlTooltip;
