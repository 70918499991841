import React from 'react';
import moment from 'moment';
import errorMessages from '../../../assets/errorMessages';

const eventFormValidationResolver = data => {
  const errors = {
    shifts: [],
  };

  if (!data.title) {
    errors.title = { message: errorMessages.REQUIRED.message };
  }

  if (data.digital && !data.link) {
    errors.link = { message: errorMessages.REQUIRED.message };
  }

  if (!data.address && !data.digital) {
    errors.address = { message: errorMessages.REQUIRED.message };
  }

  if (!data.zip && !data.digital) {
    errors.zip = { message: errorMessages.REQUIRED.message };
  }

  if (!data.digital && data.zip && (!data.city || !data.state)) {
    errors.zip = { message: errorMessages.ERR_INVALID_ZIP_CODE.message };
  }

  if (data.end && data.end.isBefore(data.begin)) {
    errors.end = {
      message: 'Event end time must be after start time!',
    };
  }

  if (data.end_date && moment(data.end_date).isBefore(moment(data.date).format('YYYY-MM-DD'))) {
    errors.end_date = {
      message: 'Event end time must be after start time!',
    };
  }

  if (data.pause_date && moment(data.pause_date).isBefore(moment(data.date).format('YYYY-MM-DD'))) {
    errors.pause_date = {
      message: 'Event pause time must be after start time!',
    };
  }

  if (data.resume_date && moment(data.resume_date).isBefore(data.pause_date)) {
    errors.resume_date = {
      message: 'Event resume time must be after pause time!',
    };
  }

  if (
    data.frequency === (1 || 3) &&
    data.days_of_week &&
    Object.values(data.days_of_week).filter((day) => day.checked).length === 0
  ) {
    errors.days_of_week = {
      message: 'You must select at least 1 day of the week',
    };
  }

  if (
    data.frequency === 2 &&
    data.days_of_month &&
    Object.values(data.days_of_month).filter((day) => day.length).length === 0
  ) {
    errors.days_of_month = {
      message: 'You must select at least 1 day of the month',
    };
  }

  data.shifts.forEach(shift => {
    const rowErrors = {};

    const hasTheSameValue = data.shifts.filter(
      (filteredShift) =>
        data.shifts.indexOf(filteredShift) !== data.shifts.indexOf(shift) &&
        filteredShift.begin.format('HH:mm:ss') === shift.begin.format('HH:mm:ss') &&
        filteredShift.end.format('HH:mm:ss') === shift.end.format('HH:mm:ss')
    );

    if (hasTheSameValue.length > 0) {
      rowErrors.begin = { message: "Shifts can't have the same time!" };
      rowErrors.end = { message: "Shifts can't have the same time!" };
    }

    if (data.begin && !shift.begin.isBetween(data.begin, data.end, null, '[]')) {
      rowErrors.begin = {
        message: 'Shift start time does not fits event time!',
      };
    }

    if (data.end && !shift.end.isBetween(data.begin, data.end, null, '[]')) {
      rowErrors.end = {
        message: 'Shift end time does not fits event time!',
      };
    }

    if (data.end && shift.end.isBefore(shift.begin)) {
      rowErrors.end = {
        message: 'Shift end time must be after start time!',
      };
    }

    if (shift.min_volunteers && shift.max_volunteers && Number(shift.min_volunteers) > Number(shift.max_volunteers)) {
      rowErrors.max_volunteers = {
        message: 'Max volunteers cannot be lower than min volunteers!',
      };
    }

    errors.shifts.push(rowErrors);
  });

  const hasErrors = Object.values(errors).some((row) => {
    // check for array fields errors
    if (Array.isArray(row)) {
      return row.some((rowObj) => Object.keys(rowObj).length > 0);
    }
    // check for 0 level errors
    if (typeof row === 'object') {
      return Object.keys(row).length > 0;
    }

    return false;
  });

  return {
    values: hasErrors ? {} : data,
    errors: hasErrors ? errors : {},
  };
};

export default eventFormValidationResolver;
