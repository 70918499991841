import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Paper, Typography, Grid, IconButton, } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import indigo from '@material-ui/core/colors/indigo';
import CookieService from '../../services/CookieService';
import { withStyles } from '@material-ui/styles';
import { IOSDialog } from './PWAAdd2HSTutorial';
import { isIOS, isAndroid, isEdgeChromium, isChrome, isOpera } from 'react-device-detect';

const StyledPaper = withStyles(({ spacing }) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    borderRadius: 0,
    borderTop: `1px solid ${indigo[500]}`,
    width: '100%',
    zIndex: 1200,
    paddingLeft: 0,
    padding: spacing(2),
  },
}))(Paper);

const A2HS_COOKIE = 'show_a2hs';

export const isSupportedAndroidDevice = isAndroid && (isChrome || isEdgeChromium || isOpera);
export const isSupportedIOSDevice = isIOS;
export const isSupportedDevice = isIOS || isSupportedAndroidDevice;
export const isInPWAView = window.matchMedia('(display-mode: standalone)').matches;

const PWAInstallButton = () => {
  const canShowPrompt = !CookieService.get(A2HS_COOKIE) && !isInPWAView;
  // by default notification is hidden, we will make it visible in beforeinstallprompt
  const [beforeInstallPromptEvent, setBeforeInstallPromptEvent] = useState(undefined);
  const [showA2HSNotification, setShowA2HSNotification] = useState(false);
  const [isIOSDialogOpen, setIsIOSDialogOpen] = useState(false);
  const { formatMessage, FormattedMessage } = useIntl();

  useEffect(() => {
    if (canShowPrompt && isSupportedAndroidDevice) {
      window.addEventListener('beforeinstallprompt', event => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        event.preventDefault();
        // Stash the event so it can be triggered later.

        setBeforeInstallPromptEvent(event);
        setShowA2HSNotification(true);
      });
    }

    if (canShowPrompt && isIOS) {
      setShowA2HSNotification(true);
    }
  }, []);

  const dismissA2HSInstallation = () => {
    setShowA2HSNotification(false);
    CookieService.set(A2HS_COOKIE, 'false', 1);
  };

  const showAndroidInstallPrompt = () => {
    if (!beforeInstallPromptEvent) {
      return;
    }

    setShowA2HSNotification(false);
    beforeInstallPromptEvent.prompt();

    beforeInstallPromptEvent.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        setShowA2HSNotification(false);
      } else {
        console.log('User dismissed the A2HS prompt');
        setShowA2HSNotification(true);
      }
      setBeforeInstallPromptEvent(null);
    });
  };

  if (isInPWAView || !showA2HSNotification) {
    return null;
  }

  return (
    <>
      <IOSDialog open={isIOSDialogOpen} onClose={() => setIsIOSDialogOpen(false)} />
      <StyledPaper id="pwa-container">
        <Grid container direction="row" justify="space-evenly" alignItems="center" wrap="nowrap" spacing={0}>
          <Grid item>
            <IconButton aria-label="delete" size="small" edge={false} onClick={dismissA2HSInstallation}>
              <CancelIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="body2">Food Rescue US</Typography>
            <Typography variant="caption">
              {formatMessage({ id: 'pwa-install.add-food-rescue-us-to-your-home-screen' })}
            </Typography>
            <br />
            <Typography variant="caption">
              {formatMessage({ id: 'pwa-install.it-will-look-and-feel-just-like-an-app' })}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              id="pwa-install-btn"
              color="primary"
              variant="outlined"
              disableElevation
              disableFocusRipple
              onClick={() => {
                if (isAndroid) {
                  showAndroidInstallPrompt();
                }
                if (isIOS) {
                  setIsIOSDialogOpen(true);
                }
              }}
            >
              {formatMessage({ id: 'pwa-install.add-button' })}
            </Button>
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
};

export default PWAInstallButton;
