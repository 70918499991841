import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Box } from '@material-ui/core';
import useActiveUser from '../../../../hooks/useActiveUser';

const HelloUser = () => {
  const activeUser = useActiveUser();
  const { formatMessage } = useIntl();

  return (
    <Box mt={0} mb={3}>
      <Typography variant="h3">
        {formatMessage({ id: 'dashboard-mobile.hello' })}, {activeUser.firstname}!
      </Typography>

      <Typography variant="body2" color="textSecondary">
        {formatMessage({ id: 'dashboard-mobile.click-on-the-tiles-to-navigate' })}
      </Typography>
    </Box>
  );
};

export default HelloUser;
