import React from 'react';
import { Box, Grid, IconButton, Link, List, ListItem, ListItemText, Typography, Divider } from '@material-ui/core';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { formatPhoneNumber } from '../../../helpers/formatters';
import HtmlTooltip from '../../../components/Common/HtmlTooltip';

const copyToClipboard = value => navigator.clipboard.writeText(value);

const ContactListDetails = ({ phone, email, meta }) => {
  if (!meta) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <List>
        <ListItem component="div">
          <ListItemText
            disableTypography
            primary={<Typography variant="body2">Email</Typography>}
            secondary={
              <Grid container alignItems="baseline" justify="space-between">
                <Grid item xs>
                  <Typography variant="body1" component="div">
                    <Link href={`mailto:${email}`}>{email}</Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => copyToClipboard(email)}>
                    <HtmlTooltip title="Copy">
                      <FileCopyIcon />
                    </HtmlTooltip>
                  </IconButton>
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        <Divider />
        {phone && (
          <ListItem component="div">
            <ListItemText
              disableTypography
              primary={<Typography variant="body2">Phone#</Typography>}
              secondary={
                <Grid container alignItems="baseline" justify="space-between">
                  <Grid item xs>
                    <Typography variant="body1" component="div">
                      <Link href={`tel:${phone}`}>{formatPhoneNumber(phone)}</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => copyToClipboard(phone)}>
                      <HtmlTooltip title="Copy">
                        <FileCopyIcon />
                      </HtmlTooltip>
                    </IconButton>
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default ContactListDetails;
