import React from 'react';
import moment from 'moment';
import { makeStyles, createMuiTheme, MuiThemeProvider, Tooltip, Button } from '@material-ui/core';
import BaseMUIDataTable from '../../../../../components/BaseMUIDataTable/BaseMUIDataTable';
import { Colors } from '../../../../../assets/theme/Colors';
import { formatTime } from '../../../../../helpers/formatters';
import { getMuiTableDataIndex } from '../../../../../helpers/getters';
import { getRescuePickupLocationFullName, getRescuePosition } from '../../../../../helpers/RescuesHelper';
import useActiveUser from '../../../../../hooks/useActiveUser';
import ReleaseRescueDesktopButtons from '../../../../../components/RescueNotification/ReleaseRescueDesktopButtons';

const useStyles = makeStyles(() => ({
  dateCell: {
    width: 125,
  },
  dayCell: {
    width: 125,
  },
  pickupBeginCell: {
    width: 125,
  },
  pickupEndCell: {
    width: 125,
  },
  cancelledRescueRow: {
    '& > td': {
      opacity: 0.25,
    },
  },
  cancelledRescueDateCell: {
    opacity: '1 !important',
    color: '#c1c1c1',
  },
}));

const RescuerDashboardMyRescuesTable = ({
  tableId,
  rescues,
  onCellClick,
}) => {
  const classes = useStyles();
  const activeUser = useActiveUser();

  return (
    <MuiThemeProvider theme={theme => createMuiTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MUIDataTableHeadCell: {
          root: {
            ...theme.overrides.MUIDataTableHeadCell.root,
            '&:last-child': {
              width: 255,
            },
          },
        },
      },
    })}>
      <BaseMUIDataTable
        tableId={tableId}
        data={rescues}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              sort: false,
              display: false,
            },
          },
          {
            name: 'date',
            label: 'Date',
            options: {
              setCellProps: () => ({ className: classes.dateCell }),
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                return (
                  <>
                    <span>
                      {moment(value, 'YYYYMMDD').format('MM/DD/YYYY')}
                    </span>

                    {rescue && rescue.cancelled_by_id && (
                      <>
                        <br />

                        <span style={{ color: Colors.errorText, fontWeight: 'bold' }}>
                          Cancelled
                        </span>
                      </>
                    )}
                  </>
                );
              },
            },
          },
          {
            name: 'day',
            label: 'Day',
            options: {
              setCellProps: () => ({ className: classes.dayCell }),
              customBodyRender: (value, tableMeta) =>
                moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd'),
            },
          },
          {
            name: 'pickup_begin',
            label: 'Begin',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupBeginCell }),
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'pickup_end',
            label: 'End',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupEndCell }),
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'location',
            label: 'Food Donor',
            options: {
              customFilterListOptions: {
                render: value => `Food Donor: ${value}`,
              },
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return null;
                }

                return getRescuePickupLocationFullName(rescue);
              },
            },
          },
          {
            name: 'receiver',
            label: 'Receiver',
            options: {
              customBodyRender: value => value || '',
            },
          },
          {
            name: 'slug',
            label: 'Description',
            options: {
              filter: false,
            },
          },
          {
            name: 'position',
            label: 'Position',
            options: {
              filter: false,
              customBodyRenderLite: dataIndex => getRescuePosition(rescues[dataIndex]),
            },
          },
          {
            name: 'notes',
            label: 'Pickup Notes',
            options: {
              filter: false,
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              Empty: true,
              filter: false,
              searchable: false,
              viewColumns: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue || rescue.cancelled_by_id !== null) {
                  return false;
                }

                return <ReleaseRescueDesktopButtons rescue={rescue} />;
              },
            },
          },
        ]}
        options={{
          sortOrder: {
            name: 'date',
            direction: 'asc',
          },
          elevation: 0,
          rowsPerPage: 15,
          selectableRows: 'none',
          responsive: 'simple',
          onCellClick: (colData, cellMeta) => {
            // ignore click on "actions" column cell
            if (cellMeta.colIndex >= 9) {
              return false;
            }

            const rescue = rescues[getMuiTableDataIndex(cellMeta)];

            if (!rescue) {
              return false;
            }

            return onCellClick(rescue);
          },
          setRowProps: (row, dataIndex) => {
            const rescue = rescues[dataIndex];

            if (!rescue || !rescue.cancelled_by_id) {
              return false;
            }

            return {
              className: classes.cancelledRescueRow,
            };
          },
        }}
      />
    </MuiThemeProvider>
  );
};

export default RescuerDashboardMyRescuesTable;
