import { apiGET } from './api';

export const getCreateQuickSightEmbedUrl = (dashboardName = '') => {
  let url = '/aws/create-quicksight-embed-url';

  if (dashboardName) {
    url += `?dashboard_name=${dashboardName}`;
  }

  return apiGET(url);
};
