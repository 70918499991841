import React from 'react';
import { CardHeader, Typography, CardActions, Grid, Button, Box, Card } from '@material-ui/core';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import { Virtuoso } from 'react-virtuoso';

const useStyles = makeStyles(() => ({
  title: {
    lineHeight: 1.2,
  },
}));

const NotificationCard = ({ data, markAsRead, showDetails, showMarkNotificationAsReadButton }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardHeader
        disableTypography
        onClick={() => showDetails(data.id)}
        title={
          <Typography gutterBottom className={classes.title} component="p" variant="subtitle1">
            {data.subject}
          </Typography>
        }
        subheader={
          <Typography component="p" variant="body2" color="textSecondary">
            {moment(data.created_at).format('MM/DD/YYYY h:mm A')}
          </Typography>
        }
      />

      {showMarkNotificationAsReadButton && (
        <CardActions disableSpacing>
          <Button
            startIcon={<CheckIcon />}
            variant="contained"
            color="primary"
            aria-label="mark as read"
            onClick={() => markAsRead(data.id)}
          >
            Mark as read
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const NotificationsCardList = ({
  isLoading,
  notifications,
  markAsRead,
  goToNotificationDetails,
  showMarkNotificationAsReadButton,
}) => (
  <Grid container direction="column" justify="center" alignItems="stretch">
    {!isLoading && notifications.length === 0 && (
      <Grid item xs>
        No data to present.
      </Grid>
    )}
    {!isLoading && notifications.length > 0 && (
      <Virtuoso
        style={{ width: '100%', height: '100%' }}
        totalCount={notifications.length}
        itemContent={index => {
          const top = index === 0 ? 0 : 2;
          const bottom = index === notifications.length ? 0 : 2;
          const notification = notifications[index];
          return (
            <Grid item xs zeroMinWidth key={notification.id}>
              <Box mt={top} mb={bottom}>
                <NotificationCard
                  showDetails={goToNotificationDetails}
                  markAsRead={markAsRead}
                  data={notification}
                  showMarkNotificationAsReadButton={showMarkNotificationAsReadButton}
                />
              </Box>
            </Grid>
          );
        }}
      />
    )}
  </Grid>
);

export default NotificationsCardList;
