import React from 'react';
import { Button, Card, CardActions, CardContent, makeStyles } from '@material-ui/core';
import * as authService from '../services/auth';

const PushInstallButtonDesktopNotification = ({ onAccept, onDismiss }) => {
  const user = authService.getCurrentlyLoggedInOrImpersonatingUser();

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#e1ebfd',
      width: '100%',
      paddingLeft: () => {
        if (user && !authService.shouldHideMainDrawer(user)) {
          return 70;
        }

        return 16;
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0px !important',
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: 0 }} data-testid="close-rescue-reminder-card">
        <div className={classes.title}>Push notifications</div>
        <div className={classes.body}>Do you want to enable push notifications?</div>
      </CardContent>

      <CardActions>
        <Button size="small" color="primary" variant="contained" onClick={onAccept}>
          Yes
        </Button>

        <Button size="small" color="primary" onClick={onDismiss}>
          Not now
        </Button>
      </CardActions>
    </Card>
  );
};

export default PushInstallButtonDesktopNotification;
