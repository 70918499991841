import React from 'react';
import { useSelector } from 'react-redux';
import PickupsListGrid from './PickupsListGrid/PickupsListGrid';
import AllPickupsTheSame from './AllPickupsTheSame/AllPickupsTheSame';
import AllDaysTheSame from './AllDaysTheSame/AllDaysTheSame';
import { FREQUENCY_ONCE } from '../../../../../models/donationsNew';

const PickupsFormVariant = () => {
  const {
    all_days_are_the_same: allDaysTheSame,
    all_pickups_are_the_same: allPickupsTheSame,
    frequency,
  } = useSelector(state => state.ui.donation_new.draft);

  if (allDaysTheSame || (frequency === FREQUENCY_ONCE && allPickupsTheSame)) {
    return <AllDaysTheSame />;
  }

  if (allPickupsTheSame) {
    return <AllPickupsTheSame />;
  }

  return <PickupsListGrid />;
};

export default PickupsFormVariant;
