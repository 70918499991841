import { apiDELETE, apiGET, apiPOST, apiPUT } from './api';

export const getUmbrellaFoodDonors = () => apiGET('/donors');

export const getUmbrellaFoodDonor = umbrellaFoodDonorId => apiGET(`/donors/${umbrellaFoodDonorId}`);

export const getUmbrellaFoodDonorStaff = umbrellaFoodDonorId => apiGET(`/donors/${umbrellaFoodDonorId}/staff`);

export const createUmbrellaFoodDonor = data => apiPOST('/donors', data);

export const createUmbrellaStaff = (umbrellaFoodDonorId, data) => apiPOST(`/donors/${umbrellaFoodDonorId}/staff`, data);

export const deleteUmbrellaStaff = (umbrellaFoodDonorId, staffId) =>
  apiDELETE(`/donors/${umbrellaFoodDonorId}/staff/${staffId}`);

export const updateUmbrellaFoodDonor = (umbrellaFoodDonorId, data) => apiPUT(`/donors/${umbrellaFoodDonorId}`, data);
