import React from 'react';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import { generatePath } from 'react-router-dom';
import routes from '../routes';

import {
  LocalGroceryStore as LocalGroceryStoreIcon,
  Kitchen as KitchenIcon,
  LocationCity as LocationCityIcon,
  DirectionsCar as DirectionsCarIcon,
} from '@material-ui/icons';

export const hasFABAvailableActions = actions => Object.values(actions).some(actionGroup => actionGroup.length > 0);

export const setFABActions = (user, history, context) => {
  const actions = {};

  if (authService.hasAnyRoleInCurrentlySelectedSite(user, [Roles.SiteDirector, Roles.SiteCoordinator])) {
    actions['#sc-actions'] = [
      {
        icon: <DirectionsCarIcon />,
        name: 'Add Rescue (quick)',
        testid: 'sp-add-rescue-quick',
        onClick: () => history.push(generatePath(routes.rescueAdd)),
      },
      {
        icon: <LocalGroceryStoreIcon />,
        name: 'Add Donation',
        testid: 'sp-add-donation',
        onClick: () => history.push(generatePath(routes.donations_add_2)),
      },
      {
        icon: <KitchenIcon />,
        name: 'Add Receiver',
        testid: 'sp-add-r-agency',
        onClick: () => history.push(generatePath(routes.receivers_add)),
      },
      {
        icon: <LocationCityIcon />,
        name: 'Add Food Donor',
        testid: 'sp-add-food-donor',
        onClick: () => history.push(generatePath(routes.foodDonorAdd)),
      },
    ];
  }

  context.setFABActions(actions, hasFABAvailableActions(actions));
};
