import * as userRegistrationsActions from '../actions/userRegistrations';
import { objectFromArray } from '../helpers';

const userRegistrationsInitialState = {
  inflight: false,
  byId: {},
  allIds: [],
  lastUpdated: null,
};

const userRegistrationsReducer = (state = userRegistrationsInitialState, action) => {
  switch (action.type) {
    case userRegistrationsActions.REQUEST_USER_REGISTRATIONS:
      return {
        ...state,
        inflight: true,
      };
    case userRegistrationsActions.RECEIVE_USER_REGISTRATIONS:
      return {
        ...state,
        inflight: false,
        allIds: action.userRegistrations.map(ur => ur.id),
        byId: objectFromArray(action.userRegistrations, ur => ur.id),
        lastUpdated: action.receivedAt,
      };
    case userRegistrationsActions.REQUEST_USER_REGISTRATION_DELETE:
      return {
        ...state,
        inflight: true,
      };
    case userRegistrationsActions.RECEIVE_USER_REGISTRATION_DELETE:
      const userRegistrations = Object
        .values(state.byId)
        .filter(userRegistration => userRegistration.id !== action.userRegistrationId);

      return {
        ...state,
        inflight: false,
        allIds: userRegistrations.map(ur => ur.id),
        byId: objectFromArray(userRegistrations, ur => ur.id),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
};

export default userRegistrationsReducer;
