import { colors as MUIColors } from '@material-ui/core';
import chroma from 'chroma-js';

export const getChromaColor = color => ({
  color: chroma(color).darken(2).css(),
  backgroundColor: chroma(color).alpha(0.1).css(),
});

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

// 17 colors (colors.length) + 4 different shades = 68 unique colors
const MUIPalette = {
  shades: { 0: 400, 1: 200, 2: 600, 3: 800 },
  colors: [
    'indigo',
    'amber',
    'deepOrange',
    'blue',
    'pink',
    'brown',
    'orange',
    'lime',
    'purple',
    'blueGrey',
    'green',
    'yellow',
    'teal',
    'cyan',
    'red',
    'deepPurple',
    'grey',
  ],
};

export const getColorByIndex = colorIndex => {
  const colorsLength = MUIPalette.colors.length;

  const iteration = Math.abs(Math.trunc(colorIndex / colorsLength));
  const shade = MUIPalette.shades[iteration];

  if (!shade) {
    // we are out of shade accents, start generating some random colors
    return getRandomColor();
  }

  // reset counter if index is greater than number of colors
  const colorKey = colorIndex >= colorsLength ? colorIndex - iteration * colorsLength : colorIndex;

  return MUIColors[MUIPalette.colors[colorKey]][shade];
};
