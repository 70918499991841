import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { formatCsvFileName, formatDateTime } from '../helpers/formatters';
import { getUserFullName } from '../helpers/user';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import TableActionButton from './TableActionButton';
import routes from '../routes';
import { sortByDateAndTime } from '../helpers/sorters';
import { rangeFilter } from '../helpers/MuiTableCustomFilters';
import { get } from 'lodash';

const EmailsTable = ({ tableId, isLoading, emails = [], sitesList = [] }) => {
  const emailsSentByList = useMemo(
    () =>
      Object.values(
        emails.reduce((acc, email) => {
          if (email.created_by) {
            acc[email.created_by.id] = email.created_by;
          }

          return acc;
        }, {})
      ),
    [emails.length]
  );

  const createdByCustomBodyRender = (value, csv = false) => {
    const userFullName = getUserFullName(value);

    if (csv) {
      return userFullName ? userFullName : '';
    }

    if (!userFullName) {
      return '-';
    }

    return <Link to={generatePath(routes.userEditNew, { id: value.id })}>{userFullName}</Link>;
  };

  const createdAtCustomBodyRender = value => formatDateTime(value.value, true);

  const renderSentByList = (filterList, onChange, index, column) => (
    <FormControl>
      <InputLabel htmlFor="sent_by-selector">{column.label}</InputLabel>
      <Select
        id="sent_by-selector"
        multiple
        value={filterList[index]}
        renderValue={selected => selected.join(', ')}
        onChange={event => {
          filterList[index] = event.target.value;
          onChange(filterList[index], index, column);
        }}
      >
        {emailsSentByList.map(user => (
          <MenuItem key={user.id} value={getUserFullName(user)}>
            <Checkbox color="primary" checked={filterList[index].indexOf(getUserFullName(user)) > -1} />
            <ListItemText primary={getUserFullName(user)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <BaseMUIDataTable
      isLoading={isLoading}
      tableId={tableId}
      data={emails}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'site_id',
          label: 'Site',
          options: {
            forceDisplay: true,
            viewColumns: false,
            filter: false,
            display: sitesList.length > 1 ? true : 'excluded',
            customBodyRender: value => {
              const foundSite = sitesList.find(s => s.id === value);

              if (!foundSite) {
                return '-';
              }

              return foundSite.name;
            },
          },
        },
        {
          name: 'subject',
          label: 'Subject',
        },
        {
          name: 'reply_to',
          label: 'Reply To',
          options: {
            customFilterListOptions: {
              render: value => `Reply To: ${value}`,
            },
            customBodyRender: replyTo => (replyTo ? replyTo : '-'),
          },
        },
        {
          name: 'number_of_recipients',
          label: '# of Receivers',
          options: {
            customFilterListOptions: {
              render: value => `# of Receivers: ${value}`,
            },
            filterOptions: {
              names: rangeFilter.names,
              logic: (claimsNo, filters) => rangeFilter.logic(claimsNo, get(filters, [0], '')),
            },
          },
        },
        {
          name: 'created_by',
          label: 'Sent By',
          options: {
            filterType: 'custom',
            customFilterListOptions: {
              render: value => `Sent By: ${value}`,
            },
            filterOptions: {
              logic: (user, filters) => {
                if (!filters || filters.length === 0) {
                  return false;
                }

                if (typeof user === 'object') {
                  return !filters.includes(getUserFullName(user));
                }

                return true;
              },
              display: renderSentByList,
            },
            customBodyRender: value => createdByCustomBodyRender(value),
            customBodyRenderCSV: value => createdByCustomBodyRender(value, true),
          },
        },
        {
          name: 'created_at',
          label: 'Sent At',
          options: {
            sortCompare: order => (row1, row2) => sortByDateAndTime(order, row2.data.value, row1.data.value),
            customFilterListOptions: {
              render: value => `Sent At: ${value}`,
            },
            customBodyRender: value => createdAtCustomBodyRender(value),
            customBodyRenderCSV: value => createdAtCustomBodyRender(value),
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            display: true,
            customBodyRenderLite: dataIndex => {
              const email = emails[dataIndex];

              return (
                <TableActionsButtonsWrapper>
                  <TableActionButton
                    title="Show Sent Email Details"
                    buttonProps={{
                      component: Link,
                      to: generatePath(routes.emailDetails, { emailId: email.id }),
                    }}
                  />
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        sortOrder: {
          name: 'id',
          direction: 'desc',
        },
        rowsPerPage: 15,
        selectableRows: 'none',
        responsive: 'simple',
        downloadOptions: {
          filename: formatCsvFileName('Sent Emails'),
        },
      }}
    />
  );
};

export default EmailsTable;
