import React from 'react';
import MaterialTable from 'material-table';
import {
  SyncAlt,
  Edit,
  Search,
  ArrowDropDown,
  Clear,
  ViewWeek,
  CloudDownload,
  FirstPage,
  LastPage,
  NavigateNext,
  NavigateBefore,
} from '@material-ui/icons';
import { generatePath, useHistory } from 'react-router-dom';
import routes from '../routes';
import { getUserFullName } from '../helpers/user';
import { isUndefinedOrNull } from '../helpers';
import moment from 'moment';
import * as usersModel from '../models/users';
import { Colors } from '../assets/theme/Colors';
const UsersTableNew = props => {
  const history = useHistory();

  const { data = [], onRowClick, ...rest } = props;

  return (
    <MaterialTable
      icons={{
        SortArrow: ArrowDropDown,
        Search: Search,
        ResetSearch: Clear,
        ViewColumn: ViewWeek,
        Export: CloudDownload,
        FirstPage: FirstPage,
        PreviousPage: NavigateBefore,
        NextPage: NavigateNext,
        LastPage: LastPage,
      }}
      title="Users"
      data={data}
      actions={[
        {
          icon: () => <Edit color="primary" />,
          tooltip: 'Edit User',
          onClick: (event, rowData) => history.push(generatePath(routes.userEdit, { id: rowData.id })),
        },
        rowData => ({
          icon: () => <SyncAlt color="primary" />,
          tooltip: 'Impersonate User',
          onClick: (event, rowData) => onRowClick(rowData.id),
        }),
      ]}
      options={{
        pageSize: 15,
        pageSizeOptions: [10, 15, 100],
        actionsColumnIndex: -1,
        columnsButton: true,
        exportButton: true,
        exportAllData: true, // export all pages
      }}
      columns={[
        {
          field: 'id',
          title: 'Id',
          hidden: true,
        },
        { field: 'firstname', title: 'First name', hidden: true },
        { field: 'lastname', title: 'Last name', hidden: true },
        {
          field: 'fullname',
          title: 'Full name',
          render: getUserFullName,
          customFilterAndSearch: (term, rowData) => {
            return (
              getUserFullName(rowData)
                .toLowerCase()
                .includes(term.toLowerCase()) ||
              `${rowData.lastname || ''} ${rowData.firstname}`
                .trim()
                .toLowerCase()
                .includes(term.toLowerCase())
            );
          },
        },
        { field: 'email', title: 'E-mail' },
        {
          field: 'email_verified',
          title: 'Email verified',
          hidden: true,
          render: rowData => {
            return !isUndefinedOrNull(rowData.email_verified)
              ? moment(rowData.email_verified).format('MM/DD/YYYY')
              : 'Not Verified';
          },
        },
        { field: 'phone', title: 'Phone', width: 110 },
        {
          field: 'phone_verified',
          title: 'Phone verified',
          hidden: true,
          render: rowData => {
            return !isUndefinedOrNull(rowData.phone_verified)
              ? moment(rowData.phone_verified).format('MM/DD/YYYY')
              : 'Not Verified';
          },
        },
        {
          field: 'active',
          title: 'Active',
          render: ({ active }) => active ? 'Yes' : 'No',
        },
        {
          field: 'role_assignments',
          title: 'Roles',
          render: rowData => {
            const userRoles = usersModel.getRolesFromRoleAssignments(rowData.role_assignments);

            return userRoles !== undefined && userRoles.length ? userRoles.join(', ') : '-';
          },
        },
        {
          field: 'completed_registration',
          title: 'Completed Registration',
          width: 195,
          render: rowData => {
            const value = rowData.completed_registration;
            if (value !== null) {
              return moment(value).format('MM/DD/YYYY');
            }

            return null;
          },
        },
      ]}
      {...rest}
    />
  );
};

export default UsersTableNew;
