const appBarSteps = [
  {
    id: 'hamburger-menu',
    attachTo: {
      element: '[data-introid="hamburger-menu"]',
      on: 'bottom',
    },
    text: 'This is the hamburger menu, it opens the side drawer menu.',
    title: 'Hamburger Menu',
  },
  {
    id: 'page-title',
    attachTo: {
      element: '[data-introid="page-title"]',
      on: 'bottom',
    },
    text: 'You can navigate to the Dashboard by clicking the site title.',
    title: 'Site Title',
  },
  {
    id: 'notification-bell',
    attachTo: {
      element: '[data-introid="notification-bell"]',
      on: 'bottom',
    },
    text: 'Here you can check all of your notifications.',
    title: 'Notifications',
  },
  {
    id: 'profile-menu-toggle',
    attachTo: {
      element: '[data-introid="profile-menu-toggle"]',
      on: 'bottom',
    },
    text: "Shows the role(s) you have in the software and it's a drop-down menu.",
    title: 'Roles/Menu',
  },
];

export const welcomePageTour = {
  steps: [
    {
      element: '[data-introid="main-drawer"]',
      intro: 'This is main drawer used to access all of the tools and features.',
      title: 'Main Drawer',
      position: 'right',
    },
    ...appBarSteps,
    {
      element: '[data-introid="fab-button"]',
      intro: 'Click on this button to add new content',
      position: 'right',
    },
  ],
};

export const siteDirectorTour = {
  steps: [
    ...appBarSteps,
    // {
    //   id: 'main-drawer',
    //   attachTo: {
    //     element: '[data-introid="main-drawer"]',
    //     on: 'auto',
    //   },
    //   text: 'This is main drawer used to access all of the tools and features.',
    //   title: 'Main Drawer',
    // },
    {
      id: 'drawer-food-donors',
      attachTo: {
        element: '[data-introid="drawer-food-donors"]',
        on: 'left',
      },
      text: 'Browse, add, or modify food donors, including their Donations and Pickup Locations.',
      title: 'Food Donors',
    },
    {
      id: 'drawer-receivers',
      attachTo: {
        element: '[data-introid="drawer-receivers"]',
        on: 'left',
      },
      text: 'Browse, add, or modify Receiving Agencies.',
      title: 'Receiving Agencies',
    },
    {
      id: 'drawer-rescuers',
      attachTo: {
        element: '[data-introid="drawer-rescuers"]',
        on: 'left',
      },
      text: 'Browse, add, or modifiy Rescuers.',
      title: 'Rescuers',
    },
    {
      id: 'drawer-donations',
      attachTo: {
        element: '[data-introid="drawer-donations"]',
        on: 'left',
      },
      text: 'Browse, add, and modify Donations.',
      title: 'Donations',
      position: 'right',
    },
    {
      id: 'drawer-rescues',
      attachTo: {
        element: '[data-introid="drawer-rescues"]',
        on: 'left',
      },
      text: 'Browse and modify Upcoming Rescues i.e. the schedule.',
      title: 'Rescues',
      position: 'right',
    },
    {
      id: 'drawer-schedule',
      attachTo: {
        element: '[data-introid="drawer-schedule"]',
        on: 'left',
      },
      text: 'Shows the upcoming rescues calendar.',
      title: 'Schedule',
      position: 'right',
    },
    {
      id: 'drawer-users',
      attachTo: {
        element: '[data-introid="drawer-users"]',
        on: 'left',
      },
      text:
        'Browse, add, or modify users for the current site (including rescuers, donor staff, and receiving agency staff). As well as send SMS and Email messages.',
      title: 'Site Users',
      position: 'right',
    },
    {
      id: 'map',
      attachTo: {
        element: '[data-introid="map"]',
        on: 'left',
      },
      text:
        "View the locations of Donors, Recievieing Agencies, and Rescuers, including a site's area in a map format.",
      title: 'Map',
      position: 'right',
    },
    {
      id: 'rescue-gallery',
      attachTo: {
        element: '[data-introid="rescue-gallery"]',
        on: 'left',
      },
      text: 'View rescue photos uploaded by Rescuers.',
      title: 'Rescue Image Gallery',
      position: 'right',
    },
    {
      id: 'drawer-reports',
      attachTo: {
        element: '[data-introid="drawer-reports"]',
        on: 'left',
      },
      text: 'Browse specific data reports.',
      title: 'Reports',
      position: 'right',
    },
    {
      id: 'drawer-unassigned-useres',
      attachTo: {
        element: '[data-introid="drawer-unassigned-useres"]',
        on: 'left',
      },
      text: 'Browse users without any role in any site.',
      title: 'Unassigned Users',
      position: 'right',
    },
    {
      id: 'drawer-in-process-registrations',
      attachTo: {
        element: '[data-introid="drawer-in-process-registrations"]',
        on: 'left',
      },
      text: 'Browse users who are in the process of fully registering.',
      title: 'In-process Registrations',
      position: 'right',
    },
    {
      id: 'drawer-donor-organizations',
      attachTo: {
        element: '[data-introid="drawer-donor-organizations"]',
        on: 'left',
      },
      text: 'Browse, add or modify Food Donor parent companies/organizations.',
      title: 'Donor Organizations',
      position: 'right',
    },
    {
      id: 'drawer-sites-menu',
      attachTo: {
        element: '[data-introid="drawer-sites-menu"]',
        on: 'left',
      },
      text: 'Browse, add, or modify Sites. Change the current Site.',
      title: 'Sites Menu',
      position: 'right',
    },
    {
      id: 'drawer-past-rescues',
      attachTo: {
        element: '[data-introid="drawer-past-rescues"]',
        on: 'left',
      },
      text: 'Browse the list of past rescues e.g. see rescuer comments.',
      title: 'Past Rescues',
      position: 'right',
    },
    {
      id: 'drawer-sent-emails',
      attachTo: {
        element: '[data-introid="drawer-sent-emails"]',
        on: 'left',
      },
      text: 'Browse emails that site coordinators or site directors have sent to users.',
      title: 'Sent Emails',
      position: 'right',
    },
    {
      id: 'drawer-events',
      attachTo: {
        element: '[data-introid="drawer-events"]',
        on: 'left',
      },
      text: 'Create, view, and manage Events.',
      title: 'Events',
      position: 'right',
    },
    {
      id: 'fab-button',
      attachTo: {
        element: '[data-introid="fab-button"]',
        on: 'left',
      },
      text: 'Click on this button to add new content.',
      position: 'right',
    },
  ],
};

export const scheduleTour = [
  {
    element: '[data-introid="schedule-rescues-container"]',
    intro: 'By default, we are showing all rescues. You can change it by clicking the "Filters" button.',
    title: 'Rescues List',
  },
  {
    element: '[data-introid="schedule-filters"]',
    intro: 'Allows you to define custom filters for the rescues list.',
    title: 'Filters Button',
  },
];

export const infiniteScheduleTour = [
  {
    element: '[data-introid="schedule-rescues-container"]',
    intro: 'By default, we are showing all rescues. You can change it by clicking the "Filters" button.',
    title: 'Rescues List',
  },
  {
    element: '[data-introid="schedule-filters"]',
    intro: 'The filters button has changed location, click here if you wish to add any filters.',
    title: 'Filters Button',
  },
];

export const closeRescueTour = {
  steps: [
    {
      id: 'no-food',
      attachTo: {
        element: '[data-introid="no-food"]',
        on: 'top',
      },
      text: 'This is how you let your site director know that there was no food available to be rescued.',
      title: 'No food available',
    },
    {
      id: 'categories',
      attachTo: {
        element: '[data-introid="categories"]',
        on: 'top',
      },
      text: 'Select any category that applies to the contents of your rescue.',
      title: 'Categories',
    },
    {
      id: 'close-rescue-next',
      attachTo: {
        element: '[data-introid="close-rescue-next"]',
        on: 'top',
      },
      text: 'Select ‘Next’ to continue to the next step',
      title: 'Next',
      rescueCloseStep: 0,
      nextStep: true,
    },
    {
      id: 'weight-lbs',
      attachTo: {
        element: '[data-introid="weight-lbs"]',
        on: 'bottom',
      },
      text: 'Editing this field will adjust the total weight for your rescue.',
      title: 'Total weight',
      rescueCloseStep: 1,
      prevStep: true,
    },
    {
      id: 'item-lbs',
      attachTo: {
        element: '[data-introid="item-lbs"]',
        on: 'bottom',
      },
      text: 'Editing these fields will affect the total weight of your rescue, in addition to the distribution of weight amongst the categories.',
      title: 'Item weight',
    },
    {
      id: 'item-slider',
      attachTo: {
        element: '[data-introid="item-slider"]',
        on: 'bottom',
      },
      text: 'Adjusting these sliders will adjust the percentage of your total rescue weight that is allocated to each category. It’s ok to estimate!',
      title: 'Sliders',
    },
    {
      id: 'upload-photo',
      attachTo: {
        element: '[data-introid="upload-photo"]',
        on: 'top',
      },
      text: 'Use this button to upload a picture of your rescue. This step is optional but encouraged.',
      title: 'Photo',
    },
    {
      id: 'rescue-comments',
      attachTo: {
        element: '[data-introid="rescue-comments"]',
        on: 'top',
      },
      text: 'This comment section is where you can provide any information you think would be helpful to your site director!',
      title: 'Comments',
    },
  ],
};
