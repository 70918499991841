import { apiPOST, apiDELETE, apiPATCH } from './api';

export const createDonation = donation => {
  return apiPOST('/donations', donation);
};

export const diffDonation = payload => apiPOST(`/donations/diff`, payload);

export const deleteDonation = donationId => apiDELETE(`/donations/${donationId}`);

export const updateDonations = payload => apiPATCH('/donations', payload);
