import { SET_USER_TABLE_OPTIONS, CLEAR_TABLE_OPTIONS } from '../actions/tableOptions';

const uiInitialState = {};

const tableOptionsReducer = (state = uiInitialState, action) => {
  switch (action.type) {
    case CLEAR_TABLE_OPTIONS:
      return {};
    case SET_USER_TABLE_OPTIONS:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          [action.payload.tableId]: action.payload.data,
        }
      };
    default:
      return state;
  }
};

export default tableOptionsReducer;
