import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch } from 'react-redux';
import * as authService from '../../services/auth';
import ViewNotSupportedCard from '../../components/ViewNotSupportedCard/ViewNotSupportedCard';
import { Roles } from '../../models/roles';
import ReceiverDashboard from '../../containers/ReceiverDashboard';
import { setDefaultDashboard } from '../../actions/uiPersisted';
import routes from '../../routes';

const NotImplementedDashboard = params => {
  const { renderParams } = params;
  return (
    <>
      <h3>{`${renderParams.role} Dashboard`}</h3>

      <p>This is a placeholder for now. In the future, you'll see content useful for Receiver staff.</p>
    </>
  );
};

const ReceiverDashboardDesktop = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    // set as default dashboard
    dispatch(setDefaultDashboard({ route: routes.dashboardReceiver, params }));
  }, []);

  if (isMobileView) {
    return <ViewNotSupportedCard />;
  }

  const currentlyLoggedInUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const userRoles = authService.getUserRolesInCurrentlySelectedSite(currentlyLoggedInUser);
  let rolesWithAccess;

  rolesWithAccess = userRoles.filter(
    role => role.role_name === Roles.ReceiverLocationAdmin || role.role_name === Roles.ReceiverLocationStaff
  );

  if (rolesWithAccess.length > 0) {
    return <ReceiverDashboard renderParams={{ id: rolesWithAccess[0].receiver_id }} />;
  }

  rolesWithAccess = userRoles.filter(
    role => role.role_name === Roles.ReceiverAdmin || role.role_name === Roles.ReceiverStaff
  );

  if (rolesWithAccess.length > 0) {
    return <NotImplementedDashboard renderParams={{ role: rolesWithAccess[0].role_name }} />;
  }

  // fallback when user does not have any Receiver role.
  return (
    <Box my={2}>
      <Card>
        <CardContent>
          <Typography color="error">No data to present. Probably you are not a DonorLocationAdmin?</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReceiverDashboardDesktop;
