import moment from 'moment';
import errorMessages from '../../../assets/errorMessages';

export const RESCUE_SIZE_BICYCLE_NAME = 'Bicycle';

export const validateDonation = (donationData, validationContext = {}) => {
  const errors = {};

  // const hasCustomEstimatedLbsSelected = document.querySelector('input[name="lbs_estimation"][value="provided"]')
  //   .checked;

  // if (hasCustomEstimatedLbsSelected) {
  //   const hasEmptyCustomEstimatedProvided = document.querySelector('input[name="lbs"]').value === '';
  //   if (hasEmptyCustomEstimatedProvided) {
  //     errors.lbs = errorMessages.REQUIRED.message;
  //   }
  // }

  // Require lbs when the Bicycle rescue size is selected
  if (donationData.rescue_size.name === RESCUE_SIZE_BICYCLE_NAME && !donationData.lbs) {
    errors.lbs = errorMessages.REQUIRED.message;
  }

  if (validationContext.validateReceivingAgency) {
    if (!donationData.pickup_specs.every(pickup => pickup.receiver && pickup.receiver.id)) {
      errors.receiver = errorMessages.REQUIRED.message;
    }
  }

  if (validationContext.validate24HoursAheadOfTimeWindow) {
    const next24h = moment().add(24, 'hours');
    const pickupBegins = donationData.pickup_specs.map(ps =>
      moment(
        `${donationData.start_date.value} ${ps.pickup_begin.value}`,
        `${donationData.start_date.format} ${ps.pickup_begin.format}`
      )
    );
    const donationRequestDateTime = moment.min(pickupBegins);

    if (donationRequestDateTime.isBefore(next24h)) {
      errors.start_date = errorMessages.INVALID.message;
    }
  }

  const pickupBeginIsBeforeEnd = donationData.pickup_specs.find((specs) =>
    moment(specs.pickup_begin.value, 'HH:mm:ss').isAfter(moment(specs.pickup_end.value, 'HH:mm:ss'))
  );

  if (pickupBeginIsBeforeEnd) {
    errors.pickups = errorMessages.PICKUP_START_TIME_BEFORE_END_TIME.message;
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors: errors,
  };
};
