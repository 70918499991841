import React, { useEffect, useContext, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { Box, Typography, Grid, InputLabel, Tooltip, FormHelperText, makeStyles } from '@material-ui/core';
import { Help as HelpIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import MinMaxTimePicker from '../../../../../../components/MinMaxTimePicker';
import { useSelector } from 'react-redux';
import DonationContext from '../../../../../../context/DonationContext/DonationContext';
import { donationManageActions, groupMonthlyPickups, groupWeeklyPickups } from '../../../../../../helpers/donations';
import RescuersNumber from '../../RescuersNumber';
import ReceiverSelector from '../../../../../../components/ReceiverSelector';
import ReceiverChip from '../../../../../../components/ReceiverChip';
import { FREQUENCY_ONCE, FREQUENCY_MONTHLY } from '../../../../../../models/donationsNew';
import TextAreaField from '../../../../../../components/Common/TextAreaField';
import { getReceiversSelectOptions, getRescuersSelectOptions } from '../../../../../../helpers/getters';
import AdopterSelector from '../../../../../../components/AdopterSelector';
import useActiveSite from '../../../../../../hooks/useActiveSite';
import moment, { weekdays } from 'moment';
import useActiveUser from '../../../../../../hooks/useActiveUser';
import { Roles } from '../../../../../../models/roles';
import { hasAnyRoleInCurrentlySelectedSite } from '../../../../../../services/auth';
import PickupSpecCardMobile from '../Mobile/PickupSpecCardMobile';
import RescuerNumberSelectorMobile from '../Mobile/RescuerNumberSelectorMobile';
import { sortAlphabetically } from '../../../../../../helpers/sorters';
import { Colors } from '../../../../../../assets/theme/Colors';
import {
  DONATION_TYPE_REQUEST,
  DONATION_TYPE_REQUEST_APPROVAL,
  DONATION_TYPE_SD,
} from '../../../../../../actions/donationNew';
import routes from '../../../../../../routes';
import { TAB_PROFILE } from '../../../../../../containers/ReceiverDashboard';

export const RECEIVING_HOURS_HASH = '#receiving-hours';

const useStyles = makeStyles((theme) => ({
  warningText: {
    color: Colors.errorText,
    marginTop: theme.spacing(1),
  },
  infoText: {
    color: Colors.gray,
    marginTop: theme.spacing(1),
  },
  warningLink: {
    color: Colors.errorText,
    display: 'inline-flex',
  },
  infoLink: {
    color: Colors.gray,
    display: 'inline-flex',
  },
  newTabIcon: {
    fontSize: '12px',
  },
}));

const AllDaysTheSame = () => {
  const classes = useStyles();
  const activeSite = useActiveSite();
  const activeUser = useActiveUser();
  const {
    frequency,
    pickup_specs: pickups,
    rescue_size: rescueSize,
    pickup_specs: pickupSpecs,
    start_date: startDate,
  } = useSelector((state) => state.ui.donation_new.draft);
  const rescues = useSelector(state => state.ui.donation_new.rescues);
  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const rescueSizesArray = rescueSizes.sorted;
  const receiversEntities = useSelector(state => state.entities.sites.receivers);
  const receiversList = getReceiversSelectOptions(Object.values(receiversEntities.byId)).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );
  const locationsEntities = useSelector(state => state.entities.receivers.locations);
  const rescuersEntities = useSelector(state => state.entities.sites.rescuers.bySiteId[activeSite.id]);
  const rescuersList = getRescuersSelectOptions(rescuersEntities || []).sort((a, b) =>
    sortAlphabetically('asc', b.label, a.label)
  );
  const { onFieldChange, isLoading, isMobileView, donationType, errors, removeError, hasFoodDonorDefined } = useContext(DonationContext);
  const days = frequency === FREQUENCY_MONTHLY ? groupMonthlyPickups(pickups || []) : groupWeeklyPickups(pickups || []);
  const positionsCount = days[0][1].length;
  const firstPickupSpec = pickups[0];
  const firstRescue = rescues[0];

  const [rescuerNotesValue, setRescuerNotesValue] = useState(firstPickupSpec.rescuer_notes || '');
  const [pickupBeginTimeValue, setPickupBeginTimeValue] = useState(firstPickupSpec.pickup_begin.value);
  const [pickupEndTimeValue, setPickupEndTimeValue] = useState(firstPickupSpec.pickup_end.value);
  const [pickupEndTimeValid, setPickupEndTimeValid] = useState(false);

  const receiverId = pickupSpecs[0]?.receiver?.id;
  const selectedReceiver = locationsEntities?.byReceiverId[receiverId];
  const receiverLocation = selectedReceiver?.length > 0 ? selectedReceiver[0] : null;
  const weeklyHours = receiverLocation?.weekly_receiving_hours || [];

  const hasHoursForSelectedDays = pickupSpecs.every((spec) =>
    weeklyHours.find((time) =>
      spec.day !== null ? spec.day === time.day_of_week : time.day_of_week === moment(startDate.value).day()
    )
  );

  const isInReceivingHours = pickupSpecs.every((spec) => {
    const receivingHours = weeklyHours.filter((time) =>
      spec.day === null
        ? time.day_of_week === moment(startDate.value).day()
        : spec.day === time.day_of_week
    );

    if (receivingHours?.length) {
      return receivingHours.find(
        (data) =>
          moment(spec.pickup_begin?.value, 'HH:mm:ss').isBetween(
            moment(data.start, 'HH:mm:ss'),
            moment(data.end, 'HH:mm:ss'),
            undefined,
            '[]'
          ) &&
          moment(spec.pickup_end?.value, 'HH:mm:ss').isBetween(
            moment(data.start, 'HH:mm:ss'),
            moment(data.end, 'HH:mm:ss'),
            undefined,
            '[]'
          )
      );
    }

    return false;
  });

  // update state value on redux data change
  useEffect(() => setRescuerNotesValue(firstPickupSpec.rescuer_notes || ''), [firstPickupSpec.rescuer_notes]);
  useEffect(() => setPickupBeginTimeValue(firstPickupSpec.pickup_begin.value), [firstPickupSpec.pickup_begin.value]);
  useEffect(() => {
    setPickupEndTimeValid(false);
    setPickupEndTimeValue(firstPickupSpec.pickup_end.value);
  }, [firstPickupSpec.pickup_end.value]);

  const isNotSCOrGreaterRoleUser = !hasAnyRoleInCurrentlySelectedSite(activeUser, [
    Roles.NationalSiteDirector,
    Roles.Admin,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const handleNotesChange = event => setRescuerNotesValue(event.target.value);
  const handleBeginChange = ({ event }) => setPickupBeginTimeValue(event.format('HH:mm:00'));

  const updatePickupBeginTime = () => {
    onFieldChange(donationManageActions.set_pickup_begin, {
      begin: pickupBeginTimeValue,
    });
  };

  const updatePickupEndTime = () => {
    if (pickupEndTimeValid) {
      onFieldChange(donationManageActions.set_pickup_end, {
        end: pickupEndTimeValue,
      });
    }
  };

  const handleEndChange = ({ event, isValid }) => {
    if (isValid) {
      setPickupEndTimeValue(event.format('HH:mm:00'));
    }
    setPickupEndTimeValid(isValid);
  };

  const handleNotesBlur = value =>
    onFieldChange(donationManageActions.set_pickup_rescuer_notes, {
      rescuer_notes: value,
    });

  const handleReceiverChange = ({ value }) =>
    onFieldChange(donationManageActions.set_pickup_receiver, {
      receiver_id: value ? value.receiver_id : null,
    });

  const handleAdopterChange = value =>
    onFieldChange(donationManageActions.set_pickup_adopter, {
      adopter_id: value ? value.id : null,
    });

  const handleRescuerChange = value =>
    onFieldChange(donationManageActions.set_pickup_rescuer, {
      rescuer_id: value ? value.id : null,
    });

  const handleRescuersNumberChange = () => onFieldChange(donationManageActions.add_pickup);

  if (!Array.isArray(pickups) || pickups.length === 0) {
    return null;
  }

  if (isMobileView) {
    return (
      <>
        {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
          <RescuerNumberSelectorMobile
            value={positionsCount}
            frequency={frequency}
            pickup={firstPickupSpec}
            onRescuersNumberChange={handleRescuersNumberChange}
          />
        )}

        <PickupSpecCardMobile
          pickup={firstPickupSpec}
          isLoading={isLoading}
          frequency={frequency}
          donationType={donationType}
          isNotSCOrGreaterRoleUser={isNotSCOrGreaterRoleUser}
          receiversList={receiversList}
          rescuersList={rescuersList}
          rescue={firstRescue}
          onPickupBeginUpdate={(pickup, value) =>
            onFieldChange(donationManageActions.set_pickup_begin, {
              begin: value,
            })
          }
          onPickupEndUpdate={(pickup, value) =>
            onFieldChange(donationManageActions.set_pickup_end, {
              end: value,
            })
          }
          onPickupNotesChange={(pickup, value) => handleNotesBlur(value)}
          onReceiverChange={(pickup, value) => handleReceiverChange({ value })}
          onAdopterChange={(pickup, value) => handleAdopterChange(value)}
          onRescuerChange={(pickup, value) => handleRescuerChange(value)}
          errors={errors}
        />
      </>
    );
  }

  return (
    <Grid container spacing={3}>
      {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
        <Grid item container xs={12}>
          <Grid container item md={12} lg={8} xl={6} spacing={2}>
            <Grid item xs={12} lg={4}>
              <RescuersNumber showLabel value={positionsCount} handleClick={handleRescuersNumberChange} />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputLabel component="label" shrink error={!!errors.receiver}>
                {donationType === DONATION_TYPE_REQUEST_APPROVAL ? 'Receiving Agency (required)' : 'Receiving Agency'}
              </InputLabel>
              {firstPickupSpec.receiver == null ? (
                <>
                  <ReceiverSelector
                    name={`receiver.${firstPickupSpec.day}_${firstPickupSpec.position}_${firstPickupSpec.ordinal || 0}`}
                    isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                    inputId={`receiver_${firstPickupSpec.day}_${firstPickupSpec.position}_${firstPickupSpec.ordinal ||
                      0}`}
                    instanceId={`receiver_${firstPickupSpec.day}_${
                      firstPickupSpec.position
                    }_${firstPickupSpec.ordinal || 0}`}
                    receivers={receiversList}
                    onChange={handleReceiverChange}
                  />
                  {errors.receiver && (
                    <FormHelperText data-testid="days-of-month-error" id="days_of_month_error" error>
                      {errors.receiver}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <ReceiverChip
                  isDisabled={isNotSCOrGreaterRoleUser}
                  value={firstPickupSpec.receiver.name}
                  onClick={handleReceiverChange}
                />
              )}
            </Grid>

            {frequency === FREQUENCY_ONCE ? (
              <Grid item xs={12} lg={4}>
                <InputLabel component="label" shrink>
                  Rescuer
                </InputLabel>
                {firstRescue && !firstRescue.rescuer ? (
                  <AdopterSelector
                    placeholder="Rescuer"
                    noOptionsText="There are no active rescuers in the Site"
                    isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                    rescuersList={rescuersList}
                    onChange={({ value }) => handleRescuerChange(value)}
                  />
                ) : (
                  <ReceiverChip
                    isDisabled={isNotSCOrGreaterRoleUser}
                    value={firstRescue && firstRescue.rescuer ? firstRescue.rescuer.name : null}
                    onClick={() => handleRescuerChange(null)}
                  />
                )}
              </Grid>
            ) : (
              <Grid item xs={12} lg={4}>
                <InputLabel component="label" shrink>
                  Adopter
                </InputLabel>
                {firstPickupSpec.adopter === null ? (
                  <AdopterSelector
                    isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                    rescuersList={rescuersList}
                    onChange={({ value }) => handleAdopterChange(value)}
                  />
                ) : (
                  <ReceiverChip
                    isDisabled={isNotSCOrGreaterRoleUser}
                    value={firstPickupSpec.adopter.name}
                    onClick={() => handleAdopterChange(null)}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container item xs={12} direction="column" wrap="nowrap">
        <Grid item xs>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Pickup window
          </Typography>
        </Grid>
        <Grid container item md={12} lg={8} xl={6} spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="no-wrap"
              alignItems="center"
              gridGap="8px"
              justifyContent="space-between"
            >
              <MinMaxTimePicker
                fullWidth
                disabled={isLoading}
                inputVariant="outlined"
                size={isMobileView ? 'medium' : 'small'}
                label="From"
                variant={isMobileView ? 'dialog' : 'inline'}
                value={moment(pickupBeginTimeValue, 'HH:mm:ss')}
                maxTime={moment(pickupEndTimeValue, 'HH:mm:ss')}
                onTimeChange={handleBeginChange}
                onClose={updatePickupBeginTime}
                data-testid="pickup_begin"
                hasHoursForSelectedDay={hasHoursForSelectedDays}
                weeklyHours={weeklyHours}
                pickupSpecs={pickupSpecs}
                startDate={startDate}
                showWarning={!!errors.pickups}
              />
              <Tooltip arrow placement="top" title="This is the earliest time a rescuer could arrive.">
                <HelpIcon htmlColor={Colors.blue.main} />
              </Tooltip>
            </Box>
            {errors.pickups && (
              <FormHelperText data-testid="pickup_end-error" id="pickup_end" error>
                {errors.pickups}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="no-wrap"
              alignItems="center"
              gridGap="8px"
              justifyContent="space-between"
            >
              <MinMaxTimePicker
                fullWidth
                disabled={isLoading}
                showFieldErrors
                label="To"
                inputVariant="outlined"
                size={isMobileView ? 'medium' : 'small'}
                variant={isMobileView ? 'dialog' : 'inline'}
                value={moment(pickupEndTimeValue, 'HH:mm:ss')}
                minTime={moment(pickupBeginTimeValue, 'HH:mm:ss')}
                onTimeChange={handleEndChange}
                onClose={updatePickupEndTime}
                data-testid="pickup_end"
                hasHoursForSelectedDay={hasHoursForSelectedDays}
                weeklyHours={weeklyHours}
                pickupSpecs={pickupSpecs}
                startDate={startDate}
                showWarning={!!errors.pickups}
              />
              <Tooltip arrow placement="top" title="This is the latest time a rescuer could arrive.">
                <HelpIcon htmlColor={Colors.blue.main} />
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        {!isInReceivingHours && hasHoursForSelectedDays && (
          <Grid item md={12} lg={8} xl={6}>
            <Typography variant="subtitle2" className={classes.warningText}>
              Pickup window is outside of the receiving hours for {receiversEntities.byId[receiverId].name}. If this is incorrect, update the
              receiving hours on the{' '}
              <Link
                className={classes.warningLink}
                target="_blank"
                to={{
                  pathname: generatePath(routes.receiver, { id: receiverId, tab: TAB_PROFILE }),
                  hash: RECEIVING_HOURS_HASH,
                }}
              >
                Profile <OpenInNewIcon className={classes.newTabIcon} />
              </Link>{' '}
              the receiving agency page.
            </Typography>
          </Grid>
        )}
        {selectedReceiver && !hasHoursForSelectedDays && (
          <Grid item md={12} lg={8} xl={6}>
            <Typography variant="subtitle2" className={classes.infoText}>
              Pickup window might be outside of the receiving hours for {receiversEntities.byId[receiverId].name}. Update the receiving hours on the{' '}
              <Link
                className={classes.infoLink}
                state={{ scroll: true }}
                target="_blank"
                to={{
                  pathname: generatePath(routes.receiver, { id: receiverId, tab: TAB_PROFILE }),
                  hash: RECEIVING_HOURS_HASH,
                }}
              >
                Profile <OpenInNewIcon className={classes.newTabIcon} />
              </Link>{' '}
              the receiving agency page.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container item xs={12}>
        <Grid container item md={12} lg={8} xl={6} spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="no-wrap"
              alignItems="center"
              gridGap="8px"
              justifyContent="space-between"
            >
              <TextAreaField
                fullWidth
                rowsMax={5}
                onChange={handleNotesChange}
                onBlur={event => handleNotesBlur(event.target.value)}
                value={rescuerNotesValue}
                label="Rescuer notes"
                data-testid="rescuer_notes"
              />
              <Tooltip
                arrow
                placement="top"
                title=" Additional info for the rescuer - examples: directions on where to pick up (kitchen doors, walk in store, etc), day-of contact person name and number, how the food will be packaged (boxes, bags, to go containers, catering trays, etc)."
              >
                <HelpIcon htmlColor={Colors.blue.main} />
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AllDaysTheSame;
