import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Shepherd from 'shepherd.js';
import '../assets/scss/shepherd.css';
import { disableTourStepper, setRescueCloseStep } from '../actions/ui';

const TourStepper = () => {
  const dispatch = useDispatch();
  const tourStepper = useSelector(state => state.ui.tourStepper);
  const disableStepper = () => {
    if (isRescueCloseTour) {
      dispatch(setRescueCloseStep(0));
    }
    dispatch(disableTourStepper());
  };
  let { steps } = tourStepper;

  if (!tourStepper.enabled) {
    return null;
  }

  const isRescueCloseTour = steps.find((step) => step.rescueCloseStep);

  if (!isRescueCloseTour) {
    steps = steps.filter(obj => document.querySelector(obj.attachTo.element));
  }

  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      scrollTo: { behavior: 'smooth', block: 'center' },
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      modalOverlayOpeningRadius: 10,
    },
    keyboardNavigation: true,
    useModalOverlay: true,
  });

  tour.addSteps(
    steps.map((step, index, { length, lastIndex = length - 1 }) => {
      const buttons = [];

      if (index > 0) {
        buttons.push({
          text: 'Prev',
          action: () => {
            if (isRescueCloseTour && step.prevStep) {
              dispatch(setRescueCloseStep(step.rescueCloseStep - 1));
              return setTimeout(() => {
                tour.back();
              }, 100); // Wait for the components to render
            }
            return tour.back();
          },
        });
      }

      if (index + 1 !== steps.length) {
        buttons.push({
          text: 'Next',
          action: () => {
            if (isRescueCloseTour && step.nextStep) {
              dispatch(setRescueCloseStep(step.rescueCloseStep + 1));
              return setTimeout(() => tour.next(), 100); // Wait for the components to render
            }
            return tour.next();
          },
        });
      }

      if (index + 1 === steps.length) {
        buttons.push({
          text: 'Done',
          action: () => {
            tour.cancel();
          },
        });
      }

      return {
        ...step,
        buttons: buttons,
      };
    })
  );

  // disable tour on close/cancel
  tour.on('cancel', disableStepper);

  tour.start();

  return null;
};

export default TourStepper;
