import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { AppBar } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import { scDashboardPaths } from './SCDashboardDesktopContainer';
import { FF_SITE_GOALS, FF_SD_DASHBOARD_ANALYTICS } from '../../../components/FeatureFlagWrapper';
import useHasActiveFeatureFlag from '../../../hooks/useFeatureFlags';

const useStyles = makeStyles(() => ({
  badge: {
    position: 'absolute',
    right: 15,
    top: 5,
  },
  tabs: {
    width: '100%',
  },
}));

const SCDashboardAppBar = () => {
  const classes = useStyles();
  const badges = useSelector(state => state.misc.scDashboardMenuBadges);
  const history = useHistory();
  const location = useLocation();
  const hasActiveSiteGoalsFeatureFlag = useHasActiveFeatureFlag(FF_SITE_GOALS);
  const hasActiveSdDashboardAnalyticsFeatureFlag = useHasActiveFeatureFlag(FF_SD_DASHBOARD_ANALYTICS);

  const changeTabUrl = (event, index) => {
    history.push(scDashboardPaths[index]);
  };

  const getActiveTab = () => {
    const path = scDashboardPaths.indexOf(location.pathname);

    return path < 0 ? 0 : path;
  };

  const renderTab = (label, id, badgeCounter, testId) => (
    <Tab
      id={id}
      label={
        <div>
          {label}

          {!!badgeCounter && (
            <span className={`badge badge-pill badge-warning ${classes.badge}`}>
                {badgeCounter > 99 ? '99+' : badgeCounter}
              </span>
          )}
        </div>
      }
      data-testid={testId}
    />
  );

  return (
    <AppBar position="static">
      <Tabs value={getActiveTab()} onChange={changeTabUrl} aria-label="sc dashboard tabs" className={classes.tabs}>
        {renderTab('To do', 'scTab0', badges.todo, 'scDesktopTab0')}
        {renderTab('At a glance', 'scTab1', undefined, 'scDesktopTab1')}
        {renderTab('Leaderboards', 'scTab2', undefined, 'scDesktopTab2')}
        {renderTab('Insight', 'scTab3', undefined, 'scDesktopTab3')}
        {hasActiveSiteGoalsFeatureFlag && renderTab('Site Goals', 'scTab4', undefined, 'scDesktopTab4')}
        {renderTab('Environment Impact', 'scTab5', undefined, 'scDesktopTab5')}
        {hasActiveSdDashboardAnalyticsFeatureFlag && renderTab('Analytics', 'scTab6', undefined, 'scDesktopTab6')}
      </Tabs>
    </AppBar>
  );
};

export default SCDashboardAppBar;
