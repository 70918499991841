import React from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import TableSkeletonLoader from '../../../../../components/Common/TableSkeletonLoader';
import routes from '../../../../../routes';
import RescuerDashboardRecentRescuesTable from './RescuerDashboardRecentRescuesTable';

const RescuerDashboardRecentRescues = ({ recentRescues, isLoading = false, onCellClick }) => {
  const history = useHistory();

  const onCloseRescueButtonClick = (rescue) => history.push(generatePath(routes.rescueClose, { rescueId: rescue.id }));

  const onCloseNoShowRescueButtonClick = (rescue) =>
    history.push(generatePath(routes.rescueCloseNoShow, { rescueId: rescue.id }));

  if (recentRescues.length === 0 && !isLoading) {
    return (
      <Typography color="textSecondary" variant="body1">
        You have no past rescues.
      </Typography>
    );
  }

  if (isLoading) {
    return <TableSkeletonLoader cols={5} rows={3} />;
  }

  return (
    <RescuerDashboardRecentRescuesTable
      tableId="#rescuer-dashboard-desktop/recent-rescues"
      rescues={recentRescues}
      onCloseRescueButtonClick={onCloseRescueButtonClick}
      onCloseNoShowRescueButtonClick={onCloseNoShowRescueButtonClick}
      onCellClick={onCellClick}
    />
  );
};

export default RescuerDashboardRecentRescues;
