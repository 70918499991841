import React, { useEffect, useState } from 'react';
import { withStyles, Grid, ListItemText, ListItem, Box } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { sortDays } from '../../../helpers/RescuesHelper';
import moment from 'moment';
import FilterComponent from './FilterComponent';

const getRange = (startDate, endDate) => {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, 'days');
  let range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, 'days'));
  }
  return range;
};

const DayButton = withStyles(({ palette }) => ({
  day: {
    padding: 0,
    height: 55,
    minWidth: 50,
    '&.Mui-selected': {
      backgroundColor: `${palette.primary.main} !important`,
      color: '#fff',
    },
    '& .MuiGrid-container': {
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
}))(({ children, classes, ...rest }) => (
  <ToggleButton className={classes.day} {...rest}>
    {children}
  </ToggleButton>
));

export const getDaysLabel = selectedDays => {
  const daysSelected = selectedDays.length;
  if (daysSelected === 0 || daysSelected === 7) {
    return 'Days: all';
  }

  return `Days: ${selectedDays.join(', ')}`;
};

const DaysSelectorFilter = ({ value, handleDaysChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState(value);
  const [daysRange, setDaysRange] = useState([]);

  useEffect(() => {
    setDaysRange(getRange(moment(), moment().add(7, 'days')));
  }, [daysRange.length === 0]);

  useEffect(() => {
    handleDaysChange(selectedDays);
  }, [selectedDays]);

  return (
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)}>
        <ListItemText primary={getDaysLabel(selectedDays)} />
        {isOpen ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <FilterComponent isOpen={isOpen}>
        <Box
          py={2}
          px={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="row"
          flexGrow={1}
          flexBasis={0}
        >
          <ToggleButtonGroup
            size="medium"
            value={selectedDays}
            onChange={(event, newValue) => setSelectedDays(newValue.sort(sortDays))}
            aria-label="text alignment"
          >
            {daysRange.map((moment, index) => (
              <DayButton key={index} value={moment.format('ddd')}>
                <Grid container direction="column" justify="center" alignItems="center">
                  <Grid>{moment.format('dd')}</Grid>
                </Grid>
              </DayButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </FilterComponent>
    </>
  );
};

export default DaysSelectorFilter;
