import Bluebird from 'bluebird';
import moment from 'moment';
import { getAvailableRescues, getClaimedAndAdoptedRescues, getPastRescues } from '../api/rescuers';
import { getRecentRescues, getTodayRescues, getUpcomingRescues } from '../helpers/RescuesHelper';

export const UPDATE_RESCUER_COUNTER_BADGE = 'UPDATE_RESCUER_COUNTER_BADGE';
export const UPDATE_SC_DASHBOARD_COUNTER_BADGE = 'UPDATE_SC_DASHBOARD_COUNTER_BADGE';

export const updateRescuerCounterBadge = (name, value) => ({
  type: UPDATE_RESCUER_COUNTER_BADGE,
  payload: {
    name,
    value,
  },
});

export const updateSCToDoCounterBadge = (name, value) => ({
  type: UPDATE_SC_DASHBOARD_COUNTER_BADGE,
  payload: {
    name,
    value,
  },
});

export const refreshRescuerMenuBadgeCounters = rescuerId => dispatch => Bluebird
  .try(() => Promise.all([
    // get rescuer claimed and adopted rescues
    Bluebird
      .try(() => getClaimedAndAdoptedRescues(
        rescuerId,
        moment().format('YYYYMMDD'),
        null,
        {
          sort_by: ['date,asc', 'pickup_begin,asc'],
        }
      ))
      .then(res => res.json())
      .then(res => res.data),

    // get rescuer past rescues
    Bluebird
      .try(() => getPastRescues(rescuerId))
      .then(res => res.json())
      .then(res => res.data),

    // get rescuer available rescues
    Bluebird
      .try(() => getAvailableRescues(
        rescuerId,
        null,
        moment().format('YYYYMMDD'),
        moment().add(3, 'w').format('YYYYMMDD'),
        null,
        ['date,asc', 'pickup_begin,asc'],
      ))
      .then(res => res.json())
      .then(res => res.data),
  ]))
  .spread((claimedAndAdoptedRescues, pastRescues, availableRescues) => {
    const todayRescues = getTodayRescues(rescuerId, pastRescues, true);
    const recentRescues = getRecentRescues(rescuerId, pastRescues, true);
    const claimedAndAdoptedRescuesWithoutTodayList = getUpcomingRescues(
      rescuerId,
      // on "MY FRUS" tab we are showing claimed rescues for next 7 days only, so the # in the badge has to be equal
      claimedAndAdoptedRescues.filter(
        rescue => moment(rescue.date, 'YYYY-MM-DD').isSameOrBefore(moment().add(7, 'days').format('YYYY-MM-DD'))
      ),
      true
    );

    dispatch(
      updateRescuerCounterBadge(
        'myFrus',
        todayRescues.length + recentRescues.length + claimedAndAdoptedRescuesWithoutTodayList.length
      )
    );

    dispatch(
      updateRescuerCounterBadge(
        'myRescues',
        claimedAndAdoptedRescues.filter(rescue => rescue.closed_by_id === null).length // including today's rescues
      )
    );

    dispatch(updateRescuerCounterBadge('schedule', availableRescues.length));
  });
