export const mapFormToCreateSitePOST = site => {
  const siteData = { ...site };

  return {
    name: siteData.name,
    notes: siteData.notes || null,
    timezone: siteData.timezone || null,
    reply_to_email: siteData.reply_to_email || null,
    zip_codes: siteData.zip_codes || [],
    user_id: siteData.primary_contact_id || null,
    user: siteData.primary_contact_id ? null : {
      firstname: siteData.primary_contact_first_name,
      lastname: siteData.primary_contact_last_name,
      email: siteData.primary_contact_email,
      phone: siteData.primary_contact_phone,
      send_invitation: siteData.send_invitation,
    },
    disabled_at: null,
  };
};

export const mapFormToUpdateSitePUT = site => {
  const siteData = { ...site };

  return {
    name: siteData.name,
    type: siteData.type || null,
    notes: siteData.notes || null,
    timezone: siteData.timezone || null,
    reply_to_email: siteData.reply_to_email || null,
    zip_codes: siteData.zip_codes || [],
    primary_contact_id: siteData.primary_contact_id || null,
    primary_contact: {
      firstname: siteData.primary_contact_first_name || null,
      lastname: siteData.primary_contact_last_name || null,
      email: siteData.primary_contact_email || null,
      phone: siteData.primary_contact_phone || null,
      send_invitation: siteData.send_invitation || false,
    },
    disabled_at: siteData.disabled_at || null,
    primary_color: siteData.primary_color || null,
    secondary_color: siteData.secondary_color || null,
    button_text_color: siteData.button_text_color || null,
    toolbar_text_color: siteData.toolbar_text_color || null,
    logo_url: siteData.logo || null,
  };
};

export const mapApiPUTResponseToStore = apiResponse => {
  const siteData = { ...apiResponse };

  return {
    ...siteData,
    primary_contact_email: siteData.primary_contact_id ? siteData.primary_contact_user.email : null,
    primary_contact_firstname: siteData.primary_contact_id ? siteData.primary_contact_user.firstname : null,
    primary_contact_last_name: siteData.primary_contact_id ? siteData.primary_contact_user.lastname : null,
    primary_contact_phone: siteData.primary_contact_id ? siteData.primary_contact_user.phone : null,
  };
};
