import Bluebird from 'bluebird';
import * as badgesApi from '../api/badges';

export const RECEIVE_BADGES = 'RECEIVE_BADGES';
export const REQUEST_BADGES = 'REQUEST_BADGES';
export const RECEIVE_RESCUER_BADGES = 'RECEIVE_RESCUER_BADGES';
export const REQUEST_RESCUER_BADGES = 'REQUEST_RESCUER_BADGES';

export const requestBadges = () => ({
  type: REQUEST_BADGES,
});

export const receiveBadges = badges => {
  return {
    type: RECEIVE_BADGES,
    badges: badges,
    receivedAt: Date.now(),
  };
};

export const requestUserBadges = ({ userId }) => ({
  type: REQUEST_RESCUER_BADGES,
  userId: userId,
});

export const receiveUserBadges = ({ badges, userId }) => {
  return {
    type: RECEIVE_RESCUER_BADGES,
    badges: badges,
    userId: userId,
    receivedAt: Date.now(),
  };
};

function shouldFetchBadges(state) {
  const badges = state.entities.badges;
  if (badges.allIds.length === 0 || !badges.lastUpdated) {
    return true;
  } else if (badges.inflight) {
    return false;
  }

  return !badges.lastUpdated || badges.lastUpdated + 0.5 * 60 * 60 * 1000 < Date.now(); // older than 0.5h
}

export const fetchBadges = (quietMode = false, params = {}) => dispatch => {
  if (!quietMode) {
    dispatch(requestBadges());
  }

  return Bluebird.try(() => badgesApi.getBadges())
    .then(res => res.json())
    .then(json => dispatch(receiveBadges(json.data)));
};

export const fetchBadgesIfNeeded = (quietMode = false, params = {}) => (dispatch, getState) => {
  if (shouldFetchBadges(getState())) {
    return dispatch(fetchBadges(quietMode, params));
  }

  return null;
};

function shouldFetchMyBadges(state) {
  const badges = state.entities.badges;
  if (badges.allIds.length === 0 || !badges.lastUpdated) {
    return true;
  } else if (badges.inflight) {
    return false;
  }

  return !badges.lastUpdated || badges.lastUpdated + 0.5 * 60 * 60 * 1000 < Date.now(); // older than 0.5h
}

export const fetchRescuerBadges = (quietMode = false, userId) => dispatch => {
  if (!quietMode) {
    dispatch(requestUserBadges({ userId }));
  }

  return Bluebird.try(() => badgesApi.getBadges(userId))
    .then(res => res.json())
    .then(json => dispatch(receiveUserBadges({ userId, badges: json.data })));
};

export const fetchRescuerBadgesIfNeeded = (quietMode = false, userId) => (dispatch, getState) => {
  if (shouldFetchMyBadges(getState())) {
    return dispatch(fetchRescuerBadges(quietMode, userId));
  }

  return null;
};
