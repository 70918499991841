import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RemoveCircleOutline as RemoveCircleIcon, Help as HelpIcon } from '@material-ui/icons';
import { Select, FormControl, Grid, IconButton, Tooltip, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import DisabledDayFrequencyTooltip from '../../../../../../components/DonationManage/Schedule/DisabledDayFrequencyTooltip';
import { Colors } from '../../../../../../assets/theme/Colors';

const weekOccurrence = [
  { key: '1st', value: 1 },
  { key: '2nd', value: 2 },
  { key: '3rd', value: 3 },
  { key: '4th', value: 4 },
  { key: '5th', value: 5 },
  { key: 'last', value: -1 },
];

const daysOccurrence = [
  { label: 'Sunday', key: 'sun', value: 0 },
  { label: 'Monday', key: 'mon', value: 1 },
  { label: 'Tuesday', key: 'tue', value: 2 },
  { label: 'Wednesday', key: 'wed', value: 3 },
  { label: 'Thursday', key: 'thu', value: 4 },
  { label: 'Friday', key: 'fri', value: 5 },
  { label: 'Saturday', key: 'sat', value: 6 },
];

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: 'pointer',
    minWidth: 30,
    paddingTop: 15,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  helpIcon: {
    marginTop: 15,
    marginLeft: 2,
  }
}));

const DayOfMonthSelector = ({
  onDayChange,
  onBlur,
  hideDeleteButton,
  hideNoneValue,
  dayValue,
  ordinalValue,
  dom,
  disabled,
}) => {
  const classes = useStyles();
  const [occurrence, setOccurrence] = useState(ordinalValue || '');
  const [day, setDay] = useState(dayValue || dayValue === 0 ? dayValue : '');
  const [isInitRender, setIsInitRender] = useState(true);

  useEffect(() => {
    if (!isInitRender && occurrence && (day || day === 0)) {
      onDayChange(occurrence, day, ordinalValue, dayValue);
      setOccurrence('');
      setDay('');
    } else {
      setIsInitRender(false); // simulates componentDidUpdate
    }
  }, [day, occurrence]);

  const deleteDayOfMonth = (day, occurrence) => onDayChange(null, null, day, occurrence);

  return (
    <Box display="flex" alignItems="flex-start">
      <DisabledDayFrequencyTooltip disabled={disabled} placement="right">
        <FormControl className={classes.formControl} disabled={disabled}>
          <InputLabel>Every</InputLabel>
          <Select onBlur={onBlur} value={occurrence} onChange={event => setOccurrence(event.target.value)}>
            {!hideNoneValue && <MenuItem value="">None</MenuItem>}
            {weekOccurrence.map(row => (
              <MenuItem
                key={row.key}
                value={row.value}
                disabled={(((day || day === 0) && dom[daysOccurrence[day].key]) || []).some(
                  dayOrdinal => dayOrdinal.ordinal === row.value
                )}
              >
                {row.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} disabled={disabled}>
          <InputLabel id="demo-mutiple-name-label">Day</InputLabel>
          <Select onBlur={onBlur} value={day} onChange={event => setDay(event.target.value)}>
            {!hideNoneValue && <MenuItem value="">None</MenuItem>}
            {daysOccurrence.map(row => (
              <MenuItem
                key={row.key}
                value={row.value}
                disabled={(dom[row.key] || []).some(dayOrdinal => dayOrdinal.ordinal === occurrence)}
              >
                {row.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.icon}>
          <Grid container direction="row" justify="center" alignItems="flex-end">
            {!hideDeleteButton && (
              <Grid item>
                <IconButton
                  color="primary"
                  size="small"
                  disabled={disabled}
                  onClick={() => deleteDayOfMonth(occurrence, day)}
                >
                  <RemoveCircleIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </FormControl>
      </DisabledDayFrequencyTooltip>
      {ordinalValue === 5 && (
        <Tooltip
          className={classes.helpIcon}
          arrow
          placement="top"
          title="No rescue will be created for months that don’t have 5 of the same day"
        >
          <HelpIcon htmlColor={Colors.blue.main} />
        </Tooltip>
      )}
    </Box>
  );
};

DayOfMonthSelector.defaultProps = {
  hideDeleteButton: false,
  hideNoneValue: true,
  disabled: false,
};

DayOfMonthSelector.propTypes = {
  dom: PropTypes.object.isRequired,
  hideDeleteButton: PropTypes.bool,
  hideNoneValue: PropTypes.bool,
};

export default DayOfMonthSelector;
