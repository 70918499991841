import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { dismissPhoneVerificationNotification } from '../actions/uiPersisted';
import PhoneVerifyDialog from './PhoneVerifyDialog';
import * as authService from '../services/auth';

class UnverifiedPhoneNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPhoneConfirmationDialogOpened: false,
    };
  }

  setPhoneConfirmationDialogOpened = () => {
    this.setState({ isPhoneConfirmationDialogOpened: true });
  };

  render() {
    const { phoneVerificationNotificationVisible, classes } = this.props;
    const { isPhoneConfirmationDialogOpened } = this.state;
    const user = authService.getCurrentlyLoggedInOrImpersonatingUser();

    if (user.phone_verified !== null || !phoneVerificationNotificationVisible) {
      return null;
    }

    const onDismiss = () => {
      const { dismissPhoneVerificationNotification } = this.props;
      dismissPhoneVerificationNotification();
    };

    return (
      <>
        <Card className={classes.root}>
          <CardContent style={{ paddingBottom: 0 }}>
            <div className={classes.title}>Unverified mobile phone</div>
            <div className={classes.body}>
              You can't claim rescues until you have verified your mobile phone#. Press VERIFY NOW to start
              verification process. Or, visit Manage Profile to change your mobile phone#.
            </div>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" variant="contained" onClick={this.setPhoneConfirmationDialogOpened}>
              VERIFY NOW
            </Button>

            <Button size="small" color="primary" onClick={onDismiss}>
              Do this later
            </Button>
          </CardActions>
        </Card>

        <PhoneVerifyDialog
          open={isPhoneConfirmationDialogOpened}
          handleClose={() => {
            this.setState({ isPhoneConfirmationDialogOpened: false });
          }}
        />
      </>
    );
  }
}

UnverifiedPhoneNotification.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
                           ui: {
                             persisted: { phoneVerificationNotificationVisible },
                           },
                           app: { loggedInUser, impersonating },
                         }) => ({
  phoneVerificationNotificationVisible,
  loggedInUser,
  impersonating,
});

const mapDispatchToProps = dispatch => ({
  dismissPhoneVerificationNotification: () => dispatch(dismissPhoneVerificationNotification()),
});

const styles = theme => ({
  root: {
    backgroundColor: '#e1ebfd',
    width: '100%',
    paddingLeft: ({ loggedInUser, impersonating }) => {
      const user = impersonating || loggedInUser;

      if (user && !authService.shouldHideMainDrawer(user)) {
        return 70;
      }

      return 16;
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px !important',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(UnverifiedPhoneNotification);
