import { convertComponentDataToApiFormat } from '../helpers/weeklyHoursConverter';

export const mapFormToPOST = receiver => {
  const receiverData = { ...receiver };

  return {
    name: receiverData.name,
    active: true, // hardcoded, UI is currently not offering a way to create non-active receiver
    type_id: +receiverData.type_id,
    type_other: receiverData.type_other || null,
    ein: receiverData.ein || null,
    site_id: receiverData.site_id,
    location: {
      name: receiverData.name,
      address1: receiverData.address1,
      phone: receiverData.phone || null,
      phone_ext: receiverData.phone_ext || null,
      phone_type: receiverData.phone_type || null,
      city: receiverData.city,
      st: receiverData.st,
      zip: receiverData.zip,
      notes: receiverData.notes || null,
      dropoff_notes: receiverData.dropoff_notes || null,
      user_id: receiverData.primary_contact_id || null,
      user: {
        firstname: receiverData.primary_contact_first_name || null,
        lastname: receiverData.primary_contact_last_name || null,
        email: receiverData.primary_contact_email || null,
        phone: receiverData.primary_contact_phone || null,
        send_invitation: receiverData.send_invitation || false,
      },
    },
  };
};

export const mapFormToPATCH = receiverLocation => {
  const receiverLocationData = {
    ...receiverLocation,
    weekly_receiving_hours: {
      ...receiverLocation.weekly_receiving_hours,
    },
  };

  return {
    receiver_name: receiverLocationData.receiver_name,
    type_id: receiverLocationData.type_id,
    type_other: receiverLocationData.type_other || null,
    ein: receiverLocationData.ein || null,
    address1: receiverLocationData.address1,
    phone: receiverLocationData.phone || null,
    phone_ext: receiverLocationData.phone_ext || null,
    phone_type: receiverLocationData.phone_type || null,
    city: receiverLocationData.city,
    st: receiverLocationData.st,
    zip: receiverLocationData.zip,
    user_id: receiverLocationData.primary_contact_id || null,
    user: {
      firstname: receiverLocationData.primary_contact_first_name || null,
      lastname: receiverLocationData.primary_contact_last_name || null,
      email: receiverLocationData.primary_contact_email || null,
      phone: receiverLocationData.primary_contact_phone || null,
      send_invitation: receiverLocationData.send_invitation || false,
    },
    notes: receiverLocationData.notes || null,
    dropoff_notes: receiverLocationData.dropoff_notes || null,
    active: !!receiverLocationData.active,
    weekly_receiving_hours: Object.keys(receiverLocationData.weekly_receiving_hours).length
      ? convertComponentDataToApiFormat(receiverLocationData.weekly_receiving_hours)
      : [],
  };
};
