import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography, Grid, IconButton, } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import indigo from '@material-ui/core/colors/indigo';
import { withStyles } from '@material-ui/styles';
import { useDeviceData, isIOS, isMobile } from 'react-device-detect';
import { createUserDevice } from '../api/users';
import useActiveUser from '../hooks/useActiveUser';
import PushInstallButtonDesktopNotification from './PushInstallButtonDesktopNotification';
import CookieService from '../services/CookieService';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_PUSH_NOTIFICATIONS } from './FeatureFlagWrapper';

const StyledPaper = withStyles(({ spacing }) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    borderRadius: 0,
    borderTop: `1px solid ${indigo[500]}`,
    width: '100%',
    zIndex: 1200,
    paddingLeft: 0,
    padding: spacing(2),
  },
}))(Paper);

const PUSH_COOKIE = 'show_push_notification';

const PushInstallButton = () => {
  const activeUser = useActiveUser();
  const deviceData = useDeviceData();
  const hasPushNotificationsFF = useHasActiveFeatureFlag(FF_PUSH_NOTIFICATIONS);
  const [showPushNotification, setShowPushNotification] = useState(false);
  const canShowPrompt = !CookieService.get(PUSH_COOKIE);

  // we need to show this pre-notification (that asks user for a push notifications permission)
  // because iOS requires us to wait until user initiated some action in the PWA
  // (see: https://documentation.onesignal.com/docs/safari-web-push-for-ios#mobile-web-push-requirements)
  // once user will click on the "yes" in our pre-notification, he will be offered a system-popup
  // asking to grant the permission for the push notifications
  //
  // our pre-notification will be only shown, when all the following requirements are meet:
  // * iOS >= 16.4, no version check for Android devices
  // * user is logged in
  // * user is on mobile (iOS / Android)
  // * PWA is installed (added to the home screen)
  // * user is not already subscribed for push notifications on the current device
  useEffect(() => {
    if ((isIOS && parseFloat(deviceData.os.version) >= 16.4) || !isIOS) {
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        serviceWorkerRegistration.pushManager.getSubscription().then((subscription) => {
          if (subscription === null) {
            setShowPushNotification(true);
          }
        });
      });
    }
  }, []);

  if (!hasPushNotificationsFF) {
    return null;
  }

  if (!showPushNotification) {
    return null;
  }

  if (!canShowPrompt) {
    return null;
  }

  const dismissAllowPushNotification = () => {
    setShowPushNotification(false);
    CookieService.set(PUSH_COOKIE, 'false', 30);
  };

  const handlePushNotificationInstall = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then(function(permission) {
        if (permission === 'granted') {
          navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
            const applicationServerPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

            serviceWorkerRegistration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: (() => {
                const padding = '='.repeat((4 - applicationServerPublicKey.length % 4) % 4);
                const base64 = (applicationServerPublicKey + padding).replace(/\-/g, '+').replace(/_/g, '/');
                const rawData = window.atob(base64);
                const outputArray = new Uint8Array(rawData.length);

                for (let i = 0; i < rawData.length; ++i) {
                  outputArray[i] = rawData.charCodeAt(i);
                }

                return outputArray;
              })(),
            }).then(async (subscription) => {
              // save subscription in the database
              await createUserDevice(activeUser.id, {
                user_agent: window.navigator.userAgent,
                push_subscription: JSON.stringify(subscription),
              });

              // hide pre-notification
              setShowPushNotification(false);
            });
          });
        }
      });
    }
  };

  if (!isMobile) {
    return (
      <PushInstallButtonDesktopNotification
        onAccept={handlePushNotificationInstall}
        onDismiss={() => dismissAllowPushNotification()}
      />
    );
  }

  if (!isMobile) {
    return null;
  }

  return (
    <StyledPaper id="pwa-container">
      <Grid container direction="row" justify="space-evenly" alignItems="center" wrap="nowrap" spacing={0}>
        <Grid item>
          <IconButton aria-label="delete" size="small" edge={false} onClick={() => dismissAllowPushNotification()}>
            <CancelIcon />
          </IconButton>
        </Grid>

        <Grid item>
          <Typography variant="body2">Food Rescue US</Typography>

          <Typography variant="caption">
            Do you want to enable <br /> push notifications?
          </Typography>
        </Grid>

        <Grid item>
          <Button
            id="pwa-install-btn"
            color="primary"
            variant="outlined"
            disableElevation
            disableFocusRipple
            onClick={handlePushNotificationInstall}
          >
            Yes
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default PushInstallButton;
