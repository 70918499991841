import React, { Component } from 'react';
import Remarkable from 'remarkable';
import { withStyles } from '@material-ui/core';
import markdownSrc from '../whatsnew.md';

class WhatsNewView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markdown: null,
    };
  }

  componentDidMount() {
    fetch(markdownSrc)
      .then(response => response.text())
      .then(text => {
        this.setState({
          markdown: text,
        });
      });
  }

  render() {
    const { markdown } = this.state;
    const { classes } = this.props;
    const md = new Remarkable({
      html : true
    });

    if (markdown === null) {
      return <div>loading whats new</div>;
    }

    const html = md.render(markdown);

    return <div className={classes.root} dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

const styles = () => ({
  root : {
    '& h1' :  {
      fontSize : '1.5rem'
    },
    '& h2' :  {
      fontSize : '1.2rem'
    }
  }
});

export default withStyles(styles)(WhatsNewView);
