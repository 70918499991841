import * as reportsActions from '../actions/reports';

export const initialSitesState = {
  receiving_agency: {
    inflight: false,
    periods: [],
    sites: [],
    total: {
      rescues: {
        periods: {},
      },
      meals: {
        periods: {},
      },
    },
    lastUpdated: null,
  },
  volume: {
    inflight: false,
    all: [],
    lastUpdated: null,
  },
  rescues: {
    inflight: false,
    periods: [],
    sites: [],
    total: {
      rescues: {
        periods: {},
      },
      meals: {
        periods: {},
      },
    },
    lastUpdated: null,
  },
};

const reportsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case reportsActions.REQUEST_RECEIVING_AGENCY_REPORT:
      return {
        ...state,
        receiving_agency: {
          ...state.receiving_agency,
          inflight: true,
        },
      };
    case reportsActions.RECEIVE_RECEIVING_AGENCY_REPORT:
      return {
        ...state,
        receiving_agency: {
          ...state.receiving_agency,
          inflight: false,
          periods: action.receiverReport.periods,
          sites: action.receiverReport.sites,
          total: action.receiverReport.total,
          lastUpdated: action.receivedAt,
        },
      };
    case reportsActions.REQUEST_VOLUME_REPORT:
      return {
        ...state,
        volume: {
          ...state.volume,
          inflight: true,
        },
      };
    case reportsActions.RECEIVE_VOLUME_REPORT:
      return {
        ...state,
        volume: {
          ...state.volume,
          inflight: false,
          all: action.volumeReport,
          lastUpdated: action.receivedAt,
        },
      };
    case reportsActions.REQUEST_RESCUE_REPORT:
      return {
        ...state,
        rescues: {
          ...state.rescues,
          inflight: true,
        },
      };
    case reportsActions.RECEIVE_RESCUE_REPORT:
      return {
        ...state,
        rescues: {
          ...state.rescues,
          inflight: false,
          periods: action.report.periods,
          sites: action.report.sites,
          total: action.report.total,
          lastUpdated: action.receivedAt,
        },
      };
    default:
      return state;
  }
};

export default reportsReducer;
