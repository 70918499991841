import { SET_USER_CALENDAR_OPTIONS } from '../actions/calendarOptions';

const uiInitialState = {};

const calendarOptionsReducer = (state = uiInitialState, action) => {
  switch (action.type) {
    case SET_USER_CALENDAR_OPTIONS:
      const userCalendarsState = state[action.payload.userId] || {};

      return {
        ...state,
        [action.payload.userId]: {
          ...userCalendarsState, // all other calendars
          [action.payload.calendarId]: {
            ...userCalendarsState[action.payload.calendarId],
            ...action.payload.data,
          },
        },
      };
    default:
      return state;
  }
};

export default calendarOptionsReducer;
