import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Box, Button, Typography, GridList, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GalleryGridItem from '../../../Rescue/RescueGallery/Components/GalleryGridItem';
import { formatDate } from '../../../../helpers/formatters';
import { getRescuePickupLocationFullName } from '../../../../helpers/RescuesHelper';
import DonationRequestCard from './DonationRequestCard';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import ReleaseRescueButtons from '../../../../components/RescueNotification/ReleaseRescueButtons';
import routes from '../../../../routes';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  titleBar: {
    padding: spacing(1),
    color: palette.common.white,
    minHeight: 80,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    right: 0,
    bottom: 0,
  },
  root: {
    width: '100%',
    height: '200px !important',
    [breakpoints.up('xs')]: {
      height: 'calc(100vh - 30px) !important',
    },
    [breakpoints.up('md')]: {
      height: 'calc(100vh - 70px) !important',
    },
  },
  withFilters: {
    [breakpoints.up('xs')]: {
      height: 'calc(100vh - 100px) !important',
    },
    [breakpoints.up('md')]: {
      height: 'calc(100vh - 140px) !important',
    },
  },
}));

const DonationRequestsCards = ({ isLoading, donations, onEditClick }) => {
  const classes = useStyles();
  if (!isLoading && donations.length === 0) {
    return (
      <Box className={classes.container}>
        <Box className={classes.noData}>
          <Typography color="textSecondary" variant="h5">
            You have no requests to donate.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <Virtuoso
        totalCount={donations.length}
        style={{ height: 'calc(100vh - 70px)' }}
        itemContent={index => {
          const donationRequest = donations[index];
          const top = index === 0 ? 0 : 1;
          const bottom = index === donations ? 0 : 1;
          return (
            <Box pt={top} pb={bottom} key={donationRequest.id}>
              <DonationRequestCard donationRequest={donationRequest} isLoading={isLoading}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={donationRequest.status !== 'pending'}
                  onClick={() => onEditClick(donationRequest.id)}
                >
                  Edit
                </Button>
              </DonationRequestCard>
            </Box>
          );
        }}
      />
    </div>
  );
};

export default DonationRequestsCards;
