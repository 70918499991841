import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import routes from '../../../../routes';
import DonationPendingRequestsCards from './DonationPendingRequestsCards';
import useActiveSite from '../../../../hooks/useActiveSite';
import { fetchRequestDonationList } from '../../../../actions/requestDonations';
import { fetchSitePickupLocations } from '../../../../actions/sites';

const DonationPendingRequestsListView = ({ history }) => {
  const dispatch = useDispatch();
  const requestDonations = useSelector(state => state.entities.requestDonations);
  const pickupLocationsEntities = useSelector(state => state.entities.sites.pickupLocations.byId);
  const activeSite = useActiveSite();
  const donationsList = (Object.values(requestDonations.byId) || []).filter(
    donationRequest => donationRequest.status !== 'approved'
  );

  useEffect(() => {
    dispatch(
      fetchRequestDonationList({
        siteIds: [activeSite.id],
      })
    );
    dispatch(fetchSitePickupLocations([activeSite.id]));
  }, [dispatch]);

  const onEditClick = requestDonationId =>
    history.push(generatePath(routes.donation_request_verify, { donationId: requestDonationId }));

  return (
    <DonationPendingRequestsCards
      isLoading={requestDonations.inflight}
      onEditClick={onEditClick}
      donations={donationsList}
      pickupLocationsEntities={pickupLocationsEntities}
    />
  );
};

export default DonationPendingRequestsListView;
