import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Bluebird from 'bluebird';
import { DividerLine } from './DividerLine';
import { GoogleLogoIcon } from '../../assets/SvgIcons';
import routes from '../../routes';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import { OnChange } from 'react-final-form-listeners';
import * as authApi from '../../api/auth';
import * as authService from '../../services/auth';
import errorMessages from '../../assets/errorMessages';
import FABContext from '../../context/FABContext/FABContext';
import { useFormStyles } from './authStyles';
import { setFABActionsForCurrentlyLoggedInUser } from '../../services/auth';

const validateForm = values => {
  const { email = '', password = '' } = values;
  const errors = {};

  if (email.length === 0) {
    errors.email = 'Required';
  }

  if (password.length === 0) {
    errors.password = 'Required';
  }

  return errors;
};
const LoginForm = () => {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const fabContext = useContext(FABContext);
  const authStyles = useFormStyles();

  // both onSubmit and handleLoginWithGoogle return promises
  // but they return different values, and the login is in different
  // states. onSubmit returns the user with role assignments
  // handleLoginWithGoogle returns just the auth0 user

  // note how this form never results in a redirect. the 'success' of
  // this form is hidden in the auth service methods.
  const onSubmit = values => {
    const { email, password } = values;

    setSubmitting(true);

    return Bluebird
      .try(() => authApi.login(email, password))
      .then(res => res.json())
      .then(json => authService.handleAuthentication(history, json.data))
      .then(() => setFABActionsForCurrentlyLoggedInUser(fabContext))
      .catch(err => {
        setSubmitting(false);

        if (err.code === errorMessages.ERR_SYSTEM_NOT_AVAILABLE.code) {
          return history.push(routes.systemNotAvailable);
        }

        return {
          password: 'Invalid email or password',
        };
      });
  };

  const handleLoginWithGoogle = () => authService.authorizeWithGoogle({}, fabContext);

  const resetFormSubmitErrors = form => {
    const emailValue = form.getFieldState('email').value;
    const passwordValue = form.getFieldState('password').value;
    form.reset({ email: emailValue, password: passwordValue });
  };

  return (
    <>
      <Form
        onSubmit={(values) => onSubmit(values)}
        validate={validateForm}
        render={({ handleSubmit, pristine, invalid, dirtySinceLastSubmit, submitting, form }) => (
          <form className={authStyles.form} onSubmit={handleSubmit}>
            <Field
              className={authStyles.input}
              margin="normal"
              name="email"
              component={TextField}
              disabled={isSubmitting}
              type="email"
              variant="outlined"
              fullWidth
              placeholder="Email Address"
              autoComplete="email"
              data-testid="login-email-input"
            />
            <OnChange name="email">
              {() => {
                if (form.getFieldState('password').submitError || form.getFieldState('email').submitError) {
                  resetFormSubmitErrors(form);
                }
              }}
            </OnChange>

            <Field
              className={authStyles.input}
              margin="normal"
              name="password"
              component={TextField}
              disabled={isSubmitting}
              type="password"
              variant="outlined"
              fullWidth
              placeholder="Password"
              autoComplete="current-password"
              data-testid="login-password-input"
            />
            <OnChange name="password">
              {() => {
                if (form.getFieldState('password').submitError || form.getFieldState('email').submitError) {
                  resetFormSubmitErrors(form);
                }
              }}
            </OnChange>

            <ButtonWithLoading
              className={authStyles.submitButton}
              fullWidth
              type="submit"
              isLoading={submitting || isSubmitting}
              disabled={pristine || (invalid && !dirtySinceLastSubmit)}
              data-testid="login-submit-button"
            >
              Login
            </ButtonWithLoading>
          </form>
        )}
      />

      <DividerLine />

      <ButtonWithLoading
        startIcon={<GoogleLogoIcon width={36} height={36} />}
        className={authStyles.googleButton}
        fullWidth
        onClick={() => handleLoginWithGoogle()}
        isLoading={isSubmitting}
        type="submit"
      >
        Login with google
      </ButtonWithLoading>
    </>
  );
};

export default LoginForm;
