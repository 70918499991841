import React from 'react';
import { Grid } from '@material-ui/core';

const FormGrid = props => {
  const { children } = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={8} md={6}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FormGrid;
