import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Dialog, DialogContent, Grid } from '@material-ui/core';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';

const useStyles = makeStyles(theme => ({
  container: {
    backdropFilter: 'blur(8px)',
  },
}));

const ScreenOrientationInfo = () => {
  const classes = useStyles();
  const checkHorizontalMode = () => window.matchMedia('(max-width: 820px) and (orientation: landscape)').matches;
  const [isMobileHorizontal, setMobileHorizontal] = useState(checkHorizontalMode());
  const onOrientationChange = () => {
    // TODO: orientationchange fires before orientation changes. Find better way to listen to after orientation change
    setTimeout(() => setMobileHorizontal(checkHorizontalMode()), 200)
  };

  useEffect(() => {
    window.addEventListener('orientationchange', onOrientationChange);

    return () => window.removeEventListener('orientationchange', onOrientationChange);
  }, []);

  return (
    <Dialog classes={classes} aria-labelledby="responsive-dialog-title" open={isMobileHorizontal}>
      <DialogContent>
        <>
          <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
            <Grid item>
              <ScreenRotationIcon fontSize="large" />
            </Grid>
            <Grid item>
              <Box my={2}>
                <Typography variant="subtitle1" align="center">
                  We’re sorry, we don’t support this orientation.
                </Typography>
                <Typography variant="subtitle2" align="center">
                  To continue, please orient the phone vertically.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default ScreenOrientationInfo;
