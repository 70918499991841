import { RECEIVE_RESCUER_LOCATION_TYPES, REQUEST_RESCUER_LOCATION_TYPES } from '../actions/rescuerLocations';
import { objectFromArray } from '../helpers';
import { sortArrayOfObjects } from '../helpers/sorters';

const initialSitesState = {
  locationTypes: {
    allIds: [],
    byId: {},
    inflight: false,
    lastUpdated: null,
  },
};

const rescuerLocationsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case REQUEST_RESCUER_LOCATION_TYPES:
      return {
        ...state,
        locationTypes: {
          ...state.locationTypes,
          inflight: true,
        },
      };
    case RECEIVE_RESCUER_LOCATION_TYPES:
      const rescuerLocationTypes = sortArrayOfObjects(action.rescuerLocationTypes, row => row.name);

      return {
        ...state,
        locationTypes: {
          allIds: rescuerLocationTypes.map(rlt => rlt.id),
          byId: objectFromArray(rescuerLocationTypes, rlt => rlt.id),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    default:
      return state;
  }
};

export default rescuerLocationsReducer;
