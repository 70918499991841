import React from 'react';
import { Paper, ButtonBase, Box, Chip, Collapse, withStyles } from '@material-ui/core';

export const Accordion = withStyles(({ palette }) => ({
  rounded: {
    borderRadius: 0,
    border: `1px solid ${palette.divider}`,
    borderBottom: 0,
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    '&:last-child': {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      borderBottom: `1px solid ${palette.divider}`,
    },
  },
}))(({ children, ...rest }) => (
  <Paper elevation={0} {...rest}>
    {children}
  </Paper>
));

export const AccordionHeader = withStyles(theme => ({
  root: {
    ...theme.typography['h6'],
    color: '#545cd8',
    width: '100%',
    textAlign: 'left',
    display: 'block',
    fontWeight: 400,
  },
  action: {
    lineHeight: 0,
  },
}))(({ title, action, counter, classes, ...rest }) => (
  <Box py={1.5} px={2} bgcolor="background.paper">
    <ButtonBase disableRipple classes={{ root: classes.root }} {...rest}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1} color="#545cd8">
          <Box >
            {counter === null || typeof counter === 'undefined' || counter === 0 ? (
              title
            ) : (
              <>
                {title} <Counter label={counter} />
              </>
            )}
          </Box>
        </Box>
        <Box className={classes.action}>{action}</Box>
      </Box>
    </ButtonBase>
  </Box>
));

export const AccordionContent = ({ children, isOpen }) => (
  <Collapse in={isOpen} mountOnEnter>
    <Box p={2} pb={3}>
      {children}
    </Box>
  </Collapse>
);

const Counter = withStyles({
  root: {
    marginLeft: 6,
    backgroundColor: '#f7b924',
    fontWeight: 700,
  },
})(props => <Chip size="small" {...props} />);
