import { store } from '../store';
import moment from 'moment';
import { getRescueClaimer } from '../helpers/RescuesHelper';
import {RESCUE_CLOSED, RESCUE_CLOSED_NO_SHOW, RESCUE_NOT_CLOSED} from "../components/PastRescuesTable";

/* apiToStore takes a rescue object as returned from the API
 * and transforms it to the redux store schema. note that for many
 * objects, this is a no-op while the store and api results match
 */
export const apiToStore = ({
  date,
  start_date,
  pickup_begin,
  pickup_end,
  pickup_notes,
  dropoff_notes,
  ...rest
}) => ({
  ...rest,
  date: moment.utc(date).format('YYYYMMDD'),
  claimer: getRescueClaimer({ ...rest }),
  start_date: moment.utc(start_date).format('YYYYMMDD'),
  pickup_begin: pickup_begin ? pickup_begin.substring(0, 5) : '',
  pickup_end: pickup_end ? pickup_end.substring(0, 5) : '',
  pickup_notes: pickup_notes === null ? '' : pickup_notes,
  dropoff_notes: dropoff_notes === null ? '' : dropoff_notes,
});


export const objectFromStore = (rescue_id) => {
  const { rescues } = store.getState().entities;

  if( !( rescue_id in rescues.byId ) ) {
    return {};
  }

  const _r = Object.assign( {}, rescues.byId[rescue_id] );

  const subset = ({
    donor,
    location : donor_location,
    date,
    pickup_address,
    pickup_contact,
    pickup_contact_email,
    pickup_contact_phone,
    pickup_spec_id : id,
    positions,
    pickup_position,
    pickup_name,
    pickup_begin,
    pickup_end,
    pickup_notes,
    receiver,
    receiver_id,
    dropoff_address,
    dropoff_contact,
    dropoff_contact_email,
    dropoff_contact_phone,
    dropoff_notes,
    cancelled_by_id,
    canceller
  }) => {
    return ({
    id: rescue_id,
    donor,
    donor_location,
    date,
    pickup_address,
    pickup_contact,
    pickup_contact_email,
    pickup_contact_phone,
    positions,
    pickup_position,
    pickup_name,
    pickup_begin,
    pickup_end,
    pickup_notes,
    receiver,
    receiver_id,
    dropoff_address,
    dropoff_contact,
    dropoff_contact_email,
    dropoff_contact_phone,
    dropoff_notes,
    cancelled_by_id,
    canceller
    });
  };

  return subset(_r);
};

export const rescuesEqual = (r1,r2) => {

  if( r1 === null ) return true;
  if( r2 === null ) return true;

  const properties = [
    'pickup_begin',
    'pickup_end',
    'pickup_notes',
    'rescuer_notes',
    'dropoff_notes',
    'released_by_id',
    'rescuer_id',
    'adopter_id'
  ];

  return properties.every(prop => r1[prop] === r2[prop]);
};

export const mapFormToUpdateRescue = rescue => {
  const rescueData = { ...rescue };

  return {
    receiver_id: rescueData.receiver_id,
    pickup_begin: rescueData.pickup_begin,
    pickup_end: rescueData.pickup_end,
    rescuer_notes: rescueData.rescuer_notes || '',
    adopter_id: rescueData.adopter_id || null,
    rescuer_id: rescueData.rescuer_id || null,
    released_by_id: rescueData.released_by_id || null,
    cancelled_by_id: rescueData.cancelled_by_id || null,
  };
};

export const mapFormToUpdateRescues = (rescues = []) => rescues.map((rescue) => ({
  rescue_id: rescue.newData.rescue_id,
  receiver_id: rescue.newData.receiving_agency_id || rescue.receiver_id,
}));
