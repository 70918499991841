import React from 'react';
import DesktopDialog from './DesktopDialog';
import MobileDialog from './MobileDialog';

const PhotoDialog = ({
  fullScreen,
  open,
  rescuePhotoData,
  handleClose,
  handleNextPhoto,
  handlePrevPhoto,
  disableNextButton,
  disablePrevButton,
}) => {
  if (!open || !rescuePhotoData) {
    return null;
  }

  if (fullScreen) {
    return <MobileDialog handleClose={handleClose} rescuePhotoData={rescuePhotoData} />;
  } else {
    return (
      <DesktopDialog
        disablePrevButton={disablePrevButton}
        disableNextButton={disableNextButton}
        handleClose={handleClose}
        rescuePhotoData={rescuePhotoData}
        handleNextPhoto={handleNextPhoto}
        handlePrevPhoto={handlePrevPhoto}
      />
    );
  }
};

export default PhotoDialog;
