import React from 'react';
import { withStyles, CircularProgress, Box } from '@material-ui/core';

export const TopCircularProgress = withStyles({
  root: {
    zIndex: 3,
  },
})((props) => <CircularProgress variant="static" {...props} />);

export const BottomCircularProgress = withStyles({
  root: {
    zIndex: 2,
    color: '#eee',
    position: 'absolute',
  },
})(({ value, ...props }) => <CircularProgress variant="static" value={100} {...props} />);

const CircularProgressWithLabel = ({ children, ...props }) => {
  return (
    <Box position="relative" display="inline-flex">
      <TopCircularProgress {...props} />
      <BottomCircularProgress {...props} />
      <Box
        style={{
          padding: props.padding || '3px 3px 3px 3px',
          top: 0,
          left: props.left || 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          wordBreak: 'break-word',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
