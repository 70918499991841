import { getCurrentlyLoggedInOrImpersonatingUser } from '../services/auth';

export const getUserFullName = user => {
  if (!user) {
    return '';
  }

  return user.lastname
    ? `${(user.firstname || '').trim()} ${(user.lastname || '').trim()}`
    : (user.firstname || '').trim();
};

export const getUserFullNameWithEmail = user => {
  if (!user) {
    return '';
  }

  let userFullNameWithEmail = user.firstname;

  if (user.lastname) {
    userFullNameWithEmail += ` ${user.lastname}`;
  }

  userFullNameWithEmail += ` (${user.email})`;

  return userFullNameWithEmail;
};

export const getCurrentlyLoggedUserFullname = () => {
  const user = getCurrentlyLoggedInOrImpersonatingUser();

  return getUserFullName(user);
};

export const isUserPhoneNumberVerified = user => {
  if (!user) {
    return false;
  }

  return user.hasOwnProperty('phone_verified') && user.phone_verified !== null;
};

export const isUserEmailVerified = user => {
  if (!user) {
    return false;
  }

  return user.hasOwnProperty('email_verified') && user.email_verified !== null;
};

export const isUserActive = user => !!user.active;

export const isUserAbleToClaimRescue = user => isUserActive(user) && isUserEmailVerified(user);
