import React, { memo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const TableSkeletonLoader = ({ cols = 4, rows = 3, actionButtons = 0 }) => {
  const colsArray = Array(cols || 10).fill('');
  const rowsArray = Array(rows || 10).fill('');
  const actionButtonsArray = Array(actionButtons || 10).fill('');

  return (
    <Table>
      <TableHead>
        {actionButtons > 0 && (
          <TableRow>
            <TableCell colSpan={cols}>
              <Box display="flex" gridColumnGap={8} justifyContent="flex-end" pr={2}>
                {actionButtonsArray.map((abE, index) => (
                  <Box p={1} key={`action-button-${index}`}>
                    <Skeleton variant="circle" height={24} width={24} />
                  </Box>
                ))}
              </Box>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          {colsArray.map((e, index) => (
            <TableCell key={`head-col-${index}`}>
              <Skeleton width="100%" />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowsArray.map((rowE, rowIndex) => (
          <TableRow key={`body_${rowIndex}`} style={{ opacity: Number(2 / rowIndex).toFixed(1) }}>
            {colsArray.map((colE, colIndex) => (
              <TableCell key={`body_${rowIndex}-col_${colIndex}`}>
                <Skeleton width="100%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(TableSkeletonLoader);
