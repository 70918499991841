import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Breadcrumbs } from '@material-ui/core';
import { StyledRescuerBreadcrumb, StyledRescuerTextBreadcrumb } from '../../Components/StyledRescuerBreadcrumb';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import {
  Home as HomeIcon,
} from '@material-ui/icons';
import routes from '../../../../../routes';

const RescuesBreadcrumb = () => {
  const { formatMessage } = useIntl();

  return (
    <Box boxShadow={0}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledRescuerBreadcrumb
          component={RouterLink}
          to={generatePath(routes.mobileDashboard)}
          label={formatMessage({ id: 'breadcrumbs.home' })}
          icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
        />
        <StyledRescuerTextBreadcrumb component="a" href="#" label={formatMessage({ id: 'breadcrumbs.rescues' })} />
      </Breadcrumbs>
    </Box>
  );
};

export default RescuesBreadcrumb;
