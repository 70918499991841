export const Roles = {
  Admin: 'Admin',
  NationalSiteDirector: 'NationalSiteDirector',
  SiteDirector: 'SiteDirector',
  SiteCoordinator: 'SiteCoordinator',
  DonorAdmin: 'DonorAdmin',
  DonorStaff: 'DonorStaff',
  DonorLocationAdmin: 'DonorLocationAdmin',
  DonorLocationStaff: 'DonorLocationStaff',
  ReceiverAdmin: 'ReceiverAdmin',
  ReceiverStaff: 'ReceiverStaff',
  ReceiverLocationAdmin: 'ReceiverLocationAdmin',
  ReceiverLocationStaff: 'ReceiverLocationStaff',
  Rescuer: 'Rescuer',
};
