import Bluebird from 'bluebird';
import * as donationSizesApi from '../api/donationSizes';

export const REQUEST_DONATION_SIZES = 'REQUEST_DONATION_SIZES';
export const RECEIVE_DONATION_SIZES = 'RECEIVE_DONATION_SIZES';

export const requestDonationSizes = () => ({
  type: REQUEST_DONATION_SIZES,
});

export const receiveDonationSizes = (donationSizes) => ({
  type: RECEIVE_DONATION_SIZES,
  donationSizes,
});

export const fetchDonationSizes = () => (dispatch) => {
  dispatch(requestDonationSizes());

  return Bluebird.try(() => donationSizesApi.getDonationSizes())
    .then((res) => res.json())
    .then((json) => dispatch(receiveDonationSizes(json.data)));
};

export const fetchDonationSizesIfNeeded = () => (dispatch) => dispatch(fetchDonationSizes());
