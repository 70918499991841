import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { FF_MARKETING_HUB } from '../../components/FeatureFlagWrapper';
import useHasActiveFeatureFlag from '../../hooks/useFeatureFlags';
import { Roles } from '../../models/roles';
import routes from '../../routes';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../../services/auth';
import MarketingHubDesktop from './MarketingHubDesktop/MarketingHubDesktop';

const MarketingHubContainer = () => {
  const history = useHistory();
  const hasAccess = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);
  const hasActiveMarketingHubFF = useHasActiveFeatureFlag(FF_MARKETING_HUB);

  if (!hasActiveMarketingHubFF) {
    return history.push(generatePath(routes.index));
  }

  if (!hasAccess) {
    return 'no access';
  }

  return <MarketingHubDesktop />;
};

export default MarketingHubContainer;
