import Bluebird from 'bluebird';
import * as rescuersApi from '../api/rescuers';
import * as usersActions from '../actions/users';

export const REQUEST_RESCUERS = 'REQUEST_RESCUERS';
export const RECEIVE_RESCUERS = 'RECEIVE_RESCUERS';
export const REQUEST_RESCUERS_PHOTOS = 'REQUEST_RESCUERS_PHOTOS';
export const RECEIVE_RESCUERS_PHOTOS = 'RECEIVE_RESCUERS_PHOTOS';
export const RECEIVE_RESCUER_POST = 'RECEIVE_RESCUER_POST';
export const RECEIVE_RESCUER_EVENTS = 'RECEIVE_RESCUER_EVENTS';
export const REQUEST_RESCUER_EVENTS = 'REQUEST_RESCUER_EVENTS';
export const INVALIDATE_RESCUER_EVENTS = 'INVALIDATE_RESCUER_EVENTS';
export const SET_RESCUER_EVENT_REGISTRATION = 'SET_RESCUER_EVENT_REGISTRATION';
export const DELETE_RESCUER_EVENT_REGISTRATION = 'DELETE_RESCUER_EVENT_REGISTRATION';

export const requestRescuers = () => ({
  type: REQUEST_RESCUERS,
});

export const receiveRescuers = json => ({
  type: RECEIVE_RESCUERS,
  rescuers: json.data,
  receivedAt: Date.now(),
});

export const receiveRescuerPost = json => ({
  type: RECEIVE_RESCUER_POST,
  rescuer: json,
  receivedAt: Date.now(),
});

export const invalidateRescuerEvents = rescuerId => ({
  type: INVALIDATE_RESCUER_EVENTS,
  rescuerId: rescuerId,
});

export const requestRescuerEvents = rescuerId => ({
  type: REQUEST_RESCUER_EVENTS,
  rescuerId: rescuerId,
});

export const receiveRescuerEvents = (rescuerId, events) => ({
  type: RECEIVE_RESCUER_EVENTS,
  events: events,
  rescuerId: rescuerId,
  receivedAt: Date.now(),
});

export const receiveRescuerEventShift = (rescuerId, eventId, eventShiftId, eventShift) => ({
  type: SET_RESCUER_EVENT_REGISTRATION,
  rescuerId,
  eventId,
  eventShiftId,
  eventShift,
});

export const receiveDeleteRescuerEventShiftRegistration = (rescuerId, eventId, eventShiftId, eventShiftRegistrationId) => ({
  type: DELETE_RESCUER_EVENT_REGISTRATION,
  rescuerId,
  eventId,
  eventShiftId,
  eventShiftRegistrationId,
});

export const createRescuer = rescuer => dispatch => Bluebird
  .try(() => rescuersApi.createRescuer(rescuer))
  .then(res => res.json())
  .then(json => {
    dispatch(receiveRescuerPost(json));

    usersActions.receiveUserAndReflectAllUserStates(json.data);
  });

export const fetchRescuers = () => dispatch => {
  dispatch(requestRescuers());

  return Bluebird
    .try(() => rescuersApi.getRescuers())
    .then(res => res.json())
    .then(json => dispatch(receiveRescuers(json)));
};

export const fetchRescuersIfNeeded = () => dispatch => dispatch(fetchRescuers());

function shouldFetchRescuerEvents(state, rescuerId) {
  const events = state.entities.events;
  const rescuerEvents = events.byRescuerId[rescuerId];
  if (!rescuerEvents) {
    return true;
  } else if (rescuerEvents.inflight) {
    return false;
  }

  return !rescuerEvents.lastUpdated || rescuerEvents.lastUpdated + 0.5 * 60 * 60 * 1000 < Date.now(); // older than 0.5h
}

export const fetchRescuerEvents = (rescuerId, quietMode = false) => dispatch => {
  if (!quietMode) {
    dispatch(requestRescuerEvents(rescuerId));
  }

  return Bluebird
    .try(() => rescuersApi.getRescuerEvents(rescuerId))
    .then(res => res.json())
    .then(response => dispatch(receiveRescuerEvents(rescuerId, response.data)));
}

export const fetchRescuerEventsIfNeeded = (rescuerId, quietMode = false) => (dispatch, getState) => {
  if (shouldFetchRescuerEvents(getState(), rescuerId)) {
    return dispatch(fetchRescuerEvents(rescuerId, quietMode));
  }

  return null;
};
