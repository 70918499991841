import React, { useState } from 'react';
import Select from 'react-select';
import { Field } from 'react-final-form';
import { InputLabel } from '@material-ui/core';

const PhoneTypeSelect = ({ value }) => {
  const phoneTypesOptions = [
    {
      label: 'Mobile',
      value: 'mobile',
    },
    {
      label: 'Office',
      value: 'office',
    },
  ];
  const [selectedPhoneType, setSelectedPhoneType] = useState(
    value ? phoneTypesOptions.find(phoneType => phoneType.value === value) : phoneTypesOptions[0]
  );

  return (
    <>
      <InputLabel shrink>
        Phone Type
      </InputLabel>

      <Field
        name="phone_type"
        render={({ input }) => (
          <Select
            fullWidth
            name="phone_type"
            placeholder="Select Phone Type"
            onChange={option => {
              input.onChange(option !== null ? option.value : null);

              setSelectedPhoneType(option);
            }}
            value={selectedPhoneType}
            options={phoneTypesOptions}
          />
        )}
      />
    </>
  );
};

export default PhoneTypeSelect;
