import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import Monthly from './Calendar/Monthly';
import { Grid } from '@material-ui/core';
import { Colors } from '../../../../assets/theme/Colors';
import TabledAgenda from './Calendar/TabledAgenda';
import { format as formatDate } from 'date-fns';
import {
  CLAIM_STATUS_CLAIMED,
  CLAIM_STATUS_NOT_CLAIMED,
  SCHEDULE_STATUS_DELETED,
  SCHEDULE_STATUS_MODIFIED,
  SCHEDULE_STATUS_NEW,
  SCHEDULE_STATUS_NOT_MODIFIED,
} from '../../../../models/donationsNew';
import { DONATION_TYPE_SD } from '../../../../actions/donationNew';
import DonationContext from '../../../../context/DonationContext/DonationContext';

export const generateEventsFromRescues = schedule =>
  (schedule || []).reduce((acc, curr) => {
    let statusColor, claimColor;

    switch (curr.schedule_status) {
      case SCHEDULE_STATUS_DELETED:
        statusColor = Colors.donation.pickups.deleted;

        break;
      case SCHEDULE_STATUS_NEW:
        statusColor = Colors.donation.pickups.new;

        break;
      case SCHEDULE_STATUS_MODIFIED:
        statusColor = Colors.donation.pickups.modified;

        break;
      case SCHEDULE_STATUS_NOT_MODIFIED:
        statusColor = Colors.donation.pickups.nonModified;

        break;
      default:
        statusColor = Colors.donation.pickups.new;
    }

    switch (curr.claim_status) {
      case CLAIM_STATUS_NOT_CLAIMED:
        claimColor = Colors.rescues.unclaimed;
        break;

      case CLAIM_STATUS_CLAIMED:
        claimColor = Colors.rescues.claimed;
        break;
    }

    const event = {
      id: curr.pickup_spec_id,
      start: `${curr.date.value} ${curr.pickup_begin.value}`,
      end: `${curr.date.value} ${curr.pickup_end.value}`,
      title: curr.rescuer_notes,
      textColor: statusColor.contrastText,
      backgroundColor: statusColor.color,
      extendedProps: {
        meta: curr,
        statusColor: statusColor,
        claimColor: claimColor,
        isCancelled: !!curr.canceller,
        noReceiver: !curr.receiver,
      },
    };

    acc.push(event);

    return acc;
  }, []);

const Calendar = () => {
  const rescues = useSelector(state => state.ui.donation_new.rescues);
  const diffInflight = useSelector(state => state.ui.donation_new.diffInflight);
  const [selectedDate, setSelectedDate] = useState();

  const { donationType } = useContext(DonationContext);

  const calendarData = generateEventsFromRescues(rescues);
  const formattedDate = selectedDate && selectedDate.start ? formatDate(selectedDate.start, 'yyyy-MM-dd') : '';
  const dayEvents = calendarData.filter(event => event.extendedProps.meta.date.value === formattedDate);

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
      <Grid item xs>
        <Monthly
          donationType={donationType}
          isLoading={diffInflight}
          handleDaySelect={setSelectedDate}
          events={calendarData}
        />
      </Grid>
      {[DONATION_TYPE_SD].includes(donationType) && (
        <Grid item xs>
          <TabledAgenda isLoading={diffInflight} events={dayEvents} />
        </Grid>
      )}
    </Grid>
  );
};

export default Calendar;
