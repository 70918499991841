import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Bluebird from 'bluebird';
import { Link, useHistory, generatePath } from 'react-router-dom';
import { Breadcrumbs, Typography, Box } from '@material-ui/core';
import routes from '../../../routes';
import OverlayLoader from '../../../components/OverlayLoader';
import RescueAddForm from './Components/RescueAddForm';
import PagePaper from '../../../components/Common/PagePaper';
import {
  fetchSiteDonorsLocationsIfNeeded,
  fetchSitePickupLocationsIfNeeded,
  fetchSiteReceiversIfNeeded,
  fetchSiteRescuersIfNeeded,
} from '../../../actions/sites';
import useActiveSite from '../../../hooks/useActiveSite';
import { fetchRescueSizesIfNeeded } from '../../../actions/rescueSizes';
import { createQuickDonation } from '../../../actions/donations';
import { getFoodDonorsSelectOptions, getPickupLocationsSelectOptions, getReceiversSelectOptions, getRescuersSelectOptions } from '../../../helpers/getters';
import { fetchSystemSettingsIfNeeded } from '../../../actions/systemSettings';
import { fetchDonationSizesIfNeeded } from '../../../actions/donationSizes';
import {
  StyledRescuerBreadcrumb
} from "../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb";
import {Home as HomeIcon} from "@material-ui/icons";

const RescueAdd = ({ isMobileView }) => {
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const history = useHistory();
  const siteRescuers = useSelector(state => state.entities.sites.rescuers);
  const siteReceivers = useSelector(state => state.entities.sites.receivers);
  const siteDonorsLocations = useSelector(state => state.entities.sites.donorsLocations);
  const sitePickupLocations = useSelector(state => state.entities.sites.pickupLocations);
  const rescueSizes = useSelector(state => state.entities.rescueSizes);
  const donationSizes = useSelector(state => state.entities.donationSizes);

  const siteDonorsLocationsList = getFoodDonorsSelectOptions(siteDonorsLocations.bySiteId[activeSite.id] || []);
  const sitePickupLocationsList = getPickupLocationsSelectOptions(sitePickupLocations.byFoodDonorId || []);
  const siteReceiversList = getReceiversSelectOptions(siteReceivers.bySiteId[activeSite.id] || []);
  const siteRescuersList = getRescuersSelectOptions(siteRescuers.bySiteId[activeSite.id] || []);
  const rescueSizesList = rescueSizes.sorted;
  const donationSizesList = Object.values(donationSizes.byId);

  useEffect(() => {
    dispatch(fetchSiteDonorsLocationsIfNeeded(activeSite.id));
    dispatch(fetchSitePickupLocationsIfNeeded(activeSite.id));
    dispatch(fetchRescueSizesIfNeeded(activeSite.id));
    dispatch(fetchDonationSizesIfNeeded(activeSite.id));
    dispatch(fetchSiteReceiversIfNeeded(activeSite.id));
    dispatch(fetchSiteRescuersIfNeeded(activeSite.id));
    dispatch(fetchSystemSettingsIfNeeded(activeSite.id));
  }, [dispatch]);

  if (rescueSizes.inflight || siteReceivers.inflight || siteDonorsLocations.inflight || sitePickupLocations.inflight) {
    return 'loading...';
  }

  const onCreateRescue = data => Bluebird
      .try(() => createQuickDonation(data))
      .then(donation => history.push(generatePath(routes.rescue, { rescueId: donation.rescues[0].id})));

  return (
    <div>
      <Breadcrumbs aria-label="Breadcrumbs">
        <StyledRescuerBreadcrumb
          component={Link}
          to={routes.index}
          label="Home"
          icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
        />

        <Typography color="textPrimary">Add Rescue</Typography>
      </Breadcrumbs>

      <Box mb={{ xs: 10, md: 0 }}>
        <PagePaper width={{ xs: 1, md: '600px' }}>
          <OverlayLoader
            isLoading={
              rescueSizes.inflight ||
              siteReceivers.inflight ||
              siteDonorsLocations.inflight ||
              sitePickupLocations.inflight
            }
          >
            <RescueAddForm
              rescueSizes={rescueSizesList}
              donationSizes={donationSizesList}
              rescuersList={siteRescuersList}
              donorsLocationsList={siteDonorsLocationsList}
              pickupLocationsList={siteDonorsLocationsList}
              foodDonorsPickupLocations={sitePickupLocationsList}
              receiversList={siteReceiversList}
              isMobileView={isMobileView}
              createRescue={onCreateRescue}
            />
          </OverlayLoader>
        </PagePaper>
      </Box>
    </div>
  );
};

export default RescueAdd;
