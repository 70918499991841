import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Bluebird from 'bluebird';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { TextFieldWrapper } from '../Form/MuiFormWrapper';
import { getZipCodes } from '../../api/zipCodes';
import { getZipCodeFromMask, getZipCodeSelectOption } from '../../helpers/getters';
import ZipCodesList from '../../assets/zipcodes.json';
import errorMessages from '../../assets/errorMessages';

const ZipCodeSelect = ({
  name,
  error = false,
  helperText = null,
  onZipCodeChange,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  value = undefined,
  InputProps = {},
  testId = 'event-zip',
  defaultLocationText = null,
  size = 'small',
  ...rest
}) => {
  const [selectedZipCode, setSelectedZipCode] = useState(null);
  const [isFetchingZipCode, setIsFetchingZipCode] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorHelperText, setErrorHelperText] = useState(null);
  const [locationText, setLocationText] = useState(defaultLocationText);
  const isLocationTextVisible = !isError && !error && locationText;

  useEffect(() => {
    setLocationText(defaultLocationText)
  }, [defaultLocationText])

  const getZipCodeFromAPI = query => {
    setIsFetchingZipCode(true);
    return Bluebird.try(() => getZipCodes(query))
      .then(res => res.json())
      .then(({ data }) => {
        if (data.length > 0) {
          return getZipCodeSelectOption(data[0]);
        }

        return null;
      })
      .finally(() => setIsFetchingZipCode(false));
  };

  const handleZipCodeChange = (queriedZipCode, currentZipCode) =>
    getZipCodeFromAPI(queriedZipCode)
      .then(zip => {
        setIsError(false);
        setErrorHelperText(null);

        if (currentZipCode.length < 3) {
          setLocationText(null);
        }
        if (currentZipCode.length === 3 || currentZipCode.length === 4) {
          setLocationText(zip.data.state);
        }

        if (currentZipCode.length === 5) {
          setLocationText(`${zip.data.city}, ${zip.data.state}`);
        }
        setSelectedZipCode({
          zipCode: currentZipCode,
          data: zip.data,
        });
        onZipCodeChange({
          zipCode: currentZipCode,
          data: zip.data,
        });
      })
      .catch(() => {
        setIsError(true);
        setErrorHelperText(errorMessages.ERR_ZIP_CODE_NOT_FOUND.message);
      });

  return (
    <Box>
      <InputMask
        testid={`mask_${name}`}
        name={name}
        mask="99999"
        value={value}
        onFocus={onFocus}
        onBlur={event => {
          // double check onBlur if field is filled correctly
          if (!value && (!selectedZipCode || selectedZipCode.zipCode !== event.currentTarget.value)) {
            onChange(selectedZipCode ? selectedZipCode.zipCode : '');
            setErrorHelperText(errorMessages.ERR_INVALID_ZIP_CODE.message);
            setIsError(true);
          }

          return onBlur(event);
        }}
        onChange={event => {
          const plainZipCode = getZipCodeFromMask(event.currentTarget.value);

          if (plainZipCode.length >= 3 && plainZipCode.length <= 5) {
            const correctZipCodesList = ZipCodesList.filter(zip => zip.startsWith(plainZipCode));

            if (correctZipCodesList.length > 0) {
              handleZipCodeChange(correctZipCodesList[0], plainZipCode);
            } else {
              setIsError(true);
              setErrorHelperText(errorMessages.ERR_INVALID_ZIP_CODE.message);
            }
          }

          return onChange(event);
        }}
      >
        <TextFieldWrapper
          name={name}
          inputRef={rest.inputRef}
          error={isError || error}
          helperText={errorHelperText || helperText}
          variant="outlined"
          size={size}
          InputProps={{
            ...InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetchingZipCode ? <CircularProgress color="inherit" size={20} /> : null}

                {InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...rest}
        />
      </InputMask>
      {isLocationTextVisible && (
        <Box ml={1}>
          <Typography variant="caption" color="textSecondary">
            {locationText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ZipCodeSelect;
