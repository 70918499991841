import React from 'react';
import RescuesScheduleView from '../../../../containers/RescuesScheduleView';
import ScheduleBreadcrumb from './Components/ScheduleBreadcrumb';

const ScheduleMobileNEW = () => (
  <>
    <ScheduleBreadcrumb />
    <RescuesScheduleView bottomPadding={66} />
  </>
);

export default ScheduleMobileNEW;
