import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Grid, Accordion, AccordionSummary, AccordionDetails, Typography, useMediaQuery } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import * as authService from '../services/auth';
import { Roles } from '../models/roles';
import * as sitesActions from '../actions/sites';
import * as growthActions from '../actions/growth';
import GrowthReportForm from '../components/GrowthReportForm';
import GrowthReportTable from '../components/GrowthReportTable';
import OverlaySpinner from './OverlaySpinner';
import useUIFilter from '../hooks/useUIFilter';
import {
  dateRangeOptionsList,
  defaultGranularityOptions,
} from '../components/DateAndGranularityPicker/DateAndGranularityPicker';
import { Colors } from '../assets/theme/Colors';
import { assign } from 'lodash';

export const GROWTH_REPORT_UI_FILTER = 'report-growth';
const GrowthReportView = () => {
  const dispatch = useDispatch();
  const [filtersExpanded, setFiltersExpanded] = useState(true);
  const sites = useSelector(state => state.entities.sites);
  const growth = useSelector(state => state.entities.growth);
  const isMediumHeight = useMediaQuery('(min-height: 680px)');
  const { setUIFilter, getUIFilter } = useUIFilter();
  const filters = assign(
    {
      period: dateRangeOptionsList.last90Days.value,
      granularity: defaultGranularityOptions.monthly.value,
      dateRange: null,
      sites: null,
      showRescueSizeLegend: true,
      supressInactiveSites: true,
      completedRegistration: null,
    },
    getUIFilter(GROWTH_REPORT_UI_FILTER)
  );

  const activeUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const sitesWithSDSCAccess = authService.getUserSitesByRoles(activeUser.role_assignments, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);
  const isAdmin = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
  ]);
  let sitesList = isAdmin
    ? Object.values(sites.byId)
    : Object.values(sites.byId).filter(site => sitesWithSDSCAccess.includes(site.id));

  // sort sites
  sitesList.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    dispatch(sitesActions.fetchSitesIfNeeded());
  }, []);

  const handleFiltersChange = values =>
    setUIFilter(GROWTH_REPORT_UI_FILTER, {
      period: values.period,
      granularity: values.granularity,
      dateRange: {
        startDate: values.startDate,
        endDate: values.endDate,
      },
      sites: values.sites,
      supressInactiveSites: values.supressInactiveSites,
      showRescueSizeLegend: values.showRescueSizeLegend,
      completedRegistration: values.completedRegistration,
    });

  const handleChange = (event, isExpanded) => setFiltersExpanded(isExpanded);

  const fetchReport = () => {
    if (
      filters.dateRange
      && filters.dateRange.startDate !== null
      && filters.dateRange.endDate !== null
      && sitesList.length
    ) {
      // if user selected sites, use it and submit
      // otherwise - check if user is an admin -> then send an empty array to receive ALL sites
      // if user is not an admin, send an array with the sites ids in which user is SD/SC
      const sitesIds = (() => {
        if (Array.isArray(filters.sites)) {
          return filters.sites.map(s => s.id);
        }

        if (isAdmin) {
          return [];
        }

        return sitesList.map(s => s.id);
      })();

      dispatch(
        growthActions.fetchGrowthIfNeeded(
          filters.dateRange.startDate,
          filters.dateRange.endDate,
          filters.granularity,
          sitesIds,
          filters.supressInactiveSites,
          filters.completedRegistration
        )
      );
    }
  };

  const setReportTableHeight = () => {
    const filtersHeight = filtersExpanded ? 175 : 48;
    const tableContainer = document.querySelector('div.table-container');

    if (tableContainer) {
      tableContainer.style.height =
        isMediumHeight || !filtersExpanded ? `calc(100vh - ${225 + filtersHeight}px)` : '50vh';
    }
  };

  useEffect(() => {
    setReportTableHeight();
  }, [filtersExpanded]);

  if (!sitesList.length) {
    return 'Loading sites...';
  }

  return (
    <OverlaySpinner isLoading={sites.inflight || growth.inflight}>
      <Grid container>
        <Grid item xs={12}>
          <Accordion expanded={filtersExpanded} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" color="textPrimary">
                Growth Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GrowthReportForm
                defaultValues={filters}
                isAdmin={isAdmin}
                sitesList={sitesList}
                onSubmit={handleFiltersChange}
                fetchReport={fetchReport}
              />
            </AccordionDetails>
          </Accordion>

          <GrowthReportTable granularity={filters.granularity} growth={growth.all} />
        </Grid>
      </Grid>
    </OverlaySpinner>
  );
};

export default GrowthReportView;
