import { Colors } from './Colors';

export const Tabs = {
  MuiTabs: {
    indicator: {
      backgroundColor: 'transparent',
    },
  },
  MuiTab: {
    root: {
      '&$selected': {
        color: `${Colors.white} !important`,
        background: Colors.secondaryColor,
      },
    },
  },
};
