import React, { useEffect } from 'react';
import { uniq } from 'lodash';
import RescuerScheduleFilters from './RescuerScheduleFilters';
import { getUniqueDonors, getUniqueReceiver } from '../../helpers/RescuesHelper';
import { RESCUER_SCHEDULE_FILTER } from '../../pages/rescuerDashboard/rescuerDashboardMobile/Schedule/ScheduleMobile';
import useUIFilter from '../../hooks/useUIFilter';
import { initialRescuerScheduleFilters } from '../../reducers/ui';
import { getUserSitesByRoles } from '../../services/auth';
import { Roles } from '../../models/roles';
import useActiveUser from '../../hooks/useActiveUser';

const RescuerScheduleFiltersContainer = ({ isMobileView, filteredRows, availableRescues }) => {
  const activeUser = useActiveUser();
  const { setUIFilter, getUIFilter } = useUIFilter();
  const rescuerScheduleFilters =  getUIFilter(RESCUER_SCHEDULE_FILTER) || initialRescuerScheduleFilters;
  const sitesWithRescuerRoles = getUserSitesByRoles(activeUser.role_assignments, Roles.Rescuer);

  useEffect(() => {
    if (!isMobileView) {
      setUIFilter(RESCUER_SCHEDULE_FILTER, {
        ...rescuerScheduleFilters,
      });
    }
  }, [isMobileView, setUIFilter]);

  const uniqueDonors = getUniqueDonors(availableRescues);
  const uniqueReceivers = getUniqueReceiver(availableRescues);
  const lastRescueDate = new Date(
    Math.max(...availableRescues.map(e => new Date(e.date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') + ' 23:59:59')))
  );
  const pickupCities = uniq(availableRescues.map(ar => `${ar.pickup_city}, ${ar.pickup_st}`));
  const dropoffCities = uniq(availableRescues.map(ar => `${ar.dropoff_city}, ${ar.dropoff_st}`));

  const handleSetScheduleFilter = (filterName, filterProps) =>
    setUIFilter(RESCUER_SCHEDULE_FILTER, {
      ...rescuerScheduleFilters,
      [filterName]: filterProps,
    });

  return (
    <RescuerScheduleFilters
      maxDate={lastRescueDate}
      isMobileView={isMobileView}
      rows={filteredRows}
      setScheduleFilter={handleSetScheduleFilter}
      rescuerScheduleFilters={rescuerScheduleFilters}
      sitesWithRescuerRoles={sitesWithRescuerRoles || []}
      donorReceiverList={[...uniqueDonors, ...uniqueReceivers]}
      pickupCities={pickupCities}
      dropoffCities={dropoffCities}
    />
  );
};

export default RescuerScheduleFiltersContainer;
