import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Colors } from '../assets/theme/Colors';

// https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

const OverlayLoader = ({ isLoading, children, wrapperStyles = {} }) => {
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      fadeSpeed={200}
      styles={{
        spinner: base => ({
          ...base,
          '& svg circle': {
            stroke: Colors.appBar.main,
          },
        }),
        wrapper: base => ({
          ...base,
          ...wrapperStyles,
        }),
        overlay: base => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.2)',
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default OverlayLoader;
