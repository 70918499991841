import * as foodDescriptionUnitLbsActions from '../actions/foodDescriptionUnitLbs';

export const initialSitesState = {
  inflight: false,
  all: [],
  lastUpdated: null,
};

const foodDescriptionUnitLbsReducer = (state = initialSitesState, action) => {
  switch (action.type) {
    case foodDescriptionUnitLbsActions.REQUEST_FOOD_DESCRIPTION_UNIT_LBS:
      return {
        ...state,
        inflight: true,
      };
    case foodDescriptionUnitLbsActions.RECEIVE_FOOD_DESCRIPTION_UNIT_LBS:
      return {
        ...state,
        inflight: false,
        all: action.foodDescriptionUnitLbs,
        lastUpdated: action.receivedAt,
      };
    case foodDescriptionUnitLbsActions.REQUEST_FOOD_DESCRIPTION_UNIT_LBS_FAILED:
      return {
        ...state,
        inflight: false,
      };
    default:
      return state;
  }
};

export default foodDescriptionUnitLbsReducer;
