import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Bluebird from 'bluebird';
import { Button, Card, CardContent, CardActions } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import * as authService from '../services/auth';
import * as usersActions from '../actions/users';
import * as uiPersistedActions from '../actions/uiPersisted';
import ButtonWithLoading from './ButtonWithLoading';

const UnverifiedEmailNotification = props => {
  const { emailVerificationNotificationVisible, classes } = props;
  const currentlyLoggedInOrImpersonatingUser = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const [isLoading, setLoading] = useState(false);

  const onResendEmailClick = () => {
    const { site, resendEmail } = props;
    const user = authService.getCurrentlyLoggedInOrImpersonatingUser();

    setLoading(true);

    return Bluebird
      .try(() => resendEmail(user.id, site ? site.id : null))
      .then(() => snackbarHelper.success(`Verification email sent to ${user.email}`))
      .catch(err => errorsHelper.handleError(err))
      .finally(() => setLoading(false));
  };

  const onDismiss = () => {
    const { dismissEmailVerificationNotification } = props;

    dismissEmailVerificationNotification();
  };

  if (
    authService.isCompleteRegistrationProcessPending()
    || currentlyLoggedInOrImpersonatingUser.email_verified !== null
    || !emailVerificationNotificationVisible
  ) {
    return null;
  }

  return (
    <Card className={classes.root}>
      <CardContent style={{ paddingBottom: 0 }} data-testid="unverified-email-card">
        <div className={classes.title}>
          Unverified email address
        </div>

        <div className={classes.body}>
          You can't claim rescues until you have verified your email address. Press RESEND EMAIL to resend the
          verification email to <strong>{currentlyLoggedInOrImpersonatingUser.email}</strong>. Or, visit Manage
          Profile to change your email address.
        </div>
      </CardContent>

      <CardActions>
        <ButtonWithLoading
          size="small"
          color="primary"
          variant="contained"
          onClick={onResendEmailClick}
          data-testid="resend-email-button"
          isLoading={isLoading}
        >
          Resend email
        </ButtonWithLoading>

        <Button size="small" color="primary" onClick={onDismiss}>
          Do this later
        </Button>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = ({
  ui: { persisted: { emailVerificationNotificationVisible } },
  app: { site, loggedInUser, impersonating }
}) => ({
  site,
  emailVerificationNotificationVisible,
  loggedInUser,
  impersonating,
});
const mapDispatchToProps = dispatch => ({
  resendEmail: (userId, siteId = null) => dispatch(usersActions.resendUserVerificationEmail(userId, siteId)),
  dismissEmailVerificationNotification: () => dispatch(uiPersistedActions.dismissEmailVerificationNotification()),
  showEmailVerificationNotification: () => dispatch(uiPersistedActions.showEmailVerificationNotification()),
});
const styles = theme => ({
  root: {
    backgroundColor: '#e1ebfd',
    width: '100%',
    paddingLeft: ({ loggedInUser, impersonating }) => {
      const user = impersonating || loggedInUser;
      if (user && !authService.shouldHideMainDrawer(user)) {
        return 70;
      }

      return 16;
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px !important',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(UnverifiedEmailNotification);
