import React from 'react';
import { useSelector } from 'react-redux';

const isEqual = (prev, next) => (prev ? prev.length : null === next ? next.length : null);

const useSystemSettings = (name, returnAsValue = true) => {
  const systemSettingsEntities = useSelector(state => state.entities.systemSettings, isEqual);
  const systemSetting = Object.values(systemSettingsEntities.byId).find(systemSetting => systemSetting.name === name);

  if (returnAsValue) {
    return systemSetting ? systemSetting.value : null;
  }
  return systemSetting || null;
};

export default useSystemSettings;
