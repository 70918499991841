import React from 'react';
import { useSelector } from 'react-redux';
import ContactList from '../../Components/ContactList';
import { getUserFullName } from '../../../../helpers/user';
import ContactListDetails from '../../Components/ContactListDetails';

const RescuersContactList = ({ siteId }) => {
  const rescuers = useSelector(state => state.entities.sites.rescuers);
  const contactList = rescuers.bySiteId[siteId] || [];

  return (
    <ContactList
      id="rescuers"
      isLoading={!rescuers.lastUpdated || rescuers.inflight}
      list={contactList}
      expandedContent={item => ContactListDetails({ ...item, meta: { name: item.name } })}
      subHeaderGetter={row => (row.firstname || '#').substr(0, 1).replace(/\d|\W/i, '#')}
      sortBy={row => getUserFullName(row)}
      labelGetter={row => getUserFullName(row)}
      searchBy={({ row, query }) =>
        (row.firstname || '').toUpperCase().includes(query) ||
        (row.lastname || '').toUpperCase().includes(query) ||
        (row.phone || '').includes(query) ||
        (row.email || '').toUpperCase().includes(query) ||
        (row.name || '').toUpperCase().includes(query)
      }
    />
  );
};

export default RescuersContactList;
