import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SitesAndDateFilter from './SitesAndDateFilter';
import LeaderboardsTables from '../../../../components/LeaderboardsTables';
import PagePaper from '../../../../components/Common/PagePaper';
import { fetchSitesIfNeeded } from '../../../../actions/sites';
import { fetchLeaderboardIfNeeded } from '../../../../actions/leaderboards';
import { getEarliestSiteDate } from '../../../../helpers/getters';

const NSDLeaderboards = () => {
  const dispatch = useDispatch();
  const sites = useSelector(state => state.entities.sites);
  const sitesList = sites.allIds.map(id => sites.byId[id]);
  const leaderboards = useSelector(state => state.entities.leaderboards);
  const isLoading = sites.inflight || leaderboards.inflight;
  const [selectedSites, setSelectedSites] = useState([]);
  const selectedSitesWithAllData = selectedSites.map(id => sites.byId[id]);

  useEffect(() => {
    dispatch(fetchSitesIfNeeded());
  }, [dispatch]);

  return (
    <div>
      <PagePaper mt={1}>
        <SitesAndDateFilter
          customDateRangeOptions={{
            minDate: getEarliestSiteDate(selectedSitesWithAllData, sitesList),
          }}
          sites={sitesList}
          disabled={isLoading}
          handleChange={state => {
            setSelectedSites(state.sites);
            return dispatch(fetchLeaderboardIfNeeded(state.dateRange, state.sites));
          }}
        />
      </PagePaper>

      <LeaderboardsTables data={leaderboards} />
    </div>
  );
};

export default NSDLeaderboards;
