import { makeStyles, fade } from '@material-ui/core/styles';
import { Colors } from '../../assets/theme/Colors';
import loginBackground from '../../assets/images/login-background.jpeg';

export const useFormStyles = makeStyles(theme => ({
  loginFormWrapper: {
    padding: `${theme.spacing(2)}px 0 !important `,
    [theme.breakpoints.down('sm')]: {
      paddingTop: `0 !important `,
      maxWidth: '444px',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '400px',
    },
  },
  paper: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  frusLogo: {
    width: 40,
    height: 40,
  },
  submitButton: {
    backgroundColor: Colors.appBar.main,
    '&:hover': {
      backgroundColor: Colors.appBar.light,
    },
    '&:disabled': {
      color: fade(Colors.white, 0.5),
      backgroundColor: fade(Colors.appBar.main, 0.5),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2, 0, 3),
    },
    margin: theme.spacing(1, 0, 2),
    padding: 12,
  },
  googleButton: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 0, 2),
    },
    margin: theme.spacing(2, 0, 1),
    background: Colors.blue.main,
    '&:hover': {
      background: Colors.blue.light,
    },
  },
  helpText: {
    padding: theme.spacing(0, 1),
    color: Colors.white,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    height: '160px',
    [theme.breakpoints.down('sm')]: {
      height: '100px',
    },
  },
  headerText: {
    ...theme.typography['h5'],
    color: `${Colors.white} !important`,
    textAlign: 'center',
    fontWeight: 600,
  },
  pageContainer: {
    backgroundColor: fade(Colors.primaryColor, 0.8),
    position: 'relative',
    display: 'flex',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  },
  pageBackground: {
    position: 'inherit',
    '&::before': {
      content: "' '",
      display: 'block',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${loginBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 0',
      backgroundSize: 'cover',
    },
  },
  input: {
    '& input': {
      borderRadius: 4,
      background: 'rgb(250, 255, 250)',
      '-webkit-box-shadow': '0 0 0 100px rgb(250, 255, 250) inset',
    },
    '& input:focus': {
      '-webkit-box-shadow': '0 0 0 100px rgb(250, 255, 250) inset',
    },
    '& input:invalid': {
      '-webkit-box-shadow': '0 0 0 100px rgb(250, 255, 250) inset',
    },
  },
}));
