import * as aggregatesApi from '../api/aggregates';
import moment from 'moment';

export const REQUEST_AGGREGATES = 'REQUEST_AGGREGATES';
export const RECEIVE_AGGREGATES = 'RECEIVE_AGGREGATES';

export const requestAggregates = ( params ) => ({
  type: REQUEST_AGGREGATES,
  ...params
} );

export const receiveAggregates = (data, granularity) => ({
  type: RECEIVE_AGGREGATES,
  payload: {
    data,
    granularity,
  },
  receivedAt: Date.now(),
});

const apiGranularityMap = {
  none: 'none',
  daily: 'day',
  weekly: 'year_week',
  week : 'year_week',
  monthly: 'year_month',
  month : 'month',
  year: 'year',
};

export const fetchAggregate = ( fact, measure, dimension, granularity, dateRange ) => dispatch => {
  dispatch(requestAggregates( { fact, measure, dimension, granularity }));

  if(!apiGranularityMap[granularity]) {
    throw new Error(`Invalid granularity (${granularity})`)
  }

  return aggregatesApi.getAggregates( fact, dimension, apiGranularityMap[granularity], dateRange)
  .then( ( agg ) => (agg.json()) )
  .then( ( agg ) => {
    dispatch(receiveAggregates( agg, granularity));
  });
};

const fetchRescuesCounts = ( dimension, granularity, dateRange ) => dispatch => {
  const fact = 'rescues';
  const measure = 'count';

  return dispatch(fetchAggregate( fact, measure, dimension, granularity, dateRange ));

};

export const fetchReceiverLocationDashboardAggregates = dateRange => dispatch => {
  let _dateRange = null;

  if (dateRange) {
    _dateRange = `${moment(dateRange.startDate).format('YYYYMMDD')}/${moment(dateRange.endDate).format('YYYYMMDD')}`;
  }

  return Promise.all([
    dispatch(fetchRescuesCounts('receiver,donor_location', 'none', _dateRange)),
    dispatch(fetchRescuesCounts('receiver,rescuer', 'none', _dateRange)),
  ]);
};

export const fetchReceiverLocationDashboardAggregatesIfNeeded = dateRange => dispatch => dispatch(
  fetchReceiverLocationDashboardAggregates(dateRange)
);
