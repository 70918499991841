import React, { Suspense, useEffect } from 'react';
import { generatePath, Route, Switch, useLocation } from 'react-router-dom';
import { matchPath, useParams } from 'react-router';
import { AppBar, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../../../routes';
import { TabLink } from '../../../components/Common/BottomNavTabs';
import { setDefaultDashboard } from '../../../actions/uiPersisted';
import Loading from '../../../components/Loading';
import PastRescuesListView from '../../../containers/PastRescuesListView';
import FutureRescuesListView from '../../../containers/FutureRescuesListView';
import { fetchFoodDonorIfNeeded } from '../../../actions/foodDonors';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../../../services/auth';
import { Roles } from '../../../models/roles';

const DldStaffList = React.lazy(() => import('./DLDStaffList'));
const DldProfile = React.lazy(() => import('./DLDProfile'));
const DldLeaderboards = React.lazy(() => import('./DLDLeaderboards'));
const DldPickupLocations = React.lazy(() => import('./DLDPickupLocations'));

export const dashboardPaths = [
  generatePath(routes.dashboardFoodDonor),
  generatePath(routes.dashboardFoodDonor, { tab: 'staff' }),
  generatePath(routes.dashboardFoodDonor, { tab: 'profile' }),
  generatePath(routes.dashboardFoodDonor, { tab: 'leaderboards' }),
  generatePath(routes.dashboardFoodDonor, { tab: 'pickup-locations' }),
  generatePath(routes.dashboardFoodDonor, { tab: 'past-rescues' }),
];

export const locationPaths = id => [
  generatePath(routes.foodDonor, { foodDonorId: id }),
  generatePath(routes.foodDonor, { foodDonorId: id, tab: 'staff' }),
  generatePath(routes.foodDonor, { foodDonorId: id, tab: 'profile' }),
  generatePath(routes.foodDonor, { foodDonorId: id, tab: 'leaderboards' }),
  generatePath(routes.foodDonor, { foodDonorId: id, tab: 'pickup-locations' }),
  generatePath(routes.foodDonor, { foodDonorId: id, tab: 'past-rescues' }),
];

const DonorLocationDashboardContainer = ({ renderParams }) => {
  const { pathname } = useLocation();
  const params = useParams();

  const isLocationView = matchPath(pathname, {
    path: routes.foodDonor,
    exact: true,
  });

  const dispatch = useDispatch();
  const foodDonorId = parseInt(params.foodDonorId || renderParams.foodDonorId, 10);
  const foodDonors = useSelector(state => state.entities.foodDonors);
  const foodDonor = foodDonors.byId[foodDonorId];
  const paths = isLocationView ? locationPaths(isLocationView.params.foodDonorId) : dashboardPaths;

  const isAdmin = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
  ]);

  useEffect(() => {
    dispatch(fetchFoodDonorIfNeeded(foodDonorId));
  }, [dispatch]);

  useEffect(() => {
    if (
      matchPath(pathname, {
        path: routes.dashboardFoodDonor,
        exact: true,
      })
    ) {
      dispatch(setDefaultDashboard({ route: routes.dashboardFoodDonor, params: params }));
    }
  }, [pathname]);

  if (!foodDonor) {
    return null;
  }

  return (
    <>
      <div data-testid="food-donor-dashboard">
        <AppBar position="static">
          <Tabs value={pathname}>
            <TabLink label="Future Rescues" to={paths[0]} value={paths[0]} />
            <TabLink label="Past Rescues" to={paths[5]} value={paths[5]} />
            <TabLink data-testid="tab-staff" label="Staff" to={paths[1]} value={paths[1]} />
            <TabLink data-testid="tab-pickup-locations" label="Pickup Locations" to={paths[4]} value={paths[4]} />
            <TabLink label="Profile" to={paths[2]} value={paths[2]} />
            <TabLink label="Leaderboards" to={paths[3]} value={paths[3]} />
          </Tabs>
        </AppBar>
      </div>

      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            path={paths[0]}
            exact
            render={() => (
              <FutureRescuesListView
                filter={(rescue) => rescue.location_id === foodDonorId}
                removedFilters={['location']}
                showOnlyPickupFilter
                showEditRescueButton={isAdmin}
              />
            )}
          />
          <Route
            path={paths[5]}
            exact
            render={() => (
              <PastRescuesListView
                filterColumns={['close_comments']}
                filter={rescue => rescue.location_id === foodDonorId}
                removedFilters={['location']}
                showOnlyPickupFilter
              />
            )}
          />
          <Route path={paths[1]} exact render={() => <DldStaffList foodDonorId={foodDonorId} />} />
          <Route path={paths[4]} exact render={() => <DldPickupLocations foodDonorId={foodDonorId} />} />
          <Route path={paths[2]} exact render={() => <DldProfile foodDonor={foodDonor} />} />
          <Route path={paths[3]} exact render={() => <DldLeaderboards foodDonorId={foodDonorId} />} />
        </Switch>
      </Suspense>
    </>
  );
};

export default DonorLocationDashboardContainer;
