import React, { useRef, useState, useContext, useEffect } from 'react';
import { List, ListItem, ListItemText, ListSubheader, withStyles, Slider, Collapse, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { Colors } from '../../../assets/theme/Colors';
import ContactListSearch from './ContactListSearch';
import { debounce } from 'lodash';
import FABContext from '../../../context/FABContext/FABContext';

const StyledList = withStyles(({ palette }) => ({
  root: {
    width: '100%',
    backgroundColor: palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '70vh',
  },
}))(List);

const StyledListSubheader = withStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.grey[100],
    lineHeight: '25px',
    fontWeight: 600,
  },
}))(ListSubheader);

const StyledListItemText = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(1, 0, 1, 0),
  },
}))(ListItemText);

const StyledListItem = withStyles(() => ({
  root: {
    padding: 0,
    width: 'auto',
  },
}))(ListItem);

const StyledListItemContainer = withStyles(({ palette, spacing }) => ({
  root: {
    margin: spacing(0, 2, 0, 2),
    '&:not(:last-child)': {
      borderBottomColor: palette.grey[100],
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
    },
  },
}))(Box);

const StyledSlider = withStyles({
  root: { position: 'absolute', right: 0, zIndex: 33 },
  vertical: { height: '400px !important', marginRight: '10px !important', top: 150 },
  mark: { display: 'none' },
  rail: { display: 'none' },
  track: { display: 'none' },
  thumb: { display: 'none' },
  markLabel: { color: Colors.secondaryColor },
})(Slider);

const loadingList = {
  A: [{ id: 1 }, { id: 2 }, { id: 3 }],
  B: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }],
  C: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
  D: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }],
  E: [{ id: 1 }, { id: 2 }, { id: 3 }],
};

const alphabet = [...'#ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
const maxRows = 26; // alphabet.length - 1;

const marks = alphabet.reverse().map((letter, index) => ({ value: index, label: letter }));
const valuetext = value => alphabet[value];

const ContactList = ({ list, isLoading, subHeaderGetter, labelGetter, subLabelGetter, sortBy, searchBy, expandedContent }) => {
  const listRef = useRef();
  const fabContext = useContext(FABContext);
  const [searchText, setSearchText] = useState('');
  const [selectedContact, setSelectedContact] = useState();

  useEffect(() => {
    // move FAB above bottom tabs
    fabContext.updateFABStyles({
      speedDial: {
        bottom: `calc(56px + 15px + ${getComputedStyle(document.documentElement).getPropertyValue(
          '--safe-area-inset-bottom'
        )})`,
      },
    });

    // reset FAB styles on component unmount
    return () => fabContext.updateFABStyles({});
  }, []);
  
  const entries = isLoading
    ? Object.entries(loadingList)
    : Object.entries(
        list
          .sort((prev, next) => sortBy(prev).localeCompare(sortBy(next), 'en', { sensitivity: 'base' }))
          .reduce((r, v, i, a, k = subHeaderGetter(v)) => {
            if (searchText && !searchBy({ row: v, query: searchText.toUpperCase() })) {
              return r;
            }
            return (r[k] || (r[k] = [])).push(v), r;
          }, {})
      );

  const scrollTo = (event, value) => {
    const header = alphabet[value] || 'A';
    const element = document.getElementById(header);

    if (!element || !listRef.current) {
      return;
    }
    listRef.current.scroll(0, document.getElementById(`${header}0`).offsetTop - 25);
  };

  const debounceSearch = debounce(setSearchText, 250);
  const handleContactClick = contactId => {
    if (selectedContact === contactId) {
      return setSelectedContact(undefined);
    }

    return setSelectedContact(contactId);
  };

  return (
    <>
      <StyledSlider
        orientation="vertical"
        defaultValue={maxRows}
        max={maxRows}
        aria-labelledby="vertical-slider"
        getAriaValueText={valuetext}
        marks={marks}
        onChange={scrollTo}
      />

      <Box my={2}>
        <ContactListSearch handleSearch={debounceSearch} />
      </Box>

      <StyledList subheader={<li />} ref={listRef}>
        {!isLoading && list.length === 0 && (
          <StyledListItemContainer>
            <StyledListItem>
              <StyledListItemText>Your list is empty.</StyledListItemText>
            </StyledListItem>
          </StyledListItemContainer>
        )}
        {entries.map(([header, headerList]) => (
          <li key={`section-${header}`} style={{ backgroundColor: 'inherit' }}>
            <ul style={{ backgroundColor: 'inherit', padding: 0 }}>
              <StyledListSubheader id={header}>{header}</StyledListSubheader>
              {headerList.map((item, key) => (
                <StyledListItemContainer key={`item-${header}-${item.id}-${key}`}>
                  <StyledListItem button id={`${header}${key}`} onClick={() => handleContactClick(item.id)}>
                    <StyledListItemText
                      primary={!isLoading ? labelGetter(item) : <Skeleton width="100%" />}
                      secondary={!isLoading && subLabelGetter && subLabelGetter(item)}
                    />
                  </StyledListItem>
                  <Collapse in={selectedContact && selectedContact === item.id} mountOnEnter>
                    {expandedContent(item)}
                  </Collapse>
                </StyledListItemContainer>
              ))}
            </ul>
          </li>
        ))}
      </StyledList>
    </>
  );
};

ContactList.propTypes = {
  list: PropTypes.array,
  subHeaderGetter: PropTypes.func,
  searchBy: PropTypes.func,
  sortBy: PropTypes.func,
  labelGetter: PropTypes.func,
  subLabelGetter: PropTypes.func,
  expandedContent: PropTypes.any,
};

ContactList.defaultProps = {
  list: [],
  subHeaderGetter: row => row.name.substr(0, 1).replace(/\d|\W/i, '#'),
  sortBy: row => row.name,
  searchBy: row => row.name,
  labelGetter: row => row.name,
  subLabelGetter: undefined,
  expandedContent: null,
};

export default ContactList;
