import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Input, InputLabel, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const RescuersNumber = ({ classes, value, handleClick, showLabel, isMobile, inputId }) => {
  const handleRescuerNumberClick = (event) =>
    handleClick({
      event,
      value: value + 1,
    });

  return (
    <FormControl
      classes={{ root: classes.formControl }}
      onClick={event => (isMobile ? handleRescuerNumberClick(event) : null)}
    >
      {showLabel && (
        <InputLabel htmlFor={inputId} shrink>
          # of Rescuers needed:
        </InputLabel>
      )}
      <Input
        id={inputId}
        readOnly
        classes={{
          input: classes.input,
        }}
        value={value}
        endAdornment={
          <IconButton
            color="primary"
            size="small"
            aria-label="add rescuer"
            onClick={event => (isMobile ? null : handleRescuerNumberClick(event))}
          >
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
        }
      />
    </FormControl>
  );
};

RescuersNumber.defaultProps = {
  showLabel: false,
  isMobile: false,
  inputId: 'numrescuers-helper',
};

RescuersNumber.propTypes = {
  showLabel: PropTypes.bool,
  isMobile: PropTypes.bool,
  inputId: PropTypes.string,
};

const styles = () => ({
  formControl: {
    maxWidth: 166,
  },
  input: {
    textAlign: 'center',
  },
});
export default withStyles(styles)(RescuersNumber);
