import React from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import BaseMUIDataTable from '../../../../components/BaseMUIDataTable/BaseMUIDataTable';
import { formatCsvFileName } from '../../../../helpers/formatters';
import { getMuiTableDataIndex } from '../../../../helpers/getters';
import { getRescuePickupLocationFullName } from '../../../../helpers/RescuesHelper';
import TableActionsButtonsWrapper from '../../../../components/TableActionsButtonsWrapper';
import TableActionButton from '../../../../components/TableActionButton';
import { Colors } from '../../../../assets/theme/Colors';

const DonationRequestsTable = ({ tableId, donations, customToolbar, onEditClick }) => {
  const startDateCustomBodyRender = value => (value ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null);

  const frequencyCustomBodyRender = donation => {
    switch (donation.frequency) {
      case 0:
        return 'one-time';
      case 1:
        return donation.frequency_every === 1 ? 'weekly' : 'every other week';
      case 2:
        return 'monthly';
      default:
        return 'unknown';
    }
  };

  const statusCustomBodyRender = donation => {
    switch (donation.status) {
      case 'declined':
        return <span style={{ color: Colors.rescues.cancelled.color, fontWeight: 'bold' }}>Declined</span>;
      case 'pending':
        return <span style={{ color: Colors.rescues.unclaimed.color, fontWeight: 'bold' }}>Pending</span>;
      case 'approved':
        return <span style={{ color: Colors.rescues.claimed.color, fontWeight: 'bold' }}>Approved</span>;
      default:
        return donation.status;
    }
  };

  return (
    <BaseMUIDataTable
      tableId={tableId}
      title="Donation Requests"
      data={donations}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'slug',
          label: 'Description',
        },
        {
          name: 'frequency',
          label: 'Frequency',
          options: {
            customBodyRender: (value, tableMeta) => {
              const donation = donations[getMuiTableDataIndex(tableMeta)];

              return frequencyCustomBodyRender(donation);
            },
            customBodyRenderCSV: (value, tableMeta) => {
              const donation = donations[getMuiTableDataIndex(tableMeta)];

              return frequencyCustomBodyRender(donation);
            },
          },
        },
        {
          name: 'display_days',
          label: 'Days',
          options: {
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'start_date',
          label: 'Start Date',
          options: {
            sort: true,
            customBodyRender: value => startDateCustomBodyRender(value),
            customBodyRenderCSV: value => startDateCustomBodyRender(value),
          },
        },
        {
          name: 'status',
          label: 'Status',
          options: {
            customBodyRender: (value, tableMeta) => {
              const donation = donations[getMuiTableDataIndex(tableMeta)];

              return statusCustomBodyRender(donation);
            },
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            display: true,
            customBodyRender: (value, tableMeta) => (
              <TableActionsButtonsWrapper>
                <TableActionButton
                  buttonProps={{
                    'data-testid': 'edit-donation-request',
                    'row-id': donations[getMuiTableDataIndex(tableMeta)].id,
                  }}
                  disabled={donations[getMuiTableDataIndex(tableMeta)].status !== 'pending'}
                  title="Edit Donation Request"
                  onClick={() => onEditClick(donations[getMuiTableDataIndex(tableMeta)].id)}
                />
              </TableActionsButtonsWrapper>
            ),
          },
        },
      ]}
      options={{
        customToolbar: customToolbar,
        selectableRows: 'none',
        responsive: 'simple',
        downloadOptions: {
          filename: formatCsvFileName('Donations'),
        },
      }}
    />
  );
};

export default DonationRequestsTable;
