import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, generatePath } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Bluebird from 'bluebird';
import { Grid, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import * as sitesActions from '../actions/sites';
import DonorsLocationsTable from '../components/DonorsLocationsTable';
import routes from '../routes';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { withNotificationService } from '../context/NotificationsContext/NotificationServiceHOC';

class DonorsLocationsListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };
  }

  componentDidMount() {
    const { site, fetchSiteDonorsLocations, fetchSiteRescues } = this.props;
    fetchSiteDonorsLocations(site.id);
    fetchSiteRescues(moment().format('YYYYMMDD'), moment().add(3, 'months').endOf('month').format('YYYYMMDD'), site.id);
  }

  handleRowClick(donorLocationId) {
    const { history } = this.props;

    history.push(generatePath(routes.foodDonor, { foodDonorId: donorLocationId }));
  }

  handlePrimaryContactUserClick(userId) {
    const { history } = this.props;

    history.push(generatePath(routes.userEdit, { id: userId }));
  }

  handleDeleteClick(donorName, donorLocationId) {
    const { siteRescues, site, deleteFoodDonor, notificationService } = this.props;

    const donorRescues = siteRescues.bySiteId[site.id].filter((rescue) => rescue.location_id === donorLocationId);

    confirmAlert({
      title: 'Are you sure you want to do this?',
      message: `Deleting "${donorName}" will remove them from your site. If you're sure, please type "DELETE" into the field below and hit "Confirm".`,
      buttons: [
        {
          label: 'Cancel',
          color: 'primary',
        },
        {
          label: 'Confirm',
          color: 'primary',
          requireDeleteInput: true,
          onClick: () => {
            this.setState({ isDeleting: true });

            return Bluebird.try(() => deleteFoodDonor(site.id, donorLocationId))
              .then(() => this.setState({ isDeleting: false }))
              .then(() => notificationService.addSuccessNotification(`${donorName} food donor deleted successfully!`));
          },
        },
      ],
      customUI: ({ title, message, onClose, buttons }) => (
        <ConfirmationDialog
          buttons={buttons}
          closeDialog={onClose}
          title={title}
          message={message}
          secondStep={{
            display: !!donorRescues.length,
            message: 'This food donor is associated with upcoming rescues. Deleting this food donor will also delete these rescues.',
            tableData: donorRescues,
            tableTitle: `${donorName} upcoming rescues`,
          }}
        />
      ),
    });
  }

  renderCustomTableToolbar = () => {
    const { history } = this.props;
    return (
      <Button
        startIcon={<Add />}
        type="button"
        variant="contained"
        color="primary"
        size="small"
        onClick={() => history.push(routes.foodDonorAdd)}
      >
        Add New Food Donor
      </Button>
    );
  };

  render() {
    const { siteDonorsLocations, classes, site } = this.props;
    const { isDeleting } = this.state;

    if (siteDonorsLocations.inflight === undefined || siteDonorsLocations.inflight) {
      return <div>loading food donors</div>;
    }

    return (
      <>
        <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
          <Typography color="textPrimary">Food Donors</Typography>
        </Breadcrumbs>

        <Grid container>
          <Grid item xs={12}>
            <DonorsLocationsTable
              isLoading={isDeleting}
              tableId="#donors-locations/donors-table"
              donorsLocations={siteDonorsLocations.bySiteId[site.id]}
              customToolbar={this.renderCustomTableToolbar}
              onRowClick={(donorId, donorLocationId) => this.handleRowClick(donorLocationId)}
              onDeleteClick={(donorName, donorLocationId) => this.handleDeleteClick(donorName, donorLocationId)}
              onPrimaryContactUserClick={userId => this.handlePrimaryContactUserClick(userId)}
              customFilters={{ showPendingRegistrationFilter: true }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({
  app: { site },
  entities: {
    sites: { donorsLocations, rescues },
  },
}) => ({
  site,
  siteDonorsLocations: donorsLocations,
  siteRescues: rescues,
});
const mapDispatchToProps = dispatch => ({
  fetchSiteDonorsLocations: siteId => dispatch(sitesActions.fetchSiteDonorsLocationsIfNeeded(siteId)),
  fetchSiteRescues: (from, to, siteId) => dispatch(sitesActions.fetchSiteRescues(from, to, siteId)),
  deleteFoodDonor: (siteId, foodDonorId) => dispatch(sitesActions.deleteSiteFoodDonor(siteId, foodDonorId)),
});
const styles = theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withNotificationService(DonorsLocationsListView)))
);
