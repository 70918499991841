import moment from 'moment';

const arrayToObject = (array, key) =>
  array.reduce((obj, item) => {
    obj[key(item)] = item;
    return obj;
  }, {});

export const objectFromArray = (array, key) => {
  return arrayToObject(array, key);
};

export const objectFromArrayWithDup = (array, key) =>
  array.reduce((obj, item) => {
    if (key(item) in obj) {
      obj[key(item)].push(item);
    } else {
      obj[key(item)] = [item];
    }
    return obj;
  }, {});

export const diff = (o1, o2) =>
  Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key],
    };
  }, {});

export const removeNullKeys = obj =>
  Object.keys(obj)
    .filter(k => obj[k] !== null && obj[k] !== undefined) // Remove undef. and null.
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object' && !(obj[k] instanceof Array)
          ? Object.assign(newObj, { [k]: removeNullKeys(obj[k]) }) // Recurse.
          : Object.assign(newObj, { [k]: obj[k] }), // Copy value.
      {}
    );

export const isUndefinedOrNull = val => typeof val === 'undefined' || val === null;

/**
 * @param start, format: HH:mm:ss
 * @param stop, format: HH:mm:ss
 *
 */
export const startEndTimeValidator = (start, stop) => {
  const begin = moment(start, 'HH:mm:ss');
  const end = moment(stop, 'HH:mm:ss');

  const isBeginValid = begin.isBefore(end);
  const isEndValid = end.isAfter(begin);
  return {
    begin: {
      isValid: isBeginValid,
      message: isBeginValid ? null : 'Start time must be before end time',
    },
    end: {
      isValid: isEndValid,
      message: isEndValid ? null : 'End time must be after start time',
    },
  };
};

/**
 * @deprecated use dates.formatDateToMonthName
 * @param monthNo
 * @param yearNo
 */
export const monthNumberToName = (monthNo, yearNo) => moment((new Date(yearNo, monthNo, 1, 0,0,0))).format('MMMM YYYY')

/**
 * @deprecated use dates.formatDateToWeekName
 * @param weekNo
 * @param yearNo
 */
export const weekNumberToDayOfMonth = (weekNo, yearNo) => {
  const week = weekNo.toString().padStart(2, '0');
  return moment(`${week} ${yearNo}`, 'ww gggg').format('MM/DD YYYY');
}
