import React, { useEffect, useState, useContext } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EventDetails from '../Components/EventDetails';
import { fetchEvent, receiveDeleteEventShiftRegistration, receiveEventShift, updateEvent } from '../../../actions/events';
import { Grid, Breadcrumbs, Typography, Box } from '@material-ui/core';
import Bluebird from 'bluebird';
import { createEventShiftRegistration, deleteRescuerEventShiftRegistration } from '../../../api/events';
import useActiveUser from '../../../hooks/useActiveUser';
import useNotificationService from '../../../hooks/useNotificationService';
import errorMessages from '../../../assets/errorMessages';
import routes from '../../../routes';
import { invalidateRescuerEvents } from '../../../actions/rescuers';
import { get } from 'lodash';
import FABContext from '../../../context/FABContext/FABContext';
import useActiveSite from '../../../hooks/useActiveSite';
import { adoptSiteEvent, deleteEventAdoption } from '../../../api/sites';

const EventRescuerDetailsView = ({ match, history }) => {
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const notificationService = useNotificationService();
  const dispatch = useDispatch();
  const fabContext = useContext(FABContext);
  const events = useSelector((state) => state.entities.events);
  const persistedDashboard = useSelector(state => state.ui.persisted.defaultDashboard);
  const { eventId } = match.params;
  const event = events.byId[eventId];
  const [submittingShift, setIsSubmittingShift] = useState(false);

  useEffect(() => {
    Bluebird.try(() => dispatch(fetchEvent(eventId))).catch((error) => {
      switch (error.code) {
        case errorMessages.ERR_EVENT_NOT_FOUND.code:
          notificationService.addErrorNotification(error, errorMessages.ERR_EVENT_NOT_FOUND.message);
          return history.push(routes.index);
        default:
          notificationService.addErrorNotification(error, error.message);
          return history.push(routes.index);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    // move FAB above bottom tabs
    fabContext.updateFABStyles({
      speedDial: {
        display: 'none',
      },
    });

    // reset FAB styles on component unmount
    return () => fabContext.updateFABStyles({});
  }, []);

  const handleShiftButtonClick = (shift) => {
    setIsSubmittingShift(shift.id);

    const shiftEventRegistration = get(shift, 'registrations', []).find(
      (r) => r.deleted_at === null && r.user_id === activeUser.id
    );

    if (shiftEventRegistration) {
      return Bluebird.try(() =>
        deleteRescuerEventShiftRegistration(
          shiftEventRegistration.user_id,
          eventId,
          shift.id,
        )
      )
        .then(() => dispatch(fetchEvent(eventId)))
        .then(() => dispatch(receiveDeleteEventShiftRegistration(eventId, shift.id, shiftEventRegistration.id)))
        .then(() => dispatch(invalidateRescuerEvents(activeUser.id))) //forces events re-fetch on rescuer dashboard page
        .catch((error) => notificationService.addErrorNotification(null, error.message))
        .finally(() => setIsSubmittingShift(false));
    }

    return (
      Bluebird.try(() =>
        createEventShiftRegistration(eventId, shift.id, {
          user_id: activeUser.id,
          created_by_id: activeUser.id,
        })
      )
        .then((res) => res.json())
        .then((res) => res.data)
        .then(() => dispatch(fetchEvent(eventId)))
        .then(() => dispatch(invalidateRescuerEvents(activeUser.id))) //forces events re-fetch on rescuer dashboard page
        .catch((error) => {
          switch (error.code) {
            case errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.code:
              notificationService.addNotification(errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.message, 'info');
              break;
            case errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.code:
              notificationService.addNotification(
                errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.message,
                'info'
              );
              break;
            default:
              notificationService.addErrorNotification(error, error.message);
              break;
          }
        })
        .finally(() => setIsSubmittingShift(false))
    );
  };

  const handleAdoptClick = (eventSpecsId, rescuerId, shift, createdById, eventSpecs, index) => {
    setIsSubmittingShift(shift.id);

    const shiftEventRegistration = get(eventSpecs, `shifts[${index}].registrations`, []).find(
      (r) => r.deleted_at === null && r.user_id === activeUser.id
    );

    if (shiftEventRegistration) {
      return Bluebird.try(() =>
        deleteEventAdoption(activeSite.id, eventSpecsId, {
          event_shift_spec_id: shift.id,
          user_id: rescuerId,
          created_by_id: createdById,
        })
      )
        .then(() => dispatch(receiveDeleteEventShiftRegistration(eventId, shift.id, shiftEventRegistration.id)))
        .then(() => dispatch(fetchEvent(eventId)))
        .then(() => dispatch(invalidateRescuerEvents(activeUser.id))) //forces events re-fetch on rescuer dashboard page
        .catch((error) => notificationService.addErrorNotification(null, error.message))
        .finally(() => setIsSubmittingShift(false));
    }

    return Bluebird.try(() =>
      adoptSiteEvent(activeSite.id, eventSpecsId, {
        event_shift_spec_id: shift.id,
        user_id: rescuerId,
        created_by_id: createdById,
      })
    )
      .then(() => dispatch(fetchEvent(eventId)))
      .then(() => dispatch(invalidateRescuerEvents(activeUser.id))) //forces events re-fetch on rescuer dashboard page
      .catch((error) => {
        switch (error.code) {
          case errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.code:
            notificationService.addNotification(errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.message, 'info');
            break;
          case errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.code:
            notificationService.addNotification(
              errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.message,
              'info'
            );
            break;
          default:
            notificationService.addErrorNotification(error, error.message);
            break;
        }
      })
      .finally(() => setIsSubmittingShift(false));
  };

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'scroll',
        width: '100%',
      }}
    >
      <Breadcrumbs aria-label="Breadcrumbs">
        <Link to={persistedDashboard ? generatePath(persistedDashboard) : generatePath(routes.index)} color="inherit">
          Events
        </Link>

        <Typography color="textPrimary">Event Details</Typography>
      </Breadcrumbs>

      <Grid>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <EventDetails
            isLoading={events.inflight || !event}
            submittingShift={submittingShift}
            eventData={event}
            onShiftButtonClick={handleShiftButtonClick}
            onAdoptClick={handleAdoptClick}
            testId="rescuer-event-details"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventRescuerDetailsView;
