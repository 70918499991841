import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Typography } from '@material-ui/core';
import routes from '../routes';
import * as sitesActions from '../actions/sites';
import * as zipCodesActions from '../actions/zipCodes';
import SiteForm from '../components/SiteForm';
import OverlayLoader from '../components/OverlayLoader';

class SiteAddView extends Component {
  constructor(props) {
    super(props);

    this.onCreateSite = this.onCreateSite.bind(this);
  }

  async onCreateSite(data) {
    const { history, createSite } = this.props;

    try {
      await createSite(data);

      history.push(routes.sites);
    } catch (e) {
      throw e;
    }
  }

  render() {
    const { fetchZipCodes, users, classes } = this.props;
    const isLoading = users.inflight;

    return (
      <>
        <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumbs">
          <Link color="inherit" to={routes.sites}>
            Sites
          </Link>

          <Typography color="textPrimary">Add a Site</Typography>
        </Breadcrumbs>

        <OverlayLoader isLoading={isLoading}>
          <SiteForm fetchZipCodes={fetchZipCodes} submitCallback={this.onCreateSite} />
        </OverlayLoader>
      </>
    );
  }
}

const mapStateToProps = ({ entities: { users } }) => ({
  users,
});
const mapDispatchToProps = dispatch => ({
  fetchZipCodes: zipCode => dispatch(zipCodesActions.fetchZipCodesIfNeeded(zipCode)),
  createSite: site => dispatch(sitesActions.createSite(site)),
});
const styles = theme => ({
  breadcrumbs: {
    padding: theme.spacing(1),
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SiteAddView);
