import React, { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RescuerEventsDesktopTable from './Components/RescuerEventsDesktopTable';
import routes from '../../../../routes';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';

const EventsDesktop = ({ history }) => {
  const dispatch = useDispatch();
  const events = useSelector(state => state.entities.events);
  const { fetchEventsTab } = useRescuerDashboardActions();

  const handleRowClick = eventId => history.push(generatePath(routes.eventSignup, { eventId: eventId }));

  useEffect(() => {
    fetchEventsTab(false, false);
  }, [dispatch, fetchEventsTab]);

  return (
    <RescuerEventsDesktopTable
      tableId="rescuer#desktop-events"
      onRowClick={handleRowClick}
      eventsList={Object.values(events.byId)}
    />
  );
};

export default EventsDesktop;
