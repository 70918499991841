import React from 'react';
import { CardHeader, CardContent, Typography } from '@material-ui/core';
import RescuerUpcomingRescuesTable from '../../components/RescuerUpcomingRescuesTable';
import { DashboardTableCard } from '../../components/Dashboard/DashboardTableCard';

const RescuerUpcomingRescues = ({
  upcomingRescues,
  handleRescueUnclaim,
  handleRescueRelease,
  user,
  isLoading = false,
  onCellClick,
  upcomingRescuesTitle = 'Showing all of your upcoming rescues.',
  noUpcomingRescuesText = 'You have no upcoming rescues.',
}) => {

  return (
    <DashboardTableCard isLoading={isLoading}>
      {upcomingRescues.length > 0 && (
        <CardHeader
          subheader={
            <Typography variant="body2" color="textSecondary">
              {upcomingRescuesTitle}
            </Typography>
          }
        />
      )}

      <CardContent>
        {isLoading && <Typography>fetching</Typography>}
        {upcomingRescues.length === 0 && !isLoading && (
          <Typography color="textSecondary" variant="body1">
            {noUpcomingRescuesText}
          </Typography>
        )}
        {upcomingRescues.length > 0 && !isLoading && (
          <RescuerUpcomingRescuesTable
            isLoading={isLoading}
            tableId="#rescuer-dashboard-desktop/upcoming-rescues"
            rescues={upcomingRescues}
            user={user}
            onUnclaimRescueButtonClick={handleRescueUnclaim}
            onReleaseRescueButtonClick={handleRescueRelease}
            onCellClick={onCellClick}
          />
        )}
      </CardContent>
    </DashboardTableCard>
  );
};

export default RescuerUpcomingRescues;
