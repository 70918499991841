import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ReactSelect from 'react-select';
import { getChromaColor } from '../../../helpers/colors';

const colourStyles = {
  container: provided => ({
    ...provided,
    maxWidth: 400,
  }),
  valueContainer: styles => ({ ...styles, minHeight: 50, minWidth: 200 }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: getChromaColor(data.color).backgroundColor,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: getChromaColor(data.color).color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const MapSiteSelector = ({ isLoading = false, selectedSite, sitesList, handleSiteChange }) => {
  return (
    <Box boxShadow={25} p={2} bgcolor="background.paper">
      <Typography gutterBottom variant="subtitle2" color="textSecondary">
        Sites
      </Typography>
      <Box>
        <ReactSelect
          styles={colourStyles}
          isDisabled={isLoading}
          placeholder="Select site(s) or leave blank to show data for all sites"
          value={selectedSite}
          options={sitesList}
          isMulti
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          onChange={handleSiteChange}
        />
      </Box>
    </Box>
  );
};

export default MapSiteSelector;
