import React, { useRef, useState } from 'react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { Box, Grid, IconButton, Typography, Divider } from '@material-ui/core';
import { ViewWeek as ViewWeekIcon, ViewComfy as ViewComfyIcon } from '@material-ui/icons';
import moment from 'moment';
import { isSafari } from 'react-device-detect';
import { Colors } from '../../../../../assets/theme/Colors';
import HtmlTooltip from '../../../../../components/Common/HtmlTooltip';
import '../Calendar/calendarStyles.css';
import { AddressLine } from '../../../../Rescue/RescueMobileCard';
import { formatTime } from '../../../../../helpers/formatters';
import OverlayLoader from '../../../../../components/OverlayLoader';
import CalendarBox from './CalendarBox';
import { getRescuePickupLocationFullName } from '../../../../../helpers/RescuesHelper';
import { DONATION_TYPE_REQUEST, DONATION_TYPE_SD } from '../../../../../actions/donationNew';
export const VIEW_DAY_GRID_MONTH = 'dayGridMonth';
export const VIEW_DAY_GRID_WEEK = 'dayGridWeek';

const EventLegend = ({ text, styles = {} }) => (
  <Box
    css={{
      ...styles,
      padding: '2px 8px',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Typography variant="caption"> {text}</Typography>
  </Box>
);

const eventContentRender = donationType => ({ event }) => {
  if (donationType === DONATION_TYPE_REQUEST) {
    return (
      <CalendarBox
        isCancelled={false}
        noReceiver={false}
        statusColor={Colors.donation.pickups.new}
        claimColor={Colors.donation.pickups.new}
      />
    );
  }

  const {
    extendedProps: { meta: rescue, statusColor, claimColor, isCancelled, noReceiver },
  } = event;

  const renderTooltip = ({ rescueTime, donorLocation, receiverLocation }) => (
    <Box pl={2} display="flex" flexDirection="column" pr={2}>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2">{rescueTime}</Typography>
      </Box>

      <Box display="flex" py={1}>
        <AddressLine />
        <Box display="flex" flexDirection="column" flexGrow={1} pl={1}>
          <Box my={0.5}>
            <Typography variant="body1">{donorLocation}</Typography>
          </Box>
          <Box my={0.5}>
            <Divider />
          </Box>
          <Box my={0.5}>
            <Typography variant="body1">{receiverLocation || 'N/A'}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <HtmlTooltip
      withBorder
      title={renderTooltip({
        rescueTime: `${formatTime(rescue.pickup_begin.value)} - ${formatTime(rescue.pickup_end.value)}`,
        receiverLocation: rescue.receiver ? rescue.receiver.name : 'N/A',
        donorLocation: rescue.food_donor
          ? getRescuePickupLocationFullName({
              pickup_location_name: rescue.food_donor.pickup_location.name,
              location: rescue.food_donor.name,
            })
          : 'N/A',
      })}
    >
      <CalendarBox
        isCancelled={isCancelled}
        noReceiver={noReceiver}
        statusColor={statusColor}
        claimColor={claimColor}
      />
    </HtmlTooltip>
  );
};

const Monthly = ({ isLoading, handleDaySelect, events, donationType }) => {
  const apiRef = useRef();
  const [currentView, setCurrentView] = useState(VIEW_DAY_GRID_MONTH);

  const onViewSwitch = () => {
    if (!apiRef.current) {
      return null;
    }
    const api = apiRef.current.getApi();
    const newView = api.view.type === VIEW_DAY_GRID_MONTH ? VIEW_DAY_GRID_WEEK : VIEW_DAY_GRID_MONTH;
    setCurrentView(newView);
    api.changeView(newView);
  };

  const onEventLick = ({ event }) => {
    if (!apiRef.current) {
      return null;
    }

    const api = apiRef.current.getApi();
    const selectedRange = {
      start: moment(`${event.extendedProps.meta.date.value} 00:00:00`).toDate(),
      end: moment(`${event.extendedProps.meta.date.value} 00:00:00`)
        .add(1, 'day')
        .toDate(),
    };
    handleDaySelect(selectedRange);
    api.select(selectedRange);
  };

  return (
    <Grid container direction="column" justify="center" alignItems="stretch" spacing={1}>
      <Grid item xs>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          {[DONATION_TYPE_REQUEST].includes(donationType) && (
            <Box display="grid" gridAutoFlow="row" gridRowGap={5}>
              <Box display="grid" gridAutoFlow="column" gridColumnGap={5}>
                <EventLegend
                  styles={{
                    backgroundColor: Colors.donation.pickups.new.color,
                    color: Colors.donation.pickups.new.contrastText,
                    border: `1px solid #000`,
                  }}
                  text="New"
                />
              </Box>
            </Box>
          )}
          {[DONATION_TYPE_SD].includes(donationType) && (
            <Box display="grid" gridAutoFlow="row" gridRowGap={5}>
              <Box display="grid" gridAutoFlow="column" gridColumnGap={5}>
                <EventLegend
                  styles={{
                    backgroundColor: Colors.donation.pickups.nonModified.color,
                    color: Colors.donation.pickups.nonModified.contrastText,
                    border: `1px solid #000`,
                  }}
                  text="Existing Schedule"
                />
                <EventLegend
                  styles={{
                    backgroundColor: Colors.donation.pickups.new.color,
                    color: Colors.donation.pickups.new.contrastText,
                    border: `1px solid #000`,
                  }}
                  text="New"
                />
                <EventLegend
                  styles={{
                    backgroundColor: Colors.donation.pickups.modified.color,
                    color: Colors.donation.pickups.modified.contrastText,
                    border: `1px solid #000`,
                  }}
                  text="Modified"
                />
                <EventLegend
                  styles={{
                    backgroundColor: Colors.donation.pickups.deleted.color,
                    color: Colors.donation.pickups.deleted.contrastText,
                    border: `1px solid #000`,
                  }}
                  text="Deleted"
                />
              </Box>

              <Box display="flex" flexDirection="row" gridColumnGap={5}>
                <EventLegend
                  styles={{
                    backgroundColor: Colors.rescues.claimed.color,
                    color: Colors.rescues.claimed.contrastText,
                    border: `1px solid #000`,
                  }}
                  text="Claimed"
                />
                <EventLegend
                  styles={{
                    backgroundColor: Colors.rescues.unclaimed.color,
                    color: Colors.rescues.unclaimed.contrastText,
                    border: `1px solid #000`,
                  }}
                  text="Not Claimed"
                />

                <EventLegend
                  styles={{
                    backgroundColor: '#fff',
                    color: Colors.rescues.cancelled.color,
                    border: `1px solid #000`,
                    background:
                      'linear-gradient(to bottom left, transparent calc(50% - 2px), red calc(50% - 1px), red calc(50% + 1px), transparent calc(50% + 2px)), linear-gradient(to bottom right, transparent calc(50% - 2px), red calc(50% - 1px), red calc(50% + 1px), transparent calc(50% + 2px))',
                  }}
                  text="Cancelled"
                />

                <EventLegend
                  styles={{
                    backgroundColor: '#fff',
                    color: Colors.rescues.noReceiver.color,
                    border: `1px solid #000`,
                    background: `linear-gradient(to bottom right, transparent calc(50% - 2px), ${Colors.rescues.noReceiver.color} calc(50% - 1px), ${Colors.rescues.noReceiver.color} calc(50% + 1px), transparent calc(50% + 2px))`,
                  }}
                  text="No receiver"
                />
              </Box>
            </Box>
          )}
          <IconButton onClick={onViewSwitch}>
            {currentView === VIEW_DAY_GRID_MONTH ? <ViewWeekIcon /> : <ViewComfyIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs>
        <OverlayLoader isLoading={isLoading} wrapperStyles={{ width: '100%' }}>
          <FullCalendar
            ref={apiRef}
            // dayMaxEvents={2}
            eventContent={eventContentRender(donationType)}
            // selectAllow={({ start, end }) => end - start === 86400000} //single day select only
            dayCellClassNames={({ view: { type } }) =>
              type === VIEW_DAY_GRID_MONTH ? 'monthly-tiles' : 'monthly-tiles weekly-tiles'
            }
            eventMouseEnter={info => {
              const { event } = info;
              const element = document.querySelector(`[data-eventid="${event.id}"]`);

              if (!element) {
                return null;
              }
              element.style.backgroundColor = '#f5f5f5';
              // element.style.color = '#fff';
            }}
            eventMouseLeave={({ event }) => {
              const element = document.querySelector(`[data-eventid="${event.id}"]`);
              if (!element) {
                return null;
              }

              element.style.backgroundColor = '';
              element.style.color = '';
            }}
            plugins={[interactionPlugin, dayGridPlugin]}
            initialView={currentView}
            selectable
            stickyHeaderDates={!isSafari}
            eventOrder={(prev, next) => prev - next}
            eventClick={onEventLick}
            select={handleDaySelect}
            // unselect={handleDaySelect}
            selectAllow={({ start, end }) => end - start === 86400000} //single day select only
            events={events}
          />
        </OverlayLoader>
      </Grid>
    </Grid>
  );
};

export default Monthly;
