export const FREQUENCY_ONCE = 0;
export const FREQUENCY_WEEKLY = 1;
export const FREQUENCY_MONTHLY = 2;
export const FREQUENCY_EVERY_OTHER_WEEK = 3;

export const FREQUENCY_WEEKLY_EVERY_WEEK = 1;
export const FREQUENCY_WEEKLY_EVERY_TWO_WEEKS = 2;

export const SCHEDULE_STATUS_NOT_MODIFIED = 'NOT_MODIFIED';
export const SCHEDULE_STATUS_NEW = 'NEW';
export const SCHEDULE_STATUS_MODIFIED = 'MODIFIED';
export const SCHEDULE_STATUS_DELETED = 'DELETED';

export const CLAIM_STATUS_NOT_CLAIMED = 'NOT_CLAIMED';
export const CLAIM_STATUS_CLAIMED = 'CLAIMED';
