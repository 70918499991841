import React from 'react';
import { FormControlLabel, FormLabel, FormGroup, Checkbox, FormControl, FormHelperText } from '@material-ui/core';
import DisabledDayFrequencyTooltip from '../../../../../components/DonationManage/Schedule/DisabledDayFrequencyTooltip';
import { daysList } from '../../../../../helpers/RescuesHelper';

const DaysOfWeekSelector = ({ onChange, dow, error }) => (
  <FormControl component="fieldset" error={!!error} data-testid="days-of-week-selector">
    <FormLabel component="legend">Days of the Week</FormLabel>
    <FormGroup row>
      {daysList.map((d, dayIndex) => {
        const dayName = d.toLowerCase();
        const isDisabled = (dow[dayName] ? dow[dayName].allow_uncheck : true) === false;
        return (
          <DisabledDayFrequencyTooltip key={d} disabled={isDisabled}>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid={`dow_${dayName}`}
                  name={dayName}
                  disabled={isDisabled}
                  checked={dow[dayName] ? dow[dayName].checked : false}
                  onChange={onChange}
                  value={dayIndex}
                />
              }
              label={d}
            />
          </DisabledDayFrequencyTooltip>
        );
      })}
    </FormGroup>
    {error && (
      <FormHelperText data-testid="days-of-week-error" id="days_of_week_error" error>
        {error.message}
      </FormHelperText>
    )}
  </FormControl>
);

export default DaysOfWeekSelector;
