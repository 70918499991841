import React from 'react';
import { useRouteMatch, Link, generatePath, Route, Switch } from 'react-router-dom';
import { Box, Typography, makeStyles } from '@material-ui/core';
import routes from '../routes';
import RescueReport from './RescueReportView';
import ReceiverReport from './ReceiverReportView';
import RescuedFoodReport from './RescuedFoodReportView';
import GrowthReport from './GrowthReportView';
import VolumeReport from './VolumeReportView';
import QuicksightReports from './QuicksightReportsView';
import { Roles } from '../models/roles';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../services/auth';
import { Colors } from '../assets/theme/Colors';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: Colors.gray,
    paddingLeft: 10,
  },
  activeLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    paddingLeft: 10,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -3,
      top: 0,
      height: '100%',
      borderLeft: '4px solid',
      borderColor: theme.palette.primary.main,
    },
  },
  reportsContainer: {
    width: 'calc(100% - 170px)',
  },
}));

const reportsList = [
  {
    name: 'Rescue',
    component: RescueReport,
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    path: generatePath(routes.reports, { tab: 'rescue' }),
    tab: 'rescue',
  },
  {
    name: 'Receiving Agency',
    component: ReceiverReport,
    roles: [
      Roles.Admin,
      Roles.NationalSiteDirector,
      Roles.SiteDirector,
      Roles.SiteCoordinator,
      Roles.DonorAdmin,
      Roles.DonorStaff,
      Roles.ReceiverLocationAdmin,
    ],
    path: generatePath(routes.reports, { tab: 'receiving-agency' }),
    tab: 'receiving-agency',
  },
  {
    name: 'Rescued Food',
    component: RescuedFoodReport,
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    path: generatePath(routes.reports, { tab: 'rescued-food' }),
    tab: 'rescued-food',
  },
  {
    name: 'Growth',
    component: GrowthReport,
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    path: generatePath(routes.reports, { tab: 'growth' }),
    tab: 'growth',
  },
  {
    name: 'Volume',
    component: VolumeReport,
    roles: [Roles.Admin, Roles.NationalSiteDirector, Roles.SiteDirector, Roles.SiteCoordinator],
    path: generatePath(routes.reports, { tab: 'volume' }),
    tab: 'volume',
  },
  {
    name: 'Quicksight Dashboard',
    component: QuicksightReports,
    roles: [Roles.Admin, Roles.NationalSiteDirector],
    path: generatePath(routes.reports, { tab: 'quicksight-dashboard' }),
    tab: 'quicksight-dashboard',
  },
];

const ReportsView = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();

  const reports = reportsList.filter((report) => currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite(report.roles));
  const paths = reports.map((report) => report.path);

  const getActiveTab = () => {
    const path = paths.indexOf(url);

    return Math.max(path, 0);
  };

  return (
    <>
      <Typography variant="h5">Reports</Typography>
      <Box mt={5} display="flex" gridGap="16px">
        <Box className={classes.subMenu} minWidth="max-content">
          {reports.map((report, index) => (
            <Typography variant="subtitle2" key={report.name} gutterBottom>
              <Link
                className={index !== getActiveTab() ? classes.link : classes.activeLink}
                to={report.path}
                key={report.name}
              >
                {report.name}
              </Link>
            </Typography>
          ))}
        </Box>
        <Box className={classes.reportsContainer}>
          <Switch>
            {reports.map((report, index) => (
              <Route
                exact
                path={index === 0 ? [generatePath(routes.reports), report.path] : report.path}
                component={report.component}
                key={report.name}
              />
            ))}
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default ReportsView;
