import React, { Component } from 'react';
import { Field } from 'react-final-form';
import SelectMultiple from './SelectMultiple';
import { InputLabel } from '@material-ui/core';
import Bluebird from 'bluebird';
import * as zipCodesApi from '../api/zipCodes';
import { getZipCodeSelectOption } from '../helpers/getters';

class SelectZipCode extends Component {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      loadSuggestionsMinChars: 2,
      selectedZipCode: value ? { label: value, value: value } : null,
      zipCodePlaceholder: 'Select zip code',
    };
  }

  handleChangeZipCode = value => {
    const { onZipCodeChange } = this.props;

    this.setState({
      selectedZipCode: value,
    });

    if (onZipCodeChange) {
      onZipCodeChange(value);
    }
  };

  loadingMessage = value => {
    const { loadSuggestionsMinChars } = this.state;

    if (value.inputValue.length <= loadSuggestionsMinChars) {
      return null;
    }

    return 'Loading...';
  };

  loadZipCodes = value => {
    const { loadSuggestionsMinChars } = this.state;

    if (value.length <= loadSuggestionsMinChars) {
      return new Promise(resolve => {
        resolve([]);
      });
    }

    return Bluebird.try(() => zipCodesApi.getZipCodes(value))
      .then(res => res.json())
      .then(res => res.data.map(zipCode => getZipCodeSelectOption(zipCode)));
  };

  componentDidMount() {
    const { selectedZipCode } = this.state;
    const { userZipCode = null } = this.props;

    if (!selectedZipCode && userZipCode) {
      Bluebird.try(() => zipCodesApi.getZipCodes(userZipCode))
        .then((res) => res.json())
        .then((res) => res.data.map((zipCode) => getZipCodeSelectOption(zipCode)))
        .then((data) => this.handleChangeZipCode(data[0]));
    }
  }

  render() {
    const { selectedZipCode, zipCodePlaceholder } = this.state;
    const { fieldName = 'zip_code', inputId = 'zip-code-select' } = this.props;

    return (
      <div>
        <InputLabel shrink htmlFor={inputId}>
          Zip code
        </InputLabel>
        <Field
          name={fieldName}
          render={({ input, meta }) => {
            return (
              <SelectMultiple
                meta={meta}
                name={input.name}
                inputId={inputId}
                value={selectedZipCode}
                loadingMessage={this.loadingMessage}
                loadOptions={this.loadZipCodes}
                onChange={value => {
                  input.onChange(value !== null ? value.value : null);
                  return this.handleChangeZipCode(value);
                }}
                selectPlaceholder={zipCodePlaceholder}
                onMenuOpen={() => {
                  this.setState({
                    zipCodePlaceholder: 'Type the 3 first digits of the zip code',
                  });
                }}
                onBlur={input.onBlur}
                onMenuClose={() => {
                  this.setState({
                    zipCodePlaceholder: 'Select zip code',
                  });
                }}
                isMulti={false}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default SelectZipCode;
