import queryString from 'query-string';
import { apiGET } from './api';

export const getAggregates = (fact, dimension, granularity, dateRange) => {
  const params = {
    fact,
    dimension,
    granularity,
  };

  if (dateRange) {
    params.dateRange = dateRange;
  }

  const query = queryString.stringify(params);
  return apiGET(`/aggregates?${query}`);
};