import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

export const MapMenuPanelContainer = withStyles(() => ({
  root: {
    minWidth: 250,
    maxWidth: 300,
    position: 'absolute',
    top: 70,
    right: 10,
    zIndex: 5,
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: 16,
  },
}))(Box);
