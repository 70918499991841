import React, { useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Bluebird from 'bluebird';
import { Delete as DeleteIcon } from '@material-ui/icons';
import routes from '../routes';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import ConfirmationDialog from './ConfirmationDialog';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import { getMuiTableDataIndex } from '../helpers/getters';
import { formatCsvFileName } from '../helpers/formatters';

const UnassignedFoodDonorsTable = ({ isLoading, unassignedFoodDonors, showDeleteDonorButton, onFoodDonorDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <BaseMUIDataTable
      tableId="#foodDonors/unassigned-food-donors"
      title="Unassigned Food Donors"
      isLoading={isLoading || isDeleting}
      data={unassignedFoodDonors}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            display: false,
          },
        },
        {
          name: 'name',
          label: 'Name',
          options: {
            filter: false,
          },
        },
        {
          name: 'phone',
          label: 'Phone',
          options: {
            filter: false,
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'title',
          label: 'Title',
          options: {
            filter: false,
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'store_id',
          label: 'Store Id',
          options: {
            filter: false,
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'zip_code',
          label: 'Zip Code',
          options: {
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'city',
          label: 'City',
          options: {
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'state',
          label: 'State',
          options: {
            customBodyRender: value => value || '-',
          },
        },
        {
          name: 'actions',
          label: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              const foodDonor = unassignedFoodDonors[getMuiTableDataIndex(tableMeta)];

              return (
                <TableActionsButtonsWrapper>
                  {showDeleteDonorButton && (
                    <>
                      <TableActionButton
                        title="Edit Food Donor"
                        buttonProps={{
                          component: Link,
                          to: generatePath(routes.unassignedFoodDonorEdit, { id: tableMeta.rowData[0] }),
                        }}
                      />

                      <TableActionButton
                        title="Delete Food Donor"
                        icon={DeleteIcon}
                        onClick={() => {
                          confirmAlert({
                            title: 'Delete this Food Donor?',
                            message: 'Are you sure you want to delete this food donor?',
                            buttons: [
                              {
                                label: 'No',
                                color: 'primary',
                              },
                              {
                                label: 'Yes',
                                color: 'primary',
                                onClick: () => {
                                  setIsDeleting(true);

                                  return Bluebird.try(() => onFoodDonorDelete(foodDonor)).then(() =>
                                    setIsDeleting(false)
                                  );
                                },
                              },
                            ],
                            customUI: ({ title, message, onClose, buttons }) => (
                              <ConfirmationDialog
                                buttons={buttons}
                                closeDialog={onClose}
                                title={title}
                                message={message}
                              />
                            ),
                          });
                        }}
                      />
                    </>
                  )}
                </TableActionsButtonsWrapper>
              );
            },
          },
        },
      ]}
      options={{
        responsive: 'simple',
        selectableRows: 'none',
        downloadOptions: {
          filename: formatCsvFileName('Unassigned Food Donors'),
        },
      }}
    />
  );
};

export default UnassignedFoodDonorsTable;
