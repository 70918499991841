import React from 'react';
import { Field } from 'react-final-form';
import { merge } from 'lodash';

const AddressField = (props) => {
  const newProps = merge({
    InputProps: {
      inputProps: {
        autoComplete: 'chrome-off',
      },
    }
  }, props);

  return (
    <Field {...newProps} />
  );
};

export default AddressField;
