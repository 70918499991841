import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

// https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

const OverlaySpinner = ({ isLoading, children }) => {
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      fadeSpeed={200}
      styles={{
        spinner: base => ({
          ...base,
          '& svg circle': {
            stroke: '#f50057',
          },
        }),
        overlay: base => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.2)',
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default OverlaySpinner;
