import badgesReducer from './badges';
import donorsReducer from './donors';
import umbrellaFoodDonorsReducer from './umbrellaFoodDonors';
import foodDonorsReducer from './foodDonors';
import receiversReducer from './receivers';
import rescuersReducer from './rescuers';
import usersReducer from './users';
import donationsReducer from './donations';
import rescuesReducer from './rescues';
import notificationsReducer from './notifications';
import sitesReducer from './sites';
import zipCodesReducer from './zipCodes';
import settingsReducer from './settings';
import systemSettingsReducer from './systemSettings';
import featureFlagsReducer from './featureFlags';
import rescueSizesReducer from './rescueSizes';
import DonationSizesReducer from './donationSizes';
import requestDonationsReducer from './requestDonations';
import userRegistrationsReducer from './userRegistrations';
import rescuerLocationsReducer from './rescuerLocations';
import unitsReducer from './units';
import foodDescriptionsReducer from './foodDescriptions';
import foodDescriptionUnitLbsReducer from './foodDescriptionUnitLbs';
import rescuedFoodReducer from './rescuedFood';
import growthReducer from './growth';
import eventsReducer from './events';
import reportsReducer from './reports';
import leaderboardsReducer from './leaderboards';
import insightsReducer from './insights';
import metricsReducer from './metrics';
import pastRescuesReducer from './pastRescues';
import emailsReducer from './emails';
import siteGoalsReducer from './siteGoals';
import environmentImpactReducer from './enviromentImpact';
import instagramReducer from './instagram';
import marketingHubReducer from './marketingHub';

const entitiesReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        marketingHub: marketingHubReducer(state.marketingHub, action),
        instagramItems: instagramReducer(state.instagramItems, action),
        environmentImpact: environmentImpactReducer(state.environmentImpact, action),
        siteGoals: siteGoalsReducer(state.siteGoals, action),
        badges: badgesReducer(state.badges, action),
        donations: donationsReducer(state.donations, action),
        donors: donorsReducer(state.donors, action),
        umbrellaFoodDonors: umbrellaFoodDonorsReducer(state.umbrellaFoodDonors, action),
        events: eventsReducer(state.events, action),
        featureFlags: featureFlagsReducer(state.featureFlags, action),
        foodDescriptions: foodDescriptionsReducer(state.foodDescriptions, action),
        foodDescriptionUnitLbs: foodDescriptionUnitLbsReducer(state.foodDescriptionUnitLbs, action),
        foodDonors: foodDonorsReducer(state.foodDonors, action),
        growth: growthReducer(state.growth, action),
        insights: insightsReducer(state.insights, action),
        leaderboards: leaderboardsReducer(state.leaderboards, action),
        metrics: metricsReducer(state.metrics, action),
        notifications: notificationsReducer(state.notifications, action),
        pastRescues: pastRescuesReducer(state.pastRescues, action),
        receivers: receiversReducer(state.receivers, action),
        reports: reportsReducer(state.reports, action),
        rescueSizes: rescueSizesReducer(state.rescueSizes, action),
        donationSizes: DonationSizesReducer(state.donationSizes, action),
        rescuedFood: rescuedFoodReducer(state.rescuedFood, action),
        rescuerLocations: rescuerLocationsReducer(state.rescuerLocations, action),
        rescuers: rescuersReducer(state.rescuers, action),
        rescues: rescuesReducer(state.rescues, action),
        requestDonations: requestDonationsReducer(state.requestDonations, action),
        settings: settingsReducer(state.settings, action),
        sites: sitesReducer(state.sites, action),
        systemSettings: systemSettingsReducer(state.systemSettings, action),
        units: unitsReducer(state.units, action),
        userRegistrations: userRegistrationsReducer(state.userRegistrations, action),
        users: usersReducer(state.users, action),
        zipCodes: zipCodesReducer(state.zipCodes, action),
        emails: emailsReducer(state.emails, action),
      };
  }
};

export default entitiesReducer;
