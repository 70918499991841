import React from 'react';
import Bluebird from 'bluebird';
import {
  Paper,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { InfoOutlined, Help as HelpIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import * as usersNotificationsPreferencesApi from '../api/usersNotificationsPreferences';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import * as userHelper from '../helpers/user';
import OverlayLoader from './OverlayLoader';
import { Colors } from '../assets/theme/Colors';

const NOTIFICATION_TYPE_INTERNAL = 'internal';
const NOTIFICATION_TYPE_SMS = 'sms';
const NOTIFICATION_TYPE_EMAIL = 'email';
const NOTIFICATION_TYPE_PUSH = 'push';

class NotificationPreferences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      notificationPreferences: props.notification.user_preferences,
    };
  }

  handleChange = event => {
    const { notification, user } = this.props;
    const { notificationPreferences } = this.state;
    const eventTargetName = event.target.value;

    notificationPreferences[eventTargetName] = event.target.checked;

    if (eventTargetName === 'realtime') {
      notificationPreferences.digest = false;
    } else if (eventTargetName === 'digest') {
      notificationPreferences.realtime = false;
    }
    const isUserPhoneVerified = userHelper.isUserPhoneNumberVerified(user);
    const isUserEmailVerified = userHelper.isUserEmailVerified(user);

    if (
      !notificationPreferences.internal &&
      (!notificationPreferences.email || !isUserEmailVerified) &&
      (!notificationPreferences.sms || !isUserPhoneVerified) &&
      !notificationPreferences.push
    ) {
      if (eventTargetName === 'receive') {
        notificationPreferences.internal = true;
        notificationPreferences.email = isUserEmailVerified;
        notificationPreferences.sms = isUserPhoneVerified;
        notificationPreferences.push = true;
      } else {
        notificationPreferences.receive = false;
      }
    }

    this.setState({
      loading: true,
      notificationPreferences,
    });

    return Bluebird.try(() =>
      usersNotificationsPreferencesApi.storeOrUpdate(user.id, {
        ...notificationPreferences,
        type: notification.type,
      })
    )
      .then(() => snackbarHelper.success('Notification preferences has been saved successfully!'))
      .catch(err => errorsHelper.handleError(err))
      .finally(() =>
        this.setState({
          loading: false,
        })
      );
  };

  isCheckboxDisabled = notificationType => {
    const { notificationPreferences } = this.state;
    const { notification, user, pushNotificationEnabled = false } = this.props;
    const isUserPhoneNumberVerified = userHelper.isUserPhoneNumberVerified(user);
    const isUserEmailVerified = userHelper.isUserEmailVerified(user);

    if (notificationType === NOTIFICATION_TYPE_SMS && !isUserPhoneNumberVerified) {
      return true;
    }

    if (notificationType === NOTIFICATION_TYPE_EMAIL && !isUserEmailVerified) {
      return true;
    }

    if (notificationType === NOTIFICATION_TYPE_PUSH && !pushNotificationEnabled) {
      return true;
    }

    if (!notification.mandatory) {
      return !notificationPreferences.receive;
    }

    return (
      [
        notificationPreferences[NOTIFICATION_TYPE_INTERNAL],
        notificationPreferences[NOTIFICATION_TYPE_EMAIL],
        notificationPreferences[NOTIFICATION_TYPE_SMS],
        notificationPreferences[NOTIFICATION_TYPE_PUSH],
      ].filter(isChecked => isChecked).length === 1 && notificationPreferences[notificationType]
    );
  };

  render() {
    const { loading, notificationPreferences } = this.state;
    const { user, notification, classes, pushNotificationEnabled = false, hasPushNotificationsFF = false } = this.props;
    const isDisabled = notification.immediate || !notificationPreferences.receive;
    const isUserPhoneNumberVerified = userHelper.isUserPhoneNumberVerified(user);
    const isUserEmailVerified = userHelper.isUserEmailVerified(user);

    return (
      <OverlayLoader isLoading={loading}>
        <Paper className={classes.paper}>
          <Grid container spacing={3} justify="space-between" alignItems="center">
            <Grid item lg={3} md={12} xs={12}>
              <Typography variant="h6" color="textPrimary">
                {notification.display_name}
              </Typography>

              {notification.mandatory && (
                <Typography color="secondary" style={{ marginBottom: notification.description ? 9 : 0 }}>
                  <InfoOutlined fontSize="small" className={classes.infoIcon} />
                  This notification is mandatory.
                </Typography>
              )}

              {notification.description && <Typography color="textSecondary">{notification.description}</Typography>}
            </Grid>

            <Grid item lg={3} md={4} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationPreferences.receive}
                    value="receive"
                    onChange={this.handleChange}
                    disabled={notification.immediate}
                  />
                }
                label="Yes, I want to receive this notification"
              />
            </Grid>

            <Grid item lg={3} md={4} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Typography variant="body2" color="textSecondary">
                    How do you want to receive this notification?
                  </Typography>
                </FormLabel>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={notificationPreferences.internal}
                        value={NOTIFICATION_TYPE_INTERNAL}
                        onChange={this.handleChange}
                        className={classes.checkboxesAndRadios}
                        disabled={this.isCheckboxDisabled(NOTIFICATION_TYPE_INTERNAL)}
                      />
                    }
                    label="by a in-app message"
                  />

                  {notification.user_may_choose_email && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isUserEmailVerified ? notificationPreferences.email : false}
                          value={NOTIFICATION_TYPE_EMAIL}
                          onChange={this.handleChange}
                          className={classes.checkboxesAndRadios}
                          disabled={this.isCheckboxDisabled(NOTIFICATION_TYPE_EMAIL)}
                        />
                      }
                      label={
                        <>
                          by an e-mail
                          {!isUserEmailVerified && (
                            <Tooltip
                              arrow
                              placement="top"
                              title={
                                `Your email address is not verified. ` +
                                `Therefore, you are unable to receive notifications in this way. ` +
                                `Please verify your email in the profile section of the software.`
                              }
                              style={{ position: 'absolute', marginLeft: 5 }}
                            >
                              <HelpIcon htmlColor={Colors.blue.main} />
                            </Tooltip>
                          )}
                        </>
                      }
                    />
                  )}

                  {notification.user_may_choose_sms && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isUserPhoneNumberVerified ? notificationPreferences.sms : false}
                          value={NOTIFICATION_TYPE_SMS}
                          onChange={this.handleChange}
                          className={classes.checkboxesAndRadios}
                          disabled={this.isCheckboxDisabled(NOTIFICATION_TYPE_SMS)}
                        />
                      }
                      label={
                        <>
                          by a text message
                          {!isUserPhoneNumberVerified && (
                            <Tooltip
                              arrow
                              placement="top"
                              title={
                                `Your phone# is not verified, therefore you are not allowed to ` +
                                `receive notification in this way.`
                              }
                              style={{ position: 'absolute', marginLeft: 5 }}
                            >
                              <HelpIcon htmlColor={Colors.blue.main} />
                            </Tooltip>
                          )}
                        </>
                      }
                    />
                  )}

                  {notification.user_may_choose_push && hasPushNotificationsFF && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pushNotificationEnabled ? notificationPreferences.push : false}
                          value={NOTIFICATION_TYPE_PUSH}
                          onChange={this.handleChange}
                          className={classes.checkboxesAndRadios}
                          disabled={this.isCheckboxDisabled(NOTIFICATION_TYPE_PUSH)}
                        />
                      }
                      label={
                        <>
                          by a push notification
                          {!pushNotificationEnabled && (
                            <Tooltip
                              arrow
                              placement="top"
                              title="Please enable push notifications for Food Rescue US in your browser to receive notification this way."
                              style={{ position: 'absolute', marginLeft: 5 }}
                            >
                              <HelpIcon htmlColor={Colors.blue.main} />
                            </Tooltip>
                          )}
                        </>
                      }
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item lg={3} md={4} xs={12}>
              {notification.user_may_choose_digest && (
                <FormControl component="fieldset">
                  <Typography variant="body2" color="textSecondary">
                    When do you want to receive this notification?
                  </Typography>

                  <RadioGroup
                    aria-label="Gender"
                    name="gender1"
                    value={notificationPreferences.realtime ? 'realtime' : 'digest'}
                    onChange={this.handleChange}
                    style={{ display: 'inline' }}
                  >
                    <FormControlLabel
                      value="realtime"
                      control={<Radio className={classes.checkboxesAndRadios} />}
                      label="immediately"
                      disabled={isDisabled}
                    />

                    <FormControlLabel
                      value="digest"
                      control={<Radio className={classes.checkboxesAndRadios} />}
                      label="in a daily digest"
                      disabled={isDisabled}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Paper>
      </OverlayLoader>
    );
  }
}

const styles = theme => ({
  paper: {
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: 16,
    },
  },
  checkboxesAndRadios: {
    paddingTop: 2.5,
    paddingBottom: 2.5,
  },
  infoIcon: {
    position: 'relative',
    top: 5,
    marginRight: 2.5,
  },
});

export default withStyles(styles)(NotificationPreferences);
