import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { generatePath } from 'react-router-dom';
import routes from '../../../../routes';
import { assign } from 'lodash';
import { RESCUE_BTN_ADOPTED } from '../../../rescueAdoptConfirmation/RescueAdoptConfirmation';
import useNotificationService from '../../../../hooks/useNotificationService';
import useActiveUser from '../../../../hooks/useActiveUser';
import { Box, CardContent, Typography } from '@material-ui/core';
import { filterRescuesList, getScheduleAvailableRescues } from '../../../../helpers/RescuesHelper';
import RescuerScheduleFiltersContainer from '../../../../components/RescuersScheduleFilters/RescuerScheduleFiltersContainer';
import RescuerDashboardScheduleRescuesTable from './Components/RescuerDashboardScheduleRescuesTable';
import { DashboardTableCard } from '../../../../components/Dashboard/DashboardTableCard';
import TableSkeletonLoader from '../../../../components/Common/TableSkeletonLoader';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import useRescuerRescueActions from '../../../../hooks/useRescuerRescueActions';
import useUIFilter from '../../../../hooks/useUIFilter';
import { RESCUER_SCHEDULE_FILTER } from '../../rescuerDashboardMobile/Schedule/ScheduleMobile';
import { initialRescuerScheduleFilters } from '../../../../reducers/ui';

const RescuerScheduleDesktop = ({ location, history }) => {
  const activeUser = useActiveUser();
  const rescuesEntities = useSelector(state => state.entities.rescues);
  const { getUIFilter } = useUIFilter();
  const scheduleFilters = assign(initialRescuerScheduleFilters, getUIFilter(RESCUER_SCHEDULE_FILTER));
  const { addSuccessNotification } = useNotificationService();
  const { fetchScheduleTab } = useRescuerDashboardActions();
  const { rescueClaim } = useRescuerRescueActions();

  const showUnclaimedRescuesOnly = scheduleFilters.showOnlyAvailableRescues.value;
  const rescuerAvailableRescues = getScheduleAvailableRescues(activeUser.id, rescuesEntities);
  const isInflight =
    rescuesEntities.inflight || rescuerAvailableRescues.inflight || rescuerAvailableRescues.lastUpdated === null;

  const allRescues = rescuerAvailableRescues.data.map(id => rescuesEntities.byId[id]);
  const availableRescues = filterRescuesList(allRescues, scheduleFilters);

  const onAdoptClick = rescue => history.push(generatePath(routes.rescueAdoptConfirmation, { rescueId: rescue.id }));
  const onCellClick = rescue => history.push(generatePath(routes.rescue, { rescueId: rescue.id }));

  useEffect(() => {
    if (
      location.state &&
      location.state.adoptButton &&
      matchPath(location.state.prevPath, {
        path: routes.rescueAdoptConfirmation,
        exact: true,
      })
    ) {
      const { state } = location;
      history.replace(location.pathname, null); //remove rescueAdoptConfirmation

      if (state.adoptButton === RESCUE_BTN_ADOPTED) {
        addSuccessNotification('Rescue adopted');
      }
    }
  }, []);

  useEffect(() => {
    fetchScheduleTab(false, true);
  }, [activeUser.id, fetchScheduleTab]);

  return (
    <DashboardTableCard isLoading={isInflight}>
      <Box className="MuiCardHeader-root" px={2} py={1} my={1}>
        <RescuerScheduleFiltersContainer
          isMobileView={false}
          filteredRows={availableRescues}
          availableRescues={allRescues}
        />
      </Box>
      <CardContent data-introid="schedule-rescues-container">
        {isInflight && <TableSkeletonLoader cols={8} rows={10} actionButtons={4} />}

        {!isInflight && availableRescues.length === 0 && allRescues.length === 0 && <Box>No available rescues.</Box>}

        {!isInflight && availableRescues.length === 0 && allRescues.length > 0 && (
          <Box>There are no available rescues that match the current filter criteria.</Box>
        )}

        {!isInflight && availableRescues.length > 0 && (
          <RescuerDashboardScheduleRescuesTable
            tableId="#rescuer-dashboard-desktop/available-rescues"
            rescues={availableRescues}
            rescuingPaused={activeUser.paused_at}
            onClaimRescueButtonClick={rescueClaim}
            onAdoptRescueButtonClick={onAdoptClick}
            onCellClick={onCellClick}
          />
        )}
      </CardContent>
    </DashboardTableCard>
  );
};

export default RescuerScheduleDesktop;
