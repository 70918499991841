import * as usersNotificationsApi from '../api/usersNotifications';
import { getCurrentlyLoggedInOrImpersonatingUser } from '../services/auth';
import * as notificationsApi from '../api/notifications';
import snackbarHelper from '../helpers/snackbarHelper';
import Bluebird from 'bluebird';
import { shouldFetch } from '../helpers/cache';

export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';
export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';

export const requestMarkNotificationAsRead = notificationId => ({
  type: MARK_NOTIFICATION_AS_READ,
  notificationId,
});

export const receiveNotification = notification => ({
  type: RECEIVE_NOTIFICATION,
  notification,
});

export const requestNotifications = (status = true) => ({
  type: REQUEST_NOTIFICATIONS,
  status,
});

export const receiveNotifications = notifications => ({
  type: RECEIVE_NOTIFICATIONS,
  notifications,
  receivedAt: Date.now(),
});

const requestNotificationDelete = notificationId => ({
  type: DELETE_NOTIFICATION,
  notificationId,
});

const requestNotificationsDelete = notificationsIds => ({
  type: DELETE_NOTIFICATIONS,
  notificationsIds: notificationsIds,
});

export const fetchNotifications = (quietMode = false) => dispatch => {
  if (!quietMode) {
    dispatch(requestNotifications());
  }

  const userId = getCurrentlyLoggedInOrImpersonatingUser().id;

  return usersNotificationsApi
    .getUserNotifications(userId)
    .then(res => res.json())
    .then(json => {
      if (json.result !== 'success') {
        throw Error();
      }

      const notifications = json.data;

      return dispatch(receiveNotifications(notifications));
    })
    .catch(() => {
      dispatch(requestNotifications(false));
    });
};

export const fetchNotification = notificationId => dispatch => Bluebird
  .try(() => notificationsApi.getNotification(notificationId))
  .then(res => res.json())
  .then(json => {
    if (json.result !== 'success') {
      throw Error();
    }

    return dispatch(receiveNotification(json.data));
  });

export const deleteNotification = notificationId => dispatch => {
  return Bluebird
    .try(() => notificationsApi.deleteNotification(notificationId))
    .then(() => dispatch(requestNotificationDelete(notificationId)));
};

export const deleteNotifications = notificationsIds => dispatch => Bluebird
  .try(() => notificationsApi.deleteNotifications(notificationsIds))
  .then(() => dispatch(requestNotificationsDelete(notificationsIds)));

const shouldFetchNotifications = ({ lastUpdated, inflight }) => {
  if (inflight) {
    return false;
  }

  return shouldFetch(lastUpdated, { minutes: 1 });
};

export const fetchNotificationsIfNeeded = () => (dispatch, getState) => {
  const notificationsEntities = getState().entities.notifications;

  if (shouldFetchNotifications(notificationsEntities)) {
    return dispatch(fetchNotifications(notificationsEntities.lastUpdated !== null));
  }
};

export const fetchNotificationIfNeeded = id => (dispatch, getState) => {
  const { notifications } = getState().entities;

  const fetch = !(notifications.unread.byId[id] || notifications.read.byId[id] || {}).hasOwnProperty('content');

  return fetch ? dispatch(fetchNotification(id)) : null;
};

export const markNotificationAsRead = notificationId => dispatch => {
  dispatch(requestNotifications());

  return Bluebird
    .try(() => dispatch(requestMarkNotificationAsRead(notificationId)))
    .then(() => notificationsApi.markNotificationAsRead(notificationId))
    .then(() => dispatch(requestNotifications(false)));
};

export const markNotificationsAsRead = notificationsIds => dispatch => {
  if (!notificationsIds || !notificationsIds.length) {
    return false;
  }

  return Bluebird
    .try(() => notificationsApi.markNotificationsAsRead(notificationsIds))
    .then(() => {
      dispatch(fetchNotifications());

      snackbarHelper.success('Notification(s) have been marked as read successfully.');
    });
};
