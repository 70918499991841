import React, { useState, useEffect } from 'react';
import { RadioGroup, Radio, FormControlLabel, FormControl, FormLabel, Grid, Typography } from '@material-ui/core';
import { NumberField } from '../../../../../components/Form/MuiFormWrapper';
import { LBS_PER_MEAL } from '../../../../../models/systemSettings';
import { toString } from 'lodash';
import useSystemSettings from '../../../../../hooks/useSystemSettings';
import { getRescueEstimatedLbs } from '../../../../../helpers/RescuesHelper';
import { RESCUE_SIZE_BICYCLE_NAME } from '../../donationValidator';

const lbsOptions = {
  estimated: 'estimated',
  provided: 'provided',
};

const LbsSelector = ({
  rescueSizeValue,
  disabled,
  lbs,
  onValueChange,
  rescueSizes,
  error,
  showOnlyTextField = false,
}) => {
  const lbsPerMeal = useSystemSettings(LBS_PER_MEAL);
  const rescueSize = rescueSizes.find(rescue => rescue.id === +rescueSizeValue);
  const [lbsValue, setLbsValue] = useState(toString(lbs));

  const [lbsOption, setLbsOption] = useState(Number.isInteger(lbs) ? lbsOptions.provided : lbsOptions.estimated);
  const estimatedLBSValue = getRescueEstimatedLbs(rescueSize, lbsPerMeal);

  useEffect(() => {
    setLbsOption(Number.isInteger(lbs) ? lbsOptions.provided : lbsOptions.estimated);
    setLbsValue(toString(lbs));
  }, [lbs]);

  const handleEstimationChange = (event, newValue) => {
    if (newValue === lbsOptions.estimated) {
      onValueChange(null);
    }
    return setLbsOption(newValue);
  };

  if (showOnlyTextField) {
    return (
      <NumberField
        required={rescueSize?.name === RESCUE_SIZE_BICYCLE_NAME}
        data-testid="lbs"
        variant="outlined"
        style={{maxWidth: 130}}
        label="Weight Estimate"
        value={lbsValue || ''}
        placeholder={toString(
          rescueSize?.meals_per_rescue ? rescueSize.meals_per_rescue * lbsPerMeal : ''
        )}
        type="number"
        onChange={(event) => {
          setLbsValue(event.target.value);
          return event.preventDefault();
        }}
        onBlur={(event) => onValueChange(event.target.value === '' ? null : event.target.value)}
        InputProps={{
          inputProps: { min: 1 },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!error}
        helperText={error || ''}
      />
    );
  }

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <FormLabel component="legend">Recorded Lbs.</FormLabel>
      <RadioGroup aria-label="lbs estimation" name="lbs_estimation" value={lbsOption} onChange={handleEstimationChange}>
        <FormControlLabel
          value={lbsOptions.estimated}
          control={<Radio />}
          label={
            <Typography component="span">
              Keep Food Rescue US estimated lbs.&nbsp;

              {rescueSize && (
                <>
                  of <strong>{estimatedLBSValue} lbs</strong>&nbsp;
                </>
              )}

              based on selected Rescue Vehicle Size
            </Typography>
          }
        />
        <FormControlLabel value={lbsOptions.provided} control={<Radio />} label="Enter custom estimated lbs." />
      </RadioGroup>
      {lbsOption === lbsOptions.provided && (
        <Grid container item xs={12}>
          <Grid container item md={12} lg={6}>
            <NumberField
              required
              disabled={disabled}
              name="lbs"
              data-testid="lbs"
              label="lbs"
              id="lbs"
              onChange={event => {
                setLbsValue(event.target.value);
                return event.preventDefault();
              }}
              onBlur={event => onValueChange(event.target.value === '' ? null : +event.target.value)}
              fullWidth
              value={lbsValue}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: !!lbsValue || undefined,
              }}
              inputProps={{
                min: 1,
              }}
              error={!!error}
              helperText={error || ''}
            />
          </Grid>
        </Grid>
      )}
    </FormControl>
  );
};

export default LbsSelector;
