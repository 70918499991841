import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Breadcrumbs, Typography } from '@material-ui/core';
import Bluebird from 'bluebird';
import { createEventShiftRegistration, deleteRescuerEventShiftRegistration } from '../../../api/events';
import useActiveUser from '../../../hooks/useActiveUser';
import useNotificationService from '../../../hooks/useNotificationService';
import errorMessages from '../../../assets/errorMessages';
import routes from '../../../routes';
import EventDetails from '../Components/EventDetails';
import {
  fetchSiteEvent,
  fetchSiteEventsListIfNeeded,
  receiveSiteDeleteEventShiftAdoption,
  receiveSiteDeleteEventShiftRegistration,
  receiveSiteEventAdoption,
  receiveSiteEventShift,
} from '../../../actions/sites';
import useActiveSite from '../../../hooks/useActiveSite';
import { get } from 'lodash';
import EventDetailsBreadcrumb from './EventDetailsBredcrumb';
import { fetchEvent, receiveDeleteEventShiftRegistration } from '../../../actions/events';
import { adoptSiteEvent, deleteEventAdoption } from '../../../api/sites';

const EventDetailsView = ({ match, history }) => {
  const activeUser = useActiveUser();
  const activeSite = useActiveSite();
  const notificationService = useNotificationService();
  const dispatch = useDispatch();
  const siteEvents = useSelector((state) => state.entities.sites.eventsList);
  const { eventId } = match.params;
  const event = siteEvents.byId[eventId];
  const [submittingShift, setIsSubmittingShift] = useState(false);

  useEffect(() => {
    Bluebird
      .try(() => dispatch(fetchSiteEventsListIfNeeded(activeSite.id)))
      .catch((error) => {
        switch (error.code) {
          case errorMessages.ERR_EVENT_NOT_FOUND.code:
            notificationService.addErrorNotification(error, errorMessages.ERR_EVENT_NOT_FOUND.message);
            return history.push(routes.index);
          default:
            notificationService.addErrorNotification(error, error.message);
            return history.push(routes.index);
        }
      });
  }, [dispatch]);

  const handleShiftButtonClick = (shift) => {
    setIsSubmittingShift(shift.id);

    const shiftEventRegistration = get(shift, 'registrations', []).find(
      (r) => r.deleted_at === null && r.user_id === activeUser.id
    );

    if (shiftEventRegistration) {
      return Bluebird.try(() =>
        deleteRescuerEventShiftRegistration(
          shiftEventRegistration.user_id,
          eventId,
          shift.id,
        )
      )
        .then(() => dispatch(receiveSiteDeleteEventShiftRegistration(activeSite.id, eventId, shift.id, shiftEventRegistration.id)))
        .catch((error) => notificationService.addErrorNotification(null, error.message))
        .finally(() => setIsSubmittingShift(false));
    }

    return Bluebird.try(() =>
      createEventShiftRegistration(eventId, shift.id, {
        user_id: activeUser.id,
        created_by_id: activeUser.id,
      })
      )
        .then(() => dispatch(fetchEvent(eventId)))
        .then((data) => data.event.shifts.find((s) => s.id === shift.id))
        .then((eventShift) => dispatch(receiveSiteEventShift(activeSite.id, eventId, shift.id, eventShift)))
      .then(() => notificationService.addSuccessNotification('You have been signed up for this event!'))
      .catch((error) => {
        switch (error.code) {
          case errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.code:
            notificationService.addNotification(errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.message, 'info');
            break;
          case errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.code:
            notificationService.addNotification(
              errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.message,
              'info'
            );
            break;
          default:
            notificationService.addErrorNotification(error, error.message);
            break;
        }
      })
      .finally(() => setIsSubmittingShift(false));
  };

  const handleAdoptClick = (eventSpecsId, rescuerId, shift, createdById, eventSpecs, index) => {
    setIsSubmittingShift(shift.id);

    const shiftEventAdoption = get(eventSpecs, `shifts[${index}].registrations`, []).find(
      (r) => r.deleted_at === null && r.user_id === activeUser.id
    );

    const shiftEventRegistration = get(shift, 'registrations', []).find(
      (r) => r.deleted_at === null && r.user_id === activeUser.id
    );

    if (shiftEventAdoption) {
      return Bluebird.try(() =>
        deleteEventAdoption(activeSite.id, eventSpecsId, {
          event_shift_spec_id: shift.id,
          user_id: rescuerId,
          created_by_id: createdById,
        })
      )
        .then(() => dispatch(receiveSiteDeleteEventShiftAdoption(activeSite.id, eventSpecsId, shift.id, shiftEventAdoption.id)))
        .then(() =>
          shiftEventRegistration
            ? dispatch(
                receiveSiteDeleteEventShiftRegistration(activeSite.id, eventId, shift.id, shiftEventRegistration.id)
              )
            : null
        )
        .catch((error) => notificationService.addErrorNotification(null, error.message))
        .finally(() => setIsSubmittingShift(false));
    }

    return Bluebird.try(() =>
      adoptSiteEvent(activeSite.id, eventSpecsId, {
        event_shift_spec_id: shift.id,
        user_id: rescuerId,
        created_by_id: createdById,
      })
    )
      .then(() => dispatch(fetchSiteEvent(activeSite.id, eventSpecsId)))
      .then((data) => data.event.shifts.find((s) => s.id === shift.id))
      .then((eventShift) => dispatch(receiveSiteEventAdoption(activeSite.id, eventSpecsId, shift.id, eventShift)))
      .then(() => dispatch(fetchEvent(eventId)))
      .then((data) => data.event.shifts.find((s) => s.id === shift.id))
      .then((eventShift) => dispatch(receiveSiteEventShift(activeSite.id, eventId, shift.id, eventShift)))
      .then(() => notificationService.addSuccessNotification('You have adopted this event!'))
      .catch((error) => {
        switch (error.code) {
          case errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.code:
            notificationService.addNotification(errorMessages.ERR_EVENT_SHIFT_HAS_NO_FREE_SLOTS_LEFT.message, 'info');
            break;
          case errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.code:
            notificationService.addNotification(
              errorMessages.ERR_USER_ALREADY_REGISTERED_FOR_EVENT_SHIFT.message,
              'info'
            );
            break;
          default:
            notificationService.addErrorNotification(error, error.message);
            break;
        }
      })
      .finally(() => setIsSubmittingShift(false));
  };

  return (
    <>
      <EventDetailsBreadcrumb />
      <Grid>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <EventDetails
            isLoading={siteEvents.inflight || !event}
            submittingShift={submittingShift}
            eventData={event}
            onShiftButtonClick={handleShiftButtonClick}
            onAdoptClick={handleAdoptClick}
            testId="event-details"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EventDetailsView;
