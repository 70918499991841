import { createBrowserHistory } from 'history';

const { push, ...rest } = createBrowserHistory();
const history = { ...rest };

history.push = (path, state) => {
  // default history.push return undefined which is generating Bluebird warnings
  // http://bluebirdjs.com/docs/warning-explanations.html#warning-a-promise-was-created-in-a-handler-but-was-not-returned-from-it
  push(path, {
    ...state,
    prevPath: window.location.pathname,
  });

  return null;
};

export default history;
