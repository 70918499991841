import React from 'react';
import { useSelector } from 'react-redux';

const isEqual = (prev, next) => (prev ? prev.id : null === next ? next.id : null);

const useActiveSite = () => {
  return useSelector(state => state.app.site, isEqual);
};

export default useActiveSite;
