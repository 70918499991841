import Bluebird from 'bluebird';
import * as reportsApi from '../api/reports';

export const REQUEST_VOLUME_REPORT = 'REQUEST_VOLUME_REPORT';
export const RECEIVE_VOLUME_REPORT = 'RECEIVE_VOLUME_REPORT';
export const REQUEST_RECEIVING_AGENCY_REPORT = 'REQUEST_RECEIVING_AGENCY_REPORT';
export const RECEIVE_RECEIVING_AGENCY_REPORT = 'RECEIVE_RECEIVING_AGENCY_REPORT';
export const REQUEST_RESCUE_REPORT = 'REQUEST_RESCUE_REPORT';
export const RECEIVE_RESCUE_REPORT = 'RECEIVE_RESCUE_REPORT';

export const requestVolumeReport = () => ({
  type: REQUEST_VOLUME_REPORT,
});

export const receiveVolumeReport = volumeReport => ({
  type: RECEIVE_VOLUME_REPORT,
  volumeReport: volumeReport,
  receivedAt: Date.now(),
});

export const requestReceivingAgencyReport = () => ({
  type: REQUEST_RECEIVING_AGENCY_REPORT,
});

export const receiveReceivingAgencyReport = receiverReport => ({
  type: RECEIVE_RECEIVING_AGENCY_REPORT,
  receiverReport: receiverReport,
  receivedAt: Date.now(),
});

export const fetchVolumeReport = (type, from, to, granularity, sitesIds = [], suppressEmptyRows = true, completedRegistration) => dispatch => {
  dispatch(requestVolumeReport());

  return Bluebird.try(() => reportsApi.getVolume(type, from, to, granularity, sitesIds, suppressEmptyRows, completedRegistration))
    .then(res => res.json())
    .then(json => dispatch(receiveVolumeReport(json.data)));
};

export const fetchVolumeReportIfNeeded = (
  type,
  from,
  to,
  granularity,
  sitesIds = [],
  suppressEmptyRows = true,
  completedRegistration
) => dispatch => dispatch(fetchVolumeReport(type, from, to, granularity, sitesIds, suppressEmptyRows, completedRegistration));

export const fetchReceivingAgency = (from, to, granularity, sitesIds = [], receivingAgenciesIds = []) => dispatch => {
  dispatch(requestReceivingAgencyReport());

  return Bluebird
    .try(() => reportsApi.getReceivingAgency(from, to, granularity, sitesIds, receivingAgenciesIds))
    .then(res => res.json())
    .then(json => dispatch(receiveReceivingAgencyReport(json.data)));
};

export const fetchReceivingAgencyReportIfNeeded = (
  from,
  to,
  granularity,
  sitesIds = [],
  receivingAgenciesIds = []
) => dispatch => dispatch(fetchReceivingAgency(from, to, granularity, sitesIds, receivingAgenciesIds));

export const requestRescueReport = () => ({
  type: REQUEST_RESCUE_REPORT,
});

export const receiveRescueReport = report => ({
  type: RECEIVE_RESCUE_REPORT,
  report: report,
  receivedAt: Date.now(),
});

export const fetchRescueReport = (startDate, endDate, granularity, siteIds) => dispatch => {
  dispatch(requestRescueReport());

  return Bluebird
    .try(() => reportsApi.getRescues(startDate, endDate, granularity, siteIds))
    .then(res => res.json())
    .then(json => dispatch(receiveRescueReport(json.data)));
};

export const fetchRescueReportIfNeeded = (startDate, endDate, granularity, siteIds) => dispatch =>
  dispatch(fetchRescueReport(startDate, endDate, granularity, siteIds));
