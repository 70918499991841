import { apiDELETE, apiGET, apiPATCH } from './api';

export const getNotification = notificationId => {
  return apiGET(`/notifications/${notificationId}`);
};

export const markNotificationAsRead = notificationId => apiPATCH(`/notifications/${notificationId}/mark_as_read`, {});

export const markNotificationsAsRead = notificationsIds => apiPATCH(
  `/notifications/mark_as_read`,
  {
    ids: notificationsIds,
  }
);

export const deleteNotification = notificationId => apiDELETE(`/notifications/${notificationId}`);

export const deleteNotifications = notificationsIds => apiDELETE('/notifications', { ids: notificationsIds });
